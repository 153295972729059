'use strict';

class StatusService {
  constructor($q, $http, $state, $window, $location, cdApp) {
    'ngInject';

    this.$q = $q;
    this.$http = $http;
    this.$state = $state;
    this.$window = $window;
    this.$location = $location;
    this.cdApp = cdApp;
  }

  getStatus() {
    return this.$http
      .get(`${this.cdApp.config.api.main}/terminal/status`)
      .then((response) => {
        this.cdApp.status = response.data || { reasons: [] };
      })
      .catch(() => {
        this.cdApp.status = { reasons: [] };
      });
  }
}
StatusService.$inject = [
  '$q',
  '$http',
  '$state',
  '$window',
  '$location',
  'cdApp',
];

angular
  .module('cdApp.shared')
  .service('StatusService', StatusService)
  .service('Status', [
    'StatusService',
    function (StatusService) {
      return StatusService.getStatus();
    },
  ]);
