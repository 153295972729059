import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';

import ElementWrapper from './ElementWrapper';

const Tags: React.FC<{ tags: string[] }> = ({ tags }) => (
  <React.Fragment>
    {(tags || []).length > 0 ? (
      <ElementWrapper>
        <FontAwesomeIcon icon={faTags} />
        <span>{tags.join(', ')}</span>
      </ElementWrapper>
    ) : null}
  </React.Fragment>
);

export default Tags;
