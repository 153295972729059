export enum ResourceTypes {
  CHURCH = 'church',
}

export interface Resource {
  id: number;
  name: string;
  color: number;
  description: string;
  shortName: string;
  orderNumber: number;
  externalId: string;
  isRoom: boolean;
  organizationId: number;
  type: ResourceTypes;
  parentResourceId: number;
  location: any;
  locationName: string;
  resources: Resource[];
  migrationIntentionYear: number;
  migrationIntentionReferenceNumber: number;
  migrationLegatReferenceNumber: number;
  migrationStiftungReferenceNumber: number;
  // Added properties
  churchName: string;
  canEdit: boolean;
  canDelete: boolean;
  // Timestamps
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface ResourceWithAccessContext extends Resource {
  access?: boolean;
}
export interface ChurchesWithPermissionContext {
  churches: ResourceWithAccessContext[];
  viaOrganizationGrant?: boolean;
}
