import { combineReducers } from '@reduxjs/toolkit';

import MainReducer, { ImmutableCalendarViewState } from './main-view';
import FilterReducer, { ImmutableFilterState } from './filters';
import { absenceReducer, AbsenceState } from './absences/absencesSlice';
import { eventsReducer, EventsState } from './events/eventsSlice';
import {
  FullCalendarEventsState,
  eventsToRenderReducer,
} from './events/fullCalendarEventsSlice';
import {
  EventsDetailsState,
  eventsDetailsReducer,
} from './events/eventsDetailsSlice';

export interface CalendarState {
  view: ImmutableCalendarViewState;
  filters: ImmutableFilterState;
  absences: AbsenceState;
  events: EventsState;
  fullCalendarEvents: FullCalendarEventsState;
  eventsDetails: EventsDetailsState;
}

export default combineReducers({
  view: MainReducer,
  filters: FilterReducer,
  absences: absenceReducer,
  events: eventsReducer,
  fullCalendarEvents: eventsToRenderReducer,
  eventsDetails: eventsDetailsReducer,
});
