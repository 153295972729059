import _ from 'lodash';

import { getCommaFormattedNumber } from '../../../../react/shared/utils';

/**
 * A drop-down that can be used to filter resources and select one or more of them.
 *
 * @prop {object[]} resourceSource - The available resources to choose from
 * @prop {number[] | string[]} eventChurchIds - The IDs of the selected `church` resources in the list
 * @prop {number[] | string[]} eventResourceIds - The IDs of the selected resources in the list
 * @prop {Function} onChurchSelected({ selected: Number[] }) - A callback whenever a `church`resource is selected
 * @prop {Function} onResourceSelected({ selected: Number[] }) - A callback whenever a resource is selected
 */
class ResourceAddressSelectComponent {
  constructor(
    appUtils,
    Authorization,
    toastr,
    $ngRedux,
    uiSelectAllowNewMarker,
    cdApp,
    gettextCatalog
  ) {
    'ngInject';

    this.appUtils = appUtils;
    this.toastr = toastr;
    this.uiSelectAllowNewMarker = uiSelectAllowNewMarker;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
  }

  // Initialization functions

  $onInit() {
    const { appUtils, toastr } = this;
    this.resourcesWithAddress = [];
    this.selectedChurchIds = this.eventChurchIds || [];
    this.selectedChurchIds &&
      this.onChurchSelected({ selectedChurchIds: this.selectedChurchIds });
    this.selectedResourceIds = this.eventResourceIds || [];
    this.noConflicts = false;
    // Init resources
    this.isComponentInitializing = true;
    this.showChurchField = false;
    const isResourcesPromise =
      typeof _.get(this.resourceSource, '$promise.then') === 'function';
    if (isResourcesPromise) {
      this.resourceSource.$promise
        .then((resources) => {
          this.initChurchesResourcesAndAddress(resources);
          this.processSelectedChurches();
        })
        .catch((error) => {
          toastr.error(appUtils.getErrorMessage(error));
        })
        .finally(() => {
          this.isComponentInitializing = false;
        });
    } else {
      this.initChurchesResourcesAndAddress(this.resourceSource);
      this.processSelectedChurches();
    }

    this.showChurchSelector = this.cdApp.showChurchSelector;

    // Register a reset function
    this.onRegisterChildReference({ childRef: this });
  }

  initChurchesResourcesAndAddress(resources) {
    const { appUtils } = this;
    // Init sorted resources

    const churches = _.filter(resources, (resource) =>
      appUtils.isResourceChurch(resource)
    );

    this.sortedChurches = _.sortBy(churches, (church) =>
      _.toLower(_.get(church, 'name'))
    );

    _.each(this.sortedChurches, (sortedChurch) => {
      const childResources = _.get(sortedChurch, 'resources');
      const sortedChildResources = _.sortBy(childResources, (childResource) =>
        _.toLower(_.get(childResource, 'name'))
      );

      _.set(sortedChurch, 'sortedResources', sortedChildResources);
    });
    const sortedChurchesWithChildResources = _.filter(
      this.sortedChurches,
      (sortedChurch) => !_.isEmpty(_.get(sortedChurch, 'resources'))
    );

    const standaloneResources = _.filter(
      resources,
      (resource) => !_.get(resource, 'type')
    );

    const sortedStandaloneResources = _.sortBy(
      standaloneResources,
      (standaloneResource) => _.toLower(_.get(standaloneResource, 'name'))
    );

    this.sortedResourceTree = [
      ...sortedChurchesWithChildResources,
      ...sortedStandaloneResources,
    ];

    this.sortedResourceArray = this.resourceTreeToArray(
      this.sortedResourceTree,
      'sortedResources'
    );

    // If editing an event for an organization with the grouped resources package enabled, retrieve the selected 'church' resources
    if (!_.isEmpty(this.selectedChurchIds)) {
      this.processSelectedChurches();
    } else {
      this.selectedChurches = [];
    }

    // Init filtered resources
    this.initFilteredResources();

    // If editing an event with resources, retrieve those resources
    if (!_.isEmpty(this.selectedResourceIds)) {
      this.processSelectedResources();
    } else {
      this.selectedResources = [];
    }

    // If single church, select the single church as default.
    if (!this.showChurchSelector) {
      this.onChurchSelected({
        selectedChurchIds: _.map(this.sortedChurches, 'id'),
      });
    }
  }

  processSelectedChurches() {
    // Select the 'church' resource objects based on the selected IDs, keeping the order in which they were selected
    this.selectedChurches = [];
    _.each(this.selectedChurchIds, (selectedChurchId) => {
      const selectedChurch = _.find(this.sortedChurches, {
        id: selectedChurchId,
      });

      if (selectedChurch) {
        this.selectedChurches.push(selectedChurch);
      }
    });
  }

  initFilteredResources() {
    const { appUtils } = this;
    this.filteredResourceTree = _.map(
      this.sortedResourceTree,
      (sortedResource) => {
        if (appUtils.isResourceChurch(sortedResource)) {
          _.set(
            sortedResource,
            'filteredResources',
            _.sortBy(_.get(sortedResource, 'sortedResources'), 'name')
          );
        }
        return sortedResource;
      }
    );

    this.filteredResourceArray = this.resourceTreeToArray(
      this.filteredResourceTree,
      'filteredResources'
    );
  }

  processSelectedResources() {
    // Select the resource objects based on the selected IDs, keeping the order in which they were selected
    let _selectedResources = [];
    let _resourcesWithAddress = [];
    _.each(this.selectedResourceIds, (selectedResourceId) => {
      const selectedResource = _.find(this.sortedResourceArray, {
        id: selectedResourceId,
      });

      _selectedResources.push(selectedResource);
      // Process resource addresses
      let resourceWithAddress;
      resourceWithAddress = selectedResource;
      if (
        (_.get(resourceWithAddress, 'location') ||
          _.get(resourceWithAddress, 'locationName')) &&
        !_.find(_resourcesWithAddress, { id: resourceWithAddress.id })
      ) {
        _resourcesWithAddress.push(resourceWithAddress);
      }
    });
    this.selectedResources = _selectedResources;
    this.resourcesWithAddress = _resourcesWithAddress;
  }

  // Main functions

  onResourceDropDownToggle(open) {
    this.isResourceDropDownOpen = open;
    this.indexOfSelectedResource = -1;
    // When closing the resource drop-down, clear the filtering options
    if (!open) {
      this.searchText = null;
      this.idOfSelectedResource = null;
      this.initFilteredResources();
    }
  }

  toggleResource(resource) {
    /**
     * Process the selected resource.
     */
    const { appUtils } = this;
    const isResourceAChurch = appUtils.isResourceChurch(resource);
    const manageChurch = isResourceAChurch;
    let newSelectedResourceIds;
    if (manageChurch) {
      const isResourceSelected = this.isResourceSelected(resource);
      const churchResourceIds = _.map(_.get(resource, 'resources'), 'id');
      if (isResourceSelected) {
        newSelectedResourceIds = _.without(
          this.selectedResourceIds,
          ...churchResourceIds
        );
      } else {
        newSelectedResourceIds = _.uniq([
          ...this.selectedResourceIds,
          ...churchResourceIds,
        ]);
      }
    } else {
      newSelectedResourceIds = _.includes(
        this.selectedResourceIds,
        _.get(resource, 'id')
      )
        ? _.without(this.selectedResourceIds, _.get(resource, 'id'))
        : [...this.selectedResourceIds, _.get(resource, 'id')];
    }
    // Set the selected resources
    this.selectedResourceIds = newSelectedResourceIds;
    // Once the resource ID's have been processed, select the resource objects
    this.processSelectedResources();
    // Finally, update the parent component's with the selected resource IDs
    this.onResourceSelected({ selectedResourceIds: this.selectedResourceIds });
    /**
     * Process the corresponding church, if applicable.
     */
    const resourceChurch = _.find(this.sortedChurches, {
      id: _.get(resource, 'parentResourceId'),
    });

    if (isResourceAChurch || resourceChurch) {
      const churchId = isResourceAChurch
        ? _.get(resource, 'id')
        : _.get(resourceChurch, 'id');
      // If the selected resource is a church or it's parent resource is a church,
      // add the church to the group of selected churches, unless it's already selected
      if (!_.includes(this.selectedChurchIds, churchId)) {
        this.selectedChurchIds = [...this.selectedChurchIds, churchId];
        // Once the church ID's have been processed, select the church objects
        this.processSelectedChurches();
        // Finally, update the parent component's with the selected church IDs
        this.onChurchSelected({ selectedChurchIds: this.selectedChurchIds });
      }
    }
  }

  checkResourceAvailability() {
    this.checkAvailability({
      onSuccess: () => {
        this.noConflicts = true;
      },
    });
  }

  resetSelectedResourceAndChurches() {
    if (this.showChurchSelector) {
      this.selectedChurchIds = [];
      this.selectedChurches = [];
      this.selectedResourceIds = [];
      this.selectedResources = [];
    } else {
      this.selectedResourceIds = [];
      this.selectedResources = [];
      this.onChurchSelected({
        selectedChurchIds: _.map(this.sortedChurches, 'id'),
      });
    }
  }

  /**
   * Handle keyboard navigation on the resources component.
   *
   * @param {Object} $event The original event object
   */
  resourcesKeyDown($event) {
    // Up key
    if ($event.which === 38) {
      this.indexOfSelectedResource =
        this.indexOfSelectedResource === 0
          ? _.size(this.filteredResourceArray) - 1
          : this.indexOfSelectedResource - 1;
      this.selectResource();
      $event.preventDefault();
    }
    // Down key
    if ($event.which === 40) {
      this.indexOfSelectedResource =
        this.indexOfSelectedResource === _.size(this.filteredResourceArray) - 1
          ? 0
          : this.indexOfSelectedResource + 1;
      this.selectResource();
      $event.preventDefault();
    }
    // Return/Enter key
    if ($event.which === 13) {
      this.toggleResource(this.getSelectedResource());
      $event.preventDefault();
    }
    // Escape key
    if ($event.which === 27) {
      this.isResourceDropDownOpen = false;
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  selectResource() {
    const selectedResource = this.getSelectedResource();
    this.idOfSelectedResource = _.get(selectedResource, 'id');
  }

  getSelectedResource() {
    return (
      _.isFinite(this.indexOfSelectedResource) &&
      this.filteredResourceArray[this.indexOfSelectedResource]
    );
  }

  onChurchDropDownToggle(open) {
    this.isChurchDropDownOpen = open;
    this.indexOfSelectedChurch = -1;
    // When closing the church drop-down, clear the filtering options
    if (!open) {
      this.idOfSelectedChurch = null;
    }
  }

  toggleChurch(church) {
    // Set the selected church
    this.selectedChurchIds = _.includes(
      this.selectedChurchIds,
      _.get(church, 'id')
    )
      ? _.without(this.selectedChurchIds, _.get(church, 'id'))
      : [...this.selectedChurchIds, _.get(church, 'id')];
    // Once the church ID's have been processed, select the church objects
    this.processSelectedChurches();
    // Finally, update the parent component's with the selected church IDs
    this.onChurchSelected({ selectedChurchIds: this.selectedChurchIds });
  }

  /**
   * Handle keyboard navigation on the churches component.
   *
   * @param {Object} $event The original event object
   */
  churchesKeyDown($event) {
    // Up key
    if ($event.which === 38) {
      this.indexOfSelectedChurch =
        this.indexOfSelectedChurch === 0
          ? _.size(this.sortedChurches) - 1
          : this.indexOfSelectedChurch - 1;
      this.selectChurch();
      $event.preventDefault();
    }
    // Down key
    if ($event.which === 40) {
      this.indexOfSelectedChurch =
        this.indexOfSelectedChurch === _.size(this.sortedChurches) - 1
          ? 0
          : this.indexOfSelectedChurch + 1;
      this.selectChurch();
      $event.preventDefault();
    }
    // Return/Enter key
    if ($event.which === 13) {
      this.toggleChurch(this.getSelectedChurch());
      $event.preventDefault();
    }
    // Escape key
    if ($event.which === 27) {
      this.isChurchDropDownOpen = false;
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  selectChurch() {
    const selectedChurch = this.getSelectedChurch();
    this.idOfSelectedChurch = _.get(selectedChurch, 'id');
  }

  getSelectedChurch() {
    return (
      _.isFinite(this.indexOfSelectedChurch) &&
      this.sortedChurches[this.indexOfSelectedChurch]
    );
  }

  isResourceSelectionDisabled() {
    return !this.canEditFieldHelper('resources');
  }

  // Conditional rendering

  showResourceSearchInput() {
    let size = _.size(this.sortedResourceTree);
    _.each(this.sortedResourceTree, (sortedResource) => {
      size += _.size(_.get(sortedResource, 'resources'));
    });
    return size > 8;
  }

  showAddressPlaceholder() {
    return (
      !this.isComponentInitializing &&
      !(this.selectedAddress || this.freeTextOrGoogleAddress)
    );
  }

  showAddress() {
    return (
      !this.isComponentInitializing &&
      (this.selectedAddress || this.freeTextOrGoogleAddress)
    );
  }

  showResetSelectedAddress() {
    return this.showAddress() && !this.isAddressSelectionDisabled();
  }

  showChurchesRequiredError() {
    return (
      this.contentCreation.event.$dirty && _.isEmpty(this.selectedChurches)
    );
  }

  // Styles

  getChurchClass(church) {
    return this.isChurchSelected(church)
      ? 'fa fa-check-square'
      : 'far fa-square';
  }

  getResourceClass(resource) {
    return this.isResourceSelected(resource)
      ? 'fa fa-check-square'
      : 'far fa-square';
  }

  getChurchesRequiredErrorClass() {
    return this.showChurchesRequiredError() ? 'ng-invalid' : '';
  }

  // Helper functions

  isChurchSelected(church) {
    return _.includes(this.selectedChurchIds, _.get(church, 'id'));
  }

  resourceTreeToArray(resourceTree, childResourceProperty) {
    const { appUtils } = this;
    const resourceArray = [];
    _.each(resourceTree, (resource) => {
      resourceArray.push(resource);
      if (appUtils.isResourceChurch(resource)) {
        const childResources = _.get(resource, childResourceProperty);
        if (!_.isEmpty(childResources)) resourceArray.push(...childResources);
      }
    });
    return resourceArray;
  }

  isResourceSelected(resource) {
    const resourcesToCheck = _.get(resource, 'resources', [resource]);
    return _.every(resourcesToCheck, (resourceToCheck) =>
      _.includes(this.selectedResourceIds, _.get(resourceToCheck, 'id'))
    );
  }

  updateResourceList() {
    const { appUtils } = this;
    this.searchText = _.trim(this.searchText);
    if (_.isEmpty(this.searchText)) {
      this.initFilteredResources();
      return;
    }
    this.filteredResourceTree = [];
    _.each(this.sortedResourceTree, (sortedResource) => {
      if (appUtils.isResourceChurch(sortedResource)) {
        const isChurchAMatch = this.isResourceASearchMatch(sortedResource);
        const matchingChildren = _.filter(
          _.get(sortedResource, 'sortedResources'),
          (childResource) => this.isResourceASearchMatch(childResource)
        );

        const isAnyMatchingChildren = !_.isEmpty(matchingChildren);
        _.set(sortedResource, 'filteredResources', matchingChildren);
        if (isChurchAMatch || isAnyMatchingChildren) {
          this.filteredResourceTree.push(sortedResource);
        }
      } else {
        if (this.isResourceASearchMatch(sortedResource)) {
          this.filteredResourceTree.push(sortedResource);
        }
      }
    });
    this.filteredResourceArray = this.resourceTreeToArray(
      this.filteredResourceTree,
      'filteredResources'
    );
  }

  isResourceASearchMatch(resource) {
    const searchText = _.toLower(this.searchText);
    return _.includes(_.toLower(_.get(resource, 'name')), searchText);
  }

  isResourceAddress(address) {
    const isResourceWithAddress = _.get(address, 'location'); // A resource with a "address" object property
    const isBuiltAddressBoundToResource = _.get(
      address,
      'custom_data.resourceId'
    );
    // A Google address object built by the "buildGooglePlacesObject" function
    const isAddressBoundToResourceFromBackend = _.get(
      address,
      'location_map.resourceSourceId'
    );
    // A address object retrieved from the backend
    return (
      isResourceWithAddress ||
      isBuiltAddressBoundToResource ||
      isAddressBoundToResourceFromBackend
    );
  }

  getSelectedResourcesWithAddress() {
    const selectedResourcesWithAddress = [];
    _.each(this.selectedResourceIds, (selectedResourceId) => {
      let selectedResource = _.find(this.sortedResourceArray, {
        id: selectedResourceId,
      });

      // If the selected resource is a child resource, retrieve its parent
      const parentResourceId = _.get(selectedResource, 'parentResourceId');
      if (parentResourceId) {
        selectedResource = _.find(this.sortedResourceTree, {
          id: parentResourceId,
        });
      }
      if (_.get(selectedResource, 'location')) {
        selectedResourcesWithAddress.push(selectedResource);
      }
    });
    return selectedResourcesWithAddress;
  }

  onLocationNameChange(locationName) {
    this.eventLocationName = locationName;
    this.onLocationNameSelected({ selectedLocationName: locationName });
  }

  hasSelectedResourceWithAddress() {
    return this.resourcesWithAddress.length > 0;
  }

  toggleLocationAccordion(value) {
    this.isOpenLocationAccordion = value;
  }

  handleAddressChange() {
    this.address = this.appUtils.parseGoogleAddressComponent(
      this.googleAddress,
      this.cdApp.organization.countryIso2
    );

    if (!_.isEmpty(this.address)) {
      this.addressState = 'manual';
    }
  }

  onLocationSelected(address) {
    this.onAddressSelected({ selectedAddress: address });
  }

  getPrepTimeLabel = (minutes) => {
    // Show hour
    if (minutes >= 60) {
      const numHours = getCommaFormattedNumber(minutes / 60, 1);
      return this.gettextCatalog.getPlural(
        numHours,
        '1 hour',
        '{{numHours}} hours',
        {
          numHours,
        }
      );
    } else {
      // Show minutes
      return this.gettextCatalog.getString('{{amount}} minutes', {
        amount: minutes,
      });
    }
  };

  shouldDisablePrepTime = () =>
    // Disable prep time if no resources selected or its an all day event
    _.isEmpty(this.eventResourceIds) || this.data.allDay;
}
ResourceAddressSelectComponent.$inject = [
  'appUtils',
  'Authorization',
  'toastr',
  '$ngRedux',
  'uiSelectAllowNewMarker',
  'cdApp',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdResourceAddressSelect', {
  template: require('./resource-address-select.component.html'),
  controller: ResourceAddressSelectComponent,
  bindings: {
    utils: '<',
    data: '<',
    prepTimeEnabled: '<',
    updatePrepTimeEnabled: '<',
    onPrepTimeSelect: '<',
    contentCreation: '<',
    resourceSource: '<',
    eventChurchIds: '<',
    eventResourceIds: '<',
    onChurchSelected: '&',
    onResourceSelected: '&',
    eventAddress: '=',
    onAddressSelected: '&',
    canEditFieldHelper: '<',
    onLocationNameSelected: '&',
    eventLocationName: '=',
    isEdit: '=',
    checkAvailability: '&',
    onRegisterChildReference: '&',
    isRequired: '<',
  },
});
