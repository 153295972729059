import cdApp from '@/react/config';

export const getEventVisibility = () => {
  const {
    canSetVisibilityToInternalAll,
    canSetVisibilityToInternalGroup,
    canSetVisibilityToPublic,
  } = cdApp.organization.permissions;

  if (canSetVisibilityToPublic) {
    return 'public';
  } else if (canSetVisibilityToInternalAll || canSetVisibilityToInternalGroup) {
    return 'internal';
  } else {
    return 'private';
  }
};
