(function () {
  'use strict';

  angular.module('cdApp.shared').directive('cdInputHelper', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      controller: function () {},
      scope: {
        cdDisabled: '=',
      },

      template:
        '<div ng-transclude class="animated fadeIn" ng-class="cdDisabled ? \'text-muted\' : \'text-danger\'" style="margin-top: .75em; font-weight: 400;"></div>',
    };
  });
})();
