import React from 'react';
import styled from 'styled-components';
import { Checkbox, Divider, Form, FormInstance, Input, Space } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import { PeopleNewsletterLists } from '../types/people';
import { HasContactConsented } from '../store/newsletterListState';

import { showCheckDefaultConsentForSpecificContactModal } from './CheckDefaultConsentForSpecificContact';

import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import { CdLock, CdNewsletterListIcon } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const Container = styled.main`
  border: 1px solid #d8ded8;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
  margin-bottom: 18px;
  padding-bottom: 16px;
`;
const Header = styled.header`
  font-weight: 700;
  padding: 12px 0 0 16px;
  margin-bottom: 0;
`;

const NewsletterSubscriptions = ({
  newsletterLists,
  updateNewsletterSubscriptions,
  access,
  contactId,
  contactName,
}: {
  newsletterLists: PeopleNewsletterLists[];
  updateNewsletterSubscriptions: (values: {
    peopleNewsletterLists: PeopleNewsletterLists[];
  }) => void;
  access: { canView: boolean; canEdit: boolean };
  contactId: number;
  contactName: string;
  churches: any;
}) => {
  const [form] = Form.useForm();
  const hasContactConsentedLoadable = useRecoilValueLoadable(
    HasContactConsented({
      contactId: contactId,
    })
  );
  const hasContactConsented: boolean =
    hasContactConsentedLoadable.state === 'hasValue' &&
    hasContactConsentedLoadable.contents;

  const sortedNewsletters = newsletterLists.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  const canViewNewsletterLists =
    access.canView && Array.isArray(newsletterLists);
  const showConfirm = (
    contact: { id: number; name: string },
    updateNewsletterSubscriptions: (values: {
      peopleNewsletterLists: PeopleNewsletterLists[];
    }) => void,
    form: FormInstance<any>
  ) =>
    showCheckDefaultConsentForSpecificContactModal({
      contact,
      updateNewsletterSubscriptions,
      form,
    });

  return (
    <Container>
      <Header>
        <Space>
          <CdNewsletterListIcon />
          {gettextCatalog.getString('Newsletter subscriptions')}
        </Space>
      </Header>
      <Divider style={{ marginTop: '12px', marginBottom: '10px' }} />
      {!canViewNewsletterLists && (
        <CdrEmptyState
          EmptyStateIcon={<CdLock />}
          subtitle={gettextCatalog.getString(
            'You do not have access to view this information'
          )}
          title={gettextCatalog.getString('Newsletter subscriptions hidden')}
        />
      )}
      <Form style={{ marginTop: '0px' }} layout="horizontal" form={form}>
        {canViewNewsletterLists &&
          sortedNewsletters?.map((newsletter, index) => (
            <React.Fragment key={newsletter.id}>
              <Form.Item
                name={['peopleNewsletterLists', index, 'id']}
                initialValue={newsletter.id}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['peopleNewsletterLists', index, 'name']}
                initialValue={newsletter.name}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['peopleNewsletterLists', index, 'subscribed']}
                valuePropName="checked"
                initialValue={newsletter.subscribed}
                style={{ marginBottom: '0px', paddingLeft: '16px' }}
              >
                <Checkbox
                  style={{ fontWeight: 'lighter' }}
                  disabled={!access.canEdit}
                  onChange={(value) => {
                    if (value.target.checked === false) {
                      updateNewsletterSubscriptions(form.getFieldsValue());
                    } else {
                      if (hasContactConsented) {
                        updateNewsletterSubscriptions(form.getFieldsValue());
                      } else {
                        showConfirm(
                          { id: contactId, name: contactName },
                          updateNewsletterSubscriptions,
                          form
                        );
                      }
                    }
                  }}
                >
                  {newsletter.name}
                </Checkbox>
              </Form.Item>
            </React.Fragment>
          ))}
      </Form>
    </Container>
  );
};

export default NewsletterSubscriptions;
