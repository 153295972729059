import { People } from '../../people/types/people';
import { FEvent as Event, DetailedEvent } from '../../calendar/models/calendar';
import { Category } from '../models/category';
import { ActionCreator, CdAction } from '../../redux/utils';

// People

export type ClearPersonType = CdAction<void>;
export const CLEAR_PERSON = 'shared/clearPerson';
export const clearPerson: ActionCreator<void> = () => ({
  type: CLEAR_PERSON,
});

export type FetchPersonType = CdAction<number>;
export const FETCH_PERSON = 'shared/fetchPerson';
export const fetchPerson: ActionCreator<number> = (payload) => ({
  type: FETCH_PERSON,
  payload,
});

export type FetchPersonSuccessType = CdAction<Partial<People>>;
export const FETCH_PERSON_SUCCESS = 'shared/fetchPersonSuccess';
export const fetchPersonSuccess: ActionCreator<Partial<People>> = (
  payload
) => ({
  type: FETCH_PERSON_SUCCESS,
  payload,
});

export type ClearSearchMatchedPeopleType = CdAction<void>;
export const CLEAR_SEARCH_MATCHED_PEOPLE = 'shared/clearSearchMatchedPeople';
export const clearSearchMatchedPeople: ActionCreator<void> = () => ({
  type: CLEAR_SEARCH_MATCHED_PEOPLE,
});

export type SearchPeopleType = CdAction<string>;
export const SEARCH_PEOPLE = 'shared/searchPeople';
export const searchPeople: ActionCreator<string> = (payload) => ({
  type: SEARCH_PEOPLE,
  payload,
});

export type SearchPeopleSuccessType = CdAction<Partial<People>[]>;
export const SEARCH_PEOPLE_SUCCESS = 'shared/searchPeopleSuccess';
export const searchPeopleSuccess: ActionCreator<Partial<People>[]> = (
  payload
) => ({
  type: SEARCH_PEOPLE_SUCCESS,
  payload,
});

// Categories

export type FetchCategoryType = CdAction<undefined>;
export const FETCH_CATEGORIES = 'shared/fetchCategories';
export const fetchCategories: ActionCreator<undefined> = () => ({
  type: FETCH_CATEGORIES,
});

export type FetchCategorySuccessType = CdAction<Category[]>;
export const FETCH_CATEGORIES_SUCCESS = 'shared/fetchCategoriesSuccess';
export const fetchCategoriesSuccess: ActionCreator<Category[]> = (payload) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload,
});

// Events

export type ClearEventActionType = CdAction<void>;
export const CLEAR_EVENT = 'shared/clearEvent';
export const clearEvent: ActionCreator<void> = () => ({
  type: CLEAR_EVENT,
});

export type FetchEventActionType = CdAction<number>;
export const FETCH_EVENT = 'shared/fetchEvent';
export const fetchEvent: ActionCreator<number> = (payload) => ({
  type: FETCH_EVENT,
  payload,
});

export type FetchEventSuccessType = CdAction<Event>;
export const FETCH_EVENT_SUCCESS = 'shared/fetchEventSuccess';
export const fetchEventSuccess: ActionCreator<DetailedEvent> = (payload) => ({
  type: FETCH_EVENT_SUCCESS,
  payload,
});

export interface FetchEventsPayload {
  start: Date;
  end: Date;
}

export type FetchEventsSuccessType = CdAction<Event[]>;
export const FETCH_EVENTS_SUCCESS = 'shared/fetchEventsSuccess';
export const fetchEventsSuccess: ActionCreator<Event[]> = (payload) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload,
});
