import { CdApp } from '..';
import { ActionCreator, CdAction } from '../../redux/utils';
import { AuthData } from '../../shared/models/authentication';

export const SET_CONFIG = 'SET_CONFIG';

export const SetConfigAction: ActionCreator<CdApp> = (config) => ({
  type: SET_CONFIG,
  payload: config,
});

export const SET_CALENDAR_SETTING = 'config/setCalendarSetting';
export interface CalendarSettingPayload {
  defaultCalendar: boolean;
  calendarSwitchAllowed: boolean;
}

export interface OrganizationChurchPayload {
  organizationId: number;
  churchId: number;
  refresh: boolean;
}

export type MainDataRequestSuccess = CdAction<AuthData>;
export const MAIN_DATA_REQUEST_SUCCESS = 'calendar/MainDataRequestSuccess';
export const MainDataRequestSuccess: ActionCreator<AuthData> = (payload) => ({
  type: MAIN_DATA_REQUEST_SUCCESS,
  payload,
});

export const REFRESH_MAIN_DATA_REQUEST = 'calendar/RefreshMainDataRequest';
export const RefreshMainDataRequest: ActionCreator<undefined> = () => ({
  type: REFRESH_MAIN_DATA_REQUEST,
});
