import { PaymentMethodTypes } from '../models/intention';
import gettextCatalog from '../../services/I18nService';

export const paymentMethods = () => [
  { id: PaymentMethodTypes.PAIDBYCASH, name: gettextCatalog.getString('Cash') },
  {
    id: PaymentMethodTypes.PAIDBYBANK,
    name: gettextCatalog.getString('Wire transfer'),
  },
];
