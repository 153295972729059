import { Row, Col, Skeleton } from 'antd';
import { invert, readableColor } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { EventIcons } from '@/react/shared/components/Icons';

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 8px;
  align-items: center;
`;

const BodyContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ContentSkeleton = styled(Skeleton)<{ $primaryColor: string }>`
  &&&& {
    .ant-skeleton-content .ant-skeleton-title {
      background: ${(props) => props.$primaryColor};
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
      background: #595959;
    }
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 16px;
    }
  }
`;

const RowCard = styled(Row)<{
  $showRoundedCorners: boolean;
  $showShadow: boolean;
  $cardColor: string;
}>`
  &&&& {
    border-radius: ${(props) => (props.$showRoundedCorners ? '16px' : '0px')};
    box-shadow: ${(props) =>
      props.$showShadow ? ' rgba(0, 0, 0, 0.44) 0px 3px 8px ' : 'none'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.$cardColor};
    padding: 0px;
  }
`;
export const EventStylePreview = ({
  primaryColor,
  cardColor,
  settings,
  showEventImage,
  pageSize,
}) => {
  const arrayOfPreviewCards = pageSize
    ? // eslint-disable-next-line prefer-spread
      Array.apply(null, { length: pageSize })
    : [null];
  return (
    <Container>
      <Row gutter={[16, 16]} style={{ padding: '24px' }}>
        {arrayOfPreviewCards.map((_, i) => (
          <Col key={`event-style-preview-${i}`} span={24}>
            <RowCard
              $showRoundedCorners={settings?.showRoundedCorners}
              $showShadow={settings?.showShadow}
              $cardColor={cardColor}
            >
              {showEventImage && (
                <Col span={10} style={{ alignSelf: 'stretch' }}>
                  <div
                    style={{
                      borderRadius: settings?.showRoundedCorners
                        ? '16px 0px 0px 16px'
                        : '0px',
                      width: '100%',
                      height: '100%',
                      backgroundColor: readableColor(cardColor || '#ffffff'),
                      color: invert(readableColor(cardColor || '#ffffff')),
                      opacity: 0.1,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <EventIcons.Image size="4x" />
                  </div>
                </Col>
              )}
              <Col span={showEventImage ? 14 : 24}>
                <BodyContainer>
                  <ContentSkeleton
                    $primaryColor={primaryColor}
                    paragraph={{ rows: 2 }}
                    title={{ width: '100px' }}
                  />
                </BodyContainer>
              </Col>
            </RowCard>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
