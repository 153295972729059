'use strict';

/**
 * @desc order a list of items with falsey values at the end
 * @example <tr ng-repeat="item in $ctrl.list | orderByTruthy: 'firstName': true">
 */

angular.module('cdApp.shared').filter('orderByTruthy', [
  '_',
  (_) => (array, key, reverse) => {
    if (!_.isArray(array)) return array;

    const order = reverse ? 'desc' : 'asc';
    const sorted = _.orderBy(
      array,
      (item) => {
        const value = _.get(item, key);
        return _.isEmpty(value) ? null : _.toLower(value);
      },
      order
    );

    // if we are doing descending sorting, we want to push the falsey values at the end
    return reverse
      ? _.sortBy(sorted, (item) => _.isEmpty(_.get(item, key)))
      : sorted;
  },
]);
