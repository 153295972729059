class PersonalICalFeedComponent {
  constructor($stateParams, cdApp) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.cdApp = cdApp;
  }

  $onInit() {
    const { $stateParams, cdApp } = this;
    const { userId, token } = $stateParams;
    this.personalFeedUrl = `${cdApp.config.api.main}/ical/user/${userId}?token=${token}`;
  }
}

PersonalICalFeedComponent.$inject = ['$stateParams', 'cdApp'];
angular.module('cdApp.calendar').component('cdPersonalICalFeedState', {
  templateUrl:
    '@/app/calendar/personal-ical-feed/personal-ical-feed.component.html',
  controller: PersonalICalFeedComponent,
});
