import { Col, Row, Skeleton, Space, Spin } from 'antd';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import React, { ReactNode, Suspense } from 'react';
import styled from 'styled-components';

export const CdPageHeader = styled(PageHeader)`
  &&&& {
    background: white;
    padding: 16px 24px;

    .ant-page-header-heading-title {
      font-size: 20px;
    }
  }
`;

const OuterContainer = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const InnerContainer = styled.div<{ $isMarginless: boolean }>`
  margin: ${(props) => (props.$isMarginless ? 0 : '16px')};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const CdPage = ({
  pageHeaderProps,
  children,
  hideHeader,
  isMarginless,
}: {
  pageHeaderProps?: PageHeaderProps;
  children: any;
  hideHeader?: boolean;
  isMarginless?: boolean;
}) => (
  <OuterContainer>
    <Suspense
      fallback={
        <CdPageSuspense
          pageHeaderProps={pageHeaderProps}
          hideHeader={hideHeader}
          isMarginless={isMarginless}
        />
      }
    >
      {!hideHeader && (
        <CdPageHeader {...pageHeaderProps} className="display-none-in-print" />
      )}
      <InnerContainer $isMarginless={isMarginless}>{children}</InnerContainer>
    </Suspense>
  </OuterContainer>
);

const CdPageSuspense = ({
  pageHeaderProps,
  hideHeader,
  isMarginless,
}: {
  pageHeaderProps: PageHeaderProps;
  hideHeader: boolean;
  isMarginless?: boolean;
}) => (
  <>
    {!hideHeader && (
      <CdPageHeader
        title={<Skeleton.Input style={{ width: 300 }} active={true} />}
        extra={
          pageHeaderProps.extra && (
            <Space>
              {Array.isArray(pageHeaderProps.extra) &&
                (pageHeaderProps.extra as ReactNode[]).length > 0 &&
                pageHeaderProps.extra.map((_, index) => (
                  <Skeleton.Button
                    key={`skeleton-button-${index}`}
                    active={true}
                  />
                ))}
              {!Array.isArray(pageHeaderProps.extra) &&
                pageHeaderProps.extra && <Skeleton.Button active={true} />}
            </Space>
          )
        }
      />
    )}
    <InnerContainer $isMarginless={isMarginless}>
      <Row justify="center" style={{ height: '100%' }}>
        <Col>
          <Spin style={{ marginTop: '24px' }} size="large" />
        </Col>
      </Row>
    </InnerContainer>
  </>
);
