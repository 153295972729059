import { EventApi } from '@fullcalendar/react';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';

import { getCurrentUser } from '../../../config/store/Selector';
import { AppState } from '../../../redux';
import { Me } from '../../../shared/models/authentication';
import { User } from '../../../user/types/User.types';
import { ViewTypes } from '../events/fullCalendarEventsSlice';

import { ResourceType, TimePeriod } from '.';

export const getTimePeriod = (state: AppState) =>
  state.calendar.view.timePeriod;

export const getShowLiturgicalCalendar = (state: AppState) =>
  state.calendar.view.showLiturgicalHolidays;

export const getShowNationalCalendar = (state: AppState) =>
  state.calendar.view.showNationalHolidays;

export const getCalendarView = (state: AppState): string => {
  const currentResourceType = state.calendar.view.resourceType;
  const currentTimePeriod = state.calendar.view.timePeriod;
  const showResourceView = state.calendar.view.showResourceView;
  let currentView = 'dayGridMonth';
  switch (currentTimePeriod) {
    case TimePeriod.Day:
      switch (currentResourceType) {
        case ResourceType.Calendar:
          if (showResourceView) currentView = 'resourceTimeGridDay';
          else currentView = 'timeGridDay';
          break;
        case ResourceType.Users:
        case ResourceType.Resources:
          currentView = 'resourceTimelineDay';
          break;
      }
      break;
    case TimePeriod.Month:
      switch (currentResourceType) {
        case ResourceType.Calendar:
          currentView = 'dayGridMonth';
          break;
        case ResourceType.Users:
        case ResourceType.Resources:
          currentView = 'resourceTimelineMonth';
          break;
      }
      break;
    case TimePeriod.Week:
      switch (currentResourceType) {
        case ResourceType.Calendar:
          currentView = 'timeGridWeek';
          break;
        case ResourceType.Users:
        case ResourceType.Resources:
          currentView = 'resourceTimelineWeek';
          break;
      }
      break;
    default:
      break;
  }
  return currentView;
};

export const getNationalHolidays = (state: AppState) =>
  _.get(state, 'calendar.view.nationalHolidays');

export const getLiturgicalHolidays = (state: AppState) =>
  _.get(state, 'calendar.view.liturgicalHolidays');

export const getTitle: (state: AppState) => string = (state) =>
  state.calendar.view.title;

export const getResourceType: (state: AppState) => ResourceType = (state) =>
  state.calendar.view.resourceType;

export const getIsCalendarChanged: (state: AppState) => boolean = (state) =>
  _.get(state, 'config.settings.calendarChanged');

const getFilterResources = (state: AppState) =>
  state.calendar.filters.filterData.resources;

const getEventResourcesFactory = (event: EventApi) =>
  createCachedSelector(getFilterResources, (res) => {
    const resourceIds = event.extendedProps.filters
      ? _.keys(event.extendedProps.filters.resources).map((key) =>
          _.parseInt(key)
        )
      : [];
    return res.filter((resource) =>
      _.some(resourceIds, (id) => resource.id === id)
    );
  })({
    keySelector: () => 'eventResources' + event.id,
  });

export const useGetEventResources = () => {
  const state = useSelector((state: AppState) => state);
  return (event: EventApi) => {
    const eventResourcesSelector = getEventResourcesFactory(event);
    return eventResourcesSelector(state);
  };
};

export const useGetUserById = () => {
  const users: User[] = useSelector((state: AppState) =>
    _.get(state, 'calendar.filters.filterData.users', [])
  );
  const me: Me = useSelector(getCurrentUser);

  return (userId: number) =>
    me.id === userId ? me : users.find((user) => user.id === userId);
};
export const getCalendarSubscriptions = (state: AppState) =>
  state.calendar.view.calendarSubscriptions;

export const selectIsInResourceView = createSelector(
  getCalendarView,
  (currentView) => currentView === ViewTypes.RESOURCES
);
