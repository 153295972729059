import cdApp from '@/react/config';

export const supportGetYourWebsiteOnline = (language: string) => {
  switch (language) {
    case 'de':
      return 'https://support.churchdesk.com/de/article/so-stellen-sie-ihre-churchdesk-internetseite-online-k57j77/';

    case 'da':
      return 'https://support.churchdesk.com/da/article/fa-din-churchdesk-hjemmeside-online-nhwiz3/';

    default:
      return 'https://support.churchdesk.com/en/article/put-your-churchdesk-website-online-1m430hx/';
  }
};

export const buyingSmsPackages = (
  language: string = cdApp?.organization?.locale?.language
) => {
  switch (language) {
    case 'de':
      return 'https://support.churchdesk.com/de/article/sms-pakete-m1a1o0/';

    case 'da':
    case 'sv':
      return 'https://support.churchdesk.com/da/article/sms-pakker-1aczusg/';

    default:
      return 'https://support.churchdesk.com/en/article/sms-packages-w04jf9/';
  }
};

export const openingHours = (
  language: string = cdApp?.organization?.locale?.language
) => {
  switch (language) {
    case 'de':
      return 'https://support.churchdesk.com/de/article/support-offnungszeiten-8a04ef/';

    case 'da':
    case 'sv':
      return 'https://support.churchdesk.com/da/article/abningstider-18jen7f/';

    default:
      return 'https://support.churchdesk.com/en/article/opening-hours-mir5f2/';
  }
};

export const locationNameArticle = (
  language: string = cdApp?.organization?.locale?.language
) => {
  switch (language) {
    case 'de':
      return 'https://support.churchdesk.com/de/article/standortsname-und-adresse-fur-veranstaltungen-9yhzyh/';

    case 'da':
      return 'https://support.churchdesk.com/da/article/stednavn-og-adresse-for-begivenheder-1aqdgmr/';

    default:
      return 'https://support.churchdesk.com/en/article/location-name-and-address-for-events-2m5neg/';
  }
};
