'use strict';

class AdvancedFiltersComponent {
  constructor(_, gettextCatalog, cdApp) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;

    this.search = {};
    this.isValid = true;
    this.comparators = {
      OR: gettextCatalog.getString('match any'),
      AND: gettextCatalog.getString('match all'),
    };

    this.selectedChurchIds = [];
  }

  $onInit() {
    this.criteria = this.filterQuery.filters || [];
    this.selectedChurchIds = this.filterQuery.churchIds || [];
    this.showSearch = false;
    this.showChurchSelector = this.cdApp.showChurchSelector;
    const contactFilterGroup = _.find(this.filterGroups, {
      property: 'contact',
    });

    const churchFilterProperty = _.find(contactFilterGroup.filters, {
      property: 'churches',
    });

    this.churches = churchFilterProperty.options;
  }

  $onChanges(changes) {
    this.criteria = this._.get(changes, 'filterQuery.currentValue.filters', []);
  }

  getEmptyFilter(filter) {
    return {
      type: filter.type,
      property: filter.property,
      operator: null,
      value: null,
      open: true,
    };
  }

  addCriteria() {
    this.showSearch = true;
    this.onBeforeAddingCriteria();
  }

  closeAddCriteria() {
    this.showSearch = false;
    this.onCriteriaAdded();
  }

  selectCriteria(filter) {
    this.showSearch = false;
    this.search = {};

    this._.each(this.criteria, (criterion) => {
      criterion.open = false;
    });

    this.criteria.push(this.getEmptyFilter(filter));
    this.emitUpdateEvent();
    this.onCriteriaAdded();
  }

  updateCriteria(criterion, index) {
    if (criterion.type === 'radio') {
      criterion.operator = this.getRadioOperator(criterion);
    }

    const criterionToUpdate = this.criteria[index];
    const isChanged =
      this.hasValue(criterion) &&
      !this._.isEqualWith(criterion, criterionToUpdate, [
        'operator',
        'value',
        'unit',
      ]);

    this._.assignIn(criterionToUpdate, criterion);
    this.emitUpdateEvent(isChanged);
  }

  deleteCriteria(index, event) {
    if (event) {
      event.stopPropagation();
    }

    const criterion = this.criteria[index];
    const hasValue = this.hasValue(criterion);

    this._.remove(this.criteria, (value, n) => n === index);
    this.emitUpdateEvent(hasValue);
  }

  getFilterByCriterion(criterion) {
    return this._(this.filterGroups)
      .map('filters')
      .flatten()
      .find({ type: criterion.type, property: criterion.property });
  }

  getRadioOperator(criterion) {
    switch (criterion.value) {
      case true:
      case false:
      case 'male':
      case 'female':
      case 'other':
        return 'eq';

      case 'known':
        return 'known';

      case 'unknown':
        return 'unknown';
    }
  }

  hasValue(criterion) {
    const value = criterion.value;
    const staticOperators = ['known', 'unknown'];
    return (
      this._.includes(staticOperators, criterion.operator) ||
      (value !== '' && !this._.isNil(value))
    );
  }

  areAllCriteriaValid() {
    const conditions = this._.map(this.criteria, (criterion) =>
      this.hasValue(criterion)
    );

    return this._.every(conditions, (value) => value === true);
  }

  changeComparator(comparator = 'OR') {
    this.filterQuery.comparison = comparator;
    this.emitUpdateEvent(true);
  }

  emitUpdateEvent(refetch = false) {
    this.isValid = this.areAllCriteriaValid();
    const churchIds = this.selectedChurchIds;
    const comparator = this.filterQuery.comparison;
    const criteria = this._(this.criteria)
      .filter((criterion) => this.hasValue(criterion))
      .map((criterion) => this._.omit(criterion, ['open']))
      .value();

    this.onUpdate({ criteria, comparator, refetch, churchIds });
  }
}
AdvancedFiltersComponent.$inject = ['_', 'gettextCatalog', 'cdApp'];

angular.module('cdApp.shared').component('cdAdvancedFilter', {
  templateUrl:
    '@/app/shared/components/advanced-filter/advanced-filter.component.html',
  bindings: {
    filterGroups: '<',
    filterQuery: '<',
    onSave: '&',
    onUpdate: '&',
    onCriteriaAdded: '&',
    onBeforeAddingCriteria: '&',
  },

  controller: AdvancedFiltersComponent,
});
