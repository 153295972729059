// import _ from 'lodash';

class ResourceLocationModalComponent {
  constructor(appUtils, gettextCatalog, Resources, toastr) {
    'ngInject';

    this.appUtils = appUtils;
    this.gettextCatalog = gettextCatalog;
    this.Resources = Resources;
    this.toastr = toastr;
  }

  // Initialization functions

  $onInit() {
    this.mode = this.resolve.mode;
    if (this.isOrganizationResourcesMode()) {
      this.resourceItems = _.map(
        _.sortBy(this.resolve.resources, 'name'),
        (resource) => ({
          resource,
          selected: false,
        })
      );

      const noResources = _.size(this.resourceItems);
      const noColumns = 2;
      this.resourceRows = [];
      for (let i = 0; i < noResources; i += noColumns) {
        const resourceRow = [];
        for (let j = 0; j < noColumns; j++) {
          const resourceIdx = i + j;
          if (resourceIdx < noResources) {
            resourceRow.push(this.resourceItems[resourceIdx]);
          }
        }
        this.resourceRows.push(resourceRow);
      }
      this.location = this.resolve.location;
    } else {
      this.resourceItems = _.map(
        _.sortBy(this.resolve.resources, 'name'),
        (resource) => ({
          resource,
          location: null,
        })
      );
    }
  }

  // Main functions

  save() {
    const { appUtils, gettextCatalog, Resources, toastr } = this;
    const selectedResources = this.getSelectedResources();
    this.isLoading = true;
    Resources.updateLocation(null, selectedResources)
      .$promise.then((response) => {
        toastr.success(_.get(response, 'message'));
        this.close();
      })
      .catch((error) => {
        appUtils.showError(
          _.get(error, 'data.message'),
          toastr,
          gettextCatalog
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  // Conditional rendering

  isOrganizationResourcesMode() {
    return this.mode === 'ORGANIZATION_RESOURCES';
  }

  isEventResourcesMode() {
    return this.mode === 'EVENT_RESOURCES';
  }

  isSaveButtonDisabled() {
    return this.isLoading || _.isEmpty(this.getSelectedResources());
  }

  // Helper functions

  getSelectedResources() {
    const { appUtils } = this;
    if (this.isOrganizationResourcesMode()) {
      const location = appUtils.buildGooglePlacesObject(this.location);
      const filteredResourceItems = _.filter(this.resourceItems, {
        selected: true,
      });

      return _.map(filteredResourceItems, (filteredResourceItem) => ({
        id: _.get(filteredResourceItem, 'resource.id'),
        location,
      }));
    } else {
      const filteredResourceItems = _.filter(
        this.resourceItems,
        (resourceItem) => resourceItem.location
      );

      return _.map(filteredResourceItems, (filteredResourceItem) => ({
        id: _.get(filteredResourceItem, 'resource.id'),
        location: _.get(filteredResourceItem, 'location'),
      }));
    }
  }
}

ResourceLocationModalComponent.$inject = [
  'appUtils',
  'gettextCatalog',
  'Resources',
  'toastr',
];

angular.module('cdApp.calendar').component('cdResourceLocationModal', {
  template: require('./resource-location-modal.component.html'),
  controller: ResourceLocationModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
