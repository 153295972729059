'use strict';

import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import {
  NewGroupButtonComponent,
  NewGroupEditButtonComponent,
  NewGroupEditButtonInGroupDetails,
} from '@/react/user/components/group/NewGroupButtonComponent';

class CreateGroupModalComponent {
  constructor(Groups, toastr, gettextCatalog, Me, _, $scope, Authorization) {
    'ngInject';

    this.Groups = Groups;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.$scope = $scope;
    this.Me = Me;
    this._ = _;
    this.hasIntentionsAccess = Authorization.hasPackage('intentions');
  }

  $onInit() {
    this.isSaving = false;
    this.group = new this.Groups(this.resolve.group);
    if (this.resolve.group) {
      // Ensure that the absenceDisabled is a boolean.
      this.group.absenceDisabled = !!this.resolve.group.absenceDisabled;
    } else {
      this.group.absenceDisabled = false;
    }

    this.isPriestGroup = this.group.type === 'priests';
    this.group.hasTask = this.group.id ? this.group.hasTask : true;
    this.group.tasks =
      this.group.id && this.group.tasks.length > 0
        ? this.group.tasks
        : [{ title: '' }];
    this.group.task = this.group.id
      ? this.group.tasks[0]?.title || this.group.name
      : '';
  }
  updateTaskField() {
    if (this.group.hasTask && !this.group.id) {
      this.group.task = this.group.name;
    }
  }

  saveGroup() {
    if (this.isSaving) {
      return; // Do nothing if a save operation is already in progress.
    }
    if (!this.group.hasTask || !this.group.task) {
      this.group.tasks = [];
    } else {
      this.group.tasks[0].title = this.group.task;
    }
    this.isSaving = true;
    this.group.type = this.isPriestGroup ? 'priests' : null;

    this.group.$save(
      ({ id }) => {
        const output = this._.extend(
          {
            members: [this.Me.id],
          },

          this.group,
          { id }
        );

        this.close({ $value: output });
        this.isSaving = false;
      },
      ({ data }) => {
        this.toastr.error(
          this._.get(data, 'message') ||
            this.gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
        );
      }
    );
  }
}
CreateGroupModalComponent.$inject = [
  'Groups',
  'toastr',
  'gettextCatalog',
  'Me',
  '_',
  '$scope',
  'Authorization',
];

angular.module('cdApp.shared').component('cdCreateGroupModal', {
  templateUrl:
    '@/app/shared/components/create-group/create-group.component.html',
  controller: CreateGroupModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});

angular
  .module('cdApp.shared')
  .component(
    'cdAddGroupButton',
    react2angular(sharedContext.use(NewGroupButtonComponent), [
      'onNewGroupCreated',
    ])
  );

angular
  .module('cdApp.shared')
  .component(
    'cdEditGroupButton',
    react2angular(sharedContext.use(NewGroupEditButtonComponent), [
      'onNewGroupCreated',
      'group',
    ])
  );
angular
  .module('cdApp.shared')
  .component(
    'cdNewGroupEditButtonInGroupDetails',
    react2angular(sharedContext.use(NewGroupEditButtonInGroupDetails), [
      'onGroupUpdated',
      'group',
    ])
  );
