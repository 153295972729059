(function () {
  'use strict';

  /**
   * @desc remove a specified class from an element
   * @param {String} the name of the class to remove
   *
   * @example <div cd-remove-class="header"></div>
   */ removeClass.$inject = ['$timeout'];

  function removeClass($timeout) {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        $timeout(function () {
          elem.removeClass(attrs.cdRemoveClass);
        });
      },
    };
  }

  angular.module('cdApp.shared').directive('cdRemoveClass', removeClass);
})();
