'use strict';

class PeopleImportState {
  constructor(
    _,
    $state,
    $q,
    $http,
    gettextCatalog,
    toastr,
    $uibModal,
    Upload,
    cdApp,
    appUtils,
    supportDomain,
    People,
    peopleFieldsService,
    BeaconChat,
    supportService,
    cdExitPrompt,
    Authorization,
    FeatureToggleService,
    Resources
  ) {
    'ngInject';

    this._ = _;
    this.$state = $state;
    this.$q = $q;
    this.$http = $http;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.$uibModal = $uibModal;
    this.Upload = Upload;
    this.cdApp = cdApp;
    this.appUtils = appUtils;
    this.supportDomain = supportDomain;
    this.People = People;
    this.peopleFieldsService = peopleFieldsService;
    this.BeaconChat = BeaconChat;
    this.supportService = supportService;
    this.cdExitPrompt = cdExitPrompt;
    this.Authorization = Authorization;
    this.FeatureToggleService = FeatureToggleService;
    this.Resources = Resources;
  }

  $onInit() {
    const {
      _,
      supportService,
      gettextCatalog,
      peopleFieldsService,
      Resources,
    } = this;

    // The file formats that the backend expects
    this.fileAccept = [
      '.csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ].join(', ');

    // The selected file
    this.file = null;

    this.selectedList = [];

    this.showChurchSelector = this.cdApp.showChurchSelector;
    // Get churches that the user has people access
    Resources.getChurches({
      permissionContext: 'people',
      permissionType: 'access',
    }).$promise.then((churches) => {
      if (_.size(churches) === 1) {
        this.selectedChurches = churches;
      }

      this.churches = churches;
    });

    // Number of custom fields that were created by the user during the import process
    this.createdCustomFieldsCount = 0;

    // Whether to overwrite contacts
    this.overwrite = false;

    const articleLink = supportService.getArticle('importPeople');
    this.supportUrl = `<a href="${articleLink}" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
      'read this article for more information'
    )}</a>`;

    this.peopleFieldsIcons = peopleFieldsService.getStaticFieldsIcons();

    this.doNotImportKey = 'DO_NOT_IMPORT';
    this.createCustomFieldKey = 'CREATE_CUSTOM_FIELD';

    this.loadPeopleFields();

    /**
     * An AngularJs filter that omits selected fields from the list of people fields to prevent mapping the same field twice.
     * @param {Object} field
     */
    this.filterPeopleFields = (field) => {
      const isAlreadyMapped = _(this.propertyMappings)
        .values()
        .map('key')
        .includes(field.name);
      return !isAlreadyMapped;
    };
  }
  handleSelectedList = (lists) => {
    this.selectedList = lists.selectedLists;
  };
  /**
   * Load all available fields of a person, including both
   * static fields (firstname, lastname, etc..) and custom fields.
   */
  loadPeopleFields() {
    const { _, People, gettextCatalog, Authorization } = this;

    People.getImportAvailableProperties().$promise.then((peopleFields) => {
      this.peopleFields = [
        {
          name: this.doNotImportKey,
          label: gettextCatalog.getString('Do not import'),
          icon: 'fa-ban',
          group: gettextCatalog.getString('Actions', null, 'People Import'),
        },

        {
          name: this.createCustomFieldKey,
          label: gettextCatalog.getString('Create new field'),
          icon: 'fa-sliders-h',
          group: gettextCatalog.getString('Actions', null, 'People Import'),
          isDisabled: !Authorization.hasPermission('canAdministerUsers'),
          disabledMessage: gettextCatalog.getString(
            'Only administrators can create new custom fields.'
          ),
        },

        ..._(peopleFields)
          .sortBy('order')
          .map((peopleField) =>
            _.assign({}, peopleField, {
              icon: peopleField.isCustomField
                ? 'fa-sliders-h'
                : _.get(this.peopleFieldsIcons, peopleField.name),
              group: gettextCatalog.getString('Fields', null, 'People Import'),
            })
          )
          .value(),
      ];
    });
  }

  uiCanExit() {
    if (!this.propertyMappings) return true;

    return this.cdExitPrompt.prompt();
  }

  onFileSelected() {
    if (!this.file) {
      this.propertyMappings = null;
      return;
    }

    const { _, Upload, cdApp } = this;

    this.isProcessing = true;
    Upload.upload({
      url: `${cdApp.config.api.main}/people/import/process-file-headers`,
      data: { file: this.file, churches: _.map(this.selectedChurches, 'id') },
    })
      .then(
        ({ data }) => {
          const { filePath, propertyMappings } = data;
          this.filePath = filePath;

          this.identifiedFields = 0;
          this.unidentifiedFields = 0;
          _.forEach(propertyMappings, (propertyMapping) => {
            if (propertyMapping.churchDeskPropertyName) {
              this.identifiedFields += 1;
            } else {
              this.unidentifiedFields += 1;
            }
          });

          this.propertyMappings = _.chain(propertyMappings)
            .keyBy('filePropertyName')
            .mapValues((propertyMapping) => {
              const { churchDeskPropertyName, sampleValue } = propertyMapping;
              const dataType = _.get(
                _.find(this.peopleFields, { name: churchDeskPropertyName }),
                'dataType'
              );

              return {
                value: churchDeskPropertyName,
                dataType,
                sampleValue,
              };
            })
            .value();
        },
        (error) => {
          this.toastr.error(this.appUtils.getErrorMessage(error));
        }
      )
      .finally(() => {
        this.isProcessing = false;
      });
  }

  onPropertyMappingChanged(filePropertyName, oldValue) {
    const currentValue = this.propertyMappings[filePropertyName].value;

    // If user selects the "Create new custom field" option
    if (currentValue === this.createCustomFieldKey) {
      this.$uibModal
        .open({
          component: 'cdCreateCustomFieldModal',
        })
        .result.then((customFieldKey) => {
          this.loadPeopleFields();
          this.createdCustomFieldsCount += 1;
          this.propertyMappings[filePropertyName].value = customFieldKey;
        })
        .catch(() => {
          // If user cancels creating a custom field, revert back to the original value
          this.propertyMappings[filePropertyName].value = oldValue;
        });
    }
  }

  removeFile($event) {
    $event.stopPropagation();
    this.file = null;
    this.propertyMappings = null;
  }

  skipOtherFields() {
    const { _ } = this;
    this.propertyMappings = _.mapValues(
      this.propertyMappings,
      (propertyMapping) => {
        if (!propertyMapping.value) {
          propertyMapping.value = this.doNotImportKey;
        }
        return propertyMapping;
      }
    );
  }

  importFile() {
    const { _, $http, cdApp } = this;

    this.isUploading = true;

    const propertyMappings = _.map(
      this.propertyMappings,
      (churchDeskProperty, filePropertyName) => {
        if (churchDeskProperty.value === this.doNotImportKey) {
          return { filePropertyName };
        }

        return {
          filePropertyName,
          churchDeskPropertyName: churchDeskProperty.value,
        };
      }
    );

    const { filePath, overwrite } = this;

    $http
      .post(`${cdApp.config.api.main}/people/import/process-file-data`, {
        propertyMappings,
        filePath,
        overwrite,
        churches: _.map(this.selectedChurches, 'id'),
        peopleLists: this.selectedList?.length ? this.selectedList : undefined,
      })
      .then(
        (response) => {
          this.propertyMappings = null;
          this.importBatchId = response.data;
        },
        (error) => {
          this.toastr.error(this.appUtils.getErrorMessage(error));
        }
      )
      .finally(() => {
        this.isUploading = false;
      });
  }

  goToImportHistory() {
    this.$state.go('app.private.people.contacts.importHistory');
  }

  canSkipField(filePropertyName) {
    const value = this._.get(this.propertyMappings, [
      filePropertyName,
      'value',
    ]);

    return !value || value !== this.doNotImportKey;
  }

  getWarningTooltip() {
    const { gettextCatalog } = this;
    if (!this.file) {
      return gettextCatalog.getString(
        'Upload a file to get started on your import.'
      );
    }
    return gettextCatalog.getString(
      'You must match or skip all columns to complete your import.'
    );
  }

  launchMessenger() {
    this.BeaconChat.showNewMessage();
  }

  shouldShowDateWarning(fieldType) {
    const peopleField = this._.find(this.peopleFields, { name: fieldType });
    return this._.get(peopleField, 'dataType') === 'date';
  }
}

PeopleImportState.$inject = [
  '_',
  '$state',
  '$q',
  '$http',
  'gettextCatalog',
  'toastr',
  '$uibModal',
  'Upload',
  'cdApp',
  'appUtils',
  'supportDomain',
  'People',
  'peopleFieldsService',
  'BeaconChat',
  'supportService',
  'cdExitPrompt',
  'Authorization',
  'FeatureToggleService',
  'Resources',
];

angular.module('cdApp.people').component('cdPeopleImportState', {
  templateUrl: '@/app/people/people-import/people-import.component.html',
  controller: PeopleImportState,
});
