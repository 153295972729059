import { Modal, Spin, Button, Typography } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CdCopyIcon } from '../../components/Icons';

import { gettextCatalog } from '@/react/services/I18nService';

const { Paragraph } = Typography;

const Container = styled.div`
  margin: 8px 32px 0 48px;
`;
export const GetCopyModal = NiceModal.create(
  ({ subTitle, copyValue, title }: CopyModalProps) => (
    <CopyModal subTitle={subTitle} copyValue={copyValue} title={title} />
  )
);

export interface CopyModalProps {
  subTitle: string;
  copyValue: string;
  title: string;
}

export const CopyModal: FunctionComponent<CopyModalProps> = ({
  subTitle,
  copyValue,
  title,
}) => {
  const modal = useModal('CopyModal');
  const [buttonText, setButtonText] = useState(title);

  const close = () => {
    modal.remove();
  };
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        width: 800,
        title: title,
        bodyStyle: { padding: 0 },
        onCancel: close,
        cancelText: gettextCatalog.getString('Close'),
        okButtonProps: { style: { display: 'none' } },
      }}
    >
      <Container>
        <Paragraph>{subTitle}</Paragraph>
        <Spin spinning={!copyValue}>
          <Paragraph code style={{ whiteSpace: 'pre' }}>
            {copyValue}
          </Paragraph>
        </Spin>
        <CopyToClipboard
          text={copyValue}
          onCopy={() => {
            setButtonText(gettextCatalog.getString('Copied'));
          }}
        >
          <Button style={{ margin: '0px 0px 10px 2px' }} icon={<CdCopyIcon />}>
            {buttonText}
          </Button>
        </CopyToClipboard>
      </Container>
    </Modal>
  );
};
export const showCopyModal = (props: CopyModalProps) => {
  NiceModal.show('CopyModal', props);
};

NiceModal.register('CopyModal', GetCopyModal);
