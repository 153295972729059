require('./create-custom-field/create-custom-field.component');
require('./create-list/create-list.component');
require('./create-people/create-people.component');
require('./create-tag/create-tag.component');
require('./delete-people/delete-people.component');
require('./edit-list/edit-list.component');
require('./edit-people/edit-people.component');
require('./export-people/export-people.component');
require('./list-switcher/list-switcher.component');
require('./message-sender/message-sender.component');
require('./multi-churching/multi-churching.component');
require('./multi-tagging/multi-tagging.component');
require('./person-consents/person-consents.component');
require('./profile-information/profile-information.component');
require('./profile-information/cdr-person-information.component');
require('./relation-card/relation-card.component');
require('./select-tags-dropdown/select-tags-dropdown.component');
require('./sms-sender-name/sms-sender-name.component');
require('./people-form/simple-people-form.component');
require('./people-form/cdr-conflicting-people-relation.component');
require('./create-people/confirmation-modal/confirmation-modal.component');
