'use strict';
import { RefreshMainDataRequest } from '../../../react/config/store/Actions';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import {
  PortalOrganizationEventCategories,
  CategoryByTypeQuery,
} from '@/react/shared/services/CategoryService';
import { Resources } from '@/react/shared/services/ResourceService';

class TaxonomyDeleteComponent {
  constructor(
    _,
    $ngRedux,
    $rootScope,
    $state,
    $stateParams,
    Resources,
    Taxonomies,
    Partnership,
    Analytics,
    toastr,
    gettextCatalog,
    cdApp
  ) {
    'ngInject';

    this._ = _;
    this.toastr = toastr;
    this.Resources = Resources;
    this.Taxonomies = Taxonomies;
    this.Partnership = Partnership;
    this.gettextCatalog = gettextCatalog;
    this.$rootScope = $rootScope;
    this.cdApp = cdApp;
    const mapDispatchToThis = (dispatch) => ({
      RefreshAuth: () => dispatch(RefreshMainDataRequest()),
    });

    const unsubscribe = $ngRedux.connect(() => ({}), mapDispatchToThis)(this);

    this.$onDestroy = function () {
      unsubscribe();
    };
  }

  $onInit() {
    this.taxonomy = this.resolve.data;
    this.replacementTaxonomies = this.resolve.replacementTaxonomies;
    this.type = this.resolve.type;

    if (this.taxonomy.type === 'church') {
      // Override type, which in this case where type=resource.
      this.type = 'church';
    }

    this.requireReplacement = _.includes(
      ['event', 'absence', 'church', 'blog'],
      this.type
    );

    if (this.requireReplacement) {
      this.replacementTaxonomies = _(this.replacementTaxonomies)
        .filter({ type: this.type })
        .reject({ id: this.taxonomy.id })
        .value();
      const replacement = _(this.replacementTaxonomies)
        .reject({ id: this.taxonomy.id })
        .find({ type: this.type });
      if (replacement) {
        this.replacementTaxonomy = replacement.id;
        this.addReplacementTaxonomy = true;
      } else {
        this.addReplacementTaxonomy = false;
      }
    }
  }

  ok(replacementId = null) {
    const copy = _.pick(this.taxonomy, ['type', 'id']);

    const TaxonomyResource =
      this.type === 'resource' || this.type === 'church'
        ? this.Resources
        : this.Taxonomies;
    const taxonomyInstance = new TaxonomyResource(this.taxonomy);
    taxonomyInstance.$delete(
      { replacementId },
      () => {
        // Delete the partners mapping also.
        if (copy.type === 'event') {
          new this.Partnership.map({ categoryId: copy.id })
            .$delete()
            .then(() => this.close())
            .catch(() => this.close());
        } else {
          this.close();
        }
        this.toastr.success(
          this.gettextCatalog.getString('The taxonomy has been deleted.')
        );

        this.$rootScope.$emit('RefreshTaxonomies');
        this.RefreshAuth();
        if (this.type === 'resource') {
          recoilRefresh(Resources);
        } else {
          recoilRefresh(
            PortalOrganizationEventCategories(cdApp.organization.id)
          );
          recoilRefresh(CategoryByTypeQuery('event'));
        }
      },
      (error) => {
        this.toastr.error(
          _.get(error, 'data.message') ||
            this.gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
        );
      }
    );
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  // Conditional rendering

  showTaxonomyReplacementSection() {
    return this.requireReplacement;
  }

  showTaxonomyReplacementControl() {
    return this.type !== 'church' && this.replacementTaxonomies.length > 1;
  }

  showTaxonomyColor() {
    return this.type !== 'church';
  }

  // Labels && titles

  getModalTitle() {
    switch (this.type) {
      case 'absence':
        return this.gettextCatalog.getString('Delete absence category');
      case 'church':
        return this.gettextCatalog.getString('Delete church');
      case 'event':
        return this.gettextCatalog.getString('Delete event category');
      case 'blog':
        return this.gettextCatalog.getString('Delete blog category');
    }
  }

  getTransferEventsLabel() {
    switch (this.type) {
      case 'absence':
        return this.gettextCatalog.getString(
          'Transfer all absences to another category'
        );

      case 'church':
        return this.gettextCatalog.getString(
          'Transfer all events to another church'
        );

      case 'event':
        return this.gettextCatalog.getString(
          'Transfer all events to another category'
        );

      case 'blog':
        return this.gettextCatalog.getString(
          'Transfer all blogs to another category'
        );
    }
  }

  getReplacementTaxonomyTitle() {
    switch (this.type) {
      case 'absence':
      case 'event':
      case 'blog':
        return this.gettextCatalog.getString('Replace with category:');
      case 'church':
        return this.gettextCatalog.getString('Replace with parish:');
    }
  }

  getReplacementComponentPlaceholder() {
    switch (this.type) {
      case 'absence':
      case 'event':
        return this.gettextCatalog.getString('Choose a category');
      case 'church':
        return this.gettextCatalog.getString('Choose a parish');
    }
  }

  getReplacementWarningMessage() {
    const replacement = { taxonomyName: this.taxonomy.name };
    switch (this.type) {
      case 'absence':
        return this.gettextCatalog.getString(
          'All absences belonging to "{{taxonomyName}}" will be lost.',
          replacement
        );

      case 'church':
      case 'event':
        return this.gettextCatalog.getString(
          'All events belonging to "{{taxonomyName}}" will be lost.',
          replacement
        );

      case 'blog':
        return this.gettextCatalog.getString(
          'All blogs belonging to "{{taxonomyName}}" will be lost.',
          replacement
        );
    }
  }

  getReplacementDeleteButton() {
    switch (this.type) {
      case 'absence':
        return this.gettextCatalog.getString('Delete and update absences');
      case 'church':
        return this.gettextCatalog.getString('Delete and update content');
      case 'event':
        return this.gettextCatalog.getString('Delete and update events');
      case 'blog':
        return this.gettextCatalog.getString('Delete and update blogs');
    }
  }
}
TaxonomyDeleteComponent.$inject = [
  '_',
  '$ngRedux',
  '$rootScope',
  '$state',
  '$stateParams',
  'Resources',
  'Taxonomies',
  'Partnership',
  'Analytics',
  'toastr',
  'gettextCatalog',
  'cdApp',
];

angular.module('cdApp.settings').component('cdTaxonomyDeleteModal', {
  template: require('./taxonomy-delete-modal.html'),
  controller: TaxonomyDeleteComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
