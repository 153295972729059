'use strict';

import { ContactInformationType } from '../../../../../react/people/services/people.service';

class PeopleProfileInformationController {
  constructor(
    _,
    moment,
    $filter,
    gettextCatalog,
    dateFormatsLookup,
    cdApp,
    Authorization,
    cdRedactedValue,
    peopleFieldsService
  ) {
    'ngInject';

    this._ = _;
    this.moment = moment;
    this.$filter = $filter;
    this.gettextCatalog = gettextCatalog;
    this.dateFormatsLookup = dateFormatsLookup;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
    this.cdRedactedValue = cdRedactedValue;
    this.peopleFieldsService = peopleFieldsService;
    this.contactInformationTypeEmail = ContactInformationType.EMAIL;
    this.contactInformationTypePhone = ContactInformationType.PHONE;
  }

  $onInit() {
    this.today = this.moment()._d;
    this.datepickerFormat = this.dateFormatsLookup.getFormat();

    // Hide specific fields from the static fields sections
    const hideFields = ['prefix', 'firstName', 'lastName'];
    const staticFieldsSections =
      this.peopleFieldsService.getStaticFieldsSections(
        this.staticFieldsOptions,
        this.person
      );

    this.sections = _.map(staticFieldsSections, (section) =>
      this._.extend({}, section, {
        fields: _.reject(section.fields, (field) =>
          _.includes(hideFields, field.key)
        ),
      })
    );

    this.setConflictingPeopleByEmailSelectedValues =
      this.setConflictingPeopleByEmailSelectedValues.bind(this);
    this.setConflictingPeopleByPhoneSelectedValues =
      this.setConflictingPeopleByPhoneSelectedValues.bind(this);
  }

  /**
   * Allow a child component set its data.
   *
   * @param {Promise<{ type: ContactInformationType, conflictingPerson: People; relation: string; }>} conflictingPeopleRelationsByEmail
   */
  setConflictingPeopleByEmailSelectedValues(conflictingPeopleRelationsByEmail) {
    this.setConflictingPeopleRelationsByEmail({
      conflictingPeopleRelationsByEmail,
    });
  }

  /**
   * Allow a child component set its data.
   *
   * @param {Promise<[{ type: ContactInformationType; conflictingPerson: People; relation: string; }]>} conflictingPeopleRelationsByPhone
   */
  setConflictingPeopleByPhoneSelectedValues(conflictingPeopleRelationsByPhone) {
    this.setConflictingPeopleRelationsByPhone({
      conflictingPeopleRelationsByPhone,
    });
  }

  /**
   * Get the parent for the model
   *
   * @param {String} path - The path to generate the model
   */
  getModelParent(path) {
    let segs = path.split('.');
    let root = this.person;
    while (segs.length > 1) {
      let pathStep = segs.shift();
      root = this._.isUndefined(root[pathStep]) ? {} : root[pathStep];
    }
    return root;
  }

  /**
   * Get the child for the model
   *
   * @param {String} path - The path to generate the model
   */
  getModelLeaf(path) {
    let segs = path.split('.');
    return segs[segs.length - 1];
  }

  /**
   * Get whether a field is sensitive or not
   * @param {Object} field - The field to check
   */
  isSensitive(field) {
    // Handle nested properties like `contact.country` => `country`
    const key = _.last(_.split(field.key, '.'));
    return this._.get(this.staticFields, [key, 'sensitive'], false);
  }

  /**
   * Check whether the user can edit a field
   * @param {Object} field - The field which the user is trying to edit
   */
  canEditField(field) {
    return this._.get(this.person, ['fields', field.key, 'canEdit']);
  }
}
PeopleProfileInformationController.$inject = [
  '_',
  'moment',
  '$filter',
  'gettextCatalog',
  'dateFormatsLookup',
  'cdApp',
  'Authorization',
  'cdRedactedValue',
  'peopleFieldsService',
];

angular.module('cdApp.people').component('cdPeopleProfileInformation', {
  templateUrl:
    '@/app/people/shared/components/profile-information/profile-information.component.html',
  bindings: {
    person: '<',
    edit: '<',
    form: '<',
    editPerson: '&',
    staticFields: '<',
    staticFieldsOptions: '<',
    setConflictingPeopleRelationsByEmail: '&',
    setConflictingPeopleRelationsByPhone: '&',
  },

  controller: PeopleProfileInformationController,
});
