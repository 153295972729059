(function () {
  'use strict';

  angular
    .module('cdApp.calendar')
    .filter('calendarFiltersSearchByName', function () {
      return function (input, query) {
        if (!query) return input;
        let result = {};
        let stra = '';
        let strb = '';
        angular.forEach(input, function (data, id) {
          stra = ('' + data.name).toLowerCase();
          strb = ('' + query).toLowerCase();
          if (stra.indexOf(strb) !== -1) {
            result[id] = data;
          }
        });
        return result;
      };
    });
})();
