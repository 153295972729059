import { Radio } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faAddressCard,
  faEye,
  faBriefcaseMedical,
} from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';
import styled from 'styled-components';

interface VisibilityDropdownProps {
  isEditMode: boolean;
  config: VisibilityDropdownConfig;
  // onChange and value makes the component compatible with <Form.Item>
  // https://ant.design/components/form/#components-form-demo-customized-form-controls
  onChange?: (value: string) => void;
  value?: string;
}

export interface VisibilityDropdownConfig {
  [key: string]: {
    title: string;
    description: string;
  };
}

const getIcon = (name: string) => {
  let icon;
  switch (name) {
    case 'fields basic':
      icon = faAddressCard;
      break;
    case 'fields extended':
      icon = faEye;
      break;
    case 'fields sensitive':
      icon = faBriefcaseMedical;
      break;
    case 'fields generic':
    default:
      icon = faAddressBook;
      break;
  }
  return icon;
};

const RadioWithIcon = styled(Radio)`
  &&&& {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &:not(.ant-radio-wrapper-disabled):hover {
      .radio-icon-wrapper {
        background: #ccc;
      }
    }
    &.ant-radio-wrapper-checked:not(.ant-radio-wrapper-disabled) {
      .radio-icon-wrapper {
        background: #008db6;
        color: white;
      }
    }
    .radio-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    .radio-icon-label {
      display: flex;
      flex-direction: column;

      .radio-icon-title {
        font-weight: 400;
        margin-bottom: 4px;
      }
      .radio-icon-description {
        color: rgba(0, 0, 0, 0.45);
        font-weight: 400;
      }
    }

    &.ant-radio-wrapper-disabled {
      .radio-icon-label .radio-icon-description {
        color: inherit;
      }
    }
  }
`;

export const VisibilityRadioOption: FunctionComponent<VisibilityDropdownProps> =
  ({ value, isEditMode, onChange, config }) => {
    const [internalValue, setInternalValue] = useState('fields generic');

    const triggerChange = (changedValue) => {
      setInternalValue(changedValue);
      if (onChange) {
        onChange(changedValue);
      }
    };

    return (
      <Radio.Group
        onChange={triggerChange}
        defaultValue={internalValue}
        disabled={!isEditMode}
        style={{ display: 'block' }}
        value={value}
      >
        {map(config, (item, key) => (
          <RadioWithIcon value={key} key={`radio-key-${key}`}>
            <div className="u-flex u-align-items-center">
              <div className="radio-icon-wrapper">
                <FontAwesomeIcon icon={getIcon(key)} size="lg" />
              </div>
              <div className="radio-icon-label">
                <span className="radio-icon-title">{item.title}</span>
                <span className="radio-icon-description">
                  {item.description}
                </span>
              </div>
            </div>
          </RadioWithIcon>
        ))}
      </Radio.Group>
    );
  };
