const intranetModule = angular.module('cdApp.intranet', []);
require('./shifts/cd-react-rota-table/RotaTable');
require('./blog');
require('./churchscreen');
require('./files');
require('./group');
require('./group-list/group-list.component');
require('./shifts');
require('./intranet.component');

require('./templates');

module.exports = intranetModule.name;
