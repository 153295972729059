import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

import { Settings } from '../../../services/me';
import mountingContainer from '../../mountContainer';

import { gettextCatalog } from '@/react/services/I18nService';
interface ModalEditProps {
  file: any;
  open: boolean;
  tagsList: string[];
  canChangeVisibility: boolean;
  onFileSave: any;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  creditSettings: Settings;
}

const ModalEdit: React.FC<ModalEditProps> = ({
  file,
  open,
  tagsList,
  canChangeVisibility,
  onFileSave,
  onCancel,
  creditSettings,
}) => {
  const { id, title, visibility, tags, credits } = file;
  const [form] = Form.useForm();
  const { requireImageCredits } = creditSettings;
  return (
    <Modal
      open={open}
      title={
        <span>{gettextCatalog.getString('Edit {{title}}', { title })}</span>
      }
      okText={gettextCatalog.getString('Save')}
      centered
      cancelText={gettextCatalog.getString('Cancel')}
      getContainer={mountingContainer}
      onCancel={() => {
        onCancel();
      }}
      zIndex={999988}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFileSave(id, values);
          })
          .catch();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_edit_file"
        initialValues={{ title, visibility, tags, credits }}
      >
        <Form.Item
          name="title"
          label={gettextCatalog.getString('Title')}
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Title field is required.'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="visibility"
          label={gettextCatalog.getString('Visibility')}
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Visibility is required.'),
            },
          ]}
        >
          <Select
            disabled={!canChangeVisibility}
            getPopupContainer={mountingContainer}
          >
            <Select.Option value="web">
              {gettextCatalog.getString('Public')}
            </Select.Option>
            <Select.Option value="group">
              {gettextCatalog.getString('Only share with group')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="tags" label={gettextCatalog.getString('Tags')}>
          <Select
            mode="tags"
            placeholder={gettextCatalog.getString('Please select tags')}
            getPopupContainer={mountingContainer}
            allowClear
          >
            {(tagsList || []).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="credits"
          label={gettextCatalog.getString('Copyright')}
          rules={
            requireImageCredits
              ? [
                  {
                    required: true,
                    message: gettextCatalog.getString('Copyright is required'),
                  },
                ]
              : []
          }
        >
          <Input
            placeholder={gettextCatalog.getString('i.e. Photo by John Smith')}
            addonBefore={<FontAwesomeIcon icon={faCopyright} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalEdit;
