import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ManageSubscription from '@/react/people/components/ManageSubscription';

angular
  .module('cdApp.people')
  .component(
    'cdrManageSubscription',
    react2angular(sharedContext.use(ManageSubscription), [], ['$stateParams'])
  );
