(function () {
  'use strict';
  WorkplanOverviewController.$inject = [
    'moment',
    '$scope',
    '$state',
    '$http',
    'Users',
    'Me',
    'Authorization',
  ];

  function WorkplanOverviewController(
    moment,
    $scope,
    $state,
    $http,
    Users,
    Me,
    Authorization
  ) {
    $scope.currentMonth = new Date().getMonth();

    // Permissions.
    $scope.permissions = _.get(cdApp, 'organization.permissions');

    // Fetch user dropdown, get the list of the users from API.
    Users.query(function (users) {
      if (Authorization.hasPermission('canCreateAbsenceAndBook')) {
        $scope.users = users;
      } else {
        $scope.users = _.filter(users, { id: Me.id });
        $scope.createUid = $scope.users[0];
      }
    });

    // Months - generate the list of the months.
    $scope.months = [];

    for (let i = 0; i < 12; i++) {
      $scope.months.push({
        id: i + 1,
        label: moment().month(i).format('MMMM'),
      });
    }

    // Years - Generate the list of the years + 5 to the current.
    $scope.years = [];
    let currentYear = new Date().getFullYear();
    for (let j = 0; j <= 5; j++) {
      $scope.years.push(parseInt(currentYear) + j);
    }

    $http
      .get(cdApp.config.api.main + '/workplan/overview')
      .success(function (workplans) {
        $scope.workplans = workplans;
        angular.element('.workplan-loading-indicator').css({ display: 'none' });
      });

    // Create workplan callback.
    $scope.createWorkPlan = function () {
      if ($scope.createUid.id && $scope.create_month.id && $scope.create_year) {
        $state.go('app.private.calendar.workplan', {
          uid: $scope.createUid.id,
          month: $scope.create_month.id,
          year: $scope.create_year,
        });
      }
    };

    // Select user.
    $scope.selectUser = function (uid) {
      $scope.createUid = uid;
    };
  }

  angular
    .module('cdApp.calendar')
    .controller('WorkplanOverviewController', WorkplanOverviewController);
})();
