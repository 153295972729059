import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValue,
} from 'recoil';
import { useState } from 'react';

import { WidgetListQuery, GetWidgetList } from '../../store/widgetService';
import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import NotificationService from '../../../services/NotificationService';
import { gettextCatalog } from '../../../services/I18nService';
import { WidgetInterface } from '../types/widget.type';

export const useWidget = () => {
  const [types, setTypes] = useState<string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const WidgetSource = WidgetListQuery({ types, searchKey });
  const widgetList = useRecoilValue(WidgetListQuery({ types, searchKey }));
  const refreshWidgetList = useRecoilRefresher(GetWidgetList);

  const onSaveWidget = useRecoilCallback(
    () =>
      async ({
        id,
        widgetType,
        data,
      }: {
        id?: number;
        widgetType?: string;
        data?: WidgetInterface;
      }) => {
        const { configuration, name } = data;

        const formattedCategoryIds = configuration?.categoryIds
          ? configuration?.categoryIds
          : undefined;

        const payload = {
          name,
          type: widgetType,
          configuration: {
            ...configuration,
            categoryIds: formattedCategoryIds,
            image: id ? configuration?.image : undefined,
            timeRange:
              typeof configuration?.timeRange === 'object' &&
              configuration?.timeRange !== null
                ? {
                    start: configuration?.timeRange[0].format('YYYY-MM-DD'),
                    end: configuration?.timeRange[1].format('YYYY-MM-DD'),
                  }
                : configuration?.timeRange,
          },
        };

        const response = id
          ? await mainApi.put<WidgetInterface>(`/widgets/${id}`, payload)
          : await mainApi.post<WidgetInterface>('/widgets', payload);

        if (!response.ok) {
          handleError(response);
          return null;
        }
        refreshWidgetList();
        id
          ? NotificationService.notifySuccess(
              gettextCatalog.getString('The widget is updated')
            )
          : NotificationService.notifySuccess(
              gettextCatalog.getString('The widget is created')
            );
      },
    [refreshWidgetList]
  );
  const deleteWidget = useRecoilCallback(
    () => async (id: number) => {
      const response = await mainApi.delete(`/widgets/${id}`);
      if (!response.ok) {
        handleError(response);
        return null;
      }
      refreshWidgetList();
      NotificationService.notifySuccess(
        gettextCatalog.getString('The widget was deleted')
      );
    },
    [refreshWidgetList]
  );

  return {
    WidgetSource,
    widgetList,
    onSaveWidget,
    deleteWidget,
    setTypes,
    setSearchKey,
  };
};
