(function () {
  'use strict';
  ChurchScreen.$inject = ['$$resource'];

  function ChurchScreen($$resource) {
    return $$resource(cdApp.config.api.main + '/churchscreen');
  }

  angular.module('cdApp.shared').factory('ChurchScreen', ChurchScreen);
})();
