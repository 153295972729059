import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { RoleInterface } from '../../types/organization-role.types';
import { RolesActions } from '../../store/roleSlice';

export const useFetchRoles = (forceUpdate = false) => {
  const dispatch = useDispatch();
  return () => dispatch(RolesActions.fetchRoles({ forceUpdate }));
};

export const useFetchRole = (
  currentRoleId: string,
  currentRoleContext: string
) => {
  const dispatch = useDispatch();
  return () =>
    dispatch(
      RolesActions.fetchRole({ id: currentRoleId, context: currentRoleContext })
    );
};

export const FetchRolesOnInit = (
  currentRoleId: string,
  currentRoleContext: string,
  forceReloadRoles: boolean
) => {
  const fetchRoles = useFetchRoles(forceReloadRoles);
  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRole = useFetchRole(currentRoleId, currentRoleContext);
  useEffect(() => {
    if (currentRoleId) {
      fetchRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoleId]);
};

export const CreateRole = (payload: RoleInterface) => {
  const dispatch = useDispatch();
  return () => dispatch(RolesActions.addRole(payload));
};

export const useUpdateRole = () => {
  const dispatch = useDispatch();
  return (payload: RoleInterface) => dispatch(RolesActions.updateRole(payload));
};
