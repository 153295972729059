'use strict';

/**
 * Format the size of a file in KB or MB
 *
 * @example <span>{{ 12345 | fileSize }}</span>
 */
angular.module('cdApp.shared').filter('fileSize', () => (sizeInput) => {
  const size = parseInt(Math.round((sizeInput / 1024) * 100) / 100);

  if (size > 1000) {
    return Math.round((size / 1024) * 100) / 100 + 'MB';
  }

  return size + 'KB';
});
