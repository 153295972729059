import { unescape } from 'lodash';

import { getExternalLink } from '@/react/homepage/services/external-link.service';
import { showModal } from '@/react/angular/ReactModalBridge';

(function () {
  'use strict';

  function GroupOverviewController(
    moment,
    $window,
    $scope,
    $rootScope,
    $http,
    $uibModal,
    $stateParams,
    $location,
    $state,
    $sce,
    AuthenticationService,
    gettextCatalog,
    toastr,
    GroupsFeed,
    Taxonomies,
    Revision,
    Blogs,
    Me,
    GroupMessage
  ) {
    $rootScope.currentGroupState = _.words($state.current.name)[4];
    $scope.messages = { messagesEmpty: false, messagesLoading: false };
    $scope.currentUser = Me;
    $scope.gid = $stateParams.gid;
    $scope.mid = $stateParams.mid;
    $scope.mainModel = {
      reverse: $rootScope.currentGroupState === 'messages',
      predicate:
        $rootScope.currentGroupState === 'messages'
          ? 'lastReplyTime'
          : ['name', 'is_pending'],
      selectedUsers: [],
    };

    $scope.group = {
      messages: [],
    };

    $scope.goTo = function (path) {
      $location.path(path);
    };

    $scope.since = function (number) {
      let m = moment(number);
      return m.fromNow();
    };

    $scope.sinceTooltip = function (number) {
      let m = moment(number);
      return m.format('lll');
    };

    $scope.formatdate = function (number) {
      let m = moment.unix(number);
      return m.format('lll');
    };

    $scope.eventDate = function (datetime) {
      let m = moment(datetime);
      return m.format('dddd, DD MMMM YYYY');
    };

    $scope.eventTime = function (datetime) {
      let m = moment(datetime);
      return m.format('HH:mm');
    };

    $scope.eventDay = function (datetime) {
      let m = moment(datetime);
      return m.format('DD');
    };

    $scope.eventMonth = function (datetime) {
      let m = moment(datetime);
      return m.format('MMM');
    };

    $scope.format_time = function (time) {
      return moment(time * 1000).format('LLLL');
    };

    $scope.getActivityPartial = function (operation) {
      return (
        '@/app/intranet/group/templates/partials/entity-' +
        _.kebabCase(operation) +
        '.html'
      );
    };

    $scope.showRevisions = function (a) {
      a._showRevisions = !a._showRevisions;
    };

    /**
     * Group Activity
     */
    if ($rootScope.currentGroupState === 'overview') {
      $scope.groupActivitiesLoaded = false;

      GroupsFeed.query(
        {
          id: $scope.gid,
        },

        function (res) {
          $scope.groupActivitiesLoaded = true;
          $scope.groupActivities = [];

          const allowedOperations = [
            'create',
            'update',
            'membershipActive',
            'membershipPending',
          ];

          _.each(res, function (activity) {
            if (!_.includes(allowedOperations, activity.operation)) return;
            if (activity.entityType === 'message') {
              $scope.groupActivities.push({
                ...activity,
                entityName: _.unescape(activity.entityName),
                entityData: {
                  ...activity.entityData,
                  message: _.unescape(activity.entityData.message),
                },
              });
            } else {
              $scope.groupActivities.push(activity);
            }
          });
        }
      );
    }

    /**
     * Group Members
     */
    $scope.changeRole = (user) => {
      $http
        .put(
          `${cdApp.config.api.main}/groups/${$scope.gid}/users/${user.id}/membership`,
          {
            isAdmin: !user.isAdmin,
          }
        )
        .then(() => {
          toastr.success(
            gettextCatalog.getString('Role successfully updated.')
          );

          user.isAdmin = !user.isAdmin;

          if (!user.isAdmin) {
            _.remove($scope.users.leaders, user);
          } else {
            $scope.users.leaders = $scope.users.leaders || [];
            $scope.users.leaders.push(user);
          }
        })
        .catch(() => {
          toastr.warning(
            gettextCatalog.getString('Role could not be updated.')
          );
        });
    };

    $scope.approveUser = (user) => {
      $http
        .put(
          `${cdApp.config.api.main}/groups/${$scope.gid}/users/${user.id}/membership`,
          {
            status: 'active',
          }
        )
        .then(() => {
          user.status = 'active';
          toastr.success(gettextCatalog.getString('User has been approved.'));
        })
        .catch(() => {
          toastr.warning(
            gettextCatalog.getString('User could not be approved.')
          );
        });
    };

    $scope.addUser = () => {
      showModal('addMemberModal', {
        group: {
          name: $scope.$parent.$parent.group.name,
          id: $scope.gid,
          members: $scope.users?.members?.map((m) => m.id) || [],
        },
      });
    };

    $scope.removeUser = (user) => {
      $uibModal
        .open({
          component: 'cdSimpleModal',
          resolve: {
            title: () => gettextCatalog.getString('Remove member?'),
            body: () =>
              gettextCatalog.getString(
                'Are you sure you want to remove <strong>{{name}}</strong> from this group?',
                { name: user.name }
              ),

            options: {
              confirmButtonText: gettextCatalog.getString('Remove'),
              closeButtonText: gettextCatalog.getString('Cancel'),
              confirmButtonType: 'danger',
            },
          },
        })
        .result.then(() => {
          $http
            .put(
              `${cdApp.config.api.main}/groups/${$scope.gid}/users/${user.id}/membership`,
              {
                status: 'remove',
              }
            )
            .then(() => {
              _.remove($scope.users.members, user);
              _.remove($scope.users.leaders, user);
              toastr.success(
                gettextCatalog.getString(
                  '{{name}} has been removed from the group.',
                  {
                    name: user.name,
                  }
                )
              );

              if ($scope.users.members.length === 0) {
                $scope.users.usersEmpty = true;
              }
            })
            .catch(() => {
              toastr.success(
                gettextCatalog.getString(
                  '{{name}} could not be removed from the group.',
                  {
                    name: user.name,
                  }
                )
              );
            });
        });
    };

    $scope.getViewUrl = function (blog) {
      return $state.href('app.private.intranet.group.blog', {
        gid: blog.groupId,
        id: blog.id,
        global: ($rootScope.currentGroupState === 'blogs' && 'false') || '',
      });
    };

    $scope.getPublicViewUrl = function (blog) {
      if (blog.visibility !== 'web') return;
      return blog.url ? blog.url : getExternalLink(blog.id, 'blog');
    };

    $scope.send = (user) => {
      $uibModal.open({
        templateUrl:
          '@/app/settings/users-settings/shared/templates/send-message.modal.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.user = user;

            $scope.send = () => {
              $http
                .post(`${cdApp.config.api.main}/users/${user.id}/contact`, {
                  subject: $scope.subject,
                  message: $scope.message,
                })
                .then(() => {
                  toastr.success(
                    gettextCatalog.getString('Your message has been sent.')
                  );
                })
                .catch((error) => {
                  toastr.error(
                    _.get(error, 'data.message') ||
                      gettextCatalog.getString(
                        'An error occurred, please try again. If the problem persists, please contact our support.'
                      )
                  );
                });

              $uibModalInstance.close(user);
            };

            $scope.cancel = () => {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    $scope.formatFileSize = function (size) {
      let sizeKb = parseFloat(Math.round((size / 1024) * 100) / 100);
      if (sizeKb > 1000) {
        return Math.round((sizeKb / 1024) * 100) / 100 + 'MB';
      }
      return sizeKb + 'KB';
    };

    /**
     * Group Messages
     */
    function getMessages() {
      $scope.total = 0;
      $scope.messages.messagesLoading = true;

      $http
        .get(`${cdApp.config.api.main}/messages`, {
          params: {
            groupId: $scope.gid,
          },
        })
        .then(({ data }) => {
          $scope.messages.messagesLoading = false;
          $scope.group.messages = data?.map((message) => ({
            ...message,
            title: unescape(message.title),
            message: unescape(message.message),
          }));
          $scope.messages.messagesEmpty = !data.length;
        })
        .catch(() => {
          $scope.messages.messagesLoading = false;
          toastr.warning(
            gettextCatalog.getString('We could not load messages.')
          );
        });
    }

    function loadSingleMessage(id) {
      $http.get(`${cdApp.config.api.main}/messages/${id}`).then(({ data }) => {
        $scope.message = {
          ...data,
          title: unescape(data.title),
          message: unescape(data.message),
          replies: data.replies?.map((reply) => ({
            ...reply,
            body: unescape(reply.body),
          })),
        };
      });
    }

    if ($rootScope.currentGroupState === 'message' && $stateParams.id) {
      loadSingleMessage($stateParams.id);
    }

    if ($rootScope.currentGroupState === 'messages') {
      getMessages();
    }

    $scope.currentmessage = {};

    $scope.newMessage = () => {
      showModal('postGroupMessageModal', {
        group: {
          id: $scope.gid,
        },
      });
    };

    $scope.removeMessage = (message) => {
      $uibModal
        .open({
          component: 'cdSimpleModal',
          resolve: {
            title: () => gettextCatalog.getString('Delete message'),
            body: () =>
              gettextCatalog.getString(
                'Are you sure you want to delete this message? This action cannot be undone.'
              ),

            options: {
              confirmButtonText: gettextCatalog.getString('Delete'),
              closeButtonText: gettextCatalog.getString('Cancel'),
              confirmButtonType: 'danger',
            },
          },
        })
        .result.then(() => {
          const messageInstance = new GroupMessage(message);

          messageInstance.$delete(() => {
            toastr.success(
              gettextCatalog.getString('The message has been deleted.')
            );

            getMessages();
            $('#messagesHide').show();
          });
        });
    };

    $scope.editMessage = (message) => {
      showModal('postGroupMessageModal', {
        group: {
          id: $scope.gid,
        },
        message: _.pick(message, ['id', 'title', 'message']),
      });
    };

    $scope.postReply = (mid, comment, onSuccess) => {
      if (!mid || !comment) return;
      $scope.processing = true;

      $http
        .post(`${cdApp.config.api.main}/messages/${mid}/comments`, {
          body: comment,
        })
        .then(() => {
          toastr.success(
            gettextCatalog.getString('Your reply has been submitted.')
          );

          if (onSuccess) onSuccess();
          loadSingleMessage(mid);
        })
        .catch(() => {
          toastr.warning(
            gettextCatalog.getString('Your reply could not be submitted.')
          );
        });
    };

    $scope.editReply = (reply, onSuccess) => {
      $http
        .put(`${cdApp.config.api.main}/messages/comments/${reply.id}`, {
          body: reply.body,
        })
        .then(() => {
          toastr.success(
            gettextCatalog.getString('Your reply has been updated.')
          );

          if (onSuccess) onSuccess();
          loadSingleMessage($stateParams.id);
        })
        .catch(() => {
          toastr.warning(
            gettextCatalog.getString('Your reply could not be updated.')
          );
        });
    };

    $scope.deleteReply = (reply, onSuccess) => {
      $http
        .delete(`${cdApp.config.api.main}/messages/comments/${reply.id}`)
        .then(() => {
          toastr.success(
            gettextCatalog.getString('Your reply has been removed.')
          );

          if (onSuccess) onSuccess();
          loadSingleMessage($stateParams.id);
        })
        .catch(() => {
          toastr.warning(
            gettextCatalog.getString('Your reply could not be removed.')
          );
        });
    };

    /**
     * Group Blogs
     */
    function getBlog() {
      let constructor = $stateParams.rid ? Revision : Blogs;

      constructor.get(
        {
          id: $stateParams.id,
          rid: $stateParams.rid,
          type: 'blog',
        },

        function (blog) {
          _.each(blog.replies, function (content) {
            content.bodyHtml = content.body.replace(/\n/g, '<br/>');
          });

          $scope.blog = _.isUndefined($stateParams.rid) ? blog : blog.data;
          $scope.isGlobalBlog =
            $stateParams.global && $stateParams.global === 'false'
              ? false
              : true;
        },
        function (error) {
          $location.path(error.status);
        }
      );
    }

    function loadBlogs(search = null) {
      $scope.loading = true;

      Blogs.get(
        {
          'cid[]': $scope.selectedCategories,
          groupId: $stateParams.gid,
          offset: ($scope.currentPage - 1) * $scope.numPerPage,
          limit: $scope.numPerPage,
          orderField: $scope.sortType,
          order: $scope.sortReverse ? 'ASC' : 'DESC',
          search,
        },

        function (response) {
          $scope.blogs = response.blogs;
          $scope.totalItems = response.count;
          $scope.loading = false;
        },
        function () {
          $scope.loading = false;
        }
      );
    }

    if ($rootScope.currentGroupState === 'blog') {
      getBlog();
    }

    if ($rootScope.currentGroupState === 'blogs') {
      // Pagination
      $scope.currentPage = 1;
      $scope.numPerPage = 10;
      $scope.maxSize = 5;
      $scope.totalItems = 0;

      $scope.sortType = 'updatedAt'; // set the default sort type
      $scope.sortReverse = false; // set the default sort

      // Get the blog categories.
      Taxonomies.query(
        {
          type: 'blog',
        },

        function (response) {
          $scope.categoryList = response;
        }
      );

      loadBlogs();

      // Translate status id to status name.
      $scope.blogStatus = function (blog) {
        // Check the status, and based on it, return the correct status title.
        if (blog.visibility === 'draft') {
          return gettextCatalog.getString('Unpublished');
        } else if (blog.visibility === 'web') {
          return blog.facebook?.publishedOn
            ? gettextCatalog.getString('Published on website & facebook')
            : gettextCatalog.getString('Published on website');
        } else {
          return gettextCatalog.getString('Published in group only');
        }
      };

      // From list of blog categories generate a string with all categories separated by comma.
      $scope.structureBlogCategories = function (categories) {
        return _.result(
          _.find($scope.categoryList, function (element) {
            return element.id === categories;
          }),
          'name'
        );
      };

      $scope.isEmpty = function (item) {
        return _.isEmpty(item);
      };

      // Pagination was pressed, and the page was changed.
      $scope.pageChanged = function () {
        loadBlogs();
      };

      // Sorting of the table.
      $scope.sortTable = function (sort, reverse) {
        $scope.sortType = sort;
        $scope.sortReverse = reverse;
        loadBlogs();
      };

      $scope.isChecked = function (cid) {
        if (_.includes($scope.selectedCategories, cid)) {
          return 'fa fa-check-square pull-left';
        }
        return 'far fa-square pull-left';
      };

      // Contains the filter by categories.
      $scope.selectedCategories = [];
      $scope.selectedCategoriesNames = [];
      $scope.setSelectedCategories = function ($event, cid, category_name) {
        $scope.loading = true;
        $event.stopPropagation();

        $scope.currentPage = 1;

        if (_.includes($scope.selectedCategories, cid)) {
          $scope.selectedCategories = _.without($scope.selectedCategories, cid);
        } else {
          $scope.selectedCategories.push(cid);
        }

        if (_.includes($scope.selectedCategoriesNames, category_name)) {
          $scope.selectedCategoriesNames = _.without(
            $scope.selectedCategoriesNames,
            category_name
          );
        } else {
          $scope.selectedCategoriesNames.push(category_name);
        }

        loadBlogs();

        return false;
      };
    }

    $scope.filterBlogsByTitle = function (search) {
      loadBlogs(search);
    };

    $scope.editBlog = function (canEdit, blogId, groupId) {
      if (!canEdit) {
        const groupName = this.getGroupName(groupId);
        toastr.error(
          gettextCatalog.getString(
            `You don't have access to edit this blog post because you are not a member of the group: {{name}}.`,
            {
              name: groupName,
            }
          )
        );
        return;
      }
      $http({
        method: 'GET',
        url: cdApp.config.api.main + '/blogs/' + blogId,
      }).success((blogRes) => {
        $scope.openCreateContentWindow('blog', 'edit', blogRes);
      });
    };
    $scope.getExternalUrl = function (blogId, blogUrl) {
      return blogUrl ? blogUrl : getExternalLink(blogId, 'blog');
    };

    $scope.deleteBlog = function (blog) {
      $uibModal.open({
        scope: $scope,
        templateUrl: 'modalDeleteBlog.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'blog',
          function ($scope, $uibModalInstance, blog) {
            'ngInject';

            $scope.modalBlog = blog;
            $scope.removeBlog = function () {
              $scope.processing = true;
              $http({
                method: 'DELETE',
                url: cdApp.config.api.main + '/blogs/' + blog.id,
              }).success(function () {
                $uibModalInstance.dismiss('cancel');
                if ($rootScope.currentGroupState === 'blog') {
                  $state.go('app.private.intranet.group.blogs');
                } else {
                  loadBlogs();
                }
              });
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],

        resolve: {
          blog: function () {
            return blog;
          },
        },
      });
    };

    $scope.currentBlog = {};

    $scope.postComment = function (blogId, newComment, onSuccess) {
      if (!newComment) return;
      $scope.processing = true;
      $http({
        method: 'POST',
        url: cdApp.config.api.main + '/blogs/' + $scope.blog.id + '/comments',
        data: {
          body: newComment,
          blogId: blogId,
        },
      })
        .success(function () {
          toastr.success(
            gettextCatalog.getString('Your comment has been submitted.')
          );

          if (onSuccess) onSuccess();
          getBlog();
          $scope.processing = false;
        })
        .error(function () {
          toastr.warning(
            gettextCatalog.getString('Your comment could not be submitted.')
          );

          $scope.processing = false;
        });
    };

    $scope.editComment = function (comment, onSuccess) {
      $http({
        method: 'PUT',
        url:
          cdApp.config.api.main +
          '/blogs/' +
          $scope.blog.id +
          '/comments/' +
          comment.id,
        data: {
          body: comment.body,
        },
      })
        .success(function () {
          toastr.success(
            gettextCatalog.getString('The comment has been updated.')
          );

          if (onSuccess) onSuccess();
          getBlog();
        })
        .error(function () {
          toastr.warning(
            gettextCatalog.getString('The comment could not be updated.')
          );
        });
    };

    $scope.removeComment = function (reply, onSuccess) {
      if (!_.get(reply, 'id')) return;
      $http({
        method: 'DELETE',
        url:
          cdApp.config.api.main +
          '/blogs/' +
          $scope.blog.id +
          '/comments/' +
          reply.id,
      })
        .success(function () {
          toastr.success(
            gettextCatalog.getString('The comment has been deleted.')
          );

          if (onSuccess) onSuccess();
          getBlog();
        })
        .error(function () {
          toastr.warning(
            gettextCatalog.getString('The comment could not be deleted.')
          );
        });
    };
  }
  GroupOverviewController.$inject = [
    'moment',
    '$window',
    '$scope',
    '$rootScope',
    '$http',
    '$uibModal',
    '$stateParams',
    '$location',
    '$state',
    '$sce',
    'AuthenticationService',
    'gettextCatalog',
    'toastr',
    'GroupsFeed',
    'Taxonomies',
    'Revision',
    'Blogs',
    'Me',
    'GroupMessage',
  ];

  angular
    .module('cdApp.intranet')
    .controller('GroupOverviewController', GroupOverviewController)
    .directive('autoGrow', [
      '$window',
      function ($window) {
        return {
          restrict: 'A',
          require: '?ngModel',
          link: function (scope, elem, attrs) {
            // Field resize method.
            let resize = function (target) {
              let element = target;
              let scrollTop = $window.pageYOffset,
                scrollLeft = $window.pageXOffset;

              $(element).height(100);

              let height = $(element)[0].scrollHeight;
              if (attrs['autoGrow']) {
                let maxHeight = attrs['autoGrow'];
                height = height > maxHeight ? maxHeight : height;
              }
              // 8 is for the padding
              if (height < 100) {
                height = 108;
              }
              $(element).height(height - 8);
              // Prevents the screen jumps.
              $window.scrollTo(scrollLeft, scrollTop);
            };

            scope.$watch(attrs.ngModel, function (newValue, oldValue) {
              if (oldValue !== newValue) {
                resize(elem);
              }
            });

            // Expand the textarea as soon as it is added to the DOM
            setTimeout(function () {
              resize(elem);
            });
          },
        };
      },
    ]);
})();
