/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Space, DatePicker, Input, Form, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faSearch,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ColumnsType } from 'antd/lib/table';

import { gettextCatalog } from '../../../services/I18nService';
import AppUtils from '../../../services/AppUtilsService';
import { StyledTable } from '../../../shared/antd/StyledAntdComponents';
import {
  navigate,
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import {
  clearIntention,
  clearIntentionEvents,
  fetchIntention,
  fetchIntentionEvents,
} from '../../redux/intentions/Actions';
import {
  getIntention,
  getIntentionEvents,
} from '../../redux/intentions/Selectors';
import { PaymentMethodTypes } from '../../models/intention';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import { Taxonomy } from '../../../shared/models/taxonomy';
import { Resource } from '../../../shared/models/resource';
import { FEvent } from '../../../calendar/models/calendar';
import IntentionService from '../../services/IntentionService';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { handleSuccessMessage } from '../../../shared/utils';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';

const { RangePicker } = DatePicker;

interface AssignIntentionStateParam {
  id: string;
}

// Constants
const tableStyle = { padding: 8 };
const tableScroll = { x: true as const };

const AssignIntention: FunctionComponent = () => {
  const dispatch = useDispatch();
  const intentionToAssign = useSelector(getIntention);
  const intentionEvents = useSelector(getIntentionEvents);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const [form] = Form.useForm();

  const stateParams = StateParamsServiceFactory<AssignIntentionStateParam>();
  const intentionId = stateParams.id;

  const intentionToAssignData: any = intentionToAssign
    ? [intentionToAssign]
    : null;
  const intentionEventsData: any = intentionEvents ? intentionEvents : null;

  const back = () => {
    navigate('app.private.intention.overview');
  };

  const getPageHeaderExtra = () => (
    <Button onClick={back}>{gettextCatalog.getString('Back')}</Button>
  );

  const assignIntentionToEvent = (event) => {
    IntentionService.assignIntention({ id: intentionId, calendarId: event.id })
      .then(() => {
        handleSuccessMessage(
          gettextCatalog.getString('Successfully assigned intention.')
        );
      })
      .then(() => back())
      .catch(ErrorHandlingService.handleError);
  };

  // Columns

  const intentionColumns: ColumnsType<Record<string, unknown>> = [
    {
      title: gettextCatalog.getString('Number'),
      dataIndex: 'formattedReferenceNumber',
      key: 'formattedReferenceNumber',
      sorter: true,
    },
    {
      title: gettextCatalog.getString('Intention text'),
      dataIndex: 'intentionText',
      key: 'intentionText',
    },
    {
      title: gettextCatalog.getString('Intention Type'),
      dataIndex: ['fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Parish'),
      dataIndex: 'church',
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: 'resource',
      key: 'resource',
    },
    {
      title: gettextCatalog.getString('Payment method'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: function columnRender(paymentMethod: PaymentMethodTypes) {
        return (
          <div>
            {paymentMethod === PaymentMethodTypes.PAIDBYCASH
              ? gettextCatalog.getString('Cash')
              : gettextCatalog.getString('Wire transfer')}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment status'),
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment date'),
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: function columnRender(paidAt: Date) {
        return <div>{paidAt ? moment(paidAt).format('YYYY-MM-DD') : null}</div>;
      },
    },
    {
      title: gettextCatalog.getString('Desired date'),
      dataIndex: 'preferredDate',
      key: 'preferredDate',
    },
    {
      title: gettextCatalog.getString('Notes for desired date'),
      dataIndex: 'preferredNote',
      key: 'preferredNote',
    },
    {
      title: gettextCatalog.getString('Founder'),
      dataIndex: 'founder',
      key: 'founder',
    },
  ];

  const eventColumns: ColumnsType<Record<string, unknown>> = [
    {
      title: gettextCatalog.getString('Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: gettextCatalog.getString('Start Date'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: function columnRender(startDate: Moment) {
        return <div>{moment(startDate).format('LLLL')}</div>;
      },
    },
    {
      title: gettextCatalog.getString('End Date'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: function columnRender(endDate: Moment) {
        return <div>{moment(endDate).format('LLLL')}</div>;
      },
    },
    {
      title: gettextCatalog.getString('Categories'),
      dataIndex: 'taxonomies',
      key: 'taxonomies',
      render: function columnRender(taxonomies: Taxonomy[]) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(taxonomies, (taxonomy) => (
              <Space key={taxonomy.id}>
                <i
                  className={
                    'fa fa-circle fa-lg ' +
                    AppUtils.getTaxonomyColorClass(taxonomy)
                  }
                />
                {taxonomy.name}
              </Space>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Ressourcen',
      dataIndex: 'resources',
      key: 'resources',
      render: function columnRender(resources: Resource[]) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(resources, (resource) => (
              <Space key={resource.id}>
                <i
                  className={
                    'fa fa-circle fa-lg ' +
                    AppUtils.getTaxonomyColorClass(resource)
                  }
                />
                {resource.name}
              </Space>
            ))}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('# of Intentions'),
      dataIndex: 'noOfAssignedIntentions',
      key: 'noOfAssignedIntentions',
    },
    {
      title: null,
      dataIndex: null,
      key: 'actionsMenu',
      fixed: 'right',
      render: function columnRender(event: FEvent) {
        return (
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              type="primary"
              size="small"
              style={{ marginRight: 8 }}
              onClick={() => assignIntentionToEvent(event)}
            >
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ marginRight: 8 }}
              />
              {gettextCatalog.getString('Assign')}
            </Button>
          </Space>
        );
      },
    },
  ];

  const searchEvents = () => {
    const searchText = form.getFieldValue('searchText');
    const startDate = form.getFieldValue('datePicker')[0];
    const endDate = form.getFieldValue('datePicker')[1];
    dispatch(
      fetchIntentionEvents({
        intentionId: intentionId,
        startDate: startDate.startOf('day').toDate(),
        endDate: endDate.endOf('day').toDate(),
        searchValue: searchText || undefined,
      })
    );
  };

  // Retrieve required entities
  useEffect(() => {
    // Initialize intention data in case of creation or update/view
    dispatch(fetchIntention(intentionId));

    // Initialize event filters
    form.setFieldsValue({
      searchText: null,
      datePicker: [moment(), moment().add(1, 'month')],
    });

    // Search on init
    searchEvents();

    // Cleanup function to be executed on component un-mounting
    return () => {
      dispatch(clearIntention());
      dispatch(clearIntentionEvents());
    };
  }, []);

  // Return the intentions home screen
  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Assign Intention to event'),
        extra: getPageHeaderExtra(),
      }}
    >
      {/* Intention to assign info */}
      <Card>
        <StyledTable
          id="intentionToAssignInfo"
          style={tableStyle}
          dataSource={intentionToAssignData}
          columns={intentionColumns}
          pagination={false}
          scroll={tableScroll}
          bordered={true}
          rowKey="id"
        />
      </Card>
      <Card
        title={gettextCatalog.getString(
          'Search for an event to add the intention to'
        )}
      >
        {/* Event Filters and Search */}
        <Form
          layout="vertical"
          id="filterEventsForm"
          form={form}
          onFinish={searchEvents}
        >
          <Row justify="center">
            <Space>
              {/* Keyword search */}
              <Form.Item name="searchText">
                <Input
                  placeholder={gettextCatalog.getString('Search title...')}
                />
              </Form.Item>
              {/* Date picker */}
              <Form.Item name="datePicker">
                <RangePicker format="YYYY-MM-DD" />
              </Form.Item>
              {/* Search button */}
              <Form.Item style={{ minWidth: 90 }}>
                <Button htmlType="submit" type="primary">
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: 8 }} />
                  {gettextCatalog.getString('Search')}
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Form>
        {/* Event results */}
        <StyledTable
          id="intentionToAssignInfo"
          style={tableStyle}
          dataSource={intentionEventsData}
          columns={eventColumns}
          loading={isLoading}
          pagination={false}
          scroll={tableScroll}
          bordered={true}
          rowKey="id"
        />
      </Card>
    </CdPage>
  );
};

export default AssignIntention;
