import { mainApi } from '../../api';
import { handleError } from '../../services/ErrorHandlingService';

// safeguarding report download token
interface SafeguardingReportDownloadTokenInterface {
  token: string;
}
export const getSafeguardingReportDownloadToken =
  async (): Promise<SafeguardingReportDownloadTokenInterface> => {
    const response =
      await mainApi.get<SafeguardingReportDownloadTokenInterface>(
        '/safeguard/report/download-token'
      );
    if (!response.ok) {
      handleError(response);
      return undefined;
    }
    return response.data;
  };
