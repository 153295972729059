import { put, call } from 'redux-saga/effects';

import {
  loadPartnerFiles,
  importPartnerFile,
  getFilters,
} from '../../services';
import {
  partnerFilesRequest,
  partnerFilesRequestSuccess,
  partnerFilesRequestFailed,
  importPartnerFileRequest,
  importPartnerFileRequestFailed,
  importPartnerFileRequestSuccess,
  closeModal,
  partnerFilesGetFiltersSuccess,
  partnerFilesGetFiltersFailed,
} from '../actions';

export function* loadPartnerFilesSaga(action) {
  const { payload } = action;
  const { partnerId, currentPage, pageSize, search, publication, type, tags } =
    payload;
  const limit = pageSize;
  // currentPage starts at 1
  const offset = (currentPage - 1) * limit;
  try {
    yield put(partnerFilesRequest());
    const options = {
      partnerId,
      offset,
      limit,
      search,
      publication,
      type,
      tags,
    };
    const partnerFiles = yield call(loadPartnerFiles, options);
    yield put(partnerFilesRequestSuccess(partnerFiles));
  } catch (error) {
    yield put(partnerFilesRequestFailed());
  }
}

export function* importPartnerFileSaga(action) {
  const { payload } = action;
  const { fileId, partnerId, callback, imageFormat } = payload;
  try {
    yield put(importPartnerFileRequest());
    const file = yield call(importPartnerFile, fileId, partnerId);
    yield put(importPartnerFileRequestSuccess({ file }));
    yield call(callback, file, imageFormat);
    yield put(closeModal());
  } catch (error) {
    yield put(importPartnerFileRequestFailed());
  }
}

export function* getPartnerFilesFiltersSaga() {
  try {
    const filters = yield call(getFilters);
    yield put(partnerFilesGetFiltersSuccess(filters));
  } catch (error) {
    yield put(partnerFilesGetFiltersFailed());
  }
}
