'use strict';

angular.module('cdApp.shared').component('cdInvitationResponse', {
  templateUrl:
    '@/app/shared/components/invitation-response/invitation-response.component.html',
  bindings: {
    response: '<',
    iconOnly: '<',
    inheritColor: '<',
  },
});
