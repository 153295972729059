import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { EditProfileButton } from '@/react/people/components/people-contacts-profile-modals/EditProfileButton';
angular
  .module('cdApp.people')
  .component(
    'cdrEditProfileButton',
    react2angular(
      sharedContext.use(EditProfileButton),
      ['contactId', 'personFields', 'personRelations'],
      []
    )
  );
