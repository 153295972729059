'use strict';

import _ from 'lodash';

angular.module('cdApp.shared').factory('StripeAccount', [
  'moment',
  '$$resource',
  function (moment, $$resource) {
    return $$resource(
      cdApp.config.api.main + '/organizations/:id/account',
      null,
      {
        get: {
          method: 'GET',
          isArray: false,
          transformResponse: function (data) {
            if (!_.isEmpty(data)) {
              data = JSON.parse(data);

              return _.extend(data, {
                birthDate:
                  (data.birthDate && moment(data.birthDate).toDate()) || null,
              });
            }

            return data;
          },
        },

        getAccount: {
          method: 'GET',
          isArray: false,
          url: cdApp.config.api.main + '/organizations/:id/stripe/account',
        },
      }
    );
  },
]);
