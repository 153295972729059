import { Button, ButtonProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const CdSalesButton: typeof Button = styled(Button)`
  &&&& {
    background: #27ae60;
    color: white;
    border-color: #27ae60;

    &.ant-btn:active {
      color: white;
      background: #279c59;
      border-color: #27ae60;
      font-weight: none;
    }
    &.ant-btn:hover,
    &.ant-btn:focus,
    &.ant-btn:active {
      text-decoration: none;
      color: white;
      background: #279c59;
      border-color: #27ae60;
    }
  }
`;
export const CdSalesButtonFromAngular = (props: ButtonProps) => (
  <CdSalesButton {...props} target="_blank" rel="noreferrer">
    {props.children}
  </CdSalesButton>
);
