(function () {
  'use strict';
  groupFactory.$inject = ['$window', '$http', '$q'];

  function groupFactory($window, $http, $q) {
    // Main factory group object.
    let groupFactory = {
      Data: {
        groups: [],
      },

      processed: false,
    };

    /**
     * Check the status of the factory data.
     * @returns {boolean|*}
     *   return boolean status if the data is laoded already.
     */
    groupFactory.ready = function () {
      return groupFactory.processed;
    };

    /**
     * Get the list of the groups.
     * @returns {*}
     *   return the angular q promise.
     */
    groupFactory.load = function () {
      let defer = $q.defer();

      $http
        .get(cdApp.config.api.main + '/groups')
        .success(function (groups) {
          groupFactory.Data.groups = groups;
          groupFactory.processed = true;
          defer.resolve(groups);
        })
        .error(function (error) {
          defer.reject(
            'We are not able to fetch groups due to the following problem: ' +
              error
          );
        });
      return defer.promise;
    };

    /**
     * Get the specific group data.
     * @return {*}
     *   return the angular promise.
     */
    groupFactory.get = function (gid) {
      if (groupFactory.ready()) {
        return _.find(groupFactory.Data.groups, { id: gid });
      }
    };

    /**
     * Update the specific group with the specifc new data.
     * @param {int} gid
     *   the id of the group.
     * @param {object} data
     *   the object of the new data.
     */
    groupFactory.update = function () {
      throw new Error('Not implemented yet.');
    };

    /**
     * Insert the group.
     * @param data
     *   the object with the grouo parameters.
     */
    groupFactory.insert = function () {
      throw new Error('Not implemented yet.');
    };

    /**
     * Delete the specific group.
     * @param {int} gid
     *   the id of the group.
     */
    groupFactory.delete = function () {
      throw new Error('Not implemented yet.');
    };

    /**
     * Get the property from the specific group.
     * @param {int} gid
     *   the id of the group.
     * @param {string} property
     *   attribute name from the data.
     * @return {string|array|object|int}
     *   return the group property
     */
    groupFactory.getProperty = function (gid, property) {
      let group = groupFactory.get(gid);
      return group && group[property];
    };

    /**
     * Refresh the data on the group factory.
     */
    groupFactory.refresh = function () {
      groupFactory.load();
    };

    return groupFactory;
  }

  angular.module('cdApp.shared').factory('groupFactory', groupFactory);
})();
