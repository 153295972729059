/**
 * A component that shows a dropdown of a list of users to select from, or create a new user.
 *
 * @prop {Object[]} users - The list of users to select from
 * @prop {string} placeholder - The placeholder used in the users dropdown
 * @prop {Function} onUserSelected({ user }) - A callback when a user is selected from the list
 * @prop {Function} onUserCreated({ user }) - A callback when a user is created
 */
class SelectOrCreateUserComponent {
  constructor($filter, $uibModal, Authorization, Me) {
    'ngInject';

    this.$filter = $filter;
    this.$uibModal = $uibModal;
    this.Authorization = Authorization;
    this.Me = Me;
  }

  $onInit() {
    const { Authorization } = this;

    this.canCreateUsers = Authorization.hasPermission('canAdministerUsers');
    this.canBookOtherUsers = Authorization.hasPermission(
      'canCreateAbsenceAndBook'
    );
  }

  $onChanges({ users }) {
    if (users && users.currentValue) {
      this.generateUsersList({
        users: users.currentValue,
      });
    }
  }

  // Create a local list of users, for rendering
  generateUsersList({ users }) {
    const { $filter, Me } = this;

    this.usersList = _(users)
      // Filter out users who are blocked or deleted
      // Filter out other users if current user can't book other users
      .filter((user) => {
        if (user.status !== 1) return false;
        if (this.canBookOtherUsers) {
          return user;
        } else {
          return user.id === Me.id ? user : false;
        }
      })
      .map((user) => ({
        id: user.id,
        name: $filter('getName')(user),
        picture: _.get(user, 'picture.url'),
        email: user.email,
        status: user.status,
      }))
      .value();
  }

  /**
   * Create a new user
   */
  createUser() {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'cdCreateUserModal',
        size: 'lg',
        windowClass: 'modal-ui-select',
      })
      .result.then((newUser) => {
        this.onUserCreated({ user: newUser });
      });
  }

  /**
   * When the user selects a user from the dropdown
   */
  onUserChanged() {
    // Find the select user from the original list of users that was passed to the component
    this.onUserSelected({
      user: _.find(this.users, { id: this.selectedUser }),
    });
  }
}
SelectOrCreateUserComponent.$inject = [
  '$filter',
  '$uibModal',
  'Authorization',
  'Me',
];

angular.module('cdApp.shared').component('cdSelectOrCreateUser', {
  template: require('./select-or-create-user.component.html'),
  controller: SelectOrCreateUserComponent,
  bindings: {
    users: '<',
    selectedUser: '<',
    placeholder: '<',
    onUserSelected: '&',
    onUserCreated: '&',
    ngRequired: '<',
  },
});
