(function () {
  'use strict';

  function stepsFactory() {
    return function (steps, options) {
      let instance = this;

      // Get the steps from the constructor
      instance.steps = _.filter(steps, { isShown: true });

      // Assign the index to each step object. It will be used later for different types of logic
      _.each(instance.steps, function (step) {
        step.index = _.indexOf(instance.steps, step);
      });

      // Set the current step by default to the first one
      instance.current =
        options && options.initialStep
          ? _.find(instance.steps, { key: options.initialStep })
          : instance.steps[0];

      /**
       * Get the index of the current step
       *
       * @return {Boolean} - The index of the current step in the flow
       */
      instance.index = function () {
        return _.indexOf(instance.steps, instance.current);
      };

      /**
       * Go to a given step in the flow
       *
       * @param {Number} step - The step to go to. Can be either an integer representing the index or the name of the step.
       */
      instance.go = function (step) {
        const targetStep = _.isNumber(step)
          ? instance.steps[step]
          : _.find(instance.steps, { key: step });
        const targetStepIndex = _.indexOf(instance.steps, targetStep);

        if (targetStep.key === instance.current.key) {
          return;
        }

        instance.current = targetStep;

        if (options && options.onChange) {
          options.onChange(targetStepIndex);
        }
      };

      /**
       * Get the closest next available step in the flow
       *
       * @return {Object} - The next available/enabled step in the flow
       */
      instance.nextStep = function () {
        return _.find(instance.steps, function (step) {
          return step.isEnabled && step.index > instance.index();
        });
      };

      /**
       * Get the closest previous available step in the flow
       *
       * @return {Object} - The previous available/enabled step in the flow
       */
      instance.prevStep = function () {
        return _.findLast(instance.steps, function (step) {
          return step.isEnabled && step.index < instance.index();
        });
      };

      /**
       * Go to the next step in the flow
       */
      instance.next = function () {
        if (instance.hasNext()) {
          instance.go(instance.nextStep().index);
        }
      };

      /**
       * Go to the previous step in the flow
       */
      instance.previous = function () {
        if (instance.hasPrev()) {
          instance.go(instance.prevStep().index);
        }
      };

      /**
       * Check if there is an active next step in the flow
       *
       * @return {Boolean}
       */
      instance.hasNext = function () {
        let next = instance.nextStep() && instance.nextStep().index;
        return (
          !_.isUndefined(instance.steps[next]) &&
          !!instance.steps[next].isEnabled
        );
      };

      /**
       * Check if there is an active previous step in the flow
       *
       * @return {Boolean}
       */
      instance.hasPrev = function () {
        let prev = instance.prevStep() && instance.prevStep().index;
        return (
          !_.isUndefined(instance.steps[prev]) &&
          !!instance.steps[prev].isEnabled
        );
      };
    };
  }

  angular.module('cdApp.shared').factory('stepsFactory', stepsFactory);
})();
