import _ from 'lodash';

const USERS_GROUPS_SEARCH_MODE = 'users_groups';
const SHIFT_USERS_SEARCH_MODE = 'users';

const BOOKINGS_USERS_TARGET = 'bookings';
const SHIFTS_USERS_TARGET = 'shifts';

class FormEventUsersComponent {
  /**
   *
   * A component that shows the booked users for an event form. It includes a searchable input to book even more users.
   *
   * @prop {string} [usersTarget="bookings"] - Define the target of the users to be selected. Possible values: "bookings", "shifts".
   * @prop {string} [searchMode="users_groups"] - Define the search mode: search on all users and groups or users belonging to a task. Possible values: "users_groups", "shift_users".
   * @prop {Object} [rota] - The rota we are assigning too. Only used in SHIFT_USERS_SEARCH_MODE.
   * @prop {number[]} bookedUsersIds - The list of users booked on the event, which should be sent back to the DB
   * @prop {object[]} entityUsersData = The raw users data from the event. Object keyed by user ids. This includes users that has been blocked/removed.
   * @prop {Object} event - Calendar event
   * @prop {Boolean} canEditBooking - Whether the user is allowed to edit bookings
   * @prop {Object} conflictingUsers - This gets updated by the parent when new conflict information is available
   * @prop {Function} onUserListUpdated({ userIds: number[] }) - Callback for when the user list has been updated
   * @prop {Function} showDoubleBookingModal - Callback for when the parent should launch the double booking modal
   * @prop {Function} getUserConflictsInEvent -
   * @prop {Function} getConflictsInEventDescription - Function to call when the conflict message is shown. This includes the rota name. The function returns a string.
   * @prop {Boolean} showSelectAll Wheter to show a 'select all' option
   */

  constructor(
    $q,
    $uibModal,
    toastr,
    gettextCatalog,
    $filter,
    Authorization,
    Users,
    Groups,
    Tasks
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.$filter = $filter;
    this.Authorization = Authorization;
    this.Users = Users;
    this.Groups = Groups;
    this.Tasks = Tasks;
  }

  $onInit() {
    const { Users, Groups, $q, gettextCatalog, Tasks } = this;

    if (
      !_.includes(
        [BOOKINGS_USERS_TARGET, SHIFTS_USERS_TARGET],
        this.usersTarget
      )
    ) {
      this.usersTarget = BOOKINGS_USERS_TARGET;
    }
    if (
      !_.includes(
        [USERS_GROUPS_SEARCH_MODE, SHIFT_USERS_SEARCH_MODE],
        this.searchMode
      )
    ) {
      this.searchMode = USERS_GROUPS_SEARCH_MODE;
    }

    if (this.searchMode === SHIFT_USERS_SEARCH_MODE) {
      // Check that required fields are set.
      if (!this.rota) {
        throw new Error(
          'form-event-users-component.js is missing required fields in search mode: SHIFT_USERS_SEARCH_MODE'
        );
      } else {
        this.taskId = this.rota.task.id;
        this.rotaGroupId = this.rota.task.groupId;
      }
    }

    this.listOfUsers = []; // List of all the users that the user can choose from.
    this.bookedUsersCombined = []; // The users selected, including shifts. Used for rendering
    this.usersAndGroups = []; // List of groups and users for the select field.

    // Loading animators:
    this.isLoadingUserList = true;

    // Build the data structure used for booking users/group members
    let queries = {
      allUsers: Users.query().$promise,
    };

    if (this.searchMode === SHIFT_USERS_SEARCH_MODE) {
      // When booking users for a task, only a set of users can be selected
      queries.assignableUsersForTask = Tasks.getAssignableUsers({
        taskId: this.taskId,
      }).$promise;
    } else {
      // When booking users for an event, we can choose a whole group to invite all of its users
      queries.groups = Groups.query().$promise;
    }

    // Format the users array so the users objects includes user ids.
    this.entityUsersData = _.map(this.entityUsersData, (user, key) => {
      user.id = parseInt(key, 10);
      return user;
    });

    $q.all(queries).then(({ allUsers, assignableUsersForTask, groups }) => {
      this.listOfUsers = allUsers;

      // Make a list that of users (and groups) to be provided to the ui-select dropdown.
      let users;
      if (this.searchMode === SHIFT_USERS_SEARCH_MODE) {
        this.assignableUsersForTask = assignableUsersForTask;
        users = assignableUsersForTask;
      } else {
        users = _.filter(allUsers, { status: 1 });
      }

      this.usersAndGroups = _(users)
        .map((user) => this.processUser(user))
        .orderBy('name')
        .value();

      if (this.showSelectAll) {
        this.usersAndGroups.unshift({
          id: 'selectAll',
          trackBy: 'selectAll',
          name: gettextCatalog.getString('Select all'),
          email: gettextCatalog.getString('Select all'),
          groupBy: 'selectAll',
          groupByLabel: '',
          order: 0,
        });
      }

      /**
       * If we're booking users for an event, we can also book a whole group
       */
      if (this.searchMode === USERS_GROUPS_SEARCH_MODE) {
        const mappedGroups = _(groups)
          .map((group) => ({
            id: group.id,
            name: group.name,
            email: group.name,
            picture: group.picture,
            members: group.members,
            tasks: group.tasks,
            trackBy: `group-${group.id}`,
            groupBy: 'groups',
            groupByLabel: gettextCatalog.getString('Groups'),
            order: 2,
          }))
          .orderBy('name')
          .value();

        this.usersAndGroups = _.concat(this.usersAndGroups, mappedGroups);
      }

      this.combineUsers();
      this.isLoadingUserList = false;
    });
  }

  processUser(user) {
    const { $filter, gettextCatalog } = this;

    return {
      id: user.id,
      name: $filter('getName')(user),
      email: user.email,
      picture: user.picture,
      rotas: this.getRotasForUser(user.id),
      // Conflicts that the user might have within the same event (e.g. assignation to multiple rotas)
      eventConflicts: _.isFunction(this.getUserConflictsInEvent)
        ? this.getUserConflictsInEvent({ userId: user.id })
        : undefined,
      trackBy: `user-${user.id}`,
      groupBy: 'users',
      groupByLabel:
        this.searchMode === USERS_GROUPS_SEARCH_MODE
          ? gettextCatalog.getString('Users')
          : undefined,
      order: 1,
    };
  }

  $onChanges(changesObj) {
    // Only update conflict data when conflict information was updated.
    if (changesObj.conflictingUsers) {
      this.setConflictData();
    }
    // isFirstChange() ensures that this function does not run before onInit
    if (
      changesObj.bookedUsersIds &&
      !changesObj.bookedUsersIds.isFirstChange()
    ) {
      this.combineUsers();
    }
  }

  /**
   * Adds conflict data to the this.conflictingUsers variable so conflicts are rendered.
   */
  setConflictData() {
    if (!this.conflictingUsers) return;
    // Get a list of all users that conflict and show that they conflict in the list
    const conflictingWorkplanUserIds = _(this.conflictingUsers.workplan)
      .keys()
      .map(_.parseInt)
      .value();
    const conflictingBookedUserIds = _(this.conflictingUsers.users)
      .keys()
      .map(_.parseInt)
      .value();
    const conflictingShiftsUserIds = _(this.conflictingUsers.shifts)
      .keys()
      .map(_.parseInt)
      .value();
    const conflictingUserIds = _.union(
      conflictingWorkplanUserIds,
      conflictingBookedUserIds,
      conflictingShiftsUserIds
    );

    // Go through each user, check if they have a conflict. If they do, set it.. if they don't, delete conflicts
    _.each(this.bookedUsersCombined, (user) => {
      const userHasConflicts = _.includes(conflictingUserIds, user.id);
      if (userHasConflicts) {
        user.conflicts = {};
        // This should be set inside an array to fit with the current double booking modal.
        if (_.get(this.conflictingUsers, `users[${user.id}]`)) {
          user.conflicts.users = [
            _.get(this.conflictingUsers, ['users', user.id]),
          ];
        }
        if (_.get(this.conflictingUsers, `workplan[${user.id}]`)) {
          user.conflicts.workplan = [
            _.get(this.conflictingUsers, ['workplan', user.id]),
          ];
        }
      } else {
        delete user.conflicts;
      }
    });
  }

  /**
   * Filter out users that are already selected or
   * groups where all the members have already been selected
   */
  filterSelectedUsers() {
    return (item) => {
      if (item.groupBy === 'selectAll') return true;
      if (item.groupBy === 'users') {
        return !_.includes(this.bookedUsersIds, item.id);
      }
      return (
        _.intersection(item.members, this.bookedUsersIds).length !==
        _.size(item.members)
      );
    };
  }

  getRotasForUser(userId) {
    if (this.usersTarget === SHIFTS_USERS_TARGET) return undefined;
    const userShifts = _.filter(this.event.shifts, { userId });
    const rotas = _.map(userShifts, (userShift) => {
      const calendarTask = _.find(this.event.calendar_tasks, {
        taskId: userShift.taskId,
      });

      return _.get(calendarTask, 'task.title');
    });
    return !_.isEmpty(rotas) ? rotas.join(', ') : undefined;
  }

  /**
   * Combines users booked for the event and users booked for shifts.
   */
  combineUsers() {
    this.bookedUsersCombined = _(this.bookedUsersIds)
      .map(
        (userId) =>
          // Find the user in the list of users on the event, if not check the users of the installation.
          _.find(this.entityUsersData, { id: userId }) ||
          _.find(this.listOfUsers, { id: userId }) || {
            id: userId,
            removed: true,
          }
      )
      .value();
    if (this.usersTarget === BOOKINGS_USERS_TARGET) {
      // Merge with users booked for rotas. This will also add back the deleted users
      _.forEach(this.event.shifts, (shift) => {
        const user = _.find(this.bookedUsersCombined, { id: shift.userId });
        const rotas = this.getRotasForUser(shift.userId);
        if (user) {
          user.rotas = rotas;
        } else {
          this.bookedUsersCombined.push(_.extend({ rotas }, shift.user));
        }
      });
    }

    // Process blocked/removed users
    _.forEach(this.bookedUsersCombined, (user) => {
      // Process removed users as they become undefined
      if (_.isNil(user)) {
        return;
      } else if (!user.statusRemovedProcessed) {
        // Process blocked users as they are available still but not a part of the organization
        const userFromInstallation = _.find(this.listOfUsers, { id: user.id });
        if (!userFromInstallation) {
          user.removed = true;
          user.status = true;
        } else {
          user.status = userFromInstallation.status;
          user.removed = false;
        }
        // Do not process multiple times.
        user.statusRemovedProcessed = true;
      }
    });

    // Remove undefined/deleted users from array
    this.bookedUsersCombined = _.compact(this.bookedUsersCombined);

    // On first load the conflict check is running asynchronously
    // and the data might come before combineUsers() is called
    this.setConflictData();
  }

  /**
   * Adds the selected users or group members
   *
   * @param {Object} item The selected user or group
   */
  addSelectedUser(item) {
    let usersToBook = [];
    if (!item) return;
    // Adding single users
    if (item.groupBy === 'users') {
      const userObject = _.find(this.listOfUsers, { id: item.id });
      usersToBook = [userObject];
      // Adding group members
    } else if (item.trackBy === 'selectAll') {
      usersToBook =
        this.searchMode === SHIFT_USERS_SEARCH_MODE
          ? this.assignableUsersForTask
          : this.listOfUsers;
    } else {
      const groupMembers = _.filter(this.listOfUsers, (user) =>
        _.includes(item.members, user.id)
      );

      usersToBook = groupMembers;
    }
    // Combine for displaying purposes
    this.bookedUsersCombined = _.unionBy(
      this.bookedUsersCombined,
      usersToBook,
      'id'
    );

    // Array of ids for users to book
    let userIdsToBook = _.map(usersToBook, 'id');
    // Adding user to a shift
    this.bookedUsersIds = _.union(this.bookedUsersIds, userIdsToBook);

    // Invoke parent controller
    this.handleUserListUpdate(false);
  }

  /**
   *  Removes a given user from the booked list.
   *
   * @param {number} userId The user identifier of the user.
   */
  removeSelectedUser(userId) {
    _.remove(this.bookedUsersCombined, (user) => user.id === userId);
    _.pull(this.bookedUsersIds, userId);

    if (this.usersTarget === BOOKINGS_USERS_TARGET) {
      // If the user was also in shifts, remove it from them
      const shifts = _.filter(
        this.event.shifts,
        (shift) => shift.userId !== userId
      );

      this.event.shifts = shifts;
    }
    // Invoke parent controller
    this.handleUserListUpdate(true);
  }

  createUser() {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'cdCreateUserModal',
        size: 'lg',
        windowClass: 'modal-ui-select',
        resolve: {
          groupIds: () => (this.rotaGroupId ? [this.rotaGroupId] : undefined),
        },
      })
      .result.then((newUser) => {
        if (!newUser) return;
        // Add some additional data to the newly created user
        // to properly show them in the list of selected users
        const extendedUser = _.extend(_.pick(newUser, ['id', 'email']), {
          status: 'active',
          isAdmin: false,
          name: _.trim(_.join([newUser.firstName, newUser.lastName], ' ')),
        });

        // Add the newly created (and extended) user to the list of available users
        this.listOfUsers.push(extendedUser);

        // Prepare the newly created user to be added to the list of users
        // and groups and sort the list afterwards
        const processedUser = this.processUser(extendedUser);
        this.usersAndGroups.push(processedUser);
        this.usersAndGroups = _(this.usersAndGroups).orderBy('name').value();

        // Add the newly created user to the list of selected users
        this.addSelectedUser(processedUser);
      });
  }

  handleUserListUpdate(wasUserRemoved) {
    const { toastr, gettextCatalog } = this;

    this.isCheckingUsersAvailability = true;
    this.onUserListUpdated({ userIds: this.bookedUsersIds, wasUserRemoved })
      .catch((error) => {
        toastr.error(
          _.get(error, 'data.message') ||
            gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
        );
      })
      .finally(() => {
        this.isCheckingUsersAvailability = false;
      });
  }

  areThereBookingConflicts(user) {
    const conflictingEntities = [
      _.get(user, 'conflicts.users'),
      _.get(user, 'conflicts.shifts'),
      _.get(user, 'conflicts.workplan'),
    ];

    return _.some(conflictingEntities);
  }

  /**
   * Invokes the parents double booking modal.
   *
   * @param {*} conflicts the selected users conflict information
   */
  showDoubleBookingModalOnParent(conflicts) {
    this.showDoubleBookingModal({ conflicts });
  }

  getPlaceholderText() {
    const { gettextCatalog } = this;

    return this.searchMode === USERS_GROUPS_SEARCH_MODE
      ? gettextCatalog.getString('Select a user or a group..')
      : gettextCatalog.getString('Assign a user..');
  }

  getSelectedUsersLabel() {
    const { gettextCatalog } = this;

    const amountSelectedUsers = _.size(this.bookedUsersCombined) || 0;
    return this.usersTarget === BOOKINGS_USERS_TARGET
      ? gettextCatalog.getPlural(
          amountSelectedUsers,
          '1 user added',
          '{{count}} users added',
          {
            count: amountSelectedUsers,
          }
        )
      : gettextCatalog.getPlural(
          amountSelectedUsers,
          '1 user assigned',
          '{{ count }} users assigned',
          {
            count: amountSelectedUsers,
          }
        );
  }

  getNoSelectedUsersLabel() {
    const { gettextCatalog } = this;

    return this.usersTarget === BOOKINGS_USERS_TARGET
      ? gettextCatalog.getString('No users added yet')
      : gettextCatalog.getString('No users assigned yet');
  }

  shouldShowEmptyState() {
    return !this.bookedUsersCombined.length && !this.isLoadingUserList;
  }

  getBusyUsersLabel() {
    const { gettextCatalog } = this;

    if (!_.some(this.bookedUsersCombined, 'conflicts')) return null;

    const amountBookedUsers = _.size(
      _.filter(this.bookedUsersCombined, 'conflicts')
    );

    return gettextCatalog.getPlural(
      amountBookedUsers,
      '1 user busy',
      '{{count}} users busy',
      {
        count: amountBookedUsers,
      }
    );
  }

  /**
   * Whether it's allowed to select users from the dropdown
   */
  shouldDisableUsersDropdown() {
    if (!this.canEditBooking) return true;

    if (this.isLoadingUserList) return true;

    const remaining = this.getRemainingCount();
    return remaining === 0;
  }

  /**
   * Get the number of missing assignees for the rota. If it returns `null`, then there's no specific limit
   */
  getRemainingCount() {
    const required = _.get(this.rota, 'required');

    // Rota doesn't have a specific limit
    if (!required) return null;

    const amountBookedUsers = _.size(this.bookedUsersCombined) || 0;
    const missing = required - amountBookedUsers;

    return missing;
  }

  getUnassignedUsersLabel() {
    const { gettextCatalog } = this;

    const missing = this.getRemainingCount();

    // Rota is unlimited or fully booked
    if (missing <= 0) return null;

    return gettextCatalog.getPlural(missing, '1 missing', '{{count}} missing', {
      count: missing,
    });
  }

  canRemoveSelectedUser(userId) {
    const { Authorization } = this;

    if (this.usersTarget === SHIFTS_USERS_TARGET) {
      const shift = _.find(this.event.shifts, { taskId: this.taskId, userId });
      // Allow user to remove select user if not part of a shift yet (meaning they have not submitted it)
      return _.get(shift, 'access.canUnassign', true);
    } else {
      return Authorization.hasPermission('canBook');
    }
  }

  getNoUserRemovalPermissionLabel() {
    const { gettextCatalog } = this;

    return this.usersTarget === BOOKINGS_USERS_TARGET
      ? gettextCatalog.getString('You do not have permission to edit bookings.')
      : gettextCatalog.getString('You do not have permission to edit rotas.');
  }

  hasUserEventConflicts(userId) {
    const user = _.find(this.usersAndGroups, { id: userId, groupBy: 'users' });
    return !_.isEmpty(_.get(user, 'eventConflicts'));
  }

  getEventConflictsLabel(userId) {
    if (!this.hasUserEventConflicts(userId)) return null;
    const user = _.find(this.usersAndGroups, { id: userId, groupBy: 'users' });
    return (
      _.isFunction(this.getConflictsInEventDescription) &&
      this.getConflictsInEventDescription({
        conflictsInEvent: _.get(user, 'eventConflicts'),
      })
    );
  }
}
FormEventUsersComponent.$inject = [
  '$q',
  '$uibModal',
  'toastr',
  'gettextCatalog',
  '$filter',
  'Authorization',
  'Users',
  'Groups',
  'Tasks',
];

// Example: <cd-form-event-users-component />
angular.module('cdApp.shared').component('cdFormEventUsersComponent', {
  template: require('./form-event-users.component.html'),
  controller: FormEventUsersComponent,
  bindings: {
    usersTarget: '@',
    searchMode: '@',
    rota: '<',
    bookedUsersIds: '<',
    entityUsersData: '<',
    event: '<',
    canEditBooking: '<', // One way binding
    conflictingUsers: '<',
    onUserListUpdated: '&',
    showDoubleBookingModal: '&',
    getUserConflictsInEvent: '&',
    getConflictsInEventDescription: '&',
    showSelectAll: '<',
  },
});
