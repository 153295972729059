import {
  Form,
  DatePicker,
  Space,
  Select,
  Button,
  Row,
  Col,
  Typography,
  Card,
} from 'antd';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { gettextCatalog } from '../../services/I18nService';

import { useTimeRegistrationReport } from '@/react/calendar/reports/hooks/useTimeRegistrationReport';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const { RangePicker } = DatePicker;

const StyledSelect = styled(Select)`
    .ant-select-selection-item{
        display: flex;
        align-items: center;
    }`;

export const TimeRegistrationReport = ({ filters }) => {
  const {
    timeRegistrationReportForm,
    initialValues,
    onExportTimeRegistrationReport,
    timeRegistrationReportUsers,
    setHasChanges,
    hasChanges,
  } = useTimeRegistrationReport({ filters });

  const [searchValue, setSearchValue] = useState<string>('');
  const userOptions = timeRegistrationReportUsers?.map((item) => ({
    label: item.name || item.email,
    picture: item.picture,
    value: item.id,
  }));

  const onFormValueChange = React.useCallback(
    (changedValues) => {
      if (changedValues.user) {
        setHasChanges(true);
      }
    },
    [setHasChanges]
  );
  const customWeekFormat = (value) => {
    if (!value) {
      return '';
    }
    const formattedDate = gettextCatalog.getString('{{year}} - Week {{week}}', {
      year: value?.year(),
      week: value?.week(),
    });
    return formattedDate;
  };
  const filteredOptions = useMemo(() => {
    const searchedArray = !searchValue
      ? userOptions
      : userOptions?.filter((option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase())
        );
    return searchedArray;
  }, [searchValue, userOptions]);

  const onSearchHandler = (value: string) => {
    if (!value) return;
    setSearchValue(value);
  };

  return (
    <Card style={{ height: '100vh', border: 'none' }}>
      <Row gutter={[12, 24]}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text>
            {gettextCatalog.getString(
              'Please select your parameters for the time registration report. We will generate the report and send it to your email.'
            )}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Form
            form={timeRegistrationReportForm}
            initialValues={initialValues}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            onValuesChange={onFormValueChange}
          >
            <Space>
              <Form.Item
                name="dateRange"
                rules={[
                  {
                    validator: (rule, value) => {
                      const [start, end] = value;
                      const weekDifference = end.diff(start, 'weeks');
                      if (weekDifference >= 53) {
                        return Promise.reject(
                          gettextCatalog.getString(
                            'The selected date range should not exceed 53 weeks'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <RangePicker
                  size="large"
                  picker="week"
                  format={customWeekFormat}
                  allowClear={false}
                  disabledDate={(date) =>
                    dayjs(date).isAfter(dayjs().endOf('week').add(1, 'day')) ||
                    dayjs(date).isBefore(dayjs('2024-06-24'))
                  }
                />
              </Form.Item>
              <Form.Item name="user">
                <StyledSelect
                  defaultValue={gettextCatalog.getString('Users')}
                  size="large"
                  style={{ width: 280 }}
                  showSearch
                  onSearch={onSearchHandler}
                  filterOption={false}
                  onChange={(value) => {
                    timeRegistrationReportForm.setFieldsValue({ user: value });
                    setHasChanges(true);
                    setSearchValue('');
                  }}
                >
                  {filteredOptions &&
                    filteredOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <Space direction="horizontal">
                          <CdUserAvatar picture={option.picture} size={30} />
                          {option.label}
                        </Space>
                      </Select.Option>
                    ))}
                </StyledSelect>
              </Form.Item>
              <Button
                size="large"
                onClick={onExportTimeRegistrationReport}
                style={{ marginBottom: '24px' }}
                type="primary"
                htmlType="submit"
                disabled={!hasChanges}
              >
                {gettextCatalog.getString('Export')}
              </Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
