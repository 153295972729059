import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import { Space, Spin, Tag, Typography } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import { NewsletterandFilterDataInterface } from '../store/newsletterListState';
import { TagInterface } from '../types/tag.type';
import { PeopleListByTypeAndSearchQuery } from '../store/newsletterListState';
import { GetAllTags } from '../store/tagState';
import useManageContacts from '../hooks/useManageContacts';

import { ManagementContactType } from './manage-contacts-drawer/ManageContactsDrawer';

import { ResourceWithAccessContext } from '@/react/shared/models/resource';
import { UserChurchesWithPermissionQuery } from '@/react/user/store/permissions';
import CdModal from '@/react/shared/components/cd-modal/CdModal';
import { gettextCatalog } from '@/react/services/I18nService';

const Container = styled.div`
  margin: 24px 32px 16px;
`;
export interface ConfirmManageContactsProps extends Record<string, unknown> {
  type: ManagementContactType;
  selectedPeople;
  isGlobalSelect: boolean;
  isNesletterAdd: boolean;
  result: { add: number[]; remove: number[] };
  filter: { churchIds: number[]; comparison: string; filters: [] };
  newsletterAndParticipantInfo: {
    newsletterAdd: number;
    newsletterRemove: number;
    participantAdd: number;
    participantRemove: number;
  };
}
const ConfirmManageContactsModal = NiceModal.create(
  ({
    type,
    selectedPeople,
    isGlobalSelect,
    isNesletterAdd,
    result,
    filter,
    newsletterAndParticipantInfo,
  }: ConfirmManageContactsProps) => (
    <ConfirmManageContacts
      type={type}
      selectedPeople={selectedPeople}
      isGlobalSelect={isGlobalSelect}
      isNesletterAdd={isNesletterAdd}
      result={result}
      filter={filter}
      newsletterAndParticipantInfo={newsletterAndParticipantInfo}
    />
  )
);
const showConfirmRegisterConsentOnContact = (
  filterSetup,
  parent: string,
  resaultForAddAndRemoveNewsletters: { add: number[]; remove: number[] },
  personIds: number[]
) => {
  NiceModal.show('ConfirmRegisterConsentOnContacts', {
    filterSetup,
    parent,
    resaultForAddAndRemoveNewsletters,
    personIds,
  });
};
const ConfirmManageContacts = ({
  type,
  selectedPeople,
  isGlobalSelect,
  isNesletterAdd,
  result,
  filter,
  newsletterAndParticipantInfo,
}: ConfirmManageContactsProps) => {
  const modal = useModal('ConfirmManageContacts');
  const { manageNewsletterAndParticipantLists, manageParishes, manageTags } =
    useManageContacts();
  const addNames = [];
  const participantAddNames = [];
  const removeNames = [];
  const participantRemoveNames = [];
  const newsletterListDataLoading = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({
      type: 'newsletter',
      searchParameter: '',
    })
  );
  const participantListDataloading = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({
      type: 'participant',
      searchParameter: '',
    })
  );
  const tagsDataloading = useRecoilValueLoadable(GetAllTags(''));
  const parishesLoadable = useRecoilValueLoadable(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'access',
    })
  );
  const newsletterListData =
    newsletterListDataLoading.state === 'hasValue'
      ? newsletterListDataLoading.contents.items.filter(
          ({ canAddPeopleToList }) => canAddPeopleToList
        )
      : [];
  const participantListData =
    participantListDataloading.state === 'hasValue'
      ? participantListDataloading.contents.items
      : [];
  const tagsData =
    tagsDataloading.state === 'hasValue' ? tagsDataloading.contents : [];

  const parishesData =
    parishesLoadable.state === 'hasValue' ? parishesLoadable.contents : [];

  const isLoading =
    newsletterListDataLoading.state === 'loading' ||
    participantListDataloading.state === 'loading' ||
    tagsDataloading.state === 'loading' ||
    parishesLoadable.state === 'loading';

  const setNames = (
    dataSource:
      | NewsletterandFilterDataInterface[]
      | TagInterface[]
      | Partial<ResourceWithAccessContext>[],
    type: string
  ) => {
    if (type === 'participant') {
      dataSource.forEach((item) => {
        if (result.add.includes(item.id)) {
          participantAddNames.push(item.name);
        }
        if (result.remove.includes(item.id)) {
          participantRemoveNames.push(item.name);
        }
      });
    } else {
      dataSource.forEach((item) => {
        if (result.add.includes(item.id)) {
          addNames.push(item.name);
        }
        if (result.remove.includes(item.id)) {
          removeNames.push(item.name);
        }
      });
    }
  };
  switch (type) {
    case ManagementContactType.Newsletter:
      setNames(newsletterListData, 'newsletter');
      setNames(participantListData, 'participant');
      break;
    case ManagementContactType.Parish:
      setNames(parishesData, 'parish');
      break;
    case ManagementContactType.Tag:
      setNames(tagsData, 'tag');
      break;
  }

  const applyChanges = async () => {
    const personIds = selectedPeople.map((person) => person.id);
    if (type === ManagementContactType.Newsletter) {
      if (isNesletterAdd) {
        showConfirmRegisterConsentOnContact(
          filter,
          'ConfirmManageContacts',
          result,
          isGlobalSelect ? undefined : personIds
        );
        modal.hide();
      } else {
        await manageNewsletterAndParticipantLists({
          filter: isGlobalSelect ? filter : undefined,
          peopleLists: result,
          personIds: isGlobalSelect ? undefined : personIds,
        });
      }
    }
    if (type === ManagementContactType.Parish) {
      await manageParishes({
        filter: isGlobalSelect ? filter : undefined,
        churches: result,
        people: isGlobalSelect ? undefined : personIds,
      });
    }
    if (type === ManagementContactType.Tag) {
      await manageTags({
        filter: isGlobalSelect ? filter : undefined,
        tags: result,
        people: isGlobalSelect ? undefined : personIds,
      });
    }
  };

  return (
    <CdModal
      title={gettextCatalog.getString('Apply {{label}} changes', {
        label:
          type === ManagementContactType.Newsletter
            ? `newsletter lists and participant lists`
            : type === ManagementContactType.Parish
              ? 'parishes'
              : type,
      })}
      okText={gettextCatalog.getString('Yes')}
      onOk={applyChanges}
      modal={modal}
    >
      <Spin spinning={isLoading}>
        <Container>
          <Typography.Paragraph>
            {gettextCatalog.getString('Do you want to:')}
          </Typography.Paragraph>
          {result.add?.length > 0 && type === ManagementContactType.Tag && (
            <Typography.Paragraph>
              <Space wrap>
                {gettextCatalog.getString(
                  'add contact(s) to the following tag(s):'
                )}
                {addNames.map((name, index) => (
                  <Tag key={index}>{name}</Tag>
                ))}
              </Space>
            </Typography.Paragraph>
          )}
          {result.add?.length > 0 && type === ManagementContactType.Parish && (
            <Typography.Paragraph>
              <Space wrap>
                {gettextCatalog.getString(
                  'add contact(s) to the following parish(es):'
                )}
                {addNames.map((name, index) => (
                  <Tag key={index}>{name}</Tag>
                ))}
              </Space>
            </Typography.Paragraph>
          )}
          {newsletterAndParticipantInfo.newsletterAdd > 0 &&
            type === ManagementContactType.Newsletter && (
              <Typography.Paragraph>
                <Space wrap>
                  {gettextCatalog.getString(
                    'add contact(s) to the following newsletter list(s):'
                  )}
                  {addNames.map((name, index) => (
                    <Tag key={index}>{name}</Tag>
                  ))}
                </Space>
              </Typography.Paragraph>
            )}
          {newsletterAndParticipantInfo.participantAdd > 0 &&
            type === ManagementContactType.Newsletter && (
              <Typography.Paragraph>
                <Space wrap>
                  {gettextCatalog.getString(
                    'add contact(s) to the following participant list(s):'
                  )}
                  {participantAddNames.map((name, index) => (
                    <Tag key={index}>{name}</Tag>
                  ))}
                </Space>
              </Typography.Paragraph>
            )}
          {result.remove?.length > 0 &&
            type === ManagementContactType.Parish && (
              <>
                <Typography.Paragraph>
                  <Space wrap>
                    {gettextCatalog.getString(
                      'remove contact(s) from the following parish(es):'
                    )}
                    {removeNames.map((name, index) => (
                      <Tag key={index}>{name}</Tag>
                    ))}
                  </Space>
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginTop: '32px' }}>
                  {gettextCatalog.getString(
                    'Please note that a contact has to have at least one parish.'
                  )}
                </Typography.Paragraph>
              </>
            )}
          {result.remove?.length > 0 && type === ManagementContactType.Tag && (
            <Typography.Paragraph>
              <Space wrap>
                {gettextCatalog.getString(
                  'remove contact(s) from the folloing tag(s):'
                )}
                {removeNames.map((name, index) => (
                  <Tag key={index}>{name}</Tag>
                ))}
              </Space>
            </Typography.Paragraph>
          )}
          {newsletterAndParticipantInfo?.newsletterRemove > 0 &&
            type === ManagementContactType.Newsletter && (
              <Typography.Paragraph>
                <Space wrap>
                  {gettextCatalog.getString(
                    'remove contact(s) from the following newsletter list(s):'
                  )}
                  {removeNames.map((name, index) => (
                    <Tag key={index}>{name}</Tag>
                  ))}
                </Space>
              </Typography.Paragraph>
            )}
          {newsletterAndParticipantInfo.participantRemove > 0 &&
            type === ManagementContactType.Newsletter && (
              <Typography.Paragraph>
                <Space wrap>
                  {gettextCatalog.getString(
                    'remove contact(s) from the following participant list(s):'
                  )}
                  {participantRemoveNames.map((name, index) => (
                    <Tag key={index}>{name}</Tag>
                  ))}
                </Space>
              </Typography.Paragraph>
            )}
        </Container>
      </Spin>
    </CdModal>
  );
};

export default ConfirmManageContactsModal;
