import _ from 'lodash';

class FormResponsePaymentSummaryComponent {
  constructor(Authorization, cdContributionStatusStrings) {
    'ngInject';

    this.Authorization = Authorization;
    this.cdContributionStatusStrings = cdContributionStatusStrings;
  }

  $onInit() {
    const { Authorization } = this;
    this.canAccessContributions = Authorization.hasPermission(
      'canAccessContributions'
    );

    this.isEmpty = _.isEmpty(this.paymentSummary);
  }

  getPaymentStatusText(status) {
    const { cdContributionStatusStrings } = this;

    const strings = cdContributionStatusStrings.getStrings();
    return _.get(strings, status);
  }

  getPaymentStatusClass(status) {
    return _.get(
      {
        failed: 'row-error',
        pending: 'row-warning',
      },

      status
    );
  }
}

FormResponsePaymentSummaryComponent.$inject = [
  'Authorization',
  'cdContributionStatusStrings',
];

angular.module('cdApp.forms').component('cdFormResponsePaymentSummary', {
  template: require('./form-response-payment-summary.component.html'),
  bindings: {
    paymentSummary: '<',
    hasDiff: '<',
  },

  controller: FormResponsePaymentSummaryComponent,
});
