/**
 * Sets the model value to the 'YYYY-MM-DD' format
 *
 * @example: <input ng-model="$ctrl.date" datepicker-local-date />
 */
angular.module('cdApp.shared').directive('datepickerLocalDate', [
  'moment',
  '_',
  (moment, _) => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModelController) {
      const format = 'YYYY-MM-DD';

      // Remove date validator
      delete ngModelController.$validators.date;

      /**
       * Parses a Date object into a string with the format 'YYYY-MM-DD'
       *
       * @param {*} viewValue The Date object picked from the datepicker
       */
      const parser = (viewValue) => {
        if (_.isNil(viewValue)) {
          ngModelController.$setValidity('date', true);
          return null;
        }

        if (!(viewValue instanceof Date)) {
          ngModelController.$setValidity('date', false);
          return;
        } else {
          // Handle timezone offset
          viewValue.setMinutes(
            viewValue.getMinutes() - viewValue.getTimezoneOffset()
          );

          // Return the format 'YYYY-MM-DD' which is the first part of the ISO format
          ngModelController.$setValidity('date', true);
          return viewValue.toISOString().substring(0, 10);
        }
      };

      /**
       * Converts a 'YYYY-MM-DD' string to a Date Object to be passed to the parser
       *
       * @param {*} modelValue The changed model value
       */
      const formatter = (modelValue) => {
        if (!modelValue) return;

        const m = moment(modelValue, format);
        if (!m.isValid()) return null;

        const viewValue = m.toDate();
        viewValue.setMinutes(viewValue.getMinutes());
        ngModelController.$setViewValue(viewValue);

        return viewValue;
      };

      ngModelController.$parsers.push(parser);
      ngModelController.$formatters.push(formatter);
    },
  }),
]);
