(function () {
  'use strict';
  Contributions.$inject = ['$$resource'];

  function Contributions($$resource) {
    return $$resource(cdApp.config.api.main + '/contributions', null, {
      payments: {
        method: 'GET',
        url: cdApp.config.api.payments + '/contributions/:id/payments',
        isArray: true,
      },

      getAccountLink: {
        method: 'GET',
        url: cdApp.config.api.payments + '/organizations/verification/links',
      },

      addBankAccount: {
        method: 'POST',
        url: cdApp.config.api.payments + '/organizations/add/bankaccount',
      },
    });
  }

  angular.module('cdApp.shared').factory('Contributions', Contributions);
})();
