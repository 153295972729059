import styled from 'styled-components';

const FolderItem = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f6f7f8;
  border: 1px solid #ddd;
`;

export default FolderItem;
