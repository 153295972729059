import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { EventsRotaTableValue } from '../components/rotas/EventsRotaTable';
import { DateInputValue } from '../components/date-input/DateInput';

import { IntentionPriorityTypes } from '@/react/intention/models/intention';
import { RotaTableState } from '@/react/calendar/store/events/eventRotaDuties';

export const useRotaAndIntentionState = () => {
  const setRotaTableState = useSetRecoilState(RotaTableState);

  const setInitialRotaTableState = useRecoilCallback(
    () => (value: EventsRotaTableValue) => {
      setRotaTableState(value);
    },
    [setRotaTableState]
  );
  const updateRotaTableStateByEventDateChange = useRecoilCallback(
    () => (calendarId: number, eventDate: DateInputValue) => {
      if (eventDate.allDay) {
        setRotaTableState((prevValue) => ({
          ...prevValue,
          shiftsAndIntentions: prevValue?.shiftsAndIntentions?.map(
            (eventInSerie) => {
              if (calendarId) {
                if (eventInSerie.calendarId === calendarId) {
                  return {
                    ...eventInSerie,
                    startDate: eventDate.startDate.startOf('day').toISOString(),
                    endDate: eventDate.endDate
                      .endOf('day')
                      .set('milliseconds', 0)
                      .toISOString(),
                  };
                } else {
                  return eventInSerie;
                }
              } else {
                return {
                  ...eventInSerie,
                  startDate: eventDate.startDate.startOf('day').toISOString(),
                  endDate: eventDate.endDate
                    .endOf('day')
                    .set('milliseconds', 0)
                    .toISOString(),
                };
              }
            }
          ),
        }));
      } else {
        setRotaTableState((prevValue) => ({
          ...prevValue,
          shiftsAndIntentions: prevValue?.shiftsAndIntentions?.map(
            (eventInSerie) => {
              if (calendarId) {
                if (eventInSerie.calendarId === calendarId) {
                  return {
                    ...eventInSerie,
                    startDate: eventDate.startDate.toISOString(),
                    endDate: eventDate.endDate.toISOString(),
                  };
                } else {
                  return eventInSerie;
                }
              } else {
                return {
                  ...eventInSerie,
                  startDate: eventDate.startDate.toISOString(),
                  endDate: eventDate.endDate.toISOString(),
                };
              }
            }
          ),
        }));
      }
    },
    [setRotaTableState]
  );

  const resetRotaTableStateToNull = useRecoilCallback(
    () => () => {
      setRotaTableState(null);
    },
    [setRotaTableState]
  );

  const updateRotaTableStateToRemoveTheRotaDuty = useRecoilCallback(
    () => (taskId: number) => {
      setRotaTableState((prevValue) => ({
        rotas: prevValue.rotas.filter((item) => item.taskId !== taskId),
        shiftsAndIntentions: prevValue.shiftsAndIntentions.map((event) => ({
          ...event,
          shifts: [...event.shifts.filter((shift) => shift.taskId !== taskId)],
        })),
      }));
    },
    [setRotaTableState]
  );

  const updateRotaTableStateToEditTheRotaDuty = useRecoilCallback(
    () => (taskId: number, requiredNumber: number) => {
      setRotaTableState((prevValue) => ({
        ...prevValue,
        rotas: prevValue.rotas.map((item) => {
          if (item.taskId === taskId) {
            return { ...item, required: requiredNumber };
          }
          return item;
        }),
      }));
    },
    [setRotaTableState]
  );

  const updateRotaTableStateByCreatingNewIntention = useRecoilCallback(
    () => (eventId: number, intention: any) => {
      setRotaTableState((prevValue) => {
        const newItems = prevValue?.shiftsAndIntentions.map((event) => {
          if (event.calendarId === eventId) {
            const existedIntentions: EventsRotaTableValue['shiftsAndIntentions'][number]['intentions'] =
              event?.intentions;
            const numberOfExistedIntentions = existedIntentions.length;
            const newIntentions = existedIntentions.concat([
              {
                id: intention.id,
                reference: `${intention.referenceNumber}-${intention.intentionYear}`,
                text: intention.intentionText,
                priority:
                  numberOfExistedIntentions >= 1
                    ? IntentionPriorityTypes.WEITERLEITUNG
                    : IntentionPriorityTypes.PERSOLVIERT,
                intentionType: null,
                founder: null,
                access: { canUnassign: true },
                assignedBy: 'frontend',
              },
            ]);

            return {
              ...event,
              intentions: newIntentions,
            };
          } else {
            return event;
          }
        });

        return {
          ...prevValue,
          shiftsAndIntentions: newItems,
        };
      });
    },
    [setRotaTableState]
  );

  const updateRotaTableStateByAssigningTheIntention = useRecoilCallback(
    () => (eventId: number, assignedIntentions: any) => {
      setRotaTableState((prevValue) => {
        const newItems = prevValue.shiftsAndIntentions.map((event) => {
          if (event.calendarId === eventId) {
            return {
              ...event,
              intentions: assignedIntentions.items,
            };
          }
          return event;
        });
        return {
          ...prevValue,
          shiftsAndIntentions: newItems,
        };
      });
    },
    [setRotaTableState]
  );

  const updateRotaTableStateToAddUserToTheRota = useRecoilCallback(
    () =>
      (
        calendarId: number,
        taskId: number,
        userAssignedIds: number[],
        note: string
      ) => {
        setRotaTableState((prevValue) => {
          const newShiftsAndIntentions = prevValue.shiftsAndIntentions.map(
            (event) => {
              if (event.calendarId === calendarId) {
                return {
                  ...event,
                  shifts: event.shifts.map((shift) => {
                    if (shift.taskId === taskId) {
                      return {
                        ...shift,
                        users: userAssignedIds.map((userId) => ({
                          // TODO fix this access.
                          access: { canUnassign: true },
                          id: userId,
                        })),
                        note,
                      };
                    } else {
                      return shift;
                    }
                  }),
                };
              } else {
                return event;
              }
            }
          );
          return {
            ...prevValue,
            shiftsAndIntentions: newShiftsAndIntentions,
          };
        });
      },
    [setRotaTableState]
  );

  const updateRotaTableStateByAddingNewRotaDuty = useRecoilCallback(
    () => (taskId: number, required: number, title: string) => {
      setRotaTableState((previousValue) => ({
        ...previousValue,
        rotas: [
          ...previousValue.rotas,
          {
            taskId: taskId,
            required: required,
            title: title,
          },
        ],
        shiftsAndIntentions: previousValue.shiftsAndIntentions.map((event) => ({
          ...event,
          shifts: [
            ...event.shifts,
            { taskId, access: { canAssignMySelf: true, canEdit: true } },
          ],
        })),
      }));
    },
    [setRotaTableState]
  );
  return {
    setInitialRotaTableState,
    updateRotaTableStateByEventDateChange,
    resetRotaTableStateToNull,
    updateRotaTableStateToRemoveTheRotaDuty,
    updateRotaTableStateToEditTheRotaDuty,
    updateRotaTableStateByCreatingNewIntention,
    updateRotaTableStateByAssigningTheIntention,
    updateRotaTableStateToAddUserToTheRota,
    updateRotaTableStateByAddingNewRotaDuty,
  };
};
