'use strict';

class ContributionStatusController {
  constructor(_, gettextCatalog, cdContributionStatusStrings) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.cdContributionStatusStrings = cdContributionStatusStrings;
  }

  $onInit() {
    const { _, gettextCatalog, cdContributionStatusStrings } = this;

    // The contribution status can only one of the following:
    const allowedStatuses = ['pending', 'paid', 'refunded', 'failed'];

    const status = _.includes(allowedStatuses, this.status)
      ? this.status
      : 'failed';
    const description = _.get(
      cdContributionStatusStrings.getStrings(this.type),
      status
    );

    const statuses = {
      pending: {
        label: gettextCatalog.getString('Pending'),
        color: 'label-warning',
        description,
      },

      refunded: {
        label: gettextCatalog.getString('Refunded'),
        color: 'label-default',
        description,
      },

      paid: {
        label: gettextCatalog.getString('Paid'),
        color: 'label-success',
        description,
      },

      failed: {
        label: gettextCatalog.getString('Failed'),
        color: 'label-danger',
        description,
      },
    };

    this.contributionStatus = _.get(statuses, status, statuses.failed);
  }
}
ContributionStatusController.$inject = [
  '_',
  'gettextCatalog',
  'cdContributionStatusStrings',
];

angular.module('cdApp.shared').component('cdContributionStatus', {
  template: require('./contribution-status.component.html'),
  bindings: {
    status: '<',
    type: '<',
    isLabel: '<',
    size: '<',
  },

  controller: ContributionStatusController,
});
