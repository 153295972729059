// eslint-disable-next-line
import { Tooltip, TooltipProps } from 'antd';
import React from 'react';

export type CdTooltipProps = {
  type?: 'default' | 'warning';
} & TooltipProps;

// https://github.com/ant-design/ant-design/issues/41206
const CdTooltip = ({ type, children, ...props }: CdTooltipProps) => (
  <>
    <Tooltip {...props} color={type === 'warning' ? 'red' : null}>
      <>{children}</>
    </Tooltip>
  </>
);

export default CdTooltip;
