'use strict';

import _ from 'lodash';

import StoleService from '../../../../../react/intention/services/StoleService';
import IntentionService from '../../../../../react/intention/services/IntentionService';
import { getIntentionFees } from '../../../../../react/settings/redux/intention-fees/Selectors';
import {
  IntentionStatusTypes,
  IntentionPriorityTypes,
} from '../../../../../react/intention/models/intention';
import { getCommaFormattedNumber } from '../../../../../react/shared/utils';
import { NO_COLOR, COLOR_INDEX } from '../../../../ResourceColors.ts';

class EventPopupComponent {
  constructor(
    $q,
    $filter,
    $ngRedux,
    $uibModal,
    $rootScope,
    $scope,
    $window,
    Calendar,
    eventPopupService,
    toastr,
    gettextCatalog,
    moment,
    cdApp,
    $state,
    Authorization,
    appUtils
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$window = $window;
    this.Calendar = Calendar;
    this.eventPopupService = eventPopupService;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.moment = moment;
    this.cdApp = cdApp;
    this.$state = $state;
    this.Authorization = Authorization;
    this.appUtils = appUtils;
  }

  $onInit() {
    this.loading = true;
    this.popupApi = $(this.targetElement).qtip('api');

    this.canGroupResources = this.cdApp.showChurchSelector;
    this.hasIntentionAccess = this.Authorization.hasPackage('intentions');

    let eventPromise;

    if (_.isNumber(this.eventSource)) {
      if (this.type === 'external') {
        eventPromise = this.Calendar.getExternalEvent({
          id: this.eventSource,
        }).$promise;
      } else {
        eventPromise = this.Calendar.get({ id: this.eventSource }).$promise;
      }
    } else if (this.eventSource instanceof this.Calendar) {
      eventPromise = this.$q.resolve(this.eventSource);
    }

    eventPromise
      .then(async (eventObject) => {
        if (this.hasIntentionAccess) {
          const intentions = await IntentionService.fetchEventIntentions(
            eventObject.id
          );

          const stole = await StoleService.getEventStole(eventObject.id);

          return [eventObject, intentions, stole];
        } else {
          return [eventObject, [], null];
        }
      })
      .then(([eventObject, intentions, stole]) => {
        this.event = eventObject;
        const category = _(eventObject.taxonomies).values().find('isMaster');
        const isExternalSubscription = eventObject.type === 'feed';
        const isExternalEvent = eventObject.type === 'externalEvent';
        const hasSignupForm = eventObject.form;

        let color;
        if (isExternalSubscription) {
          color = this.event.iCalSubscription
            ? this.event.iCalSubscription.color
            : -1;
        } else {
          color = category && category.color;
        }

        this.viewData = {
          isEvent: eventObject.type === 'event',
          isAbsence: eventObject.type === 'absence',
          isExternalSubscription,
          color: isExternalEvent ? -1 : color,
          isPublicEvent:
            eventObject.type === 'event' && eventObject.visibility === 'public',
          isExternalEvent,
          hasSignupForm,
          category,
          otherCategories: _(eventObject.taxonomies)
            .values()
            .filter((item) => !item.isMaster)
            .value(),
          intentions,
          stole,
          iCalSubscription: eventObject.iCalSubscription,
          formattedDate: this.eventPopupService.getFormattedDate(eventObject),
          userAttendanceSummary: this.getUserAttendanceSummary(eventObject),
          currentUserBooking: _.get(eventObject.users, this.cdApp.me.id),
          totalUsers: _.size(eventObject.users),
          totalChurches: _.size(eventObject.churches),
          totalResources: _.size(eventObject.resources),
          totalIntentions: _.size(intentions),
          userPictureWidth: 20,
          maximumAccordionItems: 5,
          organization: eventObject.organization,
        };

        this.eventTaxonomyIds = _.map(
          _.keys(eventObject.taxonomies),
          _.parseInt
        );

        this.sharesEventCommonTaxonomiesWithIntentionFees = !_.isEmpty(
          _.intersection(this.eventTaxonomyIds, this.intentionFeeTaxonomyIds)
        );

        this.viewData.canAddIntentions =
          this.hasIntentionAccess &&
          this.sharesEventCommonTaxonomiesWithIntentionFees;

        /**
         * Whether to render the sections
         */
        this.viewData.shouldShowCategories =
          this.viewData.isEvent && this.viewData.otherCategories.length > 0;
        this.viewData.shouldShowChurches =
          this.canGroupResources &&
          this.viewData.isEvent &&
          this.viewData.totalChurches > 0;
        this.viewData.shouldShowResources =
          this.viewData.isEvent && this.viewData.totalResources > 0;
        this.viewData.shouldShowUsers =
          (this.viewData.isEvent || this.viewData.isExternalEvent) &&
          this.viewData.totalUsers > 0;
        this.viewData.shouldShowIntentions =
          this.hasIntentionAccess &&
          this.viewData.isEvent &&
          this.viewData.totalIntentions > 0;
        this.viewData.shouldShowStole =
          this.hasIntentionAccess &&
          this.viewData.isEvent &&
          !_.isNil(this.viewData.stole);

        /**
         * Whether the section can be toggled (expanded/collapsed)
         */
        this.viewData.canToggleCategories =
          this.viewData.otherCategories.length >
          this.viewData.maximumAccordionItems;
        this.viewData.canToggleChurches =
          this.viewData.totalChurches > this.viewData.maximumAccordionItems;
        this.viewData.canToggleResources =
          this.viewData.totalResources > this.viewData.maximumAccordionItems;
        this.viewData.canToggleUsers =
          this.viewData.totalUsers > this.viewData.maximumAccordionItems;
        this.viewData.canToggleIntentions =
          this.viewData.totalIntentions > this.viewData.maximumAccordionItems;

        /**
         * Wether the sections are expanded
         */
        this.viewData.isCategoriesExpanded =
          this.viewData.otherCategories.length <=
          this.viewData.maximumAccordionItems;
        this.viewData.isChurchesExpanded =
          this.viewData.totalChurches <= this.viewData.maximumAccordionItems;
        this.viewData.isResourcesExpanded =
          this.viewData.totalResources <= this.viewData.maximumAccordionItems;
        this.viewData.isUsersExpanded =
          this.viewData.totalUsers <= this.viewData.maximumAccordionItems;
        this.viewData.isIntentionsExpanded =
          this.viewData.totalIntentions <= this.viewData.maximumAccordionItems;

        // Prep and Clean up time data
        this.hasPrepTime =
          moment(eventObject.startDate).diff(
            eventObject.preparationStartDate,
            'seconds'
          ) !== 0;
        this.hasCleanupTime =
          moment(eventObject.endDate).diff(
            eventObject.cleanupEndDate,
            'seconds'
          ) !== 0;
        this.prepTimeMinutes = moment(eventObject.startDate).diff(
          eventObject.preparationStartDate,
          'minutes'
        );

        this.cleanupTimeMinutes = moment(eventObject.cleanupEndDate).diff(
          eventObject.endDate,
          'minutes'
        );

        if (this.viewData.isAbsence) {
          const usersArray = this.$filter('toArray')(eventObject.users);
          this.viewData.absenceUser =
            this.$filter('setUserNames')(usersArray)[0];
        }

        this.churches = this.canGroupResources ? eventObject.churches : [];

        const parsedResources = _.map(this.event.resources, (value, key) => ({
          id: _.parseInt(key),
          ...value,
        }));

        this.groupedResources =
          this.canGroupResources && !_.isEmpty(this.event.parentResources)
            ? [...this.event.parentResources]
            : [];
        this.childResources = [];
        _.each(parsedResources, (parsedResource) => {
          if (
            !this.canGroupResources ||
            !_.isFinite(_.get(parsedResource, 'parentResourceId'))
          ) {
            this.groupedResources.push(parsedResource);
          } else {
            this.childResources.push(parsedResource);
          }
        });

        this.loading = false;
        this.repositionPopup();
      });

    const unsubscribe = this.$ngRedux.connect(this.mapStateToScope, {})(this);
    this.$scope.$on('$destroy', unsubscribe);
  }

  getPrepTimeLabel = (minutes) => {
    // Show hour
    if (minutes >= 60) {
      const numHours = getCommaFormattedNumber(minutes / 60, 1);
      return this.gettextCatalog.getPlural(
        numHours,
        '1h. preparation',
        '{{ numHours }}h. preparation',
        {
          numHours,
        }
      );
    } else {
      // Show minutes
      return this.gettextCatalog.getString('{{amount}} min. preparation', {
        amount: minutes,
      });
    }
  };

  getCleanupTimeLabel = (minutes) => {
    // Show hour
    if (minutes >= 60) {
      const numHours = getCommaFormattedNumber(minutes / 60, 1);
      return this.gettextCatalog.getPlural(
        numHours,
        '1h. clean up',
        '{{ numHours }}h. clean up',
        {
          numHours,
        }
      );
    } else {
      // Show minutes
      return this.gettextCatalog.getString('{{amount}} min. clean up', {
        amount: minutes,
      });
    }
  };

  getEventUrl() {
    if (!this.event) return null;

    const { id, type } = this.event;
    return this.$state.href(`app.private.calendar.${type}`, { id });
  }

  getEventUrlWithFormPopup() {
    if (!this.event) return null;

    const { id, type } = this.event;
    return this.$state.href(`app.private.calendar.${type}`, {
      id,
      openAddForm: true,
    });
  }

  editEvent() {
    this.closePopup();
    this.$rootScope.openCreateContentWindow(
      this.event.type,
      'edit',
      this.event
    );
  }

  getColor(color) {
    return COLOR_INDEX[color] ? COLOR_INDEX[color] : NO_COLOR;
  }

  deleteEvent() {
    this.closePopup();
    const event = angular.copy(this.event);

    this.$uibModal
      .open({
        component: 'cdEventDeleteModal',
        resolve: {
          event: () => this.event,
        },
      })
      .result.then(() => {
        this.onDelete({ event, fcEventId: this.fcEventId });
      });
  }

  copyEvent() {
    this.closePopup();
    this.$rootScope.openCreateContentWindow(
      this.event.type,
      'copy',
      this.event
    );
  }

  updateResponse(response) {
    this.Calendar.updateResponse(
      {
        eventId: this.event.id,
        response,
      },

      {}
    ).$promise.then(() => {
      this.viewData.currentUserBooking.attending = response;
      this.onUpdateResponse({
        event,
        fcEventId: this.fcEventId,
        attending: response,
      });

      this.toastr.success(
        this.gettextCatalog.getString(
          'Your attendance status for "{{event}}" has been updated.',
          {
            event: this.event.title,
          }
        )
      );
    });
  }

  closePopup() {
    this.popupApi.hide();
  }

  repositionPopup() {
    const that = this;

    setTimeout(() => {
      that.popupApi.reposition(new CustomEvent('manualReposition'), false);
    });
  }

  getUserAttendanceSummary(event) {
    const numbers = _(event.users).values().countBy('attending').value();

    const strings = _.mapValues(numbers, (value, key) => {
      switch (key) {
        case 'yes':
          return {
            order: 1,
            text: this.gettextCatalog.getString('{{ n }} going', { n: value }),
          };

        case 'maybe':
          return {
            order: 2,
            text: this.gettextCatalog.getString('{{ n }} maybe', { n: value }),
          };

        case 'no':
          return {
            order: 3,
            text: this.gettextCatalog.getString('{{ n }} not going', {
              n: value,
            }),
          };

        case 'no-answer':
          return {
            order: 4,
            text: this.gettextCatalog.getString('{{ n }} awaiting', {
              n: value,
            }),
          };
      }
    });

    return _(strings).values().orderBy('order').map('text').join(', ');
  }

  getNameOrEmail(user) {
    if (!_.isEmpty(user.name)) {
      return user.name;
    } else {
      return user.email;
    }
  }

  toggleUsers() {
    const previousValue = this.viewData.isUsersExpanded;

    if (this.viewData.canToggleUsers) {
      this.viewData.isUsersExpanded = !this.viewData.isUsersExpanded;

      if (!previousValue) {
        this.repositionPopup();
      }
    }
  }

  toggleCategories() {
    const previousValue = this.viewData.isCategoriesExpanded;

    if (this.viewData.canToggleCategories) {
      this.viewData.isCategoriesExpanded = !this.viewData.isCategoriesExpanded;

      if (!previousValue) {
        this.repositionPopup();
      }
    }
  }

  toggleChurches() {
    const previousValue = this.viewData.isChurchesExpanded;

    if (this.viewData.canToggleChurches) {
      this.viewData.isChurchesExpanded = !this.viewData.isChurchesExpanded;

      if (!previousValue) {
        this.repositionPopup();
      }
    }
  }

  toggleResources() {
    const previousValue = this.viewData.isResourcesExpanded;

    if (this.viewData.canToggleResources) {
      this.viewData.isResourcesExpanded = !this.viewData.isResourcesExpanded;

      if (!previousValue) {
        this.repositionPopup();
      }
    }
  }

  toggleIntentions() {
    const previousValue = this.viewData.isIntentionsExpanded;

    if (this.viewData.canToggleIntentions) {
      this.viewData.isIntentionsExpanded = !this.viewData.isIntentionsExpanded;

      if (!previousValue) {
        this.repositionPopup();
      }
    }
  }

  getIntentionPriority(intention) {
    const { status, priority } = intention;
    if (status === IntentionStatusTypes.COMPLETED) {
      return priority;
    } else {
      return priority === IntentionPriorityTypes.PERSOLVIERT
        ? 'erstintention'
        : 'weitere';
    }
  }

  getRotaShifts(calendarTask) {
    return _.filter(this.event.shifts, { taskId: calendarTask.taskId });
  }

  getRotas() {
    return _.orderBy(
      this.event.calendar_tasks,
      (calendar_task) => _.toLower(_.get(calendar_task, 'task.title')),
      ['asc']
    );
  }

  getRotaUsers(calendarTask) {
    const shifts = this.getRotaShifts(calendarTask);
    return _.orderBy(_.map(shifts, 'user'), ['name'], ['asc']);
  }

  getRotaHeader(calendarTask) {
    const shifts = this.getRotaShifts(calendarTask);
    const shiftsNumbers = calendarTask.required
      ? `${shifts.length} / ${calendarTask.required}`
      : shifts.length;
    return `${calendarTask.task.title} (${shiftsNumbers})`;
  }

  getResourceColorClass(resource) {
    return `color-${_.get(resource, 'color', 0)}`;
  }

  getResourceClass(resource) {
    return this.isParent(resource) ? 'text-muted' : '';
  }

  isParent(resource) {
    return this.canGroupResources && !_.isEmpty(this.getChildren(resource));
  }

  getChildren(resource) {
    return _.filter(this.childResources, {
      parentResourceId: _.get(resource, 'id'),
    });
  }

  getLocation() {
    const { appUtils } = this;
    if (this.event.locationObj) {
      return appUtils.formatGoogleLocation(this.event.locationObj);
    }
    return this.event.location;
  }

  createIntention() {
    if (!this.event) return null;

    this.$state.go('app.private.intention.create', {
      currentState: 'create',
      calendarId: this.event.id,
    });
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => {
    const intentionFees = getIntentionFees(state);
    const intentionFeeTaxonomyIds = [];
    _.each(intentionFees, (intentionFee) => {
      const intentionFeeTaxonomies = _.get(intentionFee, 'taxonomies');
      if (!_.isEmpty(intentionFeeTaxonomies)) {
        intentionFeeTaxonomyIds.push(..._.map(intentionFeeTaxonomies, 'id'));
      }
    });
    return {
      intentionFeeTaxonomyIds: _.uniq(intentionFeeTaxonomyIds),
    };
  };
}

EventPopupComponent.$inject = [
  '$q',
  '$filter',
  '$ngRedux',
  '$uibModal',
  '$rootScope',
  '$scope',
  '$window',
  'Calendar',
  'eventPopupService',
  'toastr',
  'gettextCatalog',
  'moment',
  'cdApp',
  '$state',
  'Authorization',
  'appUtils',
];

angular.module('cdApp.calendar').component('cdEventPopup', {
  templateUrl:
    '@/app/calendar/shared/components/event-popup/event-popup.component.html',
  controller: EventPopupComponent,
  bindings: {
    eventSource: '<',
    fcEventId: '<',
    targetElement: '<',
    type: '<',
    onDelete: '&',
    onUpdateResponse: '&',
  },
});
