import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import RenameNewsletterButton from '@/react/people/components/RenameNewsletterButton';
import { CdEditIcon } from '@/react/shared/components/Icons';

angular
  .module('cdApp.people')
  .component(
    'cdrRenameNewsletterButton',
    react2angular(
      sharedContext.use(RenameNewsletterButton),
      ['id', 'name', 'type', 'setup'],
      []
    )
  );

angular
  .module('cdApp.people')
  .component(
    'cdrCdEditFilterIcon',
    react2angular(sharedContext.use(CdEditIcon), [], [])
  );
