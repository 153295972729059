import React, { Suspense, lazy, useState, useEffect } from 'react';
import type { EmojiData } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { Button, Popover } from 'antd';
import { createGlobalStyle } from 'styled-components';
import type { ButtonShape, ButtonSize, ButtonType } from 'antd/es/button';

import { gettextCatalog } from '../../services/I18nService';

import { CdEmoji } from './Icons';

const Picker = lazy(() =>
  import(/* webpackChunkName: "shared-emoji" */ 'emoji-mart').then(
    ({ Picker }) => ({
      default: Picker,
    })
  )
);

const PaddingLessPopover = createGlobalStyle`
  .cd-emoji-picker .ant-popover-inner-content {
    padding: 0px;
  }
`;

export interface EmojiPickerProps {
  onSelect: (emoji: EmojiData) => void;
  buttonShape?: ButtonShape;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  showLabel?: boolean;
  children?: any;
}

const EmojiPopover = ({
  onSelect,
  title,
}: {
  onSelect: (emoji: EmojiData) => void;
  title: string;
}) => (
  <Picker
    emoji=""
    color="#008db6"
    theme="light"
    native
    title={title}
    onSelect={onSelect}
  />
);

export const EmojiPicker = ({
  onSelect,
  buttonShape,
  buttonType = 'default',
  buttonSize = 'small',
  showLabel = true,
  children,
}: EmojiPickerProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [titleEmoji, setTitleEmoji] = useState(' ');
  const handleVisibleChange = (visible: boolean) => {
    setShowEmojiPicker(visible);
  };

  useEffect(() => {
    setTimeout(() => {
      // Force picker to recalculate it's height after it renders
      // Bug: https://github.com/missive/emoji-mart/issues/191
      setTitleEmoji(showEmojiPicker ? '' : ' ');
    }, 3000);
  }, [showEmojiPicker]);

  const EmojiButton = ({ loading = false }) => (
    <Button
      size={buttonSize}
      loading={loading}
      type={buttonType}
      shape={buttonShape}
      icon={<CdEmoji />}
    >
      {showLabel ? gettextCatalog.getString('Add emoji') : null}
    </Button>
  );

  return (
    <Suspense fallback={<EmojiButton loading={true} />}>
      <Popover
        content={<EmojiPopover onSelect={onSelect} title={titleEmoji} />}
        title=""
        trigger="click"
        open={showEmojiPicker}
        onOpenChange={handleVisibleChange}
        overlayClassName="cd-emoji-picker"
      >
        <PaddingLessPopover />
        {children || <EmojiButton />}
      </Popover>
    </Suspense>
  );
};
