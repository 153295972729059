import React, { useState } from 'react';
import { Input, Modal, Spin, Row, Col, Menu } from 'antd';
import { debounce } from 'lodash';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { CalenderExistingFormListQuery } from '../../store/events/CalenderExistingFormListQuery';

import { gettextCatalog } from '@/react/services/I18nService';
import { ChurchdeskForm } from '@/react/forms/models/forms';
import StateServiceFactory from '@/react/services/StateServiceFactory';
import { CdForm, CdFormMissing } from '@/react/shared/components/Icons';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import { SignUpFormSearch } from '@/react/calendar/store/events/event';

const { Search } = Input;

const Frame = styled.iframe`
  border: 0px;
  width: 100%;
  height: 100%;
  @media all and (max-width: 768px) {
    width: 600px;
  }
`;
export type CdExistingSignUpFormComponentProps = {
  onChange: ({
    configuration,
    id,
    link,
  }: {
    configuration: any;
    id: string;
    link: string;
  }) => void;
};

export const CdExistingSignUpFormModal =
  NiceModal.create<CdExistingSignUpFormComponentProps>((props) => (
    <CdExistingSignUpFormComponent {...props} />
  ));

export const CdExistingSignUpFormComponent = ({
  onChange,
}: CdExistingSignUpFormComponentProps) => {
  const modal = useModal('CdExistingSignUpFormComponent');
  const [selectedForm, updateSelectedForm] = useState<ChurchdeskForm>();
  const [debouncedQuery, setDebouncedQuery] = useRecoilState(SignUpFormSearch);

  const debounceSearch = debounce(
    (event: any) => setDebouncedQuery(event.target.value),
    300
  );

  const availableFormsLoadable = useRecoilValueLoadable(
    CalenderExistingFormListQuery({ searchQuery: debouncedQuery })
  );

  const availableForms =
    availableFormsLoadable.state === 'hasValue'
      ? availableFormsLoadable.contents.filter(
          (form) => form.status === 'open' || form.status === 'pending'
        )
      : [];

  const navigateToForms = () => {
    const state = StateServiceFactory();
    const url = state.href('app.private.forms.default');
    window.open(url, '_blank');
  };

  const onOkHandler = React.useCallback(() => {
    const selectedFormId = selectedForm?.id;
    const selectedFormLink = selectedForm?.url;
    onChange({
      id: selectedFormId,
      configuration: undefined,
      link: selectedFormLink,
    });
    modal.hide();
  }, [onChange, selectedForm, modal]);

  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        okButtonProps: {
          disabled: !selectedForm,
        },
        maskClosable: false,
        closable: false,
        width: 800,
        height: 600,
        title: gettextCatalog.getString(`Add a sign up form to your event`),
        bodyStyle: { padding: 8 },
        onOk: onOkHandler,
      }}
    >
      <Spin spinning={availableFormsLoadable.state === 'loading'}>
        <Row>
          <Col span={8}>
            <Search
              defaultValue={debouncedQuery}
              onChange={debounceSearch}
              placeholder={gettextCatalog.getString('Search form titles...')}
              allowClear
              style={{ width: 250, marginBottom: '16px' }}
            />
            <Menu
              selectedKeys={selectedForm ? [selectedForm.id] : null}
              mode="inline"
              style={{
                maxWidth: 250,
                maxHeight: '70vh',
                overflowY: 'scroll',
              }}
            >
              {availableForms?.map((form) => (
                <Menu.Item
                  key={form.id}
                  onClick={() => updateSelectedForm(form)}
                  className={
                    form.id === selectedForm?.id ? 'previewedForm' : null
                  }
                  style={{
                    height: 'fit-content',
                    lineHeight: 1.6,
                    whiteSpace: 'break-spaces',
                    marginTop: 0,
                    marginBottom: 8,
                    padding: '8px 0 8px 16px',
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      width: '100%',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {form.title}
                  </span>
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          <Col span={16} style={{ minHeight: 500 }}>
            {availableForms.length === 0 ? (
              <div style={{ flex: 3 }}>
                <CdrEmptyState
                  title={gettextCatalog.getString('No forms found')}
                  subtitle={gettextCatalog.getString(
                    'You need to first create a form to add it to an event'
                  )}
                  buttonText={gettextCatalog.getString('Go to forms')}
                  EmptyStateIcon={<CdForm />}
                  onButtonClick={navigateToForms}
                />
              </div>
            ) : selectedForm ? (
              <div
                className="ant-card ant-card-bordered"
                style={{ height: '100%', padding: '16px 24px' }}
              >
                <Frame src={selectedForm.url + '/embed'}></Frame>
              </div>
            ) : (
              <div style={{ flex: 3 }}>
                <CdrEmptyState
                  title={gettextCatalog.getString('No form selected')}
                  subtitle={gettextCatalog.getString(
                    'Select a form on the left to add to the event'
                  )}
                  EmptyStateIcon={<CdFormMissing />}
                />
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};
