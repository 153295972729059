import moment from 'moment';
import { atom, selector } from 'recoil';

import { ApiSearchResult, mainApi } from '@/react/api';
import { PrintEvent } from '@/react/calendar/models/calendar';
import { SelectedOrganizationId } from '@/react/organization/store/organization';
import { EventSearchParamsProps } from '@/react/calendar/types/service-types';
import NotificationService from '@/react/services/NotificationService';

export const EventSearchParams = atom<EventSearchParamsProps>({
  key: 'EventSearchParams',
  default: {
    limit: 100,
    offset: 0,
    startDate: moment().toISOString(),
    endDate: moment().add(1, 'month').toISOString(),
  },
});

export const EventSearchQuery = atom<ApiSearchResult<PrintEvent>>({
  key: 'EventSearchQuery',
  default: selector({
    key: 'EventSearchQuerySelector',
    get: async ({ get }) => {
      const { filters, ...params } = get(EventSearchParams);

      const { ok, data } = await mainApi.get<{
        items: PrintEvent[];
        isMoreEvents: boolean;
        count: number;
        totalCount: number;
      }>('/collaboration/calendar-view', {
        ...params,
        'filters[0][eventsWithoutResourcesInChurchIds]':
          filters?.eventsWithoutResourcesInChurchIds,
        'filters[0][organizationId]': get(SelectedOrganizationId),
        'filters[0][categories]': filters?.categories,
        'filters[0][resources]': filters?.resources,
        'filters[0][churches]': filters?.churches,
        'filters[0][title]': filters?.title,
      });

      if (ok) {
        return { items: data.items, total: data.count };
      }
      if (!ok) {
        NotificationService.notifyError((data as any)?.message);
        throw data;
      }

      return { items: [], total: 0 };
    },
  }),
});
