import { react2angular } from 'react18-react2angular';

import { CdrUploadFiles } from '../../../../react/shared/components/CdrUploadFiles';
import sharedContext from '../shared-context-root/shared-context-root.component';

angular
  .module('cdApp.shared')
  .component(
    'cdrUploadFiles',
    react2angular(
      sharedContext.use(CdrUploadFiles),
      [
        'onUpdateFiles',
        'initialFiles',
        'modalGetContainerClassName',
        'canEdit',
      ],
      []
    )
  );
