import { Badge, Space } from 'antd';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';

import { EventCommentsQuery } from '@/react/calendar/store/events/event-comments.store';
import { gettextCatalog } from '@/react/services/I18nService';

export const CommentTabHeader = (props: { eventId: number }) => (
  <Suspense fallback={gettextCatalog.getString('Comments')}>
    <CommentTabHeaderInner {...props} />
  </Suspense>
);

export const CommentTabHeaderInner = (props: { eventId: number }) => {
  const comments = useRecoilValue(
    EventCommentsQuery({ calendarId: props.eventId })
  );
  const count = comments?.length || 0;
  return count > 0 ? (
    <Space>
      {gettextCatalog.getString('Comments')}
      <Badge count={count} color="green" />
    </Space>
  ) : (
    <>{gettextCatalog.getString('Comments')}</>
  );
};
