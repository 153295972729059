import { atom, selector } from 'recoil';

import { mainApi } from '@/react/api/index';
import { OrganizationSettings } from '@/react/organization/types/organization';
import { localStorageEffect } from '@/react/shared/utils';

// Deprecated use the hook useOrganizationSetings
export const ChurchSettingsState = selector({
  key: 'ChurchSettingsState',
  get: async () => {
    const response = await mainApi.get<OrganizationSettings>('church');
    if (response.ok) {
      return {
        displayOrder:
          localStorage.getItem('churchdesk.peopleSettings.displayOrder') ||
          'firstLast',
        ...response.data,
        newsletterColor: response.data?.newsletterColor || '#000',
      };
    }
    throw response;
  },
});

export const UseLandingPageState = selector({
  key: 'UseLandingPageState',
  get: ({ get }) => get(ChurchSettingsState)?.useLandingPage,
});

export const NewsletterListMigrationSkipped = atom({
  key: 'newsletterListMigrationSkipped',
  default: null,
  effects: [localStorageEffect('newsletterListMigrationSkipped')],
});
