import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';

import { useCreateIntention } from '../../hooks/use_create-intention';

import { gettextCatalog } from '@/react/services/I18nService';
import { paymentMethods } from '@/react/intention/shared/intentionCommon';
import { PaymentMethodTypes } from '@/react/intention/models/intention';

const { TextArea } = Input;
interface CreateIntentionFormProps {
  form: any;
  churchIds: number[];
}
export const CreateIntentionForm = ({
  form,
  churchIds,
}: CreateIntentionFormProps) => {
  const datePickerFormat = gettextCatalog.getLongDateFormat();
  const {
    churches,
    intentionFees,
    defaultIntentionFeeId,
    resourcesForSpecificChurch,
    nextReferenceNumber,
  } = useCreateIntention();
  const selectedChurch = Form.useWatch(['church'], form);
  const selectedYear = Form.useWatch(['intentionYear'], form);
  const paymentMethodsOptions = paymentMethods().map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (selectedChurch?.id && selectedYear) {
      nextReferenceNumber(
        selectedChurch?.id,
        moment(selectedYear).year().toString(),
        form
      );
    }
  }, [form, nextReferenceNumber, selectedChurch, selectedYear]);

  useEffect(() => {
    form?.setFieldValue('resource', undefined);
  }, [form, selectedChurch]);

  return (
    <Form form={form} size="middle" layout="vertical">
      <Row gutter={{ xs: 24, sm: 24, md: 64, lg: 128 }}>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={12}>
              {/* Church - Required */}
              <Form.Item
                name={['church', 'id']}
                label={gettextCatalog.getString('Parish')}
                required={true}
                initialValue={churchIds.length >= 1 ? churchIds[0] : undefined}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString('Please select a Parish'),
                  },
                ]}
              >
                <Select
                  placeholder={gettextCatalog.getString('Select a parish...')}
                  options={churches}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Resources */}
              <Form.Item
                name="resource"
                label={gettextCatalog.getString('Resource')}
                dependencies={['church']}
              >
                <Select
                  placeholder={gettextCatalog.getString('Select a resource...')}
                  options={resourcesForSpecificChurch(selectedChurch?.id)}
                  allowClear
                  disabled={
                    churches.find(
                      (church) => church.value === selectedChurch?.id
                    )?.resources === undefined || selectedChurch === undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Preferred Date */}
          <Form.Item
            name="preferredDate"
            label={gettextCatalog.getString('Preferred date')}
          >
            <DatePicker style={{ width: '100%' }} format={datePickerFormat} />
          </Form.Item>

          {/* Preferred Date Note */}
          <Form.Item
            name="preferredNote"
            label={gettextCatalog.getString('Preferred note')}
            style={{ marginTop: '48px' }}
          >
            <TextArea rows={3} />
          </Form.Item>

          {/* Intention text - required */}
          <Form.Item
            name="intentionText"
            label={gettextCatalog.getString('Intention text')}
            required={true}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString(
                  'Please write a text for the intention.'
                ),
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={12}>
              {/* Intention year - Required */}
              <Form.Item
                name="intentionYear"
                label={gettextCatalog.getString('Intention year')}
                required={true}
                initialValue={moment(new Date(), 'YYYY')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString('Please select a year'),
                  },
                ]}
              >
                <DatePicker
                  picker="year"
                  style={{ width: '100%' }}
                  format="YYYY"
                />
              </Form.Item>
              {/* Next reference number */}
              <Form.Item
                name="nextReferenceNumber"
                label={gettextCatalog.getString('Number')}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
              {/* Payment method */}
              <Form.Item
                name="paymentMethod"
                label={gettextCatalog.getString('Payment method')}
                style={{ marginBottom: 0, paddingTop: '24px' }}
                initialValue={PaymentMethodTypes.PAIDBYCASH}
              >
                <Select
                  placeholder={gettextCatalog.getString(
                    'Select a payment method'
                  )}
                  style={{ width: '100%' }}
                  options={paymentMethodsOptions}
                  allowClear
                />
              </Form.Item>
              {/* Payment check */}
              <Form.Item
                name="paid"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>{gettextCatalog.getString('Paid?')}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* Acceptance date */}
              <Form.Item
                name="acceptedAt"
                label={gettextCatalog.getString(
                  'Acceptance date',
                  null,
                  'intention'
                )}
                required={true}
                initialValue={moment(new Date(), datePickerFormat)}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={datePickerFormat}
                />
              </Form.Item>
              {/* Fee - Required */}
              <Form.Item
                name={['fee', 'id']}
                label={gettextCatalog.getString('Intention type')}
                required={true}
                initialValue={defaultIntentionFeeId}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'Please select an intention type.'
                    ),
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={gettextCatalog.getString('Select a type...')}
                  options={intentionFees}
                  allowClear
                />
              </Form.Item>
              {/* Payment date */}
              <Form.Item
                name="paidAt"
                style={{ marginBottom: 0, paddingTop: '24px' }}
                label={gettextCatalog.getString('Payment date')}
                initialValue={moment(new Date(), datePickerFormat)}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={datePickerFormat}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* Founder */}
              <Form.Item
                name="founder"
                label={gettextCatalog.getString('Founder')}
                style={{ paddingTop: '24px' }}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>

              {/* Internal Note */}
              <Form.Item
                name="comment"
                label={gettextCatalog.getString('Comment')}
                style={{ marginBottom: 0 }}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
