import { ActionCreator } from '../../redux/utils';
import { User, OldUserRoles } from '../types/User.types';

export const FETCH_USER_ROLES = 'user/FetchUserRoles';
export const FetchUserRoles: ActionCreator<{ userId: number }> = (payload) => ({
  type: FETCH_USER_ROLES,
  payload,
});

export const FETCH_USER_ROLES_SUCCESS = 'user/FetchUserRolesSuccess';
export const FetchUserRolesSuccess: ActionCreator<any> = (payload) => ({
  type: FETCH_USER_ROLES_SUCCESS,
  payload,
});

export interface SaveUserRolesPayload {
  newUser: boolean;
  name: string;
  userId: number;
  mode: 'modal' | 'inline';
  data: {
    churchRoles: {
      [roleId: string]: number[];
    };
    organizationRoles: {
      [roleId: string]: boolean;
    };
    oldRoles: OldUserRoles;
  };
}

export const SAVE_USER_ROLES = 'user/SaveUserRoles';
export const SaveUserRoles: ActionCreator<SaveUserRolesPayload> = (
  payload
) => ({
  type: SAVE_USER_ROLES,
  payload,
});

export interface SaveUserRolesSuccessPayload {
  newUser: boolean;
  name: string;
  userId: number;
}

export const SAVE_USER_ROLES_SUCCESS = 'user/SaveUserRolesSuccess';
export const SaveUserRolesSuccess: ActionCreator<SaveUserRolesSuccessPayload> =
  (payload) => ({
    type: SAVE_USER_ROLES_SUCCESS,
    payload,
  });

export const RESET_USER_ENTITY = 'user/ResetUserEntity';
export const ResetUserEntity: ActionCreator<any> = () => ({
  type: RESET_USER_ENTITY,
});

export const FETCH_USERS = 'user/FetchUsers';
export const FetchUsers: ActionCreator<undefined> = (payload) => ({
  type: FETCH_USERS,
  payload,
});

export const FETCH_USERS_SUCCESS = 'user/FetchUsersSuccess';
export const FetchUsersSuccess: ActionCreator<User[]> = (payload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const FETCH_USER_PRIVILEGES = 'user/FetchUserPrivileges';
export const FetchUserPrivilege: ActionCreator<
  {
    entityId: number | string;
    entityType: string;
    privileges: string[];
    entityAttributes: any;
  }[]
> = (payload) => ({
  type: FETCH_USER_PRIVILEGES,
  payload,
});

export const FETCH_USER_PRIVILEGES_SUCCESS = 'user/FetchUserPrivilegesSuccess';
export const FetchUserPrivilegeSuccess: ActionCreator<{
  privileges: {
    [entityType: string]: {
      [entityId: string]: {
        [privilege: string]: boolean;
      };
    };
  };
}> = (payload) => ({
  type: FETCH_USER_PRIVILEGES_SUCCESS,
  payload,
});

export const FETCH_USER_PERMISSION = 'user/FetchUserPermission';
export const FetchUserPermission: ActionCreator<{ permission: string }> = (
  payload
) => ({
  type: FETCH_USER_PERMISSION,
  payload,
});

export const FETCH_USER_PERMISSION_SUCCESS = 'user/FetchUserPermissionSuccess';
export const FetchUserPermissionSuccess: ActionCreator<{
  permission: string;
  access: boolean;
}> = (payload) => ({
  type: FETCH_USER_PERMISSION_SUCCESS,
  payload,
});

export const FETCH_USER_CHURCHES_BY_PERMISSIONS =
  'user/FetchUserChurchesByPermissions';
export const FetchUserChurchesByPermissions: ActionCreator<{
  permissionContext: string;
  permissionType: string;
}> = (payload) => ({
  type: FETCH_USER_CHURCHES_BY_PERMISSIONS,
  payload,
});

export const RESET_USER_PRIVILEGES = 'user/ResetUserPrivileges';
export const ResetUserPrivileges: ActionCreator<undefined> = () => ({
  type: RESET_USER_PRIVILEGES,
});
