(function () {
  'use strict';
  empty.$inject = ['gettextCatalog'];

  function empty(gettextCatalog) {
    return function (value, placeholder) {
      if (!value || _.isEmpty(_.trim(value))) {
        return gettextCatalog.getString(placeholder);
      }

      return value;
    };
  }

  angular.module('cdApp.shared').filter('empty', empty);
})();
