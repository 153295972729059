import React from 'react';
import { List, Pagination, Layout } from 'antd';

import ImageItem from '../image-item';
import FolderItem from '../folder-item';

interface Props {
  items: any;
  selectedFile: any;
  pageSize: number;
  count: number;
  showSizeChanger: boolean;
  loading: boolean;
  currentPage: number;
  onFileSelected: any;
  onFolderSelected?: any;
  onPageSizeChange: any;
  onPageChange: any;
}

const { Content, Footer } = Layout;

const FileList: React.FC<Props> = ({
  items,
  selectedFile,
  pageSize,
  count,
  showSizeChanger,
  loading,
  currentPage,
  onFileSelected,
  onFolderSelected,
  onPageSizeChange,
  onPageChange,
}) => {
  const renderItem = (item: any) => {
    if (item.type === 'image' || item.type === 'file') {
      return (
        <List.Item key={`col-${item.id}`}>
          <ImageItem
            key={`file-${item.id}`}
            item={item}
            onFileSelected={onFileSelected}
            selectedFile={selectedFile}
          />
        </List.Item>
      );
    } else if (item.type === 'folder') {
      return (
        <List.Item key={`col-${item.id}`}>
          <FolderItem
            key={`folder-${item.id}`}
            item={item}
            onFolderSelected={onFolderSelected}
          />
        </List.Item>
      );
    }
  };
  return (
    <Layout style={{ background: '#ffffff', padding: '0 12px' }}>
      <Content
        style={{ height: '70vh', overflowY: 'scroll', overflowX: 'hidden' }}
      >
        <List
          grid={{
            gutter: 8,
            xs: 2,
            sm: 3,
            lg: 4,
            xl: 6,
          }}
          dataSource={items}
          renderItem={renderItem}
          loading={loading}
        />
      </Content>
      <Footer style={{ padding: '12px 0 0', background: '#ffffff' }}>
        <Pagination
          current={currentPage}
          showSizeChanger={showSizeChanger}
          defaultPageSize={25}
          pageSize={pageSize}
          pageSizeOptions={['25', '50', '100']}
          total={count}
          onShowSizeChange={(current, size) => onPageSizeChange(size)}
          onChange={(page) => onPageChange(page)}
        />
      </Footer>
    </Layout>
  );
};

export default FileList;
