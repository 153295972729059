'use strict';
class ExportPeopleModalComponent {
  constructor(cdApp, AuthenticationService, _, People, toastr, $state) {
    'ngInject';

    this.cdApp = cdApp;
    this.AuthenticationService = AuthenticationService;
    this._ = _;
    this.People = People;
    this.toastr = toastr;
    this.$state = $state;

    this.options = {
      csv: {
        icon: 'fa-file-csv',
        label: 'CSV (.csv)',
      },

      xlsx: {
        icon: 'fa-file-excel',
        label: 'Microsoft Excel (.xlsx)',
      },

      ods: {
        icon: 'fa-file',
        label: 'Open Document Sheet (.ods)',
      },
    };
  }

  $onInit() {
    this.exported = false;
    this.selectedColumns = this.resolve.columns
      .map((column) => column.label)
      .join(', ');
  }

  chooseOption(fileType = 'csv') {
    const payload = {
      fileType,
      fields: this.resolve.columns.map((column) => column.property),
    };
    const filterQuery = this.resolve.filterQuery;
    const selectedPeople = this.resolve.selectedPeople;
    const isGlobalSelect = this.resolve.isGlobalSelect;

    if (isGlobalSelect) {
      payload.filter = filterQuery;
    } else {
      payload.people = _.map(selectedPeople, 'id');
    }

    this.People.exportPeople({}, payload)
      .$promise.then(() => {
        this.exported = true;
      })
      .catch((error) => {
        const errorMessage = _.get(error, 'data.message');
        this.toastr.error(
          errorMessage ||
            this.gettextCatalog.getString(
              'An error occurred and contacts could not be exported.'
            )
        );

        this.close();
      });
  }
}

ExportPeopleModalComponent.$inject = [
  'cdApp',
  'AuthenticationService',
  '_',
  'People',
  'toastr',
  '$state',
];

angular.module('cdApp.people').component('cdPeopleExportModal', {
  templateUrl:
    '@/app/people/shared/components/export-people/export-people.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: ExportPeopleModalComponent,
});
