(function () {
  'use strict';
  Folders.$inject = ['$$resource'];

  function Folders($$resource) {
    return $$resource(cdApp.config.api.main + '/folders/:id');
  }

  angular.module('cdApp.shared').factory('Folders', Folders);
})();
