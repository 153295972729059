import angular, { IComponentOptions, IController, IScope } from 'angular';
import NgRedux from 'ng-redux';

import { calendarSettingsSelectors } from '../../../react/calendar/store/calendar-settings/calendarSettingsSlice';
import { AppState } from '../../../react/redux';

class CalendarSwitchScreen implements IController {
  public constructor($ngRedux: NgRedux.INgRedux, $scope: IScope) {
    const unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
    $scope.$on('$destroy', unsubscribe);
  }
  public mapStateToThis(state: AppState): { isNewCalendar: boolean } {
    return {
      isNewCalendar: calendarSettingsSelectors.isNewCalendar(state),
    };
  }
}

CalendarSwitchScreen.$inject = ['$ngRedux', '$scope'];
const options: IComponentOptions = {
  template: require('./calendar-switch-screen.html'),
  controller: CalendarSwitchScreen,
};

angular.module('cdApp.calendar').component('calendarSwitchScreen', options);
