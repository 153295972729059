'use strict';

import * as _ from 'lodash';

import { isLoading } from '../../../../react/shared/loading/redux/Selectors';
import { IntentionReportTemplateTypes } from '../../../../react/settings/models/report-template';
import { getIntentionReportTemplate } from '../../../../react/settings/redux/intention-report-templates/Selectors';
import {
  clearIntentionReportTemplate,
  fetchIntentionReportTemplate,
} from '../../../../react/settings/redux/intention-report-templates/Actions';

class CreateReportTemplateComponent {
  constructor(
    gettextCatalog,
    toastr,
    Upload,
    $scope,
    $state,
    $ngRedux,
    $stateParams
  ) {
    'ngInject';

    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.$state = $state;
    this.Upload = Upload;
    this.id = $stateParams.id;
    this.type = $stateParams.type;
    this.currentState = $stateParams.currentState;
    let unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  // Lifecycle functions

  $onInit() {
    this.isLoading = false;

    if (this.currentState !== 'create' && this.id) {
      this.fetchIntentionReportTemplate(this.id);
    } else {
      this.clearIntentionReportTemplate();
      this.reportTemplate = {
        type: IntentionReportTemplateTypes.GOTTESDIENSTORDNUNG,
      };
    }

    // Start drop-down components data
    this.reportTemplateTypes = [
      {
        value: IntentionReportTemplateTypes.GOTTESDIENSTORDNUNG,
        label: 'Gottesdienstordnung',
        selected: true,
      },
    ];
  }

  // Main controller functions

  cancel() {
    this.$state.go('app.private.settings.reportTemplates.overview');
  }

  save() {
    const { currentState, reportTemplate, gettextCatalog, toastr } = this;
    // Validations
    if (_.isEmpty(reportTemplate.type)) {
      return toastr.error(
        gettextCatalog.getString('Please choose a report template type.')
      );
    }
    if (!reportTemplate.name) {
      return toastr.error(gettextCatalog.getString('Please choose a name.'));
    }
    // Initialize associated entities and save the report template
    this.isUploading = true;

    let method;
    const data = {
      type: reportTemplate.type,
      name: reportTemplate.name,
      description: reportTemplate.description,
      fromGallery: false,
      file: reportTemplate.file,
    };

    if (currentState === 'create') {
      // Create
      method = 'POST';
      // this.createIntentionReportTemplate(createPayload);
    } else {
      // Update through edit
      method = 'PUT';
      // this.updateIntentionReportTemplate({ id: reportTemplate.id, updatePayload });
    }

    this.Upload.upload({
      url: `${cdApp.config.api.main}/intention/report-templates`,
      method,
      data,
      params: { organizationId: cdApp.organization.id },
    })
      .then(
        () => {
          this.$state.go('app.private.settings.reportTemplates.overview');
        },
        (failureResponse) => {
          toastr.error(_.get(failureResponse, 'data.message'));
        }
      )
      .catch((err) => {
        toastr.error(_.get(err, 'message'));
      })
      .finally(() => {
        this.isUploading = false;
      });
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => {
    const mappedState = {
      isLoading: isLoading(state),
    };

    if (this.currentState !== 'create') {
      const reportTemplate = getIntentionReportTemplate(state);
      mappedState.reportTemplate =
        reportTemplate && reportTemplate.asMutable({ deep: true });
    }
    return mappedState;
  };

  mapDispatchToScope = (dispatch) => ({
    clearIntentionReportTemplate: () =>
      dispatch(clearIntentionReportTemplate()),
    // createIntentionReportTemplate: reportTemplate => dispatch(createIntentionReportTemplate(reportTemplate)),
    fetchIntentionReportTemplate: (id) =>
      dispatch(fetchIntentionReportTemplate(id)),
    // updateIntentionReportTemplate: ({ id, updatePayload }) => dispatch(updateIntentionReportTemplate({ id, updatePayload }))
  });
}
CreateReportTemplateComponent.$inject = [
  'gettextCatalog',
  'toastr',
  'Upload',
  '$scope',
  '$state',
  '$ngRedux',
  '$stateParams',
];

angular.module('cdApp.settings').component('cdCreateReportTemplate', {
  template: require('./create-report-templates.component.html'),
  controller: CreateReportTemplateComponent,
  bindings: {
    reportTemplateData: '<',
    currentState: '<',
  },
});
