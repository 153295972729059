import React, { Suspense } from 'react';
import { Modal, Spin, Typography } from 'antd';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import { DefaultConsentTypeName } from '../store/newsletterListState';
import useConsentStatement from '../hooks/useConsentStatement';

import { StatementType } from './ConsentCreateStatement';

import { CdWarningIcon, CdUploadIcon } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const { Paragraph, Title } = Typography;

const Container = styled.main`
  padding: 16px 16px 0px;
  display: flex;
  gap: 0 0px;
`;
interface ConfirmDefaultConsentCreationOnContactsProps
  extends Record<string, unknown> {
  statementType: StatementType;
  body: {
    label: string;
    body?: string;
    link?: string;
    defaultConsent: boolean;
  };
  id?: string;
}

export const ConfirmDefaultConsentCreationOnContactsModal = NiceModal.create(
  ({
    statementType,
    body,
    id,
  }: ConfirmDefaultConsentCreationOnContactsProps) => (
    <Suspense fallback={<CdUploadIcon />}>
      <ConfirmDefaultConsentCreationOnContacts
        statementType={statementType}
        body={body}
        id={id}
      />
    </Suspense>
  )
);

export const ConfirmDefaultConsentCreationOnContacts = ({
  statementType,
  body,
  id,
}: ConfirmDefaultConsentCreationOnContactsProps) => {
  const modal = useModal('ConfirmDefaultConsentCreationOnContacts');

  const { createStatement, editStatement } = useConsentStatement();
  const defaultConsntLoadable = useRecoilValueLoadable(DefaultConsentTypeName);
  const defaultConsentlabel =
    defaultConsntLoadable.state === 'hasValue'
      ? defaultConsntLoadable.contents.name
      : '';
  const numberOfContacts =
    defaultConsntLoadable.state === 'hasValue'
      ? defaultConsntLoadable.contents.numberOfContacts
      : 0;

  const isLoading = defaultConsntLoadable.state === 'loading';

  const onFinish = async () => {
    if (statementType === StatementType.Create) {
      await createStatement(body);
    } else {
      await editStatement(id, body);
    }

    modal.remove();
  };
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        maskClosable: false,
        onCancel: modal.remove,
        okText: gettextCatalog.getString('Proceed'),
        onOk: onFinish,
      }}
    >
      <Spin spinning={isLoading}>
        <Container className="ant-modal-confirm-body">
          <CdWarningIcon size="2x" type="warning" />
          <article>
            <Title level={5} style={{ marginTop: '0px' }}>
              {gettextCatalog.getString(
                'You have created a new default consent'
              )}
            </Title>
            <Paragraph>
              {gettextCatalog.getString(
                'This default consent replaces your old default consent "I agree to {{consentSummary}}".',
                { consentSummary: defaultConsentlabel }
              )}
            </Paragraph>
            <Paragraph>
              {gettextCatalog.getPlural(
                numberOfContacts,
                'It also means 1 current contact that has accepted your old default consent will now be registered as having accepted this new consent.',
                'It also means all "{{ $count | number }}" current contacts that have accepted your old default consent will now be registered as having accepted this new consent.',
                {
                  $count: numberOfContacts,
                }
              )}
            </Paragraph>
          </article>
        </Container>
      </Spin>
    </Modal>
  );
};
