import type { SagaIterator } from '@redux-saga/types';
import * as _ from 'lodash';
import { call, fork, put, select } from 'redux-saga/effects';

import { getHolidays } from '../calendar/sagas/CalendarControlSagas';
import {
  calendarSettingsActions,
  calendarSettingsSelectors,
} from '../calendar/store/calendar-settings/calendarSettingsSlice';
import { CdApp } from '../config';
import {
  MainDataRequestSuccess,
  SetConfigAction,
} from '../config/store/Actions';
import { Portal, OrganizationPortals } from '../portal/types/portal.type';
import PortalService from '../portal/services/portal.service';
import { InitialStateLoaded } from '../redux/startup-redux/Actions';
import AuthenticationService from '../services/AuthenticationService';
import AuthorizationService from '../services/AuthorizationService';
import FeatureToggleService from '../services/FeatureToggleService';
import { IntentionFeeTypes } from '../settings/models/fee';
import {
  fetchIntentionFeesSuccess,
  fetchStolesFeesSuccess,
} from '../settings/redux/intention-fees/Actions';
import IntentionFeeService, {
  IntentionFeesData,
} from '../settings/services/IntentionFeeService';
import { AuthData } from '../shared/models/authentication';
import { Resource } from '../shared/models/resource';
import ResourceService from '../shared/services/ResourceService';
import { fetchResourcesSuccess } from '../shared/store/resources';
import LoadTaxonomies from '../taxonomies/saga';
import { fetchPortalSuccessAction } from '../portal/redux';

declare global {
  interface Window {
    cdApp: CdApp;
    cdUseLandingPage: boolean;
    cdSiteDomain: string;
  }
}
export function* startup(): SagaIterator {
  yield put(SetConfigAction(window.cdApp));
  yield call(getAuth);

  // Only run for logged in users:
  if (!window.location.pathname.startsWith('/public/')) {
    yield fork(GetCalendarViewSetting);
    yield fork(InitializeShared);
    yield fork(InitializeIntention);
    yield fork(InitializePortal);
  }
}

export function* getAuth(): SagaIterator {
  try {
    const authData: AuthData = yield call(AuthenticationService.getCurrentUser);
    yield put(MainDataRequestSuccess(authData));
  } catch {}
}

function* GetCalendarViewSetting(): SagaIterator {
  try {
    // After having retrieved the user, check what feature flags will be activated
    const currentView = calendarSettingsSelectors.isNewCalendar(yield select());

    const defaultNewCalendar = yield call(
      FeatureToggleService.hasFeature,
      'default_new_calendar'
    );
    const calendarSwitchAllowed = yield call(
      FeatureToggleService.hasFeature,
      'calendar_switch_allowed'
    );

    const showNewCalendar = defaultNewCalendar ? true : currentView;

    yield fork(getHolidays);

    yield put(
      calendarSettingsActions.setCalendarSettings({
        defaultCalendar: showNewCalendar,
        calendarSwitchAllowed,
      })
    );
    yield call(LoadTaxonomies);
  } catch {}
}

function* InitializeShared(): SagaIterator {
  try {
    const resourcesData: Resource[] = yield call(ResourceService.getResources);
    if (resourcesData) {
      yield put(fetchResourcesSuccess(resourcesData));
    }
    yield put(InitialStateLoaded());
  } catch {}
}

function* InitializeIntention(): SagaIterator {
  try {
    const organization = _.get(window, 'cdApp.organization');
    if (!organization || !AuthorizationService.hasPackage('intentions')) return;
    // Fetch intention fees
    const intentionFeeData: IntentionFeesData = yield call(
      IntentionFeeService.getIntentionFees,
      {
        type: IntentionFeeTypes.INTENTIONEN,
      }
    );
    if (intentionFeeData) {
      yield put(fetchIntentionFeesSuccess(intentionFeeData));
    }

    // Fetch Stoles Fees
    const stolesFeeData: IntentionFeesData = yield call(
      IntentionFeeService.getIntentionFees,
      {
        type: IntentionFeeTypes.STOLARIEN,
      }
    );

    if (stolesFeeData) {
      yield put(fetchStolesFeesSuccess(stolesFeeData));
    }
    yield put(InitialStateLoaded());
  } catch {}
}

function* InitializePortal(): SagaIterator {
  try {
    const organization = _.get(window, 'cdApp.organization');

    // Fetch Portal
    const organizationPortals: OrganizationPortals = yield call(
      PortalService.getOrganizationPortals,
      {
        organizationId: organization.id,
      }
    );

    // Select the portal the the organization is a host
    const portal: Portal = _.head(
      _.filter(organizationPortals.portals, (_portal) =>
        _.find(_portal.portalOrganizations, { isHost: true })
      )
    ) as Portal;

    if (portal) {
      yield put(fetchPortalSuccessAction(portal));
    }
    yield put(InitialStateLoaded());
  } catch {}
}
