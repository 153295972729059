'use strict';

class PeopleSmsUserPhoneModal {
  constructor(_, Users) {
    'ngInject';

    this._ = _;
    this.Users = Users;
  }

  $onInit() {
    this.user = this.resolve.user;

    if (this._.isEmpty(this.user.phones)) {
      this.user.phones = [{ default: 1 }];
    }
  }

  updateUser() {
    const phoneInput = angular.element('#user-mobile-phone');
    const countryId = phoneInput.intlTelInput('getSelectedCountryData').iso2;

    const phones = this._.map(this.user.phones, (phone) => {
      delete phone.id;
      delete phone.updatedAt;

      if (phone.default) {
        phone.countryId = countryId;
      }
      return phone;
    });

    new this.Users({ id: this.user.id, phones }).$save((res) => {
      this.close({ $value: res.phones });
    });
  }
}

PeopleSmsUserPhoneModal.$inject = ['_', 'Users'];

angular.module('cdApp.shared').component('cdUpdateUserPhoneModal', {
  templateUrl:
    '@/app/shared/components/update-user-phone/update-user-phone.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: PeopleSmsUserPhoneModal,
});
