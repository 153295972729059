'use strict';

angular.module('cdApp.shared').factory('rruleFactory', [
  '_',
  'moment',
  'RRule',
  (_, moment, RRule) => {
    'ngInject';

    const rruleFactory = {
      getRRuleFromString(rrule) {
        const regex = /RRULE:([^&]*)/;
        const parsableRrule = rrule.match(regex);

        // Build the recurrence rule so we can parse it to human readable text
        return new RRule(RRule.parseString(parsableRrule[1]));
      },

      /**
       * Get the series of events from a repeat rule, counting in the excluded and included dates
       *
       * @returns {Array} An array of dates
       */
      getEventsInSeries(rrule, startDate) {
        // default startDate to now if not provided
        if (!startDate) {
          startDate = new Date();
        } else if (!moment.isDate(startDate)) {
          startDate = new Date(startDate);
        }

        let eventRepeatRuleString = rrule;

        let hasExcludedDates = eventRepeatRuleString.indexOf('&EXDATE:') !== -1;
        let hasIncludedDates = eventRepeatRuleString.indexOf('&RDATE:') !== -1;

        // Trimming the string 'RRULE:'
        if (eventRepeatRuleString.indexOf('RRULE:') !== -1) {
          eventRepeatRuleString = eventRepeatRuleString.replace('RRULE:', '');
        }
        // Extract the repeat rule itself without the added excluded/included strings
        if (eventRepeatRuleString.indexOf('&') > 0) {
          eventRepeatRuleString = eventRepeatRuleString.substring(
            0,
            eventRepeatRuleString.indexOf('&')
          );
        }

        // re-create the original repeat rule
        let eventRepeatRuleObject = RRule.parseString(eventRepeatRuleString);
        let eventRepeatRule = new RRule({
          dtstart: startDate,
          freq: eventRepeatRuleObject.freq,
          interval: eventRepeatRuleObject.interval
            ? eventRepeatRuleObject.interval
            : 1,
          count: !eventRepeatRuleObject.until
            ? eventRepeatRuleObject.count
              ? eventRepeatRuleObject.count
              : 30
            : null,
          until: eventRepeatRuleObject.until
            ? moment(eventRepeatRuleObject.until).endOf('day').toDate()
            : null,
        });

        let eventsInSeries = eventRepeatRule.all();

        if (hasIncludedDates) {
          // Trimming the string '&RDATE:'
          let includedDates = rrule.substring(rrule.indexOf('&RDATE:') + 7);
          if (includedDates.indexOf('&') !== -1) {
            includedDates = includedDates.substring(
              0,
              includedDates.indexOf('&')
            );
          }

          // include dates if they don't exist in the events series already
          if (includedDates) {
            _.forEach(includedDates.split(','), function (includedDate) {
              if (
                !_.some(eventsInSeries, function (eventDate) {
                  return moment(eventDate)
                    .endOf('day')
                    .isSame(
                      moment(
                        rruleFactory.repeatStringToDate(includedDate)
                      ).endOf('day')
                    );
                })
              ) {
                eventsInSeries.push(
                  rruleFactory.repeatStringToDate(includedDate)
                );
              }
            });
          }
        }

        if (hasExcludedDates) {
          // Trimming the string '&EXDATE:'
          let excludedDates = rrule.substring(rrule.indexOf('&EXDATE:') + 8);
          if (excludedDates.indexOf('&') !== -1) {
            excludedDates = excludedDates.substring(
              0,
              excludedDates.indexOf('&')
            );
          }

          // exclude dates
          if (excludedDates) {
            _.forEach(excludedDates.split(','), function (excludedDate) {
              _.remove(eventsInSeries, function (eventDate) {
                return moment(eventDate)
                  .endOf('day')
                  .isSame(
                    moment(rruleFactory.repeatStringToDate(excludedDate)).endOf(
                      'day'
                    )
                  );
              });
            });
          }
        }

        eventsInSeries = _.sortBy(eventsInSeries);
        return eventsInSeries;
      },

      /**
       * Parse a string in the repeat expression into a Date object
       *
       * @returns {Date}
       */
      repeatStringToDate(date) {
        let re = /^(\d{4})(\d{2})(\d{2})(T(\d{2})(\d{2})(\d{2})Z)?$/;
        let bits = re.exec(date);
        if (!bits) {
          throw new Error('Invalid DATE value: ' + date);
        }
        return new Date(
          Date.UTC(
            bits[1],
            bits[2] - 1,
            bits[3],
            bits[5] || 0,
            bits[6] || 0,
            bits[7] || 0
          )
        );
      },

      /**
       * Get excluded and included dates from rrule.
       */
      extractExcludesIncludes(rrule) {
        const excludedRegex = /EXDATE:([^&]*)/;
        const includedRegex = /RDATE:([^&]*)/;

        const extractValue = (regex, attributeKey) => {
          const fullAttribute = _.first(rrule.match(regex));
          const attributeValue = _.nth(_.split(fullAttribute, attributeKey), 1);
          return _.map(_.compact(_.split(attributeValue, ',')), (dateString) =>
            moment(dateString).toDate()
          );
        };

        return {
          excluded: extractValue(excludedRegex, 'EXDATE:'),
          included: extractValue(includedRegex, 'RDATE:'),
        };
      },
    };

    return rruleFactory;
  },
]);
