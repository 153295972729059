import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ParticipatingInComponenet from '@/react/people/components/ParticipatingInComponent';

angular
  .module('cdApp.shared')
  .component(
    'cdrParticipatingIn',
    react2angular(
      sharedContext.use(ParticipatingInComponenet),
      ['participantLists', 'updateParticipatingIn', 'access'],
      []
    )
  );
