export const createNewPayload = (values: any) => {
  const taskTitle = values?.tasks?.[0]?.title;
  const tasks = values.hasTask ? [{ title: taskTitle }] : [];
  return {
    ...values,
    task: values.hasTask ? taskTitle : null,
    tasks,
    type: values.type ? 'priests' : null,
    absenceDisabled: !values.absenceDisabled,
  };
};
export const createEditPayload = (values: any, initialValue: any) => {
  const firstTask = initialValue?.tasks?.[0] || {};
  const taskTitle = values.hasTask ? values?.tasks?.[0]?.title : null;
  const tasks = values.hasTask ? [{ ...firstTask, title: taskTitle }] : [];

  return {
    ...initialValue,
    ...values,
    task: taskTitle,
    tasks,
    type: values?.type ? 'priests' : null,
    absenceDisabled: !values.absenceDisabled,
    hasTask: values?.id ? values?.hasTask : values.hasTask,
  };
};
