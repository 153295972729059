import React, { ReactNode } from 'react';
import { Col, Row, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

export interface CdSelectProps extends SelectProps {
  placeholder: string;
  addonAfter?: ReactNode;
}

export default function CdSelect({
  allowClear = true,
  showSearch = true,
  addonAfter,
  ...rest
}: CdSelectProps) {
  return (
    <Row wrap={false} gutter={4} align="middle">
      <Col flex="auto">
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          filterOption={(input: string, option: any) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(a: DefaultOptionType, b: DefaultOptionType) =>
            ((a?.label as string) ?? '')
              .toLowerCase()
              .localeCompare(((b?.label as string) ?? '').toLowerCase())
          }
          {...rest}
        />
      </Col>
      {addonAfter && (
        <Col style={{ alignSelf: 'stretch', height: 'auto' }}>{addonAfter}</Col>
      )}
    </Row>
  );
}
