(function () {
  'use strict';

  angular.module('cdApp.shared').directive('selectOnClick', function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.on('click', function () {
          this.select();
        });
        element.on('focus', function () {
          this.select();
        });
      },
    };
  });
})();
