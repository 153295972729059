import { gettextCatalog } from '../../services/I18nService';

export enum fileUploadTypes {
  CERTIFICATE_OF_CONDUCT = 'certificateOfConduct',
  DOCUMENT = 'document',
  COURSE = 'course',
}
export const safeguardingRoles = () => [
  { value: 'employee', label: gettextCatalog.getString('Employee') },
  { value: 'volunteer', label: gettextCatalog.getString('Volunteer') },
];

export interface ISafeguardingLevelCheckType {
  value: string;
  heading: string;
  description?: string;
}

export const safeguardingLevelChecks = (): ISafeguardingLevelCheckType[] => [
  {
    value: 'not required',
    heading: gettextCatalog.getString('Not required'),
    description: gettextCatalog.getString(
      'There are no safeguarding requirements to this person and the role they perform.'
    ),
  },
  {
    value: 'verified by third party',
    heading: gettextCatalog.getString('Verified by third party'),
    description: gettextCatalog.getString(
      'The safeguarding requirements are already carried out by a trusted external organization, independent of ChurchDesk.'
    ),
  },
  {
    value: 'minimum',
    heading: gettextCatalog.getString('Minimum'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement and code of conduct.'
    ),
  },
  {
    value: 'basic',
    heading: gettextCatalog.getString('Basic'),
    description: gettextCatalog.getString(
      'For basic both the self-disclosure statement and code of conduct documents are required along with attending a basic introductory course to safeguarding.'
    ),
  },
  {
    value: 'basic plus',
    heading: gettextCatalog.getString('Basic plus'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement, code of conduct and the certificate of conduct for review. Furthermore an introduction course is necessary'
    ),
  },
  {
    value: 'extended',
    heading: gettextCatalog.getString('Extended'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement and Code of conduct as well as taking an extended course'
    ),
  },
  {
    value: 'extended plus',
    heading: gettextCatalog.getString('Extended plus'),
    description: gettextCatalog.getString(
      'Requires providing the self-disclosure statement, code of conduct and the certificate of conduct for review. Furthermore an extended course is necessary'
    ),
  },
];

export const safeguardingCourses = () => [
  {
    value: 'basic',
    heading: gettextCatalog.getString('Basic course'),
    description: gettextCatalog.getString('Includes related refresher course'),
  },
  {
    value: 'extended',
    heading: gettextCatalog.getString('Extended course'),
    description: gettextCatalog.getString('Includes related refresher course'),
  },
];

export const safeguardingTypeDropDown = () => [
  { value: 'all', label: gettextCatalog.getString('All') },
  { value: 'missing', label: gettextCatalog.getString('Missing') },
  { value: 'soonToExpire', label: gettextCatalog.getString('Soon to expire') },
];
export const safeguardingUserStatus = () => [
  { value: 'active', label: gettextCatalog.getString('Active') },
  { value: 'blocked', label: gettextCatalog.getString('Blocked') },
  { value: 'createdWithoutLogin', label: gettextCatalog.getString('Created') }, // createdWithoutLogin
];
export const documentTypes = () => [
  {
    value: 'selfDisclosure',
    label: gettextCatalog.getString('Self-disclosure statement'),
  },
  {
    value: 'codeOfConduct',
    label: gettextCatalog.getString('Code of conduct'),
  },
  {
    value: 'combinedDeclaration',
    label: gettextCatalog.getString('Combined declaration'),
  },
];
export const documentWhereaboutsTypes = () => [
  { value: 'returned', label: gettextCatalog.getString('Returned') },
  { value: 'destroyed', label: gettextCatalog.getString('Destroyed') },
];

export interface fileInterface {
  id: string;
  url: string;
  title: string;
  response?: {
    uuid: string;
  };
}
export interface documentInterface {
  id: number;
  documentType: string;
  reviewDate: Date | string;
  reviewedBy: string;
  file?: fileInterface[];
}
export interface coursesInterface {
  id?: number;
  courseTitle: string;
  courseType?: string;
  courseDate?: string;
  reviewDate: Date | string;
  reviewedBy: string;
  files?: fileInterface[];
  refreshDate?: Date | string;
  refreshRequired?: boolean;
}
export interface certificateOfConductInterface {
  id?: number;
  reviewedBy: string;
  reviewDate: string;
  followupDate: string;
  documentWhereabouts?: string;
}
export interface safeguardingInformationInterface {
  type: string; // employee, volunteer
  workAndResponsibilities: string;
  parishIds: number[];
  safeguardRequirement: string; // not_required, basic, extended
}
export interface SafeGuardingInfoPayload {
  type: string;
  workAndResponsibilities: string;
  parishIds: number[];
  safeguardRequirement: string;
  street?: string;
  city?: string;
  postCode?: string;
  countryIso2?: string;
}
export interface MissingDocumentInterface {
  codeOfConduct: boolean;
  selfDisclosure: boolean;
}
export interface MissingCourseInterface {
  basic: boolean;
  extended: boolean;
}
export interface missingDocumentInterface {
  certificateOfConduct: boolean;
  courses: MissingCourseInterface;
  documents: MissingDocumentInterface;
}
export interface SafeguardingListInterface {
  userId: number;
  fullName: string;
  email: string;
  certificateOfConduct?: certificateOfConductInterface;
  course: coursesInterface;
  requirement: string;
  missing: missingDocumentInterface;
}
export interface SafeguardingListQueryInterface {
  canEdit: boolean;
  items: SafeguardingListInterface[];
  cursor: string;
}
export interface AddressInterface {
  street?: string;
  city?: string;
  postCode?: string;
  countryIso2?: string;
}
export interface SafeguardingDetailsInterface {
  userId: number;
  fullName: string;
  email: string;
  birthdayDate: string;
  pictureUri: string;
  highestValidatedLevelInChurchDesk: string;
  safeguardInfo: safeguardingInformationInterface;
  certificatesOfConduct: certificateOfConductInterface[];
  courses: coursesInterface[];
  documents: documentInterface[];
  address?: AddressInterface;
  groups: { id: number; name: string }[];
  churches: { id: number; name: string }[];
}
export interface RecordInterface {
  type: string;
  reviewedBy: number | string;
  thirdPartyReviewName?: string;
  reviewDate: moment.Moment;
  // when type is document
  documentType?: string;
  file?: fileInterface[];
  // when type is certificateOfConduct
  followupDate?: moment.Moment;
  documentWhereabouts?: string;
  comments?: string;
  // when file type is course
  courseTitle?: string;
  courseType?: string;
  courseDuration?: string;
  courseDate?: moment.Moment;
  refreshRequired?: boolean;
  refreshDate?: moment.Moment;
}

export interface UserUploadData {
  city?: string;
  country?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  parishIds?: string;
  postCode?: string;
  safeguardRequirement?: string;
  status?: string;
  street?: string;
  type?: string;
  workAndResponsibilities?: string;
  errors?: string[];
}

export interface QueuedUserUpdate {
  data: UserUploadData;
  id?: number;
  errors: string[];
  addressUpdateStatus?: string;
  state?: 'success' | 'failed';
}

export interface SafeguardingUserProfile {
  firstName: string;
  lastName: string;
  email: string;
  status?: string;

  // safeguarding fields
  safeguardRequirement?: string;
  type?: string;
  workAndResponsibilities?: string;
  parishIds?: string;

  // address fields
  street?: string;
  city?: string;
  postCode?: string;
  country?: string;
}

export interface SafeguardingUserUploadPayload {
  firstName?: string;
  lastName?: string;
  email: string;
  status?: string;

  // safeguarding fields
  safeguarding?: {
    safeguardRequirement: string;
    type: string;
    workAndResponsibilities: string;
    parishIds: string;
  };

  address?: AddressInterface;
}
export interface SafeguardingUserUploadResponse {
  message: string;
  address: AddressInterface;
  contactId: number;
  email: string;
  id: number;
  initialTimeBalance: number;
  timeRegistrationEnabled: boolean;
}

export type ErrorInfo = {
  message: string;
  level: 'info' | 'warning' | 'error';
};
