import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import IntentionFees from '@/react/settings/pages/intention-fees/IntentionFees';

angular
  .module('cdApp.settings')
  .component(
    'cdrIntentionFees',
    react2angular(sharedContext.use(IntentionFees), [], [])
  );
