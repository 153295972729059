import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { FilterType, SelectedCalendar } from '../store/filters';
import { getSelectedCalendars } from '../store/filters/Selectors';

import CalendarAutoComplete from './CalendarAutoComplete';
import FilterItem from './FilterItem';
import FilterList from './FilterList';

const CalendarsSelect: FunctionComponent = () => {
  const selectedCalendars = useSelector(getSelectedCalendars, _.isEqual);

  return (
    <>
      <FilterList>
        {selectedCalendars.map((item: SelectedCalendar) => (
          <FilterItem
            key={`${item.filterType}-${item.id}`}
            item={item}
            feedType={
              item.filterType === FilterType.users ? 'user' : 'resource'
            }
          />
        ))}
      </FilterList>
      <div style={{ margin: '8px 14px 0px' }}>
        <CalendarAutoComplete />
      </div>
    </>
  );
};

export default CalendarsSelect;
