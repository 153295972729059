'use strict';

import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { PaymentsSettingsPage } from '@/react/settings/components/PaymentsSettingsPage';

angular
  .module('cdApp.settings')
  .component(
    'cdPaymentPage',
    react2angular(sharedContext.use(PaymentsSettingsPage), [], [])
  );
