import { ON_ROTAS_ASSIGNEES_CHANGED } from '../../../shared/components/shifts-list/shifts-list.component';

class ManageShiftsController {
  constructor(
    $scope,
    $stateParams,
    gettextCatalog,
    $uibModal,
    cdApp,
    $q,
    $http
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
    this.cdApp = cdApp;
    this.$http = $http;
    this.$q = $q;
  }

  $onInit() {
    const { $stateParams } = this;

    // Retrieve model values from the URL's query parameters
    this.queryParameters = angular.copy($stateParams);
  }

  uiOnParamsChanged(newParams, $transitions) {
    // Communicate with child components cd-shift-list
    this.queryParameters = $transitions.params();
  }

  /**
   * When the user assigned or unassigns people from rotas, both table need to update.
   * In case the user takes a shift from the `Open Rotas` table, it should show up in the `My Rotas` table.
   */
  onAssigneesChanged() {
    const { $scope } = this;
    $scope.$broadcast(ON_ROTAS_ASSIGNEES_CHANGED);
  }

  openSubscribeIcsModal() {
    const { $uibModal, cdApp, gettextCatalog, $q, $http } = this;
    $uibModal.open({
      component: 'cdCalendarFeedModal',
      resolve: {
        feedName: () => gettextCatalog.getString('Add to personal calendar'),
        feedId: () => cdApp.me.id,
        feedType: () => 'user',
        showPublicFeed: () => false,
        token: () =>
          $q((resolve, reject) => {
            $http
              .get(`${cdApp.config.api.main}/ical/token`)
              .then((res) => resolve(res.data))
              .catch(reject);
          }),
      },
    });
  }
}
ManageShiftsController.$inject = [
  '$scope',
  '$stateParams',
  'gettextCatalog',
  '$uibModal',
  'cdApp',
  '$q',
  '$http',
];

angular.module('cdApp.intranet').component('cdManageShiftsState', {
  templateUrl:
    '@/app/intranet/shifts/manage-shifts/manage-shifts.component.html',
  controller: ManageShiftsController,
});
