import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { showCreateNewsletterListModal } from './CreateNewsletterList';

import {
  CdNewsletterListIcon,
  CdParticipantList,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const DropdownLink = styled.a`
  &:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
`;

export const LaunchCreateNewsletterAndParticipantListModal = ({
  refreshSegments,
}) => {
  const showCreateParticipantListModal = () =>
    NiceModal.show('CreateParticipantList', {}).then(() => refreshSegments());

  return (
    <>
      <li>
        <DropdownLink
          onClick={() => showCreateNewsletterListModal({ refreshSegments })}
        >
          <Space>
            <CdNewsletterListIcon fixedWidth />
            {gettextCatalog.getString('Create newsletter list')}
          </Space>
        </DropdownLink>
      </li>
      <li>
        <DropdownLink onClick={showCreateParticipantListModal}>
          <Space>
            <CdParticipantList fixedWidth />
            {gettextCatalog.getString('Create participant list')}
          </Space>
        </DropdownLink>
      </li>
    </>
  );
};
