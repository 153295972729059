import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { Form, Input } from 'antd';

import useTag from '../hooks/useTag';

import CdModal from '@/react/shared/components/cd-modal/CdModal';
import { gettextCatalog } from '@/react/services/I18nService';

const Container = styled.div`
  margin: 24px 32px 16px;
`;
export interface CreateTagProps extends Record<string, unknown> {
  setNewTag: React.Dispatch<React.SetStateAction<number>>;
}
const CreateTagModal = NiceModal.create(({ setNewTag }: CreateTagProps) => (
  <CreateTag setNewTag={setNewTag} />
));
const CreateTag = ({ setNewTag }) => {
  const modal = useModal('CreateTag');
  const [form] = Form.useForm();
  const { createTag } = useTag();
  const handleCreate = async () => {
    const tagId = await createTag(form.getFieldValue('name'));
    setNewTag(tagId);
  };

  return (
    <CdModal
      title={gettextCatalog.getString('Create tag')}
      okText={gettextCatalog.getString('Create')}
      onOk={handleCreate}
      modal={modal}
    >
      <Container>
        <Form style={{ display: 'flex', flexDirection: 'column' }} form={form}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Please input a name.'),
              },
            ]}
          >
            <Input placeholder={gettextCatalog.getString('Name your tag')} />
          </Form.Item>
        </Form>
      </Container>
    </CdModal>
  );
};

export default CreateTagModal;
