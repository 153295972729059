import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHdd } from '@fortawesome/free-regular-svg-icons';

import ElementWrapper from './ElementWrapper';

const Size: React.FC<{ size: string }> = ({ size }) => (
  <ElementWrapper>
    <FontAwesomeIcon icon={faHdd} />
    <span>{size}</span>
  </ElementWrapper>
);

export default Size;
