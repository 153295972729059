(function () {
  'use strict';

  function GroupMainController(
    $http,
    $scope,
    $state,
    $stateParams,
    $uibModal,
    Groups,
    toastr,
    gettextCatalog,
    Me,
    Picture,
    groupData,
    Authorization
  ) {
    $scope.permissions = groupData.perms || [];
    $scope.permissionsLoading = false;
    $scope.aboutTextLoading = false;
    $scope.group = groupData.group;
    $scope.group.id = $stateParams.gid;
    $scope.groupTitle = groupData.group.name;
    $scope.aboutText = groupData.group.description;
    $scope.group.tasks = groupData.group.tasks || {};
    $scope.group.hasTask = !!_.get(groupData, 'group.tasks[0]');
    $scope.hasIntentionsAccess = Authorization.hasPackage('intentions');

    // Members
    $scope.users = {
      members: groupData.members,
      leaders: _.filter(groupData.members, { isAdmin: true }),
    };

    if ($scope.users.members.length === 0) {
      $scope.users.usersEmpty = true;
    }

    $scope.editGroup = function () {
      $uibModal
        .open({
          component: 'cdCreateGroupModal',
          resolve: {
            group: () => $scope.group,
          },
        })
        .result.then(() => {
          $state.reload();
        });
    };

    $scope.requestLeaveGroup = function () {
      Groups.updateUserMembership(
        { groupId: $scope.group.id, userId: Me.id },
        { status: 'remove' },
        function () {
          $scope.permissions.isPending = false;
          $scope.permissions.isMember = false;
          $state.reload();

          toastr.success(
            gettextCatalog.getString('You have been removed from the group.')
          );
        },
        function () {
          toastr.warning(
            gettextCatalog.getString('We could not remove you from the group.')
          );
        }
      );
    };

    $scope.onGroupUpdated = function () {
      $state.reload();
    };

    $scope.removeGroup = function () {
      let that = $scope;

      function deleteCall($uibModalInstance, targetId) {
        let url = `${cdApp.config.api.main}/groups/${$scope.group.id}`;
        if (_.isFinite(targetId)) {
          url += `?targetId=${targetId}`;
        }
        $http({
          method: 'DELETE',
          url,
        })
          .success(function () {
            $scope.processing = false;

            $uibModalInstance.dismiss('cancel');
            $state.go('app.private.intranet.groups');
            /**
             * If a target group was specified to transfer the content from the group being deleted,
             * also notify that the content has been successfully transferred
             */
            if (targetId) {
              toastr.success(
                gettextCatalog.getString('All content has been transferred.')
              );
            }
            toastr.success(
              gettextCatalog.getString('The group has been deleted.')
            );
          })
          .error(function () {
            $scope.processing = false;
            toastr.warning(
              gettextCatalog.getString('The group could not be deleted.')
            );
          });
      }

      $scope.processing = false;

      $uibModal.open({
        templateUrl: '@/app/intranet/group/templates/Delete.html',
        windowClass: 'modal-ui-select',
        controller: [
          '$scope',
          '$uibModalInstance',
          '$window',
          function ($scope, $uibModalInstance, $window) {
            'ngInject';

            $scope.group = that.group;
            $scope.addReplacementGroup = true;

            $http
              .get(
                cdApp.config.api.main +
                  '/organizations/' +
                  $window.churchdeskOrganizationId +
                  '/users/' +
                  Me.id +
                  '/groups'
              )
              .success(function (response) {
                $scope.groups = _.reject(response, {
                  id: parseInt($scope.group.id),
                });

                if ($scope.groups.length === 1) {
                  $scope.replacementGroup = $scope.groups[0];
                } else if (!$scope.groups.length) {
                  $scope.addReplacementGroup = false;
                }
              });

            $scope.deleteGroup = function () {
              $scope.processing = true;

              const targetId =
                $scope.addReplacementGroup && $scope.replacementGroup
                  ? $scope.replacementGroup.id
                  : undefined;
              deleteCall($uibModalInstance, targetId);
            };

            $scope.close = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],

        resolve: {
          reply: function () {
            return;
          },
        },
      });
    };

    $scope.pictureUploader = Picture(
      'groups',
      $stateParams.gid,
      function (file, response) {
        $scope.group.image = response.image;
      }
    );

    $scope.isImageEmpty = function (image) {
      return image === null || image === '';
    };

    $scope.removeImage = function () {
      $scope.group.image = null;

      new Groups($scope.group).$save(
        function () {
          toastr.success(
            gettextCatalog.getString('The group picture has been removed.')
          );
        },
        function (response) {
          toastr.error(
            _.get(response, 'data.message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
      );
    };

    $scope.requestJoinGroup = function (user) {
      Groups.updateUserMembership(
        { groupId: $scope.group.id, userId: user ? user.id : Me.id },
        { status: 'pending' },
        function (data) {
          if (data.status === 'active') {
            $scope.permissions.isPending = false;
            $scope.permissions.isMember = true;
            toastr.success(gettextCatalog.getString('You joined a new group.'));
            $state.reload();
          } else {
            $scope.permissions.isPending = true;
            $scope.permissions.isMember = false;
            toastr.success(
              gettextCatalog.getString(
                'Your request to join the group has been sent.'
              )
            );
          }
        },
        function () {
          toastr.error(gettextCatalog.getString('Request could not be sent.'));
        }
      );
    };
  }

  GroupMainController.$inject = [
    '$http',
    '$scope',
    '$state',
    '$stateParams',
    '$uibModal',
    'Groups',
    'toastr',
    'gettextCatalog',
    'Me',
    'Picture',
    'groupData',
    'Authorization',
  ];

  angular
    .module('cdApp.intranet')
    .controller('GroupMainController', GroupMainController);
})();
