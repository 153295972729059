import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import NewsletterSubscriptions from '@/react/people/components/NewsletterSubscriptions';

angular
  .module('cdApp.shared')
  .component(
    'cdrNewsletterSubscriptions',
    react2angular(
      sharedContext.use(NewsletterSubscriptions),
      [
        'newsletterLists',
        'updateNewsletterSubscriptions',
        'access',
        'contactId',
        'contactName',
      ],
      []
    )
  );
