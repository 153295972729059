import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';

import { IntentionCombinedState } from '..';

export const editingId = ({
  intention,
}: {
  intention: IntentionCombinedState;
}) => intention.collection.editingId;

export const isEditingSelector = createSelector(
  editingId,
  (editingId) => !!editingId
);

export const collectionItemsSelector = ({
  intention,
}: {
  intention: IntentionCombinedState;
}) => intention.collection.items;

export const editingCollectionSelector = createSelector(
  editingId,
  collectionItemsSelector,
  (collectionId, collections) =>
    collections.find(({ id }) => id === collectionId)
);

const isPast = (start: Moment, end: Moment): boolean => {
  const date = end ? end : start;
  return date.isBefore(Date.now());
};

export const getCollectionsToRender = createSelector(
  collectionItemsSelector,
  (collections) =>
    collections.map((collection: any) => ({
      title: collection.purpose,
      start: moment(collection.date).toDate(),
      end: moment(collection.date).add(1, 'days').toDate(),
      allDay: true,
      type: 'collection',
      backgroundColor: 'inherit',
      textColor: 'inherit',
      eventStartEditable: false,
      editable: false,
      eventDurationEditable: false,
      isPast: isPast(
        moment(collection.date),
        moment(collection.date).add(1, 'days')
      ),
    }))
);
