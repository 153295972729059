import { combineReducers } from '@reduxjs/toolkit';

import { resourcesReducer, ResourcesState } from './resources';
import CommonReducer, { ImmutableCommonState } from './common';
import { groupsReducer, GroupsState } from './groups';

export interface SharedState {
  resources: ResourcesState;
  common: ImmutableCommonState;
  groups: GroupsState;
}

export default combineReducers<SharedState>({
  resources: resourcesReducer,
  common: CommonReducer,
  groups: groupsReducer,
});
