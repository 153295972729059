import { Col, Row, Skeleton } from 'antd';
import { invert, readableColor } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { EventIcons } from '@/react/shared/components/Icons';

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BodyContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`;

const Card = styled.div<{
  $showRoundedCorners: boolean;
  $showShadow: boolean;
  $cardColor: string;
}>`
  border-radius: ${(props) => (props.$showRoundedCorners ? '16px' : '0px')};
  box-shadow: ${(props) =>
    props.$showShadow ? ' rgba(0, 0, 0, 0.44) 0px 3px 8px ' : 'none'};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.$cardColor};
  padding: 0px;
`;
const ContentSkeleton = styled(Skeleton)<{ $primaryColor: string }>`
  &&&& {
    .ant-skeleton-content .ant-skeleton-title {
      background: ${(props) => props.$primaryColor};
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
      background: #595959;
    }
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 16px;
    }
  }
`;
export const BlogStylePreview = ({
  primaryColor,
  cardColor,
  settings,
  itemsPerRow,
  pageSize,
}) => {
  const arrayOfPreviewCards = pageSize
    ? // eslint-disable-next-line prefer-spread
      Array.apply(null, { length: pageSize })
    : [null];
  let spanSize = 0;
  if (itemsPerRow === 1) {
    spanSize = 24;
  } else if (itemsPerRow === 3) {
    spanSize = 8;
  } else {
    spanSize = 12;
  }
  return (
    <Container>
      <Row gutter={[16, 16]} style={{ padding: '24px' }}>
        {arrayOfPreviewCards.map((_, i) => (
          <Col key={`blog-style-preview-${i}`} span={spanSize}>
            <Card
              $showRoundedCorners={settings.showRoundedCorners}
              $showShadow={settings.showShadow}
              $cardColor={cardColor}
            >
              <div
                style={{
                  borderRadius: settings.showRoundedCorners
                    ? '16px 16px 0px 0px'
                    : '0px',
                  width: '100%',
                  height: '120px',
                  backgroundColor: readableColor(cardColor || '#ffffff'),
                  color: invert(readableColor(cardColor || '#ffffff')),
                  opacity: 0.1,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <EventIcons.Image size="4x" />
              </div>

              <BodyContainer>
                <ContentSkeleton
                  $primaryColor={primaryColor}
                  paragraph={{ rows: 2 }}
                  title={{ width: '100px' }}
                />
              </BodyContainer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
