import styled from 'styled-components';

const FilterList = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  border-top: none;
`;

export default FilterList;
