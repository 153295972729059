import { ApiResponse } from 'apisauce';

import { API } from '../api';

import cdApp from '@/react/config';

export interface Group {
  id: number;
  name: string;
  image: string;
  imageThumbnail: string;
  members: number[];
}

export const loadGroups = async (meId): Promise<Group[]> => {
  const orgId = cdApp.organization.id;
  const response: ApiResponse<Group[]> = await API.get(
    `/organizations/${orgId}/users/${meId}/groups`
  );
  if (response.ok && response.data) {
    return response.data;
  } else {
    throw new Error('Error loading Groups');
  }
};

export interface Permissions {
  [key: string]: boolean;
}

export const loadPermissions = async () => {
  const response: ApiResponse<Permissions> = await API.get(
    '/filesystem/permissions'
  );
  if (response.ok) {
    return response.data;
  } else {
    throw new Error('Error loading Permissions');
  }
};

export interface UploadValidator {
  size: number;
  extensions: string;
}

export const loadUploadValidators = async (): Promise<UploadValidator> => {
  const response: ApiResponse<UploadValidator> = await API.get(
    '/files/uploadvalidators',
    { gallery: false }
  );
  if (response.ok && response.data) {
    return response.data;
  } else {
    throw new Error('Error loading upload validators');
  }
};
