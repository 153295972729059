import { selector } from 'recoil';

import { mainApi } from '../../api';
import { ChurchesWithPermissionContext, Resource } from '../models/resource';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';

class ResourceService {
  public async getResources(): Promise<Resource[]> {
    const response = await mainApi.get<Resource[]>('/resources');
    if (response.ok) {
      return response.data as Resource[];
    }
    throw response.data;
  }

  /**
   * Returns a list of churches based on the permission context
   * This function returns only the churches which the user has access too.
   * @param {string} permissionContext
   * @param {string} permissionType
   * @returns {object[]} list of churches
   */
  public getChurches = async (
    permissionContext: string,
    permissionType: string
  ): Promise<Resource[]> => {
    const response = await mainApi.get('/resources/churches', {
      permissionContext,
      permissionType,
    });
    if (response.ok) return response.data as Resource[];
    throw response.data;
  };

  /**
   * Returns a list of churches based on the permission context
   * This function returns all churches with a access flag showing whether the user has access or not.
   * @param {string} permissionContext
   * @param {string} permissionType
   * @returns {object} payload
   * @returns {object[]} payload.churches list of churches
   * @returns {boolean} payload.viaOrganizationGrant whether this was granted because of an organization permission. Opposite of a church/parish permission.
   *
   */
  public getChurchesV3 = async (
    permissionContext: string,
    permissionType: string
  ): Promise<ChurchesWithPermissionContext> => {
    const response = await mainApi.get<ChurchesWithPermissionContext>(
      '/resources/churches',
      {
        permissionContext,
        permissionType,
        version: 3,
      }
    );
    if (response.ok) {
      return response.data;
    }

    throw response.data;
  };
}
const resourceService = new ResourceService();

export const Resources = selector({
  key: 'Resouces',
  get: async ({ getCallback }) => {
    const resources = await resourceService.getResources();
    registerRecoilRefresher(
      Resources,
      getCallback(
        ({ refresh }) =>
          () =>
            refresh(Resources)
      )
    );
    return resources;
  },
});

export default resourceService;
