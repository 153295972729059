import * as immutable from 'seamless-immutable';
import { ImmutableObject } from 'seamless-immutable';
import { Reducer } from 'redux';

import createReducer, { CdAction, ActionCreator } from '../../redux/utils';
import { InterfaceJobs } from '../services/Jobs.service';

// Actions
export const FETCH_JOBS = 'organization/FetchJobs';
export const FETCH_JOBS_SUCCESS = 'organization/FetchJobsSuccess';

export type FetchJobsSuccess = CdAction<InterfaceJobs[]>;

// Action Creators
const FetchJobs: ActionCreator<undefined> = () => ({
  type: FETCH_JOBS,
});

const FetchJobsSuccess: ActionCreator<InterfaceJobs[]> = (payload) => ({
  type: FETCH_JOBS_SUCCESS,
  payload,
});

export const JobActionCreators = {
  FetchJobs,
  FetchJobsSuccess,
};

// State
export interface JobsState {
  jobs: InterfaceJobs[];
}

export type ImmutableJobsState = ImmutableObject<JobsState>;

// Reducers
const fetchJobsSuccess: Reducer<ImmutableJobsState, FetchJobsSuccess> = (
  state = initialState,
  { payload }
) =>
  state.merge({
    jobs: payload,
  });

// Initial State
const initialState: ImmutableJobsState = immutable.from<JobsState>({
  jobs: [],
});

// Handlers
export const handlers = {
  [FETCH_JOBS_SUCCESS]: fetchJobsSuccess,
};

export default createReducer<ImmutableJobsState>(initialState, handlers);
