import React, { useEffect } from 'react';
import _ from 'lodash';

import Config from '../../config';
import { gettextCatalog } from '../../services/I18nService';
import { FeedType } from '../store/main-view/Actions';
import CalendarService from '../services/CalendarService';

import { CdInputWithCopy } from '@/react/shared/components/cd-input-with-copy/CdInputWithCopy';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export function generateFeedUrl(
  type = 'private',
  token: string,
  feedType?: string,
  feedId?: string | number
): string {
  const parts = ['/ical'];
  if (['absences', 'categories'].includes(feedType)) {
    feedType = 'taxonomy';
  }
  if (feedType && feedType !== 'all') {
    parts.push(feedType);
  }
  if (feedId) {
    parts.push(feedId.toString());
  }

  let feedUrl = Config.config.api.main + parts.join('/');
  switch (type) {
    case 'private':
      feedUrl = `${feedUrl}?token=${token}`;
      break;
    case 'public':
      feedUrl = `${feedUrl}?organizationId=${_.get(
        window,
        'churchdeskOrganizationId'
      )}`;
      break;
    case 'resource':
      feedUrl = `${feedUrl}/public?organizationId=${_.get(
        window,
        'churchdeskOrganizationId'
      )}`;
      break;
    default:
      break;
  }

  return feedUrl;
}

type CalendarSubscribeModalProps = {
  feedType: FeedType;
  feedId?: string | number;
};

export const showCalendarSubscribeModal =
  createCdModal<CalendarSubscribeModalProps>(
    ({ setModalProps, feedType, feedId }) => {
      const [token, setToken] = React.useState<string>('');
      useEffect(() => {
        setModalProps({
          modalType: ModalType.MODAL,
          title: gettextCatalog.getString('Export'),
          width: 500,
        });

        CalendarService.getCalendarToken().then((newToken) => {
          setToken(newToken);
        });
      }, [setModalProps]);

      const privateUrl = generateFeedUrl('private', token, feedType, feedId);
      const publicUrl = generateFeedUrl('public', token, feedType, feedId);

      return (
        <>
          <div>
            {gettextCatalog.getString(
              'Export this calendar item by copying the following link to your calendar application:'
            )}
          </div>
          <div className="u-mt-20">
            <label>{gettextCatalog.getString('Personal feed')}</label>
            <CdInputWithCopy value={privateUrl} disabled />

            <div className="help-block">
              <b>
                {gettextCatalog.getString(
                  'Heads up! - These feeds are generated for your use only and can contain sensitive information.'
                )}
              </b>
            </div>
          </div>
          <div className="u-mt-20">
            <hr />

            <label>{gettextCatalog.getString('Public feed')}</label>
            <CdInputWithCopy value={publicUrl} disabled />
            <div className="help-block">
              {gettextCatalog.getString(
                'Only public events are exported to this feed.'
              )}
            </div>
          </div>
        </>
      );
    }
  );
