import _ from 'lodash';

/**
 * A pagination component, used to navigate in lists. It can also save the number of items per page in local storage.
 *
 * Note: It passes some options to uibootstrap's pagination component. @see https://angular-ui.github.io/bootstrap/#!#pagination
 *
 * @prop {Number} pageNumber - The current page
 * @prop {Boolean} buttonSizeSmall - Makes the button size in both navigation and dropdown smaller
 * @prop {Number} maxSize - The maximum number of page links to be shown
 * @prop {Number} itemsPerPage - The number of items per page
 * @prop {Number[]} itemsPerPageOptions - A list of possible values for `itemsPerPage` the user can select from
 * @prop {Number} totalItems - The total number of items in the list
 * @prop {string} localStorageItem - A key that will be used to store the pagination options in local storage
 * @prop {Function({ page })} onPageChanged - A callback when the user changes the current page
 * @prop {Function({ itemsPerPage })} onItemsPerPageChanged - A callback when the user changes the number of items per page
 */

class AdvancedPaginationController {
  constructor(paginationOptions) {
    'ngInject';

    this.paginationOptions = paginationOptions;
  }

  $onInit() {
    this.itemsPerPageOptions = this.itemsPerPageOptions || [10, 25, 50];
    this.maxSize = this.maxSize || 5;
  }

  setItemsPerPage(val) {
    const { paginationOptions } = this;

    if (this.itemsPerPage === val) return;
    this.itemsPerPage = val;
    paginationOptions.itemsPerPage.set(this.localStorageItem, val);
    this.onItemsPerPageChanged({ itemsPerPage: this.itemsPerPage });
  }

  pageChanged() {
    this.onPageChanged({ page: this.pageNumber });
  }

  shouldShowItemsPerPageDropdown() {
    return this.totalItems > _.min(this.itemsPerPageOptions);
  }
}
AdvancedPaginationController.$inject = ['paginationOptions'];

angular.module('cdApp.shared').component('cdAdvancedPagination', {
  template: require('./advanced-pagination.html'),
  controller: AdvancedPaginationController,
  bindings: {
    pageNumber: '<',
    buttonSizeSmall: '<',
    maxSize: '<',
    itemsPerPage: '<',
    itemsPerPageOptions: '<',
    totalItems: '<',
    localStorageItem: '<',
    onPageChanged: '&',
    onItemsPerPageChanged: '&',
  },
});
