import { Checkbox } from 'antd';
import React from 'react';

const TriStateCheckBox = (p) => (
  <Checkbox
    {...p}
    style={{
      fontWeight: 'lighter',
    }}
    checked={p.value === 'checked'}
    indeterminate={p.value === 'indeterminate'}
    onChange={(e) => {
      if (e?.target?.checked) return p.onChange('checked');
      return p.onChange(false);
    }}
  />
);

export default TriStateCheckBox;
