'use strict';

angular
  .module('cdApp.people')
  .factory('PeopleCustomFields', [
    '$$resource',
    ($$resource) =>
      $$resource(`${cdApp.config.api.forms}/custom-fields/fields`),
  ])
  .factory('PeopleCustomFieldsData', [
    '$$resource',
    ($$resource) =>
      $$resource(`${cdApp.config.api.forms}/custom-fields/submissions/:id`),
  ]);
