import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Typography } from 'antd';

import ListItem from '../list-item';

import Item from './Item';

const { Paragraph } = Typography;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  font-size: 60px;
  color: #008db6;
`;

const FolderItem: React.FC<{
  key: string;
  item: any;
  onFolderSelected: any;
}> = ({ item, onFolderSelected }) => (
  <ListItem>
    <Item onClick={() => onFolderSelected(item.id)}>
      <StyledFontAwesomeIcon icon={faFolder} />
      <Paragraph
        style={{
          width: '100%',
          padding: '0 6px',
          textAlign: 'center',
          display: 'block',
          marginBottom: 0,
        }}
        ellipsis={{ rows: 2 }}
      >
        {item.title}
      </Paragraph>
    </Item>
  </ListItem>
);

export default FolderItem;
