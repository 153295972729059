import { takeLatest, all } from 'redux-saga/effects';

import {
  LOAD_PARTNER_FILES,
  IMPORT_PARTNER_FILE,
  LOAD_FILEARCHIVE_FILES,
  LOAD_FILEARCHIVE_BREADCRUMB,
  FILEARCHIVE_GET_TAGS,
  FILEARCHIVE_UPDATE_FILE,
  FILEARCHIVE_DELETE_FILE,
  PARTNER_FILES_GET_FILTERS,
  GET_GEB_ACCESS,
  LOAD_LOOKUPS,
  FILEARCHIVE_FOLDER_CREATE,
} from '../actions/constants';

import {
  loadFileArchiveFilesSaga,
  loadFileArchiveBreadcrumbSaga,
  fileArchiveUpdateFileSaga,
  fileArchiveGetTagsSaga,
  fileArchiveDeleteFileSaga,
  fileArchiveFolderCreateSaga,
} from './filearchive';
import { loadLookups } from './lookups';
import {
  loadPartnerFilesSaga,
  importPartnerFileSaga,
  getPartnerFilesFiltersSaga,
} from './partner';
import { getGebAccessSaga } from './filepicker';

export default function* root(): Generator {
  yield all([
    takeLatest(LOAD_PARTNER_FILES, loadPartnerFilesSaga),
    takeLatest(IMPORT_PARTNER_FILE, importPartnerFileSaga),
    takeLatest(LOAD_FILEARCHIVE_FILES, loadFileArchiveFilesSaga),
    takeLatest(LOAD_FILEARCHIVE_BREADCRUMB, loadFileArchiveBreadcrumbSaga),
    takeLatest(FILEARCHIVE_GET_TAGS, fileArchiveGetTagsSaga),
    takeLatest(FILEARCHIVE_UPDATE_FILE, fileArchiveUpdateFileSaga),
    takeLatest(FILEARCHIVE_DELETE_FILE, fileArchiveDeleteFileSaga),
    takeLatest(PARTNER_FILES_GET_FILTERS, getPartnerFilesFiltersSaga),
    takeLatest(GET_GEB_ACCESS, getGebAccessSaga),
    takeLatest(LOAD_LOOKUPS, loadLookups),
    takeLatest(FILEARCHIVE_FOLDER_CREATE, fileArchiveFolderCreateSaga),
  ]);
}
