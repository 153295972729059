import _ from 'lodash';

import LocationNameSuggestions from '../../../../react/shared/services/LocationNameSuggestions';

/**
 * A drop-down that can be used to filter resources and select one or more of them.
 *
 * @prop {object[]} resourceSource - The available resources to choose from
 * @prop {number[] | string[]} eventResourceIds - The IDs of the selected resources in the list
 * @prop {object | string} eventAddress - The selected address (either a Google address bound to a resource, an unbound Google address or a free-text string)
 * @prop {Function} canEditFieldHelper(string) - A helper function that returns a boolean flag indicating whether the provided field can be edited or not
 */
class AddressSelectComponent {
  constructor(appUtils, cdApp, gettextCatalog, uiSelectAllowNewMarker) {
    'ngInject';
    this.gettextCatalog = gettextCatalog;
    this.appUtils = appUtils;
    this.cdApp = cdApp;
    this.uiSelectAllowNewMarker = uiSelectAllowNewMarker;
  }

  _resourcesWithAddress = [];

  get resourcesWithAddress() {
    return this._resourcesWithAddress;
  }

  // Set the address of the first selected resource
  set resourcesWithAddress(resourcesWithAddress) {
    this._resourcesWithAddress = resourcesWithAddress;
    if (
      resourcesWithAddress &&
      resourcesWithAddress.length > 0 &&
      !this.address
    ) {
      this.selectAddress(resourcesWithAddress[0]);

      if (!this.eventLocationName) {
        this.eventLocationName =
          resourcesWithAddress &&
          resourcesWithAddress.length > 0 &&
          resourcesWithAddress[0].locationName
            ? resourcesWithAddress[0].locationName
            : '';
      }
    }
  }

  getLocationName() {
    if (!this.eventLocationName) {
      return this.gettextCatalog.getString('No location name', null, 'Noun');
    }
    if (typeof this.eventLocationName === 'string') {
      this.eventLocationName = { name: this.eventLocationName };
    }
    return this.eventLocationName.name;
  }

  // Initialization functions

  $onInit() {
    this.addressState = 'search';
    this.isOpenLocationAccordion = false;
    this.initLocationNameSuggestions();
    // Init address
    if (this.freeTextOrGoogleAddress) {
      this.setManual();
      this.selectAddress(this.freeTextOrGoogleAddress);
    } else {
      if (this.resourcesWithAddress && this.resourcesWithAddress.length > 0) {
        this.selectAddress(this._resourcesWithAddress[0]);
      }
      this.goSearch();
    }
  }

  async initLocationNameSuggestions() {
    this.locationNameSuggestions = [];
    try {
      const suggestions = await LocationNameSuggestions.get();
      this.locationNameSuggestions = _.map(suggestions, (name) => ({
        name,
        type: this.gettextCatalog.getString(
          'Recently used',
          null,
          'Suggested location names'
        ),
      }));
    } catch (error) {}
  }

  getSelectedResource() {
    return (
      _.isFinite(this.indexOfSelectedResource) &&
      this.filteredResourceArray[this.indexOfSelectedResource]
    );
  }

  onAddressDropDownToggle(open) {
    this.isAddressDropDownOpen = open;
    this.indexOfSelectedAddress = -2;
  }

  checkAvailableAddress() {
    // If there are any available addresses to choose from (addresses from resources or a free-text/Google address) do not do anything
    if (!_.isEmpty(this.resourcesWithAddress) || this.freeTextOrGoogleAddress) {
      return false;
    }
    // If there are NO available addresses to choose from, open up the address selection modal
    // this.openSelectFreeTextOrGoogleAddressModal();
  }

  resetSelectedAddress($event) {
    this.selectedResource = null;
    this.freeTextOrGoogleAddress = null;
    this.isAddressDropDownOpen = false;
    /**
     * Since the component that calls this functions is nested within a button that invokes its own function when clicked,
     * if this function is called when clicking on the nested component (and therefore, the `$event` parameter is provided)
     * the propagation of the event must be stopped so as to avoid further logic execution.
     */
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  selectAddress(resourceWithAddress) {
    if (typeof resourceWithAddress === 'string') {
      this.addressState = 'oldLocation';
      return;
    }

    if (
      resourceWithAddress &&
      (resourceWithAddress.longitude ||
        resourceWithAddress.latitude ||
        resourceWithAddress.address ||
        resourceWithAddress.zipcode ||
        resourceWithAddress.country ||
        resourceWithAddress.address2)
    ) {
      this.address = resourceWithAddress;
      this.setManual();
      return;
    }

    const { appUtils } = this;
    this.selectedResource = resourceWithAddress;
    const resourceAddress = _.get(resourceWithAddress, 'location');
    const resourceId = _.get(resourceWithAddress, 'id');
    this.address = resourceAddress
      ? appUtils.buildGooglePlacesObject(resourceAddress, resourceId)
      : undefined;
    this.onLocationNameChanged({
      name: _.get(resourceWithAddress, 'locationName', ''),
    });

    if (this.address) {
      this.setManual();
    } else {
      this.goSearch();
    }

    this.isAddressDropDownOpen = false;
    this.onLocationChanged();
  }

  getSelectedAddress() {
    // If the selected address is defined, it must be either a resource with a address or a Google address
    const { gettextCatalog } = this;
    if (this.address && this.address.string) {
      return `${this.address.string}`;
    } else if (typeof this.freeTextOrGoogleAddress === 'string') {
      return this.freeTextOrGoogleAddress;
    }
    return gettextCatalog.getString('No address specified');
  }

  /**
   * Handle keyboard navigation on the address component.
   *
   * @param {Object} $event The original event object
   */
  addressKeyDown($event) {
    // Up key
    if ($event.which === 38) {
      this.indexOfSelectedAddress =
        this.indexOfSelectedAddress === -2
          ? _.size(this.resourcesWithAddress) - 1
          : this.indexOfSelectedAddress - 1;
      if (this.indexOfSelectedAddress === -1 && !this.freeTextOrGoogleAddress) {
        this.indexOfSelectedAddress = -2;
      }
    }
    // Down key
    if ($event.which === 40) {
      this.indexOfSelectedAddress =
        this.indexOfSelectedAddress === _.size(this.resourcesWithAddress) - 1
          ? -2
          : this.indexOfSelectedAddress + 1;
      if (this.indexOfSelectedAddress === -1 && !this.freeTextOrGoogleAddress) {
        this.indexOfSelectedAddress = 0;
      }
    }
    // Return/Enter key
    if ($event.which === 13) {
      if (this.indexOfSelectedAddress === -2) {
        this.openSelectFreeTextOrGoogleAddressModal();
      }
      if (this.indexOfSelectedAddress === -1) {
        this.selectFreeTextOrGoogleAddress();
      }
      if (this.indexOfSelectedAddress > -1) {
        this.selectAddress(
          this.resourcesWithAddress[this.indexOfSelectedAddress]
        );
      }
    }
    // Escape key
    if ($event.which === 27) {
      this.isAddressDropDownOpen = false;
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  showResetSelectedAddress() {
    return this.showAddress() && !this.isAddressSelectionDisabled();
  }

  // Helper functions

  isResourceAddress(address) {
    const isResourceWithAddress = _.get(address, 'location'); // A resource with a "address" object property
    const isBuiltAddressBoundToResource = _.get(
      address,
      'custom_data.resourceId'
    );
    // A Google address object built by the "buildGooglePlacesObject" function
    const isAddressBoundToResourceFromBackend = _.get(
      address,
      'location_map.resourceSourceId'
    );
    // A address object retrieved from the backend
    return (
      isResourceWithAddress ||
      isBuiltAddressBoundToResource ||
      isAddressBoundToResourceFromBackend
    );
  }

  getSelectedResourcesWithAddress() {
    const selectedResourcesWithAddress = [];
    _.each(this.selectedResourceIds, (selectedResourceId) => {
      let selectedResource = _.find(this.sortedResourceArray, {
        id: selectedResourceId,
      });

      // If the selected resource is a child resource, retrieve its parent
      const parentResourceId = _.get(selectedResource, 'parentResourceId');
      if (parentResourceId) {
        selectedResource = _.find(this.sortedResourceTree, {
          id: parentResourceId,
        });
      }
      if (_.get(selectedResource, 'location')) {
        selectedResourcesWithAddress.push(selectedResource);
      }
    });
    return selectedResourcesWithAddress;
  }

  hasSelectedResourceWithAddress() {
    return this.resourcesWithAddress.length > 0;
  }

  toggleLocationAccordion(value) {
    this.isOpenLocationAccordion = value;
    setTimeout(function () {
      $('#location_search_element').first().focus();
    }, 200);
  }

  parseGoogleAddress() {
    this.address = this.appUtils.parseGoogleAddressComponent(
      this.googleAddress,
      this.cdApp.organization.countryIso2
    );

    this.onLocationChanged();
    if (!_.isEmpty(this.address)) {
      this.setManual();
    }
  }

  goSearch() {
    this.addressState = 'search';
    this.googleAddress = null;
    setTimeout(function () {
      $('#location_search_element').first().focus();
    }, 200);
  }

  setManual() {
    const { gettextCatalog, cdApp } = this;
    this.addressState = 'manual';
    if (!this.address || !this.address.country) {
      if (!this.address) {
        this.address = {};
      }
      switch (cdApp.organization.countryIso2) {
        case 'gb':
          this.address.country = gettextCatalog.getString(
            'United Kingdom',
            null,
            'Noun'
          );

          break;
        case 'dk':
          this.address.country = gettextCatalog.getString(
            'Denmark',
            null,
            'Noun'
          );

          break;
        case 'de':
          this.address.country = gettextCatalog.getString(
            'Germany',
            null,
            'Noun'
          );

          break;
        default:
          this.address.country = gettextCatalog.getString(
            'United States',
            null,
            'Noun'
          );

          break;
      }
    }
    setTimeout(function () {
      $('#address_input').first().focus();
    }, 200);
  }

  onLocationNameChanged($item) {
    this.eventLocationName = $item;
    this.onLocationNameSelected({ selectedLocationName: $item.name });
  }

  onLocationChanged() {
    if (_.isEmpty(this.address)) return;
    this.address.string = this.appUtils.getAddressString(this.address);

    this.onLocationSelected({ selectedAddress: this.address });
  }

  getAddressLocationName(resource) {
    const { gettextCatalog } = this;
    if (!resource.locationName) {
      return gettextCatalog.getString('No location name', null, 'Noun');
    }
    return resource.locationName.name;
  }

  getAddressLocation(resource) {
    const { gettextCatalog } = this;
    if (resource.location) {
      return `${
        resource.locationName ? resource.locationName + ', ' : ''
      }${this.appUtils.getAddressString(resource.location)}`;
    }
    return gettextCatalog.getString('No address specified');
  }

  clearAddress() {
    this.address = null;
    this.onLocationSelected({ selectedAddress: null });
  }

  clearLocationName() {
    this.eventLocationName.name = undefined;
    this.onLocationNameSelected({ selectedLocationName: null });
  }
}
AddressSelectComponent.$inject = [
  'appUtils',
  'cdApp',
  'gettextCatalog',
  'uiSelectAllowNewMarker',
];

angular.module('cdApp.shared').component('cdAddressSelect', {
  template: require('./address-select.component.html'),
  controller: AddressSelectComponent,
  bindings: {
    resourcesWithAddress: '<',
    freeTextOrGoogleAddress: '<',
    isAccordion: '<',
    isRequired: '<',
    canEdit: '<',
    isEdit: '<',
    eventLocationName: '<',
    onLocationSelected: '&',
    onLocationNameSelected: '&',
  },
});
