'use strict';

class EmbedWidgetModalController {
  constructor(toastr, gettextCatalog) {
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
  }

  copyCode() {
    const embedCodeTextarea = document.querySelector('.embed-code-textarea');
    embedCodeTextarea.select();

    function unsuccessful() {
      this.toastr.error(
        this.gettextCatalog.getString(
          "Couldn't copy embed code. Try right clicking on the code to copy."
        )
      );
    }

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.toastr.info(
          this.gettextCatalog.getString('Embed code copied successfully.')
        );
      } else {
        unsuccessful();
      }
    } catch (err) {
      unsuccessful();
    }
  }

  cancel() {
    this.dismiss();
  }
}
EmbedWidgetModalController.$inject = ['toastr', 'gettextCatalog'];

angular.module('cdApp.shared').component('cdEmbedWidgetModalComponent', {
  templateUrl:
    '@/app/shared/components/widget-manager/partials/embed-widget-modal.html',
  controller: EmbedWidgetModalController,
  bindings: {
    code: '&',
    resolve: '<',
    dismiss: '&',
  },
});
