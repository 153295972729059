import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';

import AbsenceFilters from './AbsenceFilters';
import CreateCalendarSubscriptionModal from './CreateCalendarSubscriptionModal';
import MyCalenders from './MyCalenders';
import { ParishCalendars } from './ParishCalendars';
import SelectDatePicker from './SelectDatePicker';
import SidebarStyles from './SideBarStyle';
import UsersAndRoomsCalendars from './UsersAndRoomsCalendars';

export const SidebarContent: FunctionComponent = () => (
  <SidebarStyles>
    <Col style={{ width: '100%' }}>
      <Row>
        <SelectDatePicker />
      </Row>

      <Row>
        <Col style={{ width: '100%' }}>
          <MyCalenders />
          <UsersAndRoomsCalendars />
          <AbsenceFilters />
          <ParishCalendars />
        </Col>
      </Row>
    </Col>
    <CreateCalendarSubscriptionModal />
  </SidebarStyles>
);
