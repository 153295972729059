import React from 'react';
import styled from 'styled-components';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';

import { CdAttachmentIcon } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import UploadFile from '@/react/people/components/UploadFile';

interface UploadAttachmentProps {
  value?: IUploadFile[];
  onChange?: (value: IUploadFile[]) => void;
  uploadFile: (args: UploadRequestOption) => Promise<any>;
  deleteFile: (file: IUploadFile) => Promise<boolean>;
  disabled?: boolean;
  disabledMessage: string;
}

const UploadBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function UploadAttachment(props: UploadAttachmentProps) {
  if (!props.onChange && !props.value) {
    return null;
  }

  return (
    <UploadFile {...props} multiple showUploadList>
      {props.disabled ? (
        props.disabledMessage
      ) : (
        <UploadBtnContainer className="ant-upload-drag-icon">
          <CdAttachmentIcon size="2x" style={{ color: '#1fa1c2' }} />
          <p className="ant-upload-text">
            {gettextCatalog.getString('Choose a file or drag it here')}
          </p>
          <p className="ant-upload-hint">
            {gettextCatalog.getString(
              'Files uploaded here will be sent with the email to the recipients'
            )}
          </p>
        </UploadBtnContainer>
      )}
    </UploadFile>
  );
}
