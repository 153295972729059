import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getTimeRegistrationReportUsers = async (): Promise<any[]> => {
  const response = await mainApi.get<any[]>(
    `/users/time-registrations/report/users`
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const timeRegistrationReportExport = async (data: {
  startWeekNumber: number;
  startYear: number;
  endWeekNumber: number;
  endYear: number;
  userWithTimeRegistrationId: number;
}) => {
  const response = await mainApi.get(
    `/users/${data?.userWithTimeRegistrationId}/time-registrations/report`,
    {
      startWeekNumber: data.startWeekNumber,
      startYear: data.startYear,
      endWeekNumber: data.endWeekNumber,
      endYear: data.endYear,
    }
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
  return response.data;
};
