import _ from 'lodash';

import { mainApi } from '../../api';
import { Stole } from '../models/stole';
import {
  FetchStolesPayload,
  FetchBillingStolePayload,
} from '../redux/stoles/Actions';

export interface StolesData {
  items: Stole[];
  count: number;
}

class StoleService {
  public async getStolesNextReferenceNumber(
    churchId: number,
    stoleYear: number
  ): Promise<string> {
    const response = await mainApi.get(
      '/intention/stoles/next-reference-number',
      {
        churchId,
        stoleYear,
      }
    );
    if (response.ok) {
      return _.get(response.data, 'nextReferenceNumber') as string;
    }
    throw response.data;
  }

  public async getEventStole(calendarId: number): Promise<Stole> {
    const response = await mainApi.get(
      `/intention/stoles/assigned/${calendarId}`
    );
    if (response.ok) {
      return response.data as any;
    }
    throw response.data;
  }

  public async getStoles(payload: FetchStolesPayload): Promise<StolesData> {
    const endpointPayload: any = _.omit(payload, 'status');
    const status = payload.status;
    if (status) {
      endpointPayload.statuses = [status];
    }
    const response = await mainApi.get(`/intention/stoles`, endpointPayload);
    if (response.ok) {
      return response.data as StolesData;
    }
    throw response.data;
  }

  public async createStole(stole: Stole, calendarId: number): Promise<void> {
    const response = await mainApi.post(`/intention/stoles`, {
      calendarId,
      ...stole,
    });
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateStole(
    stole: Stole,
    stoleId: string,
    calendarId: number
  ): Promise<void> {
    const response = await mainApi.put(`/intention/stoles/${stoleId}`, {
      calendarId,
      ...stole,
    });
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteStole(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/stoles/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async fetchStoleBillingEvents(
    payload: FetchBillingStolePayload
  ): Promise<Event[]> {
    const response = await mainApi.get(
      `/intention/stoles/events-with-assigned-stoles`,
      payload
    );
    if (response.ok) {
      return response.data as Event[];
    }
    throw response.data;
  }

  public async processBilling(
    payload: FetchBillingStolePayload
  ): Promise<Event[]> {
    const response = await mainApi.post(
      `/intention/stoles/process-billing`,
      payload
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async revertBilling(payload: FetchBillingStolePayload): Promise<void> {
    const response = await mainApi.post(
      `/intention/stoles/reset-billing`,
      payload
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async restoreStole(id: string): Promise<void> {
    const response = await mainApi.post(
      `/intention/stoles/${id}/revert-deletion`
    );
    if (!response.ok) {
      throw response.data;
    }
  }
}

export default new StoleService();
