import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Resources } from '@/react/shared/services/ResourceService';
import { ResourceTypes } from '@/react/shared/models/resource';
import { IntentionFeesQuery } from '@/react/calendar/event-details/store/intentions';
import IntentionService from '@/react/intention/services/IntentionService';
import ErrorHandlingService from '@/react/services/ErrorHandlingService';

export const useCreateIntention = () => {
  const resources = useRecoilValue(Resources);
  const intentionFeesObject = useRecoilValue(IntentionFeesQuery);
  let defaultIntentionFeeId = null;
  const churches = resources
    ?.filter((item) => item.type === ResourceTypes.CHURCH)
    .map((item) => ({
      value: item.id,
      label: item.name,
      resources: item.resources,
    }));
  const intentionFees = intentionFeesObject.items.map((fee) => {
    if (fee.isDefault) {
      defaultIntentionFeeId = fee.id;
    }
    return {
      value: fee.id,
      label: fee.name,
      isDefault: fee.isDefault,
    };
  });
  const resourcesForSpecificChurch = useCallback(
    (churchId) => {
      const resources = churches
        ?.find((item) => item.value === churchId)
        ?.resources?.map((item) => ({ value: item.id, label: item.name }));
      return resources;
    },
    [churches]
  );
  const nextReferenceNumber = useCallback((churchId, intentionYear, form) => {
    let nextReferenceNumber = null;
    if (!churchId || !intentionYear) return nextReferenceNumber;

    nextReferenceNumber = IntentionService.getIntentionsNextReferenceNumber(
      churchId,
      intentionYear
    )
      .then((value) => {
        form.setFieldsValue({ nextReferenceNumber: value });
      })
      .catch(ErrorHandlingService.handleError);
  }, []);

  const createIntention = useCallback(
    (intention) => IntentionService.createIntention(intention),
    []
  );

  return {
    churches,
    intentionFees,
    defaultIntentionFeeId,
    resourcesForSpecificChurch,
    nextReferenceNumber,
    createIntention,
  };
};
