'use strict';
smsService.$inject = ['Church'];

function smsService(Church) {
  /**
   * Initialize the service with a message object
   *
   * @param {Object} message - The message being sent
   * @param {Object[]} lists - All lists, including the people that belong to each list
   *
   * @return {Promise}
   */
  const initializeService = (message, lists) =>
    Church.get().$promise.then(({ smsBalance }) => {
      const getTotalRecipientsCount = () => {
        const peopleIds = _(message.to).filter('contact').map('id').value();
        const listIds = _(message.to).filter('setup').map('id').value();
        const matchingLists = _(lists).filter((list) =>
          _.includes(listIds, list.id)
        );

        const peopleInSegments = _(matchingLists)
          .map('people')
          .flatten()
          .map('id')
          .value();

        return _.size(_.uniq(_.union(peopleIds, peopleInSegments)));
      };

      return {
        /**
         * Determine whether a message exceeds the SMS balance limit
         *
         * @return {Boolean} - Whether the provided message exceeds the balance or not
         */
        exceedsBalance: _.debounce(
          () => {
            if (message) {
              return (
                !message.sent &&
                message.type === 'sms' &&
                message.size().segments * getTotalRecipientsCount() > smsBalance
              );
            }
          },
          1000,
          { leading: true }
        ),
      };
    });

  return {
    init: initializeService,
  };
}

angular.module('cdApp.people').factory('smsService', smsService);
