import {
  StandardWorkingHoursInterface,
  UserTimeRegistrationCalendar,
} from '../types/timeRegistration.types';

import { mainApi } from '@/react/api';
import cdApp from '@/react/config';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getUserTimeRegistrationCalendar = async ({
  month,
  year,
}): Promise<UserTimeRegistrationCalendar[]> => {
  const currentLoggedInUserId = cdApp?.me?.id;

  const response = await mainApi.get<UserTimeRegistrationCalendar[]>(
    `/users/${currentLoggedInUserId}/time-registrations`,
    { month, year }
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const userTimeRegistrationSaveDayHours = async (data: {
  date: string;
  hours: number;
  comment?: string;
}) => {
  const currentLoggedInUserId = cdApp?.me?.id;
  const response = await mainApi.post(
    `/users/${currentLoggedInUserId}/time-registrations`,
    data
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
  return response.data;
};

export const saveStandardWorkingHours = async (
  data: StandardWorkingHoursInterface
) => {
  const currentLoggedInUserId = cdApp?.me?.id;
  const response = await mainApi.put(
    `/users/${currentLoggedInUserId}/time-registrations/standard-hours`,
    data
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
  return response.data;
};

export const getStandardWorkingHours = async () => {
  const currentLoggedInUserId = cdApp?.me?.id;
  const response = await mainApi.get(
    `/users/${currentLoggedInUserId}/time-registrations/standard-hours`
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data as StandardWorkingHoursInterface;
};
