import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import moment from 'moment-timezone';

import { ContentType, ContentTypeProps } from './types';

import cdApp from '@/react/config';

const ContentTitle = styled.h3`
  margin: 0 0 2px 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
`;

const ContentText = styled.p`
  margin: 0;
`;

const ContentLongDate = styled.p`
  margin: 0 4px 0 0;
`;

const ContentDescription = styled.p`
  height: 100%;
  font-size: 14px;
  line-height: 17px;
`;

const ContentDateColumn = styled.div`
  border-right: 3px solid ${({ theme }) => theme.colors.newsletter.main};
  color: ${({ theme }) => theme.colors.newsletter.main};
  height: fit-content;
  padding: 0 8px 0 0;
  margin: 0 12px 0 0;
`;

const ContentDateMonth = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;
const ContentDateDay = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export const ContentTextColumn = styled.div`
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 0 0;
`;

export const ContentInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 232px;
`;

export const ContentImg = styled.img`
  width: 225px;
  margin: 0 0 8px 0;
`;

const ContentButton = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  border: 1px solid ${({ theme }) => theme.colors.newsletter.main};
  width: 225px;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
`;

export default function ContentItem<T extends ContentTypeProps>({
  contributor,
  formattedDate,
  formattedDescription,
  formattedTitle,
  image,
  locationName,
  price,
  showLocation,
  showShortDescription,
  signUpText,
  contentType,
  ...item
}: T) {
  if (!formattedTitle) {
    throw new Promise(() => undefined);
  }
  let month;
  let day;
  if (contentType === ContentType.EVENT) {
    const timeZone = cdApp.organization.locale.timezone;
    month = moment(item.startDate).tz(timeZone).format('MMM').toUpperCase();
    day = moment(item.startDate).tz(timeZone).format('DD').toUpperCase();
  }

  return (
    <>
      {contentType === ContentType.EVENT && (
        <ContentDateColumn>
          <ContentDateMonth>{month}</ContentDateMonth>
          <ContentDateDay>{day}</ContentDateDay>
        </ContentDateColumn>
      )}
      <ContentTextColumn>
        <ContentTitle>{formattedTitle}</ContentTitle>
        {contentType !== ContentType.EVENT ? (
          <ContentDescription>{formattedDescription}</ContentDescription>
        ) : (
          <>
            {formattedDate && (
              <ContentLongDate>{formattedDate}</ContentLongDate>
            )}
            {showLocation && <ContentText>{locationName}</ContentText>}
            {contributor && <ContentText>{contributor}</ContentText>}
            {price && <ContentText>{price}</ContentText>}
            {showShortDescription && (
              <ContentDescription style={{ paddingTop: '10px' }}>
                {formattedDescription}
              </ContentDescription>
            )}
          </>
        )}
      </ContentTextColumn>
      <ContentInfoColumn>
        {image && <ContentImg src={image} />}
        {signUpText && <ContentButton>{signUpText}</ContentButton>}
      </ContentInfoColumn>
    </>
  );
}

ContentItem.Skeleton = () => (
  <>
    <Skeleton paragraph active title />
  </>
);

// @ts-ignore we can most deffently set a prop on a function.
ContentItem.Skeleton.displayName = 'ContentItemSkeleton';
