import styled from 'styled-components';

const ElementWrapper = styled.div`
  margin-bottom: 15px;
  svg {
    color: #777;
  }
  span {
    padding-left: 5px;
  }
`;

export default ElementWrapper;
