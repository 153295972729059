import {
  IMPORT_FILE,
  GET_GEB_ACCESS,
  GET_GEB_ACCESS_SUCCESS,
  CLOSE_MODAL,
} from './constants';

export const importFile = (payload: any) => ({
  type: IMPORT_FILE,
  payload,
});

export const getGebAccess = (payload: { partner: string }) => ({
  type: GET_GEB_ACCESS,
  payload,
});

export const getGebAccessSuccess = (payload: { isCustomer: boolean }) => ({
  type: GET_GEB_ACCESS_SUCCESS,
  payload,
});
export const closeModal = () => ({
  type: CLOSE_MODAL,
});
