import * as _ from 'lodash';
import { ImmutableArray } from 'seamless-immutable';

import { IntentionFee } from '../../models/fee';

export const getFilteredFees = (
  fees: ImmutableArray<IntentionFee>,
  taxonomyIds?: number[]
): IntentionFee[] => {
  const _fees = fees ? fees.asMutable({ deep: true }) : [];
  let filteredFees = _.filter(_fees, ['active', true]);

  // Filter intention fees by taxonomy, if specified
  if (!_.isEmpty(taxonomyIds)) {
    filteredFees = _.filter(filteredFees, (intentionFee) => {
      const intentionFeeTaxonomies = _.map(intentionFee.taxonomies, 'id');
      return !_.isEmpty(_.intersection(intentionFeeTaxonomies, taxonomyIds));
    });
  }

  return _.orderBy(filteredFees, 'name');
};
