class PastoralNoteHistoryModalController {
  constructor(_, $http) {
    this._ = _;
    this.$http = $http;
  }

  $onInit() {
    const { $http } = this;
    const { personId, pastoralNoteId } = this.resolve;
    this.isLoading = true;
    $http
      .get(
        `${cdApp.config.api.main}/people/${personId}/pastoral-notes/${pastoralNoteId}/revisions`
      )
      .then(({ data }) => {
        this.revisions = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

PastoralNoteHistoryModalController.$inject = ['_', '$http'];
angular.module('cdApp.people').component('cdPastoralNoteHistoryModal', {
  template: require('./pastoral-note-history-modal.component.html'),
  controller: PastoralNoteHistoryModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
