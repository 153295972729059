export enum SORT {
  DATE,
}

export enum ContentType {
  BLOG = 'blogs',
  EVENT = 'events',
  CONTRIBUTION = 'donations',
  FORM = 'forms',
}

export interface ContentTypeProps {
  description?: string;
  formattedDescription: string;
  formattedTitle: string;
  formFromProp?: string;
  image?: string;
  signUpText?: string;
  title?: string;
  [attributes: string]: any;
}

export interface ContentBlockProps<T> {
  value?: T[];
  onChange?: (item: T[]) => void;
  contentType: ContentType;
  imagePath?: string | string[];
  formFromProp?: string;
  showLocation?: boolean;
  showShortDescription?: boolean;
  id?: string;
  signUpText?: string;
  sort?: { key: string; type: SORT; sortDirection?: 'ASC' | 'DESC' };
  DrawerContent: ({ form }) => JSX.Element;
}
