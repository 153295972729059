import React, { Suspense, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import moment from 'moment';
import styled from 'styled-components';

import { CalendarEventTabs } from '../types/event';

import { EventAccessContext, NewEventProps, useEvent } from './hooks/use-event';
import { EventControls } from './components/form-items/EventControls';
import { EventDetails } from './tabs/event-details/EventDetails';
import { FieldSection } from './components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from './constants';
import { EventDetailsPageSuspended } from './components/EventDetailsPageSuspended';
import { FormItemWithAccess } from './components/form-items/FormItemWithAccess';
import CdAttendance from './components/CdAttendance';
import Comments from './components/comments/Comments';
import { CommentTabHeader } from './components/comments/CommentTabHeader';
import EventsRotaTable from './components/rotas/EventsRotaTable';
import { UserAnswerToEvent } from './components/form-items/UserAnswerToEvent';
import { VersionHistory } from './components/VersionHistory.table';
import { SignUpFormTab } from './components/SignUpFormTab';
import { CdSignUp } from './components/CdSignUp';
import { PosterTab } from './tabs/poster/PosterTab';
import { InviteTab } from './tabs/invite/InviteTab';
import { getEventCopy } from './services/event.service';
import { UponCloseInterface, useCloseModal } from './hooks/use-close-modal';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdUserAvatarWithState } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import AuthorizationService from '@/react/services/AuthorizationService';
import { CdErrorPage } from '@/react/shared/components/cd-error-page/CdErrorPage';
import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';
import { DateInput } from '@/react/calendar/event-details/components/date-input/DateInput';
import { CdVersionHistory } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const EventDetailsPage = (props: {
  eventId?: number;
  newEvent?: NewEventProps;
  close: (input?: UponCloseInterface) => Promise<void>;
  form: FormInstance;
  startingTab?: CalendarEventTabs;
}) => {
  const [currentTab, setCurrentTab] = useState<CalendarEventTabs>(
    props.startingTab || CalendarEventTabs.DETAILS_PAGE
  );
  const [revisionVersion, setRevisionVersion] = useState<{
    version: string;
    authorId?: number;
  }>({ version: 'current', authorId: null });
  const {
    event,
    eventAccessContext,
    churchSettings,
    onSave,
    onDelete,
    saveProcessing,
    saveAndClose,
    eventInitialFormData,
    showConflicts,
    setEventFormWithDataBasedOnVersionHistory,
  } = useEvent({
    eventId: props.eventId,
    newEvent: props.newEvent,
    form: props.form,
    close: props.close,
    setCurrentTab,
    setRevisionVersion,
  });

  const isNew = !props.eventId;
  const hasIntentionPackage = AuthorizationService.hasPackage('intentions');
  const hasFormsPackage = AuthorizationService.hasPackage('forms');
  const isViewingPreviousEvent = revisionVersion.version !== 'current';
  if (!event && !isNew) {
    return (
      <CdNotFound
        title={gettextCatalog.getString('Not found')}
        subTitle={
          <Space direction="vertical">
            {gettextCatalog.getString('The event could not be found')}
            <Button onClick={() => props.close()}>
              {gettextCatalog.getString('Close window')}
            </Button>
          </Space>
        }
      />
    );
  }
  return (
    <EventAccessContext.Provider
      value={{
        eventAccess: eventAccessContext,
        disabledByVersionHistory: isViewingPreviousEvent,
      }}
    >
      <Form
        id="formId"
        form={props.form}
        layout="vertical"
        initialValues={eventInitialFormData}
        style={{ minWidth: '1000px' }}
      >
        <Form.Item hidden noStyle name="type">
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name="visibility">
          <Input />
        </Form.Item>
        <Row
          gutter={16}
          style={{
            marginTop: '24px',
          }}
        >
          <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
            <FieldSection>
              <FormItemWithAccess
                name="title"
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'An event title is required.'
                    ),
                  },
                ]}
                noErrorMessage
              >
                <Input
                  size="large"
                  placeholder={gettextCatalog.getString('Add title')}
                />
              </FormItemWithAccess>
            </FieldSection>

            <div
              style={{
                marginTop: '24px',
              }}
            >
              <FieldSection>
                <FormItemWithAccess name="date" fieldAccessName="startDate">
                  <DateInput eventId={event?.id} previousRRule={event?.rrule} />
                </FormItemWithAccess>
              </FieldSection>
            </div>
          </Col>
          <Col flex="auto">
            {!isViewingPreviousEvent ? (
              <FieldSection>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.users !== currentValues?.users ||
                    prevValues?.rotasAndIntentions.shiftsAndIntentions !==
                      currentValues?.rotasAndIntentions.shiftsAndIntentions ||
                    prevValues?.visibilitySetting !==
                      currentValues?.visibilitySetting
                  }
                  style={{ marginBottom: 0 }}
                >
                  {({ getFieldValue }) => {
                    const users = getFieldValue('users');
                    const visibilitySetting =
                      getFieldValue('visibilitySetting');
                    const shiftsAndIntentions =
                      getFieldValue('rotasAndIntentions').shiftsAndIntentions;
                    const hasAnyRotasUser = shiftsAndIntentions?.some(
                      (item) => {
                        const shifts = item.shifts;
                        return shifts.some((shift) => shift.users?.length > 0);
                      }
                    );

                    return (
                      <EventControls
                        {...{
                          onSave,
                          onDelete,
                          saveAndClose,
                          saveProcessing,
                          close: props.close,
                          eventId: event?.id,
                          areAnyUsersBooked:
                            users?.length > 0 || hasAnyRotasUser,
                          isPublicEvent:
                            visibilitySetting?.visibility === 'public',
                          eventUrl: event?.url,
                        }}
                      />
                    );
                  }}
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.users !== currentValues?.users
                  }
                  style={{
                    marginTop: '24px',
                  }}
                >
                  {({ getFieldValue }) => {
                    const users = getFieldValue('users');

                    return (
                      <UserAnswerToEvent
                        bookingUsers={users ? users : []}
                        eventId={event?.id}
                      />
                    );
                  }}
                </Form.Item>
              </FieldSection>
            ) : (
              <FieldSection>
                <Alert
                  message={
                    <Space direction="vertical">
                      <Typography.Text strong>
                        <Space>
                          {gettextCatalog.getString('Event version:')}
                          {revisionVersion.version}
                        </Space>
                      </Typography.Text>
                      <Space>
                        {gettextCatalog.getString('By:')}
                        <CdUserAvatarWithState
                          userId={revisionVersion.authorId}
                          size="small"
                        />
                      </Space>
                    </Space>
                  }
                  description={
                    <Button
                      style={{ marginTop: `8px` }}
                      type="primary"
                      onClick={() =>
                        setEventFormWithDataBasedOnVersionHistory({
                          isFromBackToCurrentVesionButton: true,
                        })
                      }
                    >
                      {gettextCatalog.getString('Back to current version')}
                    </Button>
                  }
                  style={{ width: `400px` }}
                  type="info"
                  icon={
                    <CdVersionHistory
                      style={{ marginRight: '16px', marginTop: '4px' }}
                      size="lg"
                    />
                  }
                  showIcon
                />
              </FieldSection>
            )}
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.date !== currentValues.date ||
            prevValues.visibilitySetting !== currentValues.visibilitySetting
          }
        >
          {({ getFieldValue }) => (
            <CustomTabs
              defaultActiveKey={CalendarEventTabs.DETAILS_PAGE}
              type="card"
              activeKey={currentTab}
              style={{ marginTop: '8px' }}
              onTabClick={(key) => setCurrentTab(key as CalendarEventTabs)}
            >
              <Tabs.TabPane
                tab={gettextCatalog.getString('Event details')}
                key={CalendarEventTabs.DETAILS_PAGE}
                forceRender
              >
                <EventDetails
                  churchSettings={churchSettings}
                  event={event}
                  form={props.form}
                  showConflicts={showConflicts}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  hasIntentionPackage
                    ? gettextCatalog.getString('Rota & Intentions')
                    : gettextCatalog.getString('Rota', undefined, 'Event form')
                }
                key={CalendarEventTabs.ROTA_AND_INTENTIONS}
                forceRender
              >
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.date.startDate !== curValues.date.startDate ||
                    prevValues.date.endDate !== curValues.date.endDate ||
                    prevValues.date.rrule !== curValues.date.rrule ||
                    prevValues.date.allDay !== curValues.date.allDay ||
                    prevValues.title !== curValues.title ||
                    prevValues.resourcesLocation.churchIds !==
                      curValues.resourcesLocation.churchIds ||
                    prevValues.mainCategory !== curValues.mainCategory ||
                    prevValues.taxonomies !== curValues.taxonomies
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    let categoryIds = [];
                    const taxonomies = getFieldValue('taxonomies');
                    const mainCategory = getFieldValue('mainCategory');
                    if (mainCategory) categoryIds.push(mainCategory);
                    if (taxonomies) {
                      categoryIds = taxonomies.includes(mainCategory)
                        ? taxonomies
                        : categoryIds.concat(taxonomies);
                    }
                    return (
                      <Form.Item name="rotasAndIntentions">
                        <EventsRotaTable
                          calendarId={props.eventId}
                          eventTitle={getFieldValue('title')}
                          eventDate={getFieldValue('date')}
                          eventChurchIds={
                            getFieldValue('resourcesLocation').churchIds
                          }
                          categoryIds={categoryIds}
                          onSave={onSave}
                          saveProcessing={saveProcessing}
                          isViewingPreviousEvent={isViewingPreviousEvent}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Tabs.TabPane>

              {hasFormsPackage && (
                <Tabs.TabPane
                  tab={gettextCatalog.getString('Sign-ups')}
                  key={CalendarEventTabs.SIGN_UPS}
                  disabled={isViewingPreviousEvent}
                >
                  <Suspense
                    fallback={
                      <Spin>
                        <CdSignUp canEdit={!event || event?.canEdit} />
                      </Spin>
                    }
                  >
                    <SignUpFormTab
                      eventId={event?.id}
                      canEdit={
                        (!event || event?.canEdit) &&
                        eventAccessContext.fields.form?.canEdit
                      }
                    />
                  </Suspense>
                </Tabs.TabPane>
              )}

              <Tabs.TabPane
                tab={
                  getFieldValue('date').startDate.diff(moment(), 'minutes') >
                  30 ? (
                    <CdTooltip
                      title={gettextCatalog.getString(
                        "The event hasn't started yet. You will be able to start registering attendance 30 minutes before the event is set to begin."
                      )}
                    >
                      {gettextCatalog.getString('Attendance')}
                    </CdTooltip>
                  ) : (
                    gettextCatalog.getString('Attendance')
                  )
                }
                key={CalendarEventTabs.ATTENDANCE}
                disabled={
                  getFieldValue('visibilitySetting')?.visibility ===
                    'private' ||
                  getFieldValue('date').startDate.diff(moment(), 'minutes') > 30
                }
              >
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues?.mainCategory !== currentValues?.mainCategory ||
                    prevValues?.title !== currentValues?.title
                  }
                >
                  {({ getFieldValue }) => {
                    const title = getFieldValue('title');
                    const category = {
                      mainCategory: getFieldValue('mainCategory'),
                    };
                    const startDate = getFieldValue('date').startDate;
                    return (
                      <FormItemWithAccess name="attendance">
                        <CdAttendance
                          eventTitle={title}
                          eventCategory={category}
                          eventStartDate={startDate}
                        />
                      </FormItemWithAccess>
                    );
                  }}
                </Form.Item>
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={gettextCatalog.getString('Poster')}
                key={CalendarEventTabs.POSTER}
                disabled={isViewingPreviousEvent || isNew}
              >
                <PosterTab eventId={event?.id} saveAndClose={saveAndClose} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={gettextCatalog.getString('Invite')}
                key={CalendarEventTabs.INVITE}
                disabled={isViewingPreviousEvent || isNew}
              >
                <InviteTab eventId={event?.id} saveAndClose={saveAndClose} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<CommentTabHeader eventId={event?.id} />}
                key={CalendarEventTabs.COMMENTS}
                disabled={isViewingPreviousEvent || isNew}
              >
                <Comments eventId={event?.id} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={gettextCatalog.getString('Version history')}
                key={CalendarEventTabs.VERSION_HISTORY}
                disabled={isNew || !eventAccessContext.canEdit}
              >
                <VersionHistory
                  eventId={event?.id}
                  setEventFormWithDataBasedOnVersionHistory={
                    setEventFormWithDataBasedOnVersionHistory
                  }
                />
              </Tabs.TabPane>
            </CustomTabs>
          )}
        </Form.Item>
      </Form>
    </EventAccessContext.Provider>
  );
};

export const EventDetailsPageHoc = (props: {
  eventId?: number;
  startingTab?: CalendarEventTabs;
  $stateParams?: {
    id: string;
    openAddForm: boolean;
  };
}) => {
  const eventId = props.eventId || parseInt(props.$stateParams.id, 10);
  const startingTab =
    props.$stateParams?.openAddForm && CalendarEventTabs.SIGN_UPS;

  useEffect(() => {
    openEventDrawer({
      eventId,
      isMainPage: true,
      startingTab: startingTab,
    });
  }, [eventId, startingTab]);
  return <></>;
};

export type EventDrawerProps = {
  eventId?: number;
  newEvent?: NewEventProps;
  isMainPage?: boolean;
  copyEventId?: number;
  startingTab?: CalendarEventTabs;
};

export const openEventDrawer = async (props: EventDrawerProps) => {
  if (props.copyEventId) {
    const copiedEvent = await getEventCopy(props.copyEventId);

    // posters, invites,
    return NiceModal.show('EventDrawer', {
      newEvent: copiedEvent,
    });
  }

  return NiceModal.show('EventDrawer', {
    ...props,
  });
};

export const EventDrawer = NiceModal.create<EventDrawerProps>(
  ({ eventId, newEvent, isMainPage, startingTab }) => {
    const modal = useModal('EventDrawer');
    const [form] = Form.useForm();
    const { close } = useCloseModal({
      modal,
      form,
      isMainPage,
    });

    return (
      <Drawer
        {...antdDrawerV5(modal)}
        closable={false}
        width="100vw"
        headerStyle={{ borderBottom: '0px' }}
        bodyStyle={{ paddingTop: '0px' }}
        onClose={() => close()}
      >
        <Suspense fallback={<EventDetailsPageSuspended close={close} />}>
          <ErrorBoundary fallback={<CdErrorPage />}>
            <EventDetailsPage
              eventId={eventId}
              newEvent={newEvent}
              close={close}
              form={form}
              startingTab={startingTab}
            />
          </ErrorBoundary>
        </Suspense>
      </Drawer>
    );
  }
);

const CustomTabs = styled(Tabs)`
  &&&& .ant-tabs-tab {
    min-width: 140px;
    justify-content: center;
  }
`;
NiceModal.register('EventDrawer', EventDrawer);
