'use strict';

/**
 * Set user names to e-mail by default, if empty.
 *
 * Use this when showing a list of users, together with `orderBy: 'name'`,
 * to order users correctly regardless if they have a name set or not.
 *
 * @example <span>{{ $ctrl.users | setUserNames | orderBy: 'name' }}</span>
 */

function setUserNames(_, $filter) {
  'ngInject';

  return (users, property = 'name') =>
    _.map(users, (user) => {
      _.set(user, property, $filter('getName')(user));
      return user;
    });
}
setUserNames.$inject = ['_', '$filter'];

angular.module('cdApp.shared').filter('setUserNames', setUserNames);
