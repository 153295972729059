import { selector, selectorFamily } from 'recoil';

import { ApiSearchResult, mainApi } from '../../api';
import { WidgetInterface } from '../widgets/types/widget.type';
import { handleError } from '../../services/ErrorHandlingService';
import { WebsiteInterface } from '../websitelandingpage/types/website.type';

export const WidgetListQuery = selectorFamily<
  ApiSearchResult<WidgetInterface>,
  { types: string[]; searchKey: string }
>({
  key: 'WidgetListQuery',
  get:
    ({ types, searchKey }: { types: string[]; searchKey: string }) =>
    async ({ get }) => {
      const widgets = await get(GetWidgetList);

      let filteredWidgets = widgets.items;
      if (types.length > 0) {
        filteredWidgets = filteredWidgets.filter((widget) =>
          types.includes(widget.type)
        );
      }
      filteredWidgets = filteredWidgets.filter((widget) =>
        widget.name.toLowerCase().includes(searchKey.trimStart().toLowerCase())
      );
      return {
        items: filteredWidgets,
        total: filteredWidgets.length,
      };
    },
});

export const GetWidgetList = selector<ApiSearchResult<WidgetInterface>>({
  key: 'GetWidgetList',
  get: async () => {
    const response = await mainApi.get<WidgetInterface[]>('/widgets');
    if (!response.ok) {
      handleError(response);
      return { items: [], total: 0 };
    }
    return { items: response.data, total: response.data.length };
  },
});

export const WebsiteListQuery = selector<WebsiteInterface>({
  key: 'WebsiteListQuery',
  get: async () => {
    const response = await mainApi.get<WebsiteInterface>('/sites');
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
