import { react2angular } from 'react18-react2angular';

import { WebsiteAnalyticsPageWrapper } from '../../react/homepage/widgets/AnalyticsPage';
import sharedContext from '../shared/components/shared-context-root/shared-context-root.component';

import { WebsiteLandingPageWrapper } from '@/react/homepage/websitelandingpage/WebsiteLandingPage';
import { HomepageWidgetsPage } from '@/react/homepage/widgets/HomepageWidgets.page';

angular
  .module('cdApp.homepage')
  .component(
    'websiteLandingPage',
    react2angular(sharedContext.use(WebsiteLandingPageWrapper), [], [])
  );

angular
  .module('cdApp.homepage')
  .component(
    'homepageWidgetsPage',
    react2angular(sharedContext.use(HomepageWidgetsPage), [], [])
  );
angular
  .module('cdApp.homepage')
  .component(
    'analyticsPage',
    react2angular(sharedContext.use(WebsiteAnalyticsPageWrapper), [], [])
  );
