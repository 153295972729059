import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import IntentionReportService from '../services/IntentionReportService';
import {
  FetchOrderOfServiceReportEventsActionType,
  fetchOrderOfServiceReportEventsSuccess,
} from '../redux/intention-reports/Actions';

export function* fetchOrderOfServiceReportEventsSaga(
  action: FetchOrderOfServiceReportEventsActionType
): SagaIterator {
  const data = yield call(
    IntentionReportService.fetchOrderOfServiceReportEvents,
    action.payload
  );
  yield put(fetchOrderOfServiceReportEventsSuccess(data));
}
