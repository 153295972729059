import { call, put } from 'redux-saga/effects';

import { getGebAccess } from '../../services';
import { getGebAccessSuccess } from '../actions';

export function* getGebAccessSaga(action) {
  try {
    const { payload } = action;
    const { partner } = payload;
    const result = yield call(getGebAccess, partner);
    yield put(getGebAccessSuccess(result));
  } catch (error) {
    yield put(getGebAccessSuccess({ isCustomer: false }));
  }
}
