import React, { Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Button, Dropdown, MenuProps } from 'antd';
import { ButtonType } from 'antd/es/button';
import { useRecoilValue } from 'recoil';
import { $injector } from 'ngimport';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { MessageType, PeopleMessageRecipient } from '../../../types/message';

import {
  CdStudio,
  CdMessage,
  CdBasic,
  CdMobile,
  CdAngleDown,
  CdStudioEventInvitation,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const PeopleMessagesAngular = () =>
  $injector.get('PeopleMessages') as any;

interface MessageListCreateDropdownProps {
  fetchRecipientsFromContext?: {
    ref: (messageType: string) => PeopleMessageRecipient[];
  };
  recipients?: PeopleMessageRecipient[];
  buttonLabel?: string;
  buttonDisabled?: boolean;
  buttonSize?: SizeType;
  recipientsHasSMS?: boolean;
  recipientsHasEmail?: boolean;
  visibleNewsletter?: boolean;
  visibleInvitation?: boolean;
  disableStudio?: boolean;
  isGlobalSelect?: boolean;
  buttonType?: ButtonType;
  // Before navigate can be used to close a modal.
  beforeNavigate?: () => void;
  // Important. openInNewTab only works with contacts and not segments in the "recipients" attribute
  openInNewTab?: boolean;
}
const MessageListCreateDropdown = (props: MessageListCreateDropdownProps) => {
  const {
    recipientsHasSMS = true,
    recipientsHasEmail = true,
    visibleInvitation = true,
    visibleNewsletter = true,
    disableStudio = false,
    buttonType = 'primary',
  } = props;
  const canCommunicate = useRecoilValue(
    UserHasPermissionAnywhereQuery('people.communicate')
  );
  const handleClick = ({ key }) => {
    const to: any[] = (props.fetchRecipientsFromContext?.ref(key) || []).concat(
      props.recipients || []
    );
    props.beforeNavigate && props.beforeNavigate();

    switch (key) {
      case MessageType.BASIC:
      case MessageType.SIMPLE:
        if (props.openInNewTab) {
          const url = getStateUrl('app.private.people.messages.editorV2.view', {
            type: key,
            contactIds: to.map((c) => c.id),
          });
          window.open(url, '_blank');
        } else {
          navigate('app.private.people.messages.editorV2.view', {
            type: key,
            to,
            sourceInfo: to[0]?.anniversary && {
              anniversary: {
                uniqueId: to[0].uniqueId,
                type: to[0].anniversary.type,
                label: to[0].anniversary.label,
                date: to[0].anniversary.date,
                yearSince: to[0].anniversary.yearSince,
                inComingDate: to[0].anniversary.inComingDate,
              },
            },
          });
        }
        break;
      case MessageType.SMS:
        let messageObject = undefined;
        if (props.fetchRecipientsFromContext?.ref || props.recipients) {
          messageObject = PeopleMessagesAngular().empty();
          messageObject.type = key;
          messageObject.to = to;
          if (to[0]?.anniversary)
            messageObject.sourceInfo = {
              anniversary: {
                uniqueId: to[0].uniqueId,
                type: to[0].anniversary.type,
                label: to[0].anniversary.label,
                date: to[0].anniversary.date,
                yearSince: to[0].anniversary.yearSince,
                inComingDate: to[0].anniversary.inComingDate,
              },
            };
        }

        navigate('app.private.people.messages.create', {
          type: key,
          messageObject,
        });
        break;
      case MessageType.EVENT_INVITATION:
        NiceModal.show('CalendarEmailPosterTemplates', { recipients: to });
        break;
      case MessageType.ADVANCED:
        NiceModal.show('TemplateSelectorModal', {
          recipients: to,
          sourceInfo: to[0]?.anniversary && {
            anniversary: {
              uniqueId: to[0].uniqueId,
              type: to[0].anniversary.type,
              label: to[0].anniversary.label,
              date: to[0].anniversary.date,
              yearSince: to[0].anniversary.yearSince,
              inComingDate: to[0].anniversary.inComingDate,
            },
          },
        });
        break;
    }
  };

  const items: MenuProps['items'] = [];

  if (!disableStudio && recipientsHasEmail) {
    items.push({
      key: MessageType.ADVANCED,
      onClick: handleClick,
      icon: <CdStudio fixedWidth />,
      label: gettextCatalog.getString('Studio'),
    });
  }
  if (recipientsHasEmail) {
    if (visibleInvitation) {
      items.push({
        key: MessageType.EVENT_INVITATION,
        onClick: handleClick,
        icon: <CdStudioEventInvitation fixedWidth />,
        label: gettextCatalog.getString('Send invitation'),
      });
    }
    if (visibleNewsletter) {
      items.push({
        key: MessageType.SIMPLE,
        onClick: handleClick,
        icon: <CdMessage fixedWidth />,
        label: gettextCatalog.getString('Newsletter'),
      });
    }
    items.push({
      key: MessageType.BASIC,
      onClick: handleClick,
      icon: <CdBasic fixedWidth />,
      label: gettextCatalog.getString('E-mail'),
    });
  }
  if (recipientsHasSMS) {
    items.push({
      key: MessageType.SMS,
      onClick: handleClick,
      icon: <CdMobile fixedWidth />,
      label: gettextCatalog.getString('Text message'),
    });
  }
  return (
    <Dropdown
      menu={{ items }}
      disabled={!canCommunicate || props.buttonDisabled}
    >
      <CdTooltip
        title={
          props.buttonDisabled && props.isGlobalSelect
            ? gettextCatalog.getString(
                'Please make a filter or a list if you want to communicate to many contacts at the same time.'
              )
            : undefined
        }
      >
        <Button
          type={buttonType}
          disabled={!canCommunicate || props.buttonDisabled}
          size={props.buttonSize || 'middle'}
        >
          {props.buttonLabel || gettextCatalog.getString('Create')}
          <CdAngleDown />
        </Button>
      </CdTooltip>
    </Dropdown>
  );
};

MessageListCreateDropdown.Skeleton = (props) => (
  <Dropdown menu={null}>
    <Button type="primary" disabled loading size={props.buttonSize || 'middle'}>
      {props.buttonLabel || gettextCatalog.getString('Create')} <CdAngleDown />
    </Button>
  </Dropdown>
);
// @ts-ignore typescript don't understand that a function in javascript is just a fancy object. and we are allowed to set props on it
MessageListCreateDropdown.Skeleton.displayName =
  'MessageListCreateDropdownSkeleton';

export default function MessageListCreateDropdownWithSuspense(
  props: MessageListCreateDropdownProps
) {
  return (
    <Suspense fallback={<MessageListCreateDropdown.Skeleton {...props} />}>
      <MessageListCreateDropdown {...props} />
    </Suspense>
  );
}
