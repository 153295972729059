import { atom, selectorFamily } from 'recoil';

import {
  PeopleMessageTemplate,
  PeopleMessageTemplateFromList,
  TemplateAuthor,
  TemplateType,
} from '../types/message-template.type';

import { mainApi } from '@/react/api';

export const MessageTemplateCacheBuster = atom({
  key: 'MessageTemplateCacheBuster',
  default: 0,
});

export const MessageTemplatesByTypeQuery = selectorFamily<
  PeopleMessageTemplateFromList[],
  { authoredBy?: TemplateAuthor; type: TemplateType }
>({
  key: 'MessageTemplatesByTypeQuery',
  get:
    ({ authoredBy, type }) =>
    async ({ get }) => {
      get(MessageTemplateCacheBuster);
      const { ok, data } = await mainApi.get<PeopleMessageTemplateFromList[]>(
        `/v2/people/message-templates?type=${type}`
      );
      if (ok) {
        if (!authoredBy) return data;
        return data.filter((segment) => segment.templateSource === authoredBy);
      }
    },
});

export const MessageTemplatesByIdQuery = selectorFamily<
  PeopleMessageTemplate,
  string
>({
  key: 'MessageTemplatesByIdQuery',
  get: (id: string) => async () => {
    const { ok, data } = await mainApi.get<PeopleMessageTemplate>(
      `/v2/people/message-templates/${id}`
    );
    if (ok) {
      return data;
    }
  },
});
