import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { WebsiteListQuery } from '../../store/widgetService';
import { mainApi } from '../../../api';
import { WebsiteInterface } from '../types/website.type';
import { handleError } from '../../../services/ErrorHandlingService';
import { WebsiteLoginLoading } from '../store/website';

export const useWebsite = () => {
  const websiteList = useRecoilValue(WebsiteListQuery);
  const websiteLoginLoading = useRecoilValue(WebsiteLoginLoading);
  const refreshWebsiteList = useRecoilRefresher(WebsiteListQuery);
  const websiteLogin = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        set(WebsiteLoginLoading, (prev) => ({ ...prev, [id]: true }));
        const response = await mainApi.get<WebsiteInterface[]>(
          `/sites/${id}/login`
        );
        if (!response.ok) {
          handleError(response);
          set(WebsiteLoginLoading, (prev) => ({ ...prev, [id]: false }));
          return null;
        }
        window.open(response.data['siteLoginUrl'], '_blank');
        set(WebsiteLoginLoading, (prev) => ({ ...prev, [id]: false }));
      },
    []
  );

  const deleteWebsite = useRecoilCallback(
    () => async (id: string) => {
      const response = await mainApi.delete<WebsiteInterface[]>(`/sites/${id}`);
      if (!response.ok) {
        handleError(response);

        return null;
      }
      refreshWebsiteList();
    },
    [refreshWebsiteList]
  );

  const goToMonoWebsite = async (item) => {
    await websiteLogin(item.id);
  };
  return {
    websiteList,
    websiteLoginLoading,
    goToMonoWebsite,
    deleteWebsite,
  };
};
