import React from 'react';
import styled from 'styled-components';

import cdApp from '@/react/config';

const ScaledIframe = styled.div`
  width: 500px;
  height: 400px;
  border: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;

  #scaled-frame {
    height: 800px;
    border: 0px;
  }

  #scaled-frame {
    zoom: 0.75;
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: top center;
    -webkit-transform-origin: top center;
    -o-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    #scaled-frame {
      zoom: 1;
    }
  }
`;

export const PeopleSignUpDemo = () => (
  <ScaledIframe>
    <iframe
      src={`${cdApp.references.portal}/signup/${cdApp.organization.id}?demo=1`}
      id="scaled-frame"
    />
  </ScaledIframe>
);
