import { useState, useEffect } from 'react';
import {
  RecoilState,
  RecoilValue,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';

import { CdTableData, onTableChangeProps } from '../cd-table/types';

import { TableOrdering } from '@/react/shared/models/table';
import { ApiSearchParams, ApiSearchResult } from '@/react/api';

export interface useCdQueryTableProps<T> {
  dataResult: ({
    limit,
    pageNumber,
    extraParams,
  }: ApiSearchParams) => RecoilValue<ApiSearchResult<T>>;
  searchParams: RecoilState<Record<string, any>>;
  defaultPageSize?: number;
  extraParams?: {
    extraIntentionsData?: any;
    eventCategoryIds?: number[];
  };
}

export interface useCdQueryTableResult<T> {
  data: CdTableData<T>;
  handleTableChange: (
    pageNumber: number,
    pageSize?: number,
    order?: TableOrdering
  ) => void;
  isFetching: boolean;
  page?: [number, React.Dispatch<React.SetStateAction<number>>];
}

export function useCdQueryTable<T>({
  dataResult,
  searchParams,
  defaultPageSize = 30,
  extraParams,
}: useCdQueryTableProps<T>) {
  const [params, setParams] = useRecoilState(searchParams);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const data = useRecoilValueLoadable(
    dataResult({
      pageNumber,
      limit: defaultPageSize,
      extraParams,
    })
  );
  const [pageDataState, setPageDataState] = useState({
    items: data.contents?.items || [],
    total: data.contents?.total || 0,
  });

  useEffect(() => {
    // When filtering or sorting params change we default back to page 1.
    setPageNumber(1);
  }, [params]);

  useEffect(() => {
    if ('hasValue' === data.state) {
      setPageDataState({
        items: data.contents.items,
        total: data.contents.total,
      });
    }
  }, [data, defaultPageSize, pageNumber]);

  const handleTableChange: onTableChangeProps = (
    pageNumber: number,
    pageSize: number,
    ordering: TableOrdering,
    changedProperty: 'pagination' | 'ordering'
  ) => {
    if (changedProperty === 'ordering') {
      setParams((val) => ({
        ...val,
        sortBy: ordering?.orderBy,
        sortDirection: ordering?.orderDirection.toLowerCase(),
      }));
    }
  };

  return {
    data: pageDataState,
    page: [pageNumber, setPageNumber],
    handleTableChange,
    isFetching: data.state === 'loading',
  } as useCdQueryTableResult<T>;
}
