import { selector } from 'recoil';

import { ApiSearchResult, mainApi } from '../../api';
import { WebsiteAnalyticsInterface } from '../widgets/types/widget.type';
import { handleError } from '../../services/ErrorHandlingService';

export const WebsiteAnalytics = selector<
  ApiSearchResult<WebsiteAnalyticsInterface>
>({
  key: 'WebsiteAnalytics',
  get: async () => {
    const response = await mainApi.get<WebsiteAnalyticsInterface[]>(`/beats`);
    if (!response.ok) {
      handleError(response);
      return { items: [], total: 0 };
    }
    return {
      items: response.data,
      total: response.data.length,
    };
  },
});
