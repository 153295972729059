import _ from 'lodash';
import { $injector } from 'ngimport';
import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { Foundation } from '../models/foundation';
import { handleSuccessMessage } from '../../shared/utils';
import FoundationService from '../services/FoundationService';
import IntentionService, { IntentionData } from '../services/IntentionService';
import {
  Intention,
  IntentionStatusTypes,
  IntentionTabNames,
  IntentionPriorityTypes,
  IntentionReferenceNumberSourceType,
} from '../models/intention';
import StateServiceFactory, {
  navigate,
  RootScopeServiceFactory,
} from '../../services/StateServiceFactory';
import {
  clearIntention,
  fetchEventIntentions,
  fetchIntentions,
  FetchIntentionsActionType,
  fetchIntentionEventsSuccess,
  FetchIntentionEventsType,
  fetchIntentionsSuccess,
  FetchIntentionActionType,
  fetchIntentionSuccess,
  fetchEventIntentionsSuccess,
  FetchEventIntentionsActionType,
  CreateIntentionActionType,
  UpdateIntentionActionType,
  DeleteIntentionActionType,
  AssignIntentionType,
  UnassignIntentionType,
  UpdateEventPriorityActionType,
  FetchIntentionParentActionType,
  fetchIntentionParentSuccess,
  RestoreIntentionActionType,
  refreshIntentionOverview,
  RefreshIntentionOverviewType,
  FetchBillingIntentionsType,
  fetchBillingIntentionsSuccess,
  GenerateIntentionBillingReportActionType,
  GenerateIntentionListReportActionType,
} from '../redux/intentions/Actions';
import { fetchIntentionsForFoundation } from '../redux/foundations/Actions';
import IntentionReportService from '../services/IntentionReportService';
import ErrorHandlingService from '../../services/ErrorHandlingService';

// Intention sagas

export function* getIntentionsSaga(
  action: FetchIntentionsActionType
): SagaIterator {
  const data = yield call(IntentionService.getIntentions, action.payload);
  yield put(fetchIntentionsSuccess(data as IntentionData));
}

export function* getIntentionSaga(
  action: FetchIntentionActionType
): SagaIterator {
  const intention = yield call(IntentionService.getIntention, action.payload);
  yield put(fetchIntentionSuccess(intention as Intention));
}

export function* getIntentionParentSaga(
  action: FetchIntentionParentActionType
): SagaIterator {
  const foundation = yield call(
    FoundationService.getFoundation,
    action.payload
  );
  yield put(fetchIntentionParentSuccess(foundation as Foundation));
}

export function* getEventIntentionsSaga(
  action: FetchEventIntentionsActionType
): SagaIterator {
  const data = yield call(
    IntentionService.fetchEventIntentions,
    action.payload
  );
  yield put(fetchEventIntentionsSuccess(data));
}

export function* fetchIntentionEventsSaga(
  action: FetchIntentionEventsType
): SagaIterator {
  const data = yield call(
    IntentionService.fetchIntentionEvents,
    action.payload
  );
  yield put(fetchIntentionEventsSuccess(data));
}

export function* fetchBillingIntentionSaga(
  action: FetchBillingIntentionsType
): SagaIterator {
  const billingEvents = yield call(
    IntentionService.fetchIntentionBillingEvents,
    action.payload
  );

  const billingIntentions = [];
  _.each(billingEvents, (event) => {
    _.each(_.get(event, 'intentions'), (intention) => {
      // Add to list
      billingIntentions.push(intention);
    });
  });

  yield put(fetchBillingIntentionsSuccess(billingIntentions));
}

export function* assignIntentionSaga(
  action: AssignIntentionType
): SagaIterator {
  yield call(IntentionService.assignIntention, action.payload);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully assigned intention.')
  );
  if (action.payload.fromEvent) {
    yield put(fetchEventIntentions(action.payload.calendarId));
  } else {
    const rootScope = RootScopeServiceFactory();
    const stateService = StateServiceFactory();

    // If previous tab has id in the url it is from an event, so go back there
    const previousStateParamsId = _.get(rootScope, 'previousStateParams.id');
    if (previousStateParamsId) {
      // Go back to previous state
      stateService.go(_.get(rootScope, 'previousState.name'), {
        id: previousStateParamsId,
      });
    } else {
      // If not from event, go back to the intentions screen with intention on it
      stateService.go('app.private.intention.overview');
    }
  }
}

export function* unassignIntentionSaga(
  action: UnassignIntentionType
): SagaIterator {
  const { id, calendarId, fromTab, foundationId, type, filters } =
    action.payload;
  yield call(IntentionService.unassignIntention, id);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully unassigned intention.')
  );
  // Refresh event to reflect intention changes associated
  if (calendarId) {
    yield put(fetchEventIntentions(calendarId));
  } else {
    yield put(
      refreshIntentionOverview({ fromTab, foundationId, type, filters })
    );
  }
}

export function* createIntentionSaga(
  action: CreateIntentionActionType
): SagaIterator {
  const { intention, calendarId } = action.payload;
  if (calendarId) _.extend(intention, { calendarId });

  const newIntention: Partial<Intention> = yield call(
    IntentionService.createIntention,
    intention
  );
  const gettextCatalog: any = $injector.get('gettextCatalog');

  if (calendarId && newIntention) {
    // Show success message
    yield call(
      handleSuccessMessage,
      gettextCatalog.getString('Successfully created and assigned intention.')
    );
    // Navigate back to event
    navigate('app.private.calendar.event', { id: calendarId });
  } else {
    // Show success message
    yield call(
      handleSuccessMessage,
      gettextCatalog.getString('Successfully created intention.')
    );
    // Refresh intentions by re-fetching
    const status = IntentionStatusTypes.UNASSIGNED;
    yield put(fetchIntentions({ status }));
    // Navigate to the backlog of intentions
    navigate('app.private.intention.overview');
  }
}

export function* updateIntentionSaga({
  payload: {
    id,
    updatePayload,
    calendarId,
    status,
    shouldNavigate = true,
    stiftungId,
    legatId,
  },
}: UpdateIntentionActionType): SagaIterator {
  yield call(IntentionService.updateIntention, id, updatePayload);
  // Clear the selected intention
  yield put(clearIntention());
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully updated intention.')
  );

  // Refresh event to reflect intention changes associated
  if (calendarId) {
    yield put(fetchEventIntentions(calendarId));
    // Navigate back to event
    if (shouldNavigate) {
      navigate('app.private.calendar.event', { id: calendarId });
    }
  } else if (stiftungId) {
    navigate('app.private.intention.stiftungen.edit', {
      id: stiftungId,
      currentState: 'edit',
    });
  } else if (legatId) {
    navigate('app.private.intention.legate.edit', {
      id: legatId,
      currentState: 'edit',
    });
  } else {
    // Refresh intentions by re-fetching
    yield put(
      fetchIntentions({
        status: !_.isNil(status) ? status : undefined,
        includeDeleted: _.isNil(status),
      })
    );
    // Navigate to the corresponding listing
    if (shouldNavigate) {
      navigate('app.private.intention.overview');
    }
  }
}

export function* deleteIntentionSaga(
  action: DeleteIntentionActionType
): SagaIterator {
  const { id, calendarId, fromTab, foundationId, type, filters } =
    action.payload;
  yield call(IntentionService.deleteIntention, id);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully deleted intention.')
  );
  // Refresh data
  if (calendarId) {
    // Refresh event to reflect intention changes associated (if the Saga is being called from event view)
    yield put(fetchEventIntentions(calendarId));
  } else {
    yield put(
      refreshIntentionOverview({ fromTab, foundationId, type, filters })
    );
  }
}

export function* restoreIntentionSaga(
  action: RestoreIntentionActionType
): SagaIterator {
  const { id, calendarId, fromTab, foundationId, type, filters } =
    action.payload;
  yield call(IntentionService.restoreIntention, id);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully restored intention.')
  );

  // Refresh data
  if (calendarId) {
    // Refresh event to reflect intention changes associated (if the Saga is being called from event view)
    yield put(fetchEventIntentions(calendarId));
  } else {
    yield put(
      refreshIntentionOverview({ fromTab, foundationId, type, filters })
    );
  }
}

export function* updateEventPrioritySaga(
  action: UpdateEventPriorityActionType
): SagaIterator {
  const { newPrimaryIntentionId, oldPrimaryIntentionId, calendarId } =
    action.payload;
  if (oldPrimaryIntentionId) {
    // Update the old primary intention to change its priority to secondary
    yield call(
      IntentionService.updateIntentionPriority,
      oldPrimaryIntentionId,
      IntentionPriorityTypes.WEITERLEITUNG
    );
  }
  // Update the new primary intention to change its priority to primary
  yield call(
    IntentionService.updateIntentionPriority,
    newPrimaryIntentionId,
    IntentionPriorityTypes.PERSOLVIERT
  );
  // Refetch the events intentions to show changes
  yield put(fetchEventIntentions(calendarId));
}

export function* refreshIntentionOverviewSaga(
  action: RefreshIntentionOverviewType
): SagaIterator {
  const { fromTab, foundationId, type, filters } = action.payload;
  const includeDeleted = fromTab === IntentionTabNames.ALL;
  const status = includeDeleted ? null : (fromTab as any);

  if (foundationId && type && fromTab) {
    // Fetching intentions for specific foundation page
    yield put(
      fetchIntentionsForFoundation({
        id: foundationId,
        type,
        includeDeleted,
        status,
      })
    );
  } else {
    // Fetching all intentions for specific status
    yield put(fetchIntentions(_.extend(filters, { status, includeDeleted })));
  }
}

export function* generateIntentionBillingReport(
  action: GenerateIntentionBillingReportActionType
): SagaIterator {
  const downloadToken = yield call(
    IntentionReportService.fetchIntentionBillingReportDownloadToken
  );
  const { month, year, churchIds } = action.payload;
  const params = $.param({
    month,
    year,
    churchId: _.first(churchIds),
    downloadToken,
  });
  const fileUrl = `${window.cdApp.config.api.main}/intention/intention-reports/intention-billing/report?${params}`;
  yield call(window.open, fileUrl, '_blank');
}

export function* generateIntentionListReportSaga(
  action: GenerateIntentionListReportActionType
): SagaIterator {
  const gettextCatalog: any = $injector.get('gettextCatalog');
  const downloadToken = yield call(
    IntentionReportService.fetchIntentionListReportDownloadToken
  );
  const { intentionYear, churchIds } = action.payload;
  // Check if there is intentions fitting the report params
  const result = yield call(IntentionService.getIntentions, {
    churchIds,
    referenceNumberYear: intentionYear,
    referenceNumberSourceType: IntentionReferenceNumberSourceType.INTENTION,
    includeDeleted: true,
  });
  const numIntentions = _.get(result, 'count') || 0;
  if (numIntentions > 0) {
    const params = $.param({ intentionYear, churchIds, downloadToken });
    const fileUrl = `${window.cdApp.config.api.main}/intention/intention-reports/intention-list/report?${params}`;
    yield call(window.open, fileUrl, '_blank');
  } else {
    const error = gettextCatalog.getString(
      'No intentions were found for the specified filters.'
    );
    yield call(ErrorHandlingService.handleError, error);
  }
}
