import { mainApi } from '@/react/api';
import cdApp from '@/react/config';
import { handleError } from '@/react/services/ErrorHandlingService';

export const checkIfAliasExist = async ({
  alias,
  entityId,
  entityType,
}: { alias: string; entityId?: number; entityType?: string }) => {
  const response = await mainApi.get<any>(`/v2/organizations/alias-check`, {
    alias,
    organizationId: cdApp.organization.id,
    entityId,
    entityType,
  });

  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};
export function debounceValidator(fn, delay) {
  let timeoutId;

  return (...args) =>
    new Promise((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(async () => {
        try {
          const result = await fn(...args);
          resolve(result); // Validation passed
        } catch (error) {
          reject(error); // Validation failed
        }
      }, delay);
    });
}

export function angularDebounceValidator(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
