import { combineReducers } from 'redux';

import IntentionReducer, {
  ImmutableIntentionState,
} from './intentions/intentions';
import FoundationReducer, {
  ImmutableFoundationState,
} from './foundations/foundations';
import IntentionReportReducer, {
  ImmutableIntentionReportState,
} from './intention-reports/reports';
import StolesReducer, { ImmutableStolesState } from './stoles/stoles';
import collection, { ImmutableCollectionState } from './collection/collection';

// State combination

export interface IntentionCombinedState {
  intention: ImmutableIntentionState;
  foundation: ImmutableFoundationState;
  report: ImmutableIntentionReportState;
  stoles: ImmutableStolesState;
  collection: ImmutableCollectionState;
}

export default combineReducers<IntentionCombinedState>({
  intention: IntentionReducer,
  foundation: FoundationReducer,
  report: IntentionReportReducer,
  stoles: StolesReducer,
  collection,
});
