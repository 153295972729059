(function () {
  'use strict';

  function BlogMainController(
    moment,
    $scope,
    $stateParams,
    $http,
    $uibModal,
    $state,
    toastr,
    gettextCatalog,
    Revisions,
    Users,
    Blogs
  ) {
    // =============================================================================
    // Current blog.
    // =============================================================================

    // Fetch more information about the current blog.
    Blogs.get({ id: $stateParams.id }, function (blog) {
      $scope.currentBlog = blog;
    });

    // =============================================================================
    // Revisions of the current blog.
    // =============================================================================

    // Fetch revisions for the current event.
    Revisions.query(
      {
        entityType: 'blog',
        entityId: $stateParams.id,
      },

      function (response) {
        $scope.revisions = response;

        Users.query(function (users) {
          _.each($scope.revisions, function (revision, index) {
            $scope.revisions[index].revisionAuthorName = _.result(
              _.find(users, { id: revision.revisionAuthorId }),
              'name'
            );
          });
        });
      }
    );

    // Revert to another version.
    $scope.revert = function (revision) {
      $uibModal
        .open({
          templateUrl: 'confirmBlogRevert.html',
          scope: $scope,
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              'ngInject';

              $scope.save = function () {
                let url =
                  cdApp.config.api.main +
                  '/revisions/blog/' +
                  $stateParams.id +
                  '/revert/' +
                  revision.id;

                $http.post(url).then(
                  function () {
                    toastr.success(
                      gettextCatalog.getString(
                        '"{{title}}" has been reverted to another version.',
                        {
                          title: $scope.currentBlog.title,
                        }
                      )
                    );

                    $uibModalInstance.close();
                  },
                  function (error) {
                    toastr.error(
                      _.get(error, 'data.message') ||
                        gettextCatalog.getString(
                          'An error occurred, please try again. If the problem persists, please contact our support.'
                        )
                    );
                  }
                );
              };

              $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
              };
            },
          ],
        })
        .result.then(function () {
          $state.reload();
        });
    };

    // =============================================================================
    // Utilities.
    // =============================================================================

    // Generate a date from timestamp to e.g. Wednesday, July 8, 2015 4:22 PM
    $scope.Date = function (number) {
      return moment(number).format('LLLL');
    };
  }
  BlogMainController.$inject = [
    'moment',
    '$scope',
    '$stateParams',
    '$http',
    '$uibModal',
    '$state',
    'toastr',
    'gettextCatalog',
    'Revisions',
    'Users',
    'Blogs',
  ];

  angular
    .module('cdApp.intranet')
    .controller('BlogMainController', BlogMainController);
})();
