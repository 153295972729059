import { useRecoilCallback } from 'recoil';
import moment from 'moment';

import { BlogSearchText, FormSearchTextState } from '../store/message';

import { EventSearchParams } from '@/react/calendar/store/events/calendar-view';
import { ContributionsSearchAtom } from '@/react/shared/store/contributions';

export const useContentBlock = () => {
  const resetAllSearchFormInDrawer = useRecoilCallback(({ set }) => () => {
    set(BlogSearchText, '');
    set(EventSearchParams, {
      limit: 100,
      offset: 0,
      startDate: moment().toISOString(),
      endDate: moment().add(1, 'month').toISOString(),
    });
    set(ContributionsSearchAtom, { title: '' });
    set(FormSearchTextState, '');
  });

  return {
    resetAllSearchFormInDrawer,
  };
};
