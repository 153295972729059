import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { AppState } from '../../../redux';
import { Resource } from '../../models/resource';

const resourcesEntity = createEntityAdapter<Resource>({
  selectId: (resource) => resource.id,
});

export type ResourcesState = EntityState<Resource>;
// Slice
const resourcesSlice = createSlice({
  name: 'resources',
  initialState: resourcesEntity.getInitialState(),
  reducers: {
    fetchResourcesSuccess: resourcesEntity.setAll,
  },
});

export const { fetchResourcesSuccess } = resourcesSlice.actions;

export const resourcesReducer = resourcesSlice.reducer;

const selectors = resourcesEntity.getSelectors<AppState>(
  (state) => state.shared.resources
);

export const getChurches = (state: AppState) =>
  state.config.organization.churches;

export const getResources = selectors.selectAll;

export const getParentResources = createSelector(getResources, (resources) =>
  _.filter(resources, (resource) => !resource.parentResourceId)
);

export const getResourcesWithoutChurches = createSelector(
  getResources,
  (resources) => {
    const standaloneResources = _.filter(
      resources,
      (resource) => !_.get(resource, 'type')
    );
    const churches = _.filter(
      resources,
      (resource) => _.get(resource, 'type') === 'church'
    );
    const churchResources = [];
    // Get resources that belong to a church
    if (_.size(churches) > 0) {
      _.each(churches, (church) => {
        const childResources = _.get(church, 'resources');
        churchResources.push(...childResources);
      });
    }
    const allResources = _.concat(standaloneResources, churchResources);
    const allResourcesSorted = _.sortBy(allResources, (resource) =>
      _.toLower(_.get(resource, 'name'))
    );
    return allResourcesSorted;
  }
);

export const getResourcesById = selectors.selectById;
