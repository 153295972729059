import { Typography } from 'antd';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';

import { MessagePublicQuery } from '../../../store/message';

import { IframeMailViewer } from './IframeMailViewer';

import { CdCard } from '@/react/shared/components/CdCard';
import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { gettextCatalog } from '@/react/services/I18nService';

const { Title } = Typography;
const PublicNewsletterViewer = ({
  $stateParams: { uuid },
}: {
  $stateParams: { uuid: string };
}) => {
  const message = useRecoilValue(MessagePublicQuery(uuid));
  if (!message) {
    return (
      <CdCard $fill>
        <CdNotFound
          title={gettextCatalog.getString('Not found')}
          subTitle={gettextCatalog.getString('The message could not be found.')}
        />
        ;
      </CdCard>
    );
  }
  return (
    <>
      <Title level={2} style={{ textAlign: 'center' }}>
        {message?.title}
      </Title>
      <IframeMailViewer value={message?.rendered} minHeight="750px" />
    </>
  );
};

export const PublicNewsletter = (props) => (
  <div style={{ width: '100%', height: '100%' }}>
    <Suspense fallback={<CdrPageLoader hideButtons />}>
      <PublicNewsletterViewer {...props} />
    </Suspense>
  </div>
);
