'use strict';

import * as _ from 'lodash';

import { IntentionStatusTypes } from '../../../../../react/intention/models/intention';
import { getEventIntentions } from '../../../../../react/intention/redux/intentions/Selectors';
import { fetchEventIntentions } from '../../../../../react/intention/redux/intentions/Actions';
import { fullCalendarActions } from '../../../../../react/calendar/store/events/fullCalendarEventsSlice';

class EventDeleteModalComponent {
  constructor(
    Authorization,
    gettextCatalog,
    toastr,
    $ngRedux,
    $scope,
    $uibModal
  ) {
    'ngInit';

    this.Authorization = Authorization;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.$uibModal = $uibModal;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  // Lifecycle functions

  $onInit() {
    this.event = this.resolve.event;
    this.isRepeated = !_.isNull(this.event.rrule);
    this.isSharedWithMultipleGroups = _.size(this.event.groups) > 1;
    this.busy = false;
    this.hasIntentionsAccess = this.Authorization.hasPackage('intentions');

    // Load data
    if (this.hasIntentionsAccess) {
      this.fetchEventIntentions(this.event.id);
    }

    this.deleteEvent = this.deleteEvent.bind(this);
  }

  // Main controller functions

  deleteEvent(action) {
    const areUsersBookedToEvent = !_.isEmpty(this.event.users);
    const areUsersBookedForShifts = this.event.shifts?.length > 0;
    const shouldNotifyUsers = areUsersBookedToEvent || areUsersBookedForShifts;

    const makeDeleteRequest = (notify) => {
      const type = this.event.type;
      this.busy = true;

      this.event.$delete(
        { id: this.event.id, deleteMethod: action, sendNotifications: notify },
        () => {
          this.busy = false;
          this.reloadCurrentView();
          if (type === 'event') {
            this.toastr.success(
              notify
                ? this.gettextCatalog.getString(
                    'The event has been deleted and the users have been notified.'
                  )
                : this.gettextCatalog.getString('The event has been deleted.')
            );
          } else {
            this.toastr.success(
              this.gettextCatalog.getString('The absence has been deleted.')
            );
          }

          this.close();
        },
        (error) => {
          this.busy = false;
          this.toastr.error(
            _.get(error, 'data.message') ||
              this.gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
      );
    };
    if (
      this.event.type === 'absence' ||
      !shouldNotifyUsers ||
      !this.Authorization.hasPermission('canNotify')
    ) {
      return makeDeleteRequest(false);
    }

    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Notify users?'),
          body: () =>
            this.gettextCatalog.getString(
              'The event you are deleting has one or more users booked. Would you like to notify those users about this action?'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Notify'),
            closeButtonText: this.gettextCatalog.getString("Don't notify"),
            confirmButtonType: 'primary',
          },
        },
      })
      .result.then(() => makeDeleteRequest(true))
      .catch(() => makeDeleteRequest(false));
  }

  canEventBeDeleted() {
    const isIntentionsBlocking = this.shouldShowCompletedIntentionsError();
    return !isIntentionsBlocking;
  }

  shouldShowSingleEventDeletionWarning() {
    return !this.isRepeated && !this.isSharedWithMultipleGroups;
  }

  shouldShowAssignedIntentionsWarning() {
    return (
      this.hasIntentionsAccess &&
      _.isFinite(this.noOfAssignedIntentions) &&
      this.noOfAssignedIntentions > 0
    );
  }

  shouldShowCompletedIntentionsError() {
    return (
      this.hasIntentionsAccess &&
      _.isFinite(this.noOfCompletedIntentions) &&
      this.noOfCompletedIntentions > 0
    );
  }

  shouldDisableDeleteButton() {
    return this.busy || !this.canEventBeDeleted();
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => {
    const eventIntentions = getEventIntentions(state);
    return {
      noOfAssignedIntentions: _.size(
        _.filter(eventIntentions, ['status', IntentionStatusTypes.ASSIGNED])
      ),

      noOfCompletedIntentions: _.size(
        _.filter(eventIntentions, ['status', IntentionStatusTypes.COMPLETED])
      ),
    };
  };

  mapDispatchToScope = (dispatch) => ({
    fetchEventIntentions: (calendarId) =>
      dispatch(fetchEventIntentions(calendarId)),
    reloadCurrentView: () => dispatch(fullCalendarActions.reloadCurrentView()),
  });
}

EventDeleteModalComponent.$inject = [
  'Authorization',
  'gettextCatalog',
  'toastr',
  '$ngRedux',
  '$scope',
  '$uibModal',
];

angular.module('cdApp.calendar').component('cdEventDeleteModal', {
  templateUrl:
    '@/app/calendar/shared/components/event-delete-modal/event-delete-modal.component.html',
  controller: EventDeleteModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
