import { Form, Tag } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { get } from 'lodash';
import React from 'react';

import { gettextCatalog } from '@/react/services/I18nService';

export const NotSaved = ({
  name,
  initialValues,
}: {
  name: NamePath;
  initialValues: any;
}) => {
  const form = Form.useFormInstance();
  const watchedFieldValue = Form.useWatch(name, form);
  const initialValue = get(initialValues, name);
  const fieldHasChanged = initialValue !== watchedFieldValue;
  return (
    fieldHasChanged && (
      <Tag color="warning">{gettextCatalog.getString('Not saved')}</Tag>
    )
  );
};
