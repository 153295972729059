'use strict';

class FileUploaderComponent {
  constructor(
    $window,
    $filter,
    $uibModal,
    localStorageService,
    fileUploadService,
    userAccess,
    gettextCatalog,
    Analytics,
    cdApp,
    _
  ) {
    'ngInject';

    this.$window = $window;
    this.$filter = $filter;
    this.$uibModal = $uibModal;
    this.localStorageService = localStorageService;
    this.fileUploadService = fileUploadService;
    this.userAccess = userAccess;
    this.gettextCatalog = gettextCatalog;
    this.Analytics = Analytics;
    this.cdApp = cdApp;
    this._ = _;

    this.files = [];
    this.visibilityOptions = [
      { value: 'web', label: gettextCatalog.getString('Public') },
      {
        value: 'group',
        label: gettextCatalog.getString('Only share with group'),
      },
    ];

    this.maxImageCreditsLength = 50;
    this.enableImageCredits = _.get(
      cdApp,
      'organization.settings.enableImageCredits',
      false
    );

    this.requireImageCredits = _.get(
      cdApp,
      'organization.settings.requireImageCredits',
      false
    );

    this.imageCreditsHelperText = gettextCatalog.getString(
      "It is a good idea to provide credit/copyright information if you're going to use this image on your website or in newsletters."
    );
  }

  $onInit() {
    this.groupId = this.groupId ? parseInt(this.groupId, 10) : null;
    let defaultGroup =
      this.groups.length === 1
        ? this.groups[0]
        : this._.find(this.groups, { value: this.groupId });

    if (!this.folderId || this.folderId === 'root') {
      this.groupId = -1;
    }

    /**
     * Initialize the uploader
     */
    this.uploader = this.fileUploadService.upload({
      file_group_id: defaultGroup ? defaultGroup.value : null,
      visibility: !this.getVisibilityAccess()
        ? 'group'
        : this.fromGallery
          ? 'web'
          : null,
      formData: [
        {
          type: 'file',
          folderId:
            !this.folderId || this.folderId === 'root' ? -1 : this.folderId,
          isHtml5: this.$window.File && this.$window.FormData ? true : false,
          fromGallery: this.fromGallery || false,
        },
      ],
    });

    /**
     * Called when an error occured with a file
     */
    this.uploader.onErrorItem = (fileItem, response) => {
      if (response.message) {
        fileItem.errorText.push(response.message);
      }

      this.uploader.uploadError += 1;
    };

    /**
     * Called immediately after adding a file
     */
    this.uploader.onAfterAddingFile = (item) => {
      item.fileSize = item.file.size;
      item.fileLabel = item.file.name;
      item.errorButton = this.gettextCatalog.getString('Show error');
      item.errorText = [];
      item.isImage = this._.startsWith(item.file.type, 'image');

      // Validate file size and extension
      if (this._.size(this.validators, 'extensions') > 0) {
        const extList = this.validators.extensions.replace(/ +/g, '|');
        const regex = new RegExp(`\\.(${extList})$`, 'gi');
        const match = item.file.name.match(regex);

        if (!this._.isArray(match)) {
          item.errorText.push(
            this.gettextCatalog.getString(
              'Only files with the following extensions are allowed: {{exts}}',
              {
                exts: this.validators.extensions,
              }
            )
          );

          item.isError = true;
        }
      }

      if (this.validators.size < item.fileSize) {
        item.errorText.push(
          this.gettextCatalog.getString(
            'File size exceeds the allowed {{fileSize}} upload size',
            {
              fileSize: this.$filter('fileSize')(this.validators.size),
            }
          )
        );

        item.isError = true;
      }

      this.uploader.filesAreUploading = false;
    };

    /**
     * Called immediately before uploading a file
     */
    this.uploader.onBeforeUploadItem = (item) => {
      const organizationId = this.$window.churchdeskOrganizationId;

      item.url = `${this.cdApp.config.api.main}/files/upload?organizationId=${organizationId}`;
      item.formData[0].groupId = this.uploader.file_group_id;
      item.formData[0].visibility = this.uploader.visibility;
      item.formData[0].title = item.fileLabel;
      item.formData[0].organizationId = organizationId;

      if (item.credits) {
        item.formData[0].credits = item.credits;
      }

      if (this.fromGallery) {
        item.formData[0].fileLabel = item.fileLabel;
        item.formData[0].filename = item.fileLabel;
        item.formData[0].folderId = this._.get(this.selectedFolder, 'id', -1);
      }

      this.uploader.filesAreUploading = true;
    };

    /**
     * Called when a file has been successfully uploaded
     */
    this.uploader.onSuccessItem = (fileItem, fileResponse) => {
      if (fileResponse) {
        const file = this._.extend({}, fileResponse, {
          authorName: this.$filter('getName')(this.cdApp.me),
          groupName: this._.result(
            this._.find(this.groups, { value: fileResponse.groupId }),
            'label'
          ),
        });

        if (this._.get(fileResponse, 'fileInfo.filemime') === 'image/bmp') {
          fileItem.isWarning = true;
          fileItem.warningText = this.gettextCatalog.getString(
            'This image cannot be previewed because it is a Bitmap image. To fix this, convert the file to JPG or PNG and upload it again.'
          );
        }

        this.files.push(file);
        this.onUpload({ file });
      }
    };

    /**
     * Called when the upload of a file was cancelled
     */
    this.uploader.onCancelItem = () => {
      this.uploader.filesAreUploading = false;
    };

    /**
     * Called when all files have been successfully processed
     */
    this.uploader.onCompleteAll = () => {
      this.uploader.filesAreUploading = false;

      if (this.onComplete) {
        this.onComplete({ files: this.files });
      }
    };
  }

  getVisibilityAccess() {
    return this.userAccess.visibility_access(this.permissions);
  }

  showError(item) {
    item._showError = !item._showError;

    if (item._showError) {
      item.errorButton = this.gettextCatalog.getString('Hide error');
    } else {
      item.errorButton = this.gettextCatalog.getString('Show error');
    }
  }

  selectFromFileArchive() {
    this.onCancel({ reason: 'selectFromFileArchive' });
  }

  selectFolder() {
    this.$uibModal
      .open({
        component: 'cdSelectFolderModal',
        windowClass: 'modal-scrollable',
        resolve: {
          folders: () => this.folders,
          groups: () => this.groups,
          groupId: () => this.uploader.file_group_id,
        },
      })
      .result.then((folder) => {
        this.selectedFolder = folder;
      });
  }
}
FileUploaderComponent.$inject = [
  '$window',
  '$filter',
  '$uibModal',
  'localStorageService',
  'fileUploadService',
  'userAccess',
  'gettextCatalog',
  'Analytics',
  'cdApp',
  '_',
];

angular.module('cdApp.shared').component('cdFileUploader', {
  templateUrl:
    '@/app/shared/components/file-uploader/file-uploader.component.html',
  bindings: {
    groups: '<',
    groupId: '<',
    folders: '<',
    folderId: '<',
    permissions: '<',
    validators: '<',
    fromGallery: '<',
    onUpload: '&',
    onComplete: '&',
    onCancel: '&',
  },

  controller: FileUploaderComponent,
});
