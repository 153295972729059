import { AppState } from '../../../redux';

export const getStiftungen = (state: AppState) =>
  state.intention.foundation.stiftungen;

export const getStiftungenCount = (state: AppState) =>
  state.intention.foundation.stiftungenCount;

export const getStiftung = (state: AppState) =>
  state.intention.foundation.stiftung;

export const getLegate = (state: AppState) => state.intention.foundation.legate;

export const getLegateCount = (state: AppState) =>
  state.intention.foundation.legateCount;

export const getLegat = (state: AppState) => state.intention.foundation.legat;

export const getLegatIntentions = (state: AppState) =>
  state.intention.foundation.legatIntentions;

export const getLegatIntentionsCount = (state: AppState) =>
  state.intention.foundation.legatIntentionsCount;

export const getStiftungIntentions = (state: AppState) =>
  state.intention.foundation.stiftungIntentions;

export const getStiftungIntentionsCount = (state: AppState) =>
  state.intention.foundation.stiftungIntentionsCount;
