import { Table, Tabs } from 'antd';
import React, { FunctionComponent } from 'react';

import { gettextCatalog } from '../../../services/I18nService';

const { TabPane } = Tabs;

const TemplatePlaceholderDefinitions: FunctionComponent = () => {
  const columns = [
    {
      key: 'property',
      title: gettextCatalog.getString('Property'),
      dataIndex: 'property',
      width: '32%',
    },
    {
      key: 'placeholder',
      title: gettextCatalog.getString('Placeholder'),
      dataIndex: 'placeholder',
      width: '30%',
    },
    {
      key: 'definitions',
      title: gettextCatalog.getString('Definition'),
      dataIndex: 'definition',
      width: '38%',
    },
  ];

  const getChurchData = (mainKey, definition, placeholderSubPath = '') => ({
    key: mainKey,
    property: 'Church',
    placeholder: `{$e[i].${placeholderSubPath}church}`,
    definition,
    children: [
      {
        key: 'name',
        property: gettextCatalog.getString('Name'),
        placeholder: `{$e[i].${placeholderSubPath}church.name}`,
        definition: gettextCatalog.getString('The name of the church.'),
      },
      {
        key: 'shortName',
        property: gettextCatalog.getString('Short name'),
        placeholder: `{$e[i].${placeholderSubPath}church.shortName}`,
        definition: gettextCatalog.getString('A short name for the church.'),
      },
      {
        key: 'orderNumber',
        property: gettextCatalog.getString('Order number'),
        placeholder: `{$e[i].${placeholderSubPath}church.orderNumber}`,
        definition: gettextCatalog.getString(
          'A sequence number used for ordering the results by.'
        ),
      },
      {
        key: 'color',
        property: gettextCatalog.getString('Color'),
        placeholder: `{$e[i].${placeholderSubPath}church.color}`,
        definition: gettextCatalog.getString(
          'A color used to easily identify the church.'
        ),
      },
    ],
  });

  const getResourceData = (mainKey, definition, placeholderSubPath = '') => ({
    key: mainKey,
    property: gettextCatalog.getString('Resource'),
    placeholder: `{$e[i].${placeholderSubPath}resource}`,
    definition,
    children: [
      {
        key: 'name',
        property: gettextCatalog.getString('Name'),
        placeholder: `{$e[i].${placeholderSubPath}resource.name}`,
        definition: gettextCatalog.getString('The name of the resource.'),
      },
      {
        key: 'shortName',
        property: gettextCatalog.getString('Short name'),
        placeholder: `{$e[i].${placeholderSubPath}resource.shortName}`,
        definition: gettextCatalog.getString('A short name for the resource.'),
      },
      {
        key: 'orderNumber',
        property: gettextCatalog.getString('Order number'),
        placeholder: `{$e[i].${placeholderSubPath}resource.orderNumber}`,
        definition: gettextCatalog.getString(
          'A sequence number used for ordering the results by.'
        ),
      },
      {
        key: 'color',
        property: gettextCatalog.getString('Color'),
        placeholder: `{$e[i].${placeholderSubPath}resource.color}`,
        definition: gettextCatalog.getString(
          'A color used to easily identify the resource.'
        ),
      },
    ],
  });

  const getHolidayData = (placeholderSubPath = '') => ({
    key: 'holiday',
    property: gettextCatalog.getString('Holiday'),
    placeholder: `{$e[i].${placeholderSubPath}holyDay}`,
    definition: gettextCatalog.getString(
      "The holy days that take place on the same day as the entry's day."
    ),
    children: [
      {
        key: 'dayDate',
        property: gettextCatalog.getString('Date'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.date}`,
        definition: gettextCatalog.getString(
          'The date of the liturgical date.'
        ),
      },
      {
        key: 'weekday',
        property: gettextCatalog.getString('Weekday'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.weekday}`,
        definition: gettextCatalog.getString(
          'The weekday of the liturgical date.'
        ),
      },
      {
        key: 'title',
        property: gettextCatalog.getString('Title'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.title}`,
        definition: gettextCatalog.getString(
          'The name of the liturgical date.'
        ),
      },
      {
        key: 'description',
        property: gettextCatalog.getString('Description'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.description}`,
        definition: gettextCatalog.getString(
          'The description of the liturgical date.'
        ),
      },
      {
        key: 'rank',
        property: gettextCatalog.getString('Rank'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.rank}`,
        definition: gettextCatalog.getString(
          'H or high festival, F or festival, G or required day of remembrance, g or non-mandatory day of remembrance.'
        ),
      },
      {
        key: 'color',
        property: gettextCatalog.getString('Color'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.color}`,
        definition: gettextCatalog.getString(
          'The liturgical color (w, r, v, g).'
        ),
      },
      {
        key: 'readings1',
        property: gettextCatalog.getString('Readings 1'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.readings1}`,
        definition: gettextCatalog.getString(
          'The main readings for the celebration.'
        ),
      },
      {
        key: 'psalms',
        property: gettextCatalog.getString('Psalms'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.psalms}`,
        definition: gettextCatalog.getString('The psalms for the celebration.'),
      },
      {
        key: 'readings2',
        property: gettextCatalog.getString('Readings 2'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.readings2}`,
        definition: gettextCatalog.getString(
          'The secondary readings for the celebration.'
        ),
      },
      {
        key: 'gospel',
        property: gettextCatalog.getString('Gospel'),
        placeholder: `{$e[i].${placeholderSubPath}holyDay.gospel}`,
        definition: gettextCatalog.getString('The gospel for the celebration.'),
      },
    ],
  });

  const getCollectionData = (placeholderSubPath = '') => ({
    key: 'collection',
    property: gettextCatalog.getString('Collections'),
    placeholder: `{$e[i].${placeholderSubPath}collections[i]}`,
    definition: gettextCatalog.getString(
      "The collections that take place on the same day as the entry's day."
    ),
    children: [
      {
        key: 'purpose',
        property: gettextCatalog.getString('Purpose'),
        placeholder: `{$e[i].${placeholderSubPath}collections[i].purpose}`,
        definition: gettextCatalog.getString('The purpose of the collection.'),
      },
    ],
  });

  const getDayData = (placeholderSubPath = '') => ({
    key: 'day',
    property: gettextCatalog.getString('Day'),
    placeholder: `{$e[i].${placeholderSubPath}day:formatD('dddd DD.MM.')}`,
    definition: gettextCatalog.getString(
      "The entry's day shown with a specific format."
    ),
  });

  const eventData = (placeholderSubPath = '') => [
    getChurchData(
      'church',
      gettextCatalog.getString('The church the event is associated to.'),
      `${placeholderSubPath}events[i].`
    ),
    getResourceData(
      'resource',
      gettextCatalog.getString('The resource the event is associated to.'),
      `${placeholderSubPath}events[i].`
    ),
    {
      key: 'title',
      property: gettextCatalog.getString('Title'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].title}`,
      definition: gettextCatalog.getString("The event's title."),
    },
    {
      key: 'startDate',
      property: gettextCatalog.getString('Start date'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].startDate}`,
      definition: gettextCatalog.getString("The event's start date and time."),
    },
    {
      key: 'endDate',
      property: gettextCatalog.getString('End date'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].endDate}`,
      definition: gettextCatalog.getString("The event's end date and time."),
    },
    {
      key: 'allDay',
      property: gettextCatalog.getString('All day'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].allDay}`,
      definition: gettextCatalog.getString(
        'A flag indicating whether the event is an all-day event or not.'
      ),
    },
    {
      key: 'description',
      property: gettextCatalog.getString('Description'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].description}`,
      definition: gettextCatalog.getString("The event's description."),
    },
    {
      key: 'summary',
      property: gettextCatalog.getString('Summary'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].summary}`,
      definition: gettextCatalog.getString("The event's summary."),
    },
    {
      key: 'location',
      property: gettextCatalog.getString('Location'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].location}`,
      definition: gettextCatalog.getString("The event's location."),
    },
    {
      key: 'locationName',
      property: gettextCatalog.getString('Location name'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].locationName}`,
      definition: gettextCatalog.getString("The event's location name."),
    },
    {
      key: 'contributor',
      property: gettextCatalog.getString('Contributor'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].contributor}`,
      definition: gettextCatalog.getString("The event's contributor."),
    },
    {
      key: 'price',
      property: gettextCatalog.getString('Price'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].price}`,
      definition: gettextCatalog.getString("The event's price."),
    },
    {
      key: 'priests',
      property: gettextCatalog.getString('Priests'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].priests}`,
      definition: gettextCatalog.getString(
        'The priests celebrating the event.'
      ),
      children: [
        {
          key: 'firstName',
          property: gettextCatalog.getString('First name'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].firstName}`,
          definition: gettextCatalog.getString('The first name of the priest.'),
        },
        {
          key: 'lastName',
          property: gettextCatalog.getString('Last name'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].lastName}`,
          definition: gettextCatalog.getString('The last name of the priest.'),
        },
        {
          key: 'prefix',
          property: gettextCatalog.getString('Prefix'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].prefix}`,
          definition: gettextCatalog.getString('The prefix of the priest.'),
        },
        {
          key: 'initials',
          property: gettextCatalog.getString('Initials'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].initials}`,
          definition: gettextCatalog.getString('The initials of the priest.'),
        },
        {
          key: 'title',
          property: gettextCatalog.getString('Title'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].title}`,
          definition: gettextCatalog.getString('The job title of the priest.'),
        },
        {
          key: 'shortTitle',
          property: gettextCatalog.getString('Short title'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].priests[i].shortTitle}`,
          definition: gettextCatalog.getString(
            'A short name for the job title of the priest.'
          ),
        },
      ],
    },
    {
      key: 'intentions',
      property: gettextCatalog.getString('Intentions'),
      placeholder: `{$e[i].${placeholderSubPath}events[i].intentions}`,
      definition: gettextCatalog.getString("The event's intentions"),
      children: [
        {
          key: 'intentionText',
          property: gettextCatalog.getString('Intention text'),
          placeholder: `{$e[i].${placeholderSubPath}events[i].intentions.intentionText}`,
          definition: gettextCatalog.getString('The text of the intention'),
        },
      ],
    },
  ];

  const getDateGroupedData = (
    mainKey,
    mainProperty,
    mainPlaceholder,
    mainDefinition,
    placeholderSubPath = ''
  ) => ({
    key: mainKey,
    property: mainProperty,
    placeholder: mainPlaceholder,
    definition: mainDefinition,
    children: [
      getDayData(placeholderSubPath),
      getHolidayData(placeholderSubPath),
      getCollectionData(placeholderSubPath),
      {
        key: 'events',
        property: gettextCatalog.getString('Events'),
        placeholder: `{$e[i].${placeholderSubPath}events[i]}`,
        definition: gettextCatalog.getString(
          "The events that takes place on the same day as the entry's day. Notice, that many events can take place on the same day."
        ),
        children: eventData(placeholderSubPath),
      },
    ],
  });

  const churchGroupedData = [
    {
      key: 'entries',
      property: gettextCatalog.getString('Entries'),
      definition: gettextCatalog.getString(
        'Alias for the entries variables. Each entry represent a collection of events grouped by church.'
      ),
      placeholder: '{#e = d.entries}',
      children: [
        getChurchData(
          'churchEntry',
          gettextCatalog.getString('The church grouping its associated events.')
        ),
        getDateGroupedData(
          'dayEntries',
          gettextCatalog.getString('Day entries'),
          '{$e[i].dayEntries}',
          gettextCatalog.getString(
            'Each day entry represents a collection of events that take place on the same date.'
          ),
          'dayEntries[i].'
        ),
      ],
    },
  ];

  const resourceGroupedData = [
    {
      key: 'entries',
      property: gettextCatalog.getString('Entries'),
      definition: gettextCatalog.getString(
        'Alias for the entries variables. Each entry represent a collection of events grouped by resource.'
      ),
      placeholder: '{#e = d.entries}',
      children: [
        getResourceData(
          'resourceEntry',
          gettextCatalog.getString(
            'The resource grouping its associated events.'
          )
        ),
        getDateGroupedData(
          'dayEntries',
          gettextCatalog.getString('Day entries'),
          '{$e[i].dayEntries}',
          gettextCatalog.getString(
            'Each day entry represents a collection of events that take place on the same date.'
          ),
          'dayEntries[i].'
        ),
      ],
    },
  ];

  const dateChurchGroupedData = [
    {
      key: 'entries',
      property: gettextCatalog.getString('Entries'),
      placeholder: '{#e = d.entries}',
      definition: gettextCatalog.getString(
        'Alias for the entries variables. Each entry represent a collection of events grouped by day and sub-grouped by church.'
      ),
      children: [
        getDayData(),
        getHolidayData(),
        getCollectionData(),
        {
          key: 'churchEntries',
          property: gettextCatalog.getString('Church entries'),
          placeholder: '{$e[i].churchEntries[i]}',
          definition: gettextCatalog.getString(
            "The churches that have events that take place on the same day as the entry's day."
          ),
          children: [
            getChurchData(
              'churchEntry',
              gettextCatalog.getString(
                'The church grouping its associated events.'
              ),
              'churchEntries[i].'
            ),
            {
              key: 'events',
              property: gettextCatalog.getString('Church events'),
              placeholder: '{$e[i].churchEntries[i].events[i]}',
              definition: gettextCatalog.getString(
                "The events associated to the entry's church."
              ),
              children: eventData('churchEntries[i].'),
            },
          ],
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Tabs defaultActiveKey="date">
        <TabPane tab={gettextCatalog.getString('Date structuring')} key="date">
          <Table
            columns={columns}
            dataSource={[
              getDateGroupedData(
                'entries',
                gettextCatalog.getString('Entries'),
                '{#e = d.entries}',
                gettextCatalog.getString(
                  'Alias for the entries variables. Each entry represent a collection of events grouped by day.'
                )
              ),
            ]}
            defaultExpandedRowKeys={['entries', 'events']}
            pagination={false}
          />
        </TabPane>
        <TabPane
          tab={gettextCatalog.getString('Church structuring')}
          key="church"
        >
          <Table
            columns={columns}
            dataSource={churchGroupedData}
            defaultExpandedRowKeys={['entries', 'dayEntries', 'events']}
            pagination={false}
          />
        </TabPane>
        <TabPane
          tab={gettextCatalog.getString('Resource structuring')}
          key="resource"
        >
          <Table
            columns={columns}
            dataSource={resourceGroupedData}
            defaultExpandedRowKeys={['entries', 'dayEntries', 'events']}
            pagination={false}
          />
        </TabPane>
        <TabPane
          tab={gettextCatalog.getString('Date & Church structuring')}
          key="dateChurch"
        >
          <Table
            columns={columns}
            dataSource={dateChurchGroupedData}
            defaultExpandedRowKeys={['entries', 'churchEntries', 'events']}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default TemplatePlaceholderDefinitions;
