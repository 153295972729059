import { FEvent as Event } from '../../../calendar/models/calendar';
import { ActionCreator, CdAction } from '../../../redux/utils';

export type ClearOrderOfServiceReportEventsActionType = CdAction<void>;
export const CLEAR_ORDER_OF_SERVICE_REPORT_EVENTS =
  'intention/clearOrderOfServiceReportEvents';
export const clearOrderOfServiceReportEvents: ActionCreator<void> = () => ({
  type: CLEAR_ORDER_OF_SERVICE_REPORT_EVENTS,
});

export interface OrderOfServiceReportEventsPayload {
  startDate: Date;
  endDate: Date;
  churchIds?: number[];
  resourceIds?: number[];
  taxonomyIds?: number[];
  limit?: number;
  offset?: number;
}
export type FetchOrderOfServiceReportEventsActionType =
  CdAction<OrderOfServiceReportEventsPayload>;
export const FETCH_ORDER_OF_SERVICE_REPORT_EVENTS =
  'intention/fetchOrderOfServiceReportEvents';
export const fetchOrderOfServiceReportEvents: ActionCreator<
  OrderOfServiceReportEventsPayload
> = (payload) => ({
  type: FETCH_ORDER_OF_SERVICE_REPORT_EVENTS,
  payload,
});

export interface OrderOfServiceReportEventsResult {
  items: Event[];
  count: number;
}
export type FetchOrderOfServiceReportEventsSuccessActionType =
  CdAction<OrderOfServiceReportEventsResult>;
export const FETCH_ORDER_OF_SERVICE_REPORT_EVENTS_SUCCESS =
  'intention/fetchOrderOfServiceReportEventsSuccess';
export const fetchOrderOfServiceReportEventsSuccess: ActionCreator<
  OrderOfServiceReportEventsResult
> = (payload) => ({
  type: FETCH_ORDER_OF_SERVICE_REPORT_EVENTS_SUCCESS,
  payload,
});

export interface OrderOfServiceReportPayload
  extends OrderOfServiceReportEventsPayload {
  reportTemplateId: number;
  orderBy: string;
}
export type GenerateReportOrderOfServiceActionType =
  CdAction<OrderOfServiceReportPayload>;
export const GENERATE_REPORT_ORDER_OF_SERVICE =
  'intention/generateReportOrderOfService';
export const generateReportOrderOfService: ActionCreator<
  OrderOfServiceReportPayload
> = (payload) => ({
  type: GENERATE_REPORT_ORDER_OF_SERVICE,
  payload,
});
