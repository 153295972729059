// React, Ant
import { useEffect } from 'react';
import { ButtonProps, Checkbox, Form, Input, Space, Typography } from 'antd';
import React from 'react';

import { createCdModal, ModalType } from '../cd-modal-new/CdModal2';
import { CdWarningIcon } from '../Icons';

const Text = Typography.Text;

export type CdConfirmInputModalProps = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  icon?: JSX.Element;
  onOk: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  confirmType: 'text' | 'checkbox';
  confirmLabel: string | React.ReactNode;
  confirmWord?: string;
};

export const showConfirmInputModal = createCdModal<CdConfirmInputModalProps>(
  ({
    setModalProps,
    title,
    message,
    icon,
    onOk,
    onCancel,
    okText,
    okButtonProps,
    cancelText,
    confirmType,
    confirmLabel,
    confirmWord,
  }) => {
    useEffect(() => {
      if (setModalProps) {
        setModalProps({
          modalType: ModalType.POPUP,
          title,
          width: 500,
          onOk,
          onCancel,
          okText,
          okButtonProps,
          cancelText,
        });
      }
    }, [
      cancelText,
      okButtonProps,
      okText,
      onCancel,
      onOk,
      setModalProps,
      title,
    ]);

    const iconProps = {
      style: {
        fontSize: '32px',
      },
      type: 'warning' as 'warning' | 'danger' | 'info' | 'success' | undefined,
    };

    const [userConfirmWord, setUserConfirmWord] = React.useState<
      boolean | string
    >('');

    // Disable/enable the OK button based on the user input
    useEffect(() => {
      setModalProps((prevValues) => ({
        ...prevValues,
        okButtonProps: {
          ...prevValues.okButtonProps,
          disabled:
            (confirmWord && userConfirmWord !== confirmWord) ||
            (!confirmWord && !userConfirmWord),
        },
      }));
    }, [userConfirmWord, setModalProps, confirmWord]);

    return (
      <Space size="middle" align="start">
        {icon ? (
          React.cloneElement(icon, iconProps)
        ) : (
          <CdWarningIcon {...iconProps} />
        )}
        <Space direction="vertical">
          <Text>{message}</Text>
          <Form layout="vertical" style={{ marginTop: '16px' }}>
            {confirmType === 'checkbox' && (
              <Checkbox
                checked={!!userConfirmWord}
                onChange={(e) => setUserConfirmWord(e.target.checked)}
              >
                {confirmLabel}
              </Checkbox>
            )}
            {confirmType === 'text' && (
              <Form.Item label={confirmLabel} style={{ marginBottom: '0px' }}>
                <Input
                  placeholder={confirmWord}
                  value={userConfirmWord.toString()}
                  onChange={(e) => setUserConfirmWord(e.target.value)}
                />
              </Form.Item>
            )}
          </Form>
        </Space>
      </Space>
    );
  }
);
