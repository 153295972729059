'use strict';

import { getIsMobileOrTablet } from './browser.util';

class DocumentViewerComponent {
  constructor(
    _,
    $state,
    $scope,
    $window,
    $uibModal,
    $sce,
    AuthenticationService
  ) {
    'ngInject';

    this._ = _;
    this.$state = $state;
    this.$scope = $scope;
    this.$window = $window;
    this.$uibModal = $uibModal;
    this.$sce = $sce;
    this.AuthenticationService = AuthenticationService;
  }

  $onInit() {
    this.item = this.resolve.item;
    this.fileType = this.item.fileInfo.filetype.type;
    this.filemime = this.item.fileInfo.filemime;

    if (this._.includes(['pdf', 'image', 'audio', 'video'], this.fileType)) {
      this.template =
        '@/app/intranet/files/templates/modals/ModalDocumentViewer.html';
    } else {
      this.template =
        '@/app/intranet/files/templates/modals/ModalDocumentDownload.html';
    }

    if (!this._.has(this.item.fileInfo.filetype, 'class')) {
      this.item.fileInfo.filetype['class'] = 'fa fa-file-o fa-2x file-txt';
    }

    const params = `access_token=${this.AuthenticationService.getAccessToken()}&organizationId=${
      this.$window.churchdeskOrganizationId
    }`;
    const viewLink = `${cdApp.config.api.main}/files/${this.item.id}/view?${params}`;
    const downloadLink = `${cdApp.config.api.main}/files/${this.item.id}/download?${params}`;

    this.viewLink = this.$sce.trustAsResourceUrl(viewLink);
    this.downloadLink = this.$sce.trustAsResourceUrl(downloadLink);

    // Show download button if the app is running on mobile/iPad devices
    this.isMobileOrTablet = getIsMobileOrTablet();
  }

  getShareUrl() {
    const file = this.item;

    if (file.visibility === 'group') {
      return (
        cdApp.references.appUrl +
        this.$state.href('app.private.intranet.files.view', { id: file.id })
      );
    } else {
      return `${cdApp.config.api.main}/files/${file.uuid}/view`;
    }
  }
}
DocumentViewerComponent.$inject = [
  '_',
  '$state',
  '$scope',
  '$window',
  '$uibModal',
  '$sce',
  'AuthenticationService',
];

angular.module('cdApp.shared').component('cdDocumentViewer', {
  template: '<div ng-include="$ctrl.template">',
  controller: DocumentViewerComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
