'use strict';

/**
 * Open the file viewer modal in a state
 */
const initializeFileViewerModal = [
  '$uibModal',
  '$state',
  'item',
  '_',
  ($uibModal, $state, item, _) => {
    const viewableDocuments = ['pdf', 'image', 'audio', 'video'];
    let modalType = _.includes(viewableDocuments, item.fileInfo.filetype.type)
      ? 'document-viewer-modal'
      : 'document-download-modal';

    $uibModal
      .open({
        component: 'cdDocumentViewer',
        windowClass: modalType,
        resolve: {
          item: () => item,
        },
      })
      .result.finally(() => {
        $state.go('^');
      });
  },
];

const checkAccessToIntranet = ($state, Authorization) => {
  'ngInject';
  if (!Authorization.hasPackage('intranet')) {
    return $state.target('app.private.intranet.default', {
      errorCode: 'PACKAGE_NOT_ENABLED',
    });
  }
};
checkAccessToIntranet.$inject = ['$state', 'Authorization'];

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.intranet', {
        url: '/groups',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
        abstract: true,
      })
      .state('app.private.intranet.default', {
        url: '',
        component: 'cdrProductLandingPage',
        params: {
          errorCode: null,
          packageName: 'intranet',
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Groups');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],

          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },

        onEnter: [
          '$state',
          'errorCode',
          function ($state, errorCode) {
            if (!errorCode) {
              return $state.target('app.private.intranet.groups');
            }
          },
        ],
      })
      .state('app.private.intranet.group', {
        abstract: true,
        url: '/group/:gid',
        templateUrl: '@/app/intranet/group/templates/Main.html',
        controller: 'GroupMainController',
        resolve: {
          groupData: [
            'Groups',
            '$stateParams',
            function (Groups, $stateParams) {
              return Groups.get({ id: $stateParams.gid }).$promise;
            },
          ],

          $title: [
            'groupData',
            'routingHelpers',
            function (groupData, routingHelpers) {
              const base = 'Group';
              return {
                base,
                rendered: routingHelpers.buildTitle(groupData.group.name),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.group.overview', {
        url: '/overview',
        templateUrl: '@/app/intranet/group/templates/Activity.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Activity';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Activity')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intranet.group.members', {
        url: '/members',
        templateUrl: '@/app/intranet/group/templates/Members.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Members';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Members')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intranet.group.files', {
        url: '/files',
        templateUrl: '@/app/intranet/files/files.component.html',
        controller: 'FilesController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Files';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Files')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.group.files.view', {
        url: '/:id/view',
        resolve: {
          item: [
            'Files',
            '$stateParams',
            function (Files, $stateParams) {
              return Files.get({ id: $stateParams.id }).$promise;
            },
          ],

          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('File View');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: initializeFileViewerModal,
      })
      .state('app.private.intranet.group.messages', {
        url: '/messages',
        templateUrl: '@/app/intranet/group/templates/Messages.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Messages';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Messages')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intranet.group.message', {
        url: '/message/:id',
        templateUrl: '@/app/intranet/group/templates/Message.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Message Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Messages')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intranet.group.blogs', {
        url: '/blogs',
        templateUrl: '@/app/intranet/blog/templates/List.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Blogs';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Blogs')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.group.blog', {
        url: '/blog/:id?global',
        templateUrl: '@/app/intranet/blog/templates/Blog.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettextCatalog, routingHelpers, groupData) {
              const base = 'Group Blog Detail';
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString('Blogs')} | ${
                    groupData.group.name
                  }`
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.group.blogRevision', {
        url: '/blog/:id/revision/:rid',
        templateUrl: '@/app/intranet/blog/templates/Blog.html',
        controller: 'GroupOverviewController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            'groupData',
            function (gettext, gettextCatalog, routingHelpers, groupData) {
              const base = gettext('Blog Revisions');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  `${gettextCatalog.getString(base)} | ${groupData.group.name}`
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.groups', {
        url: '/groups',
        component: 'cdGroupListState',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Groups');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.files', {
        url: '/files',
        templateUrl: '@/app/intranet/files/files.component.html',
        controller: 'FilesController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Files');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.files.view', {
        url: '/:id/view',
        resolve: {
          item: [
            'Files',
            '$stateParams',
            function (Files, $stateParams) {
              return Files.get({ id: $stateParams.id }).$promise;
            },
          ],

          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('File View');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: initializeFileViewerModal,
      })
      .state('app.private.intranet.blogs', {
        url: '/blogs',
        templateUrl: '@/app/intranet/blog/templates/List.html',
        controller: 'BlogListController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Blogs');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.blogRevisions', {
        url: '/blogs/:id/revisions',
        templateUrl: '@/app/intranet/blog/templates/Revisions.html',
        controller: 'BlogMainController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Blog Revisions');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: checkAccessToIntranet,
      })
      .state('app.private.intranet.churchscreen', {
        url: '/churchscreen',
        templateUrl: '@/app/intranet/churchscreen/templates/ChurchScreen.html',
        controller: 'ChurchscreenController',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Churchscreen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },

        onEnter: [
          '$state',
          'Authorization',
          function checkAccessToChurchScreen($state, Authorization) {
            if (
              !Authorization.hasPackage('churchscreen') ||
              !Authorization.hasPermission('canAccessChurchScreen')
            ) {
              return $state.target('app.public.errorState', {
                errorCode: 'ACCESS_DENIED',
              });
            }
          },
        ],
      });
  },
]);
