'use strict';

class LegalDocumentsController {
  constructor(_, $uibModal, cdApp, LegalDocument) {
    'ngInject';

    this._ = _;
    this.$uibModal = $uibModal;
    this.cdApp = cdApp;
    this.LegalDocument = LegalDocument;
  }

  $onInit() {
    this.legalDocuments = this.LegalDocument.query();

    this.masquerading = this.cdApp.me.masquerading;

    // A reference to the currently open modal
    this.modal = null;
  }

  $onDestroy() {
    // Close open modal
    this.modal && this.modal.close();
  }

  launchLegalDocumentModal(legalDocument, showApproveButton) {
    // Close currently open modal if one exists
    this.modal && this.modal.close();

    this.modal = this.$uibModal.open({
      component: 'cdViewLegalDocument',
      windowClass: 'legal-document-modal',
      resolve: {
        legalDocument: () => legalDocument,
        showApproveButton: () => showApproveButton,
      },
    });

    return this.modal;
  }

  /**
   * Launch a modal to view the legal document
   * @param {Object} legalDocument
   */
  viewLegalDocument(legalDocument) {
    this.launchLegalDocumentModal(legalDocument, false);
  }

  /**
   * Launch a modal to view and approve the legal document
   * @param {Object} legalDocument
   */
  approveLegalDocument(legalDocument) {
    this.launchLegalDocumentModal(legalDocument, true).result.then(
      (approvedLegalDocument) => {
        // Replace item in list
        const indexOfOldItem = this._.findIndex(this.legalDocuments, {
          id: approvedLegalDocument.id,
        });

        this._.set(this.legalDocuments, indexOfOldItem, approvedLegalDocument);
      }
    );
  }
}
LegalDocumentsController.$inject = ['_', '$uibModal', 'cdApp', 'LegalDocument'];

angular.module('cdApp.shared').component('cdLegalDocuments', {
  templateUrl:
    '@/app/shared/components/legal-documents/legal-documents.component.html',
  controller: LegalDocumentsController,
});
