import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { ProductLandingPageWrapper } from '@/react/product-landingpage/ProductLandingPage';
angular
  .module('cdApp.shared')
  .component(
    'cdrProductLandingPage',
    react2angular(
      sharedContext.use(ProductLandingPageWrapper),
      [],
      ['$stateParams']
    )
  );
