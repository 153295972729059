import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ConsentDeleteStatementButton from '@/react/people/components/ConsentDeleteStatementButton';

angular
  .module('cdApp.people')
  .component(
    'cdrConsentDeleteStatementButton',
    react2angular(
      sharedContext.use(ConsentDeleteStatementButton),
      ['id', 'defaultConsent'],
      []
    )
  );
