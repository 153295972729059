import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ResubscribeAllPracticalEmailBasedInformationButton from '@/react/people/components/ResubscribeAllPracticalEmailBasedInformationButton';

angular
  .module('cdApp.shared')
  .component(
    'cdrResubscribeAllPracticalEmailBasedInformationButton',
    react2angular(
      sharedContext.use(ResubscribeAllPracticalEmailBasedInformationButton),
      ['personName', 'updateReceiveCommunicationFromFilters'],
      []
    )
  );
