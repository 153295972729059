(function () {
  'use strict';
  Blogs.$inject = ['$$resource'];

  /**
   * Transform the payload of the API call
   *
   * @param data {Object} - The data object
   */
  function transformBlogRequestData(data) {
    let result = angular.copy(data);

    // Removes any base64 images added by the cropping logic.
    result.image = undefined;

    return angular.toJson(result);
  }

  function Blogs($$resource) {
    return $$resource(
      cdApp.config.api.main + '/blogs/:id',
      {},
      {
        create: {
          method: 'POST',
          transformRequest: transformBlogRequestData,
        },

        update: {
          method: 'PUT',
          transformRequest: transformBlogRequestData,
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Blogs', Blogs);
})();
