'use strict';

/* eslint-disable no-var */
/* eslint eqeqeq:0 */
/* eslint no-cond-assign:0 */
/* eslint-disable no-useless-escape */

/**
 * People message editor
 *
 * @example <iframe cd-people-message-editor></iframe>
 */ peopleMessageEditorDirective.$inject = [
  '$parse',
  '$q',
  '$compile',
  '$http',
  '$templateCache',
  'gettextCatalog',
  'toastr',
  'safePasteService',
  '_',
];

function peopleMessageEditorDirective(
  $parse,
  $q,
  $compile,
  $http,
  $templateCache,
  gettextCatalog,
  toastr,
  safePasteService,
  _
) {
  return function (scope, element, attrs) {
    var updateInterval, template, editorSnippet;
    var iframe = element[0];
    var watchers = [];
    var titleLimit = 50;

    function decodeHtml(html) {
      return angular.element('<textarea />').html(html).text();
    }

    var filters = {
      contenteditable(key, value, operators, editing) {
        if (!editing) {
          return value;
        }

        var inline = _.includes(operators, 'inline');
        var noLineBreaks = _.includes(operators, 'noLineBreaks');

        if (editing) {
          watchers.push(
            scope.$watch(key, function (newValue, oldValue) {
              if (editing && newValue !== oldValue) {
                $(iframe.contentDocument.body)
                  .find('[data-model="' + key + '"]')
                  .text(decodeHtml(newValue));
              }
            })
          );
        }

        return (
          '<div contenteditable="true" data-model="' +
          key +
          '" data-inline="' +
          inline +
          '" data-no-line-breaks="' +
          noLineBreaks +
          '" class="variable-content">' +
          value +
          '</div>'
        );
      },
      redactor(key, value, operators, editing) {
        if (!editing) {
          return value;
        }

        return (
          '<textarea>' +
          value +
          '</textarea>' +
          '<div class="variable-content" data-model="' +
          key +
          '">' +
          value +
          '</div>'
        );
      },
      render(key, value, operators, editing) {
        // @syntax render:templateUrl[:watchVariable1][:watchVariable2...]
        var url = $parse(operators.shift())(scope);

        // Prevent rendering forms section for organizations that don't have Forms
        if (
          _.includes(url, 'forms') &&
          !_.get(cdApp, 'organization.modules.forms')
        ) {
          return $('<div />').html();
        }

        var template = $templateCache.get(url);
        var compiled = $compile(template)(scope);

        _.each(operators, function (operator) {
          filters.watch(operator, value, operators, editing);
        });

        scope.$digest();
        return $('<div />').append(compiled).html();
      },
      translate(key, value, operators) {
        value = _.trim(operators[0], '"\'');
        return gettextCatalog.getString(value);
      },
      watch(key, value, operators, editing) {
        if (editing) {
          const watcher = scope.$watchCollection(key, (newValue, oldValue) => {
            const changed = _.isEqualWith(
              newValue,
              oldValue,
              (newValueComp, oldValueComp) => {
                if (_.isArray(newValueComp)) {
                  return !_.isEqual(
                    _.map(newValueComp, 'id'),
                    _.map(oldValueComp, 'id')
                  );
                } else {
                  return !_.isEqual(newValueComp, oldValueComp);
                }
              }
            );

            if (editing && changed) {
              loadEditor();
            }
          });

          watchers.push(watcher);
        }

        return value;
      },
    };

    scope.$on('$destroy', function () {
      clearInterval(updateInterval);
    });

    element.on('$destroy', function () {
      clearInterval(updateInterval);
    });

    function loadEditor() {
      setTimeout(function () {
        _.invokeMap(watchers, Function.prototype.call);
        watchers = [];

        write(compile(true));

        // after the editor is loaded, apply event handlers to the contenteditible fields
        angular.element(iframe).on('load', function iframeLoaded() {
          var title = angular.element(
            '[data-model="$ctrl.message.title"]',
            iframe.contentWindow.document || iframe.contentDocument
          );

          function getTitleContent() {
            return _.get(title, '[0].innerText', '');
          }
          var truncateTitleAndShowError = function () {
            if (getTitleContent().length > titleLimit) {
              toastr.error(
                gettextCatalog.getString(
                  'The message title cannot be longer than 50 characters, including spaces.'
                ),

                ''
              );

              title.text(getTitleContent().substring(0, titleLimit));
            }
          };

          var showCantExceedLimitError = _.debounce(function () {
            toastr.error(
              gettextCatalog.getString(
                'The message title cannot be longer than 50 characters, including spaces.'
              )
            );
          }, 200);

          title.off('keydown paste').on('keydown paste', function (event) {
            // handle pasting content into contenteditable field
            if (event.type === 'paste') {
              safePasteService.handlePaste(event, iframe.contentWindow);
              // after pasting, trim the content so it doesn't exceed the title limit
              truncateTitleAndShowError();
            } else {
              // ignore special keys
              if (
                !(
                  event.altKey ||
                  event.ctrlKey ||
                  event.shiftKey ||
                  event.metaKey
                )
              ) {
                // prevent line breaks if configured
                if (
                  event.keyCode === 13 &&
                  this.getAttribute('data-no-line-breaks') === 'true'
                ) {
                  event.preventDefault();
                  return;
                }
                // prevent title to exceed the limit
                if ($(this).text().length > titleLimit && event.keyCode != 8) {
                  showCantExceedLimitError();
                  event.preventDefault();
                }
              }
            }
          });

          resize();
        });
      });
    }

    function write(content) {
      const iframeDocument = _.get(iframe, 'contentWindow.document');
      iframeDocument.open('text/html', 'replace');
      iframeDocument.write(content);
      iframeDocument.close();
    }

    const resize = _.throttle(
      () => {
        setTimeout(() => {
          const iframeWrapper = angular.element(
            '.people-message-editor__iframe-wrapper'
          );

          const iframeWrapperOffset =
            $(window).height() - iframeWrapper.parent().offset().top - 32;
          angular.element(iframe).height(iframeWrapperOffset);
        });
      },
      200,
      { leading: true }
    );

    function compile(editing) {
      var match;
      var output = template;
      scope.editing = !!editing;

      while ((match = /(data-)?ng-show=["']([^"']*)["']/.exec(output))) {
        var replacement = $parse(match[2])(scope)
          ? 'class="ng-show"'
          : 'class="ng-hide"';

        output = output.replace(match[0], replacement);
      }

      while (
        (match =
          /{{[\s]*([\w\.\$]+)[\s]*(\|?[\s]*([:'"\w\.\-\$\/]+)[\s]*)?}}/.exec(
            output
          ))
      ) {
        var filter = (match[3] || '').split(':');
        var content = $parse(match[1])(scope);

        if (match[2] && match[2][0] === ':') {
          // applying only a filter, without value
          filter[0] = match[1];
          content = '';
        }

        var filterName = filter.shift();
        var transform = filters[filterName];

        if (filterName) {
          if (!transform) {
            throw new Error('Filter ' + filterName + ' could not be found.');
          }

          content = transform(match[1], content, filter, editing);
        }

        output = output.replace(match[0], content);
      }

      if (editing) {
        output = output.replace('</head>', editorSnippet + '</head>');
        output = output.replace('</body>', '<div class="loading" /></body>');
      }

      return output;
    }

    function update(changed) {
      var content = _.get(iframe, 'contentDocument.body');

      $(content).find('.placeholder').remove();
      $(content)
        .find('.variable-content')
        .each(function (n, element) {
          if (iframe.contentDocument.activeElement === element) {
            return;
          }

          var model = $parse(element.dataset.model);
          var newContent = element.innerHTML.trim();

          if (element.dataset.inline) {
            newContent = element.textContent.trim();
            $(element).text(newContent);
          }

          // If the value contains random HTML tags, without any actual content or text,
          // we make sure the HTML is ignored and the value is set to an empty string.
          if (!_.trim(newContent.replace(/<\S[^><]*>/g, ''))) {
            newContent = '';
          }

          if (!newContent) {
            $(element).html(
              '<span class="placeholder">' +
                gettextCatalog.getString('Click here to edit') +
                '</span>'
            );
          }

          if (model(scope) != newContent) {
            changed = true;
            model.assign(scope, newContent);
          }

          if (changed) {
            scope.$apply();
          }
        });

      if (changed || !scope.$ctrl.message.rendered) {
        scope.$ctrl.message.rendered = compile();
        resize();
      }
    }

    scope.$ctrl.autosave(false, function () {
      return $q(function (resolve) {
        if (!scope.$ctrl.message.sent && scope.$ctrl.message.type === 'email') {
          setTimeout(function () {
            update(true);
            resolve();
          });
        } else {
          resolve();
        }
      });
    });

    scope.$watch(attrs.ngDisabled, function (disabled) {
      if (disabled) {
        write(scope.$ctrl.message.rendered);
        resize();
        return;
      }

      write('Loading...');

      template = $templateCache.get(
        '@/app/people/message-editor/themes/azul/email.html'
      );

      editorSnippet = $templateCache.get(
        '@/app/people/message-editor/editor.html'
      );

      iframe.onload = function () {
        $(iframe.contentDocument.body).on('mousedown', function () {
          $('body').trigger('click');
        });

        $(iframe.contentDocument.body)
          .find('.variable-content')
          .click(function (event) {
            event.preventDefault();
            event.stopPropagation();
            $(this).prev().show().trigger('focus');

            return false;
          });

        $(iframe.contentDocument.body)
          .find('[ng-click]')
          .click(function (event) {
            var expression = angular.element(this).attr('ng-click');
            var fn = $parse(expression, null, true);

            scope.$evalAsync(function () {
              if ($parse(attrs.ngDisabled)(scope)) {
                return;
              }

              fn(scope, { $event: event });
            });
          });

        $(iframe.contentDocument.body).find('.loading').remove();

        update();
      };

      loadEditor();

      clearInterval(updateInterval);
      updateInterval = setInterval(update, 500);
    });
  };
}

angular
  .module('cdApp.people')
  .directive('cdPeopleMessageEditor', peopleMessageEditorDirective);
