(function () {
  'use strict';
  Filesystem.$inject = ['$$resource'];

  function Filesystem($$resource) {
    const baseUrl = cdApp.config.api.main + '/filesystem';
    return $$resource(
      baseUrl,
      {},
      {
        getFilesFolders: {
          method: 'GET',
          url: baseUrl,
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Filesystem', Filesystem);
})();
