'use strict';

class AttendanceOverviewComponent {
  constructor(
    $q,
    $timeout,
    $uibModal,
    Attendance,
    Users,
    Diocese,
    appUtils,
    cdResourceColors,
    Authorization,
    gettextCatalog,
    cdApp,
    moment,
    _
  ) {
    'ngInit';

    this.$q = $q;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.Attendance = Attendance;
    this.Users = Users;
    this.Diocese = Diocese;
    this.appUtils = appUtils;
    this.cdResourceColors = cdResourceColors;
    this.Authorization = Authorization;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.moment = moment;
    this._ = _;
  }

  $onInit() {
    const _ = this._;

    if (!this.Authorization.hasPackage('attendance')) return;

    this.chart = {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],

          xAxes: [
            {
              ticks: {
                display: false,
              },

              scaleLabel: {
                display: true,
                labelString: this.gettextCatalog.getString(
                  'Last {{ count }} events with registered attendance',
                  {
                    count: this.maxChartEvents,
                  }
                ),
              },

              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };

    this.viewData = {
      loading: true,
    };

    this.Attendance.getFields().$promise.then((attendanceFields) => {
      this.viewData.isOnboarded = !_.isEmpty(attendanceFields);

      if (this.viewData.isOnboarded) {
        this.fetchAllEvents();
      } else {
        this.viewData.loading = false;
      }
    });
  }

  fetchEvents(limit, alreadyRegistered) {
    const offset = 0;
    return this.Attendance.getEvents({ limit, offset, alreadyRegistered })
      .$promise;
  }

  fetchAllEvents() {
    if (!this.viewData.isOnboarded) {
      return;
    }

    this.viewData.loading = true;

    this.$q
      .all([
        this.fetchEvents(this.maxChartEvents, true),
        this.fetchEvents(this.maxEvents, false),
      ])
      .then((results) => {
        const chartEvents = results[0].events;
        const listEvents = results[1].events;

        this.prepareChartData(chartEvents);
        this.events = listEvents;
        this.viewData.loading = false;
      });
  }

  prepareChartData(events = []) {
    if (!events.length) {
      return;
    }

    const _ = this._;
    const orderedEvents = _.orderBy(events, 'startDate', 'asc');
    const accumulatedFields = ['adults', 'children'];

    const labels = _.map(orderedEvents, (event) => {
      const startDate = this.moment(event.startDate);
      const date = event.allDay
        ? startDate.format('L')
        : startDate.format('L LT');
      return `${event.title} (${date})`;
    });

    const numbers = _(orderedEvents)
      .map('attendance.attendanceFields')
      .map((fields) => {
        const total = _.get(_.find(fields, { tracker: 'total' }), 'amount');
        const accumulatedTotal = _(fields)
          .filter((field) => _.includes(accumulatedFields, field.tracker))
          .sumBy('amount');
        return total || accumulatedTotal;
      })
      .value();

    const colors = _.map(orderedEvents, (event) =>
      _.get(this.cdResourceColors, _.get(event, 'category.color') || 0)
    );

    const datasetOverride = [
      {
        label: this.gettextCatalog.getString('Attendees'),
        backgroundColor: _.map(colors, (color) =>
          this.appUtils.hexToRgb(color, 0.8)
        ),

        borderColor: _.map(colors, (color) => this.appUtils.hexToRgb(color, 1)),
        borderWidth: 1,
      },
    ];

    this.chart.labels = labels;
    this.chart.data = [numbers];
    this.chart.datasetOverride = datasetOverride;
    this.chart.options.scales.yAxes[0].ticks.maxTicksLimit = Math.min(
      11,
      1 + Math.max.apply(null, numbers)
    );
  }

  registerAttendance(event) {
    this.$uibModal
      .open({
        component: 'cdRegisterAttendanceModal',
        resolve: {
          event: () => event,
          attendanceCategories: () => this.Attendance.getCategories().$promise,
          attendanceFields: () => this.Attendance.getFields().$promise,
        },
      })
      .result.then(() => {
        this.$timeout(() => this.fetchAllEvents(), 500);
      });
  }

  getStarted() {
    this.$uibModal
      .open({
        component: 'cdAttendanceGetStartedModal',
        windowClass: 'modal-ui-select',
      })
      .result.then(() => {
        this.viewData.isOnboarded = true;
        this.fetchAllEvents();
      });
  }
}
AttendanceOverviewComponent.$inject = [
  '$q',
  '$timeout',
  '$uibModal',
  'Attendance',
  'Users',
  'Diocese',
  'appUtils',
  'cdResourceColors',
  'Authorization',
  'gettextCatalog',
  'cdApp',
  'moment',
  '_',
];

angular.module('cdApp.calendar').component('cdAttendanceOverview', {
  templateUrl:
    '@/app/calendar/attendance/components/attendance-overview/attendance-overview.component.html',
  controller: AttendanceOverviewComponent,
  bindings: {
    maxEvents: '<',
    maxChartEvents: '<',
  },
});
