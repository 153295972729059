import { PeopleFromSearch, PeopleChurch, Person } from './people';
import { Segment } from './segment.type';

import { OrganizationSettings } from '@/react/organization/types/organization';

export enum MessageType {
  BASIC = 'basic',
  SIMPLE = 'simple',
  ADVANCED = 'advanced', // Studio
  SMS = 'sms',
  EMAIL = 'email', // Legacy
  POSTER = 'calendar-poster', // Event poster
  EVENT_INVITATION = 'event-invitation',
}
export enum ParentType {
  ANNIVERSARY = 'anniversary',
  MESSAGE = 'message',
}
export enum MessageStatisticsFilters {
  ALL = 'all',
  SENT = 'sent',
  DELIVERED = 'delivered',
  OPENED = 'opened',
  CLICKED = 'clicked',
  SPAM = 'spam',
  BOUNCED = 'bounced', // Studio
  FAILED = 'failed',
}

export enum MessageFrom {
  USER = 'user',
}

export interface MessageRecipients {
  people: PeopleFromSearch[];
  segments: Segment[];
}
export interface MessageRecipientsCount {
  totalRecipients: number;
  activeRecipients: number;
  inActiveRecipients: number;
}

export interface PeopleMessage {
  id: number;
  uuid?: string;
  scheduled?: string;
  sent?: string;
  type?: MessageType;
  title: string;
  rendered?: string;
  content: PeopleMessageContent;
  to: PeopleMessageRecipient[];
  from: 'user' | 'church';
  churches: PeopleChurch[];
  organizationId?: number;
  organization: OrganizationSettings;
  news: PeopleMessageBlog[];
  files: PeopleMessageFile[];
  forms: PeopleMessageForm[];
  picture?: PeopleMessagePicture;
  contributions: PeopleMessageContributions[];
  events: PeopleMessageEvent[];
  statistics?: {
    bounced: number;
    clicked: number;
    compromised: boolean;
    delivered: number;
    opened: number;
    sent: number;
    title: string;
    unsubscribed: number;
  };
  author?: {
    contactId: number;
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    intermediaryEmail: string;
    lastName: string;
  };
  anniversary?: {
    uniqueId: string;
    date: string;
    label: string;
    type: string;
    yearSince: number;
  };
}

export interface PeopleMessageStatistics {
  clicked: number;
  email: string;
  id: number;
  name: string;
  opened: number;
  personId: number;
  status: string;
  unsubscribed: boolean;
}

export interface PeopleMessageBlog {
  id: number;
  title: string;
  formattedDescription: string;
  formattedTitle: string;
  publishDate: string;
  organizationId: number;
  showAuthor: boolean;
  description: string;
  organization: {
    id: number;
    name: string;
    installationUrl: string;
  };
  image?: any;
  author: string;
  siteDomain: string;
  url: string;
  authorImage: null;
  category: string;
}

export interface BlogSearchResult {
  items: PeopleMessageBlog[];
  total: number;
}

export interface PeopleMessageForm {
  id: string;
  formattedDescription: string;
  formattedTitle: string;
}

export interface PeopleMessageEvent {
  id: number;
  formattedDescription: string;
  formattedTitle: string;
}

export interface PeopleMessagePicture {
  id: number;
  url: string;
  urlUncropped: string;
}
export interface PeopleMessageContent {
  body: string;
  blogTitle?: string;
  evnetsTitle?: string;
  formsTitle?: string;
  contributionsTitle?: string;
  newsletterColor: string;
  newsletterEventLocation?: boolean;
  newsletterShowShortDescription?: boolean;
}

export enum PeopleMessageContributionsType {
  PAYMENT = 'payment',
  DONATION = 'donation',
}

export interface PeopleMessageContributions {
  id: string;
  alias: string;
  type: PeopleMessageContributionsType;
  title: string;
  formattedDescription: string;
  formattedTitle: string;
  description?: string;
  startDate: string;
  endDate?: string;
  goalAmount?: number;
  minimumAmount: number;
  giftAid?: string;
  emailSubject: string;
  emailBody: string;
  webBody?: any;
  allowMobile: string;
  mobileCommand?: any;
  status: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  organizationId: number;
  fileId?: any;
  authorId: number;
  file?: any;
  churches: PeopleChurch & {
    contributions_churches: {
      createdAt: string;
      updatedAt: string;
      contributionId: string;
      churchId: number;
    };
  };
  totalContributions: number;
  totalAmount: string;
}

export interface PeopleMessageFile {
  id: number;
  // User generated title of the file
  title: string;
  url: string;
}

export type PeopleMessageRecipient = Segment | Partial<Person>;

export interface BasicPeopleMessage extends PeopleMessage {
  type: MessageType.BASIC;
}
