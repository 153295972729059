import { Form } from 'antd';
import moment from 'moment/moment';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { navigate } from '@/react/services/StateServiceFactory';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import {
  useSearchCalendarAbsenceCategories,
  useSearchCalendarCategories,
  useSearchCalendarGroups,
  useSearchCalendarPrint,
  useSearchCalendarUsers,
} from '@/react/calendar/reports/store/reportHooks';
import { HasMultipleChurches } from '@/react/user/store/user-session';

export const useSearchCalendarReport = ({
  filters,
  type,
}: { filters: any; type: string }) => {
  const isMultiParish = useRecoilValue(HasMultipleChurches);
  const [calendarSearchForm] = Form.useForm();
  const [filterLocalState, setFilterLocalState] = useState(filters);
  const [tableData, setTableData] = useState({
    events: [],
    isMoreEvents: false,
  });

  const [hasChanges, setHasChanges] = useState(true);

  const calendarSearchInitialValue = {
    ...filters,
    startDate: moment(filters.startDate),
    endDate: moment(filters.endDate),
  };

  const { searchCalendarUsers } = useSearchCalendarUsers();
  const { searchCalendarGroups } = useSearchCalendarGroups();
  const { searchCalendarCategories } = useSearchCalendarCategories();
  const { searchCalendarAbsenceCategories } =
    useSearchCalendarAbsenceCategories();

  const { searchCalendarPrint, isLoading } = useSearchCalendarPrint();

  useEffect(() => {
    if (filterLocalState.default) {
      return;
    }

    const getDataFunction = async () => {
      const data = await searchCalendarPrint({
        type: type,
        filters: {
          startDate: moment(filterLocalState.startDate).toISOString(),
          endDate: moment(filterLocalState.endDate).endOf('day').toISOString(),
          search: filterLocalState.searchText,
          groups: filterLocalState.groupSelect,
          categories: filterLocalState.categorySelect,
          resources: filterLocalState.resourceSelect
            ?.filter((item) => item.includes('resource-'))
            ?.map((value) => value.replace('resource-', '')),
          eventsWithoutResourcesInChurchIds: filterLocalState.resourceSelect
            ?.filter((item) => item.includes('church-'))
            ?.map((value) => value.replace('church-', '')),
          users: filterLocalState.userSelect,
          visibility: filterLocalState.visibilitySelect,
          type: type === 'absence' ? 'absence' : 'event',
        },
      });

      setTableData({
        ...data,
        events: data?.events.slice(0, 300),
      });
    };

    getDataFunction();
  }, [filterLocalState, filters, searchCalendarPrint, type]);

  const getResultHandler = useCallback(async () => {
    setHasChanges(false);

    await calendarSearchForm
      .validateFields()
      .then((formValues) => {
        const state = {
          default: false,
          searchText: formValues.searchText,
          startDate: formValues.startDate.format('YYYY-MM-DD'),
          endDate: formValues.endDate.format('YYYY-MM-DD'),
          groupSelect: formValues.groupSelect,
          categorySelect: formValues.categorySelect,
          resourceSelect: formValues.resourceSelect,
          churchSelect: formValues?.churchSelect,
          userSelect: formValues.userSelect,
          visibilitySelect: formValues.visibilitySelect,
        };

        navigate('app.private.calendar.print', state);
        setFilterLocalState(state);
      })
      .catch(handleAntFormValidationErrors);
  }, [calendarSearchForm]);

  const groupsOptions =
    searchCalendarGroups
      ?.map((item) => ({
        label: item.name,
        value: item.id.toString(),
        picture: item.picture,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const eventsOptions =
    searchCalendarCategories
      ?.map((item) => ({
        label: item.name,
        value: item.id.toString(),
        color: item.color,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const absenceOptions =
    searchCalendarAbsenceCategories
      ?.map((item) => ({
        label: item.name,
        value: item.id.toString(),
        color: item.color,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const userOptions =
    searchCalendarUsers
      ?.map((item) => ({
        label: item.name || item.email,
        value: item.id.toString(),
        picture: item.picture,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  return {
    calendarSearchForm,
    calendarSearchInitialValue,
    getResultHandler,
    groupsOptions,
    eventsOptions,
    absenceOptions,
    userOptions,
    tableData: tableData,
    isPrintDataLoading: isLoading,
    hasChanges,
    setHasChanges,
    isMultiParish,
  };
};
