(function () {
  'use strict';

  /**
   * @desc return a truncated version of a string
   * @example <span>{{ 'your long string' | trunc: 10 }}</span>
   */
  function trunc() {
    return function (input, count) {
      return _.truncate(input, { length: count }) || input;
    };
  }

  angular.module('cdApp.shared').filter('trunc', trunc);
})();
