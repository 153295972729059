import {
  LOAD_PARTNER_FILES,
  PARTNER_FILES_REQUEST,
  PARTNER_FILES_REQUEST_SUCCESS,
  PARTNER_FILES_REQUEST_FAILED,
  PARTNER_FILE_SELECTED,
  IMPORT_PARTNER_FILE,
  IMPORT_PARTNER_FILE_REQUEST,
  IMPORT_PARTNER_FILE_REQUEST_FAILED,
  IMPORT_PARTNER_FILE_REQUEST_SUCCESS,
  PARTNER_FILES_PAGE_CHANGE,
  PARTNER_FILES_PAGE_SIZE_CHANGE,
  PARTNER_FILES_GET_FILTERS,
  PARTNER_FILES_GET_FILTERS_SUCCESS,
  PARTNER_FILES_GET_FILTERS_FAILED,
} from './constants';

export interface PartnerFile {
  id: string;
  title: string;
  name: string;
  size: string;
  height: string;
  width: string;
  partnerId: string;
  seriesId: string;
  seriesName: string;
  primaryInSeries: boolean;
  publishedOn: string;
  tags: string[];
  url: string;
  imageCredit: string;
  caption: string;
  type: string;
}
export interface PartnerFiles {
  items: PartnerFile[];
  selectedFile?: PartnerFile;
  partnerFilesLoading: boolean;
  filters: {
    publication: string[];
  };
  pagination: {
    pageSizeOptions: number[];
    pageSize: number;
    currentPage: number;
    count: number;
  };
}

export const loadPartnerFiles = (payload: {
  partnerId: string;
  currentPage: number;
  search: string;
  pageSize: number;
  publication: string[];
  type: string[];
  tags: string[];
}) => ({
  type: LOAD_PARTNER_FILES,
  payload,
});

export const partnerFilesRequest = () => ({
  type: PARTNER_FILES_REQUEST,
});

export const partnerFilesRequestSuccess = (payload: {
  items: any[];
  count: number;
}) => ({
  type: PARTNER_FILES_REQUEST_SUCCESS,
  payload,
});

export const partnerFilesRequestFailed = () => ({
  type: PARTNER_FILES_REQUEST_FAILED,
});

export const onFileSelected = (fileId: string) => ({
  type: PARTNER_FILE_SELECTED,
  payload: { fileId },
});

export const onPageChange = (page: number) => ({
  type: PARTNER_FILES_PAGE_CHANGE,
  payload: { page },
});

export const onSetPageSize = (pageSize: number) => ({
  type: PARTNER_FILES_PAGE_SIZE_CHANGE,
  payload: { pageSize },
});

export const importPartnerFile = (
  fileId: string,
  partnerId: string,
  callback: any,
  imageFormat: string
) => ({
  type: IMPORT_PARTNER_FILE,
  payload: { fileId, partnerId, callback, imageFormat },
});

export const importPartnerFileRequest = () => ({
  type: IMPORT_PARTNER_FILE_REQUEST,
});

export const importPartnerFileRequestSuccess = (payload: { file: any }) => ({
  type: IMPORT_PARTNER_FILE_REQUEST_SUCCESS,
  payload,
});

export const importPartnerFileRequestFailed = () => ({
  type: IMPORT_PARTNER_FILE_REQUEST_FAILED,
});

export const partnerFilesGetFilters = () => ({
  type: PARTNER_FILES_GET_FILTERS,
});

export const partnerFilesGetFiltersSuccess = (payload: string[]) => ({
  type: PARTNER_FILES_GET_FILTERS_SUCCESS,
  payload,
});

export const partnerFilesGetFiltersFailed = () => ({
  type: PARTNER_FILES_GET_FILTERS_FAILED,
});
