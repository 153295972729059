import { react2angular } from 'react18-react2angular';

import { EventStoleComponent } from '../../../../react/intention/components';

angular
  .module('cdApp.calendar')
  .component(
    'cdrEventStole',
    react2angular(EventStoleComponent, ['event'], [])
  );
