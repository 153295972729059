import { $injector } from 'ngimport';
import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { gettextCatalog } from '../../services/I18nService';
import { handleSuccessMessage } from '../../shared/utils';
import { navigate } from '../../services/StateServiceFactory';
import { IntentionReportTemplate } from '../models/report-template';
import IntentionReportTemplateService, {
  IntentionReportTemplatesData,
} from '../services/IntentionReportTemplateService';
import {
  CreateIntentionReportTemplateActionType,
  DeleteIntentionReportTemplateActionType,
  FetchIntentionReportTemplateActionType,
  FetchIntentionReportTemplatesActionType,
  UpdateIntentionReportTemplateActionType,
  clearIntentionReportTemplate,
  fetchIntentionReportTemplateSuccess,
  fetchIntentionReportTemplates,
  fetchIntentionReportTemplatesSuccess,
} from '../redux/intention-report-templates/Actions';

export function* getIntentionReportTemplatesSaga(
  action: FetchIntentionReportTemplatesActionType
): SagaIterator {
  const data = yield call(
    IntentionReportTemplateService.getIntentionReportTemplates,
    action.payload
  );
  yield put(
    fetchIntentionReportTemplatesSuccess(data as IntentionReportTemplatesData)
  );
}

export function* getIntentionReportTemplateSaga(
  action: FetchIntentionReportTemplateActionType
): SagaIterator {
  const data = yield call(
    IntentionReportTemplateService.getIntentionReportTemplate,
    action.payload
  );
  yield put(
    fetchIntentionReportTemplateSuccess(data as IntentionReportTemplate)
  );
}

export function* createIntentionReportTemplateSaga(
  action: CreateIntentionReportTemplateActionType
): SagaIterator {
  const createPayload = action.payload;
  yield call(
    IntentionReportTemplateService.createIntentionReportTemplate,
    createPayload
  );
  // Refresh intentions by re-fetching
  yield put(fetchIntentionReportTemplates({ type: createPayload.type }));
  // Show success message
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully created report template.')
  );
  // Navigate to the corresponding listing
  navigate('app.private.settings.reportTemplates.overview', {
    tab: createPayload.type,
  });
}

export function* updateIntentionReportTemplateSaga(
  action: UpdateIntentionReportTemplateActionType
): SagaIterator {
  const { id, updatePayload } = action.payload;
  yield call(
    IntentionReportTemplateService.updateIntentionReportTemplate,
    id,
    updatePayload
  );
  // Clear the selected intention
  yield put(clearIntentionReportTemplate());
  // Refresh intentions by re-fetching
  yield put(fetchIntentionReportTemplates({ type: updatePayload.type }));
  // Show success message
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully updated report template.')
  );
  // Navigate to the corresponding listing
  navigate('app.private.settings.reportTemplates.overview', {
    tab: updatePayload.type,
  });
}

export function* deleteIntentionReportTemplateSaga(
  action: DeleteIntentionReportTemplateActionType
): SagaIterator {
  const { id, type } = action.payload;
  yield call(IntentionReportTemplateService.deleteIntentionReportTemplate, id);
  // Refresh intentions by re-fetching
  yield put(fetchIntentionReportTemplates({ type }));
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully deleted report template.')
  );
}
