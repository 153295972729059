'use strict';

class AdvancedFilterSummary {
  constructor(_, moment, gettextCatalog) {
    this._ = _;
    this.moment = moment;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.viewValue = this.getViewValue();
    this.operatorIsStatic = this.operatorRequiresValue();
  }

  $onChanges(changesObj) {
    if (changesObj.operator) {
      this.operator = changesObj.operator.currentValue;
      this.operatorIsStatic = this.operatorRequiresValue();
    }

    if (changesObj.value || changesObj.unit) {
      this.viewValue = this.getViewValue(
        this._.get(changesObj, 'value.currentValue')
      );
    }
  }

  getViewValue(value = this.value) {
    const _ = this._;
    const dateFilterTypes = ['date', 'lifeEvent'];

    // Radio and list
    if (this.filter.type === 'list' || this.filter.type === 'radio') {
      if (_.isNil(value) || value === 'known' || value === 'unknown') {
        return null;
      }

      const option = _.find(this.filter.options, { value });
      if (!option) return;

      return option.label;
    }

    // Date and life event
    if (_.includes(dateFilterTypes, this.filter.type)) {
      // Absolute
      if (
        _.isDate(value) ||
        this.moment(value, this.moment.ISO_8601).isValid()
      ) {
        return this.moment(value).format('L');
      }

      // Relative
      if (_.isNumber(value)) {
        if (!value) return null;

        if (this.filter.type === 'lifeEvent') {
          switch (this.unit) {
            case 'd':
              return this.gettextCatalog.getPlural(
                value,
                '1 day',
                '{{ count }} days',
                {
                  count: value,
                }
              );

            case 'w':
              return this.gettextCatalog.getPlural(
                value,
                '1 week',
                '{{ count }} weeks',
                {
                  count: value,
                }
              );

            case 'm':
              return this.gettextCatalog.getPlural(
                value,
                '1 month',
                '{{ count }} months',
                {
                  count: value,
                }
              );
          }
        } else {
          switch (this.unit) {
            case 'd':
              return this.gettextCatalog.getPlural(
                value,
                '1 day ago',
                '{{ count }} days ago',
                {
                  count: value,
                }
              );

            case 'w':
              return this.gettextCatalog.getPlural(
                value,
                '1 week ago',
                '{{ count }} weeks ago',
                {
                  count: value,
                }
              );

            case 'm':
              return this.gettextCatalog.getPlural(
                value,
                '1 month ago',
                '{{ count }} months ago',
                { count: value }
              );
          }
        }
      }
    }

    return value;
  }

  operatorRequiresValue() {
    return this._.includes(['known', 'unknown'], this.operator);
  }
}

AdvancedFilterSummary.$inject = ['_', 'moment', 'gettextCatalog'];

angular.module('cdApp.shared').component('cdAdvancedFilterSummary', {
  bindings: {
    filter: '<',
    operator: '<',
    value: '<',
    unit: '<',
  },

  template: `
      <span class="u-text-bold">{{ ::$ctrl.filter.label }}</span>

      <span ng-if="$ctrl.operatorIsStatic || $ctrl.viewValue">
        <cd-advanced-filter-operator operator="$ctrl.operator" filter="$ctrl.filter"></cd-advanced-filter-operator>
        <span class="u-text-italic">{{ $ctrl.viewValue }}</span>
      </span>
    `,
  controller: AdvancedFilterSummary,
});
