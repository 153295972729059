'use strict';

function userModel(moment, $$resource, cdApp, _) {
  'ngInject';

  const baseUrl = `${cdApp.config.api.main}/users/:id`;

  const userResource = $$resource(
    baseUrl,
    {},
    {
      get: {
        method: 'GET',
        isArray: false,
        transformResponse(data) {
          if (data && data !== 'null') {
            data = JSON.parse(data) || {};

            return _.extend(data, {
              birthday: data.birthday && moment(data.birthday).toDate(),
            });
          }

          return data;
        },
      },

      getPermissions: {
        url: `${baseUrl}/permissions`,
        method: 'GET',
      },

      savePermissions: {
        url: `${baseUrl}/permissions`,
        method: 'PUT',
      },

      resendInvitation: {
        url: `${baseUrl}/resend/welcome-email`,
        method: 'POST',
      },
    }
  );

  userResource.getEmptyPermissionsObject = () => ({
    canAdministerOrganization: false,
    canPublish: false,
    canAccessCalendar: false,
    canAccessPeople: false,
    canAccessContributions: false,
    canAccessWebsite: false,
    canAccessGraveyard: false,
    graveyard: {
      canAdministerGraveyard: false,
    },

    calendar: {
      canCreateInternalEvent: false,
      canEditAllEventsGlobal: false,
      canEditAllEventsLocal: false,
      booking: {
        canBook: false,
        canForceDoubleBooking: false,
      },

      canViewAllAbsence: false,
      canManageAbsence: false,
      canAccessSensitiveInformation: false,
    },

    forms: {
      access: false,
      sensitive: false,
    },

    people: {
      sensitive: false,
    },
  });

  userResource.applyPermissionsDependencies = (permissions) => {
    // Clear all calendar access permissions if user is not allowed to view calendar in the first place
    if (_.get(permissions, 'canAccessCalendar') === false) {
      permissions.calendar.canCreateInternalEvent = false;
      permissions.calendar.canEditAllEventsGlobal = false;
      permissions.calendar.canEditAllEventsLocal = false;
      permissions.calendar.booking = {
        canBook: false,
        canForceDoubleBooking: false,
      };

      permissions.calendar.canViewAllAbsence = false;
      permissions.calendar.canManageAbsence = false;
      permissions.calendar.canAccessSensitiveInformation = false;
    }
    if (
      _.get(permissions, 'calendar.booking.canBook') === false &&
      _.get(permissions, 'calendar.booking.canForceDoubleBooking') === true
    ) {
      permissions.calendar.booking.canForceDoubleBooking = false;
    }

    // Can administer graveyards if has access to graveyard in the first place
    if (_.get(permissions, 'canAccessGraveyard') === false) {
      permissions.graveyard.canAdministerGraveyard = false;
    }

    // Can access sensitive information only if has access to forms in the first place
    if (_.get(permissions, 'forms.access') === false) {
      permissions.forms.sensitive = false;
    }

    return permissions;
  };

  return userResource;
}
userModel.$inject = ['moment', '$$resource', 'cdApp', '_'];

angular.module('cdApp.shared').factory('Users', userModel);
