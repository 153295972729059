import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getLocale } from '../../config/store/Selector';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

interface YearMonthFormProps {
  date: Date;
  onChange: (date: Date) => void;
}

const YearMonthForm: FunctionComponent<YearMonthFormProps> = ({
  date,
  onChange,
}) => {
  const locale = useSelector(getLocale);
  const months = moment.localeData(locale).months();
  const years = [];
  // this will generate 10 years in the past and 10 in the future
  for (
    let i = fromMonth.getFullYear() - 10;
    i <= toMonth.getFullYear();
    i += 1
  ) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <div className="custom-select">
        <select name="month" onChange={handleChange} value={date.getMonth()}>
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="custom-select">
        <select name="year" onChange={handleChange} value={date.getFullYear()}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
};

export default YearMonthForm;
