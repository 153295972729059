import { getEventPrintDownloadToken } from '../services/event.service';

import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import cdApp from '@/react/config';

export const usePrintEvent = ({ eventId }: { eventId: number }) => {
  const printEvent = async (includeSensitiveInfo: boolean) => {
    const token = await getEventPrintDownloadToken(eventId);
    if (!token) {
      NotificationService.notifyError(
        gettextCatalog.getString('Download token is missing. Please try again.')
      );
      return;
    }
    window.open(
      `${cdApp.config.api.main}/calendar/${eventId}/print?downloadToken=${token}&organizationId=${cdApp.organization.id}&includeSensitiveInfo=${includeSensitiveInfo}`,
      '_blank'
    );
  };
  return { printEvent };
};
