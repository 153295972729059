'use strict';

class ViewLegalDocumentController {
  constructor(
    cdApp,
    gettextCatalog,
    toastr,
    LegalDocument,
    AuthenticationService,
    $sce
  ) {
    'ngInject';

    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.LegalDocument = LegalDocument;
    this.AuthenticationService = AuthenticationService;
    this.$sce = $sce;
  }

  $onInit() {
    this.email = `<span class="u-text-bold">${this.cdApp.me.email}</span>`;
    this.organizationName = `<span class="u-text-bold">${this.cdApp.organization.name}</span>`;

    this.viewLink = this.getLink('view');
    this.downloadLink = this.getLink('download');

    this.isLoading = false;
  }

  /**
   * Get a direct link to view or download the legal document
   *
   * @param {String} action Whether to `view` or `download` the document
   */
  getLink(action) {
    const { id } = this.resolve.legalDocument;
    const api = this.cdApp.config.api.main;
    const accessToken = this.AuthenticationService.getAccessToken();
    const link = this.$sce.trustAsResourceUrl(
      `${api}/legal-document/${id}/${action}?access_token=${accessToken}&organizationId=${this.cdApp.organization.id}`
    );

    return link;
  }

  /**
   * Approve the legal document and close the modal
   */
  approve() {
    const { id, organizationId } = this.resolve.legalDocument;
    this.isLoading = true;
    this.LegalDocument.approve({ id, organizationId })
      .$promise.then((legalDocument) => {
        this.close({ $value: new this.LegalDocument(legalDocument) });
        this.toastr.success(
          this.gettextCatalog.getString('Legal document approved successfully.')
        );
      })
      .catch((error) => {
        this.toastr.error(
          _.get(error, 'data.message') ||
            this.gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
ViewLegalDocumentController.$inject = [
  'cdApp',
  'gettextCatalog',
  'toastr',
  'LegalDocument',
  'AuthenticationService',
  '$sce',
];

angular.module('cdApp.shared').component('cdViewLegalDocument', {
  templateUrl:
    '@/app/shared/components/legal-document-modal/legal-document-modal.component.html',
  controller: ViewLegalDocumentController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
