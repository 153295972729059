import { selector } from 'recoil';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

type IntentionSettings = {
  // The year the changes is in effect from
  intentionReferenceInEffectFrom: string;
  intentionReferenceNumberType: 'parishSeries' | 'orgSeries';
  intentionMigrationReferenceNumber: number;
  // Whether the user can configure the intention migration reference number
  canConfigureIntentionMigrationReferenceNumber: boolean;
  // Blocks if there is intentions this and next year.
  canConfigureIntentionReferenceNumberType: boolean;
};

export const IntentionSettingsQuery = selector<IntentionSettings>({
  key: 'IntentionSettingsQuery',
  get: async () => {
    const response = await mainApi.get<IntentionSettings>(
      '/intention/settings'
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    response.data.intentionReferenceNumberType =
      response.data.intentionReferenceNumberType || 'parishSeries';
    return response.data;
  },
});
