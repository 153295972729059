import _ from 'lodash';

class CreateFormModalController {
  constructor($state, Forms, cdApp, $sce) {
    'ngInject';

    this.$state = $state;
    this.Forms = Forms;
    this.cdApp = cdApp;
    this.$sce = $sce;
  }

  $onInit() {
    const { Forms } = this;

    this.template = null;

    this.isLoading = true;
    this.churches = this.resolve.churches;
    this.labels = this.resolve.labels;
    this.showChurchSelector = this.cdApp.showChurchSelector;
    this.selectedLabel = null;
    this.selectedChurches = this.resolve.selectedChurches;
    Forms.getTemplates({
      language: this.getOrganizationLanguage(),
      countryIso: this.getOrganizationCountry(),
    })
      .$promise.then((formTemplates) => {
        this.formTemplates = _.filter(
          formTemplates,
          (formTemplate) => formTemplate.active
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  createBlankForm() {
    this.$state.go('app.private.forms.create');
  }

  chooseTemplate(templateId) {
    const { Forms, $sce } = this;

    this.selectedTemplateId = templateId;

    this.isLoadingTemplate = true;
    Forms.getTemplate({
      templateId,
      language: this.getOrganizationLanguage(),
      countryIso: this.getOrganizationCountry(),
    })
      .$promise.then((formTemplate) => {
        this.template = _.extend({}, formTemplate, {
          url: $sce.trustAsResourceUrl(
            `${formTemplate.url}/embed?hideSaveButton=true&hideTitle=true&hideDescription=true`
          ),
        });
      })
      .finally(() => {
        this.isLoadingTemplate = false;
      });
  }

  isValid() {
    return !_.isEmpty(this.selectedChurches);
  }

  useTemplate() {
    const { Forms, $state } = this;

    this.isDuplicatingForm = true;
    Forms.duplicateForm({
      id: this.template.id,
      churchIds: this.selectedChurches,
      labelId: this.selectedLabel || undefined,
    })
      .$promise.then((newForm) => {
        $state.go('app.private.forms.edit', { id: newForm.id });
      })
      .finally(() => {
        this.isDuplicatingForm = false;
      });
  }

  getOrganizationLanguage() {
    return _.get(this.cdApp, 'organization.locale.language');
  }

  getOrganizationCountry() {
    return _.get(this.cdApp, 'organization.locale.country');
  }
}

CreateFormModalController.$inject = ['$state', 'Forms', 'cdApp', '$sce'];
angular.module('cdApp.forms').component('createFormModal', {
  templateUrl:
    '@/app/forms/shared/components/create-form-modal/create-form-modal.component.html',
  controller: CreateFormModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
