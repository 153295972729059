import AuthenticationService from '@/react/services/AuthenticationService';

$.Redactor.prototype.linkit = function () {
  const format = (item) => {
    if (_.isString(item)) {
      return `
        <div class="row-fluid">
          <h3 style="color: #fff; text-align: center; background-color: #008DB6; font-weight: bold; margin: 0">
            ${item}
          </h3>
        </div>
      `;
    }

    let markup = `<div class="row-fluid"><span class="title">${item.title}</span></div>`;
    if (item.description) {
      markup += '<small>' + _.truncate(item.description, 100) + '</small>';
    }

    return markup + '</div>';
  };

  return {
    langs: {
      en: {
        cdInternalLinkNotice:
          'You can change your domain in settings on the Website. Look for "Main domain".',
      },

      da: {
        cdInternalLinkNotice:
          'Du kan ændre dit domæne under "Hoveddomæne" som du finder i indstillingerne på Hjemmesiden.',
      },

      de: {
        cdInternalLinkNotice:
          'Sie können die Domain auf Ihrer Internetseite ändern: Klicken Sie auf "Internetseiten-Einstellungen". Hier finden Sie das Feld "Hauptdomain".',
      },
    },

    getTemplate() {
      return `
        <div class="redactor-modal-tab" data-title="General">
          <section>
            <label translate>${this.lang.get('choose')}</label>
            <input type="text" id="redactor-link-content" />
          </section>

          <section>
            <label translate>${this.lang.get('text')}</label>
            <input type="text" id="redactor-link-url-text" class="form-control" />
          </section>

          <section>
            <label translate>URL</label>
            <input type="url" id="redactor-link-url" class="form-control" />
            <div id="redactor-link-url-helper" class="help-block" style="display: none;">${this.lang.get(
              'cdInternalLinkNotice'
            )}</div>
          </section>

          <section>
            <div class="checkbox">
              <label><input type="checkbox" id="redactor-link-blank">${this.lang.get(
                'link-in-new-tab'
              )}</label>
            </div>
          </section>

          <section>
            <button id="redactor-modal-button-action">${this.lang.get('insert')}</button>
            <button id="redactor-modal-button-cancel">${this.lang.get('cancel')}</button>
          </section>
        </div>
      `;
    },

    init() {
      const that = this;
      const button = this.button.addAfter('table', 'link', 'Link');
      const accessToken = AuthenticationService.getAccessToken();

      this.button.setIcon(button, '<i class="re-icon-link"></i>');
      this.modal.addTemplate('link', this.linkit.getTemplate());

      this.link.show = _.wrap(this.link.show, function (original) {
        let result = original.apply(this, _.tail(arguments));

        this.link.$inputContent = $('#redactor-link-content');
        this.link.$inputContent.val(that.link.content).select2({
          ajax: {
            url: cdApp.config.api.main + '/linkit',
            dataType: 'json',
            quietMillis: 250,
            data: (term) => ({
              search: term,
              organizationId: window.churchdeskOrganizationId,
            }),

            params: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },

            results: (data) => {
              if (data.title) {
                return { results: [] };
              }
              return { results: data };
            },
            cache: true,
          },

          id: (item) => item.url,
          minimumInputLength: 1,
          formatResult: format,
          formatSelection: (item) => {
            that.link.setModalValues({
              url: item.url,
              text: $('#redactor-link-url-text').val() || item.title,
            });

            if (/kw01.net|churchdesk.com/.test(item.url)) {
              $('#redactor-link-url-helper').show();
            }

            return format(item);
          },
          sortResults: (results) => {
            let output = _.map(results, (items, name) => {
              if (_.isEmpty(items)) return [null];

              items = _.sortBy(items, (item) => item.title);
              items.unshift(_.capitalize(name));

              return items;
            });

            return _.compact(_.flatten(_.values(output)));
          },
        });

        return result;
      });

      this.button.addCallback(button, this.link.show);
    },
  };
};
