(function () {
  'use strict';

  /**
   * @desc shorten a large number
   * @example <span>{{ yourNumber | shortenNumber }}</span>
   */

  function shortenNumber() {
    return function (num, digits) {
      let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      let decimal;

      for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (num <= -decimal || num >= decimal) {
          return +(num / decimal).toFixed(digits || 1) + units[i];
        }
      }

      return num;
    };
  }

  angular.module('cdApp.shared').filter('shortenNumber', shortenNumber);
})();
