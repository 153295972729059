import produce from 'immer';

import { IMPORT_FILE, GET_GEB_ACCESS_SUCCESS } from '../actions/constants';

export const initialState = {
  showModal: true,
  isGebCustomer: false,
};
const filePickerReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case IMPORT_FILE:
        return state;
      case GET_GEB_ACCESS_SUCCESS:
        draft.isGebCustomer = payload.isCustomer;
        return;
      default:
        return state;
    }
  });

export default filePickerReducer;
