import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { FBaseEvent, Type } from '../../models/calendar';
import { AppState } from '../../../redux';

const eventsAdapter = createEntityAdapter<FBaseEvent>({
  selectId: (event) =>
    event.type === Type.GroupedAbsence ? event.startDate.toString() : event.id,
});

export type EventsState = EntityState<FBaseEvent>;

const eventsSlice = createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState() as EventsState,
  reducers: {
    eventsReceived: eventsAdapter.setAll,
    updateOne: eventsAdapter.updateOne,
  },
});

export const eventsReducer = eventsSlice.reducer;

// Actions
export const eventsActions = eventsSlice.actions;

const selectors = eventsAdapter.getSelectors<AppState>(
  (state) => state.calendar.events
);

// Selectors
export const eventsSelectors = {
  getOriginalEvents: selectors.selectAll,
  getOriginalEvent: (id: number) => (state: AppState) =>
    selectors.selectById(state, id),
};
