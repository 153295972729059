/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { Crisp } from 'crisp-sdk-web';

/**
 * This is a simple wrapper for third-party libraries used to track user activity. The wrapper
 * is designed to work with any library and the set of methods defined here only call specific
 * methods exposed by the library.
 *
 */

class Analytics {
  constructor($window, cdApp, Me) {
    'ngInject';

    this.$window = $window;
    this.Me = Me;
    this.cdApp = cdApp;
  }

  /**
   * Call a method in a 3rd part Analytics library
   *
   * @param {string} libraryName The name of the library
   * @param {string} methodName The name of the method
   * @param {array} parameters An array of parameters that will be passed to the method
   */
  callAnalyticsMethod(libraryName, methodName, parameters) {
    const { Me, cdApp, $window } = this;

    const disabledEnvironments = ['development', 'test'];
    if (
      Me.masquerading ||
      _.includes(disabledEnvironments, cdApp.config.environment)
    ) {
      return;
    }

    const library = $window[libraryName];
    let method = library;
    if (methodName) {
      const methodExists = _.isFunction(
        _.get($window, [libraryName, methodName])
      );

      if (!methodExists) {
        // throw new Error(
        //   `Tried calling a non-existing method '${methodName}' in the library '${libraryName}'`
        // );
        console.warn(
          `Tried calling a non-existing method '${methodName}' in the library '${libraryName}'`
        );
        return;
      }
      method = library[methodName];
    }

    library && method.apply(library, parameters);
  }

  waitForElementToExist(elementId, task) {
    // Define a function to check if the element exists
    const checkElement = () => {
      const element = document.getElementById(elementId);
      if (element) {
        // If the element exists, run the task
        task();
      } else {
        // If the element doesn't exist, wait for another second and check again
        setTimeout(checkElement, 1000);
      }
    };

    // Start checking for the element
    checkElement();
  }

  /**
   * Provide additional details about a user, such as email address and name
   *
   * @param {Number} userId - The id of the user
   * @param {Object} userDetails - Details associated with the user
   */
  identifyUser(userId, userDetails) {
    const { Me } = this;

    // Boot of noticeable
    if (
      window.noticeable &&
      Me.email !== 'dev-automated-tests@churchdesk.com'
    ) {
      switch (userDetails.company.app_language) {
        case 'da':
          window.noticeableId = 'QlvkQRFgxvitTmPhvZn4';
          break;

        case 'de':
          window.noticeableId = 'KUyb1gSKLMiasGgZwc9E';
          break;

        default:
          window.noticeableId = 'ZJtKH0g0oQjPTOheSm4o';
          break;
      }

      this.waitForElementToExist(
        'noticeable-widget',
        () =>
          window.noticeableId &&
          window.noticeable.render('widget', window.noticeableId)
      );
    }

    // Boot of Crisp
    if (Me.email !== 'dev-automated-tests@churchdesk.com') {
      Crisp.chat.onChatClosed(() => {
        Crisp.chat.hide();
      });
      Crisp.message.onMessageReceived(() => {
        Crisp.chat.show();
        Crisp.chat.open();
      });
    }
    const disabledEnvironments = ['development', 'test'];

    if (
      Me.masquerading ||
      _.includes(disabledEnvironments, cdApp.config.environment)
    ) {
      return;
    }

    window.usetifulTags = {
      userId,
      language: userDetails.company.app_language,
      firstName: Me.firstName,
      createdAt: Me.createdAt ? moment(Me.createdAt).unix() : 0,
    };
    require('../../../scripts/usetiful.js');

    if (Me.email !== 'dev-automated-tests@churchdesk.com') {
      Crisp.session.onLoaded(() => {
        if (_.get(Me, 'picture.url')) {
          Crisp.user.setAvatar(Me.picture.url);
        }
        Crisp.session.setData({
          'cd-language': userDetails.company.app_language,
          'cd-altar-id': userDetails.company.organization_id,
        });
        Crisp.user.setNickname(Me.fullName);
        Crisp.user.setEmail(Me.email);
        Crisp.user.setCompany(userDetails.company.name, {
          url: userDetails.company.url,
          description: `Altar Id: ${userDetails.company.organization_id}`,
          geolocation: [userDetails.company.country, ''],
        });
      });
    }
  }

  /**
   * Provide additional details about an organization, such as name
   *
   * @param {Number} organizationId - The id of the user
   * @param {Object} organizationDetails - Details associated with the organization
   */
  /*
  identifyOrganization(organizationId, organizationDetails) {
    const { Me } = this;
     if (Me.masquerading) return;
     // Segment identification
    // this.callAnalyticsMethod(this.segment, 'group', [organizationId, organizationDetails]);
  }*/

  /**
   * Send an event for a specific feature
   *
   * @param {String} featureId - The name of the feature (e.g. `create event`)
   * @param {String} properties - A list of properties for the event (e.g. calendar event title, description etc.)
   * @param {Object} options - Additional configuration options
   */
  sendFeatureEvent(featureId, properties) {
    const { Me } = this;

    if (Me.masquerading) return;

    // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
    // this.callAnalyticsMethod('ga', null, ['event', _.get(properties, 'module', 'default'), featureId]);
  }

  /**
   * Send an event when the user navigates within the app
   *
   * @param {String} name - Name of the page
   * @param {String} title - Title of the page
   * @param {String} url - Full URL of the page
   * @param {String} path - Path portion of the URL of the page
   * @param {String} search - Query string portion of the URL of the page
   */
  // eslint-disable-next-line no-unused-vars
  trackPageView(name, title, url, path, search) {
    const { Me } = this;

    if (Me.masquerading) return;

    // this.callAnalyticsMethod('Beacon', null, [
    //   'event',
    //   {
    //     type: 'page-viewed',
    //     url: search ? `${path}?${search}` : path,
    //     title: name,
    //   },
    // ]);

    // this.callAnalyticsMethod('ga', null, ['set', 'page', path]);
    // this.callAnalyticsMethod('ga', null, ['set', 'title', name]);
    // this.callAnalyticsMethod('ga', null, ['send', 'pageview']);
  }
}

Analytics.$inject = ['$window', 'cdApp', 'Me'];
angular.module('cdApp.shared').service('Analytics', Analytics);
