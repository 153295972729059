import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import ElementWrapper from './ElementWrapper';

const Author: React.FC<{ groupName: string }> = ({ groupName }) => (
  <ElementWrapper>
    <FontAwesomeIcon icon={faUsers} />
    <span>{groupName}</span>
  </ElementWrapper>
);

export default Author;
