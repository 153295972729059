import React, { useCallback, useEffect } from 'react';
import { FormInstance, Space, Typography } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import {
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValue,
} from 'recoil';

import {
  DefaultConsentTypeName,
  HasContactConsented,
} from '../store/newsletterListState';
import { PeopleNewsletterLists } from '../types/people';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdWarningIcon } from '@/react/shared/components/Icons';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

const { Paragraph, Title } = Typography;

interface CheckDefaultConsentForSpecificContactProps
  extends Record<string, unknown> {
  contact: { id: number; name: string };
  updateNewsletterSubscriptions: (values: {
    peopleNewsletterLists: PeopleNewsletterLists[];
  }) => void;
  form: FormInstance<any>;
}

export const showCheckDefaultConsentForSpecificContactModal =
  createCdModal<CheckDefaultConsentForSpecificContactProps>(
    ({ setModalProps, contact, updateNewsletterSubscriptions, form }) => {
      const deaultConsent = useRecoilValue(DefaultConsentTypeName);

      const refresh = useRecoilRefresher(
        HasContactConsented({
          contactId: contact.id,
        })
      );

      useEffect(
        () => () => {
          refresh();
        },
        [refresh]
      );
      const onCancel = useCallback(() => {
        form.resetFields();
      }, [form]);
      const onFinish = useCallback(() => {
        updateNewsletterSubscriptions(form.getFieldsValue());
      }, [form, updateNewsletterSubscriptions]);

      useEffect(() => {
        setModalProps({
          modalType: ModalType.MODAL,
          title: gettextCatalog.getString('Approve'),
          onOk: onFinish,
          onCancel,
        });
      }, [onCancel, onFinish, setModalProps]);

      return (
        <Space size="middle" align="start">
          <CdWarningIcon size="2x" type="warning" />
          <article>
            <Title level={5} style={{ marginTop: '0px' }}>
              {gettextCatalog.getString('Registering a consent on contacts')}
            </Title>
            <Paragraph>
              {gettextCatalog.getString(
                '"{{contactName}}" has not consented to "{{deaultConsentName}}". By subscribing this contact to the selected newsletter list the contact will be registered as having consented to "{{deaultConsentName}}".',
                {
                  contactName: contact.name,
                  deaultConsentName: deaultConsent?.name,
                }
              )}
            </Paragraph>
          </article>
        </Space>
      );
    }
  );
