class CreateCustomFieldModal {
  constructor(_, toastr, gettextCatalog, cdApp, $http, appUtils) {
    'ngInject';

    this._ = _;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.$http = $http;
    this.appUtils = appUtils;
  }

  $onInit() {
    const { _, gettextCatalog } = this;

    this.isSaving = false;

    this.fieldTypes = [
      {
        type: 'textfield',
        label: gettextCatalog.getString('Text field'),
        description: gettextCatalog.getString(
          'This one-line text field allows you enter a short amount of information.'
        ),
      },

      {
        type: 'textarea',
        label: gettextCatalog.getString('Text box'),
        description: gettextCatalog.getString(
          'The multi-line text field allows you to enter a great deal of text information.'
        ),
      },

      {
        type: 'number',
        label: gettextCatalog.getString('Number'),
        description: gettextCatalog.getString(
          'This field only accepts numerical values which is useful for recording age, attendees, length, etc.'
        ),
      },

      {
        type: 'checkbox',
        label: gettextCatalog.getString('Checkbox'),
        description: gettextCatalog.getString(
          'This option will give you a single checkbox which is useful for recording yes or no (true or false) information.'
        ),
      },

      {
        type: 'selectboxes',
        label: gettextCatalog.getString('Multiple choice'),
        description: gettextCatalog.getString(
          'This option allow you to create your own list of terms and pick multiple terms from a list. This is useful for limitting options and ensure similarity.'
        ),
      },

      {
        type: 'select',
        label: gettextCatalog.getString('Dropdown'),
        description: gettextCatalog.getString(
          'This option allow you to create your own list of terms and pick a single term from a dropdown menu. This is great to lock down options and ensure that only one is selected.'
        ),
      },

      {
        type: 'lifeEventCustom',
        label: gettextCatalog.getString('Custom event'),
        description: gettextCatalog.getString(
          'The custom event field only accepts dates and is useful for recording important life events.'
        ),
      },
    ];

    const { type } = this.resolve;

    const customFieldType = _.includes(_.map(this.fieldTypes, 'type'), type)
      ? type
      : null;

    this.customField = {
      label: '',
      customFieldType,
      options: [{ label: '' }],
    };
  }

  shouldShowOptions() {
    const { _ } = this;
    return _.includes(
      ['select', 'selectboxes'],
      this.customField.customFieldType
    );
  }

  addOption() {
    this.customField.options.push({ label: '' });
    this.focusedInput = this.customField.options.length - 1;
  }

  removeOption(option) {
    const { _ } = this;
    _.remove(this.customField.options, option);
  }

  save() {
    const { _, cdApp, $http, toastr, appUtils } = this;

    const { label, customFieldType, options } = this.customField;

    const payload = { label, customFieldType };

    if (this.shouldShowOptions()) {
      payload.itemLabels = _(options)
        .map((option) => _.trim(option.label))
        .compact()
        .value();
    }

    this.isSaving = true;
    $http
      .post(`${cdApp.config.api.forms}/custom-fields/fields`, payload)
      .then((response) => {
        const customFieldKey = _.get(response, 'data.customFieldKey');
        this.close({ $value: customFieldKey });
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      })
      .finally(() => {
        this.isSaving = false;
      });
  }
}

CreateCustomFieldModal.$inject = [
  '_',
  'toastr',
  'gettextCatalog',
  'cdApp',
  '$http',
  'appUtils',
];

angular.module('cdApp.people').component('cdCreateCustomFieldModal', {
  template: require('./create-custom-field.component.html'),
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CreateCustomFieldModal,
});
