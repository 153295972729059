'use strict';

angular.module('cdApp.shared').factory('LegalDocument', [
  '$$resource',
  'cdApp',
  ($$resource, cdApp) =>
    $$resource(
      cdApp.config.api.main +
        '/organizations/:organizationId/legal-document/:id',
      {},
      {
        approve: {
          method: 'POST',
          url: `${cdApp.config.api.main}/organizations/:organizationId/legal-document/:id`,
        },
      }
    ),
]);
