(function () {
  'use strict';
  CalendarComment.$inject = ['$$resource'];

  function CalendarComment($$resource) {
    return $$resource(
      cdApp.config.api.main + '/calendar/:calendarId/comments/:id',
      {
        calendarId: '@calendarId',
      }
    );
  }

  angular.module('cdApp.shared').factory('CalendarComment', CalendarComment);
})();
