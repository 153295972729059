'use strict';

class AdvancedFilterCriteria {
  constructor(_, moment, dateFormatsLookup, gettextCatalog) {
    'ngInject';

    this._ = _;
    this.moment = moment;

    this.updateDelayMs = 1000;
    this.staticOperators = ['known', 'unknown'];
    this.dateOperatorModes = ['relative', 'absolute'];
    this.dateFilterTypes = ['date', 'lifeEvent'];
    this.datepickerFormat = dateFormatsLookup.getFormat();

    this.relativeDateUnits = {
      date: {
        d: gettextCatalog.getString('days ago'),
        w: gettextCatalog.getString('weeks ago'),
        m: gettextCatalog.getString('months ago'),
      },

      lifeEvent: {
        d: gettextCatalog.getString('days'),
        w: gettextCatalog.getString('weeks'),
        m: gettextCatalog.getString('months'),
      },
    };

    this.relativeDateMaxValues = {
      d: moment().isLeapYear() ? 366 : 365,
      w: moment().weeksInYear(),
      m: 12,
    };
  }

  $onInit() {
    const { _ } = this;
    this.criterion = angular.copy(this.criterionInput);

    if (_.size(this.filter.operators) === 1) {
      this.criterion.operator = _.first(this.filter.operators);
    }

    if (_.includes(this.dateFilterTypes, this.filter.type)) {
      this.criterion.unit = this.criterion.unit || 'd';

      const relativeOperators = {
        date: ['gt', 'eq', 'lt'],
        lifeEvent: ['lt', 'eq'],
      };

      this.dateOperators = {
        relative: _(relativeOperators[this.filter.type])
          .map((operator) => `${operator}:relative`)
          .value(),
        absolute: _(this.filter.operators)
          .map((operator) =>
            _.includes(this.staticOperators, operator)
              ? operator
              : `${operator}:absolute`
          )
          .value(),
      };
    }
  }

  changeOperator(mode) {
    const { _ } = this;

    const noValueRequired = _.includes(
      this.staticOperators,
      this.criterion.operator
    );

    let dateOperatorModeChanged = false;

    if (_.includes(this.dateFilterTypes, this.filter.type)) {
      if (mode === 'absolute') {
        dateOperatorModeChanged = _.isNumber(this.criterion.value);
        this.criterion.unit = null;
      } else {
        dateOperatorModeChanged = this.moment(
          this.criterion.value,
          this.moment.ISO_8601,
          true
        ).isValid();
        this.criterion.unit = this.criterion.unit || 'd';
      }
    }

    if (noValueRequired || dateOperatorModeChanged) {
      this.criterion.value = null;
    }

    this.emitUpdateEvent();
  }

  updateValue() {
    this.emitUpdateEvent();
  }

  isSelected(operator) {
    return operator === this.criterion.operator;
  }

  shouldShowValueInput(operator) {
    return (
      this.isSelected(operator) &&
      !this._.includes(this.staticOperators, operator)
    );
  }

  shouldShowFilterOperators() {
    return this._.size(this.filter.operators) > 1;
  }

  emitUpdateEvent() {
    this.onUpdate({ criterion: this.criterion, index: this.criterionIndex });
  }
}
AdvancedFilterCriteria.$inject = [
  '_',
  'moment',
  'dateFormatsLookup',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdAdvancedFilterCriteria', {
  templateUrl:
    '@/app/shared/components/advanced-filter/advanced-filter-criteria/advanced-filter-criteria.component.html',
  bindings: {
    filter: '<',
    criterionInput: '<criterion',
    criterionIndex: '<',
    onUpdate: '&',
  },

  controller: AdvancedFilterCriteria,
});
