'use strict';

class PeopleImportDetailsState {
  constructor(
    _,
    $window,
    cdApp,
    toastr,
    gettextCatalog,
    PeopleImportBatches,
    appUtils
  ) {
    'ngInject';

    this._ = _;
    this.$window = $window;
    this.cdApp = cdApp;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.PeopleImportBatches = PeopleImportBatches;
    this.appUtils = appUtils;
  }

  $onInit() {
    const { _, PeopleImportBatches, appUtils, toastr } = this;

    this.busy = true;
    this.showMore = false;

    PeopleImportBatches.get({ id: this.resolve.id })
      .$promise.then((importBatch) => {
        this.importBatch = importBatch;
        this.importBatch.mappings = _.map(
          this.importBatch.mappings,
          (mapping) => {
            let data = mapping.data;
            try {
              data = angular.fromJson(data);
            } catch (error) {
              data = null;
            }
            return _.assign({}, mapping, { data });
          }
        );
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  getMappings() {
    if (this.showMore) {
      return this.importBatch.mappings;
    }

    return this._.slice(this.importBatch.mappings, 0, 5);
  }

  downloadSkippedContacts() {
    const { cdApp, $window, PeopleImportBatches } = this;
    const batchId = this.resolve.id;

    PeopleImportBatches.getDownloadToken().$promise.then(({ token }) => {
      $window.location = `${cdApp.config.api.main}/people/import/skipped-people/download/${batchId}?downloadToken=${token}`;
    });
  }
}

PeopleImportDetailsState.$inject = [
  '_',
  '$window',
  'cdApp',
  'toastr',
  'gettextCatalog',
  'PeopleImportBatches',
  'appUtils',
];

angular.module('cdApp.people').component('cdPeopleImportDetailsState', {
  templateUrl:
    '@/app/people/people-import-details/people-import-details.component.html',
  controller: PeopleImportDetailsState,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
