'use strict';

class FeatureToggleService {
  constructor(_, $q, $http, cdApp) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$http = $http;
    this.cdApp = cdApp;

    this.cache = {};
  }

  hasFeature(flagKey) {
    const { _, $q, $http } = this;

    // Check cache first
    if (_.has(this.cache, flagKey)) return $q.resolve(this.cache[flagKey]);

    return $http
      .post(
        'https://flagr.churchdesk.com/api/v1/evaluation',
        this.getContext(flagKey)
      )
      .then(({ data }) => {
        const hasFeature = _.get(data, 'variantKey') === 'on';

        // Save in cache
        this.cache[flagKey] = hasFeature;
        return hasFeature;
      });
  }

  getContext(flagKey) {
    const { cdApp, _ } = this;

    const { me, organization, config } = cdApp;

    return {
      entityID: _.get(me, 'id', '').toString(),
      entityType: 'user',
      entityContext: {
        environment: config.environment,
        userId: _.get(me, 'id'),
        userEmail: _.get(me, 'email'),
        userMasquerading: _.get(me, 'masquerading'),
        masqueradingUser: _.get(me, 'masqueradingUser'),
        organizationId: _.get(organization, 'id'),
        organizationCountryIso2: _.get(organization, 'countryIso2'),
        organizationCurrencyIso: _.get(organization, 'currencyIso'),
        organizationLanguage: _.get(organization, 'locale.language'),
        organizationTimezone: _.get(organization, 'locale.timezone'),
        packages: _.map(
          _.filter(
            _.toPairs(_.get(organization, 'packages')),
            (pck) => !!_.get(pck, '[1].enabled')
          ),

          '[0]'
        ),

        planId: _.get(organization, 'planId'),
        siteStatus: _.get(organization, 'siteStatus'),
      },

      enableDebug: false,
      flagKey,
    };
  }
}

FeatureToggleService.$inject = ['_', '$q', '$http', 'cdApp'];
angular
  .module('cdApp.shared')
  .service('FeatureToggleService', FeatureToggleService);
