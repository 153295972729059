import { InterfaceGroups } from '@/react/organization/services/Groups.service';
import cdApp from '@/react/config';

export const formatOrganizationGroups = (
  groups: InterfaceGroups[],
  VisibilityAllowedValues: string[]
) => {
  const userGroups = cdApp.organization.myGroups;
  return groups
    .map((item) => ({
      value: item.id,
      label: item.name,
      disabled:
        !userGroups.includes(item.id) &&
        VisibilityAllowedValues.length < 3 &&
        VisibilityAllowedValues.includes('internal-group'),
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
};
