import type { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { head } from 'lodash';

import UserService from '../services/user.service';
import {
  FetchUsersSuccess,
  FetchUserPermissionSuccess,
  FetchUserPrivilegeSuccess,
} from '../redux/actions';
import { User } from '../types/User.types';
import { CdAction } from '../../redux/utils';
import AuthorizationService from '../../services/AuthorizationService';
import { getUserPermission as getUserPermissionSelector } from '../redux/selectors';

export function* getUsers(): SagaIterator {
  const payload: User[] = yield call(UserService.getUsers);
  yield put(FetchUsersSuccess(payload));
}

export function* getUserPermission(
  action: CdAction<{ permission: string }>
): SagaIterator {
  const permission = action.payload.permission;
  const existingAccess = yield select(getUserPermissionSelector, permission);
  // Do not request the backend if you already have a result for the privilege
  if (existingAccess !== null) return;
  const access = yield call(
    AuthorizationService.hasPermissionAnywhere,
    permission
  );
  yield put(FetchUserPermissionSuccess({ permission, access }));
}

export function* getUserPrivilege(
  action: CdAction<
    {
      entityId: number | string;
      entityType: string;
      privileges: string[];
      entityAttributes: any;
    }[]
  >
): SagaIterator {
  const privileges = action.payload;
  const entityType = head(privileges).entityType;
  const returnPrivileges = yield call(
    AuthorizationService.checkPrivilege,
    privileges
  );
  yield put(
    FetchUserPrivilegeSuccess({
      privileges: {
        [entityType]: returnPrivileges,
      },
    })
  );
}
