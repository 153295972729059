import { mainApi } from '../../api';

class LocationNameSuggestions {
  public async get(): Promise<string[]> {
    const response = await mainApi.get<string[]>(
      '/calendar/location-name/suggestions'
    );
    if (response.ok) {
      return response.data;
    }
    throw response.data;
  }
}

export default new LocationNameSuggestions();
