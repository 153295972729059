import { Form } from 'antd';
import styled from 'styled-components';

export const FormItemNoErrorMessage = styled(Form.Item)`
  &&&& {
    margin-bottom: 0px;
    .ant-form-item-explain {
      display: none;
    }
  }
`;
