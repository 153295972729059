'use strict';

class SelectCategoriesModalComponent {
  constructor(_, Taxonomies, uiSelectAllowNewMarker) {
    'ngInject';

    this._ = _;
    this.Taxonomies = Taxonomies;

    this.uiSelectAllowNewMarker = uiSelectAllowNewMarker;
  }

  $onInit() {
    const _ = this._;

    this.categories = _.filter(this.resolve.taxonomies, { type: 'event' });
    this.categoryIds = _(this.categories)
      .filter('registerAttendance')
      .map('id')
      .value();
  }

  onCategorySelect(category) {
    if (category.id || !category.name) return;

    const newCategory = new this.Taxonomies({
      type: 'event',
      name: category.name,
      color: 0,
    });

    newCategory.$save(({ id }) => {
      category.name = '';
      const savedCategory = this._.extend({ id }, newCategory);
      this.categories.push(savedCategory);
      this.categoryIds = this._.compact(this.categoryIds) || [];
      this.categoryIds.push(id);
    });
  }

  saveCategories() {
    this.Taxonomies.trackAttendance(null, {
      ids: this.categoryIds,
    }).$promise.then(() => {
      const selectedCategories = this._.filter(this.categories, (category) =>
        this._.includes(this.categoryIds, category.id)
      );

      this.close({ $value: selectedCategories });
    });
  }
}
SelectCategoriesModalComponent.$inject = [
  '_',
  'Taxonomies',
  'uiSelectAllowNewMarker',
];

angular.module('cdApp.calendar').component('cdSelectCategoriesModal', {
  templateUrl:
    '@/app/calendar/attendance/components/select-categories-modal/select-categories-modal.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: SelectCategoriesModalComponent,
});
