'use strict';

angular.module('cdApp.calendar').service('calendarMenuService', [
  '$q',
  '$http',
  '$window',
  '$state',
  'gettextCatalog',
  'calendarService',
  '_',
  'Authorization',
  'cdApp',
  function (
    $q,
    $http,
    $window,
    $state,
    gettextCatalog,
    calendarService,
    _,
    Authorization,
    cdApp
  ) {
    'ngInject';

    // Set the defaults at the beggining if they are not defined.
    if (!_.get(cdApp, 'data.calendar.settings.render')) {
      calendarService.setDefaults();
    }

    return {
      getMenu() {
        const topMenuItems = [
          {
            title: gettextCatalog.getString('Resource'),
            icon: _.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'resource'
            )
              ? 'far fa-check-square'
              : 'far fa-square',
            link: '#',
            name: 'resource',
            callback: 'topFilter',
            help: gettextCatalog.getString(
              'Choose if you want to show resources to each event in the calendar overview.'
            ),
          },

          {
            title: gettextCatalog.getString('Absence overview'),
            icon: _.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'absenceOverview'
            )
              ? 'far fa-check-square'
              : 'far fa-square',
            link: '#',
            name: 'absenceOverview',
            callback: 'topFilter',
            help: gettextCatalog.getString(
              'Choose if you want to show the absence overview below the calendar.'
            ),
          },

          {
            title: gettextCatalog.getString('Information icons'),
            icon: _.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'informationIcons'
            )
              ? 'far fa-check-square'
              : 'far fa-square',
            link: '#',
            name: 'informationIcons',
            callback: 'topFilter',
            help: gettextCatalog.getString(
              'Choose if you want to show icons explaining e.g. if the event is public and repeated.'
            ),
          },
        ];

        const menu = [
          {
            title: '',
            icon: 'fa fa-align-justify',
            class: [],
            dropdown: [
              {
                title: gettextCatalog.getString('Calendar changes'),
                icon: 'far fa-clock',
                link: $state.href('app.private.calendar.changes'),
              },

              {
                title: gettextCatalog.getString('Print calendar'),
                icon: 'fa fa-print',
                link: $state.href('app.private.calendar.print'),
              },

              {
                title: gettextCatalog.getString('Subscribe to calendar'),
                icon: 'fa fa-rss',
                link: '#',
                callback: 'export',
                attributes: { 'data-toggle': 'modal' },
              },

              {
                title: gettextCatalog.getString('Taxonomies'),
                icon: 'fa fa-sitemap',
                link: $state.href('app.private.settings.calendar', {
                  type: 'event',
                }),
              },
            ],
          },

          {
            title: '',
            icon: 'fa fa-filter',
            class: ['display-settings'],
            dropdown: [
              {
                isHeader: true,
                title: gettextCatalog.getString('My group activities'),
              },

              {
                title: gettextCatalog.getString('Events'),
                icon: _.includes(
                  _.get(cdApp, 'data.calendar.settings.render'),
                  'mygroup-events'
                )
                  ? 'far fa-check-square'
                  : 'far fa-square',
                link: '#',
                name: 'mygroup-events',
                callback: 'topFilter',
                help: gettextCatalog.getString('Show events in my groups.'),
              },

              {
                title: gettextCatalog.getString('Absence'),
                icon: _.includes(
                  _.get(cdApp, 'data.calendar.settings.render'),
                  'mygroup-absence'
                )
                  ? 'far fa-check-square'
                  : 'far fa-square',
                link: '#',
                name: 'mygroup-absence',
                callback: 'topFilter',
                help: gettextCatalog.getString('Show absences in my groups.'),
              },

              {
                isHeader: true,
                title: gettextCatalog.getString('Other calendar activities'),
              },

              {
                title: gettextCatalog.getString('Events'),
                icon: _.includes(
                  _.get(cdApp, 'data.calendar.settings.render'),
                  'othercalendar-events'
                )
                  ? 'far fa-check-square'
                  : 'far fa-square',
                link: '#',
                name: 'othercalendar-events',
                callback: 'topFilter',
                help: gettextCatalog.getString(
                  'Show all events in the church as well as availablity of users and resources.'
                ),
              },

              {
                title: gettextCatalog.getString('Absence'),
                icon: _.includes(
                  _.get(cdApp, 'data.calendar.settings.render'),
                  'othercalendar-absence'
                )
                  ? 'far fa-check-square'
                  : 'far fa-square',
                link: '#',
                name: 'othercalendar-absence',
                callback: 'topFilter',
                help: gettextCatalog.getString(
                  'Show absences from other groups.'
                ),
              },

              {
                isHeader: true,
                title: gettextCatalog.getString('My private calendar'),
              },

              {
                title: gettextCatalog.getString('Private events'),
                icon: _.includes(
                  _.get(cdApp, 'data.calendar.settings.render'),
                  'mycalendar-private'
                )
                  ? 'far fa-check-square'
                  : 'far fa-square',
                link: '#',
                name: 'mycalendar-private',
                callback: 'topFilter',
                help: gettextCatalog.getString('Show my own private events.'),
              },
            ],
          },

          {
            title: '',
            icon: 'fa fa-eye',
            class: [],
            dropdown: topMenuItems,
          },
        ];

        // Only show work plan for old orgs.
        const orgId = _.get(cdApp, 'organization.id');
        if (orgId < 802 || orgId === 2078) {
          menu[0].dropdown.push({
            title: gettextCatalog.getString('Work plan overview'),
            icon: 'fa fa-book',
            link: $state.href('app.private.calendar.workplanOverview'),
          });

          topMenuItems.push({
            title: gettextCatalog.getString('Work plan overview'),
            icon: _.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'workplanOverview'
            )
              ? 'far fa-check-square'
              : 'far fa-square',
            link: '#',
            name: 'workplanOverview',
            callback: 'topFilter',
            help: gettextCatalog.getString(
              'Choose if you want to show the work plan below the calendar.'
            ),
          });
        }

        menu[0].dropdown.push({
          title: gettextCatalog.getString('Switch to new calendar'),
          icon: 'fa fa-exchange',
          link: '#',
          callback: 'switchCalendar',
        });

        return $q((resolve) => resolve(menu));
      },
    };
  },
]);
