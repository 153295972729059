import { Card, Spin, Image, Space, Typography, Button, Alert } from 'antd';
import React, { Suspense, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useSso } from '../hooks/use-sso';

import { CdAlertInfo, CdCopyIcon } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const { Paragraph, Title, Text } = Typography;

export const SsoPage = ({ $stateParams }) => {
  const hashParams = new URLSearchParams($stateParams['#']);
  const idToken = hashParams.get('id_token');
  const state = hashParams.get('state');

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space size="large" direction="vertical" style={{ alignItems: 'center' }}>
        <Image
          src="https://assets.churchdesk.com/logos/default.blue.svg"
          preview={false}
          width={180}
        />
        <Suspense
          fallback={
            <Spin>
              <Card style={{ width: '400px' }}>
                {gettextCatalog.getString('Loading ChurchDesk')}
              </Card>
            </Spin>
          }
        >
          <SsoPageMessage idToken={idToken} state={state} />
        </Suspense>
      </Space>
    </div>
  );
};

export const SsoPageMessage = ({ idToken, state }) => {
  const { accessToken, error, email } = useSso({ idToken, state });
  const [buttonText, setButtonText] = useState(
    gettextCatalog.getString('Copy text')
  );
  const codeValue = `${idToken}&${state}&${error}`;

  const isUserMissingMessage = error === 'User does not exist';
  return (
    <Card style={{ width: '700px' }}>
      {accessToken && <>{gettextCatalog.getString('Loading ChurchDesk')}</>}
      {error && (
        <>
          <Title level={5}>
            {gettextCatalog.getString('An error occured')}
          </Title>
          {isUserMissingMessage && email && (
            <Alert
              message={gettextCatalog.getString('User does not exist')}
              description={gettextCatalog.getString(
                'Please contact your administrator and ask them to add {{ email }} as an user to ChurchDesk.',
                { email }
              )}
              type="info"
              icon={
                <span className="anticon anticon-info-circle ant-alert-icon">
                  <CdAlertInfo />
                </span>
              }
              showIcon
            />
          )}
          {!isUserMissingMessage && (
            <Text italic>
              {gettextCatalog.getString('Error message:')} {error}
            </Text>
          )}
          <Paragraph style={{ marginTop: '24px' }}>
            {gettextCatalog.getString(
              'Please include the following when contacting support:'
            )}
          </Paragraph>
          <Paragraph code style={{ wordBreak: 'break-all' }}>
            {codeValue}
          </Paragraph>
          <CopyToClipboard
            text={codeValue}
            onCopy={() => {
              setButtonText(gettextCatalog.getString('Copied'));
            }}
          >
            <Button style={{ margin: '0px 0px 10px 2px' }}>
              <CdCopyIcon />
              {buttonText}
            </Button>
          </CopyToClipboard>
        </>
      )}
    </Card>
  );
};
