'use strict';

angular.module('cdApp.shared').service('fileUploadService', [
  'FileUploader',
  'AuthenticationService',
  function (FileUploader, AuthenticationService) {
    'ngInject';

    return {
      upload(options) {
        angular.extend(options || {}, {
          autoUpload: false,
          filesAreUploading: false,
          errorButton: 'Show error',
          url: '/api/file',
          headers: {
            Authorization: `Bearer ${AuthenticationService.getAccessToken()}`,
          },
        });

        const uploader = new FileUploader(options);

        uploader._iframeTransport = function (item) {
          const form = angular.element('<form style="display: none;" />');
          const iframe = angular.element(
            '<iframe name="iframeTransport' + Date.now() + '">'
          );

          const input = item._input;

          if (item._form) item._form.replaceWith(input); // remove old form
          item._form = form; // save link to new form

          this._onBeforeUploadItem(item);

          input.prop('name', item.alias);

          angular.forEach(item.formData, (obj) => {
            angular.forEach(obj, (value, key) => {
              form.append(
                angular.element(
                  `<input type="hidden" name="${key}" value="${value}" />`
                )
              );
            });
          });

          form.prop({
            method: 'POST',
            action: item.url,
            target: iframe.prop('name'),
            enctype: 'multipart/form-data',
          });

          form.abort = () => {
            const xhr = { status: 0, dummy: true };
            const headers = {};
            const response = null;

            iframe.unbind('load').prop('src', 'javascript:false;');
            form.replaceWith(input);

            this._onCancelItem(item, response, xhr.status, headers);
            this._onCompleteItem(item, response, xhr.status, headers);
          };

          const input2 = angular.element(
            document.body.querySelector('#file-uploader-hidden-input')
          );

          input2.after(form);
          form.append(input).append(iframe);

          form[0].submit();
          this._render();
        };

        uploader.uploadAll = function () {
          const items = this.getNotUploadedItems().filter(
            (item) => !item.isUploading && !item.isError
          );

          if (!items.length || !this.file_group_id) return;

          angular.forEach(items, (item) => item._prepareToUploading());
          items[0].upload();
        };

        return uploader;
      },
    };
  },
]);
