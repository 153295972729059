import { combineReducers } from '@reduxjs/toolkit';

import { PartnerFiles } from '../actions/partner';
import { FileArchives } from '../actions/filearchive';

import filePickerReducer from './filepicker';
import partnerFilesReducer from './partner';
import lookupsReducer, { LookupsState } from './lookups';
import fileArchiveReducer from './filearchive';

export interface FilePickerState {
  filePicker: { showModal: boolean; isGebCustomer: boolean };
  partnerFiles: PartnerFiles;
  lookups: LookupsState;
  fileArchives: FileArchives;
}

export default combineReducers({
  filePicker: filePickerReducer,
  partnerFiles: partnerFilesReducer,
  lookups: lookupsReducer,
  fileArchives: fileArchiveReducer,
});
