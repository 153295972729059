(function () {
  'use strict';

  function genericFilter(type) {
    return function (users, selectedValue) {
      if (users && selectedValue && selectedValue.length > 0) {
        return _.filter(users, function (u) {
          let data = _.isArray(u[type]) ? u[type] : [u[type]];
          return !_.isEmpty(_.intersection(data, selectedValue));
        });
      } else {
        return users;
      }
    };
  }

  angular
    .module('cdApp.intranet')
    .filter('statusFilter', function () {
      return genericFilter('status');
    })
    .filter('rolesFilter', function () {
      return genericFilter('roles');
    });
})();
