/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Form,
  Select,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Col,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';

import { gettextCatalog } from '../../../services/I18nService';
import { paymentMethods } from '../../shared/intentionCommon';
import { getIntentionFees } from '../../../settings/redux/intention-fees/Selectors';
import { getChurches } from '../../../shared/store/resources';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import { StyledInlineForm } from '../../../shared/antd/StyledAntdComponents';
import { DetailedEvent } from '../../../calendar/models/calendar';
import { FoundationTypes } from '../../models/foundation';
import { IntentionFee } from '../../../settings/models/fee';
import { Resource } from '../../../shared/models/resource';
import {
  setIntentionFilters,
  setShouldShowMoreFilters,
} from '../../redux/intentions/Actions';
import {
  getIntentionFilters,
  getShouldShowMoreFilters,
} from '../../redux/intentions/Selectors';
import { IntentionFilters, IntentionSourceType } from '../../models/intention';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

// Antd
const { Option } = Select;
const { RangePicker } = DatePicker;

// Props
interface IntentionsOverviewFiltersProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSearch?: Function;
  event?: Partial<DetailedEvent>;
}

const IntentionsOverviewFilters: FunctionComponent<
  IntentionsOverviewFiltersProps
> = ({ onSearch, event }) => {
  const dispatch = useDispatch();
  const churches = useSelector(getChurches);
  const allIntentionFees = useSelector(getIntentionFees);
  const intentionFilters = useSelector(getIntentionFilters);
  const shouldShowMoreFilters = useSelector(getShouldShowMoreFilters);
  const [numberType, setNumberType] = useState<string>(IntentionSourceType.ALL);
  const [numActiveMoreFilters, updateNumActiveMoreFilters] =
    useState<number>(0);
  const [filteredChurches, setFilteredChurches] = useState<Resource[]>();
  const [filteredIntentionFees, setFilteredIntentionFees] =
    useState<IntentionFee[]>();
  const [form] = Form.useForm();

  const paymentStatuses = [
    { id: 'paid', name: gettextCatalog.getString('Paid'), value: 1 },
    { id: 'unpaid', name: gettextCatalog.getString('Unpaid'), value: 0 },
  ];

  useEffect(() => {
    /**
     * Refresh intention data if the table is not loading for the event view so by default
     * only 'regular intentions' (no stiftung intentions nor legat intentions) are loaded
     */
    if (filteredChurches && filteredIntentionFees) search();
  }, [
    JSON.stringify(_.map(filteredChurches, 'id')),
    JSON.stringify(_.map(filteredIntentionFees, 'id')),
  ]);

  useEffect(() => {
    if (allIntentionFees) {
      if (event) {
        const taxonomyIds = _.map(
          _.keys(_.get(event, 'taxonomies')),
          _.parseInt
        );
        const filteredIntentionFees = getFilteredFees(
          allIntentionFees,
          taxonomyIds
        );
        setFilteredIntentionFees(filteredIntentionFees);
        form.setFieldsValue({ feeIds: _.map(filteredIntentionFees, 'id') });
      } else {
        const orderedIntentionFees = getFilteredFees(allIntentionFees);
        setFilteredIntentionFees(orderedIntentionFees);
      }
    }
  }, [JSON.stringify(_.map(allIntentionFees, 'id'))]);

  useEffect(() => {
    if (churches) {
      if (event) {
        setFilteredChurches(event.churches as Resource[]);
        form.setFieldsValue({ churchIds: _.map(event.churches, 'id') });
      } else {
        setFilteredChurches(churches.asMutable({ deep: true }));
      }
    }
  }, [JSON.stringify(_.map(churches, 'id'))]);

  useEffect(() => {
    // Load global filters if not in manage intentions view
    if (!_.isEmpty(intentionFilters) && !event) {
      const acceptanceFrom = _.get(intentionFilters, 'acceptanceDateFrom');
      const acceptanceTo = _.get(intentionFilters, 'acceptanceDateTo');
      const calendarFrom = _.get(intentionFilters, 'calendarDateFrom');
      const calendarTo = _.get(intentionFilters, 'calendarDateTo');
      const deadlineDate = _.get(intentionFilters, 'deadlineDate');
      const completedMonth = _.get(intentionFilters, 'completedMonth');
      const completedYear = _.get(intentionFilters, 'completedYear');
      const forwardingMonth = _.get(intentionFilters, 'forwardingMonth');
      const forwardingYear = _.get(intentionFilters, 'forwardingYear');
      const paid = _.get(intentionFilters, 'paid');
      form.setFieldsValue({
        churchIds: _.get(intentionFilters, 'churchIds'),
        feeIds: _.get(intentionFilters, 'feeIds'),
        paymentMethods: _.get(intentionFilters, 'paymentMethods'),
        searchValue: _.get(intentionFilters, 'searchValue'),
        referenceNumberSourceType:
          _.get(intentionFilters, 'referenceNumberSourceType') ||
          IntentionSourceType.ALL,
        referenceNumberFrom: _.get(intentionFilters, 'referenceNumberFrom'),
        referenceNumberTo: _.get(intentionFilters, 'referenceNumberTo'),
        referenceNumberYear: _.get(intentionFilters, 'referenceNumberYear')
          ? moment().year(_.get(intentionFilters, 'referenceNumberYear'))
          : null,
        deadlineDate: deadlineDate ? moment(deadlineDate.asMutable()) : null,
        acceptanceDateRange:
          acceptanceFrom && acceptanceTo
            ? [
                moment(acceptanceFrom.asMutable()),
                moment(acceptanceTo.asMutable()),
              ]
            : null,
        calendarDateRange:
          calendarFrom && calendarTo
            ? [moment(calendarFrom.asMutable()), moment(calendarTo.asMutable())]
            : null,
        completed:
          !_.isNil(completedYear) && !_.isNil(completedMonth)
            ? moment().year(completedYear).month(completedMonth)
            : null,
        forwarding:
          !_.isNil(forwardingYear) && !_.isNil(forwardingMonth)
            ? moment().year(forwardingYear).month(forwardingMonth)
            : null,
        paymentStatus: !_.isNil(paid) ? _.toNumber(paid) : null,
      });

      const savedNumberType = _.get(
        intentionFilters,
        'referenceNumberSourceType'
      );
      if (savedNumberType) {
        setNumberType(savedNumberType);
      }

      getNumActiveMoreFilters();
    }
  }, [JSON.stringify(intentionFilters)]);

  const search = (_formValues?: any) => {
    const formValues = _formValues || form.getFieldsValue();

    const filters: IntentionFilters = {
      churchIds: _.isEmpty(formValues.churchIds)
        ? undefined
        : formValues.churchIds,
      feeIds: _.isEmpty(formValues.feeIds) ? undefined : formValues.feeIds,
      paymentMethods: _.isEmpty(formValues.paymentMethods)
        ? undefined
        : formValues.paymentMethods,
      referenceNumberSourceType:
        _.get(formValues, 'referenceNumberSourceType') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberSourceType
          : undefined,
      referenceNumberFrom:
        _.get(formValues, 'referenceNumberFrom') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberFrom
          : undefined,
      referenceNumberTo:
        _.get(formValues, 'referenceNumberTo') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberTo
          : undefined,
      referenceNumberYear: _.get(formValues, 'referenceNumberYear')
        ? formValues.referenceNumberYear.year()
        : undefined,
      searchValue: _.get(formValues, 'searchValue')
        ? formValues.searchValue
        : undefined,
      deadlineDate: _.get(formValues, 'deadlineDate')
        ? formValues.deadlineDate.startOf('day').toDate()
        : undefined,
      acceptanceDateFrom: _.get(formValues, 'acceptanceDateRange[0]')
        ? formValues.acceptanceDateRange[0].startOf('day').toDate()
        : undefined,
      acceptanceDateTo: _.get(formValues, 'acceptanceDateRange[1]')
        ? formValues.acceptanceDateRange[1].endOf('day').toDate()
        : undefined,
      calendarDateFrom: _.get(formValues, 'calendarDateRange[0]')
        ? formValues.calendarDateRange[0].startOf('day').toDate()
        : undefined,
      calendarDateTo: _.get(formValues, 'calendarDateRange[1]')
        ? formValues.calendarDateRange[1].endOf('day').toDate()
        : undefined,
      completedMonth: _.get(formValues, 'completed')
        ? moment(formValues.completed).month()
        : undefined,
      completedYear: _.get(formValues, 'completed')
        ? moment(formValues.completed).year()
        : undefined,
      forwardingMonth: _.get(formValues, 'forwarding')
        ? moment(formValues.forwarding).month()
        : undefined,
      forwardingYear: _.get(formValues, 'forwarding')
        ? moment(formValues.forwarding).year()
        : undefined,
      paid: _.isNil(_.get(formValues, 'paymentStatus'))
        ? null
        : _.get(formValues, 'paymentStatus')
          ? true
          : false,
    };

    if (event) {
      // Manage intentions on event
      onSearch(filters);
    } else {
      // Intentions overview page
      dispatch(setIntentionFilters(filters));
    }
  };

  const isNumberType = (numberTypes: IntentionSourceType[]) =>
    _.includes(numberTypes, numberType);

  const toggleMoreFilters = () =>
    dispatch(setShouldShowMoreFilters(!shouldShowMoreFilters));

  const getNumActiveMoreFilters = () => {
    // Get more filters
    const moreFields = _.pick(form.getFieldsValue(), [
      'completed',
      'forwarding',
      'deadlineDate',
      'paymentMethods',
      'paymentStatus',
    ]);
    // Check if payment methods is empty because it is a multi select
    if (_.isEmpty(moreFields.paymentMethods)) {
      moreFields.paymentMethods = null;
    }
    const numActiveMoreFields = _.countBy(
      moreFields,
      (field) => !_.isNil(field) || !_.isEmpty(field)
    ).true;
    updateNumActiveMoreFilters(numActiveMoreFields);
  };

  return (
    <React.Fragment>
      <StyledInlineForm
        id="intentionFiltersForm"
        onFinish={search}
        form={form}
        style={{
          marginBottom: 10,
          padding: 12,
          background: '#fbfbfb',
          border: '1px solid #d9d9d9',
          borderRadius: 4,
        }}
      >
        <Row gutter={8}>
          {/* Church filter */}
          <Col span={5}>
            <Form.Item name="churchIds" style={{ maxWidth: 'none' }}>
              <Select
                mode="multiple"
                placeholder={gettextCatalog.getString('Parishes')}
                allowClear
                maxTagPlaceholder={(selectedItems) => {
                  if (selectedItems.length > 1) {
                    return `${selectedItems.length} ${gettextCatalog.getString(
                      'Parishes'
                    )}`;
                  } else {
                    return selectedItems[0]?.label;
                  }
                }}
                maxTagCount={0}
              >
                {(filteredChurches || []).map((church) => (
                  <Option key={church.id} value={church.id}>
                    {_.get(church, 'name')}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Search */}
          <Col span={15}>
            <Form.Item name="searchValue" style={{ maxWidth: 'none' }}>
              <Input
                placeholder={gettextCatalog.getString(
                  'Search for text, founder, prefered note, comment or number...'
                )}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* Search */}
          <Col span={4}>
            <Button
              form="intentionFiltersForm"
              htmlType="submit"
              type="primary"
            >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Search')}
            </Button>
          </Col>
        </Row>
        <Row gutter={8}>
          {/* Search on number */}
          <Col lg={10} xl={9}>
            <Form.Item
              label={gettextCatalog.getString('Number')}
              labelCol={{ span: 24 }}
              style={{ maxWidth: 'none' }}
            >
              <Input.Group compact style={{ display: 'flex' }}>
                {/* Number Type */}
                <Form.Item
                  name="referenceNumberSourceType"
                  initialValue={IntentionSourceType.ALL}
                  noStyle
                >
                  <Select
                    placeholder={gettextCatalog.getString('Select type')}
                    onChange={(numberType) =>
                      setNumberType(numberType && numberType.toString())
                    }
                    style={{ width: 120 }}
                  >
                    <Option value={IntentionSourceType.ALL}>
                      {gettextCatalog.getString('All')}
                    </Option>
                    <Option value={IntentionSourceType.INTENTIONEN}>
                      Intentionen
                    </Option>
                    <Option value={IntentionSourceType.STIFTUNGEN}>
                      {_.startCase(FoundationTypes.STIFTUNGEN)}
                    </Option>
                    <Option value={IntentionSourceType.LEGATE}>
                      {_.startCase(FoundationTypes.LEGATE)}
                    </Option>
                  </Select>
                </Form.Item>
                {/* Source letter ('S' or 'L') */}
                {isNumberType([
                  IntentionSourceType.STIFTUNGEN,
                  IntentionSourceType.LEGATE,
                ]) && (
                  <Input
                    style={{
                      width: 35,
                      borderRight: 0,
                      pointerEvents: 'none',
                      background: '#fff',
                    }}
                    disabled
                    value={form.getFieldValue('referenceNumberSourceType')}
                  />
                )}
                {/* Number from */}
                <Form.Item name="referenceNumberFrom" noStyle>
                  {isNumberType([IntentionSourceType.ALL]) ? (
                    <CdTooltip
                      placement="topLeft"
                      title={gettextCatalog.getString(
                        'Select "Intentions", "Foundations" or "Legacies" to use this filter'
                      )}
                    >
                      <InputNumber
                        style={{ textAlign: 'center', flex: 1 }}
                        placeholder={gettextCatalog.getString('Min')}
                        disabled={true}
                      />
                    </CdTooltip>
                  ) : (
                    <InputNumber
                      style={{ textAlign: 'center', flex: 1 }}
                      placeholder={gettextCatalog.getString('Min')}
                      disabled={false}
                    />
                  )}
                </Form.Item>
                <Input
                  style={{
                    width: 45,
                    borderRight: 0,
                    pointerEvents: 'none',
                    textAlign: 'center',
                  }}
                  placeholder={gettextCatalog.getString('to')}
                  disabled
                />
                {/* Number to */}
                <Form.Item name="referenceNumberTo" noStyle>
                  {isNumberType([IntentionSourceType.ALL]) ? (
                    <CdTooltip
                      placement="topLeft"
                      title={gettextCatalog.getString(
                        'Select "Intentions", "Foundations" or "Legacies" to use this filter'
                      )}
                    >
                      <InputNumber
                        style={{ textAlign: 'center', flex: 1 }}
                        placeholder={gettextCatalog.getString('Max')}
                        disabled={true}
                      />
                    </CdTooltip>
                  ) : (
                    <InputNumber
                      style={{ textAlign: 'center', flex: 1 }}
                      placeholder={gettextCatalog.getString('Max')}
                      disabled={false}
                    />
                  )}
                </Form.Item>
                {/* Year */}
                <Form.Item name="referenceNumberYear" noStyle>
                  <DatePicker
                    placeholder={gettextCatalog.getString('Year')}
                    picker="year"
                    style={{ flex: 1 }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          {/* Acceptance date */}
          <Col lg={5} xl={6}>
            <Form.Item
              name="acceptanceDateRange"
              label={gettextCatalog.getString('Acceptance Date')}
              labelCol={{ span: 24 }}
              style={{ maxWidth: 'none' }}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                placeholder={[
                  gettextCatalog.getString('From'),
                  gettextCatalog.getString('To'),
                ]}
              />
            </Form.Item>
          </Col>
          {/* Kalendar Date */}
          <Col lg={5} xl={6}>
            <Form.Item
              name="calendarDateRange"
              label={gettextCatalog.getString('Calendar date')}
              labelCol={{ span: 24 }}
              style={{ maxWidth: 'none' }}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                placeholder={[
                  gettextCatalog.getString('From'),
                  gettextCatalog.getString('To'),
                ]}
              />
            </Form.Item>
          </Col>
          {/* More/Less filters Button */}
          <Col
            lg={4}
            xl={3}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Form.Item>
              <Button
                type="default"
                onClick={toggleMoreFilters}
                style={
                  numActiveMoreFilters > 0
                    ? { borderColor: '#1fa1c2', color: '#1fa1c2' }
                    : null
                }
              >
                <FontAwesomeIcon
                  icon={shouldShowMoreFilters ? faAngleUp : faAngleDown}
                  style={{ marginRight: 8 }}
                />
                {shouldShowMoreFilters
                  ? gettextCatalog.getString('Less filters')
                  : gettextCatalog.getString('More filters')}
                {numActiveMoreFilters > 0 ? ` · ${numActiveMoreFilters}` : null}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={8}
          style={{
            background: 'white',
            borderTop: '1px solid #d9d9d9',
            margin: '12px -12px -12px',
            padding: 12,
            display: shouldShowMoreFilters ? 'flex' : 'none',
          }}
        >
          {/* Abrechnung month */}
          <Col span={4}>
            <Form.Item
              name="completed"
              label={gettextCatalog.getString('Completed')}
              labelCol={{ span: 24 }}
              style={{ minWidth: 0, maxWidth: 'none' }}
            >
              <DatePicker
                picker="month"
                style={{ width: '100%' }}
                onChange={getNumActiveMoreFilters}
              />
            </Form.Item>
          </Col>
          {/* Weiterleitung Month */}
          <Col span={4}>
            <Form.Item
              name="forwarding"
              label={gettextCatalog.getString('Forwarded')}
              labelCol={{ span: 24 }}
              style={{ minWidth: 0, maxWidth: 'none' }}
            >
              <DatePicker
                picker="month"
                style={{ width: '100%' }}
                onChange={getNumActiveMoreFilters}
              />
            </Form.Item>
          </Col>
          {/* Deadline date */}
          <Col span={4}>
            <Form.Item
              name="deadlineDate"
              label={gettextCatalog.getString('Deadline')}
              labelCol={{ span: 24 }}
              style={{ minWidth: 0, maxWidth: 'none' }}
            >
              <DatePicker
                placeholder={gettextCatalog.getString('Date')}
                style={{ width: '100%' }}
                onChange={getNumActiveMoreFilters}
              />
            </Form.Item>
          </Col>
          {/* Payment methods */}
          <Col span={5}>
            <Form.Item
              name="paymentMethods"
              label={gettextCatalog.getString('Payment methods')}
              labelCol={{ span: 24 }}
              style={{ minWidth: 0, maxWidth: 'none' }}
            >
              <Select
                mode="multiple"
                allowClear
                maxTagPlaceholder={(selectedItems) => {
                  if (selectedItems.length > 1) {
                    return `${selectedItems.length} ${gettextCatalog.getString(
                      'Methods'
                    )}`;
                  } else {
                    return `${selectedItems.length} ${gettextCatalog.getString(
                      'Method'
                    )}`;
                  }
                }}
                maxTagCount={0}
                onChange={getNumActiveMoreFilters}
                style={{ width: '100%' }}
                placeholder={gettextCatalog.getString('Payment methods')}
              >
                {(paymentMethods() || []).map((paymentMethod) => (
                  <Option key={paymentMethod.id} value={paymentMethod.id}>
                    {_.get(paymentMethod, 'name')}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Payment status */}
          <Col span={5}>
            <Form.Item
              name="paymentStatus"
              label={gettextCatalog.getString('Payment status')}
              labelCol={{ span: 24 }}
              style={{ minWidth: 0, maxWidth: 'none' }}
            >
              <Select
                allowClear
                onChange={getNumActiveMoreFilters}
                style={{ width: '100%' }}
                placeholder={gettextCatalog.getString('Payment status')}
              >
                {(paymentStatuses || []).map((paymentStatus) => (
                  <Option key={paymentStatus.id} value={paymentStatus.value}>
                    {_.get(paymentStatus, 'name')}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </StyledInlineForm>
    </React.Fragment>
  );
};

export default IntentionsOverviewFilters;
