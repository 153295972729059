import { Form } from 'antd';

import cdApp from '../../config';
import { useOrganization } from '../../organization/hooks/useOrganization';

export enum ImprintType {
  TEXT = 'text',
  LINK = 'link',
}
export interface InitialValues {
  imprintLink: string;
  imprintText: string;
  contentType: ImprintType;
}

export const useImprint = () => {
  const [imprintForm] = Form.useForm();
  const { organizationSettings, updateSettings } = useOrganization();
  const initialValues: InitialValues = {
    imprintLink: organizationSettings?.imprintLink,
    imprintText: organizationSettings?.imprintText,
    contentType: organizationSettings?.imprintLink
      ? ImprintType.LINK
      : ImprintType.TEXT,
  };

  const viewImprint = () => {
    if (organizationSettings?.imprintLink) {
      window.open(organizationSettings.imprintLink, '_blank');
    }
    if (organizationSettings?.imprintText) {
      const language = cdApp.organization.locale.language || 'en';
      window.open(
        `${cdApp.references.imprintLandingPage}/${language}/${cdApp.organization.id}`,
        '_blank'
      );
    }
  };
  const onSave = () => {
    imprintForm.validateFields().then((values) => {
      switch (values.contentType) {
        case ImprintType.LINK:
          updateSettings({ imprintLink: values.imprintLink });
          break;
        case ImprintType.TEXT:
          updateSettings({ imprintText: values.imprintText });
          break;
      }
    });
  };
  const canViewImprint =
    organizationSettings?.imprintLink !== undefined ||
    organizationSettings?.imprintText !== undefined;

  return {
    imprintForm,
    initialValues,
    onSave,
    viewImprint,
    canViewImprint,
  };
};
