(function () {
  'use strict';
  ImageDropAreaController.$inject = [
    'Upload',
    'toastr',
    'gettextCatalog',
    'cdApp',
    '$uibModal',
    '$q',
  ];

  function ImageDropAreaController(
    Upload,
    toastr,
    gettextCatalog,
    cdApp,
    $uibModal,
    $q
  ) {
    let $ctrl = this;
    $ctrl.upload = function ($file, $newFiles) {
      let response;
      if ($ctrl.showWarning && $ctrl.warningText) {
        response = $uibModal.open({
          component: 'cdSimpleModal',
          resolve: {
            title() {
              return gettextCatalog.getString('File upload');
            },
            body() {
              return $ctrl.warningText;
            },
            options: {
              confirmButtonText: gettextCatalog.getString('Upload'),
              closeButtonText: gettextCatalog.getString('Cancel'),
              confirmButtonType: 'success',
            },
          },
        }).result;
      } else {
        const deferred = $q.defer();
        deferred.resolve();
        response = deferred.promise;
      }
      response
        .then(() => {
          if ($file) {
            $ctrl.uploading = true;

            if (
              $ctrl.largeImageLimit &&
              $newFiles[0] &&
              $newFiles[0].size > $ctrl.largeImageLimit
            ) {
              $ctrl.uploadingLargeImage = true;
            } else {
              $ctrl.uploadingLargeImage = false;
            }

            Upload.upload({
              url: $ctrl.url(),
              data: {
                file: $newFiles[0] || $file,
              },
            }).then(
              function (res) {
                $ctrl.onUpload(res);
                $ctrl.uploading = false;
                $ctrl.uploadingLargeImage = false;
              },
              function (err) {
                $ctrl.uploading = false;

                if (err && err.data) {
                  toastr.error(err.data.message);
                }

                if (err.status === 413) {
                  toastr.error(
                    gettextCatalog.getString(
                      'The image you chose is too large. The maximum allowed file size is {{size}}',
                      {
                        size: $ctrl.sizeLimit,
                      }
                    )
                  );
                }
              }
            );
          }
        })
        .catch(() => {
          $ctrl.remove();
        });
    };

    $ctrl.remove = function () {
      $ctrl.image = null;
      $ctrl.onRemove();
    };

    $ctrl.$onInit = function () {
      $ctrl.isBlob = $ctrl.image instanceof Blob;
      $ctrl.sizeLimit = $ctrl.sizeLimit || '100MB';
      $ctrl.largeImageLimit =
        $ctrl.sizeLimit.slice(0, $ctrl.sizeLimit.indexOf('MB')) *
        Math.pow(1024, 2);
      $ctrl.thumbnailWidth = $ctrl.thumbnailWidth || 150;
      $ctrl.allowedTypes =
        $ctrl.allowedTypes || gettextCatalog.getString('JPEG, PNG, GIF or BMP');

      $ctrl.url = function () {
        switch ($ctrl.context) {
          case 'users':
            return (
              cdApp.config.api.main + `/users/${$ctrl.userId}/upload/picture`
            );

          case 'profile':
            return cdApp.config.api.main + '/people/people/upload';
          case 'contribution':
            return cdApp.config.api.payments + '/files';
          case 'stripe-account':
            return `${cdApp.config.api.main}/organizations/${cdApp.organization.id}/account/identity/document`;
          case 'newsletter-logo':
            return `${cdApp.config.api.main}/organizations/upload?type=people`;
          default:
            return cdApp.config.api.payments + '/files';
        }
      };
    };
  }

  angular.module('cdApp.shared').component('cdImageDropArea', {
    templateUrl:
      '@/app/shared/components/image-drop-area/image-drop-area.component.html',
    transclude: true,
    bindings: {
      image: '<',
      context: '<',
      onUpload: '&',
      onRemove: '&',
      sizeLimit: '<',
      thumbnailWidth: '<',
      userId: '<',
      showWarning: '<',
      warningText: '<',
      allowedTypes: '<',
    },

    controller: ImageDropAreaController,
  });
})();
