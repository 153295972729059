'use strict';

class HomepageComponent {
  constructor(gettextCatalog, Authorization, _) {
    this.Authorization = Authorization;

    this.noAccess = gettextCatalog.getString('You cannot access {{package}}', {
      package: _.capitalize(
        gettextCatalog.getString('website', {}, 'The name of the app')
      ),
    });
  }
}

HomepageComponent.$inject = ['gettextCatalog', 'Authorization', '_'];

angular.module('cdApp.homepage').component('cdHomepage', {
  template: require('./homepage.component.html'),
  bindings: {
    errorCode: '<',
  },

  controller: HomepageComponent,
});
