import { useModal } from '@ebay/nice-modal-react';
import { useCallback, useState } from 'react';
import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { orderBy } from 'lodash';

import { WebsiteListQuery } from '../../store/widgetService';
import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import NotificationService from '../../../services/NotificationService';
import { gettextCatalog } from '../../../services/I18nService';
import { TemplateCard } from '../CreateWebsiteDrawer';
import cdApp from '../../../config';
import templates from '../extra/templates.json';

export const useCreateWebsite = () => {
  const language = cdApp.organization.locale.language;
  const modal = useModal('CreateWebsiteDrawer');
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null);
  const [isCreationInProgress, setIsCreationInProgress] =
    useState<boolean>(false);
  const onClose = useCallback(() => {
    modal.hide();
  }, [modal]);
  const refreshWebsiteList = useRecoilRefresher(WebsiteListQuery);
  const createWebsite = useRecoilCallback(
    () =>
      async ({ id }: { id: string }) => {
        const response = await mainApi.post<TemplateCard>('/sites', {
          templateId: +id,
        });
        if (!response.ok) {
          handleError(response);
          return null;
        }
        setIsCreationInProgress(false);
        NotificationService.notifySuccess(
          gettextCatalog.getString('The website is created')
        );
      }
  );
  const createHandler = useCallback(async () => {
    setIsCreationInProgress(true);
    await createWebsite({ id: selectedTemplateId });
    refreshWebsiteList();
    onClose();
  }, [createWebsite, onClose, refreshWebsiteList, selectedTemplateId]);

  const getWebsiteTemplates = () => {
    let websiteTemplates = templates;
    const organizationDioceseId = cdApp.organization.dioceseId;

    if (language === 'da') {
      websiteTemplates = websiteTemplates.filter(
        (item) => item.language === 'da' && !item.dioceseId
      );
    } else if (language === 'de') {
      websiteTemplates = websiteTemplates.filter(
        (item) => item.language === 'de' && !item.dioceseId
      );
    } else {
      websiteTemplates = websiteTemplates.filter(
        (item) => item.language === 'en' && !item.dioceseId
      );
    }

    websiteTemplates = websiteTemplates.concat(
      templates.filter((item) => item.dioceseId === organizationDioceseId)
    );

    return orderBy(websiteTemplates, ['dioceseId', 'title'], ['asc', 'asc']);
  };
  return {
    modal,
    setSelectedTemplateId,
    createHandler,
    selectedTemplateId,
    onClose,
    isCreationInProgress,
    getWebsiteTemplates,
  };
};
