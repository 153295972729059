import React, { Suspense, useEffect } from 'react';
import {
  useRecoilState,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import CdrPageLoader from '../../shared/components/CdrPageLoader';
import { NewsletterEditor } from '../message-editor/newsletter-editor';
import AdvancedMessageEditor from '../message-editor/advanced-editor/AdvancedMessageEditor';
import { MessageByIdQuery, peopleMessageIdState } from '../store/message';
import { MessageStatisticsFilters, MessageType } from '../types/message';
import MailEditor from '../message-editor/basic-editor/MailEditor';

export default function HocRootMailEditor(props: any) {
  return (
    <Suspense fallback={<CdrPageLoader />}>
      <RootMailEditor {...props} />
    </Suspense>
  );
}

function RootMailEditor({
  $stateParams: { messageId, to, type, filter, contactIds, sourceInfo },
}: {
  $stateParams: {
    messageId?: number;
    to: any[];
    contactIds: number[];
    type: MessageType;
    filter: string;
    sourceInfo?: any;
  };
}) {
  const [peopleMessageId, setPeopleMessageId] =
    useRecoilState(peopleMessageIdState);
  const refreshMessageOnClose = useRecoilRefresher(MessageByIdQuery(messageId));
  useEffect(() => {
    setPeopleMessageId(messageId);
    return () => {
      window.setTimeout(() => {
        if (messageId) {
          // Clear local state when the message is closed.
          // This is necessary to avoid showing the wrong message when opening a new one.
          // Or showing the right message with the wrong data such as events and blogs that might have been updated.
          // We use setTimeout to ensure that that Recoil is not fetching it right away.
          refreshMessageOnClose();
          setPeopleMessageId(null);
        }
      }, 0);
    };
  }, [messageId, setPeopleMessageId, refreshMessageOnClose]);
  const props = {
    messageId: peopleMessageId,
    to,
    statisticsFilter: filter as MessageStatisticsFilters,
    contactIds,
    sourceInfo,
  };
  if (peopleMessageId !== messageId && messageId) return null;
  const renderEditor = () => {
    switch (type) {
      case MessageType.BASIC:
        return <MailEditor {...props} />;
      case MessageType.SIMPLE:
        return <NewsletterEditor {...props} />;
      case MessageType.ADVANCED:
      case MessageType.EVENT_INVITATION:
        return <AdvancedMessageEditor type={type} {...props} />;
      default:
    }
  };

  return renderEditor();
}
