// Redux
import { getEventIntentions } from '../../../../../react/intention/redux/intentions/Selectors';
import { getIntentionFees } from '../../../../../react/settings/redux/intention-fees/Selectors';
import { getFilteredFees } from '../../../../../react/settings/redux/intention-fees/data.converter';
import {
  IntentionPriorityTypes,
  IntentionStatusTypes,
  PaymentMethodTypes,
} from '../../../../../react/intention/models/intention';
import {
  fetchEventIntentions,
  unassignIntention,
  deleteIntention,
} from '../../../../../react/intention/redux/intentions/Actions';

class EventDetailIntentionsComponent {
  constructor(
    $state,
    gettextCatalog,
    cdApp,
    Authorization,
    Planning,
    $ngRedux,
    $scope,
    $uibModal
  ) {
    'ngInject';

    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
    this.Planning = Planning;
    this.$state = $state;
    this.$uibModal = $uibModal;
    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  // Lifecycle functions

  $onInit() {
    this.fetchEventIntentions(this.calendarId);
  }

  // Main controller functions

  removeIntention = (intention) => {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Delete intention'),
          body: () =>
            this.gettextCatalog.getString(
              'Do you want to delete the intention {{referenceNumber}}?',
              {
                referenceNumber: intention.referenceNumber,
              }
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Delete'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.deleteIntention({
          id: intention.id,
          calendarId: intention.calendarId,
        });
      });
  };

  unassignIntentionFromEvent = (intention) => {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Unassign intention'),
          body: () =>
            this.gettextCatalog.getString(
              'Do you want to unassign the intention {{referenceNumber}}?',
              {
                referenceNumber: intention.referenceNumber,
              }
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Unassign'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.unassignIntention({
          id: intention.id,
          calendarId: intention.calendarId,
        });
      });
  };

  hasCompletedIntentions = () =>
    !_.isEmpty(this.eventIntentions) &&
    _.every(this.eventIntentions, ['status', IntentionStatusTypes.COMPLETED]);

  isIntentionCompleted = (intention) =>
    intention && intention.status === IntentionStatusTypes.COMPLETED;

  goToIntentionsOverview = () => {
    this.$state.go('app.private.intention.overview');
  };

  editIntention = (intention) => {
    this.$state.go('app.private.intention.edit', {
      id: intention.id,
      currentState: 'edit',
      calendarId: intention.calendarId,
    });
  };

  createIntention = () => {
    this.$state.go('app.private.intention.create', {
      currentState: 'create',
      calendarId: this.calendarId,
    });
  };

  manageIntentions = () => {
    this.$state.go('app.private.intention.eventIntentions', {
      id: this.calendarId,
    });
  };

  isPaid = (intention) => intention && intention.paid;

  isFirstIntention = (intention) =>
    intention && intention.priority === IntentionPriorityTypes.PERSOLVIERT;

  getPaymentMethodTranslation(paymentMethod) {
    switch (paymentMethod) {
      case PaymentMethodTypes.PAIDBYCASH:
        return this.gettextCatalog.getString('Cash');
      case PaymentMethodTypes.PAIDBYBANK:
        return this.gettextCatalog.getString('Wire transfer');
      default:
        return null;
    }
  }

  getIntentionPriority(intention) {
    const { status, priority } = intention;
    if (status === IntentionStatusTypes.COMPLETED) {
      return priority;
    } else {
      return priority === IntentionPriorityTypes.PERSOLVIERT
        ? 'erstintention'
        : 'weitere';
    }
  }

  hasIntentionAccess = () => this.Authorization.hasPackage('intentions');

  canAssignIntentions = () =>
    !_.isEmpty(this.taxonomyIds) && !_.isEmpty(this.intentionFees);

  showIntentionsTable = () => !_.isEmpty(this.eventIntentions);

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => {
    const eventIntentions = getEventIntentions(state);
    const intentionFees = getIntentionFees(state);
    const filteredIntentionFees = getFilteredFees(
      intentionFees,
      this.taxonomyIds
    );

    return {
      eventIntentions: eventIntentions
        ? eventIntentions.asMutable({ deep: true })
        : [],
      intentionFees: filteredIntentionFees,
    };
  };

  mapDispatchToScope = (dispatch) => ({
    fetchEventIntentions: (calendarId) =>
      dispatch(fetchEventIntentions(calendarId)),
    deleteIntention: ({ id, calendarId }) =>
      dispatch(deleteIntention({ id, calendarId })),
    unassignIntention: ({ id, calendarId }) =>
      dispatch(unassignIntention({ id, calendarId })),
  });
}
EventDetailIntentionsComponent.$inject = [
  '$state',
  'gettextCatalog',
  'cdApp',
  'Authorization',
  'Planning',
  '$ngRedux',
  '$scope',
  '$uibModal',
];

angular.module('cdApp.calendar').component('cdEventDetailIntentions', {
  template: require('./event-detail-intentions.component.html'),
  bindings: {
    calendarId: '<',
    taxonomyIds: '<',
  },

  controller: EventDetailIntentionsComponent,
});
