import { get } from 'lodash';
import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { CdApp } from '..';
import createReducer, { CdAction, Immutable } from '../../redux/utils';
import { Me } from '../../shared/models/authentication';
import { Organization } from '../../shared/models/organization';

import {
  MAIN_DATA_REQUEST_SUCCESS,
  MainDataRequestSuccess,
  SET_CONFIG,
} from './Actions';

interface OrganizationData {
  organizationId: number;
  churchId: number;
}

export interface ConfigState {
  config: CdApp;
  organizationData: OrganizationData;
  organization: Organization | null;
  me: Me | null;
}

export type ImmutableConfigState = Immutable<ConfigState>;

const initialState = immutable.from({
  config: window.cdApp,
  organization: get(window, 'churchdeskOrganizationId'),
  me: {},
}) as ImmutableConfigState;

const setConfig: Reducer<ImmutableConfigState, CdAction<CdApp>> = (
  state = initialState,
  { payload }
) => state.merge({ config: payload });

const setAuthData: Reducer<ImmutableConfigState, MainDataRequestSuccess> = (
  state = initialState,
  { payload }
) => state.merge({ me: payload.me, organization: payload.organization });

const handlers = {
  [SET_CONFIG]: setConfig,
  [MAIN_DATA_REQUEST_SUCCESS]: setAuthData,
};

export default createReducer<ImmutableConfigState>(initialState, handlers);
