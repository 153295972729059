'use strict';
PeopleImportBatches.$inject = ['$$resource'];

function PeopleImportBatches($$resource) {
  const baseUrl = `${cdApp.config.api.main}/people/import`;

  return $$resource(`${baseUrl}/:id`, null, {
    getDownloadToken: {
      method: 'GET',
      url: `${baseUrl}/skipped-people/download-token`,
    },
  });
}

angular
  .module('cdApp.shared')
  .factory('PeopleImportBatches', PeopleImportBatches);
