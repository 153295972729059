/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import {
  faBook,
  faMoneyBill,
  faCalendarAlt,
  faExternalLinkAlt,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import {
  Space,
  Card,
  Descriptions,
  DatePicker,
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  Typography,
  Checkbox,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { showModal } from 'redux-saga-modal';

import { Church, FEvent as Event } from '../../../calendar/models/calendar';
import { IntentionFee } from '../../../settings/models/fee';
import { FoundationTypes } from '../../models/foundation';
import { Resource } from '../../../shared/models/resource';
import { getEvent } from '../../../shared/store/Selectors';
import { gettextCatalog } from '../../../services/I18nService';
import { paymentMethods } from '../../shared/intentionCommon';
import IntentionService from '../../services/IntentionService';
import MODAL_TYPES from '../../sagas/modal-types';
import { fetchEvent, clearEvent } from '../../../shared/store/Actions';
import { getIntentionFees } from '../../../settings/redux/intention-fees/Selectors';
import { getFilteredFees } from '../../../settings/redux/intention-fees/data.converter';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import {
  PaymentMethodTypes,
  IntentionStatusTypes,
} from '../../models/intention';
import {
  getIntention,
  getIntentionParent,
} from '../../redux/intentions/Selectors';
import { getChurches, getResources } from '../../../shared/store/resources';
import StateServiceFactory, {
  RootScopeServiceFactory,
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import {
  clearIntention,
  createIntention,
  fetchIntention,
  updateIntention,
  fetchIntentionParent,
  clearIntentionParent,
} from '../../redux/intentions/Actions';
import CopyIntentionModal from '../../components/intentions/CopyIntentionModal';

// Antd
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

interface CreateIntentionStateParam {
  currentState: string;
  id: string;
  calendarId: number;
  stiftungId: string;
  legatId: string;
}
const CreateIntention: FunctionComponent = () => {
  const dispatch = useDispatch();

  const rootScope = RootScopeServiceFactory();
  const stateService = StateServiceFactory();
  const stateParams = StateParamsServiceFactory<CreateIntentionStateParam>();
  const currentState = stateParams.currentState;
  const intentionId = stateParams.id;
  const calendarId = stateParams.calendarId;
  const stiftungId = stateParams.stiftungId;
  const legatId = stateParams.legatId;
  const [form] = Form.useForm();

  const datePickerFormat = gettextCatalog.getLongDateFormat();

  // Define labels
  const submitButtonLabel =
    currentState === 'create'
      ? gettextCatalog.getString('Save')
      : gettextCatalog.getString('Update');

  // Retrieve required entities from Redux store
  /**
   * TODO:
   * - Consider passing on to this view, the intention to be viewed/updated, since all of its required data
   * has already been fetched when listing intentions. In this way, an unnecessary asynchronous call to the
   * backend can be avoided, the first "useEffect" block can be avoided, and the logic of the "useEffect" block
   * down below (the one listening on changes of the intention) can be simplified.
   * - Consider ALSO passing on the calendar item (event) if creating an intention from the calendar view.
   */
  const _intention = useSelector(getIntention);
  const intention = _intention && _intention.asMutable({ deep: true });
  const event: Partial<Event> = useSelector(getEvent, shallowEqual);

  const resources = useSelector(getResources);
  const churches = useSelector(getChurches);
  const allIntentionFees = useSelector(getIntentionFees);
  const taxonomyIds = _.map(_.keys(_.get(event, 'taxonomies')), _.parseInt);
  const intentionFees: IntentionFee[] = getFilteredFees(
    allIntentionFees,
    taxonomyIds
  );

  const intentionParent = useSelector(getIntentionParent);

  // Define initialization hooks and entity reload hooks
  const [filteredChurches, setFilteredChurches] = useState<Church[]>([]);
  const [churchResources, setChurchResources] = useState<Resource[]>([]);

  // Retrieve required entities
  useEffect(() => {
    // Initialize intention data in case of creation or update/view
    if (currentState === 'create') {
      dispatch(clearIntention());
      dispatch(clearIntentionParent());
    } else {
      dispatch(fetchIntention(intentionId));
    }
    // If creating intention for an event
    if (calendarId) {
      dispatch(fetchEvent(calendarId));
    } else {
      dispatch(clearEvent());
    }
    // Cleanup function to be executed on component un-mounting
    return () => {
      dispatch(clearEvent());
      dispatch(clearIntention());
      dispatch(clearIntentionParent());
    };
  }, []);

  // Update the form with the retrieved intention (update/view)
  useEffect(() => {
    if (currentState !== 'create' && intention) {
      loadChurchDependentEntities(intention.churchId);

      form.setFieldsValue({
        nextReferenceNumber: _.get(intention, 'formattedReferenceNumber'),
        preferredDate: intention.preferredDate
          ? moment(intention.preferredDate, 'YYYY-MM-DD')
          : null,
        preferredNote: intention.preferredNote,
        intentionText: intention.intentionText,
        comment: intention.comment,
        founder: intention.founder,
        church: intention.churchId,
        resource: intention.resourceId,
        fee: intention.feeId,
        paymentMethod: intention.paymentMethod,
        paid: intention.paid,
        paidAt: intention.paidAt ? moment(intention.paidAt) : null,
        intentionYear: intention.intentionYear
          ? moment().year(intention.intentionYear)
          : null,
        acceptedAt: intention.acceptedAt ? moment(intention.acceptedAt) : null,
      });

      // Load parent details
      if (intention.parentId) {
        dispatch(fetchIntentionParent(intention.parentId));
      } else {
        dispatch(clearIntentionParent());
      }
    } else {
      form.setFieldsValue({
        nextReferenceNumber: null,
        founder: null,
        church: null,
        resource: null,
        paymentMethod: PaymentMethodTypes.PAIDBYCASH,
        paid: true,
        paidAt: moment(),
        intentionYear: moment(),
        acceptedAt: moment(),
      });
    }
  }, [intention ? intention.id : null]);

  useEffect(() => {
    if (!_.isEmpty(resources)) {
      if (currentState !== 'create' && intention) {
        loadChurchDependentEntities(intention.churchId);
      }
    }
  }, [_.sortBy(_.map(resources, 'id')).join('-')]);

  // If an event is specified when creating an intention, preload some form fields based on the event properties
  useEffect(() => {
    if (currentState === 'create' && event) {
      // Set church based on event
      const eventChurches = _.get(event, 'churches');
      if (!_.isEmpty(eventChurches)) {
        setFilteredChurches(eventChurches);
        const churchId = _.get(_.first(eventChurches), 'id');
        form.setFieldsValue({
          church: churchId,
        });
        loadChurchDependentEntities(churchId);
        // Ser resource based on event (if it belongs to the church)
        const eventResourceIds = _.keys(_.get(event, 'resources'));
        if (!_.isEmpty(eventResourceIds)) {
          const eventResources = _.map(eventResourceIds, (eventResourceId) =>
            _.extend(
              { id: _.parseInt(eventResourceId) },
              _.get(event, `resources[${eventResourceId}]`)
            )
          );
          const churchResources = _.filter(eventResources, [
            'parentResourceId',
            churchId,
          ]);
          setChurchResources(churchResources);
          const resourceId = _.get(_.first(churchResources), 'id');
          form.setFieldsValue({
            resource: resourceId,
          });
        }
        // Reload the reference number
        getNextReferenceNumber();
      }
    }
  }, [event ? event.id : null]);

  useEffect(() => {
    if ((currentState !== 'create' || !event) && !_.isEmpty(churches)) {
      setFilteredChurches(churches.asMutable({ deep: true }));
    }
  }, [_.sortBy(_.map(churches, 'id')).join('-')]);

  // When creating an intention, auto-select the default fee, and if none, use the first fee in the list
  useEffect(() => {
    if (currentState === 'create') {
      const defaultFee = _.find(
        intentionFees,
        (intentionFee) => intentionFee.isDefault
      );
      form.setFieldsValue({
        fee: _.get(defaultFee || _.first(intentionFees), 'id', null),
      });
    }
  }, [_.sortBy(_.map(intentionFees, 'id')).join('-')]);

  // Form functions
  const handleChurchUpdate = (churchId: number) => {
    // Clear form values that depend on the value of the previously selected church ID
    form.setFieldsValue({
      resource: null,
    });

    if (churchId) {
      // Re-load resources
      loadChurchDependentEntities(churchId);
      // Reload the reference number
      getNextReferenceNumber();
    }
  };

  const loadChurchDependentEntities = (churchId: number) => {
    // Load church's resources
    const church = _.find(resources, ['id', churchId]);
    const churchResources = _.get(church, 'resources', []);
    const orderedChurchResources = _.orderBy(churchResources, 'name');
    setChurchResources(orderedChurchResources);
  };

  const isIntentionFromFoundation = useMemo(
    () => !_.isNil(_.get(intention, 'parentId')),
    [intention]
  );

  const getNextReferenceNumber = () => {
    const churchId: number = form.getFieldValue('church');
    const intentionYear: number = moment(
      form.getFieldValue('intentionYear')
    ).year();
    if (!_.isFinite(churchId) || !_.isFinite(intentionYear)) return;
    IntentionService.getIntentionsNextReferenceNumber(churchId, intentionYear)
      .then((value) => {
        form.setFieldsValue({ nextReferenceNumber: value });
      })
      .catch(ErrorHandlingService.handleError);
  };

  const save = (formValues) => {
    const oldChurchId = _.get(intention, 'churchId');
    const oldResourceId = _.get(intention, 'resourceId');
    const oldFeeId = _.get(intention, 'feeId');
    const associations = {
      church: formValues.church
        ? { id: formValues.church }
        : oldChurchId
          ? null
          : undefined,
      resource: formValues.resource
        ? { id: formValues.resource }
        : oldResourceId
          ? null
          : undefined,
      fee: formValues.fee
        ? { id: formValues.fee }
        : oldFeeId
          ? null
          : undefined,
    };

    // Format form inputs
    formValues.intentionYear = formValues.intentionYear
      ? formValues.intentionYear.year()
      : formValues.intentionYear;
    formValues.preferredDate = formValues.preferredDate
      ? formValues.preferredDate.format('YYYY-MM-DD')
      : formValues.preferredDate;
    formValues.preferredNote = _.isEmpty(formValues.preferredNote)
      ? null
      : formValues.preferredNote;
    formValues.intentionText = _.isEmpty(formValues.intentionText)
      ? null
      : formValues.intentionText;
    formValues.internalNote = _.isEmpty(formValues.internalNote)
      ? null
      : formValues.internalNote;

    // Since payment info is disabled in the form when it is a foundation, we must add the info manually
    let foundationPaymentInfo;
    if (isIntentionFromFoundation) {
      foundationPaymentInfo = {
        paidAt: intention.paidAt,
        paymentMethod: intention.paymentMethod,
        paid: intention.paid,
      };
    }

    const payload = _.extend(formValues, associations, foundationPaymentInfo);

    if (currentState === 'create') {
      dispatch(createIntention({ calendarId, intention: payload }));
    } else if (stiftungId) {
      dispatch(
        updateIntention({
          id: intentionId,
          updatePayload: payload,
          calendarId,
          stiftungId,
        })
      );
    } else if (legatId) {
      dispatch(
        updateIntention({
          id: intentionId,
          updatePayload: payload,
          calendarId,
          legatId,
        })
      );
    } else {
      dispatch(
        updateIntention({ id: intentionId, updatePayload: payload, calendarId })
      );
    }
  };

  const back = () => {
    if (calendarId) {
      stateService.go('app.private.calendar.event', { id: calendarId });
    } else {
      // If previous tab has id in the url it is from an event, so go back there
      const previousStateParamsId = _.get(rootScope, 'previousStateParams.id');
      if (previousStateParamsId) {
        // Go back to event
        stateService.go(_.get(rootScope, 'previousState.name'), {
          id: previousStateParamsId,
        });
      } else {
        // If not from event, go back to the intentions screen with intention on it
        stateService.go('app.private.intention.overview');
      }
    }
  };

  const goToEvent = (calendarId) => {
    const url = stateService.href(`app.private.calendar.event`, {
      id: calendarId,
    });
    window.open(url, '_blank');
  };

  const goToFoundation = (foundationId, foundationType) => {
    let url;
    switch (foundationType) {
      case FoundationTypes.LEGATE:
        url = stateService.href(`app.private.intention.legate.edit`, {
          id: foundationId,
          type: 'edit',
        });
        window.open(url, '_blank');
        break;
      case FoundationTypes.STIFTUNGEN:
        url = stateService.href(`app.private.intention.stiftungen.edit`, {
          id: foundationId,
          type: 'edit',
        });
        window.open(url, '_blank');
        break;
    }
  };

  const hasAdditionalInfo = () =>
    (intention && intention.event) || intentionParent || event;

  const copyIntention = () => {
    dispatch(showModal(MODAL_TYPES.COPY_INTENTION, { intention }));
  };

  // Get page title depending on current state
  let pageTitle;
  switch (currentState) {
    case 'edit':
      pageTitle = gettextCatalog.getString('Edit intention');
      break;
    case 'create':
      if (event) {
        // Create with event
        pageTitle = gettextCatalog.getString(
          'Create Intention for event: {{event}}',
          {
            event: event.title,
          }
        );
      } else {
        // Create with no event
        pageTitle = gettextCatalog.getString('Create Intention');
      }
      break;
    default:
      // Create
      pageTitle = gettextCatalog.getString('Create Intention');
  }

  // Return the create intention form
  return (
    <React.Fragment>
      <div className="app-main">
        <PageHeader
          style={{ padding: '0px 0px 24px 0px' }}
          className="site-page-header-responsive"
          title={pageTitle}
          extra={
            <Space>
              <Button
                form="createIntentionForm"
                type="primary"
                htmlType="submit"
              >
                {submitButtonLabel}
              </Button>
              {intention && !intention.parentId && (
                <Button onClick={copyIntention}>
                  <FontAwesomeIcon icon={faCopy} style={{ marginRight: 8 }} />
                  {gettextCatalog.getString('Copy')}
                </Button>
              )}
              <Button onClick={back}>{gettextCatalog.getString('Back')}</Button>
            </Space>
          }
        />
        <Form
          id="createIntentionForm"
          onFinish={save}
          form={form}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 8 }}
          wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 14 }}
          size="middle"
        >
          <Row gutter={16}>
            {/* Start of left column */}
            <Col xs={24} lg={10}>
              <Card bordered style={{ marginBottom: 16 }}>
                {/* Church - Required */}
                <Form.Item
                  name="church"
                  label={gettextCatalog.getString('Parish')}
                  required={true}
                >
                  <Select
                    onChange={handleChurchUpdate}
                    allowClear
                    disabled={currentState !== 'create'}
                    placeholder={gettextCatalog.getString('Select a church')}
                  >
                    {filteredChurches.map((church) => (
                      <Option key={church.id} value={church.id}>
                        {_.get(church, 'name')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Resources */}
                <Form.Item
                  name="resource"
                  label={gettextCatalog.getString('Resource')}
                >
                  <Select
                    placeholder={gettextCatalog.getString('Select a resource')}
                    disabled={
                      _.isEmpty(churchResources) ||
                      (intention &&
                        _.get(intention, 'status') !==
                          IntentionStatusTypes.UNASSIGNED)
                    }
                    allowClear
                  >
                    {churchResources.map((resource) => (
                      <Option key={resource.id} value={resource.id}>
                        {_.get(resource, 'name')}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Preferred Date */}
                <Form.Item
                  name="preferredDate"
                  label={gettextCatalog.getString('Desired date')}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format={datePickerFormat}
                  />
                </Form.Item>

                {/* Preferred Date Note */}
                <Form.Item
                  name="preferredNote"
                  label={gettextCatalog.getString('Notes for desired date')}
                >
                  <TextArea
                    rows={3}
                    placeholder={gettextCatalog.getString('Desired date notes')}
                  />
                </Form.Item>

                {/* Intention text - required */}
                <Form.Item
                  name="intentionText"
                  label={gettextCatalog.getString('Intention text')}
                  required={true}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={4}
                    placeholder={gettextCatalog.getString('Intention text')}
                  />
                </Form.Item>
              </Card>
            </Col>

            {/* Start of right column */}
            <Col xs={24} lg={14}>
              <Card bordered style={{ marginBottom: 16 }}>
                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Intention year - Required */}
                    <Form.Item
                      name="intentionYear"
                      label={gettextCatalog.getString('Intentions year')}
                      labelCol={{ span: 12 }}
                      required={true}
                    >
                      <DatePicker
                        picker="year"
                        placeholder={gettextCatalog.getString(
                          'Intentions year'
                        )}
                        onChange={getNextReferenceNumber}
                        disabled={currentState !== 'create' || !_.isNil(event)}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Next reference number */}
                    <Form.Item
                      name="nextReferenceNumber"
                      labelCol={{ span: 8 }}
                      label={gettextCatalog.getString('Number')}
                    >
                      <Input
                        style={{ width: '100%' }}
                        disabled={isIntentionFromFoundation}
                        placeholder={gettextCatalog.getString('Number')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" gutter={8} align="bottom">
                  <Col span={11}>
                    {/* Acceptance date */}
                    <Form.Item
                      name="acceptedAt"
                      label={gettextCatalog.getString('Acceptance date')}
                      labelCol={{ span: 12 }}
                      required={true}
                      style={{
                        marginBottom: isIntentionFromFoundation ? 0 : 16,
                      }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={datePickerFormat}
                        placeholder={gettextCatalog.getString(
                          'Acceptance date'
                        )}
                        disabled={isIntentionFromFoundation}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={13}>
                    {/* Fee - Required */}
                    <Form.Item
                      name="fee"
                      label={gettextCatalog.getString('Intention type')}
                      labelCol={{ span: 8 }}
                      required={true}
                      style={{
                        marginBottom: isIntentionFromFoundation ? 0 : 16,
                      }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        placeholder={gettextCatalog.getString('Select a type')}
                        disabled={
                          _.isEmpty(intentionFees) || isIntentionFromFoundation
                        }
                        allowClear
                      >
                        {intentionFees.map((intentionFee) => (
                          <Option key={intentionFee.id} value={intentionFee.id}>
                            {_.get(intentionFee, 'name')}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {isIntentionFromFoundation ? null : (
                  <Row justify="space-between" gutter={8} align="bottom">
                    <Col span={11}>
                      {/* Payment method */}
                      <Form.Item
                        name="paymentMethod"
                        labelCol={{ span: 12 }}
                        label={gettextCatalog.getString('Payment method')}
                        style={{ marginBottom: 0 }}
                      >
                        <Select
                          placeholder={gettextCatalog.getString(
                            'Select a payment method'
                          )}
                          style={{ width: '100%' }}
                          disabled={_.isEmpty(paymentMethods())}
                          allowClear
                        >
                          {paymentMethods().map((paymentMethod) => (
                            <Option
                              key={paymentMethod.id}
                              value={paymentMethod.id}
                            >
                              {_.get(paymentMethod, 'name')}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={13}>
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{ marginBottom: 0 }}
                      >
                        <Col span={8} style={{ textAlign: 'end' }}>
                          <label style={{ marginRight: 8 }}>
                            {gettextCatalog.getString('Payment date')}:
                          </label>
                        </Col>

                        <Col span={16}>
                          <Space direction="horizontal">
                            {/* Payment date */}
                            <Form.Item
                              name="paidAt"
                              style={{ marginBottom: 0 }}
                            >
                              <DatePicker
                                format={datePickerFormat}
                                placeholder={gettextCatalog.getString(
                                  'Payment date'
                                )}
                              />
                            </Form.Item>

                            {/* Payment check */}
                            <Form.Item
                              name="paid"
                              valuePropName="checked"
                              style={{ marginBottom: 0 }}
                            >
                              <Checkbox>
                                {gettextCatalog.getString('Paid?')}
                              </Checkbox>
                            </Form.Item>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Card>

              <Card bordered style={{ marginBottom: 16 }}>
                {/* Founder */}
                <Form.Item
                  name="founder"
                  label={gettextCatalog.getString('Founder')}
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={gettextCatalog.getString('Founder')}
                  />
                </Form.Item>

                {/* Internal Note */}
                <Form.Item
                  name="comment"
                  label={gettextCatalog.getString('Note')}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea
                    rows={3}
                    placeholder={gettextCatalog.getString(
                      'Additional information'
                    )}
                  />
                </Form.Item>
              </Card>
            </Col>
            {hasAdditionalInfo() ? (
              <Col xs={24} lg={10}>
                <Card bordered>
                  {intention && intention.event ? (
                    <Descriptions
                      title={
                        <Space>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          <span>{`${gettextCatalog.getString(
                            'Assigned Event:'
                          )} ${intention.event.title}`}</span>
                        </Space>
                      }
                      size="small"
                      column={1}
                      style={{ marginBottom: 16 }}
                    >
                      <Descriptions.Item
                        label={gettextCatalog.getString('Title')}
                      >
                        <Space>
                          <a
                            target="_blank"
                            onClick={() => goToEvent(intention.event.id)}
                          >
                            <span>{intention.event.title}</span>
                          </a>
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Space>
                      </Descriptions.Item>
                      {/* All day date */}
                      {intention.event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Date')}
                        >
                          {moment(intention.event.startDate).format('ddd, ll') +
                            ' ' +
                            gettextCatalog.getString('(all day)')}
                        </Descriptions.Item>
                      )}
                      {/* Start date */}
                      {!intention.event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Start Date')}
                        >
                          {moment(intention.event.startDate).format(
                            'ddd, ll (HH:mm)'
                          )}
                        </Descriptions.Item>
                      )}
                      {/* End date */}
                      {!intention.event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('End Date')}
                        >
                          {moment(intention.event.endDate).format(
                            'ddd, ll (HH:mm)'
                          )}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  ) : null}
                  {event ? (
                    <Descriptions
                      title={
                        <Space>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          <span>{`${gettextCatalog.getString('Event:')} ${
                            event.title
                          }`}</span>
                        </Space>
                      }
                      size="small"
                      column={1}
                      style={{ marginBottom: 16 }}
                    >
                      <Descriptions.Item
                        label={gettextCatalog.getString('Title')}
                      >
                        <Space>
                          <a
                            target="_blank"
                            onClick={() => goToEvent(event.id)}
                          >
                            <span>{event.title}</span>
                          </a>
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Space>
                      </Descriptions.Item>
                      {/* All day date */}
                      {event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Date')}
                        >
                          {moment(event.startDate).format('ddd, ll') +
                            ' ' +
                            gettextCatalog.getString('(all day)')}
                        </Descriptions.Item>
                      )}
                      {/* Start date */}
                      {!event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('Start Date')}
                        >
                          {moment(event.startDate).format('ddd, ll (HH:mm)')}
                        </Descriptions.Item>
                      )}
                      {/* End date */}
                      {!event.allDay && (
                        <Descriptions.Item
                          label={gettextCatalog.getString('End Date')}
                        >
                          {moment(event.endDate).format('ddd, ll (HH:mm)')}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  ) : null}
                  {_.get(intentionParent, 'type') ===
                  FoundationTypes.STIFTUNGEN ? (
                    <>
                      <Title level={3}>
                        <Space>
                          <FontAwesomeIcon icon={faBook} />
                          <span>
                            {gettextCatalog.getString(
                              'In stipend: {{number}}',
                              {
                                number:
                                  intentionParent.formattedReferenceNumber,
                              }
                            )}
                          </span>
                        </Space>
                      </Title>
                      <Button
                        target="_blank"
                        onClick={() =>
                          goToFoundation(
                            intentionParent.id,
                            FoundationTypes.STIFTUNGEN
                          )
                        }
                      >
                        <span>
                          {gettextCatalog.getString('Go to stipend')}{' '}
                          <FontAwesomeIcon
                            style={{ marginLeft: 5 }}
                            icon={faExternalLinkAlt}
                          />
                        </span>
                      </Button>
                    </>
                  ) : null}
                  {_.get(intentionParent, 'type') === FoundationTypes.LEGATE ? (
                    <>
                      <Title level={3}>
                        <Space>
                          <FontAwesomeIcon icon={faMoneyBill} />
                          <span>
                            {gettextCatalog.getString(
                              'In Legate: ' +
                                intentionParent.formattedReferenceNumber
                            )}
                          </span>
                        </Space>
                      </Title>
                      <Button
                        target="_blank"
                        onClick={() =>
                          goToFoundation(
                            intentionParent.id,
                            FoundationTypes.LEGATE
                          )
                        }
                      >
                        <span>
                          {gettextCatalog.getString('Go to Legate')}{' '}
                          <FontAwesomeIcon
                            style={{ marginLeft: 5 }}
                            icon={faExternalLinkAlt}
                          />
                        </span>
                      </Button>
                    </>
                  ) : null}
                </Card>
              </Col>
            ) : null}
            {/* Entity metadata */}
            {_.get(intention, 'createdBy') ||
            _.get(intention, 'createdAt') ||
            _.get(intention, 'assignedBy') ||
            _.get(intention, 'assignedAt') ||
            _.get(intention, 'updatedBy') ||
            _.get(intention, 'updatedAt') ||
            _.get(intention, 'deletedAt') ? (
              <Col xs={24} lg={hasAdditionalInfo() ? 14 : 24}>
                <Card bordered style={{ marginBottom: 16 }}>
                  <Descriptions
                    title={gettextCatalog.getString('Activity')}
                    column={1}
                    size="small"
                  >
                    {_.get(intention, 'createdBy') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Created by')}
                      >
                        {_.get(intention, 'createdBy')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'createdAt') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Created on')}
                      >
                        {moment(_.get(intention, 'createdAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'assignedBy') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Assigned by')}
                      >
                        {_.get(intention, 'assignedBy')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'assignedAt') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Assigned on')}
                      >
                        {moment(_.get(intention, 'assignedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'updatedBy') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Updated by')}
                      >
                        {_.get(intention, 'updatedBy')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'updatedAt') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Updated on')}
                      >
                        {moment(_.get(intention, 'updatedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                    {_.get(intention, 'deletedAt') ? (
                      <Descriptions.Item
                        label={gettextCatalog.getString('Deleted on')}
                      >
                        {moment(_.get(intention, 'deletedAt')).format('lll')}
                      </Descriptions.Item>
                    ) : null}
                  </Descriptions>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Form>
        <CopyIntentionModal />
      </div>
    </React.Fragment>
  );
};

export default CreateIntention;
