import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';

import {
  FETCH_USER_ROLES,
  SAVE_USER_ROLES,
  FETCH_USERS,
  SAVE_USER_ROLES_SUCCESS,
  FETCH_USER_PERMISSION,
  FETCH_USER_PRIVILEGES,
} from '../redux/actions';
import { withLoadingAndErrors } from '../../shared/loading/saga';

import {
  getUserRoles,
  saveUserRoles,
  showUserRoleSavedSuccess,
} from './UserRolesSagas';
import { getUsers, getUserPermission, getUserPrivilege } from './UsersSagas';

// Watcher
export default function* root(): SagaIterator {
  yield all([
    takeLatest(
      FETCH_USER_ROLES,
      withLoadingAndErrors(FETCH_USER_ROLES, getUserRoles)
    ),
    takeLatest(
      SAVE_USER_ROLES,
      withLoadingAndErrors(SAVE_USER_ROLES, saveUserRoles)
    ),
    takeLatest(
      SAVE_USER_ROLES_SUCCESS,
      withLoadingAndErrors(SAVE_USER_ROLES_SUCCESS, showUserRoleSavedSuccess)
    ),
    takeLatest(FETCH_USERS, withLoadingAndErrors(FETCH_USERS, getUsers)),
    takeEvery(
      FETCH_USER_PERMISSION,
      withLoadingAndErrors(FETCH_USER_PERMISSION, getUserPermission)
    ),
    takeEvery(
      FETCH_USER_PRIVILEGES,
      withLoadingAndErrors(FETCH_USER_PRIVILEGES, getUserPrivilege)
    ),
  ]);
}
