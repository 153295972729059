(function () {
  'use strict';
  Partnership.$inject = ['$$resource'];

  function Partnership($$resource) {
    return {
      categories: $$resource(
        cdApp.config.api.partners + '/partners/categories'
      ),

      map: $$resource(cdApp.config.api.partners + '/categories/:categoryId', {
        categoryId: '@categoryId',
      }),
    };
  }

  angular.module('cdApp.shared').factory('Partnership', Partnership);
})();
