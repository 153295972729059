import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilState,
} from 'recoil';

import { WebsiteListQuery } from '../store/widgetService';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import { AddDomainSuccess } from '@/react/homepage/types/domain.type';
import {
  CheckStatusLoadingState,
  DomainListQuery,
} from '@/react/homepage/store/domains';

export const useDomains = (siteId: string) => {
  const refreshDomains = useRecoilRefresher(DomainListQuery(siteId));
  const refreshSites = useRecoilRefresher(WebsiteListQuery);
  const listDomains = useRecoilValue(DomainListQuery(siteId));
  const [checkStatusLoading, setCheckStatusLoading] = useRecoilState(
    CheckStatusLoadingState
  );

  const addDomain = useRecoilCallback(
    () => async (domain: string) => {
      const response = await mainApi.post<AddDomainSuccess>(
        `/sites/${siteId}/domain`,
        { domain }
      );

      if (!response.ok) {
        handleError(response);
        throw response;
      }
      refreshDomains();
      refreshSites();
      return response.data;
    },
    [refreshDomains, refreshSites, siteId]
  );

  const removeDomain = useRecoilCallback(
    () => async (domainId: string) => {
      const response = await mainApi.delete<AddDomainSuccess>(
        `/sites/${siteId}/domain/${domainId}/remove`
      );

      if (!response.ok) {
        handleError(response);
        throw response;
      }
      refreshDomains();
      refreshSites();
      return response.data;
    },
    [refreshDomains, refreshSites, siteId]
  );

  const makeDomainDefault = useRecoilCallback(
    () => async (domainId: string) => {
      const response = await mainApi.post<AddDomainSuccess>(
        `/sites/${siteId}/domain/${domainId}/default`
      );

      if (!response.ok) {
        handleError(response);
        throw response;
      }
      refreshDomains();
      return response.data;
    },
    [refreshDomains, siteId]
  );

  const checkStatus = useRecoilCallback(
    () => async (domainId: string) => {
      setCheckStatusLoading(true);
      const response = await mainApi.get(
        `/sites/${siteId}/domain/${domainId}/status`
      );

      if (!response.ok) {
        setCheckStatusLoading(false);
        handleError(response);
        throw response;
      }
      refreshDomains();
      setCheckStatusLoading(false);
      return response.data;
    },
    [refreshDomains, setCheckStatusLoading, siteId]
  );

  return {
    addDomain,
    listDomains,
    makeDomainDefault,
    removeDomain,
    checkStatus,
    checkStatusLoading,
  };
};
