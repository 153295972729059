import { Button, Card, Col, Row, Space, Typography } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdNewWebsite } from '@/react/shared/components/Icons';
import { getString } from '@/react/services/GetStringX';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';

export const WebsiteCardCreate = (props: { onCap: boolean }) => {
  const isMultiParish = useRecoilValue(HasMultipleChurches);
  const createNewWebsite = () => {
    if (props.onCap) {
      showWarningModal();
    } else {
      NiceModal.show('CreateWebsiteDrawer');
    }
  };

  const showWarningModal = () =>
    showConfirmInputModal({
      title: gettextCatalog.getString(
        'Important information: 60 day website trial'
      ),
      message: (
        <Space direction="vertical" size="large">
          {getString('Your website is created as a free 60 day trial.')}
          {isMultiParish
            ? getString(
                '<b>What happens after the 60 day trial?</b><br />After the 60 day trial period the website is locked for 20 days and can be unlocked by purchasing an extra website (price / month) or deleting an existing website. You will not automatically be billed for this website.'
              )
            : getString(
                '<b>What happens after the 60 day trial?</b><br />After the 60 day trial period the website is locked for 20 days and can be unlocked by deleting an existing website or upgrade your ChurchDesk with both the Multi-parish add-on and an extra website add-on. You will not automatically be billed for this website.'
              )}
          {getString(
            '<b>How will the site be deleted?</b><br />The trial website will be unpublished after the 60 day trial period and will automatically be deleted after the additional 20 day lock period. You can also delete the trial site yourself.'
          )}
        </Space>
      ),
      okText: gettextCatalog.getString('Ok'),
      onOk: () => {
        // Don't return anything here, we want to close the modal.
        NiceModal.show('CreateWebsiteDrawer');
      },
      confirmType: 'checkbox',
      confirmLabel: gettextCatalog.getString('I agree to the above terms.'),
    });

  return (
    <Card
      hoverable
      style={{ width: 270 }}
      bodyStyle={{ padding: '0px' }}
      cover={
        <div
          onClick={() => createNewWebsite()}
          style={{
            cursor: 'pointer',
            width: 270,
            height: 216,
            background: '#008fb4',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
              }}
            >
              <CdNewWebsite
                size="3x"
                style={{ color: 'white', height: '80px' }}
              />
            </Col>
            <Col span={24}>
              <Row align="middle" justify="center">
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Typography.Title
                    level={5}
                    style={{ marginTop: '0px', color: 'white' }}
                  >
                    {gettextCatalog.getString('Create a website')}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      actions={[
        <Button type="text" onClick={() => createNewWebsite()}>
          {gettextCatalog.getString('Create a new website')}
        </Button>,
      ]}
    />
  );
};
