import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import createReducer from '../utils';

import { INITIAL_STATE_LOADED, InitialStateLoaded } from './Actions';

export interface StartupState {
  initialStateLoaded: boolean;
}

export type ImmutableStartupState = immutable.Immutable<StartupState>;

const initialState: ImmutableStartupState = immutable.from({
  initialStateLoaded: false,
});

const initialStateLoaded: Reducer<ImmutableStartupState, InitialStateLoaded> = (
  state
) => state.merge({ initialStateLoaded: true });

const handlers = {
  [INITIAL_STATE_LOADED]: initialStateLoaded,
};

export default createReducer<ImmutableStartupState>(initialState, handlers);
