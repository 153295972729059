(function () {
  'use strict';
  SognDk.$inject = ['$$resource'];

  function SognDk($$resource) {
    return $$resource(cdApp.config.api.main + '/sogndk/:type');
  }

  angular.module('cdApp.shared').factory('SognDk', SognDk);
})();
