import _ from 'lodash';
class RotasLinksState {
  constructor(
    $state,
    $uibModal,
    toastr,
    gettextCatalog,
    RotasLinks,
    Taxonomies,
    Resources,
    Groups,
    appUtils,
    cdApp,
    Authorization
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.RotasLinks = RotasLinks;
    this.Taxonomies = Taxonomies;
    this.Resources = Resources;
    this.Groups = Groups;
    this.appUtils = appUtils;
    this.rotaLink = `${cdApp.references.appUrl}/rota/`;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
  }

  $onInit() {
    const { Groups } = this;
    this.orderBy = 'name';
    this.desc = false;
    this.hasCalendarAccess =
      this.Authorization.hasPermission('canAccessCalendar');

    if (this.hasCalendarAccess) {
      Groups.query().$promise.then((groups) => {
        this.groups = groups;
        this.hasRotaGroups = _.some(
          groups,
          (group) => !_.isEmpty(_.get(group, 'tasks'))
        );
      });
      this.loadRotas();
    }
  }

  loadRotas() {
    const { orderBy, desc } = this;
    const { toastr, RotasLinks, gettextCatalog, appUtils } = this;
    this.isLoading = true;
    this.orderBy = orderBy;
    RotasLinks.getPaginatedLinks({ orderBy, desc })
      .$promise.then(({ items }) => {
        this.rotaLinks = _.map(items, (item) => {
          const { settings } = item;
          const formatBoolean = (boolean) =>
            boolean
              ? gettextCatalog.getString('Yes')
              : gettextCatalog.getString('No');
          settings.useInitialsText = formatBoolean(settings.useInitials);
          settings.includeNotesText = formatBoolean(settings.includeNotes);
          return item;
        });
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteLink(link) {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Delete rota scheme'),
          body: () =>
            this.gettextCatalog.getString(
              'Do you want to delete the rota scheme?'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Delete'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.toastr.success(
          this.gettextCatalog.getString('Rota scheme deleted successfully'),
          this.gettextCatalog.getString('Deleted'),
          {
            timeOut: 10000,
          }
        );

        this.RotasLinks.deleteLink(link).$promise.then(() => {
          this.loadRotas();
        });
      });
  }

  editSettings(link) {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdCreatePublicRotaModal',
        resolve: {
          id: () => link.id,
          name: () => link.name,
          hideNotes: () => !link.settings.includeNotes,
          useInitials: () => link.settings.useInitials,
          resources: () => link.settings.resourceIds,
          tasks: () => link.settings.taskIds,
          categories: () => link.settings.taxonomyIds,
          displayTaskIds: () => link.settings.displayTaskIds,
        },
      })
      .result.then(() => {
        this.loadRotas();
      });
  }

  edit(link) {
    const { $state } = this;
    $state.go('app.private.calendar.rotas.view', {
      rotaLinkId: link.id,
      rotaLink: link,
    });
  }

  copyLink(link) {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdCreatePublicRotaModal',
        resolve: {
          id: () => link.id,
          createdRotaUrl: () => `${cdApp.references.appUrl}/rota/${link.id}`,
        },
      })
      .result.then(() => {});
  }

  createScheme() {
    const { $state } = this;
    $state.go('app.private.calendar.rotas');
  }

  createRotaGroup() {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdCreateGroupModal',
        resolve: {},
      })
      .result.then(() => {
        this.hasRotaGroups = true;

        this.toastr.success(
          this.gettextCatalog.getString('Rota group created.')
        );
      });
  }

  // Empty state elements
  goToMeetingLink() {
    window.open(
      'https://explore.churchdesk.com/meetings/mha2/produktdemonstrationen',
      '_blank'
    );
  }

  imageUrl(image) {
    const { cdApp } = this;
    const language = _.get(cdApp, 'organization.locale.language', false);
    if (language) {
      return `/img/${language}/${image}`;
    }
    return `/img/en-gb/${image}`;
  }

  /**
   * Get the icon associated with the current ordering
   *
   * @param {String} field The field to get the icon for
   */
  getOrderIcon(field) {
    if (this.orderBy === field) {
      return this.desc ? 'fa fa-sort-up fa-fw' : 'fa fa-sort-down fa-fw';
    }
  }

  changeOrder(field) {
    if (this.orderBy === field) {
      this.desc = !this.desc;
    } else {
      this.orderBy = field;
      this.desc = false;
    }
    this.loadRotas();
  }
}

RotasLinksState.$inject = [
  '$state',
  '$uibModal',
  'toastr',
  'gettextCatalog',
  'RotasLinks',
  'Taxonomies',
  'Resources',
  'Groups',
  'appUtils',
  'cdApp',
  'Authorization',
];

angular.module('cdApp.calendar').component('cdRotasLinksState', {
  template: require('./rotas-scheme.component.html'),
  controller: RotasLinksState,
  bindings: {
    $transition$: '<',
  },
});
