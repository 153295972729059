import {
  faPencilAlt,
  faRss,
  faTimes,
  faSyncAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useCallback, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux-saga-modal';

import { gettextCatalog } from '../../services/I18nService';
import { ToggleCalendarSubscriptionAction } from '../store/filters/Actions';
import { getCalendarSubscriptionSavedFilters } from '../store/filters/Selectors';
import {
  DeleteCalendarSubscriptionAction,
  ForceSyncCalendarSubscriptionAction,
} from '../store/main-view/Actions';
import { getCalendarSubscriptions } from '../store/main-view/Selectors';
import MODAL_TYPES from '../sagas/ModalTypes';

import { FilterListItem } from './FilterItem';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const CalendarSubscriptions: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();

  const calendarSubscriptions = useSelector(
    getCalendarSubscriptions,
    _.isEqual
  );
  const calendarSubscriptionsFilters = useSelector(
    getCalendarSubscriptionSavedFilters,
    _.isEqual
  );
  const handleToggle = useCallback(
    (calendarSubscription, isChecked) => {
      dispatch(
        ToggleCalendarSubscriptionAction({
          id: calendarSubscription.id,
          isChecked,
        })
      );
    },
    [dispatch]
  );
  const onClose = useCallback(
    (calendarSubscription) => {
      showConfirmModal({
        title: gettextCatalog.getString(
          'Are you sure you want to remove the calendar "{{name}}"?',
          {
            name: calendarSubscription.name,
          }
        ),
        message: gettextCatalog.getString(
          'If you remove this calendar subscription, all events related to this calendar will be removed from your calendar.'
        ),
        okText: gettextCatalog.getString('Remove'),
        okButtonProps: { danger: true },
        onOk: () => {
          dispatch(DeleteCalendarSubscriptionAction(calendarSubscription.id));
        },
      });
    },
    [dispatch]
  );
  const onEdit = useCallback(
    (calendarSubscription) => {
      dispatch(
        showModal(MODAL_TYPES.CALENDAR_SUBSCRIPTION_MODAL, {
          calendarSubscription,
        })
      );
    },
    [dispatch]
  );

  const onOnForceSync = useCallback(
    (calendarSubscription) => {
      dispatch(ForceSyncCalendarSubscriptionAction(calendarSubscription.id));
    },
    [dispatch]
  );

  const content = (
    <div>
      {calendarSubscriptions
        ? calendarSubscriptions.map((calendarSubscription) => {
            const isChecked =
              !!calendarSubscriptionsFilters[calendarSubscription.id];
            const syncFailed =
              calendarSubscription.isProcessing === -1 ||
              calendarSubscription.noOfAttempts >= 5;
            return (
              <FilterListItem
                isDisabled={isDisabled}
                key={`calendarSubscription${calendarSubscription.id}`}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <div className="bullet-wrapper">
                  <div
                    className="bullet-label"
                    onClick={() => {
                      event.stopPropagation();
                      event.preventDefault();
                      if (isDisabled) return;
                      handleToggle(calendarSubscription, isChecked);
                    }}
                  >
                    <span
                      className={getClassName(
                        isChecked,
                        calendarSubscription.color
                      )}
                    />
                    <div className="label">
                      <span className="name">
                        {syncFailed ? (
                          <CdTooltip
                            title={gettextCatalog.getString(
                              'The feed failed to update, please click resync to get the latest update.'
                            )}
                          >
                            <FontAwesomeIcon
                              color="#e74c3c"
                              icon={faExclamationTriangle}
                              style={{ marginRight: 5 }}
                            />
                          </CdTooltip>
                        ) : (
                          <FontAwesomeIcon
                            icon={faRss}
                            style={{ marginRight: 5 }}
                          />
                        )}
                        {calendarSubscription.name}
                      </span>
                    </div>
                  </div>

                  <span className="filter-actions">
                    {syncFailed ? (
                      <CdTooltip
                        title={gettextCatalog.getString('Resync feed')}
                      >
                        <FontAwesomeIcon
                          icon={faSyncAlt}
                          onClick={() => {
                            if (isDisabled) return;
                            onOnForceSync(calendarSubscription);
                          }}
                          style={{ marginRight: 5, cursor: 'pointer' }}
                        />
                      </CdTooltip>
                    ) : null}
                    <CdTooltip title={gettextCatalog.getString('Edit')}>
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => {
                          if (isDisabled) return;
                          onEdit(calendarSubscription);
                        }}
                        style={{ marginRight: 5, cursor: 'pointer' }}
                      />
                    </CdTooltip>

                    <CdTooltip title={gettextCatalog.getString('Remove')}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          if (isDisabled) return;
                          onClose(calendarSubscription);
                        }}
                        style={{ marginRight: 5, cursor: 'pointer' }}
                      />
                    </CdTooltip>
                  </span>
                </div>
              </FilterListItem>
            );
          })
        : null}
    </div>
  );

  if (isDisabled) {
    return (
      <CdTooltip
        title={gettextCatalog.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }

  return content;
};

export default CalendarSubscriptions;
