import { EventApi } from '@fullcalendar/core';
import { ApiResponse } from 'apisauce';
import _ from 'lodash';
import moment from 'moment';
import { $injector } from 'ngimport';

import {
  ABSENCE_COLOR,
  COLOR_INDEX,
  NO_COLOR,
  PAST_ABSENCE_COLOR_COLOR,
  PAST_COLOR_INDEX,
  PAST_NO_COLOR,
} from '../../../app/ResourceColors';
import {
  AttendanceStatus,
  EventConflict,
  FBaseEvent,
  FEvent,
  FGroupedAbsences,
  DetailedEvent,
  Type,
} from '../models/calendar';
import { Filters } from '../models/calendarData';
import { mainApi } from '../../api';
import { gettextCatalog } from '../../services/I18nService';
import { ChurchCategories, FilterType } from '../store/filters';
import { CalendarEventColoringCriteria } from '../store/main-view';

import NotificationService from '@/react/services/NotificationService';

export type EventRequest = Promise<ApiResponse<FEvent[]>>;

const isPast = (
  start: Date | string | null,
  end: Date | string | null
): boolean => {
  const date = end ? end : start;
  return moment(date).isBefore(Date.now());
};

export const getColorFromIcons = (
  eventColoringCriteria: CalendarEventColoringCriteria,
  item: FEvent
): string => {
  const past = isPast(item.startDate as Date, item.endDate as Date);
  if (item.type === Type.GroupedAbsence) {
    return past ? PAST_ABSENCE_COLOR_COLOR : ABSENCE_COLOR;
  }
  const colorIndex =
    eventColoringCriteria === CalendarEventColoringCriteria.Resource
      ? _.get(item, 'resourceColor')
      : _.get(item, 'categoryColor');
  return past
    ? `${
        _.isFinite(colorIndex) && colorIndex !== -1
          ? PAST_COLOR_INDEX[colorIndex]
          : PAST_NO_COLOR
      }`
    : `${
        _.isFinite(colorIndex) && colorIndex !== -1
          ? COLOR_INDEX[colorIndex]
          : NO_COLOR
      }`;
};

const getAttendanceStatus = (
  event: FEvent,
  currentUserId: number
): AttendanceStatus | null => {
  const users = _.get(event, 'users', {});
  const status = users[currentUserId.toString()];
  if (!status) return null;
  switch (status) {
    case 'yes':
      return AttendanceStatus.Going;
    case 'no':
      return AttendanceStatus.NotGoing;
    case 'no-answer':
      return AttendanceStatus.NoAnswer;
    case 'maybe':
      return AttendanceStatus.Maybe;
  }
};

const getFilterIdsForEvent = (event): string[] => {
  // const absenceFilter = event.type === Type.Absence ? CategoryType.absence : null;
  const resourceFilters = _.map(
    _.keys((event as FEvent).resources),
    (id) => id + '-' + FilterType.resources
  );
  const userFilters = _.map(
    _.keys((event as FEvent).users),
    (id) => id + '-' + FilterType.users
  );
  return _.compact(_.concat(resourceFilters, userFilters));
};

export function mapEvents(
  events: FBaseEvent[],
  currentUserId: number,
  showPrepTime: boolean,
  eventColoringCriteria: CalendarEventColoringCriteria
): EventApi[] {
  // "_.get(item, 'preparationStartDate', item.start) as Date" can be changed to "item.preparationStartDate as Date" after the migration is run in production
  return events.map((item: FBaseEvent): EventApi => {
    const filterIds = getFilterIdsForEvent(item);
    const eventApi = {
      id: item.id
        ? item.id.toString()
        : item.type === Type.GroupedAbsence
          ? item.startDate.toString()
          : '',
      allDay: item.allDay,
      start:
        showPrepTime && item.preparationStartDate && item.type !== 'external'
          ? (_.get(item, 'preparationStartDate', item.startDate) as Date)
          : (item.startDate as Date),
      end:
        item.endDate &&
        showPrepTime &&
        item.cleanupEndDate &&
        item.type !== 'external'
          ? (_.get(item, 'cleanupEndDate', item.endDate) as Date)
          : item.endDate
            ? (item.endDate as Date)
            : (item.startDate as Date),
      title:
        item.type === Type.GroupedAbsence
          ? gettextCatalog.getPlural(
              (item as FGroupedAbsences).ids.length,
              '1 Absence',
              '{{ count }} Absences',
              {
                count: (item as FGroupedAbsences).ids.length,
              }
            ) +
            ((item as FGroupedAbsences).ids.length === 1
              ? ` (${item.firstTitle})`
              : '')
          : item.title,
      resourceIds: filterIds, // list of ids for resourceTimeGridDay view
      extendedProps: {
        originalStartDate: item.startDate as Date,
        originalEndDate: item.endDate as Date,
        preparationStartDate:
          showPrepTime && item.type !== 'external'
            ? (_.get(item, 'preparationStartDate', item.startDate) as Date)
            : (item.startDate as Date),
        cleanupEndDate:
          showPrepTime && item.type !== 'external'
            ? (_.get(item, 'cleanupEndDate', item.endDate) as Date)
            : (item.endDate as Date),
        prepTimeMinutes:
          item.type !== 'external'
            ? moment(item.startDate).diff(item.preparationStartDate, 'minutes')
            : 0,
        cleanupTimeMinutes:
          item.type !== 'external'
            ? moment(item.cleanupEndDate).diff(item.endDate, 'minutes')
            : 0,
        resources: (item as FEvent).resources,
        authorId: (item as FEvent).authorId,
        isAuthorizedByCurrentUser: (item as FEvent).authorId === currentUserId,
        organizationId: (item as FEvent).organizationId,
        comment: (item as FEvent).comments,
        substitute: (item as FEvent).substitute,
        type: item.type === Type.GroupedAbsence ? 'absence' : item.type,
        visibility: item.visibility,
        isPast: isPast(item.startDate as Date, item.endDate as Date),
        status: getAttendanceStatus(item as FEvent, currentUserId),
        customBackgroundColor: getColorFromIcons(
          eventColoringCriteria,
          item as FEvent
        ),
        customBorderColor: getColorFromIcons(
          eventColoringCriteria,
          item as FEvent
        ),
        noOfIntentions: (item as FEvent).noOfIntentions,
        noOfStoles: (item as FEvent).noOfStoles,
        isIntentionsBilled: (item as FEvent).isIntentionsBilled,
        isStolesBilled: (item as FEvent).isStolesBilled,
        isMinimizedAbsences: item.type === Type.GroupedAbsence,
        ids: (item as FGroupedAbsences).ids,
        isExternal: item.type === 'external',
        isAbsence: item.type === 'absence',
        clientVersion: item.clientVersion || 0,
      },
    } as unknown as EventApi;
    return eventApi;
  });
}
class CalendarService {
  public loadData = (
    start: Date,
    end: Date,
    organizationId: number,
    userIds: number[],
    resourceIds: number[],
    categories: ChurchCategories,
    categoryIds: number[],
    feedIds: string[],
    absenceFromGroupIds: number[],
    absenceViewMode: 'minimized' | 'all',
    showDeclinedEvents?: boolean
  ) => {
    const requestBody: Record<string, unknown> = {
      start,
      end,
      organizationId,
      absenceFromGroupIds,
      absenceViewMode,
      showDeclinedEvents,
    };
    if (!_.isEmpty(userIds)) _.set(requestBody, 'userIds', userIds);
    if (!_.isEmpty(resourceIds)) _.set(requestBody, 'resourceIds', resourceIds);
    if (!_.isEmpty(categories)) {
      const preFilteredCategories = _.map(categories, (catValue, key) => ({
        churchId: parseInt(key),
        categoryIds: _.map(
          _.pickBy(catValue, (value, key) => value && key !== 'isAllSelected'),
          (_value, key) => parseInt(key)
        ),
      }));
      if (!_.isEmpty(preFilteredCategories)) {
        _.set(requestBody, 'categories', preFilteredCategories);
      }
    }
    if (!_.isEmpty(categoryIds)) _.set(requestBody, 'categories', categoryIds);
    if (!_.isEmpty(feedIds)) _.set(requestBody, 'feedIds', feedIds);
    if (absenceViewMode === 'minimized') {
      _.set(requestBody, 'absenceTimeZone', _.get(moment, 'tz.guess')());
    }
    return mainApi.post<Event[]>('/calendar/v2/fullcalendar', requestBody);
  };

  public getFullCalendarEventEvents = (
    events: FBaseEvent[],
    currentUserId: number,
    showPrepTime: boolean,
    eventColoringCriteria: CalendarEventColoringCriteria
  ) => mapEvents(events, currentUserId, showPrepTime, eventColoringCriteria);

  public getEvent = async (
    eventId: number,
    isExternal = false
  ): Promise<DetailedEvent> => {
    const response: ApiResponse<DetailedEvent> = await mainApi.get(
      `/calendar/${isExternal ? 'external-event/' : ''}${eventId}`
    );
    if (response.ok) {
      return response.data;
    }
    if (response.status === 404) {
      NotificationService.notifyError(
        gettextCatalog.getString('This event no longer exists')
      );
    } else {
      throw new Error(response.originalError.message);
    }
  };

  public loadMultipleAbsences = async (
    absenceIds: number[]
  ): Promise<FBaseEvent[]> => {
    const response: ApiResponse<FBaseEvent[]> = await mainApi.post(
      '/calendar/multiple-absences',
      {
        absenceIds,
      }
    );
    if (response.ok) {
      return response.data;
    }
    throw new Error(response.originalError.message);
  };

  public getCalendarData = async (): Promise<Filters> => {
    const response = await mainApi.get(`/calendar/data`);
    if (response.ok) return _.get(response, 'data.calendar.filters') as Filters;
    throw new Error(response.originalError.message);
  };

  public updateEvent = async (
    event: Partial<FEvent>,
    allowDoubleBooking?: boolean
  ): Promise<EventConflict> => {
    const payload: any = _.cloneDeep(_.omit(event, ['eid', 'end', 'start']));

    if (allowDoubleBooking) {
      payload.allowDoubleBooking = allowDoubleBooking;
    }

    let start, end, cleanupEndDate, preparationStartDate;
    if (event.startDate) {
      start = moment(event.startDate);
      preparationStartDate = moment(event.preparationStartDate || start);
      end = moment(event.endDate || event.startDate);
      cleanupEndDate = moment(event.cleanupEndDate || end);
      if (!event.endDate && event.allDay) {
        end = end.add(1, 'day');
      } else {
        end = end.add(!event.endDate ? 30 : 0, 'minutes');
      }

      // For all day events we are adding additional day to render correctly.
      // Then we are updating date by drag&drop we need to remove the
      if (event.allDay) {
        start = moment(start.startOf('day').format('YYYY-MM-DD HH:mm:ss'));
        preparationStartDate = moment(
          preparationStartDate.startOf('day').format('YYYY-MM-DD HH:mm:ss')
        );
        end = end.clone().subtract('1', 'day');
        end = moment(end.endOf('day').format('YYYY-MM-DD HH:mm:ss'));
        cleanupEndDate = cleanupEndDate.clone().subtract('1', 'day');
        cleanupEndDate = moment(
          cleanupEndDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        );
      }

      if (payload.form) {
        payload.form = { id: payload.form.id };
      }
      payload.startDate = start.toISOString();
      payload.preparationStartDate = preparationStartDate.toISOString();
      payload.endDate = end.toISOString();
      payload.cleanupEndDate = cleanupEndDate.toISOString();
    }

    const response = await mainApi.put(`/calendar/${event.id}`, payload);

    if (response.ok) {
      return;
    } else if (response.status === 409) {
      return response.data as EventConflict;
    }
    throw response.data;
  };

  public getCalendarToken = async (): Promise<string> => {
    const response = await mainApi.get('/ical/token');
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  };

  // Don't Use the return of this function in the Redux store
  public UN_SAFE_getEventNgResource = (eventId: number) => {
    const calendarResource: any = $injector.get('Calendar');
    return calendarResource.get({ id: eventId }).$promise;
  };

  public updateResponse = async (eventId: number, response: string) => {
    const apiResponse = await mainApi.post(
      `/calendar/invitations/${eventId}/attending/${response}`
    );
    if (apiResponse.ok) {
      return apiResponse.data as string;
    }
    throw apiResponse.data;
  };
}

export default new CalendarService();
