export const IMPORT_FILE = 'src/Filepicker/IMPORT_FILE';
export const GET_GEB_ACCESS = 'src/Filepicker/CHECK_GEB_ACCESS';
export const CLOSE_MODAL = 'src/Filepicker/CLOSE_MODAL';
export const GET_GEB_ACCESS_SUCCESS = 'src/Filepicker/GET_GEB_ACCESS_SUCCESS';
export const GET_GEB_ACCESS_FAIL = 'src/Filepicker/GET_GEB_ACCESS_FAIL';

// Partner files
export const LOAD_PARTNER_FILES = 'src/Partner/LOAD_PARTNER_FILES';
export const PARTNER_FILES_REQUEST = 'src/Partner/PARTNER_FILES_REQUEST';
export const PARTNER_FILES_REQUEST_SUCCESS =
  'src/Partner/PARTNER_FILES_REQUEST_SUCCESS';
export const PARTNER_FILES_REQUEST_FAILED =
  'src/Partner/PARTNER_FILES_REQUEST_FAILED';
export const PARTNER_FILE_SELECTED = 'src/Partner/PARTNER_FILE_SELECTED';
export const IMPORT_PARTNER_FILE = 'src/Partner/IMPORT_PARTNER_FILE';
export const IMPORT_PARTNER_FILE_REQUEST =
  'src/Partner/IMPORT_PARTNER_FILE_REQUEST';
export const IMPORT_PARTNER_FILE_REQUEST_SUCCESS =
  'src/Partner/IMPORT_PARTNER_FILE_REQUEST_SUCCESS';
export const IMPORT_PARTNER_FILE_REQUEST_FAILED =
  'src/Partner/IMPORT_PARTNER_FILE_REQUEST_FAILED';
export const PARTNER_FILES_PAGE_SIZE_CHANGE =
  'src/Partner/PARTNER_FILES_PAGE_SIZE_CHANGE';
export const PARTNER_FILES_PAGE_CHANGE =
  'src/Partner/PARTNER_FILES_PAGE_CHANGE';
export const LOAD_LOOKUPS = 'src/Uploader/LOAD_LOOKUPS';
export const LOAD_PERMISSIONS_SUCCESS = 'src/Uploader/LOAD_PERMISSIONS_SUCCESS';
export const LOAD_FILE_VALIDATOR_SUCCESS =
  'src/Uploader/LOAD_FILE_VALIDATOR_SUCCESS';
export const LOAD_GROUPS_SUCCESS = 'src/Uploader/loadGroupsSuccess';
export const PARTNER_FILES_GET_FILTERS =
  'src/Partner/PARTNER_FILES_GET_FILTERS';
export const PARTNER_FILES_GET_FILTERS_SUCCESS =
  'src/Partner/PARTNER_FILES_GET_FILTERS_SUCCESS';
export const PARTNER_FILES_GET_FILTERS_FAILED =
  'src/Partner/PARTNER_FILES_GET_FILTERS_FAIL';

// File archive
export const LOAD_FILEARCHIVE_FILES = 'src/FileArchive/LOAD_FILEARCHIVE_FILES';
export const FILEARCHIVE_REQUEST = 'src/FileArchive/FILEARCHIVE_REQUEST';
export const FILEARCHIVE_REQUEST_SUCCESS =
  'src/FileArchive/FILEARCHIVE_REQUEST_SUCCESS';
export const FILEARCHIVE_REQUEST_FAILED =
  'src/FileArchive/FILEARCHIVE_REQUEST_FAILED';
export const FILEARCHIVE_FILE_SELECTED =
  'src/FileArchive/FILEARCHIVE_FILE_SELECTED';
export const FILEARCHIVE_FOLDER_SELECTED =
  'src/FileArchive/FILEARCHIVE_FOLDER_SELECTED';
export const FILEARCHIVE_FILES_PAGE_SIZE_CHANGE =
  'src/FileArchive/FILEARCHIVE_FILES_PAGE_SIZE_CHANGE';
export const FILEARCHIVE_FILES_PAGE_CHANGE =
  'src/FileArchive/FILEARCHIVE_FILES_PAGE_CHANGE';
export const LOAD_FILEARCHIVE_BREADCRUMB =
  'src/FileArchive/LOAD_FILEARCHIVE_BREADCRUMB';
export const FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS =
  'src/FileArchive/FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS';
export const FILEARCHIVE_BREADCRUMB_REQUEST_FAILED =
  'src/FileArchive/FILEARCHIVE_BREADCRUMB_REQUEST_FAILED';
export const FILEARCHIVE_GET_TAGS = 'src/FileArchive/FILEARCHIVE_GET_TAGS';
export const FILEARCHIVE_GET_TAGS_SUCCESS =
  'src/FileArchive/FILEARCHIVE_GET_TAGS_SUCCESS';
export const FILEARCHIVE_GET_TAGS_FAIL =
  'src/FileArchive/FILEARCHIVE_GET_TAGS_FAIL';
export const FILEARCHIVE_UPDATE_FILE =
  'src/FileArchive/FILEARCHIVE_UPDATE_FILE';
export const FILEARCHIVE_UPDATE_FILE_REQUEST =
  'src/FileArchive/FILEARCHIVE_UPDATE_FILE_REQUEST';
export const FILEARCHIVE_UPDATE_FILE_SUCCESS =
  'src/FileArchive/FILEARCHIVE_UPDATE_FILE_SUCCESS';
export const FILEARCHIVE_UPDATE_FILE_FAIL =
  'src/FileArchive/FILEARCHIVE_UPDATE_FILE_FAIL';
export const FILEARCHIVE_DELETE_FILE =
  'src/FileArchive/FILEARCHIVE_DELETE_FILE';
export const FILEARCHIVE_DELETE_FILE_REQUEST =
  'src/FileArchive/FILEARCHIVE_DELETE_FILE_REQUEST';
export const FILEARCHIVE_DELETE_FILE_SUCCESS =
  'src/FileArchive/FILEARCHIVE_DELETE_FILE_SUCCESS';
export const FILEARCHIVE_DELETE_FILE_FAIL =
  'src/FileArchive/FILEARCHIVE_DELETE_FILE_FAIL';
export const FILE_ARCHIVE_SEARCH = 'src/FileArchive/FILE_ARCHIVE_SEARCH';
export const FILEARCHIVE_ORDERING_CHANGE =
  'src/FileArchive/FILEARCHIVE_ORDERING_CHANGE';
export const FILEARCHIVE_FOLDER_CREATE =
  'src/FileArchive/FILEARCHIVE_FOLDER_CREATE';
export const FILEARCHIVE_FOLDER_CREATE_SUCCESS =
  'src/FileArchive/FILEARCHIVE_FOLDER_CREATE_SUCCESS';
export const FILEARCHIVE_FOLDER_CREATE_FAIL =
  'src/FileArchive/FILEARCHIVE_FOLDER_CREATE_FAIL';
