import { AppState } from '../../../redux';

// Intentions

export const getIntentions = (state: AppState) =>
  state.intention.intention.intentions;

export const getIntentionFilters = (state: AppState) =>
  state.intention.intention.intentionFilters;

export const getShouldShowMoreFilters = (state: AppState) =>
  state.intention.intention.shouldShowMoreFilters;

export const getIntentionsOverviewTab = (state: AppState) =>
  state.intention.intention.intentionsOverviewTab;

export const getIntentionsCount = (state: AppState) =>
  state.intention.intention.intentionsCount;

export const getIntention = (state: AppState) =>
  state.intention.intention.intention;

export const getEventIntentions = (state: AppState) =>
  state.intention.intention.eventIntentions;

export const getIntentionEvents = (state: AppState) =>
  state.intention.intention.intentionEvents;

export const getIntentionParent = (state: AppState) =>
  state.intention.intention.intentionParent;

export const getBillingIntentions = (state: AppState) =>
  state.intention.intention.billingIntentions;
