import styled from 'styled-components';

const ListItem = styled.div`
  height: 130px;
  width: 130px;

  /* Large screens or more */
  @media only screen and (min-width: 1600px) {
    height: 200px;
    width: 200px;
  }

  /* Medium screens or less */
  @media only screen and (max-width: 1200px) {
    height: 150px;
    width: 150px;
  }

  /* Small devices */
  @media only screen and (max-width: 820px) {
    height: 110px;
    width: 110px;
  }
`;

export default ListItem;
