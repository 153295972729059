import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ManageContactsDropDown from '@/react/people/components/ManageContactsDropDown';

angular
  .module('cdApp.people')
  .component(
    'cdrManageContacts',
    react2angular(
      sharedContext.use(ManageContactsDropDown),
      ['isDisabled', 'selectedPeople', 'isGlobalSelect', 'filter'],
      []
    )
  );
