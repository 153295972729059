class AccountSettingsStateComponent {
  constructor(_, cdApp, gettextCatalog) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.stripeCustomerId = this.cdApp.organization.stripeCustomerId;
    this.pageTitle = this.gettextCatalog.getString(
      'Billing',
      undefined,
      'Related to subcriptions'
    );
  }
}
AccountSettingsStateComponent.$inject = ['_', 'cdApp', 'gettextCatalog'];

angular.module('cdApp.settings').component('cdAccountSettingsState', {
  templateUrl:
    '@/app/settings/account-settings/account-settings.component.html',
  controller: AccountSettingsStateComponent,
});
