import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import BooingConflict from '@/react/calendar/components/BookingConflict';

class DoubleBookingModal {
  constructor(_, Authorization, cdApp) {
    'ngInject';

    this.Authorization = Authorization;
    this._ = _;
    this.cdApp = cdApp;
  }

  $onInit() {
    // Authorization
    this.canDoubleBook = this.Authorization.hasPermission('canDoubleBook');
    // Data from resolve
    this.conflicts = this.resolve.conflicts;
    this.organization = this.cdApp.organization;
    this.hideAllowConflicts = this.resolve.hideAllowConflicts;
  }

  allowDoubleBooking() {
    this.isLoading = true;
    this.resolve.allowConflicts().then((savedEvent) => {
      this.close({ $value: savedEvent });
    });
  }
}

DoubleBookingModal.$inject = ['_', 'Authorization', 'cdApp'];
angular.module('cdApp.calendar').component('cdDoubleBookingModal', {
  templateUrl:
    '@/app/calendar/shared/components/double-booking-modal/double-booking-modal.html',
  controller: DoubleBookingModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});

// Example: <cdr-booking-conflict conflicts="conflicts" event-to-update="eventToUpdate" />
angular
  .module('cdApp.calendar')
  .component(
    'cdrBookingConflict',
    react2angular(
      sharedContext.use(BooingConflict),
      ['conflicts', 'organization'],
      []
    )
  );
