'use strict';

class CalendarFeedModalComponent {
  constructor($window) {
    'ngInject';

    this.$window = $window;
  }

  $onInit() {
    this.feedName = this.resolve.feedName;
    this.feedId = this.resolve.feedId;
    this.feedType = this.resolve.feedType;
    this.showPublicFeed = this.resolve.showPublicFeed;
    this.token = this.resolve.token;

    this.feedUrls = {
      private: this.generateFeedUrl('private'),
      public: this.generateFeedUrl('public'),
    };

    if (this.feedType === 'resource') {
      this.feedUrls.resource = this.generateFeedUrl('resource');
    }
  }

  generateFeedUrl(type = 'private') {
    const parts = ['/ical'];

    if (this.feedType) {
      parts.push(this.feedType);
    }
    if (this.feedId) {
      parts.push(this.feedId);
    }

    let feedUrl = cdApp.config.api.main + parts.join('/');

    switch (type) {
      case 'private':
        feedUrl = `${feedUrl}?token=${this.token}`;
        break;
      case 'public':
        feedUrl = `${feedUrl}?organizationId=${this.$window.churchdeskOrganizationId}`;
        break;
      case 'resource':
        feedUrl = `${feedUrl}/public?organizationId=${this.$window.churchdeskOrganizationId}`;
        break;
      default:
        break;
    }

    return feedUrl;
  }
}

CalendarFeedModalComponent.$inject = ['$window'];
angular.module('cdApp.calendar').component('cdCalendarFeedModal', {
  templateUrl:
    '@/app/calendar/shared/components/calendar-feed/calendar-feed.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CalendarFeedModalComponent,
});
