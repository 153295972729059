(function () {
  'use strict';
  CalendarLog.$inject = ['$$resource'];

  function CalendarLog($$resource) {
    return $$resource(cdApp.config.api.main + '/calendar/changes');
  }

  angular.module('cdApp.shared').factory('CalendarLog', CalendarLog);
})();
