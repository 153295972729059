import { ApiResponse } from 'apisauce';

import { mainApi } from '../../api';

export type DataResponse = Promise<ApiResponse<any, any>>;

class DataService {
  public getData: () => DataResponse = () =>
    mainApi.get<any>('/calendar/filters?version=3');
  public setData: (data: any) => DataResponse = (data) =>
    mainApi.put<any>('/calendar/filters', data);
}

export default new DataService();
