import { atom, selector, selectorFamily } from 'recoil';
import _ from 'lodash';

import { ApiSearchResult, mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import {
  IntentionSearchParams,
  IntentionSearchParamsAtomProps,
  IntentionsSearchQueryTypes,
  IntentionFeesData,
  IntentionsTypes,
} from '../types/intention.type';

export const AssignedIntentionsQuery = selectorFamily<
  ApiSearchResult<IntentionsTypes>,
  { calendarId: number }
>({
  key: 'AssignedIntentionsQuery',
  get:
    ({ calendarId }) =>
    async () => {
      if (!calendarId) return { items: [], total: 0 };
      const response = await mainApi.get<IntentionsTypes[]>(
        `/intention/intentions/assigned/${calendarId}`
      );
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const order = { persolviert: 1, weiterleitung: 2 };
      return {
        // An 'id' is needed for the CdTable to ensure that a key is set per row.
        items: response.data
          .map((item) => ({ ...item, id: item.id }))
          .sort((a, b) => order[a.priority] - order[b.priority]),
        total: response.data.length,
      };
    },
});

export const IntentionSearchParamsAtom = atom<IntentionSearchParamsAtomProps>({
  key: 'IntentionSearchParamsAtom',
  default: {
    statuses: ['unassigned'],
  },
});

export const IntentionsSearchQuery = selectorFamily<
  ApiSearchResult<IntentionsTypes>,
  IntentionSearchParams
>({
  key: 'IntentionsSearchQuery',
  get:
    ({ extraParams, pageNumber, limit }) =>
    async ({ get }) => {
      const { extraIntentionsData, eventCategoryIds } = extraParams;
      const params = get(IntentionSearchParamsAtom);
      const offset = (pageNumber - 1) * limit;
      const payload = { ...params, limit, offset, eventCategoryIds };
      const response = await mainApi.get<
        IntentionsSearchQueryTypes<IntentionsTypes>
      >('/intention/intentions', payload);

      if (!response.ok) {
        handleError(response);
        return null;
      }
      const selectedIntentionsId = extraIntentionsData?.map((item) => item.id);
      const data = response.data.items.map((item) => {
        if (selectedIntentionsId?.includes(item.id)) {
          return { ...item, assignedBy: 'frontend' };
        }
        return item;
      });

      return {
        items: data,
        total: response.data.count,
      };
    },
});

export const MaxNumberOfPrimaryIntentionsQuery = selectorFamily<
  number,
  {
    categoryIds?: number[];
    rotas?: { taskId: number; noOfAssigned: number }[];
    calendarId?: number;
  }
>({
  key: 'MaxNumberOfPrimaryIntentionsQuery',
  get:
    (payload: {
      categoryIds?: number[];
      rotas?: { taskId: number; noOfAssigned: number }[];
      calendarId?: number;
    }) =>
    async () => {
      if (!payload) return;
      if (payload.calendarId) {
        const response = await mainApi.get<{
          maxNumberOfPrimaryIntentions: any;
        }>(
          `/intention/intentions/max-primary-intentions/${payload.calendarId}`
        );
        if (response.ok) {
          return _.get(response.data, 'maxNumberOfPrimaryIntentions') as number;
        }
        throw response.data;
      } else {
        payload.calendarId = undefined;
        const response = await mainApi.post<{
          maxNumberOfPrimaryIntentions: number;
        }>('/intention/intentions/max-primary-intentions/', payload);
        if (response.ok) {
          return _.get(response.data, 'maxNumberOfPrimaryIntentions') as number;
        }
        throw response.data;
      }
    },
});

// Intention query for checking whether intention is allowed for a perticular category or not
export const IntentionFeesQuery = selector<IntentionFeesData>({
  key: 'IntentionFeesQuery',
  get: async () => {
    const payload: any = { types: ['intentionen'] };
    const response = await mainApi.get<IntentionFeesData>(
      '/intention/fees',
      payload
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
