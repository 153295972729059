import { Checkbox, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Text } = Typography;

const CheckboxWithIcon = styled(Checkbox)<{
  $align: 'start' | 'center';
  $iconSize: 'small' | 'large';
}>`
  &.ant-checkbox-wrapper {
    align-items: ${(props) => (props.$align === 'start' ? 'start' : 'center')};

    ${(props) =>
      props.$iconSize === 'large' &&
      `
    .icon-square-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      width: 40px;
      height: 40px;
    }`}
  }

  .ant-checkbox {
    ${(props) =>
      props.$align === 'start' &&
      props.$iconSize === 'large' &&
      `
      top: 13px;
      align-self: start;
    `}
    ${(props) =>
      props.$align === 'start' &&
      props.$iconSize === 'small' &&
      `
      top: 2px;
      align-self: start;
    `}
  }

  &.ant-checkbox-wrapper-checked {
    .icon-square-box {
      ${(props) =>
        props.$iconSize === 'large' &&
        `
          background: #008db6;
          color: white;
      `}
      ${(props) =>
        props.$iconSize === 'small' &&
        `
          color: #008db6;
      `}
    }
  }
`;

export const CdCheckboxWithIcon = (props: {
  checked?: boolean;
  onChange?: (arg?: any) => void;
  Icon: any | JSX.Element;
  iconSize: 'small' | 'large';
  title?: string;
  label?: string;
  disabled?: boolean;
  align: 'start' | 'center';
  children?: React.ReactNode;
}) => (
  <>
    <CheckboxWithIcon
      checked={props.checked}
      onChange={props.onChange}
      style={{ width: '100%' }}
      disabled={props.disabled}
      $align={props.align}
      $iconSize={props.iconSize}
    >
      <Space align={props.align}>
        <div className="icon-square-box">
          <props.Icon
            fixedWidth
            size={props.iconSize === 'large' ? 'lg' : '1x'}
          />
        </div>
        <Space direction="vertical" size={0}>
          {props.title && <Text strong>{props.title}</Text>}
          {props.label && (
            <Text style={{ fontWeight: 400 }}>{props.label}</Text>
          )}
        </Space>
      </Space>
    </CheckboxWithIcon>
    {(props.children && (
      <Row style={{ marginTop: '0px' }} wrap={false}>
        <Col flex="50px" />
        <Col flex="auto">{props.children}</Col>
      </Row>
    )) ||
      undefined}
  </>
);
