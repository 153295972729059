(function () {
  'use strict';

  function transformText() {
    return function (input, specialCase) {
      switch (specialCase) {
        case 'lower':
          return _.toLower(input);
        case 'upper':
          return _.toUpper(input);
        case 'start':
        case 'capitalize':
          return _.capitalize(input);
        case 'kebab':
          return _.kebabCase(input);
        case 'snake':
          return _.snakeCase(input);
        case 'camel':
          return _.camelCase(input);
        default:
          return input;
      }
    };
  }

  angular.module('cdApp.shared').filter('transformText', transformText);
})();
