import { $injector } from 'ngimport';
import { get, isEmpty } from 'lodash';

import { mainApi } from '../api';

import { Packages } from '@/react/shared/models/authentication';

export interface PrivilegeInput {
  entityId: number | string;
  entityType: string;
  privileges: string | string[];
}

class AuthorizationService {
  public hasPermission: (permission: string) => boolean = (permission) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasPermission(permission);
  };

  public hasAnyPermission: (permissions: string[]) => boolean = (
    permissions
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasAnyPermission(permissions);
  };

  public hasPackage: (_package: keyof Packages) => boolean = (_package) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Authorization: any = $injector.get('Authorization');
    return Authorization.hasPackage(_package);
  };

  public hasPermissionAnywhere: (permission: string) => Promise<boolean> =
    async (permission) => {
      const res = await mainApi.get('/acl/has-privilege-in', {
        privilege: permission,
      });
      const data = res.data as { organization: boolean; churchIds: number[] };
      if (res.ok) {
        if (data.organization || !isEmpty(data.churchIds)) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }
      throw get(res.data, 'message');
    };

  public checkPrivilege: (privileges: PrivilegeInput[]) => Promise<{
    [entityId: string]: {
      [privilege: string]: boolean;
    };
  }> = async (privileges) => {
    const res = await mainApi.post('/acl/check-privilege', privileges);
    const data = res.data as {
      [entityId: string]: {
        [privilege: string]: boolean;
      };
    };
    if (res.ok) {
      return Promise.resolve(data);
    }
    throw get(res.data, 'message');
  };
}

export default new AuthorizationService();
