import { get } from 'lodash';

import { mainApi } from '../../api';
import { buildErrorEntityByStatus } from '../../shared/errors';

export interface InterfaceJobs {
  countryIso2: string;
  id: number;
  name: string;
  shortName: string;
}

class JobService {
  public getJobs = async (countryIso2: string) => {
    const res = await mainApi.get(`/users/jobs`, { countryIso2 });
    if (res.ok) return res.data as InterfaceJobs[];
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };
}
export default new JobService();
