'use strict';

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.portal', {
        abstract: true,
        url: '/portal',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
        resolve: {
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name,
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },

        lazyLoad: ($transition$) => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');
          angular.module('cdApp.portal', []);
          return import(
            /* webpackChunkName: "portal.module" */ './portal.components.js'
          )
            .then((mod) => $ocLazyLoad.load(mod.PORTAL_MODULE))
            .catch((err) => console.error(err));
        },
      })

      // Intentions
      .state('app.private.portal.widgets', {
        url: '/widgets',
        component: 'cdrPortalWidgets',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Portal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.portal.categories', {
        url: '/categories',
        component: 'cdrPortalCategories',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Portal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.portal.reports', {
        url: '/lists',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.portal.reports.list', {
        url: '/',
        component: 'cdrPortalReportList',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Portal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.portal.reports.view', {
        url: '/view/{reportId:string}',
        params: {
          reportId: {
            value: null,
          },
        },

        component: 'cdrPortalReportView',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Portal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.portal.organizations', {
        url: '/organizations',
        component: 'cdrPortalOrganizations',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Portal');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.portal.safeguarding', {
        url: '/safeguarding',
        component: 'cdrPortalSafeguarding',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Safeguarding');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      });
  },
]);
