import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import TemplatePlaceholderDefinitions from '@/react/settings/pages/report-templates/TemplatePlaceholderDefinitions';

angular
  .module('cdApp.settings')
  .component(
    'cdrTemplatePlaceholderDefinitions',
    react2angular(sharedContext.use(TemplatePlaceholderDefinitions), [], [])
  );
