import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetNotifications = selectorFamily<
  any,
  { entityId: number; entityType: string }
>({
  key: 'GetNotifications',
  get:
    ({ entityId, entityType }) =>
    async () => {
      const res = await mainApi.get<
        {
          email_logs: [];
          sms_logs: [];
          targetContact: { userId: number };
          updatedAt: string;
        }[]
      >(`/notifications?entityId=${entityId}&entityType=${entityType}`);
      if (!res.ok) {
        handleError(res);
        throw res;
      } else {
        const notificationsInfo = res.data.map((notification) => ({
          emailLogs: notification.email_logs,
          smsLogs: notification.sms_logs,
          userId: notification.targetContact.userId,
          notifiedAt: notification.updatedAt,
        }));
        return notificationsInfo;
      }
    },
});
