import { getCommaFormattedNumber } from '../../../react/shared/utils';
import IntentionReportService from '../../../react/intention/services/IntentionReportService';
import IntentionReportTemplateService from '../../../react/settings/services/IntentionReportTemplateService.ts';

import AuthorizationService from '@/react/services/AuthorizationService';

(function () {
  'use strict';

  function ExportController(
    moment,
    $window,
    $scope,
    $stateParams,
    $state,
    toastr,
    AuthenticationService,
    gettextCatalog,
    CalendarPrint,
    Groups,
    Taxonomies,
    Users,
    CalendarLog,
    ColumnManager,
    appUtils,
    cdApp
  ) {
    // Instantiate models
    $scope.state = $state;
    $scope.stateParams = $stateParams;
    $scope.records = {};
    $scope.ColumnManager = ColumnManager;
    $scope.generating = false;
    $scope.isSearchDisabled = false;
    let localColumns;
    // Data holders
    $scope.events = [];
    $scope.isMoreEvents = false;
    // Filter options
    $scope.groups = [];
    $scope.absenceCategories = [];
    $scope.eventCategories = [];
    $scope.allCategories = [];
    $scope.users = [];
    $scope.showChurchSelector = cdApp.showChurchSelector;

    /**
     * Initialize the filters from the state parameters
     */
    const {
      startDate,
      endDate,
      groupSelect,
      categorySelect,
      resourceSelect,
      userSelect,
      searchText,
      selectedEventsWithoutResourcesInChurchIds,
    } = $stateParams;

    // Currently selected filters
    $scope.selectedFilters = {
      startDate,
      endDate,
      groupSelect,
      categorySelect,
      resourceSelect,
      userSelect,
      searchText,
      selectedEventsWithoutResourcesInChurchIds,
    };

    setColumns();
    queryData();

    // =============================================================================
    // Generate/Search Table Functions
    // =============================================================================

    this.uiOnParamsChanged = function () {
      generate();
    };

    // =============================================================================
    // Getting Data for filters
    // =============================================================================

    function queryData() {
      // Fetch the group information.
      Groups.query(function (groups) {
        $scope.groups = groups;
      });

      // Fetch the taxonomies of absence and event information.
      Taxonomies.query({ type: ['absence', 'event'] }, function (_taxonomies) {
        $scope.absenceCategories = _.filter(_taxonomies, function (item) {
          if (item.type === 'absence') {
            // Changing group name to plural
            item.type = gettextCatalog.getString(
              'Absences',
              null,
              'print-calendar'
            );

            return true;
          }
        });

        $scope.eventCategories = _.filter(_taxonomies, function (item) {
          if (item.type === 'event') {
            // Changing group name to plural
            item.type = gettextCatalog.getString(
              'Events',
              null,
              'print-calendar'
            );

            return true;
          }
        });

        // Combining all events and absences categories
        $scope.allCategories = _.compact($scope.absenceCategories).concat(
          _.compact($scope.eventCategories)
        );

        // Grouping the categories
        $scope.allCategoriesGrouped = _.groupBy($scope.allCategories, 'type');
      });

      // Fetch the users information.
      Users.query(function (_users) {
        $scope.users = _users;
      });

      // Get churches
      $scope.churches = cdApp.organization.churches;

      // Get templates
      IntentionReportTemplateService.getIntentionReportTemplates().then(
        (_reportTemplates) => {
          $scope.reportTemplates = _reportTemplates;
        }
      );
    }

    // =============================================================================
    // Table/Filter functions
    // =============================================================================

    // Default sort.
    $scope.sortType = 'startDate';
    $scope.sortReverse = false;

    // Table filter.
    $scope.isCalendarPrintFilterSelected = function (column) {
      return column.isVisible;
    };

    // Sorting filter function.
    $scope.sortFilters = function (key) {
      $scope.sortType = key;
      $scope.sortReverse = !$scope.sortReverse;
    };

    $scope.selectFilter = function (key, $event) {
      $event.stopPropagation();

      let index = _.findIndex($scope.columns, { key: key });
      $scope.columns[index].isVisible = !$scope.columns[index].isVisible;

      calculateColumnWidth();
    };

    /**
     * If the user selected filters and hasn't applied them yet, we should allow them to apply them
     */
    function canSearch() {
      // If it is the first search than always return true
      if ($stateParams.default) return true;

      return _.some(
        [
          'startDate',
          'endDate',
          'groupSelect',
          'categorySelect',
          'resourceSelect',
          'userSelect',
          'searchText',
          'selectedEventsWithoutResourcesInChurchIds',
        ],

        (filter) =>
          !_.isEqual($scope.selectedFilters[filter], $stateParams[filter])
      );
    }

    /**
     * Update the URL and state with the selected filters in the UI
     */
    $scope.search = function () {
      const {
        startDate,
        endDate,
        groupSelect,
        categorySelect,
        resourceSelect,
        userSelect,
        searchText,
        selectedEventsWithoutResourcesInChurchIds,
      } = $scope.selectedFilters;

      $state.go(
        $state.current,
        _.extend({}, $stateParams, {
          default: false,
          startDate,
          endDate,
          groupSelect,
          categorySelect,
          resourceSelect,
          userSelect,
          searchText,
          selectedEventsWithoutResourcesInChurchIds,
        })
      );
    };

    // If not default filters when page loads then search

    if (!$stateParams.default) {
      generate();
    }

    // =============================================================================
    // Export to Excel.
    // =============================================================================

    $scope.export = function (output = 'xlsx') {
      // When filtering by event/absence categories, set the calendar type by default
      // to `event` or `absence` to avoid e.g. fetching absences when you are filtering
      // by event categories.

      let params = $.param({
        search: $scope.selectedFilters.searchText,
        startDate: moment($scope.selectedFilters.startDate)
          .startOf('day')
          .toISOString(),
        endDate: moment($scope.selectedFilters.endDate)
          .endOf('day')
          .toISOString(),
        groups: $scope.selectedFilters.groupSelect,
        categories: $scope.selectedFilters.categorySelect,
        resources: $scope.selectedFilters.resourceSelect,
        users: $scope.selectedFilters.userSelect,
        eventsWithoutResourcesInChurchIds:
          $scope.selectedFilters.selectedEventsWithoutResourcesInChurchIds,
        columns: _.map(
          _.filter($scope.columns.$indexed, { isVisible: true }),
          'property'
        ),

        access_token: AuthenticationService.getAccessToken(),
        organizationId: $window.churchdeskOrganizationId,
        output,
      });

      window.location.href =
        cdApp.config.api.main + '/calendar/export?' + params;
    };

    // ================================================================================
    // Generate order of service
    // ================================================================================
    $scope.exportOrderOfService = () => {
      // Show a modal to select report template
      if (_.isEmpty($scope.events)) {
        return appUtils.showError(
          'There are no events to generate the report.',
          toastr,
          gettextCatalog
        );
      }

      IntentionReportService.fetchIntentionOrderOfServiceReportDownloadToken().then(
        (downloadToken) => {
          const params = $.param({
            downloadToken,
            startDate: moment($scope.selectedFilters.startDate)
              .startOf('day')
              .toISOString(),
            endDate: moment($scope.selectedFilters.endDate)
              .endOf('day')
              .toISOString(),
            eventsWithoutResourcesInChurchIds:
              $scope.selectedFilters.selectedEventsWithoutResourcesInChurchIds,
            resources: $scope.selectedFilters.resourceSelect,
            categories: $scope.selectedFilters.categorySelect,
          });

          window.location.href = `${cdApp.config.api.main}/calendar/order-of-service/report?${params}`;
        }
      );
    };

    // =============================================================================
    // Generate data button.
    // =============================================================================

    function generate() {
      $scope.generating = true;
      $scope.isSearchDisabled = true;

      // Configure the column manager with those columns
      if (!$scope.columnManager) {
        $scope.columnManager = new ColumnManager({
          name: `printTable-${cdApp.organization.id}`,
          columns: localColumns,
          placeholder: gettextCatalog.getString('Search for column...'),
        });
      }

      // Fetch the calendar print information.
      CalendarPrint.get(
        {
          search: $scope.selectedFilters.searchText,
          startDate: moment($scope.selectedFilters.startDate)
            .startOf('day')
            .toISOString(),
          endDate: moment($scope.selectedFilters.endDate)
            .endOf('day')
            .toISOString(),
          groups: $scope.selectedFilters.groupSelect,
          categories: $scope.selectedFilters.categorySelect,
          resources: $scope.selectedFilters.resourceSelect,
          users: $scope.selectedFilters.userSelect,
          eventsWithoutResourcesInChurchIds:
            $scope.selectedFilters.selectedEventsWithoutResourcesInChurchIds,
        },

        function (data) {
          $scope.generating = false;
          $scope.events = data.events;
          $scope.events.map((event) => {
            let modifiedEvent = event;
            if (event.priests) {
              modifiedEvent = {
                ...event,
                priestsInitials: event.priests,
              };
            }
            return modifiedEvent;
          });
          $scope.isMoreEvents = data.isMoreEvents;
          calculateColumnWidth();
        }
      );
    }

    function setColumns() {
      // Information about columns.
      localColumns = [
        {
          property: 'startDate',
          label: gettextCatalog.getString('Start date'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: true,
        },

        {
          property: 'endDate',
          label: gettextCatalog.getString('End date'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: true,
        },

        {
          property: 'preparationStartDate',
          label: gettextCatalog.getString('Preparation time'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'cleanupEndDate',
          label: gettextCatalog.getString('Clean up time'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'title',
          label: gettextCatalog.getString('Title'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: true,
        },

        {
          property: 'description',
          label: gettextCatalog.getString('Description'),
          isVisible: false,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'group',
          label: gettextCatalog.getString('Shared with'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'locationName',
          label: gettextCatalog.getString('Location name'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'location',
          label: gettextCatalog.getString('Address', null, 'Print calendar'),
          isVisible: false,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'contributor',
          label: gettextCatalog.getString('Contributor'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'price',
          label: gettextCatalog.getString('Price'),
          isVisible: false,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'internalNote',
          label: gettextCatalog.getString('Internal note'),
          isVisible: false,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'churches',
          label: gettextCatalog.getString('Parishes'),
          isVisible: $scope.showChurchSelector,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'resources',
          label: gettextCatalog.getString('Resources'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'users',
          label: gettextCatalog.getString('Users'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'taxonomies',
          label: gettextCatalog.getString('Categories'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'priests',
          label: gettextCatalog.getString('Priest (Assigned from Rota)'),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },
        {
          property: 'priestsInitials',
          label: gettextCatalog.getString(
            'Priest Initials (Assigned from Rota)'
          ),
          isVisible: true,
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },

        {
          property: 'intentions',
          label: gettextCatalog.getString('Intentions'),
          isVisible: AuthorizationService.hasPackage('intentions'),
          group: gettextCatalog.getString('Columns'),
          sortable: false,
        },
      ];

      $scope.columns = localColumns;
      $scope.columns.$indexed = _.keyBy($scope.columns, 'property');
    }

    // Print calendar
    $scope.print = function () {
      window.print();
    };

    // Calculate column width.
    function calculateColumnWidth() {
      const selectedColumns = _.filter($scope.columns.$indexed, {
        isVisible: true,
      });

      $scope.columnWidth = `${100 / selectedColumns.length}%`;
    }

    /**
     * Get the selected filter label
     *
     * @param {Array} filterName The name of the filter
     */
    $scope.getFilterLabel = function (filterName) {
      const total = _.size(_.get($scope.selectedFilters, filterName));

      switch (filterName) {
        case 'userSelect':
          return gettextCatalog.getPlural(
            total,
            '1 user',
            '{{ $count }} users',
            { $count: total }
          );

        case 'groupSelect':
          return gettextCatalog.getPlural(
            total,
            '1 group',
            '{{ $count }} groups',
            {
              $count: total,
            }
          );

        case 'categorySelect':
          return gettextCatalog.getPlural(
            total,
            '1 category',
            '{{ $count }} categories',
            {
              $count: total,
            }
          );
      }
    };

    /**
     * Set the selected filter items
     *
     * @param {Array} filterName The name of the filter
     * @param {Array} selectedItems The filter items that are selected
     */
    $scope.setSelectedFilter = function (filterName, selectedItems) {
      $scope.selectedFilters[filterName] = selectedItems;
      $scope.isSearchDisabled = !canSearch();
    };

    /**
     * Set the selected date filter
     *
     * @param {Date} from The beginning of the interval to filter events
     * @param {Date} until The end of the interval to filter events
     */
    $scope.setDateFilter = function (from, until) {
      $scope.setSelectedFilter('startDate', from);
      $scope.setSelectedFilter('endDate', until);
    };

    // Setting link to event on row
    $scope.getEventUrl = function (event) {
      if (!event) return null;

      const { id, type } = event;
      return `app.private.calendar.${type}/${id}`;
    };

    // Check if user has a name and if they dont then replace it with their email
    $scope.getNames = function (users) {
      for (let i = 0; i < users.length; i++) {
        if (!users[i].name) users[i].name = users[i].email;
      }
      return users;
    };

    // Check if event is an absence
    $scope.isAbsence = function (event) {
      return event.type === 'absence';
    };

    // =============================================================================
    // Calendar log.
    // =============================================================================

    // Pagination options.
    $scope.currentPage = 1;
    $scope.numPerPage = 50;
    $scope.maxSize = 5;
    $scope.paginationStorageKey = 'calendarChanges';

    // Fetch the records.
    $scope.loadRecords = function () {
      $scope.loading = true;

      CalendarLog.get(
        {
          limit: $scope.numPerPage,
          offset: ($scope.currentPage - 1) * $scope.numPerPage,
        },

        function (response) {
          $scope.records = response.changes;
          $scope.total = response.count;
          $scope.loading = false;
        }
      );
    };

    if ($state.current.name === 'app.private.calendar.changes') {
      $scope.loadRecords();
    }

    // Change page.
    $scope.changePage = function (page) {
      $scope.currentPage = page;
      $scope.loadRecords();
    };

    // Change number of items in page
    $scope.changeNumPerPage = (itemsPerPage) => {
      $scope.currentPage = 1;
      $scope.numPerPage = itemsPerPage;
      $scope.loadRecords();
    };

    // Prep and cleanup time functions
    $scope.getPrepTimeLabel = (event) => {
      $scope.prepTimeMinutes = moment(event.startDate).diff(
        event.preparationStartDate,
        'minutes'
      );

      return $scope.getLabel($scope.prepTimeMinutes);
    };

    $scope.getCleanupTimeLabel = (event) => {
      $scope.cleanupTimeMinutes = moment(event.cleanupEndDate).diff(
        event.endDate,
        'minutes'
      );

      return $scope.getLabel($scope.cleanupTimeMinutes);
    };

    $scope.getLabel = (minutes) => {
      if (minutes === 0) return;

      if (minutes >= 60) {
        const numHours = getCommaFormattedNumber(minutes / 60, 1);
        return gettextCatalog.getPlural(
          numHours,
          '1 hour',
          '{{numHours}} hours',
          {
            numHours,
          }
        );
      } else {
        // Show minutes
        return gettextCatalog.getString('{{amount}} minutes', {
          amount: minutes,
        });
      }
    };
  }
  ExportController.$inject = [
    'moment',
    '$window',
    '$scope',
    '$stateParams',
    '$state',
    'toastr',
    'AuthenticationService',
    'gettextCatalog',
    'CalendarPrint',
    'Groups',
    'Taxonomies',
    'Users',
    'CalendarLog',
    'ColumnManager',
    'appUtils',
    'cdApp',
  ];

  angular
    .module('cdApp.calendar')
    .controller('ExportController', ExportController);
})();
