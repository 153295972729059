'use strict';

class AccordionComponent {
  constructor(_) {
    this._ = _;
  }

  $onInit() {
    this.theme = this.theme || 'default';
    this.isOpen = this.isAccordion === false ? true : this.isOpen;
    this.isAccordion = this.isAccordion === false ? false : true;
  }

  $onChanges(changesObj) {
    if (changesObj.hasError) {
      this.theme = changesObj.hasError.currentValue ? 'danger' : 'default';
    }
  }

  toggleAccordion() {
    if (this.isDisabled) return;

    this.isOpen = !this.isOpen;
    this.onToggle({ value: this.isOpen });
  }
}

AccordionComponent.$inject = ['_'];

/**
 * Create an accordion
 *
 * @param {String} header (optional) A string to show in the accordion header
 * @param {String="default, primary, danger, warning"} (optional) The theme color of the accordion
 * @param {Boolean} isOpen Whether the accordion is open or not
 * @param {Boolean} isDisabled Whether the accordion is disabled or not
 * @param {Boolean} hasError Whether the accordion should appear as if it's errored
 */
angular.module('cdApp.shared').component('cdAccordion', {
  templateUrl: '@/app/shared/components/accordion/accordion.component.html',
  bindings: {
    header: '<',
    theme: '<',
    isOpen: '<',
    isDisabled: '<',
    hasError: '<',
    borderless: '<',
    muteHeader: '<',
    onToggle: '&',
    isAccordion: '<',
  },

  transclude: {
    header: '?cdAccordionHeader',
    body: '?cdAccordionBody',
  },

  controller: AccordionComponent,
});
