import _ from 'lodash';

/**
 * Component used for allowing a user unassign themselves from rotas.
 * The assigned users will receive an email notifying them they have been
 * assigned to rotas, and the email will include an link that allows the
 * users to unassign themselves by clicking on it. If they decide to do so,
 * they will be redirected to a page (no authentication required) that will
 * trigger a request to a backend endpoint for unassigning the user. Once
 * the backend replies, the user will be presented with a message notifying
 * them about the outcome of the unassignment: if they were still assigned
 * to the rota at the moment they clicked the link, the message will inform
 * them that they have now been unassigned. If, on the other hand, the user
 * has already clicked on the link before, or someone have unassigned them
 * from the rotas before they clicked the link, they will be informed that
 * they could not be unassigned from the rota because they are not assigned
 * to it anymore.
 * As soon as the component is initialized, the call to the backend endpoint
 * will be executed (see "$onInit()" function), passing on the corresponding
 * parameters.
 */
class SelfUnassignFromRotasComponent {
  constructor($stateParams, Shifts, toastr, appUtils) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.Shifts = Shifts;
    this.toastr = toastr;
    this.appUtils = appUtils;
    this.isLoading = true;
  }

  $onInit() {
    const { $stateParams, Shifts, toastr, appUtils } = this;
    const { userId, eventId, organizationId, token } = $stateParams;
    Shifts.selfUnassignFromRotas({ userId, eventId, organizationId, token })
      .$promise.then(() => {
        this.removed = true;
      })
      .catch((err) => {
        if (
          err.status === 404 &&
          _.get(err, 'data.message', '').match(
            new RegExp("The user with ID '.*' is not assigned to any tasks")
          )
        ) {
          this.notRemoved = true;
          return;
        }
        this.error = true;
        toastr.error(appUtils.getErrorMessage(err));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

SelfUnassignFromRotasComponent.$inject = [
  '$stateParams',
  'Shifts',
  'toastr',
  'appUtils',
];

angular.module('cdApp.intranet').component('cdSelfUnassignFromRotasState', {
  templateUrl:
    '@/app/intranet/shifts/self-unassign-from-rotas/self-unassign-from-rotas.component.html',
  controller: SelfUnassignFromRotasComponent,
});
