import React from 'react';
import { Space, Typography } from 'antd';

import { gettextCatalog } from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import { UserUploadData } from '@/react/safeguarding/types/SafeguardingTypes';

export const ProgressModalTableColumn = () =>
  [
    {
      title: gettextCatalog.getString('First name'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: gettextCatalog.getString('Last name'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: gettextCatalog.getString('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: gettextCatalog.getString('Street'),
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: gettextCatalog.getString('City'),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: gettextCatalog.getString('Post code'),
      dataIndex: 'postCode',
      key: 'postCode',
    },
    {
      title: gettextCatalog.getString('Country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: gettextCatalog.getString('Safeguarding requirement'),
      dataIndex: 'safeguardRequirement',
      key: 'safeguardRequirement',
    },
    {
      title: gettextCatalog.getString('Type of employment'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: gettextCatalog.getString('Work and responsibility'),
      dataIndex: 'workAndResponsibilities',
      key: 'workAndResponsibilities',
    },
    {
      title: gettextCatalog.getString('Parishes'),
      dataIndex: 'parishIds',
      key: 'parishIds',
    },
    {
      title: gettextCatalog.getString('Summary'),
      key: 'summary',
      width: 400,
      render: (data) => (
        <Space direction="vertical">
          {data.errors.length > 0 && (
            <Space direction="vertical">
              {data.errors.map((error: string[], index: any) => (
                <Typography.Text key={index + 'error'} type="danger">
                  {error}.
                </Typography.Text>
              ))}
            </Space>
          )}
          {data.addressUpdateStatus && (
            <Typography.Text type="success">
              {data.addressUpdateStatus}
            </Typography.Text>
          )}
        </Space>
      ),
    },
  ] as ColumnType<UserUploadData>[];
