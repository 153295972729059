'use strict';

/* eslint-disable no-useless-escape */

const PeopleMessageModel = function (
  moment,
  $$resource,
  $state,
  $location,
  $http,
  gettextCatalog,
  toastr,
  Me,
  Church,
  cdApp
) {
  const constructor = $$resource(
    `${cdApp.config.api.main}/people/messages/:id`,
    {},
    {
      report: {
        method: 'GET',
        url: `${cdApp.config.api.main}/people/messages/:id/report`,
        isArray: true,
      },

      getAll: {
        method: 'GET',
        url: `${cdApp.config.api.main}/people/messages/:id`,
        isArray: false,
      },

      calculateRecipients: {
        method: 'POST',
        url: `${cdApp.config.api.main}/people/messages/calculate/recipients`,
        cancellable: true,
      },
    }
  );

  const emptyMessage = {
    from: 'user',
    to: [],
    type: 'email',
    title: '',
    content: '',
    events: [],
    news: [],
    forms: [],
    picture: null,
    churches: [],
  };

  constructor.type = { sms: 'SMS', email: 'E-mail' };
  constructor.churchInformation = Church.get();
  constructor.senders = { church: constructor.churchInformation, user: Me };

  constructor.contentPattern = {
    sms: /^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u0027\u0022\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\#$%&amp;()*+,\.\/\-:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\[~\]|\u20AC\u2013\u2014\u2015\u2017\u2018\u2019\u2018\u2019\u201A\u201B\u201C\u201D\u201E\u2026\u2032\u2033]+$/,
    email: /.+/,
  };

  /**
   * Get a dictionary of colors for different message statistic types
   *
   * @type {String="email,sms"} - The type of the message
   * @returns {Object}
   */
  constructor.getStatusColors = (type) => {
    const statusColors = { sent: 'gray', delivered: 'green' };
    return _.assign(
      statusColors,
      type === 'email'
        ? {
            bounced: 'red',
            opened: 'blue',
            clicked: 'blue',
            unsubscribed: 'orange',
          }
        : { failed: 'red' }
    );
  };

  /**
   * Get a pristine instance of a message
   *
   * @type {String="email,sms"} - The type of the message
   * @returns {Object}
   */
  constructor.empty = (type) => {
    emptyMessage.type = type || 'email';
    emptyMessage.organizationId = cdApp.organization.id;
    return new constructor(_.cloneDeep(emptyMessage));
  };

  /**
   * Get the content pattern for current message type
   *
   * @type {String="email,sms"} - The type of the message
   * @returns {String}
   */
  constructor.prototype.contentPattern = function () {
    return constructor.contentPattern[this.type];
  };

  /**
   * Determine whether any changes have been made to the current message
   *
   * @returns {Boolean}
   */
  constructor.prototype.hasChanged = function () {
    return !_(this)
      .pick(_.keys(emptyMessage))
      .isEqual(_.pick(this.$pristine, _.keys(emptyMessage)));
  };

  /**
   * Calculate the size of the current SMS message
   *
   * @returns {Object}
   */
  constructor.prototype.size = function () {
    if (this.type !== 'sms') {
      return {
        remaining: 0,
        segments: 0,
      };
    }

    let length = this.content.length;
    let segmentSize = this.content.length > 160 ? 153 : 160;
    let segments = Math.ceil(length / segmentSize);

    // If segments are more than one remove the first segment to get the remaining characters correctly
    if (segments > 1) {
      length -= 160;
    }

    return {
      remaining:
        segmentSize - (length % segmentSize || (segments && segmentSize)),
      segments: segments,
    };
  };

  /**
   * Determine whether the message can be sent or not
   *
   * @returns {Boolean}
   */
  constructor.prototype.canSend = function () {
    if (
      this.type === 'sms' &&
      !_.get(constructor.churchInformation, 'smsSenderName')
    ) {
      toastr.warning(
        gettextCatalog.getString('You need to set your sender name first.')
      );

      return false;
    }

    if (this.content.length && !this.contentPattern().test(this.content)) {
      toastr.error(
        gettextCatalog.getString(
          'The message you are trying to send contains illegal characters.'
        )
      );

      return false;
    }

    if (!this.title && this.type !== 'sms') {
      toastr.warning(
        gettextCatalog.getString('You cannot send an untitled message.')
      );

      return false;
    }

    if (!this.content && !this.events.length && !this.news.length) {
      toastr.warning(
        gettextCatalog.getString('You cannot send an empty message.')
      );

      return false;
    }

    if (!this.to || _.isEmpty(this.to)) {
      toastr.warning(
        gettextCatalog.getString('You cannot send a message to nobody.')
      );

      return false;
    }

    if (!this.rendered && this.type !== 'sms') {
      toastr.error(
        gettextCatalog.getString(
          'Something went wrong. We could not render your message.'
        )
      );

      return false;
    }

    if (this.type === 'email' && this.title.length > 50) {
      toastr.warning(
        gettextCatalog.getString(
          'The message title cannot be longer than 50 characters, including spaces.'
        )
      );

      return false;
    }

    return true;
  };

  /**
   * Send a test message to the current user
   */
  constructor.prototype.test = function () {
    if (!this.canSend()) return;

    $http.post(`${cdApp.config.api.main}/people/messages/test`, this).then(
      () => {
        toastr.info(
          gettextCatalog.getString('We are sending a test message to you...')
        );
      },
      (error) => {
        toastr.error(
          _.get(
            error,
            'data.message',
            gettextCatalog.getString(
              'We could not send this test message to you.'
            )
          )
        );
      }
    );
  };

  /**
   * Send the current message
   *
   * @param {Date} when - The date the message should be sent
   * @param {Function} onSuccess
   * @param {Function} onFail
   */
  constructor.prototype.send = function (when, onSuccess, onFail) {
    const messageType = this.type;

    this.to = _.filter(this.to, (recipient) => {
      if (recipient.people || recipient.setup || !messageType) {
        return true;
      }

      const property = messageType === 'sms' ? 'phone' : 'email';
      /**
       * The recipient's email or phone could exist either in the object itself, or in
       * the `fields` array if it was the payload of an elastic search
       */
      return (
        _.get(recipient, property) ||
        _.chain(recipient).get('fields').find({ property }).get('value').value()
      );
    });

    if (!this.canSend()) return;

    toastr.info(
      when
        ? gettextCatalog.getString('Your message has been scheduled.')
        : gettextCatalog.getString('We are sending your message right now...')
    );

    this.scheduled = (when || new Date()).toISOString();

    this.$save(
      function (message) {
        if (!when) {
          this.sent = moment().add(30, 'seconds').toISOString();
        }

        return (
          onSuccess ||
          function () {
            const messageId = this.id || message.id;
            if (messageId && $location.path() !== `/messages/${messageId}`) {
              setTimeout(() =>
                $state.go('app.private.people.messages.view', {
                  id: messageId,
                  isSentMessage: !when ? true : false,
                })
              );
            }
          }
        ).apply(this, arguments);
      },
      function () {
        toastr.error(
          gettextCatalog.getString('Message not sent: {{errorMessage}}'),
          {
            errorMessage: _.get(arguments, '[1].data.message'),
          }
        );

        return (onFail || _.noop).apply(this, arguments);
      }
    );
  };

  return constructor;
};

PeopleMessageModel.$inject = [
  'moment',
  '$$resource',
  '$state',
  '$location',
  '$http',
  'gettextCatalog',
  'toastr',
  'Me',
  'Church',
  'cdApp',
];

angular.module('cdApp.people').factory('PeopleMessages', PeopleMessageModel);
