import { atom, selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import { DomainInterface } from '@/react/homepage/types/domain.type';
import { handleError } from '@/react/services/ErrorHandlingService';

export const DomainListQuery = selectorFamily<DomainInterface[], string>({
  key: 'DomainListQuery',
  get: (siteId) => async () => {
    if (!siteId) return;
    const response = await mainApi.get<DomainInterface[]>(
      `/sites/${siteId}/domains`
    );

    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const CheckStatusLoadingState = atom<boolean>({
  key: 'CheckStatusLoadingState',
  default: false,
});

export const DomainCheckStatus = selectorFamily<
  DomainInterface,
  { domainId: string; siteId: number }
>({
  key: 'DomainListQuery',
  get:
    ({ siteId, domainId }) =>
    async () => {
      if (!siteId || !domainId) return;
      const response = await mainApi.get<DomainInterface>(
        `/sites/${siteId}/domain/${domainId}/status`
      );

      if (!response.ok) {
        handleError(response);
        return null;
      }
      return response.data;
    },
});
