import { all, fork, takeLatest } from 'redux-saga/effects';

import { withLoadingAndErrors } from '../loading/saga';
import {
  FETCH_PERSON,
  SEARCH_PEOPLE,
  FETCH_CATEGORIES,
  FETCH_EVENT,
} from '../store/Actions';
import { rootGroupSaga } from '../store/groups';

import {
  fetchPersonSaga,
  searchPeopleSaga,
  fetchCategoriesSaga,
  fetchEventSaga,
} from './SharedSagas';

export default function* root(): Generator {
  yield all([
    // People
    takeLatest(
      FETCH_PERSON,
      withLoadingAndErrors(FETCH_PERSON, fetchPersonSaga)
    ),
    takeLatest(
      SEARCH_PEOPLE,
      withLoadingAndErrors(SEARCH_PEOPLE, searchPeopleSaga)
    ),
    // Categories
    takeLatest(
      FETCH_CATEGORIES,
      withLoadingAndErrors(FETCH_CATEGORIES, fetchCategoriesSaga)
    ),
    // Events
    takeLatest(FETCH_EVENT, withLoadingAndErrors(FETCH_EVENT, fetchEventSaga)),
    fork(rootGroupSaga),
  ]);
}
