'use strict';

angular.module('cdApp.shared').service('cdContributionStatusStrings', [
  'gettextCatalog',
  (gettextCatalog) => {
    'ngInject';

    return {
      getStrings(type = 'payment') {
        const projectType =
          type === 'payment'
            ? gettextCatalog.getString('payment')
            : gettextCatalog.getString('contribution');
        return {
          pending: gettextCatalog.getString(
            'This {{type}} is currently being verified and it will be reserved as soon as it has been validated.',
            { type: projectType }
          ),

          failed: gettextCatalog.getString(
            'An attempt to charge this {{type}} was made, but it failed. Please contact us for further information.',
            { type: projectType }
          ),

          paid: gettextCatalog.getString(
            'This {{type}} has been verified and reserved by us. We will transfer it to your bank account shortly.',
            { type: projectType }
          ),

          refunded: gettextCatalog.getString(
            'This {{type}} has been refunded to the contributor and deducted from your total. Please contact us for further information.',
            { type: projectType }
          ),
        };
      },
    };
  },
]);
