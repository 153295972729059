'use strict';

angular.module('cdApp.shared').component('cdErrorState', {
  templateUrl: '@/app/shared/components/error-state/error-state.component.html',
  bindings: {
    errorCode: '<',
  },

  controller: [
    '$window',
    function ($window) {
      'ngInject';

      this.goBack = () => {
        $window.history.go(-1);
      };
    },
  ],
});
