(function () {
  'use strict';
  Resources.$inject = ['$$resource'];

  function Resources($$resource) {
    return $$resource(
      cdApp.config.api.main + '/resources/:id',
      {
        id: '@id',
      },

      {
        locations: {
          method: 'GET',
          params: {
            id: 'locations',
          },

          isArray: true,
        },

        updateLocation: {
          method: 'PUT',
          url: `${cdApp.config.api.main}/resources/update-location`,
        },

        copyResourceNameToLocationName: {
          method: 'POST',
          url: `${cdApp.config.api.main}/resources/copy-resource-name-to-location-name`,
        },

        getChurches: {
          // Returns all churches that you can perform the given operation in the church.
          method: 'GET',
          url: `${cdApp.config.api.main}/resources/churches`,
          isArray: true,
        },

        getChurchesV2: {
          // Returns all churches that you can perform the given operation in the church.
          // Also includes viaOrganizationGrant that shows whether you gained access to this via a church or organization grant.
          method: 'GET',
          params: { version: 2 },
          url: `${cdApp.config.api.main}/resources/churches`,
        },

        getChurchesV3: {
          // Returns all churches with a attribute 'access' that shows whether you can perform the given operation in the church.
          // Also includes viaOrganizationGrant that shows whether you gained access to this via a church or organization grant.
          method: 'GET',
          params: { version: 3 },
          url: `${cdApp.config.api.main}/resources/churches`,
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Resources', Resources);
})();
