'use strict';

ContributionsFeedController.$inject = [
  '$filter',
  '$state',
  'gettextCatalog',
  'contributionService',
];

function ContributionsFeedController(
  $filter,
  $state,
  gettextCatalog,
  contributionService
) {
  let $ctrl = this;

  $ctrl.$onInit = function () {
    if ($ctrl.item) {
      $ctrl.items.$promise.then((items) => {
        $ctrl.limit = $ctrl.limit || items.length;
      });
    }
  };

  $ctrl.color = function (status) {
    switch (status) {
      default:
      case 'pending':
      case 'charged':
        return 'yellow';
      case 'failed':
        return 'red';
      case 'refunded':
        return 'blue';
    }
  };

  $ctrl.status = function (status) {
    switch (status) {
      default:
      case 'pending':
      case 'charged':
        return gettextCatalog.getString('Pending');
      case 'failed':
        return gettextCatalog.getString('Failed');
      case 'refunded':
        return gettextCatalog.getString('Refunded');
    }
  };

  $ctrl.summary = function (contribution) {
    return gettextCatalog.getString('{{ name }} donated {{ amount }}', {
      name:
        '<a href="/people/' +
        contribution.personId +
        '">' +
        getContributorName(contribution) +
        '</a>',
      amount: $filter('isoCurrency')(
        contribution.amount,
        contribution.currency
      ),
    });
  };

  $ctrl.getUserLink = function (personId) {
    return $state.href('app.private.people.contacts.view', { id: personId });
  };

  $ctrl.getContributorName = getContributorName;

  function getContributorName(c) {
    return $ctrl.author
      ? $filter('getName')($ctrl.author)
      : contributionService.getContributorData(c);
  }
}

angular.module('cdApp.shared').component('cdContributionFeed', {
  templateUrl:
    '@/app/shared/components/contribution-feed/contribution-feed.component.html',
  bindings: {
    items: '<',
    author: '<',
    hideStatus: '<',
    hideDevice: '<',
  },

  controller: ContributionsFeedController,
});
