import _ from 'lodash';

import { mainApi } from '../../api';
import { Foundation, FoundationTypes } from '../models/foundation';
import {
  FetchFoundationsPayload,
  FetchIntentionsForFoundation,
} from '../redux/foundations/Actions';

import { IntentionData } from './IntentionService';

export interface FoundationData {
  items: Foundation[];
  count: number;
}

class FoundationService {
  public async getFoundations(
    payload: FetchFoundationsPayload
  ): Promise<FoundationData> {
    const endpointPayload: any = _.omit(payload, 'status');
    const status = payload.status;
    if (status) {
      endpointPayload.statuses = [status];
    }
    const response = await mainApi.get(
      '/intention/foundations',
      endpointPayload
    );
    if (response.ok) {
      return response.data as FoundationData;
    }
    throw response.data;
  }

  public async getFoundation(id: string): Promise<Foundation> {
    const response = await mainApi.get(`/intention/foundations/${id}`);
    if (response.ok) {
      return response.data as Foundation;
    }
    throw response.data;
  }

  public async getFoundationsNextReferenceNumber(
    churchId: number,
    type: FoundationTypes
  ): Promise<string> {
    const response = await mainApi.get(
      '/intention/foundations/next-reference-number',
      {
        churchId,
        type,
      }
    );
    if (response.ok) {
      return _.get(response.data, 'nextReferenceNumber') as string;
    }
    throw response.data;
  }

  public async createFoundation(
    foundation: Partial<Foundation>
  ): Promise<void> {
    const response = await mainApi.post('/intention/foundations', foundation);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateFoundation(
    id: string,
    foundation: Partial<Foundation>
  ): Promise<void> {
    const response = await mainApi.put(
      `/intention/foundations/${id}`,
      foundation
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async changeFoundationStatus(
    id: string,
    status: string,
    startYear: number
  ): Promise<void> {
    const response = await mainApi.put(
      `/intention/foundations/${id}/change-status`,
      {
        status,
        startYear,
      }
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteFoundation(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/foundations/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async restoreFoundation(id: string): Promise<void> {
    const response = await mainApi.post(
      `/intention/foundations/${id}/revert-deletion`
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async fetchIntentionsForFoundation(
    payload: FetchIntentionsForFoundation
  ): Promise<IntentionData> {
    const endpointPayload: any = _.omit(payload, ['status', 'id', 'type']);
    const status = payload.status;
    if (status) {
      endpointPayload.statuses = [status];
    }
    const response = await mainApi.get(
      `/intention/intentions/associated/${payload.id}`,
      endpointPayload
    );
    if (response.ok) {
      return response.data as IntentionData;
    }
    throw response.data;
  }

  public async getFoundationExportDownloadToken(): Promise<string> {
    const response = await mainApi.get('/intention/foundations/export/token');
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }

  public async getFoundationIntentionsDownloadToken(
    type: FoundationTypes
  ): Promise<string> {
    const response = await mainApi.get(
      '/intention/intention-reports/foundation-intentions/download-token',
      { type }
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }
}

export default new FoundationService();
