class ShareOnFacebookController {
  constructor(_, moment, gettextCatalog, $scope, Calendar) {
    'ngInject';

    this._ = _;
    this.moment = moment;
    this.gettextCatalog = gettextCatalog;
    this.$scope = $scope;
    this.Calendar = Calendar;
  }

  $onInit() {
    const { gettextCatalog, $scope } = this;

    this.schedulingOptions = [
      {
        value: 'now',
        label: gettextCatalog.getString('Post now'),
        description: gettextCatalog.getString('Post to Facebook right away...'),
      },

      {
        value: 'one_week',
        label: gettextCatalog.getString('1 week before'),
        units: {
          duration: 1,
          durationUnit: 'week',
          hour: 15,
          minute: 0,
        },
      },

      {
        value: 'two_weeks',
        label: gettextCatalog.getString('2 weeks before'),
        units: {
          duration: 2,
          durationUnit: 'week',
          hour: 15,
          minute: 0,
        },
      },

      {
        value: 'one_month',
        label: gettextCatalog.getString('1 month before'),
        units: {
          duration: 1,
          durationUnit: 'month',
          hour: 15,
          minute: 0,
        },
      },

      {
        value: 'custom',
        label: gettextCatalog.getString('Customise'),
        description: gettextCatalog.getString('Choose specifically...'),
      },
    ];

    // Timepicker options
    this.timePickerOptions = {
      step: 60,
      timeFormat: 'H:i',
      appendTo: 'body',
      asMoment: true,
    };

    // As the event start date is changed, reset the selected option to one of the possible options
    $scope.$watch('$ctrl.event.startDate', () =>
      this.initializeSelectedOption()
    );
  }

  /**
   * Figure out which of the scheduling options the user might have selected
   */
  initializeSelectedOption() {
    const { _, moment } = this;

    /**
     * Make a local copy of the facebook object
     */
    const facebook = _.get(this.event, 'facebook');
    if (!facebook) {
      this.facebook = { publish: true, isScheduled: false, message: '' };
    } else {
      this.facebook = _.pick(facebook, [
        'publish',
        'isScheduled',
        'schedulingOptions',
        'message',
        'publishedOn',
      ]);
    }

    const { schedulingOptions } = this.facebook;

    // Figure out which of the scheduling options the user might have selected
    if (
      !schedulingOptions ||
      (!schedulingOptions.duration &&
        !schedulingOptions.hour &&
        !schedulingOptions.minute)
    ) {
      this.scheduleOn = 'now';
    } else {
      const selectedOption = _.find(
        this.schedulingOptions,
        (scheduleOption) =>
          scheduleOption.units &&
          _.isEqual(scheduleOption.units, schedulingOptions)
      );

      // If none of the pre-defined scheduling optipn match, default back to custom scheduling
      this.scheduleOn = !selectedOption ? 'custom' : selectedOption.value;
      const { duration, hour, minute } = schedulingOptions;
      this.facebook.duration = duration;
      this.facebook.timeOfDay = moment().set({ hour, minute });
    }

    this.onValueChanged();
  }

  isScheduleOptionDisabled(scheduleOption) {
    const { _, moment } = this;
    // Now and custom options should never be disabled
    if (_.includes(['now', 'custom'], scheduleOption.value)) return false;

    // An option is disabled if it produces a publishedOn time in the past
    const publishedOn = this.getPublishOnDate(scheduleOption.units);
    return moment(publishedOn).isSameOrBefore(moment());
  }

  /**
   * Get the scheduling options selected by the user. First check if it's a pre-defined option,
   * otherwise create the object that has the scheduling configuration parameters
   */
  getSchedulingOptions() {
    const { _, moment } = this;
    const selectedOption = _.find(this.schedulingOptions, {
      value: this.scheduleOn,
    });

    // If a predefined option is selected
    if (selectedOption.units) return selectedOption.units;

    const { duration, timeOfDay } = this.facebook;
    const hour = timeOfDay ? moment(timeOfDay).hour() : 0;
    const minute = timeOfDay ? moment(timeOfDay).minute() : 0;
    return {
      duration,
      durationUnit: 'day',
      hour,
      minute,
    };
  }

  getFinalValue() {
    const { publish } = this.facebook;

    if (!publish) return this.facebook;

    const finalValue = {
      publish: true,
      isScheduled: false,
      message: this.facebook.message,
      publishedOn: this.facebook.publishedOn,
    };

    if (this.scheduleOn !== 'now') {
      finalValue.schedulingOptions = this.getSchedulingOptions();
      finalValue.isScheduled = true;
    }

    return finalValue;
  }

  /**
   * Calculate the scheduled date based on scheduling options
   */
  getPublishOnDate(schedulingOptions) {
    return moment(this.event.startDate || this.event.schedulePublish)
      .subtract(schedulingOptions.duration, schedulingOptions.durationUnit)
      .set({
        hour: schedulingOptions.hour,
        minute: schedulingOptions.minute,
        second: 0,
      });
  }

  /**
   * Renders a string that tells when the post will be scheduled.
   */
  getCalculatedPublishOnDate() {
    const { gettextCatalog, moment } = this;
    if (this.scheduleOn === 'now') return null;

    const schedulingOptions = this.getSchedulingOptions();
    const publishedOn = this.getPublishOnDate(schedulingOptions);
    return gettextCatalog.getString('Will be posted on {{date}}', {
      date: moment(publishedOn).format('LLLL'),
    });
  }

  onValueChanged(prevScheduleOn) {
    const { moment } = this;

    // If changed to 'custom', set default values for scheduling
    if (
      prevScheduleOn &&
      prevScheduleOn !== this.scheduleOn &&
      this.scheduleOn === 'custom'
    ) {
      this.facebook.duration = 2;
      this.facebook.timeOfDay = moment().set({ hour: 15, minute: 0 });
    }

    this.ngModelCtrl.$setDirty();
    const value = this.getFinalValue();

    if (value.isScheduled && !value.publishedOn) {
      const publishedOn = this.getPublishOnDate(value.schedulingOptions);
      const isFutureScheduling = moment(publishedOn).isAfter(moment());
      this.ngModelCtrl.$setValidity('isScheduledInThePast', isFutureScheduling);
    } else {
      this.ngModelCtrl.$setValidity('isScheduledInThePast', true);
    }

    this.ngModelCtrl.$setViewValue(value);
  }
}
ShareOnFacebookController.$inject = [
  '_',
  'moment',
  'gettextCatalog',
  '$scope',
  'Calendar',
];

angular.module('cdApp.shared').component('cdShareOnFacebook', {
  template: require('./share-on-facebook.component.html'),
  controller: ShareOnFacebookController,
  require: {
    ngModelCtrl: 'ngModel',
  },

  bindings: {
    event: '<',
    ngDisabled: '<',
    hideScheduling: '<',
  },
});
