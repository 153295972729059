import { mainApi } from '../../api';
import {
  IntentionFee,
  IntentionFeeTypes,
  IntentionFeeRecipient,
} from '../models/fee';
import {
  CreateIntentionFeeRecipientPayload,
  FetchIntentionFeeRecipientsPayload,
} from '../redux/intention-fees/Actions';

export interface IntentionFeesData {
  items: IntentionFee[];
  count: number;
}

class IntentionFeeService {
  public async getIntentionFees({
    type,
  }: {
    type?: IntentionFeeTypes;
  } = {}): Promise<IntentionFeesData> {
    const payload: any = { types: [type] };
    const response = await mainApi.get('/intention/fees', payload);
    if (response.ok) {
      return response.data as IntentionFeesData;
    }
    throw response.data;
  }

  public async getIntentionFee(id: string): Promise<IntentionFee> {
    const response = await mainApi.get(`/intention/fees/${id}`);
    if (response.ok) {
      return response.data as IntentionFee;
    }
    throw response.data;
  }

  public async createIntentionFee(
    intentionFee: Partial<IntentionFee>
  ): Promise<void> {
    const response = await mainApi.post('/intention/fees', intentionFee);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateIntentionFee(
    id: string,
    intentionFee: Partial<IntentionFee>
  ): Promise<void> {
    const response = await mainApi.put(`/intention/fees/${id}`, intentionFee);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteIntentionFee(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/fees/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async getIntentionFeeRecipients(
    payload: FetchIntentionFeeRecipientsPayload
  ): Promise<IntentionFeeRecipient[]> {
    const response = await mainApi.get('/intention/fees/recipients', payload);
    if (response.ok) {
      return response.data as IntentionFeeRecipient[];
    }
    throw response.data;
  }

  public async createIntentionFeeRecipient(
    payload: CreateIntentionFeeRecipientPayload
  ): Promise<void> {
    const response = await mainApi.post(`/intention/fees/recipients`, payload);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateIntentionFeeRecipient(
    id: string,
    intentionFeeRecipient: Partial<IntentionFeeRecipient>
  ): Promise<void> {
    const response = await mainApi.put(
      `/intention/fees/recipients/${id}`,
      intentionFeeRecipient
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteIntentionFeeRecipient(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/fees/recipients/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }
}

export default new IntentionFeeService();
