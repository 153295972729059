'use strict';

/**
 * Loading spinner component that supports many variations. You can use this as:
 *
 *   - an inline loading spinner, next to a button for instance
 *   - an absolute loading spinner, that will fill its parent, provided the parent has `position: relative`
 *   - a page loading spinner, that will appear on top of the entire page, with the exception of the sidebar and app menu (perfect for state transitions)
 *   - a loading spinner with text underneath
 *
 * @prop {String} size - The size of the loader. Accepted values: 'tiny, mini, small, medium, large'
 * @prop {Boolean} inline - Whether the loader should be inline
 * @prop {Boolean} inverted - Whether the loader color should be inverted. By default it is black
 * @prop {Boolean} dimmer - Whether the loader should be wrapped inside a dimmer. By default, a dimmer will fill its parent, provided the parent has `position: relative`
 * @prop {Boolean} dimmerInverted - Whether the dimmer should be inverted. By default it is white.
 * @prop {Boolean} fillPage - Whether the dimmer should fill the screen. It goes on top of everything, except the sidebar and app menu, and takes the same background color as the body
 *
 * @example <cd-loader dimmer="true" ng-if="expression"></cd-loader>
 *   // Basic loader with a white dimmer. Add `position: relative` to the parent so the dimmer fills it up
 *
 * @example <cd-loader dimmer="true" fill-page="true" ng-if="expression"></cd-loader>
 *   // Basic loader that will fill the screen. Use this for state transitions, when waiting for HTTP requests
 *
 * @example <cd-loader size="'tiny'" inline="true" inverted="true" ng-if="expression"></cd-loader>
 *   // Inside a button. Use the `inverted` property if the button has dark background
 *
 * @example <cd-loader dimmer="true" ng-if="expression">Please wait...</cd-loader>
 *   // Loader with text. Best used together with the `dimmer` property
 */
angular
  .module('cdApp.shared')
  .component('cdLoader', {
    templateUrl: '@/app/shared/components/loader/loader.component.html',
    bindings: {
      size: '<',
      inline: '<',
      inverted: '<',
      dimmer: '<',
      dimmerInverted: '<',
      fillPage: '<',
    },

    transclude: true,
    controller: [
      '$transclude',
      function ($transclude) {
        'ngInject';

        let $ctrl = this;

        // Determine if any transcluded content exist so CSS can be changed appropriately
        $transclude(function (clone) {
          $ctrl.hasText = !!clone.length;
        });
      },
    ],
  })

  // Dumb directive used for reusing the spinner HTML code. Using a directive because the `replace` functionality is essential
  .directive('cdLoaderSpinner', function () {
    return {
      transclude: true,
      replace: true,
      restrict: 'AE',
      templateUrl:
        '@/app/shared/components/loader/partials/spinner.partial.html',
    };
  });
