import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

import ElementWrapper from './ElementWrapper';

const Credit: React.FC<{ credit: string }> = ({ credit }) => (
  <React.Fragment>
    {credit ? (
      <ElementWrapper>
        <FontAwesomeIcon icon={faCopyright} />
        <span>{credit}</span>
      </ElementWrapper>
    ) : null}
  </React.Fragment>
);
export default Credit;
