(function () {
  'use strict';
  FormResponses.$inject = [
    '$$resource',
    'appUtils',
    'gettextCatalog',
    '$uibModal',
    'toastr',
    '$q',
  ];
  Forms.$inject = [
    '_',
    'moment',
    '$$resource',
    '$state',
    '$http',
    '$q',
    '$uibModal',
    'toastr',
    'gettextCatalog',
    'cdApp',
  ];

  function Forms(
    _,
    moment,
    $$resource,
    $state,
    $http,
    $q,
    $uibModal,
    toastr,
    gettextCatalog,
    cdApp
  ) {
    let FormConstructor = $$resource(
      cdApp.config.api.forms + '/forms/:id',
      null,
      {
        getTemplates: {
          method: 'GET',
          url: cdApp.config.api.forms + '/templates',
          isArray: true,
        },

        getTemplate: {
          method: 'GET',
          url: cdApp.config.api.forms + '/templates/:templateId',
          isArray: false,
        },

        duplicateForm: {
          method: 'POST',
          url: cdApp.config.api.forms + '/templates/:id/duplicate',
          params: {
            language: _.get(cdApp, 'organization.locale.language', 'en-gb'),
            countryIso: _.get(cdApp, 'organization.locale.country'),
          },
        },

        getPublic: {
          method: 'POST',
          isArray: true,
          url: cdApp.config.api.forms + '/forms/public/list',
        },

        cancelTicket: {
          method: 'POST',
          url:
            cdApp.config.api.forms +
            '/forms/:formId/submissions/:submissionId/cancel-ticket',
        },
      }
    );

    /**
     * Set a predefined form confirmation message
     */
    FormConstructor.defaultConfirmationMessage = gettextCatalog.getString(
      'We graciously thank you for your response.\n\nSincerely, \n{{churchName}}',
      { churchName: _.get(cdApp, 'organization.name') }
    );

    /**
     * Default form configuration
     */
    let empty = {
      description: '',
      startDate: moment().startOf('day').toDate(),
      endDate: null,
      organizationId: _.get(cdApp, 'organization.id'),
      message: FormConstructor.defaultConfirmationMessage,
      status: 1,
      page: 0,
      editable: true,
      usersToNotify: [],
      peopleSections: {},
      confirmationSender: {
        entity: 'organization',
        entityId: null,
      },

      components: [
        {
          input: true,
          type: 'button',
          key: 'submit',
          action: 'submit',
          label: gettextCatalog.getString('Send'),
          theme: 'success',
          size: 'md',
          leftIcon: '',
          rightIcon: '',
          block: true,
          tableView: false,
          disableOnInvalid: false,
          churchdesk: {
            notRemovable: true,
          },
        },
      ],
    };

    /**
     * Get the preview URL of a form
     */
    FormConstructor.prototype.getUrl = function () {
      return cdApp.references.formViewer + '/f/' + this.path;
    };

    /**
     * Duplicate a form
     */
    FormConstructor.prototype.duplicate = function () {
      return $http
        .post(cdApp.config.api.forms + '/forms/' + this.id + '/duplicate', {
          title:
            this.title +
            ' (' +
            gettextCatalog.getString('copy', null, 'Noun') +
            ')',
        })
        .then(function (response) {
          toastr.success(gettextCatalog.getString('Form copied.'));
          return response;
        });
    };

    /**
     * Open a form
     */
    FormConstructor.prototype.open = function () {
      this.active = true;
      this.$save(function () {
        toastr.success(gettextCatalog.getString('Form opened successfully.'));
        $state.reload();
      });
    };

    /**
     * Close a form
     */
    FormConstructor.prototype.close = function () {
      let form = this;
      form.active = false;
      form.$save(function () {
        form.status = 'closed';
        toastr.success(gettextCatalog.getString('Form closed successfully.'));
      });
    };

    /**
     * The status of a `closed` form could be more than one value depending on the reason why the form is closed
     */
    const formIsClosed = (status) =>
      _.includes(['closed', 'expired', 'charging-disabled'], status);

    /**
     * Whether a form can be opened
     */
    FormConstructor.prototype.canBeOpened = function () {
      return this.active === false && formIsClosed(this.status);
    };

    /**
     * Whether a form can be closed
     */
    FormConstructor.prototype.canBeClosed = function () {
      return this.active === true && !formIsClosed(this.status);
    };

    /**
     * Delete a form
     *
     * @param {Function} callback
     */
    FormConstructor.prototype.delete = function () {
      const _form = this;

      return $uibModal
        .open({
          component: 'cdSimpleModal',
          resolve: {
            title() {
              return gettextCatalog.getString('Delete "{{formTitle}}"', {
                formTitle: _form.title,
              });
            },
            body() {
              const responseCount = _form.responseCount;
              if (!responseCount) {
                return gettextCatalog.getString(
                  'Are you sure you want to delete "{{formTitle}}"?',
                  { formTitle: _form.title }
                );
              }
              return gettextCatalog.getString(
                'Are you sure you want to delete "{{formTitle}}" and the {{responseCount}} responses from it?',
                { formTitle: _form.title, responseCount }
              );
            },
            options: {
              confirmButtonText: gettextCatalog.getString('Delete form'),
              closeButtonText: gettextCatalog.getString('Cancel'),
              confirmButtonType: 'danger',
            },
          },
        })
        .result.then(() => {
          let deferred = $q.defer();

          _form.$delete(
            () => {
              toastr.success(
                gettextCatalog.getString('The form was successfully deleted.')
              );

              deferred.resolve();
            },
            (error) => {
              toastr.error(
                _.get(error, 'data.message') ||
                  gettextCatalog.getString('The form could not be deleted.')
              );

              deferred.reject(error);
            }
          );

          return deferred.promise;
        });
    };

    /**
     * Return a pristine Form
     */
    FormConstructor.init = function () {
      return new FormConstructor(angular.copy(empty));
    };

    /**
     * Filter components with a predicate, and return a flattened array of the components that
     * return satisfy the predicate, along with a path property that includes the container's key for sub-components
     *
     * @param {Object[]} components the list of components to filter (could include sub-components)
     * @param {function} filterBy the function invoked per iteration
     */
    function filterFormComponents(components, filterBy) {
      return _(components)
        .flatMapDepth((component) => {
          if (!component.components) {
            return _.extend({ path: component.key }, component);
          }
          return _.map(component.components, (subComponent) =>
            _.extend(
              { path: `${component.key}.${subComponent.key}` },
              subComponent
            )
          );
        }, 1)
        .filter(filterBy)
        .value();
    }

    /**
     * Get the ticket components within a form
     */
    FormConstructor.prototype.getTicketComponents = function () {
      return filterFormComponents(this.components, (component) =>
        _.get(component, 'churchdesk.ticket')
      );
    };

    FormConstructor.prototype.hasTicketsWithPrice = function () {
      return _.some(
        this.getTicketComponents(),
        (ticket) => _.get(ticket, 'churchdesk.ticket.price') > 0
      );
    };

    FormConstructor.prototype.syncSubmissions = function () {
      return $http
        .post(cdApp.config.api.forms + '/forms/' + this.id + '/sync', {})
        .then(function () {
          toastr.success(
            gettextCatalog.getString('Form submissions resynced to people.')
          );

          return;
        });
    };

    /**
     * Count the number of contains people sections for the current form.
     */
    FormConstructor.prototype.countFormPeopleSections = function () {
      return _.size(_.filter(this.components, { type: 'person' }));
    };

    /**
     * Get the people sections within a form.
     */
    FormConstructor.prototype.getFormPeopleSections = function () {
      return $http.get(
        `${cdApp.config.api.forms}/people-sections/forms/${this.id}`
      );
    };

    return FormConstructor;
  }

  function FormResponses(
    $$resource,
    appUtils,
    gettextCatalog,
    $uibModal,
    toastr,
    $q
  ) {
    let FormResponseConstructor = $$resource(
      cdApp.config.api.forms + '/forms/:formId/submissions/:id',
      {
        formId: '@formId',
      }
    );

    /**
     * Return a formatted array of responses based on the type of the form component
     *
     * @param {Object} form
     * @param {Array} responses
     *
     * @returns {Array} The list of formatted responses
     */
    FormResponseConstructor.formatResponses = function (form, responses) {
      let formattedResponses = angular.copy(responses);

      _.each(formattedResponses, function (response) {
        response.data = _.mapValues(response.data, function (value, key) {
          let correspondingColumn = _.find(form.components, { key: key });
          if (!correspondingColumn) return;

          // nested components
          if (correspondingColumn.components) {
            return _.mapValues(value, function (subValue, subKey) {
              let deepCorrespondingColumn = _.find(
                correspondingColumn.components,
                { key: subKey }
              );

              if (!deepCorrespondingColumn) return;
              return appUtils.formatSubmission(
                subValue,
                deepCorrespondingColumn,
                false
              );
            });
          }

          // normal component
          return appUtils.formatSubmission(value, correspondingColumn, false);
        });
      });

      return formattedResponses;
    };

    /**
     * Filter out file submissions from the current submission.
     */
    FormResponseConstructor.filterFileSubmissions = (
      responseId,
      form,
      responses
    ) => {
      const response = _.find(responses, { id: responseId });
      // if the response does not exist return empty.
      if (!response) return {};
      const files = _.mapValues(response.data, (value, key) => {
        let correspondingColumn = _.find(form.components, { key });
        if (!correspondingColumn) return;

        // If has not child component and is not of type file return.
        if (
          !correspondingColumn.components &&
          correspondingColumn.type !== 'file'
        ) {
          return;
        }

        // nested components
        if (correspondingColumn.components) {
          return _.mapValues(value, function (subValue, subKey) {
            let deepCorrespondingColumn = _.find(
              correspondingColumn.components,
              { key: subKey }
            );

            if (
              !deepCorrespondingColumn ||
              deepCorrespondingColumn.type !== 'file'
            ) {
              return;
            }
            return subValue;
          });
        }

        // normal component
        return value;
      });

      // Remove properties with undefined values, in other case remove those that are not related to file.
      return _.pickBy(files, _.identity);
    };

    /**
     * Open a modal to confirm deleting a form's response
     *
     * @returns {Promise} The promise is resolved if the user confirms and the response is deleted
     */
    FormResponseConstructor.prototype.confirmDelete = function () {
      return $q((resolve) => {
        $uibModal
          .open({
            component: 'cdSimpleModal',
            resolve: {
              title() {
                return gettextCatalog.getString('Delete response');
              },
              body() {
                return gettextCatalog.getString(
                  'Are you sure you want to delete this response?'
                );
              },
              options: {
                confirmButtonText: gettextCatalog.getString('Delete response'),
                closeButtonText: gettextCatalog.getString('Cancel'),
                confirmButtonType: 'danger',
              },
            },
          })
          .result.then(() => {
            this.$delete(() => {
              toastr.success(
                gettextCatalog.getString('Response deleted successfully.')
              );

              resolve();
            });
          });
      });
    };

    return FormResponseConstructor;
  }

  angular
    .module('cdApp.shared')
    .factory('Forms', Forms)
    .factory('FormResponses', FormResponses);
})();
