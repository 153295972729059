import { selector } from 'recoil';

import { OrderOfServiceTemplate } from '../../types/orderOfServiceTypes';

import { mainApi } from '@/react/api';

export const OrderOfServiceTemplatesQuery = selector<OrderOfServiceTemplate[]>({
  key: 'OrderOfServiceTemplatesQuery',
  get: async () => {
    const { ok, data } = await mainApi.get<OrderOfServiceTemplate[]>(
      `/calendar/order-of-service/templates`
    );
    if (ok) {
      return data;
    }
  },
});
