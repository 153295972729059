(function () {
  'use strict';
  Jobs.$inject = ['$$resource'];

  function Jobs($$resource) {
    return $$resource(cdApp.config.api.main + '/users/jobs');
  }

  angular.module('cdApp.shared').factory('Jobs', Jobs);
})();
