/* eslint-disable destructuring/no-rename */
import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { all, takeLatest } from 'redux-saga/effects';

import { AppState } from '../../../redux';
import { withLoadingAndErrors } from '../../loading/saga';
import { Group } from '../../services/GroupsService';

import loadGroupsSaga from './sagas/loadGroups';

const groupAdapter = createEntityAdapter<Group>({
  selectId: (group) => group.id,
});

export type GroupsState = EntityState<Group>;
const initialState: GroupsState = groupAdapter.getInitialState();
const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    loadGroups() {},
    groupsLoaded: groupAdapter.setAll,
  },
});

export const groupsReducer = groupsSlice.reducer;

export const { loadGroups, groupsLoaded } = groupsSlice.actions;

const selectors = groupAdapter.getSelectors(
  (state: AppState) => state.shared.groups
);

export const {
  selectAll: selectAllGroups,
  selectById: selectGroupById,
  selectIds: selectGroupIds,
  selectIds: selectGroupsIds,
} = selectors;

export function* rootGroupSaga() {
  yield all([
    takeLatest(loadGroups.type, withLoadingAndErrors('groups', loadGroupsSaga)),
  ]);
}
