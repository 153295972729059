import moment from 'moment';

import { DateInputValue } from '@/react/calendar/event-details/components/date-input/DateInput';

export const hasRepeatSettingsChanged = (
  event,
  formEventDate: DateInputValue
) => {
  let prevEventEndDate = moment(event?.endDate);
  let currentStartDate = moment(formEventDate?.startDate);
  let currentEndDate = moment(formEventDate?.endDate);

  if (event?.allDay) {
    prevEventEndDate = moment(prevEventEndDate).endOf('day');
    currentStartDate = moment(formEventDate.startDate).startOf('day');
    currentEndDate = moment(formEventDate.endDate).endOf('day');
  }
  return event?.id
    ? !prevEventEndDate.isSame(currentEndDate) ||
        !moment(event.startDate).isSame(currentStartDate) ||
        event.rrule !== formEventDate.rrule ||
        event.allDay !== formEventDate.allDay
    : formEventDate.rrule !== undefined;
};
