'use strict';

import { FoundationTypes } from '../../react/intention/models/foundation';

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.private.intention', {
        abstract: true,
        url: '/intention',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
        resolve: {
          previousState: [
            '$state',
            function ($state) {
              return {
                name: $state.current.name,
                params: $state.params,
                url: $state.href($state.current.name, $state.params),
              };
            },
          ],
        },

        lazyLoad: ($transition$) => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad');
          return import(
            /* webpackChunkName: "intentionen.module" */ './intentionen.module.js'
          )
            .then((mod) => $ocLazyLoad.load(mod.default))
            .catch((err) => console.error(err));
        },
      })

      // Intentions
      .state('app.private.intention.overview', {
        url: '',
        component: 'cdrIntentions',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Intentionen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.assign', {
        url: '/assign/:id',
        component: 'cdrAssignIntention',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Intentionen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.create', {
        url: '/create?{calendarId}',
        component: 'cdrCreateIntention',
        params: {
          currentState: {
            value: 'create',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Intentionen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.edit', {
        url: '/edit/:id?{calendarId}&{legatId}&{stiftungId}',
        component: 'cdrCreateIntention',
        params: {
          currentState: {
            value: 'edit',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Intentionen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.billing', {
        url: '/billing',
        component: 'cdrIntentionsBilling',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Intentionen');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Legacies
      .state('app.private.intention.legate', {
        abstract: true,
        url: '/legate',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.intention.legate.overview', {
        url: '/?{foundationType:string}',
        component: 'cdrFoundations',
        params: {
          foundationType: {
            value: FoundationTypes.LEGATE,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Legacies');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.legate.create', {
        url: '/create',
        component: 'cdrCreateLegat',
        params: {
          currentState: {
            value: 'create',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Legacies');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.legate.edit', {
        url: '/edit/:id',
        component: 'cdrCreateLegat',
        params: {
          currentState: {
            value: 'edit',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Legacies');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Stipend
      .state('app.private.intention.stiftungen', {
        abstract: true,
        url: '/stiftungen',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.intention.stiftungen.overview', {
        url: '/?{foundationType:string}',
        component: 'cdrFoundations',
        params: {
          foundationType: {
            value: FoundationTypes.STIFTUNGEN,
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Stipend');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.stiftungen.create', {
        url: '/create',
        component: 'cdrCreateStiftung',
        params: {
          currentState: {
            value: 'create',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Stipend');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.stiftungen.edit', {
        url: '/edit/:id?{currentState}',
        component: 'cdrCreateStiftung',
        params: {
          currentState: {
            value: 'edit',
            dynamic: true,
            squash: true,
          },
        },

        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Stipend');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Stoles
      .state('app.private.intention.stoles', {
        abstract: true,
        url: '/stoles',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.intention.stoles.overview', {
        url: '/',
        component: 'cdrStoles',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Stoles');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })
      .state('app.private.intention.stoles.billing', {
        url: '/billing',
        component: 'cdrStolesBilling',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Stoles');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Reports
      .state('app.private.intention.reports', {
        abstract: true,
        url: '/reports',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })

      // Report templates
      .state('app.private.intention.reports.orderOfService', {
        url: '/order-of-service',
        component: 'cdReportOrderOfService',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Gottesdienstordnung');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      })

      // Event intentions
      .state('app.private.intention.eventIntentions', {
        url: '/event/:id/intentions',
        component: 'cdrManageIntentions',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Manage Intentions');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
        },
      });
  },
]);
