'use strict';

function Dictionary($$resource, cdApp) {
  'ngInject';

  const baseUrl = `${cdApp.config.api.main}/dictionaries`;

  return $$resource(
    baseUrl,
    {},
    {
      getByCountry: {
        method: 'GET',
        url: `${baseUrl}/by-country`,
        isArray: true,
      },
    }
  );
}
Dictionary.$inject = ['$$resource', 'cdApp'];

angular.module('cdApp.shared').factory('Dictionary', Dictionary);
