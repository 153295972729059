import { ApiResponse } from 'apisauce';

import { API } from '../api';
import { SearchType } from '../store/actions/filearchive';

export const loadFileArchiveFiles = async (options: {
  folderId?: number | string;
  limit: number;
  offset: number;
  search?: SearchType;
  imagesOnly?: boolean;
  orderBy?: string;
  orderDirection?: string;
}) => {
  const {
    folderId,
    limit,
    offset,
    search,
    imagesOnly,
    orderBy,
    orderDirection,
  } = options;

  const response: ApiResponse<any> = await API.get('/filesystem', {
    folderId,
    length: limit,
    start: offset,
    search: search && search.value,
    imagesOnly,
    orderBy,
    orderDirection,
  });

  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};

export const loadFileArchiveBreadCrumb = async (folderId: number) => {
  const response: ApiResponse<any> = await API.get('/filesystem/breadcrumb', {
    folderId,
  });
  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};

export const fileArchiveUpdateFile = async (fileId, file) => {
  const response: ApiResponse<any> = await API.put(`/files/${fileId}`, file);
  if (response.ok) {
    return;
  } else {
    throw response.originalError;
  }
};

export const fileArchiveDeleteFile = async (fileId) => {
  const response: ApiResponse<any> = await API.delete(`/files/${fileId}`);
  if (response.ok) {
    return;
  } else {
    throw response.originalError;
  }
};

export const fileArchiveGetTags = async () => {
  const response: ApiResponse<any> = await API.get(`/files/tags`);
  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};

export const fileArchiveCreateFolder = async (payload) => {
  const response: ApiResponse<any> = await API.post('/folders', {
    ...payload,
  });
  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};
