import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DetailedEvent } from '../../models/calendar';
import { Intention } from '../../../intention/models/intention';
import { Stole } from '../../../intention/models/stole';
import { AppState } from '../../../redux';
import { createActionDispatcherHook } from '../../../redux/utils';

const eventsDetailsAdapter = createEntityAdapter<DetailedEvent>({
  selectId: (event) => event?.id,
});

// EntityState<DetailedEvent>

export interface EventsDetailsState {
  events: EntityState<DetailedEvent>;
  eventIntentions: { [id: number]: Intention[] };
  eventStole: { [id: number]: Stole };
}

const initialState: EventsDetailsState = {
  events: eventsDetailsAdapter.getInitialState(),
  eventIntentions: {},
  eventStole: {},
};

const eventsDetailsSlice = createSlice({
  name: 'eventsDetails',
  initialState: initialState,
  reducers: {
    eventDetailsFetched(
      state: EventsDetailsState,
      { payload }: PayloadAction<DetailedEvent>
    ) {
      state.events = eventsDetailsAdapter.upsertOne(state.events, payload);
    },
    fetchEventIntentionsSuccess(
      state: EventsDetailsState,
      {
        payload,
      }: PayloadAction<{ id: number; intentions: Intention[]; stole: Stole }>
    ) {
      const { id, intentions, stole } = payload;
      state.eventIntentions[id] = intentions;
      state.eventStole[id] = stole;
    },
  },
});

export const eventsDetailsReducer = eventsDetailsSlice.reducer;

export const eventsDetailsActions = {
  ...eventsDetailsSlice.actions,
  changeAttendingStatus: createAction<{
    eventId: number;
    eventType: string;
    status: string;
  }>('eventsDetails/changeAttendingStatus'),
};

const rootSelector = (state: AppState) => state.calendar.eventsDetails;

export const eventsDetailsSelectors = {
  selectEventIntentions: (id: number) =>
    createSelector(
      rootSelector,
      (eventsDetails) => eventsDetails.eventIntentions[id]
    ),
  selectEventStole: (id: number) =>
    createSelector(
      rootSelector,
      (eventsDetails) => eventsDetails.eventStole[id]
    ),
};

export const eventsDetailsHooks = {
  useChangeAttendingStatus: createActionDispatcherHook(
    eventsDetailsActions.changeAttendingStatus
  ),
};
