import { launchFilePicker } from '@/react/angular/ReactModalBridge';

(function () {
  'use strict';

  function imagePickerFactory() {
    return function (callback) {
      window.addEventListener('message', (event) => {
        const messageType = event.data?.message;
        const isUsedFromEditor = event.data?.data?.isUsedFromEditor;
        if (
          messageType === 'FilePickerComponentModalCallback' &&
          !isUsedFromEditor
        ) {
          const { file, imageFormat } = event.data?.data;
          const format = imageFormat || 'medium';
          const url = file.fileInfo[format].url;
          callback({
            file,
            size: format,
            url,
          });
        }
      });
      const instance = {
        response: undefined,
        open: function () {
          launchFilePicker(false);
        },
      };

      return instance;
    };
  }

  angular
    .module('cdApp.shared')
    .factory('imagePickerFactory', imagePickerFactory)
    .directive('cdImagePicker', [
      '$parse',
      'imagePickerFactory',
      function ($parse, imagePickerFactory) {
        return function ($scope, $element, attrs) {
          let model = $parse(attrs.cdImagePicker);
          let modal = imagePickerFactory(function (data) {
            $scope.$apply(() => {
              model.assign($scope, data);
            });
          });

          $element.on('click', function () {
            modal.open();
          });
        };
      },
    ]);
})();
