import React from 'react';

import { CdCircleFilled } from './Icons';
import CdTooltip from './cd-tooltip/CdTooltip';
import { CdVerticalSpace } from './cd-vertical-space/CdVerticalSpace';

export interface ModuleRedDotProps {
  notifications?: string[];
}

export const NotificationIndicator = (props: ModuleRedDotProps) => (
  <CdTooltip
    title={<CdVerticalSpace size={2}>{props.notifications}</CdVerticalSpace>}
    placement="right"
  >
    <div style={{ display: 'flex' }}>
      <CdCircleFilled type="danger" style={{ fontSize: '0.75rem' }} />
    </div>
  </CdTooltip>
);
