import { Form } from 'antd';
import { useEffect } from 'react';

import {
  ModalType,
  SetModalPropsType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { gettextCatalog } from '../../services/I18nService';
import cdApp from '../../config';
import { showConfirmModal } from '../../shared/components/cd-confirm-modal/CdConfirmModal';
import { mainApi } from '../../api';
import NotificationService from '../../services/NotificationService';

export const useAcceptLegalTermsModal = (setModalProps: SetModalPropsType) => {
  const [form] = Form.useForm();
  useEffect(() => {
    setModalProps({
      modalType: ModalType.POPUP,
      title: gettextCatalog.getString('Where is your church located?'),
      width: 500,
      onOk: async () =>
        form.validateFields().then(async (values: { country: string }) => {
          const selectedCountry = values.country;

          if (selectedCountry !== cdApp.organization.countryIso2) {
            const { resolved } = await showConfirmModal({
              title: gettextCatalog.getString(''),
              message: gettextCatalog.getString(
                'You selected a different country ({{ selectedCountry }}) than the country of your organization ({{ orgCountry }}). Are you sure you want to continue?',
                {
                  selectedCountry: selectedCountry.toUpperCase(),
                  orgCountry: cdApp.organization.countryIso2.toUpperCase(),
                },
                'Selecting the country for the organization in Stripe'
              ),
              okText: gettextCatalog.getString('Continue'),
            });
            if (!resolved) throw new Error();
          }

          const response = await mainApi.post('/organizations/account', {
            country: selectedCountry,
            tosAcceptance: true,
          });

          if (response.ok) {
            NotificationService.notifySuccess(
              gettextCatalog.getString('Terms of service accepted')
            );
          }
        }),
      okText: gettextCatalog.getString('Confirm'),
    });
  }, [form, setModalProps]);

  return { form };
};
