(() => {
  'use strict';

  function orderObjectBy(_) {
    'ngInject';

    return (itemsObj, field, reverse) => {
      let itemsArray = [];

      angular.forEach(itemsObj, (item, key) => {
        item.key = key;
        itemsArray.push(item);
      });

      return _.orderBy(
        itemsArray,
        (item) => {
          const value = item[field];
          return _.isString(value) ? _.lowerCase(value) : value;
        },
        reverse ? 'desc' : 'asc'
      );
    };
  }
  orderObjectBy.$inject = ['_'];

  angular.module('cdApp.shared').filter('orderObjectBy', orderObjectBy);
})();
