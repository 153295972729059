import DownOutlined from '@ant-design/icons/DownOutlined';
import {
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
  faCalendarDay,
  faCalendarWeek,
  faCheck,
  faClock,
  faExchange,
  faPrint,
  faRss,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Dropdown,
  Menu,
  MenuProps,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { AppState } from '../../redux';
import AuthorizationService from '../../services/AuthorizationService';
import { gettextCatalog } from '../../services/I18nService';
import StateServiceFactory, {
  navigate,
} from '../../services/StateServiceFactory';
import CalendarViewService from '../services/CalendarViewService';
import { useIsMinimizedView } from '../store/absences/absencesSlice';
import {
  calendarSettingsHooks,
  calendarSettingsSelectors,
} from '../store/calendar-settings/calendarSettingsSlice';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { CalendarEventColoringCriteria, TimePeriod } from '../store/main-view';
import {
  ChangeTimePeriodAction,
  GoToDateAction,
  SetCalendarEventColoringCriteria,
  useToggleResourceView,
  useToggleShowIntentions,
  useToggleShowDeclinedEvents,
  useToggleShowPrepTime,
  useToggleShowResources,
} from '../store/main-view/Actions';
import { getCalendarView } from '../store/main-view/Selectors';

import { CalendarCreateDropdown } from './CalendarCreateDropdown';
import { showCalendarSubscribeModal } from './CalendarSubscribeModal';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const { Text } = Typography;

const iconMixin = css<{ checked: boolean }>`
  ${({ checked }) => {
    if (checked) {
      return css`
        opacity: 1;
      `;
    }
    return css`
      opacity: 0;
    `;
  }}
`;

const StyledMenuGroup = styled(Menu.ItemGroup)`
  &&&& {
    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }
`;

const StyledCheckedMenuItem = styled(Menu.Item)`
  &&&& {
    .fa-check {
      margin-right: 10px;
      ${iconMixin}
    }
  }
`;

const StyledDownOutlined = styled(DownOutlined)`
  &&&& {
    vertical-align: baseline;

    svg {
      max-width: 10px;
      height: auto;
    }
  }
`;

const buttonSize = 'middle';

const moveDate = (
  timePeriod: TimePeriod,
  selectedDate: Date,
  amountToGo: number
) => {
  switch (timePeriod) {
    case TimePeriod.Week:
      return moment(selectedDate).add(amountToGo, 'week').toDate();
    case TimePeriod.Month:
      return moment(selectedDate).add(amountToGo, 'month').toDate();
    case TimePeriod.Day:
      return moment(selectedDate).add(amountToGo, 'day').toDate();
  }
};

const CalendarControl: FunctionComponent = () => {
  const dispatch = useDispatch();
  const hasIntentions = AuthorizationService.hasPackage('intentions');
  const isCalendarSwitchAllowed = useSelector(
    calendarSettingsSelectors.getIsCalendarSwitchAllowed
  );
  const stateService = StateServiceFactory();
  const timePeriod = useSelector(
    (state: AppState): TimePeriod => state.calendar.view.timePeriod
  );
  const showResources = useSelector(
    (state: AppState): boolean => state.calendar.view.showResources
  );
  const showPrepTime = useSelector(
    (state: AppState): boolean => state.calendar.view.showPrepTime
  );
  const showIntentions = useSelector(
    (state: AppState): boolean => state.calendar.view.showIntentions
  );
  const showResourceView = useSelector(
    (state: AppState): boolean => state.calendar.view.showResourceView
  );
  const eventColoringCriteria = useSelector(
    (state: AppState): CalendarEventColoringCriteria =>
      state.calendar.view.eventColoringCriteria
  );
  const showDeclinedEvents = useSelector(
    (state: AppState): boolean => state.calendar.view.showDeclinedEvents
  );
  const toggleCalendarView = calendarSettingsHooks.useToggleCalendarView();
  const toggleShowResources = useToggleShowResources();
  const toggleShowPrepTime = useToggleShowPrepTime();
  const toggleShowIntentions = useToggleShowIntentions();
  const toggleShowDeclinedEvents = useToggleShowDeclinedEvents();
  const toggleResourceView = useToggleResourceView();
  const [isTheViewMinimized, toggleIsMinimizedView] = useIsMinimizedView();
  const isUserFiltersLoaded = useSelector(selectIsUserFiltersLoaded);
  const selectedDate = useSelector(
    (state: AppState) =>
      state.calendar.view.selectedDate
        ? (state.calendar.view.selectedDate as unknown as Date)
        : new Date(),
    _.isEqual
  );
  const currentView = useSelector(getCalendarView);
  const [title, changeTitle] = useState(CalendarViewService.title);

  useEffect(() => {
    CalendarViewService.getViewType()
      .then((view) => {
        if (currentView === view) return;
        return CalendarViewService.changeView(currentView);
      })
      .then(() => changeTitle(CalendarViewService.title));
  }, [changeTitle, currentView]);

  useEffect(() => {
    CalendarViewService.gotoDate(selectedDate).then(() =>
      changeTitle(CalendarViewService.title)
    );
  }, [selectedDate, changeTitle]);

  const onGoNext = useCallback(() => {
    CalendarViewService.next().then(() => {
      changeTitle(CalendarViewService.title);
      dispatch(GoToDateAction(moveDate(timePeriod, selectedDate, 1)));
    });
  }, [changeTitle, selectedDate, timePeriod, dispatch]);

  const onGoPrev = useCallback(() => {
    CalendarViewService.prev().then(() => {
      changeTitle(CalendarViewService.title);
      dispatch(GoToDateAction(moveDate(timePeriod, selectedDate, -1)));
    });
  }, [changeTitle, selectedDate, timePeriod, dispatch]);

  const onGoToday = useCallback(() => {
    CalendarViewService.toDay().then(() => {
      changeTitle(CalendarViewService.title);
      dispatch(GoToDateAction(new Date()));
    });
  }, [changeTitle, dispatch]);

  const goToDayView = useCallback(
    (showResourceDayView) => {
      if (showResourceDayView) {
        CalendarViewService.changeView('resourceTimeGridDay').then(() =>
          dispatch(ChangeTimePeriodAction(TimePeriod.Day))
        );
      } else {
        CalendarViewService.changeView('timeGridDay').then(() =>
          dispatch(ChangeTimePeriodAction(TimePeriod.Day))
        );
      }
    },
    [dispatch]
  );

  const goToWeekView = useCallback((): void => {
    dispatch(ChangeTimePeriodAction(TimePeriod.Week));
  }, [dispatch]);

  const goToMonthView = useCallback((): void => {
    dispatch(ChangeTimePeriodAction(TimePeriod.Month));
  }, [dispatch]);

  const changeResourceView = async () => {
    if (timePeriod === TimePeriod.Day) {
      goToDayView(!showResourceView);
    }
    toggleResourceView();
  };

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.target['nodeName'] === 'INPUT' ||
        event.target['nodeName'] === 'TEXTAREA'
      ) {
        return;
      }
      if ((event.target as HTMLElement).classList.contains('redactor-layer')) {
        return;
      }
      // if user press Numpad1 go to day
      if (event.keyCode === 49) {
        goToDayView(showResourceView);
        return;
      }
      // if user press Numpad2 go to week
      if (event.keyCode === 50) {
        goToWeekView();
        return;
      }
      // if user press Numpad3  go to month
      if (event.keyCode === 51) {
        goToMonthView();
        return;
      }
    },
    [goToDayView, goToMonthView, goToWeekView, showResourceView]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const getCurrentViewLabel = () => {
    switch (timePeriod) {
      case TimePeriod.Day:
        return (
          <div>
            <FontAwesomeIcon icon={faCalendarDay} className="u-mr-5" />{' '}
            {gettextCatalog.getString('Day')} <StyledDownOutlined />
          </div>
        );
      case TimePeriod.Week:
        return (
          <div>
            <FontAwesomeIcon icon={faCalendarWeek} className="u-mr-5" />{' '}
            {gettextCatalog.getString('Week')} <StyledDownOutlined />
          </div>
        );
      case TimePeriod.Month:
        return (
          <div>
            <FontAwesomeIcon icon={faCalendarAlt} className="u-mr-5" />{' '}
            {gettextCatalog.getString('Month')} <StyledDownOutlined />
          </div>
        );
      default:
        return;
    }
  };
  const items: MenuProps['items'] = [
    {
      key: 'day',
      icon: <FontAwesomeIcon icon={faCalendarDay} className="u-mr-10" />,
      onClick: () => goToDayView(showResourceView),
      label: (
        <Space
          style={{
            width: '85%',
            justifyContent: 'space-between',
          }}
        >
          {gettextCatalog.getString('Day')}
          <CdTooltip title="Type 1 for shortcut">
            <Text type="secondary">{gettextCatalog.getString('1')}</Text>
          </CdTooltip>
        </Space>
      ),
    },
    {
      key: 'week',
      icon: <FontAwesomeIcon icon={faCalendarWeek} className="u-mr-10" />,
      onClick: goToWeekView,
      label: (
        <Space
          style={{
            width: '85%',
            justifyContent: 'space-between',
          }}
        >
          {gettextCatalog.getString('Week')}
          <CdTooltip title="Type 2 for shortcut">
            <Text type="secondary">{gettextCatalog.getString('2')}</Text>
          </CdTooltip>
        </Space>
      ),
    },
    {
      key: 'month',
      icon: <FontAwesomeIcon icon={faCalendarAlt} className="u-mr-10" />,
      onClick: goToMonthView,
      label: (
        <Space
          style={{
            width: '85%',
            justifyContent: 'space-between',
          }}
        >
          {gettextCatalog.getString('Month')}
          <CdTooltip title="Type 3 for shortcut">
            <Text type="secondary">{gettextCatalog.getString('3')}</Text>
          </CdTooltip>
        </Space>
      ),
    },
  ];

  return (
    <Row style={{ margin: '10px 10px 0' }}>
      <Col>
        <Row justify="start">
          <Col className="hidden-print">
            <Space size={5}>
              <CalendarCreateDropdown />
              {isUserFiltersLoaded ? (
                <>
                  <Button
                    size={buttonSize}
                    className="hidden-print"
                    onClick={onGoToday}
                  >
                    {gettextCatalog.getString('Today')}
                  </Button>

                  <Button
                    size={buttonSize}
                    className="hidden-print"
                    onClick={onGoPrev}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>
                  <Button
                    size={buttonSize}
                    className="hidden-print"
                    onClick={onGoNext}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                  <Text
                    style={{ textTransform: 'uppercase', marginLeft: 8 }}
                    className="calendar-view-title"
                  >
                    {title}
                  </Text>
                </>
              ) : null}
            </Space>
          </Col>
          <Text
            className="show-print calendar-view-title"
            style={{ textTransform: 'uppercase', marginLeft: 8 }}
          >
            {title}
          </Text>
        </Row>
      </Col>

      {isUserFiltersLoaded ? (
        <Col flex="auto" className="hidden-print">
          <Row justify="end">
            <Col>
              <Space size={5}>
                {timePeriod === TimePeriod.Day ? (
                  <Space>
                    {gettextCatalog.getString('Users & Rooms side-by-side')}
                    <Switch
                      checked={showResourceView}
                      onChange={() => changeResourceView()}
                    />{' '}
                  </Space>
                ) : null}
                <Dropdown
                  trigger={['click']}
                  placement="bottomRight"
                  menu={{ items }}
                >
                  <Button size={buttonSize}>{getCurrentViewLabel()}</Button>
                </Dropdown>
                <Button
                  type="default"
                  size={buttonSize}
                  onClick={(): void => {
                    stateService.go('app.private.calendar.print');
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} className="u-mr-5" />
                  {gettextCatalog.getString('Search')}
                </Button>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu style={{ paddingBottom: 0 }}>
                      {/* Calendar view options */}
                      <StyledMenuGroup
                        key="g1"
                        title={gettextCatalog.getString('More event details')}
                      >
                        <StyledCheckedMenuItem
                          key="colorEventsByResource"
                          onClick={() =>
                            eventColoringCriteria ===
                            CalendarEventColoringCriteria.Resource
                              ? dispatch(
                                  SetCalendarEventColoringCriteria(
                                    CalendarEventColoringCriteria.Category
                                  )
                                )
                              : dispatch(
                                  SetCalendarEventColoringCriteria(
                                    CalendarEventColoringCriteria.Resource
                                  )
                                )
                          }
                          checked={
                            eventColoringCriteria ===
                            CalendarEventColoringCriteria.Resource
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {gettextCatalog.getString('Color events by resource')}
                        </StyledCheckedMenuItem>
                        <StyledCheckedMenuItem
                          key="showBookedResources"
                          onClick={toggleShowResources}
                          checked={showResources}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {gettextCatalog.getString('Show booked resources')}
                        </StyledCheckedMenuItem>
                        <StyledCheckedMenuItem
                          onClick={toggleShowPrepTime}
                          checked={showPrepTime}
                          key="showPrepClean"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {gettextCatalog.getString('Show prep/clean up time')}
                        </StyledCheckedMenuItem>

                        <StyledCheckedMenuItem
                          onClick={toggleIsMinimizedView}
                          checked={isTheViewMinimized as boolean}
                          key="groupAbsences"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {gettextCatalog.getString('Group absences')}
                        </StyledCheckedMenuItem>
                        {hasIntentions ? (
                          <StyledCheckedMenuItem
                            onClick={toggleShowIntentions}
                            checked={showIntentions}
                            key="showIntentions"
                          >
                            <FontAwesomeIcon icon={faCheck} />
                            {gettextCatalog.getString('Show intentions')}
                          </StyledCheckedMenuItem>
                        ) : null}
                        <StyledCheckedMenuItem
                          onClick={toggleShowDeclinedEvents}
                          checked={showDeclinedEvents}
                          key="showDeclinedEvents"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {gettextCatalog.getString('Show declined events')}
                        </StyledCheckedMenuItem>
                      </StyledMenuGroup>
                      {/* Divider */}
                      <Menu.Divider />
                      {/* Calendar tools */}
                      <Menu.Item
                        key="tools-1"
                        onClick={() => navigate('app.private.calendar.changes')}
                      >
                        <FontAwesomeIcon icon={faClock} className="u-mr-5" />
                        {'\u00A0'}
                        {gettextCatalog.getString('Calendar changes')}
                      </Menu.Item>
                      <Menu.Item
                        key="tools-2"
                        onClick={() => navigate('app.private.calendar.print')}
                      >
                        <FontAwesomeIcon icon={faPrint} className="u-mr-5" />
                        {'\u00A0'}
                        {gettextCatalog.getString('Report & Print')}
                      </Menu.Item>
                      <Menu.Item
                        key="tools-3"
                        onClick={() =>
                          showCalendarSubscribeModal({ feedType: 'all' })
                        }
                      >
                        <FontAwesomeIcon icon={faRss} className="u-mr-5" />
                        {'\u00A0'}
                        {gettextCatalog.getString('Subscribe to calendar')}
                      </Menu.Item>

                      {isCalendarSwitchAllowed && (
                        <>
                          {/* Divider */}
                          <Menu.Divider style={{ marginBottom: 0 }} />

                          {/* Calendar Switcher */}
                          <Menu.Item
                            key="switcher"
                            onClick={toggleCalendarView}
                            style={{ padding: 0 }}
                          >
                            <Button
                              type="primary"
                              block
                              style={{ borderRadius: '0 0 4px 4px' }}
                            >
                              <FontAwesomeIcon
                                icon={faExchange}
                                className="u-mr-10"
                              />
                              {gettextCatalog.getString(
                                'Switch to old calendar'
                              )}
                            </Button>
                          </Menu.Item>
                        </>
                      )}
                    </Menu>
                  }
                >
                  <Button size={buttonSize}>
                    {gettextCatalog.getString('More')} <StyledDownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export default CalendarControl;
