import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  faPaintBrush,
  faPencilAlt,
  faRss,
  faSave,
  faTimes,
  faUsers,
  faCircle,
  faUser,
  faSquare,
  faPlusCircle,
  faShare,
  faSyncAlt,
  faEnvelope as faEnvelopeSolid,
  faExpandAlt,
  faCompressAlt,
  faPhone,
  faFilter,
  faCogs,
  faQrcode,
  faLink,
  faQuoteLeft,
  faUserClock,
  faChartLine,
  faFileInvoiceDollar,
  faChurch as faChurchSolid,
  faLock as faLockSolid,
  faLockOpen,
  faHomeAlt,
  faSync,
  faGraduationCap,
  faCalendarMinus,
  faHorizontalRule,
  faExclamationCircle as faExclamationCircleSolid,
  faAnalytics,
  faThumbtack,
  faUndoAlt,
  faExchange,
  faDownload as faDownloadSolid,
  faGift,
  faCube as faCubeSolid,
  faTabletAndroidAlt,
  faLightbulbOn,
  faCalendar,
  faFolderOpen,
  faBuildingColumns,
  faFilterSlash,
  faFilterCircleXmark,
  faCheckCircle as faCheckCircleSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCalendarPlus,
  faCheck,
  faCode,
  faCopy,
  faEye,
  faKey,
  faLockAlt,
  faSlash,
  faExclamationTriangle,
  faSlidersH,
  faColumns,
  faSearch,
  faDownload,
  faFileCsv,
  faFile,
  faFileExcel,
  faFileSpreadsheet,
  faTasksAlt,
  faFileAlt,
  faFolder,
  faCopyright,
  faUpload,
  faPaperclip,
  faSmile,
  faChurch,
  faPaperPlane,
  faClock,
  faDesktop,
  faMobile,
  faAt,
  faList,
  faListAlt,
  faExternalLink,
  faCalendarAlt,
  faPrint,
  faHistory,
  faImagePolaroid,
  faTrashAlt,
  faAlarmClock,
  faBullhorn,
  faPlus,
  faMinus,
  faUserPlus,
  faMoneyCheckEdit,
  faTags,
  faUserAlt,
  faWindow,
  faBuilding,
  faAlignJustify,
  faPresentation,
  faImage,
  faCropAlt,
  faTv,
  faMapMarkedAlt,
  faBell,
  faCalendarTimes,
  faCalendarStar,
  faBagsShopping,
  faCalendarDay,
  faUsers as faUsersRegular,
  faUser as faUserRegular,
  faCreditCard,
  faCircle as faCircleEmpty,
  faEdit,
  faCheckCircle,
  faTable,
  faQuestionCircle,
  faTimesCircle,
  faStickyNote,
  faFileUpload,
  faCube,
  faCubes,
  faBrowser,
  faNetworkWired,
  faSparkles,
  faRocketLaunch,
  faPlusSquare,
  faTasks,
  faMoneyBill,
  faRetweet,
  faComments,
  faFilePlus,
  faHandPointLeft,
  faGlobe,
  faEllipsisH,
  faCaretSquareDown,
  faUserSecret,
  faTablet,
  faCar,
  faBan,
  faRobot,
  faCircleExclamation,
  faInfoCircle,
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryLow,
  faBatteryEmpty,
  faBolt,
  faBoltSlash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faEnvelope,
  faPencilPaintbrush,
  faTelescope,
  faCircle as falCircle,
  faCalendarAlt as faCalendarAltLight,
  faExclamationCircle,
  faUserCircle,
  faGlobe as faGlobeLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faClock as faClockSolid,
  faSignOut,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {
  faFacebook,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';

// Usage: import Icons from '../../../Shared/components/Icons';
// <Icons.CdEditIcon />

export type IconType =
  | 'warning'
  | 'danger'
  | 'info'
  | 'success'
  | 'secondary'
  | 'churchdesk'
  | undefined;

interface CdIconProps
  extends Pick<
    FontAwesomeIconProps,
    'size' | 'transform' | 'onClick' | 'style' | 'fixedWidth'
  > {
  type?: IconType;
  animation?: 'beat' | 'spin';
}

interface CdIconInternalProps
  extends Pick<
    FontAwesomeIconProps,
    | 'size'
    | 'transform'
    | 'onClick'
    | 'style'
    | 'fixedWidth'
    | 'icon'
    | 'inverse'
  > {
  type?: IconType;
  animation?: 'beat' | 'spin';
}

export const WARNING_COLOR = '#faad14';
export const SUCCESS_COLOR = '#52c41a';
export const INFO_COLOR = '#1677ff';
export const ERROR_COLOR = '#ff4d4f';
export const SECONDARY_COLOR = `rgba(0, 0, 0, 0.45)`;
export const CHURCHDESK_COLOR = '#008db6';

export function getTypeStyle(type: IconType) {
  switch (type) {
    case 'danger':
      return { color: ERROR_COLOR };
    case 'warning':
      return { color: WARNING_COLOR };
    case 'info':
      return { color: INFO_COLOR };
    case 'success':
      return { color: SUCCESS_COLOR };
    case 'secondary':
      return { color: SECONDARY_COLOR };
    case 'churchdesk':
      return { color: CHURCHDESK_COLOR };
    default:
      return {};
  }
}

export const CdIcon = (props: CdIconInternalProps) => {
  const { size, transform, onClick, style, fixedWidth, icon } = props;

  if (props.animation === 'beat') {
    return (
      <BeatWrapper>
        <FontAwesomeIcon
          {...{ size, transform, onClick, fixedWidth, icon }}
          {...{ style: { ...style, ...getTypeStyle(props.type) } }}
          className="anticon"
        />
      </BeatWrapper>
    );
  }
  if (props.animation === 'spin') {
    return (
      <SpinWrapper>
        <FontAwesomeIcon
          {...{ size, transform, onClick, fixedWidth, icon }}
          {...{ style: { ...style, ...getTypeStyle(props.type) } }}
          className="anticon"
        />
      </SpinWrapper>
    );
  }

  return (
    <FontAwesomeIcon
      {...{ size, transform, onClick, fixedWidth, icon }}
      {...{ style: { ...style, ...getTypeStyle(props.type) } }}
      className="anticon"
    />
  );
};

export const CdCircleCheck = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheckCircleSolid} />
);

export const CdFacebookSquare = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFacebookSquare} />
);
export const CdBulkUploadFailed = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFilterCircleXmark} />
);
export const CdBulkUploadFilter = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFilterSlash} />
);

export const CdMasquerade = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUserSecret} />
);

export const CdEditIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPencilAlt} />
);
export const CdDeleteIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faTrashAlt} />
);
export const CdCopyIcon = (p: CdIconProps) => <CdIcon {...p} icon={faCopy} />;
export const CdEyeIcon = (p: CdIconProps) => <CdIcon {...p} icon={faEye} />;
export const CdSave = (p: CdIconProps) => <CdIcon {...p} icon={faSave} />;
export const CdShare = (p: CdIconProps) => <CdIcon {...p} icon={faShare} />;
export const CdAlertInfo = (p: CdIconProps) => (
  <CdIcon {...p} icon={faInfoCircle} />
);
export const CdPrint = (p: CdIconProps) => <CdIcon {...p} icon={faPrint} />;
export const CdUserPlusIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUserPlus} />
);
export const CdVersionHistory = (p: CdIconProps) => (
  <CdIcon {...p} icon={faHistory} />
);
export const CdTime = (p: CdIconProps) => <CdIcon {...p} icon={faClock} />;
export const CdPlus = (p: CdIconProps) => <CdIcon {...p} icon={faPlus} />;
export const CdMinus = (p: CdIconProps) => <CdIcon {...p} icon={faMinus} />;
export const CdPrivacyPolicy = (p: CdIconProps) => (
  <CdIcon {...p} icon={faGraduationCap} />
);

// Modal close icon
export const CdClose = (p: CdIconProps) => <CdIcon {...p} icon={faTimes} />;

export const CdDisableRole = (p: CdIconProps) => (
  <span className="anticon fa-layers">
    <CdIcon {...p} icon={faKey} />
    <CdIcon {...p} icon={faSlash} />
  </span>
);

export const CdCircleNumber = ({ label }: { label: number | string }) => (
  <span className="anticon fa-layers">
    <FontAwesomeIcon transform="grow-12" icon={faCircle} />
    <strong className="fa-layers-text fa-inverse">{label}</strong>
  </span>
);

export const CdCheckIcon = (p: CdIconProps) => <CdIcon {...p} icon={faCheck} />;
export const CdExclamationTriangleIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExclamationTriangle} />
);
export const CdWarningIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExclamationCircle} />
);
export const CdCalendarPlusIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendarPlus} />
);
export const CdBrowserIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBrowser} />
);
export const CdAbsence = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendarTimes} />
);
export const CdEventWithSignup = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendarStar} />
);

export const CdSignOut = (p: CdIconProps) => <CdIcon {...p} icon={faSignOut} />;

export const CdStyleIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPaintBrush} />
);
export const CdSetupIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faSlidersH} />
);
export const CdNewsletterListIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faRss} />
);
export const CdCodeIcon = (p: CdIconProps) => <CdIcon {...p} icon={faCode} />;
export const CdLock = (p: CdIconProps) => <CdIcon {...p} icon={faLockAlt} />;
export const CdEmoji = (p: CdIconProps) => <CdIcon {...p} icon={faSmile} />;
export const CdLockSolid = (p: CdIconProps) => (
  <CdIcon {...p} icon={faLockSolid} />
);
// Dropdown and navigation arrows
export const CdAngleDown = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleDown} />
);
export const CdAngleUp = (p: CdIconProps) => <CdIcon {...p} icon={faAngleUp} />;
export const CdAngleLeft = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleLeft} />
);
export const CdAngleRight = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleRight} />
);
// Terminal icons
export const CdViewTerminal = (p: CdIconProps) => (
  <CdIcon {...p} icon={faEye} />
);
export const CdEditTerminal = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPencilAlt} />
);
export const CdDeleteTerminal = (p: CdIconProps) => (
  <CdIcon {...p} icon={faTrashAlt} />
);
export const CdTerminalFallbackIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDesktop} />
);
export const CdTerminalFallbackInformation = (p: CdIconProps) => (
  <CdIcon {...p} icon={faList} />
);

// Transfer icons
export const CdTransfer = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExchange} />
);
export const CdRecentTransferPaid = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheck} />
);
export const CdRecentTransferFailed = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBan} />
);

export const CdUpcomingTransferNotification = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExclamationCircleSolid} />
);
export const CdRecentTransferDownloadIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDownload} />
);
export const CdUpcomingTransferDetailsDownloadIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDownload} />
);
export const CdUpcomingTransferDetailsDownloadIndicator = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleDown} />
);

// Website
export const CdDomains = (p: CdIconProps) => (
  <CdIcon {...p} icon={faNetworkWired} />
);
export const CdEllipsis = (p: CdIconProps) => (
  <CdIcon {...p} icon={faEllipsisH} />
);
export const CdNewWebsite = (p: CdIconProps) => (
  <CdIcon {...p} icon={faSparkles} />
);
export const CdExtraWebsite = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPlusSquare} />
);
export const CdMigratedWebsite = (p: CdIconProps) => (
  <CdIcon {...p} icon={faRocketLaunch} />
);
export const CdDefaultDomain = (p: CdIconProps) => (
  <CdIcon {...p} icon={faHomeAlt} />
);
export const CdRecheckDomain = (p: CdIconProps) => (
  <CdIcon {...p} icon={faSync} />
);
export const CdDomainList = (
  p: CdIconProps & { type: 'success' | 'danger' }
) => (
  <span className={`anticon fa-layers fa-${p.size}`}>
    <CdIcon icon={faGlobeLight} />
    <FontAwesomeIcon
      icon={faCircle}
      transform="shrink-6 down-5 right-5"
      style={{ color: 'white' }}
    />
    <FontAwesomeIcon
      transform="shrink-8 down-5 right-5"
      icon={p.type === 'success' ? faLockSolid : faLockOpen}
      style={{ color: p.type === 'success' ? '#279c59' : '#9c2727' }}
    />
  </span>
);

// unpaged table pagination arraows
export const NextIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleRight} />
);
export const PrevIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleLeft} />
);

// time registration
export const UserTimeRegistrationEnabled = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheck} />
);
export const CdTimeRegistrationNextMonth = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleRight} />
);
export const CdTimeRegistrationPreviousMonth = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleLeft} />
);
export const CdHoursSaving = (p: CdIconProps) => (
  <CdIcon {...p} icon={faSyncAlt} />
);
export const CdHoursSaved = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheck} />
);
export const CdTimeRegistrationIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faClockSolid} />
);

// All reports

export const CdCalendarSearchPrint = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPrint} />
);
export const CdCalendarSearchExportToExcel = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFileExcel} />
);
export const CdCalendarReportEmptyState = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendar} />
);
export const CdCalendarSearchActivityList = (p: CdIconProps) => (
  <CdIcon {...p} icon={faList} />
);
export const CdReportSendMessageWithCircle = () => (
  <span className="anticon fa-layers">
    <FontAwesomeIcon icon={falCircle} />
    <FontAwesomeIcon transform="shrink-10" icon={faPaperPlane} />
  </span>
);
export const CdCalendarResult = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendarAltLight} />
);

// Blogs
export const CdBlog = (p: CdIconProps) => <CdIcon {...p} icon={faBullhorn} />;

// Calendar

export const CdOpenInNewWindow = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExternalLink} />
);
export const CdTableIcon = (p: CdIconProps) => <CdIcon {...p} icon={faTable} />;
export const CdCalendar = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCalendarAlt} />
);
export const CdPoster = (p: CdIconProps) => (
  <CdIcon {...p} icon={faImagePolaroid} />
);
export const CdUngroupAbsences = (p: CdIconProps) => (
  <CdIcon {...p} icon={faExpandAlt} />
);
export const CdGroupAbsences = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCompressAlt} />
);
export const CdCircleFilled = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCircle} />
);
export const EventIcons = {
  Resource: (p: CdIconProps) => <CdIcon {...p} icon={faBuilding} />,
  ShowResourceSelector: (p: CdIconProps) => (
    <CdIcon {...p} icon={faCaretSquareDown} />
  ),
  Location: (p: CdIconProps) => <CdIcon {...p} icon={faMapMarkedAlt} />,
  LocationIssue: (p: CdIconProps) => (
    <span className={`anticon fa-layers fa-${p.size}`}>
      <CdIcon icon={faBuilding} {...p} />
      <CdIcon
        icon={faExclamationCircleSolid}
        transform="shrink-5 down-5 right-5"
        {...p}
      />
    </span>
  ),
  Attendance: (p: CdIconProps) => <CdIcon {...p} icon={faChartLine} />,
  Category: CdCalendar,
  Contributor: (p: CdIconProps) => <CdIcon {...p} icon={faUserAlt} />,
  Image: (p: CdIconProps) => <CdIcon {...p} icon={faImage} />,
  ImageCrop: (p: CdIconProps) => <CdIcon {...p} icon={faCropAlt} />,
  Summary: (p: CdIconProps) => <CdIcon {...p} icon={faPresentation} />,
  Description: (p: CdIconProps) => <CdIcon {...p} icon={faAlignJustify} />,
  InternalNote: (p: CdIconProps) => <CdIcon {...p} icon={faStickyNote} />,
  SensitiveInformation: CdLock,
  EventFiles: (p: CdIconProps) => <CdIcon {...p} icon={faFileUpload} />,
  Channels: (p: CdIconProps) => <CdIcon {...p} icon={faRetweet} />,
  Alias: (p: CdIconProps) => <CdIcon {...p} icon={faWindow} />,
  ChurchScreen: (p: CdIconProps) => <CdIcon {...p} icon={faTv} />,
  UserBooking: (p: CdIconProps) => <CdIcon {...p} icon={faUserCircle} />,
  Notify: (p: CdIconProps) => <CdIcon {...p} icon={faBell} />,
  SignUpForm: (p: CdIconProps) => <CdIcon {...p} icon={faFileAlt} />,
  SignUpFormNew: (p: CdIconProps) => <CdIcon {...p} icon={faFilePlus} />,
  SignUpFormExisting: (p: CdIconProps) => <CdIcon {...p} icon={faCopy} />,
  Users: (p: CdIconProps) => <CdIcon {...p} icon={faUsers} />,
  Consents: (p: CdIconProps) => <CdIcon {...p} icon={faCheckSquare} />,
  MaxTickets: (p: CdIconProps) => <CdIcon {...p} icon={faBagsShopping} />,
  CalenderLight: (p: CdIconProps) => <CdIcon {...p} icon={faCalendarDay} />,
  TicketNumbers: (p: CdIconProps) => <CdIcon {...p} icon={faUsersRegular} />,
  EditForm: (p: CdIconProps) => <CdIcon {...p} icon={faEdit} />,
  Price: (p: CdIconProps) => <CdIcon {...p} icon={faMoneyBill} />,
  SignUpPreview: (p: CdIconProps) => <CdIcon {...p} icon={faFileAlt} />,
  UserRegular: (p: CdIconProps) => <CdIcon {...p} icon={faUsersRegular} />,
  PaymentOptions: (p: CdIconProps) => <CdIcon {...p} icon={faCreditCard} />,
  Participants: (p: CdIconProps) => <CdIcon {...p} icon={faUsers} />,
  Facebook: (p: CdIconProps) => <CdIcon {...p} icon={faFacebook} />,
  FacebookSuccess: (p: CdIconProps) => <CdIcon {...p} icon={faCheckCircle} />,
  AttendanceStatusGoing: (p: CdIconProps) => (
    <CdIcon {...p} icon={faCheckCircle} />
  ),
  AttendanceStatusNotGoing: (p: CdIconProps) => (
    <CdIcon {...p} icon={faTimesCircle} />
  ),
  AttendanceStatusMaybe: (p: CdIconProps) => (
    <CdIcon {...p} icon={faQuestionCircle} />
  ),
  FormEventSignUpCircleIcon: (p: CdIconProps) => (
    <CdIcon {...p} icon={faCircleEmpty} />
  ),
  FormEventSignUpCheckedIcon: (p: CdIconProps) => (
    <CdIcon {...p} icon={faCheckCircle} />
  ),
  SingleRepeatedEvents: (p: CdIconProps) => <CdIcon {...p} icon={faCube} />,
  MultipleRepeatedEvents: (p: CdIconProps) => <CdIcon {...p} icon={faCubes} />,
  VisibilityPublic: (p: CdIconProps) => <CdIcon {...p} icon={faGlobe} />,
  VisibilityChurch: (p: CdIconProps) => <CdIcon {...p} icon={faChurchSolid} />,
  VisibilityUser: (p: CdIconProps) => <CdIcon {...p} icon={faUsers} />,
  VisibilityLock: (p: CdIconProps) => <CdIcon {...p} icon={faLockSolid} />,

  RotaMissingUsers: (p: CdIconProps) => (
    <CdIcon {...p} icon={faCircleExclamation} />
  ),

  // Event intentions
  EventIntentionList: (p: CdIconProps) => <CdIcon {...p} icon={faList} />,
  RemoveIntention: (p: CdIconProps) => <CdIcon {...p} icon={faCalendarMinus} />,
  AddIntentions: (p: CdIconProps) => <CdIcon {...p} icon={faCalendarPlus} />,
  IntentionEmptyState: (p: CdIconProps) => <CdIcon {...p} icon={faTasks} />,
  Comments: (p: CdIconProps) => <CdIcon {...p} icon={faComments} />,
  SognDK: () => (
    <span className="anticon">
      <img
        style={{ maxWidth: '17px', marginBottom: '5px' }}
        src="https://assets.churchdesk.com/misc/img/sogndk.png?class=small"
        alt="Logo of SognDK"
      />
    </span>
  ),
};

export const CdIntentionsBilling = (p: CdIconProps) => (
  <CdIcon {...p} icon={faThumbtack} />
);
export const CdListIcon = (p: CdIconProps) => <CdIcon {...p} icon={faList} />;
export const CdExportIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDownload} />
);
export const CdQouteLeftIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faQuoteLeft} />
);
// book a meeting icon
export const CdBookMeetingIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUserClock} />
);

// Contribution payments
export const CdDonationExportIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDownloadSolid} />
);

export const CdContributionTypePaymentIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCreditCard} />
);
export const CdContributionTypeDonationIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faGift} />
);
export const CdContributionProjectIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCubeSolid} />
);

// Analytics
export const CdWebsiteAnalytics = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAnalytics} />
);
export const CdAnalyticsInfo = (p: CdIconProps) => (
  <CdIcon {...p} icon={faChartLine} />
);
export const CdAnalyticsCheck = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheckCircle} />
);

// Donation / Payments
export const CdCar = (p: CdIconProps) => <CdIcon {...p} icon={faCar} />;
export const CdTablet = (p: CdIconProps) => <CdIcon {...p} icon={faTablet} />;
export const CdBot = (p: CdIconProps) => <CdIcon {...p} icon={faRobot} />;
export const CdLink = (p: CdIconProps) => <CdIcon {...p} icon={faLink} />;
export const CdQrCode = (p: CdIconProps) => <CdIcon {...p} icon={faQrcode} />;
export const CdDonationPoster = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFileInvoiceDollar} />
);
export const CdBan = (p: CdIconProps) => <CdIcon {...p} icon={faBan} />;
export const CdRefundPayment = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUndoAlt} />
);
export const CdTerminal = (p: CdIconProps) => (
  <CdIcon {...p} icon={faTabletAndroidAlt} />
);
export const CdBulbOn = (p: CdIconProps) => (
  <CdIcon {...p} icon={faLightbulbOn} />
);

// Payment details icons
export const CdEditPaymentIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPencilAlt} />
);
export const CdMoreIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAngleDown} />
);
export const CdFolderOpen = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFolderOpen} />
);

// Roles management
export const CdEnableRole = (p: CdIconProps) => <CdIcon {...p} icon={faKey} />;
export const CdRoleIsStatic = CdLock;

export const CdUserAvatar = (p: CdIconProps) => <CdIcon {...p} icon={faUser} />;
export const CdUserAvatarLight = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUserRegular} />
);
// Users / Recipients
export const CdUsers = (p: CdIconProps) => <CdIcon {...p} icon={faUsers} />;
export const CdUsersClock = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUserClock} />
);

export const CdChurch = (p: CdIconProps) => <CdIcon {...p} icon={faChurch} />;

// Forms
export const CdForm = (p: CdIconProps) => <CdIcon {...p} icon={faFileAlt} />;
export const CdFormMissing = (p: CdIconProps) => (
  <CdIcon {...p} icon={faHandPointLeft} />
);
export const CdFormRemove = () => (
  <span className="anticon fa-layers">
    <FontAwesomeIcon icon={faFileAlt} />
    <FontAwesomeIcon
      icon={faHorizontalRule}
      color="white"
      transform="rotate-45 grow-12 left-2"
    />
    <FontAwesomeIcon icon={faHorizontalRule} transform="rotate-45 left-2" />
  </span>
);

// Download / Export
export const CdDownloadIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faDownload} />
);
export const CdCSV = (p: CdIconProps) => <CdIcon {...p} icon={faFileCsv} />;
export const CdEXCEL = (p: CdIconProps) => <CdIcon {...p} icon={faFile} />;
export const CdFile = (p: CdIconProps) => <CdIcon {...p} icon={faFileExcel} />;
export const CdSpreadsheet = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFileSpreadsheet} />
);

// Tables
export const CdColumns = (p: CdIconProps) => <CdIcon {...p} icon={faColumns} />;
export const CdSearch = (p: CdIconProps) => <CdIcon {...p} icon={faSearch} />;

// Tags
export const CdTags = (p: CdIconProps) => <CdIcon {...p} icon={faTags} />;
export const CdCogs = (p: CdIconProps) => <CdIcon {...p} icon={faCogs} />;

// Portal
export const CdPortalPlugins = (p: CdIconProps) => (
  <CdIcon {...p} icon={faTasksAlt} />
);

// Files
export const CdAttachmentIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPaperclip} />
);
export const CdFolderIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFolder} />
);
export const CdCopyrightIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCopyright} />
);
export const CdUploadIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faUpload} />
);
// people - newletter lists
export const CdFilterIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faFilter} />
);
export const CdRenameFilter = (p: CdIconProps) => (
  <CdIcon {...p} icon={faMoneyCheckEdit} />
);
// People - Consent type
export const CdConsentType = (p: CdIconProps) => (
  <CdIcon {...p} icon={faCheckSquare} />
);
// People - Message
export const CdSendMessage = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPaperPlane} />
);
export const CdSendMessageWithCircle = () => (
  <span className="anticon fa-layers">
    <FontAwesomeIcon icon={falCircle} />
    <FontAwesomeIcon transform="shrink-10" icon={faPaperPlane} />
  </span>
);
export const CdSchedule = (p: CdIconProps) => (
  <CdIcon {...p} icon={faAlarmClock} />
);
export const CdTestMessage = (p: CdIconProps) => (
  <CdIcon {...p} icon={faTelescope} />
);
export const CdDesktop = (p: CdIconProps) => <CdIcon {...p} icon={faDesktop} />;
export const CdMobile = (p: CdIconProps) => <CdIcon {...p} icon={faMobile} />;
export const CdMessage = (p: CdIconProps) => (
  <CdIcon {...p} icon={faEnvelope} />
);
export const CdMessageLegacy = (p: CdIconProps) => (
  <CdIcon {...p} icon={faEnvelopeSolid} />
);
export const CdBasic = (p: CdIconProps) => <CdIcon {...p} icon={faAt} />;
export const CdStudio = ({ size, ...faProps }: CdIconProps) => (
  <span
    className={`anticon fa-layers fa-${size} ${
      faProps.fixedWidth ? 'fa-fw' : null
    }`}
  >
    <CdIcon {...faProps} icon={faEnvelope} />
    <CdIcon
      {...faProps}
      transform="shrink-4 up-5 right-5"
      style={{ color: 'white' }}
      inverse
      icon={faSquare}
    />
    <CdIcon
      {...faProps}
      transform="shrink-6 up-5 right-5"
      icon={faPencilPaintbrush}
    />
  </span>
);

export const CdStudioEventInvitation = ({ size, ...faProps }: CdIconProps) => (
  <span
    className={`anticon fa-layers fa-${size} ${
      faProps.fixedWidth ? 'fa-fw' : null
    }`}
  >
    <CdIcon {...faProps} icon={faEnvelope} />
    <CdIcon
      {...faProps}
      transform="shrink-1 up-5 right-5"
      inverse
      icon={faSquare}
      style={{ color: 'white' }}
    />
    <CdIcon
      {...faProps}
      transform="shrink-2 up-5 right-5"
      icon={faCalendarAltLight}
    />
  </span>
);

export const CdRefresh = (p: CdIconProps) => <CdIcon {...p} icon={faSyncAlt} />;
export const CdTemplates = () => (
  <span className="anticon fa-layers">
    <FontAwesomeIcon transform="shrink-1 up-2 right-2" icon={faEnvelope} />
    <FontAwesomeIcon
      transform="shrink-1 up-1 right-1"
      inverse
      icon={faSquare}
    />
    <FontAwesomeIcon transform="shrink-1" icon={faEnvelope} />
  </span>
);
export const CdNewTemplate = (p: CdIconProps) => (
  <CdIcon {...p} icon={faPlusCircle} />
);
export const CdOrderOfService = (p: CdIconProps) => (
  <CdIcon {...p} icon={faListAlt} />
);
export const CdParticipantList = (p: CdIconProps) => (
  <CdIcon {...p} icon={faList} />
);
export const CdBankIcon = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBuildingColumns} />
);
export const CdFormLabels = CdParticipantList;

export const CdPhone = (p: CdIconProps) => <CdIcon {...p} icon={faPhone} />;
export const CdInfoIcon = (p: CdIconProps) => <CdIcon {...p} icon={faInfo} />;

// Battery
export const CdBattery100 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryFull} />
);
export const CdBattery75 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryThreeQuarters} />
);
export const CdBattery50 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryHalf} />
);
export const CdBattery25 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryQuarter} />
);
export const CdBattery10 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryLow} />
);
export const CdBattery0 = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBatteryEmpty} />
);

export const CdBolt = (p: CdIconProps) => <CdIcon {...p} icon={faBolt} />;
export const CdBoltSlash = (p: CdIconProps) => (
  <CdIcon {...p} icon={faBoltSlash} />
);

export default {
  CdAngleDown,
  CdAngleLeft,
  CdAngleRight,
  CdAngleUp,
  CdCalendarPlusIcon,
  CdClose,
  CdCircleNumber,
  CdUsers,
  CdUserAvatar,
  CdCheckIcon,
  CdEmoji,
  CdChurch,
  CdCodeIcon,
  CdCopyIcon,
  CdDeleteIcon,
  CdDisableRole,
  CdEditIcon,
  CdEnableRole,
  CdEyeIcon,
  CdSave,
  CdLock,
  CdRoleIsStatic,
  CdColumns,
  CdSearch,
  CdForm,
  CdDownloadIcon,
  CdCSV,
  CdSpreadsheet,
  CdNewsletterListIcon,
  CdStyleIcon,
  CdExclamationTriangleIcon,
  CdPortalPlugins,
  CdFolderIcon,
  CdCopyrightIcon,
  CdUploadIcon,
  CdAttachmentIcon,
  CdSendMessage,
  CdSchedule,
  CdTestMessage,
  CdOrderOfService,
};

const BeatWrapper = styled.span`
  &&&& {
    display: block;
    -webkit-animation-name: fa-beat;
    animation-name: fa-beat;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
    animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(
      --fa-animation-iteration-count,
      infinite
    );
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
    animation-timing-function: var(--fa-animation-timing, ease-in-out);
  }

  @keyframes fa-beat {
    0%,
    90% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    45% {
      -webkit-transform: scale(var(--fa-beat-scale, 1.25));
      transform: scale(var(--fa-beat-scale, 1.25));
    }
  }
`;

const SpinWrapper = styled.div`
    &&&& {
        display: flex;
        -webkit-animation-name: fa-spin;
        animation-name: fa-spin;
        -webkit-animation-delay: var(--fa-animation-delay, 0s);
        animation-delay: var(--fa-animation-delay, 0s);
        -webkit-animation-direction: var(--fa-animation-direction, normal);
        animation-direction: var(--fa-animation-direction, normal);
        -webkit-animation-duration: var(--fa-animation-duration, 1s);
        animation-duration: var(--fa-animation-duration, 1s);
        -webkit-animation-iteration-count: var(
                --fa-animation-iteration-count,
                infinite
        );
        animation-iteration-count: var(--fa-animation-iteration-count, infinite);
        -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
        animation-timing-function: var(--fa-animation-timing, ease-in-out);
    }
    @keyframes fa-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
