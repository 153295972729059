import { useMemo } from 'react';
import { debounce } from 'lodash';
import { useRecoilCallback } from 'recoil';

import { ContributionsSearchAtom } from '@/react/shared/store/contributions';

export function useContributionsSearch() {
  const searchContributions = useRecoilCallback(
    ({ set }) =>
      (title: string) =>
        set(ContributionsSearchAtom, (searchAtom) => ({
          ...searchAtom,
          title,
        }))
  );

  return {
    debounce: {
      searchContributions: useMemo(
        () => debounce(searchContributions, 1000),
        [searchContributions]
      ),
    },
  };
}
