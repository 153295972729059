import { useRecoilValue, useRecoilCallback } from 'recoil';
import { debounce } from 'lodash';
import { useMemo } from 'react';

import { FormSearchResultQuery, FormSearchTextState } from '../store/message';

export function useFormSearch() {
  const searchForms = useRecoilCallback(
    ({ set }) =>
      (text: string) =>
        set(FormSearchTextState, text)
  );

  return {
    searchForms,
    debounced: {
      searchForms: useMemo(() => debounce(searchForms, 1000), [searchForms]),
    },
  };
}

export function useFormSearchResults() {
  const forms = useRecoilValue(FormSearchResultQuery);
  return { forms };
}
