'use strict';

angular.module('cdApp.shared').factory('Diocese', [
  '$$resource',
  'cdApp',
  ($$resource, cdApp) => {
    'ngInject';

    return $$resource(
      `${cdApp.config.api.main}/dioceses`,
      {},
      {
        associateWithOrganization: {
          method: 'PUT',
          url: `${cdApp.config.api.main}/organizations/:organizationId/diocese`,
        },

        getByOrganizationId: {
          method: 'GET',
          url: `${cdApp.config.api.main}/organizations/:organizationId/diocese`,
        },
      }
    );
  },
]);
