'use strict';

class PeopleImportHistoryState {
  constructor(
    _,
    toastr,
    base64,
    $state,
    $filter,
    gettextCatalog,
    PeopleImportBatches,
    appUtils,
    Users
  ) {
    'ngInject';

    this._ = _;
    this.toastr = toastr;
    this.base64 = base64;
    this.$state = $state;
    this.$filter = $filter;
    this.gettextCatalog = gettextCatalog;
    this.PeopleImportBatches = PeopleImportBatches;
    this.appUtils = appUtils;
    this.Users = Users;
  }

  $onInit() {
    // Sorting options
    this.sorting = {
      field: 'createdAt',
      reverse: true,
    };

    // Load users
    this.users = this.Users.query();

    this.refresh();
  }

  /**
   * Update the sorting order of a column
   *
   * @param {String} field - The field being sorted
   */
  setSorting(field) {
    if (this.sorting.field === field) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.field = field;
      this.sorting.reverse = false;
    }
  }

  /**
   * Return the appropriate sort class for a table column
   *
   * @param {String} field - The field being sorted
   */
  getSortingClass(field) {
    if (this.sorting.field === field) {
      return this.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }

  /**
   * Refresh the list of import batches
   */
  refresh() {
    this.busy = true;

    this.PeopleImportBatches.query()
      .$promise.then((importBatches) => {
        this.importBatches = this._.map(importBatches, (importBatch) => {
          let status = importBatch.status;
          if (status === 'finished') {
            if (importBatch.total === importBatch.skipped) {
              status = 'failed';
            }
          }
          return this._.assign({}, importBatch, { status });
        });
      })
      .catch((error) => {
        this.toastr.error(this.appUtils.getErrorMessage(error));
      })
      .finally(() => {
        this.busy = false;
      });
  }

  /**
   * Render a link to the author of the import batch
   */
  getLinkToImporter(id) {
    const { _, $state, $filter } = this;

    const user = _.find(this.users, { id });
    const userHTML = `<span>${$filter('getName')(user)}</span>`;
    if (!user) return userHTML;

    return `<a href="${$state.href('app.private.settings.users.detail', {
      id,
    })}">${userHTML}</a>`;
  }

  /**
   * Render a link to the errors of the import batch
   */
  getLinkToErrors(importBatch) {
    const { $state, gettextCatalog } = this;

    const errors = gettextCatalog.getPlural(
      importBatch.skipped,
      '1 error',
      '{{ $count | number }} errors',
      {
        $count: importBatch.skipped,
      }
    );

    const errorsSpan = `<span>${errors}</span>`;

    if (!importBatch.skipped) return errorsSpan;

    const linkToErrors = $state.href(
      'app.private.people.contacts.importHistory.details',
      {
        id: importBatch.id,
      }
    );

    return `<a href="${linkToErrors}">${errorsSpan}</a>`;
  }

  /**
   * Whether user can navigate to the people list with the tag of the import batch
   */
  canGoToContacts(importBatch) {
    return (
      importBatch.imported &&
      importBatch.status !== 'processing' &&
      this._.get(importBatch, 'tag.id')
    );
  }

  /**
   * Get a link to the people list with the tag of the import batch
   */
  getLinkToContacts(importBatch) {
    const tagId = this._.get(importBatch, 'tag.id');
    if (!tagId) return null;

    const filter = this.base64.urlencode(
      angular.toJson([
        {
          type: 'list',
          property: 'tags',
          operator: 'eq',
          value: tagId,
        },
      ])
    );

    return this.$state.href('app.private.people.contacts.list', { filter });
  }

  /**
   * Render a link to the people list, filtering with the tag of the import batch
   */
  getLinkToImportedContacts(importBatch) {
    const { gettextCatalog } = this;

    if (importBatch.status === 'processing') return '-';

    const contacts = gettextCatalog.getPlural(
      importBatch.imported,
      '1 contact',
      '{{ $count | number }} contacts',
      {
        $count: importBatch.imported,
      }
    );

    const contactsHTML = `<span>${contacts}</span>`;

    if (!this.canGoToContacts(importBatch)) return contactsHTML;

    return `<a href="${this.getLinkToContacts(
      importBatch
    )}">${contactsHTML}</a>`;
  }
}

PeopleImportHistoryState.$inject = [
  '_',
  'toastr',
  'base64',
  '$state',
  '$filter',
  'gettextCatalog',
  'PeopleImportBatches',
  'appUtils',
  'Users',
];

angular.module('cdApp.people').component('cdPeopleImportHistoryState', {
  templateUrl:
    '@/app/people/people-import-history/people-import-history.component.html',
  controller: PeopleImportHistoryState,
});
