import { ApiResponse } from 'apisauce';
import { useRecoilCallback } from 'recoil';

import { FormLabels } from '../store/form';
import { FormLabel } from '../types';

import { handleError } from '@/react/services/ErrorHandlingService';
import { formsApi } from '@/react/api';

export default function useCdFormLabel() {
  const create = useRecoilCallback(
    ({ refresh }) =>
      async (label) => {
        const { ok, data } = await formsApi.post<FormLabel>(`/label`, {
          label,
        });
        if (ok) {
          refresh(FormLabels);
        } else {
          handleError(data as unknown as ApiResponse<any>);
        }
      },
    []
  );

  const update = useRecoilCallback(
    ({ refresh }) =>
      async (label: FormLabel) => {
        const { ok, data } = await formsApi.put(`/label`, {
          ...label,
          labelId: label.id,
        });

        if (ok) {
          refresh(FormLabels);
        } else {
          handleError(data as unknown as ApiResponse<any>);
        }
      },
    []
  );

  const deleteLabel = useRecoilCallback(
    ({ refresh }) =>
      async (label: FormLabel) => {
        const { ok, data } = await formsApi.delete(`/label/${label.id}`);

        if (ok) {
          refresh(FormLabels);
        } else {
          handleError(data as unknown as ApiResponse<any>);
        }
      },
    []
  );

  return { create, update, deleteLabel };
}
