import _ from 'lodash';

class FormResponseComponent {
  constructor(
    Authorization,
    cdContributionStatusStrings,
    appUtils,
    cdRedactedValue
  ) {
    'ngInject';

    this.Authorization = Authorization;
    this.cdContributionStatusStrings = cdContributionStatusStrings;
    this.appUtils = appUtils;
    this.cdRedactedValue = cdRedactedValue;
  }

  $onInit() {
    const { Authorization } = this;
    this.hasFormSensitiveRole = Authorization.hasPermission(
      'canViewFormsSensitiveData'
    );

    this.canAccessContributions = Authorization.hasPermission(
      'canAccessContributions'
    );
  }

  formatSubmissionValue(component) {
    const { appUtils } = this;
    const submissionValue = this.data[component.key];
    return appUtils.formatSubmission(submissionValue, component) || '-';
  }

  getPaymentStatusText(status) {
    const { cdContributionStatusStrings } = this;

    const strings = cdContributionStatusStrings.getStrings();
    return _.get(strings, status);
  }

  getPaymentStatusClass(status) {
    return _.get(
      {
        failed: 'row-error',
        pending: 'row-warning',
      },

      status
    );
  }
}
FormResponseComponent.$inject = [
  'Authorization',
  'cdContributionStatusStrings',
  'appUtils',
  'cdRedactedValue',
];

angular.module('cdApp.forms').component('cdFormResponse', {
  template: require('./form-response.component.html'),
  bindings: {
    formResponseDetails: '<',
    isRootList: '<',
    hasDiff: '<',
    staticFields: '<',
    staticFieldsOptions: '<',
    customFields: '<',
    components: '<',
    data: '<',
    files: '<',
    showPaymentSummary: '<',
  },

  controller: FormResponseComponent,
});
