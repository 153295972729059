import { Taxonomy } from '../../shared/models/taxonomy';

import { IdentifiableEntity } from './commons';

export enum IntentionFeeTypes {
  INTENTIONEN = 'intentionen',
  STOLARIEN = 'stolarien',
}

export interface IntentionFee {
  id: string;
  organizationId: number;
  name: string;
  description: string;
  type: IntentionFeeTypes;
  active: boolean;
  isDefault: boolean;
  createdById: number;
  updatedById: number;
  feeAllocations: IntentionFeeAllocation[];
  taxonomies: Taxonomy[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  amount: number;
}

export interface IntentionFeeRecipient {
  id: string;
  name: string;
  isForwardingRecipient: boolean;
  organizationId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface IntentionFeeAllocation {
  id?: string;
  feeId?: string;
  fee?: IdentifiableEntity;
  recipientId: string;
  amount: number;
  shouldForwardShare: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
