import { CountryISO2 } from '@/react/shared/models/organization';

export interface EventWithStudioItems {
  id: number;
  allDay: boolean;
  studioItemsLastUpdatedAt: Date;
  startDate: Date;
  endDate: Date;
  title: string;
  posters: ICalendarPosterForFrontendLightweight[];
  invitations: IEventInvitationForFrontendLightweight[];
}
export interface EventWithStudioItemsInterface {
  list: EventWithStudioItems[];
  cursor: Date;
}

export interface ICalendarPosterForFrontendLightweight {
  id: number;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  author: {
    contactId: number;
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
  };
}

export interface IEventInvitationForFrontendLightweight {
  id: number;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  status: MessageStatus;
}

export enum MessageStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENT = 'sent',
}

export enum StudioContentKeys {
  TEMPLATES_IMAGE = 'templatesImage',
  WEBINAR_LINK = 'webinarLink',
  LANDING_PAGE_IMAGE_STUDIO_EDITOR = 'landingPageImageStudioEditor',
  LANDING_PAGE_IMAGE_NEWSLETTERS = 'landingPageImageNewsletters',
  LANDING_PAGE_IMAGE_POSTERS = 'landingPageImagePosters',
  LANDING_PAGE_IMAGE_ALL_MEDIA = 'landingPageImageAllMedia',
  ORDER_STUDIO_TEMPLATE_PACKAGE_HUBSPOT_FORM = 'orderStudioTemplatePackageHubspotForm',
  STUDIO_INFO_PAGE = 'studioInfoPage',
}

const studioContent = {
  de: {
    [StudioContentKeys.TEMPLATES_IMAGE]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dfe0bb22a50036e71ec04e_DE-templates.png',
    [StudioContentKeys.WEBINAR_LINK]:
      'https://register.gotowebinar.com/register/1307332939872976912',
    [StudioContentKeys.LANDING_PAGE_IMAGE_STUDIO_EDITOR]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd40bc62edceb5b86902a_Studio-editor-(DE).png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_NEWSLETTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd42b8fc4ce31ad224863_ChurchDesk-Studio-Templates-(DE).png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_POSTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dfe641cbbc7f60dc1e5df8_DE-Poster-v2.png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_ALL_MEDIA]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dfe0bb22a50036e71ec04e_DE-templates.png',
    [StudioContentKeys.ORDER_STUDIO_TEMPLATE_PACKAGE_HUBSPOT_FORM]:
      'https://share.hsforms.com/1F1MaISs2ThOIXDJy-BrULw9ibu',
    [StudioContentKeys.STUDIO_INFO_PAGE]:
      'https://www.churchdesk.com/de/produkt/studio',
  },
  dk: {
    [StudioContentKeys.TEMPLATES_IMAGE]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dffa6546b7dd8698a329b8_DK-templates.png',
    [StudioContentKeys.WEBINAR_LINK]:
      'https://register.gotowebinar.com/register/5810441085455216141',
    [StudioContentKeys.LANDING_PAGE_IMAGE_STUDIO_EDITOR]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd1e0c7ae640bfb66e194_Studio-Editor-(DK).png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_NEWSLETTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614b312144b74d81ab18e72e_ChurchDesk%20Studio%20Templates.png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_POSTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dff9b2471478efb8878511_DK-Posters.png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_ALL_MEDIA]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dffa6546b7dd8698a329b8_DK-templates.png',
    [StudioContentKeys.ORDER_STUDIO_TEMPLATE_PACKAGE_HUBSPOT_FORM]:
      'https://share.hsforms.com/1F1MaISs2ThOIXDJy-BrULw9ibu',
    [StudioContentKeys.STUDIO_INFO_PAGE]:
      'https://www.churchdesk.com/da/produkt/studio',
  },
  gb: {
    [StudioContentKeys.TEMPLATES_IMAGE]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dfddc9585344b5fe216cb9_UK-templates.png',
    [StudioContentKeys.WEBINAR_LINK]:
      'https://register.gotowebinar.com/register/3645030999966436621',
    [StudioContentKeys.LANDING_PAGE_IMAGE_STUDIO_EDITOR]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd2311098c6e57035f4c6_Studio-editor-(EN).png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_NEWSLETTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/614dd24913b107170fcf5e2e_ChurchDesk-Studio-Templates-(EN).png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_POSTERS]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61e15501d90fea43ce174857_UK-Posters.png',
    [StudioContentKeys.LANDING_PAGE_IMAGE_ALL_MEDIA]:
      'https://uploads-ssl.webflow.com/5c4f6a50824440c0a3f37aa9/61dfddc9585344b5fe216cb9_UK-templates.png',
    [StudioContentKeys.ORDER_STUDIO_TEMPLATE_PACKAGE_HUBSPOT_FORM]:
      'https://share.hsforms.com/1RM2yEzV6QvSfQXxzsIWDpg9ibu',
    [StudioContentKeys.STUDIO_INFO_PAGE]:
      'https://www.churchdesk.com/en/product/studio',
  },
};

export const getStudioContentByLocale = (
  countryISO2: CountryISO2,
  contentKey: StudioContentKeys
) => {
  switch (countryISO2) {
    case CountryISO2.GERMANY:
    case CountryISO2.DENMARK:
    case CountryISO2.UNITED_KINGDOM:
      return studioContent[countryISO2][contentKey];
    default:
      return studioContent[CountryISO2.UNITED_KINGDOM][contentKey];
  }
};
