'use strict';

import _ from 'lodash';
import { pick } from 'lodash';

import {
  getChurches,
  getResourcesWithoutChurches,
} from '../../../../../../react/shared/store/resources';
import { selectAllGroups } from '../../../../../../react/shared/store/groups';
import { AddEvent } from '../../../../../../react/calendar/store/main-view/Actions';
import { fetchCategories } from '../../../../../../react/shared/store/Actions';
import { loadGroups } from '../../../../../../react/shared/store/groups/groupsSlice';

import { showModal } from '@/react/angular/ReactModalBridge';

class EventPopupCreateComponent {
  constructor(
    moment,
    $ngRedux,
    $scope,
    $rootScope,
    Calendar,
    eventPopupService,
    toastr,
    gettextCatalog,
    cdApp,
    Me,
    Authorization,
    $uibModal,
    FeatureToggleService,
    $q
  ) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.Calendar = Calendar;
    this.eventPopupService = eventPopupService;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.moment = moment;
    this.cdApp = cdApp;
    this.Me = Me;
    this.Authorization = Authorization;
    this.FeatureToggleService = FeatureToggleService;
    this.$q = $q;

    // Redux
    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  $onInit() {
    this.featureFlagLoading = true;
    this.flagNewEventFormAsDefault = false;
    this.flagHideOldEventForm = false;
    const gettextCatalog = this.gettextCatalog;
    const Authorization = this.Authorization;
    const emptyUserSelect = {
      id: '',
      name: gettextCatalog.getString('Choose a user.'),
    };

    const users = _.get(this.cdApp, 'data.calendar.filters.users');
    const data = _.pick(this.data, [
      'startDate',
      'endDate',
      'allDay',
      'type',
      'users',
      'resources',
    ]);

    this.showChurchSelector = this.cdApp.showChurchSelector;
    this.showResourceSelector = Authorization.hasPermission('canBook');
    this.canSetVisibilityToPublic = Authorization.hasPermission(
      'canSetVisibilityToPublic'
    );
    this.canSetVisibilityToInternalAll = Authorization.hasPermission(
      'canSetVisibilityToInternalAll'
    );

    // If taxonomies have not been loaded in, fetch them
    if (_.isEmpty(this.taxonomies)) {
      this.fetchCategories();
    }

    // If groups have not been loaded in, fetch them
    if (_.isEmpty(this.allGroups)) {
      this.fetchGroups();
    }

    this.event = new this.Calendar(data || { type: 'event' });

    this.popupApi = $(this.targetElement).qtip('api');
    this.viewData = {
      users: [emptyUserSelect].concat(
        this.filterUsers(
          _(users)
            .mapValues((value, id) => _.extend(value, { id: _.parseInt(id) }))
            .values()
            .orderBy('name')
            .value()
        )
      ),

      formattedDate: this.eventPopupService.getFormattedDate(this.event),
    };

    // Set resource in popup to resource of event or of resource in side by side resource view
    if (data?.resources?.length > 0) {
      this.event.resources = data.resources;
      this.setChurchBasedOnResources();
    }

    this.visibilityOptions = [
      {
        title: gettextCatalog.getString('Public'),
        value: 'public',
        description: gettextCatalog.getString(
          'Allow this event on your website and include it in messages sent from People. Details are shared internally with all users.'
        ),

        icon: 'fa-globe',
        isAllowed: Authorization.hasPermission('canSetVisibilityToPublic'),
      },

      {
        title: gettextCatalog.getString('All users'),
        value: 'internal-all',
        description: Authorization.hasPermission('canEditSensitiveInfo')
          ? gettextCatalog.getString(
              'Anyone in your church with a ChurchDesk login can view the details of this event. Sensitive information remains hidden.'
            )
          : gettextCatalog.getString(
              'Anyone in your church with a ChurchDesk login can view the details of this event.'
            ),

        icon: 'fa-church',
        isAllowed: Authorization.hasPermission('canSetVisibilityToInternalAll'),
      },

      {
        title: gettextCatalog.getString('Groups'),
        value: 'internal-group',
        description: gettextCatalog.getString(
          'Anyone in these specific groups can view the details of this event.'
        ),

        icon: 'fa-users',
        isAllowed: Authorization.hasPermission(
          'canSetVisibilityToInternalGroup'
        ),
      },

      {
        title: gettextCatalog.getString('Private'),
        value: 'private',
        description: gettextCatalog.getString(
          'Only you can view this event. You can change visibility later.'
        ),
        icon: 'fa-lock-alt',
        isAllowed: true,
      },
    ];

    // Set a default visibility
    if (Authorization.hasPermission('canSetVisibilityToInternalAll')) {
      this.event.visibility = 'internal-all';
    } else if (
      !Authorization.hasPermission('canSetVisibilityToInternalAll') &&
      Authorization.hasPermission('canSetVisibilityToInternalGroup')
    ) {
      this.event.visibility = 'internal-group';
    } else {
      this.event.visibility = 'private';
    }
    this.closePopup = this.closePopup.bind(this);

    this.$q
      .all([
        this.FeatureToggleService.hasFeature('new_event_form_as_default'),
        this.FeatureToggleService.hasFeature('disable_old_event_form'),
      ])
      .then(([featureNewEventFormAsDefault, featureHideOldEventForm]) => {
        this.featureFlagLoading = false;
        this.flagNewEventFormAsDefault =
          featureNewEventFormAsDefault || featureHideOldEventForm;
        this.flagHideOldEventForm = featureHideOldEventForm;
        this.flagNewEventFormAsDefault && this.switchToEvent2();
      });
  }

  handleUserChange() {
    this.filteredGroups = this.filterGroups(
      this.allGroups,
      this.selectedAbsenceUser
    );
  }

  filterGroups(groups, userId) {
    return groups.filter((group) => {
      // If there is no event loaded yet or the type is event
      if (!this.event || this.event.type === 'event') {
        return this.Authorization.hasPermission([
          'canSetVisibilityToInternalGroup',
          '!canSetVisibilityToInternalAll',
        ])
          ? _.includes(group.members, this.Me.id)
          : true;
      } else {
        // Only include groups with absence enabled.
        return (
          !group.absenceDisabled &&
          group.members?.includes(Number(this.Me.id)) &&
          (userId ? group.members?.includes(Number(userId)) : true)
        );
      }
    });
  }

  setChurchBasedOnResources() {
    // Set church of resource
    const allResources = _.filter(this.resources, (resource) =>
      _.includes(this.event.resources, resource.id)
    );

    const parentChurchId = _.find(
      allResources,
      (resource) => !_.isNil(resource.parentResourceId)
    )?.parentResourceId;
    if (parentChurchId) {
      this.eventChurch = _.find(this.churches, ['id', parentChurchId]);
    }
  }

  filterUsers(users) {
    if (this.Authorization.hasPermission('canCreateAbsenceAndBook')) {
      return users;
    } else {
      const user = _.find(users, { id: this.Me.id });
      return [user];
    }
  }

  handleResourceChange(selectedResourceIds) {
    this.event.resources = selectedResourceIds;
    this.setChurchBasedOnResources();
  }

  handleVisibilityChange() {
    if (
      this.event.visibility === 'internal-group' &&
      this.filteredGroups.length === 1
    ) {
      this.eventGroup = _.first(this.filteredGroups);
    }
  }

  createEvent(jsEvent, clientVersion = 1) {
    const { moment } = this;
    jsEvent.currentTarget.disabled = true;
    const payload = _.omit(this.event, ['category', 'church']);
    payload.mainCategory = this.event.category.id;

    if (payload.type === 'event') {
      payload.groupIds = this.eventGroup ? [this.eventGroup.id] : undefined;

      if (payload.resources?.length > 0) {
        const firstResourceId = payload.resources[0];
        let firstResource = this.resources.find(
          (resource) => resource.id === firstResourceId
        );
        if (!firstResource) {
          // Search children
          this.resources.some((item) => {
            const resourceFound = item.resources?.find(
              (resource) => resource.id === firstResourceId
            );
            if (resourceFound) {
              firstResource = resourceFound;
            }
            return false;
          });
        }
        if (firstResource) {
          const { locationName, location } = firstResource;
          payload.locationName = locationName;
          payload.locationObj = {
            ...pick(location, [
              'address',
              'address2',
              'city',
              'country',
              'state',
              'zipcode',
            ]),
            // We add the resourceId to the custom_data so that the backend can
            // see that the location information is from a resource.
            custom_data: { resourceId: firstResourceId },
          };
        }
      }

      if (this.showChurchSelector) {
        payload.churchIds = this.eventChurch
          ? [this.eventChurch.id]
          : undefined;
      } else {
        payload.churchIds = _.map(this.churches, 'id');
      }
    } else {
      // Absence
      delete payload.visibility;
      delete payload.resources;
      payload.groupId = this.eventGroup?.id;
      payload.users = this.selectedAbsenceUser;
    }

    const callbackOutput = {
      category: this.event.category,
      resources: this.event.resources,
      users: this.event.users,
    };

    // Add timezone
    payload.timezone = moment.tz.guess();
    payload.church = this.eventChurch;
    payload.clientVersion = clientVersion;
    payload.hideEndTime = true;
    payload.$save(
      (savedEvent) => {
        this.closePopup();
        this.addEvent(savedEvent);
        this.onCreate(_.extend({}, savedEvent, callbackOutput));
        if (payload.type === 'event') {
          this.toastr.success(
            this.gettextCatalog.getString('The event has been created')
          );
        } else if (payload.type === 'absence') {
          this.toastr.success(
            this.gettextCatalog.getString('The absence has been created')
          );
        }
      },
      (response) => {
        jsEvent.currentTarget.disabled = false;

        // Handle conflicts
        if (response.status === 409) {
          this.closePopup();
          this.toastr.info(
            this.gettextCatalog.getString(
              'We found one or more conflicts with your bookings.'
            )
          );

          return this.$uibModal.open({
            component: 'cdDoubleBookingModal',
            resolve: {
              conflicts: () => response.data,
              allowConflicts: () => () => {
                payload.allowDoubleBooking = true;
                return payload.$save((savedEvent) => {
                  this.addEvent(savedEvent);
                  this.onCreate(_.extend({}, savedEvent, callbackOutput));
                  return savedEvent;
                });
              },
            },
          }).result;
        } else {
          this.toastr.error(
            _.get(
              response,
              'data.message',
              this.gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
            )
          );
        }
      }
    );
  }

  editNewEvent() {
    this.closePopup();
    const selectedChurch = this.eventChurch && [{ id: this.eventChurch.id }];
    const churches =
      cdApp.organization.churches.length > 1 ? [] : cdApp.organization.churches;
    showModal('EventDrawer', {
      newEvent: {
        title: this.event?.title,
        resources: this.event?.resources,
        mainCategory: this.event?.category?.id,
        churches: selectedChurch || churches,
        startDate: this.event?.startDate,
        endDate: this.event?.endDate,
        allDay: this.event?.allDay,
        visibility: this.event?.visibility,
        hideEndTime: true,
        fromPopup: true,
        groups: this.eventGroup?.id && [{ id: this.eventGroup?.id }],
      },
    });
  }

  switchToEvent1() {
    this.event.type = 'event';
    this.clientVersion = undefined;
  }

  switchToEvent2() {
    this.event.type = 'event';
    this.clientVersion = 2;
  }

  switchToAbsence() {
    this.event.type = 'absence';
    this.clientVersion = undefined;
    this.filteredGroups = this.filterGroups(
      this.allGroups,
      this.selectedAbsenceUser
    );
    this.selectedAbsenceUser = _.get(this.event, 'users[0]', '').toString();
  }

  debounceEditEvent = _.debounce(this.editEvent, 1000, {
    leading: true,
    trailing: false,
  });

  editEvent() {
    const eventData = _.omit(this.event, ['category']);
    eventData.mainCategory = this.event.category
      ? _.parseInt(this.event.category.id)
      : undefined;
    eventData.resources = eventData.resources || this.event.resources;

    if (eventData.type === 'event') {
      _.extend(eventData, {
        resources: !_.isEmpty(eventData.resources)
          ? _.map(eventData.resources, _.parseInt)
          : undefined,
        users: !_.isUndefined(eventData.users)
          ? _.map(eventData.users, _.parseInt)
          : undefined,
        mainCategory: this.event.category
          ? _.parseInt(this.event.category.id)
          : undefined,
        churches: this.eventChurch ? [this.eventChurch] : undefined,
        groupIds:
          eventData.visibility === 'internal-group' && this.eventGroup
            ? [this.eventGroup.id]
            : [],
      });
    } else {
      // Absence
      delete eventData.visibility;
      delete eventData.resources;
      eventData.users = !_.isArray(this.selectedAbsenceUser)
        ? _.parseInt(this.selectedAbsenceUser)
        : _.parseInt(this.selectedAbsenceUser[0]);
      eventData.groupId = this.eventGroup?.id;
    }

    this.closePopup();
    this.$rootScope.openCreateContentWindow(eventData.type, 'edit', eventData, {
      onCreate: this.onCreate,
    });
  }

  closePopup() {
    this.popupApi.hide();
  }

  mapStateToScope = (state) => {
    const churches = getChurches(state);
    const selectedChurchId = _.get(state, 'config.organizationData.churchId');
    const taxonomies = _.get(state, 'shared.common.categories');
    const allGroups = selectAllGroups(state);
    const filteredGroups = this.Authorization.hasPermission([
      'canSetVisibilityToInternalGroup',
      '!canSetVisibilityToInternalAll',
    ])
      ? this.filterGroups(allGroups, this.selectedAbsenceUser)
      : allGroups;
    return {
      selectedChurchId,
      churches,
      taxonomies,
      allGroups,
      filteredGroups,
      eventChurch: (this.eventChurch = _.find(churches, {
        id: selectedChurchId,
      })),

      resources: getResourcesWithoutChurches(state),
    };
  };

  mapDispatchToScope = (dispatch) => ({
    addEvent: (event) => dispatch(AddEvent(event)),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchGroups: () => dispatch(loadGroups()),
  });
}

EventPopupCreateComponent.$inject = [
  'moment',
  '$ngRedux',
  '$scope',
  '$rootScope',
  'Calendar',
  'eventPopupService',
  'toastr',
  'gettextCatalog',
  'cdApp',
  'Me',
  'Authorization',
  '$uibModal',
  'FeatureToggleService',
  '$q',
];

angular.module('cdApp.calendar').component('cdEventPopupCreate', {
  templateUrl:
    '@/app/calendar/shared/components/event-popup/event-popup-create/event-popup-create.component.html',
  controller: EventPopupCreateComponent,
  bindings: {
    data: '<',
    targetElement: '<',
    onCreate: '<',
  },
});
