import { MenuProps } from 'antd';

import { ActionMenuType } from './types';

// This needs to be included as a function and not a component for Antd to be able to
// correctly realize that this is a dropdown and not a menu.
// Padding and line-height is wrong when this is used as <actionMenu /> and not actionMenu({})
export default function actionMenu({
  actionButtons,
  setDropDownVisible,
  item,
}): MenuProps['items'] {
  return actionButtons.map((actionButton, index) => {
    if (actionButton.type === ActionMenuType.DIVIDER) {
      return {
        type: 'divider',
      };
    }
    return {
      icon:
        'function' === typeof actionButton.icon
          ? actionButton.icon(item)
          : actionButton.icon,

      onClick: () => {
        actionButton.onClick(item);
        setDropDownVisible(false);
      },
      key: index,
      disabled:
        'function' === typeof actionButton.disabled
          ? actionButton.disabled(item)
          : actionButton.disabled,

      label:
        'function' === typeof actionButton.text
          ? actionButton.text(item)
          : actionButton.text,
    };
  });
}
