'use strict';

angular.module('cdApp.shared').component('cdDateBox', {
  bindings: {
    date: '<',
  },

  template: `
      <div class="date-box">
        <div class="date-box__day">
          <span ng-bind="::$ctrl.date | amDateFormat: 'DD'"></span>
        </div>
        <div class="date-box__month">
          <span ng-bind="::$ctrl.date | amDateFormat: 'MMM'"></span>
        </div>
      </div>
    `,
});
