(function () {
  'use strict';

  function startFrom() {
    return function (input, start) {
      if (typeof input !== 'undefined') {
        start = +start;
        return input.slice(start);
      }
    };
  }

  angular.module('cdApp.shared').filter('startFrom', startFrom);
})();
