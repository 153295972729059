import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import React, { useContext } from 'react';
import { isArray } from 'lodash';

import { EventAccessContext } from '../../hooks/use-event';

import { FormItemNoErrorMessage } from './FormItemNoErrorMessage';

import { CdLock } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const FormItemWithAccess = (props: {
  name: string | string[];
  label?: string | React.ReactNode;
  extra?: string | React.ReactNode;
  fieldAccessName?: string;
  trigger?: string;
  children?: any;
  rules?: Rule[];
  dependencies?: NamePath[];
  style?: React.CSSProperties;
  noErrorMessage?: boolean;
  noStyle?: boolean;
  help?: string;
  valuePropName?: string;
  offset?: { top: string; bottom?: string; right: string; left?: string };
}) => {
  const { eventAccess, disabledByVersionHistory } =
    useContext(EventAccessContext);

  if (isArray(props.name) && props.fieldAccessName?.length === 0) {
    throw new Error('name is an array. Use fieldAccessName instead');
  }
  let canEdit = true;
  if (eventAccess.canEdit === false) {
    // If user cannot edit the event all fields are readonly.
    canEdit = false;
  } else {
    canEdit =
      eventAccess?.fields &&
      eventAccess.fields[props.fieldAccessName || (props.name as string)]
        ?.canEdit;
  }

  const renderChildren = () => {
    if (!isArray(props.children)) {
      return React.cloneElement(props.children, {
        disabled: canEdit === false || disabledByVersionHistory || undefined,
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'FormItemWithAccess supports only one child due to Form.Item only supporting one child'
      );
      return <>Error</>;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {props.noErrorMessage ? (
        <FormItemNoErrorMessage
          name={props.name}
          label={props.label}
          trigger={props.trigger || 'onChange'}
          normalize={canEdit ? undefined : (value, prevValue) => prevValue}
          rules={props.rules}
          noStyle={props.noStyle}
          help={props.help}
          valuePropName={props.valuePropName || 'value'}
          style={props.style}
          dependencies={props.dependencies}
          extra={props.extra}
        >
          {renderChildren()}
        </FormItemNoErrorMessage>
      ) : (
        <Form.Item
          name={props.name}
          label={props.label}
          trigger={props.trigger || 'onChange'}
          normalize={canEdit ? undefined : (value, prevValue) => prevValue}
          rules={props.rules}
          noStyle={props.noStyle}
          help={props.help}
          valuePropName={props.valuePropName || 'value'}
          style={props.style}
          dependencies={props.dependencies}
          extra={props.extra}
        >
          {renderChildren()}
        </Form.Item>
      )}
      {!canEdit && (
        <div
          style={{
            right: '8px',
            top: '5px',
            position: 'absolute',
            ...props.offset,
          }}
        >
          <CdTooltip
            title={gettextCatalog.getString(
              'You do not have sufficient access to edit this field.'
            )}
          >
            <CdLock style={{ color: '#6f6f6f' }} />
          </CdTooltip>
        </div>
      )}
    </div>
  );
};
