import { react2angular } from 'react18-react2angular';
import angular from 'angular';

import AssignUserRolesModalWrap from '../../../../../react/user/screens/AssignUserRolesModalWrap';
import { UserList } from '../../../../../react/user/screens/UserList';
import AssignUserRolesModalLauncher from '../../../../../react/user/components/assign-user-roles/AssignUserRolesModalLauncher';
import sharedContext from '../../../../shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.settings')
  .component(
    'userListComponent',
    react2angular(
      sharedContext.use(UserList),
      ['sendMessageModal', 'resendInvite'],
      []
    )
  );

// Example: <cdr-assign-user-roles-modal-wrap />
angular
  .module('cdApp.settings')
  .component(
    'cdrAssignUserRolesModalWrap',
    react2angular(
      sharedContext.use(AssignUserRolesModalWrap),
      [] as never[],
      []
    )
  );

// Example: <cdr-assign-user-roles-modal-launcher user-id="user.id" user-name="::user.name" no-of-roles="user.roles | permissionsCount" ghost="true"/>
angular
  .module('cdApp.settings')
  .component(
    'cdrAssignUserRolesModalLauncher',
    react2angular(
      sharedContext.use(AssignUserRolesModalLauncher),
      ['userId', 'userName', 'noOfRoles', 'ghost'],
      []
    )
  );
