'use strict';

class ExitPromptService {
  constructor($uibModal, gettextCatalog) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
  }

  prompt() {
    const { $uibModal, gettextCatalog } = this;

    return $uibModal.open({
      component: 'cdSimpleModal',
      resolve: {
        title: () => gettextCatalog.getString('Want to leave without saving?'),
        body: () => gettextCatalog.getString('Your changes will be lost.'),
        options: {
          confirmButtonText: gettextCatalog.getString('Leave without saving'),
          closeButtonText: gettextCatalog.getString('Cancel'),
          confirmButtonType: 'danger',
        },
      },
    }).result;
  }
}

ExitPromptService.$inject = ['$uibModal', 'gettextCatalog'];
angular.module('cdApp.shared').service('cdExitPrompt', ExitPromptService);
