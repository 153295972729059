import { Spin } from 'antd';
import { capitalize } from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { CdRRuleSummaryState } from '@/react/calendar/store/events/event';

export const CdRRuleSummary = (props: {
  rrule: string;
  startDate: string | Date | Moment;
}) => {
  const regex = /(RRULE:)?([^&]*)/;
  const parsableRrule = props.rrule && `${props.rrule}`.match(regex);
  const rruleSummaryLoadable = useRecoilValueLoadable(
    CdRRuleSummaryState({
      rrule: parsableRrule && parsableRrule[2],
      startDate: props.startDate,
    })
  );
  if (!props.rrule || !props.startDate) return <></>;
  const loading = rruleSummaryLoadable.state === 'loading';
  const rruleSummary =
    rruleSummaryLoadable.state === 'hasValue' && rruleSummaryLoadable.contents;
  if (loading) {
    return <Spin size="small" />;
  }
  return <>{capitalize(rruleSummary)}</>;
};
