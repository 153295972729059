class PastoralNotesController {
  constructor(
    _,
    gettextCatalog,
    $uibModal,
    $http,
    $filter,
    $state,
    cdApp,
    Users
  ) {
    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$filter = $filter;
    this.$state = $state;
    this.cdApp = cdApp;
    this.Users = Users;
  }

  $onInit() {
    const { cdApp, Users } = this;

    this.masquerading = cdApp.me.masquerading;
    this.loadPastoralNotes();
    this.noteLimit = 200;
    this.users = Users.query();
  }

  loadPastoralNotes() {
    const { $http, cdApp } = this;
    this.expanded = {};
    this.isLoading = true;
    $http
      .get(`${cdApp.config.api.main}/people/${this.personId}/pastoral-notes`)
      .then(({ data }) => {
        this.pastoralNotes = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getPastoralNote(pastoralNote) {
    const { _ } = this;
    const { id, note } = pastoralNote;
    if (note.length <= this.noteLimit) return note;

    const isExpanded = _.get(this.expanded, id) === true;
    if (isExpanded) return note;

    return pastoralNote.note.substring(0, this.noteLimit) + '...';
  }

  getLinkToAuthor(authorId) {
    return this.$state.href('app.private.settings.users.detail', {
      id: authorId,
    });
  }

  getAuthorPicture(authorId) {
    const { _ } = this;
    const author = _.find(this.users, { id: authorId });
    const picture = _.get(author, 'picture') || '/img/user-default.png';
    return `<a href="${this.getLinkToAuthor(authorId)}">
      <img class="img-circle u-ml-5 u-mr-5" width="20" height="20" src="${picture}" />
    </a>`;
  }

  getAuthorName(authorId) {
    const { _, $filter } = this;
    const author = _.find(this.users, { id: authorId });
    const name = $filter('getName')(author);
    return `<a href="${this.getLinkToAuthor(authorId)}">${name}</a>`;
  }

  addPastoralNote() {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdAddPastoralNote',
        resolve: {
          personId: () => this.personId,
        },
      })
      .result.then(() => {
        this.loadPastoralNotes();
      });
  }

  editPastrolNote(pastoralNote) {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdAddPastoralNote',
        resolve: {
          personId: () => this.personId,
          pastoralNoteId: () => pastoralNote.id,
          note: () => pastoralNote.note,
        },
      })
      .result.then(() => {
        this.loadPastoralNotes();
      });
  }

  deletePastrolNote(pastoralNote) {
    const { _, $http, cdApp, $uibModal, gettextCatalog } = this;

    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => gettextCatalog.getString('Are you sure?'),
          body: () =>
            gettextCatalog.getString(
              'Do you want to delete this pastoral note?'
            ),

          options: {
            confirmButtonText: gettextCatalog.getString('Delete'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        $http
          .delete(
            `${cdApp.config.api.main}/people/${this.personId}/pastoral-notes/${pastoralNote.id}`
          )
          .then(() => {
            _.remove(this.pastoralNotes, pastoralNote);
          });
      });
  }

  togglePastoralNote(pastoralNote) {
    const { _ } = this;
    const isExpanded = _.get(this.expanded, pastoralNote.id) === true;
    this._.set(this.expanded, pastoralNote.id, !isExpanded);
  }

  showPastoralNoteHistory(pastoralNote) {
    const { $uibModal } = this;

    $uibModal.open({
      component: 'cdPastoralNoteHistoryModal',
      windowClass: 'modal-scrollable',
      resolve: {
        personId: () => this.personId,
        pastoralNoteId: () => pastoralNote.id,
      },
    });
  }
}

PastoralNotesController.$inject = [
  '_',
  'gettextCatalog',
  '$uibModal',
  '$http',
  '$filter',
  '$state',
  'cdApp',
  'Users',
];

angular.module('cdApp.people').component('cdPastoralNotes', {
  template: require('./pastoral-notes.component.html'),
  controller: PastoralNotesController,
  bindings: {
    personId: '<',
  },
});
