'use strict';

angular
  .module('cdApp.people')
  .factory('Consent', [
    '$$resource',
    'cdApp',
    ($$resource, cdApp) =>
      $$resource(cdApp.config.api.main + '/people/consents/:id'),
  ]);
