import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styled, { css } from 'styled-components';

interface CdCardInterface extends CardProps {
  $fill?: boolean;
  $selected?: boolean;
  $bordered?: boolean;
  $showBorderedCover?: boolean;
}

export const CdCard = styled(Card).attrs<CdCardInterface>(
  ({ bodyStyle, $fill }) => ({
    bodyStyle: {
      ...bodyStyle,
      ...($fill && {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }),
    },
  })
)<CdCardInterface>`
  ${({ $fill }) =>
    $fill &&
    css`
      height: 100%;
      display: flex;
    `}

  ${({ $bordered, $selected, theme }) => {
    if ($bordered && !$selected) {
      return css`
        transition-duration: 0.2s, 0.3s;
        transition-property: box-shadow, border;
        border: 3px white solid;
        &:hover {
          box-shadow: ${theme.borders.boxShadow};
          border: 3px solid ${theme.colors.lightBlue} !important;
        }
      `;
    }
    if ($bordered && $selected) {
      return css`
        border: 3px solid ${theme.colors.lightBlue} !important;
      `;
    }
  }}
  .ant-card-cover {
    margin: 0px;

    border-bottom: ${({ $showBorderedCover }) =>
      $showBorderedCover ? '1px solid #e8e8e8' : 'none'};
  }
`;
