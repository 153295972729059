'use strict';

angular.module('cdApp.shared').directive('preventScrollOnNumber', () => {
  'ngInject';

  return {
    restrict: 'A',
    link(scope, element) {
      element.on('focus', () => {
        element.bind('mousewheel', (e) => e.preventDefault());
      });

      element.on('blur', () => {
        element.unbind('mousewheel');
      });
    },
  };
});
