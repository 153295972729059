// eslint-disable-next-line
import { Tag, TagProps } from 'antd';
import React from 'react';

export type CdTagProps = TagProps & {
  type?: TagType;
};
export type TagType = 'warning' | 'error' | 'info' | 'success' | undefined;

// Removed the margin-inline-end: 8px; from the style

const CdTag = ({ children, ...props }: CdTagProps) => (
  <>
    <Tag {...props} color={props.type} style={{ margin: 0 }}>
      <>{children}</>
    </Tag>
  </>
);

export default CdTag;
