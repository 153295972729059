import React from 'react';

import { gettextCatalog } from '@/react/services/I18nService';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

const ResubscribeAllPracticalEmailBasedInformationButton = ({
  personName,
  updateReceiveCommunicationFromFilters,
}) => {
  const content = gettextCatalog.getString(
    ' {{name}} will receive messages from the list {{ listName }} again.',
    {
      name: `<b>${personName}</b>`,
      listName: '<b>All practical email based informatoin<b>',
    }
  );
  const confirmResubscribeAllPracticalEmailBasedInformationModal = (
    personName: string,
    updateReceiveCommunicationFromFilters: () => void
  ) =>
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Resubscribe'),
      message: <span dangerouslySetInnerHTML={{ __html: content }}></span>,
      okType: 'primary',
      okText: gettextCatalog.getString('Resubscribe'),
      onOk: async () => {
        await updateReceiveCommunicationFromFilters();
      },
    });

  return (
    <span
      onClick={() =>
        confirmResubscribeAllPracticalEmailBasedInformationModal(
          personName,
          updateReceiveCommunicationFromFilters
        )
      }
    >
      {gettextCatalog.getString('Resubscribe')}
    </span>
  );
};

export default ResubscribeAllPracticalEmailBasedInformationButton;
