import { Select, Space, Avatar } from 'antd';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdUsers, EventIcons } from '@/react/shared/components/Icons';

const GeneralStyle = createGlobalStyle`
 .ant-select-item-option-grouped {
  padding-left: 16px;
}
`;

export const CdGroupUserSelect = ({
  placeholder,
  value,
  onChange,
  mode,
  users,
  groups,
  disabled,
  keyword,
  setKeyword,
  style,
}: {
  placeholder: string | React.ReactNode;
  value?: any;
  onChange?: any;
  mode?: any;
  users: any;
  groups?: any;
  disabled?: boolean;
  keyword: string;
  setKeyword: (parameter: string) => void;
  style?: any;
}) => {
  const searched = (keyWord) => (l) => {
    const searchWord = keyWord.toLowerCase().trim();
    return (
      l.name?.toLowerCase().includes(searchWord) ||
      l.email?.toLowerCase().includes(searchWord)
    );
  };
  const options = [
    {
      key: 'users',
      label: gettextCatalog.getString('Users').toLocaleUpperCase(),
      options:
        users
          ?.filter(searched(keyword))
          ?.map(({ id, name, picture }) => ({
            value: id,
            label: name,
            picture,
            type: 'user',
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          )
          .map((item) => ({
            label: (
              <Space
                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                key={item.value}
              >
                {item.picture ? (
                  <Avatar src={item.picture} />
                ) : (
                  <EventIcons.UserBooking
                    size="2x"
                    style={{ color: 'lightgray' }}
                  />
                )}
                {item.label ? item.label : gettextCatalog.getString('Unknown')}
              </Space>
            ),
            value: item.value,
            type: item.type,
          })) || [],
    },
  ];
  if (groups) {
    options.push({
      key: 'groups',
      label: gettextCatalog.getString('Groups').toLocaleUpperCase(),
      options:
        groups
          ?.filter(searched(keyword))
          ?.map(({ id, name, picture, members }) => ({
            value: id,
            label: name,
            picture,
            members: members.length,
            type: 'group',
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          )
          .map((item) => ({
            label: (
              <Space
                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                key={item.value}
              >
                {item.picture ? (
                  <Avatar src={item.picture} />
                ) : (
                  <CdUsers
                    style={{
                      color: 'lightgray',
                      border: '2px solid lightgray',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                    }}
                  />
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>
                    {item.label
                      ? item.label
                      : gettextCatalog.getString('Unknown')}
                  </span>
                  <span style={{ color: 'gray' }}>
                    {item.members
                      ? gettextCatalog.getString(
                          'reserve all {{count}} members',
                          { count: item.members }
                        )
                      : gettextCatalog.getString('no members')}
                  </span>
                </div>
              </Space>
            ),
            value: item.value,
            type: item.type,
          })) || [],
    });
  }
  return (
    <>
      <GeneralStyle />
      <Select
        style={style}
        value={value}
        aria-label="user-select"
        onChange={(value, option) => {
          // @ts-ignore
          onChange(value, option.type);
        }}
        mode={mode}
        showSearch
        placeholder={placeholder}
        filterOption={false}
        onSearch={(input: string) => {
          setKeyword(input);
        }}
        allowClear
        options={options}
        disabled={disabled}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </>
  );
};
