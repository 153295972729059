export enum CategoryType {
  absence = 'absence',
  blog = 'blog',
  event = 'event',
}

export interface Category {
  id: number;
  name: string;
  organizationId: number;
  color: number;
  description: string;
  type: CategoryType;
  registerAttendance: boolean;
}
