(function () {
  'use strict';

  angular.module('cdApp.shared').component('cdStatistic', {
    templateUrl: '@/app/shared/components/cd-statistic/cd-statistic.html',
    transclude: {
      action: '?cdStatisticHeaderAction',
    },

    bindings: {
      title: '<cdTitle',
      icon: '<cdIcon',
    },
  });
})();
