import { Form } from 'antd';

import { privacyPolicyContent } from '../pages/privacy-policy/template';
import { useOrganization } from '../../organization/hooks/useOrganization';

import cdApp from '@/react/config';

enum ContentType {
  BodyContent = 'bodyContent',
  LinkContent = 'linkContent',
}

const usePrivacyPolicy = () => {
  const [form] = Form.useForm();
  const { organizationSettings, updateSettings } = useOrganization();

  const initialValues = {
    privacyPolicy: organizationSettings.privacyPolicy,
    privacyPolicyText:
      organizationSettings.privacyPolicyText ||
      privacyPolicyContent(cdApp.organization.locale.language),
    contentType: organizationSettings.privacyPolicy
      ? ContentType.LinkContent
      : ContentType.BodyContent,
  };

  const canViewPolicy =
    organizationSettings.privacyPolicy !== undefined ||
    organizationSettings.privacyPolicyText !== undefined;

  const viewCurrentPolicy = () => {
    if (organizationSettings?.privacyPolicy) {
      window.open(organizationSettings.privacyPolicy, '_blank');
    }
    const language = cdApp.organization.locale.language || 'en';
    if (organizationSettings?.privacyPolicyText) {
      window.open(
        `${cdApp.references.privacyPolicyLandingPage}/${language}/${cdApp.organization.id}`,
        '_blank'
      );
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(({ contentType, privacyPolicy, privacyPolicyText }) => {
        switch (contentType) {
          case ContentType.LinkContent:
            updateSettings({ privacyPolicy });
            break;
          case ContentType.BodyContent:
            updateSettings({ privacyPolicyText });
            break;
        }
      });
  };

  return {
    form,
    initialValues,
    canViewPolicy,
    viewCurrentPolicy,
    onSubmit,
  };
};

export default usePrivacyPolicy;
