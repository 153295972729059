'use strict';

/**
 * A font picker component that displays a list of web safe fonts
 *
 * @example <cd-font-picker on-font-updated="$ctrl.font = fontFamily"></cd-font-picker>
 */
angular.module('cdApp.shared').component('cdFontPicker', {
  templateUrl: '@/app/shared/components/font-picker/font-picker.component.html',
  bindings: {
    onFontUpdated: '&',
  },

  controller: function () {
    // List of web safe fonts
    this.fonts = [
      {
        name: 'Arial',
        stack: 'Arial, Helvetica, sans-serif',
      },

      {
        name: 'Georgia',
        stack: 'Georgia, serif',
      },

      {
        name: 'Lucida Sans Unicode',
        stack: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
      },

      {
        name: 'Palatino',
        stack: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
      },

      {
        name: 'Times New Roman',
        stack: '"Times New Roman", Times, serif',
      },

      {
        name: 'Tahoma',
        stack: 'Tahoma, Geneva, sans-serif',
      },

      {
        name: 'Trebuchet MS',
        stack: '"Trebuchet MS", Helvetica, sans-serif',
      },

      {
        name: 'Verdana',
        stack: 'Verdana, Geneva, sans-serif',
      },
    ];
  },
});
