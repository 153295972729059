import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { Reports } from '@/react/calendar/reports/Reports';

angular
  .module('cdApp.calendar')
  .component(
    'calendarReports',
    react2angular(sharedContext.use(Reports), [], ['$stateParams'])
  );
