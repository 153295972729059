'use strict';

class OrderOfServiceTemplate {
  constructor(gettextCatalog) {
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.reportTemplates = this.resolve.reportTemplates;
    // Define grouping/ordering data
    this.groupingCriteria = [
      {
        id: 'Date/Time/Church',
        name: this.gettextCatalog.getString('Date/Time/Parish'),
      },

      {
        id: 'Date/Church/Time',
        name: this.gettextCatalog.getString('Date/Parish/Time'),
      },

      {
        id: 'Date/Church/Event',
        name: this.gettextCatalog.getString('Date/Church/Event'),
      },

      {
        id: 'Church/Date/Time',
        name: this.gettextCatalog.getString('Parish/Date/Time'),
      },

      {
        id: 'Date/Time/Resource',
        name: this.gettextCatalog.getString('Date/Time/Resource'),
      },

      {
        id: 'Date/Resource/Time',
        name: this.gettextCatalog.getString('Date/Resource/Time'),
      },

      {
        id: 'Resource/Date/Time',
        name: this.gettextCatalog.getString('Resource/Date/Time'),
      },
    ];

    // Define ordering criteria for churches/resources
    this.orderingCriteria = [
      {
        id: 'Alphabetical',
        name: this.gettextCatalog.getString('Alphabetical'),
      },

      { id: 'Numeric', name: this.gettextCatalog.getString('Numeric') },
    ];
  }

  getTemplateLabel(reportTemplate) {
    const name = _.get(reportTemplate, 'name');
    const description = _.get(reportTemplate, 'description');
    const suffix = !_.isEmpty(description) ? ` (${description})` : '';
    return `${name}${suffix}`;
  }

  /**
   * Generate the modal
   */
  generateReport() {
    this.close({
      $value: {
        reportTemplateId: this.reportTemplateId,
        groupBy: this.groupBy,
        orderBy: this.orderBy,
      },
    });
  }

  /**
   * Close the modal by dismissing it
   */
  cancel() {
    this.dismiss();
  }
}

OrderOfServiceTemplate.$inject = ['gettextCatalog'];
angular.module('cdApp.calendar').component('cdOrderOfServiceTemplateModal', {
  templateUrl:
    '@/app/calendar/calendar-print/order-of-service-template-modal/order-of-service-template.modal.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: OrderOfServiceTemplate,
});
