/* eslint-disable no-unused-vars */
// @ts-nocheck
import PropTypes from 'prop-types';
import * as React from 'react';
import { react2angular } from 'react18-react2angular';
import { $injector } from 'ngimport';
import {
  MultiGrid,
  CellMeasurerCache,
  CellMeasurer,
  AutoSizer,
} from 'react-virtualized';

import sharedContext from '../../../shared/components/shared-context-root/shared-context-root.component';

import EventCell from './EventCell';
import { TaskCell } from './TaskCell';
import PrintTable from './RotaPrintTable';

function getClassName({ columnIndex, rowIndex }) {
  const rowClass = rowIndex % 2 === 0 ? 'evenRow' : 'oddRow';
  return columnIndex > 2 ? `${rowClass} cell centeredCell` : `${rowClass} cell`;
}

export class CdrRotaTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this._cache = new CellMeasurerCache({
      minHeight: 50,
      minWidth: 100,
      fixedWidth: true,
    });

    this._cellRenderer = this._cellRenderer.bind(this);
    this.gridRef = React.createRef();
    this.autoSizerRef = React.createRef();
    this.state = {
      data: props.data,
      columns: props.columns,
      fixedRowCount: 1,
      fixedColumnCount: 1,
      reverseSort: false,
      reRender: 0,
    };

    this.onPrint = this.onPrint.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
  }

  componentDidMount() {
    const printElement = document.getElementById('rotaPrintLink');
    if (printElement) {
      printElement.onclick = () => this.onPrint();
    }

    setTimeout(() => {
      this.setState({
        reRender: 1,
      });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const printElement = document.getElementById('rotaPrintLink');
    if (printElement) {
      printElement.onclick = () => this.onPrint();
    }
    if (
      this.props.columns.length !== prevProps.columns.length ||
      this.props.hideNotes !== prevProps.hideNotes ||
      this.props.isPublic !== prevProps.isPublic ||
      this.state.reRender > 0
    ) {
      this._cache.clearAll();
      this.setState({
        ...this.props,
        data: _.orderBy(
          this.props.data,
          'startDate',
          this.state.reverseSort ? 'desc' : 'asc'
        ),
        reRender: 0,
      });
    }
    if (this.props.data !== prevProps.data) {
      this.setState({
        ...this.props,
        data: _.orderBy(
          this.props.data,
          'startDate',
          this.state.reverseSort ? 'desc' : 'asc'
        ),
        reRender: 0,
      });
    }
  }

  onPrint() {
    this.setState(
      {
        printMode: true,
      },
      () =>
        setTimeout(() => {
          window.print();

          this.setState({
            printMode: false,
          });
        }, 500)
    );
  }

  toggleSort() {
    const { reverseSort, data } = this.state;
    this.setState({
      reverseSort: !reverseSort,
      data: _.orderBy(data, 'startDate', !reverseSort ? 'desc' : 'asc'),
    });
  }

  _cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
    const { data, columns, reverseSort, hideNotes } = this.state;
    const { onAssignUser, scope, isPublic } = this.props;
    const gettextCatalog = $injector.get('gettextCatalog');
    const classNames = getClassName({ columnIndex, rowIndex });
    const rowData = rowIndex === 0 ? data[0] : data[rowIndex - 1];
    const isEvent = columnIndex === 0;
    const eventData = { ...rowData.columns[0] };
    const columnData = rowData.columns[columnIndex];
    const columnTitle = columnIndex > 0 ? columns[columnIndex - 1].label : '';
    if (rowIndex === 0) {
      if (isEvent) {
        return (
          <CellMeasurer
            cache={this._cache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}
            {...this.state}
          >
            <div
              className={classNames}
              style={{ ...style, cursor: 'pointer' }}
              onClick={this.toggleSort}
            >
              <div style={{ height: 'auto' }}>
                <span>{gettextCatalog.getString('Date')}</span>
                {!reverseSort ? (
                  <i className="fa fa-sort-down" />
                ) : (
                  <i className="fa fa-sort-up" />
                )}
              </div>
            </div>
          </CellMeasurer>
        );
      } else {
        return (
          <CellMeasurer
            cache={this._cache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}
          >
            <div className={classNames} style={style}>
              {columnTitle}
            </div>
          </CellMeasurer>
        );
      }
    }
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {isEvent ? (
          <EventCell
            className={classNames}
            isPublic={isPublic}
            event={eventData}
            {...this.state}
            style={{ ...style }}
          />
        ) : (
          <div
            className={
              'rotas-table__task-cell ' +
              (_.isUndefined(columnData.unassigned)
                ? 'rotas-table__task-cell--disabled '
                : '') +
              classNames
            }
            style={{ ...style }}
          >
            <TaskCell
              className={classNames}
              event={eventData}
              task={columnData}
              hideNotes={hideNotes}
              isPublic={isPublic}
              onAssignUser={onAssignUser}
              scope={scope}
              style={style}
            />
          </div>
        )}
      </CellMeasurer>
    );
  }

  render() {
    const { data, columns, gridHeight, gridWidth, printMode, hideNotes } =
      this.state;
    if (!data || !columns) return null;
    if (printMode) {
      return (
        <PrintTable
          className="table table-bordered animated fadeIn"
          columns={columns}
          data={data}
          hideNotes={hideNotes}
        />
      );
    }
    return (
      <AutoSizer ref={this.autoSizerRef}>
        {({ height, width }) => {
          this.cellWidth = 150;
          if ((columns.length + 1) * 150 <= width) {
            this.cellWidth = width / (columns.length + 1);
          }
          return (
            <MultiGrid
              ref={this.gridRef}
              {...this.state}
              className="BodyGrid"
              columnCount={columns.length + 1}
              columnWidth={this.cellWidth}
              fixedRowCount={printMode ? 0 : 1}
              fixedColumnCount={printMode ? 0 : 1}
              deferredMeasurementCache={this._cache}
              height={printMode ? gridHeight : height}
              overscanColumnCount={2}
              overscanRowCount={2}
              cellRenderer={this._cellRenderer}
              rowCount={data.length + 1}
              rowHeight={this._cache.rowHeight}
              width={printMode ? gridWidth : width}
              classNameTopLeftGrid="date-grid"
              classNameBottomLeftGrid="events-grid"
              classNameTopRightGrid="rotas-header"
              classNameBottomRightGrid="rotas-grid"
            />
          );
        }}
      </AutoSizer>
    );
  }
}

CdrRotaTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isPublic: PropTypes.bool,
  hideNotes: PropTypes.bool,
  onAssignUser: PropTypes.func,
  goToEvent: PropTypes.func,
  showNotes: PropTypes.bool,
  scope: PropTypes.object,
  columnManager: PropTypes.object,
};

export default (() => {
  angular
    .module('cdApp.intranet', [])
    .component(
      'cdrRotaTable',
      react2angular(
        sharedContext.use(CdrRotaTable),
        [
          'data',
          'columns',
          'isPublic',
          'hideNotes',
          'gettextCatalog',
          'onAssignUser',
          'columnManager',
          'scope',
        ],
        []
      )
    );
})();
