import React from 'react';
import { Form } from 'antd';
import moment from 'moment';

import { CdSognDkFormWrapper } from './CdSognDkForm';
import SognDKStatus from './CdSognDkStatus';

import { Sogndk } from '@/react/calendar/models/calendar';
import { CdCheckboxWithIcon } from '@/react/shared/components/cd-checkbox-with-icon/CdCheckboxWithIcon';
import { EventIcons } from '@/react/shared/components/Icons';

interface CdSongDkProps {
  value?: Sogndk;
  onChange?: (value: Sogndk) => void;
  eventUpdatedAt?: Date;
  currentExported: boolean;
  currentStatus: number;
  eventId?: number;
  disabled?: boolean;
  allowSognDk?: boolean;
}

export default function CdSognDk(props: CdSongDkProps) {
  const isEventRecentlyUpdated =
    moment().diff(moment(props?.eventUpdatedAt), 'seconds') < 60;

  return (
    <Form.Item name={['sogndk', 'export']} valuePropName="checked" noStyle>
      <CdCheckboxWithIcon
        Icon={EventIcons.SognDK}
        iconSize="small"
        title="Vis begivenheden på Sogn.dk"
        label={
          !props.currentExported &&
          'Ved at klikke her kan begivenheden sendes til sogn.dk. Begivenheden kan vises offentligt eller blot registreres så kirketællingen efterfølgende også kan sendes til sogn.dk.'
        }
        align="start"
        disabled={props.disabled || !props.allowSognDk}
      >
        {((props?.eventId && props.currentExported) || props.value?.export) && (
          <>
            <SognDKStatus
              eventId={props.eventId}
              currentExported={props.currentExported}
              isEventRecentlyUpdated={isEventRecentlyUpdated}
              status={props.value?.status}
              currentStatus={props.currentStatus}
            />
            {props.value?.export && (
              <CdSognDkFormWrapper disabled={props.disabled} />
            )}
          </>
        )}
      </CdCheckboxWithIcon>
    </Form.Item>
  );
}
