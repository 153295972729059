import { react2angular } from 'react18-react2angular';
import angular from 'angular';

import { SimplePeopleForm } from '../../../../../react/people/SimplePeopleForm';
import sharedContext from '../../../../shared/components/shared-context-root/shared-context-root.component';
// Example <cdr-simple-people-form />
angular
  .module('cdApp.people')
  .component(
    'cdrSimplePeopleForm',
    react2angular(
      sharedContext.use(SimplePeopleForm),
      [
        'person',
        'prefixOptions',
        'registerValidationHook',
        'registerFetchValuesHook',
        'preferredPhoneCountry',
        'createMode',
      ],
      []
    )
  );
