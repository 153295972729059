import { FEvent as Event } from '../../../calendar/models/calendar';
import { ActionCreator, CdAction } from '../../../redux/utils';
import { IntentionData } from '../../services/IntentionService';
import {
  Intention,
  IntentionFilters,
  IntentionStatusTypes,
  IntentionTabNames,
  PaymentMethodTypes,
} from '../../models/intention';
import { Foundation, FoundationTypes } from '../../models/foundation';

export type ClearIntentionActionType = CdAction<void>;
export const CLEAR_INTENTION = 'intention/clearIntention';
export const clearIntention: ActionCreator<void> = () => ({
  type: CLEAR_INTENTION,
});

// Fetch multiple intentions

export interface FetchIntentionsPayload {
  status?: IntentionStatusTypes;
  churchIds?: number[];
  feeIds?: string[];
  paymentMethods?: PaymentMethodTypes[];
  referenceNumberSourceType?: string;
  referenceNumberSourceNumber?: number;
  referenceNumberYear?: number;
  referenceNumberFrom?: number;
  referenceNumberTo?: number;
  searchValue?: string;
  includeDeleted?: boolean;
  orderBy?: string;
  orderDirection?: string;
  offset?: number;
  limit?: number;
}
export type FetchIntentionsActionType = CdAction<FetchIntentionsPayload>;
export const FETCH_INTENTIONS = 'intention/fetchIntentions';
export const fetchIntentions: ActionCreator<FetchIntentionsPayload> = (
  payload
) => ({
  type: FETCH_INTENTIONS,
  payload,
});

export type ClearIntentionsActionType = CdAction<void>;
export const CLEAR_INTENTIONS = 'intention/clearIntentions';
export const clearIntentions: ActionCreator<void> = () => ({
  type: CLEAR_INTENTIONS,
});

// Event's intentions

export type FetchEventIntentionsActionType = CdAction<number>;
export const FETCH_EVENT_INTENTIONS = 'intention/fetchEventIntentions';
export const fetchEventIntentions: ActionCreator<number> = (eventId) => ({
  type: FETCH_EVENT_INTENTIONS,
  payload: eventId,
});

export type FetchEventIntentionsSuccess = CdAction<Intention[]>;
export const FETCH_EVENT_INTENTIONS_SUCCESS =
  'intention/fetchEventIntentionsSuccess';
export const fetchEventIntentionsSuccess: ActionCreator<Intention[]> = (
  eventIntentions
) => ({
  type: FETCH_EVENT_INTENTIONS_SUCCESS,
  payload: eventIntentions,
});

export type ClearEventIntentionsActionType = CdAction<void>;
export const CLEAR_EVENT_INTENTIONS = 'intention/clearEventIntentions';
export const clearEventIntentions: ActionCreator<void> = () => ({
  type: CLEAR_EVENT_INTENTIONS,
});

// Shared fetch intentions success action
export type FetchIntentionsSuccess = CdAction<IntentionData>;

export const FETCH_INTENTIONS_SUCCESS = 'intention/fetchIntentionsSuccess';
export const fetchIntentionsSuccess: ActionCreator<IntentionData> = (
  intentions
) => ({
  type: FETCH_INTENTIONS_SUCCESS,
  payload: intentions,
});

// Fetch intention

export type FetchIntentionActionType = CdAction<string>;
export const FETCH_INTENTION = 'intention/fetchIntention';
export const fetchIntention: ActionCreator<string> = (id) => ({
  type: FETCH_INTENTION,
  payload: id,
});

export type FetchIntentionSuccessType = CdAction<Intention>;
export const FETCH_INTENTION_SUCCESS = 'intention/fetchIntentionSuccess';
export const fetchIntentionSuccess: ActionCreator<Intention> = (intention) => ({
  type: FETCH_INTENTION_SUCCESS,
  payload: intention,
});

// Fetch intention parent

export type FetchIntentionParentActionType = CdAction<string>;
export const FETCH_INTENTION_PARENT = 'intention/fetchIntentionParent';
export const fetchIntentionParent: ActionCreator<string> = (id) => ({
  type: FETCH_INTENTION_PARENT,
  payload: id,
});

export type FetchIntentionParentSuccessType = CdAction<Foundation>;
export const FETCH_INTENTION_PARENT_SUCCESS =
  'intention/fetchIntentionParentSuccess';
export const fetchIntentionParentSuccess: ActionCreator<Foundation> = (
  foundation
) => ({
  type: FETCH_INTENTION_PARENT_SUCCESS,
  payload: foundation,
});

export type ClearIntentionParentActionType = CdAction<void>;
export const CLEAR_INTENTION_PARENT = 'intention/clearIntentionParent';
export const clearIntentionParent: ActionCreator<void> = () => ({
  type: CLEAR_INTENTION_PARENT,
});

// Create intention
interface CreateIntentionPayload {
  intention: Partial<Intention>;
  calendarId?: number;
}
export type CreateIntentionActionType = CdAction<CreateIntentionPayload>;
export const CREATE_INTENTION = 'intention/createIntention';
export const createIntention: ActionCreator<CreateIntentionPayload> = (
  payload
) => ({
  type: CREATE_INTENTION,
  payload,
});

// Update intention

interface UpdateIntention {
  id: string;
  updatePayload: Partial<Intention>;
  calendarId?: number;
  status?: IntentionStatusTypes;
  shouldNavigate?: boolean;
  legatId?: string;
  stiftungId?: string;
}
export type UpdateIntentionActionType = CdAction<UpdateIntention>;
export const UPDATE_INTENTION = 'intention/updateIntention';
export const updateIntention: ActionCreator<UpdateIntention> = (payload) => ({
  type: UPDATE_INTENTION,
  payload,
});

// Delete intention

interface DeleteIntentionPayload {
  id: string;
  calendarId?: number;
  fromTab?: IntentionTabNames;
  foundationId?: string;
  type?: FoundationTypes;
  filters?: Record<string, unknown>;
}
export type DeleteIntentionActionType = CdAction<DeleteIntentionPayload>;
export const DELETE_INTENTION = 'intention/deleteIntention';
export const deleteIntention: ActionCreator<DeleteIntentionPayload> = (
  payload
) => ({
  type: DELETE_INTENTION,
  payload,
});

// Restore intention
interface RestoreIntentionPayload {
  id: string;
  calendarId?: number;
  fromTab?: IntentionTabNames;
  foundationId?: string;
  type?: FoundationTypes;
  filters?: Record<string, unknown>;
}
export type RestoreIntentionActionType = CdAction<RestoreIntentionPayload>;
export const RESTORE_INTENTION = 'intention/restoreIntention';
export const restoreIntention: ActionCreator<RestoreIntentionPayload> = (
  payload
) => ({
  type: RESTORE_INTENTION,
  payload,
});

// Intention's events

export interface FetchIntentionEventsPayload {
  intentionId: string;
  startDate: Date;
  endDate: Date;
  searchValue?: string;
  orderBy?: string;
  orderDirection?: string;
  limit?: number;
  offset?: number;
}
export type FetchIntentionEventsType = CdAction<FetchIntentionEventsPayload>;
export const FETCH_INTENTION_EVENTS = 'intention/fetchIntentionEvents';
export const fetchIntentionEvents: ActionCreator<FetchIntentionEventsPayload> =
  (payload) => ({
    type: FETCH_INTENTION_EVENTS,
    payload,
  });

export type ClearIntentionEventsActionType = CdAction<void>;
export const CLEAR_INTENTION_EVENTS = 'intention/clearIntentionEvents';
export const clearIntentionEvents: ActionCreator<void> = () => ({
  type: CLEAR_INTENTION_EVENTS,
});

export type FetchIntentionEventsSuccessType = CdAction<Event[]>;
export const FETCH_INTENTION_EVENTS_SUCCESS =
  'intention/fetchIntentionEventsSuccess';
export const fetchIntentionEventsSuccess: ActionCreator<Event[]> = (
  payload
) => ({
  type: FETCH_INTENTION_EVENTS_SUCCESS,
  payload,
});

// Assign intention

interface AssignIntentionPayload {
  id: string;
  calendarId: number;
  fromEvent: boolean;
}
export type AssignIntentionType = CdAction<AssignIntentionPayload>;
export const ASSIGN_INTENTION = 'intention/assignIntention';
export const assignIntention: ActionCreator<AssignIntentionPayload> = (
  payload
) => ({
  type: ASSIGN_INTENTION,
  payload,
});

// Unassign intention
interface UnassignIntentionPayload {
  id: string;
  calendarId?: number;
  fromTab?: IntentionTabNames;
  foundationId?: string;
  type?: FoundationTypes;
  filters?: Record<string, unknown>;
}
export type UnassignIntentionType = CdAction<UnassignIntentionPayload>;
export const UNASSIGN_INTENTION = 'intention/unassignIntention';
export const unassignIntention: ActionCreator<UnassignIntentionPayload> = (
  payload
) => ({
  type: UNASSIGN_INTENTION,
  payload,
});

// Update priority of intention in event
interface UpdateEventPriority {
  newPrimaryIntentionId: string;
  oldPrimaryIntentionId?: string;
  calendarId: number;
}
export type UpdateEventPriorityActionType = CdAction<UpdateEventPriority>;
export const UPDATE_EVENT_PRIORITY = 'intention/updateEventPriority';
export const updateEventPriority: ActionCreator<UpdateEventPriority> = (
  payload
) => ({
  type: UPDATE_EVENT_PRIORITY,
  payload,
});

// Refresh intention overview
interface RefreshIntentionOverview {
  fromTab?: IntentionTabNames;
  foundationId?: string;
  type?: FoundationTypes;
  filters?: Record<string, unknown>;
}

export type RefreshIntentionOverviewType = CdAction<RefreshIntentionOverview>;
export const REFRESH_INTENTION_OVERVIEW = 'intention/refreshIntentionOverview';
export const refreshIntentionOverview: ActionCreator<RefreshIntentionOverview> =
  (payload) => ({
    type: REFRESH_INTENTION_OVERVIEW,
    payload,
  });

// Billing
export interface FetchBillingIntentionsPayload {
  month?: number;
  year?: number;
  churchIds?: number[];
  forwardingRecipientId?: number;
  internalForwardingRecipientId?: number;
  internallyForwardedIntentionIds?: string[];
  forwardedAt?: Date;
}

export type FetchBillingIntentionsType =
  CdAction<FetchBillingIntentionsPayload>;
export const FETCH_BILLING_INTENTIONS = 'intention/fetchBillingIntentions';
export const fetchBillingIntentions: ActionCreator<
  FetchBillingIntentionsPayload
> = (payload) => ({
  type: FETCH_BILLING_INTENTIONS,
  payload,
});

export type FetchBillingIntentionsSuccessType = CdAction<Intention[]>;
export const FETCH_BILLING_INTENTIONS_SUCCESS =
  'intention/fetchBillingIntentionsSuccess';
export const fetchBillingIntentionsSuccess: ActionCreator<Intention[]> = (
  payload
) => ({
  type: FETCH_BILLING_INTENTIONS_SUCCESS,
  payload,
});

export type ClearBillingIntentionsType = CdAction<void>;
export const CLEAR_BILLING_INTENTIONS = 'intention/clearBillingIntentions';
export const clearBillingIntentions: ActionCreator<void> = () => ({
  type: CLEAR_BILLING_INTENTIONS,
});

export type GenerateIntentionBillingReportActionType =
  CdAction<FetchBillingIntentionsPayload>;
export const GENERATE_INTENTION_BILLING_REPORT =
  'intention/generateIntentionBillingReport';
export const generateIntentionBillingReport: ActionCreator<
  FetchBillingIntentionsPayload
> = (payload) => ({
  type: GENERATE_INTENTION_BILLING_REPORT,
  payload,
});

export interface FetchListIntentionsPayload {
  intentionYear: number;
  churchIds: number[];
}
export type GenerateIntentionListReportActionType =
  CdAction<FetchListIntentionsPayload>;
export const GENERATE_INTENTION_LIST_REPORT =
  'intention/generateIntentionListReport';
export const generateIntentionListReport: ActionCreator<
  FetchListIntentionsPayload
> = (payload) => ({
  type: GENERATE_INTENTION_LIST_REPORT,
  payload,
});

// Intention filters

export type SetIntentionFiltersActionType = CdAction<Partial<IntentionFilters>>;
export const SET_INTENTION_FILTERS = 'intention/setIntentionFilters';
export const setIntentionFilters: ActionCreator<Partial<IntentionFilters>> = (
  payload
) => ({
  type: SET_INTENTION_FILTERS,
  payload,
});

export type SetShouldShowMoreFiltersActionType = CdAction<boolean>;
export const SET_SHOULD_SHOW_MORE_FILTERS =
  'intention/setShouldShowMoreFilters';
export const setShouldShowMoreFilters: ActionCreator<boolean> = (payload) => ({
  type: SET_SHOULD_SHOW_MORE_FILTERS,
  payload,
});

export type SetIntentionsOverviewTabActionType = CdAction<IntentionTabNames>;
export const SET_INTENTIONS_OVERVIEW_TAB = 'intention/setIntentionsOverviewTab';
export const setIntentionsOverviewTab: ActionCreator<
  SetIntentionsOverviewTabActionType
> = (payload) => ({
  type: SET_INTENTIONS_OVERVIEW_TAB,
  payload,
});
