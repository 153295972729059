'use strict';

class PeopleScheduleMessageModal {
  constructor(moment) {
    'ngInject';

    (this.scheduleDate =
      moment(this.resolve.scheduleDate).toDate() ||
      moment().add(1, 'day').set('h', 8).set('m', 0).set('s', 0).toDate()),
      (this.dateOptions = {
        minDate: moment().toDate(),
      });

    this.ngModelOptions = {
      updateOn: 'blur',
    };
  }

  confirm() {
    this.close({ $value: this.scheduleDate });
  }
}

PeopleScheduleMessageModal.$inject = ['moment'];
angular
  .module('cdApp.people')
  .component('cdPeopleMessageScheduleMessageModal', {
    templateUrl:
      '@/app/people/message-editor/modals/schedule-message/schedule-message.component.html',
    controller: PeopleScheduleMessageModal,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    },
  });
