import _ from 'lodash';
import { $injector } from 'ngimport';
import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { handleSuccessMessage } from '../../shared/utils';
import { navigate } from '../../services/StateServiceFactory';
import FoundationService, {
  FoundationData,
} from '../services/FoundationService';
import {
  FoundationTypes,
  Foundation,
  FoundationStatusTypes,
} from '../models/foundation';
import {
  clearStiftung,
  clearLegat,
  fetchFoundations,
  FetchFoundationsActionType,
  fetchStiftungenSuccess,
  fetchLegateSuccess,
  FetchFoundationActionType,
  fetchStiftungSuccess,
  fetchLegatSuccess,
  CreateFoundationActionType,
  UpdateFoundationActionType,
  ChangeFoundationStatusActionType,
  DeleteFoundationActionType,
  FetchIntentionsForFoundationType,
  fetchIntentionsForFoundationSuccess,
  RestoreFoundationActionType,
  GenerateFoundationIntentionsReportActionType,
} from '../redux/foundations/Actions';
import { IntentionData } from '../services/IntentionService';

export function* getFoundationsSaga(
  action: FetchFoundationsActionType
): SagaIterator {
  const { type } = action.payload;
  const data = yield call(FoundationService.getFoundations, action.payload);
  if (type === FoundationTypes.STIFTUNGEN) {
    yield put(fetchStiftungenSuccess(data as FoundationData));
  } else {
    yield put(fetchLegateSuccess(data as FoundationData));
  }
}

export function* getFoundationSaga(
  action: FetchFoundationActionType
): SagaIterator {
  const { id, type } = action.payload;
  const data = yield call(FoundationService.getFoundation, id);
  if (type === FoundationTypes.STIFTUNGEN) {
    yield put(fetchStiftungSuccess(data as Foundation));
  } else {
    yield put(fetchLegatSuccess(data as Foundation));
  }
}

export function* createFoundationSaga(
  action: CreateFoundationActionType
): SagaIterator {
  const type = action.payload.type;
  yield call(FoundationService.createFoundation, action.payload);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  const successMessage =
    type === FoundationTypes.STIFTUNGEN
      ? gettextCatalog.getString('Successfully created stiftung.')
      : gettextCatalog.getString('Successfully created legat.');
  yield call(handleSuccessMessage, successMessage);
  // Refresh foundations by re-fetching
  const status = FoundationStatusTypes.INACTIVE;
  yield put(fetchFoundations({ type, status }));
  // Navigate to the corresponding listing
  const navigateURL =
    type === FoundationTypes.STIFTUNGEN
      ? 'app.private.intention.stiftungen.overview'
      : 'app.private.intention.legate.overview';
  navigate(navigateURL, { tab: status });
}

export function* updateFoundationSaga(
  action: UpdateFoundationActionType
): SagaIterator {
  const { id, type, updatePayload } = action.payload;
  yield call(FoundationService.updateFoundation, id, updatePayload);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  const successMessage =
    type === FoundationTypes.STIFTUNGEN
      ? gettextCatalog.getString('Successfully updated stiftung.')
      : gettextCatalog.getString('Successfully updated legat.');
  yield call(handleSuccessMessage, successMessage);
  // Clear selected foundation
  if (type === FoundationTypes.STIFTUNGEN) {
    yield put(clearStiftung());
  } else {
    yield put(clearLegat());
  }
  // Refresh foundations by re-fetching
  const status = _.get(updatePayload, 'status');
  yield put(
    fetchFoundations({
      type,
      status: _.isNil(status) ? status : undefined,
      includeDeleted: _.isNil(status),
    })
  );
  // Navigate to the corresponding listing
  const navigateURL =
    type === FoundationTypes.STIFTUNGEN
      ? 'app.private.intention.stiftungen.overview'
      : 'app.private.intention.legate.overview';
  navigate(navigateURL, { tab: status });
}

export function* changeFoundationStatusSaga(
  action: ChangeFoundationStatusActionType
): SagaIterator {
  const { id, type, newStatus, oldStatus, startYear, filters } = action.payload;
  yield call(
    FoundationService.changeFoundationStatus,
    id,
    newStatus,
    startYear
  );
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  let successMessage;
  if (
    type === FoundationTypes.STIFTUNGEN &&
    newStatus === FoundationStatusTypes.ACTIVE
  ) {
    successMessage = gettextCatalog.getString(
      'Successfully activated stiftung.'
    );
  } else if (
    type === FoundationTypes.STIFTUNGEN &&
    newStatus === FoundationStatusTypes.INACTIVE
  ) {
    successMessage = gettextCatalog.getString(
      'Successfully deactivated stiftung.'
    );
  } else if (
    type === FoundationTypes.STIFTUNGEN &&
    newStatus === FoundationStatusTypes.COMPLETED
  ) {
    successMessage = gettextCatalog.getString(
      'Successfully completed stiftung.'
    );
  } else if (
    type === FoundationTypes.LEGATE &&
    newStatus === FoundationStatusTypes.ACTIVE
  ) {
    successMessage = gettextCatalog.getString('Successfully activated legat.');
  } else if (
    type === FoundationTypes.LEGATE &&
    newStatus === FoundationStatusTypes.INACTIVE
  ) {
    successMessage = gettextCatalog.getString(
      'Successfully deactivated legat.'
    );
  } else if (
    type === FoundationTypes.LEGATE &&
    newStatus === FoundationStatusTypes.COMPLETED
  ) {
    successMessage = gettextCatalog.getString('Successfully completed legat.');
  }
  yield call(handleSuccessMessage, successMessage);
  // Refresh old status to update foundations table
  yield put(fetchFoundations(_.extend(filters, { type, status: oldStatus })));
  // Clear the selected foundation
  if (type === FoundationTypes.STIFTUNGEN) {
    yield put(clearStiftung());
  } else {
    yield put(clearLegat());
  }
}

export function* deleteFoundationSaga(
  action: DeleteFoundationActionType
): SagaIterator {
  const { id, type, status } = action.payload;
  yield call(FoundationService.deleteFoundation, id);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  const successMessage =
    type === FoundationTypes.STIFTUNGEN
      ? gettextCatalog.getString('Successfully deleted stiftung.')
      : gettextCatalog.getString('Successfully deleted legat.');
  yield call(handleSuccessMessage, successMessage);
  // Refresh intentions by re-fetching
  yield put(
    fetchFoundations({
      type,
      status: _.isNil(status) ? status : undefined,
      includeDeleted: _.isNil(status),
    })
  );
}

export function* restoreFoundationSaga(
  action: RestoreFoundationActionType
): SagaIterator {
  const { type, id } = action.payload;
  yield call(FoundationService.restoreFoundation, id);

  const gettextCatalog: any = $injector.get('gettextCatalog');
  // Show success message
  const successMessage =
    type === FoundationTypes.STIFTUNGEN
      ? gettextCatalog.getString('Successfully restored stiftung.')
      : gettextCatalog.getString('Successfully restored legat.');

  yield call(handleSuccessMessage, successMessage);

  // Refresh foundation by refetching
  yield put(fetchFoundations({ type, includeDeleted: true }));
}

export function* fetchIntentionsForFoundationSaga(
  action: FetchIntentionsForFoundationType
): SagaIterator {
  const { type } = action.payload;

  const data: IntentionData = yield call(
    FoundationService.fetchIntentionsForFoundation,
    action.payload
  );
  yield put(fetchIntentionsForFoundationSuccess({ type, data }));
}

export function* generateFoundationIntentionsReport(
  action: GenerateFoundationIntentionsReportActionType
): SagaIterator {
  const { id, type } = action.payload;
  const downloadToken = yield call(
    FoundationService.getFoundationIntentionsDownloadToken,
    type
  );
  const params = $.param({ foundationId: id, type, downloadToken });
  const fileUrl = `${window.cdApp.config.api.main}/intention/intention-reports/foundation-intentions/report?${params}`;
  yield call(window.open, fileUrl, '_blank');
}
