import produce, { Draft } from 'immer';

import {
  PARTNER_FILES_REQUEST_SUCCESS,
  PARTNER_FILE_SELECTED,
  PARTNER_FILES_PAGE_CHANGE,
  PARTNER_FILES_PAGE_SIZE_CHANGE,
  PARTNER_FILES_REQUEST,
  PARTNER_FILES_GET_FILTERS_SUCCESS,
  CLOSE_MODAL,
} from '../actions/constants';
import { PartnerFiles, PartnerFile } from '../actions/partner';

const initialState: PartnerFiles = {
  items: [],
  selectedFile: undefined,
  partnerFilesLoading: false,
  filters: {
    publication: [],
  },
  pagination: {
    pageSizeOptions: [25, 50, 100],
    pageSize: 25,
    currentPage: 1,
    count: 0,
  },
};

const partnerFilesReducer = (state = initialState, action) =>
  produce(state, (draft: Draft<PartnerFiles>) => {
    const { type, payload } = action;
    switch (type) {
      case PARTNER_FILES_REQUEST:
        draft.partnerFilesLoading = true;
        break;
      case PARTNER_FILES_REQUEST_SUCCESS:
        draft.items = payload.items;
        draft.pagination.count = payload.count;
        draft.partnerFilesLoading = false;
        return;
      case PARTNER_FILE_SELECTED:
        draft.selectedFile = draft.items.find(
          (item: PartnerFile) => item.id === payload.fileId
        );
        return;
      case PARTNER_FILES_PAGE_CHANGE:
        draft.pagination.currentPage = payload.page;
        return;
      case PARTNER_FILES_PAGE_SIZE_CHANGE:
        draft.pagination.pageSize = payload.pageSize;
        return;
      case PARTNER_FILES_GET_FILTERS_SUCCESS:
        draft.filters.publication = payload;
        return;
      case CLOSE_MODAL:
        draft.items = initialState.items;
        draft.pagination = initialState.pagination;
        draft.selectedFile = initialState.selectedFile;
        break;
    }
  });

export default partnerFilesReducer;
