import { call, put, fork } from 'redux-saga/effects';

import {
  loadPermissions,
  loadGroups,
  loadUploadValidators,
} from '../../services/lookups';
import {
  loadGroupsSuccess,
  loadPermissionsSuccess,
  loadFileValidatorSuccess,
} from '../actions/lookups';
import { getMe, Settings } from '../../services/me';
import { loadSettingsSuccess } from '../reducers/lookups';

import cdApp from '@/react/config';
import { Me } from '@/react/shared/models/authentication';

function* loadPermissionsSaga() {
  try {
    const permissions = yield call(loadPermissions);
    yield put(loadPermissionsSuccess(permissions));
  } catch (error) {}
}

function getOrganizationSettings(me: Me): Settings | any {
  const organization = me.organizations.find(
    (organization) => organization.id === cdApp.organization.id
  );
  return organization ? organization.settings : {};
}

function* loadSettings(me: Me) {
  try {
    const settings = yield call(getOrganizationSettings, me);
    yield put(loadSettingsSuccess(settings));
  } catch (error) {}
}

function* loadGroupsSaga() {
  try {
    const me = yield call(getMe);
    yield fork(loadSettings, me);
    const groups = yield call(loadGroups, me.id);
    yield put(loadGroupsSuccess(groups));
  } catch (error) {}
}

function* loadValidatorSaga() {
  try {
    const validator = yield call(loadUploadValidators);
    yield put(loadFileValidatorSuccess(validator));
  } catch (error) {}
}

export function* loadLookups() {
  yield fork(loadGroupsSaga);
  yield fork(loadValidatorSaga);
  yield fork(loadPermissionsSaga);
}
