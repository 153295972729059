import React from 'react';
import { Form } from 'antd';

import { gettextCatalog } from '../../../services/I18nService';
import { useAcceptLegalTermsModal } from '../../hooks/useAcceptLegalTermsModal';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import cdApp from '@/react/config';
import { CdCountrySelect } from '@/react/shared/components/cd-country-select/CdCountrySelect';
import { getString } from '@/react/services/GetStringX';

export const showAcceptLegalTermsModal = createCdModal(({ setModalProps }) => {
  const { form } = useAcceptLegalTermsModal(setModalProps);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ country: cdApp.organization.countryIso2 }}
    >
      <Form.Item
        label={gettextCatalog.getString('Select location for currency')}
        name="country"
      >
        <CdCountrySelect
          placeholder={gettextCatalog.getString('Country')}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item
        label={gettextCatalog.getString('ChurchDesk Payment Services')}
      >
        {getString(
          'ChurchDesk Payment services for {{ organization }} are provided by Stripe and are subject to the {{ connectedAccountTerms }}, which includes the {{ stripeTerms }}.',
          {
            organization: cdApp.organization.name,
            connectedAccountTerms: `<a href="https://stripe.com/connect-account/legal" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
              'Stripe Connected Account Agreement'
            )}</a>`,
            stripeTerms: `<a href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
              'Stripe Terms of Service'
            )}</a>`,
          }
        )}
      </Form.Item>
      {gettextCatalog.getString(
        'By confirming, you agree to the ChurchDesk Payment Services.'
      )}
    </Form>
  );
});
