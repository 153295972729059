import { find, sortBy } from 'lodash';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { separateRecipientSelection } from '../message-editor/shared/containers/recipient-selector/recipient-selector.lib';
import {
  messageRecipientsCombinedState,
  messageRecipientsState,
} from '../store/message';
import { peopleSimpleSelector, personFromSearchState } from '../store/people';
import { peopleSegmentsQuery } from '../store/peopleSegment';
import { SegmentType } from '../types/segment.type';

export default function useRecipient(value: string[]) {
  const messageRecipients = useRecoilValue(
    messageRecipientsCombinedState(value)
  );
  const messageRecipientsQuery = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        search,
        churchIds,
        toValue,
      }: {
        search?: string;
        churchIds: number[];
        toValue: string[];
      }) => {
        const release = snapshot.retain();
        let people = [];
        let segments = [];

        const { personIds } = separateRecipientSelection(toValue);
        try {
          [people, segments] = await Promise.all([
            snapshot.getPromise(peopleSimpleSelector({ search, churchIds })),
            snapshot.getPromise(
              peopleSegmentsQuery({
                search,
                type: [SegmentType.SEGMENT, SegmentType.ORGANIZATION],
              })
            ),
          ]);

          // Identify the people we are missing data on.
          const missingPeople: number[] = personIds.reduce(
            (accumulator, personId: number) => {
              const personExists = snapshot.getLoadable(
                personFromSearchState(personId)
              ).contents;
              if (!personExists) accumulator.push(personId);
              return accumulator;
            },
            []
          );
          // Fetch the people that we don't have data on.
          if (missingPeople.length > 0) {
            const missingPeopleFromSelection = await snapshot.getPromise(
              peopleSimpleSelector({ personIds: missingPeople, churchIds })
            );
            // Cache previously fetched people.
            missingPeopleFromSelection?.forEach((person) => {
              const personExists = snapshot.getLoadable(
                personFromSearchState(person.id)
              ).contents;
              if (!personExists) {
                set(personFromSearchState(person.id), person);
              }
            });
          }
        } finally {
          release();
        }
        const orderedPeople = sortBy(
          people,
          (person) =>
            find(person.fields, { property: 'firstName' }).value ||
            find(person.fields, { property: 'lastName' }).value
        );
        set(messageRecipientsState, { people: orderedPeople, segments });
      },
    []
  );

  return {
    messageRecipients,
    messageRecipientsQuery,
  };
}
