import NiceModal, { antdDrawerV5 } from '@ebay/nice-modal-react';
import React, { Suspense } from 'react';
import { Button, Drawer, Space } from 'antd';
import { ErrorBoundary } from '@sentry/react';

import { gettextCatalog } from '../../services/I18nService';

import { WidgetInterface, WidgetType } from './types/widget.type';
import { CreateWidgetSuspended } from './CreateWidgetSuspended';
import { WidgetTypeSelection } from './WidgetTypeSelection';
import { useWidgetDrawer, PAGES } from './hooks/use-widget-drawer';
import BlogWidgetForm from './components/forms/BlogWidgetForm';
import EventWidgetForm from './components/forms/EventWidgetForm';
import { SlideshowWidgetForm } from './components/forms/SlideshowWidgetForm';

export type WidgetDrawerInterface = {
  widgetData: WidgetInterface;
  onSaveWidget: ({
    id,
    type,
    widgetData,
  }: {
    id: number;
    type: string;
    widgetData: WidgetInterface;
  }) => void;
};
export const WidgetDrawer = NiceModal.create<WidgetDrawerInterface>(
  ({ widgetData, onSaveWidget }) => {
    const {
      widgetForm,
      onNextClick,
      widgetType,
      setWidgetType,
      page,
      onOkClick,
      modal,
      onClose,
      widgetDataForEvent,
    } = useWidgetDrawer({
      widgetData,
      onSaveWidget,
    });
    let widgetDrawerTitle = gettextCatalog.getString('Select widget type');
    if (page === PAGES.WIDGET_DETAILS_FORM) {
      if (widgetData?.id) {
        widgetDrawerTitle = gettextCatalog.getString('Edit widget');
      } else {
        widgetDrawerTitle = gettextCatalog.getString('Create widget');
      }
    }

    const renderWidgetForm = (type) => {
      switch (type) {
        case WidgetType.BLOG:
          return (
            <BlogWidgetForm widgetData={widgetData} widgetForm={widgetForm} />
          );
        case WidgetType.EVENT:
          return (
            <EventWidgetForm
              widgetData={widgetDataForEvent}
              widgetForm={widgetForm}
            />
          );
        case WidgetType.SLIDESHOW:
          return (
            <SlideshowWidgetForm
              widgetData={widgetData}
              widgetForm={widgetForm}
            />
          );
      }
    };

    return (
      <Drawer
        {...antdDrawerV5(modal)}
        title={widgetDrawerTitle}
        width={528}
        bodyStyle={{ paddingTop: '0px' }}
        destroyOnClose
        onClose={onClose}
        footer={
          <Space style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={onClose}>
              {gettextCatalog.getString('Cancel')}
            </Button>
            {page === PAGES.WIDGET_DETAILS_FORM && (
              <Button type="primary" onClick={onOkClick}>
                {gettextCatalog.getString('Ok')}
              </Button>
            )}
            {page === PAGES.WIDGET_TYPE_SELECTION && (
              <Button
                disabled={!widgetType}
                type="primary"
                onClick={onNextClick}
              >
                {gettextCatalog.getString('Next')}
              </Button>
            )}
          </Space>
        }
      >
        <Suspense fallback={<CreateWidgetSuspended />}>
          <ErrorBoundary fallback={<>Something went wrong.</>}>
            {page === PAGES.WIDGET_TYPE_SELECTION && (
              <WidgetTypeSelection onValueChange={setWidgetType} />
            )}

            {page === PAGES.WIDGET_DETAILS_FORM && renderWidgetForm(widgetType)}
          </ErrorBoundary>
        </Suspense>
      </Drawer>
    );
  }
);
