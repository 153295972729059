import { useMemo } from 'react';
import { debounce } from 'lodash';
import { useRecoilValue, useRecoilCallback } from 'recoil';

import {
  BlogSearchText,
  BlogSearchQuery,
  MessageBlogIds,
} from '../store/message';

export default function useBlogs() {
  const searchText = useRecoilValue(BlogSearchText);
  const blogSearchResult = useRecoilValue(BlogSearchQuery);
  const selectedBlogIds = useRecoilValue(MessageBlogIds);

  return {
    blogSearchResult,
    searchText,
    selectedBlogIds,
  };
}

export const useSearch = () => {
  const searchBlogs = useRecoilCallback(
    ({ set }) =>
      (searchValue) =>
        set(BlogSearchText, searchValue),
    []
  );
  return {
    searchBlogs,
    debounced: {
      searchBlogs: useMemo(() => debounce(searchBlogs, 1000), [searchBlogs]),
    },
  };
};
