'use strict';
cdHasPackage.$inject = ['Authorization'];

function cdHasPackage(Authorization) {
  return {
    restrict: 'A',
    scope: {
      package: '=cdHasPackage',
    },

    link: function (scope, element) {
      if (!_.isString(scope.package) && !_.isArray(scope.package)) {
        throw 'The value must be a string or an array of strings.';
      }

      if (!Authorization.hasPackage(scope.package)) {
        element.remove();
        scope.$destroy();
      }
    },
  };
}

angular.module('cdApp.shared').directive('cdHasPackage', cdHasPackage);

function cdMissingPackage(Authorization) {
  return {
    restrict: 'A',
    scope: {
      package: '=cdMissingPackage',
    },

    link: function (scope, element) {
      if (!_.isString(scope.package) && !_.isArray(scope.package)) {
        throw 'The value must be a string or an array of strings.';
      }

      if (Authorization.hasPackage(scope.package)) {
        element.remove();
        scope.$destroy();
      }
    },
  };
}
cdMissingPackage.$inject = ['Authorization'];
angular.module('cdApp.shared').directive('cdMissingPackage', cdMissingPackage);
