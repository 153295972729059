(function () {
  'use strict';
  cdCommentsController.$inject = ['moment', '$uibModal', 'gettextCatalog'];

  function cdCommentsController(moment, $uibModal, gettextCatalog) {
    let $ctrl = this;

    $ctrl.$onInit = function () {
      $ctrl.asReply = $ctrl.asReply || false;
      $ctrl.placeholderText = $ctrl.asReply
        ? gettextCatalog.getString('Write a reply...')
        : gettextCatalog.getString('Write a comment...');
    };

    $ctrl.since = function (number) {
      let m = moment(number);
      return m.fromNow();
    };

    $ctrl.edit = function (comment) {
      $uibModal.open({
        templateUrl:
          '@/app/shared/components/cd-comments/modals/edit-comment-modal.html',
        controllerAs: '$ctrl',
        bindToController: true,
        controller: [
          '$uibModalInstance',
          'comment',
          function ($uibModalInstance, comment) {
            'ngInject';

            let modalCtrl = this;
            modalCtrl.comment = angular.copy(comment);
            modalCtrl.updateComment = function () {
              modalCtrl.processing = true;
              $ctrl.editComment({
                comment: modalCtrl.comment,
                onSuccess: function () {
                  $uibModalInstance.close();
                },
              });
            };
            modalCtrl.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
        resolve: {
          comment: function () {
            return comment;
          },
        },
      });
    };

    $ctrl.delete = function (comment) {
      $uibModal.open({
        templateUrl:
          '@/app/shared/components/cd-comments/modals/delete-comment-confirmation-modal.html',
        controllerAs: '$ctrl',
        bindToController: true,
        controller: [
          '$uibModalInstance',
          'comment',
          function ($uibModalInstance, comment) {
            'ngInject';

            let modalCtrl = this;
            modalCtrl.deleteComment = function () {
              modalCtrl.processing = true;
              $ctrl.deleteComment({
                comment: comment,
                onSuccess: function () {
                  $uibModalInstance.close();
                },
              });
            };
            modalCtrl.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
        resolve: {
          comment: function () {
            return comment;
          },
        },
      });
    };
  }

  angular.module('cdApp.shared').component('cdComments', {
    templateUrl: '@/app/shared/components/cd-comments/cd-comments.html',
    controller: cdCommentsController,
    bindings: {
      comments: '<',
      addComment: '&',
      editComment: '&',
      deleteComment: '&',
      showForm: '<',
      asReply: '<',
    },
  });
})();
