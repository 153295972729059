import React from 'react';
import { Form, Typography } from 'antd';

import { StandardWorkingHoursInterface } from './types/timeRegistration.types';
import { TimeRegistrationHoursInput } from './TimeRegistrationHoursInput';

import { gettextCatalog } from '@/react/services/I18nService';
import { required } from '@/react/shared/antd/validations/common';

export const StandardWorkingHours = ({
  onChange,
  value,
}: {
  onChange?: (value: StandardWorkingHoursInterface) => void;
  value?: StandardWorkingHoursInterface;
}) => {
  const onChangeHandler = (workingHours, name) => {
    onChange &&
      onChange({
        ...value,
        [name]: workingHours,
      });
  };

  const weekDays = [
    { day: 'monday', dayString: gettextCatalog.getString('Monday') },
    { day: 'tuesday', dayString: gettextCatalog.getString('Tuesday') },
    { day: 'wednesday', dayString: gettextCatalog.getString('Wednesday') },
    { day: 'thursday', dayString: gettextCatalog.getString('Thursday') },
    { day: 'friday', dayString: gettextCatalog.getString('Friday') },
    { day: 'saturday', dayString: gettextCatalog.getString('Saturday') },
    { day: 'sunday', dayString: gettextCatalog.getString('Sunday') },
  ];

  const renderStandardHoursForEachDay = weekDays.map((item) => (
    <Form.Item
      name={['standardWorkingHours', item.day]}
      rules={[required()]}
      label={item.dayString}
      key={`${item.day}`}
      style={{ width: '100%', marginBottom: 8, textAlign: 'right' }}
      labelAlign="left"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    >
      <TimeRegistrationHoursInput
        onChange={(e) => onChangeHandler(e, item.day)}
        defaultValue={value?.[item.day]}
      />
    </Form.Item>
  ));

  return (
    <>
      <Typography.Text type="secondary">
        {gettextCatalog.getString(
          'The standard hours will automatically input your work hours on future dates, and will be used to compare worked hours in time registration reports. Please note that e.g. half an hour is 0.5 hours.'
        )}
      </Typography.Text>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {renderStandardHoursForEachDay}
      </div>
    </>
  );
};
