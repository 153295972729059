import { Space, Avatar, Typography, Select } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { ChurchSettingsState } from '@/react/organization/store/church';
import { CdChurch, CdUserAvatar } from '@/react/shared/components/Icons';
import { Me } from '@/react/shared/models/authentication';
import { MeState } from '@/react/user/store/user-session';
const { Text } = Typography;
import { gettextCatalog } from '@/react/services/I18nService';

const fromSelectedRender = (
  displayName: string,
  email: string,
  type: string
) => (
  <Space>
    <Avatar
      icon={type === 'user' ? <CdUserAvatar /> : <CdChurch />}
      size="small"
    />
    {displayName}
    {`<${email}>`}
  </Space>
);

const fromSelectOption = (displayName: string, email: string, type: string) => (
  <Space>
    <Avatar icon={type === 'user' ? <CdUserAvatar /> : <CdChurch />} />
    <div>
      <Text strong>{displayName}</Text>
      <br />
      <Text type="secondary">
        {gettextCatalog.getString('Replies go to:')}{' '}
        <Text style={{ fontStyle: 'italic' }}>{email}</Text>
      </Text>
    </div>
  </Space>
);

interface SenderSelectorProps {
  onChange?: any;
  value?: string;
  disabled: boolean;
}

const SenderSelector = ({ onChange, value, disabled }: SenderSelectorProps) => {
  const churchSettings = useRecoilValue(ChurchSettingsState);
  const me: Me = useRecoilValue(MeState);

  // displaylabel needs to be lowercase to not trigger an React error.
  if (!onChange && !value) {
    return null;
  }
  return (
    <Select
      onChange={onChange}
      value={value}
      optionLabelProp="displaylabel"
      defaultActiveFirstOption={true}
      disabled={disabled}
    >
      <Select.Option
        value="user"
        displaylabel={fromSelectedRender(me.fullName, me.email, 'user')}
      >
        {fromSelectOption(me.fullName, me.email, 'user')}
      </Select.Option>
      <Select.Option
        value="church"
        displaylabel={fromSelectedRender(
          churchSettings.name,
          churchSettings.organizationEmail ||
            gettextCatalog.getString('Missing'),
          'org'
        )}
      >
        {fromSelectOption(
          churchSettings.name,
          churchSettings.organizationEmail ||
            gettextCatalog.getString('Missing'),
          'org'
        )}
      </Select.Option>
    </Select>
  );
};

export default SenderSelector;
