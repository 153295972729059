import { mainApi } from '../react/api';

export async function getCDAccessToken(loginToken: string, returnUrl: string) {
  try {
    // make sure we log the user out before getting new access token
    await mainApi.get('users/logout');

    if (localStorage.getItem('churchdesk.masqueradeAccessToken')) {
      localStorage.removeItem('churchdesk.masqueradeAccessToken');
    }
    localStorage.removeItem('churchdesk.accessToken');

    try {
      const response = await mainApi.post<{ accessToken: string }>(
        'login/token',
        { loginToken }
      );
      if (200 !== response.status) {
        throw response.data;
      }
      localStorage.setItem('churchdesk.accessToken', response.data.accessToken);
      window.location.href = returnUrl;
    } catch (e) {
      throw e;
    }
  } catch (e) {
    throw e;
  }
}

export async function getCDLoginToken(redirectUrl: string) {
  try {
    const response = await mainApi.get<{ loginToken: string }>('login/token', {
      accessToken: localStorage.getItem('churchdesk.accessToken'),
    });
    if (200 !== response.status) {
      throw response.data;
    }
    window.location.href = `${redirectUrl}/${response.data.loginToken}`;
  } catch (e) {
    throw e;
  }
}
