import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';

export const ManageSubscriptionByTokenQuery = selectorFamily<
  ManageSubscription[],
  string
>({
  key: 'ManageSubscriptionByTokenQuery',
  get: (token: string) => async () => {
    const { ok, data } = await mainApi.get<ManageSubscription[]>(
      `/people/people/manage-subscriptions?token=${token}`
    );
    if (ok) {
      const contactsInformations = data;
      const result = contactsInformations.map((contact) => {
        const sortedConsents = contact.consents.sort(
          (a, b) => +b.defaultConsent - +a.defaultConsent
        );
        const subscribedParticipantLists = contact.participantLists.filter(
          (participant) => participant.subscribed
        );
        return {
          ...contact,
          consents: sortedConsents,
          participantLists: subscribedParticipantLists,
        };
      });
      return result;
    } else {
      return [];
    }
  },
});
export interface ManageSubscription {
  id: number;
  name: string;
  email: string;
  newsletters: NewsletterAndFilterType[];
  participantLists: NewsletterAndFilterType[];
  filters: NewsletterAndFilterType[];
  consents: ConsentType[];
  receiveCommunicationFromFilters: boolean;
  receiver: boolean;
}

interface NewsletterAndFilterType {
  id: number;
  name: string;
  subscribed: boolean;
}

interface ConsentType {
  id: number;
  name: string;
  consented: boolean;
  url: string;
  defaultConsent: boolean;
}
