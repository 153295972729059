import cdApp from '@/react/config';

export const getExternalLink = (
  entityId: number,
  entityType: 'event' | 'blog'
) => {
  const language = cdApp.organization.locale.language || 'en';
  if (entityType === 'blog') {
    return `${cdApp.references.landingPage}/${language}/b/${entityId}`;
  } else {
    return `${cdApp.references.landingPage}/${language}/e/${entityId}`;
  }
};
