'use strict';

/**
 * A dropdown that displays the list of people tags and allows the user to add tags on the fly as well.
 *
 * @prop {Object[]} tagList - List of tags to choose from
 * @prop {Integer} tagList[].id - Tag's id
 * @prop {String} tagList[].name - Tag's name
 * @prop {Integer[]} initialTags - The inital list of tags ids to populate the dropdown with
 * @prop {Function} onTagCreated - Callback when an added tag `newTag` is created and saved in the backend
 * @prop {Function} onTagsUpdated - Callback when the selected tags `newTags` are changed
 * @prop {Boolean} saveImmediately - Whether newly added tags should be saved in the backend immediately
 * @prop {String} placeholder - Default text when no selection active
 * @prop {String} position - Define where the dropdown should open. 'up', 'down' or 'auto'
 * @prop {Attribute} autofocus - Whether to get focus automatically when loaded (Omit if you don't want the dropdown to receive focus)
 *
 * @example     <cd-select-tags-dropdown tag-list="$ctrl.peopleTags"
 *                                       selected-tags="$ctrl.person.tags"
 *                                       on-tag-created="$ctrl.peopleTags.push(newTag)"
 *                                       on-tags-updated="$ctrl.person.tags = newTags"
 *                                       save-immediately="true"
 *                                       placeholder="'Choose tags or add a new tag'"
 *                                       position="'up'">
 */
angular.module('cdApp.people').component('cdSelectTagsDropdown', {
  templateUrl:
    '@/app/people/shared/components/select-tags-dropdown/select-tags-dropdown.html',
  bindings: {
    tagList: '<',
    initialTags: '<',
    onTagCreated: '&',
    onTagsUpdated: '&',
    saveImmediately: '<',
    placeholder: '<',
    position: '<',
    autofocus: '<',
  },

  controller: [
    'PeopleTags',
    'gettextCatalog',
    'Analytics',
    function (PeopleTags, gettextCatalog, Analytics) {
      'ngInject';

      let $ctrl = this;

      /**
       * Lifecycle hook used for initialization work
       */
      $ctrl.$onInit = function () {
        $ctrl.selectedTags = _.filter($ctrl.tagList, function (tag) {
          return _.includes($ctrl.initialTags, tag.id);
        });
        $ctrl.newTags = [];
        $ctrl.placeholder =
          $ctrl.placeholder ||
          gettextCatalog.getString('Choose one or more tags...');
      };

      /**
       * Transform a tag name into a tag instance object
       *
       * @param {String} tagName - The name of the tag
       */
      $ctrl.transformTagName = function (tagName) {
        if (_.some($ctrl.tagList, { name: tagName })) return;
        return new PeopleTags({ name: tagName });
      };

      /**
       * If the component is configured to create tags on the fly, the tag that were just
       * transformed by the 'transformTagName' will be posted to the API to be saved
       * @example `<cd-select-tags-dropdown save-immediately="true"></cd-select-tags-dropdown>`
       */
      $ctrl.onSelect = function ($item) {
        if ($ctrl.saveImmediately && !$item.id) {
          let tag = new PeopleTags({ name: $item.name });
          tag.$save(function (newTag) {
            Analytics.sendFeatureEvent('create tags', { module: 'people' });
            _.pull($ctrl.selectedTags, $item);
            $ctrl.selectedTags.push(newTag);
            $ctrl.onTagCreated({ newTag: newTag });
            $ctrl.onTagsUpdated({ newTags: $ctrl.selectedTags });
          });
        }
      };
    },
  ],
});
