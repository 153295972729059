import React from 'react';
import { Result } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';

import { gettextCatalog } from '@/react/services/I18nService';

const EmptyContainer: React.FC = () => (
  <Result
    subTitle={gettextCatalog.getString('Please choose an image.')}
    icon={<FontAwesomeIcon icon={faFileImage} size="3x" />}
  />
);

export default EmptyContainer;
