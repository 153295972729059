import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const StyledA = styled.a`
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #f6f7f8;
  width: 100%;
  height: 100%;
  padding: 0;
  display: block;
  line-height: 1.42857143;
  border: 1px solid #ddd;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: #008db6;
  }
`;

const Selector = styled.div`
  height: 1.3em;
  width: 1.3em;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 10px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 1.5em !important;
  height: 1.5em !important;
  margin-left: -1px;
  margin-top: -1px;
`;

const Item: React.FC<{ item: any; onFileSelected: any; selectedFile: any }> = ({
  item,
  onFileSelected,
  selectedFile,
}) => {
  let icon;
  if (selectedFile && item.id === selectedFile.id) {
    icon = (
      <StyledFontAwesomeIcon
        icon={faCheckCircle}
        style={{ color: '@primaryColor' }}
      />
    );
  } else {
    icon = <StyledFontAwesomeIcon icon={faCircle} />;
  }
  return (
    <StyledA
      style={{
        backgroundImage: `url(${item.url || item.fileInfo.thumbnail.url})`,
      }}
      onClick={() => onFileSelected(item.id)}
    >
      <Selector>{icon}</Selector>
    </StyledA>
  );
};
export default Item;
