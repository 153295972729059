import _ from 'lodash';

/**
 * A dropdown that can be used to filter items in a list.
 *
 * @prop {Object[]} items - The list of filters shown in the dropdown { id, name }
 * @prop {Number[] | string[]} selectedItems - The ids of the selected items in the list
 * @prop {Boolean} groupable - Whether the list of filters should be grouped
 * @prop {} defaultLabel - The label of the dropdown when there are no filters selected
 * @prop {} buttonClasses - Button classes, i.e. btn-default, btn-primary, btn-danger. (default is btn-basic)
 * @prop {} selectedLabel - The label of the dropdown when there are some filters selected
 * @prop {Function} onChanged({ selected: Number[] | string[] }) - A callback whenever the selected filters are changed.
 */
class DropdownFilterComponent {
  $onInit() {
    this.focusSearch = false;
  }

  onDropdownToggle(open) {
    this.focusSearch = open;
  }

  areAllSelected(group) {
    // If group get the difference between the items in the group and total selected items.
    // If the difference array is empty than all are selected
    if (group) {
      return _.isEmpty(
        _.difference(_.map(this.items[group], 'id'), this.selectedItems)
      );
    } else return _.isEqual(_.map(this.items, 'id'), this.selectedItems);
  }

  isSelected(itemId) {
    return _.includes(this.selectedItems, itemId);
  }

  hasColor(item) {
    return !_.isUndefined(item.color);
  }

  hasPicture(item) {
    return !_.isUndefined(item.picture);
  }

  toggleItem(item) {
    if (_.isUndefined(item)) return;

    const selected = _.includes(this.selectedItems, item)
      ? _.without(this.selectedItems, item)
      : [...this.selectedItems, item];

    this.onChanged({ selected });
  }
  // Event: A string that shows the type of event that was clicked. ['Reset' or 'Toggle']
  // Group: A string that represents group name. If empty than no specific group
  toggleAllColumns(event, group) {
    if (event === 'Reset') {
      this.onChanged({ selected: [] });
      return;
    }
    if (group) {
      let newSelectedItems = [];
      // If group get the difference between the items in the group and total selected items.
      // If the difference array is empty than all are selected
      if (
        _.isEmpty(
          _.difference(_.map(this.items[group], 'id'), this.selectedItems)
        )
      ) {
        // Selected but not in the group
        newSelectedItems = _.uniq(
          _.compact(
            _.difference(this.selectedItems, _.map(this.items[group], 'id'))
          )
        );
      } else {
        // All group items + any other that are already selected
        newSelectedItems = _.uniq(
          _.compact(
            _.concat(_.map(this.items[group], 'id'), this.selectedItems)
          )
        );
      }
      this.onChanged({ selected: newSelectedItems });
    } else {
      // Not a specific group
      // All selected
      if (_.isEqual(_.map(this.items, 'id'), this.selectedItems)) {
        // Reset
        this.onChanged({ selected: [] });
      } else {
        // All selected
        // Select all
        this.onChanged({ selected: _.map(this.items, 'id') });
      }
    }
  }
}

angular.module('cdApp.shared').component('cdDropdownFilter', {
  template: require('./dropdown-filter.component.html'),
  controller: DropdownFilterComponent,
  bindings: {
    items: '<',
    selectedItems: '<',
    groupable: '<',
    defaultLabel: '<',
    buttonClasses: '<',
    selectedLabel: '<',
    onChanged: '&',
  },
});
