(function () {
  'use strict';

  angular.module('cdApp').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('app.private.dashboard', {
          url: '/dashboard',
          abstract: true,
          resolve: {
            $title: [
              'gettext',
              'gettextCatalog',
              'routingHelpers',
              function (gettext, gettextCatalog, routingHelpers) {
                const base = gettext('Dashboard');
                return {
                  base,
                  rendered: routingHelpers.buildTitle(
                    gettextCatalog.getString(base)
                  ),
                };
              },
            ],
          },

          template: '<div class="app-view" ui-view></div>',
        })
        .state('app.private.dashboard.default', {
          url: '',
          templateUrl: '@/app/dashboard/dashboard.html',
          controller: 'DashboardController',
        })
        .state('app.private.dashboard.extended', {
          url: '/:state',
          templateUrl: '@/app/dashboard/dashboard-extended.html',
          controller: 'DashboardController',
        })
        .state('app.private.dashboard.timeRegistration', {
          url: '/time-registration',
          component: 'timeRegistrationsWidget',
        });
    },
  ]);
})();
