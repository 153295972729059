import { createSelector } from 'reselect';

import { FileArchives, FileArchive } from '../../store/actions/filearchive';

const selectFileArchiveFiles = (state: any) => state.filepicker?.fileArchives;
const selectOwnProps = (state: any, props: any) => props;

const makeSelectFiles = createSelector(
  selectFileArchiveFiles,
  (fileArchives: FileArchives) => fileArchives?.items
);

const makeSelectSearch = createSelector(
  selectFileArchiveFiles,
  (fileArchives: FileArchives) => fileArchives?.search
);
const makeSelectOrdering = createSelector(
  selectFileArchiveFiles,
  (fileArchives: FileArchives) => fileArchives?.ordering
);

const makeSelectSelectedFileId = createSelector(
  selectFileArchiveFiles,
  (fileArchives: FileArchives) => fileArchives?.selectedFileId
);

const makeSelectSelectedFile = createSelector(
  makeSelectFiles,
  makeSelectSelectedFileId,
  (items: FileArchive[], selectedFileId: number | undefined) =>
    (items || []).find((fileArchive) => fileArchive.id === selectedFileId)
);

const makeSelectSelectedFolderId = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.selectedFolderId
  );

const makeSelectBreadcrumb = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.breadcrumb
  );

const makeSelectPageSize = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.pagination.pageSize
  );

const makeSelectCurrentPage = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.pagination.currentPage
  );

const makeSelectTotalCount = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.pagination.count
  );

const makeSelectTags = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.tags
  );

const makeSelectCallback = () =>
  createSelector(selectOwnProps, (props) => props.callback);

const makeSelectReloadOnFileUpdateOrDelete = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.reloadOnFileUpdateOrDelete
  );

const makeSelectFilesLoading = () =>
  createSelector(
    selectFileArchiveFiles,
    (fileArchives: FileArchives) => fileArchives?.filesLoading
  );

const makeSelectIsUsedFromEditor = () =>
  createSelector(selectOwnProps, (props) => props.isUsedFromEditor);

export {
  makeSelectFiles,
  makeSelectSelectedFile,
  makeSelectSelectedFolderId,
  makeSelectBreadcrumb,
  makeSelectPageSize,
  makeSelectCurrentPage,
  makeSelectTotalCount,
  makeSelectCallback,
  makeSelectTags,
  makeSelectReloadOnFileUpdateOrDelete,
  makeSelectFilesLoading,
  makeSelectSearch,
  makeSelectOrdering,
  makeSelectIsUsedFromEditor,
};
