'use strict';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import { HasContactConsented } from '@/react/people/store/newsletterListState';
class PersonConsentsComponent {
  constructor(
    _,
    $q,
    $filter,
    $uibModal,
    $state,
    gettextCatalog,
    toastr,
    cdApp,
    ConsentType,
    People,
    Consent,
    Church
  ) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$filter = $filter;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.cdApp = cdApp;
    this.ConsentType = ConsentType;
    this.People = People;
    this.Consent = Consent;
    this.Church = Church;
  }

  $onInit() {
    this.isLoadingConsents = true;

    // Fetch all consent types
    this.consentTypes = this.ConsentType.query();
    // Remove consents that had their consent type removed
    this.consents = _.reject(
      this.person.consents,
      (consent) => !consent.consentType
    );

    this.$q.all([this.consentTypes.$promise]).then(() => {
      this.isLoadingConsents = false;

      // The consent types that the person hasn't been associated with yet
      this.otherConsents = this.getOtherConsents();
    });

    // Fetch requireDoubleOptIn setting
    this.Church.get((churchInfo) => {
      this.requireDoubleOptIn = churchInfo.requireDoubleOptIn;
    });
  }

  /**
   * Launch a modal to add a new consent type
   */
  createConsentType() {
    this.$uibModal
      .open({
        component: 'cdConsentTypeModal',
      })
      .result.then((newConsentType) => {
        this.consentTypes.push(newConsentType);
        this.otherConsents = this.getOtherConsents();
      });
  }

  /**
   * Get all consents
   */
  getConsents() {
    this.isLoadingConsents = true;
    this.People.get({ id: this.person.id }).$promise.then((person) => {
      // Remove consents that had their consent type removed
      this.consents = _.reject(
        person.consents,
        (consent) => !consent.consentType
      );

      this.isLoadingConsents = false;
      this.otherConsents = this.getOtherConsents();
      recoilRefresh(HasContactConsented({ contactId: this.person.id }));
    });
  }

  /**
   * Get consents that the user aren't associated to that person yet
   */
  getOtherConsents() {
    return this._.reject(this.consentTypes, (consentType) =>
      this._.some(
        this.consents,
        (consent) => _.get(consent, 'consentType.id') === consentType.id
      )
    );
  }

  /**
   * Launch a modal to add consent manually for the person
   *
   * @param {string} consentTypeId The id of consent type to give consent to
   */
  addConsent(consentTypeId, $event) {
    $event.preventDefault();

    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Register consent'),
          body: () =>
            this.gettextCatalog.getString(
              'Make sure you have clear, verifiable consent to manually register consent for this contact.'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Register'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'success',
          },
        },
      })
      .result.then(() => {
        new this.Consent({ personId: this.person.id, consentTypeId }).$save(
          () => {
            this.toastr.success(
              this.gettextCatalog.getString('Consent registered.')
            );

            this.getConsents();
          },
          () => {
            this.toastr.error(
              this.gettextCatalog.getString('Error registering consent.')
            );
          }
        );
      });
  }

  /**
   * Launch a modal to delete a consent from the person
   *
   * @param {string} consentId The id of the consent to delete
   */
  deleteConsent(consentId, $event) {
    $event.preventDefault();

    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Remove consent'),
          body: () =>
            this.gettextCatalog.getString(
              'Are you sure you want to remove the consent?'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Remove'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        new this.Consent({ id: consentId }).$delete().then(() => {
          this.getConsents();
        });
      });
  }

  /**
   * Generate an anchor tag that opens the consent type's link in a new tab
   */
  getConsentTypeLink(consentType) {
    if (consentType.link) {
      return `<a href="${consentType.link}" target="_blank" rel="noopener noreferrer">${consentType.label}</a>`;
    } else {
      const consent = this.consentTypes.find(
        (cons) => cons.id === consentType.id
      );
      return `<a href="${consent.link}" target="_blank" rel="noopener noreferrer">${consent.label}</a>`;
    }
  }

  /**
   * Generate an anchor tag that redirects to the source of the consent
   */
  getConsentSourceLink(source) {
    const { id, type, name, picture, url } = source;
    const userPicture = picture || '/img/user-default.png';
    const href = this._.get(
      {
        form: this.$state.href('app.private.forms.view', { id }),
        contribution: this.$state.href(
          'app.private.contributions.projects.view',
          { id }
        ),

        user: this.$state.href('app.private.settings.users.detail', { id }),
        subscription: url,
      },

      type,
      ''
    );

    switch (type) {
      case 'form':
      case 'contribution':
      case 'subscription':
        return `<a href="${href}">${name}</a>`;
      case 'newsletterWidget':
        return `<span>newsletter widget</span>`;
      case 'manageSubscriptions':
        return `<span>unsubscribe page</span>`;
      case 'user':
        return `
          <a href="${href}">
            <img class="img-circle u-ml-5 u-mr-5" width="20" height="20" src="${userPicture}">${name}</img>
          </a>
        `;
      default:
        return name;
    }
  }
}

PersonConsentsComponent.$inject = [
  '_',
  '$q',
  '$filter',
  '$uibModal',
  '$state',
  'gettextCatalog',
  'toastr',
  'cdApp',
  'ConsentType',
  'People',
  'Consent',
  'Church',
];

angular.module('cdApp.people').component('cdPersonConsents', {
  templateUrl:
    '@/app/people/shared/components/person-consents/person-consents.component.html',
  controller: PersonConsentsComponent,
  bindings: {
    person: '<',
  },
});
