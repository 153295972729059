'use strict';

class ConfirmationModalController {
  $onInit() {
    this.newChurches = this.resolve.newChurches;
    this.existingChurches = this.resolve.existingChurches;
    this.name = this.resolve.name;
  }

  /**
   * Close the modal by pressing the secondary button
   */
  onSecondaryButtonPress() {
    this.close({ $value: { isSecondaryButton: true } });
  }

  /**
   * Close the modal by dismissing it
   */
  cancel() {
    this.dismiss();
  }
}

angular.module('cdApp.shared').component('cdConfirmationModal', {
  templateUrl:
    '@/app/people/shared/components/create-people/confirmation-modal/confirmation-modal.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: ConfirmationModalController,
});
