import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { Form } from 'antd';
import { useCallback, useEffect } from 'react';

import { mainApi } from '../../api';
import cdApp from '../../config';
import { handleError } from '../../services/ErrorHandlingService';
import gettextCatalog from '../../services/I18nService';
import NotificationService from '../../services/NotificationService';
import {
  ModalType,
  SetModalPropsType,
} from '../../shared/components/cd-modal-new/CdModal2';

export const useAddBankAccount = (
  setModalProps: SetModalPropsType,
  country: string,
  currency: string
) => {
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  const usesAccountNumber = ['us', 'ca', 'au', 'jp', 'sg'].includes(
    cdApp.organization.countryIso2.toLowerCase()
  );

  const onOk = useCallback(
    () =>
      form.validateFields().then(
        async (values: {
          accountNumber?: string;
          routingNumber?: string;
        }) => {
          const result = usesAccountNumber
            ? await stripe.createToken('bank_account', {
                country,
                currency,
                routing_number: values.routingNumber,
                account_number: values.accountNumber,
                account_holder_name: cdApp.organization.name,
                account_holder_type: 'company',
              })
            : await stripe.createToken(elements.getElement('iban'), {
                currency,
                account_holder_name: cdApp.organization.name,
                account_holder_type: 'company',
              });

          const response = await mainApi.post(
            '/p/organizations/add/bankaccount',
            { bankAccountToken: result.token.id }
          );
          if (response.ok) {
            queryClient.invalidateQueries({
              queryKey: ['accountDetailsQuery'],
            });
            NotificationService.notifySuccess(
              gettextCatalog.getString('Updated bank information')
            );
          } else {
            handleError(response);
          }
        }
      ),
    [country, currency, elements, form, queryClient, stripe, usesAccountNumber]
  );

  useEffect(
    () =>
      setModalProps({
        modalType: ModalType.MODAL,
        width: 500,
        title: gettextCatalog.getString('Add bank account'),
        okText: gettextCatalog.getString('Save'),
        onOk,
      }),
    [setModalProps, onOk]
  );

  return {
    form,
    usesAccountNumber,
  };
};
