import { UploadFile } from 'antd/es/upload/interface';

import { mainApi } from '../../api';
import { CdrFile, Folder } from '../models/files';

interface UploadFilesPayload {
  files: UploadFile[];
  groupId: number;
  folderId: number;
  copyright: string;
  visibility: string;
  organizationId: number;
}

export async function uploadFiles({
  files,
  groupId,
  folderId,
  copyright,
  visibility,
  organizationId,
}: UploadFilesPayload): Promise<CdrFile[]> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // Upload files one at a time
  try {
    const fileList = await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('type', 'file');
        formData.append('isHtml5', 'true');
        formData.append('title', file.name);
        formData.append('groupId', groupId.toString());
        formData.append('visibility', visibility);
        formData.append('credits', copyright);
        formData.append('folderId', folderId.toString());
        formData.append('organizationId', organizationId.toString());
        formData.append('fromGallery', 'false');
        formData.append('file', file.originFileObj as any);

        const response = await mainApi.post<CdrFile>(
          `/files/upload`,
          formData,
          config
        );

        if (response.ok) {
          return response.data;
        } else {
          throw response;
        }
      })
    );
    return fileList as CdrFile[];
  } catch (err) {
    throw err;
  }
}

export async function fetchFolders(): Promise<Folder[][]> {
  const response = await mainApi.get(`/folders`);

  if (response.ok) {
    return response.data as Folder[][];
  } else {
    throw response;
  }
}
