import * as immutable from 'seamless-immutable';
import { ImmutableObject } from 'seamless-immutable';

import createReducer from '../../redux/utils';
import { ChurchMembership } from '../services/user-roles.service';
import { User, OldUserRoles } from '../types/User.types';
import { Resource } from '../../shared/models/resource';
import { RoleInterface } from '../../organization/types/organization-role.types';

import { handlers } from './reducers';

export interface UserState {
  oldRoles: OldUserRoles | any;
  churchRoles: ChurchMembership[];
  organizationRoles: RoleInterface[];
  users: User[];
  communication: {
    userOldRolesLoading: boolean;
    userChurchRolesLoading: boolean;
    userOrganizationRolesLoading: boolean;
    isUsersLoading: boolean;
  };
  permissions: {
    [key: string]: boolean;
  };
  privileges: {
    [entityType: string]: {
      [entityId: string]: {
        [privilege: string]: boolean;
      };
    };
  };
  churches: {
    [permissions: string]: ImmutableObject<Resource[]>;
  };
}
export type ImmutableUserState = ImmutableObject<UserState>;

// Initial State
export const initialState: ImmutableUserState = immutable.from<UserState>({
  oldRoles: {},
  churchRoles: [],
  organizationRoles: [],
  users: [],
  communication: {
    userOldRolesLoading: false,
    userChurchRolesLoading: false,
    userOrganizationRolesLoading: false,
    isUsersLoading: false,
  },
  permissions: {},
  privileges: {},
  churches: {},
});

export default createReducer<ImmutableUserState>(initialState, handlers);
