import produce, { Draft } from 'immer';

import { Group, Permissions, UploadValidator } from '../../services/lookups';
import {
  LOAD_FILE_VALIDATOR_SUCCESS,
  LOAD_GROUPS_SUCCESS,
  LOAD_PERMISSIONS_SUCCESS,
} from '../actions/constants';
import { Settings } from '../../services/me';

export const LOAD_SETTINGS_SUCCESS = 'filePicker/loadSettingsSuccess';
export const loadSettingsSuccess = (settings: Settings) => ({
  type: LOAD_SETTINGS_SUCCESS,
  payload: settings,
});

export interface LookupsState {
  uploadValidator: UploadValidator | null;
  permissions: Permissions | null;
  groups: Group[];
  settings: Settings | any;
}

const initialState: LookupsState = {
  uploadValidator: null,
  permissions: null,
  groups: [],
  settings: {},
};

const loadFileValidatorSuccess = (state = initialState, action) =>
  produce(state, (draft: Draft<LookupsState>) => {
    draft.uploadValidator = action.payload;
  });

const loadGroupsSuccess = (state = initialState, action) =>
  produce(state, (draft: Draft<LookupsState>) => {
    draft.groups = action.payload;
  });

const loadPermissionsSuccess = (state = initialState, action) =>
  produce(state, (draft: Draft<LookupsState>) => {
    draft.permissions = action.payload;
  });

const loadSettingsSuccessReducer = (state = initialState, action) =>
  produce(state, (draft: Draft<LookupsState>) => {
    draft.settings = action.payload;
  });

const reducersMap = {
  [LOAD_GROUPS_SUCCESS]: loadGroupsSuccess,
  [LOAD_FILE_VALIDATOR_SUCCESS]: loadFileValidatorSuccess,
  [LOAD_PERMISSIONS_SUCCESS]: loadPermissionsSuccess,
  [LOAD_SETTINGS_SUCCESS]: loadSettingsSuccessReducer,
};

export default (state = initialState, action) => {
  const reducer = reducersMap[action.type];
  if (reducer) return reducer(state, action);
  return state;
};
