import angular from 'angular';
import { react2angular } from 'react18-react2angular';

import { ConflictingPeopleRelationComponent } from '../../../../../react/people/components/ConflictingPeopleRelationComponent';

// Example <cdr-conflicting-people-relation-component />
angular
  .module('cdApp.people')
  .component(
    'cdrConflictingPeopleRelationComponent',
    react2angular(
      ConflictingPeopleRelationComponent,
      [
        'emailValue',
        'phoneValue',
        'valueType',
        'shouldListSingleConflictingPeople',
        'omittingPersonId',
        'existingRelations',
        'setSelectedValues',
      ],
      []
    )
  );
