import _ from 'lodash';

(function () {
  'use strict';

  function FeedController(
    moment,
    $scope,
    $stateParams,
    $state,
    gettextCatalog,
    eventData,
    appUtils,
    cdResourceColors
  ) {
    // =============================================================================
    // General stuff.
    // =============================================================================

    $scope.event = _.isUndefined($stateParams.rid) ? eventData : eventData.data;
    if ($scope.event.locationObj) {
      $scope.event.location = appUtils.formatGoogleLocation(
        $scope.event.locationObj
      );
    }
    $scope.eventIsRecentlyUpdated =
      moment().diff(moment($scope.event.updatedAt), 'seconds') < 60;

    $scope.isFutureEvent = moment($scope.event.startDate).isAfter();
    $scope.isAboutToStart =
      moment($scope.event.startDate).diff(moment(), 'minutes') <= 30;
    $scope.colors = cdResourceColors;

    // Display the date in different formats.
    $scope.displayDate = function (start, end, allDay, hideEndDate) {
      // It is not all day.
      if (!allDay) {
        // End time is not hidden.
        if (!hideEndDate) {
          // Difference is less than 24 hours, we only show end time.
          if (moment(end).diff(moment(start), 'hours') < 24) {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LT')
            );
          } else {
            return (
              moment(start).format('LLLL') +
              ' ' +
              gettextCatalog.getString('to', {}, 'Date interval') +
              ' ' +
              moment(end).format('LLLL')
            );
          }
        } else {
          // End date is hidden.
          return moment(start).format('LLLL');
        }
      } else {
        // Difference is less than 24 hours, we show only start date.
        if (moment(end).diff(moment(start), 'hours') < 24) {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('(all day)')
          );
        } else {
          return (
            moment(start).format('dddd, MMMM Do YYYY') +
            ' ' +
            gettextCatalog.getString('to', {}, 'Date interval') +
            ' ' +
            moment(end).format('dddd, MMMM Do YYYY')
          );
        }
      }
    };

    // Generate a date from timestamp.
    $scope.Date = function (number, format) {
      return moment(number).format(format);
    };

    // Time ago.
    $scope.timeAgo = function (number) {
      return moment(number).fromNow();
    };

    $scope.goBackToCalendar = () => {
      $state.go('app.private.calendar.full');
    };

    // Print calendar
    $scope.print = function () {
      window.print();
    };

    // Sometimes double clicking an event in the calendar triggers
    // the qtip as well, so this just makes sure the qtip is hidden
    // once the event is fully rendered.
    $(function () {
      $('.qtip').qtip('hide');
    });
  }
  FeedController.$inject = [
    'moment',
    '$scope',
    '$stateParams',
    '$state',
    'gettextCatalog',
    'eventData',
    'appUtils',
    'cdResourceColors',
  ];

  angular.module('cdApp.calendar').controller('FeedController', FeedController);
})();
