(function () {
  'use strict';

  /**
   * @desc applies html styling tags to a text
   * @example <span ng-bind-html="'Example' | styleText: 'strong'"></span>
   */

  function styleText() {
    return function (text, modifier) {
      if (!modifier) return text;

      switch (modifier) {
        case 'strong':
          return '<strong>' + text + '</strong>';
        case 'small':
          return '<small>' + text + '</small>';
        default:
          return text;
      }
    };
  }

  angular.module('cdApp.shared').filter('styleText', styleText);
})();
