import { each, get, isEmpty, map } from 'lodash';

import { AppState } from '../../../redux';

export const getIntentionFee = (state: AppState) =>
  state.settings.fee.intentionFee;

export const getIntentionFees = (state: AppState) =>
  state.settings.fee.intentionFees;

export const getStoleFees = (state: AppState) => state.settings.fee.stolesFees;

export const getStoleFeeTaxonomyIds = (state: AppState) => {
  const stoleFeeTaxonomyIds: number[] = [];
  const stoleFees = state.settings.fee.stolesFees;
  each(stoleFees, (stoleFee) => {
    const stoleFeeTaxonomies = get(stoleFee, 'taxonomies');
    if (!isEmpty(stoleFeeTaxonomies)) {
      stoleFeeTaxonomyIds.push(...map(stoleFeeTaxonomies, 'id'));
    }
  });
  return stoleFeeTaxonomyIds;
};

export const getIntentionFeeRecipients = (state: AppState) =>
  state.settings.fee.intentionFeeRecipients;
