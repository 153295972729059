import React, { FunctionComponent, useEffect } from 'react';
import { Button, Row, Form } from 'antd';
import { useSelector, Provider, useDispatch } from 'react-redux';
import { isEmpty, reduce, set } from 'lodash';

import AssignUserRoles, {
  saveUserRoles,
} from '../components/assign-user-roles/AssignUserRoles';
import {
  getUserChurchRolesByRoleId,
  getUserOldRoles,
  getUserChurchRoles,
  getUserOrganizationRoles,
} from '../redux/selectors';
import { getCurrentUserId } from '../../config/store/Selector';
import Store from '../../redux';
import { gettextCatalog } from '../../services/I18nService';
import { getChurches } from '../../shared/store/resources';

import { useFetchUserRolesWithEffect } from './hooks';

const AssignUserRolesInline: FunctionComponent<{
  userId: number;
}> = ({ userId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const oldRoles = useSelector(getUserOldRoles);
  const churches = useSelector(getChurches);
  const userChurchRoles = useSelector(getUserChurchRolesByRoleId);
  const userOrganizationRoles = useSelector(getUserOrganizationRoles);
  const currentLoggedInUserId = useSelector(getCurrentUserId);
  const churchRoles = useSelector(getUserChurchRoles);

  useFetchUserRolesWithEffect(userId);

  const save = () =>
    saveUserRoles(
      dispatch,
      form,
      'inline',
      userId,
      currentLoggedInUserId,
      oldRoles,
      churchRoles,
      churches,
      ''
    );

  useEffect(() => {
    if (isEmpty(userOrganizationRoles)) return;
    const formatUserOrganizationRoles = reduce(
      userOrganizationRoles,
      (accumulator, role) => {
        set(accumulator, role.id, true);
        return accumulator;
      },
      {}
    );
    form.setFieldsValue({ organization: formatUserOrganizationRoles });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOrganizationRoles]);
  useEffect(() => {
    if (isEmpty(userChurchRoles)) return;
    form.setFieldsValue({
      church: userChurchRoles,
      churchSwitches: reduce(
        userChurchRoles,
        function (accumulator, value, key) {
          accumulator[key] = !isEmpty(value);
          return accumulator;
        },
        {}
      ),
    });
    // Monitor userState.churchRoles instead of the selector since useEffect monitors the reference and not the contents.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChurchRoles]);
  useEffect(() => {
    if (isEmpty(oldRoles)) return;
    form.setFieldsValue({ oldRoles });
  }, [oldRoles, form]);

  return (
    <Form form={form} layout="vertical">
      <AssignUserRoles userId={userId} />
      <Row justify="end" style={{ marginTop: 24, marginBottom: 14 }}>
        <Button type="primary" onClick={save}>
          {gettextCatalog.getString('Save')}
        </Button>
      </Row>
    </Form>
  );
};

const AssignUserRolesInlineRoot: FunctionComponent<{
  userId: number;
}> = ({ userId }) => {
  const store = Store.getStore();
  return (
    <Provider store={store}>
      <AssignUserRolesInline userId={userId} />
    </Provider>
  );
};

export default AssignUserRolesInlineRoot;
