'use strict';

angular.module('cdApp.shared').directive('cdValidatePassword', [
  '_',
  (_) => ({
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      ngModel.$validators.lowerCase = (value) => {
        const pattern = /[a-z]+/;
        return !_.isUndefined(value) && pattern.test(value);
      };

      ngModel.$validators.upperCase = (value) => {
        const pattern = /[A-Z]+/;
        return !_.isUndefined(value) && pattern.test(value);
      };

      ngModel.$validators.number = (value) => {
        const pattern = /\d+/;
        return !_.isUndefined(value) && pattern.test(value);
      };

      ngModel.$validators.eightCharacters = (value) =>
        !_.isUndefined(value) && value.length >= 8;
    },
  }),
]);
