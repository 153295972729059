(function () {
  'use strict';

  /**
   * @desc sum the values of key
   * @example <span>{{ arrayOfObjects | sumBy: 'key' }}</span>
   */ sumBy.$inject = ['_'];

  function sumBy(_) {
    return function (data, key) {
      if (!data || !key) {
        return 0;
      }

      let sum = 0;

      angular.forEach(data, function (obj) {
        sum += parseFloat(_.get(obj, key));
      });

      return sum;
    };
  }

  angular.module('cdApp.shared').filter('sumBy', sumBy);
})();
