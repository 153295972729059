import { IntentionFee } from '../../settings/models/fee';
import { Resource } from '../../shared/models/resource';

import { PaymentMethodTypes } from './intention';

export enum StoleStatusTypes {
  OPEN = 'open',
  COMPLETED = 'completed',
}

export interface Stole {
  id: string;
  referenceNumber: number;
  formattedReferenceNumber: number;
  paid: boolean;
  stoleYear: number;
  stoleText: string;
  comment: string;
  status: StoleStatusTypes;
  feeId: string;
  fee: Partial<IntentionFee>;
  churchId: number;
  church: Partial<Resource>;
  resource: Partial<Resource>;
  resourceId: number;
  calendarId: number;
  paymentMethod: PaymentMethodTypes;
  paidAt: Date;
  personId: number;
  organizationId: number;
  createdById: number;
  updatedById: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  event: {
    startDate: Date;
    endDate: Date;
    title: string;
    id: number;
  };
}
