import produce, { Draft } from 'immer';

import {
  FILEARCHIVE_REQUEST_SUCCESS,
  FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS,
  FILEARCHIVE_FILE_SELECTED,
  FILEARCHIVE_FOLDER_SELECTED,
  FILEARCHIVE_FILES_PAGE_CHANGE,
  FILEARCHIVE_FILES_PAGE_SIZE_CHANGE,
  FILEARCHIVE_GET_TAGS_SUCCESS,
  FILEARCHIVE_DELETE_FILE_SUCCESS,
  FILEARCHIVE_UPDATE_FILE_SUCCESS,
  FILEARCHIVE_REQUEST,
  CLOSE_MODAL,
  FILE_ARCHIVE_SEARCH,
  FILEARCHIVE_ORDERING_CHANGE,
} from '../actions/constants';
import { FileArchives } from '../actions/filearchive';

const initialState: FileArchives = {
  items: [],
  selectedFileId: undefined,
  selectedFolderId: undefined,
  tags: [],
  breadcrumb: [],
  reloadOnFileUpdateOrDelete: '',
  filesLoading: false,
  search: {
    value: '',
    searchIn: 'searchInFolder',
  },
  ordering: {
    orderBy: 'title',
    orderDirection: 'ASC',
  },
  pagination: {
    pageSizeOptions: [25, 50, 100],
    pageSize: 25,
    currentPage: 1,
    count: 0,
  },
};

const partnerFilesReducer = (state = initialState, action) =>
  produce(state, (draft: Draft<FileArchives>) => {
    const { type, payload } = action;
    switch (type) {
      case FILEARCHIVE_REQUEST:
        draft.filesLoading = true;
        break;
      case FILEARCHIVE_REQUEST_SUCCESS:
        draft.items = payload.items;
        draft.pagination.count = payload.count;
        draft.filesLoading = false;
        return;
      case FILEARCHIVE_FILE_SELECTED:
        draft.selectedFileId = payload.fileId;
        return;
      case FILEARCHIVE_FOLDER_SELECTED:
        draft.selectedFolderId = payload.folderId;
        // Reset current page to 1
        draft.pagination.currentPage = 1;
        return;
      case FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS:
        draft.breadcrumb = payload;
        return;
      case FILEARCHIVE_FILES_PAGE_CHANGE:
        draft.pagination.currentPage = payload.page;
        return;
      case FILEARCHIVE_FILES_PAGE_SIZE_CHANGE:
        draft.pagination.pageSize = payload.pageSize;
        return;
      case FILEARCHIVE_GET_TAGS_SUCCESS:
        draft.tags = payload.tags;
        break;
      case FILEARCHIVE_DELETE_FILE_SUCCESS:
        draft.reloadOnFileUpdateOrDelete = new Date().toISOString();
        break;
      case FILEARCHIVE_UPDATE_FILE_SUCCESS:
        draft.reloadOnFileUpdateOrDelete = new Date().toISOString();
        break;
      case CLOSE_MODAL:
        // Reset states
        draft.items = initialState.items;
        draft.pagination = initialState.pagination;
        draft.selectedFileId = initialState.selectedFileId;
        draft.selectedFolderId = initialState.selectedFolderId;
        draft.breadcrumb = initialState.breadcrumb;
        draft.ordering = initialState.ordering;
        draft.search = initialState.search;
        break;
      case FILE_ARCHIVE_SEARCH:
        draft.search = payload;
        // Reset current page to 1
        draft.pagination.currentPage = 1;
        break;
      case FILEARCHIVE_ORDERING_CHANGE:
        draft.ordering = payload;
        // Reset current page to 1
        draft.pagination.currentPage = 1;
        break;
    }
  });

export default partnerFilesReducer;
