export const fonts = [
  {
    name: 'Open Sans',
    value: 'Open Sans',
    className: 'Open-Sans',
  },
  {
    name: 'Roboto Flex',
    value: 'Roboto Flex',
    className: 'Roboto_Flex',
  },
  {
    name: 'Space Grotesk',
    value: 'Space Grotesk',
    className: 'Space-Grotesk',
  },
  {
    name: 'Alegreya',
    value: 'Alegreya',
    className: 'Alegreya',
  },
  {
    name: 'Cinzel',
    value: 'Cinzel',
    className: 'Cinzel',
  },
  {
    name: 'Inter',
    value: 'Inter',
    className: 'Inter',
  },
  {
    name: 'Manrope',
    value: 'Manrope',
    className: 'Manrope',
  },
  {
    name: 'Source Sans 3',
    value: 'Source Sans 3',
    className: 'Source_Sans_3',
  },
  {
    name: 'Playfair Display',
    value: 'Playfair Display',
    className: 'Playfair_Display',
  },
  {
    name: 'Archivo',
    value: 'Archivo',
    className: 'Archivo',
  },
  {
    name: 'Asap',
    value: 'Asap',
    className: 'Asap',
  },
  {
    name: 'Montserrat',
    value: 'Montserrat',
    className: 'Montserrat',
  },
  // new fonts
  {
    name: 'Raleway',
    value: 'Raleway',
    className: 'Raleway',
  },
  {
    name: 'Rubik',
    value: 'Rubik',
    className: 'Rubik',
  },
  {
    name: 'Epilogue',
    value: 'Epilogue',
    className: 'Epilogue',
  },
  {
    name: 'Oswald',
    value: 'Oswald',
    className: 'Oswald',
  },
  {
    name: 'Nunito',
    value: 'Nunito',
    className: 'Nunito',
  },
  {
    name: 'Work Sans',
    value: 'Work Sans',
    className: 'Work_Sans',
  },
  {
    name: 'Cabin',
    value: 'Cabin',
    className: 'Cabin',
  },
  {
    name: 'Arimo',
    value: 'Arimo',
    className: 'Arimo',
  },
  {
    name: 'EB Garamond',
    value: 'EB Garamond',
    className: 'EB_Garamond',
  },
  {
    name: 'Faustina',
    value: 'Faustina',
    className: 'Faustina',
  },
  {
    name: 'Karla',
    value: 'Karla',
    className: 'Karla',
  },
  {
    name: 'Lora',
    value: 'Lora',
    className: 'Lora',
  },
  {
    name: 'Sora',
    value: 'Sora',
    className: 'Sora',
  },
  {
    name: 'Urbanist',
    value: 'Urbanist',
    className: 'Urbanist',
  },
  // web safe fonts
  {
    name: 'Helvetica',
    value: 'Helvetica',
    className: 'Helvetica',
  },
  {
    name: 'Arial',
    value: 'Arial',
    className: 'Arial',
  },
  {
    name: 'Arial Black',
    value: 'Arial Black',
    className: 'Arial_Black',
  },
  {
    name: 'Verdana',
    value: 'Verdana',
    className: 'Verdana',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma',
    className: 'Tahoma',
  },
  {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS',
    className: 'Trebuchet_MS',
  },
  {
    name: 'Impact',
    value: 'Impact',
    className: 'Impact',
  },
  {
    name: 'Times New Roman',
    value: 'Times New Roman',
    className: 'Times_New_Roman',
  },
  {
    name: 'Georgia',
    value: 'Georgia',
    className: 'Georgia',
  },
];
