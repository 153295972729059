import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { CdUnsafeHTML } from '@/react/shared/components/CdUnsafeHTML';

angular
  .module('cdApp.shared')
  .component(
    'cdUnsafeHtml',
    react2angular(sharedContext.use(CdUnsafeHTML), ['html'], [])
  );
