import { Typography } from 'antd';
import React from 'react';

import { gettextCatalog } from '../../../../services/I18nService';
import { OrderType } from '../../../../shared/models/table';

import { EventRegistration } from '@/react/calendar/types/event';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { ColumnType } from '@/react/shared/components/cd-table/types';
import { DisplayDateSimple } from '@/react/shared/components/FormattedDate';

const { Link } = Typography;

const AttendeesTableColumn = (showTickets?: boolean) => {
  const LinkRender = (props: {
    value: string;
    isContactCreated: boolean;
    personId: number;
  }) =>
    (props.isContactCreated && (
      <Link
        href={getStateUrl('app.private.people.contacts.view', {
          id: props.personId,
        })}
        target="_blank"
      >
        {props.value}
      </Link>
    )) || <>{props.value}</>;

  const AttendeesColumn: ColumnType<EventRegistration>[] = [
    {
      title: gettextCatalog.getString('First Name'),
      dataIndex: 'firstName',
      sorter: true,
      orderType: OrderType.STRING,
      defaultSortOrder: 'ascend',
      render: (data, eventRegistration) => (
        <LinkRender
          value={data}
          isContactCreated={eventRegistration.isContactCreated}
          personId={eventRegistration.personId}
        />
      ),
    },
    {
      title: gettextCatalog.getString('Last Name'),
      dataIndex: 'lastName',
      sorter: true,
      orderType: OrderType.STRING,
      render: (data, eventRegistration) => (
        <LinkRender
          value={data}
          isContactCreated={eventRegistration.isContactCreated}
          personId={eventRegistration.personId}
        />
      ),
    },
    {
      title: gettextCatalog.getString('Email'),
      dataIndex: 'email',
      sorter: true,
      orderType: OrderType.STRING,
      render: (data, eventRegistration) => (
        <LinkRender
          value={data}
          isContactCreated={eventRegistration.isContactCreated}
          personId={eventRegistration.personId}
        />
      ),
    },
  ];

  if (showTickets === undefined || showTickets === true) {
    AttendeesColumn.push({
      title: gettextCatalog.getString('Total Tickets'),
      dataIndex: 'ticketsTotal',
      sorter: true,
      orderType: OrderType.NUMBER,
    });
  }

  AttendeesColumn.push({
    title: gettextCatalog.getString('Signed up'),
    dataIndex: 'createdAt',
    sorter: true,
    orderType: OrderType.DATE,
    render: function SimpleDate(date) {
      return (
        <DisplayDateSimple
          date={date}
          allDay={false}
          showRelativeTime={false}
          dateFormat="ll"
        />
      );
    },
  });
  return AttendeesColumn;
};

export default AttendeesTableColumn;
