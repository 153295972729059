import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { AnyAction, Middleware, Reducer, Store } from 'redux';
import { Persistor, PersistPartial, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { syncChangesFromRecoil } from 'redux-to-recoil';

import ErrorHandlingService from '../services/ErrorHandlingService';

import DevConfig from './config/DevConfig';
import ReduxPersistConfig from './config/ReduxPersistConfig';
import RehydrationServices from './services/RehydrationServices';

import { AppState } from '.';

// creates the store
export default (
  rootReducer: Reducer<AppState & PersistPartial, AnyAction>,
  rootSaga: any
): {
  persistor: Persistor;
  store: Store<AppState & PersistPartial, AnyAction>;
} => {
  /* ------------- Redux Configuration ------------- */

  const middleware: Middleware[] = [];

  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      const e = new Error(error.message);
      // @ts-ignore: Add extra information to the error object.
      e.sagaStack = sagaStack;

      // Dump extra information since this error is very severe.
      console.error(error);
      console.error(sagaStack);
      ErrorHandlingService.handleError(e);
    },
  });
  middleware.push(sagaMiddleware);

  /* ------------- Dev tools extension Enhancer ------------- */

  const store = configureStore({
    reducer: syncChangesFromRecoil(rootReducer),
    // serializableCheck and immutableCheck should be set to true after we fully migrate from seamless immutable
    middleware: getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false,
    }).concat(sagaMiddleware),
    devTools: DevConfig.__DEV__,
  });

  // configure persistStore and check reducer version number
  let persistor;
  if (ReduxPersistConfig.active) {
    persistor = RehydrationServices.updateReducers(store);
  } else {
    persistor = persistStore(store);
  }

  // kick off root saga
  sagaMiddleware.run(rootSaga);

  return { persistor, store };
};
