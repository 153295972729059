import _ from 'lodash';

import { formsApi, mainApi } from '../../api';
import { ChurchdeskForm } from '../models/forms';
import { gettextCatalog } from '../../services/I18nService';

class FormService {
  // Backend API
  public async searchForms(title: string): Promise<ChurchdeskForm[]> {
    const response = await mainApi.get('/calendar/forms/search', {
      title,
    });
    if (response.ok) {
      return _.get(response, 'data') as ChurchdeskForm[];
    }
    throw response.data;
  }

  public async addFormToEvent(
    calendarId: number,
    formId: string
  ): Promise<void> {
    const response = await mainApi.post('/calendar/forms', {
      calendarId,
      formId,
    });
    if (!response.ok) {
      throw response.data;
    }
  }

  public async removeFormFromEvent(calendarId: number): Promise<void> {
    const response = await mainApi.delete(`/calendar/forms/${calendarId}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  // Forms API
  public async copyForm(form: ChurchdeskForm): Promise<ChurchdeskForm> {
    const response = await formsApi.post(`/forms/${form.id}/duplicate`, {
      title:
        form.title +
        ' (' +
        gettextCatalog.getString('copy', null, 'Noun') +
        ')',
    });
    if (response.ok) {
      return _.get(response, 'data') as ChurchdeskForm;
    }
    throw response.data;
  }

  public async getQRcode(formAlias: string): Promise<any> {
    const response = await formsApi.get('/forms/qrcode', { formAlias });
    if (response.ok) {
      return response.data as any[];
    }
    throw response.data;
  }
}

export default new FormService();
