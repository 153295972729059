import { orderBy } from 'lodash';
import { selectorFamily } from 'recoil';

import { ApiSearchResult, mainApi } from '@/react/api';
import {
  CalendarInvitation,
  CalendarStudio,
} from '@/react/calendar/types/event';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetEventInvitations = selectorFamily<
  ApiSearchResult<CalendarInvitation>,
  { eventId: number }
>({
  key: 'GetEventInvitations',
  get:
    ({ eventId }) =>
    async ({ get }) => {
      const invitations = get(
        GetEventsPosterAndInvitations({ calendarId: eventId })
      ).invitations;
      if (!invitations) {
        return { items: [], total: 0 };
      }
      return {
        items: orderBy(invitations, ['createdAt'], ['desc']),
        total: invitations.length,
      };
    },
});

export const GetEventsPosterAndInvitations = selectorFamily<
  { posters: CalendarStudio['posters']; invitations: CalendarInvitation[] },
  { calendarId: number }
>({
  key: 'GetEventsPosterAndInvitations',
  get:
    ({ calendarId }) =>
    async () => {
      const response = await mainApi.get<CalendarStudio[]>('calendar/studio', {
        calendarId,
      });

      if (!response.ok) {
        handleError(response);
        return { posters: [], invitations: [] };
      }
      return {
        posters: response.data[0].posters,
        invitations: response.data[0].invitations,
      };
    },
});
