import { $injector } from 'ngimport';

class PeopleRelationService {
  public getRelations(): any[] {
    const relationService: any = $injector.get('relationService');
    return relationService.getRelations();
  }
}

export default new PeopleRelationService();
