import { ApiResponse } from 'apisauce';

import { API } from '../api';
import { PartnerFiles } from '../store/actions/partner';

export const loadPartnerFiles = async (options: {
  partnerId: string;
  offset: number;
  limit: number;
  search?: string;
  publication?: string[];
  type: string[];
  tags: string[];
}): Promise<PartnerFiles> => {
  const { partnerId, offset, limit, search, publication, type, tags } = options;
  const response: ApiResponse<any> = await API.get('/partner-files', {
    partner: partnerId,
    offset,
    limit,
    searchValue: search,
    publication,
    type,
    tags,
  });
  if (response.ok) {
    const data: PartnerFiles = response.data;
    return data;
  } else {
    throw new Error('Error loading partner files');
  }
};

export const importPartnerFile = async (fileId: string, partnerId: string) => {
  const response: ApiResponse<any> = await API.post(
    `/partner-files/${fileId}/import-to-organization`,
    {},
    {
      params: {
        partner: partnerId,
      },
    }
  );
  if (response.ok) {
    return response.data;
  } else {
    throw new Error('Failed importing partner file to organization');
  }
};

export const getFilters = async () => {
  const response: ApiResponse<string[]> = await API.get(
    '/partner-files/filters'
  );
  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};
