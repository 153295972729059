import _ from 'lodash';
import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { DetailedEvent } from '../../calendar/models/calendar';
import CalendarService from '../../calendar/services/CalendarService';
import { AppState } from '../../redux';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { People } from '../../people/types/people';
import CategoryService from '../services/CategoryService';
import PeopleService from '../services/PeopleService';
import ResourceService from '../services/ResourceService';
import {
  fetchCategoriesSuccess,
  FetchEventActionType,
  fetchEventSuccess,
  fetchPersonSuccess,
  FetchPersonType,
  searchPeopleSuccess,
  SearchPeopleType,
} from '../store/Actions';
import { fetchResourcesSuccess } from '../store/resources';

// People

export function* fetchPersonSaga(action: FetchPersonType): SagaIterator {
  const personId = action.payload;
  let processedPerson = null;
  if (personId) {
    const data = yield call(PeopleService.fetchPerson, personId);
    processedPerson = {
      id: _.get(data, 'id'),
      name: _.get(data, 'fullName'),
      email: _.get(data, 'email'),
      phone: _.get(data, 'phone'),
      picture: _.get(data, 'picture'),
    };
  }
  yield put(fetchPersonSuccess(processedPerson));
}

export function* searchPeopleSaga(action: SearchPeopleType): SagaIterator {
  const churches = yield select((state: AppState) =>
    _.get(state, 'config.organization.churches', [])
  );
  let matchingPeople = [];
  const query = action.payload;
  if (!_.isEmpty(query)) {
    const data = yield call(
      PeopleService.searchPeople,
      query.toLowerCase(),
      churches
    );
    matchingPeople = _.map(data, processFetchedPerson);
  }
  yield put(searchPeopleSuccess(matchingPeople));
}

const processFetchedPerson = (person: People) => {
  const fields = _.get(person, 'fields');
  const firstNameProperty = _.find(fields, ['property', 'firstName']);
  const firstName = _.get(firstNameProperty, 'value');
  const lastNameProperty = _.find(fields, ['property', 'lastName']);
  const lastName = _.get(lastNameProperty, 'value');
  const emailProperty = _.find(fields, ['property', 'email']);
  const email = _.get(emailProperty, 'value');
  const phoneProperty = _.find(fields, ['property', 'phone']);
  const phone = _.get(phoneProperty, 'value');

  const processedPerson: Partial<People> = _.pick(person, ['id', 'picture']);

  // Setting person properties
  if (!_.isEmpty(firstName) && !_.isEmpty(lastName)) {
    processedPerson.name = `${firstName} ${lastName}`;
  } else if (!_.isEmpty(firstName)) {
    processedPerson.name = firstName;
  } else if (!_.isEmpty(lastName)) {
    processedPerson.name = lastName;
  }
  if (!_.isEmpty(email)) {
    processedPerson.email = email;
  }
  if (!_.isEmpty(phone)) {
    processedPerson.phone = phone;
  }

  return processedPerson;
};

// Categories

export function* fetchCategoriesSaga(): SagaIterator {
  const data = yield call(CategoryService.getCategories);
  yield put(fetchCategoriesSuccess(data));
}

export function* fetchResourcesSaga(): SagaIterator {
  const data = yield call(ResourceService.getResources);
  yield put(fetchResourcesSuccess(data));
}

// Events

export function* fetchEventSaga(action: FetchEventActionType): SagaIterator {
  try {
    const eventId = action.payload;
    const event: DetailedEvent = yield call(CalendarService.getEvent, eventId);
    yield put(fetchEventSuccess(event));
  } catch (error) {
    yield call(ErrorHandlingService.handleError, error);
  }
}
