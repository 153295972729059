'use strict';

class FormTaxPercentageModal {
  constructor($http, cdApp) {
    'ngInject';

    this.$http = $http;
    this.cdApp = cdApp;
  }

  save() {
    const { $http, cdApp } = this;

    const organizationId = cdApp.organization.id;
    const taxPercentage = this.taxPercentage;

    $http
      .post(
        `${cdApp.config.api.main}/church/settings`,
        { taxPercentage },
        { params: { organizationId } }
      )
      .then(() => {
        this.close({ $value: taxPercentage });
      });
  }
}

FormTaxPercentageModal.$inject = ['$http', 'cdApp'];
angular.module('cdApp.forms').component('formTaxPercentageModal', {
  templateUrl:
    '@/app/forms/shared/components/form-tax-percentage-modal/form-tax-percentage-modal.component.html',
  controller: FormTaxPercentageModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
