'use strict';

angular.module('cdApp.shared').component('cdPageHeader', {
  templateUrl: '@/app/shared/components/page-header/page-header.component.html',
  bindings: {
    title: '<pageTitle',
    icon: '<pageIcon',
    subtitle: '<pageSubtitle',
    center: '<',
    style: '<',
  },

  transclude: true,
});
