import _ from 'lodash';

import { LightenDarkenColor } from '../react/shared/utils';

interface ColorIndex {
  [key: number]: string;
}
// Identify new colors here https://coolors.co/

export const COLOR_INDEX: ColorIndex = {
  0: '#008db6', // ChurchDesk blue // Google Cobalt
  1: '#3F51B5', // Google Blueberry
  2: '#2ECC71', // Google Sage
  3: '#1E824C', // Google Basil
  4: '#D50000', // Google Tomato
  5: '#f6bf26', // Google Banana
  6: '#EF6C00', // Google Pumpkin
  7: '#9E69AF', // Google Amethyst
  8: '#999999', // ChurchDesk Grey
  9: '#070a0d', // ChurchDesk Black

  10: '#4285F4', // Google Peacock
  11: '#7986CB', // Google Lavender
  12: '#B39DDB', // Google Wisteria
  13: '#8E24AA', // Google Grape
  14: '#795548', // Google Cocoa
  15: '#AD1457', // Google Beetroot
  16: '#009688', // Google Eucalyptus
  17: '#7CB342', // Google Pistachio
  18: '#C0CA33', // Google Avocado
  19: '#E4C441', // Google Citron
  20: '#F09300', // Google Mango
  21: '#F4511E', // Google Tangerine
  22: '#E67C73', // Google Flamingo
  23: '#D81B60', // Google Cherry Blossom
};

/**
 * The following array determines the order in which each color of the `COLOR_INDEX`
 * map/object should be rendered. Since each property (key) of the before-mentioned
 * object is a number starting from `0`, it can be mapped to a position in this array
 * which will indicate the order each element should be in.
 */

export const COLOR_INDEX_ORDERING_TABLE: number[] = [
  15, 21, 19, 3, 1, 13, 23, 6, 18, 16, 11, 14, 4, 20, 17, 10, 12, 8, 22, 5, 2,
  0, 7, 9,
];

/**
 * The default color showing if the users chooses to color events by resource and the event has no resource
 */
export const NO_COLOR = '#A79B8E'; // Google Birch
export const NO_COLOR_LOADING = 'rgb(97, 97, 97)'; // Google Graphite
export const PAST_NO_COLOR = '#e5e1dd';

/**
 * The color for the absence minimize component
 */
export const ABSENCE_COLOR = '#D50000';

export const PAST_ABSENCE_COLOR_COLOR = '#f2b3b3';

/**
 * Holiday colors (National and Liturgical)
 */

export const NATIONAL_HOLIDAY_COLOR = '#517488';

export const PAST_NATIONAL_HOLIDAY_COLOR = LightenDarkenColor('#517488', 50);

export const LITURGICAL_HOLIDAY_COLOR = '#3D5766';

export const PAST_LITURGICAL_HOLIDAY_COLOR = LightenDarkenColor('#3D5766', 50);

export const COLLECTION_COLOR = '#3D5766';

export const PAST_COLLECTION_COLOR = LightenDarkenColor('#3D5766', 50);
/**
 * Color profiles for when an event is in the past
 */

export const PAST_COLOR_INDEX: ColorIndex = {
  0: '#c6dafc',
  1: '#c5cbe9',
  2: '#c2e9d7',
  3: '#b6d9c7',
  4: '#f2b3b3',
  5: '#fcecbe',
  6: '#fad3b3',
  7: '#e2d2e7',
  8: '#d0d0d0',
  9: '#a3a3a3',
  10: '#b3e1f7',
  11: '#d7dbef',
  12: '#e8e2f4',
  13: '#ddbde6',
  14: '#d7ccc8',
  15: '#e6b9cd',
  16: '#b3e0db',
  17: '#d8e8c6',
  18: '#ecefc2',
  19: '#f7edc6',
  20: '#fbdfb3',
  21: '#fccbbc',
  22: '#f8d8d5',
  23: '#f3bbcf',
};

/**
 * Return a font color maximizing the contrast to the provided bg color.
 * This function was based on the following article: https://www.w3.org/TR/AERT/#color-contrast
 *
 * @param {string} bgColor The background color to evaluate
 * @returns {string}
 */
export const calculateTextColorBasedOnBGColor = (bgColor: string): string => {
  const whiteColor = '#ffffff';
  const blackColor = 'rgba(32,33,36,0.5)';
  const parsedBgColor = _.replace(bgColor, '#', '');
  if (_.isEmpty(parsedBgColor) || _.size(parsedBgColor) !== 6) {
    return blackColor;
  }
  const bgColorHexRed = parsedBgColor.substring(0, 2);
  const bgColorHexGreen = parsedBgColor.substring(2, 4);
  const bgColorHexBlue = parsedBgColor.substring(4, 6);
  const bgColorRGBRed = _.parseInt(bgColorHexRed, 16);
  const bgColorRGBGreen = _.parseInt(bgColorHexGreen, 16);
  const bgColorRGBBlue = _.parseInt(bgColorHexBlue, 16);
  const threshold = Math.round(
    (bgColorRGBRed * 299 + bgColorRGBGreen * 587 + bgColorRGBBlue * 114) / 1000
  );
  return threshold > 125 ? blackColor : whiteColor;
};
