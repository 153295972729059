(function () {
  'use strict';
  cdHasPermission.$inject = ['Authorization'];

  function cdHasPermission(Authorization) {
    return {
      restrict: 'A',
      scope: {
        permission: '=cdHasPermission',
      },

      link: function (scope, element) {
        if (!_.isString(scope.permission) && !_.isArray(scope.permission)) {
          throw 'The value must be a string or an array of strings.';
        }

        if (!Authorization.hasPermission(scope.permission)) {
          element.remove();
          scope.$destroy();
        }
      },
    };
  }

  angular.module('cdApp.shared').directive('cdHasPermission', cdHasPermission);
})();
