import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import iso from 'iso-countries/dist/countries.min.js';
import { configureScope } from '@sentry/browser';

import { CD_REDACTED_VALUE } from '../react/shared/constants';
import Store from '../react/redux';

import { COLOR_INDEX } from './ResourceColors';

import { handleError } from '@/react/services/ErrorHandlingService';
import cdApp from '@/react/config';

angular
  .module('cdApp')
  .config([
    '$locationProvider',
    function ($locationProvider) {
      $locationProvider.html5Mode(true);
    },
  ])
  .config([
    '$ngReduxProvider',
    function ($ngReduxProvider) {
      $ngReduxProvider.provideStore(Store.getStore());
    },
  ])
  .config([
    '$provide',
    function ($provide) {
      $provide.decorator('$exceptionHandler', [
        '$delegate',
        function () {
          return function (exception) {
            handleError(exception);
          };
        },
      ]);
    },
  ])
  .constant('iso', iso)
  .constant('cdApp', cdApp)
  .run([
    '$rootScope',
    function ($rootScope) {
      $rootScope.cdApp = cdApp;
    },
  ])
  .constant('_', _)
  .run([
    '$rootScope',
    function ($rootScope) {
      $rootScope._ = _;
    },
  ])
  .constant('moment', moment)
  .run([
    '$rootScope',
    function ($rootScope) {
      $rootScope.moment = moment;
    },
  ])
  .value('cdRedactedValue', CD_REDACTED_VALUE)
  .value('uiTimepickerConfig', {
    step: 15,
    appendTo: 'body',
    timeFormat: 'H:i',
  })
  .constant('cdResourceColors', COLOR_INDEX)
  .config([
    'localStorageServiceProvider',
    function (localStorageServiceProvider) {
      localStorageServiceProvider.setPrefix('churchdesk');
    },
  ])
  .config([
    '$provide',
    '$httpProvider',
    function ($provide, $httpProvider) {
      'ngInject';

      $provide.factory('AuthorizationRedirect', [
        '$location',
        '$q',
        '$injector',
        'cdApp',
        '_',
        function ($location, $q, $injector, cdApp, _) {
          'ngInject';

          return {
            request(config) {
              config.params = config.params || {};
              config.headers = config.headers || {};

              const isInternalEndpoint =
                _.some(cdApp.config.api, (url) =>
                  _.startsWith(config.url, url)
                ) && !_.endsWith(config.url, '.html');
              const skipAuthorization =
                _.get(config.params, 'skipAuthorization', false) === true;
              delete config.params.skipAuthorization;

              // abort if the request is anything but an internal endpoint
              if (!isInternalEndpoint) {
                return config || $q.when(config);
              }

              if (!skipAuthorization) {
                // append organizationId to all internal API requests
                if (config.url.indexOf('organizationId=') === -1) {
                  config.params.organizationId =
                    config.params.organizationId ||
                    window.churchdeskOrganizationId;
                }

                // add authorization header to all internal API requests
                const AuthenticationService = $injector.get(
                  'AuthenticationService'
                );

                const accessToken = AuthenticationService.getAccessToken();
                if (accessToken) {
                  config.headers['Authorization'] =
                    config.headers['Authorization'] || `Bearer ${accessToken}`;
                }
              }

              // App version
              config.headers['X-CD-Version'] = new Date(
                cdApp.version
              ).toUTCString();

              // Logged in user and masquerading user, if applicable
              if (cdApp.me) {
                config.headers['X-CD-User'] = cdApp.me.id;

                if (cdApp.me.masquerading) {
                  config.headers['X-CD-Masquerading-User'] =
                    cdApp.me.masqueradingUser;
                }
              }

              return config || $q.when(config);
            },
            responseError(rejection) {
              const status = rejection.status;
              const message = _.get(rejection, 'data.message');
              const url = _.get(rejection, 'config.url');

              // The following endpoints can respond with 401, but they should not trigger a redirect
              // e.g. Logging in with a wrong e-mail or password or using a reset-password link that was already used
              const skipPaths = [
                '/login',
                '/token',
                '/reset',
                '/users/me',
                '/users/logout',
              ];

              if (status >= 500 && status < 600) {
                throw new Error(
                  `${status} > ${rejection.statusText} at ${url} > ${message}`
                );
              }
              if (
                _.some(skipPaths, (path) =>
                  _.includes(url, `${cdApp.config.api.main}${path}`)
                )
              ) {
                return $q.reject(rejection);
              }

              const skipRedirect =
                rejection?.config?.headers['CD-IGNORE-AUTH-REDIRECT'];
              if (status === 401 && !skipRedirect) {
                const AuthenticationService = $injector.get(
                  'AuthenticationService'
                );
                const $state = $injector.get('$state');
                const path =
                  location.pathname && location.pathname !== '/'
                    ? location.pathname
                    : null;

                AuthenticationService.removeAccessToken();
                $location.url(
                  $state.href('app.public.login.form', {
                    continue: path,
                  })
                );

                return $q.resolve();
              }
              return $q.reject(rejection);
            },
          };
        },
      ]);

      $httpProvider.interceptors.push('AuthorizationRedirect');
    },
  ])
  .config([
    '$compileProvider',
    function ($compileProvider) {
      if (cdApp.config.environment !== 'development') {
        $compileProvider.debugInfoEnabled(false);
      }

      // if (false && cdApp.config.environment === 'development') {
      //   // eslint-disable-next-line @typescript-eslint/no-var-requires
      //   const whyDidYouRender = require('@welldone-software/why-did-you-render');
      //   whyDidYouRender(React, {
      //     trackAllPureComponents: true,
      //     trackExtraHooks: [[require('react-redux/lib'), 'useSelector']],
      //   });
      // }

      $compileProvider.aHrefSanitizationWhitelist(
        /^\s*((https?|ftp|mailto|webcal|tel):|data:image\/)/
      );
    },
  ])
  .config([
    'redactorOptions',
    function (redactorOptions) {
      _.extend(redactorOptions, {
        linkValidation: false, // MH: Avoids stripping of https.
        imageResizable: true,
        imagePosition: true,
        buttons: [
          'bold',
          'italic',
          'underline',
          'deleted',
          'format',
          'lists',
          'horizontalrule',
        ],

        plugins: [
          'alignment',
          'table',
          'source',
          'fullscreen',
          'bufferbuttons',
          'linkit',
          'imagePicker',
          'fontsize',
          'fontcolor',
        ],

        activeButtons: ['deleted', 'italic', 'bold', 'underline'],
        activeButtonsStates: {
          b: 'bold',
          strong: 'bold',
          i: 'italic',
          em: 'italic',
          del: 'deleted',
          strike: 'deleted',
          u: 'underline',
        },

        shortcutsAdd: {
          'ctrl+u, meta+u': { func: 'inline.format', params: ['underline'] },
        },
      });
    },
  ])
  .config([
    'uiSelectConfig',
    function (uiSelectConfig) {
      uiSelectConfig.theme = 'bootstrap';
    },
  ])
  .config([
    'hotkeysProvider',
    function (hotkeysProvider) {
      hotkeysProvider.templateTitle = 'Keyboard shortcuts';
      hotkeysProvider.cheatSheetDescription = 'Show or hide this help dialog';
    },
  ])
  .config([
    '$urlRouterProvider',
    function ($urlRouterProvider) {
      $urlRouterProvider.rule(function ($injector, $location) {
        let path = $location.path();

        if (path.indexOf('/app') !== -1) {
          // Rewrite old urls like "/app/#"
          $location.replace().path($location.hash());
          $location.hash('');
        }
      });
    },
  ])
  .config([
    'toastrConfig',
    function (toastrConfig) {
      angular.extend(toastrConfig, {
        allowHtml: false,
        extendedTimeOut: 1000,
        maxOpened: 3,
        newestOnTop: true,
        positionClass: 'toast-bottom-right',
        tapToDismiss: true,
        timeOut: 10000,
        preventOpenDuplicates: true,
      });
    },
  ])
  .config([
    'uibDatepickerConfig',
    'uibDatepickerPopupConfig',
    function (uibDatepickerConfig, uibDatepickerPopupConfig) {
      angular.extend(uibDatepickerConfig, {
        startingDay: 1,
      });

      angular.extend(uibDatepickerPopupConfig, {
        datepickerPopup: 'shortDate',
        onOpenFocus: false,
        showButtonBar: false,
      });
    },
  ])
  .config([
    'msdElasticConfig',
    function (msdElasticConfig) {
      msdElasticConfig.append = '\n';
    },
  ])
  .config([
    'ChartJsProvider',
    function (ChartJsProvider) {
      'ngInject';

      const fontFamily = 'Lato, "Helvetica Neue", Helvetica, Arial, sans-serif';

      ChartJsProvider.setOptions({
        tooltips: {
          titleFontSize: 13,
          cornerRadius: 4,
          xPadding: 10,
          yPadding: 8,
        },

        global: {
          defaultFontFamily: fontFamily,
          defaultFontSize: 13,
          defaultFontColor: '#777',
        },
      });
    },
  ])
  .config([
    'IdleProvider',
    'TitleProvider',
    function (IdleProvider, TitleProvider) {
      'ngInject';

      IdleProvider.idle(20 * 60); // in seconds (20 minutes)
      IdleProvider.timeout(0); // in seconds
      TitleProvider.enabled(false);
    },
  ])
  .config([
    'StripeElementsProvider',
    function (StripeElementsProvider) {
      'ngInject';
      StripeElementsProvider.setAPIKey(cdApp.references.stripe.subscriptions);
    },
  ])

  // Extract a set of country data from the intlTelInput and assign custom array
  // of countries with translated names to a global object so it can be used wherever it's needed
  // TODO: Refactor this into a constant or service instead
  .run([
    '$timeout',
    'Me',
    'gettextCatalog',
    '_',
    function ($timeout, Me, gettextCatalog, _) {
      let countries = {};

      try {
        countries = _($.fn.intlTelInput.getCountryData())
          .groupBy('iso2')
          .mapValues((item) => item[0])
          .value();

        _.set(cdApp, 'data.countries', countries);
      } catch (e) {
        // empty
      }

      Me.then(function () {
        $timeout(() => {
          _.mapValues(countries, (country) => {
            let base = country.name.split(' (')[0];

            return _.extend(country, {
              nameEnglish: base,
              nameTranslated: gettextCatalog.getString(base),
              nameTranslatedFull: `${gettextCatalog.getString(base)} (${base})`,
            });
          });
        });
      });
    },
  ])
  .run([
    '$rootScope',
    '$transitions',
    '$injector',
    '_',
    'documentTitleService',
    function ($rootScope, $transitions, $injector, _, documentTitleService) {
      'ngInject';

      $transitions.onStart({}, function (transition) {
        transition.promise.finally(() => {
          const titleObject = transition.injector().get('$title');
          const title = _.isObject(titleObject)
            ? titleObject.rendered
            : titleObject;
          documentTitleService.set(title);
        });
      });
    },
  ])
  .run([
    'Idle',
    'Me',
    '$rootScope',
    '$http',
    'gettextCatalog',
    'cdApp',
    'toastr',
    function (Idle, Me, $rootScope, $http, gettextCatalog, cdApp, toastr) {
      'ngInject';

      if (
        cdApp.config.environment === 'development' ||
        cdApp.config.environment === 'test'
      ) {
        return;
      }
      let isRefreshMessageVisible = false;
      Me.then(() => {
        // Start watching user activity immediately
        Idle.watch();

        // Check for updates on init and then on an interval after that
        checkForUpdate();

        // The user appears to have gone idle, stop checking if they have the new version of the app.
        $rootScope.$on('IdleStart', function () {
          shouldMonitorUpdates(false);
        });
        // The user has come back from idle, check if they have the new version of the app and set the intravel for checForUpdates again
        $rootScope.$on('IdleEnd', function () {
          // Check for update when user comes off of idle
          checkForUpdate();
        });

        let checkForUpdateInterval; // The interval to check for updates
        let intervalSet = false; // Track whether checkForUpdate is being run on an interval in the background

        // Set or clear the intravel used to look for updates in the background
        // @param check -  Whether the interval should be set (true) or cleared (false)
        function shouldMonitorUpdates(check) {
          if (check) {
            if (!intervalSet) {
              intervalSet = true;

              checkForUpdateInterval = setInterval(
                checkForUpdate,
                10 * 60 * 1000 // 10 minutes
              );
            }
          } else {
            if (intervalSet) {
              intervalSet = false;
              clearInterval(checkForUpdateInterval);
            }
          }
        }
        // Compare current live version to local version to see if there is an update
        function checkForUpdate() {
          $http
            .get(`/metadata.json?c=${new Date().getTime()}`, {
              headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
              },
            })
            .then((res) => {
              const localVersion = cdApp.version;
              const liveVersion = res.data.version;

              if (localVersion !== liveVersion && !isRefreshMessageVisible) {
                shouldMonitorUpdates(false); // If an update is found then stop looking
                isRefreshMessageVisible = true;
                toastr.info(
                  gettextCatalog.getString(
                    'Please refresh your page to get the latest version.'
                  ) +
                    '<br><button id="toast-reload" class="btn btn-sm btn-default u-mt-10" style="background-color: white"> <span translate>' +
                    gettextCatalog.getString('Refresh') +
                    '</span> </button>',
                  gettextCatalog.getString(
                    'A new version of ChurchDesk is out'
                  ) + ' ✨',
                  {
                    allowHtml: true,
                    closeButton: true,
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false,
                    onShown: () => {
                      document
                        .getElementById('toast-reload')
                        .addEventListener('click', () =>
                          window.location.reload(true)
                        );
                    },
                    onHidden: function () {
                      isRefreshMessageVisible = false;
                      shouldMonitorUpdates(true);
                      Idle.watch();
                    },
                  }
                );
              } else {
                shouldMonitorUpdates(true);
              }
            })
            .catch(() => Idle.unwatch());
        }
      });
    },
  ])
  .run([
    '_',
    'cdApp',
    'localStorageService',
    function (_, cdApp, localStorageService) {
      angular.extend(cdApp.data, {
        set: function (obj, reset) {
          let stored =
            angular.fromJson(localStorageService.get('calendarData')) || {};

          _.each(obj, function (data, key) {
            if (!reset && stored.hasOwnProperty(key)) {
              stored[key] = _.extend(stored[key], data);
            } else {
              stored[key] = data;
            }
          });

          localStorageService.set('calendarData', stored);
          cdApp.data.expand();
        },

        expand: function () {
          let stored =
            angular.fromJson(localStorageService.get('calendarData')) || {};

          _.each(stored, function (data, key) {
            if (_.isFunction(cdApp.data[key])) {
              throw Error(
                'Not allowed operation: function cannot be overridden.'
              );
            }

            cdApp.data[key] = data;
          });
        },
      });
    },
  ])
  .run([
    '$rootScope',
    'Authorization',
    function ($rootScope, Authorization) {
      $rootScope.Authorization = Authorization;
    },
  ])
  .run([
    '$window',
    '$uibModal',
    'Me',
    'jwtHelper',
    'AuthenticationService',
    'moment',
    '$state',
    function (
      $window,
      $uibModal,
      Me,
      jwtHelper,
      AuthenticationService,
      moment,
      $state
    ) {
      let alreadyOpen = false;

      /**
       * Prompt user to log in when the access token is about to expire.
       *
       * The prompt should be triggered when the window receives focus and
       * when there are less than 12 hours left until the expiration of the
       * access token. This way we avoid unauthorized requests and the user
       * can renew their session and continue their work without leaving the page.
       */
      $window.onfocus = function () {
        Me.then(() => {
          if (
            Me.masquerading ||
            alreadyOpen ||
            !$state.includes('app.private')
          ) {
            return;
          }
          const accessToken = AuthenticationService.getAccessToken(true);
          if (!accessToken) return;
          const tokenExpirationDate =
            jwtHelper.getTokenExpirationDate(accessToken);
          const tokenExpiresSoon =
            moment(tokenExpirationDate).diff(moment(), 'minutes') < 720; // in minutes = 12 hours
          if (!tokenExpiresSoon) return;

          // Prevent opening the same modal again
          alreadyOpen = true;

          $uibModal
            .open({
              component: 'cdLoginPrompt',
              backdrop: 'static',
              keyboard: false,
              windowClass: 'login-prompt',
            })
            .result.finally(() => {
              alreadyOpen = false;
            });
        });
      };
    },
  ])
  .run([
    '$state',
    '$transitions',
    '_',
    'AuthenticationService',
    function ($state, $transitions, _, AuthenticationService) {
      'ngInject';

      // disable default ui-router error handler
      $state.defaultErrorHandler(_.noop);

      // Redirect to error pages when a state resolve fails
      $transitions.onError({}, (transition) => {
        const error = transition.error();
        const targetState = transition.$to().name;

        if (error && error.detail && error.detail.status) {
          switch (error.detail.status) {
            // form service down
            case -1:
              if (targetState.match(/^app\.private\.forms\./)) {
                $state.go('app.private.forms.default', {
                  errorCode: 'FORM_SERVICE_DOWN',
                });
              }
              break;

            // access denied
            case 403:
              $state.go('app.public.errorState', {
                errorCode: 'ACCESS_DENIED',
              });

              break;

            // not found
            case 404:
              $state.go('app.public.errorState', { errorCode: 'NOT_FOUND' });
              break;

            // not authenticated
            case 401:
              if (targetState.match(/^app\.private\./)) {
                AuthenticationService.removeAccessToken();
                const path =
                  location.pathname && location.pathname !== '/'
                    ? location.pathname
                    : null;
                window.location = $state.href('app.public.login.form', {
                  continue: path,
                });
              }
              break;

            // throw message by default
            default:
              throw new Error(_.get(error, 'data.message'));
          }
        }
      });
    },
  ])
  .run([
    '$window',
    function ($window) {
      $window.addEventListener(
        'dragover',
        function (e) {
          e = e || event;
          e.preventDefault();
        },
        false
      );

      $window.addEventListener(
        'drop',
        function (e) {
          e = e || event;
          e.preventDefault();
        },
        false
      );
    },
  ])
  .run([
    'Me',
    'gettextCatalog',
    'localStorageService',
    'iso',
    'iso4217',
    function (Me, gettextCatalog, localStorageService, iso, iso4217) {
      Me.then(() => {
        const country = _.get(
          cdApp,
          'organization.countryIso2',
          'us'
        ).toUpperCase();
        const currencyCode = iso.findCountryByCode(country).currency;

        _.set(cdApp, 'organization.currencyIso', currencyCode);
        _.set(
          cdApp,
          'organization.currency',
          iso4217.getCurrencyByCode(currencyCode).symbol || '$'
        );

        cdApp.gettextCatalog = gettextCatalog;

        // Set the default display order for the People table
        const savedDisplayOrder = localStorageService.get(
          'peopleSettings.displayOrder'
        );

        const defaultDisplayOrderByLanguage = {
          firstLast: ['da', 'sv'],
          lastFirst: ['de', 'en-gb'],
        };

        const lang = _.get(cdApp, 'organization.locale.language');
        if (!savedDisplayOrder) {
          const defaultPeopleDisplayOrder =
            _.findKey(defaultDisplayOrderByLanguage, (languages) =>
              _.includes(languages, lang)
            ) || 'firstLast';
          localStorageService.set(
            'peopleSettings.displayOrder',
            defaultPeopleDisplayOrder
          );
        }
      });
    },
  ])

  // Sentry.io
  .run([
    'Me',
    'cdApp',
    function (Me, cdApp) {
      'ngInject';

      if (cdApp.config.environment !== 'production') return;
      Me.then(() => {
        configureScope((scope) => {
          // Set user data
          scope.setUser({
            id: Me.id,
          });

          // Set custom data
          scope.setTag('locale', Me.locale);
          // Set custom context
          scope.setExtra('masquerading', Me.masquerading);
          scope.setExtra(
            'organization',
            _.pick(cdApp.organization, ['id', 'name', 'locale', 'siteStatus'])
          );
        });
      });
    },
  ])

  // Analytics
  .run([
    '_',
    'cdApp',
    'Me',
    'Analytics',
    '$transitions',
    '$state',
    '$uibModalStack',
    function (_, cdApp, Me, Analytics, $transitions, $state, $uibModalStack) {
      'ngInject';
      Me.then(() => {
        // Identify organization
        const organizationId = _.get(cdApp, 'organization.id');
        /*
    const organizationDetails = {
    name: _.get(cdApp, 'organization.name'),
    country: _.get(cdApp, 'organization.countryIso2')
    };
    Analytics.identifyOrganization(organizationId, organizationDetails);*/

        // Identify user
        const userId = Me.id;
        const userDetails = {
          email: Me.email,
          name: Me.fullName,
          first_name: Me.firstName,
          last_name: Me.lastName,
          company: {
            id: _.get(cdApp, 'organization.intercomOrganizationId'),
            organization_id: organizationId,
            name: _.get(cdApp, 'organization.name'),
            url: _.get(cdApp, 'organization.installationUrl'),
            installation_type: _.get(cdApp, 'organization.siteStatus'),
            app_language: _.get(cdApp, 'organization.locale.language'),
            country: _.get(cdApp, 'organization.locale.country'),
          },

          roles: Me.rolesAcrossAllOrgs,
          groupAdmin: Me.adminInAnyGroupAcrossAllOrgs,
        };

        Analytics.identifyUser(userId, userDetails);

        // Track route changes
        $transitions.onSuccess({}, function (transition) {
          const toState = transition.to();
          const urlParams = transition.params();
          const relativeUrl = $state.href(transition.to().name, urlParams);
          const absoluteUrl = $state.href(transition.to().name, urlParams, {
            absolute: true,
          });

          let path = relativeUrl.replace(/\/o\/([\d]+)/, '').split('?')[0];
          const queryString = relativeUrl.split('?')[1];
          const query = queryString ? `?${queryString}` : '';
          if (query.indexOf('page=') !== -1) return;

          const resolveTokens = transition.getResolveTokens();
          let name = toState.name;
          let title = toState.name;
          if (_.includes(resolveTokens, '$title')) {
            const { base, rendered } = transition.injector().get('$title');
            name = _.startCase(base);
            title = rendered;
          }

          if (toState.name === 'app.private.calendar.full') {
            const calendarView = _.get(cdApp, 'data.calendar.settings.view');
            if (calendarView) {
              path = `${path}/${calendarView}`;
            }
          }

          Analytics.trackPageView(name, title, absoluteUrl, path, query);
        });

        // Close any open modals after exiting a route
        $transitions.onExit({}, () => {
          $uibModalStack.dismissAll();
        });
      });
    },
  ]);
