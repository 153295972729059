import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import createReducer from '../../../redux/utils';

import { ADD_LOADING, REMOVE_LOADING, AddLoading } from './Actions';

export interface LoadingState {
  loadingModules: string[];
  isLoading: boolean;
}

export type ImmutableLoadingState = immutable.ImmutableObject<LoadingState>;

const initialState = immutable.from<LoadingState>({
  loadingModules: [],
  isLoading: false,
});

const addLoader: Reducer<ImmutableLoadingState, AddLoading> = (
  state,
  { payload }
) =>
  state.merge({
    loadingModules: [...state.loadingModules.asMutable(), payload],
    isLoading: true,
  });

const removeLoader: Reducer<ImmutableLoadingState, AddLoading> = (
  state,
  { payload }
) => {
  const currentModules = state.loadingModules
    .filter((module) => module !== payload)
    .asMutable();
  return state.merge({
    loadingModules: currentModules,
    isLoading: currentModules.length > 0,
  });
};
const handlers = {
  [ADD_LOADING]: addLoader,
  [REMOVE_LOADING]: removeLoader,
};

export default createReducer<ImmutableLoadingState>(initialState, handlers);
