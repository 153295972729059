'use strict';

class InvitationResponseButton {
  $onInit() {
    this.options = ['yes', 'maybe', 'no'];
    this.buttonSize = this.buttonSize || 'md';
    this.appendToBody = this.appendToBody || false;
  }

  selectResponse(response) {
    this.response = response;
    this.onSelect({ response });
  }
}

angular.module('cdApp.shared').component('cdInvitationResponseButton', {
  templateUrl:
    '@/app/shared/components/invitation-response-button/invitation-response-button.component.html',
  bindings: {
    response: '<',
    buttonSize: '<',
    alignRight: '<',
    appendToBody: '<',
    onSelect: '&',
  },

  controller: InvitationResponseButton,
});
