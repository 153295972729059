'use strict';

class AttendanceCellComponent {
  constructor($timeout, _) {
    'ngInject';

    this.$timeout = $timeout;
    this._ = _;

    this.oldValue = null;
  }

  $onInit() {
    this.loading = false;
    this.value = this._.isNil(this.initialValue) ? null : this.initialValue;
  }

  /**
   * Called when the cell is focused
   */
  onFocus() {
    this.oldValue = this.value;
  }

  /**
   * Update the cell value
   */
  updateValue() {
    const _ = this._;
    const isValid =
      _.isNull(this.value) ||
      (this.inputType === 'number' ? _.isInteger(this.value) : true);
    if (this.value === this.oldValue || !isValid) return;

    this.showLoader();
    this.onUpdate({ value: this.value });
  }

  /**
   * Move to the next cell on the row below on Enter
   *
   * @param {Object} jsEvent The Javascript event object
   */
  nextCell(jsEvent) {
    if (jsEvent.which !== 13) return;

    jsEvent.preventDefault();

    const regex = /attendance-cell-(.*)-([\d]+)/;
    const match = jsEvent.currentTarget.id.match(regex);
    const cellIdentifier = match[1];
    const currentRow = parseInt(match[2], 10);
    const nextCellElement = angular.element(
      `#attendance-cell-${cellIdentifier}-${currentRow + 1}`
    );

    if (nextCellElement) {
      nextCellElement.focus();
    }
  }

  showLoader() {
    this.loading = true;

    this.$timeout(() => {
      this.loading = false;
    }, 200);
  }
}

AttendanceCellComponent.$inject = ['$timeout', '_'];
angular.module('cdApp.calendar').component('cdAttendanceCell', {
  templateUrl:
    '@/app/calendar/attendance/components/attendance-cell/attendance-cell.component.html',
  controller: AttendanceCellComponent,
  bindings: {
    inputType: '<',
    initialValue: '<',
    uniqueId: '<',
    selectOptions: '<',
    onUpdate: '&',
  },
});
