import _ from 'lodash';
class FormEventPlanningComponent {
  /**
   * A component that allows for rota management on the event form.
   *
   * @prop {Object} calendarTasks - The event.calendar_tasks from the current event.
   * @prop {Boolean} availableTasks - The available tasks in the organization that the user has access to.
   * @prop {Function} onCalendarTasksUpdated({ calendarTasks: number[], shifts: object[] }) - Callback for when calendarTasks has been updated.
   *
   */

  constructor(gettextCatalog, Authorization, $uibModal) {
    'ngInject';

    this.gettextCatalog = gettextCatalog;
    this.Authorization = Authorization;
    this.$uibModal = $uibModal;
  }

  $onInit() {
    const { Authorization } = this;

    this.canBook = Authorization.hasPermission('canBook');
    this.sorting = {
      field: 'task.title',
      reverse: false,
    };

    if (!this.calendarTasks) this.calendarTasks = [];
  }

  /**
   * Checks whether the task has already been added to the event.
   *
   * @param {object} task the task.
   * @returns {boolean}
   */
  isTaskAlreadyAdded(task) {
    return _.includes(_.map(this.calendarTasks, 'taskId'), task.id);
  }

  /**
   * Return the appropriate sort class for a table column
   *
   * @param {String} field The field being sorted
   */
  getSortingDirectionClass(field) {
    if (this.sorting.field === field) {
      return this.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }

  /**
   * Update the sorting order of a column
   *
   * @param {String} field The field being sorted
   */
  setSorting(field) {
    if (this.sorting.field === field) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.field = field;
      this.sorting.reverse = false;
    }
  }

  /**
   * Adds a task to the list of tasks associated to the event.
   *
   * @param {object} task the task.
   */
  addTaskToEvent(task) {
    this.calendarTasks.push({
      required: 1,
      taskId: task.id,
      task,
    });

    this.onCalendarTasksUpdated({
      calendarTasks: this.calendarTasks,
      shifts: this.shifts,
    });
  }

  /**
   * Removes a calendarTask from the list of tasks associated to the event.
   *
   * @param {object} calendarTask the task.
   */
  removeCalendarTask(calendarTask) {
    const { $uibModal, gettextCatalog } = this;

    let taskTitle = calendarTask.task.title;
    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () =>
            gettextCatalog.getString("Remove '{{ taskName }}'", {
              taskName: taskTitle,
            }),

          body: () =>
            gettextCatalog.getString(
              "Are you sure you want to remove '{{ taskName }}' from this event including already assigned users?",
              {
                taskName: taskTitle,
              }
            ),

          options: {
            confirmButtonText: gettextCatalog.getString(
              'Yes, remove rota and all assigned users'
            ),

            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        _.remove(
          this.calendarTasks,
          (item) => item.taskId === calendarTask.taskId
        );

        _.remove(this.shifts, (item) => item.taskId === calendarTask.taskId);
        this.onCalendarTasksUpdated({
          calendarTasks: this.calendarTasks,
          shifts: this.shifts,
        });
      });
  }

  /**
   * Create a new event task and add it to a new shift
   */
  createEventTask() {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'createEventTaskModal',
        windowClass: 'modal-ui-select',
      })
      .result.then((task) => {
        this.addTaskToEvent(task);
      });
  }

  /**
   * Invoked when the required field is invoked on a calendar_task. This ensures that the value is set to null
   * instead of 0 which shows the 'unlimited' placeholder.
   *
   * @param {*} calendarTask
   */
  calendarTaskRequiredUpdated(calendarTask) {
    if (calendarTask.required === 0) calendarTask.required = null;
  }
}
FormEventPlanningComponent.$inject = [
  'gettextCatalog',
  'Authorization',
  '$uibModal',
];

angular.module('cdApp.shared').component('cdFormEventPlanningComponent', {
  template: require('./form-event-planning.component.html'),
  controller: FormEventPlanningComponent,
  bindings: {
    calendarTasks: '<',
    shifts: '<',
    availableTasks: '<',
    onCalendarTasksUpdated: '&',
  },
});
