/* eslint-disable react/prop-types */
import React from 'react';
import { $injector } from 'ngimport';

import EventCell from './EventCell';
import { TaskCell } from './TaskCell';

const RotaPrintTable = ({ className, data, columns, hideNotes }) => {
  const gettextCatalog = $injector.get('gettextCatalog');
  return (
    <table id="printTable" className={'printTable ' + className}>
      <thead>
        <tr>
          <th>
            {/* @ts-ignore */}
            <span>{gettextCatalog.getString('Date')}</span>
          </th>
          {columns.map((column) => (
            <th key={`header ${column.taskId}`}>{column.label}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row) => (
          <tr key={`row ${row.id}`}>
            {row.columns.map((column, columnIndex) => (
              <td key={`cell ${column.id}`}>
                <div className="rotas-table__cell">
                  {column.id === 'startDate' ? (
                    <EventCell event={{ ...row.columns[0] }} isPrinting />
                  ) : (
                    <TaskCell
                      isPrinting
                      event={{ ...row.columns[0] }}
                      task={row.columns[columnIndex]}
                      hideNotes={hideNotes}
                      scope={undefined}
                    />
                  )}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RotaPrintTable;
