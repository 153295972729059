const formsModule = angular.module('cdApp.forms', []).config([
  '$httpProvider',
  ($httpProvider) => {
    // The form.io library sets some headers that we don't want
    $httpProvider.defaults.headers.get['Cache-Control'] = undefined;
    $httpProvider.defaults.headers.get.Pragma = undefined;
    $httpProvider.defaults.headers.common['X-Requested-With'] =
      'XMLHttpRequest';
  },
]);

require('./forms.component');
require('./form-list/form-list');
require('./edit-form-response/edit-form-response.component');
require('./form-builder/form-builder.component');
require('./forms.component');
require('./shared');
require('./view-form-responses/view-form-responses.component');
require('./view-form');
require('./add-form-to-event/cdr-add-form-to-event-modal');

require('./templates');

require('./cancel-tickets/cancel-tickets.component');

module.exports = formsModule.name;
