import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { FEvent as Event } from '../../../calendar/models/calendar';
import createReducer from '../../../redux/utils';

import {
  CLEAR_ORDER_OF_SERVICE_REPORT_EVENTS,
  FETCH_ORDER_OF_SERVICE_REPORT_EVENTS_SUCCESS,
  FetchOrderOfServiceReportEventsSuccessActionType,
} from './Actions';

export interface IntentionReportState {
  orderOfServiceEvents: Event[];
  orderOfServiceEventCount: number;
}

export type ImmutableIntentionReportState =
  immutable.ImmutableObject<IntentionReportState>;

const initialState: ImmutableIntentionReportState =
  immutable.from<IntentionReportState>({
    orderOfServiceEvents: [],
    orderOfServiceEventCount: 0,
  });

const clearOrderOfServiceReportEvents: Reducer<ImmutableIntentionReportState> =
  (state = initialState) =>
    state.merge({
      orderOfServiceEvents: [], // Clear order of service events
    });

const fetchOrderOfServiceReportEventsSuccess: Reducer<
  ImmutableIntentionReportState,
  FetchOrderOfServiceReportEventsSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    orderOfServiceEvents: payload.items,
    orderOfServiceEventCount: payload.count,
  });

// Handlers

const handlers = {
  [CLEAR_ORDER_OF_SERVICE_REPORT_EVENTS]: clearOrderOfServiceReportEvents,
  [FETCH_ORDER_OF_SERVICE_REPORT_EVENTS_SUCCESS]:
    fetchOrderOfServiceReportEventsSuccess,
};

export default createReducer<ImmutableIntentionReportState>(
  initialState,
  handlers
);
