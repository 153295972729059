import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import moment, { Moment } from 'moment';
import { InputNumber, Typography, Row, Col } from 'antd';

import { FacebookOptions } from './CdShareFacebook';

import { CdTimePicker } from '@/react/shared/components/cd-time-picker/CdTimePicker';
import { gettextCatalog } from '@/react/services/I18nService';

const { TextArea } = Input;
const { Option } = Select;

interface FacebookSettingProps {
  value: FacebookOptions;
  startDate: Moment;
  onChange?: (value: FacebookOptions) => void;
  hasPublishedToFacebook: boolean;
  disabled?: boolean;
}
const FacebookSetting = (props: FacebookSettingProps) => {
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const options = useMemo(
    () => [
      {
        value: 'now',
        label: gettextCatalog.getString('Post now'),
        description: gettextCatalog.getString('Post to Facebook right away...'),
      },

      {
        value: 'one_week',
        label: gettextCatalog.getString('1 week before'),
        units: {
          duration: 1,
          durationUnit: 'week',
          hour: 15,
          minute: 0,
        },
      },

      {
        value: 'two_weeks',
        label: gettextCatalog.getString('2 weeks before'),
        units: {
          duration: 2,
          durationUnit: 'week',
          hour: 15,
          minute: 0,
        },
      },

      {
        value: 'one_month',
        label: gettextCatalog.getString('1 month before'),
        units: {
          duration: 1,
          durationUnit: 'month',
          hour: 15,
          minute: 0,
        },
      },
      {
        value: 'custom',
        label: gettextCatalog.getString('Customize'),
        description: gettextCatalog.getString('Choose specifically...'),
      },
    ],
    []
  );

  const defaultValue = useCallback(() => {
    const optionMatch = options.find(
      (item) =>
        props.value.schedulingOptions &&
        item.units?.duration === props.value.schedulingOptions?.duration &&
        item.units?.durationUnit ===
          props.value.schedulingOptions?.durationUnit &&
        item.units?.hour === props.value.schedulingOptions.hour &&
        item.units?.minute === props.value.schedulingOptions.minute
    );
    if (optionMatch) return optionMatch.value;
    if (props.value.schedulingOptions) return 'custom';
    return 'now';
  }, [options, props.value.schedulingOptions]);

  const getPublishOnDate = (schedulingOptions) =>
    props.startDate
      .clone()
      .subtract(schedulingOptions?.duration, schedulingOptions?.durationUnit)
      .set({
        hour: schedulingOptions?.hour,
        minute: schedulingOptions?.minute,
        second: 0,
      });
  const isScheduleOptionDisabled = (scheduleOption) => {
    // Now options should never be disabled
    if (scheduleOption.value === 'now') {
      return false;
    }

    if (scheduleOption.value === 'custom') {
      return props.startDate.isSameOrBefore(moment());
    }
    // An option is disabled if it produces a publishedOn time in the past
    const publishedOn = getPublishOnDate(scheduleOption?.units);
    return moment(publishedOn).isSameOrBefore(moment());
  };

  const onChangeTime = (time) => {
    props.onChange({
      ...props.value,
      schedulingOptions: {
        ...props.value.schedulingOptions,
        hour: moment(time, 'HH:mm').hour(),
        minute: moment(time, 'HH:mm').minute(),
      },
    });
  };
  const timeValue =
    props.value.schedulingOptions &&
    moment(
      `${props.value.schedulingOptions.hour}:${props.value.schedulingOptions.minute}`,
      'H:m'
    ).format('HH:mm');

  useEffect(() => {
    if (defaultValue() === 'custom') {
      setShowCustom(true);
    }
  }, [defaultValue]);

  return (
    <>
      <Form.Item>
        <Select
          data-testid="facebook-publishOn-select"
          getPopupContainer={(trigger) => trigger.parentNode}
          optionLabelProp="label"
          value={defaultValue()}
          disabled={props.hasPublishedToFacebook || props.disabled}
          onChange={(value) => {
            switch (value) {
              case 'now':
                setShowCustom(false);
                props.onChange({
                  ...props.value,
                  isScheduled: false,
                  schedulingOptions: undefined,
                });
                break;
              case 'custom':
                setShowCustom(true);
                props.onChange({
                  ...props.value,
                  isScheduled: true,
                  schedulingOptions: {
                    duration: 5,
                    durationUnit: 'day',
                    hour: 15,
                    minute: 0,
                  },
                });
                break;
              case 'one_week':
              case 'two_weeks':
              case 'one_month':
                setShowCustom(false);
                props.onChange({
                  ...props.value,
                  isScheduled: true,
                  schedulingOptions: options.find(
                    (element) => element.value === value
                  ).units,
                });
                break;

              default:
                break;
            }
          }}
        >
          {options.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isScheduleOptionDisabled(option) || props.disabled}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{option.label}</span>
                {option.description && (
                  <span style={{ color: 'gray' }}>{option.description}</span>
                )}
                {!option.description && (
                  <span style={{ color: 'gray' }}>
                    {getPublishOnDate(option.units).format('LLLL')}
                  </span>
                )}
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {showCustom && (
        <Row>
          <Col span={11}>
            <Form.Item>
              <InputNumber
                value={props.value.schedulingOptions?.duration}
                onChange={(value) => {
                  props.onChange({
                    ...props.value,
                    schedulingOptions: {
                      ...props.value.schedulingOptions,
                      duration: value,
                    },
                  });
                }}
                data-testid="facebook-schedulingOptions-duration"
                addonAfter={gettextCatalog.getString('days before')}
                min={1}
                step={1}
                style={{ width: '100%' }}
                disabled={props.hasPublishedToFacebook || props.disabled}
              />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Row justify="space-around">
              <Col span={3} push={1} style={{ marginTop: '4px' }}>
                <Typography.Text>
                  {gettextCatalog.getString('at', undefined, 'at time')}
                </Typography.Text>
              </Col>
              <Col span={21}>
                <Form.Item style={{ width: '100%' }}>
                  <CdTimePicker
                    id="facebook-custom-time"
                    onChange={onChangeTime}
                    value={timeValue}
                    disabled={props.hasPublishedToFacebook || props.disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Form.Item label={gettextCatalog.getString('Write facebook post')}>
        <TextArea
          data-testid="facebook-message"
          rows={3}
          onChange={({ target: { value } }) =>
            props.onChange({ ...props.value, message: value })
          }
          value={props.value?.message}
          disabled={props.disabled}
        />
        {props.value.message?.length > 100 &&
          gettextCatalog.getString(
            'Keep your message shorter than 100 characters to make it easier for people to read.'
          )}
      </Form.Item>
      {props.value.publishOn && !props.hasPublishedToFacebook && (
        <Form.Item noStyle>
          {gettextCatalog.getString('Will be posted on {{date}}', {
            date: moment(props.value.publishOn).format('LLLL'),
          })}
        </Form.Item>
      )}
    </>
  );
};

export default FacebookSetting;
