'use strict';

class PeopleCustomFieldsDataController {
  constructor(
    _,
    gettextCatalog,
    PeopleCustomFieldsData,
    Authorization,
    cdRedactedValue,
    appUtils
  ) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.PeopleCustomFieldsData = PeopleCustomFieldsData;
    this.Authorization = Authorization;
    this.cdRedactedValue = cdRedactedValue;
    this.appUtils = appUtils;
  }

  $onInit() {
    const _ = this._;

    // Format values based on the type of their component
    this.formattedValues = _(this.customFieldsComponents)
      .reject((c) => c.components)
      .reject({ type: 'button' })
      .keyBy('key')
      .reduce((result, component) => {
        const key = component.key;

        // Check if the component has value, if it does not return with out formatting
        if (!_.has(this.customFieldsData, key)) return result;

        // The original value from the submission
        const value = _.get(this.customFieldsData, key);

        // Format the value based on the type of the component
        const formattedValue = this.appUtils.formatSubmission(
          value,
          component,
          true
        );

        return _.extend({}, result, {
          [key]: formattedValue,
        });
      }, {});
  }
}
PeopleCustomFieldsDataController.$inject = [
  '_',
  'gettextCatalog',
  'PeopleCustomFieldsData',
  'Authorization',
  'cdRedactedValue',
  'appUtils',
];

angular.module('cdApp.shared').component('cdPeopleCustomFieldsData', {
  templateUrl:
    '@/app/shared/components/people-custom-fields-data/people-custom-fields-data.component.html',
  controller: PeopleCustomFieldsDataController,
  bindings: {
    customFieldsComponents: '<',
    customFieldsData: '<',
  },
});
