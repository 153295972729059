export const getCRMContactDetails = (language: string) => {
  let multiParishLearnMoreUrl;

  let bookingPhone;

  // Phone
  switch (language) {
    case 'da':
      bookingPhone = '7070 2670';
      multiParishLearnMoreUrl = 'https://churchdesk.com/da/app-multi-parish/';
      break;
    case 'sv':
      bookingPhone = '+45 7070 2670';
      multiParishLearnMoreUrl = 'https://churchdesk.com/da/app-multi-parish/';
      break;
    case 'de':
      bookingPhone = '+49 30 568373604';
      multiParishLearnMoreUrl = 'https://churchdesk.com/de/app-multi-parish/';
      break;
    default:
      bookingPhone = '+44 20 3808 5097';
      multiParishLearnMoreUrl = 'https://churchdesk.com/en/app-multi-parish/';
      break;
  }
  const extra: { multiParishLearnMoreUrl?: string } = {
    multiParishLearnMoreUrl,
  };
  return { bookingPhone, extra };
};
