import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import PrivacyPolicy from '@/react/settings/pages/privacy-policy/PrivacyPolicy';

angular
  .module('cdApp.settings')
  .component(
    'cdrPrivacyPolicy',
    react2angular(sharedContext.use(PrivacyPolicy), [], [])
  );
