import { useQuery } from '@tanstack/react-query';

import { Stole } from '../models/stole';

import { mainApi } from '@/react/api';

export const useFetchEventStole = (calendarId: number) =>
  useQuery({
    queryKey: ['useFetchEventStole', calendarId],
    queryFn: async () => {
      const response = await mainApi.get<Stole>(
        `/intention/stoles/assigned/${calendarId}`
      );
      if (response.ok) {
        return response.data as any;
      }
      throw response.data;
    },
  });
