import React from 'react';
import { Space, Tag } from 'antd';

import CdTooltip from './cd-tooltip/CdTooltip';

import { CdMasquerade } from '@/react/shared/components/Icons';

export const CdMasqueradeTag = () => (
  <CdTooltip title="Only visible for ChurchDesk Employees">
    <Tag color="purple" style={{ marginInlineEnd: '0px' }}>
      <Space>
        <CdMasquerade />
        Only Empl
      </Space>
    </Tag>
  </CdTooltip>
);
