export interface Column {
  title: string;
  dataIndex: string | string[];
  key: string;
  fixed?: string;
  align?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  render?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  sorter?: Function | boolean;
  width?: number | string;
  sortOrder?: string;
  defaultSortOrder?: string;
}

export enum OrderType {
  DATE = 'date',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface TableOrdering {
  orderBy: string;
  orderDirection: OrderDirection;
  orderType?: OrderType | string;
}
