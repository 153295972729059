import { all, takeLatest, fork } from 'redux-saga/effects';

import { STARTUP } from '../redux/startup-redux/Actions';
import CalendarSaga from '../calendar/sagas';
import ConfigSaga from '../config/saga';
import IntentionSagas from '../intention/sagas';
import OrganizationSaga from '../organization/sagas';
import UserSagas from '../user/sagas/index';
import SharedSagas from '../shared/sagas';
import SettingsSaga from '../settings/sagas';
import PortalSagas from '../portal/sagas';

import { startup } from './StartupSagas';

import FilePickerSaga from '@/react/calendar/file-picker-library/store/saga';

export default function* root(): Generator {
  yield all([
    takeLatest(STARTUP, startup),
    fork(CalendarSaga),
    fork(ConfigSaga),
    fork(IntentionSagas),
    fork(SettingsSaga),
    fork(OrganizationSaga),
    fork(FilePickerSaga),
    fork(UserSagas),
    fork(SharedSagas),
    fork(PortalSagas),
  ]);
}
