import 'react-day-picker/lib/style.css';

import { Layout } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';

import { SidebarContent } from './SidebarContent';

declare interface SideBarProps {
  children: ReactNode;
  closePopover: () => void;
}

const { Sider, Content } = Layout;

const SiderContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const StyledLayout = styled(Layout)`
  &&&& {
    overflow: initial;
    height: 100%;
  }
`;
const StyledSider = styled(Sider)`
  &&&& {
    overflow: initial;
    height: 100%;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
    z-index: 1;

    .ant-layout-sider-children {
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: 'none';
    }
    @media print {
      flex: 0 !important;
      min-width: 0 !important;
      width: 0 !important;
    }
  }
`;
const SideBarComponent: FunctionComponent<SideBarProps> = ({
  children,
  closePopover,
}) => {
  const onKeyDown = useCallback(
    (event) => {
      // keyCode 27 = esc key
      if (event.keyCode === 27) {
        closePopover();
      }
    },
    [closePopover]
  );
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
  return (
    <SiderContainer onClick={closePopover}>
      <StyledLayout>
        <StyledSider collapsed={false} collapsedWidth={0} width={230}>
          <SidebarContent />
        </StyledSider>
        <Content>{children}</Content>
      </StyledLayout>
    </SiderContainer>
  );
};

export default SideBarComponent;
