import _ from 'lodash';

angular.module('cdApp.shared').factory('ColumnManager', [
  'localStorageService',
  'gettextCatalog',
  'cdApp',
  function (localStorageService, gettextCatalog, cdApp) {
    'ngInject';

    return class ColumnManagerService {
      /**
       * Create a new instance of the column manager
       *
       * @param {String} name - The name of the instance, will be used for storing the configuration in LocalStorage. If it's not provided,
       *                        the configuration won't be stored in the local storage.
       * @param {Object[]} columns - The columns that will be shown in the column manager
       * @param {String} placeholder - The placeholder of the search input
       * @param {Function} onChange - A callback that's triggered whenever the state of the columns are changed
       */
      constructor({
        name,
        columns = [],
        placeholder = null,
        onChange = _.noop,
      }) {
        this.name = name;
        this.columns = columns;
        this.placeholder =
          placeholder || gettextCatalog.getString('Search for a field...');
        this.onChange = onChange;

        this.setColumns(this.columns);

        // Restore previously saved columns
        this.getFromLocalStorage();
      }

      /**
       * Set the columns of the column manager
       */
      setColumns(columns) {
        const showChurchSelector = cdApp.showChurchSelector;
        this.columns = showChurchSelector
          ? columns
          : _.filter(columns, (item) => item.property !== 'churches');
        this.columnsByGroup = _.groupBy(columns, 'group');
      }

      /**
       * Dynamically add a column to the column manger
       * @param {Object} column The column to add
       */
      addColumn(column) {
        this.setColumns(_.concat(this.columns, column));
        this.onColumnsChange();
      }

      /**
       * Dynamically remove a column to the column manger
       * @param property The unique property of the column to remove
       */
      removeColumn(property) {
        this.setColumns(
          _.without(this.columns, _.find(this.columns, { property }))
        );

        this.onColumnsChange();
      }

      /**
       * Toggle the visibility of a column
       *
       * @param {String} property The unique property of the column
       * @param {Object} event The event object, used to prevent unwanted behavior
       */
      toggleColumn(property, event) {
        event.stopPropagation();

        const column = _.find(this.columns, { property });
        column.isVisible = !column.isVisible;

        this.onColumnsChange();
      }

      /**
       * Toggle the visibility of all columns
       *
       * @param {Object} event The event object, used to prevent unwanted behavior
       */
      toggleAllColumns(event) {
        event.stopPropagation();

        _.each(this.columns, (column) => {
          column.isVisible = !this.isDeterminate;
        });

        this.onColumnsChange();
      }

      onColumnsChange() {
        // Store the current state of the column in local storage
        this.setToLocalStorage();

        // Update the state of the selection to whether all columns are selected or not
        this.isDeterminate = _.every(this.columns, { isVisible: true });

        // Callback to parent components
        this.onChange();
      }

      /**
       * Persist the current column configuration
       */
      setToLocalStorage() {
        if (!this.name) return;

        const columns = _(this.columns)
          .filter('isVisible')
          .map('property')
          .value();
        localStorageService.set(`columns.${this.name}`, columns);
      }

      /**
       * Retrieve the persisted column configuration
       */
      getFromLocalStorage() {
        if (!this.name) return;

        const previousColumns = localStorageService.get(`columns.${this.name}`);

        if (previousColumns) {
          _.each(this.columns, (column) => {
            column.isVisible = _.includes(previousColumns, column.property);
          });
        }
      }
    };
  },
]);
