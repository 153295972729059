import { AnyAction, Store } from 'redux';
import { Persistor, PersistPartial, persistReducer } from 'redux-persist';

import { CalendarState } from '../calendar/store';
import { ImmutableConfigState } from '../config/store/Reducers';
import { IntentionCombinedState } from '../intention/redux';
import { OrganizationCombinedState } from '../organization/redux';
import rootSaga from '../saga';
import { ImmutableLoadingState } from '../shared/loading/redux';
import { SharedState } from '../shared/store';
import { ImmutableUserState } from '../user/redux';
import { SettingsCombinedState } from '../settings/redux';
import { TaxonomiesState } from '../taxonomies/store';
import { SettingsState } from '../calendar/store/calendar-settings/calendarSettingsSlice';
import { ImmutablePortalState } from '../portal/redux';

import ReduxPersistConfig from './config/ReduxPersistConfig';
import configureStore from './CreateStore';
import RootReducer from './rootReducer';
import { ImmutableStartupState } from './startup-redux';

import { FilePickerState } from '@/react/calendar/file-picker-library/store/reducers';
export interface AppState {
  calendar: CalendarState;
  config: ImmutableConfigState;
  startup: ImmutableStartupState;
  loading: ImmutableLoadingState;
  taxonomies: TaxonomiesState;
  organization: OrganizationCombinedState;
  user: ImmutableUserState;
  intention: IntentionCombinedState;
  settings: SettingsCombinedState;
  shared: SharedState;
  modals: unknown;
  calendarSettings: SettingsState;
  portal: ImmutablePortalState;
  filepicker: FilePickerState;
}

const getStore = (): {
  persistor: Persistor;
  store: Store<AppState & PersistPartial, AnyAction>;
} => {
  const persistedReducer = persistReducer<AppState, AnyAction>(
    ReduxPersistConfig.storeConfig,
    RootReducer
  );
  return configureStore(persistedReducer, rootSaga);
};

export default class StoreSingleton {
  private static instance: Store<AppState & PersistPartial, AnyAction>;
  private static persistor: Persistor;
  public static getStore(): Store<AppState & PersistPartial, AnyAction> {
    if (!StoreSingleton.instance) {
      const { store, persistor } = getStore();
      StoreSingleton.instance = store;
      StoreSingleton.persistor = persistor;
    }
    return StoreSingleton.instance;
  }

  public static getPersistor(): Persistor {
    if (!StoreSingleton.persistor) {
      const { store, persistor } = getStore();
      StoreSingleton.instance = store;
      StoreSingleton.persistor = persistor;
    }
    return StoreSingleton.persistor;
  }
}
