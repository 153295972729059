import { forEach } from 'lodash';

import { mainApi, portalWidgetService } from '../../api';
import {
  Portal,
  PortalCategory,
  PortalWidget,
  OrganizationPortals,
  WidgetType,
} from '../types/portal.type';
import Config from '../../config';
class PortalService {
  // Get a single portal
  public async getPortal(id: string): Promise<Portal> {
    const response = await mainApi.get(`/portal/portals/v2/${id}`);
    if (response.ok) {
      return response.data as Portal;
    }
    throw response.data;
  }

  // Get the portal where the organization provided is the host
  public async getOrganizationPortals({
    organizationId,
  }: {
    organizationId?: string;
  }): Promise<OrganizationPortals> {
    const response = await mainApi.get(`/portal/portals/organization`, {
      organizationId,
    });
    if (response.ok) {
      return response.data as OrganizationPortals;
    }
    throw response.data;
  }

  public async getAllPortalCategories(
    portalId: string
  ): Promise<PortalCategory[]> {
    const response = await mainApi.get(`/portal/categories/all/${portalId}`);
    if (response.ok) {
      return response.data as PortalCategory[];
    }
    throw response.data;
  }

  public async getPortalCategory(
    portalCategoryId: string
  ): Promise<PortalCategory> {
    const response = await mainApi.get(
      `/portal/categories/${portalCategoryId}`
    );
    if (response.ok) {
      return response.data as PortalCategory;
    }
    throw response.data;
  }

  public async createPortalCategory(
    name: string,
    description: string,
    portalId: string
  ): Promise<void> {
    const response = await mainApi.post(`/portal/categories`, {
      name,
      description,
      portalId,
    });
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async updatePortalCategory(
    name: string,
    description: string,
    categoryId: string
  ): Promise<void> {
    const response = await mainApi.put(`/portal/categories/${categoryId}`, {
      name,
      description,
    });
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async deletePortalCategory(portalCategoryId: string): Promise<void> {
    const response = await mainApi.delete(
      `/portal/categories/${portalCategoryId}`
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async mapCategories(payload: {
    eventCategoryId: number;
    portalCategories: number[];
  }) {
    const response = await mainApi.post('/portal/categories/mapping', payload);

    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async getCategoryMapping(eventCategoryId: number) {
    const response = await mainApi.get(
      `/portal/categories/mapping/${eventCategoryId}`
    );
    if (response.ok) {
      return response.data;
    }

    throw response.data;
  }

  public async getAllPortalWidgets(portalId: string): Promise<PortalWidget[]> {
    const response = await mainApi.get(`/portal/widgets/all/${portalId}`);
    if (response.ok) {
      forEach(response.data as PortalWidget[], (widget: PortalWidget) => {
        widget.url = `${Config.config.api.portal}/widget-snippet-generator?widgetId=${widget.id}&portalId=${widget.portalId}`;
      });
      return response.data as PortalWidget[];
    }
    throw response.data;
  }

  public async getPortalWidget(portalWidgetId: string): Promise<PortalWidget> {
    const response = await mainApi.get(`/portal/widgets/${portalWidgetId}`);
    if (response.ok) {
      return response.data as PortalWidget;
    }
    throw response.data;
  }

  public async createPortalWidget(
    type: WidgetType,
    name: string,
    portalId: string,
    configuration?: Record<string, unknown>
  ): Promise<void> {
    const response = await mainApi.post(`/portal/widgets`, {
      type,
      portalId,
      name,
      configuration,
    });
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async updatePortalWidget(
    type: WidgetType,
    name: string,
    portalWidgetId: string,
    configuration?: Record<string, unknown>
  ): Promise<void> {
    const response = await mainApi.put(`/portal/widgets/${portalWidgetId}`, {
      type,
      name,
      configuration,
    });
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async deletePortalWidget(portalWidgetId: string): Promise<void> {
    const response = await mainApi.delete(`/portal/widgets/${portalWidgetId}`);
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async getPortalWidgetCode(
    portalId: string,
    widgetId: string
  ): Promise<string> {
    const response = await portalWidgetService.get(
      '/widget-snippet-generator',
      {
        widgetId,
        portalId,
        render: 'code',
      }
    );
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  }

  public async getSignUpWidgetCode(organizationId: number): Promise<string> {
    const response = await portalWidgetService.get(
      '/signup-widget-snippet-generator',
      {
        organizationId,
        render: 'code',
      }
    );
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  }

  public async getDonationWidgetCode({
    organizationId,
    alias,
    language,
  }: {
    organizationId: number;
    alias: string;
    language: string;
  }): Promise<string> {
    const response = await portalWidgetService.get(
      '/donation-widget-snippet-generator',
      {
        organizationId,
        alias,
        language,
        render: 'code',
      }
    );
    if (response.ok) {
      return response.data as string;
    }
    throw response.data;
  }
}

export default new PortalService();
