import { useRecoilValue, useRecoilCallback, useRecoilState } from 'recoil';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import {
  Organizations,
  OrganizationsSearchText,
  OrganizationById,
  SelectedOrganizationId,
} from '../store/organization';
import { OrganizationFromSearch } from '../types/organization';

import { mainApi } from '@/react/api';
import cdApp from '@/react/config';

interface UploadLogoResponse {
  exceededPixelSize: number;
  url: string;
}
export interface AttachFileInterface
  extends Pick<
    UploadRequestOption<UploadLogoResponse>,
    'onSuccess' | 'onProgress' | 'onError' | 'file'
  > {
  fileName?: string;
}
/**
 * Usefull hook for listing multiple organizations
 */
export default function useOrganizations() {
  const searchText = useRecoilValue(OrganizationsSearchText);
  const organizations = useRecoilValue(Organizations);
  const [searchId, setSearchId] = useRecoilState(SelectedOrganizationId);

  const resetSearch = useRecoilCallback(({ set }) => () => {
    set(SelectedOrganizationId, cdApp.organization.id);
  });
  const searchByName = useRecoilCallback(
    ({ set }) =>
      async (searchValue?: string) => {
        if (searchValue.length <= 2) {
          return;
        }

        const { ok, data } = await mainApi.get<OrganizationFromSearch[]>(
          '/organizations/search',
          {
            limit: 10, // Backend only supports 10 for this endpoint
            name: searchValue,
            country: cdApp.organization.countryIso2,
          }
        );

        if (ok) {
          data.forEach((org) => set(OrganizationById(org.id), org));
        }
        if (searchValue) {
          set(OrganizationsSearchText, searchValue);
        }
      },
    []
  );

  return {
    organizations,
    searchByName,
    searchId,
    searchText,
    setSearchId,
    resetSearch,
  };
}
