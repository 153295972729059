import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 141, 173, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;
const StyledSpin = styled(Spin)`
  &&&& {
    .ant-spin-dot-item {
      background-color: white;
    }
  }
`;
export const CdrSpinner = ({
  tipLabel,
  width,
}: {
  tipLabel: string;
  width: number;
}) => (
  <Overlay style={{ width: `${width}%` }}>
    <StyledSpin
      style={{ color: 'white', fontWeight: 900 }}
      tip={tipLabel}
      size="large"
    />
  </Overlay>
);
