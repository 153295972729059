import { ActionCreator, CdAction } from '../../../redux/utils';
import { Stole, StoleStatusTypes } from '../../models/stole';
import { StolesData } from '../../services/StoleService';
import { FEvent as Event } from '../../../calendar/models/calendar';

// Fetch stole from event

export type FetchEventStoleActionType = CdAction<number>;
export const FETCH_EVENT_STOLE = 'intention/fetchEventStole';
export const fetchEventStole: ActionCreator<number> = (payload) => ({
  type: FETCH_EVENT_STOLE,
  payload,
});

export type FetchEventStoleSuccessActionType = CdAction<Stole>;
export const FETCH_EVENT_STOLE_SUCCESS = 'intention/fetchEventStoleSuccess';
export const fetchEventStoleSuccess: ActionCreator<Stole> = (payload) => ({
  type: FETCH_EVENT_STOLE_SUCCESS,
  payload,
});

export type ClearEventStoleActionType = CdAction<void>;
export const CLEAR_EVENT_STOLE = 'intention/clearEventStole';
export const clearEventStole: ActionCreator<void> = () => ({
  type: CLEAR_EVENT_STOLE,
});

export type RefreshEventStoleActionType = CdAction<number>;
export const REFRESH_EVENT_STOLE = 'intention/refreshEventStole';
export const refreshEventStole: ActionCreator<number> = (calendarId) => ({
  type: REFRESH_EVENT_STOLE,
  payload: calendarId,
});

export interface FetchStolesPayload {
  status?: StoleStatusTypes;
  includeDeleted?: boolean;
}

export type FetchStolesActionType = CdAction<FetchStolesPayload>;
export const FETCH_STOLES = 'intention/fetchStoles';
export const fetchStoles: ActionCreator<FetchStolesPayload> = (payload) => ({
  type: FETCH_STOLES,
  payload,
});

export type FetchStolesSuccessActionType = CdAction<StolesData>;
export const FETCH_STOLES_SUCCESS = 'intention/fetchStolesSuccess';
export const fetchStolesSuccess: ActionCreator<StolesData> = (payload) => ({
  type: FETCH_STOLES_SUCCESS,
  payload,
});

// Billing
export interface FetchBillingStolePayload {
  month?: number;
  year?: number;
  churchIds?: number[];
}

export type FetchStoleBillingEventsActionType =
  CdAction<FetchBillingStolePayload>;
export const FETCH_STOLE_BILLING_EVENTS = 'intention/fetchStoleBillingEvents';
export const fetchStoleBillingEvents: ActionCreator<FetchBillingStolePayload> =
  (payload) => ({
    type: FETCH_STOLE_BILLING_EVENTS,
    payload,
  });

export type FetchStoleBillingEventsSuccessActionType = CdAction<Event[]>;
export const FETCH_STOLE_BILLING_EVENTS_SUCCESS =
  'intention/fetchStoleBillingEventsSuccess';
export const fetchStoleBillingEventsSuccess: ActionCreator<Event[]> = (
  payload
) => ({
  type: FETCH_STOLE_BILLING_EVENTS_SUCCESS,
  payload,
});

export type ClearStoleBillingEventsActionType = CdAction<void>;
export const CLEAR_STOLE_BILLING_EVENTS = 'intention/clearStoleBillingEvents';
export const clearStoleBillingEvents: ActionCreator<void> = () => ({
  type: CLEAR_STOLE_BILLING_EVENTS,
});

export type GenerateStoleBillingReportActionType =
  CdAction<FetchBillingStolePayload>;
export const GENERATE_STOLE_BILLING_REPORT =
  'intention/generateStoleBillingReport';
export const generateStoleBillingReport: ActionCreator<
  FetchBillingStolePayload
> = (payload) => ({
  type: GENERATE_STOLE_BILLING_REPORT,
  payload,
});

// Restore stole
interface RestoreStolePayload {
  id: string;
}
export type RestoreStoleActionType = CdAction<RestoreStolePayload>;
export const RESTORE_STOLE = 'intention/restoreStole';
export const restoreStole: ActionCreator<RestoreStolePayload> = (payload) => ({
  type: RESTORE_STOLE,
  payload,
});
