import { atom, selector, selectorFamily, waitForAll } from 'recoil';

import { ApiSearchParams, ApiSearchResult, formsApi } from '../../api';
import {
  FormFromBackend,
  FormFromSearch,
  FormListSearchParams,
  FormLabel,
} from '../types';

import { handleError } from '@/react/services/ErrorHandlingService';
import {
  OrganizationUsers,
  SelectedOrganizationChurches,
  SelectedOrganizationId,
} from '@/react/organization/store/organization';

export const FormListSearchCache = atom<number>({
  key: 'FormListSearchCache',
  default: 0,
});

export const FormListSearchParamsAtom = atom<FormListSearchParams>({
  key: 'FormListSearchParams',
  default: selector({
    key: 'FormListSearchParamsSelector',
    get: ({ get }) => ({
      labelId: null,
      churches: get(SelectedOrganizationChurches).map(({ id }) => id),
      title: '',
      myForms: true,
      sortBy: 'createdAt',
      sortDirection: 'desc',
      activeState: true,
    }),
  }),
});

export const FormListSearch = selectorFamily<
  ApiSearchResult<FormFromSearch>,
  ApiSearchParams
>({
  key: 'FormSearch',
  get:
    ({ limit, pageNumber }: ApiSearchParams) =>
    async ({ get }) => {
      get(FormListSearchCache);
      const searchParams: FormListSearchParams = get(FormListSearchParamsAtom);
      const { ok, data } = await formsApi.get<ApiSearchResult<FormFromBackend>>(
        '/v2/forms',
        {
          organizationId: get(SelectedOrganizationId),
          ...searchParams,
          pageNumber,
          limit,
        }
      );
      if (!ok) {
        handleError(data as unknown as Error);
      }
      const [organizationChurches, labels, authors] = get(
        waitForAll([
          SelectedOrganizationChurches,
          FormLabels,
          OrganizationUsers,
        ])
      );

      return {
        ...data,
        items: data.items.map(({ labelId, authorId, churches, ...rest }) => ({
          ...rest,
          labelId,
          label: labels.items.find(({ id }) => id === labelId)?.label,
          author: authors.find(({ id }) => id === authorId),
          churches: churches
            .filter((cId) => !!cId)
            .map((cId) => organizationChurches.find(({ id }) => id === cId))
            .filter((chuch) => !!chuch),
        })) as unknown as FormFromSearch[],
      };
    },
});

export const FormLabels = selector<ApiSearchResult<FormLabel>>({
  key: 'FormLabels',
  get: async () => {
    const { ok, data } =
      await formsApi.get<ApiSearchResult<FormLabel>>('/label');

    if (!ok) {
      handleError(data as unknown as Error);
    }

    return {
      ...data,
      items: data.items.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
    };
  },
});
