import { FEvent } from '../../calendar/models/calendar';

import { IdentifiableEntity } from './commons';

export enum IntentionSourceType {
  I = 'I',
  L = 'L',
  S = 'S',
}

export enum IntentionStatusTypes {
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
}

export enum IntentionReferenceNumberSourceType {
  INTENTION = 'I',
  LEGATE = 'L',
  STIFTUNGEN = 'S',
}

export enum IntentionTabNames {
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  ALL = 'all',
}

export enum PaymentMethodTypes {
  PAIDBYCASH = 'cash',
  PAIDBYBANK = 'bank',
}

export enum IntentionPriorityTypes {
  PERSOLVIERT = 'persolviert',
  WEITERLEITUNG = 'weiterleitung',
}

export interface Intention {
  id: string;
  sourceType: IntentionSourceType;
  sourceNumber: number;
  referenceNumber: number;
  formattedReferenceNumber: string;
  acceptedAt: Date;
  preferredDate: Date;
  preferredNote: string;
  intentionText: string;
  status: IntentionStatusTypes;
  feeId: string;
  intentionYear: number;
  churchId: number;
  church: IdentifiableEntity;
  resourceId: number;
  resource: IdentifiableEntity;
  paymentMethod: PaymentMethodTypes;
  paid: boolean;
  paidAt: Date;
  parentId: string;
  personId: number;
  person: IdentifiableEntity;
  founder: string;
  createdById: number;
  updatedById: number;
  assignedById: number;
  organizationId: number;
  calendarId: number;
  event: Partial<FEvent> | any;
  priority: IntentionPriorityTypes;
  comment: string;
  assignedAt: Date;
  completedAt: Date;
  forwardingRecipientId: string;
  forwardingRecipient: IdentifiableEntity;
  internalForwardingRecipientId: number;
  internalForwardingRecipient: IdentifiableEntity;
  internallyForwardedIntentionId: string;
  forwardedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface IntentionFilters {
  churchIds: number[];
  feeIds: string[];
  paymentMethods: PaymentMethodTypes[];
  referenceNumberSourceType: IntentionSourceType;
  referenceNumberFrom: number;
  referenceNumberTo: number;
  referenceNumberYear: number;
  searchValue: string;
  acceptanceDateFrom: Date;
  acceptanceDateTo: Date;
  calendarDateFrom: Date;
  calendarDateTo: Date;
  completedMonth: number;
  completedYear: number;
  deadlineDate: Date;
  forwardingMonth: number;
  forwardingYear: number;
  paid: boolean;
  status?: IntentionStatusTypes;
}

export enum IntentionSourceType {
  ALL = 'All',
  INTENTIONEN = 'I',
  LEGATE = 'L',
  STIFTUNGEN = 'S',
}
