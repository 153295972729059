import React from 'react';
import moment from 'moment';
import { Space, Tag } from 'antd';
import { capitalize } from 'lodash';

import { CdLockSolid } from '../../shared/components/Icons';
import { OrderType } from '../../shared/models/table';

import { WidgetInterface, WidgetType } from './types/widget.type';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { ColumnType } from '@/react/shared/components/cd-table/types';
import { gettextCatalog } from '@/react/services/I18nService';

export const marksStringsForTranslations = () => {
  gettextCatalog.getString('blog');
  gettextCatalog.getString('event');
  gettextCatalog.getString('donation');
  gettextCatalog.getString('slideshow');
};

const WidgetTableColumn = () => {
  const WidgetsColumn: ColumnType<WidgetInterface>[] = [
    {
      title: gettextCatalog.getString('Name'),
      sorter: true,
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (name, data) => (
        <Space>
          {name}
          {!data.id && <CdLockSolid />}
          {data?.legacy && (
            <CdTooltip
              trigger="hover"
              title={gettextCatalog.getString(
                'Not possible to configure content as it has been migrated with certain categories and churches. But you can continue to configure styling'
              )}
            >
              <>
                <Tag color="gold">
                  {gettextCatalog.getString('Migration - shared content')}
                </Tag>
              </>
            </CdTooltip>
          )}
        </Space>
      ),
    },
    {
      title: gettextCatalog.getString('Type'),
      dataIndex: 'type',
      sorter: true,
      render: (data) => <div>{capitalize(translateWidgetType(data))}</div>,
    },
    {
      title: gettextCatalog.getString('Created'),
      dataIndex: 'createdAt',
      sorter: true,
      render: (data) => data && <div>{moment(data).format('lll')}</div>,
    },
  ];
  return WidgetsColumn;
};

function translateWidgetType(type: WidgetType) {
  switch (type) {
    case WidgetType.EVENT:
      return gettextCatalog.getString('event');
    case WidgetType.BLOG:
      return gettextCatalog.getString('blog');
    case WidgetType.SLIDESHOW:
      return gettextCatalog.getString('slideshow');
    case WidgetType.DONATION:
      return gettextCatalog.getString('donation');
  }
}

export default WidgetTableColumn;
