'use strict';

class EventPopupService {
  constructor(
    $q,
    $compile,
    $window,
    $rootScope,
    $transitions,
    Calendar,
    moment,
    gettextCatalog,
    _
  ) {
    'ngInject';

    this.$compile = $compile;
    this.$window = $window;
    this.$rootScope = $rootScope;
    this.Calendar = Calendar;
    this.moment = moment;
    this.gettextCatalog = gettextCatalog;
    this._ = _;

    this.baseClass = 'event-popup';

    /**
     * Add the event popups container to the document's body
     */
    const containerClass = 'event-popups';
    if (angular.element(`.${containerClass}`).length === 0) {
      angular
        .element('body')
        .append(angular.element(`<div class="${containerClass}"></div>`));
    }

    $transitions.onStart({}, () => this.destroyAll());
  }

  /**
   * View an event in a popup
   *
   * @description You can either pass an event object, in which case the event will be shown immediately,
   *              or the id of an event, in which case the event will be loaded by the service and
   *              a loading indicator will be shown until the event has been fully loaded.
   *
   * @param {Number|Object} eventSource The event object or alternatively the id of the event
   * @param {Object} targetElement The target element to attach the popup to
   * @param {Object} options Additional options or callbacks
   * @param {Boolean} options.fcEventId The id of the corresponding fullcalendar event
   * @param {Boolean} options.openOnClick Whether to open the popup when clicking the element. The default is on click
   * @param {Function} options.onShow Callback function called when the popup is shown
   * @param {Function} options.onHide Callback function called when the popup is hidden
   * @param {Function} options.onDelete Callback function called when the event is deleted
   * @param {Function} options.onUpdateResponse Callback function called when the user responds to the invitation of the event
   */
  viewEvent(
    eventSource,
    targetElement,
    type,
    options = {
      fcEventId: null,
      openOnClick: false,
      onShow: this._.noop,
      onHide: this._.noop,
      onDelete: this._.noop,
      onUpdateResponse: this._.noop,
    }
  ) {
    const _ = this._;

    if (!eventSource || !targetElement) {
      throw new Error(
        '`eventSource` and `targetElement` are required to view an event.'
      );
    }
    if (typeof eventSource === 'string') {
      let eventRequest;
      if (type === 'external') {
        eventRequest = this.Calendar.getExternalEvent({ id: this.eventSource });
      } else {
        eventRequest = this.Calendar.get({ id: eventSource });
      }
      eventRequest.$promise.then(() =>
        this.renderQtip(options, eventRequest, targetElement, type)
      );

      return;
    }
    if (_.isObject(eventSource) && !(eventSource instanceof this.Calendar)) {
      throw new Error(
        'The `eventSource` must be an instance of `Calendar` or number represent event Id.'
      );
    }
    this.renderQtip(options, eventSource, targetElement, type);
  }

  renderQtip(options, eventSource, targetElement, type) {
    const _ = this._;
    options.onShow = options.onShow || _.noop;
    options.onHide = options.onHide || _.noop;
    options.onDelete = options.onDelete || _.noop;
    options.onUpdateResponse = options.onUpdateResponse || _.noop;
    const scope = _.extend(this.$rootScope.$new(true), {
      $ctrl: {
        eventSource,
        targetElement,
        type,
        fcEventId: options.fcEventId,
        onDelete: options.onDelete,
        onUpdateResponse: options.onUpdateResponse,
      },
    });

    const delay = 500;
    const content = this.$compile(
      '<cd-event-popup event-source="$ctrl.eventSource" type="$ctrl.type" fc-event-id="$ctrl.fcEventId" target-element="$ctrl.targetElement" on-delete="$ctrl.onDelete(event, fcEventId)" on-update-response="$ctrl.onUpdateResponse(event, fcEventId, attending)"></cd-event-popup>'
    )(scope);
    // The distance between the popup and the target element, in pixels
    const adjustment = 12;
    $(targetElement).qtip({
      content,
      show: {
        solo: true,
        ready: true,
        event: options.openOnClick ? 'click' : 'mouseenter',
        delay: 0,
      },

      hide: {
        fixed: true,
        event: options.openOnClick ? 'unfocus' : 'mouseleave',
        delay: options.openOnClick ? 0 : delay,
      },

      style: {
        width: '4500px',
        classes: this.baseClass,
        tip: false,
      },

      position: {
        my: 'left center',
        at: 'right center',
        target: $(targetElement),
        viewport: $(window),
        container: $('div.event-popups'),
        adjust: {
          scroll: false,
          resize: false,
        },
      },

      events: {
        show: (event, api) => {
          $(window).bind('keydown', (e) => {
            if (e.keyCode === 27) {
              api.destroy(true);
              options.onHide();
            }
          });
          options.onShow();
        },
        hide: (event, api) => {
          api.destroy(true);
          scope.$destroy();
          options.onHide();
        },
        move: (event) => {
          if (
            !event.originalEvent ||
            event.originalEvent.type !== 'manualReposition'
          ) {
            return;
          }
          const popup = $(event.target);
          const target = $(targetElement);
          const windowHeight = $(window).height();
          const popupHeight = popup.height();
          const targetHeight = target.height();
          const targetOffsetTop = target.offset().top;
          const targetOffsetBottom =
            windowHeight - targetHeight - targetOffsetTop;
          // When the popup is too tall and wouldn't fit at the bottom,
          // make it full screen by sticking it to the top of the window,
          // and the max-height will ensure that it is displayed correctly.
          if (windowHeight - targetOffsetBottom < popupHeight) {
            popup.addClass(`${this.baseClass}--full-screen`);
          }
        },
        render: (event, api) => {
          const popup = $(event.target);
          const target = $(targetElement);
          const windowWidth = $(window).width();
          const popupWidth = popup.width();
          const targetWidth = target.width();
          const targetOffsetLeft = target.offset().left;
          const targetOffsetRight =
            windowWidth - targetWidth - targetOffsetLeft;
          // Calculate whether the popup should be shown on the left or the right
          // The `move` event defined above will take care of calculating whether
          // the popup fits entirely on the screen. If it doesn't, we apply a special
          // class which pins the popup to the top of the screen so it's always in view.
          let canRenderLeft = false;
          let canRenderRight = false;
          let preferLeft = false;
          let preferRight = false;
          if (targetOffsetLeft > popupWidth) {
            canRenderLeft = true;
          } else if (windowWidth - targetOffsetRight < popupWidth) {
            canRenderRight = true;
          }
          if (targetOffsetLeft + targetWidth < targetOffsetRight) {
            preferRight = true;
          } else if (targetOffsetRight + targetWidth < targetOffsetLeft) {
            preferLeft = true;
          }
          if (preferLeft || (canRenderLeft && !preferRight)) {
            api.set({
              'position.my': 'right center',
              'position.at': 'left center',
              'position.adjust.x': -adjustment,
            });
          } else if (preferRight || (canRenderRight && !preferLeft)) {
            api.set({
              'position.my': 'left center',
              'position.at': 'right center',
              'position.adjust.x': adjustment,
            });
          } else {
            api.set({
              'position.my': 'center center',
              'position.at': 'center center',
            });
          }
        },
      },
    });
  }

  /**
   * Create an event in a popup
   *
   * @param {Number} data The initial data of the event
   * @param {Object} targetElement The target selection element where the event will be created
   * @param {Object} options Additional options or callbacks
   * @param {Function} options.onShow Callback function called when the event is created
   * @param {Function} options.onHide Callback function called when the creation is cancelled
   */
  createEvent(
    data = {},
    targetElement,
    options = {
      onShow: this._.noop,
      onHide: this._.noop,
      onCreate: this._.noop,
    }
  ) {
    if (!targetElement) {
      throw new Error('`targetElement` are required to create an event.');
    }

    options.onShow = options.onShow || this._.noop;
    options.onHide = options.onHide || this._.noop;
    options.onCreate = options.onCreate || this._.noop;

    const scope = this._.extend(this.$rootScope.$new(true), {
      $ctrl: {
        data,
        targetElement,
        onCreate: options.onCreate,
      },
    });

    const content = this.$compile(
      '<cd-event-popup-create data="$ctrl.data" target-element="$ctrl.targetElement" on-create="$ctrl.onCreate"></cd-event-popup-create>'
    )(scope);

    // The distance between the popup and the target element, in pixels
    const adjustment = 12;

    $(targetElement).qtip({
      content,
      show: {
        solo: true,
        ready: true,
        event: 'click',
      },

      hide: {
        fixed: true,
        event: 'unfocus',
      },

      style: {
        width: '450px',
        classes: `${this.baseClass} ${this.baseClass}--create`,
        tip: false,
      },

      position: {
        my: 'top center',
        at: 'bottom center',
        target: $(targetElement),
        viewport: $(window),
        container: $('div.event-popups'),
        adjust: {
          scroll: false,
          resize: false,
          x: adjustment,
          y: adjustment,
        },
      },

      events: {
        show: (event, api) => {
          $(window).bind('keydown', (e) => {
            if (e.keyCode === 27) {
              api.destroy(true);
              options.onHide();
            }
          });

          setTimeout(() => {
            angular.element(`.${this.baseClass}--create #title`).focus();
          });

          options.onShow();
        },
        hide: (event, api) => {
          api.destroy(true);
          options.onHide();
        },
        render: (event, api) => {
          const popup = $(event.target);
          // This should be refactored to Ant with better replacement logic if the component grows in size.
          // For now the quick fix is to add extra height as buffer to the qTip height.
          const roomForExtraComponentsAfterLaunch = 100;
          const target = $(targetElement);
          const windowHeight = $(window).height();
          const windowWidth = $(window).width();
          const popupHeight =
            popup.height() + roomForExtraComponentsAfterLaunch;
          const popupWidth = popup.width();
          const targetHeight = target.height();
          const targetWidth = target.width();
          const targetOffsetTop = target.offset().top;
          const targetOffsetBottom =
            windowHeight - targetOffsetTop - targetHeight;
          const targetOffsetLeft = target.offset().left;
          const targetOffsetRight =
            windowWidth - targetOffsetLeft - targetWidth;

          // If there is no enough space from the top and from the bottom.
          if (
            targetOffsetTop < popupHeight &&
            targetOffsetBottom < popupHeight
          ) {
            // Check the left and right positions.
            if (
              targetOffsetLeft > popupWidth ||
              targetOffsetRight > popupWidth
            ) {
              if (targetOffsetLeft > targetOffsetRight) {
                api.set({
                  'position.my': 'right center',
                  'position.at': 'left center',
                  'position.adjust.x': -adjustment,
                });
              } else {
                api.set({
                  'position.my': 'left center',
                  'position.at': 'right center',
                  'position.adjust.x': adjustment,
                });
              }
            } else {
              // There is not enough space on the top, bottom, left and right,
              // so we will position the popup in the middle of everything.
              api.set({
                'position.my': 'center center',
                'position.at': 'center center',
              });
            }
          }
        },
      },
    });
  }

  /**
   * Format the date of an event for displaying in the UI
   *
   * @param {Object} event The calendar event
   */
  getFormattedDate(event) {
    const moment = this.moment;
    const isAllDay = event.allDay;
    const isSingleDay =
      moment(event.endDate).diff(event.startDate, 'days') === 0;
    const allDayString = this.gettextCatalog.getString('(all day)');

    if (isSingleDay) {
      if (isAllDay) {
        return `${moment(event.startDate).format('dddd, LL')} ${allDayString}`;
      }

      // Not all day
      const date = moment(event.startDate).format('dddd, LL');
      const startTime = moment(event.startDate).format('LT');
      const endTime = moment(event.endDate).format('LT');
      return `<div>${date}</div> <div class="u-text-muted u-text-light">${startTime} - ${endTime}</div>`;
    } else {
      if (isAllDay) {
        const startDate = moment(event.startDate).format('LL');
        const endDate = moment(event.endDate).format('LL');
        return `${startDate} - ${endDate} ${allDayString}`;
      }

      // Not all day
      const startDate = moment(event.startDate).format('LLL');
      const endDate = moment(event.endDate).format('LLL');
      return `${startDate} - ${endDate}`;
    }
  }

  /**
   * Destroy all event popups.
   * Use when transitioning, so popups don't stay open when switching.
   */
  destroyAll() {
    angular.element(`.${this.baseClass}`).qtip('destroy');
  }
}

EventPopupService.$inject = [
  '$q',
  '$compile',
  '$window',
  '$rootScope',
  '$transitions',
  'Calendar',
  'moment',
  'gettextCatalog',
  '_',
];

angular
  .module('cdApp.calendar')
  .service('eventPopupService', EventPopupService);
