import React, { useState } from 'react';
import { Button, Divider, Layout } from 'antd';
import styled from 'styled-components';

import FileDetail from '../../../components/file-detail';
import Empty from '../../../components/file-detail/Empty';
import {
  Title,
  Credit,
  Date,
  Caption,
  Tags,
  GepDisclaimer,
} from '../../../components/file-detail';
import FormatSelection from '../../../components/format-selection';

import { gettextCatalog } from '@/react/services/I18nService';

const { Sider, Content, Footer } = Layout;

const StyledDivider = styled(Divider)`
  background: #d2caca;
`;

const SideBar: React.FC<{
  item: any;
  onImportFile: any;
  isUsedFromEditor: boolean;
}> = ({ item, onImportFile, isUsedFromEditor }) => {
  const [loading, setLoadingState] = useState(false);
  let imageFormat = 'medium';
  const setImageFormat = (format: string) => {
    imageFormat = format;
  };
  const onClick = () => {
    onImportFile(item.id, item.partnerId, imageFormat);
    setLoadingState(true);
  };
  let content;
  if (!item) {
    content = <Empty />;
  } else {
    content = (
      <FileDetail>
        <Layout style={{ background: '#ffffff' }}>
          <Content style={{ height: '71vh', overflowY: 'scroll' }}>
            <Title title={item.title} />
            <Caption caption={item.caption} />
            <Date date={item.publishedOn} />
            <Credit credit={item.imageCredit} />
            <Tags tags={item.tags} />
            <GepDisclaimer />
            {isUsedFromEditor ? <StyledDivider /> : null}
            {isUsedFromEditor ? (
              <FormatSelection
                defaultValue={imageFormat}
                onChange={setImageFormat}
              />
            ) : null}
          </Content>
          <Footer style={{ padding: '12px 12px 0', background: '#ffffff' }}>
            <Button type="primary" onClick={onClick} loading={loading} block>
              {gettextCatalog.getString('Select')}
            </Button>
          </Footer>
        </Layout>
      </FileDetail>
    );
  }
  return (
    <Sider theme="light" width={300} style={{ borderLeft: '1px solid #eee' }}>
      {content}
    </Sider>
  );
};

export default SideBar;
