'use strict';

class ForgotPasswordController {
  constructor(
    _,
    $timeout,
    $location,
    $state,
    toastr,
    AuthenticationService,
    Analytics,
    gettextCatalog,
    cdApp
  ) {
    this._ = _;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$state = $state;
    this.toastr = toastr;
    this.AuthenticationService = AuthenticationService;
    this.Analytics = Analytics;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
  }

  $onInit() {
    const { _, $location, AuthenticationService, gettextCatalog, Analytics } =
      this;
    this.resetData = {};
    this.isResetForm = false;
    this.isRecoverForm = true;

    if (
      $location.search().token ||
      (window.location.hash !== '' &&
        _.startsWith(window.location.hash, '#token'))
    ) {
      this.isResetForm = true;

      const token =
        $location.search().token || window.location.hash.replace('#token=', '');

      AuthenticationService.validateToken(token)
        .then((res) => {
          this.resetData = res.data;
        })
        .catch((err) => {
          Analytics.sendFeatureEvent('clicked expired reset password link');
          this.tokenValidationError =
            _.get(err, 'data.message') ||
            gettextCatalog.getString(
              'The reset link you are trying to use has is no longer valid. Please enter your e-mail to request a new link.'
            );
        });
    }
  }

  reset() {
    const { AuthenticationService, Analytics, $state, $timeout, toastr } = this;
    AuthenticationService.resetPassword({
      id: this.resetData.id,
      password: this.new,
      resetToken: this.resetData.resetPasswordToken,
      accessToken: this.resetData.accessToken,
    }).then(
      () => {
        $state.go('app.public.chooseOrganization');
        Analytics.sendFeatureEvent('reset new password successful');
      },
      (error) => {
        toastr.error(error.message);
        Analytics.sendFeatureEvent('reset new password fail');

        $timeout(() => {
          window.location = $state.href('app.public.forgotPassword');
        }, 2500);
      }
    );
  }

  forgot() {
    const { AuthenticationService, Analytics } = this;

    if (!this.email || this.email === '') {
      this.forgotResponse = 'missing-email';
      return;
    }

    AuthenticationService.forgotPassword(this.email)
      .then(() => {
        this.forgotResponse = 200;
        this.isRecoverForm = false;
        Analytics.sendFeatureEvent('requested new password successful');
      })
      .catch((err) => {
        this.forgotResponse = err.status;
        Analytics.sendFeatureEvent('requested new password fail');
      })
      .finally(() => {
        this.email = null;
      });
  }
}
ForgotPasswordController.$inject = [
  '_',
  '$timeout',
  '$location',
  '$state',
  'toastr',
  'AuthenticationService',
  'Analytics',
  'gettextCatalog',
  'cdApp',
];

angular.module('cdApp.shared').component('cdForgotPasswordPage', {
  templateUrl:
    '@/app/shared/components/forgot-password/forgot-password.component.html',
  controller: ForgotPasswordController,
});
