import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ImportNewsletterAndParticipantDropDown from '@/react/people/components/ImportNewsletterAndParticipantDropDown';

angular
  .module('cdApp.shared')
  .component(
    'cdrImportNewsletterAndParticipantSelector',
    react2angular(
      sharedContext.use(ImportNewsletterAndParticipantDropDown),
      ['handleSelectedList'],
      []
    )
  );
