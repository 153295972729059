import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../redux';
import { gettextCatalog } from '../../services/I18nService';
import { ToggleShowCollections } from '../store/main-view/Actions';

import { FilterListItem } from './FilterItem';

const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const CollectionFilter: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const onToggleCollections = useCallback(
    () => dispatch(ToggleShowCollections()),
    [dispatch]
  );
  const showCollecte = useSelector(
    (state: AppState) => state.calendar.view.showCollections
  );
  return (
    <div>
      <FilterListItem
        isDisabled={isDisabled}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <div className="bullet-wrapper">
          <div
            className="bullet-label"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              if (isDisabled) return;
              onToggleCollections();
            }}
          >
            <span className={getClassName(showCollecte, 999)} />
            <div className="label">
              <span className="name">
                {gettextCatalog.getString('Collection')}
              </span>
            </div>
          </div>
        </div>
      </FilterListItem>
    </div>
  );
};

export default CollectionFilter;
