import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Spin, Layout, Menu, Typography, Space } from 'antd';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import { gettextCatalog } from '../../../services/I18nService';
import { navigate } from '../../../services/StateServiceFactory';
import { RolesActions, roleSelectors } from '../../store/roleSlice';
import { selectIsModulesLoading } from '../../../shared/loading/redux/Selectors';
import { CdRoleIsStatic } from '../../../shared/components/Icons';
const { Sider } = Layout;
const { Text } = Typography;

const CustomMenuItem = styled(Menu.Item)`
  &&&& {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const SideMenu: FunctionComponent<{ currentRoleId: string }> = ({
  currentRoleId,
}) => {
  const organizationRoles = useSelector(roleSelectors.selectOrganizationRoles);
  const churchRoles = useSelector(roleSelectors.selectChurchRoles);
  const rolesLoading = useSelector(
    selectIsModulesLoading([RolesActions.fetchRoles.type])
  );

  const navigateTo = (roleId: string, roleContext: string) => () => {
    navigate(
      'app.private.settings.roles.edit',
      { roleContext, id: roleId, forceReloadRoles: false },
      { reload: 'app.private.settings.roles' }
    );
  };
  const renderRoleGroup = (label, roleContext, roles) => (
    <Menu.ItemGroup key={roleContext} title={label}>
      {map(
        orderBy(
          roles,
          ['isEnabled', (role) => role.name.toUpperCase()],
          ['desc', 'asc']
        ),
        (role) => {
          const isRoleLocked = !!role.usesStatic;
          return (
            <CustomMenuItem
              key={role.id}
              onClick={navigateTo(role.id, roleContext)}
            >
              <span>
                <Space>
                  <Text disabled={!role.isEnabled} delete={!role.isEnabled}>
                    {role.name}
                  </Text>
                  {isRoleLocked ? <CdRoleIsStatic /> : null}
                </Space>
              </span>
              <FontAwesomeIcon icon={faAngleRight} />
            </CustomMenuItem>
          );
        }
      )}
    </Menu.ItemGroup>
  );

  return (
    <Sider className="roles-side-menu" width={256} theme="light">
      <Spin spinning={rolesLoading} style={{ marginTop: 16 }}>
        <Menu
          style={{ width: 256, height: '100%', padding: '0 15' }}
          mode="inline"
          defaultSelectedKeys={[currentRoleId]}
        >
          {renderRoleGroup(
            gettextCatalog.getString('Organisation Roles'),
            'organization',
            organizationRoles
          )}
          {renderRoleGroup(
            gettextCatalog.getString('Parish Roles'),
            'church',
            churchRoles
          )}
        </Menu>
      </Spin>
    </Sider>
  );
};
