import { selectorFamily } from 'recoil';

import { ChurchesWithPermissionContext } from '../../shared/models/resource';
import ResourceService from '../../shared/services/ResourceService';

import AuthorizationService from '@/react/services/AuthorizationService';

type RecoilPrivilegeInput = {
  entityId: number | string;
  entityType: string;
  privilege: string;
};

type UserChurchesByPermissionInput = {
  permissionContext: string;
  permissionType: string;
};

export const UserChurchesByPermissionQuery = selectorFamily<
  ChurchesWithPermissionContext['churches'],
  UserChurchesByPermissionInput
>({
  key: 'UserChurchesByPermissionQuery',

  get:
    ({ permissionContext, permissionType }) =>
    async () => {
      const res = await ResourceService.getChurchesV3(
        permissionContext,
        permissionType
      );
      return res.churches;
    },
});

export const UserChurchesWithPermissionQuery = selectorFamily<
  ChurchesWithPermissionContext['churches'],
  UserChurchesByPermissionInput
>({
  key: 'UserChurchesWithPermissionQuery',
  get:
    ({ permissionContext, permissionType }) =>
    async ({ get }) => {
      const res = await get(
        UserChurchesByPermissionQuery({
          permissionContext,
          permissionType,
        })
      );
      return res.filter((item) => item.access);
    },
});

export const UserCheckPermisionQuery = selectorFamily<
  boolean,
  RecoilPrivilegeInput
>({
  key: 'UserCheckPermisionQuery',
  get:
    ({ privilege, entityId, entityType }) =>
    async () =>
      (
        await AuthorizationService.checkPrivilege([
          {
            entityId,
            entityType,
            privileges: [privilege],
          },
        ])
      )[entityId][privilege],
});

export const UserHasPermissionAnywhereQuery = selectorFamily<boolean, string>({
  key: 'UserHasPermissionAnywhereQuery',
  get: (permission: string) => async () => {
    const access = await AuthorizationService.hasPermissionAnywhere(permission);
    return access;
  },
});
