import {
  LOAD_FILEARCHIVE_FILES,
  FILEARCHIVE_REQUEST,
  FILEARCHIVE_REQUEST_SUCCESS,
  FILEARCHIVE_REQUEST_FAILED,
  FILEARCHIVE_FILE_SELECTED,
  FILEARCHIVE_FOLDER_SELECTED,
  FILEARCHIVE_FILES_PAGE_CHANGE,
  FILEARCHIVE_FILES_PAGE_SIZE_CHANGE,
  LOAD_FILEARCHIVE_BREADCRUMB,
  FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS,
  FILEARCHIVE_BREADCRUMB_REQUEST_FAILED,
  FILEARCHIVE_GET_TAGS,
  FILEARCHIVE_GET_TAGS_SUCCESS,
  FILEARCHIVE_GET_TAGS_FAIL,
  FILEARCHIVE_UPDATE_FILE,
  FILEARCHIVE_UPDATE_FILE_REQUEST,
  FILEARCHIVE_UPDATE_FILE_SUCCESS,
  FILEARCHIVE_UPDATE_FILE_FAIL,
  FILEARCHIVE_DELETE_FILE,
  FILEARCHIVE_DELETE_FILE_REQUEST,
  FILEARCHIVE_DELETE_FILE_SUCCESS,
  FILEARCHIVE_DELETE_FILE_FAIL,
  FILE_ARCHIVE_SEARCH,
  FILEARCHIVE_ORDERING_CHANGE,
  FILEARCHIVE_FOLDER_CREATE,
  FILEARCHIVE_FOLDER_CREATE_SUCCESS,
  FILEARCHIVE_FOLDER_CREATE_FAIL,
} from './constants';

interface FileInfo {
  fileSize: string;
  filetype: {
    type: string;
    translated_type: string;
    class: string;
    style: boolean;
  };
  filename: string;
  fileuri: string;
  fid: number;
  filemime: string;
  fileSizeBytes: number;
  thumbnail: {
    url: string;
  };
  medium: {
    url: string;
  };
  large: {
    url: string;
  };
  azul: {
    url: string;
  };
  full: {
    url: string;
  };
}
export interface FileArchive {
  id: number;
  title: string;
  visibility: string;
  organizationId: number;
  folderId: number;
  folderTitle: string;
  groupId: number;
  groupName: string;
  authorId: number;
  authorName: string;
  createdAt: Date;
  updated: Date;
  credits: string;
  type: string;
  permission: {
    canEdit: boolean;
    canDelete: boolean;
  };
  fileInfo: FileInfo;
}

export interface FileArchiveBreadCrumb {
  id: number;
  title: string;
  groupId: number;
}

export interface Ordering {
  orderBy: string;
  orderDirection: string;
}

export interface SearchType {
  value: string;
  searchIn: 'searchInFolder' | 'searchInAll';
}

export interface FileArchives {
  items: FileArchive[];
  selectedFileId?: number | undefined;
  selectedFolderId?: number;
  breadcrumb: FileArchiveBreadCrumb[];
  tags: string[];
  search: SearchType;
  reloadOnFileUpdateOrDelete: string;
  filesLoading: boolean;
  ordering: Ordering;
  pagination: {
    pageSizeOptions: number[];
    pageSize: number;
    currentPage: number;
    count: number;
  };
}

export const loadFileArchiveFiles = (payload: {
  folderId: number | string;
  offset: number;
  limit: number;
  search: SearchType;
  imagesOnly: boolean;
  orderBy: string;
  orderDirection: string;
}) => ({
  type: LOAD_FILEARCHIVE_FILES,
  payload,
});

export const fileArchiveRequest = () => ({
  type: FILEARCHIVE_REQUEST,
});

export const fileArchiveRequestSuccess = (payload: {
  items: any[];
  count: number;
}) => ({
  type: FILEARCHIVE_REQUEST_SUCCESS,
  payload,
});

export const fileArchiveSearch = (payload: {
  value: string;
  searchIn: string;
}) => ({ type: FILE_ARCHIVE_SEARCH, payload });

export const fileArchiveRequestFailed = () => ({
  type: FILEARCHIVE_REQUEST_FAILED,
});

export const onFileArchiveFileSelected = (fileId: number) => ({
  type: FILEARCHIVE_FILE_SELECTED,
  payload: { fileId },
});

export const onFileArchiveFolderSelected = (folderId: number) => ({
  type: FILEARCHIVE_FOLDER_SELECTED,
  payload: { folderId },
});

export const loadFileArchiveBreadCrumb = (folderId: number) => ({
  type: LOAD_FILEARCHIVE_BREADCRUMB,
  payload: { folderId },
});

export const fileArchiveBreadCrumbRequestSuccess = (payload) => ({
  type: FILEARCHIVE_BREADCRUMB_REQUEST_SUCCESS,
  payload,
});

export const fileArchiveBreadCrumbRequestFailed = () => ({
  type: FILEARCHIVE_BREADCRUMB_REQUEST_FAILED,
});

export const onFileArchivePageChange = (page: number) => ({
  type: FILEARCHIVE_FILES_PAGE_CHANGE,
  payload: { page },
});

export const onFileArchivePageSizeChange = (pageSize: number) => ({
  type: FILEARCHIVE_FILES_PAGE_SIZE_CHANGE,
  payload: { pageSize },
});

export const fileArchiveGetTags = () => ({
  type: FILEARCHIVE_GET_TAGS,
});

export const fileArchiveGetTagsSuccess = (payload: { tags: string[] }) => ({
  type: FILEARCHIVE_GET_TAGS_SUCCESS,
  payload,
});

export const fileArchiveGetTagsFail = () => ({
  type: FILEARCHIVE_GET_TAGS_FAIL,
});

export const fileArchiveUpdateFile = (fileId, file) => ({
  type: FILEARCHIVE_UPDATE_FILE,
  payload: { fileId, file },
});

export const fileArchiveUpdateFileRequest = () => ({
  type: FILEARCHIVE_UPDATE_FILE_REQUEST,
});

export const fileArchiveUpdateFileSuccess = () => ({
  type: FILEARCHIVE_UPDATE_FILE_SUCCESS,
});

export const fileArchiveUpdateFileFail = () => ({
  type: FILEARCHIVE_UPDATE_FILE_FAIL,
});

export const fileArchiveDeleteFile = (fileId) => ({
  type: FILEARCHIVE_DELETE_FILE,
  payload: { fileId },
});

export const fileArchiveDeleteFileRequest = () => ({
  type: FILEARCHIVE_DELETE_FILE_REQUEST,
});

export const fileArchiveDeleteFileSuccess = () => ({
  type: FILEARCHIVE_DELETE_FILE_SUCCESS,
});

export const fileArchiveDeleteFileFail = () => ({
  type: FILEARCHIVE_DELETE_FILE_FAIL,
});

export const fileArchiveOrderingChange = (payload: {
  oderBy: string;
  orderDirection: string;
}) => ({
  type: FILEARCHIVE_ORDERING_CHANGE,
  payload,
});

export const fileArchiveFolderCreate = (payload) => ({
  type: FILEARCHIVE_FOLDER_CREATE,
  payload,
});

export const fileArchiveFolderCreateSuccess = () => ({
  type: FILEARCHIVE_FOLDER_CREATE_SUCCESS,
});

export const fileArchiveFolderCreateFail = () => ({
  type: FILEARCHIVE_FOLDER_CREATE_FAIL,
});
