require('./components/calendar-feed/calendar-feed.component');
require('./components/double-booking-modal/double-booking-modal');
require('./components/event-delete-modal/event-delete-modal.component');
require('./components/event-popup');
require('./components/repeat-rule-summary/repeat-rule-summary.component');

require('./directives/calendar.directive');

require('./filters/calendar.filters');

require('./models/attendance.model');

require('./services/cache.service');
require('./services/calendar.service');
require('./services/holiday.service');
require('./services/menu.service');
require('./services/poller.service');
