import { map } from 'lodash';
import { Form, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { gettextCatalog } from '../services/I18nService';
import CdSelect from '../shared/components/cd-select/CdSelect';
import {
  emailFormat,
  phoneNumber,
  required,
} from '../shared/antd/validations/common';
import { Contact } from '../shared/models/people';
import { useChurchesV3 } from '../contributions/store/contributions';
import cdApp from '../config';

import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from './services/people.service';
import { ConflictingPeopleRelationComponent } from './components/ConflictingPeopleRelationComponent';

import { CdPhone, CdPhoneInput } from '@/react/shared/components/CdPhoneInput';

export const SimplePeopleForm = (props: {
  person: Contact;
  prefixOptions: Record<string, string>;
  createMode?: boolean;
  registerValidationHook: (func: () => void) => void;
  registerFetchValuesHook: (func: () => void) => void;
}) => {
  const [form] = Form.useForm();
  const email = Form.useWatch<string>('email', form);
  const phone = Form.useWatch<CdPhone>('phone', form);
  const prefixes = props.prefixOptions
    ? Object.keys(props.prefixOptions).map((option) => ({
        value: option,
        label: props.prefixOptions[option],
      }))
    : [];

  const churchesQuery = useChurchesV3('people', 'create');
  const churches = churchesQuery.data;
  const showChurchSelector = window?.cdApp?.showChurchSelector;

  const [conflictingPeopleByEmail, setConflictingPeopleByEmail] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [conflictingPeopleByPhone, setConflictingPeopleByPhone] = useState<
    ConflictingPersonWithRelation[]
  >([]);

  const validate = useCallback(
    () =>
      form
        .validateFields()
        .then((values) => {
          if (values?.errorFields) return false;
          return true;
        })
        .catch(() => false),
    [form]
  );

  const getValues = useCallback(() => {
    const payload = form.getFieldsValue();
    // Set the selected churches
    const selectedChurches = showChurchSelector
      ? payload.churches
      : map(churches, 'id');
    payload.churches = map(selectedChurches, (church) => ({ id: church }));

    const relations = [
      ...conflictingPeopleByEmail,
      ...conflictingPeopleByPhone,
    ];

    if (payload.phone?.code && payload.phone.phone) {
      payload.phone = '+' + payload.phone.code + payload.phone.phone;
    } else if (props.createMode) {
      payload.phone = undefined;
    }

    // Set the person relations based on conflicting contact data
    if (relations.length > 0) {
      payload.relations = relations;
    }
    if (prefixes.length > 0 && !payload.prefix) {
      payload.prefix = '';
    }
    return payload;
  }, [
    churches,
    conflictingPeopleByEmail,
    conflictingPeopleByPhone,
    form,
    prefixes.length,
    props.createMode,
    showChurchSelector,
  ]);

  useEffect(() => {
    props.registerValidationHook(validate);
    props.registerFetchValuesHook(getValues);
  }, [getValues, props, validate]);

  return (
    <Form
      form={form}
      initialValues={{
        phone: {
          short: cdApp.organization.countryIso2,
        },
        ...props.person,
        churches: props.person?.churches?.map((church) => church.id) || [],
      }}
      layout="vertical"
    >
      {prefixes.length > 0 && (
        <Form.Item name="prefix" label={gettextCatalog.getString('Title')}>
          <CdSelect placeholder="" options={prefixes} />
        </Form.Item>
      )}
      <Form.Item
        name="firstName"
        label={gettextCatalog.getString('First name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. John')} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={gettextCatalog.getString('Last name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. Smith')} />
      </Form.Item>
      {props.createMode && (
        <>
          <Form.Item
            name="email"
            label={gettextCatalog.getString('E-mail address')}
            rules={[emailFormat]}
          >
            <Input
              placeholder={gettextCatalog.getString(
                'e.g. john.smith@example.com'
              )}
            />
          </Form.Item>
          <div style={{ marginBottom: 16 }}>
            <ConflictingPeopleRelationComponent
              emailValue={email}
              phoneValue={phone && `+${phone.code + phone.phone}`}
              valueType={ContactInformationType.EMAIL}
              shouldListSingleConflictingPeople={false}
              setSelectedValues={setConflictingPeopleByEmail}
            />
          </div>
          <Form.Item
            name="phone"
            label={gettextCatalog.getString('Phone')}
            rules={[phoneNumber]}
          >
            <CdPhoneInput />
          </Form.Item>
          <div style={{ marginBottom: 16 }}>
            <ConflictingPeopleRelationComponent
              emailValue={email}
              phoneValue={phone && `+${phone.code + phone.phone}`}
              valueType={ContactInformationType.PHONE}
              shouldListSingleConflictingPeople={true}
              setSelectedValues={setConflictingPeopleByPhone}
            />
          </div>
        </>
      )}
      {showChurchSelector && (
        <Form.Item
          name="churches"
          label={gettextCatalog.getString('Parish')}
          rules={[required(gettextCatalog.getString('Parish is required.'))]}
          extra={gettextCatalog.getString(
            'Only users from selected parishes will have access to the contact.'
          )}
        >
          <CdSelect
            mode="multiple"
            options={churches?.map((church) => ({
              value: church.id,
              label: church.name,
            }))}
            placeholder={gettextCatalog.getString(
              'Choose one or more parishes...'
            )}
          />
        </Form.Item>
      )}
    </Form>
  );
};
