import React, { useEffect } from 'react';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import CdSpreadsheetImport from '@/react/shared/components/cd-spreadsheet-importer/CdrSpreadSheetImporter';
import { useSafeguardBulkUpload } from '@/react/safeguarding/hooks/useSafeguardBulkUpload';

export const ShowSafeguardingProfileUploaderModal = createCdModal(
  ({ setModalProps, closeModal }) => {
    const { fields, submitHandler, rowValidation } = useSafeguardBulkUpload({
      closeModal,
    });
    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: '',
        width: 800,
      });
    }, [setModalProps]);

    return (
      <CdSpreadsheetImport
        key="ImportCaps"
        isOpen={true}
        onClose={async () => {
          closeModal();
        }}
        rowHook={rowValidation}
        onSubmit={submitHandler}
        fields={fields}
        exampleDownloadLink="/spreadsheetImporterExample/import-safeguarding-users-example.xlsx"
      />
    );
  }
);
