import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import createReducer from '../../../redux/utils';
import { IntentionReportTemplate } from '../../models/report-template';

import {
  CLEAR_INTENTION_REPORT_TEMPLATE,
  FETCH_INTENTION_REPORT_TEMPLATE_SUCCESS,
  FETCH_INTENTION_REPORT_TEMPLATES_SUCCESS,
  FetchIntentionReportTemplateSuccessActionType,
  FetchIntentionReportTemplatesSuccessActionType,
} from './Actions';

export interface IntentionReportTemplateState {
  intentionReportTemplates: IntentionReportTemplate[];
  intentionReportTemplate: Partial<IntentionReportTemplate>;
}

export type ImmutableIntentionReportTemplateState =
  immutable.ImmutableObject<IntentionReportTemplateState>;

const initialState: ImmutableIntentionReportTemplateState =
  immutable.from<IntentionReportTemplateState>({
    intentionReportTemplates: [],
    intentionReportTemplate: {},
  });

const clearIntentionReportTemplate: Reducer<
  ImmutableIntentionReportTemplateState
> = (state = initialState) =>
  state.merge({
    intentionReportTemplate: null, // Clear selected Intention Report Template (edit/view)
  });

const fetchIntentionReportTemplateSuccess: Reducer<
  ImmutableIntentionReportTemplateState,
  FetchIntentionReportTemplateSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionReportTemplate: payload,
  });

const fetchIntentionsReportTemplatesSuccess: Reducer<
  ImmutableIntentionReportTemplateState,
  FetchIntentionReportTemplatesSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionReportTemplates: payload.items || [],
  });

// Handlers

const handlers = {
  [CLEAR_INTENTION_REPORT_TEMPLATE]: clearIntentionReportTemplate,
  [FETCH_INTENTION_REPORT_TEMPLATE_SUCCESS]:
    fetchIntentionReportTemplateSuccess,
  [FETCH_INTENTION_REPORT_TEMPLATES_SUCCESS]:
    fetchIntentionsReportTemplatesSuccess,
};

export default createReducer<ImmutableIntentionReportTemplateState>(
  initialState,
  handlers
);
