import angular from 'angular';
import { react2angular } from 'react18-react2angular';

import PersonInformationComponent from '../../../../../react/people/components/PersonInformationComponent';

// Example <cdr-person-information-component />
angular
  .module('cdApp.people')
  .component(
    'cdrPersonInformationComponent',
    react2angular(PersonInformationComponent, ['person'], [])
  );
