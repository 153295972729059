import React from 'react';

import ListItem from '../list-item';

import Item from './Item';

const ImageItem: React.FC<{
  item?: any;
  onFileSelected: any;
  selectedFile: any;
}> = ({ item, onFileSelected, selectedFile }) => (
  <ListItem>
    <Item
      item={item}
      onFileSelected={onFileSelected}
      selectedFile={selectedFile}
    />
  </ListItem>
);

export default ImageItem;
