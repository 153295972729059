'use strict';

class SimpleModalController {
  $onInit() {
    /**
     * The title of the modal. A simple string is expected, with no HTML.
     */
    this.title = this.resolve.title;

    /**
     * The content of the modal. Can be HTML, but keep it simple.
     */
    this.body = this.resolve.body;

    /**
     * Additional options to customize the modal. Supported values:
     *
     * confirmButtonType {String='primary,danger,success'}
     * confirmButtonText {String}
     * hideConfirmButton {Boolean}
     *
     * closeButtonText {String}
     * hideCloseButton {Boolean}
     *
     * showSecondaryButton {Boolean}
     * secondaryButtonType {String='primary,danger,success'}
     * secondaryButtonText {String}
     */
    this.options = angular.copy(this.resolve.options);
  }

  /**
   * Close the modal by confirming
   */
  ok() {
    this.close();
  }

  /**
   * Close the modal by pressing the secondary button
   */
  onSecondaryButtonPress() {
    this.close({ $value: { isSecondaryButton: true } });
  }

  /**
   * Close the modal by dismissing it
   */
  cancel() {
    this.dismiss();
  }
}

angular.module('cdApp.shared').component('cdSimpleModal', {
  templateUrl: '@/app/shared/components/simple-modal/simple-modal.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: SimpleModalController,
});
