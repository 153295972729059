'use strict';

angular.module('cdApp.shared').component('cdFlexAlert', {
  templateUrl: '@/app/shared/components/flex-alert/flex-alert.component.html',
  bindings: {
    icon: '<',
    iconSize: '<',
    type: '<',
  },

  transclude: true,
});
