import { selector, selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import { Consent } from '@/react/people/types/consent.type';

export const FindStatementFromAllStatements = selectorFamily<Consent, string>({
  key: 'FindStatementFromAllStatement',
  get:
    (id?: string) =>
    async ({ get }) => {
      const { items } = get(ConsentTypeQuery);
      return items.find((c) => c.id === id);
    },
});
export const DefaultConsentBodyContent = selector<{
  body: string;
  label: string;
  id: string;
}>({
  key: 'DefaultConsentBodyContent',
  get: async () => {
    const response = await mainApi.get<{
      body: string;
      label: string;
      id: string;
    }>(`people/consent-types/default`);
    return {
      body: response.data.body,
      label: response.data.label,
      id: response.data.id,
    };
  },
});

export const ConsentTypeQuery = selector<{
  items: Consent[];
  total: number;
}>({
  key: 'ConsentTypeQuery',
  get: async () => {
    const response = await mainApi.get<Consent[]>(`people/consent-types`);
    if (response.ok) {
      return { items: response.data, total: response.data.length || 0 };
    }
    throw response.originalError;
  },
});
