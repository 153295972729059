'use strict';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import { peopleSegmentsState } from '@/react/people/store/peopleSegment';
import { PeopleListByTypeQuery } from '@/react/people/store/newsletterListState';

class CreateListModalComponent {
  constructor(toastr, gettextCatalog, Analytics, PeopleSegments, _) {
    'ngInject';

    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.Analytics = Analytics;
    this.PeopleSegments = PeopleSegments;
    this._ = _;
  }

  $onInit() {
    this.filterQuery = this.resolve.filterQuery;
    this.list = new this.PeopleSegments({
      name: this.resolve.listName,
      setup: this.filterQuery,
    });
  }

  save() {
    this.list.$save(() => {
      recoilRefresh(peopleSegmentsState);
      recoilRefresh(PeopleListByTypeQuery('filter'));
      this.toastr.success(this.gettextCatalog.getString('List created.'));
      this.Analytics.sendFeatureEvent('create segment', { module: 'people' });
      this.close({ $value: this.list });
    });
  }

  getFilterByCriterion(criterion) {
    return this._(this.resolve.filterGroups)
      .map('filters')
      .flatten()
      .find({ type: criterion.type, property: criterion.property });
  }
}

CreateListModalComponent.$inject = [
  'toastr',
  'gettextCatalog',
  'Analytics',
  'PeopleSegments',
  '_',
];

angular.module('cdApp.people').component('cdPeopleCreateListModal', {
  templateUrl:
    '@/app/people/shared/components/create-list/create-list.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CreateListModalComponent,
});
