import { defaultsDeep, get } from 'lodash';

import { mainApi } from '../../api';
import { OldUserRoles, defaultOldUserRoles } from '../types/User.types';
import { RoleInterface } from '../../organization/types/organization-role.types';
import { buildErrorEntityByStatus } from '../../shared/errors';

export interface ChurchMembership {
  id?: string;
  userId?: number;
  churchId: number;
  createdById?: number;
  updatedById?: number;
  church?: {
    id: number;
    name: string;
    color: number;
  };
  roles: {
    id: string;
    name?: string;
  }[];
}

class UserRolesService {
  public getOldRoles = async (payload: {
    userId: number;
  }): Promise<OldUserRoles> => {
    const res = await mainApi.get(`/users/${payload.userId}/permissions`);
    if (res.ok) return res.data as OldUserRoles;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public getUserChurchRoles = async (
    userId: number
  ): Promise<ChurchMembership[]> => {
    const res = await mainApi.get(`/users/${userId}/churches`);
    if (res.ok) return res.data as ChurchMembership[];
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public saveUserOldRoles = async (userId: number, data: OldUserRoles) => {
    const defaultRoles = defaultOldUserRoles;
    const payload = defaultsDeep(data, defaultRoles);

    const res = await mainApi.put(`/users/${userId}/permissions`, payload);
    if (res.ok) return res.data;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public saveUserChurchRoles = async (
    userId: number,
    data: ChurchMembership[]
  ) => {
    const res = await mainApi.put(`/users/${userId}/churches`, data);
    if (res.ok) return res.data;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public getUserOrganizationRoles = async (
    userId: number
  ): Promise<RoleInterface[]> => {
    const res = await mainApi.get(`/users/${userId}/roles`);
    if (res.ok) return res.data as RoleInterface[];
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public saveUserOrganizationRoles = async (
    userId: number,
    data: Partial<RoleInterface>[]
  ) => {
    const res = await mainApi.put(`/users/${userId}/roles`, data);
    if (res.ok) return res.data;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };
}
export default new UserRolesService();
