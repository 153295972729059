import React from 'react';
import { Col, Row, Typography } from 'antd';

import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { gettextCatalog } from '@/react/services/I18nService';

export default function SognDKStatus(props: {
  eventId: number;
  currentExported: boolean;
  isEventRecentlyUpdated: boolean;
  status?: number;
  currentStatus: number;
}) {
  return (
    <CdVerticalSpace>
      {props.eventId && props.currentExported && (
        <Row style={{ marginTop: '0px' }}>
          <Col span={24}>
            {props.status && (
              <Typography.Text type="success">
                {gettextCatalog.getString(
                  'This event has been exported successfully to sogn.dk.'
                )}
              </Typography.Text>
            )}
            {props.isEventRecentlyUpdated && !props.currentStatus && (
              <Typography.Text type="warning">
                {gettextCatalog.getString(
                  'This event is being exported to sogn.dk. Please refresh the page in a minute.'
                )}
              </Typography.Text>
            )}
            {!props.isEventRecentlyUpdated && !props.currentStatus && (
              <Typography.Text type="danger">
                {gettextCatalog.getString(
                  'This event could not be exported to sogn.dk. Please save the event to try again.'
                )}
              </Typography.Text>
            )}
          </Col>
        </Row>
      )}
    </CdVerticalSpace>
  );
}
