'use strict';

class FileUploaderModalComponent {
  constructor() {
    this.files = [];
  }

  $onInit() {
    this.groups = this.resolve.groups;
    this.groupId = this.resolve.groupId || -1;
    this.folders = this.resolve.folders;
    this.folderId = this.resolve.folderId || 'root';
    this.permissions = this.resolve.permissions;
    this.validators = this.resolve.validators;
    this.fromGallery = this.resolve.fromGallery || false;
  }

  onUpload(file) {
    this.files.push(file);
  }

  closeUploader(reason) {
    if (reason) {
      this.dismiss({ $value: reason });
    } else if (this.files.length) {
      this.close({ $value: this.files });
    } else {
      this.dismiss();
    }
  }
}

angular.module('cdApp.shared').component('cdFileUploaderModal', {
  templateUrl:
    '@/app/shared/components/file-uploader-modal/file-uploader-modal.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: FileUploaderModalComponent,
});
