import { all, takeLatest } from 'redux-saga/effects';

import { withLoadingAndErrors } from '../../shared/loading/saga';
import {
  CREATE_INTENTION_FEE,
  DELETE_INTENTION_FEE,
  FETCH_INTENTION_FEE,
  FETCH_INTENTION_FEES,
  UPDATE_INTENTION_FEE,
  FETCH_INTENTION_FEE_RECIPIENTS,
  DELETE_INTENTION_FEE_RECIPIENT,
  UPDATE_INTENTION_FEE_RECIPIENT,
  CREATE_INTENTION_FEE_RECIPIENT,
} from '../redux/intention-fees/Actions';
import {
  CREATE_INTENTION_REPORT_TEMPLATE,
  DELETE_INTENTION_REPORT_TEMPLATE,
  FETCH_INTENTION_REPORT_TEMPLATE,
  FETCH_INTENTION_REPORT_TEMPLATES,
  UPDATE_INTENTION_REPORT_TEMPLATE,
} from '../redux/intention-report-templates/Actions';

import {
  createIntentionFeeSaga,
  deleteIntentionFeeSaga,
  getIntentionFeeSaga,
  getIntentionFeesSaga,
  updateIntentionFeeSaga,
  getIntentionFeeRecipientsSaga,
  deleteIntentionFeeRecipientSaga,
  updateIntentionFeeRecipientSaga,
  createIntentionFeeRecipientSaga,
} from './IntentionFees';
import {
  createIntentionReportTemplateSaga,
  deleteIntentionReportTemplateSaga,
  getIntentionReportTemplateSaga,
  getIntentionReportTemplatesSaga,
  updateIntentionReportTemplateSaga,
} from './IntentionReportTemplates';

export default function* root(): Generator {
  yield all([
    // Intention Fees
    takeLatest(
      CREATE_INTENTION_FEE,
      withLoadingAndErrors(CREATE_INTENTION_FEE, createIntentionFeeSaga)
    ),
    takeLatest(
      DELETE_INTENTION_FEE,
      withLoadingAndErrors(DELETE_INTENTION_FEE, deleteIntentionFeeSaga)
    ),
    takeLatest(
      FETCH_INTENTION_FEE,
      withLoadingAndErrors(FETCH_INTENTION_FEE, getIntentionFeeSaga)
    ),
    takeLatest(
      FETCH_INTENTION_FEES,
      withLoadingAndErrors(FETCH_INTENTION_FEES, getIntentionFeesSaga)
    ),
    takeLatest(
      UPDATE_INTENTION_FEE,
      withLoadingAndErrors(UPDATE_INTENTION_FEE, updateIntentionFeeSaga)
    ),
    takeLatest(
      FETCH_INTENTION_FEE_RECIPIENTS,
      withLoadingAndErrors(
        FETCH_INTENTION_FEE_RECIPIENTS,
        getIntentionFeeRecipientsSaga
      )
    ),
    takeLatest(
      DELETE_INTENTION_FEE_RECIPIENT,
      withLoadingAndErrors(
        DELETE_INTENTION_FEE_RECIPIENT,
        deleteIntentionFeeRecipientSaga
      )
    ),
    takeLatest(
      UPDATE_INTENTION_FEE_RECIPIENT,
      withLoadingAndErrors(
        UPDATE_INTENTION_FEE_RECIPIENT,
        updateIntentionFeeRecipientSaga
      )
    ),
    takeLatest(
      CREATE_INTENTION_FEE_RECIPIENT,
      withLoadingAndErrors(
        CREATE_INTENTION_FEE_RECIPIENT,
        createIntentionFeeRecipientSaga
      )
    ),
    // Intention Report Templates
    takeLatest(
      CREATE_INTENTION_REPORT_TEMPLATE,
      withLoadingAndErrors(
        CREATE_INTENTION_REPORT_TEMPLATE,
        createIntentionReportTemplateSaga
      )
    ),
    takeLatest(
      DELETE_INTENTION_REPORT_TEMPLATE,
      withLoadingAndErrors(
        DELETE_INTENTION_REPORT_TEMPLATE,
        deleteIntentionReportTemplateSaga
      )
    ),
    takeLatest(
      FETCH_INTENTION_REPORT_TEMPLATE,
      withLoadingAndErrors(
        FETCH_INTENTION_REPORT_TEMPLATE,
        getIntentionReportTemplateSaga
      )
    ),
    takeLatest(
      FETCH_INTENTION_REPORT_TEMPLATES,
      withLoadingAndErrors(
        FETCH_INTENTION_REPORT_TEMPLATES,
        getIntentionReportTemplatesSaga
      )
    ),
    takeLatest(
      UPDATE_INTENTION_REPORT_TEMPLATE,
      withLoadingAndErrors(
        UPDATE_INTENTION_REPORT_TEMPLATE,
        updateIntentionReportTemplateSaga
      )
    ),
  ]);
}
