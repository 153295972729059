import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { GetAllTags } from '../store/tagState';
import { TagInterface } from '../types/tag.type';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';

const useTag = () => {
  const refreshTagList = useRecoilRefresher(GetAllTags(''));

  const createTag = useRecoilCallback(
    () => async (name: string) => {
      const response = await mainApi.post<TagInterface>(`/people/tags`, {
        name,
      });
      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('Tag created successfully')
        );
        refreshTagList();
        return response.data.id;
      }
    },
    [refreshTagList]
  );
  return {
    refreshTagList,
    createTag,
  };
};

export default useTag;
