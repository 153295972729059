(function () {
  'use strict';
  WorkplanController.$inject = [
    'moment',
    '$document',
    '$scope',
    '$http',
    '$uibModal',
    '$state',
    '$stateParams',
    'gettextCatalog',
    'Users',
    'toastr',
    'Me',
  ];

  function WorkplanController(
    moment,
    $document,
    $scope,
    $http,
    $uibModal,
    $state,
    $stateParams,
    gettextCatalog,
    Users,
    toastr,
    Me
  ) {
    // Fetch user dropdown.
    Users.query(function (users) {
      $scope.users = _.sortBy(
        _.values(
          _.map(users, function (item) {
            return {
              uid: item.id,
              realname: item.firstName ? item.name : item.email,
              email: item.email,
            };
          })
        ),

        'realname'
      );
    });

    $scope.datepickerOptions = {
      datepickerMode: 'month',
      minMode: 'month',
      maxMode: 'month',
    };

    var debug = false;
    $scope.loadinga = 20;

    // Start the loading.
    $scope.loading = true;

    // Load the current user from javascript.
    $scope.uid = $stateParams.uid || Me.id;

    var d = new Date();
    $scope.year = $stateParams.year || d.getFullYear();
    $scope.currentMonth = $stateParams.month || d.getMonth() + 1;
    $scope.selectedDate = moment()
      .year(parseInt($scope.year, 10))
      .month(parseInt($scope.currentMonth - 1, 10))
      .toDate();

    var dayNames = [];
    // Generate the list of the day names.
    for (var i = 0; i < 7; i++) {
      dayNames[i] = moment().day(i).format('dd');
    }

    // Build the head of the table.
    $scope.generateMonthDaysLabels = function (numberOfDays) {
      $scope.days = [];
      for (var i = 1; i <= numberOfDays; i++) {
        var d = new Date($scope.year, $scope.currentMonth - 1, i);
        $scope.days.push({
          day: i,
          label: dayNames[d.getDay()],
          weekend: d.getDay() == 6 || d.getDay() == 0,
        });
      }
    };

    // Set the date and daysInMonth value.
    setMonth(parseInt($scope.currentMonth));

    var selectedSlots;
    var kw_fullcalendar_template_path =
      '@/app/calendar/full-calendar/templates/';

    var lang = _.get(cdApp, 'organization.locale.language');
    var separator =
      lang && (lang.toLowerCase() === 'en-gb' || lang.toLowerCase() === 'en')
        ? ':'
        : '.';

    // Function to format the time correctly.
    var formatTime = function (start, end) {
      var time = '';
      var start_time = parseInt(start) / 2;
      if (start_time % 1 == 0) {
        time += start_time + separator + '00';
      } else {
        time += start_time - (start_time % 1) + separator + '30';
      }
      var end_time = (parseInt(end) + 1) / 2;
      if (end_time == parseInt(end_time)) {
        time += ' - ' + end_time + separator + '00';
      } else {
        time += ' - ' + parseInt(end_time) + separator + '30';
      }
      return time;
    };

    // Construct the data with tables.
    function buildTimeTable(data) {
      // Build the body of the table
      var dataSlots = [];
      var hour = 0;
      var debugText = '';
      for (var x = 0; x <= 47; x++) {
        if (debug) {
          debugText = ' (' + x + ')';
        }

        var minutes = x % 2 === 0 ? '.00' : '.30';
        dataSlots[x] = {
          time: x,
          timeLabel: [hour, minutes, debugText].join(''),
          data: [],
        };

        hour = hour + parseInt(x % 2 == 0 ? 0 : 1, 10);

        ($scope.days || []).forEach(function (item) {
          if (
            data &&
            data.content &&
            data.content[x] &&
            data.content[x][item.day]
          ) {
            $scope.workTypes[
              data &&
                data.content &&
                data.content[x] &&
                data.content[x][item.day]
            ]['count'] += 0.5;
          }
          dataSlots[x].data.push({
            day: item.day,
            time: x,
            type:
              (data &&
                data.content &&
                data.content[x] &&
                data.content[x][item.day]) ||
              null,
            timeLabel: dataSlots[x]['timeLabel'],
          });
        });
      }
      return dataSlots;
    }

    $scope.dataSlots = buildTimeTable(null);

    var selectedSlotsTMP = [];

    /**
     * Loads the main data
     *
     * @param optional_uid, is only used into selecting the correct user from the dropdown menu.
     */
    $scope.fetchData = function (optional_uid) {
      $scope.loading = true;
      // This will be used when user is clicked from dropdown
      $scope.uid = optional_uid || $scope.uid;
      // '/api/work-plan/retrieve.json?month=' + $scope.currentMonth + '&year=' + $scope.year + '&uid=' + $scope.uid
      $http
        .get(
          [
            cdApp.config.api.main,
            '/workplan',
            '/',
            $scope.uid,
            '/',
            $scope.currentMonth,
            '/',
            $scope.year,
          ].join('')
        )
        .success(function (data) {
          // Check of data and user_can_view
          if (!data.headers && !data.headers.userCanView) {
            toastr.error(
              gettextCatalog.getString(
                "You don't have permissions to view this page information."
              )
            );

            return;
          }

          $scope.user_can_lock = data.headers.userCanLock;
          $scope.user_can_view = data.headers.userCanView;
          $scope.user_can_edit = data.headers.userCanEdit;
          $scope.user_can_view_overview = data.headers.userCanViewOverview;

          $scope.picture = data.headers.picture;
          $scope.loadinga = 50;

          $scope.locked = data.headers.locked;
          $scope.name = data.headers.name;
          $scope.uid = data.headers.uid;
          $scope.user_can_lock = data.headers.userCanLock;

          // Init the work type counters
          angular.forEach($scope.workTypes, function (workType) {
            workType.count = 0;
          });

          $scope.loadinga = 70;

          // Prepare data for rendering it.
          data.content = {};

          (data.workplan || []).forEach(function (item) {
            var hours = item.timeSlot / 2;
            var minutes = (item.timeSlot % 2) * 30;
            var utcDate = moment
              .utc()
              .year($scope.year)
              .month($scope.currentMonth - 1)
              .date(item.day)
              .hour(parseInt(hours, 10))
              .minutes(minutes)
              .seconds(0)
              .toISOString();
            var localDate = new Date(utcDate);
            var timeSlot =
              localDate.getHours() * 2 +
              (localDate.getMinutes() === 0 ? 0 : 1) * 1;
            data.content[timeSlot] = data.content[timeSlot] || {};
            data.content[timeSlot][item.day] = item.wtid;
          });

          $scope.dataSlots = buildTimeTable(data);
          $scope.loadinga = 100;
          $scope.loading = false;
        });

      selectedSlotsTMP = [];
    };

    // Fetch work types
    // /api/work-plan/types.json
    $http
      .get(cdApp.config.api.main + '/workplan/types')
      .success(function (data) {
        $scope.workTypes = _.map(data, function (workType) {
          return {
            label: workType.workType,
            labelCss: workType.workType.replace(/ /g, '').toLowerCase(),
            color: workType.color,
            id: workType.wtid,
            count: 0,
            selected: false,
          };
        });
        $scope.workTypes = _.keyBy($scope.workTypes, 'id');
        $scope.fetchData();
      });

    // Drag function, makes the table selectable with the mouse
    $('.selectable').selectable({
      filter: ':not(tr)',
      selected: function (event, ui) {
        if (ui.selected.localName === 'td') {
          if (
            ui.selected.attributes.getNamedItem('slot') !== null &&
            ui.selected.attributes.getNamedItem('day') !== null
          ) {
            selectedSlots.push({
              slot: ui.selected.attributes.getNamedItem('slot').value,
              day: ui.selected.attributes.getNamedItem('day').value,
            });
          }
        }
      },
      selecting: function (event, ui) {
        if (ui.selecting.localName === 'td') {
          if (
            ui.selecting.attributes.getNamedItem('slot') !== null &&
            ui.selecting.attributes.getNamedItem('day') !== null
          ) {
            selectedSlotsTMP.push(
              ui.selecting.attributes.getNamedItem('slot').value
            );
          }
        }
      },
      unselecting: function (event, ui) {
        if (ui.unselecting.localName === 'td') {
          if (
            ui.unselecting.attributes.getNamedItem('slot') !== null &&
            ui.unselecting.attributes.getNamedItem('day') !== null
          ) {
            selectedSlotsTMP.splice(
              selectedSlotsTMP.indexOf(
                ui.unselecting.attributes.getNamedItem('slot').value
              ),

              1
            );
          }
        }
      },
      stop: function () {
        selectedSlotsTMP = [];
        $scope.dragging = false;
        maybyOpenWorkTypeModal();
      },
      start: function (event) {
        selectedSlotsTMP = [];
        $scope.dragging = true;

        // Start dragging
        if (!$scope.ctrlIsDown || typeof selectedSlots === 'undefined') {
          // Only reset of ctrls is not down
          selectedSlots = [];
        }

        if (
          event.target.localName === 'td' &&
          event.target.attributes.getNamedItem('slot') !== null
        ) {
          selectedSlots.push({
            slot: event.target.attributes.getNamedItem('slot').value,
            day: event.target.attributes.getNamedItem('day').value,
          });
        }
      },
    });

    function maybyOpenWorkTypeModal() {
      if (!$scope.ctrlIsDown && !$scope.dragging) {
        // Open modal if something has been selected
        if (selectedSlots && selectedSlots.length > 0) {
          $scope.open();
        }
      }
    }

    $scope.ctrlIsDown = false;
    $scope.dragging = false;
    $document.bind('keydown', function (e) {
      // Bind to 91=CMD and 17=ctrl
      if (e.keyCode === 91 || e.keyCode === 17) {
        $scope.ctrlIsDown = true;
      }
    });
    $document.bind('keyup', function (e) {
      // Bind to 91=CMD and 17=ctrl
      if (e.keyCode === 91 || e.keyCode === 17) {
        $scope.ctrlIsDown = false;
        maybyOpenWorkTypeModal();
      }
    });

    // Selection a whole column in the table.
    $scope.selectCol = function (day) {
      if (!$scope.ctrlIsDown || !selectedSlots) {
        // Only reset of ctrls is not down.
        selectedSlots = [];
        angular
          .element('.selectable td.ui-selected')
          .removeClass('ui-selected');
      }
      angular.forEach(
        angular.element('.selectable .day-' + day).addClass('ui-selected'),
        function (value) {
          var cell = angular.element(value);
          selectedSlots.push({ slot: cell.attr('slot'), day: day });
        }
      );

      maybyOpenWorkTypeModal();
    };

    // Functions to jump months.
    $scope.nextMonth = function () {
      $scope.loading = true;
      $scope.currentMonth += 1;
      $scope.selectedDate = moment($scope.selectedDate)
        .add(1, 'month')
        .toDate();
      setMonth($scope.currentMonth);
      $scope.loadinga = 0;
      $scope.fetchData();
    };

    // Previous month.
    $scope.prevMonth = function () {
      $scope.loading = true;
      $scope.currentMonth -= 1;
      $scope.selectedDate = moment($scope.selectedDate)
        .subtract(1, 'month')
        .toDate();
      setMonth($scope.currentMonth);
      $scope.loadinga = 0;
      $scope.fetchData();
    };

    // Toggle the workplan locks.
    $scope.toggleLock = function () {
      // Fetch user dropdown
      $http({
        method: 'POST',
        url: cdApp.config.api.main + '/workplan/lock',
        data: {
          userId: $scope.uid,
          year: $scope.year,
          month: $scope.currentMonth,
          isLocked: $scope.locked,
        },
      }).then(function (lock) {
        if (lock.status === 200 && lock.statusText === 'OK') {
          $scope.locked = !$scope.locked;
        }
      });
    };

    // Change the month
    function setMonth(newMonth) {
      if (newMonth > 12) {
        newMonth = 1;
        $scope.year++;
      } else if (newMonth < 1) {
        newMonth = 12;
        $scope.year--;
      }
      $scope.currentMonth = newMonth;
      var daysInMonth = new Date($scope.year, $scope.currentMonth, 0).getDate();
      $scope.generateMonthDaysLabels(daysInMonth);
    }

    $scope.onMonthChange = function () {
      $scope.year = moment($scope.selectedDate).year();
      $scope.currentMonth = moment($scope.selectedDate).month() + 1;
      $scope.loading = true;
      setMonth($scope.currentMonth);
      $scope.loadinga = 0;
      $scope.fetchData();
    };

    // Open up date picker.
    $scope.showMonthSelector = function () {
      // Open the datepicker
      angular.element('#datepicker').datepicker('show');
    };

    /*
     * Function that toggles selected element.
     * */
    $scope.selectWorkType = function (workType) {
      // Toggle value of selected element.
      var toggledValue = !workType.selected;
      // Uncheck all elements.
      angular.forEach($scope.workTypes, function (value) {
        value.selected = false;
      });
      // Assign the toggled value to the element.
      workType.selected = toggledValue;
    };

    $scope.getWorkTypeColor = function (type) {
      return type ? $scope.workTypes[type]['color'] : '#fff';
    };

    // Print calendar.
    $scope.print = function () {
      window.print();
      return false;
    };

    // Opens the confirm save modal
    $scope.open = function () {
      // Make sure you have permissions to edit the workplan.
      if (!$scope.user_can_edit) {
        toastr.error(
          gettextCatalog.getString(
            "You don't have permission to modify the work plan information."
          )
        );

        return;
      }

      // Make sure workplan is not locked, and can be modified.
      if ($scope.locked) {
        toastr.error(gettextCatalog.getString('The work plan is locked.'));
        return;
      }
      // Create workplan modal.
      var modalInstance = $uibModal.open({
        templateUrl:
          '@/app/calendar/workplan-detail/templates/workplan-create.modal.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'selectedSlots',
          'rootScope',
          function ($scope, $uibModalInstance, selectedSlots, rootScope) {
            'ngInject';

            $scope.workTypes = rootScope.workTypes;

            // Check if the selected fields are colored
            var foundColoredCells = false;
            angular.forEach(selectedSlots, function (item) {
              if (
                rootScope.dataSlots[item.slot]['data'][item.day - 1]['type'] !==
                null
              ) {
                foundColoredCells = true;
              }
            });
            // If no colored fields were selected the remove button is hidden
            $scope.removeSelectionButtonDisabled = !foundColoredCells;

            // Select work type handler
            $scope.selectWorkType = function (workType) {
              rootScope.selectWorkType(workType);
            };

            // Clear the selected slots.
            $scope.clear = function () {
              $uibModalInstance.close('clear');
            };
            // Save the work type change.
            $scope.ok = function () {
              $uibModalInstance.close('save');
            };

            // Close the modal.
            $scope.close = function () {
              $('.selectable td').removeClass('ui-selected');
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],

        resolve: {
          selectedSlots: function () {
            return selectedSlots;
          },
          rootScope: function () {
            return $scope;
          },
        },
      });

      // Results, save.
      modalInstance.result.then(function (operation) {
        var selectedItem = 0;
        var selectedItemId = null;

        if (operation === 'save') {
          // Find which work type was selected
          angular.forEach($scope.workTypes, function (workType, key) {
            if (workType.selected) {
              selectedItem = workType.id;
              selectedItemId = key;
            }
          });
        } else {
          selectedItemId = null;
        }

        if (selectedItemId === null && operation === 'save') {
          toastr.error(gettextCatalog.getString('Work type was not selected.'));
        } else {
          // Modal submit.
          $http({
            url: cdApp.config.api.main + '/workplan', // '/api/work-plan/' + operation,
            method: 'POST',
            data: {
              userId: $scope.uid,
              month: $scope.currentMonth,
              year: $scope.year,
              wtid: selectedItemId,
              content: _.map(selectedSlots, function (item) {
                // Convert to UTC timezone.
                var hours = item.slot / 2;
                var minutes = (item.slot % 2) * 30;
                var utcDate = moment({
                  year: $scope.year,
                  month: $scope.currentMonth - 1,
                  day: item.day,
                  hour: hours,
                  minutes: minutes,
                  seconds: 0,
                });

                utcDate = utcDate.utc();
                var timeSlot =
                  utcDate.hours() * 2 + (utcDate.minutes() === 0 ? 0 : 1) * 1;
                return {
                  day: item.day,
                  timeSlot: timeSlot,
                  timestamp: utcDate.toISOString(),
                };
              }),
            },
          })
            .success(function () {
              // Success
              // Color the fields and increment the counters
              // Here we loop the selected fields
              angular.forEach(selectedSlots, function (selectedSlot) {
                if ($scope.dataSlots[selectedSlot.slot]) {
                  angular.forEach(
                    $scope.dataSlots[selectedSlot.slot]['data'],
                    function (day, key) {
                      if (day.day == selectedSlot.day) {
                        var workType =
                          $scope.dataSlots[selectedSlot.slot]['data'][key][
                            'type'
                          ];

                        if (workType) {
                          $scope.workTypes[workType]['count'] -= 0.5;
                        }
                        $scope.dataSlots[selectedSlot.slot]['data'][key][
                          'type'
                        ] = selectedItemId;
                        if (operation === 'save') {
                          $scope.workTypes[selectedItemId]['count'] += 0.5;
                        }
                      }
                    }
                  );
                }
              });
              selectedSlots = [];
              // Reset the selection
              angular
                .element('.selectable td.ui-selected')
                .removeClass('ui-selected');
            })
            .catch(function (error) {
              // Error
              toastr.error(
                _.get(error, 'data.message') ||
                  gettextCatalog.getString(
                    'An error occurred, please try again. If the problem persists, please contact our support.'
                  )
              );
            });
        }
      });
    };

    // Launch the copy modal
    $scope.copy = function () {
      var modalCopyInstance = $uibModal.open({
        templateUrl:
          '@/app/calendar/workplan-detail/templates/workplan-copy.modal.html',
        windowClass: 'modal-ui-select',
        controller: [
          '$scope',
          '$uibModalInstance',
          'rootScope',
          function ($scope, $uibModalInstance, rootScope) {
            'ngInject';

            $scope.selectedCopyDate = moment(rootScope.selectedDate)
              .add(1, 'month')
              .toDate();

            $scope.close = function () {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.ok = function () {
              $uibModalInstance.close({
                selectedYear: moment($scope.selectedCopyDate).year(),
                selectedMonth: moment($scope.selectedCopyDate).month() + 1,
              });
            };

            function disableDate(object) {
              return (
                object.mode === 'month' &&
                moment(object.date).month() ===
                  moment(rootScope.selectedDate).month()
              );
            }

            $scope.datepickerOptions = {
              dateDisabled: disableDate,
              datepickerMode: 'month',
              minMode: 'month',
              maxMode: 'month',
            };
          },
        ],

        resolve: {
          rootScope: function () {
            return $scope;
          },
        },
      });

      modalCopyInstance.result.then(function (values) {
        $scope.loadinga = 0;

        $http({
          url: cdApp.config.api.main + '/workplan/clone',
          method: 'POST',
          data: {
            userId: $scope.uid,
            month: $scope.currentMonth,
            year: $scope.year,
            targetYear: values.selectedYear,
            targetMonth: values.selectedMonth,
          },
        })
          .success(function () {
            $state.go('app.private.calendar.workplan', {
              uid: $scope.uid,
              month: values.selectedMonth,
              year: values.selectedYear,
            });
          })
          .error(function (data) {
            // Error
            toastr.error(
              gettextCatalog.getString(
                'The work plan could not be copied: {{error}}',
                {
                  error: data.message,
                }
              )
            );
          });
      });
    };

    // Unbind keys
    $scope.$on('$destroy', function () {
      $document.unbind('keyup');
      $document.unbind('keydown');
    });
  }

  angular
    .module('cdApp.calendar')
    .controller('WorkplanController', WorkplanController)
    .directive('ngWidth', function () {
      // Directive to handle width, since browsers cant handle angular code inside width tags
      return function (scope, element, attrs) {
        attrs.$observe('ngWidth', function (value) {
          element.css({
            width: value + '%',
          });
        });
      };
    });
})();
