'use strict';

import { getCRMContactDetails } from '@/react/shared/services/CRMContactDetailsService';

class NewMultiParishModalController {
  constructor(_, cdApp, gettextCatalog, Church) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.Church = Church;
  }
  $onInit() {
    this.options = angular.copy(this.resolve.options);

    const organizationLanguage = _.get(
      this.cdApp,
      'organization.locale.language'
    );

    this.Church.get((churchInfo) => {
      this.churchInfo = churchInfo;
      this.accountManager = churchInfo.accountManager;
      const { extra } = getCRMContactDetails(organizationLanguage);
      this.supportData = {
        url: this.accountManager?.bookingLink,
        image: this.accountManager?.picture,
        multiParishLearnMoreUrl: extra.multiParishLearnMoreUrl,
      };
    });
  }

  /**
   * Close the modal by confirming
   */
  ok() {
    this.close();
  }

  /**
   * Close the modal by dismissing it
   */
  cancel() {
    this.dismiss();
  }
}
NewMultiParishModalController.$inject = [
  '_',
  'cdApp',
  'gettextCatalog',
  'Church',
];

angular.module('cdApp.shared').component('cdNewMultiParishModal', {
  template: require('./new-multi-parish-modal.component.html'),
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: NewMultiParishModalController,
});
