export enum Language {
  Danish = 'da',
  German = 'de',
  English = 'en-gb',
}

export const privacyPolicyContent = (language) => {
  if (language === Language.Danish) {
    return `
        <p style="font-weight:bold; font-size:16px">Vi er den dataansvarlige - hvordan kontakter du os?</p>
        <p>
          Kirken er ansvarlig for behandlingen af de personoplysninger, du har
          sendt eller delt med os. Du finder vores oplysninger her:
        </p>
        <p style="color:red">
          [Navn på organisation]
          <br />
          [Adresse]
          <br />
          [Telefonnummer]
          <br />
          [Email]
        </p>
        <p style="font-weight:bold; font-size:16px">Formål og retsgrundlag for databehandling</p>
        <p>
          Personoplysninger er alle slags informationer, der i et eller andet
          omfang kan henføres til dig. Den data, vi indsamler i vores arbejde,
          har til formål at opfylde praktiske opgaver i kirkens arbejde samt
          kirkens kommunikationsarbejde. Kirken udfører en række samfundsmæssige
          opgaver, som kirken er retligt forpligtet til. Grundlaget for
          persondatabehandling i kirken er forskelligt afhængigt af de konkrete
          opgaver. Typisk er dette samtykkebaseret, baseret på legitim
          interesse, baseret på en kontrakt eller en retslig forpligtelse.
        </p>
        <p style="font-weight:bold; font-size:16px">Indsamling af persondata</p>
        <p>
          Når du besøger vores hjemmeside, registrerer vi en række informationer
          om dit besøg i anonymiseret form - herunder adfærd på vores
          hjemmeside, hvordan du kom til hjemmesiden og hvor lang tid du var på
          siden. Den information samler vi ind for løbende at forbedre vores
          hjemmeside.
        </p>
        <p>
          Når du tilmelder dig begivenhede, aktiviteter, kirkelige handlinger,
          nyhedsbreve, laver betalinger eller donationer, så behandler vi de
          modtagne informationer til de angivne formål. En del af den indsamlede
          data karakteriseres som personfølsomt.
        </p>
        <p style="font-weight:bold; font-size:16px">Cookies</p>
        <p>
          Vi indsætter indhold på vores hjemmeside, som efterlader cookies under
          forudsætning af at de er accepteret af den besøgende vedhjælp af vores
          cookieløsning. Dette drejer sig typisk om Youtube, Facebook,
          Soundcloud og lignende.. Du finder vores opdaterede cookie-politik i
          vores cookie-banner, hvor du, som nævnt kan afvise eller acceptere
          brugen af de pågældende cookies samt trække dit samtykke til cookies
          tilbage.
        </p>
        <p style="font-weight:bold; font-size:16px">Deling af personoplsyninger</p>
        <p>
          Vi deler kun personlige oplysninger med tredjeparter i
          overensstemmelse med gældende lov og kun når det er nødvendigt for at
          opfylde de formål, de blev indsamlet til. Vi tager passende
          foranstaltninger for at sikre, at tredjeparter behandler personlige
          oplysninger i overensstemmelse med vores instruktioner og i
          overensstemmelse med persondataforordningen.
        </p>
        <p style="font-weight:bold; font-size:16px">Opbevaring af oplysninger om dig</p>
        <p>
          Vi har truffet tekniske og organisatoriske foranstaltninger mod, at
          dine oplysninger hændeligt eller ulovligt bliver slettet,
          offentliggjort, fortabt, forringet eller kommer til uvedkommendes
          kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.
          Vi anvender kun underdatabehandlere i EU eller i lande, der kan give
          dine oplysninger en tilstrækkelig beskyttelse.
        </p>
        <p style="font-weight:bold; font-size:16px">Periode for opbevaring af dine data</p>
        <p>
          Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
          lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
          Perioden afhænger af karakteren af oplysningen og baggrunden for
          opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
          for, hvornår informationer slettes.
        </p>
        <p style="font-weight:bold; font-size:16px">Dine rettigheder</p>
        <p>
          Du har ret til at få oplyst, hvilke personoplysninger vi behandler om
          dig samt at få dine data udleveret i et letanvendeligt digtigal
          format.. Du kan desuden til enhver tid gøre indsigelse mod, at dine
          oplysninger anvendes samt begrænse, hvilke formål dine data må
          anvendes til. Du kan også tilbagekalde dit samtykke til, at der bliver
          behandlet oplysninger om dig. Hvis de oplysninger, der behandles om
          dig, er forkerte, har du ret til at få dem rettet eller slettet.
          Henvendelse herom kan ske til os på de kontaktoplysninger, som er
          listet i bunden af siden. Hvis du vil klage over vores behandling af
          dine personoplysninger, har du også mulighed for at tage kontakt til
          Datatilsynet.
        </p>
      `;
  }
  if (language === Language.German) {
    return `
        <p style="font-weight:bold; font-size:16px">
          Wir sind für die Datenverarbeitung verantwortlich - wie können Sie uns
          kontaktieren?
        </p>
        <p>
          Die Kirche ist für die Verarbeitung der personenbezogenen Daten
          verantwortlich, die Sie uns übermittelt oder mit uns geteilt haben.
          Unsere Kontaktdaten finden Sie hier:
        </p>
        <p style="color:red">
          [Name der Organisation]
          <br />
          [Adresse]
          <br />
          [Telefonnummer]
          <br />
          [E-Mail]
        </p>
        <p style="font-weight:bold; font-size:16px">
          Zweck und Rechtsgrundlage der Datenverarbeitung
        </p>
        <p>
          Personenbezogene Daten sind alle Arten von Informationen, die in
          gewissem Maße auf Sie zurückgeführt werden können. Der Zweck der
          Daten, die wir im Rahmen unserer Arbeit erheben, ist die Erfüllung
          praktischer Aufgaben in der kirchlichen Arbeit und der
          Kommunikationsarbeit der Kirche. Die Kirche erfüllt eine Reihe von
          sozialen Aufgaben, zu denen sie gesetzlich verpflichtet ist. Die
          Grundlage für die Verarbeitung personenbezogener Daten in der Kirche
          variiert je nach den spezifischen Aufgaben. In der Regel handelt es
          sich um eine Einwilligung, ein berechtigtes Interesse, einen Vertrag
          oder eine gesetzliche Verpflichtung.
        </p>
        <p style="font-weight:bold; font-size:16px">
          Erhebung von personenbezogenen Daten
        </p>
        <p>
          Wenn Sie unsere Website besuchen, registrieren wir verschiedene
          Informationen über Ihren Besuch in anonymisierter Form -
          einschließlich des Verhaltens auf unserer Website, wie Sie auf die
          Website gelangt sind und wie lange Sie sich auf der Website
          aufgehalten haben. Wir sammeln diese Informationen, um unsere Website
          fortlaufend zu verbessern.
        </p>
        <p>
          Wenn Sie sich für Veranstaltungen, Aktivitäten, Amtshandlungen,
          Newsletter, Zahlungen oder Spenden anmelden, verarbeiten wir die
          erhaltenen Informationen für die angegebenen Zwecke. Einige der
          gesammelten Daten werden als vertrauliche persönliche Daten
          bezeichnet.
        </p>
        <p style="font-weight:bold; font-size:16px">Cookies</p>
        <p>
          Wir platzieren Inhalte auf unserer Website, die Cookies hinterlassen,
          sofern sie vom Besucher mit unserer Cookie-Lösung akzeptiert werden.
          Dabei handelt es sich in der Regel um Youtube, Facebook, Soundcloud
          und dergleichen. Unsere aktualisierte Cookie-Richtlinie finden Sie in
          unserem Cookie-Banner, wo Sie, wie erwähnt, die Verwendung der
          betreffenden Cookies ablehnen, akzeptieren oder Ihre Zustimmung zu
          Cookies zurückziehen können.
        </p>
        <p style="font-weight:bold; font-size:16px">
          Weitergabe von persönlichen Daten
        </p>
        <p>
          Wir geben personenbezogene Daten nur in Übereinstimmung mit geltendem
          Recht und nur dann an Dritte weiter, wenn dies zur Erfüllung der
          Zwecke, für die sie erhoben wurden, erforderlich ist. Wir ergreifen
          geeignete Maßnahmen, um sicherzustellen, dass Dritte personenbezogene
          Daten in Übereinstimmung mit unseren Anweisungen und unter Einhaltung
          der GDPR verarbeiten.
        </p>
        <p style="font-weight:bold; font-size:16px">
          Speicherung von Informationen über Sie
        </p>
        <p>
          Wir haben technische und organisatorische Maßnahmen ergriffen, um zu
          verhindern, dass Ihre Daten versehentlich oder unrechtmäßig gelöscht,
          veröffentlicht, verloren, beeinträchtigt oder an Unbefugte
          weitergegeben, missbraucht oder anderweitig gesetzeswidrig verarbeitet
          werden. Wir verwenden nur Unterauftragsverarbeiter in der EU oder in
          Ländern, die einen angemessenen Schutz Ihrer Daten gewährleisten
          können.
        </p>
        <p style="font-weight:bold; font-size:16px">
          Dauer der Speicherung Ihrer Daten
        </p>
        <p>
          Die Daten werden für den gesetzlich zulässigen Zeitraum gespeichert,
          und wir löschen sie, wenn sie nicht mehr benötigt werden. Der Zeitraum
          hängt von der Art der Daten und dem Grund für die Speicherung ab. Es
          ist daher nicht möglich, einen allgemeinen Zeitrahmen für die Löschung
          von Daten anzugeben.
        </p>
        <p style="font-weight:bold; font-size:16px">Ihre Rechte</p>
        <p>
          Sie haben das Recht, darüber informiert zu werden, welche
          personenbezogenen Daten wir über Sie verarbeiten, und Ihre Daten in
          einem benutzerfreundlichen, digitalisierten Format zur Verfügung
          gestellt zu bekommen. Sie können auch jederzeit der Verwendung Ihrer
          Daten widersprechen und die Zwecke, für die Ihre Daten verwendet
          werden dürfen, einschränken. Ebenso können Sie Ihre Zustimmung zur
          Verarbeitung Ihrer Daten zurückziehen. Wenn die über Sie verarbeiteten
          Daten falsch sind, haben Sie das Recht, sie berichtigen oder löschen
          zu lassen. Bitte kontaktieren Sie uns über die unten auf der Seite
          aufgeführten Kontaktdaten. Wenn Sie sich über die Verarbeitung Ihrer
          persönlichen Daten durch uns beschweren möchten, können Sie sich auch
          an
          <span style="color:red">
            [Die Aufsichtsbehörde für Ihre Gemeinde]
          </span>
          .
        </p>`;
  }
  if (language === Language.English) {
    return `
    <p style="font-weight:bold; font-size:16px">We are the data controller - how do you contact us?</p>
        <p>
          Kirken er ansvarlig for behandlingen af de personoplysninger, du har
          sendt eller delt med os. Du finder vores oplysninger her:
        </p>
        <p style="color:red">
          [Name of organisation]
          <br />
          [Address]
          <br />
          [Phone number]
          <br />
          [Email]
        </p>
        <p style="font-weight:bold; font-size:16px">Purpose and legal basis for data processing</p>
        <p>
          Personal data is all kinds of information that to some extent can be
          attributed to you. The purpose of the data we collect in our work is
          to fulfil practical tasks in the church's work and the church's
          communication work. The church fulfils a number of social tasks to
          which the church is legally obliged. The basis for personal data
          processing in the church varies depending on the specific tasks.
          Typically, this is consent-based, based on legitimate interest, based
          on a contract or a legal obligation.
        </p>
        <p style="font-weight:bold; font-size:16px">Collection of personal data</p>
        <p>
          When you visit our website, we register a range of information about
          your visit in anonymised form - including behaviour on our website,
          how you came to the website and how long you spent on the site. We
          collect this information to continuously improve our website.
        </p>
        <p>
          When you sign up for events, activities, church services, newsletters,
          make payments or donations, we process the information received for
          the stated purposes. Some of the data collected is characterised as
          sensitive personal data.
        </p>
        <p style="font-weight:bold; font-size:16px">Cookies</p>
        <p>
          We place content on our website that leaves cookies, provided that
          they are accepted by the visitor using our cookie solution. This is
          typically Youtube, Facebook, Soundcloud and the like. You will find
          our updated cookie policy in our cookie banner, where you can, as
          mentioned, reject or accept the use of the cookies in question and
          withdraw your consent to cookies.
        </p>
        <p style="font-weight:bold; font-size:16px">Sharing of personal data</p>
        <p>
          We only share personal information with third parties in accordance
          with applicable law and only when necessary to fulfil the purposes for
          which it was collected. We take appropriate measures to ensure that
          third parties process personal data in accordance with our
          instructions and in compliance with the GDPR.
        </p>
        <p style="font-weight:bold; font-size:16px">Storage of information about you</p>
        <p>
          We have taken technical and organisational measures to prevent your
          data from being accidentally or illegally deleted, published, lost,
          impaired or disclosed to unauthorised persons, misused or otherwise
          processed in violation of the law. We only use sub-processors in the
          EU or in countries that can provide your data with adequate
          protection.
        </p>
        <p style="font-weight:bold; font-size:16px">Period for storing your data</p>
        <p>
          The data is stored for the period of time permitted by law, and we
          delete it when it is no longer needed. The period depends on the
          nature of the data and the reason for storage. It is therefore not
          possible to specify a general time frame for when information is
          deleted.
        </p>
        <p style="font-weight:bold; font-size:16px">Your rights</p>
        <p>
          You have the right to be informed of what personal data we process
          about you and to have your data provided in an easy-to-use digitised
          format. You can also object at any time to the use of your data and
          limit the purposes for which your data may be used. You can also
          withdraw your consent to the processing of your data. If the data
          processed about you is incorrect, you have the right to have it
          corrected or deleted. Please contact us using the contact details
          listed at the bottom of the page. If you wish to complain about our
          processing of your personal data, you can also contact the Information
          Commissioner's Office (ICO), the UK supervisory authority for data
          protection issues. The ICO can be contacted on their helpline number
          which is 0303 123 1113 between 9am and 5pm Monday to Friday, or by
          other contact methods as set out on their website.
        </p>
      `;
  }
};
