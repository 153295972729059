(function () {
  'use strict';
  filterOr.$inject = ['_'];

  function filterOr(_) {
    'ngInject';

    /**
     * AngularJS default filter with the following expression:
     * "person in people | filter: {name: $select.search, age: $select.search}"
     * performs a AND between 'name: $select.search' and 'age: $select.search'.
     * We want to perform a OR.
     */
    return function (items, props) {
      let out = [];

      if (angular.isArray(items)) {
        items.forEach(function (item) {
          let itemMatches = false;

          let keys = Object.keys(props);
          for (let i = 0; i < keys.length; i++) {
            let prop = keys[i];
            let text = props[prop].toLowerCase();
            if (_.chain(item).get(prop).toLower().includes(text).value()) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }

  angular.module('cdApp.shared').filter('filterOr', filterOr);
})();
