'use strict';

class FormsHomepageComponent {
  constructor(gettextCatalog, Authorization, _) {
    this.Authorization = Authorization;

    this.noAccess = gettextCatalog.getString('You cannot access {{package}}', {
      package: _.capitalize(
        gettextCatalog.getString('forms', {}, 'The name of the app')
      ),
    });
  }
}

FormsHomepageComponent.$inject = ['gettextCatalog', 'Authorization', '_'];
angular.module('cdApp.forms').component('cdForms', {
  templateUrl: '@/app/forms/forms.component.html',
  bindings: {
    errorCode: '<',
  },

  controller: FormsHomepageComponent,
});
