import { Button, Card, Space } from 'antd';
import React, { FunctionComponent, Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValue } from 'recoil';

import { gettextCatalog } from '../../services/I18nService';
import { PostersAndInvitesTable } from '../containers/studio/PostersAndInvitesTableContainer';
import { CdMessage, CdPoster, CdPhone } from '../../shared/components/Icons';
import { CdPage } from '../../shared/components/cd-page/CdPage';
import AuthorizationService from '../../services/AuthorizationService';
import { StudioLandingPageV2 } from '../../people/message-editor/shared/containers/StudioLandingPageV2';
import { ChurchSettingsState } from '../../organization/store/church';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';
import { CdSalesButton } from '@/react/shared/components/CdSalesButton';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';

const openCreatePosterSelector = () =>
  NiceModal.show('CalendarPosterTemplates');
const openCreateInvitationSelector = () =>
  NiceModal.show('CalendarEmailPosterTemplates');

const Posters: FunctionComponent = () => {
  const hasStudio = AuthorizationService.hasPackage('studio');
  const churchSettings = useRecoilValue(ChurchSettingsState);
  const accountManager = churchSettings.accountManager;
  const canCommunicate = useRecoilValue(
    UserHasPermissionAnywhereQuery('people.communicate')
  );

  const getPageHeaderExtra = () => (
    <Space key="getPageHeaderExtra">
      {hasStudio ? (
        <Space key="buttons">
          <Button
            onClick={openCreatePosterSelector}
            icon={<CdPoster />}
            key="createPoster"
          >
            {gettextCatalog.getString('Create poster')}
          </Button>
          <CdTooltip
            title={
              !canCommunicate
                ? gettextCatalog.getString(
                    'You don’t have permission to create invites.'
                  )
                : undefined
            }
          >
            <Button
              onClick={openCreateInvitationSelector}
              icon={<CdMessage />}
              key="createInvite"
              disabled={!canCommunicate}
            >
              {gettextCatalog.getString('Create invite')}
            </Button>
          </CdTooltip>
        </Space>
      ) : (
        <CdSalesButton
          href={accountManager?.bookingLink}
          target="_blank"
          rel="noreferrer"
          key="scheduleACall"
        >
          <CdPhone style={{ marginRight: 8 }} />
          {gettextCatalog.getString('Schedule a call')}
        </CdSalesButton>
      )}
    </Space>
  );

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Posters & Invites'),
        subTitle: gettextCatalog.getString(
          'View all events with posters and/or invites'
        ),
        extra: [getPageHeaderExtra()],
        backIcon: false,
      }}
    >
      {!hasStudio && (
        <Card bodyStyle={{ padding: '24px' }} bordered>
          <StudioLandingPageV2 />
        </Card>
      )}
      {hasStudio && <PostersAndInvitesTable canCommunicate={canCommunicate} />}
    </CdPage>
  );
};

const PostersWrapper: FunctionComponent = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <Posters />
  </Suspense>
);

export default PostersWrapper;
