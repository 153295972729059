'use strict';

/**
 * Traverse up the DOM. Restrict to 5 iterations to avoid infinite loop.
 */
function findNextElement(element) {
  let nextElement;
  let trackingElement = element;
  _.forEach(_.range(5), () => {
    if (trackingElement.next().length) {
      nextElement = trackingElement.next();
      return false;
    }
    trackingElement = trackingElement.parent();
  });
  return nextElement;
}

/**
 * Stick an element to the top of the view when scrolling past it
 *
 * @desc If you pass a string to the attribute, it will be added as a class. If you pass `false`, the element won't be made sticky.
 *
 * @example <div class="your-element" cd-sticky-on-scroll="'your-element--fixed'"></div>
 */
const stickyOnScroll = () => ({
  restrict: 'A',
  scope: {
    stickyClass: '=cdStickyOnScroll',
  },

  link: (scope, elem) => {
    if (scope.stickyClass === false) return;

    const scrollElementClass = '.app-main';
    const elementClass = `${scope.stickyClass} animated slideInDown`;

    const onWindowScroll = _.throttle(
      () => {
        const screenPosition = angular.element(window).scrollTop();
        const elementPosition = elem.offset().top;
        const elementHeight = elem.height();
        const appMenuHeight = 48;
        const nextElement = findNextElement(elem);
        const announcementsContainer = angular.element('.announcements');
        const hasAnnouncements = announcementsContainer.children().length > 0;

        if (!nextElement.length || !nextElement.offset()) return;

        const nextElementPosition = nextElement.offset().top;

        if (screenPosition + elementHeight >= elementPosition) {
          if (hasAnnouncements) {
            elem.css('top', appMenuHeight + announcementsContainer.height());
          }
          elem.addClass(elementClass);
        }

        if (screenPosition + elementHeight <= nextElementPosition) {
          if (hasAnnouncements) {
            elem.css('top', 0);
          }
          elem.removeClass(elementClass);
        }
      },
      100,
      {
        trailing: true,
      }
    );

    angular.element(scrollElementClass).on('scroll', onWindowScroll);
    scope.$on('$destroy', () =>
      angular.element(scrollElementClass).off('scroll', onWindowScroll)
    );
  },
});

angular.module('cdApp.shared').directive('cdStickyOnScroll', stickyOnScroll);
