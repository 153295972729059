'use strict';

class FeatureToggleComponent {
  constructor(FeatureToggleService) {
    'ngInject';

    this.FeatureToggleService = FeatureToggleService;
  }

  $onInit() {
    const { FeatureToggleService } = this;

    if (!this.flagKey) throw new Error('FeatureToggle: flagKey is missing!');

    FeatureToggleService.hasFeature(this.flagKey).then((hasFeature) => {
      if (this.hideOnActive) {
        this.hasFeature = !hasFeature;
      } else {
        this.hasFeature = hasFeature;
      }
    });
  }
}
FeatureToggleComponent.$inject = ['FeatureToggleService'];

angular.module('cdApp.shared').component('cdFeatureToggle', {
  transclude: true,
  bindings: {
    flagKey: '<',
    hideOnActive: '<',
  },

  controller: FeatureToggleComponent,
  template: '<div ng-if="$ctrl.hasFeature" ng-transclude></div>',
});
