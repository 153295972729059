import { put, call } from 'redux-saga/effects';

import { AddLoading, RemoveLoading } from '../redux/Actions';
import ErrorHandlingService from '../../../services/ErrorHandlingService';

export function* startLoading(module: string): Generator {
  yield put(AddLoading(module));
}

export function* stopLoading(module: string): Generator {
  yield put(RemoveLoading(module));
}

export const withLoadingAndErrors = (loadingKey, saga, ...sagaArgs) =>
  function* (action?): Generator {
    yield put(AddLoading(loadingKey));
    try {
      yield call(saga, action, ...sagaArgs);
    } catch (error) {
      yield call(ErrorHandlingService.handleError, error);
    } finally {
      yield put(RemoveLoading(loadingKey));
    }
  };
