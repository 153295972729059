import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { isModuleLoading } from '../../shared/loading/redux/Selectors';
import { calendarModuleName } from '../store/events/fullCalendarEventsSlice';

const CalendarSpinner = ({ children }) => {
  const isLoading = useSelector(isModuleLoading(calendarModuleName));
  return (
    <Spin spinning={isLoading} size="large">
      {children}
    </Spin>
  );
};
export default CalendarSpinner;
