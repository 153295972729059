'use strict';

angular.module('cdApp.shared').factory('Picture', [
  '$window',
  'FileUploader',
  'AuthenticationService',
  function ($window, FileUploader, AuthenticationService) {
    return function (type, id, callback) {
      const url = `${cdApp.config.api.main}/${type}/${id}/upload/picture?organizationId=${$window.churchdeskOrganizationId}`;

      // Uploader.
      const instance = new FileUploader({
        url: url,
        autoUpload: true,
        formData: [
          {
            type: 'file',
            isHtml5: FileUploader.isHTML5,
            fromgallery: 'TRUE',
          },
        ],

        headers: {
          Authorization: `Bearer ${AuthenticationService.getAccessToken()}`,
        },
      });

      // Uploader filters.
      instance.filters.push({
        name: 'imageFilter',
        fn(item) {
          const type =
            '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
          return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        },
      });

      // Uploader callbacks.
      instance.onCompleteItem = callback;

      return instance;
    };
  },
]);
