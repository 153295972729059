export const saveSelectedColumnsToLocalStorage = (
  tableIdentifier: string,
  keys: Record<string, boolean>
) => {
  try {
    if (tableIdentifier) {
      localStorage.setItem(
        `churchdesk.table.activeColumnKeys.${tableIdentifier}`,
        JSON.stringify(keys)
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const getSelectedColumnsFromLocalStorage = (
  tableIdentifier: string
): Record<string, boolean> => {
  if (tableIdentifier) {
    const selectedColumns = localStorage.getItem(
      `churchdesk.table.activeColumnKeys.${tableIdentifier}`
    );
    if (selectedColumns) {
      try {
        return JSON.parse(selectedColumns);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }
  return {};
};
