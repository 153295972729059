import _ from 'lodash';

import { getResources } from '../../../../react/shared/store/resources';

/**
 * A drop-down that can be used to filter resources and select one or more of them,
 * with support of multi-church.
 *
 * @prop {Function} onResourceSelected({ selectedResourceIds: Number[] }) - A callback whenever a resource is selected
 */
class ResourceSelectComponent {
  constructor(appUtils, gettextCatalog, $ngRedux, $scope) {
    'ngInject';

    this.appUtils = appUtils;
    this.gettextCatalog = gettextCatalog;

    // Redux
    const unsubscribe = $ngRedux.connect(this.mapStateToScope)(this);
    $scope.$on('$destroy', unsubscribe);
  }

  // Initialization functions

  $onInit() {
    const { appUtils, resources } = this;

    // Init resources
    this.showChurchSelector = cdApp.showChurchSelector;
    this.resourceDictionary = [];
    this.groupedResourcesByChurch = _.map(
      _.cloneDeep(resources),
      (resource) => {
        if (
          appUtils.isResourceChurch(resource) &&
          this.showOptionNoParishResourceBooked
        ) {
          if (!resource.resources) resource.resources = [];
          resource.resources.push({
            id: resource.id,
            name: this.gettextCatalog.getString('No parish resource booked'),
            type: 'selectedEventsWithoutResourcesInChurchIdsCheckBox',
            color: -1,
          });
        }
        return resource;
      }
    );

    this.initChurchesAndResources(this.groupedResourcesByChurch);
    this.resourceDictionary = _.keyBy(this.groupedResourcesByChurch, 'id');
  }

  initChurchesAndResources(resources) {
    const { appUtils } = this;
    const churches = _.filter(resources, (resource) =>
      appUtils.isResourceChurch(resource)
    );

    const filteredChurches = _.filter(
      churches,
      (church) => church.resources?.length > 0
    );

    const standaloneResources = _.filter(
      resources,
      (resource) => !_.get(resource, 'type')
    );

    this.resourceTree = [...filteredChurches, ...standaloneResources];

    // Init filtered resources
    this.initFilteredResources();

    // If editing an event with resources, retrieve those resources
    if (_.isEmpty(this.selectedResourceIds)) {
      this.selectedResourceIds = [];
    }
    if (_.isEmpty(this.selectedEventsWithoutResourcesInChurchIds)) {
      this.selectedEventsWithoutResourcesInChurchIds = [];
    }
  }

  initFilteredResources() {
    const { appUtils } = this;
    this.filteredResourceTree = _.map(this.resourceTree, (resource) => {
      if (appUtils.isResourceChurch(resource)) {
        const resources = _.get(resource, 'resources', []);
        _.set(resource, 'filteredResources', _.clone(resources));
      }
      return resource;
    });
  }

  // Main functions

  onResourceDropDownToggle(open) {
    this.isResourceDropDownOpen = open;
    this.indexOfSelectedResource = -1;
    // When closing the resource drop-down, clear the filtering options
    if (!open) {
      this.searchText = null;
      this.idOfSelectedResource = null;
      this.initFilteredResources();
    }
  }

  toggleResource(resource) {
    /**
     * Process the selected resource.
     */
    const resourceId = _.get(resource, 'id');
    if (resource.type === 'church') {
      const isChurchAlreadySelected = this.isAllChildrenSelected(resource);
      const resourceIdsFromSelectedChurch = _.filter(
        _.map(_.get(resource, 'resources', []), 'id'),
        (n) => n !== resourceId
      );

      if (isChurchAlreadySelected) {
        this.selectedResourceIds = _.without(
          this.selectedResourceIds,
          ...resourceIdsFromSelectedChurch
        );

        this.selectedEventsWithoutResourcesInChurchIds = _.without(
          this.selectedEventsWithoutResourcesInChurchIds,
          resourceId
        );
      } else {
        this.selectedResourceIds = _.uniq([
          ...this.selectedResourceIds,
          ...resourceIdsFromSelectedChurch,
        ]);

        this.selectedEventsWithoutResourcesInChurchIds = _.uniq([
          ...this.selectedEventsWithoutResourcesInChurchIds,
          resourceId,
        ]);
      }
    } else {
      // Add the resource to selected resource ids:

      // Check if type 'selectedEventsWithoutResourcesInChurchIdsCheckBox'
      if (
        resource.type === 'selectedEventsWithoutResourcesInChurchIdsCheckBox'
      ) {
        this.selectedEventsWithoutResourcesInChurchIds = _.includes(
          this.selectedEventsWithoutResourcesInChurchIds,
          resourceId
        )
          ? _.without(
              this.selectedEventsWithoutResourcesInChurchIds,
              resourceId
            )
          : [...this.selectedEventsWithoutResourcesInChurchIds, resourceId];
      } else {
        this.selectedResourceIds = _.includes(
          this.selectedResourceIds,
          resourceId
        )
          ? _.without(this.selectedResourceIds, resourceId)
          : [...this.selectedResourceIds, resourceId];
      }
    }

    // Finally, update the parent component's with the selected resource IDs
    this.onResourceSelected({
      selectedResourceIds: this.selectedResourceIds,
      selectedEventsWithoutResourcesInChurchIds:
        this.selectedEventsWithoutResourcesInChurchIds,
    });
  }

  // Conditional rendering

  showResourceSearchInput() {
    let size = _.size(this.resourceTree);
    _.each(this.resourceTree, (resources) => {
      size += _.size(_.get(resources, 'resources'));
    });
    return size > 8;
  }

  // Styles

  getResourceClass(resource) {
    const resourcesToCheck = _.get(resource, 'resources', [resource]);
    let numberOfSelected = 0;
    let numberOfResources = _.size(resourcesToCheck);
    _.each(resourcesToCheck, (resourceToCheck) => {
      numberOfSelected += this.isResourceSelected(resourceToCheck) ? 1 : 0;
    });
    if (numberOfSelected === 0) return 'far fa-square';
    if (numberOfResources === numberOfSelected) return 'fa fa-check-square';
    return 'far fa-minus-square';
  }

  // Helper functions

  isAllChildrenSelected(resource) {
    const resourcesToCheck = _.get(resource, 'resources', [resource]);
    return _.every(resourcesToCheck, (resourceToCheck) =>
      this.isResourceSelected(resourceToCheck)
    );
  }

  isResourceSelected(resource) {
    const resourceId = _.get(resource, 'id');
    if (resource.type === 'selectedEventsWithoutResourcesInChurchIdsCheckBox') {
      return _.includes(
        this.selectedEventsWithoutResourcesInChurchIds,
        resourceId
      );
    } else {
      return _.includes(this.selectedResourceIds, resourceId);
    }
  }

  updateResourceList() {
    const { appUtils } = this;
    this.searchText = _.trim(this.searchText);
    if (_.isEmpty(this.searchText)) {
      this.initFilteredResources();
      return;
    }
    this.filteredResourceTree = [];
    _.each(this.resourceTree, (resource) => {
      if (appUtils.isResourceChurch(resource)) {
        const isChurchAMatch = this.isResourceASearchMatch(resource);
        const matchingChildren = _.filter(
          _.get(resource, 'resources'),
          (childResource) => this.isResourceASearchMatch(childResource)
        );

        const isAnyMatchingChildren = !_.isEmpty(matchingChildren);
        _.set(resource, 'filteredResources', matchingChildren);
        if (isChurchAMatch || isAnyMatchingChildren) {
          this.filteredResourceTree.push(resource);
        }
      } else {
        if (this.isResourceASearchMatch(resource)) {
          this.filteredResourceTree.push(resource);
        }
      }
    });
  }

  isResourceASearchMatch(resource) {
    const searchText = _.toLower(this.searchText);
    return _.includes(_.toLower(_.get(resource, 'name')), searchText);
  }

  resetSelection() {
    this.selectedResourceIds = [];
    this.selectedEventsWithoutResourcesInChurchIds = [];
    this.onResourceSelected({
      selectedResourceIds: this.selectedResourceIds,
      selectedEventsWithoutResourcesInChurchIds:
        this.selectedEventsWithoutResourcesInChurchIds,
    });
  }

  mapStateToScope = (state) => ({
    resources: getResources(state),
  });
}
ResourceSelectComponent.$inject = [
  'appUtils',
  'gettextCatalog',
  '$ngRedux',
  '$scope',
];

angular.module('cdApp.shared').component('cdResourceSelect', {
  template: require('./resource-select.component.html'),
  controller: ResourceSelectComponent,
  bindings: {
    onResourceSelected: '&',
    selectedResourceIds: '<',
    selectedEventsWithoutResourcesInChurchIds: '<',
    fromEventPopover: '<',
    showOptionNoParishResourceBooked: '<',
    /**
     * If showOptionNoParishResourceBooked is set to true:
     * Each parish will have the option "No parish resource booked"
     * that allows to filter on data where there is no resource in
     * that parish assigned to it.
     **/
  },
});
