import { filter } from 'lodash';

import { FEvent } from '../../calendar/models/calendar';
import { AppState } from '../../redux';
import { CategoryType } from '../models/category';

// People

export const getMatchedPeople = (state: AppState) =>
  state.shared.common.matchedPeople;

export const getPerson = (state: AppState) => state.shared.common.person;

// Categories

export const getAllCategories = (state: AppState) =>
  state.shared.common.categories;

export const getEventCategories = (state: AppState) =>
  filter(
    state.shared.common.categories,
    (category) => category.type === CategoryType.event
  );

export const getAbsenceCategories = (state: AppState) =>
  filter(
    state.shared.common.categories,
    (category) => category.type === CategoryType.absence
  );

// Events

export const getEvents: (state: AppState) => FEvent[] = (state) =>
  state.shared.common.events.asMutable({ deep: true });

export const getEvent: (state: AppState) => FEvent = (state) =>
  state.shared.common.event &&
  state.shared.common.event.asMutable({ deep: true });
