import React from 'react';
import { Image, Result } from 'antd';

import notfoundImage from './notfound.png';
export const CdNotFound = ({
  subTitle,
  title,
}: {
  subTitle?: React.ReactNode;
  title: string;
}) => (
  <Result
    style={{ margin: 'auto' }}
    status="info"
    title={title}
    icon={<Image height="150px" src={notfoundImage} preview={false} />}
    subTitle={subTitle}
  />
);
