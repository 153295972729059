'use strict';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import { GetAllTags } from '@/react/people/store/tagState';
function CreateTagController(
  $q,
  PeopleTags,
  toastr,
  gettextCatalog,
  Analytics
) {
  let $ctrl = this;

  /**
   * Lifecycle hook used for initialization work
   */
  $ctrl.$onInit = function () {
    if ($ctrl.resolve.multiple) {
      $ctrl.newTags = [];
    } else {
      $ctrl.newTag = new PeopleTags();
    }
  };

  /**
   * Save the new tag(s)
   */
  $ctrl.save = function () {
    // Save multiple tags
    if ($ctrl.resolve.multiple) {
      if (!$ctrl.newTags.length) return;

      let promises = [];
      let newTags = [];
      _.each($ctrl.newTags, function (tag) {
        if (_.some($ctrl.resolve.tagList, { name: tag.name })) return;
        newTags.push(tag);
        promises.push(tag.$save());
      });

      $q.all(promises).then(function (newTags) {
        $ctrl.close({ $value: newTags });
        Analytics.sendFeatureEvent('create tags', { module: 'people' });
        recoilRefresh(GetAllTags(''));
      });

      // Save a single tag
    } else {
      if (_.some($ctrl.resolve.tagList, { name: $ctrl.newTag.name })) {
        return toastr.error(gettextCatalog.getString('Tag already exists.'));
      }

      $ctrl.newTag.$save(function (newTag) {
        $ctrl.close({ $value: newTag });
        Analytics.sendFeatureEvent('create tags', { module: 'people' });
        recoilRefresh(GetAllTags(''));
      });
    }
  };

  /**
   * Returns whether the user's tag exists in the list of tags provided in the component's bindings
   */
  $ctrl.tagAlreadyExists = () =>
    !_.isEmpty($ctrl.newTag.name) &&
    _.includes(_.map($ctrl.resolve.tagList, 'name'), $ctrl.newTag.name);
}
CreateTagController.$inject = [
  '$q',
  'PeopleTags',
  'toastr',
  'gettextCatalog',
  'Analytics',
];

angular.module('cdApp.people').component('cdCreatePeopleTag', {
  templateUrl:
    '@/app/people/shared/components/create-tag/create-tag.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: CreateTagController,
});
