import { selector } from 'recoil';
import { atomFromRedux } from 'redux-to-recoil';

import { GetAllGroups } from '../../organization/store/organization';
import { Me } from '../../shared/models/authentication';

import cdApp from '@/react/config';
import { Organization, CountryISO2 } from '@/react/shared/models/organization';

export const MeState = atomFromRedux<Me>('.config.me');

export const OrganizationState = atomFromRedux<Organization>(
  '.config.organization'
);

export const HasMultipleChurches = selector({
  key: 'hasMultiChurch',
  get: ({ get }) => get(OrganizationState)?.churches?.length > 1,
});

export const LanguageState = selector({
  key: 'Language',
  get: ({ get }) => get(OrganizationState)?.locale?.language || 'en-gb',
});

export const CountryISO2State = selector<CountryISO2>({
  key: 'Locale',
  get: ({ get }) =>
    (get(OrganizationState)?.locale?.country as CountryISO2) ||
    CountryISO2.UNITED_KINGDOM,
});

export const Churches = selector({
  key: 'Churches',
  get: ({ get }) => get(OrganizationState)?.churches,
});

export const GetUserShortName = selector({
  key: 'GetUserShortName',
  get: ({ get }) => {
    const user = get(MeState);
    return user.firstName || user.email;
  },
});
export const GetUserGroups = selector<{ value: number; label: string }[]>({
  key: 'GetUserGroups',
  get: async ({ get }) => {
    const userGroupIds = cdApp.organization.myGroups;
    const allGroup = get(GetAllGroups);

    const userGroups = allGroup
      .filter((group) => userGroupIds.includes(group.id))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    return userGroups;
  },
});
