import { RefreshMainDataRequest } from '../../../react/config/store/Actions';
(function () {
  'use strict';

  function TaxonomiesController(
    _,
    $ngRedux,
    $scope,
    $rootScope,
    $uibModal,
    Taxonomies,
    $stateParams,
    $state,
    gettextCatalog,
    Partnership,
    Authorization
  ) {
    Partnership.categories.query().$promise.then(function (categories) {
      $scope.partnerships = categories;
    });
    $scope.hasIntentionsPackage = Authorization.hasPackage('intentions');
    const mapDispatchToThis = (dispatch) => ({
      RefreshAuth: () => dispatch(RefreshMainDataRequest()),
    });

    const unsubscribe = $ngRedux.connect(() => ({}), mapDispatchToThis)($scope);

    $scope.$on('$destroy', unsubscribe);

    $scope.setView = function (type) {
      switch (type) {
        case 'event':
          $scope.tab = 2;
          break;
        case 'blog':
          if (!Authorization.hasPackage('intranet')) {
            $state.go('app.public.errorState', { errorCode: 'ACCESS_DENIED' });
          } else {
            $scope.tab = 3;
          }
          break;
        case 'absence':
          $scope.tab = 4;
          break;
        default:
          $scope.tab = 1;
      }
    };

    function initData() {
      Taxonomies.query(function (list) {
        $scope.list.taxonomies = list;
      });
    }

    $scope.setView($stateParams.type);

    $scope.colors = Taxonomies.colors;
    $scope.types = ['event', 'blog', 'absence'];
    $scope.list = {};
    initData();

    /* ============================================================================
     ** Modals
     ** ==========================================================================*/

    // Delete taxonomies.
    $scope.delete = function (taxonomy, type) {
      $state.go('app.private.settings.calendar.delete', {
        type: type,
        id: taxonomy.id,
      });
    };

    // Create taxonomies.
    $scope.create = function (type) {
      $state.go('app.private.settings.calendar.create', { type: type });
    };

    // Edit taxonomies.
    $scope.edit = function (taxonomy, type) {
      $state.go('app.private.settings.calendar.edit', {
        type: type,
        id: taxonomy.id,
      });
    };

    /**
     * Export
     */
    $scope.export = function (type, taxonomy) {
      $uibModal.open({
        component: 'cdCalendarFeedModal',
        resolve: {
          feedName: () =>
            gettextCatalog.getString('Export') +
            (_.isEmpty(taxonomy.name) ? '' : ` "${taxonomy.name}"`),
          feedId: () => taxonomy.id,
          feedType: () => type,
          showPublicFeed: () => true,
          token: [
            '$q',
            '$http',
            ($q, $http) =>
              $q((resolve, reject) => {
                $http
                  .get(`${cdApp.config.api.main}/ical/token`)
                  .then((res) => resolve(res.data))
                  .catch(reject);
              }),
          ],
        },
      });
    };

    $rootScope.$on('RefreshTaxonomies', initData);
  }
  TaxonomiesController.$inject = [
    '_',
    '$ngRedux',
    '$scope',
    '$rootScope',
    '$uibModal',
    'Taxonomies',
    '$stateParams',
    '$state',
    'gettextCatalog',
    'Partnership',
    'Authorization',
  ];

  angular
    .module('cdApp.calendar')
    .controller('TaxonomiesController', TaxonomiesController);
})();
