'use strict';

class BillingInformationComponent {
  constructor(
    _,
    $q,
    $uibModal,
    $state,
    $rootScope,
    cdApp,
    Customer,
    toastr,
    gettextCatalog,
    documentTitleService
  ) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.cdApp = cdApp;
    this.Customer = Customer;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.documentTitleService = documentTitleService;
  }

  $onInit() {
    this.loading = true;
    const { _, cdApp } = this;

    const stripeCustomerId = this.stripeCustomerId;
    const organizationName = _.get(cdApp, 'organization.name');

    const getCustomerPromise = this.Customer.getCustomer().$promise;
    const getInvoicesPromise = this.Customer.getInvoices({
      stripeCustomerId,
    }).$promise;

    getCustomerPromise.then((customer) => {
      this.customer = customer;
      // Set organization name
      this.customer.name = this.customer.name || organizationName;
      if (this.customer.sources) {
        this.customer.sources.data = this._.map(
          this.customer.sources.data,
          (source) => {
            if (source.object === 'card') {
              source.type = 'card';
              source.card = source;
            }
            return source;
          }
        );
      }

      switch (this.customer.language) {
        case 'de':
          this.legalUrl = 'de/rechtliches/';
          break;
        case 'da':
          this.legalUrl = 'da/jura/';
          break;
        default:
          this.legalUrl = 'legal/';
          break;
      }

      if (this.readOnly) {
        this.documentTitleService.set(
          `${this.gettextCatalog.getString(
            'Billing',
            undefined,
            'Related to subcriptions'
          )} | ${this.customer.name}`
        );
      }
    });

    getInvoicesPromise.then((invoiceData) => {
      const { _ } = this;
      this.invoiceData = invoiceData;
      this.startingAfter = _.last(this.invoiceData.invoices);
      this.invoiceData.invoices = _.reject(this.invoiceData.invoices, {
        amount: 0,
      });

      // Include only those with status paid or open
      this.invoiceData.invoices = _.filter(
        this.invoiceData.invoices,
        this.filterInvoice
      );

      this.loading = false;
    });

    this.$q
      .all([getCustomerPromise, getInvoicesPromise])
      .finally(() => (this.loading = false));
  }

  removePaymentMethod(source) {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Remove payment method'),
          body: () =>
            this.gettextCatalog.getString(
              'Are you sure you want to remove this payment method?'
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Remove'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.Customer.removeSource({
          sourceId: source.id,
          type: source.type,
        }).$promise.then(() => {
          this.toastr.success(
            this.gettextCatalog.getString(
              'The payment method has been removed.'
            )
          );

          this._.remove(this.customer.sources.data, { id: source.id });
        });
      });
  }

  loadMoreInvoices() {
    if (!this.invoiceData.hasMore) return;
    const { _ } = this;

    const options = {
      stripeCustomerId: this.stripeCustomerId,
      startingAfter: _.last(this.invoiceData.invoices).id,
    };

    this.Customer.getInvoices(options).$promise.then((result) => {
      this.invoiceData.hasMore = result.hasMore;
      this.startingAfter = _.last(result.invoices);
      this.invoiceData.invoices = _.unionBy(
        this.invoiceData.invoices,
        _.filter(result.invoices, this.filterInvoice)
      );
    });
  }

  filterInvoice(invoice) {
    return invoice.status === 'paid' || invoice.status === 'open';
  }

  editBillingCustomer() {
    this.$uibModal
      .open({
        component: 'cdEditBillingCustomerModal',
        resolve: {
          customer: () => this.customer,
        },
      })
      .result.then((updatedCustomer) => {
        this.customer = updatedCustomer;
      });
  }
}
BillingInformationComponent.$inject = [
  '_',
  '$q',
  '$uibModal',
  '$state',
  '$rootScope',
  'cdApp',
  'Customer',
  'toastr',
  'gettextCatalog',
  'documentTitleService',
];

angular.module('cdApp.shared').component('cdBillingInformation', {
  templateUrl:
    '@/app/shared/components/billing-information/billing-information.component.html',
  controller: BillingInformationComponent,
  bindings: {
    stripeCustomerId: '<',
    readOnly: '<',
  },
});
