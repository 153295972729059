import { mainApi } from '../../api';

export const CollectionService = {
  getAllCollections: async ({ startDate, endDate }) => {
    const { ok, data } = await mainApi.get(
      `calendar/collection-calendar?startDate=${startDate}&endDate=${endDate}`
    );
    if (!ok) {
      throw data;
    }

    return data;
  },

  putCollection: async ({ id, date, purpose, nameOfDay, number }) => {
    const { ok, data } = await mainApi.put(
      `/calendar/collection-calendar/${id}`,
      {
        date,
        nameOfDay,
        number,
        purpose,
      }
    );
    if (!ok) {
      throw data;
    }
    return data;
  },

  deleteCollection: async (id) => {
    const { ok, data } = await mainApi.delete(
      `/calendar/collection-calendar/${id}`
    );
    if (!ok) {
      throw data;
    }
    return data;
  },

  createCollection: async ({ date, purpose, nameOfDay, number }) => {
    const { ok, data } = await mainApi.post('/calendar/collection-calendar', {
      date,
      purpose,
      nameOfDay,
      number,
    });

    if (!ok) {
      throw data;
    }

    return data;
  },
};
