import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Space, Typography } from 'antd';

import { gettextCatalog } from '../../services/I18nService';

const { Text } = Typography;
/**
 * component to display and control start and end date
 * @param {start} date the start date
 * @param {end} Date the end date
 * @param {allDay} boolean if the start and end date is for full day event
 * @param {showEndTime} boolean not mandatory option to hide the end date
 */
export const DisplayDate: FunctionComponent<{
  start: Date | string;
  end: Date | string;
  allDay: boolean;
  showEndTime?: boolean;
}> = (props) => {
  const functionDefaults = { showEndTime: true };
  const { start, end, allDay, showEndTime } = { ...functionDefaults, ...props };

  const startAndEndIsDifferentDays =
    moment(start).format('L') !== moment(end).format('L');

  return (
    <Space direction="vertical" size={0} align="start">
      <Text strong>
        {moment(start).format(startAndEndIsDifferentDays ? 'LL' : 'dddd, LL')}
        {allDay && ` ${gettextCatalog.getString('(all day)')}`}
        {startAndEndIsDifferentDays && (
          <>
            <Text style={{ margin: '0 5px' }}>
              {gettextCatalog.getString('to', {
                empty: null,
                context: 'Date interval',
              })}
            </Text>
            {moment(end).format(startAndEndIsDifferentDays ? 'LL' : 'dddd, LL')}
          </>
        )}
      </Text>
      {!allDay && (
        <Text type="secondary">
          {moment(start).format('LT')}
          {showEndTime && ` - ${moment(end).format('LT')}`}
        </Text>
      )}
    </Space>
  );
};

/**
 * Simple component to display start and end date.
 *
 * This takes into account whether the event is an all day, spans multiple days or whether you want to show end time or not.
 * @param {start} date the start date
 * @param {end} Date the end date
 * @param {allDay} boolean if the start and end date is for full day event
 * @param {showEndTime=true} boolean not mandatory option to hide the end date
 */
export const DisplayDateSpanSimple: FunctionComponent<{
  start: Date | string;
  allDay: boolean;
  end: Date | string;
  showEndTime?: boolean;
  dateFormat?: string;
  timeFormat?: string;
}> = ({
  start,
  allDay,
  end,
  showEndTime = false,
  dateFormat = 'LL',
  timeFormat = 'LT',
}) => {
  const startTime = moment(start).format(timeFormat);
  const endTime = showEndTime
    ? `- ${moment(end || start).format(timeFormat)}`
    : '';
  const displayDateStart = moment(start).format(dateFormat);
  const displayDateEnd = moment(end).format(dateFormat);
  const to = gettextCatalog.getString('to', {
    empty: null,
    context: 'Date interval',
  });

  if (!allDay) {
    // It is not all day.
    // Difference is less than or equal to 24 hours, we only show end time.
    if (moment(end).diff(moment(start), 'hours') <= 24) {
      return <>{`${displayDateStart} ${startTime} ${endTime}`}</>;
    } else {
      return (
        <>{`${displayDateStart} ${
          showEndTime ? ` ${to} ${displayDateEnd} ${startTime} ${endTime}` : ''
        }`}</>
      );
    }
  } else {
    // All day
    if (moment(end).diff(moment(start), 'hours') <= 24) {
      // Difference is less than or equal to 24 hours, we show only start date.
      return (
        <>{`${displayDateStart} ${gettextCatalog.getString('(all day)')}`}</>
      );
    } else {
      return <>{`${displayDateStart} ${to} ${displayDateEnd}`}</>;
    }
  }
};

export const DisplayDateSimple: FunctionComponent<{
  date: Date | string;
  allDay?: boolean;
  dateFormat?: 'L' | 'l' | 'll' | 'LL';
  timeFormat?: 'LT' | 'LTS';
  showRelativeTime?: boolean;
}> = ({
  date,
  allDay = true,
  dateFormat = 'LL',
  timeFormat = 'LT',
  showRelativeTime = false,
}) => {
  const dateObject = moment(date);
  if (showRelativeTime && moment().diff(dateObject, 'hours') <= 24) {
    return <>{dateObject.fromNow()}</>;
  }
  const time = !allDay ? dateObject.format(timeFormat) : '';
  return <>{dateObject.format(dateFormat) + ' ' + time}</>;
};
