'use strict';

/**
 * @desc Join the contents of a collection into a string
 *
 * @example
 *  $ctrl.array = [{
 *    name: 'John'
 *  }, {
 *    name: 'Christian'
 *  }, {
 *    name: 'Michael'
 *  }]
 *
 *  // Default separator
 *  <div>{{ $ctrl.array | join: 'name' }}</div> // "John, Christian, Michael"
 *
 *  // Custom separator
 *  <div>{{ $ctrl.array | join: 'name': '~' }}</div> // "John~Christian~Michael"
 *
 *  // Custom limit
 *  <div>{{ $ctrl.array | join: 'name': ', ': 2 }}</div> // "John, Christian"
 */
angular.module('cdApp.shared').filter('join', [
  '_',
  (_) => {
    'ngInject';

    return (array, property, separator = ', ', limit) => {
      let strings;

      if (property) {
        strings = _.map(array, property);
      }
      if (limit) {
        strings = _.slice(strings, 0, limit);
      }

      return _.join(strings, separator);
    };
  },
]);
