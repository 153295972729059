(function () {
  'use strict';

  angular.module('cdApp.calendar').directive('backColor', function () {
    // Directive to handle background color, since browsers cant handle angular code inside style tags
    return function (scope, element, attrs) {
      attrs.$observe('backColor', function (value) {
        element.css({
          'background-color': value,
        });
      });
    };
  });
})();
