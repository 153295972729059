import { Crisp } from 'crisp-sdk-web';

class BeaconChatService {
  constructor($window, $timeout) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
  }

  isBeaconActive() {
    return Crisp.isCrispInjected();
  }

  show() {
    Crisp.chat.show();
    Crisp.chat.open();
  }

  hide() {
    Crisp.chat.close();
  }

  showMessages() {
    this.show();
  }

  showNewMessage() {
    this.show();
  }
}

BeaconChatService.$inject = ['$window', '$timeout'];
angular.module('cdApp.shared').service('BeaconChat', BeaconChatService);
