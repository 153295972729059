'use strict';

class PeopleMessageSenderComponent {
  constructor($http, $uibModal, PeopleMessages, cdApp, Me, _) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.PeopleMessages = PeopleMessages;
    this._ = _;
    this.cdApp = cdApp;
    this.$http = $http;
    this.Me = Me;

    this.senderOptions = {
      church: PeopleMessages.senders.church,
    };
  }

  $onInit() {
    if (this.message.$promise) {
      this.message.$promise.then(() => {
        if (this.message.sent && this.message.sentBy) {
          this.senderOptions.user = this.message.sentBy;
          this.fetchUserPhone(this.message.sentBy.id);
        } else {
          this.senderOptions.user = this.PeopleMessages.senders.user;
          this.fetchUserPhone();
        }
        if (
          this.cdApp.organization.locale.country === 'gb' &&
          this.message.type === 'sms'
        ) {
          this.setSender('church');
        } else {
          this.setSender(this.message.from);
        }
      });
    } else {
      this.senderOptions.user = this.PeopleMessages.senders.user;
      if (
        this.cdApp.organization.locale.country === 'gb' &&
        this.message.type === 'sms'
      ) {
        this.setSender('church');
      } else {
        this.setSender('user');
      }
      this.fetchUserPhone();
    }
  }

  /**
   * Fetch the current user and get their mobile phone number
   *
   * @param {Number} userId
   */
  fetchUserPhone(userId = this.Me.id) {
    this.$http
      .get(`${cdApp.config.api.main}/users/${userId}`)
      .then(({ data }) => {
        if (!data) return;
        this.currentUser = data;

        const phoneObject = _.find(data.phones, { default: 1 });
        _.set(
          this.senderOptions,
          'user.phone',
          this.getFormattedPhone(phoneObject)
        );
      });
  }

  /**
   * Callback function that outputs the selected sender
   *
   * @param {any} senderKey
   */
  setSender(senderKey) {
    this.selected = senderKey;
    this.onSelect({ senderKey });
  }

  /**
   * Update the SMS sender name
   *
   * @param {any} $event
   */
  setSmsSenderName($event) {
    $event.stopPropagation();

    this.$uibModal
      .open({
        component: 'cdPeopleSmsSenderNameModal',
        resolve: {
          smsSenderName: () =>
            this._.get(this.senderOptions, 'church.smsSenderName'),
        },
      })
      .result.then((newSmsSenderName) => {
        if (!newSmsSenderName) return;
        this.PeopleMessages.churchInformation.smsSenderName = newSmsSenderName;
      });
  }

  /**
   * Update the main organization e-mail
   *
   * @param {any} $event
   */
  setOrganizationEmail($event) {
    $event.stopPropagation();

    this.$uibModal
      .open({
        component: 'cdUpdateOrganizationEmailModal',
        resolve: {
          organizationEmail: () =>
            this._.get(this.senderOptions, 'church.organizationEmail'),
        },
      })
      .result.then((organizationEmail) => {
        if (!organizationEmail) return;
        this.cdApp.organization.email = organizationEmail;
        this.setSender('church');
      });
  }

  /**
   * Update the current user's mobile phone number
   *
   * @param {any} $event
   */
  setUserPhone($event) {
    $event.stopPropagation();

    this.$uibModal
      .open({
        component: 'cdUpdateUserPhoneModal',
        resolve: {
          user: () => this.currentUser,
        },
      })
      .result.then((phones) => {
        if (this._.isEmpty(phones)) return;

        const phoneObject = this._.find(phones, { default: 1 });
        this.senderOptions.user.phone = this.getFormattedPhone(phoneObject);
      });
  }

  /**
   * Format a phone number
   *
   * @param {Object} phoneObject
   * @returns {String}
   */
  getFormattedPhone(phoneObject) {
    if (!phoneObject) return;

    const phone = phoneObject.phone;
    return phone
      ? window.intlTelInputUtils.formatNumber(phone, phoneObject.countryId, 1)
      : null;
  }
}

PeopleMessageSenderComponent.$inject = [
  '$http',
  '$uibModal',
  'PeopleMessages',
  'cdApp',
  'Me',
  '_',
];

angular.module('cdApp.people').component('cdPeopleMessageSender', {
  templateUrl:
    '@/app/people/shared/components/message-sender/message-sender.component.html',
  bindings: {
    message: '<',
    onSelect: '&',
  },

  controller: PeopleMessageSenderComponent,
});
