(function () {
  var selectedClass = 'draggable';
  var firstSelected = [];

  angular
    .module('cdApp.intranet')
    .directive('itemDraggable', function () {
      return function (scope, elm, attr) {
        $(elm)
          .bind('click mousedown', function (e) {
            if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
              if (e.type == 'mousedown' && $('.' + selectedClass).length > 1) {
                //skip
              } else {
                //$('.' + selectedClass).removeClass(selectedClass);
                $('.draggable:not(.itemselected)').removeClass(selectedClass);
                $('.firstSelected').removeClass('firstSelected');
                $(elm).addClass(selectedClass);
                $(elm).addClass('firstSelected');
                firstSelected = $(elm);
              }
            } else if ((e.metaKey || e.ctrlKey) && e.type == 'click') {
              $('.firstSelected').removeClass('firstSelected');
              elm.toggleClass(selectedClass);
              firstSelected = $(elm);
              elm.addClass('firstSelected');
            } else if (e.shiftKey) {
              var direction, $selected;
              if (firstSelected.length > 0) {
                if (elm[0] == firstSelected[0]) {
                  $('.' + selectedClass).removeClass(selectedClass);
                  elm.addClass(selectedClass);
                  return false;
                }

                direction =
                  elm.nextAll('.firstSelected').length > 0 ? 'forward' : 'back';

                if (direction == 'forward') {
                  $selected = $(elm).nextUntil(
                    firstSelected,
                    '.ui-draggable-handle'
                  );
                } else {
                  $selected = firstSelected.nextUntil(
                    $(elm),
                    '.ui-draggable-handle'
                  );
                }

                //$('.' + selectedClass).removeClass(selectedClass);
                $selected.addClass(selectedClass);
                firstSelected.addClass(selectedClass);
                elm.addClass(selectedClass);
              } else {
                var firstLi = $('table.files-browser > tbody tr:nth-child(1)');
                $selected = firstLi.nextUntil($(elm), '.ui-draggable-handle');
                $('.' + selectedClass).removeClass(selectedClass);
                $selected.addClass(selectedClass);
                firstLi.addClass(selectedClass);
                elm.addClass(selectedClass);
              }
            }
          })
          .draggable({
            opacity: 0.7,
            addClasses: false,
            revertDuration: 5,
            cursor: 'move',
            refreshPositions: true,
            appendTo: '#files',
            containment: '#files',
            scroll: true,
            helper: function () {
              var draggedelem = $('.' + selectedClass);
              var helper =
                '<div class="dragHelper"> <div class="numberCircle" style="display: inline-block; float: left;"> ' +
                draggedelem.length +
                '</div>';
              var zindex = 100;
              angular.forEach(draggedelem, function (elm, index) {
                if (index <= 3) {
                  var model = $(elm).data('model');
                  if (model.type == 'folder') {
                    helper +=
                      "<div class='file_type_drag_helper_icon' style='z-index:" +
                      zindex +
                      ";position:relative;'><i class='fa fa-folder-o fa-2x'></i></div>";
                  } else {
                    var file_type = model.fileInfo.filetype.class;
                    if (model.fileInfo.filetype.type != 'image') {
                      helper +=
                        "<div class='file_type_drag_helper_icon' style='z-index:" +
                        zindex +
                        "; position:relative;'><i class='" +
                        file_type +
                        "'></i></div>";
                    } else {
                      helper +=
                        "<div class='file_type_drag_helper_icon' style='z-index:" +
                        zindex +
                        "; position:relative;'> " +
                        "<img class='file_type_drag_helper_icon' src='" +
                        model.fileInfo.thumbnail.url +
                        "'> </div>";
                    }
                  }
                }
                zindex -= 10;
              });

              helper += '</div>';

              return $(helper);
            },
            cursorAt: {
              top: 3,
              left: -7,
            },

            cancel: '.ui-draggable-dragging',
            stop: function (e, ui) {
              elm.css({
                top: 0,
                left: 0,
              });

              $('.' + selectedClass).css({
                top: 0,
                left: 0,
              });

              //$('.' + selectedClass).draggable("disable");
            },
            drag: function (e, ui) {
              // set selected group position to main dragged object
              // this works because the position is relative to the starting position
              $('.' + selectedClass).css({
                top: ui.position.top,
                left: ui.position.left,
              });
            },
          });
      };
    })
    .directive('itemDropable', function () {
      return function (scope, elm, attrs) {
        $(elm).droppable({
          addClasses: false,
          tolerance: 'pointer',
          over: function (event, ui) {
            var sTarget = elm.data('model');
            var hoverClass =
              sTarget.type == 'file' ? 'errorDrop' : 'activeDrop';
            if (!elm.hasClass(selectedClass)) {
              elm.addClass(hoverClass);
            }
          },
          out: function (event, ui) {
            var sTarget = elm.data('model');
            var hoverClass =
              sTarget.type == 'file' ? 'errorDrop' : 'activeDrop';
            elm.removeClass(hoverClass);
          },
          drop: function (event, ui) {
            var fnhandleDrop = scope.$eval(attrs.itemDropable);

            if (fnhandleDrop && angular.isFunction(fnhandleDrop)) {
              scope.$apply(function () {
                fnhandleDrop($('.' + selectedClass), elm, event, ui);
              });

              $('.' + selectedClass)
                .removeClass(selectedClass)
                .css({
                  top: 0,
                  left: 0,
                });
            }
          },
        });
      };
    })
    .directive('a', function () {
      return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
          if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
            elem.on('click', function (e) {
              e.preventDefault();
            });
          }
        },
      };
    })
    .directive('scrollContainerUp', [
      '$interval',
      function ($interval) {
        return function (scope, elm) {
          var timerUp;
          $(elm).droppable({
            tolerance: 'pointer',
            over: function () {
              timerUp = $interval(function () {
                $('#file-archives-list-container')[0].scrollTop -= 5;
              }, 10);
            },
            out: function () {
              $interval.cancel(timerUp);
            },
          });
        };
      },
    ])
    .directive('scrollContainerDown', [
      '$interval',
      function ($interval) {
        var timerDown;
        return function (scope, elm) {
          $(elm).droppable({
            tolerance: 'pointer',
            over: function () {
              timerDown = $interval(function () {
                $('#file-archives-list-container')[0].scrollTop += 5;
              }, 10);
            },
            out: function () {
              $interval.cancel(timerDown);
            },
          });
        };
      },
    ])
    .service('userAccess', [
      'Me',
      function (Me) {
        return {
          user_access: function (op, type, node, userPerm) {
            // If permission table is empty no need to check
            if (_.isEmpty(userPerm) === true) {
              return false;
            }

            var uid = parseInt(Me.id);

            // Permission to check for
            var perm = {
              file: {
                create: 'create file',
              },

              folder: {
                create: 'create folder',
              },
            };

            // If super user or has bypass node access
            if (uid === 1) {
              return true;
            }

            // If operation is create and the user has access to create content
            return userPerm[perm[type]['create']];
          },
          visibility_access: function (userPerm) {
            // If permission table is empty no need to check
            if (_.isEmpty(userPerm) === true) {
              return false;
            }

            var uid = parseInt(Me.id);
            // If super user or has bypass node access
            if (uid === 1) {
              return true;
            }

            return userPerm['change visibility'];
          },
        };
      },
    ]);

  // Modal picture viewer
  angular
    .module('cdApp.intranet')
    .service('$cdimageviewer', [
      '$uibModal',
      function ($uibModal) {
        return {
          open: function (opts) {
            opts = angular.extend(opts || {}, {
              backdrop: false,
              windowTemplateUrl: 'cd/modal/imageViewer.html',
            });

            return $uibModal.open(opts);
          },
        };
      },
    ])
    .run([
      '$templateCache',
      function ($templateCache) {
        $templateCache.put(
          'cd/modal/imageViewer.html',
          ' <div id="image-preview-modal" ng-click="close($event)">' +
            '  <div id="modal-preview-content" ng-transclude> </div>' +
            '</div>'
        );
      },
    ]);
})();
