'use strict';

class PeopleSmsSenderNameModal {
  constructor(_, cdApp, PeoplePreferences) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
    this.PeoplePreferences = PeoplePreferences;
  }

  $onInit() {
    const deburred = _.deburr(this.cdApp.organization.name);
    const defaultName = deburred.replace(/\s+/g, '').slice(0, 11);
    this.newSmsSenderName = this.resolve.smsSenderName || defaultName;
  }

  save() {
    new this.PeoplePreferences({
      smsSenderName: this.newSmsSenderName,
    }).$save(() => {
      this.close({ $value: this.newSmsSenderName });
    });
  }
}

PeopleSmsSenderNameModal.$inject = ['_', 'cdApp', 'PeoplePreferences'];

angular.module('cdApp.people').component('cdPeopleSmsSenderNameModal', {
  templateUrl:
    '@/app/people/shared/components/sms-sender-name/sms-sender-name.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: PeopleSmsSenderNameModal,
});
