import React, { useMemo } from 'react';
import { RecoilState, RecoilValue } from 'recoil';

import { CdTableData, CdTableProps } from '../cd-table';

import { useCdQueryTable } from './useCdQueryTable';

import CdTable from '@/react/shared/components/cd-table/CdTable';
import { ApiSearchParams, ApiSearchResult } from '@/react/api';

interface CdQueryTableProps<T>
  extends Omit<
    CdTableProps<T>,
    'isLoading' | 'data' | 'onTableChange' | 'headingText'
  > {
  dataResult: (param: ApiSearchParams) => RecoilValue<ApiSearchResult<T>>;
  searchParams: RecoilState<Record<string, any>>;
  headingText?: (data: CdTableData<T>) => string;
  extraParams?: {
    extraIntentionsData?: any;
    eventCategoryIds?: number[];
  };
}

export default function CdQueryTable<T extends Record<string, any> = any>({
  dataResult,
  searchParams,
  headingText,
  extraParams,
  ...props
}: CdQueryTableProps<T>) {
  const { isFetching, data, handleTableChange, page } = useCdQueryTable<T>({
    dataResult,
    searchParams,
    extraParams: extraParams,
    defaultPageSize: props.pageSize,
  });

  const heading = useMemo(
    () => (headingText ? headingText(data) : ''),
    [headingText, data]
  );
  return (
    <CdTable<T>
      {...props}
      isLoading={isFetching}
      data={data}
      page={page}
      onTableChange={handleTableChange}
      headingText={heading}
    />
  );
}
