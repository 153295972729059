import { selectorFamily } from 'recoil';

import { TagInterface } from '../types/tag.type';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import { mainApi } from '@/react/api';

export const GetAllTags = selectorFamily<TagInterface[], string>({
  key: 'GetAllTags',
  get:
    (searchParameter: string) =>
    async ({ getCallback }) => {
      const res = await mainApi.get<TagInterface[]>('/people/tags');
      registerRecoilRefresher(
        GetAllTags(searchParameter),
        getCallback(
          ({ refresh }) =>
            () =>
              refresh(GetAllTags(searchParameter))
        )
      );
      const searched = (keyWord) => (l) =>
        l.name.toLowerCase().includes(keyWord.toLowerCase());
      const data = res.data
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .filter(searched(searchParameter));

      return data;
    },
});
