import * as immutable from 'seamless-immutable';
import { ImmutableObject } from 'seamless-immutable';
import { Reducer } from 'redux';

import createReducer, { CdAction, ActionCreator } from '../../redux/utils';
import { InterfaceGroups } from '../services/Groups.service';

// Actions
export const FETCH_GROUPS = 'organization/FetchGroups';
export const FETCH_GROUPS_SUCCESS = 'organization/FetchGroupsSuccess';

export type FetchGroupsSuccess = CdAction<InterfaceGroups[]>;

// Action Creators
const FetchGroups: ActionCreator<undefined> = () => ({
  type: FETCH_GROUPS,
});

const FetchGroupsSuccess: ActionCreator<InterfaceGroups[]> = (payload) => ({
  type: FETCH_GROUPS_SUCCESS,
  payload,
});

export const ActionCreators = {
  FetchGroups,
  FetchGroupsSuccess,
};

// State
export interface GroupsState {
  groups: InterfaceGroups[];
  communication: {
    groupsLoading: boolean;
  };
}

export type ImmutableGroupsState = ImmutableObject<GroupsState>;

// Reducers
const fetchGroups: Reducer<ImmutableGroupsState> = (state = initialState) =>
  state.merge({
    communication: { ...state.communication, groupsLoading: true },
  });
const fetchGroupsSuccess: Reducer<ImmutableGroupsState, FetchGroupsSuccess> = (
  state = initialState,
  { payload }
) =>
  state.merge({
    groups: payload,
    communication: { ...state.communication, groupsLoading: false },
  });

// Initial State
const initialState: ImmutableGroupsState = immutable.from<GroupsState>({
  groups: [],
  communication: { groupsLoading: false },
});

// Handlers
export const handlers = {
  [FETCH_GROUPS]: fetchGroups,
  [FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
};

export default createReducer<ImmutableGroupsState>(initialState, handlers);
