import { getSafeguardingReportDownloadToken } from '../services/SafeguardingExportService';
import cdApp from '../../config';

export const useSafeguardingReport = () => {
  const getDownloadTokenAndDownloadFile = async () => {
    const response = await getSafeguardingReportDownloadToken();
    window.open(
      `${cdApp.config.api.main}/safeguard/report?downloadToken=${response?.token}`,
      '_blank'
    );
  };
  return {
    getDownloadTokenAndDownloadFile,
  };
};
