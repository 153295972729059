'use strict';

/**
 * @desc Sanitize the output when pasting highly formatted text
 * @example safePasteService.handlePaste(event);
 */

angular.module('cdApp.shared').service('safePasteService', function () {
  const specialCharacters = ['–', '’', '‘', '…', '”', '“'];
  const normalCharacters = ['-', "'", "'", '...', '"', '"'];

  function replaceInvalidCharacters(string) {
    let regEx;

    _.each(specialCharacters, function (char, index) {
      regEx = new RegExp(specialCharacters[index], 'g');
      string = string.replace(regEx, normalCharacters[index]);
    });

    return string;
  }

  /**
   * Prevent the default paste behaviour, santizie the content and paste it
   *
   * @param event The original DOM event that triggered the paste
   * @param windowObject Optional parameter, use it if the event is triggered from an iframe
   */
  function handlePaste(event, windowObject = window) {
    event.preventDefault();

    let plainText;
    let cleanText;

    if ((event.originalEvent || event).clipboardData) {
      plainText = (event.originalEvent || event).clipboardData.getData(
        'text/plain'
      );

      cleanText = replaceInvalidCharacters(plainText);

      windowObject.document.execCommand('insertText', false, cleanText);
    } else if (windowObject.clipboardData) {
      plainText = windowObject.clipboardData.getData('Text');
      cleanText = replaceInvalidCharacters(plainText);

      if (windowObject.getSelection) {
        windowObject
          .getSelection()
          .getRangeAt(0)
          .insertNode(windowObject.document.createTextNode(cleanText));
      } else {
        windowObject.document.selection.createRange().pasteHTML(cleanText);
      }
    }
  }

  return {
    handlePaste,
  };
});
