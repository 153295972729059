import { useRecoilValue } from 'recoil';

import {
  GetAllGroups,
  OrganizationChurches,
} from '@/react/organization/store/organization';
import cdApp from '@/react/config';
import { GetOrganizationPortals } from '@/react/portal/state/portal';

export const useSafeguardingList = () => {
  const churches = useRecoilValue(OrganizationChurches);
  const OrganizationGroups = useRecoilValue(GetAllGroups);
  const organizationPortals = useRecoilValue(
    GetOrganizationPortals(cdApp.organization?.id)
  );

  return {
    churches,
    OrganizationGroups,
    organizationPortals,
  };
};
