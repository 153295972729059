(function () {
  'use strict';
  cdRrule.$inject = [
    'moment',
    'gettextCatalog',
    'rruleFactory',
    'dateFormatsLookup',
    'RRule',
  ];

  function cdRrule(
    moment,
    gettextCatalog,
    rruleFactory,
    dateFormatsLookup,
    RRule
  ) {
    return {
      restrict: 'E',
      scope: {
        rrule: '@',
        startDate: '@',
        excludedDates: '@',
        includedDates: '@',
        eventId: '@',
        saveRepeat: '&',
        cancelRepeat: '&',
        hasRrule: '@',
      },

      templateUrl: '@/app/shared/components/repeat/templates/Rrule.html',
      link: function (scope) {
        // =============================================================================
        // Initialize all the data needed.
        // =============================================================================

        // Open the repeat window and initialize the models.
        scope.init = function () {
          // A replacement for the controllerAs syntax to help fix inconsistent bugs with uiSelect
          scope.selected = {};
          scope.initFrequencies();
          scope.initWeekOrdinals();
          scope.selectedMonthFrequency = 'day_of_month';
          scope.selectedEndRule = 'after';
          scope.resetData();
          scope.gettext = gettextCatalog;
          scope.currentDate = moment().toDate();

          if (scope.rrule !== '') {
            scope.parseRule(scope.rrule);
            scope.parseExcludesAndIncludes();
          }

          scope.calculateRRule();

          const hasExcludedDates =
            scope.excludedDates !== '' &&
            !_.isEmpty(JSON.parse(scope.excludedDates));
          const hasIncludedDates =
            scope.includedDates !== '' &&
            !_.isEmpty(JSON.parse(scope.includedDates));
          scope.allowExclIncl = hasExcludedDates || hasIncludedDates;

          scope.startDateParsed = JSON.parse(scope.startDate);

          scope.showIncludeAndExclude = !scope.eventId;
        };
        // Decide whether to show the warning message "Please fill in the number of repeated events"
        scope.afterWarningMessage = function () {
          if (
            scope.selectedEndRule === 'after' &&
            (scope.count === undefined || scope.count === null)
          ) {
            return true;
          } else if (scope.selectedEndRule === 'after' && scope.count) {
            return false;
          }
        };

        // Initialize the available repeat frequencies to choose from.
        scope.initFrequencies = function () {
          scope.frequencies = [
            {
              type: 'day',
              rruleType: RRule.DAILY,
              getName: (count) =>
                gettextCatalog.getPlural(count, 'day', 'days'),
            },

            {
              type: 'week',
              rruleType: RRule.WEEKLY,
              getName: (count) =>
                gettextCatalog.getPlural(count, 'week', 'weeks'),
            },

            {
              type: 'month',
              rruleType: RRule.MONTHLY,
              getName: (count) =>
                gettextCatalog.getPlural(count, 'month', 'months'),
            },

            {
              type: 'year',
              rruleType: RRule.YEARLY,
              getName: (count) =>
                gettextCatalog.getPlural(count, 'year', 'years'),
            },
          ];

          // Set the default frequency to appear when the window is opened.
          scope.selectedFrequency = scope.frequencies[1];
        };

        // Initialize the week ordinals for week numbering in English.
        scope.initWeekOrdinals = function () {
          scope.weekOrdinals = ['st', 'nd', 'rd', 'th'];

          scope.ordinals = [
            { name: gettextCatalog.getString('First'), value: 1 },
            { name: gettextCatalog.getString('Second'), value: 2 },
            { name: gettextCatalog.getString('Third'), value: 3 },
            { name: gettextCatalog.getString('Fourth'), value: 4 },
            { name: gettextCatalog.getString('Fifth'), value: 5 },
            { name: gettextCatalog.getString('Last'), value: -1 },
          ];
        };

        // Initialize the maximum number of days in a month.
        scope.initMonthlyDays = function () {
          scope.monthDays = [];

          _.times(31, function (index) {
            scope.monthDays.push({
              day: index + 1,
              value: index + 1,
              selected: false,
            });
          });

          // Set a 'Last day' value to represent the last day in a month.
          scope.monthDays.push({
            day: gettextCatalog.getString('Last day'),
            value: -1,
            selected: false,
          });
        };

        // Initialize all available days in a week.
        scope.daysOfWeek = () => {
          const days = [
            {
              name: gettextCatalog.getString('Monday'),
              value: RRule.MO,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Tuesday'),
              value: RRule.TU,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Wednesday'),
              value: RRule.WE,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Thursday'),
              value: RRule.TH,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Friday'),
              value: RRule.FR,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Saturday'),
              value: RRule.SA,
              selected: false,
            },

            {
              name: gettextCatalog.getString('Sunday'),
              value: RRule.SU,
              selected: false,
            },
          ];

          return _.map(days, (day) =>
            _.assign({}, day, { name: day.name.substr(0, 2) })
          );
        };

        scope.getDayOfWeekClass = (dayOfWeek, selectedDays) => {
          const isSelected =
            dayOfWeek.selected ||
            (selectedDays && _.includes(selectedDays, dayOfWeek.value));
          return isSelected ? 'btn-primary' : 'btn-default';
        };

        // Initialize all available months in a year.
        scope.monthsOfYear = function () {
          return [
            {
              name: gettextCatalog.getString('January'),
              abbr: 'Jan',
              value: 1,
              selected: false,
            },

            {
              name: gettextCatalog.getString('February'),
              abbr: 'Feb',
              value: 2,
              selected: false,
            },

            {
              name: gettextCatalog.getString('March'),
              abbr: 'Mar',
              value: 3,
              selected: false,
            },

            {
              name: gettextCatalog.getString('April'),
              abbr: 'Apr',
              value: 4,
              selected: false,
            },

            {
              name: gettextCatalog.getString('May'),
              abbr: 'May',
              value: 5,
              selected: false,
            },

            {
              name: gettextCatalog.getString('June'),
              abbr: 'Jun',
              value: 6,
              selected: false,
            },

            {
              name: gettextCatalog.getString('July'),
              abbr: 'Jul',
              value: 7,
              selected: false,
            },

            {
              name: gettextCatalog.getString('August'),
              abbr: 'Aug',
              value: 8,
              selected: false,
            },

            {
              name: gettextCatalog.getString('September'),
              abbr: 'Sep',
              value: 9,
              selected: false,
            },

            {
              name: gettextCatalog.getString('October'),
              abbr: 'Oct',
              value: 10,
              selected: false,
            },

            {
              name: gettextCatalog.getString('November'),
              abbr: 'Nov',
              value: 11,
              selected: false,
            },

            {
              name: gettextCatalog.getString('December'),
              abbr: 'Dec',
              value: 12,
              selected: false,
            },
          ];
        };

        // =============================================================================
        // Utilities.
        // =============================================================================

        // Reset the data.
        scope.resetData = function () {
          scope.selectedMonthFrequency = 'day_of_month';
          scope.weekDays = scope.daysOfWeek();
          scope.monthWeekDays = scope.daysOfWeek();
          scope.months = scope.monthsOfYear();
          scope.initMonthlyDays();
          scope.interval = 1;
          scope.count = scope.count || 12;
          scope.until = scope.until
            ? moment(scope.until).endOf('day').toDate()
            : null;

          if (scope.selectedFrequency.type === 'month') {
            scope.selectedOrdinal = scope.ordinals[0];

            // There has to be at least one DayOfMonth selected. If none is selected, choose it based on the event's start date
            if (
              _(scope.monthDays)
                .filter((day) => day.selected)
                .map('value')
                .isEmpty()
            ) {
              const dayOfMonthOfStartDate = moment(
                JSON.parse(scope.startDate)
              ).date();
              const dayOfMonth = _.find(
                scope.monthDays,
                ({ day }) => day === dayOfMonthOfStartDate
              );

              dayOfMonth.selected = true;
            }
          }

          scope.resetExcludes();
          scope.resetIncludes();
          scope.calculateRRule();
        };

        scope.resetIncludes = function () {
          scope.includeModel = [''];
          scope.rDate = '';
          scope.calculateRRule();
        };

        scope.resetExcludes = function () {
          scope.excludeModel = [''];
          scope.exDate = '';
          scope.calculateRRule();
        };

        // Select what the repeat rule for the monthly frequency is.
        scope.selectMonthFrequency = function (monthFrequency) {
          scope.selectedMonthFrequency = monthFrequency;

          // Reset other month options.
          scope.selectedMonths = [];
          scope.selected.monthDays = [];
          scope.selectedWeekDays = [];

          if (monthFrequency === 'day_of_week') {
            scope.selectedOrdinal = scope.ordinals[0];
          }

          scope.calculateRRule();
        };

        // Select what the repeat end rule is.
        scope.selectEndRule = function (endRule) {
          scope.selectedEndRule = endRule;

          // Reset other end options.
          scope.until = undefined;
          scope.count = undefined;

          switch (endRule) {
            case 'on':
              // Set the end date to 1 week from now by default.
              scope.until = moment(JSON.parse(scope.startDate))
                .add(1, 'w')
                .endOf('day')
                .toDate();
              break;
            case 'after':
              scope.count = scope.count || 12;
              break;

            case 'never':
              // if it is a never ending event then set until and count to undefined
              scope.until = undefined;
              scope.count = undefined;
              break;
          }

          scope.calculateRRule();
        };

        // Calculate the repeat rule based on the selection made by the user.
        scope.toggleSelected = function (item) {
          item.selected = !item.selected;
          scope.calculateRRule();
        };

        scope.datepickerFormat = dateFormatsLookup.getFormat();

        scope.datepickerOptions = {
          minDate: moment(JSON.parse(scope.startDate)).toDate(),
        };

        // Convert an ISO 8601 formatted date string into values that can be used to create a new Date object.
        scope.parseIsoDatetime = function (date) {
          // Replace anything but numbers by spaces.
          let dtstr = date.replace(/\D/g, ' ');
          // Split on space.
          let dt = dtstr.split(' ');
          // Modify month between 1 based ISO 8601 and zero based Date.
          dt[2]--;

          return new Date(Date.UTC(dt[1], dt[2], dt[3], dt[4], dt[5], dt[6]));
        };

        function dateToString(time) {
          let date = new Date(time);
          let comp,
            comps = [
              date.getUTCFullYear(),
              date.getUTCMonth() + 1,
              date.getUTCDate(),
              'T',
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds(),
              'Z',
            ];

          for (let i = 0; i < comps.length; i++) {
            comp = comps[i];
            if (!/[TZ]/.test(comp) && comp < 10) {
              comps[i] = '0' + String(comp);
            }
          }
          return comps.join('');
        }

        scope.updateOrdinal = function (ordinal) {
          scope.selectedOrdinal = ordinal;
          scope.calculateRRule();
        };

        scope.updateFrequency = function (frequency) {
          scope.selectedFrequency = frequency;
          scope.resetData();
        };

        // =============================================================================
        // Calculations for RRule.
        // =============================================================================

        // Calling different function based on the frequency selected.
        scope.calculateRRule = function () {
          switch (scope.selectedFrequency.type) {
            case 'day':
              scope.calculateDailyRRule();
              break;
            case 'week':
              scope.calculateWeeklyRRule();
              break;
            case 'month':
              scope.calculateMonthlyRRule();
              break;
            case 'year':
              scope.calculateYearlyRRule();
          }

          // Get the excluded or included dates, if any.
          scope.getExcludes();
          scope.getIncludes();

          // Build the final recurrence string.
          !_.isEmpty(scope.excludes) || !_.isEmpty(scope.includes)
            ? (scope.finalRecurrenceRule =
                scope.recurrenceRule.toString() + scope.rDate + scope.exDate)
            : (scope.finalRecurrenceRule = scope.recurrenceRule.toString());

          scope.excludedDatesString = _(scope.excludes)
            .compact()
            .orderBy()
            .map((date) => moment(date).format('LL'))
            .join(', ');
          scope.includedDatesString = _(scope.includes)
            .compact()
            .orderBy()
            .map((date) => moment(date).format('LL'))
            .join(', ');
        };

        // Calculate the repeat rule for daily frequency.
        scope.calculateDailyRRule = function () {
          // Build the repeat rule.
          if (scope.selectedEndRule === 'never') {
            scope.recurrenceRule = new RRule({
              freq: RRule.DAILY,
              interval: scope.interval ? scope.interval : 1,
              count: undefined,
              until: undefined,
            });
          } else {
            scope.recurrenceRule = new RRule({
              freq: RRule.DAILY,
              interval: scope.interval ? scope.interval : 1,
              count: !scope.until
                ? scope.count
                  ? scope.count
                  : undefined
                : null,
              until: scope.until
                ? moment(scope.until).endOf('day').toDate()
                : null,
            });
          }
        };

        // Calculate the repeat rule for weekly frequency.
        scope.calculateWeeklyRRule = function () {
          // Get the week days (Monday, Tuesday etc.) selected by the user.
          scope.selectedDays = _(scope.weekDays)
            .filter((day) => day.selected)
            .map('value')
            .value();

          // There has to be at least one DayOfWeek selected. If none is selected, choose it based on the event's start date
          if (_.isEmpty(scope.selectedDays)) {
            const dayOfWeekOfStartDate = moment(
              JSON.parse(scope.startDate)
            ).weekday();
            const defaultDayOfTheWeek = _.find(
              scope.weekDays,
              (weekDay) =>
                _.get(weekDay, 'value.weekday') === dayOfWeekOfStartDate
            );

            defaultDayOfTheWeek.selected = true;
            scope.selectedDays.push(defaultDayOfTheWeek.value);
          }

          // Build the repeat rule.
          if (scope.selectedEndRule === 'never') {
            scope.recurrenceRule = new RRule({
              freq: RRule.WEEKLY,
              byweekday: scope.selectedDays.length ? scope.selectedDays : null,
              interval: scope.interval ? scope.interval : 1,
              count: undefined,
              until: undefined,
            });
          } else {
            scope.recurrenceRule = new RRule({
              freq: RRule.WEEKLY,
              byweekday: scope.selectedDays.length ? scope.selectedDays : null,
              interval: scope.interval ? scope.interval : 1,
              count: !scope.until
                ? scope.count
                  ? scope.count
                  : undefined
                : null,
              until: scope.until
                ? moment(scope.until).endOf('day').toDate()
                : null,
            });
          }
        };

        // General function to calculate the repeat rule for monthly frequency.
        scope.calculateMonthlyRRule = function () {
          if (scope.selectedMonthFrequency === 'day_of_month') {
            scope.calculateDayOfMonthRRule();
          } else {
            scope.calculateDayOfWeekRRule();
          }
        };

        // Calculate monthly repeat rule based on the days of the month selected.
        scope.calculateDayOfMonthRRule = function () {
          // Get the month days (1, 2 etc.) selected by the user.
          scope.selected.monthDays = _(scope.monthDays)
            .filter(function (day) {
              return day.selected;
            })
            .map('value')
            .value();

          // Get the months (January, February etc.) selected by the user.
          scope.selectedMonths = _(scope.months)
            .filter(function (month) {
              return month.selected;
            })
            .map('value')
            .value();

          // Build the repeat rule.
          if (scope.selectedEndRule === 'never') {
            scope.recurrenceRule = new RRule({
              freq: RRule.MONTHLY,
              bymonth: scope.selectedMonths.length
                ? scope.selectedMonths
                : null,
              bymonthday: scope.selected.monthDays.length
                ? scope.selected.monthDays
                : null,
              interval: scope.interval ? scope.interval : 1,
              count: undefined,
              until: undefined,
            });
          } else {
            scope.recurrenceRule = new RRule({
              freq: RRule.MONTHLY,
              bymonth: scope.selectedMonths.length
                ? scope.selectedMonths
                : null,
              bymonthday: scope.selected.monthDays.length
                ? scope.selected.monthDays
                : null,
              interval: scope.interval ? scope.interval : 1,
              count: !scope.until
                ? scope.count
                  ? scope.count
                  : undefined
                : null,
              until: scope.until
                ? moment(scope.until).endOf('day').toDate()
                : null,
            });
          }
        };

        // Calculate monthly repeat rule based on the days of the week selected.
        scope.calculateDayOfWeekRRule = function () {
          // Get the week days (Monday, Tuesday etc.) selected by the user.
          scope.selectedWeekDays = _(scope.monthWeekDays)
            .filter(function (day) {
              return day.selected;
            })
            .map('value')
            .value();

          // There has to be at least one DayOfWeek selected. If none is selected, choose it based on the event's start date
          if (_.isEmpty(scope.selectedWeekDays)) {
            const dayOfWeekOfStartDate = moment(
              JSON.parse(scope.startDate)
            ).weekday();
            const defaultDayOfTheWeek = _.find(
              scope.monthWeekDays,
              (weekDay) =>
                _.get(weekDay, 'value.weekday') === dayOfWeekOfStartDate
            );

            defaultDayOfTheWeek.selected = true;
            scope.selectedWeekDays.push(defaultDayOfTheWeek.value);
          }

          // Add the ordinal to the selected week days.
          scope.selectedWeekDays = _.map(scope.selectedWeekDays, (day) =>
            day.nth(scope.selectedOrdinal.value)
          );

          // Get the months (January, February etc.) selected by the user.
          scope.selectedMonths = _(scope.months)
            .filter(function (month) {
              return month.selected;
            })
            .map('value')
            .value();

          // Build the repeat rule.
          if (scope.selectedEndRule === 'never') {
            scope.recurrenceRule = new RRule({
              freq: RRule.MONTHLY,
              bymonth: scope.selectedMonths.length
                ? scope.selectedMonths
                : null,
              byweekday: scope.selectedWeekDays.length
                ? scope.selectedWeekDays
                : null,
              interval: scope.interval ? scope.interval : 1,
              count: undefined,
              until: undefined,
            });
          } else {
            scope.recurrenceRule = new RRule({
              freq: RRule.MONTHLY,
              bymonth: scope.selectedMonths.length
                ? scope.selectedMonths
                : null,
              byweekday: scope.selectedWeekDays.length
                ? scope.selectedWeekDays
                : null,
              interval: scope.interval ? scope.interval : 1,
              count: !scope.until
                ? scope.count
                  ? scope.count
                  : undefined
                : null,
              until: scope.until
                ? moment(scope.until).endOf('day').toDate()
                : null,
            });
          }
        };

        // Calculate the repeat rule for yearly frequency.
        scope.calculateYearlyRRule = function () {
          // Build the repeat rule.
          if (scope.selectedEndRule === 'never') {
            scope.recurrenceRule = new RRule({
              freq: RRule.YEARLY,
              interval: scope.interval ? scope.interval : 1,
              count: undefined,
              until: undefined,
            });
          } else {
            scope.recurrenceRule = new RRule({
              freq: RRule.YEARLY,
              interval: scope.interval ? scope.interval : 1,
              count: !scope.until ? (scope.count ? scope.count : 30) : null,
              until: scope.until
                ? moment(scope.until).endOf('day').toDate()
                : null,
            });
          }
        };

        // Initialize the selected days from the weekly frequency.
        scope.initFromWeeklyRule = function () {
          let ruleSelectedDays = _.map(
            scope.recurrenceRule.origOptions.byweekday,
            'weekday'
          );

          _.each(scope.weekDays, function (weekDay) {
            weekDay.selected = _.includes(
              ruleSelectedDays,
              weekDay.value.weekday
            );
          });

          scope.calculateRRule();
        };

        // Initialize the selected days from the monthly frequency.
        scope.initFromMonthlyRule = function () {
          let ruleMonths = _.isArray(scope.recurrenceRule.origOptions.bymonth)
            ? scope.recurrenceRule.origOptions.bymonth
            : [scope.recurrenceRule.origOptions.bymonth];

          _.each(scope.months, function (month) {
            month.selected = _.includes(ruleMonths, month.value);
          });

          if (
            scope.recurrenceRule.origOptions.bymonthday ||
            _.size(scope.recurrenceRule.options.bynmonthday)
          ) {
            scope.initFromMonthDays();
          } else if (
            scope.recurrenceRule.origOptions.byweekday ||
            _.size(scope.recurrenceRule.options.bynweekday)
          ) {
            scope.initFromMonthWeekDays();
          }
        };

        // Initialize the selected month days from the monthly frequency.
        scope.initFromMonthDays = function () {
          let ruleMonthDays = _.isArray(
            scope.recurrenceRule.origOptions.bymonthday
          )
            ? scope.recurrenceRule.origOptions.bymonthday
            : [scope.recurrenceRule.origOptions.bymonthday];

          scope.selectedMonthFrequency = 'day_of_month';

          _.each(scope.monthDays, function (monthDay) {
            monthDay.selected = _.includes(ruleMonthDays, monthDay.value);
          });

          if (
            _.size(scope.recurrenceRule.options.bynmonthday) > 0 &&
            scope.recurrenceRule.options.bynmonthday[0] === -1
          ) {
            scope.monthDays[31].selected = true;
          }
        };

        // Initialize the selected week days from the monthly frequency.
        scope.initFromMonthWeekDays = function () {
          let ruleSelectedWeekDays = _.map(
            scope.recurrenceRule.origOptions.byweekday,
            'weekday'
          );

          scope.selectedMonthFrequency = 'day_of_week';

          _.each(scope.monthWeekDays, function (weekDay) {
            weekDay.selected = _.includes(
              ruleSelectedWeekDays,
              weekDay.value.weekday
            );
          });
        };

        // Parse the recurrence rule.
        scope.parseRule = function (rRuleString) {
          scope.recurrenceRule = RRule.fromString(rRuleString);
          if (
            scope.recurrenceRule.options.count &&
            !scope.recurrenceRule.options.until
          ) {
            scope.selectedEndRule = 'after';
          } else if (
            !scope.recurrenceRule.options.count &&
            !scope.recurrenceRule.options.until
          ) {
            scope.selectedEndRule = 'never';
          } else if (scope.recurrenceRule.options.until) {
            scope.selectedEndRule = 'on';
          }

          if (!_.isEmpty(scope.recurrenceRule.options.bymonthday)) {
            scope.selectedMonthFrequency = 'day_of_month';
            scope.selected.monthDays = scope.recurrenceRule.options.bymonthday;
          } else if (!_.isEmpty(scope.recurrenceRule.origOptions.byweekday)) {
            scope.selectedMonthFrequency = 'day_of_week';
            scope.selectedWeekDays = scope.recurrenceRule.origOptions.byweekday;
            scope.selectedOrdinal = _.find(scope.ordinals, {
              value: scope.recurrenceRule.origOptions.byweekday[0].n,
            });
          }

          scope.interval = scope.recurrenceRule.options.interval;
          scope.count = scope.recurrenceRule.options.count;
          scope.until = scope.recurrenceRule.options.until;
          scope.selectedMonths = scope.recurrenceRule.options.bymonth;

          scope.selectedDays = _.map(
            scope.recurrenceRule.options.byweekday,
            function (day) {
              return { weekday: day };
            }
          );

          scope.selectedFrequency = _.filter(
            scope.frequencies,
            function (frequency) {
              return frequency.rruleType === scope.recurrenceRule.options.freq;
            }
          )[0];

          switch (scope.selectedFrequency.type) {
            case 'week':
              scope.initFromWeeklyRule();
              break;
            case 'month':
              scope.initFromMonthlyRule();
              break;
          }

          scope.calculateRRule();
        };

        // =============================================================================
        // Include and exclude dates from the recurrence.
        // =============================================================================

        // Initialize the models. Must be an array with an empty string to begin with.
        scope.excludeModel = [''];
        scope.includeModel = [''];

        // Create another input field.
        scope.addAnother = function (context) {
          if (context === 'exclude') {
            scope.excludeModel.push('');
          } else {
            scope.includeModel.push('');
          }
        };

        // Get the excluded dates.
        scope.getExcludes = function () {
          // Define a new array to hold the ISO strings.
          scope.excludes = [];

          if (!_.isEmpty(scope.excludeModel)) {
            _.each(scope.excludeModel, function (exclude) {
              if (exclude) {
                // Convert every date object to an ISO string and add to new array.
                scope.excludes.push(
                  dateToString(exclude && exclude.toISOString())
                );
              }
            });
          }

          // Build the string to combine with RRule.
          scope.exDate =
            !_.isEmpty(scope.excludeModel) && scope.excludeModel[0] !== ''
              ? '&EXDATE:' + scope.excludes.toString()
              : '';
        };

        // Get the included dates.
        scope.getIncludes = function () {
          // Define a new array to hold the ISO strings.
          scope.includes = [];

          if (!_.isEmpty(scope.includeModel)) {
            _.each(scope.includeModel, function (include) {
              if (include) {
                // Convert every date object to an ISO string and add to new array.
                scope.includes.push(
                  dateToString(include && include.toISOString())
                );
              }
            });
          }

          // Build the string to combine with RRule.
          scope.rDate =
            !_.isEmpty(scope.includeModel) && scope.includeModel[0] !== ''
              ? '&RDATE:' + scope.includes.toString()
              : '';
        };

        // Function to get the excluded or included dates from an existing rrule.
        scope.parseExcludesAndIncludes = function () {
          // Get excluded dates from an existing rrule.
          if (
            JSON.parse(scope.excludedDates) !== '' &&
            JSON.parse(scope.excludedDates)[0] !== ''
          ) {
            let exclDates = JSON.parse(scope.excludedDates);
            _.each(exclDates, function (date, index) {
              scope.excludeModel[index] = rruleFactory.repeatStringToDate(date);
            });
          }

          // Get included dates from an existing rrule.
          if (
            JSON.parse(scope.includedDates) !== '' &&
            JSON.parse(scope.includedDates)[0] !== ''
          ) {
            let inclDates = JSON.parse(scope.includedDates);
            _.each(inclDates, function (date, index) {
              scope.includeModel[index] = rruleFactory.repeatStringToDate(date);
            });
          }
        };

        // =============================================================================
        // Initialize the directive.
        // =============================================================================
        scope.init();
      },
    };
  }

  angular.module('cdApp.shared').directive('cdRrule', cdRrule);
})();
