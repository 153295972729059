import { Form } from 'antd';
import { useEffect } from 'react';

import { useStandardWorkingHours } from '../store/timeRegistrationHooks';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  ModalType,
  SetModalPropsType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export default function useStandardWorkingHoursModal(
  setModalProps: SetModalPropsType
) {
  const [form] = Form.useForm();
  const { standardWorkingHoursData, mutateStandardWorkingHours } =
    useStandardWorkingHours();

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Edit your standard working hours'),
      okText: gettextCatalog.getString('Save'),
      width: 500,
      onOk: async () =>
        form.validateFields().then(async (values) => {
          if (values.standardWorkingHours) {
            await mutateStandardWorkingHours(values.standardWorkingHours);
          }
        }),
    });
  }, [setModalProps, form, mutateStandardWorkingHours]);

  const initialValues = {
    standardWorkingHours: {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      ...standardWorkingHoursData,
    },
  };

  return {
    initialValues,
    form,
  };
}
