import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { Drawer, DrawerProps } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { Suspense } from 'react';

import CdrPageLoader from '../CdrPageLoader';

export interface InnerDrawerProps extends DrawerProps {
  title?: string;
  width?: number;
  onOk?: () => void;
  fallback?: React.ReactNode;
}

interface InnerDrawerType {
  setDrawerProps?: React.Dispatch<React.SetStateAction<InnerDrawerProps>>;
}

export type createCdDrawerProps<T> = {
  modalName: string;
  InnerDrawer: FunctionComponent<T & InnerDrawerType>;
  styles?: Record<string, any>;
  initialProps?: Partial<InnerDrawerProps>;
};

/**
 * Wrapper than exposes a drawer and surrounds the child component with a suspense component.
 */
export const createCdDrawer = function <T extends Record<string, unknown>>({
  modalName,
  InnerDrawer,
  initialProps = {},
}: createCdDrawerProps<T>) {
  return NiceModal.create<T>((callingProps) => {
    const modal = useModal(modalName);
    const [drawerProps, setDrawerProps] = useState<InnerDrawerProps>({
      title: '',
      width: 800,
      ...initialProps,
    });

    return (
      <Drawer {...antdDrawerV5(modal)} {...drawerProps} destroyOnClose>
        <ErrorBoundary fallback={<>Error</>}>
          <Suspense fallback={drawerProps.fallback || <CdrPageLoader />}>
            <InnerDrawer setDrawerProps={setDrawerProps} {...callingProps} />
          </Suspense>
        </ErrorBoundary>
      </Drawer>
    );
  });
};
