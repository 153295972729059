import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import ElementWrapper from './ElementWrapper';

const GepDisclaimer: React.FC = () => (
  <React.Fragment>
    <ElementWrapper>
      <FontAwesomeIcon icon={faInfoCircle} />
      <span>
        Freie Nutzung im Rahmen des Abonnements &quot;Gemeindebrief Plus
        Online&quot; für nichtkommerzielle Publikationen (zum Beispiel
        Gemeindebriefe) und auf der Homepage der Kirchengemeinde. Bitte
        beachten: Nicht lizenziert für Social-Media-Netzwerke.
      </span>
    </ElementWrapper>
  </React.Fragment>
);

export default GepDisclaimer;
