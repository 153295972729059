import { mainApi } from '@/react/api';
import cdApp from '@/react/config';

class Api {
  isGermanCustomer() {
    return cdApp.organization.locale.language.toLocaleLowerCase() === 'de';
  }
}

// Create a singleton instance
const apiInstance = new Api();

const API = mainApi;

export { apiInstance, API };
