import moment from 'moment';

export enum WidgetType {
  EVENT = 'event',
  BLOG = 'blog',
  SLIDESHOW = 'slideshow',
  DONATION = 'donation',
  PORTAL_EVENT = 'portalevent',
  PORTAL_BLOG = 'portalblog',
  PORTAL_EVENT_SEARCH = 'portaleventsearch',
}
export interface TimeRangeInterface {
  start: moment.Moment;
  end: moment.Moment;
}
export interface WidgetInterface {
  id?: number;
  name: string;
  type: WidgetType;
  created: string;
  legacy?: boolean;
  permissions?: {
    canEdit: boolean;
    canDelete: boolean;
  };
  organizationId?: number;
  customizeColorScheme?: boolean;
  configuration: {
    categoryIds: number[];
    resourceIds: number[];
    includeFrom?: string;
    collaboration: {
      portalId: string;
      portalCategoryIds: number[];
      organizationIds: number[];
    };
    pageSize: number;
    imageId?: string;
    fallbackImageId?: number;
    itemsPerRow?: number;
    showImage?: boolean;
    showUserFiltering?: boolean;
    showShadow?: boolean;
    showLocationAddress?: boolean;
    showRoundedCorners?: boolean;
    primaryColor?: string;
    cardColor?: string;
    font?: string;
    image?: object;
    timeRange?: number | TimeRangeInterface;
    timeRangeType?: string;
  };
}

export interface WebsiteAnalyticsInterface {
  siteId: string;
  sharedLink: string;
  domain: string;
}
