import { Modal, Spin, Button, Typography } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import { gettextCatalog } from '../../services/I18nService';
import PortalService from '../../portal/services/portal.service';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { CdCopyIcon } from '../../shared/components/Icons';

import cdApp from '@/react/config';

const { Paragraph } = Typography;

const Container = styled.div`
  margin: 8px 32px 0 48px;
`;
export const GetCodeDonationWidgetModal = NiceModal.create(
  ({ alias }: { alias: string }) => <GetCodeDonationWidget alias={alias} />
);

interface GetCodeDonationWidgetProps {
  alias: string;
}

export const GetCodeDonationWidget: FunctionComponent<
  GetCodeDonationWidgetProps
> = ({ alias }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [codeValue, setCodeValue] = useState<string>('');
  const modal = useModal('GetCodeDonationWidget');
  const language = cdApp.organization.locale.language;
  const organizationId = cdApp.organization.id;
  const close = modal.remove;
  const [buttonText, setButtonText] = useState(
    gettextCatalog.getString('Copy code')
  );

  const getDonationWidgetCode = () => {
    PortalService.getDonationWidgetCode({ organizationId, alias, language })
      .then((data) => {
        // Remove first and last line ending.
        setCodeValue(data.replace(/^\s+|\s+$/g, ''));
      })
      .catch(ErrorHandlingService.handleError)
      .finally(() => setLoading(false));
  };

  // eslint-disable-next-line
  useEffect(getDonationWidgetCode, []);
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        width: 800,
        title: gettextCatalog.getString(`Copy code`),
        bodyStyle: { padding: 0 },
        onCancel: close,
        cancelText: gettextCatalog.getString('Close'),
        okButtonProps: { style: { display: 'none' } },
      }}
    >
      <Container>
        <Paragraph>
          {gettextCatalog.getString(
            'Insert the widget on your website by copying this code and pasting it into the HTML of your site.'
          )}
        </Paragraph>
        <Spin spinning={isLoading}>
          <Paragraph code style={{ whiteSpace: 'pre' }}>
            {codeValue}
          </Paragraph>
        </Spin>
        <CopyToClipboard
          text={codeValue}
          onCopy={() => {
            setButtonText(gettextCatalog.getString('Copied'));
          }}
        >
          <Button style={{ margin: '0px 0px 10px 2px' }}>
            <CdCopyIcon />
            {buttonText}
          </Button>
        </CopyToClipboard>
      </Container>
    </Modal>
  );
};
