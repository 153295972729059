import { combineReducers } from 'redux';

import IntentionFeeReducer, {
  ImmutableIntentionFeeState,
} from './intention-fees/fees';
import IntentionReportTemplateReducer, {
  ImmutableIntentionReportTemplateState,
} from './intention-report-templates/report-templates';

// State combination

export interface SettingsCombinedState {
  fee: ImmutableIntentionFeeState;
  reportTemplate: ImmutableIntentionReportTemplateState;
}

export default combineReducers<SettingsCombinedState>({
  fee: IntentionFeeReducer,
  reportTemplate: IntentionReportTemplateReducer,
});
