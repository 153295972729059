import React from 'react';
import { Button, Card, Col, Dropdown, Row, Space, Typography } from 'antd';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';

import { gettextCatalog } from '../../services/I18nService';
import { CdMigratedWebsite } from '../../shared/components/Icons';

export const MigratedWebsiteCard = () => (
  <Card
    hoverable
    style={{ width: 270 }}
    bodyStyle={{ padding: '0px' }}
    cover={
      <div
        style={{
          width: 270,
          height: 216,
          background: '#4f4f4f',
          display: 'flex',
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Row style={{ marginTop: '16px' }}>
          <Col
            span={24}
            style={{
              textAlign: 'center',
            }}
          >
            <CdMigratedWebsite
              size="3x"
              style={{ color: 'white', height: '80px' }}
            />
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              <Col span={16} style={{ textAlign: 'center' }}>
                <Typography.Title
                  level={5}
                  style={{ marginTop: '0px', color: 'white' }}
                >
                  {gettextCatalog.getString('Your upcoming migrated website')}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }
    actions={[
      <Button type="text" disabled={true}>
        {gettextCatalog.getString('View site')}
      </Button>,
      <Dropdown disabled={true}>
        <Button disabled={true} type="text">
          <Space>
            {gettextCatalog.getString('Actions')}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>,
    ]}
  />
);
