import * as _ from 'lodash';
import { ImmutableArray } from 'seamless-immutable';

import { Category, CategoryType } from '../models/category';

export const getCategoriesByType = (
  categories: ImmutableArray<Category>,
  type: CategoryType
): Category[] => {
  const _categories = categories ? categories.asMutable({ deep: true }) : [];
  const filteredCategories = _.filter(_categories, ['type', type]);
  return _.sortBy(filteredCategories, 'name');
};
