'use strict';

function PeopleMessageEditorController(
  $transitions,
  $state,
  $stateParams,
  $scope,
  $q,
  $uibModal,
  gettextCatalog,
  toastr,
  PeopleMessages,
  People,
  PeopleSegments,
  Resources,
  _,
  $filter,
  dateFormatsLookup,
  smsService,
  cdExitPrompt,
  imagePickerFactory,
  $timeout,
  Me
) {
  let $ctrl = this;

  /**
   * Lifecycle hook used for initialization work
   */
  $ctrl.$onInit = function () {
    $ctrl.church = PeopleMessages.churchInformation;
    $ctrl.autosave = autosave;
    $ctrl.allowSaveAndSend = true;
    $ctrl.filterRecipients = filterRecipients;
    $ctrl.cdExitPrompt = cdExitPrompt;
    $ctrl.dateFormat = dateFormatsLookup.getFormat();
    $ctrl.message.to = trackRecipient($ctrl.message.to);
    $ctrl.showChurchSelector = cdApp.showChurchSelector;
    $ctrl.messageRecipients = _.map(
      trackRecipient($ctrl.message.to),
      'track'
    ).join(',');
    $q.all([
      Resources.getChurchesV2({
        permissionContext: 'people',
        permissionType: 'communicate',
      }).$promise,
      PeopleSegments.query().$promise,
    ]).then(([{ churches }, segments]) => {
      $ctrl.churches = churches;
      if (!$ctrl.showChurchSelector && !$ctrl.message.id) {
        // If this is a new message, add it to all churches
        $ctrl.message.churches = $ctrl.churches;
      }
      $ctrl
        .refreshRecipients()
        .then(() => {
          // Remove parish list from non multi church
          if (!$ctrl.showChurchSelector) {
            segments = _.reject(segments, { filteringType: 'church' });
          }

          $ctrl.trackedSegments = _.orderBy(trackRecipient(segments), [
            'filteringType',
            'name',
          ]);

          // Update the people count
          $ctrl.message.to = trackRecipient($ctrl.message.to);
          smsService
            .init($ctrl.message, segments)
            .then(({ exceedsBalance }) => {
              $ctrl.exceedsBalance = exceedsBalance;
            });
        })
        .catch((err) => {
          $ctrl.recipients = [];
          throw err;
        });
    });

    if ($stateParams.id) {
      $ctrl.message.forms = $ctrl.messageForms;
      $ctrl.statusColors = PeopleMessages.getStatusColors($ctrl.message.type);

      if ($stateParams.isSentMessage) {
        $ctrl.message.sent = $ctrl.message.scheduled;
      }

      watchTitleAndContent();
    } else {
      watchTitleAndContent();
    }

    window.addEventListener('message', receivedMessage);

    // Initiate calculating of message recipients in case this was a copied message
    $ctrl.calculateRecipients();
  };

  $ctrl.$onDestroy = function () {
    window.removeEventListener('message', receivedMessage);
  };

  $ctrl.searchPeople = (filter = undefined, limit = 30) =>
    People.searchPeople({
      filter,
      limit,
      searchAfter: null,
      orderBy: 'fullName',
      orderDirection: 'asc',
    });

  $ctrl.refreshRecipients = (query) => {
    if (!$ctrl.churches || $ctrl.churches.length === 0) return;
    const filter = {
      comparison: 'OR',
      churchIds: _.map($ctrl.churches, 'id'),
      filters: [
        {
          type: 'text',
          property: 'fullName',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'email',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'phone',
          operator: 'like',
          value: query,
        },
      ],
    };

    $ctrl.peopleSearchLoading = true;
    return $ctrl
      .searchPeople(filter)
      .$promise.then(({ people }) => {
        const trackedPeople = _.orderBy(trackRecipient(people), 'name');
        $ctrl.recipients = _.concat($ctrl.trackedSegments, trackedPeople);
        $ctrl.peopleSearchLoading = false;
        return;
      })
      .catch((err) => {
        $ctrl.peopleSearchLoading = false;
        throw err;
      });
  };

  /**
   * Open a modal to search for and add content to the message
   *
   * @param {string} type - The type of the content that will be added to the message
   *                        Possible values: 'events', 'news', 'forms'
   */
  $ctrl.addContent = function (contentType) {
    $uibModal
      .open({
        component: 'cdPeopleMessageAddContentModal',
        resolve: {
          contentType: _.constant(contentType),
          content: function () {
            return _.get($ctrl.message, contentType);
          },
        },

        windowClass: 'modal-ui-select',
        backdrop: 'static',
        size: 'lg',
      })
      .result.then(function (newContent) {
        $ctrl.message[contentType] = $ctrl.message[contentType] || [];
        $ctrl.message[contentType] =
          $ctrl.message[contentType].concat(newContent);
        $ctrl.autosave();
      });
  };

  /**
   * Open a modal to search for content and insert (or edit) link to the message
   *
   * @param {Object} link - if provided, it's the link that should be edited
   */
  $ctrl.openInsertLinkModal = function (link) {
    // get a reference of the message editor's iframe window
    let messageIframe = document.getElementById('iframe');
    let editorWindow = messageIframe.contentWindow;

    $uibModal
      .open({
        component: 'cdPeopleMessageInsertLinkModal',
        windowClass: 'modal-ui-select',
        resolve: {
          link: function () {
            return link || { text: '', url: '' };
          },
        },
      })
      .result.then(
        function (newLink) {
          // post a message to be redactor's linkit plugin with the link to add
          editorWindow.postMessage(
            {
              message: 'REDACTOR_INSERT_LINK',
              link: {
                text: newLink.text,
                url: newLink.url,
                target: false,
              },
            },

            window.location.origin
          );
        },
        () => {
          // post a message to be redactor's linkit plugin
          editorWindow.postMessage(
            {
              message: 'REDACTOR_INSERT_LINK_CANCELLED',
            },

            window.location.origin
          );
        }
      );
  };

  /**
   * When the message-editor's iframe tries to communicate with the app
   */
  function receivedMessage(event) {
    if (event.origin !== window.location.origin) return;
    switch (_.get(event, 'data.message')) {
      case 'OPEN_INSERT_LINK_MODAL':
        $timeout(function () {
          $ctrl.openInsertLinkModal(_.get(event, 'data.link'));
        });
        break;
      default:
        break;
    }
  }

  /**
   * Open a modal to schedule a message for sending it later
   */
  $ctrl.scheduleMessage = function () {
    if (!$ctrl.message.canSend()) return;

    $uibModal
      .open({
        component: 'cdPeopleMessageScheduleMessageModal',
        windowClass: 'modal-ui-select',
        resolve: {
          scheduleDate: () => $ctrl.message.scheduled,
        },
      })
      .result.then((scheduleDate) => {
        // Send the message when closing and confirming the message schedule modal
        $ctrl.message.send(scheduleDate);
      });
  };

  /**
   * Helper object holding methods for various message actions
   */
  $ctrl.action = {
    send() {
      return $ctrl
        .autosave(true)
        .finally(_.bind($ctrl.message.send, $ctrl.message));
    },

    test() {
      return $ctrl
        .autosave(true)
        .finally(_.bind($ctrl.message.test, $ctrl.message));
    },

    delete() {
      if (!$ctrl.message.id) return;
      $ctrl.message.$delete(() => {
        // Mark the message deleted to avoid auto saving.
        $ctrl.messageDeleted = true;
        $state.go('app.private.people.messages.list');
        toastr.success(
          gettextCatalog.getString('Message successfully deleted.')
        );
      });
    },

    duplicate() {
      const payload = _.omit($ctrl.message, [
        'id',
        'title',
        'created',
        'scheduled',
        'sent',
        'sentBy',
        'statistics',
        'organization',
        'organizationId',
      ]);

      const copyString = gettextCatalog.getString('(copy)');

      if ($ctrl.message.type === 'email') {
        payload.title = `${$ctrl.message.title} ${copyString}`;
      } else {
        payload.content = `${copyString} ${payload.content}`;
      }

      new PeopleMessages(payload).$save(({ id }) => {
        $state.go('app.private.people.messages.view', { id });
        toastr.info(gettextCatalog.getString('Message duplicated.'));
      });
    },

    pickImage() {
      imagePickerFactory((data) => {
        $ctrl.message.picture = {
          id: data.file.id,
          url: data.file.fileInfo.azul.url,
        };

        $ctrl.autosave();
      }).open();
    },

    cropImage() {
      if (!$ctrl.message.picture) return;

      const context = `people-message-${$ctrl.message.id}`;
      const imageId = $ctrl.message.picture.id;
      $uibModal
        .open({
          component: 'cdImageCropModal',
          resolve: {
            contextId: () => $ctrl.message.id,
            contextType: () => 'people-message',
            file: [
              'Files',
              function (Files) {
                return Files.get({ id: imageId }).$promise;
              },
            ],
            bounds: [
              'Files',
              function (Files) {
                return Files.getCropInformation({ id: imageId, context })
                  .$promise;
              },
            ],
          },
        })
        .result.then(() => {
          PeopleMessages.get({ id: $ctrl.message.id }).$promise.then(
            ({ picture }) => {
              $ctrl.message.picture = picture;
              toastr.success(gettextCatalog.getString('Content saved.'));
            }
          );
        });
    },

    removeImage() {
      $ctrl.message.picture = null;
    },

    print() {
      const iframe = _.get(window, 'frames.iframe');
      iframe.print();
    },

    remove(contentType, event) {
      _.remove(
        $ctrl.message[contentType],
        (entity) =>
          entity.id &&
          event.currentTarget.dataset.id &&
          entity.id.toString() === event.currentTarget.dataset.id.toString()
      );

      $ctrl.autosave();
    },
  };

  /**
   * Get the url of a state
   *
   * @param {String} name - The name of the state
   * @param {Object} params - The state parameters
   */
  $ctrl.getStateUrl = function (name, params) {
    return $state.href(name, params);
  };

  /**
   * Manually save the current state of the message
   *
   * Timeout is use to give the illusion that the message is being saved,
   * because it is simply too fast and it appears as if nothing is happening
   *
   * @param {Number} delay - How long the saving should be delayed (in ms)
   */
  $ctrl.manualSave = (delay = 250) => {
    $ctrl.saving = true;
    const fn = _.bind(autosave, this, true);

    $timeout(fn, delay).then(() => {
      $ctrl.saving = false;
    });
  };

  /**
   * Save the current state of the message on the fly
   *
   * @param {Function} procedure
   * @param {Boolean} forceSave - Bypass all checks and force a save
   */
  function autosave(showErrors = false, procedure) {
    // check if it is a anniversary message
    const anniversary = $ctrl.message?.sourceInfo?.anniversary;
    if (anniversary) {
      $ctrl.message.anniversary = anniversary;
      $ctrl.message.sourceInfo = undefined;
    }
    // Register the callback to update content from the editor.
    // On save this callback will invoke the editor to render its content.
    if (typeof procedure === 'function') {
      return ($ctrl.autosave.procedure = procedure);
    }
    // Return if churches are not loaded yet
    if ($ctrl.messageDeleted || _.isEmpty($ctrl.churches)) {
      return $q((resolve) => resolve());
    }

    if ($ctrl.message.sent || !$ctrl.message.hasChanged()) {
      return $q((resolve) => resolve());
    }

    // make sure the backend knows who the author is
    if (!$ctrl.message.author) {
      $ctrl.message.author = { id: Me.id };
    }

    if (messageContainsIframe()) {
      $ctrl.allowSaveAndSend = false;
      toastr.error(
        gettextCatalog.getString(
          'You can not embed an iframe (i.e. video, form) within a newsletter'
        )
      );

      return $q((resolve, reject) => reject());
    }

    if ($ctrl.showChurchSelector && _.size($ctrl.message.churches) < 1) {
      if (showErrors) {
        toastr.error(
          gettextCatalog.getString("The 'Share with' field is required")
        );
      }
      return $q((resolve, reject) => reject());
    }

    $ctrl.allowSaveAndSend = true;

    if (typeof $ctrl.autosave.procedure === 'function') {
      return $ctrl.autosave
        .procedure()
        .finally(_.bind($ctrl.message.$save, $ctrl.message));
    } else {
      return $ctrl.message.$save();
    }
  }

  function messageContainsIframe() {
    return $ctrl.message && $ctrl.message.content.includes('<iframe');
  }

  /**
   * Modify the recipient objects in order to allow tracking by group and id
   *
   * @param recipient {Object} - The recipient object(s) to be modified
   */
  function trackRecipient(recipient) {
    const isList = _.has(recipient, 'setup');

    if (_.isArray(recipient)) {
      return _.map(recipient, trackRecipient);
    }
    if (_.isArray(recipient.fields)) {
      // Multiple contacts from the people list, get the name from the fields
      const firstName = _.find(recipient.fields, {
        property: 'firstName',
      }).value;
      const lastName = _.find(recipient.fields, { property: 'lastName' }).value;
      const email = _.find(recipient.fields, { property: 'email' }).value;
      const name = _.join([firstName, lastName], ' ');
      recipient.name = $filter('getName')({ name, email });
    } else {
      // Single list or single contact
      recipient.name = isList ? recipient.name : $filter('getName')(recipient);
    }

    recipient.group = isList ? 'lists' : 'contacts';
    recipient.track = recipient.group + ':' + recipient.id;

    // Update the people count in the segment
    // Count is zero if the list is part of the selected recipients.
    if (isList && !recipient.peopleCount) {
      const segment = _.find($ctrl.trackedSegments, { id: recipient.id });
      recipient.peopleCount = (segment && segment.peopleCount) || 0;
    }

    return recipient;
  }

  /**
   * Filter the list of recipients
   *
   * @param {Object} recipient
   */
  const filterRecipients = () => (recipient) => {
    if (recipient.setup || !$ctrl.message.type) {
      return true;
    }
    return $ctrl.message.type === 'sms'
      ? !_.isEmpty(recipient.phone)
      : !_.isEmpty(recipient.email);
  };

  /**
   * Register watchers to trigger an autosave whenever the message's title or content changes
   */
  function watchTitleAndContent() {
    $scope.$watchGroup(
      ['$ctrl.message.title', '$ctrl.message.content'],
      function (newVal, oldVal) {
        let titleChanged = oldVal[0] !== newVal[0];
        let contentChanged = oldVal[1] !== newVal[1];

        let newTitle = newVal[0];
        if (_.includes(newTitle, '\n')) {
          // replace new lines with spaces
          $ctrl.message.title = newTitle.replace(/\n/g, ' ');
        }
        if (
          $ctrl.message.type === 'email' &&
          (titleChanged || contentChanged)
        ) {
          $ctrl.autosave && $ctrl.autosave();
        }
      }
    );
  }

  /**
   * Transition hook invoked when exiting the state. Use for autosaving the message upon leaving
   */
  $transitions.onExit(
    {
      exiting: $state.current.name,
    },

    function () {
      return $ctrl
        .autosave(true)
        .then(() => true)
        .catch(() => $ctrl.cdExitPrompt.prompt());
    }
  );

  // Shown when the chosen number of recipients exceeds the SMS balance of the organization
  $ctrl.exceedingBalanceString = gettextCatalog.getString(
    'You are trying to send more SMS messages than what is left on your balance. Please contact support@churchdesk.com to purchase more SMS messages.'
  );

  /**
   * Update the sender of the message
   *
   * @param {String="user,church"} senderKey
   */
  $ctrl.setSender = (senderKey) => {
    $ctrl.message.from = senderKey;
    autosave();
  };

  $ctrl.calculateRecipients = () => {
    const segments = _.filter(
      $ctrl.message.to,
      (recipient) => recipient.group === 'lists'
    );

    const personIds = _.map(
      _.filter($ctrl.message.to, (recipient) => recipient.group !== 'lists'),
      'id'
    );

    if (
      $ctrl.calculateRecipientsPromise &&
      !$ctrl.calculateRecipientsPromise.$resolved
    ) {
      $ctrl.calculateRecipientsPromise.$cancelRequest();
    }
    $ctrl.calculateRecipientsPromise = PeopleMessages.calculateRecipients({
      segments,
      personIds,
      type: $ctrl.message.type,
    });

    $ctrl.calculateRecipientsPromise.$promise.then(
      ({ totalRecipients, inActiveRecipients, activeRecipients }) => {
        // totalRecipients = activeRecipients + inActiveRecipients
        $ctrl.totalRecipients = totalRecipients;
        $ctrl.inActiveRecipients = inActiveRecipients;
        $ctrl.activeRecipients = activeRecipients;
      }
    );
  };

  $ctrl.getMessageSummary = () =>
    gettextCatalog.getString('{{count}} total recipients', {
      count: $ctrl.activeRecipients,
    });

  $ctrl.selectAllChurches = () => {
    $ctrl.message.churches = $ctrl.churches;
  };

  $ctrl.clearAllChurches = () => {
    $ctrl.message.churches = [];
  };

  $ctrl.filterAlreadySelected = (church) =>
    !_.includes(_.map($ctrl.message.churches, 'id'), church.id);
}

PeopleMessageEditorController.$inject = [
  '$transitions',
  '$state',
  '$stateParams',
  '$scope',
  '$q',
  '$uibModal',
  'gettextCatalog',
  'toastr',
  'PeopleMessages',
  'People',
  'PeopleSegments',
  'Resources',
  '_',
  '$filter',
  'dateFormatsLookup',
  'smsService',
  'cdExitPrompt',
  'imagePickerFactory',
  '$timeout',
  'Me',
];

angular.module('cdApp.people').component('cdPeopleMessageEditorState', {
  templateUrl: '@/app/people/message-editor/message-editor.component.html',
  bindings: {
    message: '<',
    messageForms: '<',
  },

  controller: PeopleMessageEditorController,
});
