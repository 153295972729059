'use strict';

angular.module('cdApp.calendar').service('calendarPollerService', [
  'moment',
  '$state',
  'Calendar',
  'poller',
  'toastr',
  'gettextCatalog',
  function (moment, $state, Calendar, poller, toastr, gettextCatalog) {
    'ngInject';

    // Turn the poller on/off
    this.enabled = false;

    // Private variables
    let rangeStart;
    let rangeEnd;
    let viewRenderedAt;
    let calendarCacheInstance;

    /**
     * Initialize the poller
     */
    const init = () => {
      /**
       * Create the poller, if it hasn't been created already
       */
      this.poller = poller.get(Calendar, {
        action: 'checkForUpdates',
        delay: 15000, // 15 seconds
        smart: true,
        argumentsArray: () => [
          {
            start: rangeStart.toISOString(),
            end: rangeEnd.toISOString(),
          },
        ],
      });

      /**
       * When a request is completed, determine whether we continue polling or inform the user of the changes
       */
      this.poller.promise.then(null, null, (res) => {
        if (!res.$resolved) {
          this.stop();
        }

        // We consider the calendar has been updated in the background if the last updated event
        // is after the date the current calendar view was initially rendered
        const calendarHasBeenUpdated = res.updatedAt
          ? moment(res.updatedAt).isAfter(viewRenderedAt)
          : false;

        // Inform the user the calendar has been updated in the background, so they can refresh if they choose so
        if (calendarHasBeenUpdated) {
          toastr.info(
            gettextCatalog.getString(
              'The calendar has recently been updated. Click here to refresh.'
            ),

            {
              allowHtml: true,
              closeButton: true,
              timeOut: 0,
              extendedTimeOut: 0,
              onTap: () => $state.reload(),
            }
          );

          // Clear the calendar cache so next time we refresh or navigate, we get fresh data
          calendarCacheInstance.removeCache(rangeStart, rangeEnd);

          // Stop the poller after informing the user, no reason to keep running it
          // for the current calendar view. We restart it anyway if they change the view
          this.stop();
        }
      });
    };

    /**
     * Update poller arguments, without restarting the poller
     *
     * @param {Date} start - A Moment that is the first visible day in the current calendar view
     * @param {Date} end - A Moment that is the exclusive last visible day in the current calendar view
     * @param {Object} calendarCache - The calendar cache instance that will be used to clear the cache
     */
    this.update = ({ start, end, calendarCache }) => {
      if (!this.enabled) return;

      viewRenderedAt = moment();
      rangeStart = start;
      rangeEnd = end;
      calendarCacheInstance = calendarCache;

      if (!this.poller) {
        init();
      } else if (this.stopped) {
        toastr.clear();
        this.poller.start();
      }
    };

    /**
     * Stop the poller
     */
    this.stop = () => {
      if (!this.poller || !this.enabled) return;
      this.poller.stop();
      this.stopped = true;
    };

    return this;
  },
]);
