import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  FetchPortalWidgetsActionType,
  fetchPortalWidgetsSuccessAction,
  FETCH_PORTAL_WIDGETS,
} from '../redux';
import { PortalWidget } from '../types/portal.type';
import PortalService from '../services/portal.service';
import { withLoadingAndErrors } from '../../shared/loading/saga';

function* fetchPortalWidgetsSaga(
  action: FetchPortalWidgetsActionType
): SagaIterator {
  const data = yield call(PortalService.getAllPortalWidgets, action.payload);
  yield put(fetchPortalWidgetsSuccessAction(data as PortalWidget[]));
}

export default function* root(): Generator {
  yield all([
    takeLatest(
      FETCH_PORTAL_WIDGETS,
      withLoadingAndErrors(FETCH_PORTAL_WIDGETS, fetchPortalWidgetsSaga)
    ),
  ]);
}
