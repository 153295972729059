import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';

import { mainApi } from '@/react/api';
import { IntentionSettingsQuery } from '@/react/intention/store/intention-settings.store';
import { handleError } from '@/react/services/ErrorHandlingService';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';

export const useIntentionSettings = () => {
  const settings = useRecoilValue(IntentionSettingsQuery);
  const refreshSettings = useRecoilRefresher(IntentionSettingsQuery);

  const onFinish = (values) => {
    showConfirmInputModal({
      title: gettextCatalog.getString('Warning'),
      message: gettextCatalog.getString(
        'Please confirm that you want to change how intentions numbers are generated. Switching back is not possible.'
      ),
      confirmType: 'checkbox',
      confirmLabel: gettextCatalog.getString('I understand.'),
      onOk: () => save(values),
    });
  };
  const save = useRecoilCallback(() => async (values) => {
    const response = await mainApi.post('/intention/settings', values);
    if (!response.ok) {
      handleError(response);
    } else {
      NotificationService.notifySuccess(
        gettextCatalog.getString('Settings updated.')
      );
      refreshSettings();
    }
  });

  return { settings, onFinish };
};
