(function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = '79c0cdc17333dda87f8dac7659fdba47';
  a.appendChild(r);
})(window, document, 'https://www.usetiful.com/dist/usetiful.js');
// For our CSP the following has been allowed 'sha256-HWvST6vKrXAfBTiN3ZSXSCHmPX+35nknbmYGpnPNl/M='
