import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Space,
  Spin,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';

import {
  CdAlertInfo,
  CdAngleRight,
  CdDeleteIcon,
  CdDomains,
  CdEllipsis,
  CdExclamationTriangleIcon,
  CdOpenInNewWindow,
  EventIcons,
} from '@/react/shared/components/Icons';
import { WebsiteInterface } from '@/react/homepage/websitelandingpage/types/website.type';
import { gettextCatalog } from '@/react/services/I18nService';
import AuthorizationService from '@/react/services/AuthorizationService';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import cdApp from '@/react/config';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdMasqueradeTag } from '@/react/shared/components/CdMasqueradeTag';

const WebsiteCardStyled = styled(Card)<{
  $titleLength: number;
}>`
  &&&& {
    .ant-card-cover {
      position: relative;
    }
    .website-thumbnail-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      overflow: hidden;

      .website-thumbnail-background {
        height: 164px;
        width: 270px;
        background: white;
        background-image: url(https://assets.churchdesk.com/misc/img/person-studying-at-a-desk.svg);
        background-size: contain;
        background-position: center;
      }

      .website-thumbnail-title-box {
        height: 52px;
        background: #008db6;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${(props) => (props.$titleLength > 35 ? '14px' : '16px')};
      }
    }

    .open-designer {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: #00000090;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 22px;
      opacity: 0;
    }
    &:hover .open-designer {
      opacity: 1;
    }
  }
`;

export const WebsiteCard = ({
  website,
  goToMonoWebsite,
  websiteLoginLoading,
  deleteWebsite,
}: {
  website: WebsiteInterface['sites'][0];
  goToMonoWebsite: (WebsiteInterface) => void;
  deleteWebsite: (id: string) => void;
  websiteLoginLoading;
}) => {
  const canDeleteMonoWebsite =
    AuthorizationService.hasPermission('canAccessHomepage');
  const websiteLaunching = !!websiteLoginLoading[website.id];
  const canEditDomains =
    AuthorizationService.hasPermission('canAccessHomepage');
  const canEditWebsite = canEditDomains && !website.isLocked;
  const domainIssues = website.activeDomains === 0 || website.brokenDomains > 0;

  const confirmWord = gettextCatalog.getString(
    'DELETE',
    undefined,
    'Confirm word for when deleting a website'
  );
  const isChurchDeskEmployee = cdApp.me.isChurchDeskEmployee;
  const items: MenuProps['items'] = [
    isChurchDeskEmployee && {
      label: (
        <CopyToClipboard text={website.externalSiteId.toString()}>
          <Space>
            {`# ${website.externalSiteId}`}
            <CdMasqueradeTag />
          </Space>
        </CopyToClipboard>
      ),
      icon: <CdAlertInfo fixedWidth />,
      key: 'externalSiteId',
    },
    isChurchDeskEmployee && {
      label: (
        <CdTooltip title="Visible for ChurchDesk employess only">
          <Space>
            Mono RAI
            <CdMasqueradeTag />
          </Space>
        </CdTooltip>
      ),
      onClick: () =>
        window.open(
          `https://rai.monosolutions.com/siteoverview/active-sites/sites?accountId=${website.organization?.websiteAccountId}&highlightSiteId=${website.externalSiteId}#site${website.externalSiteId}`,
          '_blank'
        ),
      icon: <CdOpenInNewWindow fixedWidth />,
      key: 'raiLink',
    },
    {
      label: gettextCatalog.getString('Delete'),
      icon: <CdDeleteIcon fixedWidth />,
      disabled: !canDeleteMonoWebsite,
      key: 'delete',
      onClick: () => {
        if (website.activeDomains > 0 || website.brokenDomains > 0) {
          showConfirmModal({
            title: gettextCatalog.getString('Warning'),
            message: gettextCatalog.getString(
              'You cannot delete this site because it has domains attached to it. Please remove the domains first.'
            ),
            onOk: () => {},
          });
        } else {
          showConfirmInputModal({
            title: gettextCatalog.getString(
              'Are you sure you want to delete this site?'
            ),
            message: gettextCatalog.getString(
              'The site will be permantly deleted and cannot be restored after 5 days.'
            ),
            onOk: () => deleteWebsite(website.id),
            okText: gettextCatalog.getString('Delete'),
            okButtonProps: { danger: true },
            confirmType: 'text',
            confirmLabel: gettextCatalog.getString(
              'Type {{ confirmWord }} to confirm.',
              { confirmWord }
            ),
            confirmWord,
          });
        }
      },
    },
  ].filter(Boolean);

  const getWebsiteUrl = () => {
    if (website.primaryDomain) {
      return website.primaryDomain.startsWith('https')
        ? website.primaryDomain
        : `https://${website.primaryDomain}`;
    } else {
      return `https://${website.subdomain}.churchdesk.site/`;
    }
  };

  const title = website.primaryDomain || website.subdomain + '.churchdesk.site';

  return (
    <Col key={website.id}>
      <WarningRibbon website={website}>
        <WebsiteCardStyled
          hoverable={canEditWebsite}
          style={{ width: 270 }}
          bodyStyle={{ padding: '0px' }}
          $titleLength={title?.length}
          cover={
            <Spin
              spinning={websiteLaunching}
              tip={gettextCatalog.getString('Opening...')}
            >
              <div className="website-thumbnail-container">
                <div className="website-thumbnail-background" />
                <div className="website-thumbnail-title-box">{title}</div>
                {canEditWebsite && (
                  <div
                    className="open-designer"
                    onClick={() =>
                      !websiteLaunching &&
                      canEditWebsite &&
                      goToMonoWebsite(website)
                    }
                  >
                    <Space
                      style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        margin: '0px 16px',
                      }}
                    >
                      {gettextCatalog.getString('Open designer')}
                      <CdAngleRight />
                    </Space>
                  </div>
                )}
              </div>
            </Spin>
          }
          actions={[
            (website.subdomain || website.primaryDomain) && (
              <Button
                icon={
                  <EventIcons.VisibilityPublic style={{ color: 'initial' }} />
                }
                type="text"
                href={getWebsiteUrl()}
                target="_blank"
              >
                {!canDeleteMonoWebsite && gettextCatalog.getString('View site')}
              </Button>
            ),
            canEditDomains && (
              <Button
                type="text"
                onClick={() =>
                  NiceModal.show('ManageDomainsDrawer', { siteId: website.id })
                }
              >
                <Space>
                  <CdDomains />
                  {!canDeleteMonoWebsite && gettextCatalog.getString('Domains')}
                  {domainIssues && (
                    <CdExclamationTriangleIcon animation="beat" type="danger" />
                  )}
                </Space>
              </Button>
            ),
            canDeleteMonoWebsite && (
              <Dropdown menu={{ items }}>
                <Button type="text" onClick={(e) => e.preventDefault()}>
                  <CdEllipsis />
                </Button>
              </Dropdown>
            ),
          ].filter(Boolean)}
        />
      </WarningRibbon>
    </Col>
  );
};

const WarningRibbon = ({ website, children }) => {
  let ribbonColor;
  let ribbonText;
  if (website.isLocked) {
    ribbonColor = 'red';
    const lockDurationDays =
      moment(website.lockEndDate).diff(moment(), 'days') + 1;
    ribbonText = gettextCatalog.getPlural(
      lockDurationDays,
      'Locked. Deletes in {{ xDays }} day',
      'Locked. Deletes in {{ xDays }} days',
      {
        xDays: lockDurationDays,
      }
    );
  } else if (website.isTrial) {
    ribbonColor = 'orange';
    const trialDurationDays =
      moment(website.trialEndDate).diff(moment(), 'days') + 1;
    ribbonText = gettextCatalog.getPlural(
      trialDurationDays,
      'Trial. Locks in {{ xDays }} day',
      'Trial. Locks in {{ xDays }} days',
      {
        xDays: trialDurationDays,
      }
    );
  }

  if (ribbonColor) {
    return (
      <Badge.Ribbon text={ribbonText} color={ribbonColor}>
        {children}
      </Badge.Ribbon>
    );
  }
  return <>{children}</>;
};
