import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Crop } from 'react-image-crop';
import { reduce } from 'lodash';

import { ImageCrop, saveCrops } from '@/react/files/store/images';
import NotificationService from '@/react/services/NotificationService';
import { CropBounds } from '@/react/files/types/images.type';

interface useCropInformationProps {
  entityId: number | string;
  entityType: 'people-message' | 'event' | 'contribution';
  fileId: number;
  onlyCropType?: string;
}

export const useCropInformation = ({
  entityId,
  entityType,
  fileId,
  onlyCropType,
}: useCropInformationProps) => {
  const context = `${entityType}-${entityId}`;
  const imgRef = useRef<HTMLImageElement>();
  const currentImage = imgRef.current;

  const [cropType, setCropType] = useState<string>(onlyCropType || '16-9');

  const [crops, _setCrop] = useState<{ [cropType: string]: Crop }>({});

  const setCrop = useCallback(
    (newCrop: Crop) => {
      _setCrop((previousValues) => ({
        ...previousValues,
        [cropType]: newCrop,
      }));
    },
    [cropType, _setCrop]
  );

  const refresh = useRecoilRefresher(
    ImageCrop({
      fileId,
      context,
    })
  );

  const saveImageCrop = useRecoilCallback(
    () => async () => {
      const { naturalWidth, naturalHeight } = currentImage;
      const renderWidth = currentImage.width;
      const renderHeight = currentImage.height;
      const heightRatio = naturalHeight / renderHeight;
      const widthRatio = naturalWidth / renderWidth;

      const bounds = reduce(
        crops,
        (acc, value, key) => {
          const { x, y, width, height } = value;
          const top = renderHeight - y;
          const left = x;
          const bottom = top - height;
          const right = x + width;

          acc[key] = {
            right: Math.round(right * widthRatio),
            top: Math.round(top * heightRatio),
            bottom: Math.round(bottom * heightRatio),
            left: Math.round(left * widthRatio),
          };

          return acc;
        },
        {}
      );

      const response = await saveCrops({
        fileId,
        context,
        bounds,
      });

      refresh();

      if (!response.ok) {
        NotificationService.notifyError((response as any)?.data?.message);
        throw response;
      }
    },
    [context, crops, currentImage, fileId, refresh]
  );

  const imageCrop = useRecoilValue(
    ImageCrop({
      fileId,
      context,
    })
  );

  /**
   * The crop coordinates for the current crop type
   * @type {CropBounds | undefined}
   */
  const cropCoordsFromDB: CropBounds = imageCrop && imageCrop[cropType];

  useEffect(() => {
    if (!currentImage || !cropCoordsFromDB) {
      return;
    }
    const { naturalWidth, naturalHeight } = currentImage;
    const renderWidth = currentImage.width;
    const renderHeight = currentImage.height;
    const heightRatio = naturalHeight / renderHeight;
    const widthRatio = naturalWidth / renderWidth;

    const newCropWidth = Math.round(
      (cropCoordsFromDB.right - cropCoordsFromDB.left) / widthRatio
    );
    const newCropHeight = Math.round(
      (cropCoordsFromDB.top - cropCoordsFromDB.bottom) / heightRatio
    );
    const crop = crops[cropType];
    if (
      (crop &&
        crop.x === 0 &&
        crop.y === 0 &&
        crop.width === 0 &&
        crop.height === 0) ||
      !crop
    ) {
      setCrop({
        aspect: newCropWidth / newCropHeight,
        x: Math.round(cropCoordsFromDB.left / widthRatio),
        y: Math.round((naturalHeight - cropCoordsFromDB.top) / heightRatio),
        width: newCropWidth,
        height: newCropHeight,
        unit: 'px',
      });
    }
  }, [cropCoordsFromDB, cropType, crops, currentImage, setCrop]);

  return {
    imgRef,
    refresh,
    saveImageCrop,
    cropType,
    setCropType,
    crop: crops[cropType],
    setCrop,
  };
};
