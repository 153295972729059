import { useState } from 'react';

import { checkIfAliasExist } from '../../event-details/services/checkIfAliasExist';

import { getStateUrl } from '@/react/services/StateServiceFactory';
import { gettextCatalog } from '@/react/services/I18nService';

export const useEventAliasValidation = ({ eventId }) => {
  const [duplicateAliasLink, setDuplicateAliasLink] = useState<string | null>(
    null
  );
  const validateAlias = async (rule, value) => {
    if (value) {
      const response = await checkIfAliasExist({
        alias: value,
        entityId: eventId,
        entityType: 'event',
      });

      if (response) {
        const redirectionUrl =
          response.type === 'event'
            ? getStateUrl('app.private.calendar.event', {
                id: response.id,
              })
            : response.url;

        setDuplicateAliasLink(redirectionUrl);
        // Reject with an error message to indicate validation failure
        throw new Error(
          gettextCatalog.getString('The alternative url is already being used.')
        );
      } else {
        setDuplicateAliasLink(null);
        // Resolve the promise to indicate validation success
        return;
      }
    } else {
      setDuplicateAliasLink(null);
      // Resolve the promise to indicate validation success
      return;
    }
  };
  return { duplicateAliasLink, validateAlias };
};
