class ColumnManagerController {
  $onInit() {
    if (!this.buttonType) this.buttonType = 'basic';
    if (!this.buttonSize) this.buttonSize = 'md';
    this.buttonClass = `btn btn-${this.buttonType} btn-${this.buttonSize}`;
  }
}

angular.module('cdApp.shared').component('cdColumnManager', {
  templateUrl:
    '@/app/shared/components/column-manager/column-manager.component.html',
  controller: ColumnManagerController,
  bindings: {
    columnManager: '<',
    isDisabled: '<',
    buttonText: '<',
    buttonSize: '<',
    buttonType: '<',
  },
});
