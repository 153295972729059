import { ActionCreator, CdAction } from '../../../redux/utils';
import { FoundationData } from '../../services/FoundationService';
import {
  PaymentMethodTypes,
  IntentionStatusTypes,
} from '../../models/intention';
import {
  Foundation,
  FoundationTypes,
  FoundationStatusTypes,
} from '../../models/foundation';
import { IntentionData } from '../../services/IntentionService';

export type ClearStiftungActionType = CdAction<Foundation>;
export const CLEAR_STIFTUNG = 'intention/clearStiftung';
export const clearStiftung: ActionCreator<Foundation> = () => ({
  type: CLEAR_STIFTUNG,
});

export type ClearLegatActionType = CdAction<Foundation>;
export const CLEAR_LEGAT = 'intention/clearLegat';
export const clearLegat: ActionCreator<Foundation> = () => ({
  type: CLEAR_LEGAT,
});

// Fetch foundations

export interface FetchFoundationsPayload {
  type: FoundationTypes;
  status?: FoundationStatusTypes;
  churchIds?: number[];
  feeIds?: string[];
  paymentMethods?: PaymentMethodTypes[];
  referenceNumberFrom?: number;
  referenceNumberTo?: number;
  searchValue?: string;
  includeDeleted?: boolean;
  orderBy?: string;
  orderDirection?: string;
}
export type FetchFoundationsActionType = CdAction<FetchFoundationsPayload>;
export const FETCH_FOUNDATIONS = 'intention/fetchFoundations';
export const fetchFoundations: ActionCreator<FetchFoundationsPayload> = (
  payload
) => ({
  type: FETCH_FOUNDATIONS,
  payload,
});

export type FetchLegateSuccess = CdAction<FoundationData>;
export const FETCH_LEGATE_SUCCESS = 'intention/fetchLegateSuccess';
export const fetchLegateSuccess: ActionCreator<FoundationData> = (
  legateData
) => ({
  type: FETCH_LEGATE_SUCCESS,
  payload: legateData,
});

export type FetchStiftungenSuccess = CdAction<FoundationData>;
export const FETCH_STIFTUNGEN_SUCCESS = 'intention/fetchStiftungenSuccess';
export const fetchStiftungenSuccess: ActionCreator<FoundationData> = (
  stiftungenData
) => ({
  type: FETCH_STIFTUNGEN_SUCCESS,
  payload: stiftungenData,
});

// Fetch foundation

interface FoundationPayload {
  id: string;
  type: FoundationTypes;
}
export type FetchFoundationActionType = CdAction<FoundationPayload>;
export const FETCH_FOUNDATION = 'intention/fetchFoundation';
export const fetchFoundation: ActionCreator<FoundationPayload> = (payload) => ({
  type: FETCH_FOUNDATION,
  payload,
});

export type FetchLegatSuccess = CdAction<Foundation>;
export const FETCH_LEGAT_SUCCESS = 'intention/fetchLegatSuccess';
export const fetchLegatSuccess: ActionCreator<Foundation> = (legatData) => ({
  type: FETCH_LEGAT_SUCCESS,
  payload: legatData,
});

export type FetchStiftungSuccess = CdAction<Foundation>;
export const FETCH_STIFTUNG_SUCCESS = 'intention/fetchStiftungSuccess';
export const fetchStiftungSuccess: ActionCreator<Foundation> = (
  stiftungData
) => ({
  type: FETCH_STIFTUNG_SUCCESS,
  payload: stiftungData,
});

// Create foundation

export type CreateFoundationActionType = CdAction<Partial<Foundation>>;
export const CREATE_FOUNDATION = 'intention/createFoundation';
export const createFoundation: ActionCreator<Partial<Foundation>> = (
  payload
) => ({
  type: CREATE_FOUNDATION,
  payload,
});

// Update foundation

interface UpdateFoundation {
  id: string;
  type: FoundationTypes;
  updatePayload: Partial<Foundation>;
  status?: FoundationStatusTypes;
}
export type UpdateFoundationActionType = CdAction<UpdateFoundation>;
export const UPDATE_FOUNDATION = 'intention/updateFoundation';
export const updateFoundation: ActionCreator<UpdateFoundation> = (payload) => ({
  type: UPDATE_FOUNDATION,
  payload,
});

interface ChangeFoundationStatus {
  id: string;
  type: FoundationTypes;
  newStatus: FoundationStatusTypes;
  oldStatus: FoundationStatusTypes;
  startYear: number;
  filters: any;
}
export type ChangeFoundationStatusActionType = CdAction<ChangeFoundationStatus>;
export const CHANGE_FOUNDATION_STATUS = 'intention/changeFoundationStatus';
export const changeFoundationStatus: ActionCreator<ChangeFoundationStatus> = (
  payload
) => ({
  type: CHANGE_FOUNDATION_STATUS,
  payload,
});

// Delete foundation

interface DeleteFoundationPayload extends FoundationPayload {
  status?: FoundationStatusTypes;
}
export type DeleteFoundationActionType = CdAction<DeleteFoundationPayload>;
export const DELETE_FOUNDATION = 'intention/deleteFoundation';
export const deleteFoundation: ActionCreator<DeleteFoundationPayload> = (
  payload
) => ({
  type: DELETE_FOUNDATION,
  payload,
});

// Restore foundation

export type RestoreFoundationActionType = CdAction<FoundationPayload>;
export const RESTORE_FOUNDATION = 'intention/restoreFoundation';
export const restoreFoundation: ActionCreator<FoundationPayload> = (
  payload
) => ({
  type: RESTORE_FOUNDATION,
  payload,
});

// Fetch intentions associated with a foundation
export interface FetchIntentionsForFoundation {
  id: string;
  type: FoundationTypes;
  status?: IntentionStatusTypes;
  includeDeleted?: boolean;
  orderBy?: string;
  orderDirection?: string;
  offset?: number;
  limit?: number;
}
export type FetchIntentionsForFoundationType =
  CdAction<FetchIntentionsForFoundation>;
export const FETCH_INTENTIONS_FOR_FOUNDATION =
  'intention/fetchIntentionsForFoundation';
export const fetchIntentionsForFoundation: ActionCreator<
  FetchIntentionsForFoundation
> = (payload) => ({
  type: FETCH_INTENTIONS_FOR_FOUNDATION,
  payload,
});

interface FetchIntentionsForFoundationSuccess {
  data: IntentionData;
  type: FoundationTypes;
}
export type FetchIntentionsForFoundationSuccessType =
  CdAction<FetchIntentionsForFoundationSuccess>;
export const FETCH_INTENTIONS_FOR_FOUNDATION_SUCCESS =
  'intention/fetchIntentionsForFoundationSuccess';
export const fetchIntentionsForFoundationSuccess: ActionCreator<
  FetchIntentionsForFoundationSuccess
> = (payload) => ({
  type: FETCH_INTENTIONS_FOR_FOUNDATION_SUCCESS,
  payload,
});

export type ClearIntentionsForFoundationsType = CdAction<void>;
export const CLEAR_INTENTIONS_FOR_FOUNDATIONS =
  'intention/clearIntentionsForFoundations';
export const clearIntentionsForFoundations: ActionCreator<void> = () => ({
  type: CLEAR_INTENTIONS_FOR_FOUNDATIONS,
});

export type GenerateFoundationIntentionsReportActionType =
  CdAction<FoundationPayload>;
export const GENERATE_FOUNDATION_INTENTIONS_REPORT =
  'intention/generateFoundationIntentionsReport';
export const generateFoundationIntentionsReport: ActionCreator<
  FoundationPayload
> = (payload) => ({
  type: GENERATE_FOUNDATION_INTENTIONS_REPORT,
  payload,
});
