import { antdModalV5 } from '@ebay/nice-modal-react';
import { Modal } from 'antd';
import React, { useState } from 'react';
interface CdModalProps {
  modal: any;
  title: string;
  okText: string;
  onOk: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  width?: number | string;
  disabledOkButton?: boolean;
}

const CdModal = ({
  children,
  modal,
  title,
  okText,
  onOk,
  style,
  width,
  disabledOkButton,
}: CdModalProps) => {
  const [disable, setDisable] = useState(false);
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        title,
        bodyStyle: { padding: 0 },
        okText,
        style,
        width,
        okButtonProps: {
          disabled: disable || disabledOkButton,
          loading: disable,
        },
        onOk: async () => {
          try {
            setDisable(true);
            await onOk();
            modal.hide();
          } catch (error) {
            setDisable(false);
          }
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default CdModal;
