import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { Form } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';

import { gettextCatalog } from '../../services/I18nService';
import { SafeGuardingDetailsQuery } from '../store/Safeguarding';
import NotificationService from '../../services/NotificationService';
import {
  handleAntFormValidationErrors,
  handleError,
} from '../../services/ErrorHandlingService';
import { mainApi } from '../../api';
import { RecordInterface, fileUploadTypes } from '../types/SafeguardingTypes';
import cdApp from '../../config';

import { useSafeguarding } from './useSafeguarding';

export const useSafeguardingAddRecord = (
  currentUserId: number,
  fileType: string
) => {
  const [fileUploadForm] = Form.useForm();
  const refreshSafeguarding = useRecoilRefresher(
    SafeGuardingDetailsQuery(currentUserId)
  );
  const { refreshSafeguardingList } = useSafeguarding();
  const onSaveFileUploadForm = useRecoilCallback(
    () => async (addRecordFormFields: RecordInterface) => {
      const payload = {
        type: fileType,
        reviewedBy:
          addRecordFormFields?.reviewedBy === 'other'
            ? null
            : addRecordFormFields?.reviewedBy,
        reviewDate:
          (addRecordFormFields?.reviewDate &&
            addRecordFormFields?.reviewDate.format('YYYY-MM-DD')) ||
          moment().format('YYYY-MM-DD'),
        thirdPartyReviewName:
          addRecordFormFields?.reviewedBy === 'other'
            ? addRecordFormFields?.thirdPartyReviewName
            : undefined,
        followupDate:
          addRecordFormFields?.followupDate &&
          addRecordFormFields?.followupDate.format('YYYY-MM-DD'),
        documentWhereabouts: addRecordFormFields?.documentWhereabouts,
        courseTitle: addRecordFormFields?.courseTitle,
        courseType: addRecordFormFields?.courseType,
        courseDuration: addRecordFormFields?.courseDuration,
        courseDate:
          addRecordFormFields?.courseDate &&
          addRecordFormFields?.courseDate.format('YYYY-MM-DD'),
        documentType: addRecordFormFields?.documentType,
        fileIds:
          fileType === fileUploadTypes.COURSE
            ? addRecordFormFields?.file?.map((item) => item?.response?.uuid) ||
              []
            : [],
        fileId:
          fileType === fileUploadTypes.DOCUMENT
            ? addRecordFormFields?.file[0]?.response?.uuid
            : undefined,
        comments: addRecordFormFields?.comments,
        // extra fields for all types
        refreshDate:
          addRecordFormFields?.refreshDate &&
          addRecordFormFields?.refreshDate.format('YYYY-MM-DD'),
        refreshRequired: addRecordFormFields?.refreshDate ? true : false,
      };
      const response = await mainApi.post(
        `/safeguard?userId=${currentUserId}`,
        payload
      );
      if (!response.ok) {
        handleError(response);
        throw response.data;
      }
      refreshSafeguarding();
      await refreshSafeguardingList();
      NotificationService.notifySuccess(
        gettextCatalog.getString('Record is saved successfully')
      );
      return response.data;
    },
    [currentUserId, fileType, refreshSafeguarding, refreshSafeguardingList]
  );

  const onFileUploadFormFinish = useCallback(async () => {
    await fileUploadForm
      .validateFields()
      .then((formVal) => {
        onSaveFileUploadForm(formVal);
      })
      .catch(handleAntFormValidationErrors);
  }, [fileUploadForm, onSaveFileUploadForm]);
  const fileUploadFormInitialValue = {
    reviewedBy: cdApp.me?.id,
    reviewDate: moment(),
    refreshDate:
      fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT
        ? moment().add(5, 'years')
        : undefined,
  };
  return {
    fileUploadForm,
    onSaveFileUploadForm,
    onFileUploadFormFinish,
    fileUploadFormInitialValue,
  };
};
