'use strict';
class PeopleMessageAddContentModal {
  constructor(
    $http,
    cdApp,
    _,
    moment,
    Organizations,
    Authorization,
    dateFormatsLookup
  ) {
    'ngInject';

    this.$http = $http;
    this.cdApp = cdApp;
    this._ = _;
    this.moment = moment;
    this.Organizations = Organizations;
    this.Authorization = Authorization;

    this.selectedContent = [];
    this.selectedOrganization = this.cdApp.organization.id;

    this.datepicker = {
      format: dateFormatsLookup.getFormat(),
      opened: false,
      options: {
        minDate: this.moment().startOf('day').toDate(),
      },
    };
  }

  $onInit() {
    this.existingContent = this.resolve.content;
    this.contentType = this.resolve.contentType;
  }

  /**
   * Fetch content matching the search query
   *
   * @param {String} search The string to filter content
   */
  searchContent(search) {
    const _ = this._;

    this.endpointByContentType = {
      events: `${this.cdApp.config.api.main}/collaboration/calendar-view`,
      news: `${this.cdApp.config.api.main}/collaboration/blog-view`,
      forms: `${this.cdApp.config.api.forms}/forms/public/search`,
    };

    const organizationId =
      this.selectedOrganization || this.cdApp.organization.id;
    const limit = 10;
    const offset = this.selectedDate ? 0 : this.selectedContent.length;
    const params = {};

    if (this.contentType === 'forms') {
      _.extend(params, { search, limit, organizationId });
    } else {
      _.extend(params, {
        limit,
        offset,
        imageFormat: 'span3_16-9',
        'filters[0][title]': search,
        'filters[0][organizationId]': organizationId,
        stripHtml: true,
      });

      // If searching for events and a date has been selected,
      // search only for events happening on that exact date
      if (this.contentType === 'events' && this.selectedDate) {
        _.extend(params, {
          startDate: this.moment(this.selectedDate)
            .startOf('day')
            .toISOString(),
          endDate: this.moment(this.selectedDate).endOf('day').toISOString(),
        });
      }
    }

    return this.$http
      .get(this.endpointByContentType[this.contentType], { params })
      .then(({ data }) => {
        const results = this.contentType === 'forms' ? data : data.items;
        const filteredResults = _.reject(results, (item) =>
          _.some(this.existingContent, { id: item.id })
        );

        this.contentSearchResults = filteredResults;
      });
  }

  /**
   * Fetch organizations with collaboration package matching the search query
   *
   * @param {String} search The string to filter organizations
   */
  searchOrganizations(search) {
    const currentOrganization = this.cdApp.organization;

    const params = {
      limit: 10,
      country: currentOrganization.countryIso2,
    };

    if (this._.size(search) >= 3) {
      params.name = search;
    }

    return this.$http
      .get(`${cdApp.config.api.main}/organizations/search`, { params })
      .then(({ data }) => {
        this.organizationSearchResults = [];
        this.organizationSearchResults =
          this.organizationSearchResults.concat(data);

        // If we're not searching and the current organization is not part of the results,
        // add it to the list of results so it can be selected by default
        const currentOrganizationIsPartOfSearchResults = this._.some(data, {
          id: currentOrganization.id,
        });

        if (!search && !currentOrganizationIsPartOfSearchResults) {
          const defaultOrganization = this._.pick(currentOrganization, [
            'id',
            'name',
            'installationUrl',
            'countryIso2',
            'locale.language',
          ]);

          this.organizationSearchResults.unshift(defaultOrganization);
        }
      });
  }

  /**
   * Save the selected content
   */
  addContent() {
    this.close({ $value: this.selectedContent });
  }
}

PeopleMessageAddContentModal.$inject = [
  '$http',
  'cdApp',
  '_',
  'moment',
  'Organizations',
  'Authorization',
  'dateFormatsLookup',
];

angular.module('cdApp.people').component('cdPeopleMessageAddContentModal', {
  templateUrl:
    '@/app/people/message-editor/modals/add-content/add-content.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: PeopleMessageAddContentModal,
});
