(function () {
  'use strict';
  Revision.$inject = ['$$resource'];

  function Revision($$resource) {
    return $$resource(
      cdApp.config.api.main + '/revisions/:type/:id/view/:rid',
      {},
      {
        get: {
          method: 'GET',
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Revision', Revision);
})();
