import { react2angular } from 'react18-react2angular';
import { cloneDeep } from 'lodash';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import FormListHOC from '@/react/forms/components/list/FormList';
import LabelList from '@/react/forms/components/LabelList';

class FormListComponent {
  constructor(_, cdApp, $stateParams, $uibModal) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
  }

  createForm = (churches, labels) => {
    const { $uibModal } = this;
    const clonedChurches = cloneDeep(churches);
    const clonedLabels = cloneDeep(labels);

    $uibModal.open({
      component: 'createFormModal',
      size: 'lg',
      resolve: {
        churches: () => clonedChurches,
        labels: () => clonedLabels,
        selectedChurches: () => clonedChurches.map(({ id }) => id),
      },
    });
  };
}
FormListComponent.$inject = ['_', 'cdApp', '$stateParams', '$uibModal'];

angular
  .module('cdApp.forms')
  .component('formList', {
    template: require('./form-list.html'),
    controller: FormListComponent,
  })
  .component(
    'cdrFormList',
    react2angular(
      sharedContext.use(FormListHOC),
      ['createForm'],
      ['$stateParams']
    )
  )
  .component(
    'cdrFormLabels',
    react2angular(sharedContext.use(LabelList), [], [])
  );
