import _ from 'lodash';
import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { $injector } from 'ngimport';

import StoleService, { StolesData } from '../services/StoleService';
import IntentionReportService from '../services/IntentionReportService';
import {
  FetchEventStoleActionType,
  fetchEventStoleSuccess,
  FetchStolesActionType,
  fetchStolesSuccess,
  fetchStoleBillingEventsSuccess,
  FetchStoleBillingEventsActionType,
  GenerateStoleBillingReportActionType,
  RefreshEventStoleActionType,
  clearEventStole,
  fetchEventStole,
  fetchStoles,
  RestoreStoleActionType,
} from '../redux/stoles/Actions';
import { Stole } from '../models/stole';
import { handleSuccessMessage } from '../../shared/utils';

export function* getEventStoleSaga(
  action: FetchEventStoleActionType
): SagaIterator {
  const stole: Stole = yield call(StoleService.getEventStole, action.payload);
  yield put(fetchEventStoleSuccess(stole));
}

export function* getStolesSaga(action: FetchStolesActionType): SagaIterator {
  const data: StolesData = yield call(StoleService.getStoles, action.payload);
  yield put(fetchStolesSuccess(data));
}

export function* fetchStoleBillingEventsSaga(
  action: FetchStoleBillingEventsActionType
): SagaIterator {
  const data = yield call(StoleService.fetchStoleBillingEvents, action.payload);
  yield put(fetchStoleBillingEventsSuccess(data));
}

export function* generateStoleBillingReport(
  action: GenerateStoleBillingReportActionType
): SagaIterator {
  const downloadToken = yield call(
    IntentionReportService.fetchStoleBillingReportDownloadToken
  );
  const { month, year, churchIds } = action.payload;
  const params = $.param({
    month,
    year,
    churchId: _.first(churchIds),
    downloadToken,
  });
  const fileUrl = `${window.cdApp.config.api.main}/intention/intention-reports/stole-billing/report?${params}`;
  yield call(window.open, fileUrl, '_blank');
}

export function* refreshEventStoleSaga(
  action: RefreshEventStoleActionType
): SagaIterator {
  yield put(clearEventStole());
  yield put(fetchEventStole(action.payload));
}

export function* restoreStoleSaga(
  action: RestoreStoleActionType
): SagaIterator {
  const { id } = action.payload;
  yield call(StoleService.restoreStole, id);
  // Show success message
  const gettextCatalog: any = $injector.get('gettextCatalog');
  yield call(
    handleSuccessMessage,
    gettextCatalog.getString('Successfully restored stole.')
  );

  yield put(fetchStoles({ includeDeleted: true }));
}
