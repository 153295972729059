import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { FEvent } from '../../calendar/models/calendar';
import { People } from '../../people/types/people';
import createReducer from '../../redux/utils';
import { Category } from '../models/category';

import {
  CLEAR_PERSON,
  CLEAR_SEARCH_MATCHED_PEOPLE,
  SearchPeopleSuccessType,
  SEARCH_PEOPLE_SUCCESS,
  FetchPersonSuccessType,
  FETCH_PERSON_SUCCESS,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FetchCategorySuccessType,
  FetchEventsSuccessType,
  FETCH_EVENT_SUCCESS,
  FetchEventSuccessType,
  CLEAR_EVENT,
} from './Actions';

export interface CommonState {
  matchedPeople: People[];
  person: People;
  categories: Category[];
  events: FEvent[];
  event: FEvent;
}

export type ImmutableCommonState = immutable.ImmutableObject<CommonState>;

const initialState: ImmutableCommonState = immutable.from({
  matchedPeople: [],
  person: null,
  categories: [],
  events: [],
  event: null,
});

// People

const clearPerson: Reducer<ImmutableCommonState> = (state = initialState) =>
  state.merge({
    person: null,
  });

const fetchPersonSuccess: Reducer<
  ImmutableCommonState,
  FetchPersonSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    person: payload,
  });

const clearSearchMatchedPeople: Reducer<ImmutableCommonState> = (
  state = initialState
) =>
  state.merge({
    matchedPeople: [],
  });

const searchPeopleSuccess: Reducer<
  ImmutableCommonState,
  SearchPeopleSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    matchedPeople: payload || [],
  });

// Categories

const fetchCategoriesSuccess: Reducer<
  ImmutableCommonState,
  FetchCategorySuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    categories: payload || [],
  });

// Events

const fetchEventsSuccess: Reducer<
  ImmutableCommonState,
  FetchEventsSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    events: payload || [],
  });

const fetchEventSuccess: Reducer<ImmutableCommonState, FetchEventSuccessType> =
  (state = initialState, { payload }) =>
    state.merge({
      event: payload || {},
    });

const clearEvent: Reducer<ImmutableCommonState> = (state = initialState) =>
  state.merge({
    event: null,
  });

// Handlers

const handlers = {
  // People
  [CLEAR_PERSON]: clearPerson,
  [CLEAR_SEARCH_MATCHED_PEOPLE]: clearSearchMatchedPeople,
  [FETCH_PERSON_SUCCESS]: fetchPersonSuccess,
  [SEARCH_PEOPLE_SUCCESS]: searchPeopleSuccess,
  // Categories
  [FETCH_CATEGORIES_SUCCESS]: fetchCategoriesSuccess,
  // Events
  [FETCH_EVENTS_SUCCESS]: fetchEventsSuccess,
  [FETCH_EVENT_SUCCESS]: fetchEventSuccess,
  [CLEAR_EVENT]: clearEvent,
};

export default createReducer<ImmutableCommonState>(initialState, handlers);
