import React from 'react';
import { Layout, Card, Spin, Row, Col, Skeleton, Space } from 'antd';

import { CdPageHeader } from './cd-page/CdPage';

const CdrPageLoader = ({
  hideButtons,
  marginLess,
}: {
  hideButtons?: boolean;
  marginLess?: boolean;
}) => (
  <Layout.Content className={marginLess ? undefined : 'app-main'}>
    <CdPageHeader
      title={<Skeleton.Input style={{ width: 300 }} active={true} />}
      extra={
        !hideButtons && (
          <Space>
            <Skeleton.Button active={true} />
            <Skeleton.Button active={true} />
          </Space>
        )
      }
    />
    <Card
      bodyStyle={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
      style={{ height: '100%' }}
    >
      <Row justify="center" style={{ height: '100%' }}>
        <Col>
          <Spin style={{ marginTop: '24px' }} size="large" />
        </Col>
      </Row>
    </Card>
  </Layout.Content>
);

export default CdrPageLoader;
