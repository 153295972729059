import { Radio, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { Text } = Typography;

const RadioWithIcon = styled(Radio)<{
  $align: 'start' | 'center';
  $iconSize: 'small' | 'large';
}>`
  &.ant-radio-wrapper {
    align-items: ${(props) => (props.$align === 'start' ? 'start' : 'center')};

    ${(props) =>
      props.$iconSize === 'large' &&
      `
    .icon-square-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      width: 40px;
      height: 40px;
    }`}
  }

  .ant-radio {
    ${(props) =>
      props.$align === 'start' &&
      props.$iconSize === 'large' &&
      `
      top: 13px;
      align-self: start;
    `}
    ${(props) =>
      props.$align === 'start' &&
      props.$iconSize === 'small' &&
      `
      top: 2px;
      align-self: start;
    `}
  }

  &.ant-radio-wrapper-checked {
    .icon-square-box {
      ${(props) =>
        props.$iconSize === 'large' &&
        `
          background: #008db6;
          color: white;
      `}
      ${(props) =>
        props.$iconSize === 'small' &&
        `
          color: #008db6;
      `}
    }
  }
`;

export const CdRadioWithIcon = (props: {
  value;
  Icon;
  iconSize: 'small' | 'large';
  title?: string;
  label: string;
  disabled?: boolean;
  align: 'start' | 'center';
}) => (
  <RadioWithIcon
    value={props.value}
    style={{ width: '100%' }}
    disabled={props.disabled}
    $align={props.align}
    $iconSize={props.iconSize}
  >
    <Space align={props.align}>
      <div className="icon-square-box">
        <props.Icon
          fixedWidth
          size={props.iconSize === 'large' ? 'lg' : '1x'}
        />
      </div>
      <Space direction="vertical" size={0}>
        {props.title && <Text strong>{props.title}</Text>}
        <Text>{props.label}</Text>
      </Space>
    </Space>
  </RadioWithIcon>
);
