import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ErrorPage from '@/react/people/pages/Errorpage';

angular
  .module('cdApp.people')
  .component(
    'cdrErrorLinkExpiration',
    react2angular(sharedContext.use(ErrorPage), [], [])
  );
