class AddPastoralNoteController {
  constructor(_, $http, People, localStorageService) {
    this._ = _;
    this.$http = $http;
    this.People = People;
    this.localStorageService = localStorageService;
  }

  $onInit() {
    const { _, localStorageService } = this;
    this.hasPersonId = !_.isUndefined(this.resolve.personId);
    this.isEdit = !_.isUndefined(this.resolve.pastoralNoteId);
    this.note = this.resolve.note || '';
    this.churches = this.resolve.churches;

    const nameDisplayOrder = localStorageService.get(
      'peopleSettings.displayOrder'
    );

    this.orderBy = nameDisplayOrder === 'lastFirst' ? 'lastName' : 'firstName';
    this.reverseName = nameDisplayOrder === 'lastFirst';

    this.searchContacts = _.debounce(this.searchContacts, 500);
  }

  /**
   * When the user types in the search input, we check if it's more than 3 characters
   * and we query the backend.
   *
   * @param {string} query The search query
   */
  onSearchContactsChange(query) {
    // Save the last search query value
    this.searchQuery = query;
    this.searchContacts(query);
  }

  searchContacts(query) {
    const { _ } = this;

    // construct Elastic search filters
    const filter = {
      comparison: 'OR',
      churchIds: _.map(this.churches, 'id'),
      filters: [
        {
          type: 'text',
          property: 'fullName',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'email',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'phone',
          operator: 'like',
          value: query,
        },
      ],
    };

    // Extract a value from the `fields` array of a contact
    const getContactField = (contact, property) =>
      _.get(_.find(contact.fields, { property }), 'value');

    // Search contacts
    this.contacts = [];
    this.isSearching = true;
    this.People.searchPeople({
      filter,
      limit: 20,
      searchAfter: null,
      orderBy: this.orderBy,
      orderDirection: 'asc',
      reverseName: this.reverseName,
    })
      .$promise.then(({ people }) => {
        this.contacts = _.map(people, (contact) => ({
          id: contact.id,
          email: getContactField(contact, 'email'),
          fullName: [
            getContactField(contact, 'firstName'),
            getContactField(contact, 'lastName'),
          ].join(' '),
          picture: contact.picture || '/img/user-default.png',
        }));
      })
      .finally(() => (this.isSearching = false));
  }

  /**
   * Add/Edit the pastoral note
   */
  savePastoralNote() {
    const { $http } = this;
    const personId = this.resolve.personId || this.personId;
    this.isLoading = true;
    const payload = {
      personId,
      note: this.note,
    };

    const savePastoralNote = this.isEdit
      ? $http.put(
          `${cdApp.config.api.main}/people/${personId}/pastoral-notes/${this.resolve.pastoralNoteId}`,
          payload
        )
      : $http.post(
          `${cdApp.config.api.main}/people/${personId}/pastoral-notes`,
          payload
        );

    savePastoralNote
      .then(() => {
        this.close();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

AddPastoralNoteController.$inject = [
  '_',
  '$http',
  'People',
  'localStorageService',
];

angular.module('cdApp.people').component('cdAddPastoralNote', {
  template: require('./add-pastoral-note-modal.component.html'),
  controller: AddPastoralNoteController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
