import { atom, selector, selectorFamily } from 'recoil';

import {
  EventWithStudioItems,
  EventWithStudioItemsInterface,
} from '../types/studio';

import { ApiSearchResult, mainApi } from '@/react/api';
import { UnPagedTableApiSearchResult } from '@/react/shared/components/cd-unpaged-table/types';
import { SelectedOrganizationId } from '@/react/organization/store/organization';
import { handleError } from '@/react/services/ErrorHandlingService';

export const StudioState = atom<ApiSearchResult<EventWithStudioItems>>({
  key: 'StudioState',
  default: selector({
    key: 'StudioState/Selector',
    get: async ({ get }) => {
      const organizationId = get(SelectedOrganizationId);
      const response = await mainApi.get<EventWithStudioItems[]>(
        '/calendar/studio',
        {
          organizationId,
        }
      );

      if (!response.ok) {
        handleError(response);
        throw response;
      }
      return { items: response.data, total: response.data?.length };
    },
  }),
});

export type PosterSearchParams = {
  limit: number;
  cursor?: string;
};

export const getPosters = selectorFamily<
  UnPagedTableApiSearchResult<EventWithStudioItems>,
  PosterSearchParams
>({
  key: 'getPosters',
  get:
    ({ limit, cursor }: PosterSearchParams) =>
    async () => {
      const response = await mainApi.get<EventWithStudioItemsInterface>(
        '/calendar/studio',
        {
          limit,
          searchAfter: cursor,
          version: '2', // version2 API introduces pagination with searchAfter
        }
      );

      if (!response.ok) {
        handleError(response);
        return { items: [], cursor: undefined };
      }

      return {
        items: response.data.list,
        cursor: response?.data?.cursor,
      };
    },
});
