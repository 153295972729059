import { $injector } from 'ngimport';
import moment, { Moment } from 'moment';
import { IRootScopeService } from 'angular';

import { DetailedEvent } from '../models/calendar';
import { fullCalendarActions } from '../store/events/fullCalendarEventsSlice';
import Store from '../../redux';

import CalendarViewService from './CalendarViewService';

interface CreateEventOptions {
  onHide: () => void;
  onCreate: (event: DetailedEvent) => void;
}

export interface RootScope extends IRootScopeService {
  openCreateContentWindow(
    eventType: string,
    operation: 'edit' | 'create' | 'copy',
    event?: any
  );
  previousStateParams: any;
  previousState: any;
}

export interface UibModal<T = void> {
  open(options: {
    windowClass?: string;
    backdrop?: string;
    keyboard?: boolean;
    size?: string;
    component: string;
    resolve: any;
  }): { result: Promise<T> };
}

interface CreateEventData {
  type: string;
  allDay: boolean;
  showAbsence: boolean;
  startDate?: string;
  endDate?: string;
  resources?: number[];
  users?: number[];
}

export interface PreloadedEventData {
  resources?: number[];
  users?: number[];
}

interface ViewEventOptions {
  openOnClick: boolean;
  onDelete: () => void;
  onUpdateResponse: () => void;
}

interface AngularEventPopupService {
  createEvent: (
    eventData: CreateEventData,
    target: EventTarget,
    options: CreateEventOptions
  ) => void;
  viewEvent: (
    eventId: number,
    target: EventTarget,
    options: ViewEventOptions
  ) => void;
}

const defaultViewEventOptions: ViewEventOptions = {
  openOnClick: true,
  onDelete: (): void => {
    const store = Store.getStore();
    store.dispatch(fullCalendarActions.reloadCurrentView());
  },
  onUpdateResponse: (): void => {
    const store = Store.getStore();
    store.dispatch(fullCalendarActions.reloadCurrentView());
  },
};

class EventPopupService {
  public viewEvent(
    eventId: number,
    target: Element | Text,
    type: string,
    options?: ViewEventOptions
  ): void {
    const eventPopupService: EventPopupService =
      $injector.get('eventPopupService');
    eventPopupService.viewEvent(
      eventId,
      target,
      type,
      options || defaultViewEventOptions
    );
  }

  public createEvent(
    start: Date,
    end: Date,
    target: EventTarget,
    allDay: boolean,
    data: PreloadedEventData,
    onCreate: (event: DetailedEvent) => void
  ): void {
    const eventPopupService: AngularEventPopupService =
      $injector.get('eventPopupService');
    const startM: Moment = moment(start);
    const endM: Moment = moment(end);
    const eventData: CreateEventData = {
      type: 'event',
      allDay,
      showAbsence: true,
      ...data,
    };

    if (eventData.allDay) {
      eventData.startDate = new Date(
        startM.startOf('day').format('YYYY/MM/DD HH:mm:ss')
      ).toISOString();
      eventData.endDate = new Date(
        endM.subtract(1, 'days').endOf('day').format('YYYY/MM/DD HH:mm:ss')
      ).toISOString();
    } else {
      eventData.startDate = startM.toISOString();
      eventData.endDate = endM.toISOString();
    }
    eventPopupService.createEvent(eventData, target, {
      onHide: () => {
        CalendarViewService.unselect();
      },
      onCreate: (result) => {
        onCreate(result as DetailedEvent);
      },
    });
  }

  public createEventDetails(eventType: 'event' | 'absence'): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'create');
  }

  public editEventDetails(eventType: string, event: DetailedEvent): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'edit', event);
  }

  public copyEvent(eventType: string, event: DetailedEvent): void {
    const rootScope = $injector.get('$rootScope') as RootScope;
    rootScope.openCreateContentWindow(eventType, 'copy', event);
  }

  public deleteEvent(event: any): { result: Promise<void> } {
    const $uibModal = $injector.get('$uibModal') as UibModal;
    return $uibModal.open({
      component: 'cdEventDeleteModal',
      resolve: {
        event: () => event,
      },
    });
  }
}

export default new EventPopupService();
