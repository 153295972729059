'use strict';

/**
 * @desc return the type of a people message
 *
 * @example <span>{{ 'sms' | messageType }}</span>
 */ messageType.$inject = ['PeopleMessages'];

function messageType(PeopleMessages) {
  return function (input) {
    return PeopleMessages.type[input];
  };
}

angular.module('cdApp.shared').filter('messageType', messageType);
