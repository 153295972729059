class PastoralNotesListController {
  constructor(_, gettextCatalog, $uibModal, $http, $state, cdApp, Resources) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$state = $state;
    this.cdApp = cdApp;
    this.Resources = Resources;
  }

  $onInit() {
    const { cdApp, Resources } = this;

    this.masquerading = cdApp.me.masquerading;
    this.noteLimit = 200;

    this.pagination = {
      currentPage: 1,
      total: 0,
      totalPerPage: 10,
      totalPerPageOptions: [10, 25, 50],
    };

    this.searchParams = {
      organizationId: cdApp.organization.id,
      limit: 20,
      offset: 0,
      orderBy: 'createdAt',
      orderDirection: 'DESC',
      search: null,
      mode: 'my-notes',
    };

    this.loadPastoralNotes();
    // Get churches that the user has people access
    Resources.getChurches({
      permissionContext: 'people',
      permissionType: 'view',
    }).$promise.then((churches) => {
      this.churches = churches;
    });
  }

  /**
   * When the user changes the search input
   */
  onSearchChange() {
    const { _ } = this;

    if (!_.isEmpty(_.trim(this.searchParams.search))) {
      // Can only search in private notes
      this.searchParams.mode = 'my-notes';

      if (_.size(_.trim(this.searchParams.search)) >= 3) {
        this.changePage(1);
      }
    } else {
      // Parameter "search" can't be sent as an empty string to the backend
      this.searchParams.search = null;
      this.changePage(1);
    }
  }

  /**
   * When the user toggles the search mode using the dropdown
   *
   * @param {string} mode The search mode, can be 'all-notes' or 'my-notes'
   */
  onSearchModeChange(mode) {
    if (mode === 'all-notes') {
      this.searchParams.search = null;
    }

    if (this.searchParams.mode !== mode) {
      this.searchParams.mode = mode;
      this.changePage(1);
    }
  }

  /**
   * Query the backend for pastoral notes with the search parameters and pagination options
   */
  loadPastoralNotes() {
    const { _, $http, cdApp } = this;
    this.isLoading = true;
    const { totalPerPage, currentPage } = this.pagination;
    const params = _.extend({}, this.searchParams, {
      limit: totalPerPage,
      offset: (currentPage - 1) * totalPerPage,
    });

    $http
      .get(`${cdApp.config.api.main}/people/pastoral-notes`, { params })
      .then(({ data: { count, rows } }) => {
        this.pastoralNotes = rows;
        this.pagination.total = count;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Paginate to a specific page
   *
   * @param {number} page The number of the page (Starts with 1)
   */
  changePage(page) {
    this.pagination.currentPage = page;
    this.loadPastoralNotes();
  }

  /**
   * Change the total items per page
   *
   * @param {number} totalPerPage - The number of items per page
   */
  changeTotalPerPage(totalPerPage) {
    this.pagination.totalPerPage = totalPerPage;
    this.changePage(1);
  }

  /**
   * Sort list by a specific field
   *
   * @param {string} orderBy The field to sort by
   */
  setSorting(orderBy) {
    if (this.searchParams.orderBy === orderBy) {
      this.searchParams.orderDirection =
        this.searchParams.orderDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.searchParams.orderBy = orderBy;
      this.searchParams.orderDirection =
        orderBy === 'firstName' ? 'ASC' : 'DESC';
    }
    this.loadPastoralNotes();
  }

  /**
   * Returns a CSS font-awesome class for a specific column in the table
   *
   * @param {string} orderBy The field to sort by
   */
  getSortingClass(orderBy) {
    if (this.searchParams.orderBy !== orderBy) return null;
    return this.searchParams.orderDirection === 'DESC'
      ? 'fa fa-fw fa-sort-down'
      : 'fa fa-fw fa-sort-up';
  }

  /**
   * Clips the note according to the `noteLimit` variable in the constructor
   *
   * @param {Object} pastoralNote The pastoral note object
   */
  getPastoralNote(pastoralNote) {
    if (pastoralNote.note.length <= this.noteLimit) return pastoralNote.note;

    return pastoralNote.note.substring(0, this.noteLimit) + '...';
  }

  /**
   * Renders a div that has the contact's picture and full name
   *
   * @param {Object} pastoralNote The pastoral note object
   */
  getContact(pastoralNote) {
    const { _ } = this;
    const linkToContact = this.$state.href('app.private.people.contacts.view', {
      id: pastoralNote.personId,
      step: 'pastoral-notes',
    });

    const picture =
      _.get(pastoralNote.person, 'picture.url') || '/img/user-default.png';
    return `<a href="${linkToContact}">
      <img class="img-circle u-ml-5 u-mr-5" width="20" height="20" src="${picture}" />
      ${pastoralNote.person.fullName}
    </a>`;
  }

  /**
   * Launches a modal to add a new pastoral note
   */
  addPastoralNote() {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdAddPastoralNote',
        resolve: {
          churches: () => this.churches,
        },
      })
      .result.then(() => {
        this.loadPastoralNotes();
      });
  }

  deletePastoralNote(pastoralNote) {
    const { _, $http, cdApp, $uibModal, gettextCatalog } = this;

    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => gettextCatalog.getString('Are you sure?'),
          body: () =>
            gettextCatalog.getString(
              'Do you want to delete this pastoral note?'
            ),

          options: {
            confirmButtonText: gettextCatalog.getString('Delete'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        $http
          .delete(
            `${cdApp.config.api.main}/people/${pastoralNote.personId}/pastoral-notes/${pastoralNote.id}`
          )
          .then(() => {
            _.remove(this.pastoralNotes, pastoralNote);
          });
      });
  }

  /**
   * Edit an existing pastoral note
   *
   * @param {Object} pastoralNote The pastoral note object
   */
  editPastoralNote(pastoralNote) {
    const { $uibModal } = this;
    $uibModal
      .open({
        component: 'cdAddPastoralNote',
        resolve: {
          personId: () => pastoralNote.personId,
          pastoralNoteId: () => pastoralNote.id,
          note: () => pastoralNote.note,
        },
      })
      .result.then(() => {
        this.loadPastoralNotes();
      });
  }
}

PastoralNotesListController.$inject = [
  '_',
  'gettextCatalog',
  '$uibModal',
  '$http',
  '$state',
  'cdApp',
  'Resources',
];

angular.module('cdApp.people').component('cdPastoralNotesListState', {
  template: require('./pastoral-notes-list.component.html'),
  controller: PastoralNotesListController,
});
