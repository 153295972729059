(function () {
  'use strict';

  function dateFormatsLookup() {
    let formats = {
      da: {
        L: 'dd/MM/yyyy',
        LT: 'HH:mm',
        LL: 'd. MMMM yyyy',
      },

      de: {
        L: 'dd.MM.yyyy',
        LT: 'HH:mm',
        LL: 'd. MMMM yyyy',
      },

      fr: {
        L: 'dd/MM/yyyy',
        LT: 'HH:mm',
        LL: 'D MMMM YYYY',
      },

      en: {
        L: 'MM/dd/yyyy',
        LT: 'h:mm a',
        LL: 'MMMM d, yyyy',
      },

      'en-gb': {
        L: 'dd/MM/yyyy',
        LT: 'HH:mm',
        LL: 'd MMMM yyyy',
      },

      sv: {
        L: 'yyyy-MM-dd',
        LT: 'HH:mm',
        LL: 'd MMMM yyyy',
      },
    };

    return {
      getFormat: function (language, format) {
        let lang = _.toLower(
          language || _.get(cdApp, 'organization.locale.language', 'en')
        );

        return _.get(formats[lang], format || 'L');
      },
    };
  }

  angular
    .module('cdApp.shared')
    .factory('dateFormatsLookup', dateFormatsLookup);
})();
