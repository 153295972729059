'use strict';

class TextAvatarComponent {
  constructor(_, appUtils) {
    'ngInject';

    this._ = _;
    this.appUtils = appUtils;
  }

  $onInit() {
    this.size = this.size || 2;
    this.firstChar = (this._.first(this.text) || '').toUpperCase();
  }

  getBackgroundColor() {
    return this.color || this.appUtils.generateColor(this.text);
  }
}
TextAvatarComponent.$inject = ['_', 'appUtils'];

angular.module('cdApp.shared').component('cdTextAvatar', {
  templateUrl: '@/app/shared/components/text-avatar/text-avatar.component.html',
  controller: TextAvatarComponent,
  bindings: {
    text: '<',
    color: '<',
    textColor: '<',
    size: '<',
  },
});
