import storage from 'redux-persist/lib/storage';
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable';
import { createMigrate } from 'redux-persist';
import _ from 'lodash';

const transformConfig = {};
const migrations = {
  1: (state) => ({
    ...state,
    calendarSettings: {
      ...state.config.settings,
    },
  }),
};

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '2.7',
  storeConfig: {
    version: 1,
    key: _.get(window, 'churchdeskOrganizationId') || 'before-login',
    storage,
    debounce: 500,
    // blacklist: [], // reducer keys that you do NOT want stored to persistence here
    whitelist: ['calendarSettings'], // Optionally, just specify the keys you DO want stored to
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator(transformConfig)],
    migrate: createMigrate(migrations, { debug: true }),
  },
};

export default REDUX_PERSIST;
