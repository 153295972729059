import { Alert, Button, Col, Form, Row, Space } from 'antd';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { EventIcons } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { FieldSection } from '@/react/calendar/event-details/components/FieldSection';
import { EventForm } from '@/react/calendar/types/event';

export const CdSignUp = (props: {
  canEdit: boolean;
  value?: EventForm;
  onChange?: (value: EventForm) => void;
}) => {
  const { value, onChange } = props;
  const date = Form.useWatch(['date']);
  const showCdNewSignUpFormModal = () =>
    NiceModal.show('CdNewSignUpFormComponent', {
      value,
      onChange,
    });
  const showCdExistingSignUpFormModal = () =>
    NiceModal.show('CdExistingSignUpFormComponent', {
      value,
      onChange,
    });

  return (
    <FieldSection>
      <Form.Item
        label={gettextCatalog.getString('Add a sign-up form to this event')}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {gettextCatalog.getString(
              'You can either create a new sign-up form for this event or you can use an existing form that is already created in your forms module.'
            )}
          </Col>
          {date?.rrule && (
            <Col span={24}>
              <Alert
                type="info"
                message={gettextCatalog.getString(
                  'You can add a sign-up form to a single event in an event series or you can add the same sign-up form to all the events in the series, which is great for classes or similar.'
                )}
              />
            </Col>
          )}

          <Col span={24}>
            <Space size="middle">
              <Button
                onClick={showCdNewSignUpFormModal}
                icon={<EventIcons.SignUpFormNew />}
                disabled={!props.canEdit}
              >
                {gettextCatalog.getString('Add new sign-up form')}
              </Button>
              {gettextCatalog.getString('or')}
              <Button
                onClick={showCdExistingSignUpFormModal}
                icon={<EventIcons.SignUpFormExisting />}
                disabled={!props.canEdit}
              >
                {gettextCatalog.getString('Add existing sign-up form')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form.Item>
    </FieldSection>
  );
};
