(function () {
  'use strict';
  Notifications.$inject = ['$$resource'];

  function Notifications($$resource) {
    return $$resource(cdApp.config.api.main + '/notifications');
  }

  angular.module('cdApp.shared').factory('Notifications', Notifications);
})();
