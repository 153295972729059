import { Reducer } from 'redux';
import immutable from 'seamless-immutable';

import createReducer from '../../../redux/utils';
import { Collection } from '../../types/Collection';

import {
  FETCH_ALL_COLLECTIONS_SUCCESS,
  SET_COLLECTION_EDITID,
} from './Actions';

const updateCollections: Reducer = (state, { payload }) =>
  state.merge({ items: payload });

const setEditMode: Reducer = (state, { payload }) =>
  state.merge({
    editingId: payload,
  });

interface CollectionState {
  editingId: string | false;
  items: Collection[];
}

export type ImmutableCollectionState =
  immutable.ImmutableObject<CollectionState>;

const initialState: ImmutableCollectionState = immutable.from<CollectionState>({
  items: [],
  editingId: false,
});

export default createReducer<ImmutableCollectionState>(initialState, {
  [FETCH_ALL_COLLECTIONS_SUCCESS]: updateCollections,
  [SET_COLLECTION_EDITID]: setEditMode,
});
