import React, { useCallback, useState } from 'react';
import './fonts.css';
import { Select } from 'antd';
import styled from 'styled-components';

import { fonts } from './fonts';
const { Option } = Select;

const StyledSelect = styled(Select)`
  &&&& {
    height: 50px;
    font-size: 24px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      font-size: 16px;
    }
    .ant-select-clear {
      margin-right: 2px;
      font-size: 16px;
    }
    width: 100%;
  }
`;
export const CdrFontPicker = ({
  onChange,
  value,
  disabled,
}: {
  value?: string;
  onChange?: (value) => void;
  disabled?: boolean;
}) => {
  const [filteredOptions, setFilteredOptions] = useState(fonts);
  const handleSearch = useCallback((value) => {
    if (value === '') {
      setFilteredOptions(fonts);
      return;
    }
    const filtered = fonts.filter((option) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, []);
  const handleClear = useCallback(() => {
    setFilteredOptions(fonts);
  }, []);
  return (
    <StyledSelect
      showSearch
      disabled={disabled}
      allowClear
      onChange={(value) => onChange(value)}
      value={value}
      style={{ maxWidth: '250px' }}
      filterOption={false}
      onSearch={handleSearch}
      onClear={handleClear}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
    >
      {filteredOptions.map((item) => (
        <Option key={item.name}>
          <div style={{ fontSize: '20px' }} className={item.className}>
            {item.name}
          </div>
        </Option>
      ))}
    </StyledSelect>
  );
};
