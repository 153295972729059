import * as immutable from 'seamless-immutable';
import { ImmutableObject } from 'seamless-immutable';
import { Reducer } from 'redux';

import createReducer, { CdAction, ActionCreator } from '../../redux/utils';
import { Portal, PortalWidget } from '../types/portal.type';

// Actions
export const FETCH_PORTAL_SUCCESS = 'portal/fetchPortalSuccess';
export const FETCH_PORTAL_WIDGETS = 'portal/fetchPortalWidgets';
export const FETCH_PORTAL_WIDGETS_SUCCESS = 'portal/fetchPortalWidgetsSuccess';

type FetchPortalSuccess = CdAction<Portal>;
type FetchPortalWidgetsSuccess = CdAction<PortalWidget[]>;

// Action Creators
export const fetchPortalSuccessAction: ActionCreator<Portal> = (payload) => ({
  type: FETCH_PORTAL_SUCCESS,
  payload,
});

export type FetchPortalWidgetsActionType = CdAction<string>;
export const fetchPortalWidgetsAction: ActionCreator<string> = (payload) => ({
  type: FETCH_PORTAL_WIDGETS,
  payload,
});

export const fetchPortalWidgetsSuccessAction: ActionCreator<PortalWidget[]> = (
  payload
) => ({
  type: FETCH_PORTAL_WIDGETS_SUCCESS,
  payload,
});

// State
interface PortalState {
  organizationPortal: Partial<Portal>;
  portalWidgets: PortalWidget[];
}

export type ImmutablePortalState = ImmutableObject<PortalState>;

// Initial State
const initialState: ImmutablePortalState = immutable.from<PortalState>({
  organizationPortal: null,
  portalWidgets: [],
});

// Reducers
const fetchPortalSuccess: Reducer<ImmutablePortalState, FetchPortalSuccess> = (
  state = initialState,
  { payload }
) =>
  state.merge({
    organizationPortal: payload,
  });

const fetchPortalWidgetsSuccess: Reducer<
  ImmutablePortalState,
  FetchPortalWidgetsSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    portalWidgets: payload,
  });

// Handlers
const handlers = {
  [FETCH_PORTAL_SUCCESS]: fetchPortalSuccess,
  [FETCH_PORTAL_WIDGETS_SUCCESS]: fetchPortalWidgetsSuccess,
};

export default createReducer<ImmutablePortalState>(initialState, handlers);
