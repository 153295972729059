(function () {
  'use strict';
  MyNotifications.$inject = ['$$resource'];

  function MyNotifications($$resource) {
    return $$resource(cdApp.config.api.main + '/users/notifications');
  }

  angular.module('cdApp.shared').factory('MyNotifications', MyNotifications);
})();
