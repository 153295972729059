import { Button, Typography, Image } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ButtonType } from 'antd/es/button';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const { Paragraph } = Typography;

const EmptyStateContainer = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .empty-state__image__caption {
      margin-top: 1.5em;
    }
  }
`;

const EmptyStateTitle = styled.h4`
  margin-top: 1em;
  font-weight: 700;
  margin-bottom: 10px;
`;

const EmptyStateSubtitle = styled(Paragraph)`
  &&&& {
    margin-top: 0.5em;
    white-space: pre-wrap;
  }
`;

const EmptyStateImage = styled(Image)`
  &&&& {
    width: 65vw;
    margin: 1.5vw auto 0;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    border: 1px solid #eee;
  }
`;
const EmptyStateImageCaption = styled(Paragraph)`
  &&&& {
    margin-top: 1.5em;
  }
`;

export interface CdrEmptyStateProps {
  title: JSX.Element | string;
  EmptyStateIcon?: JSX.Element;
  subtitle?: JSX.Element | string;
  hideButton?: boolean;
  buttonText?: string;
  buttonType?: ButtonType; // Ant button types: Ex. Primary, default, etc
  buttonDisabled?: boolean;
  onButtonClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  image?: string;
  hideImage?: boolean;
  imageCaption?: string;
  buttonAction?: JSX.Element;
}

const CdrEmptyState: FunctionComponent<CdrEmptyStateProps> = ({
  title,
  EmptyStateIcon,
  subtitle,
  hideButton,
  buttonText,
  buttonType,
  buttonDisabled,
  onButtonClick,
  image,
  hideImage,
  imageCaption,
  buttonAction,
}) => (
  <EmptyStateContainer className="empty-state">
    {EmptyStateIcon &&
      React.cloneElement(EmptyStateIcon, { size: '3x' as SizeProp })}
    {title && <EmptyStateTitle>{title}</EmptyStateTitle>}
    {subtitle && <EmptyStateSubtitle>{subtitle}</EmptyStateSubtitle>}
    {hideButton || !buttonText || !onButtonClick ? null : (
      <Button
        type={buttonType || 'primary'}
        onClick={onButtonClick}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
    )}
    {image && !hideImage && <EmptyStateImage src={image} />}
    {image && !hideImage && imageCaption && (
      <EmptyStateImageCaption>{imageCaption}</EmptyStateImageCaption>
    )}
    {buttonAction && React.cloneElement(buttonAction)}
  </EmptyStateContainer>
);

export default CdrEmptyState;
