(function () {
  'use strict';
  WorkplanCalendarController.$inject = [
    'moment',
    '$rootScope',
    '$scope',
    '$http',
    '$timeout',
    'gettextCatalog',
    'toastr',
    '_',
  ];

  function WorkplanCalendarController(
    moment,
    $rootScope,
    $scope,
    $http,
    $timeout,
    gettextCatalog,
    toastr,
    _
  ) {
    $scope.hide = true;
    $scope.timeHeader = [];
    $scope.wrongView = false;

    // Retrieve the work types.
    // '/api/work-plan/types.json'
    $http
      .get(cdApp.config.api.main + '/workplan/types')
      .success(function (workTypes) {
        $scope.workTypes = workTypes;
      })
      .error(function () {
        toastr.error(
          gettextCatalog.getString(
            'Problem occurred during work plan types fetching. Contact system administrator.'
          )
        );
      });

    var lang = _.get(cdApp, 'organization.locale.language');
    var separator =
      lang && (lang.toLowerCase() === 'en-gb' || lang.toLowerCase() === 'en')
        ? ':'
        : '.';

    // Prep the table header
    for (var i = 12; i < 48; i = i + 2) {
      if (i % 2 === 0) {
        $scope.timeHeader.push({
          label: i / 2 + separator + '00',
          leftBlock: i,
          rightBlock: i + 1,
        });
      }
    }

    $scope.all_data = [];
    $scope.initFilters = function (view) {
      if (_.includes(['agendaDay', 'resourceDay'], view)) {
        $scope.wrongView = false;
        $scope.hide = !_.includes(
          _.get(cdApp, 'data.calendar.settings.render'),
          'workplanOverview'
        );
      } else {
        $scope.hide = true;
        $scope.wrongView = _.includes(
          _.get(cdApp, 'data.calendar.settings.render'),
          'workplanOverview'
        );
      }

      $timeout(function () {
        $rootScope.$broadcast('invalidation');
      });
    };

    $scope.$on('updateFilter', function (event, args) {
      $scope.initFilters(args.view);
    });

    // this function is invoked by fullcalendar when the workplan is toggled on
    angular.forEach(
      ['timeUpdate', 'filterUpdate', 'initWorkplanView'],
      function (value) {
        $scope.$on(value, function (event, args) {
          if (
            args.view &&
            _.includes(['agendaDay', 'resourceDay'], args.view)
          ) {
            // Needed for the form to create the workplan.
            var date = $('#fullCalendar').fullCalendar('getDate');
            $scope.calendarCurrentYear = date.format('YYYY');
            $scope.calendarCurrentMonth = date.format('MM');
            $scope.lastFetch = args.start.format('X');

            if ($scope.all_data[args.start.format('X')]) {
              $scope.render_data(
                $scope.all_data[args.start.format('X')],
                _.get(cdApp, 'data.calendar.filters.users'),
                args.view
              );
            } else {
              // '/api/work-plan/calendar/data'
              $http
                .get(
                  cdApp.config.api.main +
                    '/workplan/calendar/' +
                    [
                      args.start.format('YYYY'),
                      args.start.format('M'),
                      args.start.format('D'),
                    ].join('/')
                )
                .success(function (data) {
                  // Convert data to correct time zone.
                  (data || []).forEach(function (item) {
                    for (var key in item.work_types) {
                      if (item.work_types.hasOwnProperty(key)) {
                        var obj = item.work_types[key];
                        if (obj.time) {
                          obj.time = _.map(obj.time, function (time) {
                            // Convert time to the corect time zone.
                            var hours = time / 2;
                            var minutes = (time % 2) * 30;
                            var utcDate = moment
                              .utc()
                              .year(item.year)
                              .month(item.month - 1)
                              .date(item.day)
                              .hour(parseInt(hours, 10))
                              .minutes(minutes)
                              .seconds(0)
                              .toISOString();
                            var localDate = new Date(utcDate);
                            var timeSlot =
                              localDate.getHours() * 2 +
                              (localDate.getMinutes() === 0 ? 0 : 1) * 1;
                            return timeSlot;
                          });
                        }
                      }
                    }
                  });

                  $scope.all_data[args.start.format('X')] = data;
                  $scope.render_data(
                    data,
                    _.get(cdApp, 'data.calendar.filters.users'),
                    args.view
                  );
                });
            }
          } else {
            $scope.initFilters(args.view);
          }
        });

        $scope.formatTime = function (start, end) {
          var time = '';
          var start_time = start / 2;
          if (start_time % 1 == 0) {
            time += start_time + separator + '00';
          } else {
            time += start_time - (start_time % 1) + separator + '30';
          }
          var end_time = (end + 1) / 2;
          if (end_time % 1 == 0) {
            time += ' - ' + end_time + separator + '00';
          } else {
            time += ' - ' + (end_time - (end_time % 1)) + separator + '30';
          }
          return time;
        };

        $scope.render_data = function (data, filters, view) {
          var returnData = {};
          var count = 0;
          _.each(data, function (value) {
            var key = value.userId;
            if (!filters[key] || !filters[key].checked) {
              return;
            }

            var timeFrames = [];
            returnData[count] = value;

            _.each(value['work_types'], function (work) {
              work.time = work.time.sort();
              var start = _.first(work.time);
              var previous = null;

              for (var i = 0; i < work.time.length || 0; i++) {
                if (previous == null) {
                  previous = work.time[i];
                  continue;
                }

                if (i == work.time.length - 1) {
                  timeFrames.push({
                    start: start,
                    end: work.time[i],
                    showTime: $scope.formatTime(start, work.time[i]),
                  });

                  continue;
                }

                if (work.time[i] - 1 != previous) {
                  // add the values because found
                  timeFrames.push({
                    start: start,
                    end: previous,
                    showTime: $scope.formatTime(start, previous),
                  });

                  start = work.time[i];
                  previous = null;
                } else {
                  previous = work.time[i];
                }
              }
            });

            returnData[count++]['timeFrame'] = timeFrames;
          });

          $scope.work_data = returnData;
          $scope.initFilters(view);

          // If there is nothing to show, hide the table.
          $scope.hasData = _.size(returnData) > 0 ? true : false;

          $timeout(function () {
            $rootScope.$broadcast('invalidation');
          });

          // Apply scrollbar
          $timeout(function () {
            $(
              '#workplan .fullcalendar-workplan-inner-scroll'
            ).perfectScrollbar();
          }, 2000);
        };

        $scope.colorizeWorkType = function (workTypesData, time) {
          var backColor = '#FFF';
          angular.forEach(workTypesData, function (x) {
            var color = x.color;
            if ($.inArray(time, x.time) > -1) {
              backColor = color;
            }
          });
          return backColor;
        };

        $scope.getWorkTypeTime = function (data, time) {
          var showTime = null;
          if (data.timeFrame) {
            angular.forEach(data.timeFrame, function (value) {
              if (value.start <= time && time <= value.end) {
                showTime = value.showTime;
              }
            });
          }
          return showTime;
        };

        // Initial rendering.
        $timeout(function () {
          // Update the calendar on the load.
          var fullcalendarObject = $('#fullCalendar').fullCalendar('getView');
          $scope.$broadcast('initWorkplanView', {
            view: fullcalendarObject.name,
            start: fullcalendarObject.start,
            end: fullcalendarObject.end,
          });

          $scope.$broadcast('updateFilter', {
            view: fullcalendarObject.name,
          });
        }, 500);
      }
    );
  }

  angular
    .module('cdApp.calendar')
    .controller('WorkplanCalendarController', WorkplanCalendarController)
    .controller('absenceViewCtrl', [
      '$rootScope',
      '$scope',
      '$http',
      '$timeout',
      'gettextCatalog',
      '$sce',
      function ($rootScope, $scope, $http, $timeout, gettextCatalog, $sce) {
        'ngInject';

        var lang = _.get(cdApp, 'organization.locale.language');

        $scope.hide = true;
        $scope.all_data = [];
        $scope.wrongView = false;

        $scope.initFilters = function (view) {
          if (view === 'agendaDay' || view === 'resourceDay') {
            $scope.wrongView = false;
            $scope.hide = !_.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'absenceOverview'
            );
          } else {
            $scope.hide = true;
            $scope.wrongView = _.includes(
              _.get(cdApp, 'data.calendar.settings.render'),
              'absenceOverview'
            );
          }

          $timeout(function () {
            $rootScope.$broadcast('invalidation');
          });
        };

        $scope.$on('updateFilter', function (event, args) {
          $scope.initFilters(args.view);
        });

        angular.forEach(
          ['timeUpdate', 'filterUpdate', 'absenceCreate', 'initAbsenceView'],
          function (value) {
            $scope.$on(value, function (event, args) {
              // Do stuff only on agendaDay and resourceDay views.
              if (args.view === 'agendaDay' || args.view === 'resourceDay') {
                // Get the list of the calendar events.
                var absences = _.filter(
                  $('#fullCalendar').fullCalendar('clientEvents') || [],
                  function (item) {
                    if (item.allDay) {
                      return (
                        item.type === 'absence' &&
                        (moment(item.start.toISOString()).isSame(
                          args.start.toISOString()
                        ) ||
                          moment(args.start.toISOString()).isBetween(
                            item.start.toISOString(),
                            item.end.toISOString()
                          ))
                      );
                    }

                    return item.type === 'absence';
                  }
                );

                $scope.render_data(absences, args.view);
              } else {
                // hide absence overview
                $scope.initFilters(args.view);
              }
            });
          }
        );

        var i18n = {
          columnFormat: {
            da: {
              week: 'ddd D.M',
            },

            de: {
              week: 'dd, D.M.',
            },

            'en-gb': {
              week: 'ddd D/M',
            },
          },

          dateFormat: {
            da: 'DD.MM.YYYY',
            de: 'DD.MM.YYYY',
            'en-gb': 'DD/MM/YYYY',
            en: 'DD/MM/YYYY',
          },

          timeFormat: {
            da: 'HH.mm',
            de: 'HH:mm',
            'en-gb': 'HH:mm',
            en: 'HH:mm',
          },
        };

        $scope.formatDate = function (startDate, endDate, allDay) {
          var start = moment(startDate);
          var end = moment(endDate);

          var dateFormat = 'L';
          var timeFormat = 'LT';

          allDay = allDay || false;

          // Format the date to be stored correctly.
          if (allDay) {
            start = new Date(
              start.startOf('day').format('YYYY/MM/DD HH:mm:ss')
            ).toISOString();
            end = new Date(
              end
                .clone()
                .subtract(1, 'days')
                .endOf('day')
                .format('YYYY/MM/DD HH:mm:ss')
            ).toISOString();
          } else {
            start = start.toISOString();
            end = end.toISOString();
          }

          // Required for the allday date rendering.
          var allDayendDate = allDay
            ? moment(end).clone().subtract(1, 'days').add(1, 'second')
            : end;

          var stringBuilder = '';
          // Check if event is allDay event.
          if (allDay) {
            // Check how many days the event last.
            if (allDayendDate.diff(moment(start), 'days') == 0) {
              // Single day.
              stringBuilder +=
                '<span>' +
                [
                  moment(start).format(dateFormat),
                  gettextCatalog.getString('(all day)'),
                ].join(' ') +
                '</span>';
            } else {
              // Multiple days.
              stringBuilder +=
                '<span>' +
                [
                  moment(start).format(dateFormat),
                  gettextCatalog.getString('(all day)'),
                ].join(' ') +
                '</span>';
              stringBuilder +=
                '<strong> ' +
                gettextCatalog.getString('to', {}, 'Date interval') +
                ' </strong>';
              stringBuilder +=
                '<span>' +
                [
                  moment(end)
                    .clone()
                    .subtract(1, 'days')
                    .add(1, 'second')
                    .format(dateFormat),
                  gettextCatalog.getString('(all day)'),
                ].join(' ') +
                '</span>';
            }
          } else {
            // This is not all day event.
            // Event is the single day still.
            if (moment(end).diff(moment(start), 'days') == 0) {
              // Same day event, don't show date twice.
              stringBuilder +=
                '<span>' + moment(start).format('L LT') + '</span>';
              stringBuilder +=
                '<strong> ' +
                gettextCatalog.getString('to', {}, 'Date interval') +
                ' </strong>';
              stringBuilder += '<span>' + moment(end).format('LT') + '</span>';
            } else {
              // Multiple days event, repeat the date.
              stringBuilder +=
                '<span>' + moment(start).format('L LT') + '</span>';
              stringBuilder +=
                '<strong> ' +
                gettextCatalog.getString('to', {}, 'Date interval') +
                ' </strong>';
              stringBuilder +=
                '<span>' + moment(end).format('L LT') + '</span>';
            }
          }
          return $sce.trustAsHtml(stringBuilder);
        };

        // Check the filters before render values.
        $scope.render_data = function (data, view) {
          var returnData = _.filter(data, function (item) {
            var user = _.get(cdApp, [
              'data',
              'calendar',
              'filters',
              'users',
              _.first(_.keys(item.filters.users)),
            ]);

            var absence = _.get(cdApp, [
              'data',
              'calendar',
              'filters',
              'absences',
              _.first(_.keys(item.filters.absences)),
            ]);

            return (absence && absence.checked) || (user && user.checked);
          });
          $scope.hasData = !!_.size(returnData);
          $scope.absenceList = _.map(returnData, function (element) {
            var user = _.get(cdApp, [
              'data',
              'calendar',
              'filters',
              'users',
              _.first(_.keys(element.filters.users)),
            ]);

            var absence = _.get(cdApp, [
              'data',
              'calendar',
              'filters',
              'absences',
              _.first(_.keys(element.filters.absences)),
            ]);

            return {
              name: (user && user.name) || '',
              date: $scope.formatDate(
                element.start,
                element.end,
                element.allDay
              ),

              color: (absence && absence.color) || 0,
              category: absence && absence.name,
              substitute: element.substitute,
              comment: element.comment,
            };
          });
          $scope.initFilters(view);

          // If there is nothing to show, hide the table.
          $scope.filtersHideAllData = _.size(returnData) > 0 ? false : true;

          $timeout(function () {
            $rootScope.$broadcast('invalidation');
          });

          // Apply scrollbar
          $timeout(function () {
            // it should not be run on a timeout

            // Check if the instance of perfrectscrollar is created already.
            if ($('#absenceView .perfect-scrollbar').hasClass('ps-container')) {
              // Check if the height is less then 65
              if ($('#absenceView .perfect-scrollbar').height() < 65) {
                // Then we need to scroll to the top of the scroller.
                // This is necessary because we have limited space of the view,
                // so then we scroll down, and let's say unselect some absences
                // the list is regenerated and it might be that the scroll position
                // is not correct, thats why we need to scroll to the top, otherwise
                // the data is not visible and might confuse customers.
                $('#absenceView .perfect-scrollbar').scrollTop(0);
              }
              // Update the scrollbar without new instance creating.
              $('#absenceView .perfect-scrollbar').perfectScrollbar('update');
            } else {
              // Create the instance of perfect scrollbar.
              $('#absenceView .perfect-scrollbar').perfectScrollbar();
            }
          }, 1000);
        };

        // Initial rendering.
        $timeout(function () {
          var fullcalendar = $('#fullCalendar').fullCalendar('getView');
          $scope.$broadcast('initAbsenceView', {
            view: fullcalendar.name,
            start: fullcalendar.start,
            end: fullcalendar.end,
          });

          $scope.$broadcast('updateFilter', {
            view: fullcalendar.name,
          });
        }, 500);
      },
    ]);
})();
