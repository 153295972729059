import React from 'react';
import { Modal, Form, DatePicker, TimePicker, Space, FormProps } from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import moment from 'moment';
import { Moment } from 'moment/moment';

import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { CdSchedule } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { PeopleMessage } from '@/react/people/types/message';

const DatePickerMoment =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);

type SendConfirmModalProps = {
  sendMessage: (message: PeopleMessage, when?: Date | moment.Moment) => void;
  message: PeopleMessage;
};
export const ScheduleMailSendModal = NiceModal.create(
  ({ sendMessage, message }: SendConfirmModalProps) => {
    const modal = useModal('ScheduleMailSendModal');
    const [form] = Form.useForm();
    const formItemLayout: FormProps = {
      layout: 'horizontal',
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const datePickerFormat = gettextCatalog.getLongDateFormat();
    const config = {
      rules: [
        {
          type: 'object' as const,
          required: true,
          message: gettextCatalog.getString('Please select time!'),
        },
        ({ getFieldValue }) => ({
          validator() {
            const date = getFieldValue('date-picker');
            const time = getFieldValue('time-picker');
            if (date && time) {
              date.minute(time.minute());
              date.hour(time.hour());
              if (date.isBefore(moment())) {
                return Promise.reject(
                  'The scheduled date cannot be in the past.'
                );
              }
            }
            return Promise.resolve();
          },
        }),
      ],
    };

    const format = 'HH:mm';

    return (
      <>
        <Modal
          {...antdModalV5(modal)}
          title={
            <Space>
              <CdSchedule />
              {gettextCatalog.getString('Schedule message')}
            </Space>
          }
          maskTransitionName="maskTransitionName"
          destroyOnClose
          onOk={() =>
            form.validateFields().then((values) => {
              const hours = values['time-picker'].hour();
              const minutes = values['time-picker'].minute();
              const scheduled = values['date-picker']
                .hour(hours)
                .minute(minutes);
              sendMessage(message, scheduled);
              modal.hide();
            })
          }
          onCancel={modal.hide}
          okText={gettextCatalog.getString('Schedule')}
          cancelText={gettextCatalog.getString('Cancel')}
        >
          <CdVerticalSpace>
            {gettextCatalog.getString(
              'Schedule a message to be sent automatically at any time you choose.'
            )}
            <Form
              name="people_message_confirm_modal"
              form={form}
              {...formItemLayout}
            >
              <Form.Item
                name="date-picker"
                label={gettextCatalog.getString('Date')}
                {...config}
              >
                <DatePickerMoment
                  disabledDate={(d) =>
                    !d || d.isBefore(moment().startOf('day').subtract('1 day'))
                  }
                  style={{ width: '100%' }}
                  format={datePickerFormat}
                />
              </Form.Item>
              <Form.Item
                name="time-picker"
                label={gettextCatalog.getString('Time')}
                {...config}
                dependencies={['date-picker']}
              >
                <TimePicker
                  format={format}
                  style={{ width: '100%' }}
                  showNow={false}
                />
              </Form.Item>
            </Form>
          </CdVerticalSpace>
        </Modal>
      </>
    );
  }
);
