'use strict';

angular.module('cdApp.people').factory('PeopleSegments', [
  'moment',
  '$$resource',
  '_',
  (moment, $$resource, _) => {
    'ngInject';

    return $$resource(
      `${cdApp.config.api.main}/people/segments/:id`,
      {},
      {
        query: {
          method: 'GET',
          isArray: true,
          transformResponse: (response) => {
            const lists = angular.fromJson(response);
            if (!lists) return;

            _.each(lists, (list) => {
              _.each(list.setup, (object) => {
                if (
                  object.active &&
                  moment(object.value, moment.ISO_8601).isValid()
                ) {
                  object.value = moment(object.value).toDate();
                }
              });
            });

            return lists;
          },
        },
        getPublicNewsletterLists: {
          method: 'GET',
          url: `${cdApp.config.api.main}/people/segments/public/newsletter-lists`,
          isArray: false,
        },
      }
    );
  },
]);
