'use strict';

class PeopleMessageListComponent {
  constructor(
    _,
    $filter,
    $state,
    $stateParams,
    PeopleMessages,
    paginationOptions,
    stepsFactory,
    gettextCatalog,
    Resources,
    $ngRedux,
    $scope,
    FeatureToggleService,
    cdApp
  ) {
    'ngInject';

    this._ = _;
    this.$filter = $filter;
    this.$state = $state;
    this.PeopleMessages = PeopleMessages;
    this.gettextCatalog = gettextCatalog;
    this.Resources = Resources;
    this.cdApp = cdApp;
    this.FeatureToggleService = FeatureToggleService;

    this.messages = [];
    this.loading = false;
    this.statusColors = {
      email: {
        sent: 'text-muted',
        opened: 'text-primary',
        clicked: 'text-warning',
      },

      sms: { sent: 'text-muted', delivered: 'text-success', '': '' },
    };

    this.pagination = {
      currentPage: $stateParams.page,
      total: 0,
      totalPerPageOptions: [10, 25, 50],
      storageKey: 'peopleMessages',
    };

    const savedValue = paginationOptions.itemsPerPage.get(
      this.pagination.storageKey
    );

    if (!savedValue) {
      this.pagination.totalPerPage = 25;
      paginationOptions.itemsPerPage.set(
        this.pagination.storageKey,
        this.pagination.totalPerPage
      );
    } else {
      this.pagination.totalPerPage = _.parseInt(savedValue);
    }

    this.stepsInstance = new stepsFactory(
      [
        {
          key: 'sent',
          title: gettextCatalog.getString('Sent'),
          description: gettextCatalog.getString(
            'Messages that have already been sent'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'scheduled',
          title: gettextCatalog.getString('Scheduled'),
          description: gettextCatalog.getString(
            'Messages that have been scheduled to be sent later'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'draft',
          title: gettextCatalog.getString('Drafts'),
          description: gettextCatalog.getString(
            'Messages that have not yet been sent or scheduled'
          ),

          isEnabled: true,
          isShown: true,
        },
      ],

      {
        initialStep: $stateParams.tab,
        onChange: (index) => {
          const tab = this.stepsInstance.steps[index].key;

          this.pagination.currentPage = 1;
          $state.go($state.current.name, {
            tab,
            page: this.pagination.currentPage,
          });

          this.fetchMessages();
        },
      }
    );

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  $onInit() {
    this.selectedChurches = [];
    this.showChurchSelector = this.cdApp.showChurchSelector;

    this.loading = true;
    this.Resources.getChurchesV2({
      permissionContext: 'people',
      permissionType: 'communicate',
    }).$promise.then(({ churches }) => {
      this.churches = churches;
      this.fetchMessages();
    });
  }

  /**
   * Fetch a list of messages
   *
   * @param {String} status The status of the messages returned. Can be "sent", "scheduled" or "draft".
   */
  fetchMessages(status = this.stepsInstance.current.key) {
    this.loading = true;

    const search = this.searchText || undefined;
    const limit = this.pagination.totalPerPage;
    const offset =
      (this.pagination.currentPage - 1) * this.pagination.totalPerPage;
    const churchIds = this.selectedChurches;

    this.PeopleMessages.getAll({ search, status, limit, offset, churchIds })
      .$promise.then((res) => {
        this.messages = res.messages;
        this.pagination.total = res.total;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  clearChurchSelection() {
    this.selectedChurches = [];
    this.fetchMessages();
  }

  getMessageLink(message, display = 'view', filter) {
    switch (message.type) {
      case 'email':
      case 'sms':
        return this.$state.href(`app.private.people.messages.${display}`, {
          id: message.id,
          filter,
        });

      default:
        return this.$state.href(
          `app.private.people.messages.editorV2.${display}`,
          {
            type: message.type,
            messageId: message.id,
            display,
            filter,
          }
        );
    }
  }

  /**
   * Get either the title of the message or a snippet of its content
   *
   * @param {Object} message The message object
   */
  getMessageTitle(message) {
    const length = 40;

    if (message.title) {
      return this._.truncate(message.title, { length });
    }

    if (!message.title && message.content && message.type === 'sms') {
      const sanitized = this.$filter('stripHtml')(message.content);
      return this._.truncate(sanitized, { length });
    }

    return this.gettextCatalog.getString('Untitled');
  }

  /**
   * Change the current page
   *
   * @param {Number} page The index of the new page
   */
  changePage(page) {
    this.pagination.currentPage = page;
    this.$state.go(this.$state.current.name, { page });
    this.fetchMessages();
  }

  getFilterLabel() {
    const total = _.size(this.selectedChurches);
    return this.gettextCatalog.getPlural(
      total,
      '1 parish',
      '{{ $count }} parishes',
      {
        $count: total,
      }
    );
  }

  /**
   * Set the selected filter items
   *
   * @param {Array} selectedItems The filter items that are selected
   */
  updateFilteredMessages(selectedItems) {
    this.selectedChurches = selectedItems;
    this.fetchMessages();
  }

  /**
   * Change the number of events per page
   *
   * @param {Number} totalPerPage The new total of items per page
   */
  changeTotalPerPage(totalPerPage) {
    this.pagination.currentPage = 1;
    this.pagination.totalPerPage = totalPerPage;
    this.fetchMessages();
  }

  /**
   * Lifecycle hook called when a ui-router parameter is changed, i.e. when navigating back and forth
   */
  uiOnParamsChanged(newParams) {
    if (newParams.tab && newParams.tab !== this.tab) {
      const tab = newParams.tab;
      const stepExists = this._.includes(
        this._.map(this.stepsInstance.steps, 'key'),
        tab
      );

      if (stepExists) {
        this.stepsInstance.go(tab);
      }
    }
    if (newParams.page && newParams.page !== this.pagination.currentPage) {
      this.changePage(newParams.page);
    }
  }
}

PeopleMessageListComponent.$inject = [
  '_',
  '$filter',
  '$state',
  '$stateParams',
  'PeopleMessages',
  'paginationOptions',
  'stepsFactory',
  'gettextCatalog',
  'Resources',
  '$ngRedux',
  '$scope',
  'FeatureToggleService',
  'cdApp',
];

angular.module('cdApp.people').component('cdPeopleMessageListState', {
  templateUrl: '@/app/people/message-list/message-list.component.html',
  controller: PeopleMessageListComponent,
});
