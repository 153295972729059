angular.module('cdApp.shared').service('documentTitleService', function () {
  'ngInject';

  class DocumentTitleService {
    set(title) {
      document.title = title;
    }
  }

  return new DocumentTitleService();
});
