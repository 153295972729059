import _ from 'lodash';
import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import { ChurchdeskForm } from '@/react/forms/models/forms';
import { handleError } from '@/react/services/ErrorHandlingService';

export const CalenderExistingFormListQuery = selectorFamily<
  ChurchdeskForm[],
  { searchQuery: string }
>({
  key: 'CalenderExistingFormListQuery',
  get:
    ({ searchQuery }) =>
    async () => {
      const response = await mainApi.get<ChurchdeskForm[]>(
        '/calendar/forms/search',
        {
          title: searchQuery,
        }
      );

      if (response.ok) {
        return _.get(response, 'data');
      }
      handleError(response);
    },
});
