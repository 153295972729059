import _ from 'lodash';

import { mainApi } from '../../api';
import {
  IntentionReportTemplate,
  IntentionReportTemplateTypes,
} from '../models/report-template';

export interface IntentionReportTemplatesData {
  items: IntentionReportTemplate[];
  count: number;
}

class IntentionReportTemplateService {
  public async getIntentionReportTemplates({
    type,
  }: {
    type?: IntentionReportTemplateTypes;
  } = {}): Promise<IntentionReportTemplatesData> {
    const payload: any = {};
    if (type) {
      payload.types = [type];
    }
    const response = await mainApi.get('/intention/report-templates', payload);
    if (response.ok) {
      return response.data as IntentionReportTemplatesData;
    }
    throw response.data;
  }

  public async getIntentionReportTemplate(
    id: string
  ): Promise<IntentionReportTemplate> {
    const response = await mainApi.get(`/intention/report-templates/${id}`);
    if (response.ok) {
      return response.data as IntentionReportTemplate;
    }
    throw response.data;
  }

  public async createIntentionReportTemplate(
    intentionReportTemplate: IntentionReportTemplate
  ): Promise<void> {
    const response = await mainApi.post(
      '/intention/report-templates',
      intentionReportTemplate
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateIntentionReportTemplate(
    id: string,
    intentionReportTemplate: IntentionReportTemplate
  ): Promise<void> {
    const response = await mainApi.put(
      `/intention/report-templates/${id}`,
      intentionReportTemplate
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteIntentionReportTemplate(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/report-templates/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async fetchIntentionReportTemplateDownloadToken(): Promise<string> {
    const response = await mainApi.get(
      '/intention/report-templates/download-token'
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }
}

export default new IntentionReportTemplateService();
