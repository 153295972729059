'use strict';

class AttendanceComponent {
  constructor(
    $q,
    $rootScope,
    $timeout,
    $state,
    $stateParams,
    $uibModal,
    $filter,
    stepsFactory,
    Analytics,
    Users,
    Attendance,
    Calendar,
    Taxonomies,
    Resources,
    paginationOptions,
    cdApp,
    gettextCatalog,
    _,
    moment,
    appUtils,
    cdResourceColors
  ) {
    'ngInit';

    const that = this;

    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.Analytics = Analytics;
    this.Users = Users;
    this.Attendance = Attendance;
    this.Calendar = Calendar;
    this.Taxonomies = Taxonomies;
    this.Resources = Resources;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
    this._ = _;
    this.moment = moment;
    this.appUtils = appUtils;
    this.cdResourceColors = cdResourceColors;

    this.search = {};
    this.ordering = {
      field: 'startDate',
      reverse: true,
    };

    this.pagination = {
      currentPage: $stateParams.page,
      total: 0,
      totalPerPageOptions: [10, 25, 50],
      storageKey: 'attendanceEvents',
    };

    const savedValue = paginationOptions.itemsPerPage.get(
      this.pagination.storageKey
    );

    if (!savedValue) {
      this.pagination.totalPerPage = 10;
      paginationOptions.itemsPerPage.set(
        this.pagination.storageKey,
        this.pagination.totalPerPage
      );
    } else {
      this.pagination.totalPerPage = _.parseInt(savedValue);
    }

    this.quickDateSuggestions = [
      {
        key: 'currentyear',
        label: gettextCatalog.getString('Current Year'),
        from: moment().startOf('year').toDate(),
        until: moment().endOf('day').toDate(),
      },

      {
        key: 'week',
        label: this.gettextCatalog.getString('Past week'),
        from: moment().subtract(1, 'week').startOf('day').toDate(),
        until: moment().endOf('day').toDate(),
      },

      {
        key: 'month',
        label: this.gettextCatalog.getString('Past month'),
        from: moment().subtract(1, 'month').startOf('day').toDate(),
        until: moment().endOf('day').toDate(),
      },

      {
        key: 'year',
        label: this.gettextCatalog.getString('Past year'),
        from: moment().subtract(1, 'year').startOf('day').toDate(),
        until: moment().endOf('day').toDate(),
      },
    ];

    const defaultDateSuggestion = _.find(this.quickDateSuggestions, {
      key: 'month',
    });

    this.viewData = {
      events: [],
      loading: false,
      loadingTotal: false,
      hideFilters: false,
      showSognDk: this.cdApp.organization.countryIso2 === 'dk',
      yearSelectorOpen: false,
      yearSelectorOptions: {
        datepickerMode: 'year',
        minMode: 'year',
        maxMode: 'year',
        maxDate: moment().endOf('year').toDate(),
      },
    };

    this.datepickerMaxDate = moment().startOf('day').toDate();

    this.filters = {
      categories: [],
      resources: [],
      eventsWithoutResourcesInChurchIds: [],
      attendanceCategories: [],
      from: defaultDateSuggestion.from,
      until: defaultDateSuggestion.until,
      year: moment().endOf('year').toDate(),
    };

    this.charts = {
      yearOverview: {
        labels: moment.monthsShort(),
        baseColors: [
          cdResourceColors['2'], // light green
          cdResourceColors['6'], // orange
        ],
        options: {
          legend: {
            display: true,
            onClick(e, legendItem) {
              const defaultClickHandler =
                window.Chart.defaults.global.legend.onClick;
              const datasetIndex = legendItem.datasetIndex;
              const previousYearDatasetIndex = 0;
              const chart = this.chart;

              // If clicking on the previous year dataset or if there is only one other dataset,
              // then we do not have to recalculate the previous year, so we will only toggle the visibility.
              if (
                datasetIndex === previousYearDatasetIndex ||
                chart.data.datasets.length <= 2
              ) {
                defaultClickHandler.call(this, e, legendItem);
              } else {
                // Toggle the visibility of the current dataset.
                chart.data.datasets[datasetIndex].hidden =
                  !chart.data.datasets[datasetIndex].hidden;

                // Recalculate the previous year data so it includes/excludes the toggled dataset.
                const fields = that
                  ._(chart.data.datasets)
                  .filter((dataset) => dataset.tracker && !dataset.hidden)
                  .map('tracker')
                  .value();
                const yearOverviewChartData =
                  that.getYearOverviewChartData(fields);
                chart.data.datasets[previousYearDatasetIndex].data =
                  yearOverviewChartData.data[previousYearDatasetIndex];
                that.charts.yearOverview.data[previousYearDatasetIndex] =
                  yearOverviewChartData.data[previousYearDatasetIndex];

                chart.update();
              }
            },
          },

          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],

            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    };

    this.stepsInstance = new stepsFactory(
      [
        {
          key: 'new',
          title: gettextCatalog.getString('Missing attendance'),
          description: gettextCatalog.getString(
            'Events with no registered attendance'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'current',
          title: gettextCatalog.getString('Edit and export'),
          description: gettextCatalog.getString(
            'Create reports for already registered attendance'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'insights',
          title: gettextCatalog.getString('Attendance charts'),
          description: gettextCatalog.getString(
            'Gain insights by visualizing attendance in charts'
          ),

          isEnabled: true,
          isShown: true,
        },
      ],

      {
        initialStep: $stateParams.tab,
        onChange: (index) => {
          const key = this.stepsInstance.steps[index].key;

          this.pagination.currentPage = 1;
          $state.go('app.private.calendar.attendance', {
            tab: key,
            page: this.pagination.currentPage,
          });

          if (this.viewData.isOnboarded) {
            if (key === 'insights') {
              this.fetchInsights();
            } else {
              this.fetchEvents(key === 'new');
            }
          }
        },
      }
    );
  }

  $onInit() {
    const _ = this._;

    this.viewData.isOnboarded = !this._.isEmpty(this.attendanceFields);
    if (!this.viewData.isOnboarded) return;

    if (this.$stateParams.tab === 'insights') {
      this.fetchInsights();
    } else {
      this.fetchEvents();
    }

    // Fetch additional resources
    this.$q
      .all({
        users: this.Users.query().$promise,
        taxonomies: this.Taxonomies.query().$promise,
        resources: this.Resources.query().$promise,
      })
      .then(({ users, taxonomies, resources }) => {
        this.viewData.users = _(users)
          .filter({ status: 1 })
          .map((user) => ({ id: user.id, name: this.$filter('getName')(user) }))
          .value();

        this.viewData.resources = resources;
        this.viewData.categories = _.filter(taxonomies, {
          type: 'event',
          registerAttendance: true,
        });
      });

    this.Users.query().$promise.then((users) => {
      this.viewData.users = _(users)
        .filter({ status: 1 })
        .map((user) => ({ id: user.id, name: this.$filter('getName')(user) }))
        .value();
    });
    this.viewData.attendanceCategoriesGrouped = _.groupBy(
      this.attendanceCategories,
      'group'
    );
  }

  /**
   * Get a list of events for registering attendance
   *
   * @param {Boolean} notRegistered Whether to get events that already have attendance data or events that don't have attendance data.
   * @param {Boolean} hideFilters Whether to hide the filters while the events are being fetched. We want to hide them when switching tabs, but not when filtering.
   * @param {Boolean} fetchTotal Whether to fetch the total numbers or not.
   */
  fetchEvents(
    notRegistered = this.$stateParams.tab === 'new',
    hideFilters = true,
    fetchTotal = true
  ) {
    this.viewData.loading = true;
    this.viewData.hideFilters = hideFilters;

    const queryParams = this._.extend(
      {},
      {
        limit: this.pagination.totalPerPage,
        offset:
          (this.pagination.currentPage - 1) * this.pagination.totalPerPage,
        alreadyRegistered: !notRegistered,
        orderBy: this.ordering?.field || 'startDate',
        orderDirection: this.ordering?.reverse ? 'DESC' : 'ASC',
      },

      _.omit(this.filters, 'year')
    );

    this.Attendance.getEvents(queryParams).$promise.then(
      ({ count, events }) => {
        this.pagination.total = count;
        this.viewData.events = events;
        this.viewData.loading = false;
        this.viewData.hideFilters = false;

        const filters = _.omit(this.filters, 'year');

        if (fetchTotal && !notRegistered) {
          this.viewData.loadingTotal = true;

          this.Attendance.getTotal(filters).$promise.then((total) => {
            this.viewData.total = total;
            this.viewData.loadingTotal = false;
          });
        }
      }
    );
  }

  /**
   * Get a list of various attendance statistics
   *
   * @param {Boolean} hideFilters Whether to hide the filters while the data is being fetched. We want to hide them when switching tabs, but not when filtering.
   */
  fetchInsights(hideFilters = true) {
    this.viewData.loading = true;
    this.viewData.hideFilters = hideFilters;

    const filters = _.pick(this.filters, [
      'categories',
      'resources',
      'attendanceCategories',
    ]);

    filters.year = this.moment(this.filters.year).format('Y');

    this.Attendance.getInsights(filters).$promise.then((insights) => {
      if (insights.yearOverview) {
        this.viewData.insights = insights;

        const fields = _.some(this.attendanceFields, { tracker: 'total' })
          ? ['total']
          : undefined;
        const yearOverviewChartData = this.getYearOverviewChartData(fields);
        _.extend(this.charts.yearOverview, yearOverviewChartData);
      }

      this.viewData.loading = false;
      this.viewData.hideFilters = false;
    });
  }

  /**
   * Get the year overview chart data
   *
   * @param {String[]} fields A list of attendance fields that should be included in the chart. The `tracker` property is expected.
   */
  getYearOverviewChartData(fields = ['adults', 'children']) {
    const _ = this._;
    const yearOverview = this.viewData.insights.yearOverview;
    const selectedYear = this.moment(this.filters.year).year();
    const previousYear = selectedYear - 1;
    const datasets = [];
    const numbers = [];
    const colors = [];

    const datasetFields = _(this.attendanceFields)
      .filter((field) => _.includes(fields, field.tracker))
      .orderBy('order', 'asc')
      .value();

    // The bar chart represents the currently selected year, broken down into months.
    _.each(datasetFields, (field, index) => {
      colors.push(this.charts.yearOverview.baseColors[index]);

      const dataset = {
        type: 'bar',
        label: `${field.name} - ${selectedYear}`,
        tracker: field.tracker,
        backgroundColor: this.appUtils.hexToRgb(colors[index], 0.8),
        borderColor: this.appUtils.hexToRgb(colors[index], 1),
        borderWidth: 1,
      };

      datasets.push(dataset);

      const datasetNumbers = _(yearOverview[selectedYear])
        .values()
        .map((monthFields) =>
          _.get(_.find(monthFields, { id: field.id }), 'amount', 0)
        )
        .value();

      numbers.push(datasetNumbers);
    });

    // The line chart represents the year before the currently selected year,
    // and acts as a benchmark. The line chart shows an accumulated value of
    // the available datasets, unlike the bar chart which shows the fields stacked.
    const previousYearDataset = {
      type: 'line',
      label: this.gettextCatalog.getString(
        'Previous year - {{ previousYear }}',
        { previousYear }
      ),

      borderWidth: 2,
      fill: false,
    };

    const previousYearNumbers = _(yearOverview[previousYear])
      .values()
      .map((monthFields) => {
        const filtered = _.filter(monthFields, (monthField) =>
          _.some(datasetFields, { id: monthField.id })
        );

        return _.sumBy(filtered, 'amount');
      })
      .value();

    datasets.unshift(previousYearDataset);
    numbers.unshift(previousYearNumbers);
    colors.unshift(this.cdResourceColors['0']); // light blue

    return {
      colors,
      data: numbers,
      datasetOverride: datasets,
      series: _.map(datasets, 'label'),
    };
  }

  /**
   * Update the attendance category or vicar of an event
   *
   * @param {Number} eventId The id of the event
   * @param {String} property The property being updated
   * @param {*} value The new value
   */
  updateEvent(eventId, property, value) {
    this.Attendance.updateEvent({ eventId }, { [property]: value });
    this.Analytics.sendFeatureEvent(
      property === 'vicarId'
        ? 'update attendance vicar'
        : 'update attendance category',
      {
        module: 'attendance',
      }
    );
  }

  /**
   * Update the attendance numbers for an event
   *
   * @param {Number} eventId The id of the event
   * @param {Number} attendanceFieldId The id of the attendance type
   * @param {Number} amount The new amount
   */
  updateAmount(eventId, attendanceFieldId, amount) {
    this.Attendance.updateAmount({ eventId, attendanceFieldId }, { amount });
    this.Analytics.sendFeatureEvent('update attendance numbers', {
      module: 'attendance',
    });
  }

  /**
   * Get the number of attendees on a given field for a given event
   *
   * @param {Object} event The calendar event
   * @param {String} attendanceFieldId The id of the attendance field
   */
  getAttendanceFieldValue(event, attendanceFieldId) {
    return this._.result(
      this._.find(event.attendance.attendanceFields, { id: attendanceFieldId }),
      'amount'
    );
  }

  /**
   * Get started with attendance
   */
  getStarted() {
    this.$uibModal
      .open({
        component: 'cdAttendanceGetStartedModal',
        windowClass: 'modal-ui-select',
      })
      .result.then(() => {
        this.$timeout(() => this.$state.reload(), 500);
      });
  }

  /**
   * Update the ordering
   *
   * @param {String} field The new field to order by
   */
  setOrder(field) {
    if (this.ordering.field === field) {
      this.ordering.reverse = !this.ordering.reverse;
    } else {
      this.ordering.field = field;
      this.ordering.reverse = false;
    }
    this.fetchEvents(undefined, false, false);
  }

  /**
   * Get the icon associated with the currrent ordering
   *
   * @param {String} field The field to get the icon for
   */
  getOrderIcon(field) {
    if (this.ordering.field === field) {
      return this.ordering.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }

  /**
   * Change the current page
   *
   * @param {Number} page The index of the new page
   */
  changePage(page) {
    this.pagination.currentPage = page;
    this.$state.go('app.private.calendar.attendance', { page });
    this.fetchEvents(undefined, false, false);
  }

  /**
   * Change the number of events per page
   *
   * @param {Number} totalPerPage The new total of items per page
   */
  changeTotalPerPage(totalPerPage) {
    this.pagination.currentPage = 1;
    this.pagination.totalPerPage = totalPerPage;
    this.$state.go('app.private.calendar.attendance', { page: 1 });
    this.fetchEvents(undefined, false, false);
  }

  /**
   * Export an event to sogn.dk
   *
   * @param {Number} eventId The id of the event
   */
  exportToSognDk(eventId) {
    this.Calendar.get({
      id: eventId,
      type: 'event',
      operation: 'view',
    }).$promise.then((eventObject) => {
      this.$rootScope.openCreateContentWindow(
        'event',
        'edit',
        eventObject,
        { scrollTo: 'sogndk' },
        () => {
          this.fetchEvents();
        }
      );
    });
  }

  /**
   * Select event categories for tracking attendance
   */
  selectEventCategories() {
    this.$uibModal
      .open({
        component: 'cdSelectCategoriesModal',
        resolve: {
          taxonomies: [
            'Taxonomies',
            function (Taxonomies) {
              'ngInject';

              return Taxonomies.query().$promise;
            },
          ],
        },
      })
      .result.then((selectedCategories) => {
        this.viewData.categories = selectedCategories;

        if (this.$stateParams.tab === 'insights') {
          this.fetchInsights();
        } else {
          this.fetchEvents();
        }
      });
  }

  /**
   * Apply all the filters
   */
  applyFilters() {
    if (this.$stateParams.tab === 'insights') {
      this.fetchInsights(false);
    } else {
      this.fetchEvents(undefined, false);
    }
  }

  /**
   * Clear all the filters
   */
  clearFilters() {
    this.filters = this._.mapValues(this.filters, () => []);
  }

  /**
   * Set the selected filter items
   *
   * @param {Array} filterName The name of the filter
   * @param {Array} selectedItems The filter items that are selected
   */
  setSelectedFilter(filterName, selectedItems) {
    this.filters[filterName] = selectedItems;
  }

  /**
   * Set the selected date filter
   *
   * @param {Date} from The beginning of the interval to filter events
   * @param {Date} until The end of the interval to filter events
   */
  setDateFilter(from, until) {
    this.filters.from = from;
    this.filters.until = until;
  }

  /**
   * Get the selected filter label
   *
   * @param {Array} filterName The name of the filter
   */
  getFilterLabel(filterName) {
    const total = this.filters[filterName].length;

    switch (filterName) {
      case 'categories':
        return this.gettextCatalog.getPlural(
          total,
          '1 event selected',
          '{{ $count }} events selected',
          { $count: total }
        );

      case 'resources':
        return this.gettextCatalog.getPlural(
          total,
          '1 resource selected',
          '{{ $count }} resources selected',
          { $count: total }
        );

      case 'attendanceCategories':
        return this.gettextCatalog.getPlural(
          total,
          '1 attendance category selected',
          '{{ $count }} attendance categories selected',
          {
            $count: total,
          }
        );
    }
  }

  /**
   * Export attendance
   *
   * @param {String} fileType The type of the file to download
   */
  exportAttendance(fileType = 'csv') {
    this.Attendance.getExportToken().$promise.then(({ token }) => {
      const url = new Uri(this.cdApp.config.api.main);
      const filters = this._.omit(this.filters, 'year');

      url.setPath(`${url.path()}/attendance/export/data`);
      url.addQueryParam('fileType', fileType);
      url.addQueryParam('exportToken', token);

      this._.each(filters, (filterValue, filterKey) => {
        if (_.isArray(filterValue)) {
          this._.each(filterValue, (value) =>
            url.addQueryParam(filterKey, value)
          );
        } else {
          if (filterValue) url.addQueryParam(filterKey, filterValue);
        }
      });

      window.location = url.toString();
    });
  }

  /**
   * Lifecycle hook called when a ui-router parameter is changed, i.e. when navigating back and forth
   */
  uiOnParamsChanged(newParams) {
    if (newParams.tab) {
      const key = newParams.tab;
      const stepExists = this._.includes(
        this._.map(this.stepsInstance.steps, 'key'),
        key
      );

      if (stepExists) {
        this.stepsInstance.go(key);
      }
    }
  }
}

AttendanceComponent.$inject = [
  '$q',
  '$rootScope',
  '$timeout',
  '$state',
  '$stateParams',
  '$uibModal',
  '$filter',
  'stepsFactory',
  'Analytics',
  'Users',
  'Attendance',
  'Calendar',
  'Taxonomies',
  'Resources',
  'paginationOptions',
  'cdApp',
  'gettextCatalog',
  '_',
  'moment',
  'appUtils',
  'cdResourceColors',
];

angular.module('cdApp.calendar').component('cdCalendarAttendanceState', {
  templateUrl: '@/app/calendar/attendance/attendance.component.html',
  controller: AttendanceComponent,
  bindings: {
    attendanceCategories: '<',
    attendanceFields: '<',
  },
});
