import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import RootMailEditor from '@/react/people/components/RootMailEditor';
import {
  CdStudio,
  CdStudioEventInvitation,
} from '@/react/shared/components/Icons';
import MessageListCreateDropdown from '@/react/people/message-editor/shared/containers/MessageListCreateDropdown';
import { PublicNewsletter } from '@/react/people/message-editor/shared/containers/PublicNewsletter';

angular
  .module('cdApp.people')
  .component(
    'cdrRootMailEditor',
    react2angular(sharedContext.use(RootMailEditor), [], ['$stateParams'])
  );

angular
  .module('cdApp.people')
  .component(
    'cdrStudioIcon',
    react2angular(sharedContext.use(CdStudio), [], [])
  );

angular
  .module('cdApp.people')
  .component(
    'cdrStudioEventInvitation',
    react2angular(sharedContext.use(CdStudioEventInvitation), [], [])
  );

angular
  .module('cdApp.people')
  .component(
    'cdrMessageListCreateDropdown',
    react2angular(sharedContext.use(MessageListCreateDropdown), [
      'fetchRecipientsFromContext',
      'recipients',
      'buttonLabel',
      'buttonDisabled',
      'buttonSize',
      'recipientsHasSMS',
      'recipientsHasEmail',
      'disableStudio',
      'isGlobalSelect',
    ])
  );

angular
  .module('cdApp.people')
  .component(
    'cdPublicNewsletter',
    react2angular(sharedContext.use(PublicNewsletter), [], ['$stateParams'])
  );
