import { react2angular } from 'react18-react2angular';

import ImprintWrapper from '../../../react/settings/pages/imprint/ImprintPage';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.settings')
  .component(
    'cdrImprint',
    react2angular(sharedContext.use(ImprintWrapper), [], [])
  );
