import React, { useCallback } from 'react';
import { Input, Select, Form, Card } from 'antd';

import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { gettextCatalog } from '@/react/services/I18nService';
import useOrganizations from '@/react/organization/hooks/useOrganizations';
import { useFormSearch } from '@/react/people/hooks/useFormSearch';
import { PeopleMessageForm } from '@/react/people/types/message';
import { FormSearchResultQuery } from '@/react/people/store/message';
import AuthorizationService from '@/react/services/AuthorizationService';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';

const formColumns = () => [
  {
    title: gettextCatalog.getString('Title'),
    dataIndex: 'title',
    key: 'title',
    sorter: true,
    active: true,
  },
];

interface FormTableProps {
  onRowsChange?: (blogs: PeopleMessageForm[]) => void;
}

const FormTable = ({ onRowsChange }: FormTableProps) => (
  <CdSimpleTable
    columns={formColumns()}
    recoilSource={FormSearchResultQuery}
    emptyStateProps={{ title: gettextCatalog.getString('No forms found') }}
    rowSelection={{
      type: 'checkbox',
      onChange: (_id, forms) => onRowsChange(forms),
    }}
    columnSelectorKey="FormSearchForm"
  />
);

export default function FormSearchForm({ form }) {
  const {
    debounced: { searchForms },
  } = useFormSearch();
  const { organizations, setSearchId, searchId, searchByName } =
    useOrganizations();
  const handleSearch = useCallback(
    (e) => searchForms(e.target.value),
    [searchForms]
  );
  const hasCollaborationPackage =
    AuthorizationService.hasPackage('collaboration');

  return (
    <CdVerticalSpace>
      <Card>
        <Form onValuesChange={handleSearch} layout="vertical">
          {hasCollaborationPackage && (
            <Form.Item label={gettextCatalog.getString('Organization')}>
              <Select<number>
                showSearch
                value={searchId}
                onSearch={searchByName}
                filterOption
                optionFilterProp="label"
                onChange={setSearchId}
                options={organizations.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                style={{ width: '100%' }}
              />
            </Form.Item>
          )}
          <Form.Item label={gettextCatalog.getString('Title')}>
            <Input
              placeholder={gettextCatalog.getString('Search')}
              onChange={handleSearch}
            />
          </Form.Item>
        </Form>
      </Card>
      <Form form={form}>
        <Form.Item
          name="forms"
          trigger="onRowsChange"
          valuePropName="selectedKeys"
        >
          <FormTable />
        </Form.Item>
      </Form>
    </CdVerticalSpace>
  );
}
