import React from 'react';

import ElementWrapper from './ElementWrapper';

const Title: React.FC<{ title: string }> = ({ title }) => (
  <ElementWrapper>
    <strong>{title}</strong>
  </ElementWrapper>
);

export default Title;
