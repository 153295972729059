import React, { Suspense } from 'react';
import { Button, Card, Col, Form, Row, Badge, Space } from 'antd';
import styled from 'styled-components';

import { CdrFontPicker } from '../../shared/components/cd-font-picker/CdrFontPicker';
import { CdPage } from '../../shared/components/cd-page/CdPage';
import {
  CdExclamationTriangleIcon,
  CdSave,
} from '../../shared/components/Icons';
import { useOrganization } from '../../organization/hooks/useOrganization';
import CdrPageLoader from '../../shared/components/CdrPageLoader';
import { CdrColorPicker } from '../../shared/components/cd-color-picker/CdrColorPicker';

import WidgetList from './WidgetList';
import { NotSaved } from './components/NotSaved';
import { SuggestedWidgetSwitch } from './components/forms/SuggestedWidgetSwitch';

import { gettextCatalog } from '@/react/services/I18nService';
import AuthorizationService from '@/react/services/AuthorizationService';
import { WidgetCornerStyleButton } from '@/react/homepage/widgets/components/WidgetCornerStyleButton';
import { WidgetShadowStyleButton } from '@/react/homepage/widgets/components/WidgetShadowStyleButton';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const HomepageWidgetsPage = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <HomepageWidgetsPageComponent />
  </Suspense>
);

const BadgeRibbonWrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 2px;
`;

export const HomepageWidgetsPageComponent = () => {
  const [form] = Form.useForm();
  const { organizationSettings, updateSettings } = useOrganization();
  const hasStudio = AuthorizationService.hasPackage('studio');
  const handleClickSaveButton = async () => {
    const values = form.getFieldsValue();

    await updateSettings({
      ...values,
    });
    showConfirmModal({
      title: gettextCatalog.getString('Settings saved'),
      message: gettextCatalog.getString(
        'It may take 10 minutes before the changes are visible on your website. However, the changes are visible immediately in the widget overview below.'
      ),
      icon: <CdExclamationTriangleIcon type="info" />,
      cancelText: gettextCatalog.getString('Ok'),
      hideOk: true,
    });
  };
  const canAccessHomepage =
    AuthorizationService.hasPermission('canAccessHomepage');

  return (
    <Suspense fallback={<CdrPageLoader />}>
      <CdPage
        pageHeaderProps={{
          title: gettextCatalog.getString('Widgets'),
        }}
      >
        <Form
          layout="vertical"
          initialValues={organizationSettings}
          form={form}
        >
          <Row gutter={[16, 24]}>
            <Col span={8}>
              <Card
                bodyStyle={{ display: 'flex', flexDirection: 'column' }}
                title={gettextCatalog.getString('Design options')}
                style={{ height: '100%' }}
                extra={
                  <Button
                    icon={<CdSave />}
                    key="1"
                    type="primary"
                    disabled={!canAccessHomepage}
                    onClick={handleClickSaveButton}
                  >
                    {gettextCatalog.getString('Save')}
                  </Button>
                }
              >
                <Form.Item name="primaryColor">
                  <CdrColorPicker
                    title={
                      <Space>
                        {gettextCatalog.getString('Primary color')}
                        <NotSaved
                          name="primaryColor"
                          initialValues={organizationSettings}
                        />
                      </Space>
                    }
                  />
                </Form.Item>

                <Form.Item name="cardColor">
                  <CdrColorPicker
                    title={
                      <Space>
                        {gettextCatalog.getString('Card background color')}
                        <NotSaved
                          name="cardColor"
                          initialValues={organizationSettings}
                        />
                      </Space>
                    }
                  />
                </Form.Item>
                <Form.Item name="showSuggestedEventsOrBlogs">
                  <SuggestedWidgetSwitch settings={organizationSettings} />
                </Form.Item>
              </Card>
            </Col>
            <Col span={16}>
              <BadgeRibbonWrapper>
                <Badge.Ribbon
                  text={gettextCatalog.getString('Studio only')}
                  color="#27ae60"
                  style={hasStudio ? { display: 'none' } : {}}
                >
                  <Card
                    title={gettextCatalog.getString('Widget Design')}
                    style={{ height: '100%' }}
                    bordered={false}
                    extra={
                      <Button
                        icon={<CdSave />}
                        key="1"
                        type="primary"
                        onClick={handleClickSaveButton}
                        disabled={!hasStudio || !canAccessHomepage}
                      >
                        {gettextCatalog.getString('Save')}
                      </Button>
                    }
                  >
                    <Row gutter={[16, 24]} justify="space-around">
                      <Col span={8}>
                        <Form.Item
                          label={
                            <Space>
                              {gettextCatalog.getString('Corner style')}
                              <NotSaved
                                name="showRoundedCorners"
                                initialValues={organizationSettings}
                              />
                            </Space>
                          }
                          name="showRoundedCorners"
                          style={{ marginBottom: '0px' }}
                        >
                          <WidgetCornerStyleButton disabled={!hasStudio} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <Space>
                              {gettextCatalog.getString('Show shadow')}
                              <NotSaved
                                name="showShadow"
                                initialValues={organizationSettings}
                              />
                            </Space>
                          }
                          name="showShadow"
                          style={{ marginBottom: '0px' }}
                        >
                          <WidgetShadowStyleButton disabled={!hasStudio} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <Space>
                              {gettextCatalog.getString('Widget font')}
                              <NotSaved
                                name="font"
                                initialValues={organizationSettings}
                              />
                            </Space>
                          }
                          name="font"
                          style={{ marginBottom: '0px' }}
                        >
                          <CdrFontPicker disabled={!hasStudio} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Badge.Ribbon>
              </BadgeRibbonWrapper>
            </Col>
          </Row>
        </Form>
        <WidgetList />
      </CdPage>
    </Suspense>
  );
};
