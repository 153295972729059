import { RecoilValue } from 'recoil';

/**
 * Map of Recoil states and their associated refresh functions
 */
const states = new Map<RecoilValue<any>, () => void>();

/**
 * Function to refresh a Recoil state
 * @param state The Recoil state to refresh
 */
export const recoilRefresh = (state: RecoilValue<any>): void => {
  if (!states.has(state)) {
    return;
  }
  states.get(state)();
};

/**
 * Function to register a refresh function for a Recoil state
 * @param state The Recoil state to register the refresh function for
 * @param refresher The refresh function to register
 */
export const registerRecoilRefresher = (
  state: RecoilValue<any>,
  refresher: () => void
): void => {
  states.set(state, refresher);
};
