import { Button, Image, Space } from 'antd';
import React from 'react';

import cdApp from '@/react/config';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const CdSSOButtons = () => (
  <Space>
    <Button
      shape="circle"
      size="large"
      style={{
        padding: 0,
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
      href={`${cdApp.config.api.main}/login/sso?provider=mainz&env=prod`}
    >
      <CdTooltip title="Katholisch Bistum Mainz">
        <Image
          preview={false}
          width={37}
          src="https://assets.churchdesk.com/sso/Logo-Bistum-Mainz-crop.png?class=small"
        />
      </CdTooltip>
    </Button>
    <Button
      shape="circle"
      size="large"
      style={{
        padding: 0,
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
      href={`${cdApp.config.api.main}/login/sso?provider=ekkw&env=prod`}
    >
      <CdTooltip title="Evangelische Kirche von Kurhessen-Waldeck">
        <Image
          preview={false}
          width={37}
          src="https://assets.churchdesk.com/sso/Logo-EKKW.png?class=small"
        />
      </CdTooltip>
    </Button>
  </Space>
);
