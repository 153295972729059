'use strict';

class UserOrganizationsPageComponent {
  constructor($stateParams, cdApp, AuthenticationService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.user = cdApp.me;
    this.AuthenticationService = AuthenticationService;
  }

  redirect(organizationId) {
    this.AuthenticationService.redirect(
      organizationId,
      this.$stateParams.continue
    );
  }
  // Log out the user
  logOut() {
    this.AuthenticationService.logout(true);
  }
}
UserOrganizationsPageComponent.$inject = [
  '$stateParams',
  'cdApp',
  'AuthenticationService',
];

angular.module('cdApp.shared').component('cdUserOrganizationsPage', {
  templateUrl:
    '@/app/shared/components/user-organizations/user-organizations.component.html',
  controller: UserOrganizationsPageComponent,
});
