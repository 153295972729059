import { assignIn, get, isEmpty } from 'lodash';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  throttle,
} from 'redux-saga/effects';

import { getCurrentOrganization } from '../../config/store/Selector';
import { CdAction } from '../../redux/utils';
import AuthenticationService from '../../services/AuthenticationService';
import { navigate } from '../../services/StateServiceFactory';
import { withLoadingAndErrors } from '../../shared/loading/saga';
import { ResetUserPrivileges } from '../../user/redux/actions';
import {
  ActionCreators as GroupActionCreators,
  FETCH_GROUPS,
} from '../redux/groups';
import { JobActionCreators, FETCH_JOBS } from '../redux/jobs';
import GroupsService from '../services/Groups.service';
import RolesService, { RoleContext } from '../services/roles.service';
import { RolesActions, roleSelectors } from '../store/roleSlice';
import {
  FetchRolesOptionsInterface,
  QueryRoleInterface,
  RoleInterface,
} from '../types/organization-role.types';
import JobsService from '../services/Jobs.service';

function* getRoles(action: CdAction<FetchRolesOptionsInterface>): SagaIterator {
  let churchRoles = yield select(roleSelectors.selectChurchRoles);
  let organizationRoles = yield select(roleSelectors.selectOrganizationRoles);
  if (
    get(action, 'payload.forceUpdate') ||
    isEmpty(churchRoles) ||
    isEmpty(organizationRoles)
  ) {
    churchRoles = yield call(RolesService.getAll, RoleContext.church);
    organizationRoles = yield call(
      RolesService.getAll,
      RoleContext.organization
    );
  }
  yield put(
    RolesActions.rolesFetched([].concat(churchRoles, organizationRoles))
  );
}

function* getRole(action: CdAction<QueryRoleInterface>): SagaIterator {
  const role: RoleInterface = yield call(RolesService.get, action.payload);
  yield put(RolesActions.roleFetched(role));
}

function* createRole(action: CdAction<RoleInterface>): SagaIterator {
  const role: any = yield call(RolesService.create, action.payload);
  yield put(RolesActions.roleAdded(role));
  navigate(
    'app.private.settings.roles.edit',
    { roleContext: role.context, id: role.id, forceReloadRoles: true },
    { reload: 'app.private.settings.roles' }
  );
}

function* deleteRole(action: CdAction<QueryRoleInterface>): SagaIterator {
  yield call(RolesService.delete, action.payload);
  yield put(RolesActions.roleRemoved(action.payload.id));
  navigate(
    'app.private.settings.roles',
    { forceReloadRoles: true },
    { reload: 'app.private.settings.roles' }
  );
}

function* updateRole(action: CdAction<RoleInterface>): SagaIterator {
  const role: RoleInterface = yield call(RolesService.update, action.payload);
  yield put(RolesActions.roleFetched(role));
  yield all([
    call(AuthenticationService.getCurrentUser),
    put(ResetUserPrivileges()),
  ]);
}

function* getGroups(): SagaIterator {
  const groups = yield call(GroupsService.getAll);
  yield put(GroupActionCreators.FetchGroupsSuccess(groups));
}

function* copyRole(action: CdAction<string>): SagaIterator {
  const newRole: RoleInterface = yield call(
    RolesService.copyRole,
    action.payload
  );
  yield put(RolesActions.roleFetched(newRole));
  navigate(
    'app.private.settings.roles.edit',
    { roleContext: newRole.context, id: newRole.id, forceReloadRoles: true },
    { reload: 'app.private.settings.roles' }
  );
}

function* disableRole(action: CdAction<RoleInterface>): SagaIterator {
  action.payload = assignIn({}, action.payload, { isEnabled: false });
  yield call(updateRole, action);
}

function* enableRole(action: CdAction<RoleInterface>): SagaIterator {
  action.payload = assignIn({}, action.payload, { isEnabled: true });
  yield call(updateRole, action);
}

function* getJobs(): SagaIterator {
  const organization = yield select(getCurrentOrganization);
  const jobs = yield call(
    JobsService.getJobs,
    get(organization, 'countryIso2')
  );
  yield put(JobActionCreators.FetchJobsSuccess(jobs));
}

// Watcher
export default function* root(): SagaIterator {
  yield all([
    takeLatest(
      RolesActions.fetchRoles.type,
      withLoadingAndErrors(RolesActions.fetchRoles.type, getRoles)
    ),
    takeLatest(FETCH_GROUPS, withLoadingAndErrors(FETCH_GROUPS, getGroups)),
    takeLatest(FETCH_JOBS, withLoadingAndErrors(FETCH_JOBS, getJobs)),
    takeLatest(
      RolesActions.fetchRole.type,
      withLoadingAndErrors(RolesActions.fetchRole.type, getRole)
    ),
    takeLatest(
      RolesActions.copyRole.type,
      withLoadingAndErrors(RolesActions.copyRole.type, copyRole)
    ),
    takeLatest(
      RolesActions.disableRole.type,
      withLoadingAndErrors(RolesActions.disableRole.type, disableRole)
    ),
    takeLatest(
      RolesActions.enableRole.type,
      withLoadingAndErrors(RolesActions.enableRole.type, enableRole)
    ),
    throttle(
      2000,
      RolesActions.updateRole.type,
      withLoadingAndErrors(RolesActions.updateRole.type, updateRole)
    ),
    throttle(
      2000,
      RolesActions.addRole.type,
      withLoadingAndErrors(RolesActions.addRole.type, createRole)
    ),
    throttle(
      2000,
      RolesActions.removeRole.type,
      withLoadingAndErrors(RolesActions.removeRole.type, deleteRole)
    ),
  ]);
}
