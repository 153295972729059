(function () {
  'use strict';
  Revisions.$inject = ['$$resource'];

  function Revisions($$resource) {
    return $$resource(
      cdApp.config.api.main + '/revisions/:entityType/:entityId'
    );
  }

  angular.module('cdApp.shared').factory('Revisions', Revisions);
})();
