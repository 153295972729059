import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getContactProfileInformation = async (contactId): Promise<any> => {
  const response = await mainApi.get<any>(`/people/people/${contactId}`);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const saveContactProfileInformation = async ({
  contactId,
  payload,
}): Promise<any> => {
  const response = await mainApi.put<any>(
    `/people/people/${contactId}`,
    payload
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};
