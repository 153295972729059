import { selector } from 'recoil';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const IcalTokenQuery = selector<string>({
  key: 'IcalTokenQuery',
  get: async () => {
    const response = await mainApi.get<string>('/ical/token');

    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
