import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { filter } from 'lodash';

import { AppState } from '../../redux';
import {
  FetchRolesOptionsInterface,
  RoleInterface,
  QueryRoleInterface,
} from '../types/organization-role.types';

const roleAdapter = createEntityAdapter<RoleInterface>({
  selectId: (role) => role.id,
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState: roleAdapter.getInitialState(),
  reducers: {
    rolesFetched: roleAdapter.upsertMany,
    roleFetched: roleAdapter.upsertOne,
    roleRemoved: roleAdapter.removeOne,
    roleAdded: roleAdapter.addOne,
  },
});

export const rolesReducer = rolesSlice.reducer;

export const RolesActions = {
  fetchRoles: createAction<FetchRolesOptionsInterface>('roles/fetchRoles'),
  fetchRole: createAction<QueryRoleInterface>('roles/fetchRole'),
  addRole: createAction<RoleInterface>('roles/addRole'),
  updateRole: createAction<RoleInterface>('roles/updateRole'),
  removeRole: createAction<QueryRoleInterface>('roles/removeRole'),
  copyRole: createAction<string>('roles/copyRole'),
  disableRole: createAction<RoleInterface>('roles/disableRole'),
  enableRole: createAction<RoleInterface>('roles/enableRole'),
  ...rolesSlice.actions,
};

const roleAdapterSelectors = roleAdapter.getSelectors(
  (state: AppState) => state.organization.roles
);

export const roleSelectors = {
  selectAll: roleAdapterSelectors.selectAll,
  selectById: (roleId: string) => (state: AppState) =>
    roleAdapterSelectors.selectById(state, roleId),
  selectEntities: roleAdapterSelectors.selectEntities,
  selectOrganizationRoles: createSelector(
    roleAdapterSelectors.selectEntities,
    (roles) =>
      filter(roles, (role: RoleInterface) => role.context === 'organization')
  ),
  selectChurchRoles: createSelector(
    roleAdapterSelectors.selectEntities,
    (roles) => filter(roles, (role: RoleInterface) => role.context === 'church')
  ),
};
