import { put, call } from 'redux-saga/effects';

import {
  loadFileArchiveFiles,
  loadFileArchiveBreadCrumb,
  fileArchiveGetTags,
  fileArchiveUpdateFile,
  fileArchiveDeleteFile,
  fileArchiveCreateFolder,
} from '../../services';
import {
  fileArchiveRequest,
  fileArchiveRequestSuccess,
  fileArchiveRequestFailed,
  fileArchiveBreadCrumbRequestSuccess,
  fileArchiveBreadCrumbRequestFailed,
  fileArchiveUpdateFileRequest,
  fileArchiveUpdateFileSuccess,
  fileArchiveUpdateFileFail,
  fileArchiveGetTagsSuccess,
  fileArchiveGetTagsFail,
  fileArchiveDeleteFileRequest,
  fileArchiveDeleteFileSuccess,
  fileArchiveDeleteFileFail,
  fileArchiveFolderCreateSuccess,
  fileArchiveFolderCreateFail,
} from '../actions';

export function* loadFileArchiveFilesSaga(action) {
  const { payload } = action;
  try {
    yield put(fileArchiveRequest());
    const extend = { folderId: payload.folderId };
    if (payload.search.searchIn === 'searchInAll' && payload.search.value) {
      extend.folderId = undefined;
    }
    const fileArchives = yield call(loadFileArchiveFiles, {
      ...payload,
      ...extend,
    });
    yield put(fileArchiveRequestSuccess(fileArchives));
  } catch (error) {
    yield put(fileArchiveRequestFailed());
  }
}

export function* loadFileArchiveBreadcrumbSaga(action) {
  const { payload } = action;
  const { folderId } = payload;
  try {
    if (folderId) {
      const breadcrumb = yield call(loadFileArchiveBreadCrumb, folderId);
      yield put(fileArchiveBreadCrumbRequestSuccess(breadcrumb));
    } else {
      // Reset breadcrumb
      yield put(fileArchiveBreadCrumbRequestSuccess([]));
    }
  } catch (error) {
    yield put(fileArchiveBreadCrumbRequestFailed());
  }
}

export function* fileArchiveUpdateFileSaga(action) {
  const { payload } = action;
  const { fileId, file } = payload;
  try {
    yield put(fileArchiveUpdateFileRequest());
    yield call(fileArchiveUpdateFile, fileId, file);
    yield put(fileArchiveUpdateFileSuccess());
  } catch (error) {
    yield put(fileArchiveUpdateFileFail());
  }
}

export function* fileArchiveDeleteFileSaga(action) {
  const { payload } = action;
  const { fileId } = payload;
  try {
    yield put(fileArchiveDeleteFileRequest());
    yield call(fileArchiveDeleteFile, fileId);
    yield put(fileArchiveDeleteFileSuccess());
  } catch (error) {
    yield put(fileArchiveDeleteFileFail());
  }
}

export function* fileArchiveGetTagsSaga() {
  try {
    const tags: string[] = yield call(fileArchiveGetTags);
    yield put(fileArchiveGetTagsSuccess({ tags }));
  } catch (error) {
    yield put(fileArchiveGetTagsFail());
  }
}

export function* fileArchiveFolderCreateSaga(action) {
  try {
    const { payload } = action;
    yield call(fileArchiveCreateFolder, payload);
    yield put(fileArchiveFolderCreateSuccess());
  } catch (error) {
    yield put(fileArchiveFolderCreateFail());
  }
}
