(function () {
  'use strict';

  function cutText() {
    return function (value, maxheadInput, maxtailInput, trimmed) {
      if (!value) {
        return '';
      }

      let maxhead = parseInt(maxheadInput, 10);
      if (!maxhead) {
        return value;
      }

      let maxtail = parseInt(maxtailInput, 10);
      if (value.length <= maxhead + maxtail) {
        return value;
      }

      let tailvalue = '';
      if (maxtail && maxhead + maxtail < value.length) {
        tailvalue = value.substring(value.length - maxtail);
      }

      let headvalue = value.substring(0, maxhead);

      return headvalue + trimmed + tailvalue;
    };
  }

  angular.module('cdApp.shared').filter('cutText', cutText);
})();
