import React from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  DatePicker,
  Alert,
  Typography,
} from 'antd';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  CdCalendarResult,
  CdCalendarSearchActivityList,
  CdCalendarSearchExportToExcel,
  CdCalendarSearchPrint,
} from '@/react/shared/components/Icons';
import { useSearchCalendarReport } from '@/react/calendar/reports/hooks/useSearchCalendarReport';
import { SearchCalendarPrintTable } from '@/react/calendar/reports/SearchCalendarPrintTable';
import { SearchCalendarLocations } from '@/react/calendar/reports/SearchCalendarLocations';
import { useTemplates } from '@/react/calendar/hooks/order-of-service/useTemplates';
import { getString } from '@/react/services/GetStringX';
import { useExportReport } from '@/react/calendar/reports/hooks/useExportReport';
import { ReportsFilterSelect } from '@/react/calendar/reports/ReportFilterSelect';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';

export const SearchCalendar = ({
  filters,
  tabKey,
}: { filters: any; tabKey: string }) => {
  const {
    calendarSearchForm,
    calendarSearchInitialValue,
    getResultHandler,
    userOptions,
    groupsOptions,
    eventsOptions,
    absenceOptions,
    tableData,
    isPrintDataLoading,
    hasChanges,
    setHasChanges,
    isMultiParish,
  } = useSearchCalendarReport({ filters, type: tabKey });

  const { handleExport } = useExportReport({
    filters,
    type: tabKey,
    allCategories:
      tabKey === 'absence'
        ? absenceOptions
        : tabKey === 'search' || tabKey === 'activityList'
          ? eventsOptions
          : [],
  });

  const { openTemplateSelector } = useTemplates();
  const count = tableData?.events?.length;

  const onFormValueChange = React.useCallback(
    (changedValues) => {
      if (changedValues) {
        setHasChanges(true);
      }
    },
    [setHasChanges]
  );

  return (
    <Row
      gutter={[0, 24]}
      style={{ background: 'white', padding: '24px' }}
      className="no-padding-or-margin-in-print"
    >
      <Col
        span={24}
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="display-none-in-print"
      >
        <Space direction="horizontal" size="small">
          <CdCalendarResult size="lg" />
          <Typography.Text strong>
            {gettextCatalog.getPlural(count, '1 result', '{{count}} results', {
              count: count,
            })}
          </Typography.Text>
        </Space>
        <Space direction="horizontal" size="small">
          {(tabKey === 'search' || tabKey === 'absence') && (
            <Button
              key="searchCalendarPrint"
              type="default"
              icon={<CdCalendarSearchPrint />}
              disabled={tableData?.events?.length < 1}
              onClick={() => {
                window.print();
              }}
            >
              {gettextCatalog.getString('Print')}
            </Button>
          )}
          {tabKey === 'activityList' && (
            <Button
              type="primary"
              icon={<CdCalendarSearchActivityList />}
              disabled={tableData?.events?.length < 1}
              onClick={() =>
                openTemplateSelector({
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                  categories: filters.categorySelect,
                  groups: filters.groupSelect,
                  users: filters.userSelect,
                  search: filters.searchText,
                  resources: filters.resourceSelect
                    ?.filter((item) => item.includes('resource-'))
                    ?.map((value) => value.replace('resource-', '')),
                  eventsWithoutResourcesInChurchIds: filters.resourceSelect
                    ?.filter((item) => item.includes('church-'))
                    ?.map((value) => value.replace('church-', '')),
                  visibility: filters.visibilitySelect,
                })
              }
            >
              {gettextCatalog.getString('Print worship overview')}
            </Button>
          )}
          <Button
            key="searchCalendarReportExport"
            type="default"
            icon={<CdCalendarSearchExportToExcel />}
            disabled={tableData?.events?.length < 1}
            onClick={handleExport}
          >
            {gettextCatalog.getString('Export to Excel')}
          </Button>
        </Space>
      </Col>
      <Col span={24} className="display-none-in-print">
        <Form
          colon={false}
          form={calendarSearchForm}
          initialValues={calendarSearchInitialValue}
          onValuesChange={onFormValueChange}
        >
          <Row>
            <Col span={20} style={{ paddingRight: '6px' }}>
              <Row gutter={[6, 0]} justify="end">
                <Col span={8} style={{ height: '42px' }}>
                  <Form.Item name="searchText">
                    <Input
                      type="text"
                      placeholder={gettextCatalog.getString('Search')}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ height: '42px' }}>
                  <Form.Item name="startDate">
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder={gettextCatalog.getString('Start date')}
                      format={gettextCatalog.getLongDateFormat()}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ height: '42px' }}>
                  <Form.Item name="endDate">
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder={gettextCatalog.getString('End date')}
                      format={gettextCatalog.getLongDateFormat()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[6, 0]} justify="end">
                {(tabKey === 'search' || tabKey === 'absence') && (
                  <Col span={4}>
                    <Form.Item name="groupSelect">
                      <ReportsFilterSelect
                        options={groupsOptions}
                        type="group"
                      />
                    </Form.Item>
                  </Col>
                )}
                {(tabKey === 'search' || tabKey === 'activityList') && (
                  <Col span={4}>
                    <Form.Item name="categorySelect">
                      <ReportsFilterSelect
                        options={eventsOptions}
                        type="event"
                      />
                    </Form.Item>
                  </Col>
                )}
                {tabKey === 'absence' && (
                  <Col span={4}>
                    <Form.Item name="categorySelect">
                      <ReportsFilterSelect
                        options={absenceOptions}
                        type="absence"
                      />
                    </Form.Item>
                  </Col>
                )}
                {tabKey !== 'absence' && (
                  <Col span={4}>
                    <Form.Item name="resourceSelect">
                      <SearchCalendarLocations />
                    </Form.Item>
                  </Col>
                )}
                <Col span={4}>
                  <Form.Item name="userSelect">
                    <ReportsFilterSelect options={userOptions} type="user" />
                  </Form.Item>
                </Col>
                {(tabKey === 'search' || tabKey === 'activityList') && (
                  <Col span={4}>
                    <Form.Item name="visibilitySelect">
                      <CdSelect
                        style={{ width: '100%' }}
                        placeholder={gettextCatalog.getString('Visibility')}
                        options={[
                          {
                            label: gettextCatalog.getString('Public'),
                            value: 'public',
                          },
                          {
                            label: gettextCatalog.getString('All users'),
                            value: 'all-users',
                          },
                          {
                            label: gettextCatalog.getString('Groups'),
                            value: 'groups',
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={3} style={{ height: '42px' }}>
              <Space direction="horizontal" size="small">
                <Button
                  onClick={getResultHandler}
                  type="primary"
                  disabled={!hasChanges}
                >
                  {gettextCatalog.getString('Get result')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
      {tableData?.isMoreEvents &&
        (tabKey === 'search' ||
          tabKey === 'activityList' ||
          (tabKey === 'absence' && count > 200)) && (
          <Col span={24}>
            <Alert
              type="warning"
              description={getString(
                'Currently showing your first {{ number }} results. To view more, narrow your search or choose <i>Export to Excel</i> to view all results!',
                {
                  number: count,
                  i: (text) => `<i>${text}</i>`,
                }
              )}
            />
          </Col>
        )}
      <Col span={24}>
        <SearchCalendarPrintTable
          events={tableData?.events}
          type={tabKey}
          isPrintDataLoading={isPrintDataLoading}
          isMultiParish={isMultiParish}
        />
      </Col>
    </Row>
  );
};
