/**
 * A modal that allows users to create a public rota and share it via a URL.
 *
 * @param {Object[]} resolve.categories
 * @param {Object[]} resolve.tasks
 * @param {Object[]} resolve.resources
 */
class CreatePublicRotaModal {
  constructor(
    toastr,
    $state,
    Shifts,
    RotasLinks,
    appUtils,
    gettextCatalog,
    cdApp
  ) {
    'ngInject';

    this.toastr = toastr;
    this.Shifts = Shifts;
    this.RotasLinks = RotasLinks;
    this.appUtils = appUtils;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.$state = $state;
  }

  $onInit() {
    this.rota = {
      id: this.resolve.id,
      name: this.resolve.name,
      taxonomyIds: this.resolve.categories,
      taskIds: this.resolve.tasks,
      displayTaskIds: this.resolve.displayTaskIds,
      resourceIds: this.resolve.resources,
      showInitials: this.resolve.useInitials || false,
      eventsWithoutResourcesInChurchIds:
        this.resolve.eventsWithoutResourcesInChurchIds || [],
      hideNotes: this.resolve.hideNotes || false,
    };

    this.createdRotaUrl = this.resolve.createdRotaUrl;
    this.isSchemeCreate = this.resolve.isSchemeCreate || false;
  }

  createRota() {
    const { RotasLinks, cdApp, toastr, gettextCatalog, $state } = this;

    this.isLoading = true;
    this.rota.showNotes = !this.rota.hideNotes;
    const rota = new RotasLinks(this.rota);
    rota.$save(({ id }) => {
      this.rota.id = this.rota.id || id;
      // If creating for first time display modal to copy sharable link.
      if (this.isSchemeCreate) {
        this.createdRotaUrl = `${cdApp.references.appUrl}/rota/${id}`;
      } else {
        toastr.success(gettextCatalog.getString('Rota scheme updated.'));
        $state.go($state.current, {}, { reload: true });

        this.close({ $value: this.rota });
      }
    });
  }

  copyRotaLink() {
    const { toastr, gettextCatalog } = this;
    const rotaUrlInput = document.querySelector('#createdRotaUrl');
    rotaUrlInput.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toastr.info(gettextCatalog.getString('Link copied to clipboard.'));
      } else {
        unsuccessful();
      }
    } catch (err) {
      unsuccessful();
    }

    function unsuccessful() {
      toastr.error(
        gettextCatalog.getString(
          "Couldn't copy rota link. Try right clicking on the link to copy."
        )
      );
    }
  }

  closeModal() {
    const { $state } = this;
    // If creating a scheme redirect to the created scheme when modal closes
    if (this.isSchemeCreate) {
      $state.go(
        'app.private.calendar.rotasLinkEdit',
        { rotaLinkId: this.rota && this.rota.id, rotaLink: null },
        { reload: true }
      );
    }

    this.close({ $value: this.rota });
  }
}

CreatePublicRotaModal.$inject = [
  'toastr',
  '$state',
  'Shifts',
  'RotasLinks',
  'appUtils',
  'gettextCatalog',
  'cdApp',
];

angular.module('cdApp.intranet').component('cdCreatePublicRotaModal', {
  template: require('./create-public-rota.component.html'),
  controller: CreatePublicRotaModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
