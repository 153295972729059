import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import ConsentCreateStatementButton from '@/react/people/components/ConsentCreateStatementButton';

angular
  .module('cdApp.people')
  .component(
    'cdrConsentCreateStatementButton',
    react2angular(sharedContext.use(ConsentCreateStatementButton), [], [])
  );
