import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Modal,
  Select,
  Steps,
  Typography,
  Upload,
  Input,
} from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import React, { useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { Group } from '../../services/lookups';
import { Settings } from '../../services/me';
import { FileArchiveBreadCrumb } from '../../store/actions/filearchive';
import mountingContainer from '../mountContainer';

import {
  FilePermissions,
  useFileValidator,
  useGetLookups,
  useHasPermission,
} from './hooks';

import AuthenticationService from '@/react/services/AuthenticationService';
import { gettextCatalog } from '@/react/services/I18nService';
import cdApp from '@/react/config';

const { Paragraph, Text } = Typography;

const GlobalStyle = createGlobalStyle`
  .antd-ns .ant-select-dropdown,
  .antd-ns .ant-dropdown-trigger,
  .antd-ns .ant-tooltip-inner,
  .antd-ns .ant-message {
    z-index: 999999 !important;;
  }
  .antd-ns .ant-modal-wrap {
    z-index: 999998 !important;;
  }
  .antd-ns .site-result-demo-error-icon {
    color: red;
  }
  .antd-ns .ant-upload-list {
    max-height: 25vw;
    overflow-y: scroll;
  }
  .ant-upload-list-item-done {
    border-color: #7ab800 !important;
    border-width: 2px !important;

    .ant-upload-list-item-name {

      &::after {
        content: "L";
        font-family: arial;
        -ms-transform: scaleX(-1) rotate(-40deg); /* IE 9 */
        -webkit-transform: scaleX(-1) rotate(-40deg); /* Chrome, Safari, Opera */
        transform: scaleX(-1) rotate(-40deg);
        color: #7ab800;
        float: right;
        font-size: 1.5em;
        font-weight: 900;
        margin-right: 10px;
      }
    }
  }
`;
interface FileUploadProps {
  groups: Group[];
  canChangeVisibility: boolean;
  fileValidator: (file: File) => boolean;
  folder: FileArchiveBreadCrumb | undefined;
  onFinish: () => void;
  accept?: string;
  creditSettings: Settings;
}
export interface FormValues {
  copyright: string;
  visibility: boolean;
  groupId: number;
  folderId: number;
}

const FileUploadModal: React.FC<FileUploadProps> = (props) => {
  const {
    canChangeVisibility,
    groups,
    fileValidator,
    folder,
    onFinish,
    accept,
    creditSettings,
  } = props;
  const orgId = cdApp.organization.id;
  const { requireImageCredits } = creditSettings;
  const [visible, setVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const [filesWithError, setFilesWithError] = useState<
    { file: RcFile; error: string }[]
  >([]);
  const [formValues, setFormRValues] = useState<FormValues | null>(null);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const [disableFinish, setDisableFinish] = useState<boolean>(false);
  const editFieldDisabled = !!(folder && folder.groupId);
  const onStepChanging = (step: number) => {
    form
      .validateFields(['visibility', 'groupId', 'copyright', 'folderId'])
      .then(() => {
        const isValid =
          form.getFieldError('visibility').length === 0 &&
          form.getFieldError('groupId').length === 0 &&
          form.getFieldError('copyright').length === 0 &&
          form.getFieldError('folderId').length === 0;
        if (isValid) {
          form.submit();
          setCurrent(step);
        }
      });
  };
  return (
    <div>
      <GlobalStyle />
      <Button type="primary" icon={<UploadOutlined />} onClick={showModal}>
        {gettextCatalog.getString('Upload to folder')}
      </Button>
      <Modal
        width={700}
        open={visible}
        centered
        title={
          <Steps
            style={{ marginRight: 50, width: '80%' }}
            direction="horizontal"
            size="small"
            current={current}
            onChange={onStepChanging}
          >
            <Steps.Step title={gettextCatalog.getString('Settings')} />
            <Steps.Step title={gettextCatalog.getString('Upload Files')} />
          </Steps>
        }
        onCancel={hideModal}
        getContainer={() =>
          document.getElementsByClassName('antd-ns').item(0) as HTMLElement
        }
        footer={
          current === 1 ? (
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
                form.resetFields();
                setCurrent(0);
                onFinish && onFinish();
              }}
              disabled={disableFinish}
            >
              {gettextCatalog.getString('Close')}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                onStepChanging(1);
              }}
            >
              {gettextCatalog.getString('Next')}
            </Button>
          )
        }
      >
        {current === 0 ? (
          <Form
            form={form}
            ref={formRef}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 12,
            }}
            layout="horizontal"
            onFinish={(values) => {
              setFormRValues(values as FormValues);
            }}
            fields={[
              { name: ['visibility'], value: 'group' },
              {
                name: ['folderId'],
                value: folder && folder.id ? folder.id : -1,
              },
              {
                name: ['groupId'],
                value: folder && folder.groupId ? folder.groupId : undefined,
              },
            ]}
          >
            <Form.Item
              name="folderId"
              label={gettextCatalog.getString('Folder')}
              extra={
                !folder
                  ? gettextCatalog.getString(
                      'To place an image in a folder, please navigate to the folder before clicking upload.'
                    )
                  : undefined
              }
            >
              <Text disabled style={{ padding: '5px 0' }}>
                <FontAwesomeIcon icon={faFolder} style={{ marginRight: 5 }} />{' '}
                {folder
                  ? folder.title
                  : gettextCatalog.getString('Main folder')}
              </Text>
            </Form.Item>
            <Form.Item
              name="visibility"
              label={gettextCatalog.getString('Visibility')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('Please choose visibility'),
                },
              ]}
            >
              <Select
                disabled={!canChangeVisibility}
                placeholder={gettextCatalog.getString('Choose visibility..')}
                getPopupContainer={mountingContainer}
              >
                <Select.Option value="web">
                  {gettextCatalog.getString('Public')}
                </Select.Option>
                <Select.Option value="group">
                  {gettextCatalog.getString('Only share with group')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="groupId"
              label={gettextCatalog.getString('Share with group')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('Please select a Group'),
                },
              ]}
            >
              <Select
                placeholder={gettextCatalog.getString('Select group')}
                disabled={editFieldDisabled}
                getPopupContainer={mountingContainer}
              >
                {groups.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="copyright"
              label={gettextCatalog.getString('Copyright')}
              rules={
                requireImageCredits
                  ? [
                      {
                        required: true,
                        message: gettextCatalog.getString(
                          'Copyright is required'
                        ),
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder={gettextCatalog.getString(
                  'i.e. Photo by John Smith'
                )}
                addonBefore={<FontAwesomeIcon icon={faCopyright} />}
              />
            </Form.Item>
          </Form>
        ) : (
          <div>
            {filesWithError.map((fileWithError) => (
              <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />{' '}
                {fileWithError.file.name}
                <span> {fileWithError.error}</span>
              </Paragraph>
            ))}
            <Upload.Dragger
              name="file"
              listType="picture"
              multiple
              accept={accept}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
              }}
              beforeUpload={(file: RcFile) => {
                const isValid = fileValidator(file);
                if (!isValid) {
                  setFilesWithError([
                    ...filesWithError,
                    {
                      file,
                      error: gettextCatalog.getString('File size is too big'),
                    },
                  ]);
                  return Promise.reject(
                    gettextCatalog.getString('File size is too big')
                  );
                }
                return true;
              }}
              headers={{
                Authorization: `Bearer ${AuthenticationService.getAccessToken()}`,
              }}
              action={`${cdApp.config.api.main}/files/upload?organizationId=${orgId}`}
              data={(file) => ({
                type: 'file',
                isHtml5: true,
                title: file.name,
                groupId: formValues && formValues.groupId,
                visibility: formValues && formValues.visibility,
                organizationId: orgId,
                credits: (formValues && formValues.copyright) || '',
                folderId: formValues && formValues.folderId,
              })}
              onChange={({ fileList }) => {
                if (
                  fileList.length > 0 &&
                  fileList.every((file) => file.status === 'done')
                ) {
                  setDisableFinish(false);
                } else {
                  setDisableFinish(true);
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                {gettextCatalog.getString(
                  'Click or drag files to this area to upload'
                )}
              </p>
              <p className="ant-upload-hint">
                {gettextCatalog.getString('Single and bulk upload.')}
              </p>
            </Upload.Dragger>
          </div>
        )}
      </Modal>
    </div>
  );
};

export interface FileUploadContainerProps {
  folder: FileArchiveBreadCrumb | undefined;
  onFinish: () => void;
  accept?: string;
  style: React.CSSProperties;
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({
  folder,
  onFinish,
  accept,
  style,
}) => {
  const fileValidator = useFileValidator();
  const lookups = useGetLookups();
  const canChangeVisibility = useHasPermission()(
    FilePermissions.changeVisibility
  );
  const creditSettings: Settings = useSelector(
    (state) => state.filepicker?.lookups.settings
  );
  return (
    <div style={{ ...style }}>
      <GlobalStyle />
      <FileUploadModal
        fileValidator={fileValidator}
        accept={accept}
        groups={lookups.groups}
        canChangeVisibility={canChangeVisibility}
        folder={folder}
        onFinish={onFinish}
        creditSettings={creditSettings}
      />
    </div>
  );
};

export default FileUploadContainer;
