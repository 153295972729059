'use strict';

function TaxonomiesModel($$resource, cdResourceColors) {
  const baseUrl = `${cdApp.config.api.main}/taxonomies`;

  const constructor = $$resource(
    `${baseUrl}/:id`,
    {},
    {
      trackAttendance: {
        method: 'PUT',
        url: `${baseUrl}/attendance`,
      },
    }
  );

  constructor.colors = cdResourceColors;
  return constructor;
}
TaxonomiesModel.$inject = ['$$resource', 'cdResourceColors'];

angular.module('cdApp.shared').factory('Taxonomies', TaxonomiesModel);
