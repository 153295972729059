export class BaseError extends Error {
  public status: number;
  public errors?: any;
  public constructor(
    message = 'Base error',
    name = 'BaseError',
    status = 500,
    errors?: any
  ) {
    super();
    super.message = message;
    super.name = name;
    this.status = status;
    this.errors = errors;
    Error.captureStackTrace(this, this.constructor);
  }
}

export class BadRequest extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Bad request', 'BadRequest', 400, errors);
  }
}

export class Unauthorized extends BaseError {
  public constructor(message: string, errors?: any) {
    // Should only be used by the bearer system. 401 is caught by the FE and signals a log out.
    super(message || 'Unauthorized', 'UnauthorizedError', 401, errors);
  }
}

export class PaymentRequired extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Payment Required', 'PaymentRequiredError', 402, errors);
  }
}

export class Forbidden extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Forbidden', 'ForbiddenError', 403, errors);
  }
}

export class NotFound extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Not found', 'NotFoundError', 404, errors);
  }
}

export class Unacceptable extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Unacceptable', 'Unacceptable', 406, errors);
  }
}

export class NotConsistent extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Not consistent', 'NotConsistent', 409, errors);
  }
}

export class UpgradeRequired extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Upgrade Required', 'UpgradeRequired', 426, errors);
  }
}

export class TooManyRequests extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Too many requests', 'TooManyRequests', 429, errors);
  }
}

export class InternalServerError extends BaseError {
  public constructor(message: string, errors?: any) {
    super(
      message || 'Internal server error',
      'InternalServerError',
      500,
      errors
    );
  }
}

export class ServiceUnavailable extends BaseError {
  public constructor(message: string, errors?: any) {
    super(message || 'Service unavailable', 'ServiceUnavailable', 503, errors);
  }
}

export const buildErrorEntityByStatus = (
  status: number,
  message: string,
  errors?: any
): BaseError => {
  switch (status) {
    case 400:
      return new BadRequest(message, errors);
    case 401:
      return new Unauthorized(message, errors);
    case 402:
      return new PaymentRequired(message, errors);
    case 403:
      return new Forbidden(message, errors);
    case 404:
      return new NotFound(message, errors);
    case 406:
      return new Unacceptable(message, errors);
    case 409:
      return new NotConsistent(message, errors);
    case 426:
      return new UpgradeRequired(message, errors);
    case 429:
      return new TooManyRequests(message, errors);
    case 500:
      return new InternalServerError(message, errors);
    case 503:
      return new ServiceUnavailable(message, errors);
    default:
      return new BaseError(message, null, status, errors);
  }
};
