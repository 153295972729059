import _ from 'lodash';

import { getAddressFormat } from '@/react/calendar/event-details/services/address.service.ts';

class AppUtilsService {
  constructor(moment, $filter, gettextCatalog, cdApp, cdRedactedValue) {
    'ngInject';

    this.moment = moment;
    this.$filter = $filter;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.cdRedactedValue = cdRedactedValue;

    this.colors = [
      '#e57373',
      '#f06292',
      '#ba68c8',
      '#9575cd',
      '#7986cb',
      '#64b5f6',
      '#4fc3f7',
      '#4dd0e1',
      '#4db6ac',
      '#81c784',
      '#aed581',
      '#ff8a65',
      '#d4e157',
      '#673ab7',
      '#ffb74d',
      '#a1887f',
      '#90a4ae',
    ];

    this.addressKeys = {
      streetNumber: 'street_number',
      route: 'route',
      postalCode: 'postal_code',
      locality: 'locality',
      country: 'country',
      administrativeLevel1: 'administrative_area_level_1',
      subLocality: 'sublocality',
      postalTown: 'postal_town',
      premise: 'premise',
    };

    this.addressObjectTemplate = {
      DK: {
        address: ['<%= route %> <%= streetNumber %>'],
        zipcode: ['<%= postalCode %>'],
        city: ['<%= subLocality %>', '<%= locality %>'],
        country: ['<%= country %>'],
      },

      DE: {
        address: ['<%= route %> <%= streetNumber %>'],
        zipcode: ['<%= postalCode %>'],
        city: ['<%= locality %>'],
        country: ['<%= country %>'],
      },

      US: {
        address: ['<%= streetNumber %> <%= route %>'],
        zipcode: ['<%= postalCode %>'],
        city: ['<%= locality %>'],
        state: ['<%= administrativeLevel1 %>'],
        country: ['<%= country %>'],
      },

      GB: {
        // TODO handle <%= premise %>,
        address: ['<%= streetNumber %> <%= route %>'],
        zipcode: ['<%= postalCode %>'],
        city: ['<%= postalTown %>'],
        country: ['<%= country %>'],
      },

      default: {
        address: ['<%= route %> <%= streetNumber %>'],
        zipcode: ['<%= postalCode %>'],
        city: ['<%= locality %>'],
        country: ['<%= country %>'],
      },
    };
  }

  /**
   * Determine whether a string can be linkified
   *
   * @param {String} inputText The string or text to be checked
   * @return {Boolean}
   */
  isLinky(inputText) {
    const linkRegex =
      /((ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;
    return linkRegex.test(inputText);
  }

  /**
   * Format a single response value corresponding to a form component
   *
   * @param {any} submission The raw response value to be formatted
   * @param {Object} component The form component object the response relates to
   * @param {Boolean} linkify Whether to transform strings containing URLs into HTML links
   *
   * @returns {any} The formatted value
   */
  formatSubmission(submission, component, linkify) {
    const { moment, cdApp, gettextCatalog } = this;

    if (submission === this.cdRedactedValue) {
      return this.cdRedactedValue;
    }

    // Dates
    if (moment(submission, 'YYYY-MM-DD', true).isValid()) {
      return moment(submission).format('LL');
    }

    // Checkbox
    if (
      _.includes(['checkbox', 'consent', 'newsletter'], component.fieldType)
    ) {
      return submission ? gettextCatalog.getString('Yes') : '-';
    }

    // Dropdown
    if (component.fieldType === 'dropdown') {
      return _.get(
        _.find(component.data.values, { value: submission }),
        'label'
      );
    }

    // Radio buttons (Single choice)
    if (component.fieldType === 'radio') {
      return _.get(_.find(component.values, { value: submission }), 'label');
    }

    // Multiple choice
    if (component.fieldType === 'multipleChoice') {
      return _(component.values)
        .map((item) => {
          // Only pick items that exist in the selected values
          if (_.get(submission, item.value)) return item.label;
        })
        .compact()
        .sortBy()
        .join(', ');
    }

    // Country
    if (component.type === 'peopleCountry') {
      return _.get(
        cdApp.data,
        ['countries', submission, 'nameTranslated'],
        submission
      );
    }

    // CPR number
    if (component.type === 'cprNumber') {
      return submission
        ? [submission.slice(0, 6), '-', submission.slice(6)].join('')
        : submission;
    }

    // Files
    if (component.type === 'file') {
      return _(submission).map('title').compact().join(', ');
    }

    // Text with URL
    if (linkify && this.isLinky(submission)) {
      return this.$filter('linky')(submission, '_blank', {
        rel: 'noopener noreferrer',
      });
    }

    return submission;
  }

  /**
   * Convert a HEX color to RGB(A)
   *
   * @param {String} hex The HEX color
   * @param {Number} [alpha] Optional alpha channel (opacity)
   *
   * @returns {String}
   */
  hexToRgb(hex, alpha = 1) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${[r, g, b, alpha].join(', ')})`;
  }

  /**
   * Generate a HEX color based on a text
   *
   * @param {String} text The text to generate the color from
   * @returns {String} A HEX color code
   */
  generateColor(text) {
    return this.colors[Math.abs(this.textToNumber(text)) % this.colors.length];
  }

  /**
   * Convert a text to a 32bit integer
   *
   * @param {String} text The text to convert
   * @returns {Integer} The unique 32bit integer representing the text
   */
  textToNumber(text) {
    let h = 0;

    for (let i = 0; i < text.length; i++) {
      h = 31 * h + text.charCodeAt(i);
      h |= 0; // Convert to 32bit integer
    }

    return h;
  }

  getErrorMessage(error) {
    const { gettextCatalog } = this;
    return (
      _.get(error, 'data.message') ||
      gettextCatalog.getString(
        'An error occurred, please try again. If the problem persists, please contact our support.'
      )
    );
  }

  formatGoogleLocation(location) {
    if (!location) return null;
    const string = _.get(location, 'string');
    if (!_.isEmpty(string)) return string;
    const name = _.get(location, 'name');
    const formattedAddress = _.get(location, 'formatted_address');
    const shouldIncludeName =
      _.isString(name) &&
      !_.isEmpty(name) &&
      !_.includes(formattedAddress, name);
    return `${shouldIncludeName ? name.concat(', ') : ''}${formattedAddress}`;
  }

  /**
   * Build Google places object (synthetic google.maps.places.PlaceResult
   * object) with the location data from the Backend API.
   *
   * @param {object} location A location object with the Backend format
   * @param {number} [resourceId] The ID of the resource (if any) related to the location
   * @returns {object} A location object with Google format
   */
  buildGooglePlacesObject(location, resourceId) {
    /**
     * If the provided location is a falsy value, a string or it is an object
     * that has the `formatted_address` property (the provided location already
     * is a Google location), then return it as it is
     */
    return {
      ...location,
      custom_data: {
        resourceId,
      },
    };
  }

  parseGoogleAddressComponent(googleAddress, countryCode = 'DK') {
    if (!googleAddress) return {};
    const addressComponent = googleAddress.address_components;
    if (!addressComponent) return {};
    const parsedAddress = _.reduce(
      this.addressKeys,
      (carry, addressKey, key) => {
        const matchedComponent = _.find(addressComponent, (item) =>
          item.types.includes(addressKey)
        );

        carry[key] =
          (matchedComponent && matchedComponent.long_name) || undefined;
        return carry;
      },
      {}
    );

    const addressFormatComponents =
      this.addressObjectTemplate[(countryCode || '').toUpperCase()] ||
      this.addressObjectTemplate.default;

    let address = _.reduce(
      addressFormatComponents,
      (carry, addressFormatComponent, key) => {
        const candidateValues = _.map(addressFormatComponent, (format) =>
          this.formatString(format, parsedAddress)
        );

        carry[key] = _.head(_.compact(candidateValues));
        return carry;
      },
      {}
    );

    // add name
    address.name = googleAddress.name;

    address.string = this.getAddressString(address);

    // add geo location
    address = {
      ...address,
      longitude: googleAddress.geometry.location.lng(),
      latitude: googleAddress.geometry.location.lat(),
    };

    return address;
  }

  getAddressString(address) {
    if (!address) return '';
    const cloneAddress = { ...address };
    if (!cloneAddress.address) cloneAddress.address = '';
    if (!cloneAddress.address2) cloneAddress.address2 = '';
    if (!cloneAddress.city) cloneAddress.city = '';
    if (!cloneAddress.zipcode) cloneAddress.zipcode = '';
    if (!cloneAddress.country) cloneAddress.country = '';

    return getAddressFormat(cloneAddress);
  }

  isResourceChurch(resource) {
    return _.get(resource, 'type') === 'church';
  }

  getTaxonomyColorClass(taxonomy) {
    return `color-${_.get(taxonomy, 'color', 0)}`;
  }

  getResourceLocationClass(resourceWithLocation) {
    return this.isResourceChurch(resourceWithLocation)
      ? 'fa fa-church fa-1'
      : `fa fa-circle fa-1 ${this.getTaxonomyColorClass(resourceWithLocation)}`;
  }

  showError(errorMessage, toastr, gettextCatalog) {
    toastr.error(
      errorMessage ||
        gettextCatalog.getString(
          'An error occurred, please try again. If the problem persists, please contact our support.'
        )
    );
  }
}

AppUtilsService.$inject = [
  'moment',
  '$filter',
  'gettextCatalog',
  'cdApp',
  'cdRedactedValue',
];

angular.module('cdApp.shared').service('appUtils', AppUtilsService);
