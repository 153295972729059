import { Moment } from 'moment';
import { EventApi } from '@fullcalendar/core';

import { Stole } from '../../intention/models/stole';
import { CalendarPoster } from '../types/event';

import { Organization } from '@/react/shared/models/organization';

export enum AttendanceStatus {
  NoAnswer = 'no-answer',
  Going = 'yes',
  Maybe = 'maybe',
  NotGoing = 'no',
}

export interface FBaseEvent {
  users: any;
  visibility: any;
  id: number;
  title: string;
  startDate: string | Date | Moment;
  endDate: string | Date | Moment;
  type: Type | string;
  allDay: boolean;
  preparationStartDate: string | Date;
  cleanupEndDate: string | Date;
  firstTitle: string;
  clientVersion: number;
}

export interface FGroupedAbsences extends FBaseEvent {
  ids: number[];
  isOpened: boolean;
}
export interface User {
  name: string;
  attending: string;
  email: string;
  image: string;
  createdAt: Date;
}

export interface UsersDictionary {
  [id: number]: User;
}
export interface FEvent extends FBaseEvent {
  form: any;
  status: AttendanceStatus | null;
  backgroundColor: string;
  isPast: boolean;
  cls: string;
  fcEvent: any;
  editable: boolean;
  repeating: boolean;
  master: number | null;
  authorId: number;
  organizationId: number;
  comments?: CommentsObj[];
  substitute?: null;
  visibility: Visibility;
  stole: Partial<Stole>;
  taxonomies: Taxonomies | number[];
  noOfIntentions: number;
  noOfStoles: number;
  isIntentionsBilled: boolean;
  isStolesBilled: boolean;
  resources: {
    [id: number]: { color: number; id: number; name: string };
  };
  users: UsersDictionary;
  mainCategory: number;
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
  churches: Church[];
}

export interface Church {
  id: number;
  color: number;
  name: string;
}

export interface CalendarTask {
  id: string;
  calendarId: number;
  createdAt: Date;
  deletedAt: Date | null;
  note: string;
  required: number;
  task: {
    group: { id: number; name: string; type: string | null };
    id: number;
    groupId: number;
    title: string;
  };
  access: {
    canAssignMySelf: boolean;
    canEdit: boolean;
  };
  taskId: number;
  updatedAt: Date;
}
export interface Shift {
  id: number;
  taskId: number;
  updatedAt: Date;
  updatedBy: {
    id: number;
    email: string;
    name: string;
    picture: string;
  };
  user: {
    id: number;
    name: string;
    email: string;
    picture: string;
  };
  userId: number;
}

export interface IcalSubscription {
  color: number;
}

export enum EventType {
  Absence = 'absence',
  Event = 'event',
  Feed = 'feed',
  // External denotes that the event is originating from a difference organization.
  External = 'externalEvent',
}

export interface LocationObj {
  name?: string;
  address: string;
  address2: string;
  city: string;
  zipcode: string;
  country: string;
  state: string;
  longitude?: string;
  latitude?: string;
  resourceId?: number;
  string?: string;
  custom_data?: { resourceId: number };
}
export interface CommentsObj {
  id: number;
  body: string;
  authorId: number;
  status: number;
  name: string;
  updatedAt: string;
  canEdit: boolean;
  canDelete: boolean;
}
export interface DetailedEvent extends FEvent {
  absenceComment: string;
  shifts: Shift[];
  calendar_tasks: CalendarTask[];
  locationName?: string;
  groupId: number;
  category: any;
  id: number;
  title: string;
  type: EventType;
  visibility: Visibility;
  startDate: Date | string;
  endDate: Date | string;
  rrule: string;
  parentEntity: number;
  filters: Filters;
  authorId: number;
  organizationId: number;
  allDay: boolean;
  author: Author;
  createdAt: Date;
  updatedAt: Date;
  showInSlideshow: boolean;
  taxonomies: Taxonomies | number[];
  iCalSubscription: IcalSubscription;
  group: { id: number };
  groups: { id: number }[];
  hideEndTime: boolean;
  imageId: null;
  image: null;
  cropKey: string;
  description: string;
  internalNote: string;
  secureInformation: string;
  summary: null;
  location: null;
  locations: any[];
  contributor: null;
  price: null;
  sogndk: Sogndk;
  mainCategory: number;
  alias: null;
  attendance: Attendance;
  fields: FieldsAccess;
  files: {
    calendar_files: { calendarId: number; fileId: number };
    canDownload: boolean;
    downloadUrl: string;
    fileName: string;
    groupId: number;
    id: number;
  }[];
  posters: CalendarPoster[];
  htmlDescParsedToText: boolean;
  canComment: boolean;
  canNotify: boolean;
  canEdit: boolean;
  canDelete: boolean;
  comments: any[];
  churches: Church[];
  organization: Organization;
  locationObj: LocationObj;
  facebook?: {
    exportStatus: string;
    fbPostId: string;
    isScheduled: boolean;
    message: string;
    publish: boolean;
    publishOn: string;
    publishedOn: string;
    schedulingOptions: any;
  };
  noOfAssignedIntentions: number;
  rotas: Rota[];
  shiftsAndIntentions: {
    calendarId?: number;
    startDate?: Date | string;
    endDate?: Date | string;
    shifts?: {
      note?: string;
      taskId: number;
      users?: Array<{ id: number; access?: { canUnassign: boolean } }>;
      access: { canEdit: boolean; canAssignMySelf: boolean };
    }[];
    intentions?: Intention[];
    rrule?: string;
  }[];
  fromPopup: boolean;
  isCopiedEvent: boolean;
  url: string;
}

export interface Intention {
  id: string;
  reference: string;
  text: string;
  priority: string;
  intentionType: string;
  founder: string;
  access: { canUnassign: boolean };
  assignedBy: string;
  isRemoved?: boolean;
}
export interface VisibilityResponse {
  canEdit: boolean;
  canCreate: boolean;
  canNotify: boolean;
  fields: {
    facebook: boolean;
  };
  createEventPermissions: object;
}

export interface PrintEvent {
  author: string;
  description?: string;
  id: number;
  url: string;
  formattedDate: string;
  formattedDescription: string;
  formattedTitle: string;
  image: string;
  startDate: string;
  endDate: string;
  allDay: boolean;
  title: string;
  locationName: string;
  location: string;
  contributor?: string;
  price: null;
  organization: Pick<
    Organization,
    'id' | 'name' | 'installationUrl' | 'countryIso2'
  >;
  summary?: string;
}
export interface Rota {
  taskId: number;
  title?: string;
  required?: number;
}

export interface ExtendedEventApi extends EventApi {
  preparationStartDate: string | Date;
  cleanupEndDate: string | Date;
  editable: boolean;
}

export interface Attendance {
  attendanceCategory: number;
  attendanceFields: AttendanceField[];
  vicar?: { id: number };
}

export interface AttendanceField {
  id: string;
  name: string;
  tracker: string;
  locked: boolean;
  order: number;
  amount: number;
  lastUpdatedBy: number;
}
export interface Author {
  id: number;
  email: string;
  contact: Contact;
}

export interface Contact {
  fullName: string;
  firstName: string;
  lastName: string;
}

export interface FieldsAccess {
  id: FieldAccess;
  title: FieldAccess;
  type: FieldAccess;
  visibility: VisibilityType;
  startDate: FieldAccess;
  endDate: FieldAccess;
  rrule: FieldAccess;
  parentEntity: FieldAccess;
  authorId: FieldAccess;
  organizationId: FieldAccess;
  allDay: FieldAccess;
  author: FieldAccess;
  createdAt: FieldAccess;
  updatedAt: FieldAccess;
  showInSlideshow: FieldAccess;
  taxonomies: FieldAccess;
  updateAll: FieldAccess;
  allowDoubleBooking: FieldAccess;
  groupIds: FieldAccess;
  groups: FieldAccess;
  hideEndTime: FieldAccess;
  imageObj: FieldAccess;
  imageSize: FieldAccess;
  imageId: FieldAccess;
  image: FieldAccess;
  files: FieldAccess;
  description: FieldAccess;
  internalNote: FieldAccess;
  summary: FieldAccess;
  secureInformation: FieldAccess;
  locationName: FieldAccess;
  location: FieldAccess;
  locations: FieldAccess;
  contributor: FieldAccess;
  price: FieldAccess;
  resources: FieldAccess;
  sogndk: FieldAccess;
  mainCategory: FieldAccess;
  users: FieldAccess;
  locationObj: FieldAccess;
  calendar_tasks: FieldAccess;
  shifts: FieldAccess;
  otherCategory: FieldAccess;
  alias: FieldAccess;
  cropKey: FieldAccess;
  attendance: FieldAccess;
  facebook: FieldAccess;
  rotas: FieldAccess;
  intentions: FieldAccess;
  form: FieldAccess;
}

export interface FieldAccess {
  canEdit: boolean;
}

export interface VisibilityType {
  canEdit: boolean;
  allowedValues: string[];
}

export interface The123 {
  name: string;
  color: number;
}

export interface Sogndk {
  id?: number;
  export: boolean;
  sognId: number;
  churchId: number;
  pastorId: number;
  categoryId: number;
  status?: number;
}

export interface Taxonomies {
  [id: number]: Taxonomy;
}

export interface Taxonomy {
  id?: number;
  name: string;
  color: number;
  isMaster: boolean;
  parentResourceId?: number;
  type?: string;
  amount?: number;
}

export interface Filters {
  categories: {
    [key: string]: string;
  };
  resources: Resources;
  absences: {
    [key: string]: string;
  };
  users: {
    [key: string]: string;
  };
  usersOnShifts: {
    [key: string]: string;
  };
  groups: {
    [key: string]: string;
  };
}
export interface Resources {
  id: number;
}
export interface Icons {
  repeated?: Repeated;
  'access-public'?: AccessPublic;
  'access-group'?: AccessGroup;
  attendance?: string;
}
export enum AccessGroup {
  FaFaGroup = 'fa fa-group',
}
export enum AccessPublic {
  FaFaGlobe = 'fa fa-globe',
}
export enum Repeated {
  FaFaRepeat = 'fa fa-redo',
}
export enum Type {
  Absence = 'absence',
  Event = 'event',
  GroupedAbsence = 'absence-minimized',
  External = 'external',
}
export enum Visibility {
  Internal = 'internal',
  Public = 'public',
  Private = 'private',
}

export interface EventConflict {
  users: { [key: string]: ResourceValue };
  resources: { [key: string]: ResourceValue };
  workplan: { [key: string]: WorkPlanConflicts };
}

interface WorkPlanConflicts {
  name: string;
  workType: string[];
}
export interface ResourceValue {
  name: string;
  data: DetailedEvent[];
}

export interface Author {
  id: number;
  email: string;
}

export interface ResourceElement {
  id: number;
  name: string;
  color: number;
  description: null;
  isRoom: boolean;
  type: Type;
  parentResourceId: null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  organizationId: number;
  calendar_resources: CalendarResources;
}

export interface CalendarResources {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  calendarId: number;
  resourceId: number;
}

export enum Type {
  Site = 'site',
}

export interface User {
  id: number;
  email: string;
  contact: Contact;
  calendar_user: CalendarUser;
}

export interface CalendarUser {
  attending: Attending;
}

export enum Attending {
  NoAnswer = 'no-answer',
}

export interface CalendarSubscription {
  id: string;
  url: string;
  name: string;
  color: number;
}

export const convertTypeToEventType = (type: Type): EventType => {
  switch (type) {
    case Type.External:
      return EventType.External;
    case Type.Event:
      return EventType.Event;
    case Type.Absence:
      return EventType.Absence;
    default:
      return EventType.Event;
  }
};
