import { orderBy } from 'lodash';
import { selectorFamily } from 'recoil';

import { ApiSearchResult } from '@/react/api';
import { CalendarPoster } from '@/react/calendar/types/event';
import { GetEventsPosterAndInvitations } from '@/react/calendar/event-details/store/invitations';

export const GetEventPosters = selectorFamily<
  ApiSearchResult<CalendarPoster>,
  { eventId: number }
>({
  key: 'GetEventPosters',
  get:
    ({ eventId }) =>
    ({ get }) => {
      const posters = get(
        GetEventsPosterAndInvitations({ calendarId: eventId })
      ).posters;
      if (!posters) {
        return { items: [], total: 0 };
      }
      return {
        items: orderBy(posters, ['createdAt'], ['desc']),
        total: posters.length,
      };
    },
});
