import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import OrderOfServiceButton from '@/react/calendar/components/OrderOfServiceButton';

angular.module('cdApp.calendar').component(
  'cdrOrderOfServiceButton',
  react2angular(
    sharedContext.use(OrderOfServiceButton),
    [
      'startDate',
      'endDate',
      'categories',
      'groups',
      'resources',
      'users',
      'search',
      'disabled',
      'eventsWithoutResourcesInChurchIds',
    ],

    []
  )
);
