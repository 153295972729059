import { getExternalLink } from '@/react/homepage/services/external-link.service';

(function () {
  'use strict';

  function BlogListController(
    moment,
    $scope,
    $http,
    $uibModal,
    toastr,
    gettextCatalog,
    Blogs,
    Taxonomies,
    Groups,
    $state,
    $rootScope
  ) {
    // =============================================================================
    // General stuff.
    // =============================================================================

    $scope.search = {};
    // Pagination.
    $scope.currentPage = 1;
    $scope.numPerPage = 10;
    $scope.maxSize = 5;

    $scope.sortType = 'publishDate';
    $scope.sortReverse = false;

    // =============================================================================
    // Fetch the data needed.
    // =============================================================================
    $scope.homepageV2 = $state.current.name === 'app.private.website.blogs';
    // Get groups
    Groups.query().$promise.then((res) => {
      $scope.groups = res;
    });

    // Get the blog categories.
    Taxonomies.query(
      {
        type: 'blog',
      },

      function (response) {
        $scope.categoryList = response;
      }
    );

    let loadBlogs = function (search = null) {
      $scope.loading = true;

      Blogs.get(
        {
          'cid[]': $scope.selectedCategories,
          offset: ($scope.currentPage - 1) * $scope.numPerPage,
          limit: $scope.numPerPage,
          orderField: $scope.sortType,
          order: $scope.sortReverse ? 'ASC' : 'DESC',
          search,
        },

        function (response) {
          $scope.blogs = response.blogs;
          $scope.totalItems = response.count;
          $scope.loading = false;
        }
      );
    };

    loadBlogs();

    // =============================================================================
    // Utilities
    // =============================================================================

    $scope.getViewUrl = function (blog) {
      if ($scope.homepageV2) return;
      return $state.href('app.private.intranet.group.blog', {
        gid: blog.groupId,
        id: blog.id,
        global: ($rootScope.currentGroupState === 'blogs' && 'false') || '',
      });
    };

    $scope.getPublicViewUrl = function (blog) {
      if (blog.visibility !== 'web') return;
      if (blog.url) return blog.url;
      if ($scope.homepageV2) {
        return (
          getExternalLink(blog.id, 'blog') +
          '/' +
          encodeURIComponent(blog.title)
        );
      }
      return getExternalLink(blog.id, 'blog');
    };

    $scope.getGroupName = function (groupId) {
      return _.result(
        _.find($scope.groups, function (element) {
          return element.id === groupId;
        }),
        'name'
      );
    };

    // From list of blog categories generate a string with all categories separated by comma.
    $scope.structureBlogCategories = function (categories) {
      return _.result(
        _.find($scope.categoryList, function (element) {
          return element.id === categories;
        }),
        'name'
      );
    };

    // Translate status id to status name.
    $scope.blogStatus = function (blog) {
      // Check the status, and based on it, return the correct status title.
      if (blog.visibility === 'draft') {
        return gettextCatalog.getString('Unpublished');
      } else if (blog.visibility === 'web') {
        return blog.facebook?.publishedOn
          ? gettextCatalog.getString('Published on website & facebook')
          : gettextCatalog.getString('Published on website');
      } else {
        return gettextCatalog.getString('Published in group');
      }
    };

    // Generate a date from the timestamp.
    $scope.sinceTooltip = function (number) {
      // Transfer drupal timestamp to JS.
      return moment(number).format('lll');
    };

    // Make sure the destination is added to the edit.
    $scope.getDestination = function (url) {
      url = url || window.location.pathname + window.location.hash;
      return encodeURIComponent(url);
    };

    // Contains the filter by categories.
    $scope.selectedCategories = [];
    $scope.selectedCategoriesNames = [];

    $scope.setSelectedCategories = function ($event, cid, category_name) {
      $scope.loading = true;
      $event.stopPropagation();

      $scope.currentPage = 1;

      if (_.includes($scope.selectedCategories, cid)) {
        $scope.selectedCategories = _.without($scope.selectedCategories, cid);
      } else {
        $scope.selectedCategories.push(cid);
      }

      if (_.includes($scope.selectedCategoriesNames, category_name)) {
        $scope.selectedCategoriesNames = _.without(
          $scope.selectedCategoriesNames,
          category_name
        );
      } else {
        $scope.selectedCategoriesNames.push(category_name);
      }

      loadBlogs();

      return false;
    };

    $scope.isChecked = function (cid) {
      if (_.includes($scope.selectedCategories, cid)) {
        return 'fa fa-check-square pull-left';
      }
      return 'far fa-square pull-left';
    };

    // Pagination was pressed, and the page was changed.
    $scope.pageChanged = function () {
      loadBlogs();
    };

    $scope.filterBlogsByTitle = function (search) {
      loadBlogs(search);
    };
    // Sorting of the table.
    $scope.sortTable = function (sort, reverse) {
      $scope.sortType = sort;
      $scope.sortReverse = reverse;
      loadBlogs();
    };

    $scope.getExternalLink = function (blogId) {
      return getExternalLink(blogId, 'blog');
    };

    $scope.editBlog = function (canEdit, blogId, groupId) {
      if (!canEdit) {
        const groupName = this.getGroupName(groupId);
        toastr.error(
          gettextCatalog.getString(
            `You don't have access to edit this blog post because you are not a member of the group: {{name}}.`,
            {
              name: groupName,
            }
          )
        );
        return;
      }
      $http({
        method: 'GET',
        url: cdApp.config.api.main + '/blogs/' + blogId,
      }).success((blogRes) => {
        $scope.openCreateContentWindow('blog', 'edit', blogRes);
      });
    };

    // =============================================================================
    // Modals.
    // =============================================================================

    // Delete blog modal.
    $scope.deleteBlog = function (blog) {
      $uibModal.open({
        scope: $scope,
        templateUrl: 'modalDeleteBlog.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'blog',
          function ($scope, $uibModalInstance, blog) {
            'ngInject';

            $scope.modalBlog = blog;
            $scope.removeBlog = function () {
              $scope.processing = true;
              $http({
                method: 'DELETE',
                url: cdApp.config.api.main + '/blogs/' + blog.id,
              }).success(function () {
                loadBlogs();
                $uibModalInstance.dismiss('cancel');
              });
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],

        resolve: {
          blog: function () {
            return blog;
          },
        },
      });
    };
  }
  BlogListController.$inject = [
    'moment',
    '$scope',
    '$http',
    '$uibModal',
    'toastr',
    'gettextCatalog',
    'Blogs',
    'Taxonomies',
    'Groups',
    '$state',
    '$rootScope',
  ];

  angular
    .module('cdApp.intranet')
    .controller('BlogListController', BlogListController);
})();
