'use strict';

function PeopleMessageStatisticsController(
  $state,
  $stateParams,
  $scope,
  gettextCatalog,
  PeopleMessages
) {
  let $ctrl = this;
  const ALLOWED_STATUSES = [
    'sending',
    'delivered',
    'undelivered',
    'failed',
    'bounced',
    'spam',
    'MissingEmailError',
    'UnsubscribedAllEmailError',
    'EmailStatusError',
    'EmailBlockedError',
    'MissingPhoneError',
    'UnsubscribedAllSMSError',
    'UnsubscribedFromSegmentError',
    'PersonNotFound',
    'DuplicateMessageError',
  ];

  const MESSAGE_STATUS_DESCRIPTION = {
    sending: gettextCatalog.getString('This message is still being sent.'),
    failed: gettextCatalog.getString(
      'The sms could either not reach the client’s mobile phone or the phone number has been banned.'
    ),

    delivered: gettextCatalog.getString(
      'This message has been successfully received by the recipient.'
    ),

    undelivered: gettextCatalog.getString(
      'This sms has been queued and the Mobile network operator is trying to reach the client’s mobile.'
    ),

    bounced: gettextCatalog.getString(
      'This message could not be delivered to the recipient. Please ensure the email is correct and still in use.'
    ),

    spam: gettextCatalog.getString('This message has been marked as spam.'),
    MissingEmailError: gettextCatalog.getString(
      'The recipient does not have email, an email must exist to send a message to the recipient.'
    ),

    UnsubscribedAllEmailError: gettextCatalog.getString(
      'The recipient does not wish to receive any email messages, please review their settings.'
    ),

    EmailStatusError: gettextCatalog.getString(
      'The recipient has not confirmed their email yet.'
    ),

    EmailBlockedError: gettextCatalog.getString(
      'The recipient email is blocked by the email provider. Please ensure the email is correct and still in use.'
    ),

    MissingPhoneError: gettextCatalog.getString(
      'The recipient does not have phone, an phone number must exist to send a message to the recipient.'
    ),

    UnsubscribedAllSMSError: gettextCatalog.getString(
      'The recipient does not wish to receive any sms messages, please review their settings.'
    ),

    UnsubscribedFromSegmentError: gettextCatalog.getString(
      'The recipient does not wish to receive any similar messages, please review their settings.'
    ),

    PersonNotFound: gettextCatalog.getString(
      'The recipient does not exist any more, it is deleted from the contact database.'
    ),

    DuplicateMessageError: gettextCatalog.getString(
      'The recipient is sharing an email/phone with other recipients. A message is sent to other contact that has the same email/phone. Click to view all the recipients that are sharing email/phone.'
    ),
  };

  const MESSAGE_STATUS_STRINGS = {
    MissingEmailError: gettextCatalog.getString('Missing email address'),
    UnsubscribedAllEmailError: gettextCatalog.getString(
      'Unsubscribed from all email'
    ),

    EmailStatusError: gettextCatalog.getString('Email is blocked'),
    EmailBlockedError: gettextCatalog.getString('Email is blocked'),
    MissingPhoneError: gettextCatalog.getString('Missing phone number'),
    UnsubscribedAllSMSError: gettextCatalog.getString(
      'Unsubscribed from all SMS'
    ),

    UnsubscribedFromSegmentError: gettextCatalog.getString(
      'Unsubscribed from this list'
    ),

    DuplicateMessageError: gettextCatalog.getString('Duplicate message'),
  };

  this.$onInit = function () {
    $ctrl.sorting = {
      field: 'name',
      reverse: false,
    };

    $ctrl.filter = {};

    $ctrl.messageId = $stateParams.id;

    $ctrl.report = [];
    $ctrl.percentageStatistics = {};

    $ctrl.setFilter = setFilter;
    $ctrl.setSorting = setSorting;
    $ctrl.getHeaderClass = getHeaderClass;

    if ($stateParams.id !== undefined) {
      if ($stateParams.filter) {
        setFilter($stateParams.filter);
      }

      setStatisticsData($ctrl.message.statistics);

      PeopleMessages.report(
        { id: $stateParams.id },
        function (data) {
          $ctrl.canViewDetailedMessageStatistics = true;
          $ctrl.report = data;

          _.each($ctrl.report, function (row) {
            row.statusDetails = getStatus(row.status);

            if (row.status !== 'delivered' && row.status !== 'sending') {
              row.bounced = true;
            }

            row.isOpened = row.opened ? true : false;
            row.isClicked = row.clicked ? true : false;
          });
        },
        function (error) {
          if (error.status === 403) {
            $ctrl.report = {
              $resolved: true,
            };

            $ctrl.canViewDetailedMessageStatistics = false;
          }
        }
      );
    }
  };

  ($ctrl.resetScrollDistance = function () {
    $ctrl.scrollDistance = 25;
  })();

  $ctrl.scrollDown = function () {
    $ctrl.scrollDistance += 25;
  };

  $scope.$watch('$ctrl.filter', $ctrl.resetScrollDistance, true);

  function setStatisticsData(data) {
    $ctrl.statisticsData = data;

    if (angular.isDefined($ctrl.statisticsData.opened)) {
      $ctrl.messageType = 'email';
      _.each(
        ['delivered', 'opened', 'clicked', 'unsubscribed'],
        function (status) {
          $ctrl.percentageStatistics[status] = calculatePercentage(
            $ctrl.statisticsData[status]
          );
        }
      );
    } else {
      $ctrl.messageType = 'sms';
    }
    $ctrl.statusColors = PeopleMessages.getStatusColors($ctrl.messageType);
  }

  function calculatePercentage(value) {
    return value
      ? parseFloat((value / $ctrl.statisticsData.sent) * 100).toFixed(1)
      : 0;
  }

  function setFilter(status) {
    $ctrl.activeStat = status;
    $ctrl.filter = {};

    if (status !== 'sent') {
      if (status === 'opened' || status === 'clicked' || status === 'bounced') {
        if (status === 'opened') {
          status = 'isOpened';
        }
        if (status === 'clicked') {
          status = 'isClicked';
        }
        $ctrl.filter[status] = true;
      } else if (status === 'unsubscribed') {
        $ctrl.filter = { unsubscribed: true };
      } else {
        $ctrl.filter = { status };
      }
    }
  }

  function setSorting(field) {
    if ($ctrl.sorting.field === field) {
      $ctrl.sorting.reverse = !$ctrl.sorting.reverse;
    } else {
      $ctrl.sorting.field = field;
      $ctrl.sorting.reverse = false;
    }
  }

  function getHeaderClass(field) {
    if ($ctrl.sorting.field === field) {
      return $ctrl.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }

  function getStatus(status) {
    status = _.includes(ALLOWED_STATUSES, status) ? status : 'sending';
    const statusDescription =
      status === 'sending' && _.get($ctrl, 'statisticsData.compromised')
        ? gettextCatalog.getString(
            'Sorry! Due to problems with our mail provider we can not provide the proper status.'
          )
        : MESSAGE_STATUS_DESCRIPTION[status];

    return {
      status: MESSAGE_STATUS_STRINGS[status] || status,
      color: getStatusColor(status),
      details: statusDescription,
    };
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'sending':
      case 'MissingEmailError':
      case 'MissingPhoneError':
      case 'undelivered':
        return 'default';
      case 'spam':
      case 'UnsubscribedAllEmailError':
      case 'UnsubscribedAllSMSError':
      case 'UnsubscribedFromSegmentError':
        return 'warning';
      case 'delivered':
        return 'success';
      case 'DuplicateMessageError':
        return 'info';
      default:
        return 'danger';
    }
  };

  $ctrl.searchContactsSharingEmailOrPhone = (recipient) => {
    if ($ctrl.messageType === 'email') {
      $ctrl.search = recipient.email;
    } else {
      $ctrl.search = recipient.number;
    }
  };
}

PeopleMessageStatisticsController.$inject = [
  '$state',
  '$stateParams',
  '$scope',
  'gettextCatalog',
  'PeopleMessages',
];

angular.module('cdApp.people').component('cdPeopleMessageStatisticsState', {
  templateUrl:
    '@/app/people/message-statistics/message-statistics.component.html',
  controller: PeopleMessageStatisticsController,
  bindings: {
    message: '<',
  },
});
