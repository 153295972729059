class ContributionsLegalPopupController {
  constructor($http, gettextCatalog, cdApp, Organizations) {
    'ngInject';

    this.$http = $http;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.Organizations = Organizations;
  }

  $onInit() {
    const { cdApp, gettextCatalog } = this;

    this.countries = cdApp.data.countries;
    this.country = _.get(cdApp, 'organization.countryIso2');
    this.organizationName = _.get(cdApp, 'organization.name');
    this.connectedAccountTerms = `<a href="https://stripe.com/connect-account/legal" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
      'Stripe Connected Account Agreement'
    )}</a>`;
    this.stripeTerms = `<a href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
      'Stripe Terms of Service'
    )}</a>`;
  }

  getTermsLink() {
    const termsOfService = this.gettextCatalog.getString('Terms of service');
    return `<a ui-sref="app.private.settings.legal" target="_blank">${termsOfService}</a>`;
  }

  confirm() {
    const { cdApp, Organizations, $http } = this;

    this.loading = true;

    if (this.country !== _.get(cdApp, 'organization.countryIso2')) {
      new Organizations(
        _.extend(cdApp.organization, { countryIso2: this.country })
      ).$save();
    }

    $http
      .post(`${cdApp.config.api.main}/organizations/account`, {
        country: this.country,
        tosAcceptance: true,
      })
      .success(() => {
        this.loading = false;
        this.close();
      })
      .error(() => {
        this.loading = false;
      });
  }
}
ContributionsLegalPopupController.$inject = [
  '$http',
  'gettextCatalog',
  'cdApp',
  'Organizations',
];

angular.module('cdApp.shared').component('cdContributionsLegalPopup', {
  templateUrl: '@/app/shared/components/legal-popup/legal-popup.component.html',
  controller: ContributionsLegalPopupController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
