import { Button, Col, Row, Image, Space, Skeleton, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useRecoilValueLoadable } from 'recoil';

import { FilePickerComponentProps } from '@/react/calendar/file-picker-library/FilePickerComponent';
import { gettextCatalog } from '@/react/services/I18nService';
import { GetFile } from '@/react/files/store/files';
import { EventIcons } from '@/react/shared/components/Icons';
import { showCropImageModal } from '@/react/people/message-editor/newsletter-editor/components/cover-image/CoverImageCrop';

const { Text } = Typography;

export const CdImagePicker = ({
  value,
  onChange,
  entityId,
  entityType,
  canEdit,
  defaultImageUrl,
  canCrop = true,
  useFileObject,
}: {
  entityId: number | string;
  entityType: string;
  value?: any;
  onChange?: any;
  canEdit?: boolean;
  defaultImageUrl?: string;
  canCrop?: boolean;
  useFileObject?: boolean;
}) => {
  const [cache, setCache] = useState<number>(Date.now());
  const fileLoadable = useRecoilValueLoadable(
    GetFile(useFileObject ? value?.id : value)
  );
  const openCDFileArchive = useCallback(
    () =>
      NiceModal.show<FilePickerComponentProps>('FilePickerComponentModal', {
        isUsedFromEditor: false,
      } as FilePickerComponentProps).then(({ file }) => {
        onChange(useFileObject ? file : (file as any).id);
      }),
    [onChange, useFileObject]
  );

  const showCropModal = useCallback(() => {
    if (
      canCrop &&
      entityType !== 'event' &&
      entityType !== 'people-message' &&
      entityType !== 'contribution'
    ) {
      throw new Error(
        "Entitytype should be one of 'event' | 'people-message' | 'contribution' when canCrop is true"
      );
    }
    showCropImageModal({
      fileId: useFileObject ? value?.id : value,
      entityId,
      entityType: entityType as 'event' | 'people-message' | 'contribution',
    }).then(() => setCache(Date.now()));
  }, [canCrop, entityType, useFileObject, value, entityId]);

  const context = entityId && `${entityType}-${entityId}`;

  const hasImage = fileLoadable.contents?.fileInfo?.medium.url;
  const previewUrl =
    hasImage &&
    hasImage
      .replace('ckeditormedium', 'span5_16-9')
      .replace('/non/', `/${context}/`) + `?c=${cache}`;

  const altLabel =
    fileLoadable.contents?.title ||
    gettextCatalog.getString('Image depicting a placeholder image');
  /*
  If there is a default image the image picker will show a default Image
   */
  const renderDefaultImage = () => {
    if (!hasImage && !previewUrl) {
      return defaultImageUrl ? (
        <Image
          width={200}
          height={112} // Forces aspect ratio to 16:9 for both placeholder and selected images.
          src={defaultImageUrl}
        />
      ) : (
        <Skeleton.Image style={{ width: '200px', height: '112px' }} />
      );
    } else if (hasImage && previewUrl) {
      return (
        <Image
          width={200}
          height={112} // Forces aspect ratio to 16:9 for both placeholder and selected images.
          src={hasImage && previewUrl}
          alt={altLabel}
          preview={false}
          onClick={() => canEdit && !previewUrl && openCDFileArchive()}
        />
      );
    }
  };
  return (
    <Row gutter={16}>
      <Col flex="200px">
        <Space direction="vertical">
          {renderDefaultImage()}
          {canCrop && hasImage && previewUrl && (
            <Text type="secondary">
              {gettextCatalog.getString('"Landscape" preview.')}
            </Text>
          )}
        </Space>
      </Col>
      <Col flex="auto">
        <Space direction="vertical">
          {!previewUrl && (
            <Button onClick={openCDFileArchive} disabled={!canEdit}>
              {gettextCatalog.getString('Choose image')}
            </Button>
          )}
          {previewUrl && (
            <>
              {canCrop && (
                <Button
                  icon={<EventIcons.ImageCrop />}
                  onClick={showCropModal}
                  disabled={!canEdit}
                >
                  {gettextCatalog.getString('Crop')}
                </Button>
              )}
              <Button
                type="dashed"
                danger
                onClick={() => {
                  onChange(null);
                }}
                disabled={!canEdit}
              >
                {gettextCatalog.getString('Remove')}
              </Button>
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

/**
 * Generate a unique id.
 */
export function getTempCropKey() {
  // @see http://stackoverflow.com/a/2117523/599991
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
