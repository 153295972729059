import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { Button, Space, Switch, Typography, Form, Select, Card } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import NiceModal from '@ebay/nice-modal-react';

import useNewsletterList from '../hooks/useNewsletterList';
import { useOrganization } from '../../organization/hooks/useOrganization';
import useConsentStatement from '../hooks/useConsentStatement';
import { Consent } from '../types/consent.type';
import NewsletterListForm from '../components/NewsletterListForm';
import { ConsentTypeQuery } from '../store/consentState';

import { gettextCatalog } from '@/react/services/I18nService';
import { NewsletterandFilterDataInterface } from '@/react/people/store/newsletterListState';
import { CdCheckIcon, CdClose } from '@/react/shared/components/Icons';
import cdApp from '@/react/config';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CdCard } from '@/react/shared/components/CdCard';
import { PeopleSignUpDemo } from '@/react/people/components/PeopleSignUpDemo';

const { Text, Title } = Typography;
const ConsentForm = styled(Form)`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`;
const NewsletterSignupWidgetForm = styled(Form)`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`;
const ButtonsArea = styled(Space)`
  &&&& {
    display: flex;
    justify-content: flex-end;
  }
`;

const EmbededContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;
const { Option } = Select;

const NewsletterSetupPage: FunctionComponent<{
  $stateParams: { continue: string };
}> = ({ $stateParams }) => {
  const destinationPageName = $stateParams.continue;
  const destinationPageUrl =
    destinationPageName === 'people'
      ? 'app.private.people.default'
      : 'app.private.website.default';
  const [newsletterPartForm] = Form.useForm();
  const [consentPartForm] = Form.useForm();
  const [newsletterSignupWidgetPartForm] = Form.useForm();
  const [selectedConsent, setSelectedConsent] = useState<Consent>();
  const churchSettingInformation = useRecoilValue(ChurchSettingsState);

  const allConsentType = useRecoilValue(ConsentTypeQuery);

  const [selectedFilter, setSelectedFilter] =
    useState<NewsletterandFilterDataInterface>();

  const { createList } = useNewsletterList();
  const { updateSettings } = useOrganization();
  const { editStatement } = useConsentStatement();

  useEffect(() => {
    const consent = allConsentType?.items?.find(
      (consent) => consent.defaultConsent === true
    );
    consentPartForm.setFieldsValue({
      consent: consent?.id,
    });
    if (consent) {
      if (consent.body === null) {
        setSelectedConsent({ ...consent, body: undefined });
      } else {
        setSelectedConsent({ ...consent, link: undefined });
      }
    }
    // eslint-disable-next-line
  }, [allConsentType]);

  useEffect(() => {
    newsletterSignupWidgetPartForm.setFieldsValue(churchSettingInformation);

    // eslint-disable-next-line
  }, [churchSettingInformation]);

  const showConfirm = (
    filter: NewsletterandFilterDataInterface,
    name: string,
    setup: { churchIds: number[] },
    filterIds: number[],
    type: string,
    destinationUrl: string,
    newsletterWidgetEnabled: boolean,
    parent: string
  ) => {
    NiceModal.show('ConfirmRegisterConsentOnContacts', {
      filter,
      name,
      setup,
      filterIds,
      type,
      destinationUrl,
      newsletterWidgetEnabled,
      parent,
    });
  };
  const getCodeSignUpWidget = () => {
    const organizationId = cdApp.organization.id;
    NiceModal.show('GetCodeSignUpWidget', { organizationId });
  };
  const handleClickSaveButton = async () => {
    await editStatement(selectedConsent.id, selectedConsent);

    if (newsletterPartForm.getFieldValue('filterIds')) {
      showConfirm(
        selectedFilter,
        newsletterPartForm.getFieldValue('name'),
        newsletterPartForm.getFieldValue('setup'),
        newsletterPartForm.getFieldValue('filterIds'),
        'newsletter',
        destinationPageUrl,
        newsletterSignupWidgetPartForm.getFieldValue('newsletterWidgetEnabled'),
        'migrationPage'
      );
    } else {
      await createList(
        newsletterPartForm.getFieldValue('name'),
        newsletterPartForm.getFieldValue('setup'),
        undefined,
        'newsletter'
      );
      await updateSettings({
        newsletterWidgetEnabled: newsletterSignupWidgetPartForm.getFieldValue(
          'newsletterWidgetEnabled'
        ),
        newsletterListMigrationComplete: true,
      });
      navigate(destinationPageUrl);
    }
  };

  const cardStyle = { maxWidth: '700px', width: '100%' };

  return (
    <CdPage hideHeader>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CdCard
          title={
            <Typography.Title level={3}>
              {gettextCatalog.getString(
                'Convert your list into our new Newsletter list'
              )}
            </Typography.Title>
          }
          style={cardStyle}
        >
          <NewsletterListForm
            form={newsletterPartForm}
            setSelectedFilter={setSelectedFilter}
          />
        </CdCard>
        <Card style={cardStyle}>
          <ConsentForm
            layout="vertical"
            form={consentPartForm}
            onFieldsChange={() => {
              const consent = allConsentType?.items?.find(
                (consent) =>
                  consent.id === consentPartForm.getFieldValue('consent')
              );
              if (consent.body === null) {
                setSelectedConsent({
                  ...consent,
                  defaultConsent: true,
                  body: undefined,
                });
              } else {
                setSelectedConsent({
                  ...consent,
                  defaultConsent: true,
                  link: undefined,
                });
              }
            }}
          >
            <Form.Item
              name="consent"
              label={gettextCatalog.getString(
                'Which consent should your newsletter subscribers consent to?'
              )}
              style={{ marginBottom: '0' }}
            >
              <Select
                placeholder={gettextCatalog.getString(
                  'Please select a consent'
                )}
                style={{ width: '100%' }}
              >
                {allConsentType?.items?.map((consent) => (
                  <Option value={consent.id} key={consent.label}>
                    <Space>
                      <Text italic>I agree to</Text>
                      {consent.label}
                    </Space>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </ConsentForm>
        </Card>
        <Card style={cardStyle}>
          <NewsletterSignupWidgetForm
            layout="vertical"
            form={newsletterSignupWidgetPartForm}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ width: '700px' }}>
                <Space style={{ display: 'flex', alignContent: 'center' }}>
                  <Form.Item
                    name="newsletterWidgetEnabled"
                    valuePropName="checked"
                    initialValue={false}
                    noStyle
                  >
                    <Switch
                      checkedChildren={<CdCheckIcon />}
                      unCheckedChildren={<CdClose />}
                    />
                  </Form.Item>
                  <Title level={4}>
                    {gettextCatalog.getString(
                      'Add the newsletter sign-up widget to your website'
                    )}
                  </Title>
                </Space>

                <Text style={{ color: '#899499' }}>
                  {gettextCatalog.getString(
                    `If disabled the newsletter widget will not load on your website even when installed`
                  )}
                </Text>

                <EmbededContainer>
                  <Typography.Title level={4}>
                    {gettextCatalog.getString(
                      'Embed javascript code that you can add to any website page header'
                    )}
                  </Typography.Title>
                  <Button type="default" onClick={getCodeSignUpWidget}>
                    {gettextCatalog.getString('Get code')}
                  </Button>
                </EmbededContainer>
                <p>
                  {gettextCatalog.getString(
                    'If you do not use ChurchDesk website you can add the newsletter widget to your any to let people sign-up up for your newsletters. The widget will load shortly after the webpage has loaded.'
                  )}
                </p>
              </div>

              <PeopleSignUpDemo />
            </div>
          </NewsletterSignupWidgetForm>
        </Card>
        <Card style={cardStyle}>
          <ButtonsArea>
            <Button href={getStateUrl(`${destinationPageUrl}`)}>
              {gettextCatalog.getString('Close wizard')}
            </Button>
            <Button type="primary" onClick={handleClickSaveButton}>
              {gettextCatalog.getString('Save')}
            </Button>
          </ButtonsArea>
        </Card>
      </div>
    </CdPage>
  );
};
const NewsletterSetupPageParent = (props) => (
  <Suspense fallback={<CdrPageLoader />}>
    <NewsletterSetupPage {...props} />
  </Suspense>
);
export default NewsletterSetupPageParent;
