'use strict';

angular.module('cdApp.shared').component('cdEmptyState', {
  templateUrl: '@/app/shared/components/empty-state/empty-state.component.html',
  bindings: {
    icon: '<cdIcon',
    title: '<cdTitle',
    subtitle: '<cdSubtitle',
    hideButton: '<cdHideButton',
    buttonText: '<cdButtonText',
    buttonClass: '<cdButtonClass',
    buttonDisabled: '<cdButtonDisabled',
    buttonClick: '&cdButtonClick',
    image: '<cdImage',
    hideImage: '<cdHideImage',
    imageCaption: '<cdImageCaption',
    compact: '<cdCompact',
  },

  transclude: true,
});
