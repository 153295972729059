import React from 'react';

import mobile from './mobile.png';
export const MobilePreview = ({ value }: { value: string }) => (
  <div style={{ flexGrow: 1, textAlign: 'center' }}>
    <div
      style={{
        margin: '15px auto',
        position: 'relative',
        zIndex: 1,
        border: '0px',
        backgroundImage: `url(${mobile})`,
        backgroundSize: 'contain',
        display: 'block',
        height: '748px',
        width: '364px',
      }}
    >
      <div style={{ position: 'absolute', inset: '82px 21px 83px 20px' }}>
        <iframe
          width="323px"
          height="583px"
          style={{ border: 0 }}
          srcDoc={value}
        />
      </div>
    </div>
  </div>
);
