'use strict';

class ConnectToFacebookController {
  constructor(
    facebookService,
    AuthenticationService,
    $uibModal,
    gettextCatalog
  ) {
    'ngInject';

    this.facebookService = facebookService;
    this.AuthenticationService = AuthenticationService;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.hasLoadedSDK = this.facebookService.hasLoadedSDK();
  }

  connect() {
    const { facebookService, AuthenticationService } = this;

    this.isLoadingSDK = true;
    return facebookService
      .authenticate()
      .then(() =>
        AuthenticationService.getCurrentUser().then(() => {
          this.onAuthenticated();
        })
      )
      .finally(() => {
        this.isLoadingSDK = false;
      });
  }
}

ConnectToFacebookController.$inject = [
  'facebookService',
  'AuthenticationService',
  '$uibModal',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdConnectToFacebook', {
  template: require('./connect-to-facebook.component.html'),
  controller: ConnectToFacebookController,
  bindings: {
    onAuthenticated: '&',
  },
});
