import React from 'react';

import { SearchCalendarPrintTableData } from '@/react/calendar/reports/types/report.types';
import CdTable from '@/react/shared/components/cd-table';
import { gettextCatalog } from '@/react/services/I18nService';
import { SearchCalendarPrintTableColumn } from '@/react/calendar/reports/SearchCalendarPrintTableColumn';
import { CdCalendarReportEmptyState } from '@/react/shared/components/Icons';

export const SearchCalendarPrintTable = ({
  events,
  type,
  isPrintDataLoading,
  isMultiParish,
}: {
  events: SearchCalendarPrintTableData[];
  type: string;
  isPrintDataLoading: boolean;
  isMultiParish: boolean;
}) => (
  <CdTable
    noStyle
    data={{ items: events, total: 0 }}
    emptyStateProps={{
      title: gettextCatalog.getString('No matching results'),
      subtitle: gettextCatalog.getString(
        'Customize the filters and generate the data by pressing the button above.'
      ),
      EmptyStateIcon: <CdCalendarReportEmptyState />,
    }}
    columns={SearchCalendarPrintTableColumn(type, isMultiParish)}
    columnSelectorKey={`CalendarReport.${type}`}
    isLoading={isPrintDataLoading}
    onRow={() => ({
      className: 'vertical-align-top',
    })}
  />
);
