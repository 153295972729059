import React, { forwardRef, Ref, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Input } from 'antd';

import { EmojiPicker } from '../../../../shared/components/EmojiPicker';

interface InputWithEmojiButtonProps {
  onChange?: any;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
}
const InputWithEmojiButton = forwardRef(
  (
    {
      onChange,
      value,
      disabled,
      placeholder = '',
      className,
      ...rest
    }: InputWithEmojiButtonProps,
    ref
  ) => {
    const [cursorPosition, setCursorPosition] = useState(value?.length || 0);

    const onEmojiSelect = (emoji) => {
      onChange(
        [
          value.slice(0, cursorPosition),
          emoji.native,
          value.slice(cursorPosition),
        ].join('')
      );
      setCursorPosition(cursorPosition + emoji.native.length);
    };

    const registerCursorPosition = (selectionEnd: number) => {
      setCursorPosition(selectionEnd);
    };
    const debouncedRegisterCursorPosition = useMemo(
      () => debounce(registerCursorPosition, 500),
      []
    );
    useEffect(
      () => () => {
        debouncedRegisterCursorPosition.cancel();
      },
      [debouncedRegisterCursorPosition]
    );

    if (!onChange && !value) {
      return null;
    }
    return (
      <Input
        {...rest}
        placeholder={placeholder}
        suffix={
          !disabled && (
            <EmojiPicker buttonShape="round" onSelect={onEmojiSelect} />
          )
        }
        onChange={onChange}
        ref={ref as Ref<any>}
        value={value}
        className={className}
        onKeyUp={(event) => {
          debouncedRegisterCursorPosition(
            (event.target as HTMLInputElement)?.selectionEnd
          );
        }}
        onMouseUp={(event) => {
          debouncedRegisterCursorPosition(
            (event.target as HTMLInputElement)?.selectionEnd
          );
        }}
        disabled={disabled}
      />
    );
  }
);

InputWithEmojiButton.displayName = 'InputWithEmojiButton';

export default InputWithEmojiButton;
