import React, { FunctionComponent } from 'react';
import { sagaModal } from 'redux-saga-modal';
import { Modal } from 'antd';

import { FEvent } from '../models/calendar';
import MODAL_TYPES from '../sagas/ModalTypes';
import { gettextCatalog } from '../../services/I18nService';

interface UpdateEventModalProps {
  isOpen: boolean;
  hide: () => void;
  submit: () => void;
  oldEvent: FEvent;
  newEvent: FEvent;
}

const UpdateEventConfirmation: FunctionComponent<UpdateEventModalProps> = ({
  isOpen,
  submit,
  hide,
  oldEvent,
}) =>
  oldEvent ? (
    <Modal
      title={
        oldEvent.type === 'event'
          ? gettextCatalog.getString('Update event')
          : gettextCatalog.getString('Update absence')
      }
      open={isOpen}
      okText={gettextCatalog.getString('Update')}
      onOk={submit}
      onCancel={hide}
    >
      {gettextCatalog.getString(
        'Are you sure you want to update {{ title }}?',
        {
          title: oldEvent.title,
        }
      )}
    </Modal>
  ) : null;

export default sagaModal({
  // A unique name for the modal
  name: MODAL_TYPES.EVENT_UPDATE_CONFIRM,
})(UpdateEventConfirmation);
