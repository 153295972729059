export const loadStripeDynamically = ($window): Promise<void> =>
  new Promise((resolve) => {
    const existingScript = document.getElementById('stripe-script');

    if (!existingScript) {
      const script = $window.document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://js.stripe.com/v3/'; // URL for the third-party library being loaded.
      script.id = 'stripe-script';
      $window.document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
    }

    if (existingScript) resolve();
  });
