'use strict';

/**
 * @desc return a label and description for the status of a person in People
 *
 * @example <span>{{ $ctrl.person.emailStatus | cdContactStatus: 'label' }}</span>
 */ contactStatus.$inject = ['_', 'gettextCatalog'];

function contactStatus(_, gettextCatalog) {
  return function (status, property) {
    if (!status) return gettextCatalog.getString('Inactive');

    property = property || 'label';

    let dictionary = {
      active: {
        label: gettextCatalog.getString('Active'),
        description: gettextCatalog.getString(
          'This contact is receiving all your messages, unless their message settings are disabled.'
        ),
      },

      bounced: {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'We identified that emails sent to this contact are bouncing and we will no longer send emails to them.'
        ),
      },

      'hard bounce': {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'We identified that emails sent to this contact are bouncing and we will no longer send emails to them.'
        ),
      },

      preblocked: {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'We identified that emails sent to this contact are bouncing and we will no longer send emails to them.'
        ),
      },

      kickbox: {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'We could not validate this email address and we will no longer send any emails to this contact.'
        ),
      },

      pending: {
        label: gettextCatalog.getString('Pending'),
        description: gettextCatalog.getString(
          'This contact has not confirmed their email yet and they will not receive any emails until they do so.'
        ),
      },

      'quota exceeded': {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'This contact reached the maximum number of emails they can receive and we will no longer send emails to them.'
        ),
      },

      spam: {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'This contact marked one of your emails as spam and we will no longer send other emails to them.'
        ),
      },

      'spam preblocked': {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'This contact marked one of your emails as spam and we will no longer send other emails to them.'
        ),
      },

      unsub: {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'This contact unsubscribed from our mail provider and we will no longer send other emails to them.'
        ),
      },

      'pending-kickbox': {
        label: gettextCatalog.getString('Inactive'),
        description: gettextCatalog.getString(
          'The e-mail address of this contact is in the process of being verified.'
        ),
      },
    };

    return _.get(
      dictionary,
      [status, property],
      dictionary['pending-kickbox'][property]
    );
  };
}

angular.module('cdApp.people').filter('cdContactStatus', contactStatus);
