export const FETCH_ALL_COLLECTIONS = 'FETCH_ALL_COLLECTION';
export const fetchAllCollections = (payload) => ({
  type: FETCH_ALL_COLLECTIONS,
  payload,
});

export const FETCH_ALL_COLLECTIONS_SUCCESS = 'FETCH_ALL_COLLECTIONS_SUCCESS';
export const fetchAllCollectionsSuccess = (payload) => ({
  type: FETCH_ALL_COLLECTIONS_SUCCESS,
  payload,
});

export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const fetchCollection = (payload) => ({
  type: FETCH_COLLECTION,
  payload,
});

export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const fetchCollectionSuccess = (payload) => ({
  type: FETCH_COLLECTION_SUCCESS,
  payload,
});

export const SET_COLLECTION_EDITID = 'TOGGLE_COLLECTION_EDITID';
export const setEditId = (payload: boolean) => ({
  type: SET_COLLECTION_EDITID,
  payload,
});

export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const createCollection = (payload) => ({
  type: CREATE_COLLECTION,
  payload,
});

export const PUT_COLLECTION = 'PUT_COLLECTION';
export const putCollection = (payload) => ({
  type: PUT_COLLECTION,
  payload,
});

export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const createCollectionSuccess = (payload) => ({
  type: CREATE_COLLECTION_SUCCESS,
  payload,
});

export const PUT_COLLECTION_SUCCESS = 'PUT_COLLECTION_SUCCESS';
export const putCollectionSuccess = (payload) => ({
  type: PUT_COLLECTION_SUCCESS,
  payload,
});

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const removeCollection = (payload) => ({
  type: DELETE_COLLECTION,
  payload,
});
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const removeCollectionSuccess = (payload) => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload,
});
