(function () {
  'use strict';

  /**
   * @desc toggle the indeterminate state of a checkbox
   *
   * <input type="checkbox" cd-indeterminate="yourBool">
   */

  function indeterminate() {
    return {
      compile: function (elem, attrs) {
        if (!attrs.type || attrs.type.toLowerCase() !== 'checkbox') {
          return angular.noop;
        }

        return function (scope, elem, attrs) {
          scope.$watch(attrs.cdIndeterminate, function (newVal) {
            elem[0].indeterminate = !!newVal;
          });
        };
      },
    };
  }

  angular.module('cdApp.shared').directive('cdIndeterminate', indeterminate);
})();
