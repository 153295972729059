import _ from 'lodash';

class PeoplePanelComponent {
  constructor($uibModal, $state, Authorization, People, PeopleSegments) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$state = $state;
    this.Authorization = Authorization;
    this.People = People;
    this.PeopleSegments = PeopleSegments;
  }

  $onInit() {
    const { Authorization, People, PeopleSegments } = this;

    this.hasPeoplePackage = Authorization.hasPackage('people');
    this.canAccessPeople = Authorization.hasPermission('canAccessPeople');

    /**
     * Fetch people and people messages
     */
    if (this.canAccessPeople) {
      // Get recently created People
      this.isLoadingPeople = true;
      PeopleSegments.query().$promise.then((lists) => {
        const allList = _.find(lists, { filteringType: 'organization' });
        const churchIds = _.get(allList, 'setup.churchIds');
        People.searchPeople({
          filter: { comparison: 'AND', filters: [], churchIds },
          limit: 6,
          searchAfter: null,
          orderBy: 'createdAt',
          orderDirection: 'desc',
        })
          .$promise.then(({ people }) => {
            // Should have at least 2 contacts in the People database in order to show a list of contacts
            if (_.size(people) < 2) return;
            this.people = _.map(people, (person) => {
              const findField = (property) =>
                _.get(_.find(person.fields, { property }), 'value');
              const firstName = findField('firstName');
              const lastName = findField('lastName');
              let name = _.join([firstName, lastName], ' ');
              if (_.isEmpty(firstName) && _.isEmpty(lastName)) {
                name = findField('email');
              }
              const createdAt = findField('createdAt');
              return {
                id: person.id,
                name,
                createdAt,
                picture: person.picture,
              };
            });
          })
          .finally(() => {
            this.isLoadingPeople = false;
          });
      });
    }
  }

  /**
   * Create a new person
   */
  createPeople() {
    const { $uibModal, $state } = this;

    $uibModal
      .open({
        component: 'cdCreatePeopleModal',
      })
      .result.then((newPerson) => {
        $state.go('app.private.people.contacts.view', { id: newPerson.id });
      });
  }
}

PeoplePanelComponent.$inject = [
  '$uibModal',
  '$state',
  'Authorization',
  'People',
  'PeopleSegments',
];

angular.module('cdApp.dashboard').component('cdDashboardPeoplePanel', {
  template: require('./people-panel.component.html'),
  controller: PeoplePanelComponent,
});
