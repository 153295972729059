import { react2angular } from 'react18-react2angular';

import { SafeguardingWrapper } from '../../react/safeguarding/safeguarding/Safeguarding';
import sharedContext from '../shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.safeguarding')
  .component(
    'cdSafeguarding',
    react2angular(sharedContext.use(SafeguardingWrapper), [], ['$stateParams'])
  );
