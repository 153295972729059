import _ from 'lodash';
import { Button, Dropdown, Menu, Space } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faPlus,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { gettextCatalog } from '../../../services/I18nService';
import { FoundationTypes } from '../../models/foundation';
import { TableOrdering } from '../../../shared/models/table';
import {
  navigate,
  StateParamsServiceFactory,
} from '../../../services/StateServiceFactory';
import FoundationsOverviewFilters from '../../components/foundations/FoundationsOverviewFilters';
import FoundationsOverview from '../../components/foundations/FoundationsOverview';
import { IntentionTabNames } from '../../models/intention';
import FoundationService from '../../services/FoundationService';
import ErrorHandlingService from '../../../services/ErrorHandlingService';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';

interface FilteringAndOrderingOptions extends TableOrdering {
  status?: IntentionTabNames;
}

interface FoundationsStateParam {
  foundationType: FoundationTypes;
}

const Foundations: FunctionComponent = () => {
  // Define initialization hook and entity reload hooks
  const [searchFilters, setSearchFilters] = useState();
  const [
    currentFilteringAndOrderingOptions,
    setCurrentFilteringAndOrderingOptions,
  ] = useState<FilteringAndOrderingOptions>();
  const stateParams = StateParamsServiceFactory<FoundationsStateParam>();
  const { foundationType } = stateParams;

  let createFoundationText, title, subTitle;
  if (foundationType === FoundationTypes.STIFTUNGEN) {
    title = gettextCatalog.getString('Stipend');
    subTitle = gettextCatalog.getString('Overview of all stipends');
    createFoundationText = gettextCatalog.getString('New stipend');
  } else if (foundationType === FoundationTypes.LEGATE) {
    title = gettextCatalog.getString('Legacies');
    subTitle = gettextCatalog.getString('Overview of all legacies');
    createFoundationText = gettextCatalog.getString('New legacy');
  }

  const navigateToCreateFoundation = () => {
    if (foundationType === FoundationTypes.STIFTUNGEN) {
      navigate('app.private.intention.stiftungen.create', {
        currentState: 'create',
      });
    } else if (foundationType === FoundationTypes.LEGATE) {
      navigate('app.private.intention.legate.create', {
        currentState: 'create',
      });
    }
  };

  const updateFilters = (filters) => {
    setSearchFilters(filters);
  };

  const updateFilteringAndSortingOptions = (
    filteringAndOrderingOptions: FilteringAndOrderingOptions
  ) => {
    setCurrentFilteringAndOrderingOptions(
      _.extend(
        {},
        currentFilteringAndOrderingOptions,
        filteringAndOrderingOptions
      )
    );
  };

  const getExportMenu = () => (
    <Menu>
      <Menu.Item key="view">
        <a onClick={() => exportFoundations('csv')}>
          {gettextCatalog.getString('CSV (.csv)')}
        </a>
      </Menu.Item>
      <Menu.Item key="edit">
        <a onClick={() => exportFoundations('xlsx')}>
          {gettextCatalog.getString('Microsoft Excel (.xlsx)')}
        </a>
      </Menu.Item>
      <Menu.Item key="delete">
        <a onClick={() => exportFoundations('ods')}>
          {gettextCatalog.getString('Open Document Sheet (.ods)')}
        </a>
      </Menu.Item>
    </Menu>
  );

  const exportFoundations = (fileType = 'csv') => {
    FoundationService.getFoundationExportDownloadToken()
      .then((exportToken) => {
        const { status, orderBy, orderDirection } =
          currentFilteringAndOrderingOptions;
        // Process filtering parameters
        const includeDeleted = status === IntentionTabNames.ALL;
        // Sanitize search filter values, preserving the boolean values
        const parsedSearchFilters = _.pickBy(searchFilters, (v) =>
          _.isBoolean(v) ? true : _.identity(v)
        );
        const filteringOptions: any = _.extend({}, parsedSearchFilters, {
          exportToken,
          fileType,
          includeDeleted,
          type: foundationType,
        });
        if (!includeDeleted) filteringOptions.statuses = [status];
        // Process ordering parameters
        const orderingOptions: any = {};
        if (orderBy) orderingOptions.orderBy = orderBy;
        if (orderDirection) orderingOptions.orderDirection = orderDirection;
        // Build filtering and ordering parameters
        const params = $.param(_.extend({}, filteringOptions, orderingOptions));
        const fileUrl = `${window.cdApp.config.api.main}/intention/foundations/export/data?${params}`;
        window.open(fileUrl, '_blank');
      })
      .catch(ErrorHandlingService.handleError);
  };

  const getPageHeaderExtra = () => (
    <Space>
      <Dropdown
        overlay={getExportMenu()}
        trigger={['click']}
        className="u-mr-10"
      >
        <Button>
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: 8 }} />
          {gettextCatalog.getString('Export')}
          <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: 8 }} />
        </Button>
      </Dropdown>
      <Button type="primary" onClick={() => navigateToCreateFoundation()}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
        {createFoundationText}
      </Button>
    </Space>
  );
  // Return the foundations home screen
  return (
    <CdPage pageHeaderProps={{ title, subTitle, extra: getPageHeaderExtra() }}>
      <FoundationsOverviewFilters
        foundationType={foundationType}
        onChange={updateFilters}
      />
      <FoundationsOverview
        foundationType={foundationType}
        filters={searchFilters}
        updateParentOnChange={updateFilteringAndSortingOptions}
      />
    </CdPage>
  );
};

export default Foundations;
