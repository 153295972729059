(function () {
  'use strict';

  /**
   * @desc automatically resize a textarea to fit its content
   * @example <textarea textarea-fit-content="{{'true'}}"></textarea>
   *
   * @see answer here http://stackoverflow.com/questions/7477/autosizing-textarea-using-prototype
   */

  function textareaFitContent() {
    return {
      restrict: 'A',
      link: function (scope, element, atrributes) {
        let textarea = element[0];
        atrributes.$observe('textareaFitContent', function (val) {
          if (val === 'true') {
            angular.element(textarea).css('maxHeight', 'none');
            angular.element(textarea).height(textarea.scrollHeight);
          }
        });
      },
    };
  }

  angular
    .module('cdApp.shared')
    .directive('textareaFitContent', textareaFitContent);
})();
