'use strict';

class CancelTickets {
  constructor($stateParams, $uibModal, gettextCatalog, Forms) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.Forms = Forms;
    this.gettextCatalog = gettextCatalog;
  }
  $onInit() {
    const { formId, submissionId, organizationId, token, formName } =
      this.$stateParams;
    this.formId = formId;
    this.submissionId = submissionId;
    this.organizationId = organizationId;
    this.token = token;
    this.formName = decodeURIComponent(formName);
    this.texts = {
      confirmSubTitle: this.gettextCatalog.getString(
        "You're about to cancel all your tickets for {{ formName }}.",
        {
          formName: formName,
        }
      ),

      confirmTitle: this.gettextCatalog.getString(
        'Are you sure you want to cancel all your tickets?'
      ),

      button: this.gettextCatalog.getString('Yes, cancel all my tickets'),
      poweredBy: this.gettextCatalog.getString('Powered by'),
      successTitle: this.gettextCatalog.getString(
        'All your tickets were canceled'
      ),

      successSubTitle: this.gettextCatalog.getString(
        'Thank you for letting us know. We hope to see you again another time.'
      ),

      failTitle: this.gettextCatalog.getString(
        'An error occurred when canceling your tickets'
      ),

      failSubTitle: this.gettextCatalog.getString(
        'Try to refresh the page. Please contact the church if the error continues.'
      ),

      loadingText: this.gettextCatalog.getString('Canceling tickets...'),
    };
  }

  cancelTicket() {
    this.cancelingTicket = true;
    const { formId, submissionId, organizationId, token } = this;
    this.Forms.cancelTicket(
      { formId, submissionId, organizationId, accessToken: token },
      {}
    )
      .$promise.then(() => {
        this.cancelingTicket = false;
        this.cancelTicketSucceeded = true;
      })
      .catch(() => {
        this.cancelingTicket = false;
        this.cancelTicketFailed = true;
      });
  }
}

CancelTickets.$inject = [
  '$stateParams',
  '$uibModal',
  'gettextCatalog',
  'Forms',
];

angular.module('cdApp.forms').component('cdCancelFreeTickets', {
  templateUrl: '@/app/forms/cancel-tickets/cancel-tickets.html',
  controller: CancelTickets,
  bindings: {
    isPublic: '<',
  },
});
