'use strict';
import cdApp from '@/react/config';

Projects.$inject = [
  'moment',
  '$$resource',
  '$stateParams',
  '$uibModal',
  'toastr',
  'gettextCatalog',
  '_',
  'Analytics',
];

function Projects(
  moment,
  $$resource,
  $stateParams,
  $uibModal,
  toastr,
  gettextCatalog,
  _,
  Analytics
) {
  let Project = $$resource(
    cdApp.config.api.payments + '/contributions/:id',
    null,
    {
      get: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data) {
          if (data !== 'null') {
            data = JSON.parse(data);

            return _.extend(data, {
              startDate: data.startDate && moment(data.startDate).toDate(),
              endDate: (data.endDate && moment(data.endDate).toDate()) || null,
            });
          }

          return data;
        },
      },

      stats: {
        method: 'GET',
        url: cdApp.config.api.payments + '/contributions/:id/stats',
      },

      contributions: {
        method: 'GET',
        url: cdApp.config.api.payments + '/contributions/:id/payments',
        isArray: true,
      },

      getActiveDonations: {
        method: 'GET',
        url: cdApp.config.api.payments + '/contributions/active-donations',
      },

      getTransfers: {
        method: 'GET',
        url: cdApp.config.api.payments + '/transfers',
      },

      exportContributions: {
        method: 'GET',
        url: cdApp.config.api.payments + '/contributions/:id/export/preview',
        isArray: true,
      },
    }
  );

  let empty = {
    type: 'donation',
    startDate: moment().toDate(),
    giftAid: false,
    allowMobile: false,
    status: 'new',
  };

  Project.init = function (type) {
    return new Project(
      _.extend(_.cloneDeep(empty), {
        type: type,
        giftAid:
          _.get(cdApp, 'organization.countryIso2', '').toLowerCase() === 'gb' &&
          type !== 'payment',
      })
    );
  };

  Project.prototype.send = function (activate, success, error) {
    let self = this;

    if (
      this.endDate &&
      moment(this.endDate)
        .endOf('day')
        .isBefore(moment(this.startDate).startOf('day'))
    ) {
      return toastr.error(
        gettextCatalog.getString('The stop date must be in the future.')
      );
    }

    if (activate) this.status = 'active';

    this.$save(
      function () {
        Analytics.sendFeatureEvent(
          (!activate && !$stateParams.id ? 'create' : 'update') + ' project',
          { module: 'contributions' }
        );

        return (
          success ||
          function () {
            toastr.success(gettextCatalog.getString('Project saved.'));
          }
        ).apply(this, arguments);
      },
      function (err) {
        if (err.status === 409) {
          self.status = 'new';

          $uibModal
            .open({
              component: 'cdContributionsLegalPopup',
              backdrop: 'static',
              keyboard: false,
            })
            .result.then(function () {
              self.send(true, success, error);
            });
        } else {
          toastr.error(gettextCatalog.getString('Project could not be saved.'));
        }

        return (
          error ||
          function () {
            throw new Error(err && err.message);
          }
        ).apply(this, arguments);
      }
    );
  };

  Project.prototype.getUrl = function () {
    return cdApp.references.paymentsFrontend + '/' + this.alias;
  };

  Project.prototype.getUrlV1 = function () {
    if (this.type === 'donation') {
      return (cdApp.references.donateFrontend + '/' + this.alias).replace(
        '{language}',
        cdApp.organization.locale.language
      );
    }

    return cdApp.references.paymentsFrontend + '/' + this.alias;
  };

  return Project;
}

angular.module('cdApp.shared').factory('Projects', Projects);
