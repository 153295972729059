const sharedModule = angular.module('cdApp.shared', []);

require('./components');
require('./directives');
require('./filters');
require('./models');
require('./services');

require('./templates');

module.exports = sharedModule.name;
