class ViewFormResponsesComponent {
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    gettextCatalog,
    hotkeys,
    People,
    PeopleCustomFields,
    Forms,
    FormResponses,
    routingHelpers,
    documentTitleService
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.gettextCatalog = gettextCatalog;
    this.hotkeys = hotkeys;
    this.People = People;
    this.PeopleCustomFields = PeopleCustomFields;
    this.Forms = Forms;
    this.FormResponses = FormResponses;
    this.routingHelpers = routingHelpers;
    this.documentTitleService = documentTitleService;
  }

  $onInit() {
    const {
      $q,
      $scope,
      $stateParams,
      People,
      PeopleCustomFields,
      Forms,
      FormResponses,
      hotkeys,
    } = this;

    const { responseId, formId } = $stateParams;

    this.responseId = responseId;

    // Get those objects from the query parameters if they're provided instead of fetching them again
    const formPromise = $stateParams.form || Forms.get({ id: formId }).$promise;
    const formResponsesPromise =
      $stateParams.formResponses || FormResponses.query({ formId }).$promise;
    const staticFieldsPromise =
      $stateParams.staticFields || People.getStaticFields().$promise;
    const staticFieldsOptionsPromise =
      $stateParams.staticFieldsOptions ||
      People.getStaticFieldsOptions().$promise;
    const customFieldsPromise =
      $stateParams.customFields || PeopleCustomFields.get().$promise;

    // Fetch form response details
    const formResponseDetailsPromise = FormResponses.get({
      id: responseId,
      formId,
    }).$promise;

    this.setPageHeader(_.get($stateParams.form, 'title'));

    this.isLoading = true;
    $q.all([
      formPromise,
      formResponsesPromise,
      formResponseDetailsPromise,
      staticFieldsPromise,
      staticFieldsOptionsPromise,
      customFieldsPromise,
    ])
      .then(
        ([
          form,
          formResponses,
          formResponseDetails,
          staticFields,
          staticFieldsOptions,
          customFields,
        ]) => {
          this.form = form;
          this.formResponses = formResponses;
          this.formResponseDetails = formResponseDetails;
          this.staticFields = staticFields;
          this.staticFieldsOptions = staticFieldsOptions;
          this.customFields = customFields;

          this.setPageHeader(this.form.title);

          const formResponse = _.find(this.formResponses, {
            id: this.responseId,
          });

          // Filter files submission values for the current submission before formatting;
          this.files = FormResponses.filterFileSubmissions(
            this.responseId,
            this.form,
            this.formResponses
          );

          // Format the response values
          this.formattedFormResponses = FormResponses.formatResponses(
            this.form,
            this.formResponses
          );

          this.formattedFormResponses = this.formResponses;

          // Assign the index to each response object
          _.each(this.formattedFormResponses, (response) => {
            response.index = _.indexOf(this.formattedFormResponses, response);
          });

          // Set the current response to the one specified in the URL or to the first one by default
          this.currentResponse =
            _.find(this.formattedFormResponses, { id: this.responseId }) ||
            _.first(this.formattedFormResponses);

          // Get the total number of responses
          this.totalResponseCount = _.size(this.formattedFormResponses);

          /**
           * A form submission should not be deleted if it has ticket fields that already received payments
           */
          this.canDeleteSubmission = _(this.form.getTicketComponents()).every(
            (ticket) => {
              const price = _.get(ticket, 'churchdesk.ticket.price');
              if (!price) return true;

              const purchasedTicket = _.get(formResponse.data, ticket.path) > 0;
              const isFailed =
                _.get(formResponse, 'paymentStatus') === 'failed';
              return isFailed || !purchasedTicket;
            }
          );

          /**
           * There's a diff, if the backend returns at least 1 contact section with exactly 1 contact
           */
          this.hasDiff = !_.isEmpty(formResponseDetails.contacts);
        }
      )
      .finally(() => {
        this.isLoading = false;
      });

    // Handle right and left arrow key presses
    hotkeys
      .bindTo($scope)
      .add({
        combo: 'left',
        callback: () => this.previousResponse(),
      })
      .add({
        combo: 'right',
        callback: () => this.nextResponse(),
      });
  }

  /**
   * Set the title of the page to the header of the form
   * @param {string} formTitle
   */
  setPageHeader(formTitle) {
    const { gettextCatalog, routingHelpers, documentTitleService } = this;
    this.pageTitle = formTitle
      ? gettextCatalog.getString('Responses to {{ formTitle }}', { formTitle })
      : gettextCatalog.getString('Responses');
    documentTitleService.set(routingHelpers.buildTitle(this.pageTitle));
  }

  /**
   * Go to a given response
   *
   * @param {Number} index - The index of the response
   */
  changeResponse(index) {
    const { $state } = this;
    if (!_.isNumber(index)) return;

    const newResponse = _.find(this.formattedFormResponses, { index });

    // Update the response id in the state URL
    $state.go($state.current, {
      responseId: newResponse.id,
      form: this.form,
      formResponses: this.formResponses,
      staticFields: this.staticFields,
      staticFieldsOptions: this.staticFieldsOptions,
      customFields: this.customFields,
    });
  }

  /**
   * Go to the next response
   */
  nextResponse(index) {
    if (!this.currentResponse) return;

    if (_.isUndefined(index)) {
      index = this.currentResponse.index;
    }

    // Find the next response by looking from left to right for the first response object with a greater index
    let nextResponse = _.find(
      this.formattedFormResponses,
      (response) => response.index > index
    );

    // If no response object with a greater index exists, we go back to the first one
    if (!nextResponse) {
      nextResponse = _.first(this.formattedFormResponses);
    }

    this.changeResponse(nextResponse.index);
  }

  /**
   * Go to the previous response
   */
  previousResponse() {
    if (!this.currentResponse) return;

    // Find the previous response by looking from right to left for the first response object with a smaller index
    let previousResponse = _.findLast(
      this.formattedFormResponses,
      (response) => response.index < this.currentResponse.index
    );

    // If no response object with a smaller index exists, we go back to the last one
    if (!previousResponse) {
      previousResponse = _.last(this.formattedFormResponses);
    }

    this.changeResponse(previousResponse.index);
  }

  /**
   * Print the current page
   */
  print() {
    window.print();
  }

  /**
   * Delete the response
   */
  delete() {
    const { $state } = this;

    const currentIndex = this.currentResponse.index;
    this.currentResponse.confirmDelete().then(() => {
      if (_.size(this.formattedFormResponses) > 1) {
        this.nextResponse(currentIndex);
      } else {
        $state.go('app.private.forms.view', { id: this.form.id });
      }
    });
  }
}

ViewFormResponsesComponent.$inject = [
  '$q',
  '$scope',
  '$state',
  '$stateParams',
  'gettextCatalog',
  'hotkeys',
  'People',
  'PeopleCustomFields',
  'Forms',
  'FormResponses',
  'routingHelpers',
  'documentTitleService',
];

angular.module('cdApp.forms').component('cdViewFormResponses', {
  template: require('./view-form-responses.component.html'),
  controller: ViewFormResponsesComponent,
});
