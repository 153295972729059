export const typeFilters = {
  gep_typ_foto: 'Fotos',
  gep_typ_grafik: 'Grafiken',
  gep_typ_cartoon: 'Cartoons',
  gep_typ_kombination: 'Bild-Text-Kombinationen',
  gep_typ_text: 'Texte',
};

export const tagFilters = [
  {
    title: 'Kirchenjahr',
    value: 'kirchenjahr',
    key: 'kirchenjahr',
    children: [
      {
        title: 'Weihnachtskreis',
        value: 'weihnachtskreis',
        key: 'weihnachtskreis',
      },
      {
        title: 'Osterkreis',
        value: 'osterkreis',
        key: 'osterkreis',
      },
      {
        title: 'Pfingstkreis',
        value: 'pfingstkreis',
        key: 'pfingstkreis',
      },
    ],
  },
  {
    title: 'Religionen',
    value: 'religionen',
    key: 'religionen',
    children: [
      {
        title: 'Evangelische Kirche',
        value: 'evangelischeKirche',
        key: 'evangelischeKirche',
      },
      {
        title: 'Katholische Kirche',
        value: 'katholischeKirche',
        key: 'katholischeKirche',
      },
      {
        title: 'Orthodoxe Kirche',
        value: 'orthodoxeKirche',
        key: 'orthodoxeKirche',
      },
      {
        title: 'Judentum',
        value: 'judentum',
        key: 'judentum',
      },
      {
        title: 'Islam',
        value: 'islam',
        key: 'islam',
      },
      {
        title: 'Buddhismus',
        value: 'buddhismus',
        key: 'buddhismus',
      },
    ],
  },
  {
    title: 'Kasualien',
    value: 'kasualien',
    key: 'kasualien',
    children: [
      {
        title: 'Taufe',
        value: 'taufe',
        key: 'taufe',
      },
      {
        title: 'Konfirmation',
        value: 'konfirmation',
        key: 'konfirmation',
      },
      {
        title: 'Trauung',
        value: 'trauung',
        key: 'trauung',
      },
      {
        title: 'Bestattung',
        value: 'bestattung',
        key: 'bestattung',
      },
    ],
  },
  {
    title: 'Themenmotiv',
    value: 'themenmotiv',
    key: 'themenmotiv',
    children: [
      {
        title: 'Themenmotiv Frühling',
        value: 'themenmotivFrühling',
        key: 'themenmotivFrühling',
      },
      {
        title: 'Themenmotiv Sommer',
        value: 'themenmotivSommer',
        key: 'themenmotivSommer',
      },
      {
        title: 'Themenmotiv Herbst',
        value: 'themenmotivHerbst',
        key: 'themenmotivHerbst',
      },
      {
        title: 'Themenmotiv Winter',
        value: 'themenmotivWinter',
        key: 'themenmotivWinter',
      },
    ],
  },
  {
    title: 'Humor',
    value: 'humor',
    key: 'humor',
    children: [
      {
        title: 'Comics "Mina & Freunde"',
        value: 'comicsMinaFreunde',
        key: 'comicsMinaFreunde',
      },
      {
        title: 'Comics "Amen"',
        value: 'comicsAmen',
        key: 'comicsAmen',
      },
      {
        title: 'Karikatur Plaßmann',
        value: 'karikaturPlaßmann',
        key: 'karikaturPlaßmann',
      },
      {
        title: 'Karikatur Mester',
        value: 'karikaturMester',
        key: 'karikaturMester',
      },
    ],
  },
  {
    title: 'Für Kinder',
    value: 'fürKinder',
    key: 'fürKinder',
    children: [
      {
        title: 'Kinderseite Benjamin',
        value: 'kinderseiteBenjamin',
        key: 'kinderseiteBenjamin',
      },
      {
        title: 'Suche 5 Fehler im Bild',
        value: 'sucheFehlerimBild',
        key: 'sucheFehlerimBild',
      },
      {
        title: 'Kinderseiten Frühling',
        value: 'kinderseitenFrühling',
        key: 'kinderseitenFrühling',
      },
      {
        title: 'Kinderseiten Sommer',
        value: 'kinderseitenSommer',
        key: 'kinderseitenSommer',
      },
      {
        title: 'Kinderseiten Herbst',
        value: 'kinderseitenHerbst',
        key: 'kinderseitenHerbst',
      },
      {
        title: 'Kinderseiten Winter',
        value: 'kinderseitenWinter',
        key: 'kinderseitenWinter',
      },
      {
        title: 'Basteltipps',
        value: 'basteltipps',
        key: 'basteltipps',
      },
      {
        title: 'Internet-ABC',
        value: 'internetABC',
        key: 'internetABC',
      },
      {
        title: 'Was passiert in der Kirche?',
        value: 'wasPassiertInDerKirche',
        key: 'wasPassiertInDerKirche',
      },
      {
        title: 'Evangelisch - was ist das?',
        value: 'evangelischWasIstDas',
        key: 'evangelischWasIstDas',
      },
    ],
  },
  {
    title: 'Symbol',
    value: 'symbol',
    key: 'symbol',
    children: [
      {
        title: 'Vignetten',
        value: 'vignetten',
        key: 'vignetten',
      },
      {
        title: 'Geburtstage',
        value: 'geburtstage',
        key: 'geburtstage',
      },
      {
        title: 'Gemeindesymbole',
        value: 'gemeindesymbole',
        key: 'gemeindesymbole',
      },
      {
        title: 'Kirchenmusik',
        value: 'kirchenmusik',
        key: 'kirchenmusik',
      },
      {
        title: 'Feiertagssymbole',
        value: 'feiertagssymbole',
        key: 'feiertagssymbole',
      },
    ],
  },
  {
    title: 'Jahreszeit',
    value: 'jahreszeit',
    key: 'jahreszeit',
    children: [
      {
        title: 'Frühling',
        value: 'frühling',
        key: 'frühling',
      },
      {
        title: 'Sommer',
        value: 'sommer',
        key: 'sommer',
      },
      {
        title: 'Herbst',
        value: 'herbst',
        key: 'herbst',
      },
      {
        title: 'Winter',
        value: 'winter',
        key: 'winter',
      },
    ],
  },
  {
    title: 'Jahreslosung 2020',
    value: 'jahreslosung',
    key: 'jahreslosung',
  },
  {
    title: 'Monatssprüche 2020',
    value: 'monatssprüche',
    key: 'monatssprüche',
  },
];

export const tagMapping = {
  kirchenjahr: ['kirchenjahr'],
  weihnachtskreis: ['weihnachtskreis', 'advent', 'weihnachten', 'epiphanias'],
  osterkreis: [
    'osterkreis',
    'aschermittwoch',
    'fastenzeit',
    'passionszeit',
    'palmsonntag',
    'karwoche',
    'gründonnerstag',
    'karfreitag',
    'karsamstag',
    'ostern',
    'ostersonntag',
    'ostermontag',
    'christi himmelfahrt',
    'pfingsten',
    'pfingstsonntag',
  ],
  pfingstkreis: [
    'pfingstkreis',
    'trinitatis',
    'fronleichnam',
    'mariä himmelfahrt',
    'michaelistag',
    'erntedankfest',
    'reformationsfest',
    'allerheiligen',
    'allerseelen',
    'volkstrauertag',
    'buß- und bettag',
    'ewigkeitssonntag',
  ],
  religionen: ['Religionen'],
  evangelischeKirche: ['Evangelische Kirche', 'Evangelisch'],
  katholischeKirche: ['Katholische Kirche', 'Katholisch'],
  orthodoxeKirche: ['Orthodoxe Kirche', 'Orthodoxe'],
  judentum: ['Judentum', 'Juden'],
  islam: ['Islam'],
  buddhismus: ['Buddhismus'],
  kasualien: ['kasualien'],
  taufe: ['taufe', 'Taufen'],
  konfirmation: ['konfirmation'],
  trauung: ['trauung', 'Hochzeit', 'Ehe', 'Ehepaar'],
  bestattung: ['bestattung', 'Tod', 'Trauer', 'Sterben'],
  themenmotiv: ['im blick'],
  themenmotivFrühling: ['themenmotiv frühling'],
  themenmotivSommer: ['themenmotiv sommer'],
  themenmotivHerbst: ['themenmotiv herbst'],
  themenmotivWinter: ['themenmotiv winter'],
  humor: ['comic', 'humor'],
  comicsMinaFreunde: ['mina und freunde'],
  comicsAmen: ['Comic Amen'],
  karikaturPlaßmann: ['Plaßmann'],
  karikaturMester: ['Mester'],
  fürKinder: ['Für Kinder'],
  kinderseiteBenjamin: ['benjamin'],
  sucheFehlerimBild: ['Suche 5 Fehler im Bild'],
  kinderseitenFrühling: ['Kinderseiten Frühling'],
  kinderseitenSommer: ['Kinderseiten Sommer'],
  kinderseitenHerbst: ['Kinderseiten Herbst'],
  kinderseitenWinter: ['Kinderseiten Winter'],
  basteltipps: ['Basteltipps'],
  internetABC: ['Internet-ABC'],
  wasPassiertInDerKirche: ['Krokodil unterm Kirchturm'],
  evangelischWasIstDas: [
    'TaufeEvangelisch - was ist das?',
    'SonneEvangelisch - was ist das?',
    'SchiffEvangelisch - was ist das?',
    'SchiffEvangelisch - was ist das?',
    'SchiffEvangelisch - was ist das?',
    'Pfarrerinnen und PfarrerEvangelisch - was ist das?',
    'OrgelEvangelisch - was ist das?',
    'OrgelEvangelisch - was ist das?',
    'ÖkumeneEvangelisch - was ist das?',
    'MädchenEvangelisch - was ist das?',
    'KinderseiteEvangelisch - was ist das?',
    'Evangelisch - was ist das?',
    'Evangelisch - Was ist das?',
    'Evangelisch - Was ist das?',
    'Evangelisch - Was ist das?',
    'Evangelisch - was ist das?',
    'Evangelisch - was ist das?',
    'Ecangelisch - was ist das?',
  ],
  symbol: ['Symbol'],
  vignetten: ['Vignetten'],
  geburtstage: ['Geburtstage'],
  gemeindesymbole: ['Gemeindesymbole'],
  kirchenmusik: ['Kirchenmusik'],
  feiertagssymbole: ['Feiertagssymbole'],
  jahreszeit: ['Jahreszeit'],
  frühling: ['Frühling'],
  sommer: ['Sommer'],
  herbst: ['Herbst'],
  winter: ['Winter'],
  jahreslosung: ['Jahreslosung 2020'],
  monatssprüche: ['Monatssprüche 2020', 'Grafiken Monatssprüche 2020'],
};
