(function () {
  'use strict';

  function Authorization(cdApp, localStorageService) {
    const checkPermission = (permissions, organization, func) => {
      permissions = _.isArray(permissions) ? permissions : [permissions];
      if (!permissions.length) return false;

      return func(permissions, (permission) => {
        const permissionsObject = _.get(organization, 'permissions');
        const hasNegation = _.first(permission) === '!';

        if (hasNegation) {
          permission = permission.slice(1);
        }
        if (!_.has(permissionsObject, permission)) return false;

        return _.get(permissionsObject, permission) === !hasNegation;
      });
    };

    const checkPackage = (packages, organization, func) => {
      packages = _.isArray(packages) ? packages : [packages];
      if (!packages.length) return false;

      return func(packages, (packageName) => {
        let hasNegation = _.first(packageName) === '!';
        if (hasNegation) {
          packageName = packageName.slice(1);
        }
        const organizationPackages = _.get(organization, 'packages');
        if (!_.has(organizationPackages, packageName)) return false;

        const developerPackages = localStorageService.get(
          'developerMode.packages'
        );

        if (developerPackages?.[packageName] !== undefined) {
          // eslint-disable-next-line no-console
          console.log(`Developer mode enabled: ${packageName}`);
          return developerPackages[packageName] === !hasNegation;
        }
        return (
          _.get(organizationPackages, `${packageName}.enabled`) === !hasNegation
        );
      });
    };

    const auth = {
      /**
       * Check if the user has all the specified permissions.
       *
       * @param {String|Array} permissions - The permissions to be checked. Can be either a string or an array of permissions.
       * @param {Object} [organization] - The organization to check the permission on (optional).
       * @returns {Boolean} Flag indicating whether the organization has the permission or not.
       */
      hasPermission: (permissions, organization = cdApp.organization) =>
        _.partial(checkPermission, permissions, organization)(_.every),

      /**
       * Check if the user has any of the specified permissions.
       *
       * @param {String|Array} permissions - The permissions to be checked. Can be either a string or an array of permissions.
       * @param {Object} [organization] - The organization to check the specified permissions on (optional).
       * @returns {Boolean} Flag indicating whether the organization has any of the specified permissions or not.
       */
      hasAnyPermission: (permissions, organization = cdApp.organization) =>
        _.partial(checkPermission, permissions, organization)(_.some),

      /**
       * Check if the organization has a specific package enabled.
       *
       * @param {String|Array} packageName - The package name to be checked.
       * @param {Object} [organization] - The organization to check the package on (optional).
       * @returns {Boolean} Flag indicating whether the organization has the package or not.
       */
      hasPackage: (packageName, organization = cdApp.organization) => {
        if (packageName === 'intentionSimple') {
          return _.partial(
            checkPackage,
            ['intentionSimple', 'intentions', 'intentionExtended'],
            organization
          )(_.some);
        }
        if (packageName === 'intentionExtended') {
          return _.partial(
            checkPackage,
            ['intentions', 'intentionExtended'],
            organization
          )(_.some);
        }
        if (packageName === 'intentions') {
          return _.partial(
            checkPackage,
            ['intentionSimple', 'intentions', 'intentionExtended'],
            organization
          )(_.some);
        }

        return _.partial(checkPackage, packageName, organization)(_.every);
      },
    };

    return auth;
  }
  Authorization.$inject = ['cdApp', 'localStorageService'];
  angular.module('cdApp.shared').factory('Authorization', Authorization);
})();
