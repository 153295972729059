'use strict';

class PeopleExportDownloadState {
  constructor($stateParams, People, $state, _, cdApp, AuthenticationService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.People = People;
    this.$state = $state;
    this._ = _;
    this.cdApp = cdApp;
    this.AuthenticationService = AuthenticationService;
  }

  $onInit() {
    this.exportId = this.$stateParams.id;
    this.errorWhenDownloading = false;
    this.errorMessage = null;
    this.checkExport();
  }

  checkExport() {
    const { People, $state, _, cdApp, AuthenticationService } = this;

    People.checkExport({
      id: this.exportId,
    })
      .$promise.then(() => {
        const url = new Uri(cdApp.config.api.main);

        url.setPath(`${url.path()}/people/export/${this.exportId}/download`);
        url.addQueryParam('organizationId', cdApp.organization.id);
        url.addQueryParam(
          'access_token',
          AuthenticationService.getAccessToken()
        );

        window.location = url.toString();
        $state.go('app.private.people.contacts.list');
      })
      .catch((error) => {
        this.errorMessage =
          _.get(error, 'data.message') || _.get(error, 'statusText');
        this.errorWhenDownloading = true;
      });
  }
}

PeopleExportDownloadState.$inject = [
  '$stateParams',
  'People',
  '$state',
  '_',
  'cdApp',
  'AuthenticationService',
];

angular.module('cdApp.people').component('cdPeopleExportDownloadState', {
  templateUrl:
    '@/app/people/people-export-download/people-export-download.component.html',
  controller: PeopleExportDownloadState,
});
