import React from 'react';
import { Space, Input, Select } from 'antd';
import { toNumber } from 'lodash';

import { SafeGuardingListQuery } from '../store/Safeguarding';
import { gettextCatalog } from '../../services/I18nService';
import {
  SafeguardingListInterface,
  safeguardingRoles,
  safeguardingTypeDropDown,
} from '../types/SafeguardingTypes';
import { useSafeguarding } from '../hooks/useSafeguarding';
import CdUnPagedTable from '../../shared/components/cd-unpaged-table/CdUnPagedTable';

import { safeguardingTableColumns } from './SafeguardingTableColumns';

import CdSelect from '@/react/shared/components/cd-select/CdSelect';
import { OrganizationUserStatus } from '@/react/safeguarding/safeguarding/OrganizationUserStatus';
import { getRowClassName } from '@/react/user/screens/UserList';

const { Search } = Input;

export const SafeguardingList = ({
  filter,
  status,
  userTypes,
  churchIds,
  groupIds,
}: {
  filter: string;
  status: string[];
  userTypes: string[];
  churchIds: number[];
  groupIds: number[];
}) => {
  const {
    canEdit,
    onChangeHandler,
    filterHandler,
    statusFilterHandler,
    roleHandler,
    churchIdsChangesHandler,
    groupIdsChangesHandler,
    churches,
    OrganizationGroups,
    organizationPortals,
  } = useSafeguarding();

  return (
    <CdUnPagedTable<SafeguardingListInterface>
      columns={safeguardingTableColumns({ canEdit })}
      dataResult={SafeGuardingListQuery}
      emptyStateProps={{
        title: gettextCatalog.getString('No safeguarding records found'),
      }}
      extraParams={{
        filter: filter,
        status: status,
        userTypes: userTypes,
        churchIds: churchIds,
        groupIds: groupIds,
      }}
      filterForm={
        <Space style={{ display: 'flex', justifyContent: 'left' }}>
          {/* the two filters supposed to be added if  */}

          {!organizationPortals.isPortalHost && (
            <CdSelect
              mode="multiple"
              placeholder={gettextCatalog.getString('Groups')}
              value={groupIds?.map((id) => toNumber(id))}
              options={[...OrganizationGroups]
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((group) => ({
                  label: group.name,
                  value: group.id,
                }))}
              onChange={groupIdsChangesHandler}
              style={{ minWidth: 100 }}
              dropdownStyle={{ minWidth: 200 }}
              maxTagCount={0}
              maxTagPlaceholder={(omittedValues) => {
                const itemCount = omittedValues.length;
                return gettextCatalog.getPlural(
                  itemCount,
                  '1 group',
                  '{{ $count }} groups',
                  { $count: itemCount }
                );
              }}
            />
          )}

          {!organizationPortals.isPortalHost && (
            <CdSelect
              mode="multiple"
              placeholder={gettextCatalog.getString('Parish')}
              value={churchIds?.map((id) => toNumber(id))}
              options={[...churches]
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((church) => ({
                  label: church.name,
                  value: church.id,
                }))}
              onChange={churchIdsChangesHandler}
              style={{ minWidth: 100 }}
              dropdownStyle={{ minWidth: 200 }}
              maxTagCount={0}
              maxTagPlaceholder={(omittedValues) => {
                const itemCount = omittedValues.length;
                return gettextCatalog.getPlural(
                  itemCount,
                  '1 parish',
                  '{{ $count }} parishes',
                  { $count: itemCount }
                );
              }}
            />
          )}

          <OrganizationUserStatus
            status={status}
            statusHandler={statusFilterHandler}
          />
          <Select
            defaultValue={filter}
            options={safeguardingTypeDropDown()}
            onChange={filterHandler}
            style={{ minWidth: 100 }}
            dropdownStyle={{ minWidth: 200 }}
          />
          <Select
            mode="multiple"
            placeholder={gettextCatalog.getString('User Type')}
            defaultValue={userTypes}
            options={safeguardingRoles()}
            onChange={roleHandler}
            style={{ minWidth: 100 }}
          />
          <Search
            placeholder={gettextCatalog.getString('Search...')}
            style={{ width: 200 }}
            onChange={onChangeHandler}
            allowClear
          />
        </Space>
      }
      rowClassName={getRowClassName}
    />
  );
};
