'use strict';

class PeopleSettingsComponent {
  constructor(
    $http,
    _,
    $state,
    gettextCatalog,
    toastr,
    localStorageService,
    PeoplePreferences,
    cdApp
  ) {
    'ngInject';

    this._ = _;
    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.localStorageService = localStorageService;
    this.PeoplePreferences = PeoplePreferences;
    this.cdApp = cdApp;
    this.$http = $http;

    this.peopleSettings = {
      displayOrder: localStorageService.get('peopleSettings.displayOrder'),
    };
  }

  $onInit() {
    const fields = ['smsSenderName', 'organizationEmail', 'requireDoubleOptIn'];
    this.canEditAnyField = this._.some(
      fields,
      (field) => _.get(this.churchInfo.fields, [field, 'canEdit']) === true
    );

    this.noPeopleAccessString = this.gettextCatalog.getString(
      'You cannot access {{package}}',
      {
        package: this._.capitalize(
          this.gettextCatalog.getString('people', {}, 'The name of the app')
        ),
      }
    );

    this.smsBalance = this.churchInfo.smsBalance;
    this.newsletterLogo = this.churchInfo.logo && this.churchInfo.logo.small;
  }

  save(payload) {
    this.localStorageService.set(
      'peopleSettings.displayOrder',
      this.peopleSettings.displayOrder
    );

    payload = _.pick(payload, [
      'smsSenderName',
      'organizationEmail',
      'requireDoubleOptIn',
    ]);

    new this.PeoplePreferences(payload).$save(() => {
      this.toastr.success(
        this.gettextCatalog.getString('Your changes have been saved.')
      );

      this.$state.reload();
    });
  }

  getSmsBalanceClass() {
    if (this.smsBalance >= 100) return 'text-success';
    if (this.smsBalance < 100) return 'text-danger';
  }

  onUploadSuccess(data) {
    this.newsletterLogo = data.url;
  }

  onRemove() {
    const { $http, cdApp } = this;
    $http
      .post(
        `${cdApp.config.api.main}/organizations/upload?type=people&mode=reset`
      )
      .then(() => {
        this.newsletterLogo = null;
      });
  }
}
PeopleSettingsComponent.$inject = [
  '$http',
  '_',
  '$state',
  'gettextCatalog',
  'toastr',
  'localStorageService',
  'PeoplePreferences',
  'cdApp',
];

angular.module('cdApp.settings').component('cdPeopleSettingsState', {
  templateUrl: '@/app/settings/people-settings/people-settings.component.html',
  bindings: {
    churchInfo: '<',
  },

  controller: PeopleSettingsComponent,
});
