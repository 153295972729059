import React from 'react';
import CountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputProps,
} from 'antd-country-phone-input';
import styled from 'styled-components';
import { CountryCode } from 'libphonenumber-js';

import CdCountryFlag from './cd-country-flag/CdCountryFlag';

import { getCountryList } from '@/react/services/locale/country.service';

const StyledComponent = styled.div`
  .antd-country-phone-input {
    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const CdPhoneInput = (props: CountryPhoneInputProps) => (
  <ConfigProvider
    locale={getCountryList()}
    areaMapper={(area) => ({
      ...area,
      emoji: (
        <CdCountryFlag
          countryIso2={area.short.toLowerCase()}
          style={{ marginRight: '5px', alignSelf: 'center' }}
        />
      ),
    })}
  >
    <StyledComponent>
      <CountryPhoneInput inline {...props} />
    </StyledComponent>
  </ConfigProvider>
);

export interface CdPhone {
  phone?: string;
  code?: number; // eg. 45
  short: CountryCode; // Capitalized countryIso2
}
