(function () {
  'use strict';
  cdAutofocus.$inject = ['$timeout'];

  function cdAutofocus($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $timeout(function () {
          $element[0].focus();
        });
      },
    };
  }

  angular.module('cdApp.shared').directive('cdAutofocus', cdAutofocus);
})();
