import { combineReducers } from 'redux';

import { rolesReducer } from '../store/roleSlice';

import GroupsReducer, { ImmutableGroupsState } from './groups';
import JobsReducer, { ImmutableJobsState } from './jobs';

export interface OrganizationCombinedState {
  groups: ImmutableGroupsState;
  roles: any;
  jobs: ImmutableJobsState;
}

export default combineReducers<OrganizationCombinedState>({
  groups: GroupsReducer,
  roles: rolesReducer,
  jobs: JobsReducer,
});
