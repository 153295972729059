(function () {
  'use strict';
  UserFeed.$inject = ['$$resource'];

  function UserFeed($$resource) {
    return $$resource(cdApp.config.api.main + '/users/:id/feed');
  }

  angular.module('cdApp.shared').factory('UserFeed', UserFeed);
})();
