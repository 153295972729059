import { IdentifiableEntity } from './commons';

export enum IntentionReportTemplateTypes {
  GOTTESDIENSTORDNUNG = 'gottesdienstordnung',
}

export interface IntentionReportTemplate {
  id: string;
  organizationId: number;
  name: string;
  description: string;
  fileId: number;
  file: IdentifiableEntity;
  type: IntentionReportTemplateTypes;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}
