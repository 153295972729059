import React from 'react';
import { Radio, Space } from 'antd';
import styled from 'styled-components';

const WidgetStyleButton = styled.div`
  width: 50px;
  height: 50px;

  div {
    overflow: hidden;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAFpJREFUWAntljEKADAIA23p6v//qQ+wfUEcCu1yriEgp0FHRJSJcnehmmWm1Dv/lO4HIg1AAAKjTqm03ea88zMCCEDgO4HV5bS757f+7wRoAAIQ4B9gByAAgQ3pfiDmXmAeEwAAAABJRU5ErkJggg==')
      0% 0% / 28px;
    height: 100%;
    width: 100%;
  }
  padding: 2px;
  position: relative;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 6px;
  &.selected {
    border: 1px solid #038db6;
  }
  &:hover {
    border: 1px solid #038db6;
  }
  &.disabled {
    border: 1px solid #d9d9d9 !important;
    cursor: not-allowed !important;
  }
`;

const WidgetStyle = styled.div`
  &&&& {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translate(30%, 30%);
    border: 2px solid rgba(0, 0, 0, 0.45);
    background: rgb(255, 255, 255);
  }
`;

const WidgetStyleSharp = styled(WidgetStyle)`
  &&&& {
    border-radius: 2px;
  }
`;

const WidgetStyleRounded = styled(WidgetStyle)`
  &&&& {
    border-radius: 16px;
  }
`;

const RadioWithNoLabel = styled(Radio)`
  &&&& {
    margin-right: 0px;
  }
`;

export const WidgetCornerStyleButton = (props: {
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  disabled: boolean;
}) => {
  const radioValue = props.value ? 'round' : 'sharp';
  const radioOnChange = (value: string) => props.onChange(value === 'round');

  let sharpButtonClassName = radioValue === 'sharp' ? 'selected' : '';
  let roundButtonClassName = radioValue === 'round' ? 'selected' : '';

  if (props.disabled) {
    sharpButtonClassName += ' disabled';
    roundButtonClassName += ' disabled';
  }

  return (
    <Space direction="horizontal">
      <div
        style={{ textAlign: 'center' }}
        onClick={() => !props.disabled && radioOnChange('sharp')}
      >
        <Space direction="vertical">
          <WidgetStyleButton className={sharpButtonClassName}>
            <div>
              <WidgetStyleSharp />
            </div>
          </WidgetStyleButton>
          <RadioWithNoLabel
            disabled={props.disabled}
            value="sharp"
            checked={radioValue === 'sharp'}
            onChange={(e) => !props.disabled && radioOnChange(e.target.value)}
          />
        </Space>
      </div>
      <div
        style={{ textAlign: 'center' }}
        onClick={() => !props.disabled && radioOnChange('round')}
      >
        <Space direction="vertical">
          <WidgetStyleButton className={roundButtonClassName}>
            <div>
              <WidgetStyleRounded />
            </div>
          </WidgetStyleButton>
          <RadioWithNoLabel
            disabled={props.disabled}
            value="round"
            checked={radioValue === 'round'}
            onChange={(e) => !props.disabled && radioOnChange(e.target.value)}
          />
        </Space>
      </div>
    </Space>
  );
};
