(function () {
  'use strict';
  userFactory.$inject = [
    '$window',
    '$http',
    '$q',
    'localStorageService',
    'gettextCatalog',
    'Me',
  ];

  function userFactory(
    $window,
    $http,
    $q,
    localStorageService,
    gettextCatalog,
    Me
  ) {
    // Main factory data object.
    let userFactory = {
      Data: [],
      User: [],
      Roles: [],
      Content: [],
      processed: false,
    };

    /**
     * Check the state of the Factory data.
     * @returns {boolean|*}
     *   boolean representing the state of the Factory.
     */
    userFactory.ready = function () {
      return userFactory.processed;
    };

    /**
     * Get the list of the users.
     * @returns array {null|*}
     *   return the list of the users.
     */
    userFactory.load = function () {
      let defer = $q.defer();
      // Request to get the data.
      $http
        .get(cdApp.config.api.main + '/users')
        .success(function (response) {
          userFactory.Data = response;
          userFactory.processed = true;
          defer.resolve(response);
        })
        .error(function (error) {
          defer.reject(
            'We are not able to fetch users due to the following problem: ' +
              error
          );
        });
      return defer.promise;
    };

    /**
     * Public function to get the user object, it will load in the backend all
     * the users. Be aware to use this is correct content. If we need only single
     * user from the endpoint, use userFactory.fetchUser(uid).
     * @param <int> uid
     *  the user id we want to get.
     * @returns {object}
     *  return user object.
     */
    userFactory.get = function (uid) {
      // If user uid is not specified, return current user id.
      uid = uid || Me.id;
      // If we already have the data return it.
      if (userFactory.ready()) {
        return _.find(userFactory.Data, { id: uid });
      }
    };

    /**
     * Global function to fetch the user object single property.
     * @param <int> uid
     *   the user id.
     * @param <string> property
     *   property name
     * @returns {*}
     *   returns user property data.
     */
    userFactory.getProperty = function (uid, property) {
      let user = userFactory.get(uid);
      return user && user[property];
    };

    /**
     * Fetch the single user from the database.
     * @param <int> uid
     *   the user id, which we would like to get.
     * @returns {*}
     *   return the promise.
     */
    userFactory.retrieve = function (uid) {
      let defer = $q.defer();
      if (uid === undefined) {
        defer.resolve();
        return defer.promise;
      }

      $http
        .get(cdApp.config.api.main + '/users/' + uid)
        .success(function (response) {
          userFactory.User = response;
          userFactory.processed = true;
          defer.resolve(response);
        })
        .error(function (error) {
          defer.reject(
            'We are not able to fetch single user due to the following problem: ' +
              error
          );
        });

      return defer.promise;
    };

    /**
     * Update the user object information in the backend.
     * @param <int> uid
     *   user id
     * @param <object> data
     *   object containing information which needs to be updated.
     * @returns {*}
     *   returns promise.
     */
    userFactory.update = function (uid, data) {
      let defer = $q.defer();
      $http({
        method: 'PUT',
        url: cdApp.config.api.main + '/users/' + uid,
        data: data,
      })
        .success(function () {
          // Update the user object with the data.
          let idx = _.indexOf(_.map(userFactory.Data, 'id'), uid);
          userFactory.Data[idx] = $.extend({}, userFactory.Data[idx], data);
          defer.resolve('User updated successfully!');
        })
        .error(function (error) {
          defer.reject('Error occured during the user update' + error);
        });
      return defer.promise;
    };

    /**
     * Delete the user object.
     * @param <int> uid
     *   user id
     */
    userFactory.delete = function (uid) {
      let defer = $q.defer();
      $http({
        method: 'DELETE',
        url: cdApp.config.api.main + '/users/' + uid,
      })
        .success(function () {
          _.remove(userFactory.Data, { id: uid });
          defer.resolve('User deleted successfully!');
        })
        .error(function (error) {
          defer.reject('Error occurred during user delete. ' + error);
        });
      return defer.promise;
    };

    /**
     * Promise request to database to fetch the user roles.
     * @returns {*}
     *   returns the promise.
     */
    userFactory.getRoles = function (done) {
      // The roles now come directly from node, and the id's do not change.
      let roles = [
        {
          categoryName: gettextCatalog.getString('Organization administrator'),
          roles: [
            {
              id: 8,
              name: gettextCatalog.getString('Organization administrator'),
            },
          ],
        },

        {
          categoryName: gettextCatalog.getString('Calendar'),
          roles: [
            {
              id: 15,
              name: gettextCatalog.getString('Calendar access'),
            },

            {
              id: 16,
              name: gettextCatalog.getString(
                'Can share events with all groups'
              ),
            },

            {
              id: 17,
              name: gettextCatalog.getString('Can edit all events'),
            },

            {
              id: 4,
              name: gettextCatalog.getString('Can edit group events'),
            },

            {
              id: 7,
              name: gettextCatalog.getString('Can book and plan'),
            },

            {
              id: 6,
              name: gettextCatalog.getString('Can force double booking'),
            },

            {
              id: 11,
              name: gettextCatalog.getString('Can view absence(s)'),
            },

            {
              id: 10,
              name: gettextCatalog.getString('Can manage absence(s)'),
            },

            {
              id: 9,
              name: gettextCatalog.getString(
                'Can access sensitive information'
              ),
            },
          ],
        },

        {
          categoryName: gettextCatalog.getString('People'),
          roles: [
            {
              id: 2,
              name: gettextCatalog.getString('Can access People'),
            },

            {
              id: 20,
              name: gettextCatalog.getString(
                'Can access sensitive information'
              ),
            },
          ],
        },

        {
          categoryName: gettextCatalog.getString('Contributions'),
          roles: [
            {
              id: 14,
              name: gettextCatalog.getString('Contributions access'),
            },
          ],
        },

        {
          categoryName: gettextCatalog.getString('Website'),
          roles: [
            {
              id: 3,
              name: gettextCatalog.getString('Can edit Website'),
            },
          ],
        },

        {
          categoryName: gettextCatalog.getString('Publish'),
          roles: [
            {
              id: 5,
              name: gettextCatalog.getString('Can Publish'),
            },
          ],
        },

        {
          category: 'graveyard-section',
          categoryName: gettextCatalog.getString('Graveyard'),
          roles: [
            {
              id: 13,
              name: gettextCatalog.getString('Can access Graveyard'),
            },

            {
              id: 12,
              name: gettextCatalog.getString('Is Graveyard Administrator'),
            },
          ],
        },
      ];

      // Ignore graveyard roles for non-danish customers
      let countryCode = _.get(cdApp, 'organization.countryIso2');
      if (
        countryCode &&
        !(
          countryCode.toLowerCase() === 'dk' &&
          _.has(cdApp.organization.modules, 'graveyard')
        )
      ) {
        _.remove(roles, { category: 'graveyard-section' });
      }

      if (_.has(cdApp.organization.modules, 'forms')) {
        roles = _.concat(roles, {
          categoryName: gettextCatalog.getString('Forms'),
          roles: [
            {
              id: 18,
              name: gettextCatalog.getString('Forms access'),
            },

            {
              id: 19,
              name: gettextCatalog.getString(
                'Can access sensitive information'
              ),
            },
          ],
        });
      }

      return done(roles);
    };

    /**
     * Fetch content created by a single user.
     * @param <int> uid
     *   the user id, which we would like to get the content from.
     * @returns {*}
     *   return the promise.
     */
    userFactory.getContent = function (uid) {
      let defer = $q.defer();
      if (uid === undefined) {
        defer.resolve();
        return defer.promise;
      }
      $http
        .get(cdApp.config.api.main + '/users/' + uid + '/feed')
        .success(function (response) {
          userFactory.Content = response;
          userFactory.processed = true;
          defer.resolve(response);
        })
        .error(function (error) {
          defer.reject(
            'We are not able to fetch the content for this user due to the following problem: ' +
              error
          );
        });
      return defer.promise;
    };

    return userFactory;
  }

  angular.module('cdApp.shared').factory('userFactory', userFactory);
})();
