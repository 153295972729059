(function () {
  'use strict';
  Groups.$inject = ['$$resource'];

  function Groups($$resource) {
    return $$resource(
      cdApp.config.api.main + '/groups/:id',
      {},
      {
        updateUserMembership: {
          method: 'PUT',
          url:
            cdApp.config.api.main + '/groups/:groupId/users/:userId/membership',
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Groups', Groups);
})();
