import angular from 'angular';

angular.module('cdApp.dashboard').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/dashboard/dashboard-extended.html',
      require('./dashboard-extended.html')
    );

    $templateCache.put(
      '@/app/dashboard/dashboard.html',
      require('./dashboard.html')
    );

    $templateCache.put(
      '@/app/dashboard/modals/declined-invitations.html',
      require('./modals/declined-invitations.html')
    );

    $templateCache.put(
      '@/app/dashboard/partials/booking-notification.html',
      require('./partials/booking-notification.html')
    );

    $templateCache.put(
      '@/app/dashboard/partials/see-all-invitations.html',
      require('./partials/see-all-invitations.html')
    );

    $templateCache.put(
      '@/app/dashboard/partials/see-all-upcoming.html',
      require('./partials/see-all-upcoming.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/contributions.html',
      require('./views/contributions.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/events.html',
      require('./views/events.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/messages.html',
      require('./views/messages.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/notifications.html',
      require('./views/notifications.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/shifts.html',
      require('./views/shifts.html')
    );

    $templateCache.put(
      '@/app/dashboard/views/support.html',
      require('./views/support.html')
    );
  },
]);
