(function () {
  'use strict';
  Invitations.$inject = ['$$resource'];

  function Invitations($$resource) {
    return $$resource(cdApp.config.api.main + '/calendar/invitations');
  }

  angular.module('cdApp.shared').factory('Invitations', Invitations);
})();
