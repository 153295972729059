import React from 'react';
import styled from 'styled-components';

import File from './File';
import Author from './Author';
import Caption from './Caption';
import Credit from './Credit';
import GepDisclaimer from './GepDisclaimer';
import Date from './Date';
import Empty from './Empty';
import Group from './Group';
import Size from './Size';
import Tags from './Tags';
import Title from './Title';
import Visibility from './Visibility';
import FileType from './FileType';

const Wrapper = styled.div`
  padding: 0 10px 10px 10px;
`;

const FileDetail = ({ children }) => (
  <Wrapper>
    <File>{children}</File>
  </Wrapper>
);

export default FileDetail;

export {
  Author,
  Caption,
  Credit,
  GepDisclaimer,
  Date,
  Empty,
  Group,
  Size,
  Tags,
  Title,
  Visibility,
  FileType,
};
