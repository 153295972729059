(function () {
  'use strict';

  function ChurchscreenController(
    moment,
    $scope,
    $state,
    $http,
    $uibModal,
    ChurchScreen,
    Files,
    toastr,
    gettextCatalog
  ) {
    // =============================================================================
    // Fetch or create all data that is needed.
    // =============================================================================

    // Instantiate models.
    $scope.jobs = [];
    $scope.tags = [];

    // Get church screen settings.
    ChurchScreen.get(
      { countryIso2: _.get(cdApp, 'organization.countryIso2') },
      function (data) {
        function done() {
          $scope.jobs = ($scope.data && $scope.data.jobList) || [];
          $scope.tags = ($scope.data && $scope.data.peopleTagList) || [];

          if (!data.readMore) {
            $scope.data.readMore =
              _.get(cdApp, 'organization.installationUrl') + '/';
          }
        }

        if (data.imageId) {
          let imageId = data.imageId;

          Files.get({ id: imageId })
            .$promise.then((file) => {
              let originalUrl = data.image;
              $scope.data = data;
              $scope.data.image = {
                file,
                url: originalUrl,
              };

              done();
            })
            .catch(() => {
              $scope.data = data;
              done();
            });
        } else {
          $scope.data = data;
          done();
        }
      }
    );

    // Search events. We use $http because it works with promises.
    $scope.search = function (val, type) {
      return $http
        .get(cdApp.config.api.main + '/churchscreen/search/' + type, {
          params: {
            search: val,
          },
        })
        .then(function (response) {
          return _.map(response.data, function (item) {
            if (item.startDate) {
              item.date = $scope.Date(item.startDate);
            } else if (
              item.scheduleUnpublish &&
              !_.isNull(item.scheduleUnpublish)
            ) {
              item.date = $scope.Date(item.scheduleUnpublish);
            }
            return item;
          });
        });
    };

    $scope.cropImage = () => {
      const context = 'churchscreen';

      $uibModal
        .open({
          component: 'cdImageCropModal',
          resolve: {
            cropKey: () => context,
            file: () => $scope.data.image.file,
            bounds: [
              'Files',
              function (Files) {
                'ngInject';
                return Files.getCropInformation({
                  id: $scope.data.image.file.id,
                  context,
                }).$promise;
              },
            ],
          },
        })
        .result.then(({ croppedImage }) => {
          $scope.data.image.url = croppedImage;
        });
    };

    // =============================================================================
    // General stuff.
    // =============================================================================

    // Add the selected item to an array.
    $scope.select = function (item, context) {
      $scope.data[context].push(item);
    };

    // Remove the desired item from the selection.
    $scope.remove = function (index, context) {
      $scope.data[context].splice(index, 1);
    };

    // Save church screen settings.
    $scope.save = function (form) {
      let payload = {};
      let inputs = _.pickBy(form, function (value, key) {
        return key[0] !== '$' && !value.$pristine;
      });

      _.each(inputs, function (input, key) {
        _.set(payload, key, input.$modelValue);
      });

      payload.events = !_.isEmpty($scope.data.events)
        ? _.map($scope.data.events, 'id')
        : null;
      payload.blogs = !_.isEmpty($scope.data.blogs)
        ? _.map($scope.data.blogs, 'id')
        : null;
      payload.image = _.get($scope.data, 'image.file.id', null);

      new ChurchScreen(payload).$save(
        function () {
          toastr.success(
            gettextCatalog.getString('Churchscreen settings have been saved.')
          );

          $state.reload();
        },
        function (error) {
          toastr.error(
            _.get(error, 'data.message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
      );
    };

    // =============================================================================
    // Utilities.
    // =============================================================================

    // Generate a date from timestamp to e.g. Wednesday, July 8, 2015 4:22 PM
    $scope.Date = function (number) {
      return moment(number).format('LLLL');
    };
  }
  ChurchscreenController.$inject = [
    'moment',
    '$scope',
    '$state',
    '$http',
    '$uibModal',
    'ChurchScreen',
    'Files',
    'toastr',
    'gettextCatalog',
  ];

  angular
    .module('cdApp.intranet')
    .controller('ChurchscreenController', ChurchscreenController);
})();
