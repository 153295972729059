import { selectorFamily } from 'recoil';

import { getCalendar } from '@/react/calendar/event-details/services/event.service';
import { CommentItem } from '@/react/calendar/types/comment';

export const EventCommentsQuery = selectorFamily<
  CommentItem[],
  { calendarId: number }
>({
  key: 'EventCommentsQuery',
  get:
    ({ calendarId }) =>
    async () => {
      if (!calendarId) return;
      const event = await getCalendar(calendarId, 3);
      return event.comments || [];
    },
});
