'use strict';

import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { OtherSettingsPage } from '@/react/settings/components/OtherSettingsPage';

angular.module('cdApp.settings').component('cdSettings', {
  templateUrl: '@/app/settings/settings.component.html',
});

angular
  .module('cdApp.settings')
  .component(
    'cdOtherSettingPage',
    react2angular(sharedContext.use(OtherSettingsPage))
  );
