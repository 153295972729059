import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValueLoadable,
} from 'recoil';

import { mainApi } from '@/react/api';
import {
  MessageType,
  PeopleMessageRecipient,
} from '@/react/people/types/message';
import { handleError } from '@/react/services/ErrorHandlingService';
import { navigate } from '@/react/services/StateServiceFactory';
import { CalendarInvitation } from '@/react/calendar/types/event';
import { EventInvitationTemplatesQuery } from '@/react/calendar/store/events/event';
import { StudioState } from '@/react/calendar/services/studioService';
import { GetEventInvitations } from '@/react/calendar/event-details/store/invitations';

function recipientReducer(
  accumulator: { id: number; group: string }[],
  currentValue: string
) {
  if (currentValue.includes('person-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('person-', ''), 10),
      group: 'contacts',
    });
  }
  if (currentValue.includes('segment-')) {
    accumulator.push({
      id: parseInt(currentValue.replace('segment-', ''), 10),
      group: 'lists',
    });
  }
  return accumulator;
}

function mapEventInvitationsFromForm(message) {
  return {
    ...message,
    to: message.to
      .map((target) =>
        target.setup ? `segment-${target.id}` : `person-${target.id}`
      )
      .reduce(recipientReducer, []),
  };
}

export const useEventInvitations = ({ calendarId }) => {
  const refreshStudioState = useRecoilRefresher(StudioState);
  const eventInvitationRefresher = useRecoilRefresher(
    GetEventInvitations({ eventId: calendarId })
  );
  const selectTemplate = useRecoilCallback(
    ({}) =>
      async ({
        templateId,
        calendarId,
        recipients,
      }: {
        templateId: string;
        calendarId: number;
        recipients?: PeopleMessageRecipient[];
      }) => {
        const response = await mainApi.post<CalendarInvitation>(
          `/calendar/${calendarId}/event-invitation?templateId=${templateId}`,
          { ...mapEventInvitationsFromForm({ to: recipients || [] }) }
        );

        if (!response.ok) {
          handleError(response);
          throw response;
        }
        navigate(
          'app.private.people.messages.editorV2.view',
          { messageId: response.data.id, type: MessageType.EVENT_INVITATION },
          { reload: false }
        );
        refreshStudioState();
        eventInvitationRefresher();
      },
    [eventInvitationRefresher, refreshStudioState]
  );

  const templatesLoadable = useRecoilValueLoadable(
    EventInvitationTemplatesQuery
  );
  const templatesLoading = templatesLoadable.state === 'loading';
  const templates =
    templatesLoadable.state === 'hasValue' ? templatesLoadable.contents : [];
  return { selectTemplate, templatesLoading, templates };
};
