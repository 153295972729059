'use strict';

angular.module('cdApp.shared').factory('Announcement', [
  '$$resource',
  'cdApp',
  ($$resource, cdApp) => {
    'ngInject';

    return $$resource(
      `${cdApp.config.api.main}/users/announcements`,
      {},
      {
        dismiss: {
          method: 'PUT',
        },
      }
    );
  },
]);
