'use strict';

import { COLOR_INDEX_ORDERING_TABLE } from '../../ResourceColors';
import { RefreshMainDataRequest } from '../../../react/config/store/Actions';
import PortalService from '../../../react/portal/services/portal.service';

import { recoilRefresh } from '@/app/cdRecoilRefresher';
import { Resources } from '@/react/shared/services/ResourceService';
import {
  PortalOrganizationEventCategories,
  CategoryByTypeQuery,
} from '@/react/shared/services/CategoryService';

class TaxonomyDetailComponent {
  constructor(
    _,
    $ngRedux,
    $rootScope,
    $state,
    $stateParams,
    Resources,
    Taxonomies,
    Partnership,
    Analytics,
    toastr,
    gettextCatalog,
    appUtils,
    Authorization,
    $scope,
    cdApp
  ) {
    'ngInject';
    const mapDispatchToThis = (dispatch) => ({
      RefreshAuth: () => dispatch(RefreshMainDataRequest()),
    });

    const unsubscribe = $ngRedux.connect(() => ({}), mapDispatchToThis)(this);

    this.$onDestroy = function () {
      unsubscribe();
    };
    this._ = _;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.Resources = Resources;
    this.Taxonomies = Taxonomies;
    this.Partnership = Partnership;
    this.Analytics = Analytics;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.colors = _.map(
      COLOR_INDEX_ORDERING_TABLE,
      (order) => Taxonomies.colors[order]
    );

    this.isTaxonomyState =
      $state.is('app.private.settings.calendar.edit') ||
      $state.is('app.private.settings.resources.edit');
    this.appUtils = appUtils;
    this.hasIntentionsPackage = Authorization.hasPackage('intentions');
    this.$scope = $scope;
    this.showIntentionFields = false;
    this.processing = false;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
  }

  $onInit() {
    const {
      _,
      $stateParams,
      appUtils,
      gettextCatalog,
      Resources,
      Taxonomies,
      cdApp,
      Authorization,
    } = this;

    this.type = this.resolve.type;
    const data = this.resolve.data;
    this.organizationPortals = this.resolve.organizationPortals;
    this.portals = this.organizationPortals && this.organizationPortals.portals;
    this.churches = [
      { id: null, name: gettextCatalog.getString('No church...') },
      ...this.resolve.churches,
    ];

    this.showChurchSelector = cdApp.showChurchSelector;
    this.showIntentionFields =
      this.showChurchSelector && Authorization.hasPackage('intentions');
    const TaxonomyResource = _.includes(['church', 'resource'], this.type)
      ? Resources
      : Taxonomies;
    this.isAccordion = false;

    if (this.isTaxonomyState && $stateParams.id) {
      this.taxonomy = _.extend(data, { type: this.type });
      this.selectedColorIdx = _.indexOf(COLOR_INDEX_ORDERING_TABLE, data.color);
      this.title = gettextCatalog.getString('Edit "{{taxonomyName}}"', {
        taxonomyName: data.name,
      });
    } else {
      this.taxonomy = new TaxonomyResource({ type: this.type });
      this.selectedColorIdx = _.indexOf(COLOR_INDEX_ORDERING_TABLE, 0);

      this.title = gettextCatalog.getString('Create {{taxonomyType}}', {
        taxonomyType: this.taxonomyType(),
      });
    }

    if (this.resolve.map && this.type === 'event') {
      _.each(this.resolve.map || [], (item) => {
        this.taxonomy.partners = this.taxonomy.partners || {};
        this.taxonomy.partners[item.partnerId] = item.partnerCategoryId;
      });
    }

    // Portal category mappings
    if (
      this.resolve.portalCategoryMap &&
      (this.type === 'event' || this.type === 'blog')
    ) {
      this.taxonomy.portals = this.resolve.portalCategoryMap;
    }

    if (_.includes(['church', 'resource'], this.type)) {
      // Try to retrieve the resource's location (church or standalone resource)
      let location = this.taxonomy.location;
      /**
       * If no location was found, it could be that the resource is a child
       * resource and the location has to be retrieved from its parent
       */
      if (!location) {
        location = _.get(
          _.find(this.churches, { id: this.taxonomy.parentResourceId }),
          'location'
        );
      }
      // Finally, convert the location (returned with the backend format) to the Google format
      this.location = appUtils.buildGooglePlacesObject(location);
    }
  }

  selectColor(colorIdx) {
    this.selectedColorIdx = colorIdx;
  }

  taxonomyType() {
    switch (this.type) {
      case 'church':
        return this.gettextCatalog.getString('parish');
      case 'resource':
        return this.gettextCatalog.getString('resource');
      case 'event':
        return this.gettextCatalog.getString('event category');
      case 'blog':
        return this.gettextCatalog.getString('blog category');
      case 'absence':
        return this.gettextCatalog.getString('absence type');
    }
  }

  shouldPortalSelectorBeDisabled(type, portalCategories) {
    return (
      this._.filter(portalCategories, (category) => category.type === type)
        .length === 0
    );
  }

  saveTaxonomy() {
    const {
      _,
      $rootScope,
      $stateParams,
      Analytics,
      gettextCatalog,
      Partnership,
      toastr,
    } = this;
    const type = this.type;
    if (this.processing) return;
    this.processing = true;

    this.taxonomy.color = _.get(
      COLOR_INDEX_ORDERING_TABLE,
      this.selectedColorIdx,
      0
    );

    this.taxonomy.description = this.taxonomy.description || null;

    // Modify the resource location field
    if (_.includes(['church', 'resource'], this.type)) {
      this.taxonomy.type = type === 'church' ? 'church' : null;

      if (this.showIntentionFields && type === 'church') {
        const {
          migrationIntentionReferenceNumberAndYear,
          migrationLegatReferenceNumber,
          migrationStiftungReferenceNumber,
        } = this.taxonomy;
        // Set empty strings to null
        if (_.isEmpty(migrationIntentionReferenceNumberAndYear)) {
          this.taxonomy.migrationIntentionReferenceNumberAndYear = null;
        }
        if (!migrationLegatReferenceNumber) {
          this.taxonomy.migrationLegatReferenceNumber = null;
        }
        if (!migrationStiftungReferenceNumber) {
          this.taxonomy.migrationStiftungReferenceNumber = null;
        }
      }
    }

    this.taxonomy.$save(
      (savedTaxonomy) => {
        const id = savedTaxonomy.id;
        if (type === 'event' && this.taxonomy.partners) {
          if (_.head(_.values(this.taxonomy.partners))) {
            // We have already saved the taxonomy.
            // Let's map the category to the organization partners
            new Partnership.map({
              categoryId: this.taxonomy.id,
              partners: this.taxonomy.partners,
            }).$save();
          } else {
            // We need to remove the mapping for that category
            new Partnership.map({
              categoryId: this.taxonomy.id,
            }).$delete();
          }
        }

        // Map portal categories as well
        if (!_.isEmpty(this.taxonomy.portals)) {
          const portalCategories = _.reduce(
            _.flatMap(this.taxonomy.portals),
            (accumulator, portalCategory) => {
              if (!_.includes(accumulator, portalCategory)) {
                accumulator.push(portalCategory);
              }
              return accumulator;
            },
            []
          );

          const payload = {
            eventCategoryId: type === 'event' ? id : undefined,
            blogCategoryId: type === 'blog' ? id : undefined,
            portalCategories: _.compact(portalCategories),
          };

          PortalService.mapCategories(payload);
        }

        this.RefreshAuth();
        toastr.success(
          gettextCatalog.getString('The taxonomy has been saved.')
        );

        $rootScope.$emit('RefreshTaxonomies');
        this.close({
          $value: _.extend(
            { id },
            _.pick(this.taxonomy, [
              'type',
              'color',
              'name',
              'location',
              'locationName',
            ])
          ),
        });

        if (!this.isTaxonomyState || !$stateParams.id) {
          if (type === 'blog') {
            Analytics.sendFeatureEvent('create taxonomy blog', {
              module: 'intranet',
            });
          } else {
            Analytics.sendFeatureEvent(`create taxonomy ${type}`, {
              module: 'calendar',
            });
          }
        }

        if (type === 'resource') {
          recoilRefresh(Resources);
        } else {
          recoilRefresh(
            PortalOrganizationEventCategories(cdApp.organization.id)
          );
          recoilRefresh(CategoryByTypeQuery('event'));
          recoilRefresh(CategoryByTypeQuery('blog'));
        }
      },
      (error) => {
        this.processing = false;
        toastr.error(
          _.get(
            error,
            'data.message',
            gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
          )
        );
      }
    );
  }

  onChurchSelect(church) {
    const { _, appUtils } = this;
    const churchLocation = _.get(church, 'location');
    // Only inherit the church location if the resource doesn't have a location.
    if (!this.location) {
      this.location =
        churchLocation && appUtils.buildGooglePlacesObject(churchLocation);
    }
  }

  cancel() {
    this.taxonomy.$undo();
    this.dismiss({ $value: 'cancel' });
  }

  onLocationNameSelected(selectedLocationName) {
    this.taxonomy.locationName = selectedLocationName;
  }

  onAddressSelected(selectedAddress) {
    this.taxonomy.location = selectedAddress;
  }

  isInvalid() {
    return (
      _.filter(
        this.$scope.taxonomies.$error.required,
        (error) => error.$name !== ''
      ).length > 0
    );
  }
}
TaxonomyDetailComponent.$inject = [
  '_',
  '$ngRedux',
  '$rootScope',
  '$state',
  '$stateParams',
  'Resources',
  'Taxonomies',
  'Partnership',
  'Analytics',
  'toastr',
  'gettextCatalog',
  'appUtils',
  'Authorization',
  '$scope',
  'cdApp',
];

angular.module('cdApp.calendar').component('cdTaxonomyDetailModal', {
  template: require('./taxonomy-detail.component.html'),
  controller: TaxonomyDetailComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
    canEditFieldHelper: '&',
    isRequired: '<',
  },
});
