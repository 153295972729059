import _ from 'lodash';

/**
 * A component that shows the diff between a form submission and an existing contact section
 *
 * @prop {Boolean} hasDiff - Whether there's a diff to show. When `false`, the right part of the component that shows the contact section won't be rendered
 * @prop {Object} peopleSectionComponent - The formio component that corresponds to that people section
 * @prop {Object[]} staticFields - The list of static fields (Prefix, Allergies, etc..)
 * @prop {Object[]} staticFieldsOptions - The options of those static fields (Prefix, Allergies, etc..)
 * @prop {Object[]} customFields - The list of custom fields of this organization
 * @prop {Object} data - The form submission
 * @prop {Object} contact - The contact object from the backend, matching the form submission key
 * @prop {Boolean} contact.deleted - Whether the contact has been deleted
 * @prop {Boolean} contact.updating - Whether the contact is being updating/synced
 * @prop {Object[]} contact.contacts - The contacts that are matching the form submission
 * @prop {string} formId - The id of the form
 * @prop {string} submissionId - The id of the form submission
 */
class FormResponsePeopleSectionComponent {
  constructor(
    $http,
    $state,
    $uibModal,
    cdApp,
    toastr,
    gettextCatalog,
    appUtils,
    Authorization,
    cdRedactedValue
  ) {
    'ngInject';

    this.$http = $http;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.cdApp = cdApp;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.appUtils = appUtils;
    this.Authorization = Authorization;
    this.cdRedactedValue = cdRedactedValue;
  }

  $onInit() {
    /**
     * Get the person section associated with this form section.
     */
    if (this.contact && this.hasDiff) {
      const { deleted, updating, contacts } = this.contact;
      if (deleted) {
        this.emptySectionMode = 'DELETED';
      } else if (updating) {
        this.emptySectionMode = 'UPDATING';
      } else if (_.size(contacts) > 1) {
        this.emptySectionMode = 'DUPLICATE';
        this.matchingContacts = contacts;
      } else if (_.size(contacts) === 1) {
        this.person = _.first(contacts);
      }
    }

    this.peopleFields = _(this.peopleSectionComponent.components)
      .reject({ type: 'button' })
      .map((component) => ({
        component,
        key: component.key,
        label: component.label,
        sensitive: _.get(component, 'churchdesk.privacy.sensitive'),
        value: {
          form: this.getValueFromSubmission(component), // Value from submission
          contact: this.getValueFromContact(component), // Value from contact section
        },
        // Consent and NewsletterList fields should be at the bottom of the list and only shown in the form submission panel
        isShown:
          this.person &&
          component.fieldType !== 'consent' &&
          component.fieldType !== 'newsletter',
      }))

      // Format values from rendering and decide whether there's a difference
      .map((peopleField) =>
        _.extend({}, peopleField, {
          formattedValue: {
            form: this.formatSubmissionValue(
              peopleField.value.form,
              peopleField.component
            ),

            contact: this.formatContactValue(
              peopleField.value.contact,
              peopleField.component
            ),
          },

          changed: this.person && this.fieldHasChanged(peopleField),
        })
      )

      // Move consent components that have 'isShown: false' to the bottom of the list
      .orderBy((component) => !component.isShown)
      .value();

    this.hasChanges = _.some(
      this.peopleFields,
      (component) => component.changed
    );
  }

  /**
   * Determine whether that people field's value changed between the form submission and contact
   */
  fieldHasChanged(peopleField) {
    const { cdRedactedValue } = this;

    // Consent and NewsletterList fields shouldn't be taken in consideration when comparing values
    if (
      peopleField.component.fieldType === 'consent' ||
      peopleField.component.fieldType === 'newsletter'
    ) {
      return false;
    }

    // Compare values that come from the "contact" and those that come from the "form submission"
    const contactValue = peopleField.value.contact;
    const formValue = peopleField.value.form;

    // If the value is redacted, do not compare
    if (contactValue === cdRedactedValue || formValue === cdRedactedValue) {
      return false;
    }

    /**
     * When comparing booleans (checkbox), compare that both values are true
     */
    if (_.includes(['checkbox', 'consent'], peopleField.component.fieldType)) {
      return Boolean(contactValue) !== Boolean(formValue);
    }

    /**
     * When comparing arrays (Multiple choice, checkboxes), compare that
     * the same items exist regardless of their order
     */
    if (peopleField.component.fieldType === 'multipleChoice') {
      /**
       * If the value is an object, for example `{ nuts: false, eggs: true, dairy: false, soy: true }`,
       * it needs to be transformed into the array `['eggs', 'sory']` so we could compare properly
       */
      const convertToSortedArray = (submission) => {
        if (_.isPlainObject(submission)) {
          return _(submission).pickBy().keys().sortBy().value();
        }
        if (_.isArray(submission)) return _.sortBy(submission);
        return submission;
      };

      return !_.isEqual(
        convertToSortedArray(contactValue),
        convertToSortedArray(formValue)
      );
    }

    // If comparing an empty string, default to null
    return (
      ((_.toString(contactValue) || '')?.toLowerCase() || null) !==
      ((_.toString(formValue) || '')?.toLowerCase() || null)
    );
  }

  /**
   * Extract the component type
   */
  getComponentType(component) {
    const componentType = _.get(
      component,
      'churchdesk.people.componentType'
    ).toLowerCase();

    /**
     * If the component type doesn't match the key in the contact object.
     * For example: A contact's "job" is being returned as "occupation" in the contact object, etc..
     */
    const mapping = {
      job: 'occupation',
      mobilephone: 'phone',
      address: 'street',
    };

    return _.get(mapping, componentType) || componentType;
  }

  /**
   * Get the value from the form submission that's passed to the component as `data`
   */
  getValueFromSubmission(component) {
    return _.get(this.data, component.key);
  }

  /**
   * Get the value from the `person` object returned from the backend.
   * Tries to match keys by lowercasing them, `firstname` <=> `firstName`
   */
  getValueFromContact(component) {
    const componentType = this.getComponentType(component);
    const componentKey = componentType.toLowerCase();

    const submission = _(this.person)
      .mapKeys((value, key) => key.toLowerCase())
      .get(componentKey);

    return submission;
  }

  /**
   * Determine whether a contact field is sensitive.
   *
   * Backend returns static fields in the structure:
   *   {
   *     allergy: { name: "Allergies and intolerances", locked: false, sensitive: true }
   *     birthday: { name: "Birthday", locked: false, sensitive: false }
   *   }
   * We need to access this object by key, based on the component type and get the `sensitive` flag value.
   */
  isSensitivePeopleField(component) {
    const componentType = this.getComponentType(component);

    /**
     * Checking if a static field is sensitive:
     *
     * Since backend returns static fields in the structure:
     * {
     *   allergy: { name: "Allergies and intolerances", locked: false, sensitive: true }
     *   birthday: { name: "Birthday", locked: false, sensitive: false }
     * }
     * We need to access this object by key, based on the component type and get the `sensitive` flag value.
     */
    const staticFieldsObject = _.mapKeys(this.staticFields, (value, key) =>
      key.toLowerCase()
    );

    const isStaticField = _.has(staticFieldsObject, componentType);
    if (isStaticField) {
      return _.get(staticFieldsObject, [componentType, 'sensitive']);
    }

    // Otherwise it's a custom field, we just need to find it in the customFields components
    return (
      _.chain(this.customFields.components)
        // Flatten components
        .flatMap((c) => c.components || c)
        // Find the component by type
        .find({
          key: component.churchdesk.people.componentType,
        })
        .get('churchdesk.privacy.sensitive')
        .value() === true
    );
  }

  /**
   * Determine whether a form component is sensitive.
   */
  isSensitiveFormFields(component) {
    return _.get(component, 'churchdesk.privacy.sensitive') === true;
  }

  /**
   * Determine whether a form component is a phone number.
   */
  isPhoneNumber(peopleField) {
    return _.get(peopleField, 'component.fieldType') === 'phone';
  }

  /**
   * The value that will be shown at the left panel (form submission)
   */
  formatSubmissionValue(value, component) {
    const { appUtils } = this;
    return appUtils.formatSubmission(value, component) || '-';
  }

  /**
   * The value that will be shown at the right panel (contact)
   */
  formatContactValue(value, component) {
    const { Authorization } = this;

    const componentType = this.getComponentType(component);

    const isSensitive = this.isSensitivePeopleField(component);

    if (
      isSensitive &&
      !Authorization.hasPermission('canViewPeopleSensitiveData')
    ) {
      return value || '-';
    }

    /**
     * Format static fields (Allergies, Prefix, etc...)
     */
    const isStaticField = _.has(this.staticFieldsOptions, componentType);
    if (isStaticField) {
      // Get options for that component type
      const staticFieldOptions = _.get(this.staticFieldsOptions, componentType);

      if (_.isArray(value)) {
        return _(value)
          .map((option) => _.get(staticFieldOptions, option))
          .sortBy()
          .join(', ');
      }

      return _.get(staticFieldOptions, value);
    }

    /**
     * Format Basic fields (firstName, lastName, email) and any other custom fields
     */
    return this.appUtils.formatSubmission(value, component) || '-';
  }

  /**
   * Renders a link to a contact that matches a form submission
   */
  getLinkToContact(contact) {
    const { gettextCatalog, $state } = this;
    const link = $state.href('app.private.people.contacts.view', {
      id: contact.id,
    });

    return `
      <a href="${link}">
        <i class="fa fa-fw fa-user-circle-o text-info u-ml-5"></i>
        ${gettextCatalog.getString('Matches {{contact}}', {
          contact: contact.fullName,
        })}
      </a>
    `;
  }

  /**
   * Gets which contact
   */
  getMatchingConflict(peopleFieldKey, formSubmissionValue) {
    if (!this.matchingContacts) return null;

    // Matching by email
    if (peopleFieldKey === 'peopleEmail' && !_.isEmpty(formSubmissionValue)) {
      const matchingContact = _.find(this.matchingContacts, {
        email: formSubmissionValue,
      });

      if (matchingContact) {
        return this.getLinkToContact(matchingContact);
      }
    }

    // Matching by phone
    if (
      peopleFieldKey === 'peopleMobilePhone' &&
      !_.isEmpty(formSubmissionValue)
    ) {
      const matchingContact = _.find(this.matchingContacts, {
        phone: formSubmissionValue,
      });

      if (matchingContact) {
        return this.getLinkToContact(matchingContact);
      }
    }
  }

  /**
   * When the user clicks on the 'overwrite contact' button
   */
  overwriteContact() {
    const { $http, $state, $uibModal, cdApp, toastr, gettextCatalog } = this;

    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () =>
            gettextCatalog.getString('Overwrite contact information'),
          body: () =>
            gettextCatalog.getString(
              'Are you sure you want to update the profile with this new information?'
            ),

          options: {
            confirmButtonText: gettextCatalog.getString('Overwrite'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'success',
          },
        },
      })
      .result.then(() => {
        this.isLoading = true;
        $http
          .post(
            `${cdApp.config.api.forms}/forms/${this.formId}/submissions/${this.submissionId}/overwrite`,
            {},
            {
              params: {
                organizationId: cdApp.organization.id,
                personId: this.person.id,
                personComponentKey: this.peopleSectionComponent.key,
              },
            }
          )
          .then(() => {
            toastr.success(
              gettextCatalog.getString('Contact overrwritten successfully.')
            );

            $state.reload();
          })
          .catch((error) => {
            toastr.error(
              _.get(error, 'data.message') ||
                gettextCatalog.getString(
                  "Couldn't overrwrite the contact. Please try again."
                )
            );
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
  }
}
FormResponsePeopleSectionComponent.$inject = [
  '$http',
  '$state',
  '$uibModal',
  'cdApp',
  'toastr',
  'gettextCatalog',
  'appUtils',
  'Authorization',
  'cdRedactedValue',
];

angular
  .module('cdApp.forms')
  .component('cdFormResponsePeopleSection', {
    template: require('./form-response-people-section.component.html'),
    bindings: {
      hasDiff: '<',
      peopleSectionComponent: '<',
      staticFields: '<',
      staticFieldsOptions: '<',
      customFields: '<',
      data: '<',
      contact: '<',
      formId: '<',
      submissionId: '<',
    },

    controller: FormResponsePeopleSectionComponent,
  })
  .component('cdFormResponsePeopleSectionSensitiveIcon', {
    template: `
      <i class="far fa-fw fa-lock-alt"
         uib-tooltip="{{'Responses in this column can only be viewed by users with permission to sensitive information.' | translate}}"
         tooltip-append-to-body="true">
      </i>
    `,
  });
