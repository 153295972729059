(function () {
  'use strict';

  angular.module('cdApp.forms').component('formConflictsModal', {
    templateUrl:
      '@/app/forms/shared/components/form-conflicts-modal/form-conflicts-modal.component.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    },
  });
})();
