import React from 'react';
import { Button } from 'antd';

export const SafeguardingFileUploadButton = ({
  buttonText,
  buttonHandler,
}: {
  buttonText: string;
  buttonHandler: () => void;
}) => (
  <Button
    style={{ marginBottom: '24px' }}
    type="primary"
    onClick={buttonHandler}
  >
    {buttonText}
  </Button>
);
