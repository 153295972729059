import { IdentifiableEntity } from './commons';
import { PaymentMethodTypes } from './intention';

export enum FoundationTypes {
  LEGATE = 'legate',
  STIFTUNGEN = 'stiftungen',
}

export enum FoundationStatusTypes {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export interface FieldPermission {
  canEdit: boolean;
  canView: boolean;
}
export interface Foundation {
  id: string;
  referenceNumber: number;
  type: FoundationTypes;
  preferredDate: Date;
  preferredNote: string;
  intentionsPerYear: number;
  totalYears: number;
  feeId: string;
  intentionText: string;
  internalNote: string;
  paymentMethod: PaymentMethodTypes;
  startBalance: number;
  remainingBalance: number;
  paid: boolean;
  paidAt: Date;
  acceptedAt: Date;
  churchId: number;
  church: IdentifiableEntity;
  resourceId: number;
  resource: IdentifiableEntity;
  startYear: number;
  status: FoundationStatusTypes;
  personId: number;
  person: IdentifiableEntity;
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canRevert: boolean;
  canActivate: boolean;
  canDeactivate: boolean;
  canComplete: boolean;
  fields: Map<string, FieldPermission>;
  createdById: number;
  updatedById: number;
  activatedAt: Date;
  resolvedAt: Date;
  activatedById: number;
  organizationId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  formattedReferenceNumber: string;
  intentionsCount: number;
  founder: string;
}

export enum FoundationFields {
  preferredDate = 'preferredDate',
  preferredNote = 'preferredNote',
  intentionText = 'intentionText',
  internalNote = 'internalNote',
  totalYears = 'totalYears',
  feeId = 'feeId',
  startBalance = 'startBalance',
  resourceId = 'resourceId',
  startYear = 'startYear',
  remainingBalance = 'remainingBalance',
  referenceNumber = 'referenceNumber',
  churchId = 'churchId',
  intentionsPerYear = 'intentionsPerYear',
  paymentMethod = 'paymentMethod',
  paid = 'paid',
  paidAt = 'paidAt',
  personId = 'personId',
  founder = 'founder',
}
