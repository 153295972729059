import { useCallback, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import NotificationService from '@/react/services/NotificationService';

export const PAGES = {
  WIDGET_TYPE_SELECTION: 'widgetTypeSelection',
  WIDGET_DETAILS_FORM: 'widgetDetailsForm',
};

export const useWidgetDrawer = ({ widgetData, onSaveWidget }) => {
  const modal = useModal('WidgetDrawer');
  const [widgetForm] = Form.useForm();
  const [page, setPage] = useState(() => {
    if (widgetData?.id) return PAGES.WIDGET_DETAILS_FORM;

    return PAGES.WIDGET_TYPE_SELECTION;
  });
  const [widgetType, setWidgetType] = useState(widgetData?.type);

  const onClose = useCallback(() => {
    modal.hide();
  }, [modal]);

  const onNextClick = useCallback(() => {
    setPage(PAGES.WIDGET_DETAILS_FORM);
  }, []);

  const onOkClick = useCallback(async () => {
    const id = widgetData?.id;
    await widgetForm.validateFields().catch((err) => {
      if (!isEmpty(err.errorFields)) {
        err.errorFields?.forEach((field) => {
          field.errors?.forEach((error) => {
            NotificationService.notifyError(error);
          });
        });
      }
      throw err;
    });
    await onSaveWidget({ id, widgetType, data: widgetForm.getFieldsValue() });
    onClose();
  }, [onClose, onSaveWidget, widgetData, widgetForm, widgetType]);
  const widgetDataForEvent = {
    ...widgetData,
    configuration: {
      ...widgetData?.configuration,
      timeRange:
        typeof widgetData?.configuration?.timeRange === 'object' &&
        widgetData?.configuration?.timeRange !== null
          ? [
              moment(widgetData?.configuration?.timeRange?.start),
              moment(widgetData?.configuration?.timeRange?.end),
            ]
          : widgetData?.configuration?.timeRange,
    },
  };

  return {
    widgetForm,
    modal,
    onClose,
    onNextClick,
    widgetType,
    setWidgetType,
    page,
    onOkClick,
    widgetDataForEvent,
  };
};
