(function () {
  'use strict';
  unsafe.$inject = ['$sce'];

  function unsafe($sce) {
    return function (val) {
      return $sce.trustAsHtml(val);
    };
  }

  angular.module('cdApp.shared').filter('unsafe', unsafe);
})();
