import { Pagination } from 'antd';
import React from 'react';

import { CdTableData } from './types';

export interface PaginationWrapperProps {
  pageNumber: number;
  pageSize: number;
  data: CdTableData<any>;
  handlePaginationChange: (
    pageNumber: number | string,
    pageSize: number
  ) => void;
  OverridePagination?: React.ComponentType<any>;
  isLoading?: boolean;
}
export const CdPagination = ({
  pageNumber,
  pageSize,
  data,
  handlePaginationChange,
  OverridePagination,
  isLoading,
}: PaginationWrapperProps) => {
  if (OverridePagination) {
    return (
      <OverridePagination
        onChange={handlePaginationChange}
        total={data?.total}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    );
  }

  return (
    <>
      {data?.items?.length > 0 || isLoading ? (
        <Pagination
          current={pageNumber}
          style={{ margin: '8px 0px', float: 'right' }}
          defaultCurrent={1}
          total={data?.total}
          onChange={handlePaginationChange}
          pageSize={pageSize}
          hideOnSinglePage
          showSizeChanger={false}
        />
      ) : null}
    </>
  );
};
