import { InputNumber, InputNumberProps } from 'antd';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '@/react/services/I18nService';
import { getDecimalSeparator } from '@/react/shared/utils';

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
`;

// eslint-disable-next-line react/display-name
export const TimeRegistrationHoursInput = forwardRef(
  (props: InputNumberProps, ref: React.Ref<HTMLInputElement>) => (
    <StyledInputNumber
      ref={ref}
      style={{
        width: 150,
        height: 40,
        borderRadius: 8,
        fontSize: 16,
        textAlign: 'right',
      }}
      data-testid="time-registration-hours-input"
      addonAfter={gettextCatalog.getString('hours')}
      precision={2}
      step={0.01}
      decimalSeparator={getDecimalSeparator()}
      controls={false}
      {...props}
    />
  )
);
