'use strict';

function People($$resource, _, moment) {
  'ngInject';

  const baseUrl = `${cdApp.config.api.main}/people`;

  return $$resource(`${baseUrl}/people/:id`, null, {
    update: {
      method: 'PUT',
      transformRequest(_data) {
        const data = angular.copy(_data);

        // Send birthday as YYYY-MM-DD to match the backend's validation
        if (data.birthday) {
          _.set(data, 'birthday', moment(data.birthday).format('YYYY-MM-DD'));
        }

        // Clearing specific properties if empty string, to match the backend's validation
        _.each(['email', 'phone', 'workPhone', 'homePhone'], (path) => {
          _.update(data, path, (value) => {
            if (_.isUndefined(value)) return value;
            return value || null;
          });
        });

        // Omit the sensitive fields from the payload that's sent to the backend
        _(data.fields)
          .pickBy((field) => !field.canEdit)
          .each((field, key) => {
            _.unset(data, key);
          });

        // Omit the fields object
        _.unset(data, 'fields');

        return angular.toJson(data);
      },
    },

    multiTag: {
      method: 'PUT',
      url: `${baseUrl}/people/tags`,
    },

    multiChurch: {
      method: 'PUT',
      url: `${baseUrl}/people/churches`,
    },

    multiDelete: {
      method: 'POST',
      url: `${baseUrl}/people/delete`,
    },

    getFilters: {
      method: 'GET',
      url: `${baseUrl}/people/filters`,
      isArray: true,
    },

    searchPeople: {
      method: 'POST',
      url: `${baseUrl}/people/search`,
    },

    getStaticFields: {
      method: 'GET',
      url: `${baseUrl}/people/fields`,
    },

    getStaticFieldsOptions: {
      method: 'GET',
      url: `${baseUrl}/people/static/fields`,
    },

    saveSensitiveFields: {
      method: 'POST',
      url: `${baseUrl}/people/fields/sensitive`,
    },

    getExportToken: {
      method: 'GET',
      url: `${baseUrl}/export/token`,
    },

    getImportAvailableProperties: {
      method: 'GET',
      url: `${baseUrl}/import/available-properties`,
      isArray: true,
    },

    exportPeople: {
      method: 'POST',
      url: `${baseUrl}/export/data`,
    },

    checkExport: {
      method: 'GET',
      url: `${baseUrl}/export/:id/check`,
    },

    addToChurches: {
      method: 'PUT',
      url: `${baseUrl}/people/:id/extend`,
    },

    unsubscribe: {
      method: 'POST',
      url: `${baseUrl}/people/:id/unsubscribe`,
    },

    getContactsSharingEmail: {
      method: 'GET',
      url: `${baseUrl}/people/:id/contacts-sharing-email`,
    },

    reSubscribeToList: {
      method: 'PUT',
      url: `${baseUrl}/people/:id/re-subscribe`,
    },
  });
}
People.$inject = ['$$resource', '_', 'moment'];
angular.module('cdApp.people').factory('People', People);
