class RepeatRuleModalComponent {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.startDate = this.resolve.startDate;
    this.excludedDates = this.resolve.excludedDates;
    this.includedDates = this.resolve.includedDates;
    this.rrule = this.resolve.rrule;
    this.eventId = this.resolve.eventId;
    this.hasRrule = this.resolve.hasRrule;
  }

  done(rrule, exdate, rdate) {
    this.resolve.selectRrule(rrule, exdate, rdate);
    this.close();
  }

  cancel() {
    this.resolve.setSelectedRRuleOption();
    this.close();
  }
}

RepeatRuleModalComponent.$inject = [];
angular.module('cdApp.calendar').component('repeatRuleModalComponent', {
  templateUrl: '@/app/shared/components/repeat/templates/Modal.html',
  controller: RepeatRuleModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
