(function () {
  'use strict';

  /**
   * @desc return a user name
   * @example <span>{{ $ctrl.user | getName }}</span>
   */ getName.$inject = ['_', 'gettextCatalog'];

  function getName(_, gettextCatalog) {
    return function (user, firstOnly) {
      if (!user) return gettextCatalog.getString('Anonymous');
      if (firstOnly) {
        return user.firstName || _.get(user, 'contact.firstName') || user.email;
      }

      if (user.contact && _.trim(user.contact.fullName)) {
        return user.contact.fullName;
      } else if (_.trim(user.name)) {
        return user.name;
      } else if (_.trim(user.fullName)) {
        return user.fullName;
      } else {
        return _.trim(user.email)
          ? user.email
          : gettextCatalog.getString('Unknown');
      }
    };
  }

  angular.module('cdApp.shared').filter('getName', getName);
})();
