import React, { useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Button, Card, Layout, Modal, Space, Typography } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';

import { gettextCatalog } from '../../services/I18nService';
import cdApp from '../../config';
import { CdCopyIcon } from '../../shared/components/Icons';

import { WidgetInterface } from './types/widget.type';

export const WidgetPreviewAndGetCodeModal = NiceModal.create(
  ({ widgetData }: { widgetData: WidgetInterface }) => {
    const { id, type, organizationId } = widgetData;
    const modal = useModal('WidgetPreviewAndGetCodeModal');
    const [height, setHeight] = useState('100px');
    const close = modal.remove;
    const baseUrl = cdApp.references.websiteWidgetsUrl;
    const locale = cdApp.organization.locale.language;
    const iframeUrl = `${baseUrl}/${locale}/w/${organizationId}/${type}/${id}/1/-/-/-/-/-/?frameId=${id}`;
    const [buttonText, setButtonText] = useState(
      gettextCatalog.getString('Copy code')
    );
    const altarId = cdApp.organization.id;
    // data-churchdesk-user-embed=true ensures that portal widget knows not to override the organzation id on a Mono website.
    const codeTemplate = `<div data-churchdesk-widget-id="${id}" data-churchdesk-organization-id="${altarId}" data-churchdesk-user-embed=true data-churchdesk-widget-type="${type}"></div><script src="${baseUrl}/widget-loader.js"></script>`;

    window.addEventListener('message', (e) => {
      const data = e && e.data;
      const action = data && data.action;
      const frameId = data && data.frameId;
      if (action === 'churchdeskAdjustHeight' && frameId === id) {
        setHeight(`${Math.max(data.height, 100)}px`);
      }
    });

    return (
      <Space>
        <Modal
          {...{
            ...antdModalV5(modal),
            width: 1000,
            title: gettextCatalog.getString(`Widget preview`),
            bodyStyle: { padding: 0 },
            onCancel: close,
            cancelText: gettextCatalog.getString('Close'),
            okButtonProps: { style: { display: 'none' } },
          }}
        >
          <Space
            direction="vertical"
            size={0}
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Layout style={{ padding: '24px' }}>
              <Card bodyStyle={{ padding: '16px' }}>
                <iframe
                  src={iframeUrl}
                  style={{
                    width: '100%',
                    height: `${height}`,
                    border: '0px',
                  }}
                ></iframe>
              </Card>
            </Layout>
            <Space
              direction="vertical"
              size="middle"
              style={{ margin: '16px 32px' }}
            >
              <Typography.Title level={5}>
                {gettextCatalog.getString('Copy code')}
              </Typography.Title>
              <Typography.Text style={{ marginBottom: '32px !important' }}>
                {gettextCatalog.getString(
                  'Insert the widget on your website by copying this code and pasting it into the HTML of your site.'
                )}
              </Typography.Text>
              <Typography.Paragraph code>{codeTemplate}</Typography.Paragraph>
              <CopyToClipboard
                text={codeTemplate}
                onCopy={() => {
                  setButtonText(gettextCatalog.getString('Copied'));
                }}
              >
                <Button>
                  <CdCopyIcon />
                  {buttonText}
                </Button>
              </CopyToClipboard>
            </Space>
          </Space>
        </Modal>
      </Space>
    );
  }
);
