import { Button, Space } from 'antd';
import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { showModal } from 'redux-saga-modal';
import { upperCase } from 'lodash';

import MODAL_TYPES from '../../redux/modal-types';
import { gettextCatalog } from '../../../services/I18nService';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const AssignUserRolesModalLauncher: FunctionComponent<{
  userId: number;
  userName: string;
  noOfRoles: number;
  ghost: boolean;
}> = ({ userId, userName, noOfRoles, ghost = false }) => {
  const dispatch = useDispatch();

  const launchModal = () => {
    dispatch(showModal(MODAL_TYPES.USER_ROLES, { userId, userName }));
  };

  if (ghost) {
    return (
      <CdTooltip title={gettextCatalog.getString('Click to view permissions')}>
        <div onClick={launchModal} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faUserLock} />
          <div style={{ width: '80%', margin: '0 auto' }}>
            {upperCase(gettextCatalog.getString('Edit permissions'))}
          </div>
        </div>
      </CdTooltip>
    );
  }

  return (
    <Button onClick={launchModal}>
      <Space>
        <FontAwesomeIcon icon={faUserLock} />
        {noOfRoles}
      </Space>
    </Button>
  );
};

const AssignUserRolesModalLauncherRoot: FunctionComponent<{
  userId: number;
  userName: string;
  noOfRoles: number;
  ghost: false;
}> = ({ userId, userName, noOfRoles, ghost }) => (
  <AssignUserRolesModalLauncher
    userId={userId}
    userName={userName}
    noOfRoles={noOfRoles}
    key={`user-launch-modal-${userId}`}
    ghost={ghost}
  />
);
export default AssignUserRolesModalLauncherRoot;
