'use strict';

class UpdatePersonTagsModalController {
  constructor(_, PeopleTags, Analytics) {
    'ngInject';

    this._ = _;
    this.PeopleTags = PeopleTags;
    this.Analytics = Analytics;
  }

  $onInit() {
    this.person = this.resolve.person;
    this.tags = this.resolve.tags;
  }

  toggleTag(tag) {
    if (this._.some(this.person.tags, { id: tag.id })) {
      this.person.tags = this._.reject(this.person.tags, { id: tag.id });
    } else {
      this.person.tags.push(tag);
    }
  }

  hasTag(tag) {
    return this._.some(this.person.tags, { id: tag.id });
  }

  createTag(input) {
    this.tags.$add(
      new this.PeopleTags({
        name: input.$viewValue,
      }),

      (newTag) => {
        this.person.tags.push(newTag);
        this.Analytics.sendFeatureEvent('create tags', { module: 'people' });
      }
    );

    input.$setPristine();
    input.$setUntouched();
    input.$setViewValue();
    input.$render();
  }

  confirm() {
    this.close({ $value: this.person.tags });
  }
}
UpdatePersonTagsModalController.$inject = ['_', 'PeopleTags', 'Analytics'];

angular.module('cdApp.shared').component('cdUpdatePersonTagsModal', {
  templateUrl:
    '@/app/shared/components/update-person-tags-modal/update-person-tags-modal.component.html',
  controller: UpdatePersonTagsModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
