import { get, pick } from 'lodash';

import { mainApi } from '../../api';
import { RoleInterface } from '../types/organization-role.types';
import { buildErrorEntityByStatus } from '../../shared/errors';

export enum RoleContext {
  organization = '/roles/organization?version=2',
  church = '/roles/church?version=2',
}

class RolesService {
  public getAll = async (context: RoleContext) => {
    const res = await mainApi.get(context.toString());
    if (res.ok) return res.data as RoleInterface[];
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public get = async (payload: RoleInterface) => {
    const res = await mainApi.get(`/roles/${payload.context}/${payload.id}`);
    if (res.ok) return res.data as RoleInterface;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public create = async (payload: RoleInterface) => {
    const res = await mainApi.post(
      `/roles/${payload.context}`,
      pick(payload, ['name'])
    );
    if (res.ok) return res.data as RoleInterface;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public update = async (payload: RoleInterface) => {
    const body = {
      name: payload.name,
      description: payload.description,
      permissions: payload.permissions,
      isEnabled: payload.isEnabled,
    };
    const res = await mainApi.put(
      `/roles/${payload.context}/${payload.id}`,
      body
    );
    if (res.ok) return res.data as RoleInterface;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public delete = async (payload: RoleInterface) => {
    const res = await mainApi.delete(`/roles/${payload.context}/${payload.id}`);
    if (res.ok) return res.data;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };

  public copyRole = async (roleId: string): Promise<RoleInterface> => {
    const res = await mainApi.post(`/roles/${roleId}/copy`);
    if (res.ok) return res.data as RoleInterface;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  };
}
export default new RolesService();
