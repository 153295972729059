'use strict';

function AttendanceModel($$resource, cdApp, _) {
  'ngInject';

  const baseUrl = `${cdApp.config.api.main}/attendance`;

  return $$resource(
    `${baseUrl}/calendar/events/:eventId`,
    {},
    {
      getCategories: {
        method: 'GET',
        url: `${baseUrl}/categories`,
        isArray: true,
        transformResponse(responseData) {
          if (responseData === 'null') {
            return responseData;
          }

          responseData = JSON.parse(responseData);

          // Get the list of categories that have children, so we can ignore them,
          // because you shouldn't be able to select a top-level category directly
          const topLevelCategories = _(responseData)
            .map('parentCategoryId')
            .uniq()
            .compact()
            .value();

          // Format and nest the categories so they're displayed correctly in groups
          return _(responseData)
            .map((category) => {
              const outputCategory = {
                id: category.id,
                name: category.name,
              };

              if (category.parentCategoryId) {
                const parentCategory = _.find(responseData, {
                  id: category.parentCategoryId,
                });

                outputCategory.group = parentCategory.name;
              }

              return outputCategory;
            })
            .reject((category) => _.includes(topLevelCategories, category.id))
            .value();
        },
      },

      getFields: {
        method: 'GET',
        url: `${baseUrl}/fields`,
        isArray: true,
      },

      getEvents: {
        method: 'GET',
        url: `${baseUrl}/events`,
      },

      updateEvent: {
        method: 'PUT',
        url: `${baseUrl}/events/:eventId`,
      },

      updateAmount: {
        method: 'PUT',
        url: `${baseUrl}/events/:eventId/amount/:attendanceFieldId`,
      },

      getStarted: {
        method: 'PUT',
        url: `${baseUrl}/get-started`,
      },

      importFromSognDk: {
        method: 'PUT',
        url: `${baseUrl}/import/sogndk`,
      },

      getExportToken: {
        method: 'GET',
        url: `${baseUrl}/export/token`,
      },

      getTotal: {
        method: 'GET',
        url: `${baseUrl}/total`,
      },

      getInsights: {
        method: 'GET',
        url: `${baseUrl}/insights`,
      },
    }
  );
}
AttendanceModel.$inject = ['$$resource', 'cdApp', '_'];
angular.module('cdApp.calendar').factory('Attendance', AttendanceModel);
