import { selectorFamily } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetFile = selectorFamily({
  key: 'GetFile',
  get: (fileId: number) => async () => {
    if (!fileId) return;
    const response = await mainApi.get(`/files/${fileId}`);

    if (!response.ok) {
      NotificationService.notifyError((response as any)?.data?.message);
      throw response;
    }
    return response.data;
  },
});

/**
 * Custom hook for retrieving file system permissions.
 * @returns An object containing the file permissions query data and loading status.
 */
export const useGetFilePermissionsQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['useGetFilePermissionsQuery'],
    queryFn: async () => {
      const response = await mainApi.get<{
        'change visibility': boolean;
        'create file': boolean;
        'create folder': boolean;
        'create gallery': boolean;
      }>('/filesystem/permissions');
      if (response.ok) {
        return response.data;
      } else {
        handleError((response as any)?.data?.message);
        throw response;
      }
    },
  });

  return {
    filePermissionsQuery: data,
    filePermissionsQueryIsLoading: isLoading,
  };
};
