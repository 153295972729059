export const separateRecipientSelection = (
  value: string[]
): { personIds: number[]; segmentIds: number[] } =>
  (value || []).reduce(
    (collection, element) => {
      if (element?.includes('person-')) {
        collection.personIds.push(parseInt(element.replace('person-', ''), 10));
      }
      if (element?.includes('segment-')) {
        collection.segmentIds.push(
          parseInt(element.replace('segment-', ''), 10)
        );
      }
      return collection;
    },
    { personIds: [], segmentIds: [] }
  );
