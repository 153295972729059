class FacebookService {
  constructor(_, $q, $window, $http, toastr, gettextCatalog, $uibModal, cdApp) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$window = $window;
    this.$http = $http;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.$uibModal = $uibModal;
    this.cdApp = cdApp;

    /**
     * Required permissions for managing a Facebook page
     * @see https://developers.facebook.com/docs/pages/access-tokens
     */
    this.scope = [
      'pages_show_list',
      'manage_pages',
      'publish_pages',
      'pages_read_engagement',
      'pages_manage_posts',
    ].join(',');

    this.userId = null;
  }

  hasLoadedSDK() {
    const { _, $window } = this;
    return !_.isEmpty($window.FB);
  }

  /**
   * Load the Facebook SDK
   */
  init() {
    const { $window } = this;

    return this.$q((resolve, reject) => {
      if ($window.FB) {
        return resolve();
      }

      /**
       * After the Facebook SDK is loaded
       */
      $window.fbAsyncInit = () => {
        try {
          $window.FB.init({
            appId: '236012810341221',
            status: true,
            cookie: true,
            xfbml: true,
            version: 'v3.0',
          });

          return resolve();
        } catch (error) {
          return reject(error);
        }
      };

      // load the Facebook javascript SDK
      (function (d, s, id) {
        let js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  }

  getLoginStatus() {
    return this.$q((resolve, reject) => {
      this.$window.FB.getLoginStatus(({ authResponse, status }) => {
        switch (status) {
          case 'connected': // The user is logged in and has authenticated the ChurchDesk app
            this.userId = authResponse.userID;
            this.accessToken = authResponse.accessToken;
            return resolve(authResponse);
          case 'authorization_expired': // The access token has expired
          case 'not_authorized': // The user hasn't authorized ChurchDesk
          default:
            // The user isn't logged in to Facebook
            this.userId = null;
            this.accessToken = null;
            return reject();
        }
      });
    });
  }

  login() {
    return this.$q((resolve, reject) => {
      this.$window.FB.login(
        ({ status, authResponse }) => {
          if (status === 'connected' && authResponse) {
            this.getLoginStatus().then(resolve).catch(reject);
          } else {
            // User cancelled login or did not fully authorize
            reject();
          }
        },
        {
          scope: this.scope,
        }
      );
    });
  }

  /**
   * Get a list of the facebook pages the user is an admin of
   */
  getPages() {
    return this.$q((resolve, reject) => {
      this.$window.FB.api(
        `${this.userId}/accounts`,
        'GET',
        { fields: 'id,name,picture' },
        (response) => {
          if (response.error) {
            return reject(response.error);
          }
          const pages = response.data;
          return resolve(pages);
        }
      );
    });
  }

  /**
   * Authenticate with Facebook
   */
  authenticate() {
    const { _, $uibModal, $http, toastr, gettextCatalog, cdApp } = this;

    return this.$q((resolve, reject) => {
      const organizationId = cdApp.organization.id;

      this.init()
        .then(() =>
          // Login to Facebook modal
          $uibModal
            .open({
              component: 'cdSimpleModal',
              resolve: {
                title: () =>
                  gettextCatalog.getString('Please connect to Facebook'),
                body: () =>
                  gettextCatalog.getString(
                    'To share your event to your Facebook Page, you need to connect it to ChurchDesk.'
                  ),

                options: {
                  confirmButtonText: gettextCatalog.getString('Connect'),
                  confirmButtonType: 'primary',
                },
              },
            })
            .result.then(() => {
              this.login()
                .then((authResponse) => {
                  const fbUserId = authResponse.userID;
                  const fbAccessToken = authResponse.accessToken;

                  this.getPages()
                    .then((pages) => {
                      if (_.isEmpty(pages)) {
                        toastr.error(
                          gettextCatalog.getString(
                            'This account is not an admin of any Facebook pages.'
                          )
                        );

                        return reject();
                      }

                      // Have the user select a Facebook page
                      return $uibModal
                        .open({
                          component: 'cdSelectFacebookPage',
                          resolve: {
                            pages: () => pages,
                          },
                        })
                        .result.then((fbPageId) => {
                          $http
                            .post(
                              `${cdApp.config.api.main}/church/settings?organizationId=${organizationId}`,
                              {
                                facebook: {
                                  fbAccessToken,
                                  fbUserId,
                                  fbPageId,
                                },
                              }
                            )
                            .then(() => {
                              toastr.success(
                                gettextCatalog.getString(
                                  "You've now connected your facebook page to ChurchDesk"
                                )
                              );

                              return resolve();
                            })
                            .catch((error) => {
                              toastr.error(
                                _.get(error, 'data.message') ||
                                  gettextCatalog.getString(
                                    'An error occurred, please try again. If the problem persists, please contact our support.'
                                  )
                              );

                              return reject();
                            });
                        })
                        .catch(() => {
                          toastr.error(
                            gettextCatalog.getString(
                              'You need to allow ChurchDesk to manage your facebook page.'
                            )
                          );

                          return reject();
                        });
                    })
                    .catch(() => {
                      toastr.error(
                        gettextCatalog.getString(
                          'An error occurred, please try again. If the problem persists, please contact our support.'
                        )
                      );

                      return reject();
                    });
                })
                .catch(() => {
                  toastr.error(
                    gettextCatalog.getString(
                      'You need to allow ChurchDesk to manage your facebook page.'
                    )
                  );

                  return reject();
                });
            })
        )
        .catch(reject);
    });
  }
}

FacebookService.$inject = [
  '_',
  '$q',
  '$window',
  '$http',
  'toastr',
  'gettextCatalog',
  '$uibModal',
  'cdApp',
];

angular.module('cdApp.shared').service('facebookService', FacebookService);
