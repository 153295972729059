'use strict';

function MultiChurchingController(
  $scope,
  $uibModal,
  $filter,
  People,
  gettextCatalog,
  toastr
) {
  let $ctrl = this;

  /**
   * Lifecycle hook used for initialization work
   */
  $ctrl.$onInit = function () {
    $ctrl.busy = false;
    $ctrl.isOpen = $ctrl.isOpen || false;

    resetScheduledChurches();
  };

  /**
   * Lifecycle hook user for watching for property changes
   */
  $ctrl.$onChanges = function (changesObj) {
    if (
      changesObj.selectedPeople &&
      !changesObj.selectedPeople.isFirstChange() &&
      changesObj.selectedPeople.currentValue
    ) {
      $ctrl.updateChurchList();
    }
  };

  /**
   * Close the dropdown
   *
   * @param {Object} $event - The original click event
   */
  $ctrl.closeDropdown = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    resetScheduledChurches();
    $ctrl.isOpen = false;
  };

  /**
   * Called when the dropdown is being closed
   *
   * @param {Boolean} open - Whether the dropdown is opened or closed
   */
  $ctrl.onDropdownToggle = function (open) {
    if (open) {
      $ctrl.updateChurchList();
    } else {
      if (
        _.size($ctrl.scheduledChurches.toAdd) > 0 ||
        _.size($ctrl.scheduledChurches.toRemove) > 0
      ) {
        resetScheduledChurches();
      }
    }
  };

  /**
   *  Refresh the list of churches by applying filtering and ordering and figuring out its checked state
   */
  $ctrl.updateChurchList = function () {
    $ctrl.filteredChurches = angular.copy($ctrl.churches);
    $ctrl.scheduledChurches.indeterminate = [];

    _.forEach($ctrl.filteredChurches, function (church) {
      // A church is checked if it exists in the scheduledChurches.toAdd array
      let churchIsInScheduledChurches =
        $ctrl.scheduledChurches &&
        _.includes($ctrl.scheduledChurches.toAdd, church.id);

      if ($ctrl.isGlobalSelect) {
        // Don't process whether it should be checked or not. All churches are indeterminate when isGlobalSelect=true
        $ctrl.scheduledChurches.indeterminate.push(church.id);
        church.indeterminate = true;
        return;
      }

      // A church is also checked if all the selected people have it in their churches array
      let churchBelongsToAllSelectedPeople =
        _.size($ctrl.selectedPeople) &&
        _.every($ctrl.selectedPeople, function (selectedPerson) {
          const churchesField = _.find(selectedPerson.fields, {
            property: 'churches',
          });

          if (!churchesField) return false;
          return _.includes(_.map(churchesField.value, 'value'), church.id);
        });

      let churchBelongsToSomeSelectedPeople =
        _.size($ctrl.selectedPeople) &&
        _.some($ctrl.selectedPeople, function (selectedPerson) {
          const churchesField = _.find(selectedPerson.fields, {
            property: 'churches',
          });

          if (!churchesField) return false;
          return _.includes(_.map(churchesField.value, 'value'), church.id);
        });
      if (
        churchBelongsToSomeSelectedPeople &&
        !churchBelongsToAllSelectedPeople
      ) {
        $ctrl.scheduledChurches.indeterminate.push(church.id);
        church.indeterminate = true;
      }
      church.checked =
        churchIsInScheduledChurches || churchBelongsToAllSelectedPeople;
    });

    let searchResults = $filter('filter')($ctrl.filteredChurches, {
      name: $ctrl.search,
    });

    let sorted = $filter('orderBy')(searchResults, 'name');

    $ctrl.filteredChurches = sorted;
  };

  /**
   * Called when a church is selected
   *
   * @param {Object} church - The church object
   * @param {Number} index - The index of the church
   */
  $ctrl.churchClicked = function (church, index) {
    church.checked = !church.checked;
    const id = church.id;

    if (church.checked) {
      if (!_.includes($ctrl.scheduledChurches.toRemove, id)) {
        // schedule the church to be added
        $ctrl.scheduledChurches.toAdd.push(id);
      } else {
        if (_.includes($ctrl.scheduledChurches.indeterminate, id)) {
          $ctrl.scheduledChurches.toAdd.push(id);
        }
        _.pull($ctrl.scheduledChurches.toRemove, id);
      }
    } else {
      if (!_.includes($ctrl.scheduledChurches.toAdd, id)) {
        // schedule the church to be removed
        $ctrl.scheduledChurches.toRemove.push(id);
      } else {
        if (_.includes($ctrl.scheduledChurches.indeterminate, id)) {
          $ctrl.scheduledChurches.toRemove.push(id);
        }
        _.pull($ctrl.scheduledChurches.toAdd, id);
      }
    }

    $ctrl.indexOfActiveItem = index;
  };

  /**
   * Determine whether there are pending churches that need to be applied
   */
  $ctrl.churchesHaveChanged = function () {
    if (!$ctrl.scheduledChurches) return false;
    return (
      _.size($ctrl.scheduledChurches.toAdd) > 0 ||
      _.size($ctrl.scheduledChurches.toRemove) > 0
    );
  };

  /**
   * Apply changes
   */
  $ctrl.applyScheduledChurches = function () {
    updateSelectedPeople();
  };

  /**
   * Discard changes
   */
  $ctrl.discardScheduledChurches = function () {
    resetScheduledChurches();
    $ctrl.isOpen = false;
  };

  // The index of the active item
  $ctrl.indexOfActiveItem = 0;

  /**
   * When the search query changes, and the churches list happens again, ensure that the active index doesn't exceed the length of the array
   */
  $scope.$watch('$ctrl.filteredChurches.length', function (newValue, oldValue) {
    if (
      newValue !== oldValue &&
      newValue !== 0 &&
      newValue < $ctrl.indexOfActiveItem + 1
    ) {
      $ctrl.indexOfActiveItem = newValue - 1;
    }
  });

  /**
   * Handle keyboard navigation
   *
   * @param {Object} $event - The original event object
   */
  $ctrl.keyDown = function ($event) {
    // return/enter key
    if ($event.which === 13) {
      if ($ctrl.selectedPeople.length) {
        $ctrl.churchClicked(
          $ctrl.filteredChurches[$ctrl.indexOfActiveItem],
          $ctrl.indexOfActiveItem
        );
      }
    } else if ($event.which === 38) {
      // up key
      if ($ctrl.selectedPeople.length) {
        $ctrl.indexOfActiveItem =
          $ctrl.indexOfActiveItem === 0
            ? $ctrl.filteredChurches.length - 1
            : $ctrl.indexOfActiveItem - 1;
      }
      $event.preventDefault();
    } else if ($event.which === 40) {
      // down key
      if ($ctrl.selectedPeople.length) {
        $ctrl.indexOfActiveItem =
          $ctrl.indexOfActiveItem === $ctrl.filteredChurches.length - 1
            ? 0
            : $ctrl.indexOfActiveItem + 1;
      }
      $event.preventDefault();
    } else if ($event.which === 27) {
      // Escape key
      $ctrl.discardScheduledChurches();
    }
  };

  /**
   * Reset selected churches
   */
  function resetScheduledChurches() {
    $ctrl.scheduledChurches = {
      toAdd: [],
      toRemove: [],
      indeterminate: [],
    };
  }

  /**
   * Updates the selected people's church with the new churches
   */
  function updateSelectedPeople() {
    const addCount = $ctrl.scheduledChurches.toAdd.length;
    const removeCount = $ctrl.scheduledChurches.toRemove.length;
    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => gettextCatalog.getString('Apply parish changes'),
          body: () =>
            gettextCatalog.getString('Do you want to:') +
            '\n' +
            (addCount > 0
              ? '- ' +
                gettextCatalog.getPlural(
                  addCount,
                  'add 1 parish',
                  'add {{ $count }} parishes',
                  {
                    $count: addCount,
                  }
                ) +
                '\n'
              : '') +
            (removeCount > 0
              ? '- ' +
                gettextCatalog.getPlural(
                  removeCount,
                  'remove 1 parish',
                  'remove {{ $count }} parishes',
                  { $count: removeCount }
                ) +
                '\n'
              : '') +
            (removeCount > 0
              ? '\n\n' +
                gettextCatalog.getString(
                  'Please note that a contact has to have at least one parish.'
                )
              : ''),
          options: {
            confirmButtonText: gettextCatalog.getString('Yes'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'primary',
          },
        },
      })
      .result.then(() => {
        let payload = {};
        $ctrl.busy = true;
        payload.churches = {
          add: $ctrl.scheduledChurches.toAdd,
          remove: $ctrl.scheduledChurches.toRemove,
        };

        if (!$ctrl.isGlobalSelect) {
          payload.people = _.map($ctrl.selectedPeople, 'id');
        } else {
          payload.filter = $ctrl.filter;
        }

        People.multiChurch(
          {},
          payload,
          () => {
            $ctrl.busy = false;
            toastr.success(
              gettextCatalog.getString('Parishes updated successfully.')
            );

            resetScheduledChurches();
            $ctrl.onUpdate();
          },
          () => {
            $ctrl.busy = false;
          }
        );
      });
  }
}
MultiChurchingController.$inject = [
  '$scope',
  '$uibModal',
  '$filter',
  'People',
  'gettextCatalog',
  'toastr',
];

angular.module('cdApp.people').component('cdMultiChurching', {
  transclude: true,
  template: require('./multi-churching.component.html'),
  bindings: {
    isOpen: '=',
    buttonSize: '<',
    isDisabled: '<',
    onUpdate: '&',
    selectedPeople: '<',
    selectedChurches: '<',
    churches: '<',
    filter: '<',
    isGlobalSelect: '<',
  },

  controller: MultiChurchingController,
});
