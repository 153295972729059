class IntranetComponent {
  constructor(_, cdApp) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
  }

  $onInit() {}
}
IntranetComponent.$inject = ['_', 'cdApp'];

angular.module('cdApp.intranet').component('cdIntranetComponent', {
  template: require('./intranet.component.html'),
  controller: IntranetComponent,
  bindings: {
    errorCode: '<',
  },
});
