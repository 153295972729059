import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

const RowContainer = styled(Row)`
  .ant-input[disabled] {
    color: #000;
  }
  .ant-picker-input > input[disabled] {
    color: #000;
  }
`;

export const FieldSection = ({ children }: { children: any }) => (
  <RowContainer>
    <Col flex="1 1 0">{children}</Col>
  </RowContainer>
);
