'use strict';

/**
 * Text input component that supports searching for a page from the ChurchDesk website.
 *
 * @prop {String} initialValue - The initial value of the input field.
 * @prop {Object} form - A reference to the form that will be used for showing validation messages.
 * @prop {Function} onChange - A function that will be called when the value changes. The changed value is passed as `$value`.
 * @prop {String} name - An optional name that will be used for the form component. Default value to 'page'.
 * @prop {Boolean} isRequired - Whether the input field is required.
 *
 * @example <cd-input-search-pages form="$ctrl.form" initial-value="$ctrl.linkToPage" on-change="$ctrl.linkToPage = $value" is-required="true" />
 */
class InputSearchPages {
  constructor(_, $http, cdApp) {
    'ngInject';

    this._ = _;
    this.$http = $http;
    this.cdApp = cdApp;
  }

  $onInit() {
    this.page = this.initialValue;
    this.name = this.name || 'page';
  }

  searchPages(search) {
    if (this._.isEmpty(search)) {
      return [];
    }
    return this.$http
      .get(this.cdApp.config.api.main + '/linkit/pages', {
        params: { search },
      })
      .then((response) => this._.get(response, 'data', []));
  }

  onTypeaheadChange($value) {
    this.onChange({ $value });
  }
}
InputSearchPages.$inject = ['_', '$http', 'cdApp'];

angular.module('cdApp.shared').component('cdInputSearchPages', {
  templateUrl:
    '@/app/shared/components/input-search-pages/input-search-pages.component.html',
  bindings: {
    initialValue: '<',
    form: '<',
    onChange: '&',
    name: '@',
    isRequired: '<',
  },

  controller: InputSearchPages,
});
