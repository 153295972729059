import angular from 'angular';

angular.module('cdApp.forms').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/forms/edit-form-response/edit-form-response.html',
      require('./edit-form-response/edit-form-response.html')
    );

    $templateCache.put(
      '@/app/forms/form-builder/form-builder.html',
      require('./form-builder/form-builder.html')
    );

    $templateCache.put(
      '@/app/forms/form-builder/partials/builder-step.html',
      require('./form-builder/partials/builder-step.html')
    );

    $templateCache.put(
      '@/app/forms/form-builder/partials/settings-step.html',
      require('./form-builder/partials/settings-step.html')
    );

    $templateCache.put(
      '@/app/forms/forms.component.html',
      require('./forms.component.html')
    );

    $templateCache.put(
      '@/app/forms/shared/components/create-form-modal/create-form-modal.component.html',
      require('./shared/components/create-form-modal/create-form-modal.component.html')
    );

    $templateCache.put(
      '@/app/forms/shared/components/form-conflicts-modal/form-conflicts-modal.component.html',
      require('./shared/components/form-conflicts-modal/form-conflicts-modal.component.html')
    );

    $templateCache.put(
      '@/app/forms/shared/components/form-tax-percentage-modal/form-tax-percentage-modal.component.html',
      require('./shared/components/form-tax-percentage-modal/form-tax-percentage-modal.component.html')
    );

    $templateCache.put(
      '@/app/forms/view-form/modals/form-tickets-details.html',
      require('./view-form/modals/form-tickets-details.html')
    );

    $templateCache.put(
      '@/app/forms/view-form/view-form.html',
      require('./view-form/view-form.html')
    );

    $templateCache.put(
      '@/app/forms/cancel-tickets/cancel-tickets.html',
      require('./cancel-tickets/cancel-tickets.html')
    );
  },
]);
