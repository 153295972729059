import { mainApi } from '../../api';

export interface Holiday {
  date: Date;
  title: string;
}

export enum HolyDayTypes {
  LITURGICAL = 'liturgical',
  NATIONAL = 'national',
}

export enum FeedTypes {
  GOOGLE = 'google',
  LOCAL = 'local',
}
class HolidayService {
  public async loadData(
    countryCode: string,
    type?: HolyDayTypes
  ): Promise<Holiday[]> {
    const response = await mainApi.get(
      `/calendar/holydays/${countryCode.toLowerCase()}`,
      { type }
    );
    if (response.ok) {
      return response.data as Holiday[];
    }
    throw response.data;
  }
}

export default new HolidayService();
