'use strict';

const peopleModule = angular.module('cdApp.people', []);

require('./message-editor/cdr-mail-editor');
require('./newsletter-list/cdr-newsletter-list');
require('./people.component');
require('./people-detail/cdr-edit-profile-button/cdr-edit-profile-button');
require('./consent-create-statement-button/cdr-consent-create-statement-button');
require('./consent-create-statement-button/cdr-consent-edit-statement-button');
require('./people-detail/newsletter-subscription-part/cdr-newsletter-subscriptions');
require('./people-detail/participating-in-part/cdr-participating-in');
require('./people-import-newsletter-and-participant/import-newsletter-and-participant');
require('./consent-create-statement-button/cdr-consent-delete-statement-button');
require('./shared/components/rename-newsletter-or-filter-button/cdr-rename-newsletter-button');
require('./manage-subscription/cdr-manage-subscription');
require('./manage-contacts/cdr-manage-contacts');
require('./people-detail/cdr-resubscribe-all-practical-email-based-information-button/cdr-resubscribe-all-practical-email-based-information-button');
require('./custom-fields/custom-fields.component');
require('./shared/components/anniversary/cdr-anniversary');
require('./message-list/message-list.component');
require('./message-statistics/message-statistics.component');
require('./pastoral-notes-list/pastoral-notes-list.component');
require('./people-detail');
require('./people-export-download/people-export-download.component');
require('./people-import/people-import.component');
require('./people-import-details/people-import-details.component');
require('./people-import-history/people-import-history.component');
require('./people-list/people-list.component');
require('./newsletter-sign-up-announcement/cdr-newsletter-sign-up-announcement-page');
require('./newsletter-sign-up-announcement/cdr-newsletter-setup-page');
require('./cdr-launch-create-newsletter-list-modal/cdr-launch-create-newsletter-list-modal');
require('./shared');
require('./message-editor');
require('./subscription');
require('./tag-list/tag-list.component');
require('./error-link-expiration/cdr-error-link-expiration');

require('./templates');

module.exports = peopleModule.name;
