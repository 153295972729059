import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import { SelectedOrganizationId } from '@/react/organization/store/organization';
import {
  PeopleMessageContributions,
  PeopleMessageContributionsType,
} from '@/react/people/types/message';
import { ApiSearchResult, contributionsApi } from '@/react/api';

export const ContributionsState = atom<
  ApiSearchResult<PeopleMessageContributions>
>({
  key: 'ContributionsState',
  default: selector({
    key: 'ContributionsStateSelector',
    get: async ({ get }) => {
      const organizationId = get(SelectedOrganizationId);
      const { ok, data } = await contributionsApi.get<
        PeopleMessageContributions[]
      >('/contributions/public', { organizationId });

      if (ok) {
        return {
          items: data,
          total: data.length,
        };
      }

      return {
        items: [],
        total: 0,
      };
    },
  }),
});

export const ContributionsByTypeQuery = selectorFamily({
  key: 'ContributionsByTypeQuery',
  get:
    (type: PeopleMessageContributionsType) =>
    async ({ get }) => {
      const contributions = get(ContributionsState);
      const { title } = get(ContributionsSearchAtom);

      if (type) {
        const filtered = contributions.items
          .filter((c) => c.type === type)
          .filter((c) =>
            c.title.toLocaleLowerCase().includes(title.toLocaleLowerCase())
          );
        return {
          items: filtered,
          total: filtered.length,
        };
      }
      return {
        items: contributions.items,
        total: contributions.total,
      };
    },
});

export const ContributionsByTypeState = atomFamily<
  ApiSearchResult<PeopleMessageContributions>,
  PeopleMessageContributionsType
>({
  key: 'ContributionsByTypeState',
  default: ContributionsByTypeQuery,
});

export const ContributionsSearchAtom = atom({
  key: 'ContributionsSearchAtom',
  default: { title: '' },
});
