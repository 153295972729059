import { persistStore, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import ReduxPersist from '../config/ReduxPersistConfig';
import { StartupActionCreator } from '../startup-redux/Actions';

const reconcileStore = (persistor: Persistor): void => {
  // Check to ensure latest reducer version
  const reducerVersion = ReduxPersist.reducerVersion;
  storage
    .getItem('reducerVersion')
    .then((localVersion): void => {
      if (localVersion !== reducerVersion) {
        // Purge store
        persistor.purge();
        storage.setItem('reducerVersion', reducerVersion);
      }
    })
    .catch((): void => {
      storage.setItem('reducerVersion', reducerVersion);
    });
};

const updateReducers = (store: {
  dispatch: (action: any) => void;
}): Persistor => {
  const startup = (): void => store.dispatch(StartupActionCreator());
  const persistor = persistStore(store, null, startup);
  reconcileStore(persistor);
  return persistor;
};

export default {
  updateReducers,
};
