import { react2angular } from 'react18-react2angular';
import angular from 'angular';

import AssignUserRolesInline from '../../../../../react/user/screens/AssignUserRolesInline';
import sharedContext from '../../../../shared/components/shared-context-root/shared-context-root.component';

// Example: <cdr-assign-user-roles-inline userId="userId"/>
angular
  .module('cdApp.settings')
  .component(
    'cdrAssignUserRolesInline',
    react2angular(sharedContext.use(AssignUserRolesInline), ['userId'], [])
  );
