import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  ChurchSettingsState,
  NewsletterListMigrationSkipped,
} from '../store/church';

import cdApp from '@/react/config';
import { UserCheckPermisionQuery } from '@/react/user/store/permissions';
import { useOrganization } from '@/react/organization/hooks/useOrganization';

/**
 * Hooks for working with the Newsletter-List migration.
 */
export const useNewsletterListMigration = () => {
  const [savedNewsletterDate, setSkipped] = useRecoilState(
    NewsletterListMigrationSkipped
  );
  const churchSettingInformation = useRecoilValue(ChurchSettingsState);
  const { updateSettings } = useOrganization();

  const manageNewsletterListMigration = useRecoilValue(
    UserCheckPermisionQuery({
      entityId: cdApp.organization.id,
      entityType: 'organization',
      privilege: 'organization.actions.manageNewsletterListMigration',
    })
  );

  const shouldSkip = (): boolean => {
    if (savedNewsletterDate !== null) {
      const dateFromLocalStorage = moment(
        savedNewsletterDate,
        moment.ISO_8601,
        true
      );
      if (!dateFromLocalStorage.isValid()) {
        return false;
      }

      const hoursSinceSkip = moment().diff(dateFromLocalStorage, 'h');
      return hoursSinceSkip < 24;
    } else {
      return false;
    }
  };
  const newsletterListMigrationComplete =
    churchSettingInformation.newsletterListMigrationComplete;
  const markNewsletterListMigrationComplete = () =>
    updateSettings({
      newsletterListMigrationComplete: true,
    });

  const markSkipped = () => setSkipped(moment().toISOString());
  return {
    newsletterListMigrationComplete,
    markNewsletterListMigrationComplete,
    manageNewsletterListMigration,
    shouldSkip: shouldSkip(),
    markSkipped,
  };
};
