import { ApiResponse } from 'apisauce';

import { API } from '../api';

import { Me } from '@/react/shared/models/authentication';

export interface Calendar {
  enabled: boolean;
  url: string;
}
export interface Attendance {
  enacled: boolean;
}
export interface Settings {
  enableImageCredits: boolean;
  requireImageCredits: boolean;
}

export const getMe = async () => {
  const response: ApiResponse<Me> = await API.get('users/me');
  if (response.ok && response.data) {
    return response.data;
  } else {
    throw new Error('Error loading Groups');
  }
};
