(function () {
  'use strict';
  stickyFixedHeader.$inject = ['$window', '$timeout'];

  function stickyFixedHeader($window, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        let targetElement = angular.element(attrs.stickyFixedHeaderTarget);

        function resizeHandler() {
          $timeout(function () {
            elem.css('position', 'fixed');
            elem.addClass('sticky');
            if (targetElement) {
              targetElement.css('paddingTop', elem.height());
            }
          });
        }
        resizeHandler();
        angular.element($window).bind('resize', resizeHandler);
        scope.$on('$destroy', function () {
          angular.element($window).unbind('resize', resizeHandler);
        });
      },
    };
  }

  angular
    .module('cdApp.shared')
    .directive('stickyFixedHeader', stickyFixedHeader);
})();
