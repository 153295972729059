import { selector } from 'recoil';

import { PartnershipCategory } from '../types/partnership.types';

import { partnershipApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const PartnershipCategories = selector<PartnershipCategory[]>({
  key: 'PartnershipCategories',
  get: async () => {
    const response = await partnershipApi.get<PartnershipCategory[]>(
      'partners/categories'
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const HasPartnership = selector<boolean>({
  key: 'HasPartnership',
  get: async ({ get }) => {
    const categories = get(PartnershipCategories);
    return categories && categories.length > 0;
  },
});
