import _ from 'lodash';

import { mainApi } from '../../api';
import { FEvent as Event } from '../../calendar/models/calendar';

class IntentionReportService {
  public async fetchIntentionOrderOfServiceReportDownloadToken(): Promise<string> {
    const response = await mainApi.get(
      '/calendar/order-of-service/download-token'
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }

  public async fetchOrderOfServiceReportEvents({
    startDate,
    endDate,
    churchIds,
    resourceIds,
    taxonomyIds,
    limit,
    offset,
  }: {
    startDate?: Date;
    endDate?: Date;
    churchIds?: number[];
    resourceIds?: number[];
    taxonomyIds?: number[];
    limit?: number;
    offset?: number;
  } = {}): Promise<{
    items: Event[];
    count: number;
  }> {
    const response = await mainApi.get('/calendar/order-of-service/events', {
      startDate,
      endDate,
      churchIds,
      resourceIds,
      taxonomyIds,
      limit,
      offset,
    });
    if (response.ok) {
      return response.data as any;
    }
    throw response.data;
  }

  public async fetchIntentionBillingReportDownloadToken(): Promise<string> {
    const response = await mainApi.get(
      '/intention/intention-reports/intention-billing/download-token'
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }

  public async fetchIntentionListReportDownloadToken(): Promise<string> {
    const response = await mainApi.get(
      '/intention/intention-reports/intention-list/download-token'
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }

  public async fetchStoleBillingReportDownloadToken(): Promise<string> {
    const response = await mainApi.get(
      '/intention/intention-reports/stole-billing/download-token'
    );
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }
}

export default new IntentionReportService();
