import { mainApi } from '../../api';

class TaxonomiesService {
  public loadData: () => Promise<Taxonomy[]> = async () => {
    const response = await mainApi.get(`/taxonomies`);
    if (!response.ok) {
      throw { code: response.status, error: response.originalError };
    }
    return response.data as Taxonomy[];
  };
}

export default new TaxonomiesService();

export interface Taxonomy {
  id: number;
  type: TaxonomyType;
  name: string;
  color: number | null;
  description?: string;
  registerAttendance: boolean;
}

export enum TaxonomyType {
  Absence = 'absence',
  Blog = 'blog',
  Event = 'event',
}
