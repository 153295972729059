import React, { useState } from 'react';
import { Space, Spin, Form, Select } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import useNewsletterList from '../hooks/useNewsletterList';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  NewsletterandFilterDataInterface,
  PeopleListByTypeAndSearchQuery,
} from '@/react/people/store/newsletterListState';
import {
  CdFilterIcon,
  CdNewsletterListIcon,
  CdParticipantList,
} from '@/react/shared/components/Icons';
import CdModal from '@/react/shared/components/cd-modal/CdModal';

const Container = styled.div`
  margin: 0 32px 48px;
`;

const Header = styled.p`
  font-size: 16px;
`;

export const AddContactsToListsModal = NiceModal.create(
  ({ filter }: AddContactsToListsProps) => (
    <AddContactsToLists filter={filter} />
  )
);

export interface AddContactsToListsProps extends Record<string, unknown> {
  filter: NewsletterandFilterDataInterface;
}
export const AddContactsToLists = ({ filter }: AddContactsToListsProps) => {
  const modal = useModal('AddContactsToLists');

  const [selectedList, setSelectedList] = useState<number>();
  const { addContactsToLists } = useNewsletterList();
  const addContactsToListHandle = async () => {
    const selectNewsletter = newsletterLists.find(
      (newsletter) => newsletter.id === selectedList
    );
    const selectFilter = filterLists.find(
      (filter) => filter.id === selectedList
    );
    const selectParticipant = participantLists.find(
      (participant) => participant.id === selectedList
    );
    let selectedListAddFrom;
    if (selectNewsletter) {
      selectedListAddFrom = selectNewsletter;
    }
    if (selectFilter) {
      selectedListAddFrom = selectFilter;
    }
    if (selectParticipant) {
      selectedListAddFrom = selectParticipant;
    }
    if (filter.type === 'newsletter') {
      showConfirm(
        selectedListAddFrom,
        'addContactToListModal',
        [filter.id],
        selectFilter ? 'filter' : ''
      );
    } else {
      await addContactsToLists({
        filterIds: selectFilter ? [selectedList] : undefined,
        fromPeopleListIds: selectFilter ? undefined : [selectedList],
        toPeopleListIds: [filter.id],
      });
    }
  };
  const newsletterlistsDataLoadable = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({ type: 'newsletter', searchParameter: '' })
  );
  const filterlistsDataLoadable = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({ type: 'filter', searchParameter: '' })
  );
  const participantlistsDataLoadable = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({ type: 'participant', searchParameter: '' })
  );
  const loading =
    newsletterlistsDataLoadable.state === 'loading' ||
    filterlistsDataLoadable.state === 'loading' ||
    participantlistsDataLoadable.state === 'loading';
  const newsletterLists = newsletterlistsDataLoadable.contents.items;
  const filterLists = filterlistsDataLoadable.contents.items;
  const participantLists = participantlistsDataLoadable.contents.items;
  const showConfirm = (
    filter: NewsletterandFilterDataInterface,
    parent: string,
    listId: number[],
    typeOfListAddedContactsFrom: string
  ) => {
    NiceModal.show('ConfirmRegisterConsentOnContacts', {
      filter,
      parent,
      listId,
      typeOfListAddedContactsFrom,
    });
  };

  const options = [
    {
      key: 'participantlists',
      label: gettextCatalog.getString('Participant Lists').toLocaleUpperCase(),
      options:
        participantLists?.map((item) => ({
          label: (
            <Space>
              <CdParticipantList />
              {item.name}
            </Space>
          ),
          value: item.id,
        })) || [],
    },
    {
      key: 'newsletterlists',
      label: gettextCatalog.getString('Newsletter Lists').toLocaleUpperCase(),
      options:
        newsletterLists?.map((item) => ({
          label: (
            <Space>
              <CdNewsletterListIcon />
              {item.name}
            </Space>
          ),
          value: item.id,
        })) || [],
    },
    {
      key: 'lists',
      label: gettextCatalog.getString('Filters').toLocaleUpperCase(),
      options:
        filterLists?.map((item) => ({
          label: (
            <Space>
              <CdFilterIcon />
              {item.name}
            </Space>
          ),
          value: item.id,
        })) || [],
    },
  ];

  return (
    <CdModal
      title={gettextCatalog.getString('Add contacts in bulk to "{{name}}"', {
        name: filter.name,
      })}
      okText={gettextCatalog.getString('Add contacts')}
      modal={modal}
      onOk={addContactsToListHandle}
    >
      <Spin spinning={loading}>
        <Form style={{ marginTop: '16px' }} layout="horizontal">
          <Container>
            <Header>
              {gettextCatalog.getString(
                'Choose which list to add contacts from:'
              )}
            </Header>
            <Select
              placeholder={gettextCatalog.getString(
                'Select list for adding contacts as subscribers'
              )}
              onChange={(value) => setSelectedList(value)}
              value={selectedList}
              options={options}
              style={{ width: '100%' }}
            />
          </Container>
        </Form>
      </Spin>
    </CdModal>
  );
};
