import React, { FunctionComponent } from 'react';
import * as Sentry from '@sentry/react';
import NiceModal from '@ebay/nice-modal-react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { SyncReduxToRecoil } from 'redux-to-recoil';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { WidgetDrawer } from '../../homepage/widgets/WidgetDrawer';
import { CreateWebsiteDrawer } from '../../homepage/websitelandingpage/CreateWebsiteDrawer';
import { ConnectToAccountManagerModal } from '../../homepage/websitelandingpage/ConnectToAccountManagerModal';
import { WidgetPreviewAndGetCodeModal } from '../../homepage/widgets/WidgetPreviewModal';
import { SafeguardingDrawer } from '../../safeguarding/safeguarding/SafeguardingDrawer';

import CdrThemeProvider from './CdrThemeProvider';
import { CdrUploadFilesModal } from './CdrUploadFilesModal';

import AssignLabel from '@/react/forms/components/AssignLabel';
import Store from '@/react/redux/index';
import { gettextCatalog } from '@/react/services/I18nService';
import { ShareNewsletterModal } from '@/react/people/message-editor/shared/containers/ShareNewsletterModal';
import { TemplateSelector } from '@/react/people/message-editor/shared/containers/TemplateSelector';
import { OrderOfServiceTemplateSelector } from '@/react/calendar/containers/OrderOfServiceTemplateSelector';
import { ScheduleMailSendModal } from '@/react/people/message-editor/shared/containers/ScheduleMailSendModal';
import { CalendarPosterTemplates } from '@/react/calendar/containers/event/CalendarPosterTemplates.drawer';
import { CalendarEmailPosterTemplates } from '@/react/calendar/containers/event/CalendarEmailPosterTemplates.drawer';
import { ManageContactsDrawer } from '@/react/people/components/manage-contacts-drawer/ManageContactsDrawer';
import { AddContactsToListsModal } from '@/react/people/components/AddContactsToLists';
import { ConsentCreateStatementModal } from '@/react/people/components/ConsentCreateStatement';
import { RenameNewsletterOrFilterModal } from '@/react/people/components/RenameNewsletterOrFilter';
import { GetCodeSignUpWidgetModal } from '@/react/people/components/GetCodeSignUpWidget';
import { ManageSubscriptionsAndConsentsModal } from '@/react/people/components/ManageSubscriptionsAndConsents';
import { ExportContactsFromNewsletterOrFilterModal } from '@/react/people/components/ExportContactsFromNewsletterOrFilter';
import { ConfirmRegisterConsentOnContactsModal } from '@/react/people/components/ConfirmRegisterConsentOnContacts';
import { ConfirmDefaultConsentCreationOnContactsModal } from '@/react/people/components/ConfirmDefaultConsentCreationOnContacts';
import CreateParticipantListModal from '@/react/people/components/CreateParticipantList';
import CreateLabel from '@/react/forms/components/CreateLabel';
import EditLabel from '@/react/forms/components/EditLabel';
import ConfirmManageContactsModal from '@/react/people/components/ConfirmManageContacts';
import CreateTagModal from '@/react/people/components/CreateTag';
import { GetCodeDonationWidgetModal } from '@/react/contributions/components/GetCodeDonationWidget';
import CreatePosterWidgetModal from '@/react/contributions/components/CreatePosterWidget';
import { CdNewSignUpFormModal } from '@/react/calendar/event-details/components/CdNewSignUpFormModal';
import { CdExistingSignUpFormModal } from '@/react/calendar/event-details/components/CdExistingSignUpFormModal';
import { AddUserToRotaModal } from '@/react/calendar/event-details/components/rotas/AddUserToRotaModal';
import { EditRepeatedEventModal } from '@/react/calendar/event-details/components/modals/EditRepeatedEventModal';
import { ManageDomainsDrawer } from '@/react/homepage/components/manage-domains/ManageDomains.drawer';
import { FilePickerComponent } from '@/react/calendar/file-picker-library/FilePickerComponent';

const queryClient = new QueryClient();

export const RootProvider: FunctionComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const store = Store.getStore();
  return (
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <CdrThemeProvider>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <SyncReduxToRecoil />
              <ConfigProvider locale={gettextCatalog.getANTDesignLocale()}>
                <NiceModal.Provider>{children}</NiceModal.Provider>
              </ConfigProvider>
            </QueryClientProvider>
          </RecoilRoot>
        </CdrThemeProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

NiceModal.register('ScheduleMailSendModal', ScheduleMailSendModal);
NiceModal.register('AssignLabel', AssignLabel);
NiceModal.register('CreateLabel', CreateLabel);
NiceModal.register('EditLabel', EditLabel);
NiceModal.register('ShareNewsletterModal', ShareNewsletterModal);
NiceModal.register('TemplateSelectorModal', TemplateSelector);
NiceModal.register('ManageContactsDrawer', ManageContactsDrawer);
NiceModal.register('CalendarPosterTemplates', CalendarPosterTemplates);
NiceModal.register(
  'CalendarEmailPosterTemplates',
  CalendarEmailPosterTemplates
);
NiceModal.register(
  'OrderOfServiceTemplateSelector',
  OrderOfServiceTemplateSelector
);
NiceModal.register('AddContactsToLists', AddContactsToListsModal);
NiceModal.register('ConsentCreateStatement', ConsentCreateStatementModal);
NiceModal.register('RenameNewsletterOrFilter', RenameNewsletterOrFilterModal);
NiceModal.register(
  'ExportContactsFromNewsletterOrFilter',
  ExportContactsFromNewsletterOrFilterModal
);
NiceModal.register('GetCodeSignUpWidget', GetCodeSignUpWidgetModal);
NiceModal.register(
  'ManageSubscriptionsAndConsents',
  ManageSubscriptionsAndConsentsModal
);
NiceModal.register(
  'ConfirmRegisterConsentOnContacts',
  ConfirmRegisterConsentOnContactsModal
);
NiceModal.register(
  'ConfirmDefaultConsentCreationOnContacts',
  ConfirmDefaultConsentCreationOnContactsModal
);
NiceModal.register('EditRepeatedEventModal', EditRepeatedEventModal);
NiceModal.register('ConfirmManageContacts', ConfirmManageContactsModal);
NiceModal.register('CreateTag', CreateTagModal);
NiceModal.register('CreateParticipantList', CreateParticipantListModal);
NiceModal.register('GetCodeDonationWidget', GetCodeDonationWidgetModal);
NiceModal.register('CreatePosterWidget', CreatePosterWidgetModal);
NiceModal.register('CdNewSignUpFormComponent', CdNewSignUpFormModal);
NiceModal.register('CdExistingSignUpFormComponent', CdExistingSignUpFormModal);
NiceModal.register('CdrUploadFilesModal', CdrUploadFilesModal);
NiceModal.register('AddUserToRotaModal', AddUserToRotaModal);
NiceModal.register('WidgetDrawer', WidgetDrawer);
NiceModal.register('CreateWebsiteDrawer', CreateWebsiteDrawer);
NiceModal.register(
  'ConnectToAccountManagerModal',
  ConnectToAccountManagerModal
);
NiceModal.register('ManageDomainsDrawer', ManageDomainsDrawer);
NiceModal.register(
  'WidgetPreviewAndGetCodeModal',
  WidgetPreviewAndGetCodeModal
);
NiceModal.register('SafeguardingDrawer', SafeguardingDrawer);
NiceModal.register('FilePickerComponentModal', FilePickerComponent);

export default RootProvider;
