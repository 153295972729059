export enum SegmentType {
  ORGANIZATION = 'organization',
  CHURCH = 'church',
  SEGMENT = 'segment',
}

export interface Segment {
  id: number;
  name: string;
  filteringType: SegmentType;
  lastSent: string;
  setup: SegmentFilter;
  type?: string;
  canAddPeopleToList: boolean;
}

export interface SegmentFilter {
  comparison: SegmentComparison;
  churchIds?: number[];
  filters: SegmentFilterComponent[];
}

export enum SegmentComparison {
  AND = 'AND',
  OR = 'OR',
}
export interface SegmentFilterComponent {
  type: SegmentFilterType;
  property?: string;
  operator: SegmentFilterOperator;
  value?: string | number | number[];
  unit?: string; // For dates as m (month) or d (day)
}
export enum SegmentFilterOperator {
  Equal = 'eq',
  NotEqual = 'ne',
  LessThan = 'lt',
  GreaterThan = 'gt',
  Like = 'like',
  NotLike = 'notLike',
  Known = 'known',
  Unknown = 'unknown',
  InArray = 'inArray',
}

export enum SegmentFilterType {
  Text = 'text',
  Number = 'number',
  Radio = 'radio',
  List = 'list',
  Date = 'date',
  LifeEvent = 'lifeEvent',
}
