interface NSProvider {
  name: string;
  search: string;
  domain: string;
  isChurchDesk?: boolean;
}

export const findNSProvider = (
  domain: string,
  nsDomain: string
): Partial<NSProvider> => {
  // If the domain is a ChurchDesk domain, then we know the NS provider.
  for (let i = 0; i < churchDeskCustomersFromOne.length; i++) {
    if (domain.includes(churchDeskCustomersFromOne[i])) {
      return { isChurchDesk: true };
    }
  }
  if (nsDomain) {
    // Go through the list of NS providers and find the one that matches the domain.
    for (const provider of listOfNSProviders) {
      if (nsDomain.includes(provider.search)) {
        return provider;
      }
    }
  }
  return null;
};

const churchDeskCustomersFromOne = [
  'churchdesk.com',
  'aabenraasogn.dk',
  'billumkirke.dk',
  'bronshojkirke.dk',
  'dintromintro.dk',
  'frederiksvaerk-vinderoed.dk',
  'ggbkirker.dk',
  'hansegedeskirke.dk',
  'hedenstedkirke.dk',
  'helsinge-kirke.dk',
  'hojdevangskirken.dk',
  'islevkirke.dk',
  'karlslundekirke.dk',
  'koebenhavnsdomkirke.dk',
  'kregmekirke.dk',
  'lindevangkirke.dk',
  'maarumkirke.dk',
  'maarumsogn.dk',
  'munkebo-kirke.dk',
  'nykoebing-kirke.dk',
  'nykoebingkirke.dk',
  'oppesundbykirke.dk',
  'pederstrup-sogn.dk',
  'praestebrokirke.dk',
  'rungstedkirke.dk',
  'sct-lukas-kirke.dk',
  'sesogne.dk',
  'sjkn.dk',
  'snostrupkirke.dk',
  'solvangsogn.dk',
  'soroeklosterkirke.dk',
  'ullerodkirke.dk',
  'xn--lstedkirke-zcb.dk',
  'xn--prstebrokirke-4fb.dk',
  'xn--prstebrosogn-7cb.dk',
  'xn--ullerdkirke-kgb.dk',
];

const listOfNSProviders: Array<NSProvider> = [
  {
    name: 'Cloudflare',
    search: 'cloudflare.com',
    domain: 'cloudflare.com',
    isChurchDesk: true,
  },
  {
    name: 'AWS',
    search: 'awsdns',
    domain: 'aws.amazon.com',
    isChurchDesk: true,
  },
  {
    name: 'Simply',
    search: 'simply.com',
    domain: 'simply.com',
  },
  {
    name: 'UnoEuro',
    search: 'unoeuro.com',
    domain: 'unoeuro.com',
  },
  {
    name: 'Hostinger',
    search: 'dns-parking.com',
    domain: 'hostinger.com',
  },
  {
    name: 'Namecheap',
    search: 'registrar-servers.com',
    domain: 'namecheap.com',
  },
  { name: 'One.com', search: 'one.com', domain: 'one.com' },
  {
    name: 'Scannet',
    search: 'scannet',
    domain: 'scannet.dk',
  },
  {
    name: 'Gigahost',
    search: 'gigahost',
    domain: 'gigahost.dk',
  },
  {
    name: 'Curanet',
    search: 'curanet',
    domain: 'curanet.dk',
  },
  { name: 'Dandomain', search: 'dandomain', domain: 'dandomain.dk' },
  { name: 'Ionos.de', search: 'ui-dns', domain: 'ionos.de' },
  { name: 'Ionos.de', search: 'rzone.de', domain: 'ionos.de' },
  { name: 'Strato.de', search: 'strato', domain: 'strato.de' },
  { name: 'Godaddy', search: 'domaincontrol.com', domain: 'godaddy.com' },
  { name: 'Hosteurope.de', search: 'hosteurope', domain: 'hosteurope.de' },
  { name: 'World4You', search: 'ns14.net', domain: 'world4you.com' },
  { name: 'World4You', search: 'w-commerce.de', domain: 'world4you.com' },
  { name: 'DeLink', search: 'delink.de', domain: 'delink.de' },
  { name: 'Easyname', search: 'cloudpit', domain: 'www.easyname.com' },
  { name: 'Antagus', search: 'antagus.de', domain: 'antagus.de' },
  { name: 'Schwinge', search: 'schwingedns.de', domain: 'schwinge.com' },
  { name: 'United Domains', search: 'udag.', domain: 'uniteddomains.com' },
  { name: 'All Inkl', search: 'kasserver.com', domain: 'all-inkl.com' },
  { name: 'All Inkl', search: 'name-server-kas.de', domain: 'all-inkl.com' },
  { name: 'All Inkl', search: 'ai-dns.de', domain: 'all-inkl.com' },
];
