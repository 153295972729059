'use strict';

angular.module('cdApp.shared').directive('cdImageCrop', () => ({
  restrict: 'E',
  templateUrl: '@/app/shared/components/image-crop/image-crop.component.html',
  scope: {
    image: '=',
    bounds: '=',
    key: '@',
    width: '@',
    height: '@',
    contextId: '@',
    contextType: '@',
    onlyMode: '@',
    onSave: '&',
    onCancel: '&',
  },

  link(scope, element) {
    const parentWidth = element.parent().width();
    const width = scope.width || Math.max(parentWidth, 100);
    const height = scope.height || (width * 9) / 16;

    element.children('canvas').attr('width', width).attr('height', height);
  },
  controller: [
    '$http',
    '$scope',
    'Files',
    '_',
    function ($http, $scope, Files, _) {
      'ngInject';
      const getBounds = (format) => {
        if (!$scope.image) return {};

        if (_.isObject($scope.image)) {
          const defaultBounds = {
            slideshow: {},
            'slideshow-widgets': {},
            '16-9': {},
            churchscreen: {},
            'people-message': {},
          };

          if (_.isEmpty($scope.bounds)) {
            $scope.bounds = defaultBounds;
          }

          return format ? _.get($scope.bounds, format, {}) : $scope.bounds;
        }
      };

      $scope.cropper = {
        croppedImage: null,
        bounds: { left: 0, right: 0, top: 0, bottom: 0 },
        format: {},
      };

      $scope.format = {
        '16-9': {
          width: 640,
          height: 360,
        },

        churchscreen: {
          width: 360,
          height: 640,
        },

        'people-message': {
          width: 620,
          height: 280,
        },

        'slideshow-widgets': {
          width: 2000,
          height: 625,
        },
      };

      $http.get(`${cdApp.config.api.main}/slideshow/setting`).then((res) => {
        $scope.format.slideshow = res.data;
        $scope.select($scope.active || '16-9');
      });

      $scope.select = (format) => {
        const targetFormat = $scope.onlyMode || format;
        $scope.cropper.bounds = getBounds(targetFormat);
        $scope.cropper.format = $scope.format[targetFormat];
        $scope.active = targetFormat;
      };

      $scope.save = () => {
        let context = _.isEmpty($scope.contextId)
          ? $scope.key
          : `${$scope.contextType}-${$scope.contextId}`;
        let bounds = $scope.bounds;
        Files.updateCropInformation(
          { id: $scope.image.file.id },
          { context, bounds }
        ).$promise.then(() =>
          $scope.onSave({
            croppedImage: $scope.cropper.croppedImage,
          })
        );
      };

      $scope.$watch('image', () => {
        if (!$scope.image) return;
        $scope.select($scope.active || '16-9');
      });

      $scope.$watchCollection('cropper.bounds', (newValue, oldValue) => {
        if (oldValue === newValue) return;
        _.assign(getBounds($scope.active), newValue);
      });
    },
  ],
}));
