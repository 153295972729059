import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Form } from 'antd';
import React from 'react';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { EventQuery } from '@/react/calendar/store/events/event';
import { IcalTokenQuery } from '@/react/calendar/store/ical/ical';
import cdApp from '@/react/config';
import { CdInputWithCopy } from '@/react/shared/components/cd-input-with-copy/CdInputWithCopy';

export interface AssignIntentionModalProps extends Record<string, unknown> {
  eventId: number;
}

export const launchExportIcalModal = createCdModal<AssignIntentionModalProps>(
  ({ setModalProps, eventId }) => {
    const event = useRecoilValue(
      EventQuery({ calendarId: eventId, clientVersion: 3 })
    );
    const icalToken = useRecoilValue(IcalTokenQuery);
    const iCalLink = `${cdApp.config.api.main}/ical/entity/${eventId}?token=${icalToken}`;

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        cancelText: gettextCatalog.getString('Close'),
        title: gettextCatalog.getString('Export {{eventTitle}}', {
          eventTitle: event.title,
        }),
        hideOk: true,
      });
    }, [event, iCalLink, setModalProps]);
    return (
      <Form
        layout="vertical"
        initialValues={{
          feed: iCalLink,
        }}
      >
        <Form.Item
          name="feed"
          label={gettextCatalog.getString('Event feed')}
          extra={gettextCatalog.getString(
            'Export this calendar item by copying the following link to your calendar application.'
          )}
        >
          <CdInputWithCopy disabled />
        </Form.Item>
      </Form>
    );
  }
);
