import {
  PeopleAnniversarySettings,
  PeopleAnniversaryUsersToNotify,
} from '../../types/people';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getAnniversarySettings =
  async (): Promise<PeopleAnniversarySettings> => {
    const response = await mainApi.get<PeopleAnniversarySettings>(
      `people/people/anniversaries/settings`
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  };
export const getAnniversaryUsersToNotify = async (
  parishIds: number[]
): Promise<PeopleAnniversaryUsersToNotify[]> => {
  const response = await mainApi.get<PeopleAnniversaryUsersToNotify[]>(
    `people/people/anniversaries/notify-users`,
    { parishIds }
  );
  if (!response.ok) {
    handleError(response);
    return [];
  }
  return response.data;
};
