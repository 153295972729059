import { Moment } from 'moment';

import cdApp from '@/react/config';

export const getRRuleText = async (props: {
  rrule: string;
  startDate: string | Date | Moment;
}) => {
  const language =
    cdApp.organization.locale.language === 'en-gb'
      ? 'en'
      : cdApp.organization.locale.language;
  const timezone = cdApp.organization.locale.timezone;
  const response = await fetch('https://rrule-cdn.churchdesk.com/rrule/text', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      rrule: props.rrule,
      startDate: props.startDate,
      language,
      timezone,
    }),
  })
    .then((response) => response.json())
    .catch(() => null);
  return response?.text;
};
