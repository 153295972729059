'use strict';

angular
  .module('cdApp.shared')
  .constant('uiSelectAllowNewMarker', 'isPlaceholder')
  .filter('uiSelectAllowNew', [
    'uiSelectAllowNewMarker',
    '$filter',
    'Authorization',
    '_',
    (uiSelectAllowNewMarker, $filter, Authorization, _) => {
      'ngInject';

      return (items, property, searchText, permissions) => {
        if (!items) return [];
        const actualItems = _.reject(items, uiSelectAllowNewMarker);
        let isAny = false;
        if (typeof permissions === 'string' && permissions === 'any') {
          isAny = true;
        }
        // When not searching, do not allow creating new items
        if (
          !searchText ||
          (!isAny && !Authorization.hasPermission(permissions))
        ) {
          return actualItems;
        }

        // When the search matches exactly an existing taxonomy, do not allow creating duplicates
        const filteredItems = $filter('filter')(actualItems, {
          [property]: searchText,
        });

        const exactNameMatch = _.some(
          filteredItems,
          (item) => _.toLower(item[property]) === _.toLower(searchText)
        );

        if (exactNameMatch) return actualItems;

        const placeholder = _.find(items, { [uiSelectAllowNewMarker]: true });
        if (placeholder) {
          _.set(placeholder, property, searchText);
        } else {
          items.push({
            [property]: searchText,
            [uiSelectAllowNewMarker]: true,
          });
        }

        return items;
      };
    },
  ]);
