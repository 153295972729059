import { $injector } from 'ngimport';

class AppUtilsService {
  public getTaxonomyColorClass(taxonomy: any): string {
    const appUtils: any = $injector.get('appUtils');
    return appUtils.getTaxonomyColorClass(taxonomy);
  }

  public formatGoogleLocation(location: any) {
    const appUtils: any = $injector.get('appUtils');
    return appUtils.formatGoogleLocation(location);
  }
}

export default new AppUtilsService();
