import _ from 'lodash';

import { mainApi } from '../../api';
import { People } from '../../people/types/people';
import { Resource } from '../models/resource';

// Interfaces

class PeopleService {
  public async fetchPerson(personId: number): Promise<People> {
    const response = await mainApi.get(`/people/people/${personId}`);
    if (response.ok) {
      return response.data as People;
    }
    throw response.data;
  }

  public async searchPeople(
    query: string,
    churches: Resource[]
  ): Promise<People[]> {
    const filter = {
      comparison: 'OR',
      churchIds: _.map(churches, 'id'),
      filters: [
        {
          type: 'text',
          property: 'fullName',
          operator: 'like',
          value: query,
        },
        {
          type: 'text',
          property: 'email',
          operator: 'like',
          value: query,
        },
        {
          type: 'text',
          property: 'phone',
          operator: 'like',
          value: query,
        },
      ],
    };
    const response = await mainApi.post('/people/people/search', {
      filter,
      limit: 30,
      searchAfter: null,
      orderBy: 'fullName',
      orderDirection: 'asc',
    });
    if (response.ok) {
      return _.get(response.data, 'people') as People[];
    }
    throw response.data;
  }
}

export default new PeopleService();
