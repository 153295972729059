'use strict';

class PeopleListSwitcherComponent {
  constructor(_, Authorization) {
    'ngInject';

    this._ = _;
    this.Authorization = Authorization;
  }

  $onInit() {
    /**
     * Whether the user can view a list
     */
    this.canViewList = this._.reduce(
      this.lists,
      (result, list) => {
        const canViewList = list.access;
        _.set(result, list.id, canViewList);
        return result;
      },
      {}
    );
  }

  /**
   * Select a list
   *
   * @param {Number} listId
   * @memberof PeopleListSwitcherComponent
   */
  selectList(listId) {
    this.onSelect({ listId });
  }

  editList(listId) {
    this.onEditList({ listId });
  }
}

PeopleListSwitcherComponent.$inject = ['_', 'Authorization'];

angular.module('cdApp.people').component('cdPeopleListSwitcher', {
  templateUrl:
    '@/app/people/shared/components/list-switcher/list-switcher.component.html',
  controller: PeopleListSwitcherComponent,
  bindings: {
    lists: '<',
    selectedList: '<',
    onSelect: '&',
    showOnlyListType: '<',
    onEditList: '&',
  },
});
