(function () {
  'use strict';

  function permissionsCount() {
    // getting the id of 'calendarAccess' permissionsCount, currently its id is 15
    let calendarAccessId = 15;

    return function (values) {
      if (!_.isArray(values)) {
        return null;
      }
      return _.every(values, _.isNumber) && _.includes(values, calendarAccessId)
        ? values.length - 1
        : values.length;
    };
  }

  angular.module('cdApp.shared').filter('permissionsCount', permissionsCount);
})();
