import React from 'react';
import { Form, Input } from 'antd';
import { unescape } from 'lodash';

import { gettextCatalog } from '../../services/I18nService';
import { usePostGroupMessageModal } from '../hooks/usePostGroupMessageModal';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { required } from '@/react/shared/antd/validations/common';

export interface PostGroupMessageModalProps {
  group: { id: number };
  message?: { id: number; title: string; message: string };
}

export const showPostGroupMessageModal =
  createCdModal<PostGroupMessageModalProps>((props) => {
    const { form } = usePostGroupMessageModal(props);
    const unescapedMessage = {
      ...props.message,
      title: unescape(props.message?.title),
      message: unescape(props.message?.message),
    };

    return (
      <Form form={form} layout="vertical" initialValues={unescapedMessage}>
        <Form.Item
          name="title"
          label={gettextCatalog.getString('Title')}
          rules={[required()]}
        >
          <Input
            placeholder={gettextCatalog.getString('The title of the message')}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name="message"
          label={gettextCatalog.getString('Message')}
          rules={[required()]}
        >
          <Input.TextArea
            placeholder={gettextCatalog.getString(
              'What is your message to the group?'
            )}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </Form.Item>
      </Form>
    );
  });
