import React, { useEffect, Suspense, useState } from 'react';
import styled from 'styled-components';
import { Button, Space, Typography } from 'antd';
import { useRecoilValue } from 'recoil';

import { getStateUrl, navigate } from '@/react/services/StateServiceFactory';
import { gettextCatalog } from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { getCRMContactDetails } from '@/react/shared/services/CRMContactDetailsService';
import { CdCard } from '@/react/shared/components/CdCard';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { LanguageState } from '@/react/user/store/user-session';
import { PeopleSignUpDemo } from '@/react/people/components/PeopleSignUpDemo';
import { useNewsletterListMigration } from '@/react/organization/hooks/usePeopleWizard';

const { Title } = Typography;

const FirstSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const List = styled.div`
  ul {
    font-size: 16px;
    margin-top: 16px;
  }
  li {
    margin-left: 30px;
  }
`;
const NewsletterSignUpAnnouncementPageContainer = ({
  $stateParams,
}: {
  $stateParams: { continue: string };
}) => {
  const destinationPageName = $stateParams.continue;
  const {
    manageNewsletterListMigration,
    shouldSkip,
    markSkipped,
    newsletterListMigrationComplete,
    markNewsletterListMigrationComplete,
  } = useNewsletterListMigration();
  const language = useRecoilValue(LanguageState);

  const destinationPageUrl =
    destinationPageName === 'people' && 'app.private.people.default';
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (manageNewsletterListMigration) {
      if (newsletterListMigrationComplete || shouldSkip) {
        navigate(destinationPageUrl);
      } else {
        setLoading(false);
      }
    } else {
      navigate(destinationPageUrl);
    }
  }, [
    newsletterListMigrationComplete,
    manageNewsletterListMigration,
    shouldSkip,
    destinationPageUrl,
  ]);
  const videoGuideLinks = {
    da: 'https://www.churchdesk.com/da/webinarer',
    sv: 'https://www.churchdesk.com/da/webinarer',
    en: 'https://www.churchdesk.com/en/live-classes',
    de: 'https://www.churchdesk.com/de/webinare',
  };
  const supportContent = gettextCatalog.getString(
    `This will take about 5 minutes. If you have questions you can call support at {{number}} or check out our {{videoGuideLink}} to help you make use of these new features.`,
    {
      number: getCRMContactDetails(language).bookingPhone,
      videoGuideLink: `<a href="${
        videoGuideLinks[language] || videoGuideLinks['en']
      }" target="_blank" rel="noopener">${gettextCatalog.getString(
        'video guide'
      )}</a>`,
    }
  );

  return (
    <>
      {loading ? (
        <CdrPageLoader />
      ) : (
        <CdPage hideHeader>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CdCard style={{ maxWidth: '1000px', marginBottom: '16px' }}>
              <Title level={2} style={{ marginTop: '0' }}>
                {gettextCatalog.getString(
                  'We just launched a better way of managing your newsletter lists and subscribers'
                )}
              </Title>
              <FirstSection>
                <div>
                  <List
                    dangerouslySetInnerHTML={{
                      __html: gettextCatalog.getString(
                        `<ul><li>You can now add a modern pop-up to your website to get newsletter subscribers. Also if you don’t use our website system</li><li>You can easily add and remove contacts as subscribers to your newsletter</li><li>People can also now easily subscribe to your newsletters directly from sign-up forms</li><li>Finally we are also now asking for peoples consent automatically when they sign-up to your newsletter </li></ul>`
                      ),
                    }}
                  ></List>
                  <Title level={2}>
                    {gettextCatalog.getString(
                      'We would like to help you get started with the benefits'
                    )}
                  </Title>
                  <Title level={3}>
                    {gettextCatalog.getString(
                      'If you click continue we will go through a quick guide that will:'
                    )}
                  </Title>
                  <List
                    dangerouslySetInnerHTML={{
                      __html: gettextCatalog.getString(
                        `<ul><li>Convert your old lists to our improved newsletter lists</li><li>Help you ensure that you ask for proper consent</li><li>Add the newsletter sign-up widget to your website</li></ul>`
                      ),
                    }}
                  ></List>
                </div>
                <PeopleSignUpDemo />
              </FirstSection>
              <Title level={3}>
                <span
                  dangerouslySetInnerHTML={{ __html: supportContent }}
                ></span>
              </Title>

              <div style={{ textAlign: 'right', marginTop: '24px' }}>
                <Space>
                  <Button
                    onClick={async () => {
                      await markNewsletterListMigrationComplete();
                      navigate(destinationPageUrl);
                    }}
                    size="large"
                  >
                    {gettextCatalog.getString('We do not send newsletters')}
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      markSkipped();
                      navigate(destinationPageUrl);
                    }}
                  >
                    {gettextCatalog.getString('Skip setup wizard')}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    href={getStateUrl(
                      'app.private.people.people-setup-newsletter',
                      {
                        continue: destinationPageName,
                      }
                    )}
                  >
                    {gettextCatalog.getString('Continue')}
                  </Button>
                </Space>
              </div>
            </CdCard>
          </div>
        </CdPage>
      )}
    </>
  );
};
const NewsletterSignUpAnnouncementPage = (props) => (
  <Suspense fallback={<CdrPageLoader />}>
    <NewsletterSignUpAnnouncementPageContainer {...props} />
  </Suspense>
);
export default NewsletterSignUpAnnouncementPage;
