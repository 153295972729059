import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

interface CalendarExportReportDownloadTokenInterface {
  token: string;
}
export const getCalendarReportDownloadToken =
  async (): Promise<CalendarExportReportDownloadTokenInterface> => {
    const response =
      await mainApi.get<CalendarExportReportDownloadTokenInterface>(
        '/calendar/export/download-token'
      );
    if (!response.ok) {
      handleError(response);
      return undefined;
    }
    return response.data;
  };
