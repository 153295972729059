import { react2angular } from 'react18-react2angular';

import { Collection } from '../../../../react/intention/pages';
import sharedContext from '../../../shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.calendar')
  .component(
    'cdrCollection',
    react2angular(sharedContext.use(Collection), [], [])
  );
