import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';

import ElementWrapper from './ElementWrapper';

const FileType: React.FC<{ fileType: string }> = ({ fileType }) => (
  <ElementWrapper>
    <FontAwesomeIcon icon={faFile} />
    <span>{fileType}</span>
  </ElementWrapper>
);

export default FileType;
