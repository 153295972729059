(function () {
  'use strict';
  GroupsFeed.$inject = ['$$resource'];

  function GroupsFeed($$resource) {
    return $$resource(cdApp.config.api.main + '/groups/:id/feed');
  }

  angular.module('cdApp.shared').factory('GroupsFeed', GroupsFeed);
})();
