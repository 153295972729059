/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form, Select, DatePicker, Space, Alert, Row, Col } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbtack,
  faHistory,
  faPrint,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Church } from '../../../calendar/models/calendar';
import { gettextCatalog } from '../../../services/I18nService';
import { handleSuccessMessage } from '../../../shared/utils';
import IntentionService from '../../services/IntentionService';
import { navigate } from '../../../services/StateServiceFactory';
import { getChurches } from '../../../shared/store/resources';
import { getBillingIntentions } from '../../redux/intentions/Selectors';
import {
  IntentionStatusTypes,
  Intention,
  IntentionPriorityTypes,
} from '../../models/intention';
import ErrorHandlingService from '../../../services/ErrorHandlingService';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import {
  getStyledInlineForm,
  StyledTable,
  CapitalizedDiv,
} from '../../../shared/antd/StyledAntdComponents';
import {
  fetchBillingIntentions,
  clearBillingIntentions,
  generateIntentionBillingReport,
} from '../../redux/intentions/Actions';
import { getIntentionFeeRecipients } from '../../../settings/redux/intention-fees/Selectors';
import { fetchIntentionFeeRecipients } from '../../../settings/redux/intention-fees/Actions';

import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

// Antd
const { Option } = Select;
const StyledInlineForm = getStyledInlineForm(150, 320);

const isIntentionDeleted = (intention: Intention) =>
  intention && !_.isNil(intention.deletedAt);

const isIntentionCompleted = (intention: Intention) =>
  intention && intention.status === IntentionStatusTypes.COMPLETED;

const invalidateMouseEvent = (event: React.MouseEvent) => {
  if (!event) return;
  event.preventDefault();
  event.stopPropagation();
};

const navigateToIntention = (type, intention, event) => {
  invalidateMouseEvent(event);
  navigate('app.private.intention.edit', {
    id: intention.id,
    currentState: type,
  });
};

const IntentionsBilling: FunctionComponent = () => {
  const dispatch = useDispatch();

  const isLoading: boolean = useSelector(isLoadingSelector);

  const churches = useSelector(getChurches);
  const forwardedIntentionFeeRecipients = useSelector(
    getIntentionFeeRecipients
  );
  const [
    selectedInternalForwardingRecipientId,
    setSelectedInternalForwardingRecipientId,
  ] = useState<number>();

  const _billingIntentions = useSelector(getBillingIntentions);
  const billingIntentions =
    _billingIntentions && _billingIntentions.asMutable({ deep: true });
  const [
    selectedInternallyForwardedIntentionIds,
    setSelectedInternallyForwardedIntentionIds,
  ] = useState<string[]>([]);
  const [shouldDisableProcessButton, setShouldDisableProcessButton] =
    useState<boolean>(true);
  const [shouldDisableRevertButton, setShouldDisableRevertButton] =
    useState<boolean>(true);
  const [
    shouldDisableGenerateReportButton,
    setShouldDisableGenerateReportButton,
  ] = useState<boolean>(true);

  const [form] = Form.useForm();
  const title: string = gettextCatalog.getString('Intentions Billing');

  // Initial load
  useEffect(() => {
    // Clear the billing results on load
    updateBillingEvents();
    // Fetch intention fee recipients who are marked as forwarded
    dispatch(fetchIntentionFeeRecipients({ forwardingRecipientsOnly: true }));
  }, []);

  useEffect(() => {
    updateButtonsState();
  }, [_.map(billingIntentions, 'id').join(',')]);

  const back = () => {
    navigate('app.private.intention.overview');
  };

  const getInternalForwardingRecipients = (): Church[] => {
    const selectedChurchId = form.getFieldValue('church');
    if (!selectedChurchId) return [];
    return _.filter(churches, (church) => church.id !== selectedChurchId);
  };

  // Move intentions from assigned to completed
  const processBilling = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');
    const month = moment(selectedDate).month();
    const year = moment(selectedDate).year();
    const selectedForwardingRecipientId = form.getFieldValue(
      'forwardingRecipient'
    );
    const selectedInternalForwardingRecipientId = form.getFieldValue(
      'internalForwardingRecipient'
    );
    const selectedForwardingDate = form.getFieldValue('forwardingDate');

    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Process Billing'),
      message: gettextCatalog.getString(
        'Do you want to process the intention billing?'
      ),
      onOk() {
        IntentionService.processBilling({
          month,
          year,
          churchIds: [selectedChurchId],
          forwardingRecipientId: selectedForwardingRecipientId,
          forwardedAt: moment(selectedForwardingDate).toDate(),
          internalForwardingRecipientId: selectedInternalForwardingRecipientId,
          internallyForwardedIntentionIds:
            selectedInternallyForwardedIntentionIds,
        })
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully processed billing.')
            );
            generateBillingReport();
            updateBillingEvents();
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  const handleMonthSelection = () => {
    const selectedDate = form.getFieldValue('month');
    form.setFieldsValue({
      forwardingDate: selectedDate ? selectedDate.endOf('month') : null,
    });
    updateBillingEvents();
  };

  const handleInternalForwardingRecipientUpdate = (recipientId: number) => {
    setSelectedInternalForwardingRecipientId(recipientId);
  };

  const handleInternalForwardingIntentionsChange = (
    selectedIntentionIds: string[]
  ) => {
    setSelectedInternallyForwardedIntentionIds(selectedIntentionIds);
  };

  const revertBilling = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');
    const month = moment(selectedDate).month();
    const year = moment(selectedDate).year();

    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Revert Billing'),
      message: gettextCatalog.getString('Do you want to revert billing?'),
      onOk() {
        IntentionService.revertBilling({
          month,
          year,
          churchIds: [selectedChurchId],
        })
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully restored billing.')
            );
            updateBillingEvents();
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  };

  const generateBillingReport = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');
    const month = moment(selectedDate).month();
    const year = moment(selectedDate).year();

    dispatch(
      generateIntentionBillingReport({
        month,
        year,
        churchIds: [selectedChurchId],
      })
    );
  };

  const updateBillingEvents = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');

    // If a month is selected
    if (selectedDate && selectedChurchId) {
      const month = moment(selectedDate).month();
      const year = moment(selectedDate).year();
      dispatch(
        fetchBillingIntentions({ month, year, churchIds: [selectedChurchId] })
      );
    } else {
      dispatch(clearBillingIntentions());
    }
  };

  const invalidateMouseEvent = (event: React.MouseEvent): void => {
    if (!event) return;
    event.preventDefault();
    event.stopPropagation();
  };

  const navigateToEvent = (intention: Intention, event: React.MouseEvent) => {
    invalidateMouseEvent(event);
    navigate('app.private.calendar.event', { id: intention.calendarId });
  };

  // Set disabled months in month selector
  const disabledDate = (current) =>
    current && current > moment().startOf('month');

  const shouldShowForwardingFields = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');

    return (
      selectedDate &&
      selectedChurchId &&
      areAllIntentionsAssigned() &&
      areAllIntentionsPaid()
    );
  };

  const shouldDisableIntentionSelectionForInternalForwarding = (
    intention: Intention
  ) =>
    // If an internal forwarding recipient is selected, allow ONLY selecting non-primary intentions
    !selectedInternalForwardingRecipientId ||
    intention.priority === IntentionPriorityTypes.PERSOLVIERT;
  const areAllIntentionsPaid = () =>
    /**
     * Since the "paid" flag is not guaranteed to be true or false (it could store as null)
     * the check verifies that every intention has its "paid" flag set to true.
     */
    !_.isEmpty(billingIntentions) && _.every(billingIntentions, ['paid', true]);
  const areAllIntentionsAssigned = () =>
    !_.isEmpty(billingIntentions) &&
    _.every(billingIntentions, ['status', IntentionStatusTypes.ASSIGNED]);

  const areAllIntentionsCompleted = () =>
    !_.isEmpty(billingIntentions) &&
    _.every(billingIntentions, ['status', IntentionStatusTypes.COMPLETED]);

  const updateButtonsState = () => {
    const selectedChurchId = form.getFieldValue('church');
    const selectedDate = form.getFieldValue('month');
    const selectedForwardingRecipientId = form.getFieldValue(
      'forwardingRecipient'
    );
    const selectedForwardingDate = form.getFieldValue('forwardingDate');

    setShouldDisableProcessButton(
      !selectedDate ||
        !selectedChurchId ||
        !selectedForwardingRecipientId ||
        !selectedForwardingDate ||
        _.isEmpty(billingIntentions) ||
        !areAllIntentionsAssigned() ||
        !areAllIntentionsPaid()
    );

    setShouldDisableRevertButton(
      !selectedDate ||
        !selectedChurchId ||
        _.isEmpty(billingIntentions) ||
        !areAllIntentionsCompleted() ||
        !areAllIntentionsPaid()
    );

    setShouldDisableGenerateReportButton(
      !selectedDate ||
        !selectedChurchId ||
        _.isEmpty(billingIntentions) ||
        !areAllIntentionsCompleted() ||
        !areAllIntentionsPaid()
    );
  };

  const tableColumns: any = [
    {
      title: gettextCatalog.getString('Number'),
      dataIndex: 'formattedReferenceNumber',
      key: 'formattedReferenceNumber',
    },
    {
      title: gettextCatalog.getString('Intention text'),
      dataIndex: 'intentionText',
      key: 'intentionText',
    },
    {
      title: gettextCatalog.getString('Intention Type'),
      dataIndex: ['fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Payment status'),
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment date'),
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: function columnRender(paidAt: Date) {
        return <div>{paidAt ? moment(paidAt).format('YYYY-MM-DD') : null}</div>;
      },
    },
    {
      title: gettextCatalog.getString('Status'),
      dataIndex: 'status',
      key: 'status',
      render: function columnRender(status: IntentionStatusTypes) {
        return (
          <div>
            <label>
              {status === IntentionStatusTypes.ASSIGNED
                ? gettextCatalog.getString('Open')
                : gettextCatalog.getString('Completed')}
            </label>
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Priority'),
      dataIndex: null,
      key: 'priority',
      render: function columnRender(intention: Intention) {
        const priority = intention.priority;
        if (intention.status === IntentionStatusTypes.COMPLETED) {
          return <CapitalizedDiv>{priority}</CapitalizedDiv>;
        } else if (intention.status === IntentionStatusTypes.ASSIGNED) {
          return (
            <div>
              {priority === IntentionPriorityTypes.PERSOLVIERT
                ? gettextCatalog.getString('Primary')
                : gettextCatalog.getString('Forwarded')}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: gettextCatalog.getString('Forwarding recipient'),
      dataIndex: 'forwardingRecipientId',
      key: 'forwardingRecipientId',
      render: function columnRender(
        forwardingRecipientId: string,
        intention: Intention
      ) {
        const internalForwardingRecipientId = _.get(
          intention,
          'internalForwardingRecipientId'
        );
        if (forwardingRecipientId) {
          const forwardingRecipientName = _.get(
            _.find(forwardedIntentionFeeRecipients, [
              'id',
              forwardingRecipientId,
            ]),
            'name'
          );
          return <div>{forwardingRecipientName}</div>;
        } else if (internalForwardingRecipientId) {
          const internalForwardingRecipient = _.find(churches, [
            'id',
            internalForwardingRecipientId,
          ]);
          let internalForwardingRecipientName = !_.isEmpty(
            internalForwardingRecipient.shortName
          )
            ? internalForwardingRecipient.shortName
            : internalForwardingRecipient.name;
          internalForwardingRecipientName = gettextCatalog.getString(
            'Internal Forwarding {{internalForwardingRecipientName}}',
            {
              internalForwardingRecipientName,
            }
          );
          return <div>{internalForwardingRecipientName}</div>;
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: gettextCatalog.getString('Forwarding date'),
      dataIndex: 'forwardedAt',
      key: 'forwardedAt',
      render: function columnRender(forwardedAt: Date) {
        return (
          <div>
            {forwardedAt ? moment(forwardedAt).format('YYYY-MM-DD') : null}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Calendar entry'),
      dataIndex: null,
      key: 'person',
      render: function columnRender(intention: Intention) {
        return (
          <div>
            <a
              onClick={(event: React.MouseEvent) =>
                navigateToEvent(intention, event)
              }
            >
              <Space direction="vertical">
                <label>
                  {gettextCatalog.formatDate(intention.event.startDate, 'LLL')}
                </label>
                <span>{intention.event.title}</span>
              </Space>
            </a>
          </div>
        );
      },
    },
  ];

  // Return the intentions home screen
  return (
    <CdPage
      pageHeaderProps={{
        title,
        extra: (
          <Button onClick={back}>{gettextCatalog.getString('Back')}</Button>
        ),
      }}
    >
      <StyledInlineForm
        form={form}
        layout="vertical"
        onValuesChange={updateButtonsState}
      >
        <Row gutter={16}>
          <Col span={8}>
            {/* Church - Required */}
            <Form.Item
              name="church"
              label={gettextCatalog.getString('Select a church:')}
              required={true}
              style={{ maxWidth: 'none' }}
            >
              <Select
                placeholder={gettextCatalog.getString('Parish')}
                onChange={updateBillingEvents}
                allowClear
              >
                {churches &&
                  churches.map((church) => (
                    <Option key={church.id} value={church.id}>
                      {_.get(church, 'name')}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* Month Selector */}
            <Form.Item
              name="month"
              label={gettextCatalog.getString('Select a month to bill:')}
              required={true}
              style={{ maxWidth: 'none' }}
            >
              <DatePicker
                picker="month"
                disabledDate={disabledDate}
                onChange={handleMonthSelection}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            {/* Forwarding recipient */}
            {shouldShowForwardingFields() ? (
              <Form.Item
                name="forwardingRecipient"
                label={gettextCatalog.getString(
                  'Select a forwarding recipient'
                )}
                required={true}
                style={{ maxWidth: 'none' }}
              >
                <Select
                  placeholder={gettextCatalog.getString('Forwarding Recipient')}
                  allowClear
                >
                  {forwardedIntentionFeeRecipients &&
                    forwardedIntentionFeeRecipients.map((recipient) => (
                      <Option key={recipient.id} value={recipient.id}>
                        {_.get(recipient, 'name')}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
          </Col>
          <Col span={8}>
            {/* Forwarding date */}
            {shouldShowForwardingFields() ? (
              <Form.Item
                name="forwardingDate"
                label={gettextCatalog.getString('Select a forwarding date')}
                required={true}
                style={{ maxWidth: 'none' }}
              >
                <DatePicker picker="date" style={{ width: '100%' }} />
              </Form.Item>
            ) : null}
          </Col>
          <Col span={8}>
            {/* Internal forwarding recipient */}
            {shouldShowForwardingFields() ? (
              <Form.Item
                name="internalForwardingRecipient"
                label={gettextCatalog.getString(
                  'Select an internal forwarding recipient'
                )}
                style={{ maxWidth: 'none' }}
              >
                <Select
                  placeholder={gettextCatalog.getString(
                    'Internal forwarding Recipient'
                  )}
                  onChange={handleInternalForwardingRecipientUpdate}
                  allowClear
                >
                  {getInternalForwardingRecipients().map((recipient) => (
                    <Option key={recipient.id} value={recipient.id}>
                      {_.get(recipient, 'name')}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
          </Col>
        </Row>

        <Space style={{ paddingBottom: 8, paddingTop: 8 }}>
          <Button
            onClick={processBilling}
            disabled={shouldDisableProcessButton}
          >
            <FontAwesomeIcon icon={faThumbtack} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Process Billing')}
          </Button>
          <Button onClick={revertBilling} disabled={shouldDisableRevertButton}>
            <FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Revert Billing')}
          </Button>
          <Button
            onClick={generateBillingReport}
            disabled={shouldDisableGenerateReportButton}
          >
            <FontAwesomeIcon icon={faPrint} style={{ marginRight: 8 }} />
            {gettextCatalog.getString('Generate Billing Report')}
          </Button>
        </Space>

        {!_.isEmpty(billingIntentions) && !areAllIntentionsPaid() ? (
          <Alert
            message={gettextCatalog.getString(
              'In order to process or print a month, all intentions must be paid'
            )}
            type="warning"
            style={{ marginTop: 8, marginBottom: 8 }}
            showIcon
          />
        ) : null}

        {/* Intentions list */}
        <StyledTable
          id="intentionsBillingTable"
          size="small"
          scroll={{ x: true }}
          bordered={true}
          rowKey="id"
          dataSource={billingIntentions}
          columns={tableColumns}
          loading={isLoading}
          pagination={false}
          onRow={(intention: Intention) => ({
            onClick: (event: React.MouseEvent) =>
              isIntentionCompleted(intention) || isIntentionDeleted(intention)
                ? null
                : navigateToIntention('edit', intention, event),
          })}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys: React.Key[]) => {
              handleInternalForwardingIntentionsChange(
                selectedRowKeys as string[]
              );
            },
            getCheckboxProps: (intention: Intention) => ({
              disabled:
                shouldDisableIntentionSelectionForInternalForwarding(intention),
            }),
          }}
          rowClassName={(intention: Intention) => {
            const paid = _.get(intention, 'paid');
            return _.isBoolean(paid) && paid ? null : 'warningRow';
          }}
          title={() => gettextCatalog.getString('Intentions to be billed:')}
        />
      </StyledInlineForm>
    </CdPage>
  );
};

export default IntentionsBilling;
