'use strict';

class CreateFolderModalComponent {
  constructor($window, $http, Folders, _) {
    'ngInject';

    this.$window = $window;
    this.$http = $http;
    this.Folders = Folders;
    this._ = _;
  }

  $onInit() {
    this.groups = this.resolve.groups;
    this.groupId =
      this.resolve.groupId && this.resolve.groupId !== -1
        ? parseInt(this.resolve.groupId, 10)
        : null;
    this.parentFolderId = this.resolve.parentFolderId;

    this.folder = new this.Folders({
      type: 'folder',
      groupId: this.groupId,
      organizationId: this.$window.churchdeskOrganizationId,
    });
  }

  saveFolder() {
    // Skip parentFolderId if id is -1 or root, that means it is the home folder.
    if (
      this.parentFolderId &&
      this.parentFolderId !== -1 &&
      this.parentFolderId !== 'root'
    ) {
      this.folder.parentFolderId = this.parentFolderId;
    }

    this.folder.$save().then((res) => {
      this.close({ $value: res });
    });
  }
}

CreateFolderModalComponent.$inject = ['$window', '$http', 'Folders', '_'];

angular.module('cdApp.shared').component('cdCreateFolderModal', {
  templateUrl:
    '@/app/shared/components/create-folder/create-folder.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: CreateFolderModalComponent,
});
