class ShareOnFacebookModalController {
  constructor(
    _,
    toastr,
    $state,
    gettextCatalog,
    Calendar,
    cdApp,
    AuthenticationService
  ) {
    'ngInject';

    this._ = _;
    this.toastr = toastr;
    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.Calendar = Calendar;
    this.cdApp = cdApp;
    this.AuthenticationService = AuthenticationService;
  }

  $onInit() {
    this.event = angular.copy(this.resolve.event);

    this.setIsAuthenticated();

    // Whether the event has been scheduled for posting
    this.hasBeenScheduled = true;

    // Mark the event for publishing
    if (!this._.get(this.event, 'facebook.publish')) {
      this.hasBeenScheduled = false;
      this.event.facebook = {
        publish: true,
        isScheduled: false,
      };
    }
  }

  setIsAuthenticated() {
    const { _, cdApp } = this;
    this.isAuthenticated = !_.isEmpty(_.get(cdApp, 'organization.facebook'));
  }

  onAuthenticated() {
    this.setIsAuthenticated();
  }

  post() {
    this.saveEvent(this.facebook);
  }

  unschedule() {
    this.saveEvent({ publish: false });
  }

  saveEvent(facebook) {
    const { _, toastr, gettextCatalog, Calendar } = this;

    const calendar = _.extend(
      _.pick(this.event, [
        'id',
        'type',
        'title',
        'visibility',
        'groupIds',
        'startDate',
        'endDate',
        'allDay',
        'rrule',
      ]),

      { facebook, churchIds: _.map(this.event.churches, 'id') }
    );

    this.isLoading = true;
    new Calendar(calendar)
      .$save()
      .then(() => {
        this.close();
      })
      .catch((error) => {
        toastr.error(
          _.get(error, 'data.message') ||
            gettextCatalog.getString(
              'An error occurred, please try again. If the problem persists, please contact our support.'
            )
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
ShareOnFacebookModalController.$inject = [
  '_',
  'toastr',
  '$state',
  'gettextCatalog',
  'Calendar',
  'cdApp',
  'AuthenticationService',
];

angular.module('cdApp.shared').component('cdShareOnFacebookModal', {
  template: require('./share-on-facebook-modal.component.html'),
  controller: ShareOnFacebookModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
