'use strict';

import IntentionReportTemplateService from '../../../react/settings/services/IntentionReportTemplateService';
import { getIntentionReportTemplates } from '../../../react/settings/redux/intention-report-templates/Selectors';
import {
  fetchIntentionReportTemplates,
  deleteIntentionReportTemplate,
} from '../../../react/settings/redux/intention-report-templates/Actions';

class ReportTemplatesOverview {
  constructor(
    appUtils,
    gettextCatalog,
    $ngRedux,
    $scope,
    $state,
    $stateParams,
    $uibModal
  ) {
    'ngInit';

    this.appUtils = appUtils;
    this.gettextCatalog = gettextCatalog;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  // Lifecycle functions

  $onInit() {
    this.fetchIntentionReportTemplates();
  }

  // Main controller functions

  createReportTemplate(action, data) {
    // 'edit', 'overview' or 'create'
    if (action === 'edit') {
      // edit
      this.$state.go('app.private.settings.reportTemplates.edit', {
        id: data.id,
        currentState: action,
      });
    } else {
      // create
      this.$state.go('app.private.settings.reportTemplates.create', {
        currentState: action,
      });
    }
  }

  removeReportTemplate(reportTemplate) {
    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Delete report template'),
          body: () =>
            this.gettextCatalog.getString(
              'Do you want to delete the report template {{name}}?',
              {
                name: reportTemplate.name,
              }
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Delete'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.deleteIntentionReportTemplate({ id: reportTemplate.id });
      });
  }

  downloadReportTemplateFile(reportTemplate) {
    if (!reportTemplate || !reportTemplate.id) {
      return this.toastr.error(
        this.gettextCatalog.getString(
          'The selected template cannot be downloaded.'
        )
      );
    }

    IntentionReportTemplateService.fetchIntentionReportTemplateDownloadToken()
      .then((downloadToken) => {
        const params = $.param({ downloadToken });

        window.location.href = `${cdApp.config.api.main}/intention/report-templates/${reportTemplate.id}/download?${params}`;
      })
      .catch((err) => {
        this.appUtils.showError(err.message, this.toastr, this.gettextCatalog);
      });
  }

  openTemplatePlaceholderDefinitionsModal() {
    this.$uibModal.open({
      templateUrl:
        '@/app/settings/report-templates/template-placeholder-definitions-modal.html',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          'ngInject';
          this.dismiss = $uibModalInstance.dismiss;
        },
      ],
      controllerAs: '$ctrl',
      size: 'lg',
    });
  }

  // AngularJS <-> Redux mapping functions

  mapStateToScope = (state) => {
    const reportTemplates = getIntentionReportTemplates(state);
    return {
      reportTemplates: reportTemplates
        ? reportTemplates.asMutable({ deep: true })
        : [],
    };
  };

  mapDispatchToScope = (dispatch) => ({
    fetchIntentionReportTemplates: (type) =>
      dispatch(fetchIntentionReportTemplates({ type })),
    deleteIntentionReportTemplate: ({ id, type }) =>
      dispatch(deleteIntentionReportTemplate({ id, type })),
  });
}
ReportTemplatesOverview.$inject = [
  'appUtils',
  'gettextCatalog',
  '$ngRedux',
  '$scope',
  '$state',
  '$stateParams',
  '$uibModal',
];

angular.module('cdApp.settings').component('cdReportTemplatesOverview', {
  template: require('./report-templates.component.html'),
  controller: ReportTemplatesOverview,
});
