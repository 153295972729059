import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import NewsletterList from '@/react/people/components/NewsletterList';
import ConsentTypeList from '@/react/people/pages/ConsentTypeList';

angular
  .module('cdApp.people')
  .component(
    'cdrNewsletterList',
    react2angular(sharedContext.use(NewsletterList), [], ['$stateParams'])
  );

angular
  .module('cdApp.people')
  .component(
    'cdrConsentTypeList',
    react2angular(sharedContext.use(ConsentTypeList), [], [])
  );
