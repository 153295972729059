import { react2angular } from 'react18-react2angular';

import {
  EventPopoverAttendance,
  EventPopoverDate,
} from '../../../react/calendar/components/EventPopover';
import sharedContext from '../../shared/components/shared-context-root/shared-context-root.component';

// Usage <cdr-event-popover-attendance event-id="2" event-type="event" title="Hello" trigger="hover">
angular
  .module('cdApp.calendar')
  .component(
    'cdrEventPopoverAttendance',
    react2angular(
      sharedContext.use(EventPopoverAttendance),
      ['eventId', 'eventType', 'title', 'trigger'],
      []
    )
  );

// Usage <cdr-event-popover-date event-id="2" event-type="event" date="Date">
angular
  .module('cdApp.calendar')
  .component(
    'cdrEventPopoverDate',
    react2angular(
      sharedContext.use(EventPopoverDate),
      ['eventId', 'eventType', 'date'],
      []
    )
  );
