import moment from 'moment';

import { getCalendarReportDownloadToken } from '@/react/calendar/reports/service/calendarExportService';
import cdApp from '@/react/config';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { getSelectedColumnsFromLocalStorage } from '@/react/shared/components/cd-table/store';

export interface ExportReportProps {
  filters: any;
  type: string;
  allCategories: {
    label: string;
    value: string;
    color: number;
  }[];
}

export const useExportReport = ({
  filters,
  type,
  allCategories,
}: ExportReportProps) => {
  const handleExport = async () => {
    try {
      const response = await getCalendarReportDownloadToken();
      if (!response?.token) {
        throw new Error('Invalid download token');
      }

      const activeColumns = getSelectedColumnsFromLocalStorage(
        `CalendarReport.${type}`
      );

      const params = {
        organizationId: cdApp.organization.id,
        downloadToken: response.token,
        startDate: moment(filters.startDate).toISOString(),
        endDate: moment(filters.endDate).endOf('day').toISOString(),
        search: filters.searchText || '',
        groups: filters.groupSelect || [],
        categories:
          filters.categorySelect?.length > 0
            ? filters.categorySelect
            : allCategories.map((item) => item.value),
        resources: filters.resourceSelect
          ?.filter((item) => item.includes('resource-'))
          ?.map((value) => value.replace('resource-', '')),
        eventsWithoutResourcesInChurchIds: filters.resourceSelect
          ?.filter((item) => item.includes('church-'))
          ?.map((value) => value.replace('church-', '')),
        users: filters.userSelect || [],
        visibility: filters.visibilitySelect,
        output: 'xlsx',
      };

      const groupsParams = params.groups
        .map((group) => `groups[]=${group}`)
        .join('&');
      const categoriesParams = params.categories
        .map((category) => `categories[]=${category}`)
        .join('&');
      const resourcesParams = params.resources
        .map((resource) => `resources[]=${resource}`)
        .join('&');
      const eventsWithoutResourcesInChurchIdsParams =
        params.eventsWithoutResourcesInChurchIds
          .map((event) => `eventsWithoutResourcesInChurchIds[]=${event}`)
          .join('&');
      const usersParams = params.users
        .map((user) => `users[]=${user}`)
        .join('&');
      const visibilityParams =
        params.visibility?.length > 0 ? `visibility=${params.visibility}` : '';
      const columnsParams = Object.keys(activeColumns)
        .filter((column) => activeColumns[column])
        .map((column) => `columns[]=${column}`)
        .join('&');

      const url = `${
        cdApp.config.api.main
      }/calendar/export/download?downloadToken=${
        response?.token
      }&organizationId=${cdApp.organization.id}&startDate=${
        params.startDate
      }&endDate=${params.endDate}&search=${
        params.search
      }&${groupsParams}&${categoriesParams}&${resourcesParams}&${eventsWithoutResourcesInChurchIdsParams}&${usersParams}&${columnsParams}&${visibilityParams}&output=${
        params.output
      }&type=${type === 'absence' ? 'absence' : 'event'}`;

      window.open(url, '_blank');
    } catch (error) {
      NotificationService.notifyError(
        gettextCatalog.getString(
          'An error occurred while exporting the report. Please try again.'
        )
      );
    }
  };
  return { handleExport };
};
