(function () {
  'use strict';

  function ViewShiftNoteController(
    $uibModalInstance,
    resolvedTask,
    resolvedNote
  ) {
    let $ctrl = this;

    $ctrl.task = resolvedTask;
    $ctrl.note = resolvedNote;

    $ctrl.close = function () {
      $uibModalInstance.dismiss();
    };
  }
  ViewShiftNoteController.$inject = [
    '$uibModalInstance',
    'resolvedTask',
    'resolvedNote',
  ];

  angular
    .module('cdApp.shared')
    .controller('ViewShiftNoteController', ViewShiftNoteController);
})();
