'use strict';

class LoginFormComponent {
  constructor(AuthenticationService, Analytics) {
    'ngInject';

    this.AuthenticationService = AuthenticationService;
    this.Analytics = Analytics;
  }

  $onInit() {
    this.loggingIn = false;
    this.passwordVisible = false;
    this.error = null;
  }

  logIn() {
    const { AuthenticationService, Analytics } = this;

    if (this.form.$invalid) return;
    this.loggingIn = true;

    AuthenticationService.login(this.email, this.password)
      .then(() => {
        this.onSuccess({ email: this.email });
        Analytics.sendFeatureEvent('login successful');
      })
      .catch((err) => {
        this.loggingIn = false;
        this.form.$setPristine();

        this.password = null;

        if (err) {
          this.error = err.message;
        }

        Analytics.sendFeatureEvent('login fail');
      });
  }

  togglePassword() {
    this.passwordVisible = !this.passwordVisible;
  }
}
LoginFormComponent.$inject = ['AuthenticationService', 'Analytics'];

angular.module('cdApp.shared').component('cdLoginForm', {
  templateUrl: '@/app/shared/components/login-form/login-form.component.html',
  controller: LoginFormComponent,
  bindings: {
    email: '<',
    readonlyEmail: '<',
    onSuccess: '&',
    onError: '&',
  },
});
