import React from 'react';
import { Button, Card, Col, Input, Row, Select, Space, Typography } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { ActionMenu } from '../../shared/components/cd-table';
import CdSimpleTable from '../../shared/components/cd-simple-table/CdSimpleTable';
import {
  CdCodeIcon,
  CdDeleteIcon,
  CdEditIcon,
  CdEyeIcon,
  CdExclamationTriangleIcon,
  EventIcons,
  CdCopyIcon,
} from '../../shared/components/Icons';
import AuthorizationService from '../../services/AuthorizationService';

import { WidgetInterface, WidgetType } from './types/widget.type';
import WidgetTableColumn from './WidgetTableColumns';
import { useWidget } from './hooks/use-widget';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { gettextCatalog } from '@/react/services/I18nService';

const { Search } = Input;
const WidgetList = () => {
  const {
    onSaveWidget,
    deleteWidget,
    WidgetSource,
    widgetList,
    setSearchKey,
    setTypes,
  } = useWidget();
  const canAccessHomepage =
    AuthorizationService.hasPermission('canAccessHomepage');
  const actionButtons: ActionMenu<WidgetInterface> = [
    {
      text: gettextCatalog.getString('Edit'),
      icon: <CdEditIcon />,
      disabled: (data) => !data?.permissions?.canEdit || !canAccessHomepage,
      onClick: (data) => {
        NiceModal.show('WidgetDrawer', {
          widgetData: data,
          onSaveWidget,
        });
      },
    },
    {
      text: gettextCatalog.getString('Preview'),
      icon: <CdEyeIcon />,
      disabled: false,
      onClick: (data) => {
        NiceModal.show('WidgetPreviewAndGetCodeModal', {
          widgetData: data,
        });
      },
    },
    {
      text: gettextCatalog.getString('Get code'),
      icon: <CdCodeIcon />,
      disabled: false,
      onClick: (data) => {
        NiceModal.show('WidgetPreviewAndGetCodeModal', { widgetData: data });
      },
    },
    {
      text: gettextCatalog.getString('Duplicate'),
      icon: <CdCopyIcon />,
      disabled: (data) => {
        const { type } = data;
        return (
          !data?.id ||
          (type !== WidgetType.EVENT && type !== WidgetType.BLOG) ||
          data?.legacy ||
          !canAccessHomepage
        );
      },
      onClick: async (data: WidgetInterface) => {
        const { type } = data;
        const widget = {
          ...data,
          name: data.name + ` (${gettextCatalog.getString('copy')})`,
        };
        await onSaveWidget({ widgetType: type, data: widget });
      },
    },
    {
      text: gettextCatalog.getString('Delete'),
      icon: <CdDeleteIcon />,
      disabled: (data) => !data?.permissions?.canDelete || !canAccessHomepage,
      onClick: (data) => {
        showConfirmModal({
          title: gettextCatalog.getString('Delete widget?'),
          message: gettextCatalog.getString(
            'Are you sure you want to delete the widget {{ widgetName }}?',
            { widgetName: data?.name }
          ),
          icon: <CdExclamationTriangleIcon />,
          okButtonProps: { danger: true },
          okText: gettextCatalog.getString('Delete'),
          onOk: async () => {
            await deleteWidget(data.id);
          },
        });
      },
    },
  ];

  return (
    <Card style={{ marginTop: '32px', marginBottom: '32px' }}>
      <Row gutter={[12, 12]}>
        <Col
          span={24}
          flex={2}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text strong>
            {gettextCatalog.getPlural(
              widgetList.total,
              '1 widget',
              '{{count}} widgets',
              {
                count: widgetList.total,
              }
            )}
          </Typography.Text>
          <Space>
            {gettextCatalog.getString('Filter by:')}
            <Select
              mode="multiple"
              style={{ minWidth: '200px' }}
              options={[
                {
                  label: gettextCatalog.getString('Event'),
                  value: 'event',
                },
                {
                  label: gettextCatalog.getString('Blog'),
                  value: 'blog',
                },
                {
                  label: gettextCatalog.getString('Donation'),
                  value: 'donation',
                },
                {
                  label: gettextCatalog.getString('Slideshow'),
                  value: 'slideshow',
                },
              ]}
              placeholder={gettextCatalog.getString('Choose type...')}
              onChange={setTypes}
            />
            <Search
              placeholder="Search..."
              style={{ width: 200 }}
              onChange={(e) => setSearchKey(e.currentTarget.value)}
            />
            <Button
              type="primary"
              disabled={!canAccessHomepage}
              onClick={() =>
                NiceModal.show('WidgetDrawer', {
                  onSaveWidget,
                })
              }
            >
              {gettextCatalog.getString('Create widget')}
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <CdSimpleTable<WidgetInterface>
            noStyle
            recoilSource={WidgetSource}
            emptyStateProps={{
              title: gettextCatalog.getString('No Widgets'),
              EmptyStateIcon: <EventIcons.Participants />,
            }}
            columns={WidgetTableColumn()}
            actionButtons={actionButtons}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default WidgetList;
