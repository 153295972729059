import { react2angular } from 'react18-react2angular';

import { CreateStoleComponent } from '../../../../react/intention/components';

angular.module('cdApp.calendar').component(
  'cdrCreateStole',
  react2angular(
    CreateStoleComponent,
    [
      'mainTaxonomy',
      'eventYear',
      'updateParentOnChange',
      'selectedChurchIds',
      'selectedResourceIds',
      'calendarId',
    ],

    []
  )
);
