import { useCallback } from 'react';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { Action, Reducer } from 'redux';
import * as immutable from 'seamless-immutable';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

export interface CdAction<Payload> extends Action<string> {
  payload?: Payload;
}

export type Immutable<T> = immutable.Immutable<T>;

export type ActionCreator<Payload> = (payload?: Payload) => CdAction<Payload>;

export default function createReducer<TState>(
  initialState: TState,
  handler: {
    [action: string]: Reducer<TState, Action>;
  }
): Reducer<TState, Action> {
  return (state = initialState, action: Action): TState => {
    if (action.type && handler[action.type]) {
      return handler[action.type](state, action);
    } else return state;
  };
}

export function createActionDispatcherHook<TPayload>(
  actionCreator: ActionCreatorWithOptionalPayload<TPayload>
) {
  return function useActionDispatcher() {
    const dispatch = useDispatch();
    return useCallback(
      (payload?: TPayload) => {
        dispatch(actionCreator(payload));
      },
      [dispatch]
    );
  };
}

export function createActionValueHook<TState, TValue, TPayload>(
  action: ActionCreatorWithOptionalPayload<TPayload>,
  selector: Selector<TState, TValue>
): () => [TValue, (payload: TPayload) => void] {
  return function useActionValue() {
    const dispatch = useDispatch();
    const actionDispatch = useCallback(
      (payLoad: TPayload) => {
        dispatch(action(payLoad));
      },
      [dispatch]
    );
    const value = useSelector(selector);
    return [value, actionDispatch];
  };
}
