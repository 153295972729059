import React from 'react';
import { Result } from 'antd';

import { gettextCatalog } from '@/react/services/I18nService';

export const CdErrorPage = ({
  subTitle,
  title,
}: {
  subTitle?: React.ReactNode;
  title?: string;
}) => (
  <Result
    style={{ margin: 'auto' }}
    status="error"
    title={title || gettextCatalog.getString('An error has occurred')}
    subTitle={
      subTitle ||
      gettextCatalog.getString(
        'Try to refresh the page. Please contact support if the error continues.'
      )
    }
  />
);
