import _ from 'lodash';

const calendarProperties = [
  'id',
  'type',
  'title',
  'visibility',
  'groupIds',
  'startDate',
  'endDate',
  'allDay',
  'rrule',
  'calendar_tasks',
  'shifts',
];

export const ON_ROTAS_ASSIGNEES_CHANGED = 'ON_ROTAS_ASSIGNEES_CHANGED';

class ShiftsTableController {
  constructor(
    $scope,
    $timeout,
    $state,
    $stateParams,
    $uibModal,
    gettextCatalog,
    Me,
    Calendar,
    Planning,
    Shifts,
    paginationOptions
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
    this.Me = Me;
    this.Calendar = Calendar;
    this.Planning = Planning;
    this.Shifts = Shifts;
    this.paginationOptions = paginationOptions;

    this.defaultLimit = 6;
    this.defaultOffset = 0;
  }

  $onInit() {
    const { $scope } = this;

    $scope.$on(ON_ROTAS_ASSIGNEES_CHANGED, () => {
      this.loadRotas();
    });
  }

  /**
   * Used for initializing values that are used inside `$onChanges`.
   * Couldn't use `$onInit` because AngularJS runs `$onChanges` before `$onInit`.
   */
  initialize() {
    const { $stateParams, gettextCatalog, paginationOptions } = this;

    // Searching
    const search = !_.isEmpty($stateParams.search) ? $stateParams.search : null;

    // Filtering
    const period = !_.isEmpty($stateParams.period)
      ? $stateParams.period
      : 'upcoming';

    // Sorting
    const orderBy = !_.isEmpty($stateParams.orderBy)
      ? $stateParams.orderBy
      : 'startDate';
    const desc = $stateParams.desc || false;

    // Pagination
    if (!this.hidePagination) {
      this.sortingOptions = [
        { name: 'startDate', title: gettextCatalog.getString('Event date') },
        { name: 'eventTitle', title: gettextCatalog.getString('Event title') },
        { name: 'taskTitle', title: gettextCatalog.getString('Rota') },
      ];

      this.paginationStorageKey = `shifts-${this.mode}`;
      const savedValue = paginationOptions.itemsPerPage.get(
        this.paginationStorageKey
      );

      if (!savedValue) {
        this.itemsPerPage = 10;
        paginationOptions.itemsPerPage.set(
          this.paginationStorageKey,
          this.itemsPerPage
        );
      } else {
        this.itemsPerPage = _.parseInt(savedValue);
      }
    }

    // Update the model
    this.search = search;
    this.period = period;
    this.orderBy = orderBy;
    this.desc = desc;

    if (this.loadOnInit) this.loadRotas();
  }

  /**
   * Update the values in the controller to match those in the URL.
   *
   * @param <Object> newParams If not provided, default values will be used for filters and pagination
   */
  $onChanges({ queryParameters }) {
    if (queryParameters.isFirstChange()) {
      this.initialize();
    }

    const { currentValue, previousValue } = queryParameters;
    const search = _.get(currentValue, 'search');
    const period = _.get(currentValue, 'period');
    const orderBy = _.get(currentValue, 'orderBy');
    const desc = _.get(currentValue, 'desc');
    const pageParamName =
      this.mode === 'open-rotas' ? 'pageOpenRotas' : 'pageMyRotas';
    const pageNumber = _.get(currentValue, pageParamName) || 1;
    /**
     * If the component is handling `my-rotas`, then check whether the `filter` options, `sorting` options and `pagination`
     * options for `my-rotas` have changed in order to determine if the UI should be reloaded. If handling `open-rotas` instead,
     * only check whether the `pagination` options for `open-rotas` have changed for determining whether to reload or not the UI.
     */
    const pickedProperties =
      this.mode === 'open-rotas'
        ? [pageParamName]
        : ['search', 'period', 'orderBy', 'desc', pageParamName];
    const shouldReload = !_.isEqual(
      _.pick(previousValue, pickedProperties),
      _.pick(currentValue, pickedProperties)
    );

    if (!shouldReload) return;

    // Update the model
    this.search = search;
    this.period = period;
    this.orderBy = orderBy;
    this.desc = desc;
    this.pageNumber = pageNumber;

    this.loadRotas();
  }

  loadRotas() {
    const { Shifts } = this;

    // Reload rotas based on the configuration in the controller (pagination, filtering and sorting)
    const params =
      this.mode === 'open-rotas'
        ? {
            type: 'open',
            period: 'upcoming',
            orderBy: 'startDate',
            desc: false,
          }
        : {
            type: 'user',
            period: this.period,
            search: this.search,
            orderBy: this.orderBy,
            desc: this.desc,
          };

    params.limit = this.hidePagination ? this.defaultLimit : this.itemsPerPage;
    params.offset = this.hidePagination
      ? this.defaultOffset
      : this.itemsPerPage * ((this.pageNumber || 1) - 1);

    this.rotas = Shifts.getAll(params, (response) => {
      // If the user changes the URL themselves, the count will have a value,
      // but the items will be an empty array, so we make sure the user is
      // redirected to the first page.
      if (response.count > 0 && _.isEmpty(response.items)) {
        this.backToFirstPage();
      }
    });
  }

  /**
   * Update the URL's query parameters.
   */
  updateUrlState(forceReload) {
    const { $state, $stateParams } = this;

    /**
     * If the component is handling `my-rotas`, then set the `filter` options, the `sorting` options and the `pagination`
     * options for `my-rotas` before changing the state, since all those parameters should update the URL. If handling
     * `open-rotas` instead, only set the `pagination` for `open-rotas` for updating the URL.
     */
    const newStateParams =
      this.mode === 'open-rotas'
        ? {
            pageOpenRotas: this.pageNumber,
          }
        : {
            search: this.search,
            pageMyRotas: this.pageNumber,
            period: this.period,
            orderBy: this.orderBy,
            desc: this.desc,
          };

    $state.go($state.current, _.extend({}, $stateParams, newStateParams));

    if (forceReload) {
      this.loadRotas();
    }
  }

  /**
   * When the user changes the rota's type: 'upcoming' or 'past',
   * update the URL to have the new rota's type.
   */
  onPeriodChanged(period) {
    if (this.period === period) return;
    this.period = period;
    this.updateUrlState();
  }

  getSortingClass(sortingOption) {
    return this.orderBy === sortingOption.name && !this.desc
      ? 'fa fa-sort-alpha-down'
      : 'fa fa-sort-alpha-up';
  }

  getSortingOptionString(sortingOption) {
    const { gettextCatalog } = this;

    return `${sortingOption.title} (${
      this.orderBy === sortingOption.name && !this.desc
        ? gettextCatalog.getString('desc')
        : gettextCatalog.getString('asc')
    })`;
  }

  getCurrentOrderByString() {
    const { gettextCatalog } = this;

    const sortingOption = _.find(this.sortingOptions, { name: this.orderBy });
    return `${_.get(sortingOption, 'title')} (${
      this.desc
        ? gettextCatalog.getString('desc')
        : gettextCatalog.getString('asc')
    })`;
  }

  getEmptyStateTitle() {
    const { gettextCatalog } = this;

    return this.mode === 'open-rotas'
      ? gettextCatalog.getString('All rotas are covered')
      : gettextCatalog.getString('You are not assigned to any rota');
  }

  /**
   * Go back to the first page and update the URL to have a query parameter of page = 1.
   */
  backToFirstPage(forceReload = false) {
    this.onPageChanged(1, forceReload);
  }

  /**
   * When pagination changes, update the URL to have the new page number.
   */
  onPageChanged(page, forceReload = false) {
    this.pageNumber = page;
    this.updateUrlState(forceReload);
  }

  /**
   * When items per page changes, go back to the first page
   * (force a reload to make sure rotas are reloaded even if the user was
   * at the first page when he changed the items per page count).
   */
  onItemsPerPageChanged(itemsPerPage) {
    this.itemsPerPage = itemsPerPage;
    this.backToFirstPage(true);
  }

  setSorting(sortingOption) {
    if (this.orderBy === sortingOption.name) {
      this.desc = !this.desc;
    } else {
      this.orderBy = sortingOption.name;
      this.desc = false;
    }
    this.updateUrlState();
  }

  takeRota(rota) {
    const { Planning, Me } = this;

    this.isRotaManagementLoading = true;
    this.loadCalendarItem(rota.calendar.id)
      .then((calendar) => {
        const calendarShifts = calendar.shifts || [];
        calendarShifts.push({ taskId: rota.taskId, userId: Me.id });
        const payload = _.extend(calendar, { shifts: calendarShifts });
        return Planning.saveTaskManagementChanges(payload, 'assign', true);
      })
      .then(() => {
        this.loadRotas();
        this.onAssigneesChanged();
      })
      .finally(() => {
        this.isRotaManagementLoading = false;
      });
  }

  unassignUser(rota) {
    const { Planning, Me } = this;

    this.isRotaManagementLoading = true;
    this.loadCalendarItem(rota.calendar.id)
      .then((calendar) => {
        const calendarShifts = _.reject(
          calendar.shifts,
          (calendarShift) =>
            calendarShift.taskId === rota.taskId &&
            calendarShift.userId === Me.id
        );

        const payload = _.extend(calendar, { shifts: calendarShifts });
        return Planning.saveTaskManagementChanges(payload, 'un-assign', true);
      })
      .then(() => {
        this.loadRotas();
        this.onAssigneesChanged();
      })
      .finally(() => {
        this.isRotaManagementLoading = false;
      });
  }

  loadCalendarItem(id) {
    const { Calendar } = this;

    return Calendar.get({ id }).$promise.then((calendar) =>
      _.extend({}, _.pick(calendar, calendarProperties), {
        churchIds: _.map(calendar.churches, 'id'),
      })
    );
  }

  showTaskNote(rota) {
    const { $uibModal } = this;

    $uibModal.open({
      templateUrl:
        '@/app/shared/components/shifts-view-note/shifts-view-note.html',
      controller: 'ViewShiftNoteController',
      controllerAs: '$ctrl',
      windowClass: 'modal-scrollable',
      resolve: {
        resolvedTask: () => rota.task.title,
        resolvedNote: () => rota.note,
      },
    });
  }
}
ShiftsTableController.$inject = [
  '$scope',
  '$timeout',
  '$state',
  '$stateParams',
  '$uibModal',
  'gettextCatalog',
  'Me',
  'Calendar',
  'Planning',
  'Shifts',
  'paginationOptions',
];

angular.module('cdApp.shared').component('cdShiftsList', {
  templateUrl: '@/app/shared/components/shifts-list/shifts-list.html',
  controller: ShiftsTableController,
  bindings: {
    mode: '@',
    loadOnInit: '<',
    compactButtons: '<',
    queryParameters: '<',
    hidePagination: '<',
    hideFilters: '<',
    hideSearch: '<',
    onAssigneesChanged: '&',
  },
});
