'use strict';

function organizationModel($$resource, cdApp) {
  'ngInject';

  return $$resource(`${cdApp.config.endpoint}/v2/organizations/:id`, null, {
    validateDomain: {
      method: 'POST',
      url: `${cdApp.config.endpoint}/v2/organizations/:id/validate/domain`,
      isArray: false,
    },

    checkSubscription: {
      method: 'GET',
      url: `${cdApp.config.endpoint}/v2/organizations/:id/subscription`,
    },

    isLocked: {
      method: 'GET',
      url: `${cdApp.config.endpoint}/v2/organizations/:id/locked`,
    },

    getBilling: {
      method: 'GET',
      url: `${cdApp.config.endpoint}/v2/organizations/:id/billing`,
      isArray: true,
    },

    hasMVR: {
      method: 'GET',
      url: `${cdApp.config.endpoint}/organizations/:id/account/has-mvr`,
    },
  });
}
organizationModel.$inject = ['$$resource', 'cdApp'];
angular.module('cdApp.shared').factory('Organizations', organizationModel);
