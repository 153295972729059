class CreateEventTaskComponent {
  constructor($q, Groups, Tasks, cdApp) {
    'ngInject';

    this.$q = $q;
    this.Groups = Groups;
    this.Tasks = Tasks;
    this.cdApp = cdApp;
  }

  $onInit() {
    const { Groups, cdApp } = this;

    Groups.query().$promise.then((groups) => {
      /**
       * A user can only add tasks to groups that they're an admin of
       */
      const myGroups = _.filter(groups, (group) =>
        _.includes(group.leaders, cdApp.me.id)
      );

      /**
       * We show a list of groups that doesn't have tasks
       */
      this.groups = _.filter(
        myGroups,
        (group) => group.tasks && _.isEmpty(group.tasks)
      );
    });

    this.createNewGroup = true;
  }

  shouldDisableCreateButton() {
    const missingNewGroupName = this.createNewGroup && !this.groupName;
    const missingTask = !this.createNewGroup && !this.taskGroupId;
    const missingTaskTitle = !this.taskTitle;
    return missingNewGroupName || missingTask || missingTaskTitle;
  }

  submit() {
    const { $q, Groups, Tasks } = this;

    /**
     * Create the group first if needed
     */
    const createNewGroupPromise = !this.createNewGroup
      ? $q.resolve()
      : /**
         * We're passing `_.noop` to the `$save` method in order to override the default toastr message that shows if no callback is provided
         */
        new Groups({ name: this.groupName }).$save(_.noop);

    createNewGroupPromise.then((createdGroup) => {
      const selectedGroup = _.find(this.groups, { id: this.taskGroupId });

      const group = this.createNewGroup
        ? {
            id: _.get(createdGroup, 'id'),
            name: this.groupName,
            picture: null,
          }
        : {
            id: this.taskGroupId,
            name: _.get(selectedGroup, 'name'),
            picture: _.get(selectedGroup, 'picture'),
          };

      /**
       * Create the task and associate it with the created/selected group
       */
      new Tasks({
        title: this.taskTitle,
        groupId: group.id,
      })
        .$save(_.noop)
        .then(({ id, title }) => {
          const task = {
            id,
            title,
            groupId: group.id,
            group,
          };

          this.close({ $value: task });
        });
    });
  }
}
CreateEventTaskComponent.$inject = ['$q', 'Groups', 'Tasks', 'cdApp'];

angular.module('cdApp.shared').component('createEventTaskModal', {
  template: require('./create-event-task.component.html'),
  controller: CreateEventTaskComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
