import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { FEvent as Event } from '../../../calendar/models/calendar';
import createReducer from '../../../redux/utils';
import {
  Intention,
  IntentionFilters,
  IntentionTabNames,
} from '../../models/intention';
import { Foundation } from '../../models/foundation';

import {
  CLEAR_INTENTION,
  CLEAR_INTENTIONS,
  FETCH_INTENTION_EVENTS_SUCCESS,
  FETCH_INTENTIONS_SUCCESS,
  FETCH_INTENTION_SUCCESS,
  FETCH_EVENT_INTENTIONS_SUCCESS,
  FETCH_INTENTION_PARENT_SUCCESS,
  CLEAR_INTENTION_PARENT,
  FETCH_BILLING_INTENTIONS_SUCCESS,
  CLEAR_BILLING_INTENTIONS,
  FetchIntentionEventsSuccessType,
  FetchIntentionsSuccess,
  FetchIntentionSuccessType,
  FetchEventIntentionsSuccess,
  FetchIntentionParentSuccessType,
  FetchBillingIntentionsSuccessType,
  CLEAR_INTENTION_EVENTS,
  CLEAR_EVENT_INTENTIONS,
  SetIntentionFiltersActionType,
  SET_INTENTION_FILTERS,
  SetShouldShowMoreFiltersActionType,
  SET_SHOULD_SHOW_MORE_FILTERS,
  SetIntentionsOverviewTabActionType,
  SET_INTENTIONS_OVERVIEW_TAB,
} from './Actions';

export interface IntentionState {
  intentions: Intention[];
  intentionsCount: number;
  intention: Partial<Intention>;
  eventIntentions: Intention[];
  intentionEvents: Event[];
  intentionParent: Partial<Foundation>;
  billingIntentions: Intention[];
  intentionFilters: Partial<IntentionFilters>;
  shouldShowMoreFilters: boolean;
  intentionsOverviewTab: IntentionTabNames;
}

export type ImmutableIntentionState = immutable.ImmutableObject<IntentionState>;

const initialState: ImmutableIntentionState = immutable.from<IntentionState>({
  intentions: [],
  intentionsCount: 0,
  intention: null,
  eventIntentions: [],
  intentionEvents: [],
  intentionParent: null,
  billingIntentions: null,
  intentionFilters: {},
  shouldShowMoreFilters: false,
  intentionsOverviewTab: IntentionTabNames.UNASSIGNED,
});

const clearIntention: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    intention: null, // Clear selected Intention (edit/view)
  });

const clearIntentions: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    intentions: [],
    intentionsCount: 0,
  });

const clearIntentionParent: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    intentionParent: null,
  });

const clearBillingIntentions: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    billingIntentions: null,
  });

const fetchIntentionsSuccess: Reducer<
  ImmutableIntentionState,
  FetchIntentionsSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    intentions: payload.items || [],
    intentionsCount: payload.count || 0,
  });

const fetchIntentionSuccess: Reducer<
  ImmutableIntentionState,
  FetchIntentionSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    intention: payload,
  });

const fetchEventIntentionsSuccess: Reducer<
  ImmutableIntentionState,
  FetchEventIntentionsSuccess
> = (state = initialState, { payload }) =>
  state.merge({
    eventIntentions: payload || [],
  });

const fetchIntentionEventsSuccess: Reducer<
  ImmutableIntentionState,
  FetchIntentionEventsSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionEvents: payload || [],
  });

const clearIntentionEvents: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    intentionEvents: [],
  });

const clearEventIntentions: Reducer<ImmutableIntentionState> = (
  state = initialState
) =>
  state.merge({
    eventIntentions: [],
  });

const fetchIntentionParentSuccess: Reducer<
  ImmutableIntentionState,
  FetchIntentionParentSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionParent: payload,
  });

const fetchBillingIntentionsSuccess: Reducer<
  ImmutableIntentionState,
  FetchBillingIntentionsSuccessType
> = (state = initialState, { payload }) =>
  state.merge({
    billingIntentions: payload,
  });

const setIntentionFilters: Reducer<
  ImmutableIntentionState,
  SetIntentionFiltersActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionFilters: payload,
  });

const setShouldShowMoreFilters: Reducer<
  ImmutableIntentionState,
  SetShouldShowMoreFiltersActionType
> = (state = initialState, { payload }) =>
  state.merge({
    shouldShowMoreFilters: payload,
  });

const setIntentionsOverviewTab: Reducer<
  ImmutableIntentionState,
  SetIntentionsOverviewTabActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionsOverviewTab: payload,
  });

// Handlers

const handlers = {
  [CLEAR_INTENTION]: clearIntention,
  [CLEAR_INTENTIONS]: clearIntentions,
  [CLEAR_EVENT_INTENTIONS]: clearEventIntentions,
  [CLEAR_BILLING_INTENTIONS]: clearBillingIntentions,
  [CLEAR_INTENTION_PARENT]: clearIntentionParent,
  [FETCH_INTENTIONS_SUCCESS]: fetchIntentionsSuccess,
  [FETCH_INTENTION_SUCCESS]: fetchIntentionSuccess,
  [FETCH_EVENT_INTENTIONS_SUCCESS]: fetchEventIntentionsSuccess,
  [FETCH_INTENTION_EVENTS_SUCCESS]: fetchIntentionEventsSuccess,
  [CLEAR_INTENTION_EVENTS]: clearIntentionEvents,
  [FETCH_INTENTION_PARENT_SUCCESS]: fetchIntentionParentSuccess,
  [FETCH_BILLING_INTENTIONS_SUCCESS]: fetchBillingIntentionsSuccess,
  [SET_INTENTION_FILTERS]: setIntentionFilters,
  [SET_SHOULD_SHOW_MORE_FILTERS]: setShouldShowMoreFilters,
  [SET_INTENTIONS_OVERVIEW_TAB]: setIntentionsOverviewTab,
};

export default createReducer<ImmutableIntentionState>(initialState, handlers);
