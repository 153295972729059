'use strict';

class LoginPromptComponent {
  constructor($state, Me, AuthenticationService, toastr, gettextCatalog) {
    'ngInject';

    this.$state = $state;
    this.AuthenticationService = AuthenticationService;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;

    this.loggingIn = false;

    Me.then(() => {
      this.email = Me.email;
    });
  }

  onLogin(email) {
    if (email === this.email) {
      this.toastr.success(
        this.gettextCatalog.getString('You have been successfully logged in.')
      );

      this.close();
    } else {
      window.location.reload();
    }
  }

  forgotPassword() {
    this.AuthenticationService.logout(false).then(() => {
      window.location = this.$state.href('app.public.forgotPassword');
    });
  }
}
LoginPromptComponent.$inject = [
  '$state',
  'Me',
  'AuthenticationService',
  'toastr',
  'gettextCatalog',
];

angular.module('cdApp.shared').component('cdLoginPrompt', {
  templateUrl:
    '@/app/shared/components/login-prompt/login-prompt.component.html',
  controller: LoginPromptComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
