'use strict';

function remember(resource) {
  let memory = _.cloneDeep(resource);
  memory = _.pick(memory, _.keys(resource));
  resource.$pristine = memory;
  return memory;
}

function deprecate(original) {
  if (_.isFunction(this)) {
    throw new Error('deprecated call');
  }

  return original.apply(this, _.tail(arguments));
}

function $$resource(
  $window,
  $resource,
  localStorageService,
  toastr,
  gettextCatalog
) {
  return function (url, params, methods) {
    methods = angular.extend(
      {
        create: { method: 'POST' },
        update: { method: 'PUT', isArray: false },
      },

      methods
    );

    params = angular.extend(
      {
        organizationId: $window.churchdeskOrganizationId,
        id: '@id',
      },

      params
    );

    let constructor = $resource(url, params, methods);
    let resource = function () {
      constructor.apply(this, arguments);
      remember(this);
      return this;
    };

    resource.prototype = constructor.prototype;
    angular.extend(resource, constructor);

    resource.save = _.wrap(constructor.save, deprecate);
    resource.remove = _.wrap(constructor.remove, deprecate);
    resource.delete = _.wrap(constructor.delete, deprecate);

    resource.query = _.wrap(constructor.query, function (original) {
      // $resource overrides the old object content when the server returns something.
      // we don't like it and the current function saves a cached version of our resource for us.
      let That = this;
      let result = original.apply(this, _.tail(arguments));

      result.$add = function (data, success, error) {
        let resource = new That(data);
        return resource.$save(function () {
          result.push(this);
          success.apply(this, arguments);
        }, error);
      };

      result.$promise.then(function (items) {
        _.each(items, function (item) {
          item.$delete = _.wrap(item.$delete, function (original) {
            let promise = original.apply(this, _.tail(arguments));

            promise.then(function () {
              _.remove(items, item);
            });

            return promise;
          });
        });
      });

      return result;
    });

    resource.prototype.$undo = function () {
      // return the resource to its unmodified stage
      let that = this;
      let memory = this.$pristine;

      angular.forEach(that, function (value, key) {
        delete that[key];
      });

      _.assign(that, memory);

      if (that.$pristine === undefined) {
        remember(that);
      }

      return that;
    };

    /**
     *
     * @callback RequestSuccessCallback
     * @param {*} updatedResource
     * @callback RequestErrorCallback
     * @param {*} errorResponse
     */

    /**
     *
     * @param {Object[]} omitList - List of object fields that should be omited before save
     * @param {string} omitList[].field - Name of the field
     * @param {Boolean} omitList[].condition - Value to determine if a field should be removed from the payload
     * @param {RequestSuccessCallback} [success]
     * @param {RequestSuccessCallback} [error]
     */
    resource.prototype.$save = function () {
      // $resource overrides the old object content when the server returns something.
      // we don't like it and the current function undo it for us.
      let that = this;
      let args = Array.prototype.slice.call(arguments);
      let success,
        error,
        omits = [];

      while (args.length && _.last(args) === undefined) {
        args.pop();
      }

      if (_.isFunction(_.last(args))) {
        success = args.pop();
      }

      if (_.isFunction(_.last(args))) {
        error = success;
        success = args.pop();
      }

      if (_.isArray(_.last(args))) {
        omits = args.pop();
      }

      if (!success) {
        success = function () {
          toastr.success(gettextCatalog.getString('Content saved.'));
        };
      }

      if (!error) {
        error = function (response) {
          toastr.error(
            gettextCatalog.getString('Content not saved: {{error}}', {
              error: (response.data || response).message,
            })
          );
        };
      }

      const omitList = ['$pristine'].concat(
        omits
          .filter(({ condition }) => !!condition)
          .map(({ field }) => field) || []
      );

      let createMode = !this.id;
      let method = createMode ? this.$create : this.$update;
      let promise = method.apply(_.omit(this, omitList), args);
      let memory = remember(this);
      delete memory.$pristine;

      promise.then(function (response) {
        if (createMode) {
          memory.id = response.id;
        }

        that.$pristine = memory;
        that.$undo();

        success.apply(that, arguments);
      }, error);

      return promise;
    };

    resource.prototype.filter = function (predicate, callback) {
      this.query(function (segments) {
        callback(_.filter(segments, predicate));
      });
    };

    return resource;
  };
}
$$resource.$inject = [
  '$window',
  '$resource',
  'localStorageService',
  'toastr',
  'gettextCatalog',
];

angular.module('cdApp.shared').factory('$$resource', $$resource);
