(function () {
  'use strict';
  Files.$inject = ['$$resource', 'cdApp'];
  function Files($$resource, cdApp) {
    const baseUrl = `${cdApp.config.api.main}/files/:id`;
    return $$resource(
      baseUrl,
      {},
      {
        // Retrieves crop information for a file and a context.
        getCropInformation: {
          method: 'GET',
          url: `${baseUrl}/crop-information`,
          params: {
            context: null, // The context of the crop. Example 'event-612'
          },
        },

        // Updates the crop information for a file and a context.
        updateCropInformation: {
          method: 'PUT',
          url: `${baseUrl}/crop-information`,
        },

        getDownloadToken: {
          method: 'GET',
          url: `${cdApp.config.api.main}/files/download/token`,
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Files', Files);
})();
