(function () {
  'use strict';
  CalendarPrint.$inject = ['$$resource'];

  function CalendarPrint($$resource) {
    return $$resource(`${cdApp.config.api.main}/calendar/print`, null, {
      get: {
        method: 'GET',
        isArray: false,
      },
    });
  }

  angular.module('cdApp.shared').factory('CalendarPrint', CalendarPrint);
})();
