import { useRecoilCallback } from 'recoil';

import { PeopleListByTypeAndSearchQuery } from '../store/newsletterListState';
import { Segment } from '../types/segment.type';
import { peopleSegmentsState } from '../store/peopleSegment';
import later from '../services/promiseTimeout';

import { gettextCatalog } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { mainApi } from '@/react/api';

const useParticipantList = () => {
  const createParticipantList = useRecoilCallback(
    ({ refresh }) =>
      async (
        name: string,
        setup: {
          churchIds: number[];
        },
        filterIds: number[],
        type: string
      ) => {
        const response = await mainApi.post<Segment>(`/people/segments`, {
          name,
          setup,
          filterIds,
          type,
        });
        if (!response.ok) {
          NotificationService.notifyError((response as any)?.data?.message);
          throw response;
        } else {
          if (filterIds) await later(5000);

          NotificationService.notifySuccess(
            gettextCatalog.getString('List was created!')
          );
          refresh(peopleSegmentsState);
          refresh(
            PeopleListByTypeAndSearchQuery({
              type: 'participant',
              searchParameter: '',
            })
          );
        }
      },
    []
  );

  return {
    createParticipantList,
  };
};

export default useParticipantList;
