import React from 'react';
import { Switch, Space } from 'antd';
import styled from 'styled-components';

const WidgetStyleButton = styled.div`
  width: 50px;
  height: 50px;

  div {
    overflow: hidden;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAFpJREFUWAntljEKADAIA23p6v//qQ+wfUEcCu1yriEgp0FHRJSJcnehmmWm1Dv/lO4HIg1AAAKjTqm03ea88zMCCEDgO4HV5bS757f+7wRoAAIQ4B9gByAAgQ3pfiDmXmAeEwAAAABJRU5ErkJggg==')
      0% 0% / 28px;
    height: 100%;
    width: 100%;
  }
  padding: 2px;
  position: relative;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 6px;
  &.selected {
    border: 1px solid #038db6;
  }
  &:hover {
    border: 1px solid #038db6;
  }
  &.disabled {
    border: 1px solid #d9d9d9 !important;
    cursor: not-allowed !important;
  }
`;

const WidgetStyle = styled.div`
  &&&& {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translate(30%, 30%);
    background: rgb(255, 255, 255);
  }
`;

const WidgetStyleWithShadow = styled(WidgetStyle)`
  &&&& {
    border-radius: 5px;

    &.show-shadow {
      box-shadow: rgba(0, 0, 0, 0.44) 0px 3px 8px;
    }
  }
`;

export const WidgetShadowStyleButton = (props: {
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  disabled: boolean;
}) => {
  let showShadowButtonClassName = props.value ? 'selected' : '';

  if (props.disabled) {
    showShadowButtonClassName += ' disabled';
  }
  return (
    <Space direction="vertical" style={{ alignItems: 'center' }}>
      <WidgetStyleButton
        className={showShadowButtonClassName}
        onClick={() => !props.disabled && props.onChange(!props.value)}
      >
        <div>
          <WidgetStyleWithShadow className={props.value ? 'show-shadow' : ''} />
        </div>
      </WidgetStyleButton>
      <Switch
        size="small"
        checked={props.value}
        onClick={() => !props.disabled && props.onChange(!props.value)}
      />
    </Space>
  );
};
