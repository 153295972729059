'use strict';

const consentTypeModel = ($$resource, $state, base64) => {
  'ngInject';

  const constructor = $$resource(
    `${cdApp.config.api.main}/people/consent-types/:id`
  );

  constructor.prototype.viewInPeopleUrl = function () {
    const filters = [
      {
        type: 'date',
        property: `consentType-${this.id}`,
        operator: 'known',
        unit: null,
        value: null,
      },
    ];

    const json = angular.toJson(filters);
    const encoded = base64.urlencode(json);

    return $state.href('app.private.people.contacts.list', { filter: encoded });
  };

  return constructor;
};
consentTypeModel.$inject = ['$$resource', '$state', 'base64'];
angular.module('cdApp.people').factory('ConsentType', consentTypeModel);
