'use strict';

function GroupMessageModel($$resource) {
  'ngInject';

  return $$resource(`${cdApp.config.api.main}/messages/:id`);
}
GroupMessageModel.$inject = ['$$resource'];

angular.module('cdApp.shared').factory('GroupMessage', GroupMessageModel);
