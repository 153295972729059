'use strict';

import _ from 'lodash';

import PeopleService from '../../../../../react/people/services/people.service';
import ErrorHandlingService from '../../../../../react/services/ErrorHandlingService';

class EditPeopleController {
  constructor(
    moment,
    $state,
    $filter,
    cdApp,
    Authorization,
    People,
    PeopleCustomFieldsData,
    relationService,
    dateFormatsLookup,
    Resources,
    $uibModal,
    gettextCatalog
  ) {
    'ngInject';

    this.moment = moment;
    this.$state = $state;
    this.$filter = $filter;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
    this.People = People;
    this.PeopleCustomFieldsData = PeopleCustomFieldsData;
    this.relationService = relationService;
    this.dateFormatsLookup = dateFormatsLookup;
    this.Resources = Resources;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
  }

  /**
   * Lifecycle hook used for initialization work
   */
  $onInit() {
    const { moment } = this;
    this.today = moment()._d;
    this.context = this.resolve.context || 'personal';
    this.person = this.resolve.person;

    this.datepickerFormat = this.dateFormatsLookup.getFormat();

    if (this.context === 'personal') {
      _.set(
        this.person,
        'country',
        _.get(this.person, 'country') ||
          _.get(this.cdApp, 'organization.locale.country')
      );

      if (this.person.birthday) {
        this.person.birthday = moment(this.person.birthday).toDate();
      }
    }

    if (this.context === 'custom') {
      this.customFieldsData = _.has(this.resolve, 'customFieldsData.id')
        ? angular.copy(this.resolve.customFieldsData)
        : new this.PeopleCustomFieldsData({ data: {} });
    }

    if (this.context === 'family') {
      if (!_.size(this.person.relations)) {
        this.person.relations = [{ relation: null, person: null }];
      }

      this.relationTypes = this.relationService.getRelations();
    }

    this.showPrefixField = !_.isEmpty(this.resolve.staticFieldsOptions.prefix);
    this.registerValidationHook = this.registerValidationHook.bind(this);
    this.registerFetchValuesHook = this.registerFetchValuesHook.bind(this);

    this.Resources.getChurches({
      permissionContext: 'people',
      permissionType: 'view',
    }).$promise.then((churches) => {
      this.churchIds = _.map(churches, 'id');
    });

    this.conflictingPeopleRelationsByEmail = [];
    this.conflictingPeopleRelationsByPhone = [];
  }

  /**
   * Determine whether a person has already been added with a relation
   *
   * @param {Object} person
   */
  isPersonAlreadySelected(person) {
    const alreadySelectedPeople = _.map(
      this.person.relations,
      'person.personId'
    );

    return _.includes(alreadySelectedPeople, person.id);
  }

  /**
   * Lets a child component register its validation function.
   *
   * @param {Promise<boolean>} childValidationFunction
   */
  registerValidationHook(childValidationFunction) {
    this.validateHook = childValidationFunction;
  }
  /**
   * Lets a child component register its fetch form values function.
   *
   * @param {*} childGetValuesFunction
   */
  registerFetchValuesHook(childGetValuesFunction) {
    this.fetchValues = childGetValuesFunction;
  }

  setConflictingPeopleRelationsByEmail({ conflictingPeopleRelationsByEmail }) {
    this.conflictingPeopleRelationsByEmail = conflictingPeopleRelationsByEmail;
  }

  setConflictingPeopleRelationsByPhone({ conflictingPeopleRelationsByPhone }) {
    this.conflictingPeopleRelationsByPhone = conflictingPeopleRelationsByPhone;
  }

  /**
   * Save the new person
   */
  save() {
    const closeModal = (newPerson) => this.close({ $value: newPerson });
    if (this.context === 'basic') {
      this.isSavingBlocked = true;
      this.validateHook()
        .then((isValid) => {
          if (isValid) {
            const formData = this.fetchValues();
            this.person = _.extend(this.person, formData);
            if (!this.showPrefixField) {
              this.person.prefix = null;
            }

            return this.person.$save((newPerson) => {
              closeModal(newPerson);
            });
          }
        })
        .catch(console.error)
        .finally(() => {
          this.isSavingBlocked = false;
        });
    } else {
      this.savePerson(closeModal);
    }
  }

  savePerson(closeModal) {
    this.isSavingBlocked = true;
    try {
      // If there are any preexistent relations, strip the unnecessary properties from them
      if (!_.isEmpty(this.person.relations)) {
        this.person.relations = _.map(this.person.relations, (relation) =>
          _.pick(relation, ['relationId', 'relation', 'person'])
        );
      }

      // Then, process the relations originated from contact information conflicts
      const conflictingPeopleRelations = [];
      if (!_.isEmpty(this.conflictingPeopleRelationsByEmail)) {
        conflictingPeopleRelations.push(
          ...this.conflictingPeopleRelationsByEmail
        );
      }
      if (!_.isEmpty(this.conflictingPeopleRelationsByPhone)) {
        conflictingPeopleRelations.push(
          ...this.conflictingPeopleRelationsByPhone
        );
      }
      const processedRelations =
        PeopleService.processConflictingPeopleRelations(
          conflictingPeopleRelations,
          this.person.relations
        );

      if (!_.isEmpty(processedRelations)) {
        this.person.relations = processedRelations;
      }

      this.person.$save(
        [{ condition: !this.showPrefixField, field: 'prefix' }],
        (newPerson) => {
          if (this.context === 'custom') {
            this.PeopleCustomFieldsData.update(
              { id: newPerson.id },
              this.customFieldsData,
              closeModal
            );
          } else {
            closeModal(newPerson);
          }
        }
      );
    } catch (err) {
      return ErrorHandlingService.handleError(err.message);
    } finally {
      this.isSavingBlocked = false;
    }
  }

  searchPeople(filter = undefined, limit = 30) {
    return this.People.searchPeople({
      filter,
      limit,
      searchAfter: null,
      orderBy: 'createdAt',
      orderDirection: 'desc',
    });
  }

  refreshPartners(query) {
    const filter = {
      comparison: 'OR',
      churchIds: this.churchIds,
      filters: [
        {
          type: 'text',
          property: 'fullName',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'email',
          operator: 'like',
          value: query,
        },

        {
          type: 'text',
          property: 'phone',
          operator: 'like',
          value: query,
        },
      ],
    };

    return this.searchPeople(filter).$promise.then(({ people }) => {
      this.filteredPeopleList = _.map(people, (person) => {
        const firstName = _.find(person.fields, {
          property: 'firstName',
        }).value;
        const lastName = _.find(person.fields, { property: 'lastName' }).value;
        const email = _.find(person.fields, { property: 'email' }).value;
        const name = _.join([firstName, lastName], ' ');
        return {
          id: person.id,
          name,
          firstName,
          lastName,
          email,
          personId: person.id,
        };
      });
    });
  }

  /**
   * Create a new empty family relation
   */
  createRelation() {
    this.person.relations = this.person.relations || [];
    this.person.relations.push({ relation: null, person: null });
  }

  /**
   * Remove a relation
   *
   * @param {Object} relation - The relation object to be removed
   */
  removeRelation(relation) {
    _.remove(this.person.relations, relation);
  }

  /**
   * Get the uploaded picture
   *
   * @param {Object} object - The image object
   */
  getPicture(object) {
    this.person.picture = object;
  }

  /**
   * Delete the current picture
   */
  deletePicture() {
    this.person.picture.id = null;
    this.person.picture.url = null;
  }
}

EditPeopleController.$inject = [
  'moment',
  '$state',
  '$filter',
  'cdApp',
  'Authorization',
  'People',
  'PeopleCustomFieldsData',
  'relationService',
  'dateFormatsLookup',
  'Resources',
  '$uibModal',
  'gettextCatalog',
];

angular.module('cdApp.people').component('cdEditPeopleModal', {
  templateUrl:
    '@/app/people/shared/components/edit-people/edit-people.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
    form: '<',
  },

  controller: EditPeopleController,
});
