(function () {
  'use strict';

  function ProfileController(
    moment,
    $window,
    $scope,
    $http,
    $uibModal,
    $stateParams,
    $location,
    AuthenticationService,
    gettextCatalog,
    toastr,
    userFactory,
    Users,
    Jobs,
    UserFeed,
    MyGroups,
    Me,
    userData,
    Authorization
  ) {
    // Check if we are in the intranet. We will restrict some things in graveyard.
    $scope.isIntranet = true;

    // Different user profile url, if only graveyard.
    $scope.context = 'app';

    $scope.canAdministerUsers =
      Authorization.hasPermission('canAdministerUsers');

    $scope.organizationId = $window.churchdeskOrganizationId;
    $scope.accessToken = AuthenticationService.getAccessToken();

    // =============================================================================
    // Fetch the data that is needed.
    // =============================================================================

    // Permissions.
    $scope.permissions = _.get(cdApp, 'organization.permissions');

    // Fetch the user information.
    $scope.user = userData;

    // Get the groups the user is part of.
    MyGroups.query(
      {
        userId: $stateParams.id,
        organizationId: $window.churchdeskOrganizationId,
      },

      function (groups) {
        $scope.user.groups = groups;
      }
    );

    // Fetch the user activities.
    if ($scope.isIntranet) {
      UserFeed.query({ id: $stateParams.id }, function (content) {
        $scope.messages = _.find(content, { entityType: 'message' });
        $scope.content = _.difference(content, $scope.messages);
      });
    }

    // =============================================================================
    // General stuff.
    // =============================================================================

    // Get id of current logged in user.
    $scope.currentUser = Me.id;

    // Limit the amount of content shown on the user profile.
    $scope.limitContent = 5;

    $scope.loadMore = function () {
      $scope.limitContent += 10;
    };

    $scope.getGroupsHtml = function (groups) {
      return (_.map(groups, 'name') || []).join(', ');
    };

    // Translate status id to status name.
    $scope.userStatus = function (status) {
      if (status === 1) {
        return gettextCatalog.getString('Active');
      } else if (status === 0) {
        return gettextCatalog.getString('Blocked');
      }
    };

    $scope.timeSince = function (element) {
      return moment(element).fromNow();
    };

    // Get actual date for unix numbers.
    $scope.getDate = function (number) {
      if (number === 0) {
        return gettextCatalog.getString('Never');
      }
      return moment(number).format('lll');
    };

    // Relative time for latest login.
    $scope.latestLogin = function (number) {
      if (number === 0 || _.isNull(number)) {
        return gettextCatalog.getString('Never');
      }
      return moment(number).fromNow();
    };

    // Check if latest access happened in the past 10 minutes.
    $scope.isOnline = function (time) {
      let last = moment(time);
      let now = moment(new Date());
      let diff = now.diff(last, 'minutes');

      return diff < 10;
    };

    $scope.isAllowedOperation = (operation) => {
      const allowedOperations = [
        'create',
        'membershipactive',
        'membershippending',
        'update',
      ];

      return _.includes(allowedOperations, _.toLower(operation));
    };

    $scope.getEntityPartial = function (operation) {
      return (
        '@/app/settings/users-settings/shared/partials/Entity' +
        capitalizeFirstLetter(operation) +
        '.html'
      );
    };

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // =============================================================================
    // Modals.
    // =============================================================================

    // Send message to a user.
    $scope.send = function (user) {
      $uibModal.open({
        templateUrl:
          '@/app/settings/users-settings/shared/templates/send-message.modal.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            'ngInject';

            $scope.user = user;

            $scope.send = function () {
              $http
                .post(
                  cdApp.config.api.main + '/users/' + user.id + '/contact',
                  {
                    subject: $scope.subject,
                    message: $scope.message,
                  }
                )
                .then(
                  function () {
                    toastr.success(
                      gettextCatalog.getString('Your message has been sent.')
                    );
                  },
                  function (error) {
                    toastr.error(
                      _.get(error, 'data.message') ||
                        gettextCatalog.getString(
                          'An error occurred, please try again. If the problem persists, please contact our support.'
                        )
                    );
                  }
                );

              $uibModalInstance.close(user);
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
        ],
      });
    };

    $scope.viewUserRoles = function (user) {
      $uibModal
        .open({
          component: 'cdUserPermissionsModal',
          windowClass: 'modal-scrollable',
          resolve: {
            user: () => user,
            canAdministerUsers: () =>
              Authorization.hasPermission('canAdministerUsers'),
            userPermissions: [
              'Users',
              function (Users) {
                'ngInject';
                return Users.getPermissions({ id: user.id }).$promise;
              },
            ],
          },
        })
        .result.then(function () {
          $window.location.reload();
        });
    };
  }

  ProfileController.$inject = [
    'moment',
    '$window',
    '$scope',
    '$http',
    '$uibModal',
    '$stateParams',
    '$location',
    'AuthenticationService',
    'gettextCatalog',
    'toastr',
    'userFactory',
    'Users',
    'Jobs',
    'UserFeed',
    'MyGroups',
    'Me',
    'userData',
    'Authorization',
  ];

  angular
    .module('cdApp.intranet')
    .controller('ProfileController', ProfileController);
})();
