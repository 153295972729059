import { useSelector } from 'react-redux';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  ErrorInfo,
  SafeguardingUserProfile,
} from '@/react/safeguarding/types/SafeguardingTypes';
import { showUserUploadProgressModal } from '@/react/safeguarding/safeguarding/ShowUserUploadProgressModal';
import {
  getAlternateMatches,
  getValidationErrors,
  tableTranslations,
  validateRows,
} from '@/react/safeguarding/common/Helpers';
import { getResources } from '@/react/shared/store/resources';
import { Resource } from '@/react/shared/models/resource';

export const useSafeguardBulkUpload = ({
  closeModal,
}: { closeModal: () => void }) => {
  const fields = [
    {
      label: tableTranslations.firstName,
      key: 'firstName',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.firstName),
        'Vorname',
      ],
      fieldType: { type: 'input' },
      example: 'Jacob',
    },
    {
      label: tableTranslations.lastName,
      key: 'lastName',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.lastName),
        'Nachname',
      ],
      fieldType: { type: 'input' },
      example: 'Naesborg',
    },
    {
      label: tableTranslations.email,
      key: 'email',
      alternateMatches: getAlternateMatches(tableTranslations.email),
      fieldType: { type: 'input' },
      example: 'jacob@example.com',
      validations: [
        {
          rule: 'required',
          errorMessage: gettextCatalog.getString('Email is required'),
          level: 'error',
        },
        {
          rule: 'regex',
          value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
          errorMessage: gettextCatalog.getString('Invalid email format'),
          level: 'error',
        },
      ],
    },
    {
      label: tableTranslations.street,
      key: 'street',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.street),
        'Straße',
      ],
      fieldType: { type: 'input' },
      example: 'StuenGade 12',
    },
    {
      label: tableTranslations.city,
      key: 'city',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.city),
        'Stadt',
      ],
      fieldType: { type: 'input' },
      example: 'Kobenhavn',
    },
    {
      label: tableTranslations.postCode,
      key: 'postCode',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.postCode),
        'Postleitzahl',
      ],
      fieldType: { type: 'input' },
      example: '2300',
    },
    {
      label: tableTranslations.country,
      key: 'country',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.country),
        'Land',
      ],
      fieldType: { type: 'input' },
      example: 'Denmark',
    },
    {
      label: tableTranslations.safeguardRequirement,
      key: 'safeguardRequirement',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.safeguardRequirement),
        'Erforderliche Präventionsmaßnahmen',
      ],
      fieldType: {
        type: 'select',
        options: [
          { label: tableTranslations.notRequired, value: 'not required' },
          { label: tableTranslations.minimum, value: 'minimum' },
          { label: tableTranslations.basic, value: 'basic' },
          { label: tableTranslations.basicPlus, value: 'basic plus' },
          { label: tableTranslations.extended, value: 'extended' },
          { label: tableTranslations.extendedPlus, value: 'extended plus' },
          {
            label: tableTranslations.verifiedByThirdParty,
            value: 'verified by third party',
          },
          { label: tableTranslations.select, value: undefined },
        ],
      },
      example: gettextCatalog.getString('Extended'),
    },
    {
      label: tableTranslations.type,
      key: 'type',
      alternateMatches: [...getAlternateMatches(tableTranslations.type), 'Typ'],
      fieldType: {
        type: 'select',
        options: [
          { label: tableTranslations.employee, value: 'employee' },
          { label: tableTranslations.volunteer, value: 'volunteer' },
          { label: tableTranslations.select, value: undefined },
        ],
      },
      example: gettextCatalog.getString('Employee'),
    },
    {
      label: tableTranslations.workAndResponsibilities,
      key: 'workAndResponsibilities',
      alternateMatches: [
        ...getAlternateMatches(tableTranslations.workAndResponsibilities),
        'Beruf und Verantwortlichkeiten',
      ],
      fieldType: { type: 'input' },
      example: 'Baptism',
    },
    {
      label: tableTranslations.parishIds,
      key: 'parishIds',
      alternateMatches: getAlternateMatches(tableTranslations.parishIds),
      fieldType: { type: 'input' },
      example: '122, 123, 124',
    },
  ];

  const resources = useSelector(getResources).filter(
    (resource: Resource) => resource.type === 'church'
  );

  const rowValidation = (
    row: SafeguardingUserProfile,
    addError: (fieldKey: string, error: ErrorInfo) => void
  ) => {
    const receivedError = (id: string, error: unknown) => {
      if (error instanceof Error) {
        addError(id, { message: error.message, level: 'error' });
      }
    };
    validateRows(row, receivedError, resources);
    return row;
  };

  const submitHandler = async ({ all }: { all: SafeguardingUserProfile[] }) => {
    const payloadArray = [];

    const toUploadRows = all.map((row) => {
      payloadArray.push(row);

      return {
        data: row,
        errors: getValidationErrors(row, resources),
      };
    });
    closeModal();
    // open a modal to show the progress bar
    await showUserUploadProgressModal({
      toUploadRows,
    });
  };

  return {
    fields,
    submitHandler,
    rowValidation,
  };
};
