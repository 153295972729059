'use strict';

class RepeatRuleSummaryComponent {
  constructor(_, rruleFactory, Calendar) {
    'ngInject';

    this._ = _;
    this.rruleFactory = rruleFactory;
    this.Calendar = Calendar;
  }

  $onInit() {
    const { _, rruleFactory, Calendar } = this;

    if (_.isEmpty(_.get(this.event, 'rrule'))) return;

    // If the rule repeats until a number of occurences, calculate the end date of the series
    const rruleObject = rruleFactory.getRRuleFromString(this.event.rrule);
    if (rruleObject.options.count) {
      const getLastEventInSeries = (startDate) =>
        _.last(rruleFactory.getEventsInSeries(this.event.rrule, startDate));

      if (this.event.parentEntity) {
        Calendar.get({
          id: this.event.parentEntity,
          type: 'event',
          operation: 'view',
        }).$promise.then((parentEvent) => {
          this.endsOn = getLastEventInSeries(parentEvent.startDate);
        });
      } else {
        this.endsOn = getLastEventInSeries(this.event.startDate);
      }
    }

    const { excluded, included } = rruleFactory.extractExcludesIncludes(
      this.event.rrule
    );

    this.hasExcludedDates = !_.isEmpty(_.concat(excluded, included));
    this.excludedDates = _.orderBy(
      _.concat(
        _.map(excluded, (excludedDate) =>
          _.assign({}, { date: excludedDate }, { type: 'excluded' })
        ),

        _.map(included, (includedDate) =>
          _.assign({}, { date: includedDate }, { type: 'included' })
        )
      ),

      'date'
    );

    this.showMoreExcludedDates = false;
    this.toggleExpandExcludedDates = () =>
      (this.showMoreExcludedDates = !this.showMoreExcludedDates);

    this.getExcludedDates = () =>
      this.showMoreExcludedDates
        ? this.excludedDates
        : _.slice(this.excludedDates, 0, 3);
  }
}

RepeatRuleSummaryComponent.$inject = ['_', 'rruleFactory', 'Calendar'];
angular.module('cdApp.calendar').component('cdRepeatRuleSummary', {
  templateUrl:
    '@/app/calendar/shared/components/repeat-rule-summary/repeat-rule-summary.component.html',
  controller: RepeatRuleSummaryComponent,
  bindings: {
    event: '<',
  },
});
