'use strict';

class ImageCropModal {
  $onInit() {
    this.contextId = this.resolve.contextId;
    this.contextType = this.resolve.contextType;
    this.bounds = this.resolve.bounds;
    this.cropKey =
      this.resolve.cropKey || `${this.contextType}-${this.contextId}`;
    this.onlyMode =
      this.contextType === 'people-message' ? 'people-message' : null;

    // Wait for the modal to be fully rendered, so the image crop directive
    // can calculate its width and height relative to the modal element correctly
    this.modalInstance.rendered.then(() => {
      this.image = {
        file: this.resolve.file,
      };
    });
  }

  closeModal(result) {
    this.close({ $value: result });
  }
}

ImageCropModal.$inject = [];

angular.module('cdApp.shared').component('cdImageCropModal', {
  templateUrl:
    '@/app/shared/components/image-crop-modal/image-crop-modal.component.html',
  bindings: {
    resolve: '<',
    modalInstance: '<',
    close: '&',
    dismiss: '&',
  },

  controller: ImageCropModal,
});
