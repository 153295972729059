(function () {
  'use strict';

  /**
   * @desc strip html tags from the provided source
   * @example <span>{{ message.content | stripHtml }}</span>
   */

  function stripHtml() {
    return function (text) {
      return text ? String(text).replace(/<\S[^><]*>/g, '') : '';
    };
  }

  angular.module('cdApp.shared').filter('stripHtml', stripHtml);
})();
