import { some, omit } from 'lodash';

export const isAnyLegacyPermissionEnabled = (permissions: any): boolean =>
  permissions.people &&
  (some(omit(permissions, ['forms', 'people', 'graveyard', 'calendar'])) ||
    some(permissions.people) ||
    some(permissions.forms) ||
    some(permissions.calendar.booking) ||
    some(omit(permissions.calendar, ['booking'])) ||
    some(permissions.graveyard));
