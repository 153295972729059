import { EventApi } from '@fullcalendar/core';
import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { AppState } from '../../../redux';
import { createActionDispatcherHook } from '../../../redux/utils';

const eventsAdapter = createEntityAdapter<EventApi>({
  selectId: (event) => event.id,
});

export type FullCalendarEventsState = EntityState<EventApi>;

export interface ChangeEventArgs {
  oldEvent: EventApi;
  newEvent: EventApi;
  revert?: () => void;
}

export enum ViewTypes {
  DAY = 'timeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth',
  RESOURCES = 'resourceTimeGridDay',
}

export const calendarModuleName = 'fullCalendarEvents';
const eventsSlice = createSlice({
  name: calendarModuleName,
  initialState: eventsAdapter.getInitialState() as FullCalendarEventsState,
  reducers: {
    // @ts-ignore: Fullcalendar interfaces confuses me or redux toolkit.
    updateEventToRender: eventsAdapter.setAll,
  },
});

export const fullCalendarActions = {
  updateEventToRender: eventsSlice.actions.updateEventToRender,
  reloadCurrentView: createAction('calendar/reloadCurrentView'),
  refreshEventsToRender: createAction('calendar/refreshEventsToRender'),
  editEvent: createAction<{ eventId: number; type: string }>(
    'events/editEvent'
  ),
  deleteEvent: createAction<number>('events/deleteEvent'),
  copyEvent: createAction<number>('events/copyEvent'),
};

export const eventsToRenderReducer = eventsSlice.reducer;

const selectors = eventsAdapter.getSelectors<AppState>(
  (state) => state.calendar.fullCalendarEvents
);

export const fullCalendarSelectors = {
  getEvents: selectors.selectAll,
  selectFullCalendarEventById: selectors.selectById,
};

export const fullCalendarHooks = {
  useEditEvent: createActionDispatcherHook(fullCalendarActions.editEvent),
  useDeleteEvent: createActionDispatcherHook(fullCalendarActions.deleteEvent),
  useCopyEvent: createActionDispatcherHook(fullCalendarActions.copyEvent),
};
