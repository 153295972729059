(function () {
  'use strict';
  cdMinDate.$inject = ['moment'];

  function cdMinDate(moment) {
    let link = function ($scope, $element, $attrs, ctrl) {
      function validate() {
        let comparisonModel;
        let viewValue;

        try {
          comparisonModel = JSON.parse($attrs.cdMinDate);
          viewValue = JSON.parse($attrs.cdSelectedDate);
        } catch (error) {
          // continue regardless of errors
        }

        if (!viewValue || !comparisonModel) {
          // It's valid because we have nothing to compare against
          ctrl.$setValidity('cdMinDate', true);
        }

        // It's valid if model is lower than the model we're comparing against
        ctrl.$setValidity(
          'cdMinDate',
          moment(viewValue).isAfter(comparisonModel)
        );

        return viewValue;
      }

      ctrl.$parsers.unshift(validate);
      ctrl.$formatters.push(validate);

      $attrs.$observe('cdSelectedDate', function () {
        // Whenever the view model changes we'll re-validate
        return validate();
      });

      $attrs.$observe('cdMinDate', function () {
        // Whenever the comparison model changes we'll re-validate
        return validate();
      });
    };

    return {
      require: 'ngModel',
      link: link,
      restrict: 'A',
    };
  }

  angular.module('cdApp.shared').directive('cdMinDate', cdMinDate);
})();
