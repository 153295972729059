/* eslint-disable react-hooks/rules-of-hooks */
import { Modal, Form } from 'antd';
import React, { FunctionComponent, useMemo, useEffect } from 'react';
import { sagaModal, destroyModal } from 'redux-saga-modal';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, each, set, reduce } from 'lodash';

import MODAL_TYPES from '../../redux/modal-types';
import { gettextCatalog } from '../../../services/I18nService';
import { useFetchUserRolesWithEffect } from '../../screens/hooks';
import {
  getUserChurchRoles,
  getUserOldRoles,
  getUserOrganizationRoles,
} from '../../redux/selectors';
import { getCurrentUserId } from '../../../config/store/Selector';
import AuthorizationService from '../../../services/AuthorizationService';
import { getChurches } from '../../../shared/store/resources';

import AssignUserRoles, { saveUserRoles } from './AssignUserRoles';

const AssignUserRolesModal: FunctionComponent<{
  isOpen: boolean;
  userId: number;
  userName: string;
}> = ({ isOpen, userId, userName }) => {
  const dispatch = useDispatch();
  if (!isOpen) return null;
  const [form] = Form.useForm();
  const churchRoles = useSelector(getUserChurchRoles);
  const churches = useSelector(getChurches);
  const userOrganizationRoles = useSelector(getUserOrganizationRoles);
  const oldRoles = useSelector(getUserOldRoles);
  const currentLoggedInUserId = useSelector(getCurrentUserId);
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');

  const userChurchRoles = useMemo(() => {
    const internalUserChurchRoles = {};
    each(churchRoles, (churchMembership) => {
      each(churchMembership.roles, (churchMembershipRoles) => {
        if (!internalUserChurchRoles[churchMembershipRoles.id]) {
          internalUserChurchRoles[churchMembershipRoles.id] = [];
        }
        internalUserChurchRoles[churchMembershipRoles.id].push(
          churchMembership.churchId
        );
      });
    });
    return internalUserChurchRoles;
  }, [churchRoles]);

  useFetchUserRolesWithEffect(userId);

  const save = () =>
    saveUserRoles(
      dispatch,
      form,
      'modal',
      userId,
      currentLoggedInUserId,
      oldRoles,
      churchRoles,
      churches,
      userName
    );

  useEffect(() => {
    if (isEmpty(userOrganizationRoles)) return;
    const formatUserOrganizationRoles = reduce(
      userOrganizationRoles,
      (accumulator, role) => {
        set(accumulator, role.id, true);
        return accumulator;
      },
      {}
    );
    form.setFieldsValue({ organization: formatUserOrganizationRoles });
  }, [userOrganizationRoles, form]);
  useEffect(() => {
    if (isEmpty(userChurchRoles)) return;
    form.setFieldsValue({
      church: userChurchRoles,
      churchSwitches: reduce(
        userChurchRoles,
        function (accumulator, value, key) {
          accumulator[key] = !isEmpty(value);
          return accumulator;
        },
        {}
      ),
    });
  }, [userChurchRoles, form]);
  useEffect(() => {
    if (isEmpty(oldRoles)) return;
    form.setFieldsValue({ oldRoles });
  }, [oldRoles, form]);

  const closeModal = () => {
    dispatch(destroyModal(MODAL_TYPES.USER_ROLES));
  };

  return (
    <Modal
      title={gettextCatalog.getString('Edit roles of {{userName}}', {
        userName,
      })}
      open={isOpen}
      width="80vw"
      okText={gettextCatalog.getString('Save')}
      onOk={save}
      onCancel={closeModal}
      okButtonProps={{ disabled: !hasUserAdminRole }}
      destroyOnClose
      maskTransitionName="maskTransitionName"
    >
      <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
        <Form form={form} layout="vertical">
          <AssignUserRoles userId={userId} />
        </Form>
      </div>
    </Modal>
  );
};

export default sagaModal({
  // A unique name for the modal
  name: MODAL_TYPES.USER_ROLES,
})(AssignUserRolesModal);
