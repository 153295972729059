import { react2angular } from 'react18-react2angular';

import ManageRoles from '../../../react/organization/screens/manage-roles/ManageRoles';
import sharedContext from '../../shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.settings')
  .component(
    'cdrManageRoles',
    react2angular(
      sharedContext.use(ManageRoles),
      ['currentRoleId', 'currentRoleContext', 'forceReloadRoles'],
      []
    )
  );
