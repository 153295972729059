'use strict';

class UpdateOrganizationEmailModal {
  constructor(_, cdApp, PeoplePreferences) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
    this.PeoplePreferences = PeoplePreferences;
  }

  $onInit() {
    this.organizationEmail = this.resolve.organizationEmail;
  }

  save() {
    new this.PeoplePreferences({
      organizationEmail: this.organizationEmail,
    }).$save(() => {
      this.close({ $value: this.organizationEmail });
    });
  }
}

UpdateOrganizationEmailModal.$inject = ['_', 'cdApp', 'PeoplePreferences'];

angular.module('cdApp.shared').component('cdUpdateOrganizationEmailModal', {
  templateUrl:
    '@/app/shared/components/update-organization-email/update-organization-email.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: UpdateOrganizationEmailModal,
});
