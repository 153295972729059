(function () {
  'use strict';
  Calendar.$inject = ['_', 'cdApp', '$$resource', 'Authorization'];

  function Calendar(_, cdApp, $$resource, Authorization) {
    /**
     * Transform the payload of the API call
     *
     * @param data {Object} - The data object
     */
    function transformCalendarRequestData(data) {
      let result = angular.copy(data);
      let acceptedVisibilityValues = ['internal', 'public', 'private'];

      // Transform event shifts from one-to-many into one-to-one structure
      function flattenEventShifts(shifts) {
        if (!Authorization.hasPermission('canAccessPlanning')) return [];
        return _.map(shifts, (shift) => _.pick(shift, ['taskId', 'userId']));
      }

      // Translate internal values to what the API expects when creating/updating
      if (result) {
        switch (result.type) {
          case 'event':
            _.extend(result, {
              groupIds:
                data.visibility === 'internal-group' ||
                data.visibility === 'public'
                  ? _.isArray(data.groupIds)
                    ? data.groupIds
                    : _.compact([data.groupIds])
                  : [],
              visibility: _.includes(acceptedVisibilityValues, data.visibility)
                ? data.visibility
                : _.isUndefined(data.visibility)
                  ? undefined
                  : 'internal',
            });

            if (data.shifts) {
              result.shifts = flattenEventShifts(data.shifts);
            }
            if (data.visibility === 'private') {
              result.churchIds = undefined;
            }
            result.form = undefined;
            result.image = undefined;
            break;

          case 'absence':
            _.extend(result, {
              groupIds: _.compact([data.groupId]),
              users: _.compact([data.users]),
            });

            break;
        }
      }

      return angular.toJson(result);
    }

    /**
     * Transform the response of the API call
     *
     * @param data {Object|String} - The (un)serialized data object
     */
    function transformCalendarResponseData(data) {
      let result = (data = angular.fromJson(data));

      // Transform values from the API to what we use internally in the controller
      if (result) {
        switch (result.type) {
          case 'event':
            _.extend(result, {
              groupIds: _.map(data.groups, 'id'),
              visibility:
                data.visibility === 'internal'
                  ? _.isEmpty(data.groups)
                    ? 'internal-all'
                    : 'internal-group'
                  : data.visibility,
              shifts: Authorization.hasPermission('canAccessPlanning')
                ? data.shifts
                : [],
            });

            break;

          case 'absence':
            _.extend(result, {
              groupId: _.get(data, 'group.id'),
            });

            break;
        }
      }

      return result;
    }

    const CalendarResource = $$resource(
      `${cdApp.config.api.main}/calendar/:id`,
      {},
      {
        create: {
          method: 'POST',
          transformRequest: transformCalendarRequestData,
        },

        update: {
          method: 'PUT',
          transformRequest: transformCalendarRequestData,
        },

        get: {
          method: 'GET',
          transformResponse: transformCalendarResponseData,
          cancellable: true,
        },

        checkAvailability: {
          method: 'POST',
          url: `${cdApp.config.api.main}/calendar/check`,
          transformRequest: transformCalendarRequestData,
        },

        checkForUpdates: {
          method: 'GET',
          url: `${cdApp.config.api.main}/calendar/last-updated`,
        },

        updateResponse: {
          method: 'POST',
          url: `${cdApp.config.api.main}/calendar/invitations/:eventId/attending/:response`,
        },

        getExternalEvent: {
          method: 'GET',
          url: `${cdApp.config.api.main}/calendar/external-event/:id`,
        },
      }
    );

    return CalendarResource;
  }
  angular.module('cdApp.shared').factory('Calendar', Calendar);
})();
