import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { FilterType, SelectedCalendar } from '../store/filters';

export const FilterDiv = styled.div`
  list-style-type: none;
  line-height: 25px;

  .bullet-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bullet-label {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 21px);
        padding: 0;
        color: #333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;

        .name {
          display: block;
          width: 100%;
          font-size: 13px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        small {
          width: 100%;
          line-height: 1.4;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .bullet {
        margin-right: 1em;
        border-radius: 4px;
        min-width: 14px;
        min-height: 14px;
        display: block;
        cursor: pointer;
        border-color: transparent;

        &.color-888 {
          background-color: #333;
          border-color: #333;
          color: white;
        }
      }
    }

    .filter-actions {
      display: none;
      padding-right: 5px;
    }

    &:hover {
      .filter-actions {
        display: block;

        a:hover {
          i {
            color: #333;
          }
        }
      }
    }
  }
`;

export interface CalendarItemProps {
  item: SelectedCalendar;
}

const getClassName = (item: SelectedCalendar): string => {
  const color = item.filterType === FilterType.users ? 888 : item.color;
  return `bullet color-${color || 0} bullet-full`;
};

const CalendarItem: FunctionComponent<CalendarItemProps> = ({ item }) => (
  <FilterDiv>
    <div className="bullet-wrapper">
      <div className="bullet-label">
        <span className={getClassName(item)} />
        <div className="label">
          <span className="name" title={item.name || item.email}>
            {item.name}
          </span>
        </div>
      </div>
    </div>
  </FilterDiv>
);

export default CalendarItem;
