import _ from 'lodash';

import { mainApi } from '../../api';
import { gettextCatalog } from '../../services/I18nService';
import {
  People,
  PeopleRelation,
  PeopleRelationType,
} from '../../shared/models/people';

export enum ContactInformationType {
  EMAIL = 'email',
  PHONE = 'phone',
}

interface ConflictingPerson {
  person: People;
  isEmailConflict: boolean;
  isPhoneConflict: boolean;
}

export interface ConflictingPersonWithRelation {
  conflictingPerson: People;
  relation: PeopleRelationType;
  type: ContactInformationType;
}

class PeopleService {
  public async checkConflictingPeople(body): Promise<ConflictingPerson[]> {
    const response = await mainApi.post(
      '/people/people/check-conflicting-people',
      body
    );
    if (response.ok) {
      return _.get(response.data, 'conflictingPeople') as ConflictingPerson[];
    }
    throw response.data;
  }

  public async removeSharedContactInformation(personId, body): Promise<void> {
    const response = await mainApi.post(
      `/people/people/${personId}/remove-shared-contact-information`,
      body
    );
    if (!response.ok) throw response.data;
  }

  public processConflictingPeopleRelations(
    conflictingPeopleWithRelation: ConflictingPersonWithRelation[],
    personExistingRelations?: PeopleRelation[]
  ): PeopleRelation[] {
    // Validations
    if (_.isEmpty(conflictingPeopleWithRelation)) return;

    const conflictingPeopleWithRelationsByEmail = _.filter(
      conflictingPeopleWithRelation,
      {
        type: ContactInformationType.EMAIL,
      }
    );
    const conflictingPeopleWithRelationsByPhone = _.filter(
      conflictingPeopleWithRelation,
      {
        type: ContactInformationType.PHONE,
      }
    );

    const repeatedConflictingContactIds = _.intersection(
      _.map(conflictingPeopleWithRelationsByEmail, 'conflictingPerson.id'),
      _.map(conflictingPeopleWithRelationsByPhone, 'conflictingPerson.id')
    );

    _.each(repeatedConflictingContactIds, (repeatedConflictingContactId) => {
      const email = _.find(conflictingPeopleWithRelationsByEmail, {
        conflictingPerson: { id: repeatedConflictingContactId },
      });
      const phone = _.find(conflictingPeopleWithRelationsByPhone, {
        conflictingPerson: { id: repeatedConflictingContactId },
      });
      if (email.relation !== phone.relation) {
        const errorMessage = gettextCatalog.getString(
          'The selected email and phone belong to the same contact, so the selected relations must also be the same.'
        );
        throw new Error(errorMessage);
      }
    });

    _.each(conflictingPeopleWithRelation, (conflictingPersonWithRelation) => {
      const { relation, type } = conflictingPersonWithRelation;
      if (_.isEmpty(relation)) {
        const errorMessage =
          type === ContactInformationType.EMAIL
            ? gettextCatalog.getString(
                'You must provide a relation with the contact sharing the specified email address.'
              )
            : gettextCatalog.getString(
                'You must provide a relation with the contact sharing the specified phone number.'
              );
        throw new Error(errorMessage);
      }
    });

    // Relations processing

    /**
     * Group people by person ID since some people in the conflicting people list
     * could be duplicated (this is, the same person conflicting by email and phone).
     */
    const groupedConflictingPeople = _.groupBy(
      conflictingPeopleWithRelation,
      'conflictingPerson.id'
    );

    /**
     * If there are any existing relations for the person (update scenario), use them as
     * starting point and add any new relations to them or modify the existing ones if
     * applicable.
     */
    const relations = personExistingRelations || [];

    _.each(
      _.keys(groupedConflictingPeople),
      (groupedConflictingPeopleKey: string) => {
        /**
         * Take always the first person from the grouped conflicting people, as they
         * are the exact same person in each group.
         */
        const conflictingPersonWithRelation = _.first(
          groupedConflictingPeople[groupedConflictingPeopleKey]
        );
        const { conflictingPerson, relation } = conflictingPersonWithRelation;
        const conflictingPersonId = conflictingPerson.id;

        const existingConflictingPerson = _.find(relations, {
          person: { personId: conflictingPersonId },
        });

        /**
         * Before adding the relation check whether the conflicting person is already related,
         * in which case the relation should ONLY be updated.
         */
        if (existingConflictingPerson) {
          existingConflictingPerson.relation = relation;
          return;
        }

        relations.push({
          person: {
            personId: conflictingPersonId,
          },
          relation,
        });
      }
    );

    return relations;
  }
}

export default new PeopleService();
