'use strict';

class SelectFolderModalComponent {
  constructor($uibModal, $http, Filesystem, _) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$http = $http;
    this.Filesystem = Filesystem;
    this._ = _;
  }

  $onInit() {
    this.loading = false;
    this.breadcrumbs = [];
    this.folders = this.resolve.folders || [];
    this.groups = this.resolve.groups || [];
    this.groupId = this.resolve.groupId;
    this.showFolderGroup = this.resolve.showFolderGroup || false;
  }

  toggleSelection(folder) {
    this.selectedFolderId =
      this.selectedFolderId === folder.id ? null : folder.id;
  }

  createFolder() {
    const parentFolder = this._.last(this.breadcrumbs);

    this.$uibModal
      .open({
        component: 'cdCreateFolderModal',
        resolve: {
          groups: () => this.groups,
          groupId: () => this.groupId,
          parentFolderId: () => this._.get(parentFolder, 'id'),
        },
      })
      .result.then((newFolder) => {
        this.folders.push(newFolder);

        if (!this.selectedFolderId) {
          this.selectedFolderId = newFolder.id;
        }
      });
  }

  fetchFolders(parentFolderId) {
    this.loading = true;

    const params = {
      groupId: this.groupId,
      fromGallery: true,
      start: 0,
      length: 99999999,
    };

    if (parentFolderId) {
      params.folderId = parentFolderId;
    }

    this.Filesystem.getFilesFolders(params).$promise.then((res) => {
      this.folders = this._.filter(res.items, { type: 'folder' });
      this.loading = false;
    });
  }

  goToFolder(folder, $event) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    this.selectedFolderId = null;
    this.breadcrumbs.push(folder);
    this.fetchFolders(folder.id);
  }

  changeBreadcrumb(newBreadcrumb) {
    if (!newBreadcrumb) {
      this.breadcrumbs = [];
      this.fetchFolders();
    } else {
      const indexes = this._.map(this.breadcrumbs, (breadcrumb, index) => {
        if (newBreadcrumb.id === breadcrumb.id) return index;
      });

      this.breadcrumbs.splice(
        indexes[0] + 1,
        this.breadcrumbs.length - indexes[0] - 1
      );

      this.fetchFolders(newBreadcrumb.id);
    }
  }

  getGroupName(groupId) {
    return this._.get(this._.find(this.groups, { value: groupId }), 'label');
  }

  closeModal() {
    this.close({
      $value: this._.find(this.folders, { id: this.selectedFolderId }),
    });
  }
}

SelectFolderModalComponent.$inject = ['$uibModal', '$http', 'Filesystem', '_'];

angular.module('cdApp.shared').component('cdSelectFolderModal', {
  templateUrl:
    '@/app/shared/components/select-folder/select-folder.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: SelectFolderModalComponent,
});
