angular.module('cdApp.shared').factory('RotasLinks', [
  '$$resource',
  'cdApp',
  ($$resource, cdApp) => {
    'ngInject';

    return $$resource(
      `${cdApp.config.api.main}/shifts/rotas/link/:id`,
      {},
      {
        getPaginatedLinks: {
          method: 'GET',
          isArray: false,
        },

        deleteLink: {
          method: 'DELETE',
          url: `${cdApp.config.api.main}/shifts/rotas/link/:id`,
        },

        updateLink: {
          method: 'PUT',
          url: `${cdApp.config.api.main}/shifts/rotas/link/:id`,
        },
      }
    );
  },
]);
