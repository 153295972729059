import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Space, Switch } from 'antd';

import { usePrintEvent } from '../hooks/use-print-event';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdLock } from '@/react/shared/components/Icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const showPrintModal = createCdModal<{
  eventId: number;
  accessToSensitiveInfo: boolean;
}>(({ setModalProps, eventId, accessToSensitiveInfo }) => {
  const [sensitiveInformation, setSensitiveInformation] = React.useState(false);
  const { printEvent } = usePrintEvent({ eventId });
  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Print'),
      width: 600,
      okText: gettextCatalog.getString('Print'),
      onOk: async () => {
        await printEvent(sensitiveInformation);
      },
    });
  }, [printEvent, sensitiveInformation, setModalProps]);
  return (
    <Container>
      {gettextCatalog.getString(
        'You can print with or without sensitive information. Switch on the toggle to include it.'
      )}
      <Space>
        <Switch
          onChange={setSensitiveInformation}
          disabled={!accessToSensitiveInfo}
        />
        {gettextCatalog.getString('Include sensitive informaion')}
        {!accessToSensitiveInfo && (
          <CdTooltip
            title={gettextCatalog.getString(
              'You do not have permission to view sensitive information'
            )}
          >
            <CdLock />
          </CdTooltip>
        )}
      </Space>
    </Container>
  );
});
