import _ from 'lodash';
import { useSelector } from 'react-redux';

import { LookupsState } from '../../store/reducers/lookups';

export const useGetLookups = (): LookupsState =>
  useSelector((state) => _.get(state, 'filepicker.lookups', {}));

export const useFileValidator = () => {
  const { uploadValidator } = useGetLookups();
  return (file: File): boolean => {
    if (!uploadValidator) false;
    const isGt50 = file.size > uploadValidator.size;
    return !isGt50;
  };
};

export enum FilePermissions {
  createFile = 'create file',
  createFolder = 'create folder',
  changeVisibility = 'change visibility',
}
export const useHasPermission = () => {
  const { permissions } = useGetLookups();
  return (permission: FilePermissions) => {
    if (!permissions) return false;
    return permissions[permission.toString()];
  };
};
