import { useRecoilValue } from 'recoil';

import { WebsiteAnalytics } from '../../store/analytics';

export const useWebsitesAnalytics = () => {
  const websitesWithAnalytics = useRecoilValue(WebsiteAnalytics);
  return {
    websitesWithAnalytics,
  };
};
