export enum OrderOfServiceGeneralOrderingCriteria {
  DATE_TIME_CHURCH = 'Date/Time/Church',
  DATE_CHURCH_TIME = 'Date/Church/Time',
  CHURCH_DATE_TIME = 'Church/Date/Time',
  DATE_TIME_RESOURCE = 'Date/Time/Resource',
  DATE_RESOURCE_TIME = 'Date/Resource/Time',
  RESOURCE_DATE_TIME = 'Resource/Date/Time',
  DATE_CHURCH_EVENT = 'Date/Church/Event',
}

export enum OrderOfServiceResourceOrderingCriteria {
  ALPHABETICAL = 'Alphabetical',
  NUMERIC = 'Numeric',
}

export interface OrderOfServiceTemplate {
  title: string;
  description: string;
  screenshotUrl: string;
  id: string;
  orderBy: OrderOfServiceResourceOrderingCriteria;
  groupBy: OrderOfServiceGeneralOrderingCriteria;
  fileName: string;
  active: boolean;
  requiresStudioPackage: boolean;
}

export interface OrderOfServiceReportPayload {
  templateId: string;
  startDate: Date;
  endDate: Date;
  groupBy?: string;
  orderBy: string;
  categories?: number[];
  groups?: number[];
  resources?: number[];
  users?: number[];
  search?: string;
  eventsWithoutResourcesInChurchIds?: number[];
  additionalEventDataToInclude: OrderOfServiceAdditionalEventDataOptions[];
}

export type OrderOfServiceSearchParams = {
  startDate: Date;
  endDate: Date;
  categories?: number[];
  groups?: number[];
  resources?: number[];
  users?: number[];
  eventsWithoutResourcesInChurchIds?: number[];
  search?: string;
  disabled?: boolean;
  visibility?: string;
};

export enum OrderOfServiceAdditionalEventDataOptions {
  CONTRIBUTOR = 'contributor',
  LOCATION_NAME = 'locationName',
  PRICE = 'price',
  DESCRIPTION = 'description',
  ALL_DAY = 'allDay',
  SUMMARY = 'summary',
  LOCATION = 'location',
  PRIESTS = 'priests',
  INTENTIONS = 'intentions',
  CHURCH = 'church',
  RESOURCE = 'resource',
}
