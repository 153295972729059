'use strict';

class SelectFacebookPageController {
  constructor(_) {
    this._ = _;
  }
  select(pageId) {
    this.close({ $value: _.toString(pageId) });
  }
}
SelectFacebookPageController.$inject = ['_'];

angular.module('cdApp.shared').component('cdSelectFacebookPage', {
  template: require('./select-facebook-page.component.html'),
  controller: SelectFacebookPageController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
