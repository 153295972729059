import { $injector } from 'ngimport';
import { selectorFamily } from 'recoil';

class FeatureToggleService {
  public hasFeature(flagKey: string): Promise<boolean> {
    const featureToggleService: any = $injector.get('FeatureToggleService');
    return featureToggleService.hasFeature(flagKey);
  }
  public getContext(flagKey: string): Promise<boolean> {
    const featureToggleService: any = $injector.get('FeatureToggleService');
    return featureToggleService.getContext(flagKey);
  }
}
const featureToggleService = new FeatureToggleService();

export const FeatureFlagQuery = selectorFamily({
  key: 'FeatureFlagQuery',
  get: (key: string) => async () => await featureToggleService.hasFeature(key),
});

export default featureToggleService;
