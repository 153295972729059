import { createSelector } from 'reselect';

import { PartnerFiles } from '../../store/actions/partner';

const selectPartnerFiles = (state: any) => state.filepicker.partnerFiles;
const selectOwnProps = (state: any, props: any) => props;

const makeSelectFiles = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.items
  );

const makeSelectCurrentPage = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.pagination.currentPage
  );

const makeSelectTotalCount = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.pagination.count
  );

const makeSelectPartnerId = () =>
  createSelector(selectOwnProps, (props) => props.partnerId);

const makeSelectPageSize = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.pagination.pageSize
  );

const makeSelectSelectedFile = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.selectedFile
  );

const makeSelectPageSizeOptions = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.pagination.pageSizeOptions
  );

const makeSelectCallback = () =>
  createSelector(selectOwnProps, (props) => props.callback);

const makeSelectPartnerFilesLoading = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.partnerFilesLoading
  );

const makeSelectPartnerFilesPublicationFilters = () =>
  createSelector(
    selectPartnerFiles,
    (partnerFiles: PartnerFiles) => partnerFiles.filters.publication
  );

const makeSelectIsGebCustomer = () =>
  createSelector(selectOwnProps, (props) => props.isGebCustomer);

const makeSelectIsUsedFromEditor = () =>
  createSelector(selectOwnProps, (props) => props.isUsedFromEditor);

export {
  makeSelectFiles,
  makeSelectCurrentPage,
  makeSelectTotalCount,
  makeSelectPartnerId,
  makeSelectPageSize,
  makeSelectSelectedFile,
  makeSelectPageSizeOptions,
  makeSelectCallback,
  makeSelectPartnerFilesLoading,
  makeSelectPartnerFilesPublicationFilters,
  makeSelectIsGebCustomer,
  makeSelectIsUsedFromEditor,
};
