import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { FetchUserRoles, ResetUserEntity } from '../redux/actions';

export const useFetchUserRoles = (userId: number) => {
  const dispatch = useDispatch();
  return () => dispatch(FetchUserRoles({ userId }));
};

export const useFetchUserRolesWithEffect = (userId: number) => {
  const dispatch = useDispatch();

  const fetchUserRoles = useFetchUserRoles(userId);
  useEffect(() => {
    if (!userId) return;
    fetchUserRoles();
    return () => {
      dispatch(ResetUserEntity());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, dispatch]);
};
