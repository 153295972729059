export interface PeopleChurch {
  id: number;
  name: string;
}

export interface PersonTag {
  id: number;
  name: string;
}

export enum GenderTypes {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}
export interface People {
  id: number;
  email: string;
  phone: string;
  name: string;
  firstName: string;
  lastName: string;
  picture: string;
  roles: number[];
  status: number;
  lastAccess: Date;
  createdAt: Date;
  groups: number[];
}

export interface Person {
  allergy: string;
  churches: PeopleChurch[];
  city?: string;
  contactId: number;
  countryIso2?: string;
  deletedAt: string;
  email: string;
  emailNotification: boolean;
  emailStatus: string;
  fields: PeopleFields[];
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  organizationId: number;
  phone?: string;
  picture: { url?: string };
  postCode?: string;
  smsNotification: boolean;
  street?: string;
  tags: PersonTag;
  peopleNewsletterLists: PeopleNewsletterLists[];
}

export interface PeopleNewsletterLists {
  id: number;
  name: string;
  subscribed: boolean;
}
export interface ParticipantLists {
  id: number;
  name: string;
  subscribed: boolean;
}
interface PeopleFields {
  label: string;
  value: string;
  property: string;
  type: string;
  group?: string;
  sensitive: boolean;
}

export interface PeopleFromSearch {
  id: number;
  organizationId: number;
  contactId: number;
  picture: string;
  fields: PeopleFields[];
}

export interface PeopleSearch {
  total: number;
  people: PeopleFromSearch[];
  totalMatching: number;
  searchAfter: [];
}

export interface PeopleAnniversarySettings {
  anniversariesTypeSetting: {
    [key: string]: {
      checked: boolean;
      selected?: number[];
      label: string;
    };
  };
  usersToNotify: number[];
}
export interface PeopleAnniversaryUsersToNotify {
  id: number;
  name: string;
  email: string;
  picture: string;
}
