(function () {
  'use strict';

  function matchHeight() {
    return {
      restrict: 'A',
      link: function (scope, element, attributes) {
        // watch over the height of the target element
        scope.$watch(
          function () {
            return angular.element(attributes.matchHeight).height();
          },
          function (newVal, oldVal) {
            if (newVal !== oldVal) {
              // match the height of the element with that of the target
              element.css({
                minHeight: angular.element(attributes.matchHeight).height(),
              });
            }
          }
        );
      },
    };
  }

  angular.module('cdApp.shared').directive('matchHeight', matchHeight);
})();
