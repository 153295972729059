import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { FormInstance } from 'antd';

import { PortalResponse } from '../types/portal';
import { WidgetInterface } from '../types/widget.type';

import {
  GetOrganizationPortals,
  GetPortal,
  PortalOrganizationsAndCategories,
} from '@/react/portal/state/portal';
import { SelectedOrganizationId } from '@/react/organization/store/organization';

export default function usePortalWidget(
  widgetData: WidgetInterface,
  form: FormInstance
) {
  const selectedOrganizationId = useRecoilValue<number>(SelectedOrganizationId);
  const organizationPortals = useRecoilValue<PortalResponse>(
    GetOrganizationPortals(selectedOrganizationId)
  );
  const [selectedPortalId, setSelectedPortalId] = useState<string>(
    widgetData?.configuration?.collaboration
      ? widgetData?.configuration?.collaboration.portalId
      : organizationPortals.portals && organizationPortals.portals.length > 0
        ? organizationPortals.portals[0].id
        : undefined
  );

  const selectedPortal: PortalOrganizationsAndCategories =
    useRecoilValue<PortalOrganizationsAndCategories>(
      GetPortal(selectedPortalId)
    );

  const [timeRangeType, setTimeRangeType] = useState(
    typeof widgetData?.configuration?.timeRange === 'object' &&
      widgetData?.configuration?.timeRange !== null
      ? 'dateRange'
      : widgetData?.configuration?.timeRange
        ? 'days'
        : 'all'
  );

  const onTimeRangeTypeChange = (type: string) => {
    if (type === 'days') {
      form.setFieldValue(
        ['configuration', 'timeRange'],
        widgetData?.configuration?.timeRange
      );
    } else if (type === 'all') {
      form.setFieldValue(['configuration', 'timeRange'], undefined);
    } else if (type === 'dateRange') {
      form.setFieldValue(
        ['configuration', 'dateRange'],
        widgetData?.configuration?.timeRange
      );
    }
    setTimeRangeType(type);
  };

  const onFormValuesChange = (values: WidgetInterface) => {
    if (values.configuration?.collaboration?.portalId) {
      form.setFieldValue(
        ['configuration', 'collaboration', 'portalCategoryIds'],
        []
      );
      form.setFieldValue(
        ['configuration', 'collaboration', 'organizationIds'],
        []
      );
      setSelectedPortalId(values.configuration.collaboration.portalId);
    }
  };

  return {
    organizationPortals,
    selectedPortal,
    onFormValuesChange,
    timeRangeType,
    onTimeRangeTypeChange,
  };
}
