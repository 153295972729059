import { react2angular } from 'react18-react2angular';

import AddFormToEventModal from '../../../react/forms/components/AddFormToEventModal';

angular
  .module('cdApp.forms')
  .component(
    'cdrAddFormToEventModal',
    react2angular(AddFormToEventModal, ['modalInstance', 'resolve'], [])
  );
