(function () {
  'use strict';

  function cdCommentsFormController() {
    let $ctrl = this;
    $ctrl.postComment = function () {
      if (!$ctrl.newComment) return;
      $ctrl.cdCommentsController.addComment({
        comment: $ctrl.newComment,
        onSuccess: function () {
          $ctrl.newComment = '';
        },
      });
    };
  }

  angular.module('cdApp.shared').directive('cdCommentsForm', function () {
    return {
      restrict: 'E',
      templateUrl:
        '@/app/shared/components/cd-comments-form/cd-comments-form.html',
      replace: true,
      controller: cdCommentsFormController,
      controllerAs: '$ctrl',
      require: '^cdComments',
      bindToController: true,
      link: function (scope, element, attrs, cdCommentsController) {
        let $ctrl = scope.$ctrl;
        $ctrl.cdCommentsController = cdCommentsController;
        $ctrl.placeholderText = $ctrl.cdCommentsController.placeholderText;

        let keydown = false;
        element.on('keydown', function (event) {
          if (event.which === 13 && (event.ctrlKey || event.metaKey)) {
            if (!keydown) {
              keydown = true;
              event.preventDefault();
              $ctrl.postComment();
              return false;
            }
          }
        });
      },
    };
  });
})();
