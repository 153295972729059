'use strict';

angular.module('cdApp.forms').component('formTicketsDetailsModal', {
  templateUrl: '@/app/forms/view-form/modals/form-tickets-details.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller() {
    this.$onInit = () => {
      const form = this.resolve.form;
      this.tickets = _.map(this.resolve.ticketComponents, (ticketComponent) => {
        const sold = _.get(form, `tickets.${ticketComponent.key}.sold`);
        const total = _.get(form, `tickets.${ticketComponent.key}.max`);
        return { sold, total, label: ticketComponent.label };
      });
    };
  },
});
