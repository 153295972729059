import { Popover } from 'antd';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { Type, convertTypeToEventType } from '../models/calendar';

import { EventPopoverContent } from './EventPopoverContent';

// Do nothing.
const stopPropagation = () => true;

/**
 * Generic Event Popover
 */
export const EventPopoverGeneric: FunctionComponent<{
  eventId: number;
  eventType: Type;
  children: ReactNode;
  trigger: any;
}> = ({ eventId, eventType, children, trigger }) => {
  const [visible, setVisible] = useState(false);

  const handleHoverChange = (visible) => {
    setVisible(visible);
  };

  const closeToolTip = () => {
    setVisible(false);
  };
  const content = (
    <EventPopoverContent
      closeToolTip={closeToolTip}
      stopPropagation={stopPropagation}
      eventId={eventId}
      eventType={convertTypeToEventType(eventType)}
    />
  );

  return (
    <Popover
      title={false}
      content={content}
      arrow={{ pointAtCenter: false }}
      placement="left"
      trigger={trigger}
      autoAdjustOverflow
      overlayStyle={{
        padding: 0,
        backgroundColor: 'transparent',
      }}
      overlayInnerStyle={{ padding: '0px' }}
      overlayClassName="event-popover-generic"
      open={visible}
      onOpenChange={handleHoverChange}
      destroyTooltipOnHide
    >
      {children}
    </Popover>
  );
};

/**
 * Event Popover surrounding a date box allowing the component to be added in AngularJS.
 */
export const EventPopoverDate: FunctionComponent<{
  date: Date;
  eventId: number;
  eventType: Type;
}> = ({ date, eventId, eventType }) => (
  <EventPopoverGeneric eventId={eventId} eventType={eventType} trigger="hover">
    <div className="date-box">
      <div className="date-box__day">
        <span>{moment(date).format('DD')}</span>
      </div>
      <div className="date-box__month">
        <span>{moment(date).format('MMM')}</span>
      </div>
    </div>
  </EventPopoverGeneric>
);

/**
 * Event Popover surrounding an event title allowing the component to be added in AngularJS.
 */
export const EventPopoverAttendance: FunctionComponent<{
  eventId: number;
  eventType: Type;
  title: string;
  trigger: string;
}> = ({ eventId, eventType, title, trigger }) => (
  <EventPopoverGeneric
    eventId={eventId}
    eventType={eventType}
    trigger={trigger}
  >
    {title}
  </EventPopoverGeneric>
);
