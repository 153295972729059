import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

import ElementWrapper from './ElementWrapper';

const Author = ({ authorName }: { authorName: string }) => (
  <ElementWrapper>
    <FontAwesomeIcon icon={faUser} />
    <span>{authorName}</span>
  </ElementWrapper>
);

export default Author;
