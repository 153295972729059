(function () {
  'use strict';

  angular
    .module('cdApp.shared')
    .component('cdPageStatistic', {
      templateUrl:
        '@/app/shared/components/page-statistics/page-statistics.component.html',
      bindings: {
        value: '<',
        text: '<',
        color: '<',
        link: '<',
        active: '<',
        interactive: '<',
      },
    })
    .component('cdPageStatistics', {
      template: '<div ng-transclude class="page-statistics"></div>',
      transclude: true,
    });
})();
