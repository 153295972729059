'use strict';
relationService.$inject = ['gettextCatalog'];

import { PeopleRelationType } from '../../../../react/shared/models/people';

function relationService(gettextCatalog) {
  const relations = {
    [PeopleRelationType.PARTNER]: {
      label: gettextCatalog.getString('Partner'),
      icon: 'fa fa-heart',
    },

    [PeopleRelationType.PARENT]: {
      label: gettextCatalog.getString('Parent / Guardian'),
      icon: 'fa fa-user',
    },

    [PeopleRelationType.CHILD]: {
      label: gettextCatalog.getString('Child'),
      icon: 'fa fa-child',
    },

    [PeopleRelationType.SIBLING]: {
      label: gettextCatalog.getString('Sibling'),
      icon: 'fa fa-users',
    },

    [PeopleRelationType.UNSPECIFIED]: {
      label: gettextCatalog.getString('Family relationship is not specified'),
      icon: 'fa fa-question',
    },
  };

  return {
    getRelations: function () {
      return _.map(_.keys(relations), function (key) {
        return {
          value: key,
          icon: relations[key].icon,
          label: relations[key].label,
        };
      });
    },

    getRelation: function (relationName) {
      return _.get(relations, relationName);
    },

    getRelationInfo: function (relationName, info) {
      return _.get(relations, [relationName, info]);
    },
  };
}

angular.module('cdApp.people').factory('relationService', relationService);
