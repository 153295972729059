(function () {
  'use strict';
  MyGroups.$inject = ['$$resource'];

  function MyGroups($$resource) {
    return $$resource(
      cdApp.config.api.main +
        '/organizations/:organizationId/users/:userId/groups'
    );
  }

  angular.module('cdApp.shared').factory('MyGroups', MyGroups);
})();
