import React, { useEffect } from 'react';

import { TimeRegistrationLandingPage } from './TimeRegistrationLandingPage';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const showTimeRegistrationLandingModal = createCdModal(
  ({ setModalProps }) => {
    useEffect(() => {
      setModalProps({
        modalType: ModalType.POPUP,
        title: '',
        width: 600,
      });
    }, [setModalProps]);

    return (
      <div style={{ height: 700, margin: 24 }}>
        <TimeRegistrationLandingPage />
      </div>
    );
  }
);
