'use strict';

class EditFormResponseController {
  constructor($scope, $state, gettextCatalog, _, cdApp) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;

    /**
     * Intercept the submit event from form.io and save the submission
     */
    $scope.$on('formSubmission', () => {
      this.formResponse.$save(() => {
        $state.go('app.private.forms.responses', {
          formId: this.form.id,
          responseId: this.formResponse.id,
        });
      });
    });
  }

  $onInit() {
    const { _, cdApp } = this;

    this.form.editingResponse = true;
    this.cdMainApi = cdApp.config.api.main;
    this.cdFormsApi = cdApp.config.api.form;
    this.cdPaymentsFrontendUrl = cdApp.references.paymentsFrontend;
    this.environment = cdApp.config.environment;
    this.organizationId = cdApp.organization.id;
    this.organizationCountry = cdApp.organization.countryIso2;
    this.organizationCurrency = cdApp.organization.currency;
    this.organizationName = cdApp.organization.name;

    // Change label of the submit button of the form
    const submitButton = _.find(this.form.components, {
      type: 'button',
      action: 'submit',
    });

    submitButton.label = this.gettextCatalog.getString('Save');

    // Release the tickets temporarily in the form so that we can show the correct number of remaining tickets
    _.forEach(this.form.getTicketComponents(), (ticketComponent) => {
      const ticket = _.get(this.form, `tickets.${ticketComponent.key}`);
      const claimedTickets = _.get(
        this.formResponse,
        `data.${ticketComponent.path}`,
        0
      );

      _.set(
        this.form,
        `tickets.${ticketComponent.key}.sold`,
        ticket.sold - claimedTickets
      );

      _.set(
        this.form,
        `tickets.${ticketComponent.key}.remaining`,
        ticket.remaining + claimedTickets
      );
    });
  }
}

EditFormResponseController.$inject = [
  '$scope',
  '$state',
  'gettextCatalog',
  '_',
  'cdApp',
];

angular.module('cdApp.forms').component('cdEditFormResponse', {
  templateUrl: '@/app/forms/edit-form-response/edit-form-response.html',
  bindings: {
    form: '<formObject',
    formResponse: '<',
    userPermissions: '<',
  },

  controller: EditFormResponseController,
});
