import { all, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { withLoadingAndErrors } from '../../shared/loading/saga';
import {
  FETCH_FOUNDATIONS,
  FETCH_FOUNDATION,
  CREATE_FOUNDATION,
  UPDATE_FOUNDATION,
  CHANGE_FOUNDATION_STATUS,
  DELETE_FOUNDATION,
  FETCH_INTENTIONS_FOR_FOUNDATION,
  RESTORE_FOUNDATION,
  GENERATE_FOUNDATION_INTENTIONS_REPORT,
} from '../redux/foundations/Actions';
import {
  FETCH_EVENT_INTENTIONS,
  FETCH_INTENTIONS,
  FETCH_INTENTION,
  CREATE_INTENTION,
  UPDATE_INTENTION,
  DELETE_INTENTION,
  RESTORE_INTENTION,
  FETCH_INTENTION_EVENTS,
  ASSIGN_INTENTION,
  UNASSIGN_INTENTION,
  UPDATE_EVENT_PRIORITY,
  FETCH_INTENTION_PARENT,
  REFRESH_INTENTION_OVERVIEW,
  FETCH_BILLING_INTENTIONS,
  GENERATE_INTENTION_BILLING_REPORT,
  GENERATE_INTENTION_LIST_REPORT,
} from '../redux/intentions/Actions';
import {
  FETCH_EVENT_STOLE,
  FETCH_STOLES,
  FETCH_STOLE_BILLING_EVENTS,
  RESTORE_STOLE,
  GENERATE_STOLE_BILLING_REPORT,
  REFRESH_EVENT_STOLE,
} from '../redux/stoles/Actions';
import { FETCH_ORDER_OF_SERVICE_REPORT_EVENTS } from '../redux/intention-reports/Actions';
import {
  CREATE_COLLECTION,
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  FETCH_ALL_COLLECTIONS,
  PUT_COLLECTION,
  PUT_COLLECTION_SUCCESS,
} from '../redux/collection/Actions';

import {
  getIntentionSaga,
  getIntentionsSaga,
  createIntentionSaga,
  updateIntentionSaga,
  deleteIntentionSaga,
  restoreIntentionSaga,
  fetchIntentionEventsSaga,
  assignIntentionSaga,
  unassignIntentionSaga,
  getEventIntentionsSaga,
  updateEventPrioritySaga,
  getIntentionParentSaga,
  refreshIntentionOverviewSaga,
  fetchBillingIntentionSaga,
  generateIntentionBillingReport,
  generateIntentionListReportSaga,
} from './Intentions';
import {
  getFoundationsSaga,
  getFoundationSaga,
  createFoundationSaga,
  updateFoundationSaga,
  changeFoundationStatusSaga,
  deleteFoundationSaga,
  restoreFoundationSaga,
  fetchIntentionsForFoundationSaga,
  generateFoundationIntentionsReport,
} from './foundations';
import {
  getEventStoleSaga,
  getStolesSaga,
  fetchStoleBillingEventsSaga,
  generateStoleBillingReport,
  refreshEventStoleSaga,
  restoreStoleSaga,
} from './Stoles';
import { fetchOrderOfServiceReportEventsSaga } from './IntentionReports';
import {
  fetchAllCollections,
  putCollection,
  deleteCollection,
  createCollection,
} from './Collection';

export default function* root(): Generator {
  yield all([
    takeLatest(
      FETCH_ALL_COLLECTIONS,
      withLoadingAndErrors(FETCH_ALL_COLLECTIONS, fetchAllCollections)
    ),
    takeLatest(
      PUT_COLLECTION,
      withLoadingAndErrors(PUT_COLLECTION, putCollection)
    ),
    takeLatest(
      CREATE_COLLECTION,
      withLoadingAndErrors(CREATE_COLLECTION, createCollection)
    ),
    takeLatest(
      DELETE_COLLECTION,
      withLoadingAndErrors(DELETE_COLLECTION, deleteCollection)
    ),
    takeLatest(
      CREATE_COLLECTION_SUCCESS,
      withLoadingAndErrors(FETCH_ALL_COLLECTIONS, fetchAllCollections),
      // @ts-ignore TS dont belive takeLatest can take 3 arguments
      {
        payload: {
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment().endOf('year').format('YYYY-MM-DD'),
        },
      }
    ),
    takeLatest(
      DELETE_COLLECTION_SUCCESS,
      withLoadingAndErrors(FETCH_ALL_COLLECTIONS, fetchAllCollections),
      // @ts-ignore TS dont belive takeLatest can take 3 arguments
      {
        payload: {
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment().endOf('year').format('YYYY-MM-DD'),
        },
      }
    ),
    takeLatest(
      PUT_COLLECTION_SUCCESS,
      withLoadingAndErrors(FETCH_ALL_COLLECTIONS, fetchAllCollections),
      // @ts-ignore TS dont belive takeLatest can take 3 arguments
      {
        payload: {
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment().endOf('year').format('YYYY-MM-DD'),
        },
      }
    ),
    // Intentions
    takeLatest(
      FETCH_INTENTIONS,
      withLoadingAndErrors(FETCH_INTENTIONS, getIntentionsSaga)
    ),
    takeLatest(
      FETCH_INTENTION,
      withLoadingAndErrors(FETCH_INTENTION, getIntentionSaga)
    ),
    takeLatest(
      FETCH_INTENTION_PARENT,
      withLoadingAndErrors(FETCH_INTENTION_PARENT, getIntentionParentSaga)
    ),
    takeLatest(
      FETCH_EVENT_INTENTIONS,
      withLoadingAndErrors(FETCH_EVENT_INTENTIONS, getEventIntentionsSaga)
    ),
    takeLatest(
      CREATE_INTENTION,
      withLoadingAndErrors(CREATE_INTENTION, createIntentionSaga)
    ),
    takeLatest(
      UPDATE_INTENTION,
      withLoadingAndErrors(UPDATE_INTENTION, updateIntentionSaga)
    ),
    takeLatest(
      DELETE_INTENTION,
      withLoadingAndErrors(DELETE_INTENTION, deleteIntentionSaga)
    ),
    takeLatest(
      RESTORE_INTENTION,
      withLoadingAndErrors(RESTORE_INTENTION, restoreIntentionSaga)
    ),
    takeLatest(
      FETCH_INTENTION_EVENTS,
      withLoadingAndErrors(FETCH_INTENTION_EVENTS, fetchIntentionEventsSaga)
    ),
    takeLatest(
      ASSIGN_INTENTION,
      withLoadingAndErrors(ASSIGN_INTENTION, assignIntentionSaga)
    ),
    takeLatest(
      UNASSIGN_INTENTION,
      withLoadingAndErrors(UNASSIGN_INTENTION, unassignIntentionSaga)
    ),
    takeLatest(
      UPDATE_EVENT_PRIORITY,
      withLoadingAndErrors(UPDATE_EVENT_PRIORITY, updateEventPrioritySaga)
    ),
    takeLatest(
      REFRESH_INTENTION_OVERVIEW,
      withLoadingAndErrors(
        REFRESH_INTENTION_OVERVIEW,
        refreshIntentionOverviewSaga
      )
    ),
    takeLatest(
      FETCH_BILLING_INTENTIONS,
      withLoadingAndErrors(FETCH_BILLING_INTENTIONS, fetchBillingIntentionSaga)
    ),
    takeLatest(
      GENERATE_INTENTION_BILLING_REPORT,
      withLoadingAndErrors(
        GENERATE_INTENTION_BILLING_REPORT,
        generateIntentionBillingReport
      )
    ),
    takeLatest(
      GENERATE_INTENTION_LIST_REPORT,
      withLoadingAndErrors(
        GENERATE_INTENTION_LIST_REPORT,
        generateIntentionListReportSaga
      )
    ),
    // Foundations
    takeLatest(
      FETCH_FOUNDATIONS,
      withLoadingAndErrors(FETCH_FOUNDATIONS, getFoundationsSaga)
    ),
    takeLatest(
      FETCH_FOUNDATION,
      withLoadingAndErrors(FETCH_FOUNDATION, getFoundationSaga)
    ),
    takeLatest(
      CREATE_FOUNDATION,
      withLoadingAndErrors(CREATE_FOUNDATION, createFoundationSaga)
    ),
    takeLatest(
      UPDATE_FOUNDATION,
      withLoadingAndErrors(UPDATE_FOUNDATION, updateFoundationSaga)
    ),
    takeLatest(
      CHANGE_FOUNDATION_STATUS,
      withLoadingAndErrors(CHANGE_FOUNDATION_STATUS, changeFoundationStatusSaga)
    ),
    takeLatest(
      DELETE_FOUNDATION,
      withLoadingAndErrors(DELETE_FOUNDATION, deleteFoundationSaga)
    ),
    takeLatest(
      RESTORE_FOUNDATION,
      withLoadingAndErrors(RESTORE_FOUNDATION, restoreFoundationSaga)
    ),
    takeLatest(
      FETCH_INTENTIONS_FOR_FOUNDATION,
      withLoadingAndErrors(
        FETCH_INTENTIONS_FOR_FOUNDATION,
        fetchIntentionsForFoundationSaga
      )
    ),
    takeLatest(
      GENERATE_FOUNDATION_INTENTIONS_REPORT,
      withLoadingAndErrors(
        GENERATE_FOUNDATION_INTENTIONS_REPORT,
        generateFoundationIntentionsReport
      )
    ),
    // Intention Reports
    takeLatest(
      FETCH_ORDER_OF_SERVICE_REPORT_EVENTS,
      withLoadingAndErrors(
        FETCH_ORDER_OF_SERVICE_REPORT_EVENTS,
        fetchOrderOfServiceReportEventsSaga
      )
    ),
    // Stoles
    takeLatest(
      FETCH_EVENT_STOLE,
      withLoadingAndErrors(FETCH_EVENT_STOLE, getEventStoleSaga)
    ),
    takeLatest(
      REFRESH_EVENT_STOLE,
      withLoadingAndErrors(REFRESH_EVENT_STOLE, refreshEventStoleSaga)
    ),
    takeLatest(FETCH_STOLES, withLoadingAndErrors(FETCH_STOLES, getStolesSaga)),
    takeLatest(
      FETCH_STOLE_BILLING_EVENTS,
      withLoadingAndErrors(
        FETCH_STOLE_BILLING_EVENTS,
        fetchStoleBillingEventsSaga
      )
    ),
    takeLatest(
      RESTORE_STOLE,
      withLoadingAndErrors(RESTORE_STOLE, restoreStoleSaga)
    ),
    takeLatest(
      GENERATE_STOLE_BILLING_REPORT,
      withLoadingAndErrors(
        GENERATE_STOLE_BILLING_REPORT,
        generateStoleBillingReport
      )
    ),
  ]);
}
