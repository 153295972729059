import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

import { gettextCatalog } from '../../services/I18nService';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const getIcon = (status: string) => {
  const defaultClass = 'fa';
  switch (status) {
    case 'yes':
      return {
        icon: faCheckCircle,
        reply: gettextCatalog.getString('Going'),
        color: defaultClass + ' text-success',
      };
    case 'maybe':
      return {
        icon: faQuestionCircle,
        reply: gettextCatalog.getString('Maybe'),
        color: defaultClass + ' text-warning',
      };
    case 'no':
      return {
        icon: faTimesCircle,
        reply: gettextCatalog.getString('Not going'),
        color: defaultClass + ' text-danger',
      };
    case 'no-answer':
      return {
        icon: faCircle,
        reply: gettextCatalog.getString('No reply'),
        color: defaultClass + ' text-muted',
      };
    default:
      return null;
  }
};

const InvitationResponse: FunctionComponent<{ status: string }> = ({
  status,
}) => {
  const iconData = getIcon(status);
  if (!iconData) return null;
  return (
    <div className="invitation-response u-inline-block">
      <CdTooltip title={iconData.reply}>
        <FontAwesomeIcon
          icon={iconData.icon}
          className={iconData.color}
          style={{ marginRight: 8 }}
        />
      </CdTooltip>
    </div>
  );
};

export default InvitationResponse;
