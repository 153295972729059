import React, { useCallback } from 'react';
import { Button, Space } from 'antd';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';

import { showCropImageModal } from './CoverImageCrop';

import { gettextCatalog } from '@/react/services/I18nService';
import { FilePickerComponentProps } from '@/react/calendar/file-picker-library/FilePickerComponent';

const CoverImageMenu = styled(Space)`
  &&&& {
    position: absolute;
    display: none;
    opacity: 1;
    z-index: 1;
  }
`;

const CoverImageMenuBtn = Button;

const CoverImageImg = styled.img`
  width: 600px;
  height: 325px;
`;

const CoverImageContainer = styled.div`
  background-color: #808080;
  border: none;
  display: flex;
  flex: 1 1;
  position: relative;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;

  &:hover img {
    border-radius: 10px;
    opacity: 0.5;
  }

  &&&&:hover ${CoverImageMenu} {
    display: inline-flex;
    opacity: 1;
  }
`;

interface CoverImageProps {
  messageId: number;
  value?: { id: number; url: string };
  onChange?: (coverImg: { id: number | null; url?: string }) => void;
  refetchMessage: any;
}

export default function CoverImage({
  value,
  onChange,
  messageId,
  refetchMessage,
}: CoverImageProps) {
  const selectImage = useCallback(() => {
    // Open CD file archive
    NiceModal.show<FilePickerComponentProps>('FilePickerComponentModal', {
      isUsedFromEditor: false,
    } as FilePickerComponentProps).then(({ file }: any) => {
      onChange({ id: file.id });
    });
  }, [onChange]);

  const showCropModal = useCallback(() => {
    showCropImageModal({
      fileId: value?.id,
      entityId: messageId,
      entityType: 'people-message',
      onlyCropType: 'people-message-simple',
    }).then(() => {
      refetchMessage(true);
    });
  }, [messageId, refetchMessage, value]);

  const removeCoverImage = useCallback(
    () => onChange({ id: null }),
    [onChange]
  );

  return (
    <CoverImageContainer>
      <CoverImageMenu direction="horizontal">
        {value?.url && (
          <CoverImageMenuBtn onClick={showCropModal} type="primary">
            {gettextCatalog.getString('Crop')}
          </CoverImageMenuBtn>
        )}

        <CoverImageMenuBtn
          onClick={selectImage}
          type={value?.url ? 'default' : 'primary'}
        >
          {value?.url
            ? gettextCatalog.getString('Change Image')
            : gettextCatalog.getString('Select Image')}
        </CoverImageMenuBtn>

        {value?.url && (
          <CoverImageMenuBtn onClick={removeCoverImage} type="default">
            {gettextCatalog.getString('Remove')}
          </CoverImageMenuBtn>
        )}
      </CoverImageMenu>
      <CoverImageImg
        src={
          !!value?.url
            ? value.url
            : `${window.cdApp.references.appUrl}/img/placeholder.jpeg`
        }
      />
    </CoverImageContainer>
  );
}
