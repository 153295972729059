import { Crisp } from 'crisp-sdk-web';

export const BeaconChatService = {
  showMessages: () => {
    if (Crisp.isCrispInjected()) {
      Crisp.chat.show();
      Crisp.chat.open();
    } else {
      window.open('mailto:support@churchdesk.com');
    }
  },

  showNewMessage: () => {
    if (Crisp.isCrispInjected()) {
      Crisp.chat.show();
      Crisp.chat.open();
    } else {
      window.open('mailto:support@churchdesk.com');
    }
  },
};
