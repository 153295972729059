import { Form } from 'antd';
import React from 'react';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';

import { gettextCatalog } from '@/react/services/I18nService';
import cdApp from '@/react/config';
import { getCountryList } from '@/react/services/locale/country.service';

export const PhoneInput = ({ name, label }) => {
  const country = cdApp.organization.countryIso2;

  return (
    <ConfigProvider locale={getCountryList()}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: false,
            validator(
              _,
              value: { phone: string; code: number; short: CountryCode }
            ) {
              try {
                if (
                  !value?.phone?.length ||
                  isValidPhoneNumber(`+${value.code}${value.phone}`)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error(
                      gettextCatalog.getString(
                        'Please input a valid phone number'
                      )
                    )
                  );
                }
              } catch (e) {
                // Mute errors
                return Promise.reject(
                  new Error(
                    gettextCatalog.getString(
                      'Please input a valid phone number'
                    )
                  )
                );
              }
            },
          },
        ]}
        initialValue={{
          short: country,
        }}
        style={{ marginBottom: '10px' }}
      >
        <CountryPhoneInput inline />
      </Form.Item>
    </ConfigProvider>
  );
};
