import { ApiResponse } from 'apisauce';

import { API } from '../api';

export const getGebAccess = async (partner: string) => {
  const response: ApiResponse<any> = await API.get(
    '/partner-files/is-customer',
    { partner }
  );
  if (response.ok) {
    return response.data;
  } else {
    throw response.originalError;
  }
};
