import { ActionCreator, CdAction } from '../../../redux/utils';
import { IntentionReportTemplatesData } from '../../services/IntentionReportTemplateService';
import {
  IntentionReportTemplateTypes,
  IntentionReportTemplate,
} from '../../models/report-template';

export type ClearIntentionReportTemplateActionType = CdAction<void>;
export const CLEAR_INTENTION_REPORT_TEMPLATE =
  'intention/clearIntentionReportTemplate';
export const clearIntentionReportTemplate: ActionCreator<void> = () => ({
  type: CLEAR_INTENTION_REPORT_TEMPLATE,
});

// Fetch multiple intention report templates

interface FetchIntentionReportTemplatesPayload {
  type?: IntentionReportTemplateTypes;
}
export type FetchIntentionReportTemplatesActionType =
  CdAction<FetchIntentionReportTemplatesPayload>;
export const FETCH_INTENTION_REPORT_TEMPLATES =
  'intention/fetchIntentionReportTemplates';
export const fetchIntentionReportTemplates: ActionCreator<
  FetchIntentionReportTemplatesPayload
> = (payload) => ({
  type: FETCH_INTENTION_REPORT_TEMPLATES,
  payload,
});

export type FetchIntentionReportTemplatesSuccessActionType =
  CdAction<IntentionReportTemplatesData>;
export const FETCH_INTENTION_REPORT_TEMPLATES_SUCCESS =
  'intention/fetchIntentionReportTemplatesSuccess';
export const fetchIntentionReportTemplatesSuccess: ActionCreator<
  IntentionReportTemplatesData
> = (payload) => ({
  type: FETCH_INTENTION_REPORT_TEMPLATES_SUCCESS,
  payload,
});

// Fetch intention report template

export type FetchIntentionReportTemplateActionType = CdAction<string>;
export const FETCH_INTENTION_REPORT_TEMPLATE =
  'intention/fetchIntentionReportTemplate';
export const fetchIntentionReportTemplate: ActionCreator<string> = (
  payload
) => ({
  type: FETCH_INTENTION_REPORT_TEMPLATE,
  payload,
});

export type FetchIntentionReportTemplateSuccessActionType =
  CdAction<IntentionReportTemplate>;
export const FETCH_INTENTION_REPORT_TEMPLATE_SUCCESS =
  'intention/fetchIntentionReportTemplateSuccess';
export const fetchIntentionReportTemplateSuccess: ActionCreator<
  IntentionReportTemplate
> = (payload) => ({
  type: FETCH_INTENTION_REPORT_TEMPLATE_SUCCESS,
  payload,
});

// Create intention report template

export type CreateIntentionReportTemplateActionType =
  CdAction<IntentionReportTemplate>;
export const CREATE_INTENTION_REPORT_TEMPLATE =
  'intention/createIntentionReportTemplate';
export const createIntentionReportTemplate: ActionCreator<
  IntentionReportTemplate
> = (payload) => ({
  type: CREATE_INTENTION_REPORT_TEMPLATE,
  payload,
});

// Update intention report template

interface UpdateIntentionReportTemplatePayload {
  id: string;
  updatePayload: IntentionReportTemplate;
}
export type UpdateIntentionReportTemplateActionType =
  CdAction<UpdateIntentionReportTemplatePayload>;
export const UPDATE_INTENTION_REPORT_TEMPLATE =
  'intention/updateIntentionReportTemplate';
export const updateIntentionReportTemplate: ActionCreator<
  UpdateIntentionReportTemplatePayload
> = (payload) => ({
  type: UPDATE_INTENTION_REPORT_TEMPLATE,
  payload,
});

// Delete intention report template

interface DeleteIntentionReportTemplatePayload
  extends FetchIntentionReportTemplatesPayload {
  id: string;
}
export type DeleteIntentionReportTemplateActionType =
  CdAction<DeleteIntentionReportTemplatePayload>;
export const DELETE_INTENTION_REPORT_TEMPLATE =
  'intention/deleteIntentionReportTemplate';
export const deleteIntentionReportTemplate: ActionCreator<
  DeleteIntentionReportTemplatePayload
> = (payload) => ({
  type: DELETE_INTENTION_REPORT_TEMPLATE,
  payload,
});
