/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Dropdown, Space, Tabs, Typography } from 'antd';
import {
  faAngleDown,
  faHistory,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { showModal } from 'redux-saga-modal';
import { PaginationProps } from 'antd/es/pagination';

import { FoundationTypes } from '../../models/foundation';
import { gettextCatalog } from '../../../services/I18nService';
import { getChurches } from '../../../shared/store/resources';
import { navigate } from '../../../services/StateServiceFactory';
import {
  Column,
  OrderDirection,
  TableOrdering,
} from '../../../shared/models/table';
import {
  StyledTabs,
  StyledTable,
  CapitalizedDiv,
} from '../../../shared/antd/StyledAntdComponents';
import {
  getIntentionFilters,
  getIntentions,
  getIntentionsCount,
  getIntentionsOverviewTab,
} from '../../redux/intentions/Selectors';
import { getIntentionFeeRecipients } from '../../../settings/redux/intention-fees/Selectors';
import { isLoading as isLoadingSelector } from '../../../shared/loading/redux/Selectors';
import {
  fetchIntentionsForFoundation,
  clearIntentionsForFoundations,
} from '../../redux/foundations/Actions';
import {
  deleteIntention,
  unassignIntention,
  fetchIntentions,
  restoreIntention,
  setIntentionsOverviewTab,
} from '../../redux/intentions/Actions';
import {
  Intention,
  IntentionPriorityTypes,
  IntentionStatusTypes,
  PaymentMethodTypes,
  IntentionTabNames,
} from '../../models/intention';
import {
  getLegatIntentions,
  getStiftungIntentions,
  getLegatIntentionsCount,
  getStiftungIntentionsCount,
} from '../../redux/foundations/Selectors';
import { fetchIntentionFeeRecipients } from '../../../settings/redux/intention-fees/Actions';
import MODAL_TYPES from '../../sagas/modal-types';

import CopyIntentionModal from './CopyIntentionModal';

import {
  CdCalendarPlusIcon,
  CdCopyIcon,
  CdDeleteIcon,
  CdEditIcon,
  CdEyeIcon,
  EventIcons,
} from '@/react/shared/components/Icons';
import { showConfirmModalDeprecated } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

// Constants
const OFFSET_DEFAULT = 0;
const CURRENT_PAGE_DEFAULT = 1;
const PAGE_SIZE_DEFAULT = 10;

// Antd
const { TabPane } = Tabs;

// Props
interface IntentionsOverviewProps {
  stiftungId?: string;
  legatId?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateParentOnChange?: Function;
}

const navigateToEvent = (intention: Intention) => {
  navigate('app.private.calendar.event', { id: intention.calendarId });
};

const isIntentionDeleted = (intention: Intention) =>
  intention && !_.isNil(intention.deletedAt);

const isIntentionUnassigned = (intention: Intention) =>
  intention && intention.status === IntentionStatusTypes.UNASSIGNED;

const isIntentionAssigned = (intention: Intention) =>
  intention && intention.status === IntentionStatusTypes.ASSIGNED;

const isIntentionCompleted = (intention: Intention) =>
  intention && intention.status === IntentionStatusTypes.COMPLETED;

const isIntentionFromFoundation = (intention: Intention) =>
  intention && intention.parentId;

const hasIntentionEvent = (intention: Intention) =>
  intention && !_.isNil(intention.event);

const IntentionsOverview: FunctionComponent<IntentionsOverviewProps> = ({
  stiftungId,
  legatId,
  updateParentOnChange,
}) => {
  // Redux
  const dispatch = useDispatch();

  // Retrieve required entities from Redux store
  const [queryParams, setQueryParams] = useState<any>();
  const churches = useSelector(getChurches);
  const forwardedIntentionFeeRecipients = useSelector(
    getIntentionFeeRecipients
  );
  const currentTab = useSelector(getIntentionsOverviewTab);
  const legatIntentions = useSelector(getLegatIntentions);
  const legatIntentionsCount: number = useSelector(getLegatIntentionsCount);
  const stiftungIntentions = useSelector(getStiftungIntentions);
  const stiftungIntentionsCount: number = useSelector(
    getStiftungIntentionsCount
  );
  const allIntentions = useSelector(getIntentions);
  const allIntentionsCount: number = useSelector(getIntentionsCount);
  const isLoading: boolean = useSelector(isLoadingSelector);
  const filters = useSelector(getIntentionFilters);
  let intentions: Intention[];
  let intentionsCount: number;

  // Create data source
  if (legatId) {
    intentions = legatIntentions.asMutable({ deep: true });
    intentionsCount = legatIntentionsCount;
  } else if (stiftungId) {
    intentions = stiftungIntentions.asMutable({ deep: true });
    intentionsCount = stiftungIntentionsCount;
  } else {
    intentions = allIntentions.asMutable({ deep: true });
    intentionsCount = allIntentionsCount;
  }

  // When query pagination and ordering are changed, a search is triggered.
  // Whereas when table pagination and ordering are changed, the cosmetics of the table change
  const [queryPagination, setQueryPagination] = useState<PaginationProps>();
  const [tablePagination, setTablePagination] = useState<PaginationProps>({
    current: CURRENT_PAGE_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
    total: 0,
  });
  const [tableOrdering, setTableOrdering] = useState<Partial<TableOrdering>>({
    orderBy: 'referenceNumber',
    orderDirection: OrderDirection.DESC,
  });

  // Retrieve required entities
  useEffect(() => {
    const foundationId = legatId || stiftungId;

    if (updateParentOnChange) updateParentOnChange({ status: currentTab });

    // Initialize intention data
    if (!foundationId) {
      dispatch(clearIntentionsForFoundations());
    }

    // Fetch intention fee recipients who are marked as forwarded
    dispatch(fetchIntentionFeeRecipients({ forwardingRecipientsOnly: true }));
  }, []);

  useEffect(() => {
    if (intentionsCount) {
      // Updating total intentions
      setTablePagination({
        current: _.get(tablePagination, 'current') || CURRENT_PAGE_DEFAULT,
        pageSize: _.get(tablePagination, 'pageSize') || PAGE_SIZE_DEFAULT,
        total: intentionsCount,
      });
    }
  }, [intentionsCount]);

  // Refresh table when pagination updates (Change page or receive total # of intentions)
  useEffect(() => {
    if (queryPagination) {
      setTablePagination(queryPagination);
      refreshOverviewTable(false);
    }
  }, [JSON.stringify(queryPagination)]);

  // Refresh table when sort updates
  useEffect(() => {
    if (tableOrdering) {
      refreshOverviewTable(false);
    }
  }, [JSON.stringify(tableOrdering)]);

  // Update intentions based on filter change
  useEffect(() => {
    // The IntentionsOverviewFilters are set by default which triggers a refresh of the table.
    // Then when the filters are changed in the future they also trigger a refresh
    if (filters) {
      refreshOverviewTable(true);
    }
  }, [JSON.stringify(filters)]);

  // Search table when switching tabs
  useEffect(() => {
    if (currentTab) {
      refreshOverviewTable(true);
    }
  }, [currentTab]);

  const refreshFoundationTab = (
    tab: IntentionTabNames,
    foundationId,
    type: FoundationTypes,
    resetPagination: boolean
  ) => {
    const includeDeleted = tab === IntentionTabNames.ALL;
    const status = includeDeleted ? null : (tab as any);

    // Calculate pagination
    let limit, offset;
    if (resetPagination) {
      // Reset limit, offset and table view pagination
      setTablePagination({
        current: CURRENT_PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT,
        total: intentionsCount || 0,
      });
      offset = OFFSET_DEFAULT;
      limit = PAGE_SIZE_DEFAULT;
    } else {
      const currentPage =
        _.get(queryPagination, 'current') || CURRENT_PAGE_DEFAULT;
      limit = _.get(queryPagination, 'pageSize') || PAGE_SIZE_DEFAULT;
      offset = (currentPage - 1) * limit;
    }

    const params = _.extend(
      {
        id: foundationId,
        type,
        includeDeleted,
        status,
        offset,
        limit,
      },
      tableOrdering
    );

    dispatch(fetchIntentionsForFoundation(params));
  };

  const refreshIntentionTab = (
    tab: IntentionTabNames,
    resetPagination: boolean
  ) => {
    const includeDeleted = tab === IntentionTabNames.ALL;
    const status = includeDeleted ? null : (tab as any);

    // Calculate pagination
    let limit, offset;
    if (resetPagination) {
      // Reset limit, offset and pagination if there is a filter change
      setTablePagination({
        current: CURRENT_PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT,
        total: intentionsCount || 0,
      });
      offset = OFFSET_DEFAULT;
      limit = PAGE_SIZE_DEFAULT;
    } else {
      const currentPage =
        _.get(queryPagination, 'current') || CURRENT_PAGE_DEFAULT;
      limit = _.get(queryPagination, 'pageSize') || PAGE_SIZE_DEFAULT;
      offset = (currentPage - 1) * limit;
    }

    const params = _.extend(
      {
        ...(filters as any),
        offset,
        limit,
        status,
        includeDeleted,
      },
      tableOrdering
    );

    setQueryParams(params);
    dispatch(fetchIntentions(params));
  };

  const refreshOverviewTable = (resetPagination: boolean) => {
    if (currentTab) {
      if (stiftungId) {
        refreshFoundationTab(
          currentTab,
          stiftungId,
          FoundationTypes.STIFTUNGEN,
          resetPagination
        );
      } else if (legatId) {
        refreshFoundationTab(
          currentTab,
          legatId,
          FoundationTypes.LEGATE,
          resetPagination
        );
      } else {
        refreshIntentionTab(currentTab, resetPagination);
      }
    }
  };

  const unassignIntentionFromEvent = (intention: Intention) => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Unassign intention'),
      message: gettextCatalog.getString(
        'Do you want to unassign the intention {{referenceNumber}}?',
        {
          referenceNumber: intention.referenceNumber,
        }
      ),
      onOk() {
        // From specific foundation page
        if (stiftungId) {
          dispatch(
            unassignIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: stiftungId,
              type: FoundationTypes.STIFTUNGEN,
            })
          );
        } else if (legatId) {
          dispatch(
            unassignIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: legatId,
              type: FoundationTypes.LEGATE,
            })
          );
        } else {
          // From intention overview
          dispatch(
            unassignIntention({
              id: intention.id,
              fromTab: currentTab,
              filters: queryParams,
            })
          );
        }
      },
    });
  };

  const revertDeletion = (intention: Intention) => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Restore intention'),
      message: gettextCatalog.getString(
        'Do you want to restore the deleted intention {{referenceNumber}}?',
        {
          referenceNumber: intention.formattedReferenceNumber,
        }
      ),
      onOk() {
        // From specific foundation page
        if (stiftungId) {
          dispatch(
            restoreIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: stiftungId,
              type: FoundationTypes.STIFTUNGEN,
            })
          );
        } else if (legatId) {
          dispatch(
            restoreIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: legatId,
              type: FoundationTypes.LEGATE,
            })
          );
        } else {
          // From intention overview
          dispatch(
            restoreIntention({
              id: intention.id,
              fromTab: currentTab,
              filters: queryParams,
            })
          );
        }
      },
    });
  };

  const removeIntention = (intention: Intention) => {
    showConfirmModalDeprecated({
      title: gettextCatalog.getString('Delete intention'),
      message: gettextCatalog.getString(
        'Do you want to delete the intention {{referenceNumber}}?',
        {
          referenceNumber: intention.formattedReferenceNumber,
        }
      ),
      onOk() {
        // From specific foundation page
        if (stiftungId) {
          dispatch(
            deleteIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: stiftungId,
              type: FoundationTypes.STIFTUNGEN,
            })
          );
        } else if (legatId) {
          dispatch(
            deleteIntention({
              id: intention.id,
              fromTab: currentTab,
              foundationId: legatId,
              type: FoundationTypes.LEGATE,
            })
          );
        } else {
          // From intention overview
          dispatch(
            deleteIntention({
              id: intention.id,
              fromTab: currentTab,
              filters: queryParams,
            })
          );
        }
      },
    });
  };

  const copyIntention = (intention: Intention) => {
    dispatch(showModal(MODAL_TYPES.COPY_INTENTION, { intention }));
  };

  const onTabChange = (tab: IntentionTabNames) => {
    dispatch(setIntentionsOverviewTab(tab as any));
    if (updateParentOnChange) updateParentOnChange({ status: tab });
  };

  const handleTableChange = (newPagination, _filters, sorter) => {
    // Setting pagination if it has changed
    if (!_.isEqual(newPagination, tablePagination)) {
      setQueryPagination(newPagination);
    }

    // Only set field if order is provided
    const newTableOrdering: TableOrdering = {
      // formattedReferenceNumber is not a database column
      orderBy: sorter.field,
      orderDirection:
        sorter.order === 'ascend'
          ? OrderDirection.ASC
          : sorter.order === 'descend'
            ? OrderDirection.DESC
            : null,
    };

    // Setting ordering if it has changed
    if (!_.isEqual(newTableOrdering, tableOrdering)) {
      setTableOrdering(newTableOrdering);
      if (updateParentOnChange) updateParentOnChange(newTableOrdering);
    }
  };

  const navigateToIntention = (type, intention) => {
    if (!isIntentionDeleted(intention)) {
      if (type === 'assign') {
        navigate('app.private.intention.assign', { id: intention.id });
      } else if (type === 'edit') {
        if (stiftungId) {
          // Editing intention from stiftung view
          navigate('app.private.intention.edit', {
            id: intention.id,
            currentState: type,
            stiftungId,
          });
        } else if (legatId) {
          // Editing intention from legat view
          navigate('app.private.intention.edit', {
            id: intention.id,
            currentState: type,
            legatId,
          });
        } else {
          navigate('app.private.intention.edit', {
            id: intention.id,
            currentState: type,
          });
        }
      } else {
        // From intention overview
        navigate('app.private.intention.create', { currentState: type });
      }
    }
  };

  // Column builders

  const getPriorityColumn = (): Partial<Column> => ({
    title: gettextCatalog.getString('Priority'),
    dataIndex: null,
    key: 'priority',
    render: function columnRender(intention: Intention) {
      const priority = intention.priority;
      if (intention.status === IntentionStatusTypes.COMPLETED) {
        return <CapitalizedDiv>{priority}</CapitalizedDiv>;
      } else if (intention.status === IntentionStatusTypes.ASSIGNED) {
        return (
          <div>
            {priority === IntentionPriorityTypes.PERSOLVIERT
              ? gettextCatalog.getString('Primary')
              : gettextCatalog.getString('Forwarded')}
          </div>
        );
      } else {
        return <div></div>;
      }
    },
  });

  const getForwardedRecipientColumn = (): Partial<Column> => ({
    title: gettextCatalog.getString('Forwarding recipient'),
    dataIndex: 'forwardingRecipientId',
    key: 'forwardingRecipientId',
    render: function columnRender(
      forwardingRecipientId: string,
      intention: Intention
    ) {
      const internalForwardingRecipientId = _.get(
        intention,
        'internalForwardingRecipientId'
      );
      if (forwardingRecipientId) {
        const forwardingRecipientName = _.get(
          _.find(forwardedIntentionFeeRecipients, [
            'id',
            forwardingRecipientId,
          ]),
          'name'
        );
        return <div>{forwardingRecipientName}</div>;
      } else if (internalForwardingRecipientId) {
        const internalForwardingRecipient = _.find(churches, [
          'id',
          internalForwardingRecipientId,
        ]);
        let internalForwardingRecipientName = !_.isEmpty(
          internalForwardingRecipient.shortName
        )
          ? internalForwardingRecipient.shortName
          : internalForwardingRecipient.name;
        internalForwardingRecipientName = gettextCatalog.getString(
          'Internal Forwarding {{internalForwardingRecipientName}}',
          {
            internalForwardingRecipientName,
          }
        );
        return <div>{internalForwardingRecipientName}</div>;
      } else {
        return <div></div>;
      }
    },
  });

  const getForwardedDateColumn = (): Partial<Column> => ({
    title: gettextCatalog.getString('Forwarding date'),
    dataIndex: 'forwardedAt',
    key: 'forwardedAt',
    render: function columnRender(forwardedAt: Date) {
      return (
        <div>
          {forwardedAt ? moment(forwardedAt).format('YYYY-MM-DD') : null}
        </div>
      );
    },
  });

  const getEventColumn = (): Partial<Column> => ({
    title: gettextCatalog.getString('Calendar entry'),
    dataIndex: null,
    key: 'event',
    render: function columnRender(intention: Intention) {
      return (
        <div>
          {hasIntentionEvent(intention) ? (
            <a onClick={() => navigateToEvent(intention)}>
              <Space direction="vertical">
                <label>
                  {intention.event.allDay
                    ? gettextCatalog.formatDate(
                        intention.event.startDate,
                        'LL'
                      ) +
                      ' ' +
                      gettextCatalog.getString('(all day)')
                    : gettextCatalog.formatDate(
                        intention.event.startDate,
                        'LLL'
                      )}
                </label>
                <span>{intention.event.title}</span>
              </Space>
            </a>
          ) : null}
        </div>
      );
    },
  });

  const getActionColumn = ({
    width = 120,
  }: {
    width?: number;
  } = {}): Partial<Column> => ({
    title: null,
    dataIndex: null,
    key: 'actionsMenu',
    fixed: 'right',
    width,
    render: function columnRender(intention: Intention) {
      return (
        <Space style={{ display: 'flex', flexDirection: 'row' }}>
          {isIntentionDeleted(intention) ? (
            <Button
              danger
              size="small"
              style={{ marginRight: 8 }}
              onClick={() => revertDeletion(intention)}
            >
              <FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />
              {gettextCatalog.getString('Restore')}
            </Button>
          ) : null}
          {isIntentionDeleted(intention) ? null : (
            <Dropdown
              menu={{ items: getActionsMenu(intention) }}
              trigger={['click']}
            >
              <Button size="small">
                {gettextCatalog.getString('Actions')}{' '}
                <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: 8 }} />
              </Button>
            </Dropdown>
          )}
        </Space>
      );
    },
  });

  // Menus
  const getActionsMenu = (intention: Intention) => {
    const actions = [];

    if (isIntentionUnassigned(intention) && !isIntentionDeleted(intention)) {
      actions.push({
        key: 'assign',
        label: gettextCatalog.getString('Assign'),
        onClick: () => navigateToIntention('assign', intention),
        icon: <CdCalendarPlusIcon />,
      });
    }

    if (isIntentionAssigned(intention) && !isIntentionDeleted(intention)) {
      actions.push({
        key: 'unassign',
        label: gettextCatalog.getString('Unassign'),
        onClick: () => unassignIntentionFromEvent(intention),
        icon: <EventIcons.RemoveIntention />,
      });
    }

    if (!isIntentionDeleted(intention)) {
      actions.push({
        key: 'edit',
        label: isIntentionCompleted(intention)
          ? gettextCatalog.getString('View')
          : gettextCatalog.getString('Edit'),
        onClick: () => navigateToIntention('edit', intention),
        icon: isIntentionCompleted(intention) ? <CdEyeIcon /> : <CdEditIcon />,
      });
    }

    if (!isIntentionFromFoundation(intention)) {
      actions.push({
        key: 'copy',
        label: gettextCatalog.getString('Copy'),
        onClick: () => copyIntention(intention),
        icon: <CdCopyIcon />,
      });
    }

    if (isIntentionUnassigned(intention) && !isIntentionDeleted(intention)) {
      actions.push({
        key: 'delete',
        label: gettextCatalog.getString('Delete'),
        onClick: () => removeIntention(intention),
        icon: <CdDeleteIcon />,
      });
    }

    return actions;
  };

  // Columns

  const sharedColumns: Partial<Column>[] = [
    {
      title: gettextCatalog.getString('Number'),
      dataIndex: null,
      key: 'referenceNumber',
      sorter: true,
      defaultSortOrder: 'descend',
      render: function columnRender(intention: Intention) {
        return (
          <Typography.Link
            onClick={() => navigateToIntention('edit', intention)}
          >
            {intention.formattedReferenceNumber}
          </Typography.Link>
        );
      },
    },
    {
      title: gettextCatalog.getString('Intention text'),
      dataIndex: 'intentionText',
      key: 'intentionText',
      render: function columnRender(intentionText: string, record) {
        return (
          <Typography.Link onClick={() => navigateToIntention('edit', record)}>
            {intentionText}
          </Typography.Link>
        );
      },
    },
    {
      title: gettextCatalog.getString('Intention type'),
      dataIndex: ['fee', 'name'],
      key: 'fee',
    },
    {
      title: gettextCatalog.getString('Parish'),
      dataIndex: 'church',
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: 'resource',
      key: 'resource',
    },
    {
      title: gettextCatalog.getString('Acceptance date'),
      dataIndex: 'acceptedAt',
      key: 'acceptedAt',
      render: function columnRender(acceptedAt: Date) {
        return (
          <div>
            {acceptedAt ? moment(acceptedAt).format('YYYY-MM-DD') : null}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment method'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: function columnRender(paymentMethod: PaymentMethodTypes) {
        return (
          <div>
            {paymentMethod === PaymentMethodTypes.PAIDBYCASH
              ? gettextCatalog.getString('Cash')
              : gettextCatalog.getString('Wire transfer')}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment status'),
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
      render: function columnRender(paid: boolean) {
        return (
          <div>
            {paid ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        );
      },
    },
    {
      title: gettextCatalog.getString('Payment date'),
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: function columnRender(paidAt: Date) {
        return <div>{paidAt ? moment(paidAt).format('YYYY-MM-DD') : null}</div>;
      },
    },
    {
      title: gettextCatalog.getString('Desired date'),
      dataIndex: 'preferredDate',
      key: 'preferredDate',
      sorter: true,
    },
    {
      title: gettextCatalog.getString('Notes for desired date'),
      dataIndex: 'preferredNote',
      key: 'preferredNote',
      sorter: true,
    },
    {
      title: gettextCatalog.getString('Founder'),
      dataIndex: 'founder',
      key: 'founder',
    },
  ];

  const unassignedColumns = _.cloneDeep(sharedColumns);
  unassignedColumns.splice(unassignedColumns.length + 1, 0, getActionColumn());

  const assignedColumns = _.cloneDeep(sharedColumns);
  assignedColumns.splice(7, 0, getPriorityColumn());
  assignedColumns.splice(assignedColumns.length + 1, 0, getEventColumn());
  assignedColumns.splice(assignedColumns.length + 1, 0, getActionColumn());

  const completedColumns = _.cloneDeep(sharedColumns);
  completedColumns.splice(7, 0, getPriorityColumn());
  completedColumns.splice(8, 0, getForwardedRecipientColumn());
  completedColumns.splice(9, 0, getForwardedDateColumn());

  completedColumns.splice(completedColumns.length + 1, 0, getEventColumn());
  completedColumns.splice(completedColumns.length + 1, 0, getActionColumn());

  const allColumns = _.cloneDeep(
    _.filter(
      completedColumns,
      (completedColumn) => completedColumn.key !== 'actionsMenu'
    )
  );
  allColumns.splice(allColumns.length + 1, 0, getActionColumn());

  const statusColumn = {
    title: gettextCatalog.getString('Status'),
    dataIndex: 'status',
    key: 'status',
    render: function columnRender(status: IntentionStatusTypes) {
      switch (status) {
        case IntentionStatusTypes.ASSIGNED:
          return <div>{gettextCatalog.getString('Assigned')}</div>;
        case IntentionStatusTypes.UNASSIGNED:
          return <div>{gettextCatalog.getString('Unassigned')}</div>;
        case IntentionStatusTypes.COMPLETED:
          return <div>{gettextCatalog.getString('Completed')}</div>;
      }
    },
  };
  allColumns.splice(1, 0, statusColumn);

  // Get table config based on state
  let columns;
  switch (currentTab) {
    case IntentionTabNames.UNASSIGNED:
      columns = unassignedColumns;
      break;
    case IntentionTabNames.ASSIGNED:
      columns = assignedColumns;
      break;
    case IntentionTabNames.COMPLETED:
      columns = completedColumns;
      break;
    default:
      // All
      columns = allColumns;
  }

  // Tabs CONFIG

  const intentionTableTabs = [
    {
      name: gettextCatalog.getString('Unassigned'),
      key: IntentionStatusTypes.UNASSIGNED,
    },
    {
      name: gettextCatalog.getString('Assigned'),
      key: IntentionStatusTypes.ASSIGNED,
    },
    {
      name: gettextCatalog.getString('Completed'),
      key: IntentionStatusTypes.COMPLETED,
    },
    {
      name: gettextCatalog.getString('All'),
      key: 'all',
    },
  ];

  // Return / Render

  return (
    <>
      <StyledTabs
        activeKey={currentTab}
        defaultActiveKey={IntentionStatusTypes.UNASSIGNED}
        size="large"
        onChange={onTabChange}
        type="card"
      >
        {intentionTableTabs.map((intentionTableTab) => (
          <TabPane tab={intentionTableTab.name} key={intentionTableTab.key}>
            <StyledTable
              id="intentionsOverviewTable"
              size="small"
              dataSource={intentions}
              columns={columns}
              loading={isLoading}
              scroll={{ x: true }}
              pagination={tablePagination}
              bordered={true}
              rowKey="id"
              rowClassName={(record: Intention) =>
                !_.isNil(record.deletedAt) ? 'errorRow' : null
              }
              onChange={handleTableChange}
            />
          </TabPane>
        ))}
      </StyledTabs>
      <CopyIntentionModal />
    </>
  );
};

IntentionsOverview.displayName = 'IntentionsOverview';

export default IntentionsOverview;
