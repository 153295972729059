import React from 'react';
import './flags.css';

export default function CdCountryFlag({
  countryIso2,
  style,
}: {
  countryIso2: string | null;
  style?: React.CSSProperties;
}) {
  return (
    <span
      style={{
        verticalAlign: 'middle',
        marginBottom: '0.1rem',
        ...style,
      }}
      className={`flag:${countryIso2?.toUpperCase()}`}
    />
  );
}
