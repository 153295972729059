import cdApp from '@/react/config';

/**
 * Address formats by country.
 */
export const getAddressFormat = ({
  address,
  address2,
  zipcode,
  city,
  state,
}: {
  address: string;
  address2: string;
  zipcode: string;
  city: string;
  state: string;
}) => {
  let addressString;
  const _zipcode = zipcode || '';
  const _city = city || '';
  const _state = state || '';
  const _address = address || '';
  const _address2 = address2 || '';

  switch ((cdApp.organization?.countryIso2 || '').toUpperCase()) {
    case 'DE':
    case 'DK':
    case 'SE':
    case 'NO':
      addressString = [`${_address}`, `${_address2}`, `${_zipcode} ${_city}`];
      break;
    case 'US':
      addressString = [
        `${_address}`,
        `${_address2}`,
        `${_city}`,
        `${_state} ${_zipcode}`,
      ];
      break;
    case 'GB':
      addressString = [`${_address}`, `${_address2}`, `${_city} ${_zipcode}`];
      break;
    default:
      addressString = [`${_address}`, `${_address2}`, `${_zipcode} ${_city}`];
      break;
  }
  addressString = addressString
    .filter((item) => item && item.trim())
    .map((item) => item.trim());
  return addressString.join(', ');
};
