import {
  calendarSettingsActions,
  calendarSettingsSelectors,
} from '../../../react/calendar/store/calendar-settings/calendarSettingsSlice';

function CalendarController(
  _,
  moment,
  $scope,
  $rootScope,
  $http,
  $q,
  $uibModal,
  $timeout,
  $stateParams,
  $location,
  $state,
  calendarService,
  gettextCatalog,
  calendarMenuService,
  holidayService,
  Groups,
  toastr,
  Calendar,
  hotkeys,
  Me,
  dateFormatsLookup,
  $transitions,
  CalendarCache,
  calendarPollerService,
  eventPopupService,
  $ngRedux
) {
  let eventPopupRequest;

  var lang = _.get(cdApp, 'organization.locale.language');
  $scope.permissions = _.get(cdApp, 'organization.permissions');

  const mapStateToScope = (state) => ({
    isNewCalendar: calendarSettingsSelectors.isNewCalendar(state),
    isCalendarSwitchAllowed:
      calendarSettingsSelectors.getIsCalendarSwitchAllowed(state) &&
      calendarSettingsSelectors.getShowCalendarSwitch(state),
  });

  const mapDispatchToScope = (dispatch) => ({
    toggleView: () => dispatch(calendarSettingsActions.toggleCalendarView()),
    hideSwitcher: () => dispatch(calendarSettingsActions.hideCalendarSwitch()),
  });

  let unsubscribe = $ngRedux.connect(
    mapStateToScope,
    mapDispatchToScope
  )($scope);

  $scope.$on('$destroy', unsubscribe);
  // Fetch all groups.
  $scope.groups = Groups.query();

  // Fetch action menus on the top.
  calendarMenuService.getMenu().then(function (data) {
    $scope.actionMenu = data;
  });

  // Fullcalendar DOM.
  var fullcalendar = $('#fullCalendar');
  var splashElement = $('.splashContent').get(0);
  $scope.filters = {};

  $scope.getDestination = function () {
    return encodeURIComponent(
      ($scope.group_id ? '/group/' + $scope.group_id : '') + '/calendar'
    );
  };

  _.each(_.get(cdApp, 'data.calendar.filters'), function (list, category) {
    var addLink = '/home',
      order = 0,
      title = '';
    switch (category) {
      case 'users':
        addLink = $state.href('app.private.settins.users.list');
        order = 2;
        title = gettextCatalog.getString('Users');
        break;
      case 'resources':
        addLink = $state.href('app.private.settings.resources.create', {
          type: 'resource',
        });

        order = 3;
        title = gettextCatalog.getString('Resources');
        break;
      case 'categories':
        addLink = $state.href('app.private.settings.calendar.create', {
          type: 'event',
        });

        order = 1;
        title = gettextCatalog.getString('Categories');
        break;
      case 'absences':
        order = 4;
        addLink = $state.href('app.private.settings.calendar.create', {
          type: 'absence',
        });

        title = gettextCatalog.getString('Absences');
        break;
    }

    // omit the category 'groups' from the calendar filters
    if (category === 'groups') return;

    $scope.filters[category] = {
      data: list,
      add_link: addLink,
      order: order,
      title: title,
    };
  });

  // Function which returns bool depends on if all filters is checked or not.
  function areAllFiltersChecked() {
    var status = true;
    _.forEach($scope.filters, function (data) {
      if (data.checked === false) {
        status = false;
      }
    });
    return status;
  }

  function areAllFiltersUnChecked() {
    var status = true;
    _.forEach($scope.filters, function (data) {
      if (data.unchecked === false) {
        status = false;
      }
    });
    return status;
  }

  $scope.$watch(
    'filters',
    function (newVal, oldVal) {
      _.each(_.get(cdApp, 'data.calendar.filters'), function (list, category) {
        // omit the category 'groups' from the calendar filters
        if (category === 'groups') return;

        $scope.filters[category].selected = _.filter(
          $scope.filters[category].data,
          {
            checked: true,
          }
        ).length;
        $scope.filters[category].all = _.filter(
          $scope.filters[category].data
        ).length;

        $scope.filters[category].checked = _.every(
          $scope.filters[category].data,
          _.matches({
            checked: true,
          })
        );

        $scope.filters[category].unchecked = _.every(
          $scope.filters[category].data,
          _.matches({
            checked: false,
          })
        );
      });

      $scope.allFiltersAreChecked = areAllFiltersChecked();
      $scope.allFiltersAreUnchecked = areAllFiltersUnChecked();
    },
    true
  );

  $scope.redirectTo = function (url) {
    $location.path(url.replace('/app/#', ''));
  };

  var i18n = {
    columnFormat: {
      da: {
        week: 'ddd D.M',
      },

      de: {
        week: 'dd, D.M.',
      },

      'en-gb': {
        week: 'ddd D/M',
      },

      sv: {
        week: 'ddd, D.M',
      },
    },
  };

  function splashScreen(show) {
    if (show) {
      splashElement.style.top = fullcalendar.position().top + 'px';
    } else {
      $scope.setHeight();
    }

    $scope.loading = show;
    splashElement.style.display = show ? 'block' : 'none';
  }

  splashScreen(true);
  $scope.firstLoad = true;
  $scope.calendar = {
    isEmpty: true,
  };

  // Datepicker options.
  $scope.datepickerOptions = {
    'starting-day': 1,
  };

  $scope.group_id = null;

  // Check if the calendar is group calendar.
  if ($stateParams.hasOwnProperty('gid')) {
    $scope.group_id = $stateParams.gid;
  }

  // Check show event value.
  $scope.showEvent = false;
  if ($stateParams.showEvent && $stateParams.eventDate) {
    $scope.showEvent = {
      nid: $stateParams.showEvent,
      date: moment($stateParams.eventDate),
    };

    // remove url parameters
    $location.search({});
  }

  // Redirect to the specific path.
  $scope.goTo = function (path) {
    $location.path(path);
  };

  $scope.hideParent = function ($event) {
    $($event.target).parent().fadeOut();
  };

  // Calendar view to the scope with translation.
  $scope.calendarViews = _.map(
    _.get(cdApp, 'data.calendar.views').split(','),
    function (data) {
      var title = data;
      switch (data) {
        case 'agendaDay':
          title = gettextCatalog.getString('Day');
          break;
        case 'agendaWeek':
          title = gettextCatalog.getString('Week');
          break;
        case 'month':
          title = gettextCatalog.getString('Month');
          break;
        case 'resources':
          title = gettextCatalog.getString('Resources');
          break;
        case 'users':
          title = gettextCatalog.getString('Users');
          break;
      }

      return {
        name: data,
        title: title,
      };
    }
  );

  function updateFixedScrollers(option) {
    var individualSize = 150;
    var width = Math.max(
      fullcalendar.width(),
      individualSize * $('.fc-day-header').length
    );

    $('.fc-view').width(width);
    $('.fc-scroller')
      .perfectScrollbar(option)
      .scroll(function () {
        $('.qtip').qtip('reposition');
      });

    $('.fc-view-container')
      .perfectScrollbar(option)
      .scroll(function () {
        // for the left column.
        var scrollLeft = $(this).scrollLeft() - 1;
        $(this).find('.fc-slats .fc-axis').css('left', scrollLeft);

        // for the vertical scrollbar.
        var scrollbar = $(this).find(
          '.fc-time-grid-container .ps-scrollbar-y-rail'
        );

        scrollLeft = Math.min(
          $(this).scrollLeft() + $(this).width(),
          $(this).children('.fc-view').width()
        );

        scrollbar.css('left', scrollLeft - (scrollbar.outerWidth() + 2));
        $('.qtip').qtip('reposition');
      })
      .triggerHandler('scroll');
  }

  $scope.setHeight = function () {
    if ($scope.$$destroyed) {
      return;
    }

    var gutter = 24;
    var viewName = fullcalendar.fullCalendar('getView').name;
    var calendarTop = fullcalendar.offset().top + gutter;
    var viewport =
      window.innerHeight - calendarTop - $('.calendarInfo').outerHeight(true);

    if (viewName === 'month') {
      return fullcalendar.fullCalendar('option', 'contentHeight', viewport);
    }

    var container = fullcalendar.find('.fc-time-grid-container');
    var target = fullcalendar.find('.fc-slats');

    if (container.length && target.length) {
      var needed = container.offset().top - calendarTop + target.outerHeight();
      fullcalendar.fullCalendar(
        'option',
        'contentHeight',
        Math.min(needed, viewport)
      );
    }

    if (viewName === 'resourceDay') {
      updateFixedScrollers();
    } else {
      $('.fc-scroller').scroll(function () {
        $('.qtip').qtip('reposition');
      });
    }
  };

  $scope.$on('invalidation', function () {
    $timeout($scope.setHeight);
  });

  $scope.calendarChangeView = function (newName) {
    var force;
    var oldName = fullcalendar.fullCalendar('getView').name;
    if (newName === 'resources' || newName === 'users') {
      var changing = _.get(cdApp, 'data.calendar.settings.asset') !== newName;
      _.set(cdApp, 'data.calendar.settings.asset', newName);
      newName = 'resourceDay';
      // If moving from one asset category to another, fullcalendar will think we
      // are still on the same view and will not re-render.
      force = changing && oldName === newName;
    }

    if (force || oldName !== newName) {
      splashScreen(true);

      $('.fc-scroller, .fc-view-container').perfectScrollbar('destroy');
      $timeout(function () {
        fullcalendar.fullCalendar('changeView', newName);

        if (force) {
          fullcalendar.fullCalendar('render', true);
        }
      });
    }
  };

  // Manage fullcalendar head line classes.
  $scope.getViewClass = function (viewName) {
    var cls = ['btn', 'btn-default', viewName];

    if (viewName === _.get(cdApp, 'data.calendar.settings.asset')) {
      viewName = 'resourceDay';
    }

    if (
      fullcalendar.fullCalendar &&
      viewName === fullcalendar.fullCalendar('getView').name
    ) {
      cls.push('active');
    } else {
      _.pull(cls, 'active');
    }

    return cls.join(' ');
  };

  $scope.refresh = _.throttle(function () {
    if ($scope.loading) {
      return $timeout($scope.refresh, 1000);
    }

    var view = fullcalendar.fullCalendar('getView');
    if (!view) return;

    $timeout(function () {
      splashScreen(true);
    });

    $timeout(function () {
      if (view.name === 'resourceDay') {
        fullcalendar.fullCalendar('render', true);
      } else {
        fullcalendar.fullCalendar('rerenderEvents');
      }

      splashScreen(false);
    }, 100);
  }, 2000);

  $scope.toggle = function (filter, type, id) {
    if (filter) {
      filter.checked = !filter.checked;
      $scope.updateCalendarSettings('filters', type, [
        {
          id: id,
          value: filter.checked,
        },
      ]);

      var calendar = fullcalendar.fullCalendar('getView');
      if (_.includes(['absences', 'users'], type)) {
        $scope.$broadcast('filterUpdate', {
          start: calendar.start,
          end: calendar.end,
          view: calendar.name,
          force: false,
        });
      }
    }
    $scope.refresh();
  };

  // Top menu activity.
  $scope.menuCallback = function (callback, option, event) {
    switch (callback) {
      case 'export':
        $scope.exportCalendarModal();
        break;
      case 'topFilter':
        event.stopPropagation();
        $scope.changeTopFilter(option, event);
        break;
      case 'switchCalendar':
        $scope.toggleView();
        break;
    }

    return false;
  };

  // Setup of the filter accordion
  // timeout is used to ensure that the content is already loaded when we start the accordion.
  $timeout(function () {
    $('#calendar-filters .toggle').click(function (e) {
      // checks if we haven't hit any other element inside the main element
      var mainElement = e.currentTarget.contains(e.target);
      if (mainElement) {
        $('#calendar-filters').toggleClass('active');
      }

      return !mainElement;
    });

    $('.CalendarController').click(function (e) {
      if (!$(e.target).parents('#calendar-filters').length) {
        $('#calendar-filters').removeClass('active');
      }
    });
  });

  $scope.manageResourceHtml = function () {
    var contains = _.includes(
      _.get(cdApp, 'data.calendar.settings.render'),
      'resource'
    );

    $('ul.resourceView').toggleClass('hide', !contains);
  };

  $scope.manageInformationIcons = function () {
    var contains = _.includes(
      _.get(cdApp, 'data.calendar.settings.render'),
      'informationIcons'
    );

    $('div.event_icons').toggleClass('hide', !contains);
  };

  /**
   * Generate the url of filter element edit page.
   * @param filter - the name of the filter
   * @param id - id of the filter
   * @returns {string} - url of filter edit page
   */
  $scope.filterEditUrl = function (filter, id) {
    var url = '';
    switch (filter) {
      case 'users':
        url = $state.href('app.private.settings.users.edit', { id: id });
        break;
      case 'resources':
        url = $state.href('app.private.settings.resources.edit', {
          type: 'resource',
          id: id,
        });

        break;
      case 'absences':
        url = $state.href('app.private.settings.calendar.edit', {
          type: 'absence',
          id: id,
        });

        break;
      case 'categories':
        url = $state.href('app.private.settings.calendar.edit', {
          type: 'event',
          id: id,
        });

        break;
    }

    return url;
  };

  $scope.selectGroup = function (groups, value) {
    if (!_.isArray(groups)) {
      groups = [groups];
    }

    _.each(groups, function (group) {
      var changed = [];

      _.each($scope.filters[group].data, function (filter, id) {
        if (filter.checked === value) {
          return;
        }

        filter.checked = value;
        changed.push({
          id: id,
          value: value,
        });
      });

      if (changed.length) {
        $scope.updateCalendarSettings('filters', group, changed);
      }
    });

    $scope.toggle();
  };

  $scope.selectAllGroups = function (value) {
    $scope.selectGroup(_.keys($scope.filters), value);
  };

  $scope.qtip = {};

  // Update event information then you resize the event.
  $scope.updateEvent = function (event, delta, revertFunc, allowDoubleBooking) {
    // Use node update service to update the event. This allow us to fire the hooks.
    var start = event.start.clone();
    var end = (event.end || event.start).clone();
    if (!event.end && event._allDay) {
      end = end.add(1, 'day');
    } else {
      end = end.add(!event.end ? 30 : 0, 'minutes');
    }

    // For all day events we are adding additional day to render correctly.
    // Then we are updating date by drag&drop we need to remove the
    if (event._allDay) {
      end = end.clone().subtract('1', 'day');
    }

    if (event._allDay) {
      start = moment(start.startOf('day').format('YYYY-MM-DD HH:mm:ss'));
      end = moment(end.endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    }

    var payload = {
      id: event.eid,
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      allDay: event._allDay,
      type: event.type,
      visibility: event.visibility,
      title: event.title,
      churchIds: _.map(event.churches, 'id'),
    };

    if (allowDoubleBooking) {
      payload.allowDoubleBooking = true;
    }

    if (delta.view === 'resourceDay') {
      payload.users = _(event.filters.users).keys().map(_.parseInt).value();
      payload.resources = _(event.filters.resources)
        .keys()
        .map(_.parseInt)
        .value();
    }

    if (event.type === 'absence') {
      payload.groupIds = _(event.filters.groups).keys().map(_.parseInt).value();
    }

    return $http
      .put(cdApp.config.api.main + '/calendar/' + event.eid, payload)
      .then(
        function () {
          // Clean the cached qtip for that event.
          delete $scope.qtip[event.eid];
          fullcalendar.fullCalendar('updateEvent', event);
          var view = fullcalendar.fullCalendar('getView');

          // Remove the cache for the data for the calendar for specific dates.
          calendarCache.removeCache(view.start, view.end);
          toastr.success(
            gettextCatalog.getString('The calendar item has been updated.')
          );

          return event;
        },
        function (response) {
          if (response.status === 409) {
            toastr.info(
              gettextCatalog.getString(
                'We found one or more conflicts with your bookings.'
              )
            );

            $scope.doubleBooking(response.data, event, delta, revertFunc);
          } else {
            toastr.error(
              response.data.message ||
                gettextCatalog.getString(
                  'An error occurred, please try again. If the problem persists, please contact our support.'
                )
            );

            revertFunc();
          }
        }
      );
  };

  /**
   * Save calendar settings to user object in database.
   * @param setting - (filters, calendar etc.)
   * @param key - (the setting key)
   * @param data - (array of values)
   */
  $scope.updateCalendarSettings = function (setting, key, data) {
    // Check that the setting value exists in the user data object.
    cdApp.data.calendar[setting] = cdApp.data.calendar[setting] || {};
    // Check if we are working with filters.
    if (setting === 'filters') {
      // Loop through all the data values.
      _.forEach(data, function (val) {
        // The filter is missing, probably it is the first load of the calendar for the new user.
        // Copy the filter to the user filter.
        cdApp.data.calendar.filters[key] =
          cdApp.data.calendar.filters[key] || {};

        cdApp.data.calendar.filters[key][val.id] = cdApp.data.calendar.filters[
          key
        ][val.id] || {
          checked: $scope.filters[key].data[val.id].checked,
        };

        cdApp.data.calendar.filters[key][val.id].checked = val.value;
      });
    } else {
      if (key === 'render') {
        cdApp.data.calendar[setting][key] = _.isArray(
          cdApp.data.calendar[setting][key]
        )
          ? cdApp.data.calendar[setting][key]
          : [];
        // Check if element exist then remove
        var idx = _.indexOf(cdApp.data.calendar[setting][key], data.value);
        if (idx !== -1) {
          // remove from array
          cdApp.data.calendar[setting][key].splice(idx, 1);
        } else {
          // add to array.
          cdApp.data.calendar[setting][key].push(data.value);
        }
        if (data.value === 'resource') {
          $scope.manageResourceHtml();
        } else if (data.value === 'informationIcons') {
          $scope.manageInformationIcons();
        }
        // Otherwise add to the array
      } else {
        _.set(cdApp, ['data', 'calendar', setting, key], data.value);
      }
    }

    if (!$scope.firstLoad) {
      calendarService.save(cdApp.data.calendar, setting);
    }
  };

  function prepareAsset(object, category) {
    return _.map(object, function (resource, id) {
      return {
        id: id,
        category: category,
        name: resource.name,
        className: 'color-' + resource.color || '0',
        checked: function () {
          return resource.checked;
        },
      };
    });
  }

  $scope.calendarNavigate = function (nav) {
    // fullcalendar.fullCalendar(nav);
    var view = fullcalendar.fullCalendar('getView');
    var time = fullcalendar.fullCalendar('getDate');
    switch (nav) {
      case 'nextYear':
        time = moment(time).add(1, 'y');
        break;
      case 'prevYear':
        time = moment(time).subtract(1, 'y');
        break;
      case 'today':
        time = moment();
        break;
      case 'next':
        if (view.name === 'agendaDay' || view.name === 'resourceDay') {
          time = moment(time).add(1, 'd');
        } else if (view.name === 'agendaWeek') {
          time = moment(time).add(1, 'w');
        } else if (view.name === 'month') {
          time = moment(time).add(1, 'M');
        }
        break;
      case 'prev':
        if (view.name === 'agendaDay' || view.name === 'resourceDay') {
          time = moment(time).subtract(1, 'd');
        } else if (view.name === 'agendaWeek') {
          time = moment(time).subtract(1, 'w');
        } else if (view.name === 'month') {
          time = moment(time).subtract(1, 'M');
        }
        break;
    }

    $scope.datepicker = time._d;
    $scope.datepickerPlaceholder = time.format('L');
    $scope.fullcalendar_date_change();
  };

  /* This function will generate necessary html information for the resources */
  $scope.generateResourcesHtml = function (resources) {
    if (!resources || resources.length === 0) {
      return '';
    }
    var found = false;
    var classes = 'resourceView';
    if (
      !_.includes(_.get(cdApp, 'data.calendar.settings.render'), 'resource')
    ) {
      classes += ' hide';
    }
    var html = '<ul class="' + classes + '">';
    var width = 100 / parseFloat(resources.length);
    angular.forEach(resources, function (id) {
      if ($scope.filters.resources.data[id] !== undefined) {
        found = true;
        html +=
          '<li class="color-' +
          ($scope.filters.resources.data[id].color || 0) +
          '" style="width:' +
          width +
          '%;">' +
          $scope.filters.resources.data[id].name +
          '</li>';
      }
    });
    html += '</ul>';
    if (found === false) {
      return '';
    }
    return html;
  };

  // Generate the icons for the event.
  $scope.generateIcons = function (icons) {
    if (!icons || icons.length === 0) {
      return '';
    }
    var icons_html = '';
    var classes = 'event_icons';
    if (
      !_.includes(
        _.get(cdApp, 'data.calendar.settings.render'),
        'informationIcons'
      )
    ) {
      classes += ' hide';
    }

    angular.forEach(icons, function (icon) {
      icons_html += '<i class="' + icon + '"></i>';
    });

    if (icons_html.length > 1) {
      return '<div class="' + classes + '">' + icons_html + '</div>';
    } else {
      return '';
    }
  };

  var calendarCache = new CalendarCache();
  calendarCache.setGroup($scope.group_id);

  // Default view.
  var defaultView = _.get(cdApp, 'data.calendar.settings.view') || 'agendaWeek';
  if (typeof defaultView !== 'string') {
    defaultView = 'agendaWeek';
  }
  defaultView = $scope.showEvent.view || defaultView;

  // Default date.
  var defaultDate = _.get(cdApp, 'data.calendar.settings.date') || moment();
  defaultDate = $scope.showEvent.date || defaultDate;

  function initFullcalendar() {
    // Initialize the fullCalendar.
    fullcalendar.fullCalendar({
      // Configurations.
      header: {
        left: '',
        center: '',
        right: '',
      },

      allDayText: '',
      weekNumbers: true,
      firstDay: 1,
      slotEventOverlap: false,
      weekMode: 'variable',
      minTime: '06:00:00',
      editable: true,
      timezone: 'local',
      lazyFetching: false,
      eventLimitText: gettextCatalog.getString('More'),
      weekNumberTitle: gettextCatalog.getString('W'),
      eventLimit: {
        agenda: 10,
        default: 10,
      },

      eventLimitClick: function () {
        $timeout(function () {
          // we need this deferred function here as we wanna resize the popover element that is still gonna be created.
          $('.fc-popover').each(function () {
            var reference = $(this).parent();

            if (fullcalendar.fullCalendar('getView').name !== 'month') {
              reference = reference.parent();
            }

            var spacing = reference.height();
            var height = $(this).height();

            if ($(this).position().top + height - spacing > 0) {
              $(this).css('top', Math.max(spacing - height, 0));
            }

            $(this).css('max-height', spacing - $(this).position().top);
          });
        }, 10);
        return 'popover';
      },
      columnFormat: _.has(i18n.columnFormat, lang)
        ? i18n.columnFormat[lang]
        : {},
      lang: lang,
      timeFormat: 'HH:mm',
      defaultTimedEventDuration: '00:30:00',
      defaultView: defaultView,
      resources: _.union(
        prepareAsset($scope.filters.resources.data || [], 'resources'),
        prepareAsset($scope.filters.users.data || [], 'users')
      ),

      resourceFilter: function (resource) {
        var filter = $scope.filters[resource.category].data[resource.id];
        return (
          _.get(cdApp, 'data.calendar.settings.asset') === resource.category &&
          filter &&
          filter.checked
        );
      },
      resourceSort: function SortByName(a, b) {
        var aName = a.name.toLowerCase();
        var bName = b.name.toLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      },
      hasResource: function (event, resource) {
        if (event.resources && event.resources.length) {
          // only not persisted events
          return $.grep(event.resources, function (id) {
            return id === resource.id;
          }).length;
        }
        // only persisted events
        return (
          event.filters &&
          event.filters[resource.category] &&
          event.filters[resource.category].hasOwnProperty(resource.id)
        );
      },
      selectable: true,
      selectHelper: true,
      defaultDate: defaultDate,
      unselectAuto: false,
      events: calendarCache.getEvents,
      annotations: holidayService.data,
      loading: function (isLoading) {
        if (isLoading) {
          splashScreen(true);
        } else {
          $scope.firstLoad = false;
          splashScreen(false);
          if ($scope.showEvent) {
            $('.fc-event-container .nid-' + $scope.showEvent.nid).trigger(
              'click'
            );

            $scope.showEvent = false;
          }
        }
      },
      eventAfterAllRender: function (view) {
        $scope.$broadcast('timeUpdate', {
          start: view.start,
          end: view.end,
          view: view.name,
        });

        $scope.manageResourceHtml();
      },
      viewRender: function (view, element) {
        // Save option to the user object.
        $scope.updateCalendarSettings('settings', 'view', {
          value: view.name,
        });

        // Save current date.
        $scope.updateCalendarSettings('settings', 'date', {
          value: view.calendar.getDate().format(),
        });

        $scope.calendarTitle = view.title;
        $scope.datepickerPlaceholder = view.start.format('L');

        if (view.name === 'resourceDay') {
          var background = element.children('div');
          var content = element.children('table');
          background.width(content.width());
        }

        if (!view.renderEvents.modified) {
          // overrides renderEvents to filter the elements we wanna show
          var backReference = view.renderEvents;

          view.renderEvents = function (events) {
            $scope.calendar.isEmpty = !events.length;

            // returns true if the event is shared with a group that the current user is a part of
            function intersectionOfUserGroupsAndEventGroups(event) {
              var eventGroups = _.map(
                _.keys(_.get(event, 'filters.groups')),
                _.parseInt
              );

              var userGroups = _.get(cdApp, 'organization.myGroups');
              return _.intersection(eventGroups, userGroups).length;
            }

            // checks the calendar settings to see if a field exists and performs an AND with some other conditions
            function calendarSettingsHasField(field) {
              return _.includes(
                _.get(cdApp, 'data.calendar.settings.render'),
                field
              );
            }

            // Filters out by event's type
            events = _.filter(events, function (event) {
              var isAbsence = event.type === 'absence';
              var isPublicEvent =
                event.type === 'event' && event.visibility === 'public';
              var isInternalEvent =
                event.type === 'event' && event.visibility === 'internal';
              var isPrivateEvent =
                event.type === 'event' && event.visibility === 'private';
              var isPublicOrInternalEvent = isPublicEvent || isInternalEvent;
              var isByUser = event.authorId === Me.id;
              var hasNoGroups = _.isEmpty(_.get(event, 'filters.groups'));
              var hasGroups = !hasNoGroups;
              var hasUserGroups =
                intersectionOfUserGroupsAndEventGroups(event) > 0;
              var hasNoUserGroups =
                intersectionOfUserGroupsAndEventGroups(event) === 0;

              // hide my group events (if an event's visibility is (internal or public) and it's shared with a group the user is a member of)
              var isPublicOrInternalAndSharedWithUserGroups =
                isPublicOrInternalEvent && hasGroups && hasUserGroups;
              if (
                !calendarSettingsHasField('mygroup-events') &&
                isPublicOrInternalAndSharedWithUserGroups
              ) {
                return false;
              }

              // hide other calendar events (if an event's visibility is public, or internal and it's not shared with any group) or (event is private and does not belongs to user)
              var publicOrInternalAndSharedWithNoUserGroup =
                isPublicOrInternalEvent &&
                (hasNoGroups || (hasGroups && hasNoUserGroups));
              var privateAndNotByUser = isPrivateEvent && !isByUser;
              if (
                !calendarSettingsHasField('othercalendar-events') &&
                (publicOrInternalAndSharedWithNoUserGroup ||
                  privateAndNotByUser)
              ) {
                return false;
              }

              // hide mygroup absences
              if (
                !calendarSettingsHasField('mygroup-absence') &&
                isAbsence &&
                hasUserGroups
              )
                return false;

              // hide othercalendar absences
              if (
                !calendarSettingsHasField('othercalendar-absence') &&
                isAbsence &&
                !hasUserGroups
              )
                return false;

              // hide private events
              if (
                !calendarSettingsHasField('mycalendar-private') &&
                isPrivateEvent &&
                isByUser
              ) {
                return false;
              }

              return true;
            });

            // Filters the events looking for at least one active filter
            events = _.filter(events, function (eventObj) {
              return _.some(
                _.omit(eventObj.filters, 'groups'),
                function (filters, category) {
                  return _.some(filters, function (value, id) {
                    var resource;

                    // Booked users and shift users are both filtered with the `users` filter
                    if (category === 'users' || category === 'usersOnShifts') {
                      resource = _.get($scope.filters, ['users', 'data', id]);
                    } else {
                      resource = _.get($scope.filters, [category, 'data', id]);
                    }

                    return resource ? resource.checked : false;
                  });
                }
              );
            });
            $scope.calendar.isFullyFiltered =
              !$scope.calendar.isEmpty && !events.length;

            return backReference.call(view, events);
          };

          view.renderEvents.modified = true;
        }

        // Update the poller with the current view's date range and rendered events
        calendarPollerService.update({
          start: view.start,
          end: view.end,
          calendarCache,
        });
      },
      filters: $scope.filters,
      eventRender(event, element) {
        if (event.repeating) {
          event.editable = false;
        }

        if (element.hasClass('fc-helper')) {
          return true;
        }

        if (event.type === 'event') {
          const userReply = _.get(event, ['filters', 'users', cdApp.me.id]);
          if (userReply) {
            element.context.classList.add(
              _.get(
                {
                  yes: 'fc-event-reply-yes',
                  no: 'fc-event-reply-no',
                  maybe: 'fc-event-reply-maybe',
                  'no-answer': 'fc-event-reply-no-answer',
                },

                userReply
              )
            );
          }
        }

        const eventContent = element.find('.fc-content');

        // Adding the absence icon
        if (event.type === 'absence') {
          eventContent
            .find('.fc-title')
            .prepend('<i class="fa fa-calendar-times"></i>&nbsp');
        }

        eventContent.append(
          $scope.generateResourcesHtml(_.keys(event.filters.resources))
        );

        eventContent.append($scope.generateIcons(event.icons));

        return true;
      },
      // Double click on the month view redirect to day view.
      dayClick: function (date, jsEvent, view) {
        if (view.name === 'month' && jsEvent.type === 'dblclick') {
          fullcalendar.fullCalendar('gotoDate', date);
          fullcalendar.fullCalendar('changeView', 'agendaDay');
        }
      },
      handleWindowResize: true,
      windowResize: function () {
        $scope.setHeight();
      },

      /**
       * Create an event on select
       */
      select(start, end, jsEvent, view, resources) {
        const target = jsEvent.target;
        const asset = _.get(cdApp, 'data.calendar.settings.asset');

        const data = {
          type: 'event',
          allDay: !(start.hasTime() && end.hasTime()),
          showAbsence: !(view.name === 'resourceDay' && asset === 'resources'),
        };

        if (data.allDay) {
          data.startDate = new Date(
            moment(start).startOf('day').format('YYYY/MM/DD HH:mm:ss')
          ).toISOString();
          data.endDate = new Date(
            moment(end.clone())
              .subtract(1, 'days')
              .endOf('day')
              .format('YYYY/MM/DD HH:mm:ss')
          ).toISOString();
        } else {
          data.startDate = moment(start).toISOString();
          data.endDate = moment(end).toISOString();
        }

        if (asset) {
          data[asset] = _.map(resources, _.parseInt);
        }

        eventPopupService.createEvent(data, target, {
          onHide() {
            fullcalendar.fullCalendar('unselect');
          },
          onCreate(newEvent) {
            const fullCalendar = $('#fullCalendar');
            const calendar = fullCalendar.fullCalendar('getView');
            newEvent.category = newEvent.taxonomies[newEvent.mainCategory]
              ? newEvent.taxonomies[newEvent.mainCategory]
              : newEvent.category;
            newEvent.category.id = newEvent.mainCategory;
            const fcEvent = {
              eid: newEvent.id,
              title: newEvent.title,
              start: newEvent.startDate,
              end: newEvent.allDay
                ? moment(newEvent.endDate).add(1, 'second')
                : newEvent.endDate,
              allDay: newEvent.allDay,
              type: newEvent.type,
              className: `color-${newEvent.category.color}`,
            };

            if (newEvent.type === 'event') {
              _.extend(fcEvent, {
                visibility: newEvent.visibility,
                filters: {
                  categories: {},
                },
              });

              fcEvent.filters.categories[newEvent.category.id] = '';

              // Attach the booked resources.
              if (newEvent.resources) {
                fcEvent.filters.resources = _.mapKeys(newEvent.resources);
              }
              // Attach booked users.
              if (newEvent.users) {
                fcEvent.filters.users = {};
                fcEvent.filters.users[_.first(newEvent.users)] = '';
              }
              // Set event group
              if (newEvent.groupIds) {
                fcEvent.filters.groups = {};
                _.set(
                  fcEvent.filters.groups,
                  newEvent.groupIds,
                  _.result(
                    _.find($scope.groups, { id: newEvent.groupIds }),
                    'name'
                  )
                );
              }

              // Broadcast the message to other services to update the data.
              $rootScope.$broadcast('filterUpdate', {
                start: calendar.start,
                end: calendar.end,
                view: calendar.name,
                filters: $scope.filters,
                force: false,
              });

              $rootScope.$broadcast('addEvent', { event: newEvent });
              toastr.success(
                gettextCatalog.getString('A new event has been created.')
              );
            } else {
              _.extend(fcEvent, {
                icons: ['fa fa-group'],
                filters: {
                  absences: {},
                  users: {},
                },
              });

              fcEvent.filters.absences[newEvent.category.id] = '';
              fcEvent.filters.users[newEvent.users] = 'no-answer';

              if (newEvent.groupId) {
                fcEvent.filters.groups = {};
                _.set(
                  fcEvent.filters.groups,
                  newEvent.groupId,
                  _.result(
                    _.find($scope.groups, { id: newEvent.groupId }),
                    'name'
                  )
                );
              }

              $rootScope.$broadcast('absenceCreate', {
                start: calendar.start,
                end: calendar.end,
                view: calendar.name,
                filters: $scope.filters,
                force: true,
              });

              $rootScope.$broadcast('addEvent');
              toastr.success(
                gettextCatalog.getString('A new absence has been created.')
              );
            }

            // Add event to full calendar.
            fullCalendar.fullCalendar('renderEvent', fcEvent);
          },
        });
      },

      /**
       * Resize the event
       */
      eventResize(event, delta, revertFunc, jsEvent, ui, view) {
        delta.action = 'resize';
        delta.view = view.name;
        $scope.confirmModal($scope.updateEvent, event, delta, revertFunc);
      },

      /**
       * Drop the event
       */
      eventDrop(event, delta, revertFunc, jsEvent, ui, view) {
        const asset = _.get(cdApp, 'data.calendar.settings.asset');
        const backup = event.filters[asset];

        delta.action = 'drop';
        delta.view = view.name;
        if (view.name === 'resourceDay') {
          event.filters[asset] = _.zipObject(event.resources, []);
        }

        $scope.confirmModal($scope.updateEvent, event, delta, () => {
          event.filters[asset] = backup;
          event.resources = _.keys(backup);
          revertFunc.apply(this, arguments);
        });
      },

      /**
       * Click on a calendar event
       */
      eventClick(event, jsEvent) {
        const target = jsEvent.currentTarget || jsEvent.target;
        if ($(jsEvent.currentTarget).hasClass('fc-helper')) return;

        // Create an element that pulsates to indicate that the event is being loaded
        const pulser = angular.element(`<div class="fc-event-pulser"></div>`);
        $(target).append(pulser);

        const eventId = event.eid;
        const doubleClickInterval = 500;
        const lastClickTime = $.data(event, 'lastClick');

        // Redirect to event when double clicking on it
        if (
          lastClickTime &&
          new Date().getTime() - $.data(event, 'lastClick') <
            doubleClickInterval
        ) {
          pulser.remove();
          eventPopupRequest.$cancelRequest();
          $state.go(`app.private.calendar.${event.type}`, { id: eventId });
        } else {
          // Store the request in a variable, so that we can cancel it when the double click occurs
          eventPopupRequest = Calendar.get({ id: eventId });

          eventPopupRequest.$promise.then(() => {
            pulser.remove();

            eventPopupService.viewEvent(eventPopupRequest, target, null, {
              fcEventId: event._id,
              openOnClick: true,
              onDelete(eventObject, fcEventId) {
                const isRepeated = !_.isNull(eventObject.rrule);

                fullcalendar.fullCalendar('removeEvents', fcEventId);
                const view = fullcalendar.fullCalendar('getView');

                // Remove the cache for the data for the calendar for specific dates
                calendarCache.removeCache(view.start, view.end);

                // Reload the calendar after deleting a repeated event
                if (isRepeated) $state.reload();
              },
              onUpdateResponse(eventObject, fcEventId, attending) {
                // Update event
                _.set(event, ['filters', 'users', cdApp.me.id], attending);
                fullcalendar.fullCalendar('updateEvent', event);

                // Remove the cache for the data for the calendar for specific dates
                const view = fullcalendar.fullCalendar('getView');
                calendarCache.removeCache(view.start, view.end);
              },
            });
          });
        }

        // Save timestamp for last click
        $.data(event, 'lastClick', new Date().getTime());
      },
    });
  }

  // Confirm update modal.
  $scope.confirmModal = function (callback, event, delta, revertFunc) {
    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () =>
            event.type === 'event'
              ? gettextCatalog.getString('Update event')
              : gettextCatalog.getString('Update absence'),
          body: () =>
            gettextCatalog.getString(
              'Are you sure you want to update <b>{{ title }}</b>?',
              {
                title: event.title,
              }
            ),

          options: {
            confirmButtonText: gettextCatalog.getString('Update'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'primary',
          },
        },
      })
      .result.then(() => callback(event, delta, revertFunc))
      .catch(() => revertFunc());
  };

  // Generate a date from timestamp to e.g. Wednesday, July 8, 2015 4:22 PM
  $scope.Date = function (number) {
    return moment(number).format('LLLL');
  };

  // Double booking occurred.
  $scope.doubleBooking = function (data, event, delta, revertFunc) {
    $scope.conflictCheck = false;

    $uibModal.open({
      component: 'cdDoubleBookingModal',
      resolve: {
        conflicts: () => data,
        allowConflicts: () => () => {
          return $scope.updateEvent(event, delta, revertFunc, true);
        },
      },
    });
  };

  // Change top filters status.
  $scope.changeTopFilter = function (filter_id, event) {
    $scope.updateCalendarSettings('settings', 'render', {
      value: filter_id,
    });

    var target = angular.element(event.target);
    if (!_.includes(_.get(cdApp, 'data.calendar.settings.render'), filter_id)) {
      // remove
      target
        .parent()
        .find('i.fa-check-square')
        .removeClass('fa-check-square')
        .addClass('fa-square');
    } else {
      // add
      target
        .parent()
        .find('i.fa-square')
        .removeClass('fa-square')
        .addClass('fa-check-square');
    }

    if (_.includes(['absenceOverview', 'workplanOverview'], filter_id)) {
      var calendar = fullcalendar.fullCalendar('getView');
      $scope.$broadcast('updateFilter', {
        view: calendar.name,
      });
    }
    if (
      _.includes(
        [
          'mygroup-events',
          'mygroup-absence',
          'othercalendar-events',
          'othercalendar-absence',
          'mycalendar-private',
        ],

        filter_id
      )
    ) {
      $scope.refresh();
    }
  };

  $scope.showEverything = function ($event) {
    $event && $event.preventDefault();
    $event && $event.stopPropagation();
    $event && $event.stopImmediatePropagation();

    setTimeout(function () {
      $('.display-settings')
        .parent()
        .find('li a:not(:has(i.fa-check-square-o))')
        .click();
    });

    return $scope.selectAllGroups(true);
  };

  // Export calendar modal.
  $scope.exportCalendarModal = (type, element) => {
    let showPublicFeed = !type && !$scope.group_id ? true : false;
    let feedType = type ? type : $scope.group_id ? 'group' : '';
    let feedId = feedType === 'group' ? $scope.group_id : null;

    if (element) {
      feedId = type === 'entity' ? element.id : parseInt(element.key, 10);
    }

    if (_.includes(['absences', 'categories'], feedType)) {
      feedType = 'taxonomy';
      showPublicFeed = true;
    }
    if (feedType === 'users') {
      feedType = 'user';
      showPublicFeed = true;
    }
    if (feedType === 'resources') {
      feedType = 'resource';
      showPublicFeed = true;
    }
    let feedName = type === 'entity' ? element.title : _.get(element, 'name');
    $uibModal.open({
      component: 'cdCalendarFeedModal',
      resolve: {
        feedName: () =>
          gettextCatalog.getString('Export') +
          (_.isEmpty(feedName) ? '' : ` "${feedName}"`),
        feedId: () => feedId,
        feedType: () => feedType,
        showPublicFeed: () => showPublicFeed,
        token: () =>
          $q((resolve, reject) => {
            $http
              .get(`${cdApp.config.api.main}/ical/token`)
              .then((res) => resolve(res.data))
              .catch(reject);
          }),
      },
    });
  };

  $('#myTabs a').bind('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // Datepicker.
  $scope.datepicker = moment(_.get(cdApp, 'data.calendar.settings.date'))._d;
  $scope.open = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.opened = true;
  };

  // Special datepicker format.
  $scope.datepickerFormat = dateFormatsLookup.getFormat(lang);

  $scope.fullcalendar_date_change = function () {
    // If date is valid.
    if ($scope.datepicker && moment($scope.datepicker).isValid()) {
      $('#datepicker-input').removeClass('ng-invalid');
      var view = fullcalendar.fullCalendar('getView');
      var val = $.fullCalendar.moment($scope.datepicker);
      if (!val.isWithin(view.intervalStart, view.intervalEnd)) {
        splashScreen(true);
      }
      $timeout(function () {
        fullcalendar.fullCalendar('gotoDate', val);
      });
    } else {
      if (!$('#datepicker-input').hasClass('ng-invalid')) {
        $('#datepicker-input').addClass('ng-invalid');
      }
    }
  };

  // Add event to the cache object of fullcalendar
  $scope.$on('addEvent', function () {
    var view = fullcalendar.fullCalendar('getView');
    calendarCache.removeCache(view.start, view.end);

    // Update the poller after adding a new event
    calendarPollerService.update({
      start: view.start,
      end: view.end,
      calendarCache,
    });
  });

  // Fetch the nationalHolidays and then only initialize the full calendar.
  $q.all([holidayService.promise]).then(initFullcalendar);

  // =============================================================================
  // ChurchDesk calendar shortcuts.
  // =============================================================================

  hotkeys
    .bindTo($scope)
    .add({
      combo: 'left',
      description: gettextCatalog.getString('Previous'),
      callback: function () {
        $scope.calendarNavigate('prev'); // Go to Previous month/week/day view by pressing <-
      },
    })
    .add({
      combo: 'right',
      description: gettextCatalog.getString('Next'),
      callback: function () {
        $scope.calendarNavigate('next'); // Go to Next month/week/day view by pressing ->
      },
    })
    .add({
      combo: '1',
      description: gettextCatalog.getString('Switch to day view'),
      callback: function () {
        $scope.calendarChangeView('agendaDay'); // Go to Day view by pressing 1
      },
    })
    .add({
      combo: '2',
      description: gettextCatalog.getString('Switch to week view'),
      callback: function () {
        $scope.calendarChangeView('agendaWeek'); // Go to Week view by pressing 2
      },
    })
    .add({
      combo: '3',
      description: gettextCatalog.getString('Switch to month view'),
      callback: function () {
        $scope.calendarChangeView('month'); // Go to Month view by pressing 3
      },
    })
    .add({
      combo: '4',
      description: gettextCatalog.getString('Switch to resources view'),
      callback: function () {
        $scope.calendarChangeView('resources'); // Go to Resources view by pressing 4
      },
    })
    .add({
      combo: '5',
      description: gettextCatalog.getString('Switch to users view'),
      callback: function () {
        $scope.calendarChangeView('users'); // Go to Users view by pressing 5
      },
    })
    .add({
      combo: '0',
      description: gettextCatalog.getString('Go to today'),
      callback: function () {
        $scope.calendarNavigate('today'); // Go to Today view by pressing 0
      },
    })
    .add({
      combo: 'p c',
      description: gettextCatalog.getString('Print calendar'),
      action: 'keydown',
      callback: function () {
        $state.go('app.private.calendar.print'); // Print calendar by pressing P
      },
    });

  // Search button
  $scope.goToSearch = function () {
    $state.go('app.private.calendar.print');
  };

  /**
   * Transition hooks
   */

  // Remove the poller and clear toasts when leaving the calendar
  $transitions.onExit(
    {
      exiting: $state.current.name,
      to: (state) => state.name !== $state.current.name,
    },

    () => {
      toastr.clear();

      if (calendarPollerService.enabled) {
        calendarPollerService.poller.remove();
      }
    }
  );
}

CalendarController.$inject = [
  '_',
  'moment',
  '$scope',
  '$rootScope',
  '$http',
  '$q',
  '$uibModal',
  '$timeout',
  '$stateParams',
  '$location',
  '$state',
  'calendarService',
  'gettextCatalog',
  'calendarMenuService',
  'holidayService',
  'Groups',
  'toastr',
  'Calendar',
  'hotkeys',
  'Me',
  'dateFormatsLookup',
  '$transitions',
  'CalendarCache',
  'calendarPollerService',
  'eventPopupService',
  '$ngRedux',
];

angular
  .module('cdApp.calendar')
  .component('calendarComponent', {
    template: require('./full-calendar.component.html'),
    controller: CalendarController,
  })
  .directive('setFilterAccordionHeight', [
    '$window',
    '$timeout',
    function ($window, $timeout) {
      return {
        restrict: 'A',
        link: function (scope, element) {
          function setHeight() {
            $timeout(function () {
              if (!scope.element.isOpen) {
                element.css('height', '');
              } else if (scope.element.isOpen) {
                var containerHeight = angular
                  .element('#calendar-filters .calendar-filters-content')
                  .height();
                var searchElementHeight = angular
                  .element('#calendar-filters .navbar-form')
                  .height();
                var otherAccordions = angular.element(
                  '.calendar-filters-content .panel:not(.panel-open)'
                );

                var otherAccordionsHeight = 38 * otherAccordions.length;

                element
                  .css('height', containerHeight)
                  .css(
                    'height',
                    '-=' +
                      _.sum([searchElementHeight, otherAccordionsHeight]) +
                      'px'
                  );
              }
            });
          }
          scope.$watch('element.isOpen', setHeight);

          var resizeHandler = angular
            .element($window)
            .on(
              'resize',
              _.debounce(setHeight, 100, { leading: true, trailing: false })
            );

          scope.$on('$destroy', function () {
            angular.element($window).off('resize', resizeHandler);
          });
        },
      };
    },
  ]);
