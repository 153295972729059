import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { mainApi } from '@/react/api';
import { CheckUsersAvailablilityInputs } from '@/react/calendar/store/events/event';

export const useCheckAvailableResources = (showConflicts) => {
  const [isResourcesAvailable, setIsResourcesAvailable] =
    useState<boolean>(false);
  const checkResourcesAvailability = useRecoilCallback(
    () => async (body: CheckUsersAvailablilityInputs) => {
      // the rrule shouldn't be considered if it is an existing event or the rrule hasn't changed
      if (body.rrule === body.initialRrule) {
        body.rrule = undefined;
      }
      body.initialRrule = undefined;
      const response = await mainApi.post<any>('calendar/check', body);
      return response;
    },
    []
  );

  const handleDobleBookingResource = async ({
    eventDate,
    eventId,
    resourceIds,
    initialRrule,
  }) => {
    let startDate = eventDate?.startDate?.toISOString();
    let endDate = eventDate?.endDate?.toISOString();
    if (eventDate?.allDay) {
      startDate = eventDate?.startDate?.startOf('day')?.toISOString();
      endDate = eventDate?.endDate.endOf('day')?.toISOString();
    }
    const response = await checkResourcesAvailability({
      endDate: endDate,
      eventId: eventId,
      startDate: startDate,
      rrule: eventDate?.rrule,
      resources: resourceIds,
      initialRrule,
    });
    if (response.ok) {
      setIsResourcesAvailable(true);
    } else {
      setIsResourcesAvailable(false);
      showConflicts(response.data, true);
    }
  };
  return {
    handleDobleBookingResource,
    isResourcesAvailable,
    setIsResourcesAvailable,
  };
};
