'use strict';

class ShiftsHomepageComponent {
  constructor(gettextCatalog, Authorization) {
    this.Authorization = Authorization;

    this.noAccess = gettextCatalog.getString('You cannot access {{package}}', {
      package: gettextCatalog.getString('Rotas'),
    });
  }
}

ShiftsHomepageComponent.$inject = ['gettextCatalog', 'Authorization'];
angular.module('cdApp.intranet').component('cdShifts', {
  templateUrl: '@/app/intranet/shifts/shifts.component.html',
  bindings: {
    errorCode: '<',
  },

  controller: ShiftsHomepageComponent,
});
