(function () {
  'use strict';
  paginationOptions.$inject = ['localStorageService'];

  function paginationOptions(localStorageService) {
    /* Get the object paginationOption from the local storage */
    function getPaginationOptionsObject() {
      return localStorageService.get('paginationOptions') || {};
    }

    return {
      /* An object with getter and setter for items per page */
      itemsPerPage: {
        get: function (key) {
          if (!key) return;
          let paginationOptions = getPaginationOptionsObject();
          return _.get(paginationOptions, `itemsPerPage.${key}`);
        },
        set: function (key, value) {
          if (!key || !value) return;
          let paginationOptions = getPaginationOptionsObject();
          _.set(paginationOptions, `itemsPerPage.${key}`, value);
          localStorageService.set('paginationOptions', paginationOptions);
        },
      },
    };
  }

  angular
    .module('cdApp.shared')
    .factory('paginationOptions', paginationOptions);
})();
