import React, { Suspense } from 'react';

import AuthorizationService from '../services/AuthorizationService';
import { gettextCatalog } from '../services/I18nService';
import { CdFallbackPage } from '../shared/components/CdFallbackPage';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import cdApp from '@/react/config';

export const ProductLandingPage = ({
  packageName,
}: { packageName: string }) => {
  console;
  const language = cdApp.organization.locale?.language;
  // @ts-ignore
  const hasPackage = AuthorizationService.hasPackage(packageName);
  let iframeUrl: string = '';
  switch (packageName) {
    case 'calendar':
      iframeUrl = `https://churchdesk.com/${language}/app-calendar/`;
      break;
    case 'people':
      iframeUrl = `https://churchdesk.com/${language}/app-people/`;
      break;
    case 'forms':
      iframeUrl = `https://churchdesk.com/${language}/app-forms/`;
      break;
    case 'contributions':
      iframeUrl = `https://churchdesk.com/${language}/app-payments/`;
      break;
    case 'intentions':
      iframeUrl = 'https://churchdesk.com/de/app-intentionen/';
      break;
    case 'homepage':
      iframeUrl = `https://churchdesk.com/${language}/app-website/`;
      break;
    case 'safeguarding':
      iframeUrl = 'https://churchdesk.com/de/app-safeguarding/';
      break;
    case 'intranet':
      iframeUrl = `https://churchdesk.com/${language}/app-groups/`;
      break;
    default:
      break;
  }
  const getPackageName = (keyName: string) => {
    let name: string = '';
    switch (keyName) {
      case 'calendar':
        name = gettextCatalog.getString('Calendar', {}, 'The name of the app');
        break;
      case 'people':
        name = gettextCatalog.getString('People', {}, 'The name of the app');
        break;
      case 'forms':
        name = gettextCatalog.getString('Forms', {}, 'The name of the app');
        break;
      case 'contributions':
        name = gettextCatalog.getString(
          'Contributions',
          {},
          'The name of the app'
        );
        break;
      case 'intentions':
        name = gettextCatalog.getString(
          'Intentions',
          {},
          'The name of the app'
        );
        break;
      case 'homepage':
        name = gettextCatalog.getString('Website', {}, 'The name of the app');
        break;
      case 'safeguarding':
        name = gettextCatalog.getString(
          'Safeguarding',
          {},
          'The name of the app'
        );
        break;
      case 'intranet':
        name = gettextCatalog.getString('Rotas', {}, 'The name of the app');
        break;
      default:
        break;
    }
    return name;
  };

  return !hasPackage ? (
    <iframe
      data-testid="productLandingPageIframe"
      src={iframeUrl}
      style={{
        width: '100%',
        height: '100%',
        border: '0px',
      }}
    ></iframe>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <CdFallbackPage
        title={gettextCatalog.getString(
          'You do not have access to {{product}}.',
          {
            product: getPackageName(packageName),
          }
        )}
        subtitle={gettextCatalog.getString(
          'Please ask your organization administrator to grant you the {{product}} access permission.',
          {
            product: getPackageName(packageName),
          }
        )}
        hideButton={true}
        hasBody={false}
      />
    </div>
  );
};

export const ProductLandingPageWrapper = ({ $stateParams }) => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <ProductLandingPage packageName={$stateParams?.packageName} />
  </Suspense>
);
