(function () {
  'use strict';
  Tasks.$inject = ['$$resource', 'cdApp'];

  function Tasks($$resource, cdApp) {
    return $$resource(
      cdApp.config.api.main + '/groups/:groupId/tasks/:taskId',
      {
        groupId: '@groupId',
      },

      {
        getAll: {
          method: 'GET',
          url: cdApp.config.api.main + '/tasks',
          isArray: true,
        },

        getAssignableUsers: {
          method: 'GET',
          url: cdApp.config.api.main + '/tasks/:taskId/assignable-users',
          isArray: true,
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Tasks', Tasks);
})();
