import _ from 'lodash';

class RotasTableController {
  constructor(
    $scope,
    $timeout,
    $state,
    gettextCatalog,
    Planning,
    cdResourceColors
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.Planning = Planning;
    this.cdResourceColors = cdResourceColors;
  }

  $onInit() {
    const { cdResourceColors } = this;

    // Static category/resource colors.
    this.colors = cdResourceColors;

    // Table Sorting
    this.reverseSort = false;
  }

  $onChanges({ rotas, columnManager }) {
    /**
     * When the rotas are changed, we need to generate the table again
     */
    if (
      rotas &&
      rotas.currentValue &&
      rotas.currentValue !== rotas.previousValue
    ) {
      this.generateTable();
    }

    /**
     * Whenever a column in the column manager is toggled, we need to generate the table again
     */
    if (columnManager && columnManager.currentValue) {
      const prevOnChange = columnManager.currentValue.onChange;
      columnManager.currentValue.onChange = () => {
        if (prevOnChange) {
          prevOnChange();
        }
        this.generateTable(true);
      };
    }
  }

  toggleSortDirection() {
    this.reverseSort = !this.reverseSort;
    this.generateTable();
  }

  assignUser($event, calendarId, taskId) {
    const { Planning } = this;

    $event.target.focus = false;
    Planning.showAssignUsersToTaskModal(calendarId, taskId).then(
      ({ resolved }) => {
        if (resolved) {
          this.refreshData();
        }
      }
    );
  }

  /**
   * Checks whether a cell a task specified.
   *
   * @returns {boolean}
   */
  cellIsDisabled(column) {
    return _.isUndefined(column.unassigned);
  }

  onTableCellKeyPress($event, calendarId, taskId) {
    $event.preventDefault();
    $event.target.focus = false;
    if ($event.charCode === 32 || $event.charCode === 13) {
      this.assignUser($event, calendarId, taskId);
    }
  }

  goToCalendar() {
    this.$state.go('app.private.calendar.full');
  }

  generateTable(columnChange = false) {
    /**
     * Generate table columns: first column is the event start date, and then there's a column for every rota
     */
    this.columns = _.filter(this.columnManager.columns, { isVisible: true });

    this.rows = _(this.rotas)
      .map(
        ({
          id,
          title,
          startDate,
          endDate,
          allDay,
          resources,
          tasks,
          clientVersion,
        }) => {
          /**
           * Each row represents an event, and has the event cell at the left
           */
          const row = {
            id,
            startDate,
            columns: [
              {
                id: 'startDate',
                eventId: id,
                title,
                startDate,
                endDate,
                allDay,
                resources,
                clientVersion,
              },
            ],
          };

          /**
           * Each column of the column manager represents a task
           */
          _.forEach(this.columns, ({ taskId }) => {
            const eventTask = _.get(tasks, taskId);
            if (!eventTask) {
              // No available rotas for this event
              row.columns.push({ id: taskId, users: null });
            } else {
              row.columns.push({
                id: taskId,
                users: eventTask.users,
                unassigned: _.isBoolean(eventTask.unassigned)
                  ? eventTask.unassigned
                    ? 1
                    : 0
                  : eventTask.unassigned,
                note: eventTask.note,
                groupId: eventTask.groupId,
              });
            }
          });

          return row;
        }
      )
      .orderBy('startDate', this.reverseSort ? 'desc' : 'asc')
      .value();
    if (columnChange) {
      this.onColumnChange();
    }
  }
}
RotasTableController.$inject = [
  '$scope',
  '$timeout',
  '$state',
  'gettextCatalog',
  'Planning',
  'cdResourceColors',
];

angular.module('cdApp.shared').component('cdRotasTable', {
  template: require('./rotas-table.component.html'),
  bindings: {
    isPublic: '<',
    rotas: '<',
    isLoading: '<',
    isUpdating: '<',
    hideNotes: '<',
    columnManager: '<',
    refreshData: '&',
    isNewRotaScheme: '<',
    onColumnChange: '&',
    isMyScheme: '<',
  },

  controller: RotasTableController,
});
