(function () {
  'use strict';
  angular
    .module('cdApp.shared')
    .directive('uibDropdownStayClosed', function () {
      return {
        restrict: 'A',
        link: function (scope) {
          scope.$$listeners.$locationChangeSuccess = [];
        },
      };
    });
})();
