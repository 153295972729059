import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Modal, Space, Typography } from 'antd';
import React from 'react';

import { gettextCatalog } from '../../services/I18nService';
import { CdSalesButton } from '../../shared/components/CdSalesButton';

import { CdBookMeetingIcon } from '@/react/shared/components/Icons';
import cdApp from '@/react/config';

export const openConnectToAccountManagerModal = () =>
  NiceModal.show('ConnectToAccountManagerModal');

export const ConnectToAccountManagerModal = NiceModal.create(() => {
  const modal = useModal('ConnectToAccountManagerModal');
  const language = cdApp.organization.locale.language;
  let bookingLink;
  switch (language) {
    case 'da':
      bookingLink = 'https://in-app-dk.youcanbook.me';
      break;
    case 'sv':
      bookingLink = 'https://in-app-dk.youcanbook.me';
      break;
    case 'de':
      bookingLink = 'https://in-app-de.youcanbook.me';
      break;
    default:
      bookingLink = 'https://in-app-en.youcanbook.me';
  }
  return (
    <Modal
      {...{
        ...antdModalV5(modal),
        closable: true,
        width: 416,
        bodyStyle: { padding: 8 },
        footer: null,
      }}
    >
      <Space
        size="large"
        direction="vertical"
        align="center"
        style={{
          margin: '24px',
          textAlign: 'center',
        }}
      >
        <CdBookMeetingIcon size="2x" style={{ color: '#0091b2' }} />
        <Typography.Text>
          {gettextCatalog.getString(
            'Learn more about how to create a new website. An account manager will be able to answer all your questions.'
          )}
        </Typography.Text>
        <CdSalesButton target="_blank" href={bookingLink} rel="noopener">
          {gettextCatalog.getString('Book a meeting')}
        </CdSalesButton>
      </Space>
    </Modal>
  );
});
