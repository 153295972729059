'use strict';

// Redux
import { FetchUserPrivilege } from '../../../react/user/redux/actions';
import { getUserPrivilege } from '../../../react/user/redux/selectors';

class PeopleCustomFieldsStateComponent {
  constructor(
    _,
    $q,
    $state,
    $stateParams,
    $scope,
    $ngRedux,
    gettextCatalog,
    People,
    peopleFieldsService,
    PeopleCustomFields,
    formioComponents,
    toastr,
    stepsFactory,
    cdExitPrompt
  ) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.People = People;
    this.peopleFieldsService = peopleFieldsService;
    this.PeopleCustomFields = PeopleCustomFields;
    this.formioComponents = formioComponents;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.stepsFactory = stepsFactory;
    this.cdExitPrompt = cdExitPrompt;

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  $onInit() {
    const { _ } = this;
    /**
     * Flatten the icons object structure to match the result returned from the static fields endpoint
     * @example { 'contact.country': 'dk' } => { 'country': 'dk' }
     */
    this.staticFieldIcons = _.reduce(
      this.peopleFieldsService.getStaticFieldsIcons(),
      (result, value, key) => {
        if (_.includes(key, '.')) {
          key = _.last(key.split('.'));
        }
        return _.assign(result, { [key]: value });
      },
      {}
    );

    // Format the static fields into a formio-valid structure
    this.staticFieldsSections = _(
      this.peopleFieldsService.getStaticFieldsSections()
    )
      .map((section) => ({
        key: section.title,
        title: section.title,
        components: _(section.fields)
          .reject((field) => !field.available)
          .map((field) => {
            const key = _.last(_.split(field.key, '.'));
            const staticField = _.get(this.staticFields.toJSON(), key);
            const label = _.get(staticField, 'name');
            const locked = _.get(staticField, 'locked');
            const sensitive = _.get(staticField, 'sensitive');
            return {
              key,
              label,
              locked,
              churchdesk: { privacy: { sensitive } },
            };
          })
          .value(),
      }))
      .value();

    this.originalStaticFields = angular.copy(this.staticFieldsSections);
    this.originalCustomFields = angular.copy(this.customFields);

    this.setPeopleFields();

    /**
     * Generate the steps view
     */
    this.stepsInstance = new this.stepsFactory(
      [
        {
          key: 'settings',
          title: this.gettextCatalog.getString('All fields'),
          description: this.gettextCatalog.getString(
            'View all contact fields and mark which are sensitive'
          ),

          isEnabled: true,
          isShown: true,
        },

        {
          key: 'custom',
          title: this.gettextCatalog.getString('Edit fields'),
          description: this.gettextCatalog.getString(
            'Add your own custom fields to enrich the data of your contacts'
          ),

          isEnabled: true,
          isShown: true,
        },
      ],

      {
        initialStep: this.$stateParams.step,
        onChange: (index) => {
          this.$state.go(this.$state.current, {
            step: this.stepsInstance.steps[index].key,
          });

          if (this.stepsInstance.steps[index].key) {
            this.setPeopleFields();
          }
        },
      }
    );

    this.FetchUserPrivilege([
      {
        entityId: window.churchdeskOrganizationId,
        entityType: 'organization',
        privileges: ['organization.peopleFields.edit'],
      },
    ]);
  }

  // AngularJS <-> Redux mapping functions
  mapStateToScope = (state) => ({
    canManagePeopleFields: getUserPrivilege(state, {
      entityId: window.churchdeskOrganizationId,
      entityType: 'organization',
      privilege: 'organization.peopleFields.edit',
    }),
  });

  mapDispatchToScope = (dispatch) => ({
    FetchUserPrivilege: (privileges) =>
      dispatch(FetchUserPrivilege(privileges)),
  });

  save() {
    const fields = this.customFields.components.map((field) => {
      if (!field.key) {
        return {
          ...field,
          key: Math.random()
            .toString(36)
            .substring(0, 9)
            .replace(/[0-9]/g, 'b')
            .replace('.', 'z'),
        };
      } else {
        return field;
      }
    });
    this.customFields = { ...this.customFields, components: fields };
    this.$q
      .all([
        this.PeopleCustomFields.update(this.customFields).$promise,
        this.People.saveSensitiveFields({
          peopleFieldsSensitiveSetting: this._.mapValues(
            this.staticFields,
            (value, key) => {
              const fields = _.flatMap(this.staticFieldsSections, 'components');
              const field = _.find(fields, { key });
              return _.get(
                field,
                'churchdesk.privacy.sensitive',
                value.sensitive
              );
            }
          ),
        }).$promise,
      ])
      .then(() => {
        this.originalStaticFields = angular.copy(this.staticFieldsSections);
        this.originalCustomFields = angular.copy(this.customFields);

        // Clean up custom fields that have been already registered as components in Forms
        this.formioComponents.components = this._.omitBy(
          this.formioComponents.components,
          (value, key) => this._.startsWith(key, 'peopleCustomField')
        );

        if (this.$stateParams.sourceContactId) {
          this.$state.go('app.private.people.contacts.view', {
            id: this.$stateParams.sourceContactId,
          });
        } else {
          this.$state.reload();
        }

        this.toastr.success(
          this.gettextCatalog.getString('The fields have been saved.')
        );
      });
  }

  /**
   * Get a list of nested components of both static fields and custom fields
   */
  setPeopleFields() {
    this.peopleFields = this._.concat(
      // Static fields
      this.staticFieldsSections,
      // Custom fields
      this._.reject(this.customFields.components, { type: 'button' })
    );
  }

  /**
   * Returns the font-awesome class that shows whether the field is sensitive
   * @param {Object} field - The formio component
   */
  getSensitiveClass(field) {
    return this._.get(field, 'churchdesk.privacy.sensitive', false)
      ? 'far fa-lock-alt'
      : 'far text-muted fa-unlock-alt';
  }
  getSensitiveTooltip(field) {
    return this._.get(field, 'churchdesk.privacy.sensitive', false)
      ? this.gettextCatalog.getString('Make field not-sensitive')
      : this.gettextCatalog.getString('Make field sensitive');
  }

  /**
   * Get the FontAwesome icon depending on the field type
   *
   * @param {string} key
   */
  getFieldIcon(key) {
    return this._.get(this.staticFieldIcons, key) || 'fa-sliders-h';
  }

  /**
   * Toggles the sensitive property of a formio component
   * @param {Object} field The formio component
   */
  toggleSensitive(field) {
    if (field.locked) return;
    const isSensitive = this._.get(
      field,
      'churchdesk.privacy.sensitive',
      false
    );

    this._.set(field, 'churchdesk.privacy.sensitive', !isSensitive);
  }

  /**
   * Check whether an object has changed
   * @param {*} original
   * @param {*} copy
   */
  hasChanged(original, copy) {
    return !angular.equals(angular.toJson(original), angular.toJson(copy));
  }

  uiCanExit() {
    if (
      this.hasChanged(
        this.originalCustomFields.components,
        this.customFields.components
      ) ||
      this.hasChanged(this.originalStaticFields, this.staticFieldsSections)
    ) {
      return this.cdExitPrompt.prompt();
    }
  }

  /**
   * Navigate to the correct step when the URL parameter changes
   */
  uiOnParamsChanged() {
    const stepKey = this.$stateParams.step;
    const stepExists = this._(this.stepsInstance.steps)
      .map('key')
      .includes(stepKey);
    if (stepExists) this.stepsInstance.go(stepKey);
  }
}

PeopleCustomFieldsStateComponent.$inject = [
  '_',
  '$q',
  '$state',
  '$stateParams',
  '$scope',
  '$ngRedux',
  'gettextCatalog',
  'People',
  'peopleFieldsService',
  'PeopleCustomFields',
  'formioComponents',
  'toastr',
  'stepsFactory',
  'cdExitPrompt',
];

angular.module('cdApp.people').component('cdPeopleCustomFieldsState', {
  templateUrl: '@/app/people/custom-fields/custom-fields.component.html',
  controller: PeopleCustomFieldsStateComponent,
  bindings: {
    staticFields: '<',
    customFields: '<',
  },
});
