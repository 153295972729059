'use strict';

/**
 * Get the translated name of a country from an ISO code
 *
 * @example <span>{{ 'dk' | countryName }}</span> // => Denmark
 */
angular.module('cdApp.shared').filter('countryName', [
  'cdApp',
  '_',
  (cdApp, _) => {
    'ngInject';

    return (isoCode) => {
      if (!isoCode) return null;
      if (!cdApp.data.countries) return isoCode;

      return _.get(cdApp.data.countries, isoCode.toLowerCase()).nameTranslated;
    };
  },
]);
