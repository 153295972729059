'use strict';

function MultiTaggingController(
  $scope,
  $uibModal,
  $filter,
  People,
  PeopleTags,
  gettextCatalog,
  toastr
) {
  let $ctrl = this;

  /**
   * Lifecycle hook used for initialization work
   */
  $ctrl.$onInit = function () {
    $ctrl.busy = true;
    $ctrl.isOpen = $ctrl.isOpen || false;

    resetScheduledTags();
  };

  /**
   * Lifecycle hook user for watching for property changes
   */
  $ctrl.$onChanges = function (changesObj) {
    if (
      changesObj.selectedPeople &&
      !changesObj.selectedPeople.isFirstChange() &&
      changesObj.selectedPeople.currentValue
    ) {
      $ctrl.updateTagsList();
    }
  };

  /**
   * Close the dropdown
   *
   * @param {Object} $event - The original click event
   */
  $ctrl.closeDropdown = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    resetScheduledTags();
    $ctrl.isOpen = false;
  };

  /**
   * Called when the dropdown is being closed
   *
   * @param {Boolean} open - Whether the dropdown is opened or closed
   */
  $ctrl.onDropdownToggle = function (open) {
    if (open) {
      $ctrl.updateTagsList();
    } else {
      if (
        _.size($ctrl.scheduledTags.toAdd) > 0 ||
        _.size($ctrl.scheduledTags.toRemove) > 0
      ) {
        resetScheduledTags();
      }
    }
  };

  /**
   *  Fetch all tags
   */
  $ctrl.initialTags = PeopleTags.query({}, function () {
    $ctrl.busy = false;
    $ctrl.updateTagsList();
  });

  /**
   *  Refresh the list of tags by applying filtering and ordering and figuring out its checked state
   */
  $ctrl.updateTagsList = function () {
    $ctrl.filteredTags = angular.copy($ctrl.initialTags);
    $ctrl.scheduledTags.indeterminate = [];

    _.forEach($ctrl.filteredTags, function (tag) {
      // A tag is checked if it exists in the scheduledTags.toAdd array
      let tagIsInScheduledTags =
        $ctrl.scheduledTags && _.includes($ctrl.scheduledTags.toAdd, tag.id);

      if ($ctrl.isGlobalSelect) {
        // Don't process whether it should be checked or not. All tags are indeterminate when isGlobalSelect=true
        $ctrl.scheduledTags.indeterminate.push(tag.id);
        tag.indeterminate = true;
        return;
      }

      // A tag is also checked if all the selected people have it in their tags array
      let tagBelongsToAllSelectedPeople =
        _.size($ctrl.selectedPeople) &&
        _.every($ctrl.selectedPeople, function (selectedPerson) {
          const tagsField = _.find(selectedPerson.fields, { property: 'tags' });
          if (!tagsField) return false;
          return _.includes(_.map(tagsField.value, 'value'), tag.id);
        });

      let tagBelongsToSomeSelectedPeople =
        _.size($ctrl.selectedPeople) &&
        _.some($ctrl.selectedPeople, function (selectedPerson) {
          const tagsField = _.find(selectedPerson.fields, { property: 'tags' });
          if (!tagsField) return false;
          return _.includes(_.map(tagsField.value, 'value'), tag.id);
        });
      if (tagBelongsToSomeSelectedPeople && !tagBelongsToAllSelectedPeople) {
        $ctrl.scheduledTags.indeterminate.push(tag.id);
        tag.indeterminate = true;
      }
      tag.checked = tagIsInScheduledTags || tagBelongsToAllSelectedPeople;
    });

    let searchResults = $filter('filter')($ctrl.filteredTags, {
      name: $ctrl.search,
    });

    let sorted = $filter('orderBy')(searchResults, 'name');

    if (
      !$ctrl.search ||
      _.some($ctrl.initialTags, {
        name: $ctrl.search,
      })
    ) {
      $ctrl.filteredTags = sorted;
    } else {
      $ctrl.filteredTags = _.union(sorted, [
        {
          isCreateTag: true,
        },
      ]);
    }
  };

  /**
   * Called when a tag is selected
   *
   * @param {Object} tag - The tag object
   * @param {Number} index - The index of the tag
   */
  $ctrl.tagClicked = function (tag, index) {
    tag.checked = !tag.checked;
    const id = tag.id;
    if (tag.isCreateTag) {
      createNewTag();
    } else {
      if (tag.checked) {
        if (!_.includes($ctrl.scheduledTags.toRemove, id)) {
          // schedule the tag to be added
          $ctrl.scheduledTags.toAdd.push(id);
        } else {
          if (_.includes($ctrl.scheduledTags.indeterminate, id)) {
            $ctrl.scheduledTags.toAdd.push(id);
          }
          _.pull($ctrl.scheduledTags.toRemove, id);
        }
      } else {
        if (!_.includes($ctrl.scheduledTags.toAdd, id)) {
          // schedule the tag to be removed
          $ctrl.scheduledTags.toRemove.push(id);
        } else {
          if (_.includes($ctrl.scheduledTags.indeterminate, id)) {
            $ctrl.scheduledTags.toRemove.push(id);
          }
          _.pull($ctrl.scheduledTags.toAdd, id);
        }
      }

      $ctrl.indexOfActiveItem = index;
    }
  };

  /**
   * Determine whether there are pending tags that need to be applied
   */
  $ctrl.tagsHaveChanged = function () {
    if (!$ctrl.scheduledTags) return false;
    return (
      _.size($ctrl.scheduledTags.toAdd) > 0 ||
      _.size($ctrl.scheduledTags.toRemove) > 0
    );
  };

  /**
   * Apply changes
   */
  $ctrl.applyScheduledTags = function () {
    updateSelectedPeople();
  };

  /**
   * Discard changes
   */
  $ctrl.discardScheduledTags = function () {
    resetScheduledTags();
    $ctrl.isOpen = false;
  };

  /**
   * Create a new tag
   */
  $ctrl.createTag = function () {
    $uibModal
      .open({
        component: 'cdCreatePeopleTag',
        resolve: {
          tagList: _.constant($ctrl.initialTags),
        },
      })
      .result.then(function (tag) {
        $ctrl.isOpen = true;

        toastr.success(gettextCatalog.getString('Tag added successfully.'));

        if (_.size($ctrl.selectedPeople) > 0) {
          $ctrl.scheduledTags.toAdd.push(tag.id);
        }

        $ctrl.initialTags.push(tag);
        $ctrl.updateTagsList();

        $ctrl.onTagCreated({ tag });
      });
  };

  // The index of the active item
  $ctrl.indexOfActiveItem = 0;

  /**
   * When the search query changes, and the tags list happens again, ensure that the active index doesn't exceed the length of the array
   */
  $scope.$watch('$ctrl.filteredTags.length', function (newValue, oldValue) {
    if (
      newValue !== oldValue &&
      newValue !== 0 &&
      newValue < $ctrl.indexOfActiveItem + 1
    ) {
      $ctrl.indexOfActiveItem = newValue - 1;
    }
  });

  /**
   * Handle keyboard navigation
   *
   * @param {Object} $event - The original event object
   */
  $ctrl.keyDown = function ($event) {
    // return/enter key
    if ($event.which === 13) {
      if ($ctrl.selectedPeople.length) {
        $ctrl.tagClicked(
          $ctrl.filteredTags[$ctrl.indexOfActiveItem],
          $ctrl.indexOfActiveItem
        );
      }
    } else if ($event.which === 38) {
      // up key
      if ($ctrl.selectedPeople.length) {
        $ctrl.indexOfActiveItem =
          $ctrl.indexOfActiveItem === 0
            ? $ctrl.filteredTags.length - 1
            : $ctrl.indexOfActiveItem - 1;
      }
      $event.preventDefault();
    } else if ($event.which === 40) {
      // down key
      if ($ctrl.selectedPeople.length) {
        $ctrl.indexOfActiveItem =
          $ctrl.indexOfActiveItem === $ctrl.filteredTags.length - 1
            ? 0
            : $ctrl.indexOfActiveItem + 1;
      }
      $event.preventDefault();
    } else if ($event.which === 27) {
      // Escape key
      $ctrl.discardScheduledTags();
    }
  };

  /**
   * Reset selected tags
   */
  function resetScheduledTags() {
    $ctrl.scheduledTags = {
      toAdd: [],
      toRemove: [],
      indeterminate: [],
    };
  }

  /**
   * Updates the selected people's tag with the new tags
   */
  function updateSelectedPeople() {
    const addCount = $ctrl.scheduledTags.toAdd.length;
    const removeCount = $ctrl.scheduledTags.toRemove.length;
    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => gettextCatalog.getString('Apply tag changes'),
          body: () =>
            gettextCatalog.getString('Do you want to:') +
            '\n' +
            (addCount > 0
              ? '- ' +
                gettextCatalog.getPlural(
                  addCount,
                  'add 1 tag',
                  'add {{ $count }} tags',
                  {
                    $count: addCount,
                  }
                ) +
                '\n'
              : '') +
            (removeCount > 0
              ? '- ' +
                gettextCatalog.getPlural(
                  removeCount,
                  'remove 1 tag',
                  'remove {{ $count }} tags',
                  {
                    $count: removeCount,
                  }
                ) +
                '\n'
              : ''),
          options: {
            confirmButtonText: gettextCatalog.getString('Yes'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'primary',
          },
        },
      })
      .result.then(() => {
        let payload = {};
        $ctrl.busy = true;
        payload.tags = {
          add: $ctrl.scheduledTags.toAdd,
          remove: $ctrl.scheduledTags.toRemove,
        };

        if (!$ctrl.isGlobalSelect) {
          payload.people = _.map($ctrl.selectedPeople, 'id');
        } else {
          payload.filter = $ctrl.filter;
        }

        People.multiTag(
          {},
          payload,
          () => {
            $ctrl.busy = false;
            toastr.success(
              gettextCatalog.getString('Tags updated successfully.')
            );

            resetScheduledTags();
            $ctrl.onUpdate();
          },
          () => {
            $ctrl.busy = false;
          }
        );
      });
  }

  /**
   *  Create a new tag with the search input
   */
  function createNewTag() {
    $ctrl.busy = true;

    new PeopleTags({ name: $ctrl.search }).$save(
      function (tag) {
        if (_.size($ctrl.selectedPeople) > 0) {
          // schedule the tag to be added
          tag.checked = true;
          $ctrl.scheduledTags.toAdd.push(tag.id);
        }

        // add it to the list of tags
        $ctrl.initialTags.push(tag);

        // reset search query
        $ctrl.search = '';
        $ctrl.updateTagsList();
        $ctrl.busy = false;

        $ctrl.onTagCreated({ tag });
      },
      function (err) {
        if (err) {
          toastr.error(err.message || err.data.message);
        }

        $ctrl.search = '';
        $ctrl.updateTagsList();
        $ctrl.busy = false;
      }
    );
  }
}
MultiTaggingController.$inject = [
  '$scope',
  '$uibModal',
  '$filter',
  'People',
  'PeopleTags',
  'gettextCatalog',
  'toastr',
];

angular.module('cdApp.people').component('cdMultiTagging', {
  transclude: true,
  templateUrl:
    '@/app/people/shared/components/multi-tagging/multi-tagging.component.html',
  bindings: {
    isOpen: '=',
    buttonSize: '<',
    isDisabled: '<',
    onUpdate: '&',
    onTagCreated: '&',
    selectedPeople: '<',
    selectedChurches: '<',
    filter: '<',
    isGlobalSelect: '<',
  },

  controller: MultiTaggingController,
});
