(function () {
  'use strict';

  /**
   * @desc focus on an input
   *
   * @example <input cd-focus-on="expression">
   */ focusOn.$inject = ['$parse', '$timeout'];

  function focusOn($parse, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        let model = $parse(attrs.cdFocusOn);

        scope.$watch(model, function (value) {
          if (value === true) {
            $timeout(function () {
              elem[0].focus();
            });
          }
        });
      },
    };
  }

  angular.module('cdApp.shared').directive('cdFocusOn', focusOn);
})();
