'use strict';

class SupportService {
  constructor(supportDomain, cdApp, _) {
    'ngInject';

    this.supportDomain = supportDomain;
    this.cdApp = cdApp;
    this._ = _;

    this.articles = {
      createUser: {
        da: '/dansk-support/introduktion/opret-og-administrer-brugere',
        de: '/deutsch-support/einfuhrung/benutzer-erstellen-und-berechtigungen-zuteilen',
        'en-gb': '/english-support/introduction/create-and-manage-users',
      },

      groups: {
        da: '/dansk-support/grupper/grupper',
        de: '/deutsch-support/gruppen/gruppen',
        'en-gb': '/english-support/groups/groups',
      },

      importPeople: {
        da: 'https://support.churchdesk.com/da/article/importer-jeres-kontaktdata-rz85oe',
        de: 'https://support.churchdesk.com/de/article/importieren-sie-ihre-kontaktdaten-8f3yru/',
        'en-gb':
          'https://support.churchdesk.com/en/article/import-your-contact-data-1y3v2rj/',
      },
    };
  }

  getArticle(articleId) {
    const { _, cdApp } = this;
    const language = _.get(cdApp, 'organization.locale.language');
    const articleUrl = _.get(
      this.articles,
      [articleId, language],
      _.get(this.articles, [articleId, 'en-gb'])
    );

    if (articleUrl.includes('https')) {
      return articleUrl;
    }

    return this.supportDomain + articleUrl;
  }

  getArticles(articleIds = []) {
    const { _, cdApp } = this;
    const language = _.get(cdApp, 'organization.locale.language');

    return _(this.articles)
      .pick(articleIds)
      .mapValues(
        (urls) => this.supportDomain + _.get(urls, language, urls['en-gb'])
      )
      .value();
  }
}

SupportService.$inject = ['supportDomain', 'cdApp', '_'];
angular
  .module('cdApp.shared')
  .constant('supportDomain', 'https://support.churchdesk.com')
  .service('supportService', SupportService);
