import { fork, put, select, take } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';

import { withLoadingAndErrors } from '../../shared/loading/saga';
import { fullCalendarActions } from '../store/events/fullCalendarEventsSlice';
import { AppState } from '../../redux';
import { selectIsUserFiltersLoaded } from '../store/filters/Selectors';
import { userFiltersLoaded } from '../store/filters/Actions';

export const reloadCalendarWithLoadingAndErrors = withLoadingAndErrors(
  'calendar',
  fireLoadAction
);
export const withUpdateFilterData = (saga?) =>
  function* (action?): Generator {
    if (saga) {
      yield fork(saga, action);
    }
    const isInitialStateLoaded = selectIsUserFiltersLoaded(
      (yield select()) as AppState
    );
    if (!isInitialStateLoaded) {
      yield take(userFiltersLoaded.type);
    }
    yield put({ type: 'onSaveCalendarData' });
  };

export function* fireLoadAction(): SagaIterator {
  yield put(fullCalendarActions.reloadCurrentView());
}

export const reLoadAndUpdateFilterData = withUpdateFilterData(fireLoadAction);
