'use strict';

angular.module('cdApp.calendar').factory('CalendarCache', [
  'moment',
  '$http',
  'toastr',
  function (moment, $http, toastr) {
    'ngInject';

    return function () {
      let error = false;
      let cache = {};
      let info = [];

      // Maximum lifetime of cached elements, in seconds
      const cacheLifetime = 10 * 60;

      // Initial number of pages to prefetch on first load (X ahead and X backwards)
      const prefetchLimit = 2;

      // Number of pages to prefetch once the user starts navigating (X ahead if user moves forward in time)
      const prefetchLimitWhenNavigating = 5;

      /**
       * Set the group
       *
       * @param {Number} groupId
       */
      this.setGroup = (groupId) => {
        this.groupId = groupId;
      };

      /**
       * Remove the cache for a specific time range
       *
       * @param {Date} start
       * @param {Date} end
       */
      this.removeCache = (start, end) => {
        cache = {};
        loadData(start, end);
        prefetchEvents(start, end);
      };

      /**
       * Feed (cached) events for the requested range to the callback function, and initiate prefetching
       *
       * @param {Data} start
       * @param {Data} end
       * @param {String} timezone
       * @param {Function} callback
       */
      this.getEvents = (start, end, timezone, callback) => {
        if (cacheIsValid(start, end)) {
          // Callback from the cache
          callback(cache[cacheId(start, end)].data);
        } else {
          // Load the data from the server
          loadData(start, end, timezone, callback);
        }

        prefetchEvents(start, end);
      };

      /**
       * Prefetch events for the requested range
       *
       * @param {Date} start
       * @param {Date} end
       */
      const prefetchEvents = (start, end) => {
        // Predictive forward-loading
        const difference = end.diff(start, 'days');

        // First load
        let back = -1;
        let ahead = prefetchLimit;

        if (
          typeof info.last_start !== 'undefined' &&
          info.last_start !== start.format('X')
        ) {
          back =
            info.last_start - start.format('X') > 0
              ? -prefetchLimitWhenNavigating
              : -1;
          ahead =
            info.last_start - start.format('X') > 0
              ? 1
              : prefetchLimitWhenNavigating;
        }

        let start_;
        let end_;
        for (let i = back; i <= ahead; i++) {
          if (i === 0) continue;

          if (difference < 8) {
            start_ = start.clone().add(i * difference, 'days');
            end_ = end.clone().add(i * difference, 'days');
          } else {
            start_ = start
              .clone()
              .add(1, 'week')
              .add(i, 'months')
              .startOf('months');
            end_ = start_.clone().endOf('months').endOf('week').add(1, 'days');
            start_.startOf('week');
          }

          if (!cacheIsValid(start_, end_) && !cacheIsLoading(start_, end_)) {
            loadData(start_, end_);
          }
        }

        // Last start UNIX timestamp
        info.last_start = start.format('X');
      };

      /**
       * Get the data from the server, and return to the fullCalendar callback function
       *
       * @param {Data} start
       * @param {Data} end
       * @param {String} timezone
       * @param {Function} callback
       */
      const loadData = (start, end, timezone, callback) => {
        // Create empty cache object for this day format
        cache[cacheId(start, end)] = {
          time: 0,
          data: [],
          ready: false,
        };

        // Load the data from the server
        $http
          .post(`${cdApp.config.api.main}/calendar/fullcalendar`, {
            groupId: this.groupId,
            start: start.toDate(),
            end: end.toDate(),
          })
          .success((data) => {
            // Store the data in the cache
            cache[cacheId(start, end)] = {
              data,
              time: moment().format('X'),
              ready: true,
            };

            // Callback to FullCalendar
            if (callback) {
              callback(data);
            }
          })
          .error(() => {
            if (!error) {
              toastr.error(
                'Error happened while trying to load the calendar, please try again or contact support for this case.'
              );

              error = true;
            }
          });
      };

      /**
       * Returns the cache id variable
       *
       * @param {Date} start
       * @param {Date} end
       */
      const cacheId = (start, end) =>
        `${start.format('X')}-${end.format('X')}-${this.groupId}`;

      /**
       * Check if the cache is valid and exists
       *
       * @param {Date} start
       * @param {Date} end
       */
      const cacheIsValid = (start, end) =>
        cacheId(start, end) in cache &&
        moment().format('X') - cache[cacheId(start, end)].time <=
          cacheLifetime &&
        cache[cacheId(start, end)].ready === true;

      /**
       * Check if the cache is loading
       *
       * @param {Date} start
       * @param {Date} end
       */
      const cacheIsLoading = (start, end) =>
        cacheId(start, end) in cache && !cache[cacheId(start, end)].ready;
    };
  },
]);
