import { Col, Row, Tag, Typography, Input, Button, Space, Select } from 'antd';
import React, { useEffect } from 'react';
import {
  useRecoilValue,
  useRecoilState,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilCallback,
} from 'recoil';
import { orderBy } from 'lodash';

import {
  emailSent,
  getStaticsLabel,
  StatisticsStatus,
} from './StatisticsStatus';

import {
  MessageStatisticsFilters,
  PeopleMessage,
  PeopleMessageStatistics,
} from '@/react/people/types/message';
import { gettextCatalog } from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import {
  CdFilterIcon,
  CdRefresh,
  CdSchedule,
  CdUsers,
} from '@/react/shared/components/Icons';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { StatisticsDiagram } from '@/react/people/message-editor/statistics/StatisticsDiagram';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';
import { OrderFunctions } from '@/react/shared/components/cd-table/useCdTable';
import { TableOrdering } from '@/react/shared/models/table';
import {
  MessageStatisticsByIdFilter,
  MessageStatisticsByIdQuery,
} from '@/react/people/store/message';

const { Search } = Input;
const { Option } = Select;
const { Link } = Typography;

export const Statistics = ({
  message,
  refetchMessage,
  defaultFilter,
}: {
  message: PeopleMessage;
  refetchMessage: () => void;
  defaultFilter: MessageStatisticsFilters;
}) => {
  const statistics = useRecoilValue(
    MessageStatisticsByIdFilter.Query(message.id)
  );

  const refreshMessageStatistics = useRecoilRefresher(
    MessageStatisticsByIdQuery(message.id)
  );
  const [filter, setFilter] = useRecoilState(
    MessageStatisticsByIdFilter.Filter
  );
  const orderFunctions: OrderFunctions<PeopleMessageStatistics> = {
    status: (order: TableOrdering, data: PeopleMessageStatistics[]) =>
      orderBy(data, (item) => getStaticsLabel(item.status), [
        (order?.orderDirection?.toLowerCase() as 'asc' | 'desc') || 'asc',
      ]),
  };
  const {
    tooltipTextDelivered,
    tooltipTextOpened,
    tooltipTextClicked,
    tooltipTextBounced,
    tooltipTextUnsubscriptions,
  } = getTooltipString(message);

  const columns: ColumnType<PeopleMessageStatistics>[] = getColumns(setFilter);
  const isScheduled = message.scheduled;
  const isSent = message.sent;

  const invalidateMessageAndStatistics = useRecoilCallback(
    () => () => {
      setFilter({ type: 'all', search: null });
      refreshMessageStatistics();
      refetchMessage();
    },
    [refetchMessage, refreshMessageStatistics, setFilter]
  );

  useEffect(() => {
    if (defaultFilter) {
      setFilter((filter) => ({
        ...filter,
        type:
          defaultFilter === MessageStatisticsFilters.SENT
            ? MessageStatisticsFilters.DELIVERED
            : defaultFilter,
      }));
    }
  }, [defaultFilter, setFilter]);

  useEffect(
    () => () =>
      window.setTimeout(() => invalidateMessageAndStatistics(), 0) as any,
    [invalidateMessageAndStatistics]
  );

  const totalLength = statistics.total;
  const tableHeader =
    isSent &&
    gettextCatalog.getPlural(
      totalLength,
      '1 recipient',
      '{{count}} recipients',
      {
        count: totalLength,
      }
    );

  return (
    <>
      <Row gutter={16} style={{ marginBottom: '16px' }} justify="space-around">
        <Col span={4} style={{ textAlign: 'center' }}>
          <StatisticsDiagram
            title={gettextCatalog.getString('Delivered')}
            tooltipText={tooltipTextDelivered}
            total={message.statistics.sent}
            actual={message.statistics.delivered}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <StatisticsDiagram
            title={gettextCatalog.getString('Opened')}
            tooltipText={tooltipTextOpened}
            total={message.statistics.sent}
            actual={message.statistics.opened}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <StatisticsDiagram
            title={gettextCatalog.getString('Clicked')}
            tooltipText={tooltipTextClicked}
            total={message.statistics.sent}
            actual={message.statistics.clicked}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <StatisticsDiagram
            title={gettextCatalog.getString('Bounce')}
            tooltipText={tooltipTextBounced}
            total={message.statistics.sent}
            actual={message.statistics.bounced}
            status="exception"
          />
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <StatisticsDiagram
            title={gettextCatalog.getString('Unsubscribes')}
            tooltipText={tooltipTextUnsubscriptions}
            total={message.statistics.sent}
            actual={message.statistics.unsubscribed}
            status="exception"
          />
        </Col>
      </Row>
      <CdSimpleTable
        columns={columns}
        recoilSource={MessageStatisticsByIdFilter.Query(message.id)}
        orderFunctions={orderFunctions}
        headingText={tableHeader}
        emptyStateProps={{
          title: (() => {
            if (!isSent) {
              return gettextCatalog.getString(
                'The message is scheduled for sending at {{date}}',
                {
                  date: gettextCatalog.formatDate(
                    isScheduled as unknown as Date,
                    'LLL'
                  ),
                }
              );
            } else {
              if (!statistics) {
                return gettextCatalog.getString(
                  "You don't have access to view detailed message statistics"
                );
              } else {
                return gettextCatalog.getString('No results');
              }
            }
          })(),
          EmptyStateIcon: !isSent ? <CdSchedule /> : <CdUsers />,
        }}
        columnSelectorKey="MessageStatisticsByIdFilter"
        filterForm={
          <Row justify="end">
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Space>
                <Button
                  icon={<CdRefresh />}
                  onClick={invalidateMessageAndStatistics}
                >
                  {gettextCatalog.getString('Refetch statistics')}
                </Button>
                <CdFilterIcon />
                <Select<string>
                  style={{ width: 120 }}
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, ...{ type: value } }))
                  }
                  defaultValue={defaultFilter}
                  value={filter.type}
                >
                  <Option value="all">{gettextCatalog.getString('All')}</Option>
                  <Option value="delivered">
                    {gettextCatalog.getString('Delivered')}
                  </Option>
                  <Option value="bounced">
                    {gettextCatalog.getString('Bounced')}
                  </Option>
                  <Option value="failed">
                    {gettextCatalog.getString('Failed')}
                  </Option>
                  <Option value="opened">
                    {gettextCatalog.getString('Opened')}
                  </Option>
                  <Option value="clicked">
                    {gettextCatalog.getString('Clicked')}
                  </Option>
                </Select>
                <Search
                  placeholder={gettextCatalog.getString('Search for a person')}
                  allowClear
                  onSearch={(search) =>
                    setFilter((prev) => ({
                      ...prev,
                      ...{ search },
                    }))
                  }
                  enterButton
                  value={filter.search}
                  onChange={(search) =>
                    setFilter((prev) => ({
                      ...prev,
                      ...{ search: search.target.value },
                    }))
                  }
                />
              </Space>
            </Col>
          </Row>
        }
      />
    </>
  );
};

const getTooltipString = (message) => {
  const tooltipTextDelivered = gettextCatalog.getString(
    'Sent: {{sent}} / Delivered: {{delivered}}',
    {
      sent: message.statistics.sent,
      delivered: message.statistics.delivered,
    }
  );
  const tooltipTextOpened = gettextCatalog.getString('Opened: {{count}}', {
    count: message.statistics.opened,
  });
  const tooltipTextClicked = gettextCatalog.getString('Clicked: {{count}}', {
    count: message.statistics.clicked,
  });
  const tooltipTextBounced = gettextCatalog.getString('Bounced: {{count}}', {
    count: message.statistics.bounced,
  });
  const tooltipTextUnsubscriptions = gettextCatalog.getString(
    'Unsubscriptions: {{count}}',
    {
      count: message.statistics.unsubscribed,
    }
  );
  return {
    tooltipTextDelivered,
    tooltipTextOpened,
    tooltipTextClicked,
    tooltipTextBounced,
    tooltipTextUnsubscriptions,
  };
};

const getColumns: (
  setFilter: (p: { type: string; search: string }) => void
) => ColumnType<PeopleMessageStatistics>[] = (setFilter) => [
  {
    dataIndex: 'name',
    sorter: true,
    title: gettextCatalog.getString('Name'),
    active: true,
    defaultSortOrder: 'ascend',
    render: function renderType(name, row) {
      return (
        <Link
          href={getStateUrl('app.private.people.contacts.view', {
            id: row.personId,
          })}
        >
          {name}
        </Link>
      );
    },
  },
  {
    dataIndex: 'email',
    sorter: true,
    title: gettextCatalog.getString('Email'),
    active: true,
    defaultSortOrder: 'ascend',
    render: function renderType(email, row) {
      return (
        <Link
          href={getStateUrl('app.private.people.contacts.view', {
            id: row.personId,
          })}
        >
          {email}
        </Link>
      );
    },
  },
  {
    dataIndex: 'status',
    sorter: true,
    title: gettextCatalog.getString('Status'),
    active: true,
    orderType: 'status',
    render: function renderType(status, row) {
      return (
        <StatisticsStatus
          type={status}
          onClick={
            (status === 'DuplicateMessageError' &&
              (() => setFilter({ search: row.email, type: 'all' }))) ||
            undefined
          }
        />
      );
    },
  },
  {
    dataIndex: 'opened',
    sorter: true,
    title: gettextCatalog.getString('Opened'),
    active: true,
    render: function renderType(opened, row) {
      return (
        (opened > 0 && (
          <Tag color="success">{gettextCatalog.getString('Yes')}</Tag>
        )) ||
        (emailSent(row.status) && <Tag>{gettextCatalog.getString('No')}</Tag>)
      );
    },
  },
  {
    dataIndex: 'clicked',
    sorter: true,
    title: gettextCatalog.getString('Clicked'),
    active: true,
    render: function renderType(clicked, row) {
      return (
        (clicked > 0 && (
          <Tag color="success">{gettextCatalog.getString('Yes')}</Tag>
        )) ||
        (emailSent(row.status) && <Tag>{gettextCatalog.getString('No')}</Tag>)
      );
    },
  },
  {
    dataIndex: 'unsubscribed',
    sorter: true,
    title: gettextCatalog.getString('Unsubscribed'),
    active: true,
    render: function renderType(unsubscribed, row) {
      return (
        (unsubscribed && (
          <Tag color="error">{gettextCatalog.getString('Yes')}</Tag>
        )) ||
        (emailSent(row.status) && (
          <Tag color="success">{gettextCatalog.getString('No')}</Tag>
        ))
      );
    },
  },
];
