import React, { useCallback, useState } from 'react';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import {
  useTimeRegistration,
  useDayHoursSave,
  useStandardWorkingHours,
} from '../store/timeRegistrationHooks';

import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';

export const useTimeRegistrationDrawer = () => {
  const [timeRegistrationForm] = Form.useForm();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs>();

  const { timeRegistrationCalendarData } = useTimeRegistration({
    month: dayjs(selectedDate).month(),
    year: dayjs(selectedDate).year(),
  });

  const { standardWorkingHoursData, mutateStandardWorkingHours } =
    useStandardWorkingHours();

  const { mutateDayHours } = useDayHoursSave();

  const onHoursSaveHandler = useCallback(
    async (day: Dayjs) => {
      const date = dayjs(day).format('YYYY-MM-DD');
      await timeRegistrationForm
        .validateFields()
        .then(async (formValues) => {
          const { hours, comment, standardWorkingHours } = formValues;

          if (standardWorkingHours) {
            await mutateStandardWorkingHours(standardWorkingHours);
          } else {
            mutateDayHours({ hours, date, comment });
          }
          setHasChanges(false);
        })
        .catch(handleAntFormValidationErrors);
    },
    [mutateDayHours, mutateStandardWorkingHours, timeRegistrationForm]
  );

  const selectedDateInsight = React.useMemo(() => {
    const dateData = timeRegistrationCalendarData?.find((item) =>
      dayjs(item.date).isSame(selectedDate, 'day')
    ) || { booking: false, hours: 0 };

    return {
      hours: 0,
      ...dateData,
      date: dayjs(selectedDate),
    };
  }, [selectedDate, timeRegistrationCalendarData]);

  const initialValues = {
    hours: selectedDateInsight.hours || 0,
    standardWorkingHours: {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      ...standardWorkingHoursData,
    },
  };

  return {
    selectedDate,
    setSelectedDate,
    timeRegistrationCalendarData,
    timeRegistrationForm,
    initialValues,
    onHoursSaveHandler,
    selectedDateInsight,
    standardWorkingHoursData,
    hasChanges,
    setHasChanges,
  };
};
