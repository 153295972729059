/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { useSetRecoilState } from 'recoil';
import {
  get,
  isEmpty,
  isNil,
  startCase,
  includes,
  pick,
  countBy,
} from 'lodash';

import { paymentMethods } from '../../../../intention/shared/intentionCommon';
import { gettextCatalog } from '../../../../services/I18nService';
import {
  IntentionFilters,
  IntentionSourceType,
  IntentionStatusTypes,
} from '../../../../intention/models/intention';
import { FoundationTypes } from '../../../../intention/models/foundation';
import {
  IntentionSearchParamsAtomProps,
  IntentionSearchParamsProps,
} from '../../types/intention.type';
import { IntentionSearchParamsAtom } from '../../store/intentions';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdSearch } from '@/react/shared/components/Icons';
// Antd
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IntentionsFilters {
  // eslint-disable-next-line @typescript-eslint/ban-types
  churchIds?: number[];
}

const IntentionsFilters: FunctionComponent<IntentionsFilters> = ({
  churchIds,
}) => {
  const [numberType, setNumberType] = useState<string>(IntentionSourceType.ALL);
  const [numActiveMoreFilters, updateNumActiveMoreFilters] =
    useState<number>(0);

  const [intentionsFilterForm] = Form.useForm();
  const setIntentionFilterParam = useSetRecoilState(IntentionSearchParamsAtom);

  const paymentStatuses = [
    { id: 'paid', name: gettextCatalog.getString('Paid'), value: 1 },
    { id: 'unpaid', name: gettextCatalog.getString('Unpaid'), value: 0 },
  ];
  const [isShowMoreFilters, setIsShowMoreFilters] = useState(false);

  useEffect(() => {
    setIntentionFilterParam(
      (params) =>
        ({
          ...params,
          churchIds,
        }) as IntentionSearchParamsProps
    );
  }, [churchIds]);

  const search = useCallback(() => {
    const formValues = intentionsFilterForm.getFieldsValue();

    const filters: IntentionFilters = {
      churchIds: isEmpty(formValues.churchIds)
        ? undefined
        : formValues.churchIds,
      feeIds: isEmpty(formValues.feeIds) ? undefined : formValues.feeIds,
      paymentMethods: isEmpty(formValues.paymentMethods)
        ? undefined
        : formValues.paymentMethods,
      referenceNumberSourceType:
        get(formValues, 'referenceNumberSourceType') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberSourceType
          : undefined,
      referenceNumberFrom:
        get(formValues, 'referenceNumberFrom') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberFrom
          : undefined,
      referenceNumberTo:
        get(formValues, 'referenceNumberTo') &&
        formValues.referenceNumberSourceType !== IntentionSourceType.ALL
          ? formValues.referenceNumberTo
          : undefined,
      referenceNumberYear: get(formValues, 'referenceNumberYear')
        ? formValues.referenceNumberYear.year()
        : undefined,
      searchValue: get(formValues, 'searchValue'),
      deadlineDate: get(formValues, 'deadlineDate')
        ? formValues.deadlineDate.startOf('day').toDate()
        : undefined,
      acceptanceDateFrom: get(formValues, 'acceptanceDateRange[0]')
        ? formValues.acceptanceDateRange[0].startOf('day').toDate()
        : undefined,
      acceptanceDateTo: get(formValues, 'acceptanceDateRange[1]')
        ? formValues.acceptanceDateRange[1].endOf('day').toDate()
        : undefined,
      calendarDateFrom: get(formValues, 'calendarDateRange[0]')
        ? formValues.calendarDateRange[0].startOf('day').toDate()
        : undefined,
      calendarDateTo: get(formValues, 'calendarDateRange[1]')
        ? formValues.calendarDateRange[1].endOf('day').toDate()
        : undefined,
      completedMonth: get(formValues, 'completed')
        ? moment(formValues.completed).month()
        : undefined,
      completedYear: get(formValues, 'completed')
        ? moment(formValues.completed).year()
        : undefined,
      forwardingMonth: get(formValues, 'forwarding')
        ? moment(formValues.forwarding).month()
        : undefined,
      forwardingYear: get(formValues, 'forwarding')
        ? moment(formValues.forwarding).year()
        : undefined,
      paid: isNil(get(formValues, 'paymentStatus'))
        ? null
        : !!get(formValues, 'paymentStatus'),
    };

    // Manage intentions on event
    const params = {
      ...filters,
      statuses: [IntentionStatusTypes.UNASSIGNED],
      churchIds,
    };

    setIntentionFilterParam(
      (oldParams) =>
        ({
          ...oldParams,
          ...params,
        }) as IntentionSearchParamsAtomProps
    );
  }, []);

  const isNumberType = (numberTypes: IntentionSourceType[]) =>
    includes(numberTypes, numberType);

  const toggleMoreFilters = () => setIsShowMoreFilters(!isShowMoreFilters);

  const getNumActiveMoreFilters = () => {
    // Get more filters
    const moreFields = pick(intentionsFilterForm.getFieldsValue(), [
      'completed',
      'forwarding',
      'deadlineDate',
      'paymentMethods',
      'paymentStatus',
    ]);
    // Check if payment methods is empty because it is a multi select
    if (isEmpty(moreFields.paymentMethods)) {
      moreFields.paymentMethods = null;
    }
    const numActiveMoreFields = countBy(
      moreFields,
      (field) => !isNil(field) || !isEmpty(field)
    ).true;
    updateNumActiveMoreFilters(numActiveMoreFields);
  };

  return (
    <Form
      id="intentionFiltersForm"
      form={intentionsFilterForm}
      style={{
        padding: 12,
        background: '#fbfbfb',
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        width: '100%',
      }}
    >
      <Row
        style={{
          alignItems: 'center',
        }}
      >
        {/* Search */}
        <Col
          span={15}
          style={{
            padding: '0 8px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Form.Item name="searchValue" noStyle>
            <Input
              placeholder={gettextCatalog.getString(
                'Search for text, founder, prefered note, comment or number...'
              )}
              allowClear
            />
          </Form.Item>
        </Col>
        {/* Search */}
        <Col
          span={6}
          style={{
            padding: '0 8px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Space>
            <Button onClick={search} type="primary" icon={<CdSearch />}>
              {gettextCatalog.getString('Search')}
            </Button>
            {/* More/Less filters Button */}
            <Button
              type="default"
              onClick={toggleMoreFilters}
              style={
                numActiveMoreFilters > 0
                  ? { borderColor: '#1fa1c2', color: '#1fa1c2' }
                  : null
              }
            >
              <FontAwesomeIcon
                icon={isShowMoreFilters ? faAngleUp : faAngleDown}
                style={{ marginRight: 8 }}
              />
              {isShowMoreFilters
                ? gettextCatalog.getString('Less filters')
                : gettextCatalog.getString('More filters')}
              {numActiveMoreFilters > 0 ? ` · ${numActiveMoreFilters}` : null}
            </Button>
          </Space>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: 8,
          display: isShowMoreFilters ? 'flex' : 'none',
        }}
      >
        {/* Search on number */}
        <Col lg={9} xl={9} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            label={gettextCatalog.getString('Number')}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 'none' }}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              {/* Number Type */}
              <Form.Item
                name="referenceNumberSourceType"
                initialValue={IntentionSourceType.ALL}
                noStyle
              >
                <Select
                  placeholder={gettextCatalog.getString('Select type')}
                  onChange={(numberType) =>
                    setNumberType(numberType && numberType.toString())
                  }
                  style={{ width: 120 }}
                >
                  <Option value={IntentionSourceType.ALL}>
                    {gettextCatalog.getString('All')}
                  </Option>
                  <Option value={IntentionSourceType.INTENTIONEN}>
                    {gettextCatalog.getString('Intentions')}
                  </Option>
                  <Option value={IntentionSourceType.STIFTUNGEN}>
                    {startCase(FoundationTypes.STIFTUNGEN)}
                  </Option>
                  <Option value={IntentionSourceType.LEGATE}>
                    {startCase(FoundationTypes.LEGATE)}
                  </Option>
                </Select>
              </Form.Item>
              {/* Source letter ('S' or 'L') */}
              {isNumberType([
                IntentionSourceType.STIFTUNGEN,
                IntentionSourceType.LEGATE,
              ]) && (
                <Input
                  style={{
                    width: 35,
                    borderRight: 0,
                    pointerEvents: 'none',
                    background: '#fff',
                  }}
                  disabled
                  value={intentionsFilterForm.getFieldValue(
                    'referenceNumberSourceType'
                  )}
                />
              )}
              {/* Number from */}
              <Form.Item name="referenceNumberFrom" noStyle>
                {isNumberType([IntentionSourceType.ALL]) ? (
                  <CdTooltip
                    placement="topLeft"
                    title={gettextCatalog.getString(
                      'Select "Intentions", "Foundations" or "Legacies" to use this filter'
                    )}
                  >
                    <InputNumber
                      style={{ textAlign: 'center', flex: 1 }}
                      placeholder={gettextCatalog.getString('Min')}
                      disabled={true}
                    />
                  </CdTooltip>
                ) : (
                  <InputNumber
                    style={{ textAlign: 'center', flex: 1 }}
                    placeholder={gettextCatalog.getString('Min')}
                    disabled={false}
                  />
                )}
              </Form.Item>
              <Input
                style={{
                  width: 45,
                  borderRight: 0,
                  pointerEvents: 'none',
                  textAlign: 'center',
                }}
                placeholder={gettextCatalog.getString('to')}
                disabled
              />
              {/* Number to */}
              <Form.Item name="referenceNumberTo" noStyle>
                {isNumberType([IntentionSourceType.ALL]) ? (
                  <CdTooltip
                    placement="topLeft"
                    title={gettextCatalog.getString(
                      'Select "Intentions", "Foundations" or "Legacies" to use this filter'
                    )}
                  >
                    <InputNumber
                      style={{ textAlign: 'center', flex: 1 }}
                      placeholder={gettextCatalog.getString('Max')}
                      disabled={true}
                    />
                  </CdTooltip>
                ) : (
                  <InputNumber
                    style={{ textAlign: 'center', flex: 1 }}
                    placeholder={gettextCatalog.getString('Max')}
                    disabled={false}
                  />
                )}
              </Form.Item>
              {/* Year */}
              <Form.Item name="referenceNumberYear" noStyle>
                <DatePicker
                  placeholder={gettextCatalog.getString('Year')}
                  picker="year"
                  style={{ flex: 1 }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        {/* Acceptance date */}
        <Col lg={5} xl={5} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="acceptanceDateRange"
            label={gettextCatalog.getString('Acceptance Date')}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 'none' }}
          >
            <RangePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder={[
                gettextCatalog.getString('From'),
                gettextCatalog.getString('To'),
              ]}
            />
          </Form.Item>
        </Col>
        {/* Kalendar Date */}
        <Col lg={5} xl={5} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="calendarDateRange"
            label={gettextCatalog.getString('Desired date')}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 'none' }}
          >
            <RangePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder={[
                gettextCatalog.getString('From'),
                gettextCatalog.getString('To'),
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        style={{
          background: 'white',
          borderTop: '1px solid #d9d9d9',
          margin: '12px -12px -12px',
          padding: 12,
          display: isShowMoreFilters ? 'flex' : 'none',
        }}
      >
        {/* Abrechnung month */}
        <Col span={4} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="completed"
            label={gettextCatalog.getString('Completed')}
            labelCol={{ span: 24 }}
            style={{ minWidth: 0, maxWidth: 'none', paddingBottom: 24 }}
          >
            <DatePicker
              placeholder={gettextCatalog.getString('Month')}
              picker="month"
              style={{ width: '100%' }}
              onChange={getNumActiveMoreFilters}
            />
          </Form.Item>
        </Col>
        {/* Weiterleitung Month */}
        <Col span={4} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="forwarding"
            label={gettextCatalog.getString('Forwarded')}
            labelCol={{ span: 24 }}
            style={{ minWidth: 0, maxWidth: 'none' }}
          >
            <DatePicker
              placeholder={gettextCatalog.getString('Month')}
              picker="month"
              style={{ width: '100%' }}
              onChange={getNumActiveMoreFilters}
            />
          </Form.Item>
        </Col>
        {/* Deadline date */}
        <Col span={4} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="deadlineDate"
            label={gettextCatalog.getString('Deadline')}
            labelCol={{ span: 24 }}
            style={{ minWidth: 0, maxWidth: 'none' }}
          >
            <DatePicker
              placeholder={gettextCatalog.getString('Date')}
              style={{ width: '100%' }}
              onChange={getNumActiveMoreFilters}
            />
          </Form.Item>
        </Col>

        {/* Payment methods */}
        <Col span={5} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="paymentMethods"
            label={gettextCatalog.getString('Payment methods')}
            labelCol={{ span: 24 }}
            style={{ minWidth: 0, maxWidth: 'none' }}
          >
            <Select
              mode="multiple"
              placeholder={gettextCatalog.getString('Payment methods')}
              allowClear
              maxTagPlaceholder={(selectedItems) => {
                if (selectedItems.length > 1) {
                  return `${selectedItems.length} ${gettextCatalog.getString(
                    'Methods'
                  )}`;
                } else {
                  return `${selectedItems.length} ${gettextCatalog.getString(
                    'Method'
                  )}`;
                }
              }}
              maxTagCount={0}
              onChange={getNumActiveMoreFilters}
              style={{ width: '100%' }}
            >
              {(paymentMethods() || []).map((paymentMethod) => (
                <Option key={paymentMethod.id} value={paymentMethod.id}>
                  {get(paymentMethod, 'name')}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* Payment status */}
        <Col span={5} style={{ padding: '0 8px', height: '72px' }}>
          <Form.Item
            name="paymentStatus"
            label={gettextCatalog.getString('Payment status')}
            labelCol={{ span: 24 }}
            style={{ minWidth: 0, maxWidth: 'none' }}
          >
            <Select
              placeholder={gettextCatalog.getString('Payment status')}
              allowClear
              onChange={getNumActiveMoreFilters}
              style={{ width: '100%' }}
            >
              {(paymentStatuses || []).map((paymentStatus) => (
                <Option key={paymentStatus.id} value={paymentStatus.value}>
                  {get(paymentStatus, 'name')}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default IntentionsFilters;
