import { Button, Input, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdEditIcon } from '@/react/shared/components/Icons';

interface HeadingInputWithEmojiProps {
  onChange?: any;
  value?: string;
  placeholder?: string;
}

const Container = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding: 4px 38px;
  margin: 0;
`;

const HeadingSpace = styled(Space)`
  &&&& {
    justify-content: space-between;
    width: 100%;
  }
`;

const HeadingInputWithEmoji = ({
  onChange,
  value,
  placeholder = '',
}: HeadingInputWithEmojiProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <Container>
      {!editMode ? (
        <HeadingSpace>
          {isEmpty(value) ? (
            <span style={{ color: '#00000040' }}>{placeholder}</span>
          ) : (
            value
          )}
          <Button
            size="small"
            icon={<CdEditIcon />}
            onClick={() => {
              setEditMode(true);
            }}
          >
            {isEmpty(value)
              ? gettextCatalog.getString('Add')
              : gettextCatalog.getString('Edit')}
          </Button>
        </HeadingSpace>
      ) : (
        <Input
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          autoFocus
          onBlur={() => {
            setEditMode(false);
          }}
        />
      )}
    </Container>
  );
};

export default HeadingInputWithEmoji;
