(function () {
  'use strict';

  /**
   * @desc execute a function when the search query changes
   * @example <ui-select ui-select-on-search="$ctrl.someFunction"></ui-select>
   */ uiSelectOnSearch.$inject = ['$parse'];

  function uiSelectOnSearch($parse) {
    return {
      require: 'uiSelect',
      link: function (scope, element, attrs, $select) {
        scope.$select = $select;
        scope.$watch('$select.search', function (newVal, oldVal) {
          if (newVal !== oldVal) {
            let onSearch = $parse(attrs.uiSelectOnSearch);
            onSearch && onSearch();
            // scroll to the top of the ui-select-choices list
            element.find('ul.ui-select-choices').scrollTop(0);
          }
        });
      },
    };
  }

  angular
    .module('cdApp.shared')
    .directive('uiSelectOnSearch', uiSelectOnSearch);
})();
