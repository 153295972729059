'use strict';

import _ from 'lodash';
import moment from 'moment';

/**
 *
 * A component that shows a date selector inside a drop down. At the top we show suggested intervals.
 *
 * @prop {Date} from
 * @prop {Date} until
 * @prop {Function} onSelect({ from: date, until: date }) Callback for when the user selects a new suggestion or picks a new date.
 * @prop {Object[]} quickDateSuggestions The date suggestions.
 * @prop {string} quickDateSuggestions.key The identifier.
 * @prop {string} quickDateSuggestions.label The translated label shown to the user.
 * @prop {moment} quickDateSuggestions.from The interval should start from this date.
 * @prop {moment} quickDateSuggestions.until The interval should end at this date.
 */
class DateIntervalFilterComponent {
  constructor(gettextCatalog, Me, dateFormatsLookup) {
    'ngInject';

    this.gettextCatalog = gettextCatalog;
    this.Me = Me;
    this.dateFormatsLookup = dateFormatsLookup;
  }

  $onInit() {
    const { Me } = this;

    this.setSelectedDateSuggestion();

    /**
     * Set the datepicker format after Me endpoint is resolve
     */
    Me.finally(() => {
      // Configuration for the date pickers
      this.datepickers = {
        from: {
          opened: false,
        },

        until: {
          opened: false,
        },
        format: this.dateFormatsLookup.getFormat(),
      };
    });

    this.fromOptions = {
      maxDate: this.maxDate,
    };
    this.toOptions = {
      maxDate: this.maxDate,
    };
  }

  $onChanges({ from, until, quickDateSuggestions }) {
    if (from || until || quickDateSuggestions) {
      this.setSelectedDateSuggestion();
    }
  }

  /**
   * When the user toggles one of the date pickers.
   * @param {string} popup Which datepicker is being toggled
   */
  togglePopup(popup) {
    if (popup === 'from') {
      this.datepickers.from.opened = true;
      this.datepickers.until.opened = false;
    } else if (popup === 'until') {
      this.datepickers.until.opened = true;
      this.datepickers.from.opened = false;
    }
  }

  /**
   * Get an interval `{ from, until }` from the quick date suggestion
   * @param {string} option - The key of that quick date suggestion
   */
  getIntervalFromOption(option) {
    const selectedOption = _.find(this.quickDateSuggestions, { key: option });
    return {
      from: selectedOption.from.toDate(),
      until: selectedOption.until.toDate(),
    };
  }

  /**
   * Set the default selected value and interval based on the default option
   */
  setSelectedDateSuggestion() {
    this.selectedSuggestion = _.find(
      this.quickDateSuggestions,
      ({ from, until }) =>
        moment(from).isSame(this.from, 'day') &&
        moment(until).isSame(this.until, 'day')
    );
  }

  /**
   * When user clicks on one of the quick date suggestion
   * @param {Object} suggestion - The quick date suggestion
   */
  setIntervalFromSuggestion(suggestion) {
    if (this.selectedSuggestion === suggestion) return;

    this.selectedSuggestion = suggestion;
    this.onSelect({
      from: suggestion.from,
      until: suggestion.until,
    });
  }

  /**
   * When user changes the dates one of the date pickers
   */
  setIntervalFromDatepicker() {
    /**
     * If the user selectes a `from` date that's after the `until` day,
     * make sure that `until` is adjusted accordingly.
     */
    if (moment(this.from).isAfter(this.until)) {
      this.until = moment(this.from).add(1, 'week').toDate();

      // It should never exceed the max date
      if (this.maxDate && moment(this.until).isAfter(this.maxDate)) {
        this.until = moment(this.maxDate).toDate();
      }
    }

    this.selectedSuggestion = null;
    this.onSelect({
      from: this.from,
      until: this.until,
    });
  }

  /**
   * Returns the string rendered in the dropdown button
   */
  getDateIntervalString() {
    const { gettextCatalog } = this;

    if (this.selectedSuggestion) {
      return _.get(this.selectedSuggestion, 'label');
    }

    const from = moment(this.from).format('L');
    const until = moment(this.until).format('L');
    return `${from} ${gettextCatalog.getString(
      'to',
      {},
      'Date interval'
    )} ${until}`;
  }
}
DateIntervalFilterComponent.$inject = [
  'gettextCatalog',
  'Me',
  'dateFormatsLookup',
];

angular.module('cdApp.shared').component('cdDateIntervalFilter', {
  templateUrl:
    '@/app/shared/components/date-interval-filter/date-interval-filter.component.html',
  controller: DateIntervalFilterComponent,
  bindings: {
    from: '<',
    until: '<',
    onSelect: '&',
    maxDate: '<',
    quickDateSuggestions: '<',
  },
});
