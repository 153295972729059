import { ActionCreator, CdAction } from '../../../redux/utils';
import {
  IntentionFeeTypes,
  IntentionFee,
  IntentionFeeRecipient,
} from '../../models/fee';
import { IntentionFeesData } from '../../services/IntentionFeeService';

export type ClearIntentionFeeActionType = CdAction<void>;
export const CLEAR_INTENTION_FEE = 'intention/clearIntentionFee';
export const clearIntentionFee: ActionCreator<void> = () => ({
  type: CLEAR_INTENTION_FEE,
});

// Fetch multiple intention fees

interface FetchIntentionFeesPayload {
  type?: IntentionFeeTypes;
  activeOnly?: boolean;
}
export type FetchIntentionFeesActionType = CdAction<FetchIntentionFeesPayload>;
export const FETCH_INTENTION_FEES = 'intention/fetchIntentionFees';
export const fetchIntentionFees: ActionCreator<FetchIntentionFeesPayload> = (
  payload
) => ({
  type: FETCH_INTENTION_FEES,
  payload,
});

export type FetchIntentionFeesSuccessActionType = CdAction<IntentionFeesData>;
export const FETCH_INTENTION_FEES_SUCCESS =
  'intention/fetchIntentionFeesSuccess';
export const fetchIntentionFeesSuccess: ActionCreator<IntentionFeesData> = (
  payload
) => ({
  type: FETCH_INTENTION_FEES_SUCCESS,
  payload,
});

export type FetchStolesFeesSuccessActionType = CdAction<IntentionFeesData>;
export const FETCH_STOLES_FEES_SUCCESS = 'intention/fetchStolesFeesSuccess';
export const fetchStolesFeesSuccess: ActionCreator<IntentionFeesData> = (
  payload
) => ({
  type: FETCH_STOLES_FEES_SUCCESS,
  payload,
});

// Fetch intention fee

export type FetchIntentionFeeActionType = CdAction<string>;
export const FETCH_INTENTION_FEE = 'intention/fetchIntentionFee';
export const fetchIntentionFee: ActionCreator<string> = (payload) => ({
  type: FETCH_INTENTION_FEE,
  payload,
});

export type FetchIntentionFeeSuccessActionType = CdAction<IntentionFee>;
export const FETCH_INTENTION_FEE_SUCCESS = 'intention/fetchIntentionFeeSuccess';
export const fetchIntentionFeeSuccess: ActionCreator<IntentionFee> = (
  payload
) => ({
  type: FETCH_INTENTION_FEE_SUCCESS,
  payload,
});

export type FetchStolesFeeSuccessActionType = CdAction<IntentionFee>;
export const FETCH_STOLES_FEE_SUCCESS = 'intention/fetchStolesFeeSuccess';
export const fetchStolesFeeSuccess: ActionCreator<IntentionFee> = (
  payload
) => ({
  type: FETCH_STOLES_FEE_SUCCESS,
  payload,
});

// Create intention fee

export type CreateIntentionFeeActionType = CdAction<Partial<IntentionFee>>;
export const CREATE_INTENTION_FEE = 'intention/createIntentionFee';
export const createIntentionFee: ActionCreator<Partial<IntentionFee>> = (
  payload
) => ({
  type: CREATE_INTENTION_FEE,
  payload,
});

// Update intention fee

interface UpdateIntentionFeePayload {
  id: string;
  updatePayload: Partial<IntentionFee>;
}
export type UpdateIntentionFeeActionType = CdAction<UpdateIntentionFeePayload>;
export const UPDATE_INTENTION_FEE = 'intention/updateIntentionFee';
export const updateIntentionFee: ActionCreator<UpdateIntentionFeePayload> = (
  payload
) => ({
  type: UPDATE_INTENTION_FEE,
  payload,
});

// Delete intention fee

interface DeleteIntentionFeePayload extends FetchIntentionFeesPayload {
  id: string;
}
export type DeleteIntentionFeeActionType = CdAction<DeleteIntentionFeePayload>;
export const DELETE_INTENTION_FEE = 'intention/deleteIntentionFee';
export const deleteIntentionFee: ActionCreator<DeleteIntentionFeePayload> = (
  payload
) => ({
  type: DELETE_INTENTION_FEE,
  payload,
});

// Intention fee recipients
export interface FetchIntentionFeeRecipientsPayload {
  forwardingRecipientsOnly?: boolean;
  nonForwardingRecipientsOnly?: boolean;
}
export type FetchIntentionFeeRecipientsActionType =
  CdAction<FetchIntentionFeeRecipientsPayload>;
export const FETCH_INTENTION_FEE_RECIPIENTS =
  'intention/fetchIntentionFeeRecipients';
export const fetchIntentionFeeRecipients: ActionCreator<
  FetchIntentionFeeRecipientsPayload
> = (payload) => ({
  type: FETCH_INTENTION_FEE_RECIPIENTS,
  payload,
});

export type FetchIntentionFeeRecipientsSuccessActionType = CdAction<
  IntentionFeeRecipient[]
>;
export const FETCH_INTENTION_FEE_RECIPIENTS_SUCCESS =
  'intention/fetchIntentionFeeRecipientsSuccess';
export const fetchIntentionFeeRecipientsSuccess: ActionCreator<
  IntentionFeeRecipient[]
> = (payload) => ({
  type: FETCH_INTENTION_FEE_RECIPIENTS_SUCCESS,
  payload,
});

export interface CreateIntentionFeeRecipientPayload {
  name: string;
  isForwardingRecipient: boolean;
}
export type CreateIntentionFeeRecipientActionType =
  CdAction<CreateIntentionFeeRecipientPayload>;
export const CREATE_INTENTION_FEE_RECIPIENT =
  'intention/createIntentionFeeRecipient';
export const createIntentionFeeRecipient: ActionCreator<
  CreateIntentionFeeRecipientPayload
> = (payload) => ({
  type: CREATE_INTENTION_FEE_RECIPIENT,
  payload,
});

interface UpdateIntentionFeeRecipientPayload {
  id: string;
  updatePayload: Partial<IntentionFeeRecipient>;
}
export type UpdateIntentionFeeRecipientActionType =
  CdAction<UpdateIntentionFeeRecipientPayload>;
export const UPDATE_INTENTION_FEE_RECIPIENT =
  'intention/updateIntentionFeeRecipient';
export const updateIntentionFeeRecipient: ActionCreator<
  UpdateIntentionFeeRecipientPayload
> = (payload) => ({
  type: UPDATE_INTENTION_FEE_RECIPIENT,
  payload,
});

export type DeleteIntentionFeeRecipientActionType = CdAction<string>;
export const DELETE_INTENTION_FEE_RECIPIENT =
  'intention/deleteIntentionFeeRecipient';
export const deleteIntentionFeeRecipient: ActionCreator<string> = (id) => ({
  type: DELETE_INTENTION_FEE_RECIPIENT,
  payload: id,
});
