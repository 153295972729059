import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebounceCallback } from 'usehooks-ts';

import {
  SafeguardingListCache,
  SafeGuardingQuery,
  SafeguardingSearchParamAtom,
} from '../store/Safeguarding';

import { useSafeguardingList } from './useSafeguardingList';

import { navigate } from '@/react/services/StateServiceFactory';

export const useSafeguarding = () => {
  const { churches, OrganizationGroups, organizationPortals } =
    useSafeguardingList();
  const setSafeguardingSearchParam = useSetRecoilState(
    SafeguardingSearchParamAtom
  );

  const safeguarding = useRecoilValue(SafeGuardingQuery);
  const canEdit = safeguarding?.canEdit || false;
  const debouncedSetTable = useDebounceCallback(
    setSafeguardingSearchParam,
    1000
  );
  const onChangeHandler = (e) => {
    debouncedSetTable(e.target.value);
  };
  const filterHandler = (value: string) => {
    navigate('app.private.safeguarding.list', {
      filter: value,
    });
    setSafeguardingSearchParam('');
  };
  const refreshSafeguardingList = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(SafeguardingListCache, (value) => value + 1);
      },
    []
  );
  const statusFilterHandler = (value: string[]) => {
    navigate('app.private.safeguarding.list', {
      status: value,
    });
    setSafeguardingSearchParam('');
  };
  const roleHandler = (value: string[]) => {
    navigate('app.private.safeguarding.list', {
      userTypes: value,
    });
  };
  const churchIdsChangesHandler = (value: number[]) => {
    navigate('app.private.safeguarding.list', {
      churchIds: value,
    });
  };
  const groupIdsChangesHandler = (value: number[]) => {
    navigate('app.private.safeguarding.list', {
      groupIds: value,
    });
  };
  return {
    safeguarding,
    canEdit,
    onChangeHandler,
    setSafeguardingSearchParam,
    refreshSafeguardingList,
    filterHandler,
    statusFilterHandler,
    roleHandler,
    churchIdsChangesHandler,
    churches,
    OrganizationGroups,
    groupIdsChangesHandler,
    organizationPortals,
  };
};
