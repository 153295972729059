import { Button, Select, Space } from 'antd';
import {
  compact,
  concat,
  find,
  forEach,
  get,
  isEmpty,
  isNull,
  range,
  values,
} from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { RRule } from 'rrule';
import { $injector } from 'ngimport';

import { CdRRuleSummary } from './CdRRuleSummary';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdEditIcon } from '@/react/shared/components/Icons';
import { UibModal } from '@/react/calendar/services/EventPopupService';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

export const CdRepeatPicker = (props: {
  startDate?: Moment;
  rrule?: string;
  onChange?;
  eventId: number;
  previousRRule: string;
  disabled?: boolean;
}) => {
  let rRuleInstance: RRule;
  let excludedDates: string[];
  let includedDates: string[];
  const alreadyHadRRule = Boolean(props.eventId && props.previousRRule);

  if (props.rrule && props.rrule !== 'custom') {
    // We extract the correct parsable rule.
    const re = /RRULE:([^&]*)/;
    const parsableRrule = props.rrule.match(re);

    // Build the recurrence rule so we can parse it to human readable text.
    rRuleInstance = new RRule(RRule.parseString(parsableRrule[1]));

    // We extract excluded and included dates, if any.
    const excludedPart = props.rrule.match(/EXDATE:([^&]*)/);
    const includedPart = props.rrule.match(/RDATE:([^&]*)/);

    excludedDates = !isNull(excludedPart) ? excludedPart[1].split(',') : [];
    includedDates = !isNull(includedPart) ? includedPart[1].split(',') : [];
  }
  const { repeatDropdownOptions } = generateRRuleOptions(
    props.startDate?.clone(),
    rRuleInstance
  );

  /**
   * Finds out what should be selected in the dropdown.
   * By comparing the current rrule instance (rRuleInstance) with each
   * generated rule from the dropdown options.
   */
  const getSelectedRRuleOption = () => {
    const doesntRepeat = find(repeatDropdownOptions, {
      value: 'never',
    });

    const customOption = find(repeatDropdownOptions, {
      value: 'custom',
    });

    if (!props.rrule) {
      return doesntRepeat.value;
    }
    const hasExcludedOrIncludedDates = !isEmpty(
      compact(concat(excludedDates, includedDates))
    );

    if (hasExcludedOrIncludedDates) {
      return customOption.value;
    }
    return (
      find(repeatDropdownOptions, (option) => {
        if (!option.rrule || !rRuleInstance) return false;
        return (
          option.rrule && option.rrule.toString() === rRuleInstance.toString()
        );
      }) || customOption
    ).value;
  };

  const selectRRule = (value, exdate, rdate) => {
    props.onChange(value + exdate + rdate);
  };

  const openRepeatModal = () => {
    const $uibModal = $injector.get('$uibModal') as UibModal;
    $uibModal.open({
      windowClass: 'repeat-modal modal-ui-select',
      backdrop: 'static',
      keyboard: false,
      component: 'repeatRuleModalComponent',
      size: 'lg',
      resolve: {
        rrule: () => rRuleInstance,
        startDate: () => `"${props.startDate.toISOString()}"`,
        excludedDates: () => excludedDates,
        includedDates: () => includedDates,
        selectRrule: () => selectRRule,
        // setSelectedRRuleOption: Not needed, this was previously used in Angular to update the interface.
        setSelectedRRuleOption: () => () => {},
        eventId: () => props.eventId,
        hasRrule: () => props.previousRRule,
      },
    });
  };

  const onChange = (value) => {
    let newValue;
    if (value === 'custom') {
      openRepeatModal();
      newValue = 'custom';
    } else {
      newValue = find(repeatDropdownOptions, {
        value,
      }).rrule?.toString();
    }
    props.onChange(newValue);
  };

  return alreadyHadRRule ? (
    <Button onClick={openRepeatModal}>
      {gettextCatalog.getString('View repeat rule')}
    </Button>
  ) : (
    <Space direction="horizontal">
      <CdTooltip
        title={
          alreadyHadRRule &&
          gettextCatalog.getString(
            'You cannot modify the repeat rule for an existing event series. To exclude events, go to the specific event and delete it. For adding events, either create a new single event or a new event series.'
          )
        }
      >
        <Select
          disabled={alreadyHadRRule || props.disabled}
          options={repeatDropdownOptions}
          style={{ width: '184px' }}
          value={getSelectedRRuleOption()}
          onChange={onChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
      </CdTooltip>
      {!alreadyHadRRule &&
        getSelectedRRuleOption() &&
        getSelectedRRuleOption() === 'custom' && (
          <Button type="link" icon={<CdEditIcon />} onClick={openRepeatModal}>
            {gettextCatalog.getString('Edit')}
          </Button>
        )}
    </Space>
  );
};

/**
 * Generate a list of possible values for the dropdown.
 *
 * StartDate is needed to provide options such as "Weekly on Wednesdays"
 * if today is a Wednesday.
 *
 * @param {Moment} startDate current start date selected in the form.
 * @param {RRule} rRuleInstance current rrule instance based on the the current rrule.
 * @returns
 */
const generateRRuleOptions = (startDate: Moment, rRuleInstance: RRule) => {
  const startDateStartOfDay = startDate?.startOf('day');

  // Get week day based on event's start date
  const weekdays = moment.weekdays(); // ['Sunday', 'Monday', 'Tuesday', ...]
  const weekdayIndex = startDateStartOfDay?.day();
  const weekday = weekdays[weekdayIndex];
  const { ordinalString, ordinal } = getOrdinalString(startDateStartOfDay);

  // Weekday based on the start date of the event
  const rruleWeekdays = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ];

  const repeatRules = {
    daily: new RRule({
      freq: RRule.DAILY,
      interval: 1,
    }),

    weekly: new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: [rruleWeekdays[weekdayIndex]],
    }),

    monthly: new RRule({
      freq: RRule.MONTHLY,
      interval: 1,
      byweekday: [rruleWeekdays[weekdayIndex].nth(ordinal)],
    }),

    yearly: new RRule({
      freq: RRule.YEARLY,
      interval: 1,
    }),
  };

  const repeatDropdownOptions: {
    value: string;
    label: string | JSX.Element;
    rrule: any;
  }[] = [
    {
      value: 'never',
      label: gettextCatalog.getString("Doesn't repeat"),
      rrule: null,
    },
    {
      value: 'daily',
      label: gettextCatalog.getString('Daily'),
      rrule: repeatRules.daily,
    },
    {
      value: 'weekly',
      label: gettextCatalog.getString('Weekly on {{weekday}}', {
        weekday,
      }),
      rrule: repeatRules.weekly,
    },
    {
      value: 'monthly',
      label: gettextCatalog.getString(
        'Monthly on the {{ordinal}} {{weekday}}',
        {
          ordinal: ordinalString,
          weekday,
        }
      ),
      rrule: repeatRules.monthly,
    },
    {
      value: 'yearly',
      label: gettextCatalog.getString('Yearly on {{date}}', {
        date: moment(startDateStartOfDay).format('LL'),
      }),
      rrule: repeatRules.yearly,
    },
  ];

  /**
   * Add the currently selected custom rule as an option in the dropdown
   */
  if (rRuleInstance) {
    const isPredefinedRule = find(
      values(repeatRules),
      (repeatRule) => repeatRule.toString() === rRuleInstance.toString()
    );

    if (!isPredefinedRule) {
      repeatDropdownOptions.push({
        rrule: rRuleInstance,
        label: (
          <CdRRuleSummary
            startDate={startDate}
            rrule={rRuleInstance.toString()}
          />
        ),
        value: 'selectedCustom',
      });
    }
  }

  repeatDropdownOptions.push({
    value: 'custom',
    label: gettextCatalog.getString('Custom...'),
    rrule: null,
  });
  return { repeatDropdownOptions };
};

const getOrdinalString = (startDate: moment.Moment) => {
  // Get day of month based on event's start date
  const startOfMonth = moment(startDate).startOf('month');
  const daysInMonth = startOfMonth.daysInMonth();

  /**
   * Get which weekday is it: 1st, 2nd, 3rd, 4th, 5th
   */
  let ordinal = 1;
  let weekdaysCount = 0;
  forEach(range(daysInMonth), (dayInMonthIndex) => {
    const weekDay = moment(startOfMonth).add(dayInMonthIndex, 'day');
    if (weekDay?.weekday() === startDate?.weekday()) {
      weekdaysCount += 1;
      if (weekDay.isBefore(startDate)) {
        ordinal += 1;
      }
    }
  });

  let ordinalString = get(
    [
      gettextCatalog.getString('1st'),
      gettextCatalog.getString('2nd'),
      gettextCatalog.getString('3rd'),
      gettextCatalog.getString('4th'),
      gettextCatalog.getString('5th'),
    ],

    ordinal - 1
  );

  // Find out if it's the last weekday of the month (last Friday, etc..)
  if (ordinal === weekdaysCount) {
    ordinal = -1;
    // eslint-disable-next-line spaced-comment
    /// Monthly on the "last" Friday
    ordinalString = gettextCatalog.getString('last', null, 'weekday ordinal');
  }

  return { ordinal, ordinalString };
};
