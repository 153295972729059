import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import ElementWrapper from './ElementWrapper';

const Author: React.FC<{ date: string }> = ({ date }) => {
  const formatDate = (date) => {
    const m = moment(date, moment.ISO_8601);
    return m.format('lll');
  };
  return (
    <ElementWrapper>
      <FontAwesomeIcon icon={faClock} />
      <span>{formatDate(date)}</span>
    </ElementWrapper>
  );
};

export default Author;
