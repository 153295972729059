'use strict';

class AttendanceGetStartedModal {
  constructor(
    $q,
    $filter,
    Analytics,
    Diocese,
    Attendance,
    Taxonomies,
    toastr,
    gettextCatalog,
    uiSelectAllowNewMarker,
    cdApp,
    _,
    BeaconChat
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.Analytics = Analytics;
    this.Diocese = Diocese;
    this.Attendance = Attendance;
    this.Taxonomies = Taxonomies;
    this.cdApp = cdApp;
    this._ = _;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.BeaconChat = BeaconChat;

    this.uiSelectAllowNewMarker = uiSelectAllowNewMarker;
    this.dioceseId = null;
    this.categoryIds = [];
    this.importFromSognDk = cdApp.organization.countryIso2 === 'dk';

    this.currentStep = 1;
    this.totalSteps = cdApp.organization.countryIso2 === 'dk' ? 3 : 2;
    this.loading = false;

    $q.all([
      Diocese.query({ country: cdApp.organization.countryIso2 }).$promise,
      Taxonomies.query().$promise,
    ]).then((results) => {
      const dioceses = results[0];
      const taxonomies = results[1];

      this.dioceses = _.concat(dioceses, [
        {
          id: -1,
          name: gettextCatalog.getString(
            'Other diocese / I am an independent church'
          ),

          isLast: true,
        },
      ]);

      this.categories = _.filter(taxonomies, { type: 'event' });
      this.categoryIds = _(this.categories)
        .filter('registerAttendance')
        .map('id')
        .value();
    });
  }

  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    this.currentStep--;
  }

  savePreferences() {
    this.loading = true;

    const organizationId = this.cdApp.organization.id;
    const dioceseId = this.dioceseId === -1 ? null : this.dioceseId;
    const promises = [
      this.Taxonomies.trackAttendance(null, { ids: this.categoryIds }).$promise,
    ];

    if (dioceseId) {
      promises.push(
        this.Diocese.associateWithOrganization(
          { organizationId },
          { dioceseId }
        ).$promise
      );
    } else {
      promises.push(this.Attendance.getStarted().$promise);
    }

    this.$q
      .all(promises)
      .then(() => {
        this.Analytics.sendFeatureEvent('get started with attendance', {
          module: 'attendance',
        });

        return this.importFromSognDk
          ? this.Attendance.importFromSognDk().$promise
          : this.$q.resolve();
      })
      .then((res) => {
        if (this.importFromSognDk) {
          this.toastr.success(
            this.gettextCatalog.getString(
              'You will receive an e-mail confirmation when all data has been imported.'
            ),

            this.gettextCatalog.getString(
              'Importing {{ totalEvents }} events...',
              {
                totalEvents: this.$filter('number')(res.totalEvents),
              }
            ),

            {
              timeOut: 10000,
            }
          );

          this.Analytics.sendFeatureEvent('import attendance from sogn.dk', {
            module: 'attendance',
          });
        }

        this.loading = false;
        this.close();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  onCategorySelect(category) {
    if (category.id || !category.name) return;

    const newCategory = new this.Taxonomies({
      type: 'event',
      name: category.name,
      color: 0,
    });

    newCategory.$save(({ id }) => {
      category.name = '';
      const savedCategory = this._.extend({ id }, newCategory);
      this.categories.push(savedCategory);
      this.categoryIds = this._.compact(this.categoryIds) || [];
      this.categoryIds.push(id);
    });
  }

  openBeaconChat() {
    this.BeaconChat.showNewMessage();
  }
}
AttendanceGetStartedModal.$inject = [
  '$q',
  '$filter',
  'Analytics',
  'Diocese',
  'Attendance',
  'Taxonomies',
  'toastr',
  'gettextCatalog',
  'uiSelectAllowNewMarker',
  'cdApp',
  '_',
  'BeaconChat',
];

angular.module('cdApp.calendar').component('cdAttendanceGetStartedModal', {
  templateUrl:
    '@/app/calendar/attendance/components/get-started-modal/get-started-modal.component.html',
  controller: AttendanceGetStartedModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
