import { Group, Permissions, UploadValidator } from '../../services/lookups';

import {
  LOAD_FILE_VALIDATOR_SUCCESS,
  LOAD_GROUPS_SUCCESS,
  LOAD_PERMISSIONS_SUCCESS,
  LOAD_LOOKUPS,
} from './constants';

export const loadLookups = () => ({ type: LOAD_LOOKUPS });

export const loadGroupsSuccess = (groups: Group[]) => ({
  type: LOAD_GROUPS_SUCCESS,
  payload: groups,
});

export const loadPermissionsSuccess = (permissions: Permissions) => ({
  type: LOAD_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const loadFileValidatorSuccess = (uploadValidator: UploadValidator) => ({
  type: LOAD_FILE_VALIDATOR_SUCCESS,
  payload: uploadValidator,
});
