import React, { useCallback, useEffect, useRef, useState } from 'react';

export const IframeMailViewer = ({ minHeight, value }) => {
  const [iframeHeight, setIframeHeight] = useState('748px');
  const desktopIframeRef = useRef();

  const onMessage = useCallback(
    (event) => {
      if (event.data.func === 'setHeight') {
        if (iframeHeight !== event.data.message + 'px') {
          setIframeHeight(event.data.message + 'px');
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [iframeHeight]
  );

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage, false);
  }, [onMessage]);

  return (
    <iframe
      ref={desktopIframeRef}
      width="100%"
      style={{ border: 0, minHeight }}
      height={iframeHeight}
      scrolling="no"
      srcDoc={
        value?.replace('</html>', '').replace('</body>', '') +
        iframeHeightCalculator +
        '</body></html>'
      }
      sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
    />
  );
};

// This is allowed by the CSP configuration using the following hash: 'sha256-JjhGlcb9CiEHBvwHypnKXNTbUM1Iw3/dwAuIsjkALPM='
const iframeHeightCalculator = `
<script>
  function postHeight() {
    window.parent.postMessage({
      'func': 'setHeight',
      'message': Math.max(document.body.scrollHeight, window.innerHeight)
    }, "*");
  }
  // Update the size if the window changes size
  window.addEventListener('resize', (event) => {
    postHeight();
  });
  // Notify height on initial load
  window.addEventListener('DOMContentLoaded', (event) => {
    postHeight();
  });
  // Notify height when resources has finished loading
  window.addEventListener('load', (event) => {
    postHeight();
  });
</script>`;
