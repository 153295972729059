import React, { FunctionComponent } from 'react';

import { CollectionYearList } from '../components/collection';

import CreateLegat from './legate/CreateLegat';
import CreateStiftung from './stiftungen/CreateStiftung';
import CreateIntention from './intentions/CreateIntention';
import AssignIntention from './intentions/AssignIntention';
import ManageIntentions from './intentions/ManageIntentions';
import IntentionsBilling from './intentions/IntentionsBilling';
import Stoles from './stoles/Stoles';
import StolesBilling from './stoles/StolesBilling';
import Foundations from './foundations/Foundations';

export { CollectionYearList as Collection };

export const FoundationsRoot: FunctionComponent<null> = () => <Foundations />;

export const CreateIntentionRoot: FunctionComponent<null> = () => (
  <CreateIntention />
);

export const AssignIntentionRoot: FunctionComponent<null> = () => (
  <AssignIntention />
);

export const ManageIntentionsRoot: FunctionComponent = () => (
  <ManageIntentions />
);

export const CreateLegatRoot: FunctionComponent<null> = () => <CreateLegat />;

export const CreateStiftungRoot: FunctionComponent<null> = () => (
  <CreateStiftung />
);

export const IntentionsBillingRoot: FunctionComponent<null> = () => (
  <IntentionsBilling />
);

export const StolesRoot: FunctionComponent<null> = () => <Stoles />;

export const StolesBillingRoot: FunctionComponent<null> = () => (
  <StolesBilling />
);
