'use strict';

function PeopleMessageInsertLinkController($http) {
  let $ctrl = this;

  $ctrl.$onInit = function () {
    // If editing a link
    $ctrl.text = _.get($ctrl.resolve, 'link.text');
    $ctrl.url = _.get($ctrl.resolve, 'link.url');

    $ctrl.isInternalUrl = /kw01.net|churchdesk.com/.test($ctrl.url) || false;

    $ctrl.onSelect = function ($item) {
      $ctrl.text = $ctrl.text ? $ctrl.text : $item.title;
      $ctrl.url = $item.url;
      $ctrl.isInternalUrl = /kw01.net|churchdesk.com/.test($item.url);
    };

    // Query the linkit endpoint
    $ctrl.fetch = function (query) {
      $http
        .get(cdApp.config.api.main + '/linkit', {
          params: {
            search: query || undefined,
          },
        })
        .then(function (response) {
          // the response names 'news' as 'blogs, we need to fix that
          response.data.news = response.data.blogs;
          delete response.data.blogs;

          if (_.get(response, 'data.group') === 'No results') {
            $ctrl.choices = [];
          } else {
            $ctrl.choices = _.mapValues(response.data, function (list, key) {
              // make sure we're iterating over an array, and not a string in case backend returns the string "no results find"
              if (_.isArray(list)) {
                _.each(list, function (item) {
                  item.group = key;
                });
              }
              return list;
            });
            $ctrl.choices = _.flatten(_.values($ctrl.choices));
          }
        });
    };
  };

  $ctrl.confirm = function () {
    $ctrl.close({
      $value: {
        text: $ctrl.text,
        url: $ctrl.url,
      },
    });
  };
}
PeopleMessageInsertLinkController.$inject = ['$http'];
angular.module('cdApp.people').component('cdPeopleMessageInsertLinkModal', {
  templateUrl:
    '@/app/people/message-editor/modals/insert-link/insert-link.component.html',
  controller: PeopleMessageInsertLinkController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
