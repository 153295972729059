import React, { useCallback } from 'react';
import { Comment } from '@ant-design/compatible';
import { Button, List, Modal, Input, Divider } from 'antd';
import styled from 'styled-components';
import { compact } from 'lodash';
import moment from 'moment';

import { useCommentList } from '../../hooks/use-comment-list';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdDeleteIcon, CdEditIcon } from '@/react/shared/components/Icons';

const { TextArea } = Input;

const ContentDiv = styled.div`
  white-space: pre-wrap;
`;

const AuthorDiv = styled.div`
  color: #3d8bb2;
`;

const CommentList = ({ eventId }: { eventId: number }) => {
  const {
    editedCommentBody,
    isEditModalOpen,
    onEditHandler,
    onCancelHandler,
    onDeleteHandler,
    textChangeHandler,
    comments,
    updateComment,
  } = useCommentList({ eventId });

  const getActions = useCallback(
    ({ canEdit, canDelete, itemProps }) => {
      const editButton = canEdit && (
        <Button
          aria-label="edit"
          onClick={() => onEditHandler(itemProps)}
          size="small"
          type="text"
        >
          <CdEditIcon />
        </Button>
      );
      const deleteButton = canDelete && (
        <Button
          aria-label="delete"
          onClick={() => onDeleteHandler(itemProps.id)}
          size="small"
          type="text"
        >
          <CdDeleteIcon />
        </Button>
      );

      return compact([editButton, deleteButton]);
    },
    [onDeleteHandler, onEditHandler]
  );

  return (
    <>
      {comments?.length ? (
        <List
          dataSource={comments}
          itemLayout="horizontal"
          renderItem={(itemProps) => {
            const { canDelete, canEdit, ...restItemProps } = itemProps;
            return (
              <Comment
                actions={getActions({
                  canEdit,
                  canDelete,
                  itemProps: restItemProps,
                })}
                content={
                  <ContentDiv>
                    <div>{restItemProps.body}</div>
                  </ContentDiv>
                }
                author={<AuthorDiv>{restItemProps.name}</AuthorDiv>}
                avatar={restItemProps.picture}
                datetime={moment(restItemProps.updatedAt).fromNow()}
              />
            );
          }}
        />
      ) : undefined}
      {comments.length ? <Divider /> : undefined}
      <Modal
        title={gettextCatalog.getString('Edit comment')}
        open={isEditModalOpen}
        onOk={() => updateComment(editedCommentBody)}
        okText={gettextCatalog.getString('Save')}
        onCancel={onCancelHandler}
        cancelButtonProps={{ type: 'text' }}
      >
        <TextArea
          rows={4}
          onChange={textChangeHandler}
          value={editedCommentBody}
        />
      </Modal>
    </>
  );
};
export default CommentList;
