import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { AppState } from '../../redux';
import { gettextCatalog } from '../../services/I18nService';
import {
  ToggleShowNationalHolidays,
  ToggleShowLiturgicalHolidays,
} from '../store/main-view/Actions';

import { FilterListItem } from './FilterItem';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

/**
 * TODO: This style is repeated in `src/React/Calendar/components/CalendarItem.tsx`!
 * Style definitions should NOT be mixed with structural components!!!
 */

export const getClassName = (isSelected: boolean, color: number): string =>
  `bullet color-${color || 0} ${isSelected ? 'bullet-full' : 'bullet-empty'}`;

const HolidaysFilter: FunctionComponent<{ isDisabled: boolean }> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const onToggleNationalHolidays = useCallback(
    () => dispatch(ToggleShowNationalHolidays()),
    [dispatch]
  );
  const onToggleLiturgicalHolidays = useCallback(
    () => dispatch(ToggleShowLiturgicalHolidays()),
    [dispatch]
  );
  const liturgicalHolidays = useSelector(
    (state: AppState) => state.calendar.view.liturgicalHolidays
  );
  const nationalHolidays = useSelector(
    (state: AppState) => state.calendar.view.nationalHolidays
  );
  const areNationalHolidaysSelected = useSelector(
    (state: AppState) => state.calendar.view.showNationalHolidays
  );
  const areLiturgicalHolidaysSelected = useSelector(
    (state: AppState) => state.calendar.view.showLiturgicalHolidays
  );

  const content = (
    <div>
      {/* Liturgical Calendar */}
      {!_.isEmpty(liturgicalHolidays) ? (
        <FilterListItem
          isDisabled={isDisabled}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <div className="bullet-wrapper">
            <div
              className="bullet-label"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (isDisabled) return;
                onToggleLiturgicalHolidays();
              }}
            >
              <span
                className={getClassName(areLiturgicalHolidaysSelected, 998)}
              />
              <div className="label">
                <span className="name">
                  {gettextCatalog.getString('Liturgical calendar')}
                </span>
              </div>
            </div>
          </div>
        </FilterListItem>
      ) : null}
      {/* National Holidays */}
      {!_.isEmpty(nationalHolidays) ? (
        <FilterListItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          isDisabled={isDisabled}
        >
          <div className="bullet-wrapper">
            <div
              className="bullet-label"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (isDisabled) return;
                onToggleNationalHolidays();
              }}
            >
              <span
                className={getClassName(areNationalHolidaysSelected, 999)}
              />
              <div className="label">
                <span className="name">
                  {gettextCatalog.getString('National holidays')}
                </span>
              </div>
            </div>
          </div>
        </FilterListItem>
      ) : null}
    </div>
  );
  if (isDisabled) {
    return (
      <CdTooltip
        title={gettextCatalog.getString(
          'This calendar cannot be enabled for this view'
        )}
      >
        {content}
      </CdTooltip>
    );
  }
  return content;
};

export default HolidaysFilter;
