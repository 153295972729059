import { ActionCreator, CdAction } from '../utils';

export const STARTUP = 'STARTUP';

export type StartupAction = CdAction<undefined>;

export const StartupActionCreator: ActionCreator<undefined> = () => ({
  type: STARTUP,
});

export const INITIAL_STATE_LOADED = 'startup/initialStateLoaded';

export type InitialStateLoaded = CdAction<undefined>;

export const InitialStateLoaded: ActionCreator<undefined> = () => ({
  type: INITIAL_STATE_LOADED,
});
