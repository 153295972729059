import React from 'react';
import { Button, Space } from 'antd';

import { NextIcon, PrevIcon } from '../Icons';

export const UnPagedTablePagination = ({
  onChange,
  total,
  pageSize,
  pageNumber,
}: {
  onChange: (type: number | string) => void;
  total: number;
  pageSize: number;
  pageNumber: number;
}) => {
  const handlePrev = () => {
    onChange('prev');
  };
  const handleNext = () => {
    onChange('next');
  };
  return (
    <div
      style={{
        width: '100%',
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Space direction="horizontal">
        <Button size="large" disabled={pageNumber === 1} onClick={handlePrev}>
          <PrevIcon size="lg" />
        </Button>
        <Button size="large" disabled={total < pageSize} onClick={handleNext}>
          <NextIcon size="lg" />
        </Button>
      </Space>
    </div>
  );
};
