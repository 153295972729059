import React, { useEffect } from 'react';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { UserUploadProgress } from '@/react/safeguarding/safeguarding/UserUploadProgress';
import { useBulkImportSafeguardingUsers } from '@/react/safeguarding/hooks/useBulkImportSafeguardingUsers';
import { gettextCatalog } from '@/react/services/I18nService';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const showUserUploadProgressModal = createCdModal<{
  toUploadRows: any[];
}>(({ setModalProps, toUploadRows }) => {
  const { processing, queue } = useBulkImportSafeguardingUsers({
    setModalProps,
    rows: toUploadRows,
  });

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Uploading Safeguarding Users'),
      width: '100vw',
      maxBodyHeight: 76,
      hideOk: true,
      cancelText: processing
        ? gettextCatalog.getString('Stop import')
        : gettextCatalog.getString('Close'),
      onCancel: processing
        ? () =>
            showConfirmModal({
              title: gettextCatalog.getString('Close Import'),
              message: gettextCatalog.getString(
                'Are you sure you want to close this?'
              ),
              okText: gettextCatalog.getString('Stop Import'),
            }).then(({ resolved }) => {
              if (!resolved) return { preventClose: true };
            })
        : undefined,
    });
  }, [processing, setModalProps]);

  return <UserUploadProgress processing={processing} queue={queue} />;
});
