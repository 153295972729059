(function () {
  'use strict';

  /**
   * @desc strip html tags from the model value
   * @param {Bool} to strip or not to strip tags
   *
   * @example <textarea ng-model="message.content" cd-strip-tags></textarea>
   */

  function stripTags() {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
      },

      link: function (scope, elem, attrs) {
        let currValue = scope.ngModel || '';
        let newValue;

        if (attrs.cdStripTags) {
          newValue = currValue.replace(/<\S[^><]*>/g, '');
          scope.ngModel = newValue;

          _.defer(function () {
            scope.$apply();
          });
        }
      },
    };
  }

  angular.module('cdApp.shared').directive('cdStripTags', stripTags);
})();
