import { selector } from 'recoil';

import { mainApi } from '@/react/api';
import { AttendanceField } from '@/react/calendar/models/calendar';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetAttendanceFields = selector({
  key: 'GetAttendanceFields',
  get: async () => {
    const res = await mainApi.get<AttendanceField[]>('/attendance/fields');
    if (res.ok) {
      return res.data;
    } else {
      handleError(res);
      throw res;
    }
  },
});
