import React, { useEffect } from 'react';

import cdApp from '@/react/config';

export const SsoMobilePage = ({ $stateParams }) => {
  const { provider, env } = $stateParams;

  useEffect(() => {
    localStorage.setItem('CD_MOBILE_SSO', `true`);
    window.location.assign(
      `${cdApp.config.api.main}/login/sso?provider=${provider}&env=${env}`
    );
  }, [provider, env]);
  return <div>Redirecting...</div>;
};
