import angular from 'angular';

angular.module('cdApp.intranet').run([
  '$templateCache',
  ($templateCache) => {
    $templateCache.put(
      '@/app/intranet/blog/templates/Blog.html',
      require('./blog/templates/Blog.html')
    );

    $templateCache.put(
      '@/app/intranet/blog/templates/List.html',
      require('./blog/templates/List.html')
    );

    $templateCache.put(
      '@/app/intranet/blog/templates/Revisions.html',
      require('./blog/templates/Revisions.html')
    );

    $templateCache.put(
      '@/app/intranet/churchscreen/templates/ChurchScreen.html',
      require('./churchscreen/templates/ChurchScreen.html')
    );

    $templateCache.put(
      '@/app/intranet/files/files.component.html',
      require('./files/files.component.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalDeleteItem.html',
      require('./files/templates/modals/ModalDeleteItem.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalDeleteMultipleFiles.html',
      require('./files/templates/modals/ModalDeleteMultipleFiles.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalDocumentDownload.html',
      require('./files/templates/modals/ModalDocumentDownload.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalDocumentShare.html',
      require('./files/templates/modals/ModalDocumentShare.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalDocumentViewer.html',
      require('./files/templates/modals/ModalDocumentViewer.html')
    );

    $templateCache.put(
      '@/app/intranet/files/templates/modals/ModalEditFormModal.html',
      require('./files/templates/modals/ModalEditFormModal.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Activity.html',
      require('./group/templates/Activity.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Delete.html',
      require('./group/templates/Delete.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Main.html',
      require('./group/templates/Main.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Members.html',
      require('./group/templates/Members.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Message.html',
      require('./group/templates/Message.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/Messages.html',
      require('./group/templates/Messages.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/modals/DeleteMessage.html',
      require('./group/templates/modals/DeleteMessage.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/modals/EditMessage.html',
      require('./group/templates/modals/EditMessage.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/partials/entity-create.html',
      require('./group/templates/partials/entity-create.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/partials/entity-membership-active.html',
      require('./group/templates/partials/entity-membership-active.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/partials/entity-membership-pending.html',
      require('./group/templates/partials/entity-membership-pending.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/partials/entity-update.html',
      require('./group/templates/partials/entity-update.html')
    );

    $templateCache.put(
      '@/app/intranet/group/templates/partials/join-group.html',
      require('./group/templates/partials/join-group.html')
    );

    $templateCache.put(
      '@/app/intranet/group-list/group-list.component.html',
      require('./group-list/group-list.component.html')
    );

    $templateCache.put(
      '@/app/intranet/shifts/manage-shifts/manage-shifts.component.html',
      require('./shifts/manage-shifts/manage-shifts.component.html')
    );

    $templateCache.put(
      '@/app/intranet/shifts/rotas/rotas.component.html',
      require('./shifts/rotas/rotas.component.html')
    );

    $templateCache.put(
      '@/app/intranet/shifts/shifts.component.html',
      require('./shifts/shifts.component.html')
    );

    $templateCache.put(
      '@/app/intranet/shifts/self-unassign-from-rotas/self-unassign-from-rotas.component.html',
      require('./shifts/self-unassign-from-rotas/self-unassign-from-rotas.component.html')
    );
  },
]);
