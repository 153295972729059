import _, { compact } from 'lodash';

import { mainApi } from '../../api';
import { FEvent } from '../../calendar/models/calendar';
import { Intention, IntentionPriorityTypes } from '../models/intention';
import {
  FetchIntentionEventsPayload,
  FetchIntentionsPayload,
  FetchBillingIntentionsPayload,
} from '../redux/intentions/Actions';

export interface IntentionData {
  items: Intention[];
  count: number;
}

class IntentionService {
  public async getIntentions(
    payload: FetchIntentionsPayload
  ): Promise<IntentionData> {
    const endpointPayload: any = _.omit(payload, 'status');
    const status = payload.status;
    if (status) {
      endpointPayload.statuses = [status];
    }
    const response = await mainApi.get(
      '/intention/intentions',
      endpointPayload
    );
    if (response.ok) {
      return response.data as IntentionData;
    }
    throw response.data;
  }

  public async getIntention(id: string): Promise<Intention> {
    const response = await mainApi.get(`/intention/intentions/${id}`);
    if (response.ok) {
      return response.data as Intention;
    }
    throw response.data;
  }

  public async getIntentionsNextReferenceNumber(
    churchId: number,
    intentionYear: number
  ): Promise<string> {
    const response = await mainApi.get(
      '/intention/intentions/next-reference-number',
      {
        churchId,
        intentionYear,
      }
    );
    if (response.ok) {
      return _.get(response.data, 'nextReferenceNumber') as string;
    }
    throw response.data;
  }

  public async createIntention(
    intention: Partial<Intention>
  ): Promise<Partial<Intention>> {
    const response = await mainApi.post('/intention/intentions', intention);
    if (response.ok) {
      return response.data as Partial<Intention>;
    }
    throw response.data;
  }

  public async updateIntention(
    id: string,
    intention: Partial<Intention>
  ): Promise<void> {
    const response = await mainApi.put(
      `/intention/intentions/${id}`,
      intention
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async updateIntentionPriority(
    id: string,
    newPriority: IntentionPriorityTypes
  ): Promise<void> {
    const response = await mainApi.put(`/intention/intentions/${id}`, {
      priority: newPriority,
    });
    if (!response.ok) {
      throw response.data;
    }
  }

  public async deleteIntention(id: string): Promise<void> {
    const response = await mainApi.delete(`/intention/intentions/${id}`);
    if (!response.ok) {
      throw response.data;
    }
  }

  public async restoreIntention(id: string): Promise<void> {
    const response = await mainApi.post(
      `/intention/intentions/${id}/revert-deletion`
    );
    if (!response.ok) {
      throw response.data;
    }
  }

  public async fetchIntentionEvents(
    payload: FetchIntentionEventsPayload
  ): Promise<FEvent[]> {
    const {
      intentionId,
      startDate,
      endDate,
      searchValue,
      orderBy,
      orderDirection,
      limit,
      offset,
    } = payload;
    const response = await mainApi.get(
      `/intention/intentions/${intentionId}/events-to-assign`,
      {
        startDate,
        endDate,
        searchValue,
        orderBy,
        orderDirection,
        limit,
        offset,
      }
    );
    if (response.ok) {
      return response.data as FEvent[];
    }
    throw response.data;
  }

  public async assignIntention({ id, calendarId }): Promise<void> {
    const response = await mainApi.post(
      `/intention/intentions/${id}/assign-to/${calendarId}`
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async unassignIntention(id: string): Promise<void> {
    const response = await mainApi.post(
      `/intention/intentions/${id}/un-assign`
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async fetchEventIntentions(calendarId: number): Promise<Intention[]> {
    const response = await mainApi.get(
      `/intention/intentions/assigned/${calendarId}`
    );
    if (response.ok) {
      // Sort intentions with persolviet first and then by intentionText
      return compact(
        _.orderBy(
          response.data as Intention[],
          ['priority', 'intentionText'],
          ['asc', 'asc']
        )
      );
    }
    throw response.data;
  }

  public async fetchIntentionBillingEvents(
    payload: FetchBillingIntentionsPayload
  ): Promise<FEvent[]> {
    const response = await mainApi.get(
      `/intention/intentions/events-with-assigned-intentions`,
      payload
    );
    if (response.ok) {
      return response.data as FEvent[];
    }
    throw response.data;
  }

  public async processBilling(
    payload: FetchBillingIntentionsPayload
  ): Promise<FEvent[]> {
    const response = await mainApi.post(
      `/intention/intentions/process-billing`,
      payload
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async revertBilling(
    payload: FetchBillingIntentionsPayload
  ): Promise<void> {
    const response = await mainApi.post(
      `/intention/intentions/reset-billing`,
      payload
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }

  public async getIntentionExportDownloadToken(): Promise<string> {
    const response = await mainApi.get('/intention/intentions/export/token');
    if (response.ok) {
      return _.get(response.data, 'token') as string;
    }
    throw response.data;
  }

  public async getMaxNumberOfPrimaryIntentions(
    calendarId: number
  ): Promise<number> {
    const response = await mainApi.get(
      `/intention/intentions/max-primary-intentions/${calendarId}`
    );
    if (response.ok) {
      return _.get(response.data, 'maxNumberOfPrimaryIntentions') as number;
    }
    throw response.data;
  }

  public async copyIntention(
    intentionId: string,
    noOfCopies: number
  ): Promise<void> {
    const response = await mainApi.post(
      `/intention/intentions/${intentionId}/copy`,
      {
        noOfCopies,
      }
    );
    if (response.ok) {
      return;
    }
    throw response.data;
  }
}

export default new IntentionService();
