'use strict';

import ErrorHandlingService from '../../../react/services/ErrorHandlingService';

class PeopleSubscriptionManageState {
  constructor($state, $stateParams, gettextCatalog, cdApp, People) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.People = People;
  }

  $onInit() {
    this.loading = false;
    this.unsubscribe = { setting: 'similar' };
    this.organization = this.organization.data;
    // Load all users that are sharing the same email with the current user.
    this.People.getContactsSharingEmail({
      id: this.$stateParams.user,
      organizationId: this.$stateParams.organization,
      messageId: this.$stateParams.message,
      outsideAccess: this.$stateParams.outsideAccess,
    })
      .$promise.then((contact) => {
        this.contact = contact;
      })
      .catch(ErrorHandlingService.handleError);
  }

  save() {
    this.loading = true;
    const unsubscribeEmailSharingContact = _.filter(
      this.contact.sharingContacts,
      {
        unsubscribe: true,
      }
    );

    this.People.unsubscribe(
      {
        id: this.$stateParams.user,
        organizationId: this.$stateParams.organization,
        outsideAccess: this.$stateParams.outsideAccess,
      },

      {
        messageId:
          this.unsubscribe.setting === 'similar'
            ? this.$stateParams.message
            : undefined,
        notificationLogId: this.$stateParams.notificationLogId,
        skipAuthorization: true,
        emailSharingContactIds: _.map(unsubscribeEmailSharingContact, 'id'),
      }
    )
      .$promise.then(() => {
        this.$state.go('app.public.confirmPeopleSubscription');
      })
      .catch(ErrorHandlingService.handleError)
      .finally(() => {
        this.loading = false;
      });
  }
}
PeopleSubscriptionManageState.$inject = [
  '$state',
  '$stateParams',
  'gettextCatalog',
  'cdApp',
  'People',
];

angular.module('cdApp.people').component('cdPeopleSubscriptionManageState', {
  templateUrl: '@/app/people/subscription/subscription-manage.component.html',
  bindings: {
    organization: '<',
  },

  controller: PeopleSubscriptionManageState,
});
