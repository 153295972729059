import _ from 'lodash';

const calendarProperties = [
  'type',
  'title',
  'visibility',
  'groupIds',
  'startDate',
  'endDate',
  'allDay',
  'calendar_tasks',
];

class AssignUsersToTaskModalComponent {
  constructor(
    $q,
    $uibModal,
    $scope,
    Calendar,
    Planning,
    toastr,
    gettextCatalog,
    appUtils
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.Calendar = Calendar;
    this.Planning = Planning;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.appUtils = appUtils;
  }

  $onInit() {
    const { Calendar, appUtils, toastr } = this;

    this.calendarId = this.resolve.calendarId;
    this.taskId = this.resolve.taskId;

    this.isLoadingInitialData = true;
    Calendar.get({ id: this.calendarId })
      .$promise.then((event) => {
        this.event = event;

        this.rota = _.find(this.event.calendar_tasks, { taskId: this.taskId });
        this.otherRotas = _.filter(
          this.event.calendar_tasks,
          (calendarTask) => calendarTask.taskId !== this.taskId
        );

        this.shifts = _.filter(this.event.shifts, { taskId: this.taskId });
        this.otherShifts = _.filter(
          this.event.shifts,
          (shift) => shift.taskId !== this.taskId
        );

        this.assignedUserIds = _.map(this.shifts, 'user.id');
        this.totalShifts = this.shifts.length;

        // Load conflicting users' data
        return this.checkUsersAvailability();
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
        this.dismiss();
      })
      .finally(() => {
        this.isLoadingInitialData = false;
      });
  }

  prepareEventPayload() {
    const payload = _.pick(this.event, calendarProperties);
    payload.eventId = this.event.id;
    payload.id = this.event.id;
    // Add churches
    payload.churchIds = _.map(this.event.churches, 'id');
    payload.shifts = _(this.event.shifts)
      .filter((shift) => shift.taskId !== this.taskId)
      .map((shift) => ({ taskId: shift.taskId, userId: shift.userId }))
      .value();
    _.each(this.assignedUserIds, (assignedUserId) => {
      payload.shifts.push({ taskId: this.taskId, userId: assignedUserId });
    });
    // Focus notifications on the task only and not the entire event.
    payload.notificationTarget = { taskId: this.taskId };
    return payload;
  }

  save(sendNotifications) {
    const { Planning } = this;

    const payload = _.extend(this.prepareEventPayload(), { sendNotifications });

    this.isLoading = true;
    Planning.saveTaskManagementChanges(payload, 'assign', false)
      .then(({ shifts, calendar_tasks }) => {
        this.close({ $value: { shifts, calendar_tasks } });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Called by <cd-form-event-users-component> to check access.
   */
  canEditShifts() {
    return (
      _.get(this.rota, 'access.canEdit', false) ||
      _.get(this.rota, 'access.canAssignMySelf', false)
    );
  }
  canEdit() {
    return _.get(this.rota, 'access.canEdit', false);
  }

  /**
   * Triggered by <cd-form-event-users-component> when the selected shift users change.
   * Update list of assigned users and check availability.
   *
   * @param {number[]} userIds
   * @param {boolean} wasUserRemoved
   *
   * @returns {Promise}
   */
  onUserListUpdated(userIds, wasUserRemoved) {
    const { $q } = this;

    this.assignedUserIds = userIds;
    if (wasUserRemoved) return $q.resolve();
    return this.checkUsersAvailability();
  }

  checkUsersAvailability() {
    const { $q, Calendar } = this;

    this.isLoading = true;
    return $q((resolve) => {
      // Prepare payload to check user availability
      const payload = this.prepareEventPayload();

      // Call the API for checking conflicts
      Calendar.checkAvailability(
        null,
        payload,
        () => {
          this.isLoading = false;
          return resolve();
        },
        ({ status, data }) => {
          // Skip if not a conflict error or no users/workplan users conflict
          if (
            status !== 409 ||
            (!data.users && !data.workplan && !data.shifts)
          ) {
            return resolve();
          }
          // Sets the data for the <cd-form-event-users-component>
          this.conflictingUsers = data;
          this.isLoading = false;
          return resolve();
        }
      );
    });
  }

  /**
   * Shows the double booking information modal. Invoked by <cd-form-event-users-component>
   */
  showDoubleBookingModal(conflicts) {
    const { $uibModal } = this;

    $uibModal.open({
      component: 'cdDoubleBookingModal',
      resolve: {
        conflicts: () => conflicts,
        allowConflicts: () => () => Promise.resolve(),
      },
    });
  }

  getOtherRotasUserIsAssignedTo(userId) {
    const otherAssignations = _.filter(this.otherShifts, { userId });

    return _.map(otherAssignations, (otherAssignation) =>
      _.get(
        _.find(this.otherRotas, { taskId: otherAssignation.taskId }),
        'task.title'
      )
    );
  }

  getConflictingRotasInEvent(conflictsInEvent) {
    const { gettextCatalog } = this;

    return gettextCatalog.getString('Also on: {{rotas}}', {
      rotas: conflictsInEvent.join(', '),
    });
  }
}
AssignUsersToTaskModalComponent.$inject = [
  '$q',
  '$uibModal',
  '$scope',
  'Calendar',
  'Planning',
  'toastr',
  'gettextCatalog',
  'appUtils',
];

angular.module('cdApp.shared').component('cdAssignUsersToTaskModal', {
  templateUrl:
    '@/app/shared/components/task-assign-user/task-assign-users.component.html',
  controller: AssignUsersToTaskModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
