import { Alert, Button, Col, List, Row, Space, Table, Tag } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '../../../services/I18nService';

import { findNSProvider } from './services/find-ns-provider';

import { useDomains } from '@/react/homepage/hooks/use-domains';
import { DomainInterface } from '@/react/homepage/types/domain.type';
import {
  CdDomainList,
  CdDefaultDomain,
  CdExclamationTriangleIcon,
  CdDeleteIcon,
  CdRecheckDomain,
} from '@/react/shared/components/Icons';
import { supportGetYourWebsiteOnline } from '@/react/homepage/utils/support-articles';
import cdApp from '@/react/config';
import { BeaconChatService } from '@/react/services/beacon-chat.service';
import {
  showConfirmModal,
  showConfirmModalDeprecated,
} from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const contactSupport = BeaconChatService.showMessages;

const StyledListItemMeta = styled(List.Item.Meta)`
  &&&& {
    h4.ant-list-item-meta-title {
      margin-top: 0px;
    }
  }
`;

export const DomainListItem = ({
  domain,
  siteId,
  numberOfDomains,
}: {
  domain: DomainInterface;
  siteId: string;
  numberOfDomains: number;
}) => {
  const { removeDomain, checkStatus, makeDomainDefault, checkStatusLoading } =
    useDomains(siteId);
  const canPrimaryBeDeleted =
    numberOfDomains > 1 ? domain.status && domain.primary : true;
  return (
    <List.Item key={domain.domain} style={{ alignItems: 'flex-start' }}>
      <StyledListItemMeta
        avatar={
          <div style={{ width: '30px' }}>
            <CdDomainList
              size="2x"
              type={!domain.status ? 'danger' : 'success'}
            />
          </div>
        }
        title={
          <Row wrap={false}>
            <Col flex="auto">
              <Space>
                {domain.domain}
                {domain.status && !domain.primary && (
                  <Button
                    icon={<CdDefaultDomain />}
                    color="default"
                    size="small"
                    onClick={() =>
                      showConfirmModalDeprecated({
                        message: (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: gettextCatalog.getString(
                                'Do you want to make <i>{{ domainName }}</i> your primary domain?',
                                { domainName: domain.domain }
                              ),
                            }}
                          />
                        ),
                        icon: <CdExclamationTriangleIcon />,
                        title: gettextCatalog.getString('Make primary'),
                        onOk: () => makeDomainDefault(domain.id),
                      })
                    }
                  >
                    {gettextCatalog.getString('Make primary')}
                  </Button>
                )}
                {domain.status && domain.primary && (
                  <Tag icon={<CdDefaultDomain />} color="success">
                    {gettextCatalog.getString('Primary domain')}
                  </Tag>
                )}
              </Space>
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
              <Space>
                {!domain.status && (
                  <CdTooltip title={domain.statusError}>
                    <Tag color="error" icon={<CdExclamationTriangleIcon />}>
                      {gettextCatalog.getString('Issues detected!')}
                    </Tag>
                  </CdTooltip>
                )}
                <CdTooltip
                  title={
                    domain.primary &&
                    !canPrimaryBeDeleted &&
                    gettextCatalog.getString(
                      'A primary domain cannot be deleted.'
                    )
                  }
                >
                  <Button
                    icon={<CdDeleteIcon fixedWidth />}
                    size="small"
                    disabled={domain.primary && !canPrimaryBeDeleted}
                    onClick={() =>
                      showConfirmModal({
                        message: (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: gettextCatalog.getString(
                                'Do you want to remove <i>{{ domainName }}</i>?',
                                { domainName: domain.domain }
                              ),
                            }}
                          />
                        ),
                        title: gettextCatalog.getString('Remove domain'),
                        onOk: () => removeDomain(domain.id),
                        okText: gettextCatalog.getString('Remove'),
                        okButtonProps: { danger: true },
                      })
                    }
                  >
                    {gettextCatalog.getString('Remove')}
                  </Button>
                </CdTooltip>
              </Space>
            </Col>
          </Row>
        }
        description={
          <Space direction="vertical" size={0}>
            {gettextCatalog.getString('Including {{ domainName }}', {
              domainName: 'www.' + domain.domain,
            })}
            {!domain.status && (
              <SetupInstructions
                domain={domain}
                checkStatus={() => checkStatus(domain.id)}
                checkStatusLoading={checkStatusLoading}
              />
            )}
            {domain.status &&
              gettextCatalog.getString('Connected to ChurchDesk')}
          </Space>
        }
      />
    </List.Item>
  );
};

const SetupInstructions = ({
  domain,
  checkStatus,
  checkStatusLoading,
}: {
  domain: DomainInterface;
  checkStatus;
  checkStatusLoading: boolean;
}) => {
  const domainSupportArticle = supportGetYourWebsiteOnline(
    cdApp?.organization?.locale.language
  );
  const columns = [
    {
      title: gettextCatalog.getString('Type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: gettextCatalog.getString('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: gettextCatalog.getString('Value'),
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const nsProvider = findNSProvider(
    domain.domain,
    domain.nameServers && domain.nameServers[0]
  );
  const registrarByChurchDesk = nsProvider?.isChurchDesk;

  useEffect(() => {
    // To avoid CSP issues with inline scripts, we're using event listeners to open the chat.
    // Currently we cannot add a React button inside our translations.
    const contactSupportLinks = document.querySelectorAll('.contact-support');
    contactSupportLinks.forEach((contactSupportLink) => {
      contactSupportLink.addEventListener('click', () => contactSupport());
    });

    return () => {
      const contactSupportLinks = document.querySelectorAll('.contact-support');
      contactSupportLinks.forEach((contactSupportLink) => {
        contactSupportLink.removeEventListener('click', () => contactSupport());
      });
    };
  }, []);

  let helpText;
  if (registrarByChurchDesk) {
    helpText = gettextCatalog.getString(
      'The domain is not pointing correctly. <b>Contact <a>ChurchDesk Support</a> as soon as possible to get this resolved and get your new website up and running.</b><br /><br />Important: If you do not contact ChurchDesk Support yourself, we will automatically transfer your domains on January 15th 2024.',
      {
        a: (text) =>
          `<a
            class="contact-support"
            href="#"
          >
            ${text}
          </a>`,
      }
    );
  } else if (nsProvider) {
    helpText = gettextCatalog.getString(
      "The domain is not pointing correctly. <b>You need to change the domain's settings with your domain registrar.</b> Click here to go to your domain registrar: {{ goToRegistrarButton }}<br /><br />Important: Make sure your domains have the settings below. If you need further guidance, please read <a>this documentation</a>.",
      {
        a: (text) =>
          `<a href="${domainSupportArticle}" target="_blank" rel="noopener noreferrer">${text}</a>`,
        support: (text) => `<a href="#" class="contact-support">${text}</a>`,
        goToRegistrarButton: `<a class="btn btn-default" href="https://${nsProvider.domain}" target="_blank" rel="noopener noreferrer">${nsProvider.name}</a>`,
      }
    );
  } else {
    helpText = gettextCatalog.getString(
      "The domain is not pointing correctly. <b>You need to change the domain's settings with your domain registrar.</b><br /><br />Important: Make sure your domains have the settings below. If you need further guidance, please read <support>this documentation</support>.",
      {
        support: (text) =>
          `<a href="${domainSupportArticle}" target="_blank" rel="noopener noreferrer">${text}</a>`,
      }
    );
  }

  return (
    <Alert
      message={
        <Space direction="vertical">
          <span
            dangerouslySetInnerHTML={{
              __html: helpText,
            }}
          />
          {!registrarByChurchDesk && (
            <Table
              dataSource={[
                {
                  key: 1,
                  type: 'A',
                  name: domain.domain,
                  value: '185.58.213.233',
                },
                {
                  key: 2,
                  type: 'A',
                  name: 'www.' + domain.domain,
                  value: '185.58.213.233',
                },
              ]}
              columns={columns}
              pagination={false}
              size="small"
            />
          )}
          <Space>
            <Button
              type="primary"
              icon={<CdRecheckDomain />}
              onClick={checkStatus}
              loading={checkStatusLoading}
            >
              {gettextCatalog.getString('Check Status')}
            </Button>
            {gettextCatalog.getString(
              'NOTE: It may take up to a few hours for DNS changes to take effect'
            )}
          </Space>
        </Space>
      }
      type="warning"
    />
  );
};
