import { Form } from 'antd';
import React from 'react';

import { StandardWorkingHours } from './StandardWorkingHours';
import useStandardWorkingHoursModal from './hooks/useStandardWorkingHoursModal';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';

export const showStandardWorkingHoursModal = createCdModal(
  ({ setModalProps }) => {
    const { form, initialValues } = useStandardWorkingHoursModal(setModalProps);

    return (
      <Form form={form} colon={false} initialValues={initialValues}>
        <StandardWorkingHours />
      </Form>
    );
  }
);
