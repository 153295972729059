'use strict';

class AnnouncementsComponent {
  constructor(_, Announcement, $scope) {
    'ngInject';

    this._ = _;
    this.Announcement = Announcement;
    this.$scope = $scope;
  }

  $onInit() {
    const { Announcement, $scope } = this;

    this.limit = 2;

    const fetchAnnouncements = () => {
      this.announcements = Announcement.query();
      this.announcements.$promise.then(() => {
        this.updateDropdownsHeight();
      });
    };
    fetchAnnouncements();

    $scope.$on('updateAnnouncements', () => {
      fetchAnnouncements();
    });
  }

  dismissAnnouncement(announcement) {
    const { identifier } = announcement;

    this.Announcement.dismiss({ dismiss: [identifier] }, () => {
      this._.remove(this.announcements, { identifier });
      this.updateDropdownsHeight();
    });
  }

  updateDropdownsHeight() {
    const totalShown = this._.min([this.limit, this.announcements.length]);
    const offsetHeight = totalShown * 36;

    // Update the max-height of the dropdowns in the app-menu to take into account the announcements
    if (totalShown) {
      angular
        .element('.app-menu .dropdown-menu')
        .css({ maxHeight: `calc(100vh - 48px - 24px - ${offsetHeight}px)` });
    }
  }
}
AnnouncementsComponent.$inject = ['_', 'Announcement', '$scope'];
angular.module('cdApp.shared').component('cdAnnouncements', {
  templateUrl:
    '@/app/shared/components/announcements/announcements.component.html',
  controller: AnnouncementsComponent,
});
