import React, { useCallback, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Form } from 'antd';
import moment from 'moment';

import { useCreateIntention } from '../../hooks/use_create-intention';
import { useRotaAndIntentionState } from '../../hooks/use-rota-and-intention-state';

import { CreateIntentionForm } from './CreateIntentionForm';

import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { gettextCatalog } from '@/react/services/I18nService';

export type CreateIntentionModalProps = {
  eventId?: number;
  churchIds?: number[];
  eventStartDate?: string;
  eventTitle?: string;
};

export const openCreateIntentionModal =
  createCdModal<CreateIntentionModalProps>(
    ({ setModalProps, churchIds, eventId }) => {
      const [form] = Form.useForm();
      const { createIntention } = useCreateIntention();
      const { updateRotaTableStateByCreatingNewIntention } =
        useRotaAndIntentionState();
      const onOk = useCallback(async () => {
        const values = await form.validateFields().catch((err) => {
          handleAntFormValidationErrors(err);
        });
        if (!values) {
          return;
        } else {
          values.intentionYear = moment(values.intentionYear).year();
          values.resource = values.resource
            ? { id: values.resource }
            : undefined;
          const intention = await createIntention(values);
          if (intention) {
            updateRotaTableStateByCreatingNewIntention(eventId, intention);
          }
        }
      }, [
        createIntention,
        eventId,
        form,
        updateRotaTableStateByCreatingNewIntention,
      ]);

      useEffect(() => {
        setModalProps({
          modalType: ModalType.MODAL,
          title: gettextCatalog.getString('Create intention'),
          width: '90vw',
          onOk,
        });
      }, [onOk, setModalProps]);

      return <CreateIntentionForm form={form} churchIds={churchIds} />;
    }
  );
