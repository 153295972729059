'use strict';

angular.module('cdApp.calendar').service('calendarService', [
  '$interval',
  '$http',
  '_',
  function ($interval, $http, _) {
    'ngInject';

    // Create the factory object.
    const service = {};
    let data = {};
    let updateType = [];

    // Save endpoint.
    service.save = (values, type) => {
      data = values;
      if (_.indexOf(updateType, type) === -1) {
        updateType.push(type);
      }
      service.updateCondition = true;
    };

    // Set defaults.
    service.setDefaults = () => {
      // Create calendar object if needed.
      cdApp.data.calendar = cdApp.data.calendar || {};
      cdApp.data.calendar.settings = cdApp.data.calendar.settings || {};
      cdApp.data.calendar.settings.render = [
        'mygroup-events',
        'mygroup-absence',
        'mycalendar-private',
        'othercalendar-events',
        'othercalendar-absence',
      ];

      service.save(cdApp.data.calendar, 'settings');
    };

    // Initialize the default values.
    if (_.get(cdApp, 'data.calendar.settings.setDefaults')) {
      cdApp.data.calendar.settings.setDefaults = false;
      service.setDefaults();
    }

    service.updateCondition = false;

    // Update command.
    service.update = (type, obj) => {
      if (_.includes(updateType, type)) {
        const name = _.get(
          {
            filters: 'calendarFilters',
            settings: 'calendarSettings',
          },

          type
        );

        // Http post to update user object.
        $http({
          method: 'PUT',
          url: `${cdApp.config.api.main}/calendar/data`,
          data: {
            name: name,
            data: obj[type],
          },
        });

        updateType = _.without(updateType, type);
      }
    };

    // Repeat the function each 5 seconds, check the status of changes.
    $interval(() => {
      if (service.updateCondition) {
        // Change the update status not to repeat same update multiple times.
        service.updateCondition = false;

        service.update('filters', data);
        service.update('settings', data);
      }
    }, 5000);

    return service;
  },
]);
