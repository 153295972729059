import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import { IntentionFee, IntentionFeeRecipient } from '../../models/fee';
import createReducer from '../../../redux/utils';

import {
  CLEAR_INTENTION_FEE,
  FETCH_INTENTION_FEE_SUCCESS,
  FETCH_INTENTION_FEES_SUCCESS,
  FetchIntentionFeeSuccessActionType,
  FetchIntentionFeesSuccessActionType,
  FETCH_STOLES_FEES_SUCCESS,
  FETCH_STOLES_FEE_SUCCESS,
  FetchStolesFeesSuccessActionType,
  FetchStolesFeeSuccessActionType,
  FETCH_INTENTION_FEE_RECIPIENTS_SUCCESS,
  FetchIntentionFeeRecipientsSuccessActionType,
} from './Actions';

export interface IntentionFeeState {
  intentionFees: IntentionFee[];
  intentionFee: Partial<IntentionFee>;
  stolesFees: IntentionFee[];
  stolesFee: Partial<IntentionFee>;
  intentionFeeRecipients: IntentionFeeRecipient[];
}

export type ImmutableIntentionFeeState =
  immutable.ImmutableObject<IntentionFeeState>;

const initialState: ImmutableIntentionFeeState =
  immutable.from<IntentionFeeState>({
    intentionFees: [],
    intentionFee: null,
    stolesFees: [],
    stolesFee: null,
    intentionFeeRecipients: [],
  });

const clearIntentionFee: Reducer<ImmutableIntentionFeeState> = (
  state = initialState
) =>
  state.merge({
    intentionFee: null, // Clear selected Intention Fee (edit/view)
  });

const fetchIntentionFeeSuccess: Reducer<
  ImmutableIntentionFeeState,
  FetchIntentionFeeSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionFee: payload,
  });

const fetchIntentionFeesSuccess: Reducer<
  ImmutableIntentionFeeState,
  FetchIntentionFeesSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionFees: payload.items || [],
  });

const fetchStolesFeeSuccess: Reducer<
  ImmutableIntentionFeeState,
  FetchStolesFeeSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    stolesFee: payload,
  });

const fetchStolesFeesSuccess: Reducer<
  ImmutableIntentionFeeState,
  FetchStolesFeesSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    stolesFees: payload.items || [],
  });

const fetchIntentionFeeRecipientsSuccess: Reducer<
  ImmutableIntentionFeeState,
  FetchIntentionFeeRecipientsSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    intentionFeeRecipients: payload || [],
  });

// Handlers

const handlers = {
  [CLEAR_INTENTION_FEE]: clearIntentionFee,
  [FETCH_INTENTION_FEE_SUCCESS]: fetchIntentionFeeSuccess,
  [FETCH_INTENTION_FEES_SUCCESS]: fetchIntentionFeesSuccess,
  [FETCH_STOLES_FEE_SUCCESS]: fetchStolesFeeSuccess,
  [FETCH_STOLES_FEES_SUCCESS]: fetchStolesFeesSuccess,
  [FETCH_INTENTION_FEE_RECIPIENTS_SUCCESS]: fetchIntentionFeeRecipientsSuccess,
};

export default createReducer<ImmutableIntentionFeeState>(
  initialState,
  handlers
);
