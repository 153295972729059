import { Button, Checkbox, Dropdown } from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';

import { gettextCatalog } from '@/react/services/I18nService';
import { safeguardingUserStatus } from '@/react/safeguarding/types/SafeguardingTypes';

export const OrganizationUserStatus = ({
  status,
  statusHandler,
}: { status: string[]; statusHandler: ([]) => void }) => {
  const handleStatusChange = (checkedValue: string, checked: boolean) => {
    const newStatus = checked
      ? [...status, checkedValue]
      : status.filter((item) => item !== checkedValue);
    statusHandler(newStatus);
  };

  const statusItems = safeguardingUserStatus().map((item) => ({
    key: item.value,
    label: (
      <Checkbox
        onChange={(e) => handleStatusChange(item.value, e.target.checked)}
        checked={status.includes(item.value)}
        style={{ width: '100%', fontWeight: 400 }}
      >
        {item.label}
      </Checkbox>
    ),
    onChange: (e) => handleStatusChange(item.value, e.target.checked),
  }));

  return (
    <Dropdown
      menu={{ items: statusItems }}
      trigger={['click']}
      overlayStyle={{ minWidth: 200 }}
    >
      <Button
        style={{
          textAlign: 'left',
          minWidth: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {gettextCatalog.getString('User status')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
