import { Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';

import { gettextCatalog } from '@/react/services/I18nService';

export const NotificationStatus = ({ smsLogs, emailLogs, notifiedAt }) => (
  <>
    {smsLogs?.length > 0 && emailLogs?.length === 0 && (
      <>
        <Row>
          <Space>
            <span>{gettextCatalog.getString('SMS')} </span>
            <span>{moment(notifiedAt).format('lll')}</span>
          </Space>
        </Row>
        <Row>{gettextCatalog.getString('Email is disabled by user.')}</Row>
      </>
    )}
    {smsLogs?.length === 0 && emailLogs?.length === 0 && (
      <>
        <Row>{moment(notifiedAt).format('lll')}</Row>
        <Row>
          {gettextCatalog.getString('Both SMS and Email are disabled by user.')}
        </Row>
      </>
    )}
    {emailLogs?.length > 0 && smsLogs?.length === 0 && (
      <>
        <Row>
          <Space>
            <span>{gettextCatalog.getString('Email')}</span>
            <span>{moment(notifiedAt).format('lll')}</span>
          </Space>
        </Row>
        <Row>{gettextCatalog.getString('SMS is disabled by user.')}</Row>
      </>
    )}
    {smsLogs?.length > 0 && emailLogs?.length > 0 && (
      <>
        <span>{gettextCatalog.getString('SMS & Email')}</span>
        <span>{moment(notifiedAt).format('lll')}</span>
      </>
    )}
  </>
);
