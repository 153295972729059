import { atomFamily, selectorFamily } from 'recoil';

import { mainApi } from '../../api';
import { PeopleFromSearch, PeopleSearch } from '../types/people';
import {
  SegmentComparison,
  SegmentFilterOperator,
  SegmentFilterType,
} from '../types/segment.type';

export type PeopleSearchInput = {
  search?: string;
  churchIds: number[];
  personIds?: number[];
};

export const personFromSearchState = atomFamily<PeopleFromSearch, number>({
  key: 'personFromSearchState',
  default: () => null,
});

export const PeopleSelector = selectorFamily<PeopleSearch, PeopleSearchInput>({
  key: 'peopleSelector',
  get:
    ({ search, churchIds, personIds }: PeopleSearchInput) =>
    async () => {
      if (!churchIds || churchIds.length === 0) {
        return null;
      }

      let filters = [];
      if (search) {
        filters = getSearchFilters(search);
      } else if (personIds && personIds.length > 0) {
        filters = getPeopleByIdFilters(personIds);
      }

      const filter = {
        comparison: SegmentComparison.OR,
        churchIds,
        filters,
      };

      const response = await mainApi.post<PeopleSearch>(
        'people/people/search',
        {
          orderDirection: 'ASC',
          orderBy: 'firstName',
          limit: personIds?.length || 10,
          filter,
        }
      );

      if (response.ok) {
        return response.data;
      }

      if (403 === response.status) {
        return null;
      }
      throw response;
    },
});
export const peopleSimpleSelector = selectorFamily<
  PeopleFromSearch[],
  PeopleSearchInput
>({
  key: 'peopleSimpleSelector',
  get:
    ({ search, churchIds, personIds }: PeopleSearchInput) =>
    ({ get }) =>
      get(PeopleSelector({ churchIds, search, personIds }))?.people,
});
const getPeopleByIdFilters = (personIds: number[]) => [
  {
    type: SegmentFilterType.List,
    property: '_id',
    value: personIds,
    operator: SegmentFilterOperator.InArray,
  },
];
const getSearchFilters = (search: string) => [
  {
    type: SegmentFilterType.Text,
    property: 'fullName',
    operator: SegmentFilterOperator.Like,
    value: search,
  },
  {
    type: SegmentFilterType.Text,
    property: 'email',
    operator: SegmentFilterOperator.Like,
    value: search,
  },
  {
    type: SegmentFilterType.Text,
    property: 'phone',
    operator: SegmentFilterOperator.Like,
    value: search,
  },
];
