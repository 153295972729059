import { isEqual } from 'lodash';

export const hasEventFormChanged = ({
  currentFormValues,
  initialFormValues,
}) => {
  let hasChanged = false;
  for (const key in currentFormValues) {
    if (!isEqual(currentFormValues?.[key], initialFormValues?.[key])) {
      hasChanged = true;
      // To aid in debugging what has been touched.
      // eslint-disable-next-line no-console
      console.log({
        touchedField: key,
      });
    }
  }
  return hasChanged;
};
