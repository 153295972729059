(function () {
  'use strict';
  Shifts.$inject = ['$$resource', 'cdApp'];

  function Shifts($$resource, cdApp) {
    return $$resource(cdApp.config.api.main + '/shifts/:id', null, {
      getAll: {
        isArray: false,
        method: 'GET',
        url: cdApp.config.api.main + '/shifts',
      },

      getRotas: {
        isArray: false,
        method: 'GET',
        url: cdApp.config.api.main + '/shifts/rotas',
      },

      getPublicRota: {
        isArray: false,
        method: 'GET',
        url: cdApp.config.api.main + '/shifts/rotas/public/:rotaId',
      },

      getPersonalPublicRota: {
        isArray: false,
        method: 'GET',
        url: cdApp.config.api.main + '/shifts/rotas/public/personal',
      },

      createPublicRota: {
        method: 'POST',
        url: cdApp.config.api.main + '/shifts/rotas/link',
      },

      selfUnassignFromRotas: {
        method: 'POST',
        url: `${cdApp.config.api.main}/shifts/self-unassign-from-rotas`,
      },
    });
  }

  angular.module('cdApp.shared').factory('Shifts', Shifts);
})();
