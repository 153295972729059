import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { CdLocationSelect } from '../../shared/components/cd-location-select/CdLocationSelect';

import { Resources } from '@/react/shared/services/ResourceService';

interface ResourceLocationProps {
  value?: string[];
  onChange?: any;
}

export const SearchCalendarLocations = (props: ResourceLocationProps) => {
  const resources = useRecoilValue(Resources);
  const onChange = useCallback(
    (newValue) => {
      props.onChange(newValue);
    },
    [props]
  );
  if (!props.onChange) return null;
  return (
    <CdLocationSelect
      value={props.value}
      selectedResources={resources}
      onChange={onChange}
      showSearch={true}
      aggregateSelection={true}
      popupMatchSelectWidth={false}
      dropdownStyle={{ width: '350px' }}
    />
  );
};
