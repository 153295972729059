'use strict';
class PeopleHomepageComponent {
  constructor(gettextCatalog, Authorization, _) {
    this.Authorization = Authorization;

    this.noAccess = gettextCatalog.getString('You cannot access {{package}}', {
      package: _.capitalize(
        gettextCatalog.getString('people', {}, 'The name of the app')
      ),
    });
  }
}

PeopleHomepageComponent.$inject = ['gettextCatalog', 'Authorization', '_'];
angular.module('cdApp.people').component('cdPeople', {
  templateUrl: '@/app/people/people.component.html',
  bindings: {
    errorCode: '<',
  },

  controller: PeopleHomepageComponent,
});
