(function () {
  'use strict';

  /**
   * @desc sanitize the output when pasting highly formatted text
   * @example <p contenteditable="true" cd-safe-paste></p>
   */
  angular.module('cdApp.shared').directive('cdSafePaste', [
    'safePasteService',
    (safePasteService) => ({
      restrict: 'A',
      link: function (scope, elem) {
        elem.on('paste', safePasteService.handlePaste);

        scope.$on('$destroy', function () {
          elem.off('paste', safePasteService.handlePaste);
        });
      },
    }),
  ]);
})();
