import _ from 'lodash';
import moment from 'moment';

class EventDetailRotasComponent {
  constructor($filter, $state, gettextCatalog, cdApp, Authorization, Planning) {
    'ngInject';

    this.$filter = $filter;
    this.$state = $state;
    this.gettextCatalog = gettextCatalog;
    this.cdApp = cdApp;
    this.Authorization = Authorization;
    this.Planning = Planning;
  }

  $onInit() {
    const { Authorization } = this;

    this.isFutureEvent = moment(this.event.startDate).isAfter();

    // Whether the user can manage rotas
    this.canManageRotas =
      !this.isRevision &&
      Authorization.hasPermission('canBook') &&
      this.isFutureEvent;
  }

  /**
   * Get the string saying who last updated a shift.
   *
   * @param {Object} shift
   */
  getShiftUpdatedByString(shift) {
    const { $filter, gettextCatalog } = this;

    if (_.isEmpty(shift.updatedBy)) return '';
    const userName = $filter('getName')(shift.updatedBy);

    return gettextCatalog.getString(
      'Last updated by {{ user }} on {{ date }}',
      {
        user: userName,
        date: moment(shift.updatedAt).format('llll'),
      }
    );
  }

  /**
   * Launch a modal to edit the rota
   */
  editRota(calendarTask) {
    const { Planning } = this;

    Planning.showAssignUsersToTaskModal(
      this.event.id,
      calendarTask.taskId
    ).then(({ shifts, calendar_tasks }) => {
      this.onShiftsUpdated({ shifts, calendar_tasks });
    });
  }
}
EventDetailRotasComponent.$inject = [
  '$filter',
  '$state',
  'gettextCatalog',
  'cdApp',
  'Authorization',
  'Planning',
];

angular.module('cdApp.calendar').component('cdEventDetailRotas', {
  template: require('./event-detail-rotas.component.html'),
  bindings: {
    event: '<',
    rotas: '<',
    isRevision: '<',
    onShiftsUpdated: '&',
  },

  controller: EventDetailRotasComponent,
});
