import { includes } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from '../../../redux';

export const isLoading: (state: AppState) => boolean = (state) =>
  state.loading.isLoading;

export const isModuleLoading = (moduleString: string) =>
  createSelector(
    (state: AppState) => state.loading.loadingModules,
    (modules) => modules.some((module) => module === moduleString)
  );

export const selectIsModulesLoading = (listOfModuleStrings: string[]) =>
  createSelector(
    (state: AppState) => state.loading.loadingModules,
    (modules) => modules.some((module) => includes(listOfModuleStrings, module))
  );
