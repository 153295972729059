import { selectorFamily, atomFamily, atom, selector } from 'recoil';
import { RcFile } from 'antd/lib/upload/interface';
import { uniqBy } from 'lodash';

import {
  PeopleMessage,
  MessageRecipientsCount,
  MessageFrom,
  PeopleMessageFile,
  PeopleMessageStatistics,
  PeopleMessageForm,
  PeopleMessageBlog,
} from '../types/message';
import { PeopleFromSearch } from '../types/people';
import { separateRecipientSelection } from '../message-editor/shared/containers/recipient-selector/recipient-selector.lib';
import { Segment, SegmentType } from '../types/segment.type';

import { peopleSegmentsByTypeQuery } from './peopleSegment';
import { personFromSearchState } from './people';

import { UserCheckPermisionQuery } from '@/react/user/store/permissions';
import { ChurchSettingsState } from '@/react/organization/store/church';
import { SelectedOrganizationId } from '@/react/organization/store/organization';
import { ApiSearchResult, formsApi, mainApi } from '@/react/api';

type MessageRecipientsCalculatorInput = {
  segmentIds: number[];
  personIds: number[];
  type: 'email' | 'sms';
};

export const messageRecipientsCalculatorQuery = selectorFamily<
  MessageRecipientsCount,
  MessageRecipientsCalculatorInput
>({
  key: 'messageRecipientsCalculatorQuery',
  get:
    ({ segmentIds, personIds, type }) =>
    async ({ get }) => {
      const segments = get(
        peopleSegmentsByTypeQuery([
          SegmentType.SEGMENT,
          SegmentType.ORGANIZATION,
        ])
      );
      const filteredSegments = segments.filter((item) =>
        segmentIds.includes(item.id)
      );

      const { data, ok } = await mainApi.post<MessageRecipientsCount>(
        'people/messages/calculate/recipients',
        {
          segments: filteredSegments,
          personIds,
          type,
        }
      );
      if (ok) {
        return data;
      }
    },
});

export const unlayerControlState = atom({
  key: 'unlayerControlState',
  default: null,
});

export const messageRecipientsCombinedState = selectorFamily<
  {
    people: PeopleFromSearch[];
    segments: Segment[];
  },
  string[]
>({
  key: 'messageRecipientsCombinedState',
  get:
    (toValue: string[]) =>
    ({ get }) => {
      const { personIds } = separateRecipientSelection(toValue);

      const selectedPersons = personIds.reduce(
        (accumulator, personId: number) => {
          const person = get(
            personFromSearchState(personId)
          ) as PeopleFromSearch;
          if (person) accumulator.push(person);
          return accumulator;
        },
        []
      );
      const { people, segments } = get(messageRecipientsState);
      return { people: uniqBy(people.concat(selectedPersons), 'id'), segments };
    },
});

export const messageRecipientsState = atom<{
  people: PeopleFromSearch[];
  segments: Segment[];
}>({
  key: 'messageRecipientsState',
  default: { people: [], segments: [] },
});

export const peopleMessageIdState = atom<number>({
  key: 'peopleMessageIdState',
  default: null,
});

export const MessageByIdQuery = atomFamily<PeopleMessage, number>({
  key: 'MessageByIdQuery',
  default: selectorFamily({
    key: 'MessageByIdQuerySelector',
    get:
      (id: number) =>
      async ({ get }) => {
        if (!id) {
          return {
            id: undefined,
            to: [],
            from: MessageFrom.USER,
            title: '',
            churches: [],
            news: [],
            events: [],
            forms: [],
            organization: get(ChurchSettingsState),
            contributions: [],
            files: [],
            content: {
              body: '',
              blogTitle: '',
              evnetsTitle: '',
              formsTitle: '',
              contributionsTitle: '',
              newsletterColor: '',
              newsletterEventLocation: false,
              newsletterShowShortDescription: false,
            },
          } as PeopleMessage;
        }

        const { ok, data } = await mainApi.get<PeopleMessage>(
          `/v2/people/messages/${id}`
        );

        if (ok) {
          return data;
        }
      },
  }),
});

export const MessageEventIds = selector<number[]>({
  key: 'MessageEventIds',
  get: ({ get }) =>
    get(MessageByIdQuery(get(peopleMessageIdState))).events.map(({ id }) => id),
});

export const MessageBlogIds = selector<number[]>({
  key: 'MessageBlogIds',
  get: ({ get }) =>
    get(MessageByIdQuery(get(peopleMessageIdState))).news.map(({ id }) => id),
});

export const MessageStatisticsByIdPermission = selectorFamily<boolean, number>({
  key: 'MessageStatisticsByIdFilter.Permision',
  get:
    (id: number) =>
    async ({ get }) => {
      if (!id) {
        return true;
      }
      return await get(
        UserCheckPermisionQuery({
          entityId: id,
          entityType: 'people_message',
          privilege: 'people_message.viewDetailedMessageStatistics',
        })
      );
    },
});

export const MessageStatisticsByIdFilter = {
  Filter: atom<{ type: string; search: string }>({
    key: 'MessageStatisticsByIdFilter.Filter',
    default: {
      type: 'all',
      search: null,
    },
  }),

  Query: selectorFamily<
    { items: PeopleMessageStatistics[]; total: number },
    number
  >({
    key: 'MessageStatisticsByIdFilter.Query',
    get:
      (id: number) =>
      async ({ get }) => {
        if (!id) {
          return { items: [] as PeopleMessageStatistics[], total: 0 };
        }
        const filter = get(MessageStatisticsByIdFilter.Filter);
        let statistics = get(MessageStatisticsByIdQuery(id));
        if (!statistics) {
          return {
            items: [],
            total: 0,
          };
        }
        if (filter.search?.length) {
          const searchLowerCased = filter.search.toLowerCase();
          statistics = statistics.filter(
            (element) =>
              element?.name?.toLowerCase().includes(searchLowerCased) ||
              element?.email?.toLowerCase().includes(searchLowerCased)
          );
        }
        if (filter.type && filter.type !== 'all') {
          statistics = statistics.filter(filterStatus(filter.type));
        }
        return { items: statistics, total: statistics.length };
      },
  }),
};

export const MessageStatisticsByIdQuery = selectorFamily<
  PeopleMessageStatistics[],
  number
>({
  key: 'MessageStatisticsById.Query',
  get: (id: number) => async () => {
    if (!id) {
      return [] as PeopleMessageStatistics[];
    }
    const { ok, data } = await mainApi.get<PeopleMessageStatistics[]>(
      `/people/messages/${id}/report`
    );
    if (ok) {
      return data;
    }
  },
});

const filterStatus = (type) => {
  switch (type) {
    case 'delivered':
      return (element) => ['delivered'].includes(element.status);

    case 'bounced':
      return (element) => ['bounced'].includes(element.status);

    case 'opened':
      return (element) => element.opened > 0;

    case 'clicked':
      return (element) => element.clicked > 0;

    case 'failed':
      return (element) =>
        !['delivered', 'sending', 'bounced'].includes(element.status);

    default:
      break;
  }
};

export const MessagePublicQuery = atomFamily<PeopleMessage, string>({
  key: 'MessagePublicQuery',
  default: async (uuid: string) => {
    if (!uuid) {
      return null as PeopleMessage;
    }
    const { ok, data } = await mainApi.get<PeopleMessage>(
      `/v2/people/messages/${uuid}/share`
    );
    if (ok) {
      return data;
    }
  },
});

export const AddMessageFile = selectorFamily<
  PeopleMessageFile | PeopleMessageFile[] | RcFile | RcFile[],
  number
>({
  key: 'AddMessageFiles',
  get:
    (messageId) =>
    ({ get }) =>
      get(MessageByIdQuery(messageId)).files,
  set:
    (messageId) =>
    ({ set }, file: PeopleMessageFile) =>
      set(MessageByIdQuery(messageId), (message) => ({
        ...message,
        files: message.files.concat(file),
      })),
});

export const RemoveMessageFile = selectorFamily<
  PeopleMessageFile | PeopleMessageFile[] | RcFile | RcFile[],
  number
>({
  key: 'RemoveMessageFiles',
  get:
    (messageId) =>
    ({ get }) =>
      get(MessageByIdQuery(messageId)).files,
  set:
    (messageId) =>
    ({ set }, file: PeopleMessageFile) =>
      set(MessageByIdQuery(messageId), (message) => ({
        ...message,
        files: message.files.filter(({ id }) => id !== file.id),
      })),
});

export const BlogSearchText = atom<string>({
  key: 'BlogSearchText',
  default: '',
});

export const BlogSearchQuery = atom<ApiSearchResult<PeopleMessageBlog>>({
  key: 'BlogSearchQuery',
  default: selector({
    key: 'BlogSearchQuerySelector',
    get: async ({ get }) => {
      const id = get(SelectedOrganizationId);
      const searchText = get(BlogSearchText);

      if (!id) {
        return { items: [], total: 0 };
      }

      const { data, ok } = await mainApi.get<{
        items: PeopleMessageBlog[];
        totalCount: number;
      }>('/collaboration/blog-view', {
        imageFormat: 'span3_16-9',
        offset: 0,
        limit: 100,
        stripHtml: true,
        'filters[0][title]': searchText,
        'filters[0][organizationId]': id,
      });

      if (ok) {
        return { items: data.items, total: data.totalCount };
      }
    },
  }),
});

export const FormSearchTextState = atom<string>({
  key: 'FormSearchTextStateState',
  default: '',
});

export const FormSearchResultQuery = atom<ApiSearchResult<PeopleMessageForm>>({
  key: 'FormSearchResultQuerySelector',
  default: selector({
    key: 'FormSearchResultQuery',
    get: async ({ get }) => {
      const search = get(FormSearchTextState);
      const organizationId = get(SelectedOrganizationId);
      const { ok, data } = await formsApi.get<PeopleMessageForm[]>(
        '/forms/public/search',
        {
          limit: 2000,
          search,
          organizationId,
        }
      );
      if (ok) {
        return { items: data, total: data.length };
      }
      return { items: [], total: 0 };
    },
  }),
});
