'use strict';

class EditListModal {
  constructor(gettextCatalog, toastr) {
    'ngInject';

    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
  }

  $onInit() {
    this.list = angular.copy(this.resolve.list);
  }

  /**
   * Save the list
   *
   * @memberof EditListModal
   */
  saveList() {
    this.list.$save(() => {
      this.toastr.success(this.gettextCatalog.getString('List updated.'));
      this.close({ $value: this.list });
    });
  }
}
EditListModal.$inject = ['gettextCatalog', 'toastr'];

angular.module('cdApp.shared').component('cdEditPeopleListModal', {
  templateUrl:
    '@/app/people/shared/components/edit-list/edit-list.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: EditListModal,
});
