import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import FilePicker from './containers/file-picker';
export interface FilePickerComponentProps extends Record<string, unknown> {
  isUsedFromEditor: boolean;
}

export const FilePickerComponent = NiceModal.create<FilePickerComponentProps>(
  (props) => {
    const modal = useModal('FilePickerComponentModal');

    return (
      <FilePicker
        showModal={modal.visible}
        onCloseModal={modal.hide}
        callback={(file, imageFormat) => {
          modal.resolve({
            file,
            imageFormat,
            isUsedFromEditor: props.isUsedFromEditor,
          });
          modal.hide();
        }}
        isUsedFromEditor={props.isUsedFromEditor}
        onClose={modal.hide}
      />
    );
  }
);
