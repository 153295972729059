'use strict';

class EditBillingCustomerModalComponent {
  constructor(_, $q, $scope, cdApp, gettextCatalog, Customer) {
    'ngInject';

    this._ = _;
    this.$q = $q;
    this.$scope = $scope;
    this.cdApp = cdApp;
    this.gettextCatalog = gettextCatalog;
    this.Customer = Customer;
  }

  $onInit() {
    const { _ } = this;
    this.viewData = {
      loading: false,
    };

    const contactDetails = {
      name: this.resolve.customer.name,
      email: this.resolve.customer.email,
      address: {
        line1: _.get(this.resolve.customer.address, 'line1'),
        postalCode: _.get(this.resolve.customer.address, 'postal_code'),
        city: _.get(this.resolve.customer.address, 'city'),
        country: _.get(
          this.resolve.customer.address,
          'country',
          ''
        ).toLowerCase(),
      },
    };

    this.formData = contactDetails;
  }

  /**
   * Save the customer information
   *
   */
  saveCustomerPaymentDetails() {
    const paymentDetails = this._.pick(this.formData, [
      'name',
      'email',
      'address',
    ]);

    this.Customer.updateCustomer({}, paymentDetails)
      .$promise.then((source) => {
        this.viewData.loading = false;
        this.close({ $value: source });
      })
      .catch((error) => this.handleError(error));
  }

  /**
   * Handle errors returned by Stripe
   *
   * @param {Object} error
   */
  handleError(error) {
    this.viewData.loading = false;
    this.viewData.error = (error && error.data) || null;
  }
}
EditBillingCustomerModalComponent.$inject = [
  '_',
  '$q',
  '$scope',
  'cdApp',
  'gettextCatalog',
  'Customer',
];

angular.module('cdApp.shared').component('cdEditBillingCustomerModal', {
  templateUrl:
    '@/app/shared/components/add-payment-method/edit-billing-customer.component.html',
  controller: EditBillingCustomerModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
