'use strict';

angular.module('cdApp.people').component('cdPeopleRelationCard', {
  templateUrl:
    '@/app/people/shared/components/relation-card/relation-card.component.html',
  bindings: {
    relation: '<',
    removeRelation: '&',
  },

  controller: [
    'relationService',
    function (relationService) {
      'ngInject';

      let $ctrl = this;

      $ctrl.person = $ctrl.relation.person;
      $ctrl.relations = relationService.getRelations();
    },
  ],
});
