import { CdAction, ActionCreator } from '../../../redux/utils';

export const ADD_LOADING = 'startup/AddLoading';

export type AddLoading = CdAction<string>;

export const AddLoading: ActionCreator<string> = (payload) => ({
  type: ADD_LOADING,
  payload,
});

export const REMOVE_LOADING = 'startup/RemoveLoading';

export type RemoveLoading = CdAction<string>;

export const RemoveLoading: ActionCreator<string> = (payload) => ({
  type: REMOVE_LOADING,
  payload,
});
