import React, { useCallback, useEffect } from 'react';
import {
  Typography,
  Alert,
  Form,
  Space,
  Input,
  Col,
  Row,
  FormInstance,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';

import {
  StandardWorkingHoursInterface,
  UserTimeRegistrationCalendar,
} from './types/timeRegistration.types';
import { showStandardWorkingHoursModal } from './StandardWorkingHoursModal';
import { TimeRegistrationHoursInput } from './TimeRegistrationHoursInput';
import { useWorkingHours } from './hooks/useWorkingHours';
import { AutoSaveStateStatus } from './AutoSaveState';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdEditIcon } from '@/react/shared/components/Icons';
import { formatDecimalToTime } from '@/react/shared/utils';

export const WorkingHours = ({
  selectedDateInsight,
  timeRegistrationForm,
  standardWorkingHoursData,
}: {
  selectedDateInsight: UserTimeRegistrationCalendar;
  timeRegistrationForm: FormInstance;
  standardWorkingHoursData: StandardWorkingHoursInterface;
}) => {
  const { saveState, onInputChange, hoursInputReference, cancelDebounce } =
    useWorkingHours(
      selectedDateInsight.date.format('YYYY-MM-DD'),
      timeRegistrationForm
    );
  const hours = Form.useWatch('hours', timeRegistrationForm);

  const renderWarningMessage = useCallback(() => {
    if (
      selectedDateInsight?.booking &&
      (selectedDateInsight.hours === undefined ||
        selectedDateInsight.hours === 0)
    ) {
      return (
        <Alert
          showIcon
          message={gettextCatalog.getString(
            'Bookings or rota in calendar but no hours logged'
          )}
          type="warning"
        />
      );
    }
  }, [selectedDateInsight]);

  useEffect(() => {
    timeRegistrationForm.setFieldsValue({
      hours: selectedDateInsight ? selectedDateInsight.hours : 0,
      standardWorkingHours: standardWorkingHoursData,
      comment: selectedDateInsight?.comment,
    });
  }, [timeRegistrationForm, selectedDateInsight, standardWorkingHoursData]);

  return (
    <>
      <Col span={24}>
        <Typography.Text strong>
          {gettextCatalog.getString('Add hours worked')}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {gettextCatalog.getString(
            'You can use negative numbers to adjust your time balance if required.'
          )}
        </Typography.Text>
      </Col>
      <Col span={24}>{renderWarningMessage()}</Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label={
                <div
                  style={{
                    display: 'inline-flex',
                    gap: 8,
                    fontWeight: 'normal',
                  }}
                >
                  <Typography.Text>
                    {moment(dayjs(selectedDateInsight?.date).toDate()).format(
                      'dddd, LL'
                    )}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {dayjs(selectedDateInsight?.date).isSame(
                      dayjs().toDate(),
                      'day'
                    ) && `(${gettextCatalog.getString('Today')})`}
                  </Typography.Text>
                  <AutoSaveStateStatus saveState={saveState} />
                </div>
              }
              name="hours"
              style={{ marginBottom: '0px', textAlign: 'right', width: '100%' }}
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      const splits = value.toString().split(/[.,]+/);
                      if (
                        splits.length > 1 &&
                        splits[splits.length - 1].length > 2
                      ) {
                        cancelDebounce();
                        return Promise.reject(
                          gettextCatalog.getString(
                            'No more than 2 decimal places'
                          )
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              extra={formatDecimalToTime(hours)}
            >
              <TimeRegistrationHoursInput
                ref={hoursInputReference}
                onChange={onInputChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Link onClick={() => showStandardWorkingHoursModal()}>
              <Space>
                <CdEditIcon />
                {gettextCatalog.getString('Edit your standard working hours')}
              </Space>
            </Typography.Link>
          </Col>
          <Col span={24}>
            <CdVerticalSpace size={0}>
              <Form.Item
                label={gettextCatalog.getString('Comment')}
                name="comment"
                style={{ marginBottom: '0px' }}
              />
              <Form.Item
                name="comment"
                rules={[
                  {
                    max: 255,
                    message: gettextCatalog.getString(
                      'Comment must be less than 255 characters'
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={gettextCatalog.getString(
                    'Here you can register start and end time for your work day, projects you have worked on, or special cases like paid out overtime.'
                  )}
                />
              </Form.Item>
            </CdVerticalSpace>
          </Col>
        </Row>
      </Col>
    </>
  );
};
