import React from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '@/react/services/I18nService';

const Wrapper = styled.div`
  padding-left: 5px;
`;

const StyledH5 = styled.h5`
  color: #777;
  font-size: 15px;
  margin-top: 20px;
  text-transform: uppercase;
  padding-bottom: 10px;
`;

const FileDetail = ({ children }) => (
  <Wrapper>
    <StyledH5>{gettextCatalog.getString('Image details')}</StyledH5>
    {children}
  </Wrapper>
);

export default FileDetail;
