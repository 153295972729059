import { mainApi } from '../../api';

export interface CalendarSubscriptionPayload {
  id?: string;
  url: string;
  name: string;
  color: number;
  checkBookingConflict: boolean;
}

class CalendarSubscription {
  public async createCalendarSubscription(
    calendarSubscription: CalendarSubscriptionPayload
  ) {
    const response = await mainApi.post(
      '/calendar/ical-subscription',
      calendarSubscription
    );
    if (response.ok) return response.data;
    throw response.data;
  }

  public async fetchCalendarSubscriptions() {
    const response = await mainApi.get('/calendar/ical-subscription');
    if (response.ok) return response.data;
    throw response.data;
  }

  public async deleteCalendarSubscription(id: string) {
    const response = await mainApi.delete(`/calendar/ical-subscription/${id}`);
    if (response.ok) return response.data;
    throw response.data;
  }

  public async updateCalendarSubscription(
    id: string,
    calendarSubscription: Partial<CalendarSubscriptionPayload>
  ) {
    const { name, color, checkBookingConflict } = calendarSubscription;
    const response = await mainApi.put(`/calendar/ical-subscription/${id}`, {
      name,
      color,
      checkBookingConflict,
    });
    if (response.ok) return response.data;
    throw response.data;
  }

  public async forceResyncCalendarSubscription(id: string) {
    const response = await mainApi.post(
      `/calendar/ical-subscription/${id}/force-sync`
    );
    if (response.ok) return response.data;
    throw response.data;
  }
}

export default new CalendarSubscription();
