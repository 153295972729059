import { Reducer } from 'redux';
import * as immutable from 'seamless-immutable';

import createReducer from '../../../redux/utils';
import { Stole } from '../../models/stole';
import { FEvent as Event } from '../../../calendar/models/calendar';

import {
  CLEAR_EVENT_STOLE,
  FETCH_EVENT_STOLE_SUCCESS,
  FETCH_STOLES_SUCCESS,
  CLEAR_STOLE_BILLING_EVENTS,
  FETCH_STOLE_BILLING_EVENTS_SUCCESS,
  FetchEventStoleSuccessActionType,
  FetchStolesSuccessActionType,
  FetchStoleBillingEventsSuccessActionType,
} from './Actions';

export interface StolesState {
  eventStole: Stole;
  stoles: Stole[];
  stolesCount: number;
  stoleBillingEvents: Event[];
}

export type ImmutableStolesState = immutable.ImmutableObject<StolesState>;

const initialState: ImmutableStolesState = immutable.from<StolesState>({
  eventStole: null,
  stoles: [],
  stolesCount: null,
  stoleBillingEvents: [],
});

const clearEventStole: Reducer<ImmutableStolesState> = (state = initialState) =>
  state.merge({
    eventStole: null, // Clear stole for event view
  });

const clearStoleBillingEvents: Reducer<ImmutableStolesState> = (
  state = initialState
) =>
  state.merge({
    stoleBillingEvents: null, // Clear stoles for billing view
  });

const fetchEventStoleSuccess: Reducer<
  ImmutableStolesState,
  FetchEventStoleSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    eventStole: payload,
  });

const fetchStolesSuccess: Reducer<
  ImmutableStolesState,
  FetchStolesSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    stoles: payload.items,
    stolesCount: payload.count,
  });

const fetchStoleBillingEventsSuccess: Reducer<
  ImmutableStolesState,
  FetchStoleBillingEventsSuccessActionType
> = (state = initialState, { payload }) =>
  state.merge({
    stoleBillingEvents: payload,
  });

// Handlers

const handlers = {
  [CLEAR_EVENT_STOLE]: clearEventStole,
  [CLEAR_STOLE_BILLING_EVENTS]: clearStoleBillingEvents,
  [FETCH_EVENT_STOLE_SUCCESS]: fetchEventStoleSuccess,
  [FETCH_STOLES_SUCCESS]: fetchStolesSuccess,
  [FETCH_STOLE_BILLING_EVENTS_SUCCESS]: fetchStoleBillingEventsSuccess,
};

export default createReducer<ImmutableStolesState>(initialState, handlers);
