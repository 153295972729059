import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import NewsletterSignUpAnnouncementPage from '@/react/people/pages/NewsletterSignUpAnnouncementPage';

angular
  .module('cdApp.shared')
  .component(
    'cdrNewsletterSignUpAnnouncementPage',
    react2angular(
      sharedContext.use(NewsletterSignUpAnnouncementPage),
      [],
      ['$stateParams']
    )
  );
