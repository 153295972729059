import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { LaunchCreateNewsletterAndParticipantListModal } from '@/react/people/components/LaunchCreateNewsletterAndParticipantListModal';

angular
  .module('cdApp.people')
  .component(
    'cdrLaunchCreateNewsletterListModal',
    react2angular(
      sharedContext.use(LaunchCreateNewsletterAndParticipantListModal),
      ['refreshSegments'],
      []
    )
  );
