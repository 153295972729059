(function () {
  'use strict';

  /**
   * @desc automatically focus on the search input of a ui-select element
   * @example <ui-select ui-select-focus-input></ui-select>
   *
   * @see https://github.com/angular-ui/ui-select/issues/1201#issuecomment-145451324
   */ uiSelectFocusInput.$inject = ['$timeout'];

  function uiSelectFocusInput($timeout) {
    return {
      require: 'ui-select',
      restrict: 'A',
      link: function (scope) {
        function focusSearchInput() {
          scope.$select.searchInput[0].focus();
        }
        scope.$on('uis:activate', function () {
          $timeout(focusSearchInput, 0);
          $timeout(focusSearchInput, 100);
          $timeout(focusSearchInput, 200);
        });
      },
    };
  }

  angular
    .module('cdApp.shared')
    .directive('uiSelectFocusInput', uiSelectFocusInput);
})();
