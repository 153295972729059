import { useState } from 'react';

import { gettextCatalog } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';
import { UserUploadData } from '@/react/safeguarding/types/SafeguardingTypes';

export const useUserUploadProgress = (queue) => {
  const tableData = queue.rows.map((row: any) => ({
    ...row.data,
    errors: row.errors,
    addressUpdateStatus: row?.addressUpdateStatus,
  }));
  const [onlyShowFailed, setOnlyShowFailed] = useState(false);
  const filteredData: UserUploadData[] = onlyShowFailed
    ? tableData.filter((item: UserUploadData) => item.errors.length > 0)
    : tableData;

  const copyTableToClipboard = async () => {
    try {
      const translatedFirstName = gettextCatalog.getString('First name');
      const translatedLastName = gettextCatalog.getString('Last name');
      const translatedEmail = gettextCatalog.getString('Email');
      const translatedStreet = gettextCatalog.getString('Street');
      const translatedCity = gettextCatalog.getString('City');
      const translatedPostalCode = gettextCatalog.getString('Postal code');
      const translatedCountry = gettextCatalog.getString('Country');
      const translatedSafeguardingRequirement = gettextCatalog.getString(
        'Safeguarding Requirement'
      );
      const translatedType = gettextCatalog.getString('Type');
      const translatedWorkAndResponsibilities = gettextCatalog.getString(
        'Work and Responsibilities'
      );
      const translatedParishIds = gettextCatalog.getString('Parish Ids');

      const translatedErrors = gettextCatalog.getString('Errors');

      let rows = '';

      queue.rows.forEach((row) => {
        rows += `
        <tr>
          <td>${row.data.firstName || ''}</td>
          <td>${row.data.lastName || ''}</td>
          <td>${row.data.email || ''}</td>
          <td>${row.data.street || ''}</td>
          <td>${row.data.city || ''}</td>
          <td>${row.data.postCode || ''}</td>
          <td>${row.data.country || ''}</td>
          <td>${row.data.safeguardRequirement || ''}</td>
          <td>${row.data.type || ''}</td>
          <td>${row.data.workAndResponsibilities || ''}</td>
          <td>${row.data.parishIds || ''}</td>
          <td style="color: red;">${row.errors.toString()}</td>
        </tr>
      `;
      });

      const type = 'text/html';
      const data = new Blob(
        [
          `<table>
          <thead>
            <tr>
              <th>${translatedFirstName}</th>
              <th>${translatedLastName}</th>
              <th>${translatedEmail}</th>
              <th>${translatedStreet}</th>
              <th>${translatedCity}</th>
              <th>${translatedPostalCode}</th>
              <th>${translatedCountry}</th>
              <th>${translatedSafeguardingRequirement}</th>
              <th>${translatedType}</th>
              <th>${translatedWorkAndResponsibilities}</th>
              <th>${translatedParishIds}</th>
              <th>${translatedErrors}</th>
            </tr>
          </thead>
          <tbody>
            ${rows}
          </tbody>
        </table>`,
        ],
        { type }
      );

      const tableClipboard = new ClipboardItem({ [type]: data });

      await navigator.clipboard.write([tableClipboard]);
      NotificationService.notifySuccess(
        gettextCatalog.getString('{{count}} Rows copied to clipboard', {
          count: queue.rows.length,
        })
      );
    } catch (error: unknown) {
      NotificationService.notifyError((error as Error).message);
    }
  };
  return {
    tableData,
    onlyShowFailed,
    setOnlyShowFailed,
    filteredData,
    copyTableToClipboard,
  };
};
