(function () {
  'use strict';

  angular.module('cdApp.shared').component('cdSteps', {
    templateUrl: '@/app/shared/components/steps/steps.html',
    bindings: {
      stepsInstance: '<',
      makeSticky: '<',
      hideNumbers: '<',
      hideArrows: '<',
    },

    controller: [
      '$transclude',
      function ($transclude) {
        'ngInject';

        $transclude((element) => {
          this.hasTranscludedContent = element.length > 1;
        });
      },
    ],
    transclude: true,
  });
})();
