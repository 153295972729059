import { createSelector } from 'reselect';

const selectFilePicker = (state: any) => state?.filepicker?.filePicker;

const selectOwnProps = (state: any, props: any) => props;

export const makeSelectCallback = () =>
  createSelector(selectOwnProps, (props) => props.callback);
export const makeSelectIsGebCustomer = () =>
  createSelector(selectFilePicker, (filepicker) => filepicker?.isGebCustomer);
export const makeSelectIsUsedFromEditor = () =>
  createSelector(selectOwnProps, (props) => props.isUsedFromEditor);
