(function () {
  'use strict';

  /**
   * @desc return a formatted version of a phone number
   * @example <span>{{ 'your phone number' | formatPhoneNumber }}</span>
   */

  function formatPhoneNumber() {
    return function (input, countryCode, format) {
      if (!window.intlTelInputUtils) return input;
      return window.intlTelInputUtils.formatNumber(
        input,
        countryCode || null,
        format || 1
      );
    };
  }

  angular.module('cdApp.shared').filter('formatPhoneNumber', formatPhoneNumber);
})();
