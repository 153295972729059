class LandingPage {
  constructor(BeaconChat) {
    'ngInject';
    this.BeaconChat = BeaconChat;
  }

  openBeaconChat() {
    this.BeaconChat.showNewMessage();
  }
}
LandingPage.$inject = ['BeaconChat'];

angular.module('cdApp.shared').component('cdLandingPage', {
  templateUrl:
    '@/app/shared/components/landing-page/landing-page.component.html',
  controller: LandingPage,
  bindings: {
    packageName: '<',
    subtitle: '<',
    packageIcon: '<',
    packageIsEnabled: '<',
    userHasAccess: '<',
  },

  transclude: true,
});
