'use strict';

angular.module('cdApp.calendar').service('holidayService', [
  'moment',
  '$http',
  '$q',
  '_',
  function (moment, $http, $q, _) {
    'ngInject';

    this.data = [];

    this.promise = (() => {
      const deferred = $q.defer();
      const countryCode = _.get(cdApp, 'organization.countryIso2', 'gb');

      $http
        .get(
          `${
            cdApp.config.api.main
          }/calendar/holydays/${countryCode.toLowerCase()}`,
          {
            timeout: 5000,
          }
        )
        .then((res) => {
          this.data = _.map(res.data, (holiday) => ({
            title: holiday.title,
            start: holiday.date,
            end: moment(holiday.date).add(1, 'days'),
            allDay: true,
            backgroundColor: 'inherit',
            cls: 'holiday',
            textColor: 'inherit',
          }));
        })
        .finally(() => {
          deferred.resolve.call(this, this.data);
        });

      return deferred.promise;
    })();

    return this;
  },
]);
