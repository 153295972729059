'use strict';

class AdvancedFilterOperator {
  $onChanges(changesObj) {
    if (changesObj.operator) {
      this.operator = changesObj.operator.currentValue;
    }
  }
}

angular.module('cdApp.shared').component('cdAdvancedFilterOperator', {
  templateUrl:
    '@/app/shared/components/advanced-filter/advanced-filter-operator/advanced-filter-operator.component.html',
  bindings: {
    operator: '<',
    filter: '<',
  },

  controller: AdvancedFilterOperator,
});
