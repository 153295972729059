'use strict';

class AttendanceModalComponent {
  constructor($q, Attendance, Users, _) {
    'ngInject';

    this.$q = $q;
    this.Attendance = Attendance;
    this.Users = Users;
    this._ = _;
  }

  $onInit() {
    this.viewData = {};
    this.modelData = this.resolve.attendanceData || {
      attendanceCategoryId: null,
      vicarId: null,
      attendanceFields: {},
    };

    this.event = this.resolve.event;
    this.event.category =
      this.event.category || this._.find(this.event.taxonomies, 'isMaster');
    this.attendanceCategories = this.resolve.attendanceCategories;
    this.attendanceFields = this.resolve.attendanceFields;
    this.users = this.resolve.users || this.Users.query();
  }

  saveAttendance() {
    const _ = this._;
    const eventId = this.event.id;
    const promises = [
      this.$q.all(
        _.map(
          this.modelData.attendanceFields,
          (amount, attendanceFieldId) =>
            this.Attendance.updateAmount(
              { eventId, attendanceFieldId },
              { amount }
            ).$promise
        )
      ),
    ];

    if (
      !_.isUndefined(this.modelData.attendanceCategoryId) ||
      !_.isUndefined(this.modelData.vicarId)
    ) {
      promises.push(
        this.Attendance.updateEvent(
          { eventId },
          _.pick(this.modelData, ['attendanceCategoryId', 'vicarId'])
        ).$promise
      );
    }

    this.$q.all(promises).then(() => this.close());
  }
}

AttendanceModalComponent.$inject = ['$q', 'Attendance', 'Users', '_'];
angular.module('cdApp.calendar').component('cdRegisterAttendanceModal', {
  templateUrl:
    '@/app/calendar/attendance/components/attendance-modal/attendance-modal.component.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },

  controller: AttendanceModalComponent,
});
