import React from 'react';
import { Form, Input } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, IbanElement } from '@stripe/react-stripe-js';

import cdApp from '../../../config';
import { required } from '../../../shared/antd/validations/common';
import {
  createCdModal,
  SetModalPropsType,
} from '../../../shared/components/cd-modal-new/CdModal2';
import { useAddBankAccount } from '../../hooks/useAddBankAccount';

import { gettextCatalog } from '@/react/services/I18nService';

export interface AddBankAccountModalProps {
  currency: string;
  country: string;
}

const stripePromise = loadStripe(cdApp.references.stripe.contributions);

export const showAddBankAccountModal = createCdModal<AddBankAccountModalProps>(
  ({ setModalProps, country, currency }) => (
    <Elements stripe={stripePromise}>
      <InnerModal
        setModalProps={setModalProps}
        country={country}
        currency={currency}
      />
    </Elements>
  )
);

const InnerModal = ({
  setModalProps,
  country,
  currency,
}: { setModalProps: SetModalPropsType } & AddBankAccountModalProps) => {
  const { form, usesAccountNumber } = useAddBankAccount(
    setModalProps,
    country,
    currency
  );

  return (
    <Form form={form} layout="vertical">
      {!usesAccountNumber ? (
        <Form.Item extra={gettextCatalog.getString('Please enter your IBAN')}>
          <IbanElement
            options={{
              supportedCountries: ['SEPA'],
              placeholderCountry: (
                cdApp.organization.countryIso2 || ''
              ).toUpperCase(),
            }}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            label={gettextCatalog.getString('Account number')}
            name="accountNumber"
            rules={[required()]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={gettextCatalog.getString('Routing number')}
            name="routingNumber"
            rules={[required()]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </Form>
  );
};
