import { useQuery } from '@tanstack/react-query';

import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import { CdClientSideTableApiSearchParam } from '@/react/shared/components/cd-client-side-table/types';

export const GetPeopleAnniversaryList = (
  params: CdClientSideTableApiSearchParam
) =>
  useQuery({
    queryKey: ['usePeopleAnniveraryList', params],
    queryFn: async () => {
      const { type } = params.extraData;
      const payload = { filters: { anniversaryType: type } };
      const response = await mainApi.get<AnniversaryInterface[]>(
        `/people/people/anniversaries`,
        payload
      );
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      return { items: response.data, total: response.data?.length };
    },
  });
