'use strict';

angular.module('cdApp.shared').factory('contributionService', [
  '$filter',
  ($filter) => {
    'ngInject';

    return {
      /**
       * Get the name or email of the contributor
       *
       * @param {Object} contributor
       * @param {String} onlyEmail
       */
      getContributorData(payment, onlyEmail) {
        if (!payment.contributorData) {
          return onlyEmail
            ? _.get(payment, 'contact.email')
            : $filter('getName')(payment);
        }
        return onlyEmail
          ? payment.contributorData?.email || payment.payerEmail
          : `${payment.contributorData?.firstName || payment.payerFirstName} ${
              payment.contributorData?.lastName || payment.payerLastName
            }`;
      },
    };
  },
]);
