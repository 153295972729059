(function () {
  'use strict';

  function cdBlogCategoryFilter() {
    return function (blogs, selectedCategories) {
      if (
        !_.isUndefined(blogs) &&
        !_.isUndefined(selectedCategories) &&
        selectedCategories.length > 0
      ) {
        let tempBlogs = [];

        _.each(selectedCategories, function (name) {
          _.each(blogs, function (blog) {
            if (blog.category.name === name) {
              tempBlogs.push(blog);
            }
          });
        });

        return tempBlogs;
      } else {
        return blogs;
      }
    };
  }

  angular
    .module('cdApp.intranet')
    .filter('cdBlogCategoryFilter', cdBlogCategoryFilter);
})();
