class MangeRolesStateComponent {
  constructor(_, cdApp, $stateParams) {
    'ngInject';

    this._ = _;
    this.cdApp = cdApp;
    this.currentRoleId = $stateParams.id;
    this.currentRoleContext = $stateParams.roleContext;
    this.forceReloadRoles = $stateParams.forceReloadRoles;
  }
}
MangeRolesStateComponent.$inject = ['_', 'cdApp', '$stateParams'];

angular.module('cdApp.settings').component('cdManageRolesState', {
  template: require('./manage-roles.component.html'),
  controller: MangeRolesStateComponent,
});
