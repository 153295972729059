'use strict';

function CustomerModel($$resource, cdApp) {
  'ngInject';

  return $$resource(
    `${cdApp.config.api.main}/organizations/billing/:stripeCustomerId`,
    {},
    {
      getCustomer: {
        method: 'GET',
        url: `${cdApp.config.api.main}/stripe/customer`,
      },

      removeSource: {
        method: 'DELETE',
        url: `${cdApp.config.api.main}/stripe/sources`,
      },

      getInvoices: {
        method: 'GET',
        url: `${cdApp.config.api.main}/stripe/invoices/list/:stripeCustomerId`,
      },

      updateCustomer: {
        method: 'PUT',
        url: `${cdApp.config.api.main}/stripe/customer`,
      },
    }
  );
}
CustomerModel.$inject = ['$$resource', 'cdApp'];

angular.module('cdApp.shared').factory('Customer', CustomerModel);
