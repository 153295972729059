'use strict';

class EditRecurringModalComponent {
  constructor(_, gettextCatalog) {
    'ngInject';

    this._ = _;
    this.gettextCatalog = gettextCatalog;
  }

  $onInit() {
    this.selectedOption = 'single';
    this.type = this.resolve.type;
    this.timeChanged = this.resolve.timeChanged;
    this.shiftsChanged = this.resolve.shiftsChanged;

    this.options = [
      {
        value: 'single',
        icon: 'fa-cube',
        label:
          this.type === 'event'
            ? this.gettextCatalog.getString('Only this event')
            : this.gettextCatalog.getString('Only this absence'),
      },

      {
        value: 'all',
        icon: 'fa-cubes',
        label:
          this.type === 'event'
            ? this.gettextCatalog.getString('This and all following events')
            : this.gettextCatalog.getString('This and all following absences'),
      },
    ];
  }

  confirm() {
    this.close({
      $value: { updateAll: this.selectedOption === 'all' },
    });
  }
}
EditRecurringModalComponent.$inject = ['_', 'gettextCatalog'];

angular.module('cdApp.shared').component('cdEditRecurringModal', {
  templateUrl:
    '@/app/shared/components/edit-recurring-modal/edit-recurring-modal.component.html',
  controller: EditRecurringModalComponent,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
});
