import { $injector } from 'ngimport';

export function notifySuccess(errorMessage: string): void {
  this.notify(errorMessage, 'success');
}

export function notifyInfo(errorMessage: string): void {
  this.notify(errorMessage, 'info');
}

export function notifyWarning(errorMessage: string): void {
  this.notify(errorMessage, 'warning');
}

export function notifyError(errorMessage: string): void {
  this.notify(errorMessage, 'error');
}

export function notify(
  errorMessage: string,
  type: 'success' | 'info' | 'warning' | 'error',
  subTitle?: string,
  options?: any
): void {
  const toastr: any = $injector.get('toastr');
  switch (type) {
    case 'success':
      toastr.success(errorMessage);
      break;
    case 'info':
      if (subTitle) {
        toastr.info(subTitle, errorMessage, options);
      } else {
        toastr.info(errorMessage, options);
      }
      break;
    case 'warning':
      toastr.warning(errorMessage);
      break;
    case 'error':
      toastr.error(errorMessage);
      break;
  }
}

export default {
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyError,
  notify,
};
