'use strict';

/**
 * @desc A component that renders a ui-bootstrap progressbar with a dynamic color that changes depending
 * on the progress
 *
 * @example <cd-dynamic-progressbar value="50" max="100"></cd-dynamic-progressbar>
 *
 * @param {Number} value
 * @param {Number} max
 */
angular.module('cdApp.shared').component('cdDynamicProgressbar', {
  templateUrl:
    '@/app/shared/components/cd-dynamic-progressbar/cd-dynamic-progressbar.html',
  bindings: {
    value: '<',
    max: '<',
  },

  controller() {
    const $ctrl = this;

    // Determine the color of the progressbar
    const percent = $ctrl.value / $ctrl.max;
    let type;
    if (percent > 0.75) {
      type = 'success';
    } else if (percent > 0.25) {
      type = 'primary';
    } else {
      type = 'warning';
    }
    $ctrl.type = type;
  },
});
