import { AnyAction, combineReducers, Reducer } from 'redux';
import { reducer as modalReducer } from 'redux-saga-modal';

import CalendarReducer from '../calendar/store';
import ConfigReducer from '../config/store/Reducers';
import IntentionReducer from '../intention/redux';
import OrganizationReducer from '../organization/redux';
import LoadingReducer from '../shared/loading/redux';
import SharedReducer from '../shared/store';
import TaxonomiesReducer from '../taxonomies/store';
import UserReducer from '../user/redux';
import SettingsReducer from '../settings/redux';
import PortalReducer from '../portal/redux';
import filePickerReducer from '../calendar/file-picker-library/store/reducers';
import { calendarSettingsReducer } from '../calendar/store/calendar-settings/calendarSettingsSlice';

import StartupReducer from './startup-redux';

import { AppState } from '.';

const modalsReducerTyped = modalReducer as unknown as Reducer<
  AppState,
  AnyAction
>;
export default combineReducers<AppState, AnyAction>({
  calendar: CalendarReducer,
  config: ConfigReducer,
  startup: StartupReducer,
  modals: modalsReducerTyped,
  loading: LoadingReducer,
  taxonomies: TaxonomiesReducer,
  intention: IntentionReducer,
  settings: SettingsReducer,
  organization: OrganizationReducer,
  user: UserReducer,
  filepicker: filePickerReducer,
  shared: SharedReducer,
  calendarSettings: calendarSettingsReducer,
  portal: PortalReducer,
});
