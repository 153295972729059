import { get } from 'lodash';

import { mainApi } from '@/react/api';
import { buildErrorEntityByStatus } from '@/react/shared/errors';

const LanguageService = {
  getLanguage: async (organizationId: number) => {
    const res = await mainApi.get(`organizations/${organizationId}/public`);
    if (res.ok) return res.data;
    throw buildErrorEntityByStatus(
      get(res.data, 'status'),
      get(res.data, 'message')
    );
  },
};

export default LanguageService;
