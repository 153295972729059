import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { CollectionService } from '../services/CollectionService';
import {
  fetchAllCollectionsSuccess,
  createCollectionSuccess,
  putCollectionSuccess,
  removeCollectionSuccess,
} from '../redux/collection/Actions';

export function* fetchAllCollections({ payload }): SagaIterator {
  const data = yield call(CollectionService.getAllCollections, payload);
  yield put(fetchAllCollectionsSuccess(data));
}

export function* createCollection({ payload }): SagaIterator {
  const data = yield call(CollectionService.createCollection, payload);
  yield put(createCollectionSuccess(data));
}

export function* putCollection({ payload }): SagaIterator {
  const data = yield call(CollectionService.putCollection, payload);
  yield put(putCollectionSuccess(data));
}

export function* deleteCollection({ payload }): SagaIterator {
  const data = yield call(CollectionService.deleteCollection, payload);
  yield put(removeCollectionSuccess(data));
}
