import { useState, useCallback, useMemo } from 'react';
import { RecoilValue, useRecoilValueLoadable } from 'recoil';

import {
  UnPagedTableApiSearchParams,
  UnPagedTableApiSearchResult,
} from './types';

export interface useCdUnPagedTableProps<T> {
  dataResult: (
    param: UnPagedTableApiSearchParams
  ) => RecoilValue<UnPagedTableApiSearchResult<T>>;
  extraParams?: any;
  pageSize?: number;
  headingText?: (data: UnPagedTableApiSearchResult<T>) => string;
}

export interface useCdUnPagedTableResult<T> {
  data: UnPagedTableApiSearchResult<T>;
  handleTableChange: (type: 'next' | 'prev' | number) => void;
  isFetching: boolean;
  pageNumber: number;
  updatePageNumber: (type: 'next' | 'prev') => void;
  heading: string;
}

export function useCdUnPagedTable<T>({
  dataResult,
  pageSize,
  extraParams,
  headingText,
}: useCdUnPagedTableProps<T>) {
  // pageHistoryState to keep track of the cursor values for pagination
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageHistoryState, setPageHistoryState] = useState([]);

  const cursor =
    pageHistoryState?.length === 0 || pageHistoryState === undefined
      ? undefined
      : pageHistoryState[pageHistoryState?.length - 1];

  const data = useRecoilValueLoadable(
    dataResult({
      limit: pageSize,
      extraParams,
      cursor: cursor,
    })
  );

  // get the cursor value from data and push it to the pageHistory array
  const cursorValue = data?.contents?.cursor;

  const handleNext = useCallback(() => {
    setPageHistoryState([...pageHistoryState, cursorValue]);
  }, [pageHistoryState, cursorValue]);

  const handlePrev = useCallback(() => {
    const newHistoryState = [...pageHistoryState];

    // pop the last value from the pageHistory array to get the previous cursor value
    newHistoryState?.length > 0 && newHistoryState.pop();
    setPageHistoryState([...newHistoryState]);
  }, [pageHistoryState, setPageHistoryState]);

  const handleTableChange = useCallback(
    (type) => {
      if (type === 'next') {
        handleNext();
      }

      if (type === 'prev') {
        handlePrev();
      }
    },
    [handleNext, handlePrev]
  );

  const updatePageNumber = useCallback((type: string) => {
    if (type === 'next') {
      setPageNumber((prevValue) => prevValue + 1);
    }

    if (type === 'prev') {
      setPageNumber((prevValue) => {
        if (prevValue === 1) return 1;

        return prevValue - 1;
      });
    }
  }, []);

  const heading = useMemo(
    () => (headingText ? headingText(data?.contents) : ''),
    [headingText, data]
  );

  return {
    data: data?.contents,
    handleTableChange,
    isFetching: data.state === 'loading',
    pageNumber,
    updatePageNumber,
    heading,
  } as useCdUnPagedTableResult<any>;
}
