import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import { CropBounds, CropInformation } from '@/react/files/types/images.type';

export const getCrop = ({
  fileId,
  context,
}: {
  fileId: number;
  context: string;
}) =>
  mainApi.get<CropInformation>(
    `/files/${fileId}/crop-information?context=${context}`
  );

export const saveCrop = ({
  fileId,
  context,
  bounds,
  crop,
}: {
  fileId: number;
  context: string;
  bounds: string;
  crop: CropBounds;
}) =>
  mainApi.put(`/files/${fileId}/crop-information`, {
    context,
    bounds: {
      [bounds]: crop,
    },
  });

/**
 * Function to save crop information for a file
 * @param fileId The ID of the file to save crop information for
 * @param context The context of the crop information
 * @param bounds The crop bounds to save
 * @returns A Promise that resolves when the crop information is saved
 */
export const saveCrops = ({
  fileId,
  context,
  bounds,
}: {
  fileId: number;
  context: string;
  bounds: { [bound: string]: CropBounds };
}) =>
  mainApi.put(`/files/${fileId}/crop-information`, {
    context,
    bounds,
  });

export const ImageCrop = selectorFamily<
  CropInformation,
  { fileId: number; context: string }
>({
  key: 'ImageCrop',
  get:
    ({ fileId, context }: { fileId: number; context: string }) =>
    async () => {
      if (!fileId) {
        return;
      }
      const res = await getCrop({ fileId, context });
      if (res.ok) {
        return res.data;
      }
      if (!res.ok) {
        NotificationService.notifyError((res as any)?.data?.message);
        throw res;
      }
    },
});
