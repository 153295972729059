import NiceModal from '@ebay/nice-modal-react';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import CalendarControl from '../components/CalendarControl';
import CalendarView from '../components/CalendarView';
import Sidebar from '../components/SideBar';
import { useToggleOpenedAbsencePopover } from '../store/absences/absencesSlice';

import { navigate } from '@/react/services/StateServiceFactory';
const CalendarViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

interface CalendarContainerProps {
  $stateParams: { viewEvent: number };
}

const CalendarContainer = (props: CalendarContainerProps) => {
  const setIsToolTipVisible = useToggleOpenedAbsencePopover();

  const setIsToolTipVisibleCallBack = useCallback(() => {
    setIsToolTipVisible(false);
  }, [setIsToolTipVisible]);

  useEffect(() => {
    const eventId = props.$stateParams?.viewEvent;
    if (eventId) {
      NiceModal.show('EventDrawer', { eventId }).then(() => {
        navigate('app.private.calendar.full', {
          viewEvent: null,
        });
      });
    }
  }, [props.$stateParams?.viewEvent]);

  return (
    <Sidebar closePopover={setIsToolTipVisibleCallBack}>
      <CalendarViewContainer>
        <CalendarControl />
        <CalendarView />
      </CalendarViewContainer>
    </Sidebar>
  );
};

export default CalendarContainer;
