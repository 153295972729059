(function () {
  'use strict';
  Church.$inject = ['$$resource'];

  function Church($$resource) {
    return $$resource(
      cdApp.config.api.main + '/church',
      {},
      {
        get: {
          method: 'GET',
        },
      }
    );
  }

  angular.module('cdApp.shared').factory('Church', Church);
})();
