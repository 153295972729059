import React from 'react';
import styled from 'styled-components';

import { useOrganization } from '@/react/organization/hooks/useOrganization';

const LogoImg = styled.img`
  align-self: center;
  margin: 0;
  margin: 0 0 25px 0;
`;

export default function Logo() {
  const {
    organizationSettings: { logo, name },
  } = useOrganization();

  return logo.large && <LogoImg src={logo.large} alt={`${name} logo`} />;
}
